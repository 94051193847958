import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GrainBinFragmentFragment as Result,
  UpdateGrainBinMutationVariables as Variables,
  withUpdateGrainBin as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUpdateGrainBinHocChildProps = {
  updateGrainBin: (variables: Variables) => Promise<Result>;
};
export const withUpdateGrainBinHoc: AmberHoc<{}, WithUpdateGrainBinHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithUpdateGrainBinHocChildProps, typeof component>,
    WithUpdateGrainBinHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updateGrainBin: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected error');
          }
          const result = await mutate({
            variables,
          });
          if (!result) {
            throw new Error('Unexpected response from server');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected response from server');
          }
          return data.updateGrainBin;
        },
      };
    },
  })(component as any);
