import { push } from 'connected-react-router';
import { put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import { errorNav, pushNav, setAccountId } from '../action';
import { notifyIos } from '../api';
import { SelectedState } from '../reducer';

type StateWithSelected = {
  selected: SelectedState;
};
const selectedSelector = <T extends StateWithSelected>({ selected }: T): SelectedState => selected;

function* pushNavSaga(action: ReturnType<typeof pushNav>) {
  const {
    payload: { path, params = { selected: {} } },
  } = action;
  const selected_old: SelectedState = yield select(selectedSelector);
  const selected = { ...selected_old, ...params.selected };
  yield put(push(path, { selected }));
  notifyIos({ path, selected });
}

function* errorNavSaga() {
  if ((window as any).location.pathname.startsWith('/error')) {
    return;
  }
  const selected: SelectedState = yield select(selectedSelector);
  yield put(push('/error', { selected }));
}

// the react router redirect was not working as expected for the dashboard redirect on account selection so a manual redirect was added
function* setAccountIdSaga(action: ActionType<typeof setAccountId>) {
  const {
    payload: {
      account: { pile_support, grain_bin_support, barge_support },
    },
  } = action;

  if (window.location.pathname.includes('/grain_bin') && !grain_bin_support) {
    if (barge_support) {
      yield put(pushNav({ path: '/barge/dashboard' }));
    }
    if (pile_support) {
      yield put(pushNav({ path: '/pile/dashboard' }));
    }
  } else if (window.location.pathname.includes('/barge') && !barge_support) {
    if (grain_bin_support) {
      yield put(pushNav({ path: '/grain_bin/dashboard' }));
    }
    if (pile_support) {
      yield put(pushNav({ path: '/pile/dashboard' }));
    }
  } else if (window.location.pathname.includes('/pile') && !pile_support) {
    if (grain_bin_support) {
      yield put(pushNav({ path: '/grain_bin/dashboard' }));
    }
    if (barge_support) {
      yield put(pushNav({ path: '/barge/dashboard' }));
    }
  }
}

export function* navWatcher<T extends StateWithSelected = StateWithSelected>() {
  yield takeLatest(getType(pushNav), pushNavSaga);
  yield takeLatest(getType(errorNav), errorNavSaga);
  yield takeLatest(getType(setAccountId), setAccountIdSaga);
}
