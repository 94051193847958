import { Snackbar, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { QRScanDialog } from '../..';
import { UiFormError } from '../../../../../core/src/util/format-error';
import {
  FanSettingsFragmentFragment,
  FanType,
  withAssignFanControllerHoc,
  WithAssignFanControllerHocChildProps,
} from '../../../api';
import { getDiagTestFanControllerApi } from '../../../api/rest';
import { parseDeviceCoreId } from '../../../util/parsing-utils';
import { Alert } from '../../account/AccountUsersLayout';
import {
  DEVICE_NOT_FOUND_MSG,
  QR_CODE_SCAN_FAILED_MSG,
} from '../../device-wizard/AddFanControllerStep';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../../util/form2/BaseForm';
import { ButtonSubmit } from '../../util/form2/Button';
import { ErrorBox } from '../../util/form2/ErrorBox';
import { FanModelDetails } from '../grain-fan-model/GrainFanModelSelector';
import { FanControllerFormFields, validationSchema, Values } from './FanControllerFormFields';

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  largeGrid: {
    textAlign: 'initial',
    padding: 20,
    minWidth: 300,
    width: 300,
    [theme.breakpoints.up('sm')]: {
      width: 570,
    },
  },
}));

export const AssignFanControllerFormBase = ({
  assignFanController,
  grain_bin_id,
  ...props
}: WithAssignFanControllerHocChildProps &
  FormikWrapperHandlerProps<Values, FanSettingsFragmentFragment> & { grain_bin_id: number }) => {
  const classes = useStyles();
  const [showQRScannerDialog, setshowQRScannerDialog] = useState(false);
  const [hasQRScanningFailed, setHasQRScanningFailed] = useState(false);
  const [isDeviceNotFound, setIsDeviceNotFound] = useState(false);
  const [currentFanModelState, setCurrentFanModelState] = useState<FanModelDetails | null>(null);
  const openQRScannerDialog = () => {
    setshowQRScannerDialog(true);
  };
  const DEFAULT_NO_FANS_CONNECTED = '1';
  console.log('currentFanModelState', currentFanModelState);

  const closeQRScannerDialog = () => {
    setshowQRScannerDialog(false);
  };

  const handleScan = async (data, setFieldValue) => {
    if (data) {
      try {
        const coreId = parseDeviceCoreId(data);
        const response = await getDiagTestFanControllerApi(coreId);
        console.log('response', response.data.data);
        if (Object.keys(response.data.data).length === 0) {
          setIsDeviceNotFound(true);
          return;
        }
        setFieldValue('core_id', response.data.data.alias);
        closeQRScannerDialog();
      } catch (error) {
        setHasQRScanningFailed(true);
        console.error(error);
      }
    }
  };

  const handleError = (err) => {
    console.log('myerr', err);
    setHasQRScanningFailed(true);
  };

  return (
    <FormikWrapper<Values, FanSettingsFragmentFragment>
      {...props}
      validationSchema={validationSchema}
      initialValues={{
        core_id: '',
        fan_alias: '',
        has_plenum_sensor: false,
        phase: null,
        power_factor: null,
        voltage: null,
        diag_test_is_enable: true,
        no_of_fans_connected: DEFAULT_NO_FANS_CONNECTED,
      }}
      onSubmit={async (values, { resetForm }) => {
        const {
          core_id,
          fan_alias: alias,
          has_plenum_sensor,
          phase,
          power_factor,
          voltage,
          no_of_fans_connected,
        } = validationSchema.validateSync(values);
        if (currentFanModelState === null) {
          console.error('Invalid Fan Model');
          throw new UiFormError('Please Select Valid Fan Model');
        }
        const result = await assignFanController({
          core_id,
          grain_bin_id,
          alias,
          power_factor,
          has_plenum_sensor,
          no_of_fans_connected: Number(no_of_fans_connected),
          phase: phase === undefined || phase === null ? null : phase,
          voltage: voltage === undefined || voltage === null ? null : voltage,
          ...currentFanModelState,
          fan_horsepower: String(currentFanModelState.fan_horsepower),
          fan_model_type: currentFanModelState.fan_model_type as FanType,
        });
        await resetForm();
        setCurrentFanModelState(null); // reset fan model
        return result;
      }}
      render={({ validateForm, setFieldValue }) => {
        useEffect(() => {
          validateForm();
        }, [currentFanModelState]);
        return (
          <BaseForm submitting_message="Adding Fan Controller..." className={classes.largeGrid}>
            <div className={classes.column}>
              <FanControllerFormFields
                openQRScannerDialog={openQRScannerDialog}
                fan_model_id={null}
                currentFanModelState={currentFanModelState}
                setCurrentFanModelState={setCurrentFanModelState}
              />
              <ButtonSubmit
                variant="contained"
                color="primary"
                style={{ marginTop: 15, width: 100 }}
                allow_btn_disabled_for_validation={false}
              >
                Add
              </ButtonSubmit>
            </div>
            <ErrorBox withoutGrid />
            <QRScanDialog
              title="Scan device QR code"
              showDialog={showQRScannerDialog}
              closeDialog={closeQRScannerDialog}
              onQRCodeScan={(e) => handleScan(e, setFieldValue)}
              onQRCodeError={handleError}
            />
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={hasQRScanningFailed}
              onClose={() => setHasQRScanningFailed(false)}
              autoHideDuration={6000}
            >
              <Alert
                style={{ fontSize: 16 }}
                severity="error"
                onClose={() => setHasQRScanningFailed(false)}
              >
                {QR_CODE_SCAN_FAILED_MSG}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={isDeviceNotFound}
              onClose={() => setIsDeviceNotFound(false)}
              autoHideDuration={6000}
            >
              <Alert
                style={{ fontSize: 16 }}
                severity="error"
                onClose={() => setIsDeviceNotFound(false)}
              >
                {DEVICE_NOT_FOUND_MSG}
              </Alert>
            </Snackbar>
          </BaseForm>
        );
      }}
    />
  );
};

export const AssignFanControllerForm = withAssignFanControllerHoc(AssignFanControllerFormBase);
