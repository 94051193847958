import { AmberHoc, AmberHocProps } from '../../../util';
import { UserId as Result, withDeleteUser as HOC } from '../__generated';
import { GraphQLErrors } from './error';

export type WithDeleteUserHocChildProps = {
  deleteUser: (variables: { user_id: number; account_id: number }) => Promise<Result>;
};
export const withDeleteUserHoc: AmberHoc<{}, WithDeleteUserHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithDeleteUserHocChildProps, typeof component>,
    WithDeleteUserHocChildProps
  >({
    options: {
      errorPolicy: 'all',
    },
    props: (props) => {
      const { mutate, data } = props;
      return {
        deleteUser: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.deleteUser;
        },
      };
    },
  })(component as any);
