import { Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { light_gray_7 } from '../../../../core/src/style';
import { pushNav } from '../../action';
import { UserRole, ViewerFragmentFragment } from '../../api';
import { SelectedState } from '../../reducer';
import { ContainerTypeLegacy } from '../../util';

const selector = ({ selected }: { selected: SelectedState }) => selected;

export const GrainBinMenu: FunctionComponent<{
  viewer: ViewerFragmentFragment;
  url_prefix: string;
  handleGrainMenuClose: any;
  anchorEl: null | HTMLElement;
}> = ({
  viewer: {
    user: { role },
  },
  url_prefix,
  anchorEl,
  handleGrainMenuClose,
}) => {
  const dispatch = useDispatch();
  const onClickAddDevice = useCallback(() => dispatch(pushNav({ path: `${url_prefix}/devices` })), [
    url_prefix,
    dispatch,
  ]);
  const onClickExport = useCallback(
    () => dispatch(pushNav({ path: `${url_prefix}/export_telemetry` })),
    [url_prefix, dispatch]
  );
  const onClickSettings = useCallback(() => dispatch(pushNav({ path: `${url_prefix}/settings` })), [
    url_prefix,
    dispatch,
  ]);
  const onClickManageTickets = useCallback(
    () => dispatch(pushNav({ path: `${url_prefix}/tickets` })),
    [url_prefix, dispatch]
  );

  const onClickManageStoragePeriods = useCallback(
    () => dispatch(pushNav({ path: `${url_prefix}/storage_periods` })),
    [url_prefix, dispatch]
  );

  const selected = useSelector(selector);

  let settings_type = '';
  if (selected) {
    if (selected.container_type === ContainerTypeLegacy.bin) {
      settings_type = 'Grain Bin ';
    } else if (selected.container_type === ContainerTypeLegacy.barge) {
      settings_type = 'Barge ';
    } else if (selected.container_type === ContainerTypeLegacy.pile) {
      settings_type = 'Pile ';
    }
  }
  return (
    <>
      <MoreVert style={{ height: '46px', marginLeft: 5 }} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={(e: any) => {
          e.stopPropagation();
          handleGrainMenuClose();
        }}
        onClose={(e: any) => {
          e.stopPropagation();
          handleGrainMenuClose();
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          style: {
            border: `1px solid ${light_gray_7}`,
          },
        }}
      >
        {role !== UserRole.ReadOnly && role !== UserRole.FanAccess && onClickSettings && (
          <MenuItem onClick={onClickSettings}>{settings_type}Settings</MenuItem>
        )}
        {/* first menu item is automatically selected fro some reason  */}
        <MenuItem key="placeholder" style={{ display: 'none' }} />
        {role !== UserRole.ReadOnly && role !== UserRole.FanAccess && onClickAddDevice && (
          <MenuItem onClick={onClickAddDevice}>Add/Configure Devices</MenuItem>
        )}
        {/*{role !== UserRole.ReadOnly && role !== UserRole.FanAccess && url_prefix === '/barge' && (*/}
        {/*  <MenuItem onClick={onClickContainerEvents}>Events</MenuItem>*/}
        {/*)}*/}
        {onClickExport && <MenuItem onClick={onClickExport}>Download Telemetry</MenuItem>}

        <MenuItem onClick={onClickManageStoragePeriods}>Configure Storage Periods</MenuItem>
        <MenuItem onClick={onClickManageTickets}>Manage Grain Tickets</MenuItem>
      </Menu>
    </>
  );
};
