import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { AccountFragmentFragment, UserRole, ViewerFragmentFragment } from '../../api';
import { AuthenticatedRoute } from '../util';
import { AccountUsersLayout } from './AccountUsersLayout';

export enum AccountRoutesSuffix {
  users = 'users',
}
const validateAccess = (
  account: AccountFragmentFragment,
  { user: { role } }: ViewerFragmentFragment
) => [UserRole.Admin, UserRole.FullAccess, UserRole.ReadOnly, UserRole.FanAccess].includes(role);
const accessFailureRedirect = () => '/';
export const AccountRoutes = ({
  history,
  location,
  match,
  staticContext,
  account_id,
  viewerAccountId,
  hasAdminAccess,
}: RouteComponentProps & {
  account_id: number;
  viewerAccountId: number;
  hasAdminAccess: boolean;
}) => (
  <AuthenticatedRoute
    validateAccess={validateAccess}
    accessFailureRedirect={accessFailureRedirect}
    account_id={account_id}
  >
    <Switch>
      {/*Test*/}
      <Route
        path={`${match.url}/${AccountRoutesSuffix.users}`}
        render={() => (
          <AccountUsersLayout
            account_id={account_id}
            viewerAccountId={viewerAccountId}
            hasAdminAccess={hasAdminAccess}
            history={history}
          />
        )}
      />
      <Route render={() => <Redirect to={'/'} />} />
    </Switch>
  </AuthenticatedRoute>
);
