import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { GrayGearIcon, HideShowIcon, SmallPlusIcon } from '../../../../../../core/src/media';
import { black_shade_2, black_shade_4, white } from '../../../../../../core/src/style';
import { UNASSIGNED_SITE_NAME } from '../ManageSitesView';

const useStyles = makeStyles((theme: Theme) => ({
  addBtn: {
    width: 63,
    height: 24,
    border: `1px solid ${black_shade_4}`,
    borderRadius: 4,
    color: black_shade_2,
    fontSize: 14,
    textTransform: 'capitalize',
    fontFamily: 'Work Sans,sans-serif',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: white,
  },
  groupCell: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px dashed var(--ag-border-color, #babfc7)!important',
  },
  ungroupCell: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ManageSitesGroupHeader = (props) => {
  console.log('props in manage', props);
  const classes = useStyles();
  // TODO: fix: showing input box is not working sometimes
  const addNewSite = async (): Promise<number> => {
    console.log('props in addNewSite', props);
    props.api.refreshCells({ force: true });
    const initVal = `New Site ${Math.round(Math.random() * 100)}`; // must be unique to create new group
    const newSite = [{ id: initVal, site_id: null, siteHierarchy: [initVal] }];
    const { add } = props.api.applyTransaction({
      add: newSite,
      addIndex: 0,
    });
    console.log('row added', add[0]);
    const addedRowIndex = add[0].rowIndex;
    return addedRowIndex;
  };

  const handleCreateNewSite = async () => {
    console.log('props.isCellEditing', props.isCellEditing);
    if (!props.isCellEditing) {
      const addedRowIndex = await addNewSite();
      console.log('addedRowIndex', addedRowIndex);

      props.api.clearFocusedCell();
      // props.api.setFocusedCell(3, 'ag-Grid-AutoColumn');
      setTimeout(() => {
        props.api.startEditingCell({
          rowIndex: addedRowIndex,
          colKey: 'ag-Grid-AutoColumn',
          charPress: 'New Site',
        });
      }, 10);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        width: '100%',
        height: '100%',
      }}
    >
      <div className={classes.groupCell} style={{ width: 79 }}>
        <button className={classes.addBtn} onClick={handleCreateNewSite}>
          <SmallPlusIcon style={{ marginRight: 5 }} /> Site
        </button>
      </div>
      <div className={classes.groupCell} style={{ width: 40 }}>
        <HideShowIcon />
      </div>
      <div className={classes.groupCell} style={{ width: 171 }}>
        Name
      </div>
      <div className={classes.ungroupCell} style={{ width: 41 }}>
        <GrayGearIcon />
      </div>
    </div>
  );
};

export { ManageSitesGroupHeader };
