import { Grid, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import * as yup from 'yup';
import { FanModelCreatedType, FanType } from '../../../api';

import { getDistanceUnitLabel } from '../../../../../core/src/util';
import Info from '../../info-section/Info';
import { CenteredSpinner } from '../../spinner';
import { BaseForm, FormikWrapper } from '../../util/form2/BaseForm';
import { ButtonSubmit } from '../../util/form2/Button';
import { ErrorBox } from '../../util/form2/ErrorBox';
import { NumberTextField } from '../../util/form2/NumberField';
import { Select as SelectForm } from '../../util/form2/Select';
import { TextField } from '../../util/form2/TextField';
import { FanModelDetails } from './GrainFanModelSelector';

type AddOrUpdateGrainFanModelProps = {
  currentCustomFanModelState: FanModelDetails | null;
  handleSetCurrentFanModelState: (currentCustomFanModelState) => void;
};

export type FanModelAddOrUpdateValues = {
  make: string;
  fan_brand_name: string;
  fan_horsepower?: number | null;
  fan_model_type: string | null;
  diameter?: number | null;
};

const base_validation = {
  make: yup
    .string()
    .label('Brand')
    .required(),
  fan_brand_name: yup
    .string()
    .label('Model')
    .required(),
  fan_model_type: yup
    .string()
    .oneOf(['axial_flow', 'centrifugal_high', 'centrifugal', 'centrifugal_inline'])
    .required()
    .typeError('Type is required field')
    .nullable(false)
    .label('Type'),

  fan_horsepower: yup
    .number()
    .typeError('A number is required')
    .positive()
    .required()
    .label('Horsepower'),
  diameter: yup
    .number()
    .typeError('A number is required')
    .positive()
    .required()
    .label('Diameter'),
};
export const updateAddNewFanModelValidationSchema = yup.object().shape({
  ...base_validation,
});

export const to_fan_model_type_fan_type = {
  ['axial_flow']: FanType.AxialFlow,
  ['centrifugal']: FanType.Centrifugal,
  ['centrifugal_high']: FanType.CentrifugalHigh,
  ['centrifugal_inline']: FanType.CentrifugalInline,
};

export const fan_model_type_to_option_value = {
  [FanType.AxialFlow]: 'axial_flow',
  [FanType.Centrifugal]: 'centrifugal',
  [FanType.CentrifugalHigh]: 'centrifugal_high',
  [FanType.CentrifugalInline]: 'centrifugal_inline',
};

const useStyles = makeStyles({
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});

export const AddOrUpdateCustomGrainFanModel = (props: AddOrUpdateGrainFanModelProps) => {
  const classes = useStyles();

  const { handleSetCurrentFanModelState, currentCustomFanModelState } = props;
  console.log('current selected fan_model state', currentCustomFanModelState);
  const isUpdateCustomGrainFanModelFlow = Boolean(currentCustomFanModelState);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const distanceUnitLabel = getDistanceUnitLabel({ cgs: true });
  const initialValues = currentCustomFanModelState
    ? {
        make: currentCustomFanModelState.make,
        fan_brand_name: currentCustomFanModelState.fan_brand_name,
        fan_model_type: fan_model_type_to_option_value[currentCustomFanModelState.fan_model_type],
        diameter: currentCustomFanModelState.diameter,
        fan_horsepower: currentCustomFanModelState.fan_horsepower,
      }
    : {
        make: '',
        fan_brand_name: '',
        fan_model_type: null,
        diameter: null,
        fan_horsepower: null,
      };
  const submitBtnText = isUpdateCustomGrainFanModelFlow ? 'Update' : 'Add';

  const handleSubmit = async (values) => {
    const {
      make,
      fan_brand_name,
      diameter,
      fan_horsepower,
      fan_model_type,
    } = updateAddNewFanModelValidationSchema.validateSync(values);
    setShowLoadingIcon(true);
    try {
      let result;
      if (currentCustomFanModelState && isUpdateCustomGrainFanModelFlow) {
        result = {
          fan_brand_name,
          fan_horsepower,
          diameter,
          make,
          fan_model_type: to_fan_model_type_fan_type[fan_model_type],
          fan_model_created_type: FanModelCreatedType.CustomCreated,
          fan_model_id: currentCustomFanModelState.fan_model_id,
        };
      } else {
        result = {
          make,
          fan_brand_name,
          fan_model_type: to_fan_model_type_fan_type[fan_model_type],
          fan_horsepower: fan_horsepower ? fan_horsepower : 0,
          diameter: diameter ? diameter : 0,
          fan_model_created_type: FanModelCreatedType.CustomCreated,
          fan_model_id: null,
        };
      }
      result && handleSetCurrentFanModelState(result);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoadingIcon(false);
    }
    return { make, fan_brand_name, diameter, fan_horsepower, fan_model_type };
  };

  if (showLoadingIcon) {
    return (
      <Grid>
        <CenteredSpinner fadeIn="none" />
      </Grid>
    );
  }

  return (
    <FormikWrapper<
      FanModelAddOrUpdateValues,
      {
        make: string;
        fan_brand_name: string;
        fan_model_type: string;
        diameter: number;
        fan_horsepower: number;
      }
    >
      {...props}
      validationSchema={updateAddNewFanModelValidationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={() => (
        <BaseForm
          submitting_message="Adding new Fan Model..."
          style={{ padding: 0, width: '100%' }}
        >
          <div className={classes.column}>
            <Grid container>
              <Grid item xs={12}>
                <Info>
                  <Typography variant="body1" style={{ fontStyle: 'italic', marginBottom: 6 }}>
                    *If your fan motor has a Horse Power (HP) range choose the HP at the top of the
                    range.
                  </Typography>
                  <Typography variant="body1" style={{ fontStyle: 'italic' }}>
                    For instance, if the HP is rated at "10-15", type in "15" in the HP input field.
                  </Typography>
                </Info>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <TextField
                  style={{ marginTop: 10 }}
                  name="make"
                  label="Brand"
                  placeholder="Make"
                  fullWidth
                  required
                />
                <TextField
                  style={{ marginTop: 10 }}
                  name="fan_brand_name"
                  label="Model"
                  placeholder="Model"
                  fullWidth
                  required
                />
                <SelectForm
                  name="fan_model_type"
                  label="Type"
                  fullWidth
                  style={{ marginTop: 10 }}
                  placeholder="None Selected"
                >
                  <MenuItem value={'axial_flow'}>Axial</MenuItem>
                  <MenuItem value={'centrifugal_high'}>Centrifugal High(3500 RPM)</MenuItem>
                  <MenuItem value={'centrifugal'}>Centrifugal Low(1750 RPM)</MenuItem>
                  <MenuItem value={'centrifugal_inline'}>Centrifugal Inline</MenuItem>
                </SelectForm>

                <NumberTextField
                  style={{ marginTop: 10 }}
                  name="fan_horsepower"
                  label="Horsepower"
                  placeholder="Horsepower"
                  id="fan_horsepower"
                  parseValue={(val) => Number(val)}
                  inputMode="decimal"
                  step=".01"
                  fullWidth
                  required
                />
                <NumberTextField
                  style={{ marginTop: 10 }}
                  name="diameter"
                  id="diameter"
                  label={`Diameter (${distanceUnitLabel})`}
                  placeholder="Diameter"
                  parseValue={(val) => Number(val)}
                  inputMode="decimal"
                  step=".01"
                  fullWidth
                  required
                />

                <ButtonSubmit
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 20, marginLeft: 0, width: 100 }}
                  allow_pristine
                >
                  {submitBtnText}
                </ButtonSubmit>
              </Grid>
            </Grid>
          </div>
          <ErrorBox withoutGrid />
        </BaseForm>
      )}
    />
  );
};
