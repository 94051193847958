import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import {
  GrainSurfaceShapeType,
  GrainTicketActionType,
  GrainType,
  SensorGeneratedGrainTicket,
} from '../../../../../../core/src/api';
import { BaseForm, FormikWrapper } from '../../../../../../core/src/component/util/form2/BaseForm';
import { Button, ButtonSubmit } from '../../../../../../core/src/component/util/form2/Button';
import { ErrorBox } from '../../../../../../core/src/component/util/form2/ErrorBox';
import { SelectGrainTicketActionType } from '../../../../../../core/src/component/util/form2/SelectGrainTicketActionType';
import { TextField } from '../../../../../../core/src/component/util/form2/TextField';
import { ActiveStoragePeriodContext } from '../../../../../../core/src/contexts';
import {
  DoubleArrowIcon,
  HalfInvertedGrainSurfaceIcon,
  HalfPeakedGrainSurfaceIcon,
  InvertedGrainSurfaceIcon,
  LevelGrainSurfaceIcon,
  PeakedGrainSurfaceIcon,
  SideDrawGrainSurfaceIcon,
  SmallRightArrowIcon,
} from '../../../../../../core/src/media';
import { amber_green, blue_shade_2 } from '../../../../../../core/src/style';
import { getMassUnitLabel, getTestWeightLabel } from '../../../../../../core/src/util';
import { UiFormError } from '../../../../../../core/src/util/format-error';
import {
  calculateTicketMetricsForSensorTicket,
  grainTicketFormValidationSchema,
} from '../CreateOrUpdateGrainTicketForm';
import { UpdateGrainTicket } from './ManagePendingGrainTickets';

type SensorGeneratedGrainTicketInput = {
  ticket_date: string;
  ticket_time: string;
  volume: string | number;
  weight_in_lbs: string | number;
  test_weight_in_lbs: string | number;
  grain_ticket_action: GrainTicketActionType;
  grain_moisture_pct: number | string;
  notes: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  approveButton: {
    backgroundColor: amber_green,
    color: '#fff',
    '&:hover': {
      backgroundColor: amber_green,
    },
  },
  grainSurfaceShape: {
    '& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)': {
      marginLeft: '4px',
    },
    '& .Mui-selected': {
      borderColor: 'gray !important',
    },
  },
  grainSurfaceShapeOptLabel: {
    marginTop: 4,
  },
  grainSurfaceShapeOptBtn: {
    lineHeight: 1,
    // width: 50,
    border: '1px solid lightgray !important',
    borderRadius: '4px !important',
    padding: '4px 2px',
    [theme.breakpoints.up(theme.breakpoints.width('xs') + 445)]: {
      width: 60,
    },
    [theme.breakpoints.between(410, 445)]: {
      marginRight: 5,
    },
    '&:last-child': {
      marginRight: 0,
    },
    '& .MuiToggleButton-label': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      textTransform: 'capitalize',
      [theme.breakpoints.only('xs')]: {
        lineHeight: 1,
      },
    },
  },
}));

export const ApproveGrainTicketForm = ({
  grainTicket,
  handleTicketApprove,
  grain_type,
  closeForm,
  grainSurfaceShape,
  handleGrainSurfaceShapeChange,
  handleGrainActionChange,
}: {
  grainTicket: SensorGeneratedGrainTicket;
  handleTicketApprove: (updatedGrainTicket: UpdateGrainTicket) => Promise<void>;
  grain_type: GrainType;
  closeForm: () => void;
  grainSurfaceShape: GrainSurfaceShapeType;
  handleGrainSurfaceShapeChange: (
    event: React.MouseEvent<HTMLElement>,
    grainSurfaceShapeVal: GrainSurfaceShapeType
  ) => void;
  handleGrainActionChange: (action: GrainTicketActionType) => void;
}) => {
  const classes = useStyles();
  const [isHighlightGrainMoistField, setIsHighlightGrainMoistField] = useState(false);
  const [isHighlightTestWeightField, setIsHighlightTestWeightField] = useState(false);
  const [isHighlightWeightField, setIsHighlightWeightField] = useState(false);
  const [isHighlightVolumeField, setIsHighlightVolumeField] = useState(false);
  const activeStoragePeriodContext = useContext(ActiveStoragePeriodContext);
  const activeStoragePeriod = activeStoragePeriodContext.activeStoragePeriod;
  const {
    grain_bin_id,
    epoch_time: grain_bin_ticket_epoch_time,
    grain_bin_ticket_id,
    volume,
    notes,
    action_performed,
  } = grainTicket;
  const ticketDate = grain_bin_ticket_epoch_time
    ? DateTime.fromMillis(grain_bin_ticket_epoch_time.getTime()).toFormat('yyyy-MM-dd')
    : DateTime.local().toFormat('yyyy-MM-dd');
  const ticketTime = grain_bin_ticket_epoch_time
    ? DateTime.fromMillis(grain_bin_ticket_epoch_time.getTime()).toFormat('HH:mm')
    : DateTime.local().toFormat('HH:mm');

  const initialValues = {
    ticket_date: ticketDate || '',
    ticket_time: ticketTime || '',
    volume: volume !== undefined ? volume : '',
    weight_in_lbs: '',
    test_weight_in_lbs: '',
    grain_ticket_action: action_performed || GrainTicketActionType.Added,
    grain_moisture_pct: '',
    notes: notes || '',
  };
  const massUnitLabel = getMassUnitLabel();
  const testWeightLabel = getTestWeightLabel();

  const handleMoistureOnFocus = () => {
    setIsHighlightGrainMoistField(true);
    setIsHighlightTestWeightField(true);
    setIsHighlightWeightField(true);
  };

  const handleMoistureOnBlur = () => {
    setIsHighlightGrainMoistField(false);
    setIsHighlightTestWeightField(false);
    setIsHighlightWeightField(false);
  };

  const handleVolumeOnFocus = () => {
    setIsHighlightVolumeField(true);
    setIsHighlightWeightField(true);
  };
  const handleVolumeOnBlur = () => {
    setIsHighlightVolumeField(false);
    setIsHighlightWeightField(false);
  };

  const handleTestWeightOnFocus = () => {
    setIsHighlightTestWeightField(true);
    setIsHighlightWeightField(true);
  };

  const handleTestWeightOnBlur = () => {
    setIsHighlightTestWeightField(false);
    setIsHighlightWeightField(false);
  };

  const handleWeightOnFocus = () => {
    setIsHighlightWeightField(true);
    setIsHighlightVolumeField(true);
  };

  const handleWeightOnBlur = () => {
    setIsHighlightWeightField(false);
    setIsHighlightVolumeField(false);
  };

  const handleSubmit = async (values) => {
    const {
      ticket_date,
      ticket_time,
      volume,
      weight_in_lbs,
      test_weight_in_lbs,
      grain_ticket_action,
      grain_moisture_pct,
      notes,
    } = values;
    grainTicketFormValidationSchema.validateSync(values);
    console.log('values', {
      ticket_date,
      ticket_time,
      volume,
      weight_in_lbs,
      test_weight_in_lbs,
      grain_ticket_action,
      grain_moisture_pct,
      notes,
    });
    const epoch_time = DateTime.fromFormat(
      `${ticket_date} ${ticket_time}`,
      'yyyy-MM-dd HH:mm'
    ).toJSDate();
    console.log(
      'epoch_time',
      epoch_time,
      DateTime.fromFormat(`${ticket_date} ${ticket_time}`, 'yyyy-MM-dd HH:mm')
    );
    const isGrainTicketValidforSelectedStoragePeriod = activeStoragePeriod
      ? epoch_time.getTime() >= activeStoragePeriod.start_date.getTime() &&
        (activeStoragePeriod.end_date
          ? epoch_time.getTime() <= activeStoragePeriod.end_date.getTime()
          : true)
      : true;

    if (activeStoragePeriod && !isGrainTicketValidforSelectedStoragePeriod) {
      const getFormatedDateString = (date) =>
        DateTime.fromMillis(date.getTime()).toFormat('MM/dd/yyyy');
      const start_date_str = getFormatedDateString(activeStoragePeriod.start_date);
      const end_date_str = activeStoragePeriod.end_date
        ? getFormatedDateString(activeStoragePeriod.end_date)
        : 'Ongoing';
      throw new UiFormError(
        `Please specify the ticket date and ticket time within selected storage period(${start_date_str}-${end_date_str})`
      );
    }
    const result = await handleTicketApprove({
      grain_bin_ticket_id,
      grain_bin_id,
      epoch_time,
      volume,
      weight_in_lbs,
      test_weight_in_lbs,
      grain_moisture_pct,
      notes,
      action_performed,
    });
  };
  return (
    <FormikWrapper<SensorGeneratedGrainTicketInput, void>
      initialValues={initialValues}
      validationSchema={grainTicketFormValidationSchema}
      onSubmit={handleSubmit}
      render={({ values, setFieldValue, handleChange, validateField }) => {
        return (
          <BaseForm submitting_message={'Saving changes...'} style={{ padding: 0 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} sm={6}>
                <TextField
                  fullWidth
                  name="ticket_date"
                  label="Ticket Date"
                  type="date"
                  placeholder="MM/DD/YYYY"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  fullWidth
                  name="ticket_time"
                  label="Ticket Time"
                  type="time"
                  placeholder="HH:MM AM"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="notes"
                  type="text"
                  label="Notes"
                  placeholder="e.g. from field A.. , by John.."
                  autoComplete="off"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <SelectGrainTicketActionType
                  name="grain_ticket_action"
                  label="Action"
                  onChange={handleGrainActionChange}
                  fullWidth
                />
              </Grid>
              {/* left section start*/}
              <Grid item xs={5} sm={5}>
                <Grid
                  container
                  style={{
                    paddingTop: 6,
                    paddingBottom: 20,
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      name="grain_moisture_pct"
                      type="number"
                      label="Grain Moisture(%)"
                      inputProps={{
                        inputMode: 'decimal',
                      }}
                      onChange={async (e) => {
                        await handleChange(e);
                        const current_values = { ...values };
                        validateField('grain_moisture_pct');
                        current_values['grain_moisture_pct'] = parseFloat(
                          parseFloat(e.target.value).toFixed(2)
                        );
                        calculateTicketMetricsForSensorTicket(
                          grain_type,
                          setFieldValue,
                          current_values,
                          'grain_moisture_pct'
                        );
                      }}
                      onFocus={handleMoistureOnFocus}
                      onBlur={handleMoistureOnBlur}
                      style={{
                        backgroundColor: isHighlightGrainMoistField ? blue_shade_2 : undefined,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: 20 }}>
                    <TextField
                      name="volume"
                      type="number"
                      label="Volume(bushel)"
                      inputProps={{
                        inputMode: 'decimal',
                      }}
                      fullWidth
                      onChange={async (e) => {
                        await handleChange(e);
                        const current_values = { ...values };
                        validateField('volume');
                        current_values['volume'] = parseFloat(
                          parseFloat(e.target.value).toFixed(2)
                        );
                        calculateTicketMetricsForSensorTicket(
                          grain_type,
                          setFieldValue,
                          current_values,
                          'volume'
                        );
                      }}
                      onFocus={handleVolumeOnFocus}
                      onBlur={handleVolumeOnBlur}
                      style={{
                        backgroundColor: isHighlightVolumeField ? blue_shade_2 : undefined,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* center section start*/}
              <Grid
                item
                xs={1}
                sm={1}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100px',
                }}
              >
                <div>
                  <SmallRightArrowIcon />
                </div>
                <div>
                  <DoubleArrowIcon />
                </div>
              </Grid>

              {/* right section start*/}
              <Grid item xs={6} sm={6}>
                <Grid
                  container
                  style={{
                    border: '1px solid lightgray',
                    borderRadius: 8,
                    padding: '6px 6px 20px 6px',
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      name="test_weight_in_lbs"
                      type="number"
                      label={`Test Weight(${testWeightLabel})`}
                      inputProps={{
                        inputMode: 'decimal',
                      }}
                      fullWidth
                      onChange={async (e) => {
                        await handleChange(e);
                        const current_values = { ...values };
                        validateField('test_weight_in_lbs');
                        current_values['test_weight_in_lbs'] = parseFloat(
                          parseFloat(e.target.value).toFixed(2)
                        );
                        calculateTicketMetricsForSensorTicket(
                          grain_type,
                          setFieldValue,
                          current_values,
                          'test_weight_in_lbs'
                        );
                      }}
                      onFocus={handleTestWeightOnFocus}
                      onBlur={handleTestWeightOnBlur}
                      style={{
                        backgroundColor: isHighlightTestWeightField ? blue_shade_2 : undefined,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: 20 }}>
                    <TextField
                      name="weight_in_lbs"
                      type="number"
                      label={`Total Weight(${massUnitLabel})`}
                      fullWidth
                      onChange={async (e) => {
                        await handleChange(e);
                        const current_values = { ...values };
                        validateField('weight_in_lbs');
                        current_values['weight_in_lbs'] = parseFloat(
                          parseFloat(e.target.value).toFixed(2)
                        );
                        calculateTicketMetricsForSensorTicket(
                          grain_type,
                          setFieldValue,
                          current_values,
                          'weight_in_lbs'
                        );
                      }}
                      onFocus={handleWeightOnFocus}
                      onBlur={handleWeightOnBlur}
                      style={{
                        backgroundColor: isHighlightWeightField ? blue_shade_2 : undefined,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              {/* Grain Surface Shape starts */}
              <Grid item xs={12}>
                <Typography style={{ marginBottom: 5 }}>Grain Surface Shape</Typography>
                <ToggleButtonGroup
                  value={grainSurfaceShape}
                  exclusive
                  size="medium"
                  onChange={handleGrainSurfaceShapeChange}
                  aria-label="text alignment"
                  className={classes.grainSurfaceShape}
                >
                  <ToggleButton
                    value={GrainSurfaceShapeType.Peaked}
                    aria-label="Peaked"
                    className={classes.grainSurfaceShapeOptBtn}
                  >
                    <PeakedGrainSurfaceIcon />
                    <span className={classes.grainSurfaceShapeOptLabel}>Peaked</span>
                  </ToggleButton>
                  <ToggleButton
                    value={GrainSurfaceShapeType.HalfPeaked}
                    aria-label="Half Peaked"
                    className={classes.grainSurfaceShapeOptBtn}
                  >
                    <HalfPeakedGrainSurfaceIcon />
                    <span className={classes.grainSurfaceShapeOptLabel}>&#189; Peaked</span>
                  </ToggleButton>
                  <ToggleButton
                    value={GrainSurfaceShapeType.Level}
                    aria-label="Level"
                    className={classes.grainSurfaceShapeOptBtn}
                  >
                    <LevelGrainSurfaceIcon />
                    <span className={classes.grainSurfaceShapeOptLabel}>Level</span>
                  </ToggleButton>
                  <ToggleButton
                    value={GrainSurfaceShapeType.HalfInverted}
                    aria-label="Half Inverted"
                    className={classes.grainSurfaceShapeOptBtn}
                  >
                    <HalfInvertedGrainSurfaceIcon />
                    <span className={classes.grainSurfaceShapeOptLabel}>&#189; Inverted</span>
                  </ToggleButton>
                  <ToggleButton
                    value={GrainSurfaceShapeType.Inverted}
                    aria-label="Inverted"
                    className={classes.grainSurfaceShapeOptBtn}
                  >
                    <InvertedGrainSurfaceIcon />
                    <span className={classes.grainSurfaceShapeOptLabel}>Inverted</span>
                  </ToggleButton>
                  <ToggleButton
                    value={GrainSurfaceShapeType.SideDraw}
                    aria-label="Side Draw"
                    className={classes.grainSurfaceShapeOptBtn}
                  >
                    <SideDrawGrainSurfaceIcon />
                    <span className={classes.grainSurfaceShapeOptLabel}>Side Draw</span>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{
                    margin: 0,
                    maxWidth: '100%',
                    maxHeight: '100%',
                    minWidth: '100%',
                    minHeight: '100%',
                  }}
                  onClick={closeForm}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={9}>
                <ButtonSubmit
                  className={classes.approveButton}
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{
                    margin: 0,
                    maxWidth: '100%',
                    maxHeight: '100%',
                    minWidth: '100%',
                    minHeight: '100%',
                  }}
                  allow_pristine
                  prevent_submit_on_enter
                  allow_btn_disabled_for_validation={false}
                >
                  Approve
                </ButtonSubmit>
              </Grid>

              <ErrorBox />
            </Grid>
          </BaseForm>
        );
      }}
    />
  );
};
