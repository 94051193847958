import { IconButton, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { pushNav, setFanCardView } from '../../../../../core/src/action';

import { ErrorIcon, MediumErrorIcon, MediumRedAlertIcon } from '../../../../../core/src/media';
import { ThreeDotMenuIcon } from '../../../../../core/src/media/icons/ThreeDotMenuIcon';
import { black_shade_8, light_gray_7 } from '../../../../../core/src/style';
import { ContainerTypeLegacy } from '../../../../../core/src/util';
import { FanControlsNavigation } from '../../grain-container/FanControlNavigationOption';

type GrainBinCellMenuProps = {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleGrainMenuClose: () => void;
  goToContainerBin: () => void;
  goToGrainBinAutomationFlow: () => void;
  goToManageGrainTickets: () => void;
  hasBinContainsAnyFan: boolean;
  automationMenuItemText: string;
};

export const BinCellRenderer = (props) => {
  console.log('props in BinCellRenderer', props);
  const goContainer = props.goContainer;
  const dispatch = useDispatch();
  const { value: container_name, data } = props;
  const grainBinId = data.grain_bin_id;
  const fanAutomationInfo = data.automation_info;
  const hasBinContainsAnyFan = data.bin_fan_status.length > 0;
  const automationMenuItemText = fanAutomationInfo.enabled
    ? 'Edit Fan Automation'
    : 'Start Fan Automation';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleGrainMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleGrainMenuClose = () => {
    setAnchorEl(null);
  };

  const goToManageGrainTickets = () => {
    dispatch(
      pushNav({
        path: `/grain_bin/${grainBinId}/tickets`,
        params: {
          selected: {
            container_name,
            container_id: grainBinId,
            container_type: ContainerTypeLegacy.bin,
          },
        },
      })
    );
  };

  const goToContainerBin = () => {
    goContainer({
      container_name,
      container_id: grainBinId,
      container_type: ContainerTypeLegacy.bin,
    });
  };

  const goToGrainBinAutomationFlow = () => {
    goContainer({
      container_name,
      container_id: grainBinId,
      container_type: ContainerTypeLegacy.bin,
    });
    dispatch(
      setFanCardView({
        initialFanControlCardNavigationType: FanControlsNavigation.AskCurrentGrainConditions,
      })
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div
        style={{
          textAlign: 'left',
          lineHeight: '20px',
          fontSize: 14,
          color: black_shade_8,
        }}
      >
        {container_name}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {data.has_errored_fan && (
          <div
            style={{
              display: 'flex',
              marginRight: data.last_week_co2_alert_sent_on ? 10 : undefined,
            }}
          >
            <MediumErrorIcon />
          </div>
        )}

        {data.last_week_co2_alert_sent_on && (
          <div
            style={{
              display: 'flex',
            }}
          >
            <MediumRedAlertIcon />
          </div>
        )}

        <div
          style={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <IconButton
            onClick={handleGrainMenuClick}
            aria-haspopup="true"
            aria-controls={open ? 'grain-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="more"
            style={{ padding: 5, width: 30, height: 30 }}
          >
            <ThreeDotMenuIcon />
          </IconButton>
          <GrainBinCellMenu
            anchorEl={anchorEl}
            open={open}
            handleGrainMenuClose={handleGrainMenuClose}
            goToContainerBin={goToContainerBin}
            goToGrainBinAutomationFlow={goToGrainBinAutomationFlow}
            goToManageGrainTickets={goToManageGrainTickets}
            hasBinContainsAnyFan={hasBinContainsAnyFan}
            automationMenuItemText={automationMenuItemText}
          />
        </div>
      </div>
    </div>
  );
};

const GrainBinCellMenu = ({
  anchorEl,
  open,
  handleGrainMenuClose,
  goToContainerBin,
  goToGrainBinAutomationFlow,
  goToManageGrainTickets,
  hasBinContainsAnyFan,
  automationMenuItemText,
}: GrainBinCellMenuProps) => {
  const menuItemStyles = { minHeight: 22 };
  return (
    <Menu
      id="grain-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleGrainMenuClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        style: {
          border: `1px solid ${light_gray_7}`,
          marginLeft: 10,
        },
      }}
      MenuListProps={{
        disablePadding: true,
      }}
    >
      <MenuItem style={menuItemStyles} onClick={goToContainerBin}>
        Go to Bin
      </MenuItem>
      {hasBinContainsAnyFan && (
        <MenuItem style={menuItemStyles} onClick={goToGrainBinAutomationFlow}>
          {automationMenuItemText}
        </MenuItem>
      )}
      <MenuItem style={menuItemStyles} onClick={goToManageGrainTickets}>
        Manage Grain Tickets
      </MenuItem>
    </Menu>
  );
};
