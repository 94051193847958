import {
  Button,
  Grid,
  MenuItem,
  Select,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFanStartTriggered, setIsFanStopTriggered } from '../../../../../core/src/action';
import { useGrainBinData } from '../../../../../core/src/contexts';
import { AutomationIcon, EqualizerIcon } from '../../../../../core/src/media';
import { renderModeColor } from '../../../../../core/src/util';
import {
  ContainerType,
  FanControllerRunWindow,
  FanControllerStateValueNext,
  FanRunWindowRecommendedOption,
  UserRole,
  withGetUserHoc,
  WithGetUserHocChildProps,
  withSetFanRunNowScheduleHoc,
  WithSetFanRunNowScheduleHocChildProps,
} from '../../../api';
import { amber_green, black_shade_3, light_gray_7, white } from '../../../style';
import { getFanStatusInfo } from '../../operations/ops-table-components';
import { DialogSpinner } from '../../spinner';
import ConfirmationModal from '../../util/ConfirmationModal';
import {
  createNavigationTypeFromNavOption,
  FanControlNavigationOption,
  FanControlsNavigation,
} from '../FanControlNavigationOption';
import { AerationWindow } from './AerationWindow';
import { CancelScheduleDialog } from './CancelScheduleDialog';
import { EditScheduleDialog } from './EditScheduleDialog';
import EndAutomationWhenNoRecommWindows from './EndAutomationWhenNoRecommWindows';
import { FanStatusValues } from './FanControls';
import { SimpleFanStatus } from './FanStatusHelpers';
import { GrainWeatherInputsCard } from './GrainWeatherInputsCard';
import { UpcomingFanScheduleTable } from './UpcomingFanScheduleTable';

const formatTime = (value) => value.toFormat('h:mm a');

export const getRecommedationTypeMode = (recommendationType) => {
  if (recommendationType) {
    return {
      [FanRunWindowRecommendedOption.Cooling]: 'Cool',
      [FanRunWindowRecommendedOption.Drying]: 'Dry',
      [FanRunWindowRecommendedOption.Reconditioning]: 'Recon',
      [FanRunWindowRecommendedOption.Custom]: 'Custom',
      [FanRunWindowRecommendedOption.Manual]: 'Manual',
    }[recommendationType];
  }
  return 'NA';
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: { paddingLeft: 10, paddingRight: 10, height: 36, width: 36 },
  left_margin: { marginLeft: 10 },
  alertText: { marginBottom: 10, fontWeight: 650, fontSize: 16 },
  fanAutomationIconContainer: {
    marginRight: 6,
    width: 16,
    height: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  generateAutomation: {
    fontFamily: 'Work Sans,sans-serif',
    width: 306,
    height: 43,
    fontSize: 16,
    color: white,
    backgroundColor: amber_green,
    boxShadow: '0px 0px 4px #00000033',
    borderRadius: 6,
    textTransform: 'capitalize',
    [theme.breakpoints.down(375)]: {
      width: 298,
    },
  },
  dividerContainer: {
    width: 306,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down(375)]: {
      width: 298,
    },
  },
  divider: { backgroundColor: light_gray_7, height: 1, width: '100%', marginTop: 5 },
  verticalDivider: {
    backgroundColor: light_gray_7,
    height: 14,
    width: 1,
  },
  dividerText: {
    fontFamily: 'Work Sans,sans-serif',
    color: light_gray_7,
    fontSize: 12,
    margin: '1px 0px',
  },
  customBtn: {
    width: '145px',
    height: '38px',
    boxShadow: '0px 0px 4px #00000033',
    fontFamily: 'Work Sans,sans-serif',
    border: `1px solid ${amber_green}`,
    borderRadius: 6,
    fontSize: 16,
    textTransform: 'capitalize',
    marginRight: 20,
    [theme.breakpoints.down(375)]: {
      marginRight: 8,
    },
  },
  runNowBtn: {
    fontFamily: 'Work Sans,sans-serif',
    padding: 0,
    display: 'flex',
    width: '145px',
    height: '38px',
    boxShadow: '0px 0px 4px #00000033',
    border: `1px solid ${amber_green}`,
    borderRadius: 6,
    fontSize: 16,
    backgroundColor: white,
  },
  fanRunNowPeriod: {
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: 20,
    },
  },
  grainWeatherInputsCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 25,
    marginBottom: 30,
  },
}));

export const FAN_RUN_NOW_PERIODS = {
  '15min': '15min',
  '1hr': '1hr',
  '12hr': '12hr',
  '24hr': '24hr',
  '1week': '1week',
  forever: 'forever',
};
export interface FanRunNowPeriodOptionValue {
  displayName: string;
  value: string;
}
export const FAN_RUN_NOW_PERIOD_OPTIONS: FanRunNowPeriodOptionValue[] = [
  { displayName: '15 Min', value: FAN_RUN_NOW_PERIODS['15min'] },
  { displayName: '1 Hour', value: FAN_RUN_NOW_PERIODS['1hr'] },
  { displayName: '12 Hours', value: FAN_RUN_NOW_PERIODS['12hr'] },
  { displayName: '24 Hours', value: FAN_RUN_NOW_PERIODS['24hr'] },
  { displayName: '1 Week', value: FAN_RUN_NOW_PERIODS['1week'] },
  { displayName: 'Forever', value: FAN_RUN_NOW_PERIODS.forever },
];

const FanScheduleBase: React.FunctionComponent<
  {
    grain_bin_id: number;
    any_running: boolean;
    has_override: boolean;
    fan_count: number;
    scheduled_start: DateTime | null;
    scheduled_end: DateTime | null;
    aeration_schedule: FanControllerRunWindow[] | null;
    aeration_schedule_type: FanRunWindowRecommendedOption | null;
    hide_restart_delay?: boolean;
    stop_sent: Date | null;
    restart_sent: Date | null;
    setNavigation: Dispatch<SetStateAction<FanControlNavigationOption>>;
    navigation: FanControlNavigationOption;
    stopFans: () => void;
    cancelNextRun: () => void;
    cancelAllSchedule: () => void;
    restartFans: () => void;
    pollAllFanControllerState: () => Promise<any>;
    opt_in_fan_guidance: boolean;
    fan_guidance_start_date: Date | null;
    fan_guidance_end_date: Date | null;
    enable_fan_guidance: boolean;
    recommendation_type: string | null;
    fan_statuses: FanStatusValues[];
    refechGrainBin: () => Promise<any>;
    user_id: number;
    current_account_id: number;
    show_fan_guidance_ext_prompt: boolean;
    setShowFanGuidanceExtPrompt: (show_fan_guidance_ext) => void;
    onClickEdit: () => void;
    hasManualMode: boolean;
    showRestartButton: boolean;
    setShowRestartButton: React.Dispatch<React.SetStateAction<boolean>>;
  } & WithGetUserHocChildProps &
    WithSetFanRunNowScheduleHocChildProps
> = ({
  grain_bin_id,
  any_running,
  has_override,
  fan_count,
  scheduled_start,
  scheduled_end,
  aeration_schedule,
  aeration_schedule_type,
  hide_restart_delay: hide_restart_button,
  stop_sent,
  restart_sent,
  navigation,
  user,
  setNavigation,
  stopFans,
  cancelNextRun,
  cancelAllSchedule,
  restartFans,
  setFanRunNowSchedule,
  pollAllFanControllerState,
  opt_in_fan_guidance,
  enable_fan_guidance,
  fan_guidance_start_date,
  fan_guidance_end_date,
  recommendation_type,
  fan_statuses,
  user_id,
  current_account_id,
  refechGrainBin,
  refecthUser,
  show_fan_guidance_ext_prompt,
  setShowFanGuidanceExtPrompt,
  onClickEdit,
  hasManualMode,
  showRestartButton,
  setShowRestartButton,
}) => {
  const [isFanStartTriggered] = useSelector(({ global_state: { isFanStartTriggered } }) => [
    isFanStartTriggered,
  ]);
  const { current_grain_temp, current_grain_emc, target_grain_emc } = useGrainBinData();
  const modeType = hasManualMode ? FanRunWindowRecommendedOption.Manual : aeration_schedule_type;
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [isSavingSchedule, setIsSavingSchedule] = useState<boolean>(false);
  const defaultFanRunNowPeriodPref = FAN_RUN_NOW_PERIODS['15min'];
  const lastSelectedFanRunNowPeriodPref =
    user &&
    Object.keys(FAN_RUN_NOW_PERIODS).includes(
      user.grain_bin_fan_run_now_period_pref.fan_run_now_period
    )
      ? user.grain_bin_fan_run_now_period_pref.fan_run_now_period
      : defaultFanRunNowPeriodPref;
  const [fanRunNowPeriod, setFanRunNowPeriod] = useState<string>(lastSelectedFanRunNowPeriodPref);
  const handleFanRunNowPeriodChange = (event) => {
    setFanRunNowPeriod(event.target.value);
  };
  const hasUserReadOnlyAccess = Boolean(user && user.role === UserRole.ReadOnly);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const tooSmallMobile = useMediaQuery('(max-width:375px)');
  const classes = useStyles();
  const has_valid_schedule =
    scheduled_start && scheduled_end && scheduled_end.getTime() > new Date().getTime();
  const first_fan_status = fan_statuses[0].status;
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const onClickCancel = () => setShowCancelDialog(true);
  const closeCanceltDialog = () => setShowCancelDialog(false);
  const hasSingleRunWindow = aeration_schedule && aeration_schedule.length === 1;
  let has_current_overlap_runwindow = false;
  const cancelWindowDirectly = (opt_in_fan_guidance && enable_fan_guidance) || hasSingleRunWindow;

  const showFanStopCommandSentMsg = Boolean(
    any_running && stop_sent && new Date().getTime() - new Date(stop_sent).getTime() < 30 * 1000
  );

  const fanStopCommandSentMsg =
    stop_sent && showFanStopCommandSentMsg
      ? `Please wait, Stop command sent
  ${formatTime(DateTime.fromMillis(new Date(stop_sent).getTime()))}`
      : '';

  const showFanRestartCommandSentMsg = Boolean(
    restart_sent && new Date().getTime() - new Date(restart_sent).getTime() < 30 * 1000
  );

  const fanRestartCommandSentMsg =
    restart_sent && showFanRestartCommandSentMsg
      ? `Please wait, Restart command sent
      ${formatTime(DateTime.fromMillis(new Date(restart_sent).getTime()))}`
      : '';

  if (scheduled_start && scheduled_end) {
    const now = new Date().getTime();
    has_current_overlap_runwindow = Boolean(
      now && scheduled_start && scheduled_end && now > scheduled_start && now < scheduled_end
    );
  }
  useEffect(() => {
    const advanced_fan_statuses = fan_statuses.map((it) => {
      return {
        ...it,
        status: getFanStatusInfo(it.status, any_running).status,
      };
    });
    const hasAnyFanReady = advanced_fan_statuses.some(
      ({ status }) => status === SimpleFanStatus.READY
    );
    if (
      !isFanStartTriggered &&
      !enable_fan_guidance &&
      !any_running &&
      hasAnyFanReady &&
      has_current_overlap_runwindow &&
      !hide_restart_button
    ) {
      setShowRestartButton(true);
    } else {
      setShowRestartButton(false);
    }
  }, [
    has_current_overlap_runwindow,
    first_fan_status,
    any_running,
    enable_fan_guidance,
    hide_restart_button,
  ]);
  useEffect(() => {
    setFanRunNowPeriod(lastSelectedFanRunNowPeriodPref);
  }, [lastSelectedFanRunNowPeriodPref]);

  const handleFanRunNow = async () => {
    try {
      dispatch(setIsFanStopTriggered({ isFanStopTriggered: false }));
      dispatch(setIsFanStartTriggered({ isFanStartTriggered: true }));
      setIsSavingSchedule(true);
      const result = await setFanRunNowSchedule({
        user_id,
        account_id: current_account_id,
        container_id: grain_bin_id,
        container_type: ContainerType.Bin,
        period: fanRunNowPeriod,
      });
      console.log('setFanRunNowSchedule response', result);
      await Promise.all([refechGrainBin(), refecthUser(), pollAllFanControllerState()]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSavingSchedule(false);
    }
  };

  if (isSavingSchedule) {
    return <DialogSpinner title="Saving Schedule..." open={isSavingSchedule} />;
  }

  // first and foremost if the fan is running you should be able to stop it
  return (
    <div style={{ width: '100%' }}>
      {any_running && (
        <>
          <div className={classes.grainWeatherInputsCard}>
            <GrainWeatherInputsCard
              current_grain_temp={
                current_grain_temp === null || current_grain_temp === undefined
                  ? null
                  : current_grain_temp
              }
              current_grain_emc={current_grain_emc || null}
              target_grain_emc={target_grain_emc || null}
              mode={getRecommedationTypeMode(modeType)}
              modeColor={renderModeColor(modeType)}
            />
          </div>
          <AerationWindow
            fan_schedules={aeration_schedule}
            cancel_button_text={'STOP'}
            onClickCancel={() => {
              opt_in_fan_guidance && enable_fan_guidance
                ? setShowConfirmationModal(true)
                : stopFans();
            }}
            show_edit_sched_btn={true}
            onClickEdit={onClickEdit}
            start={scheduled_start}
            end={scheduled_end}
            grain_bin_id={grain_bin_id}
            show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
            setShowFanGuidanceExtPrompt={setShowFanGuidanceExtPrompt}
            hasUserReadOnlyAccess={hasUserReadOnlyAccess}
            hasManualMode={hasManualMode}
            showFanStopCommandSentMsg={showFanStopCommandSentMsg}
            fanStopCommandSentMsg={fanStopCommandSentMsg}
            showFanRestartCommandSentMsg={showFanRestartCommandSentMsg}
            fanRestartCommandSentMsg={fanRestartCommandSentMsg}
          />
        </>
      )}
      {!any_running && has_valid_schedule && (
        <Fragment>
          <div className={classes.grainWeatherInputsCard}>
            <GrainWeatherInputsCard
              current_grain_temp={
                current_grain_temp === null || current_grain_temp === undefined
                  ? null
                  : current_grain_temp
              }
              current_grain_emc={current_grain_emc || null}
              target_grain_emc={target_grain_emc || null}
              mode={getRecommedationTypeMode(modeType)}
              modeColor={renderModeColor(modeType)}
            />
          </div>
          <AerationWindow
            fan_schedules={aeration_schedule}
            cancel_button_text={'CANCEL'}
            restart_button_text={`RESTART ${fan_count === 1 ? 'FAN' : 'FANS'}`}
            onClickCancel={cancelWindowDirectly ? cancelNextRun : onClickCancel}
            onClickRestart={restartFans}
            start={scheduled_start as Date}
            end={scheduled_end as Date}
            grain_bin_id={grain_bin_id}
            show_restart_button={showRestartButton}
            show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
            setShowFanGuidanceExtPrompt={setShowFanGuidanceExtPrompt}
            hasUserReadOnlyAccess={hasUserReadOnlyAccess}
            show_edit_sched_btn={true}
            onClickEdit={onClickEdit}
            hasManualMode={hasManualMode}
            showFanStopCommandSentMsg={showFanStopCommandSentMsg}
            fanStopCommandSentMsg={fanStopCommandSentMsg}
            showFanRestartCommandSentMsg={showFanRestartCommandSentMsg}
            fanRestartCommandSentMsg={fanRestartCommandSentMsg}
          />
        </Fragment>
      )}
      {!any_running && aeration_schedule && aeration_schedule.length === 0 && enable_fan_guidance && (
        <EndAutomationWhenNoRecommWindows
          cancelNextRun={cancelNextRun}
          aeration_schedule={aeration_schedule}
          aeration_schedule_type={aeration_schedule_type || null}
          setNavigation={setNavigation}
          navigation={navigation}
          opt_in_fan_guidance={opt_in_fan_guidance}
          enable_fan_guidance={enable_fan_guidance}
          fan_guidance_start_date={fan_guidance_start_date}
          fan_guidance_end_date={fan_guidance_end_date}
          recommendation_type={recommendation_type}
          grain_bin_id={grain_bin_id}
          show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
          setShowFanGuidanceExtPrompt={setShowFanGuidanceExtPrompt}
          show_edit_sched_btn={enable_fan_guidance && opt_in_fan_guidance}
          addRecommendedWindow={() => {
            setNavigation(
              createNavigationTypeFromNavOption(
                FanControlsNavigation.AskCurrentGrainConditions,
                navigation
              )
            );
          }}
          hasManualMode={hasManualMode}
          hasUserReadOnlyAccess={hasUserReadOnlyAccess}
        />
      )}
      {aeration_schedule && (aeration_schedule.length || hasManualMode) ? (
        <UpcomingFanScheduleTable
          any_running={any_running}
          fan_schedules={aeration_schedule}
          aeration_schedule_type={aeration_schedule_type}
          loading={false}
          startSettingNavigation={() =>
            setNavigation(
              createNavigationTypeFromNavOption(
                FanControlsNavigation.ShowManualRunWindows,
                navigation
              )
            )
          }
          addRecommendedWindow={() => {
            setNavigation(
              createNavigationTypeFromNavOption(
                FanControlsNavigation.AskCurrentGrainConditions,
                navigation
              )
            );
          }}
          opt_in_fan_guidance={opt_in_fan_guidance}
          enable_fan_guidance={enable_fan_guidance}
          fan_guidance_start_date={fan_guidance_start_date}
          fan_guidance_end_date={fan_guidance_end_date}
          recommendation_type={recommendation_type}
          hasUserReadOnlyAccess={hasUserReadOnlyAccess}
          hasManualMode={hasManualMode}
          show_restart_button={showRestartButton}
          show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
        />
      ) : enable_fan_guidance ? null : (
        <>
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <Grid item>
              <Button
                size="medium"
                variant="contained"
                className={classes.generateAutomation}
                style={{
                  color: white,
                  backgroundColor: amber_green,
                }}
                onClick={() =>
                  setNavigation(
                    createNavigationTypeFromNavOption(
                      FanControlsNavigation.AskCurrentGrainConditions,
                      navigation
                    )
                  )
                }
                disabled={hasUserReadOnlyAccess}
              >
                <div className={classes.fanAutomationIconContainer}>
                  <AutomationIcon />
                </div>
                GENERATE AUTOMATIONS
              </Button>
            </Grid>
            <Grid item className={classes.dividerContainer}>
              <div className={classes.divider} />
              <div className={classes.dividerText}>OR</div>
              <div className={classes.verticalDivider} />
            </Grid>
            <Grid item style={{ display: 'flex' }}>
              <Button
                size="medium"
                variant="contained"
                className={classes.customBtn}
                style={{
                  color: black_shade_3,
                  backgroundColor: white,
                }}
                onClick={() =>
                  setNavigation(
                    createNavigationTypeFromNavOption(
                      FanControlsNavigation.ShowManualRunWindows,
                      navigation
                    )
                  )
                }
                disabled={hasUserReadOnlyAccess}
              >
                <EqualizerIcon style={{ marginRight: 6 }} />
                CUSTOM
              </Button>

              <div className={classes.runNowBtn}>
                <Button
                  variant="contained"
                  style={{
                    width: 45,
                    minWidth: 45,
                    color: white,
                    backgroundColor: amber_green,
                    margin: 0,
                    padding: '6px',
                    border: `1px solid ${amber_green}`,
                    textTransform: 'uppercase',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    fontSize: 16,
                    fontFamily: 'Work Sans,sans-serif',
                  }}
                  onClick={handleFanRunNow}
                  disabled={hasUserReadOnlyAccess}
                >
                  Run
                </Button>
                <Select
                  className={classes.fanRunNowPeriod}
                  value={fanRunNowPeriod}
                  onChange={handleFanRunNowPeriodChange}
                  style={{
                    width: 100,
                    fontSize: 16,
                    fontFamily: 'Work Sans,sans-serif',
                    margin: '6px',
                    color: black_shade_3,
                  }}
                  disabled={hasUserReadOnlyAccess}
                >
                  {FAN_RUN_NOW_PERIOD_OPTIONS.map((option, i) => {
                    return (
                      <MenuItem key={`${i}`} value={option.value.toString()}>
                        {option.displayName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      <ConfirmationModal
        showModal={showConfirmationModal}
        confirmationMessage={
          <>
            Are you sure you want to <strong>STOP {fan_count === 1 ? 'FAN' : 'FANS'}</strong> and{' '}
            <strong>END AUTOMATION</strong>?
          </>
        }
        cancelBtnText="No"
        confirmBtbText="Yes"
        handleCancel={() => setShowConfirmationModal(false)}
        handleConfirm={() => {
          stopFans();
          setShowFanGuidanceExtPrompt(false);
          setShowConfirmationModal(false);
        }}
      />

      <CancelScheduleDialog
        open={showCancelDialog}
        onClickClose={closeCanceltDialog}
        has_current_overlap_runwindow={has_current_overlap_runwindow}
        cancelNextRun={() => {
          cancelNextRun();
          closeCanceltDialog();
        }}
        cancelAllRuns={() => {
          cancelAllSchedule();
          closeCanceltDialog();
        }}
      />
    </div>
  );
};

export const FanSchedule = withGetUserHoc(withSetFanRunNowScheduleHoc(FanScheduleBase));
