import {
  GrainBinSiteFragmentFragment,
  GrainBinSiteLink,
  OpsViewRowVisibilityPrefsFragmentFragment,
  OpsViewVisibilityPrefsFragmentFragment,
} from '../../../../../core/src/api';
import { PrefJsonItem, PrefJsonType } from './ManageSitesTable';
import { UNASSIGNED_BINS_SITE_ID, UNASSIGNED_SITE_NAME } from './ManageSitesView';

const createNewGrainSiteOperation = async ({
  node,
  api,
  newValue,
  createGrainSite,
  binsVisibilityPrefs,
  updateOperationsViewRowVisibilityPrefs,
}: {
  node: any;
  api: any;
  newValue: string;
  createGrainSite: (site_name: string) => Promise<GrainBinSiteFragmentFragment | null>;
  binsVisibilityPrefs: any;
  updateOperationsViewRowVisibilityPrefs: (newSiteRows: PrefJsonItem[]) => Promise<void>;
}) => {
  // Create a new site
  node.groupData['ag-Grid-AutoColumn'] = newValue;
  api.refreshCells({
    force: true,
    rowNodes: [node],
  });
  // add new site api call
  const newCreatedSite = await createGrainSite(newValue);
  console.log('newCreatedSite', newCreatedSite);
  if (newCreatedSite) {
    const newSite = {
      id: `site-${newCreatedSite.site_id}`,
      site_id: newCreatedSite.site_id,
      siteHierarchy: [newCreatedSite.site_name],
    };
    node.data = newSite;
    node.id = `site-${newCreatedSite.site_id}`;
    node.key = newCreatedSite.site_name;
  } else {
    return;
  }

  api.refreshCells({
    force: true,
    rowNodes: [node],
  });

  // update operations view visibility prefs with new site
  const siteRows: PrefJsonItem[] = [];
  api.getModel().rowsToDisplay.forEach((rowNode, index) => {
    const rowData = rowNode.data;
    // only sites
    if (rowData && rowData.siteHierarchy.length === 1) {
      const childBins = rowNode.childrenAfterFilter.map((node) => {
        const prevBinVisibilityPref =
          binsVisibilityPrefs.length > 0
            ? binsVisibilityPrefs.find(({ bin_id }) => bin_id === node.data.bin_id)
            : null;
        return {
          bin_id: node.data.bin_id,
          hide: prevBinVisibilityPref ? prevBinVisibilityPref.hide : false,
        };
      });
      siteRows.push({
        site_id: rowData.site_id,
        bins: childBins,
      });
    }
  });

  // enforce UNASSIGNED_BINS site to reside on the last index always
  const newSiteRows: PrefJsonItem[] = [
    ...siteRows.filter((sitePref) => sitePref.site_id !== UNASSIGNED_BINS_SITE_ID),
    ...siteRows.filter((sitePref) => sitePref.site_id === UNASSIGNED_BINS_SITE_ID),
  ];

  console.log('siteRows after createNewGrainSiteOperation', newSiteRows);
  await updateOperationsViewRowVisibilityPrefs(newSiteRows);
};

const renameOperations = async ({
  site_id,
  bin_id,
  node,
  api,
  newValue,
  renameGrainBin,
  renameGrainSite,
  isGrainBinCell,
}: {
  site_id: number;
  bin_id: number;
  node: any;
  api: any;
  newValue: string;
  renameGrainSite: (site_id: number, site_name: string) => void;
  renameGrainBin: (grain_bin_id: number, bin_name: string) => Promise<void>;
  isGrainBinCell: boolean;
}) => {
  console.log('before node in renameOperations', node, isGrainBinCell);
  const oldValue = node.key;
  if (newValue === oldValue) {
    console.log('No need to make rename api call');
    return;
  }

  // Rename exsting site
  node.groupData['ag-Grid-AutoColumn'] = newValue;
  if (isGrainBinCell) {
    node.data.bin_name = newValue;
    node.data.siteHierarchy[1] = newValue;
  } else {
    api.getModel().rowsToDisplay.forEach((rowNode, index) => {
      if (rowNode.data.site_id === site_id) {
        rowNode.data.siteHierarchy[0] = newValue;
      }
    });
  }
  api.refreshCells({
    force: true,
  });
  if (isGrainBinCell) {
    await renameGrainBin(node.data.bin_id, newValue);
  } else {
    await renameGrainSite(site_id, newValue);
  }
};

export const onCellEditingStopped = async ({
  event,
  renameGrainSite,
  renameGrainBin,
  createGrainSite,
  setRowData,
  binsVisibilityPrefs,
  updateOperationsViewRowVisibilityPrefs,
}: {
  event: any;
  renameGrainSite: (site_id: number, site_name: string) => void;
  renameGrainBin: (grain_bin_id: number, bin_name: string) => Promise<void>;
  createGrainSite: (site_name: string) => Promise<GrainBinSiteFragmentFragment | null>;
  setRowData: any;
  binsVisibilityPrefs: any;
  updateOperationsViewRowVisibilityPrefs: (newSiteRows: PrefJsonItem[]) => Promise<void>;
}) => {
  const { newValue, node } = event;
  console.log('newValue', newValue, node);
  const site_id = node.data.site_id;
  const bin_id = node.data.bin_id;
  const isGrainBinCell = Boolean(node.data.bin_name);

  if (!newValue || newValue.trim() === '') return;

  if (site_id === null) {
    createNewGrainSiteOperation({
      node,
      newValue,
      createGrainSite,
      binsVisibilityPrefs,
      updateOperationsViewRowVisibilityPrefs,
      api: event.api,
    });
  } else {
    renameOperations({
      site_id,
      bin_id,
      node,
      newValue,
      renameGrainBin,
      renameGrainSite,
      isGrainBinCell,
      api: event.api,
    });
  }
};

export const performRowPrefChanges = ({
  rowData,
  setRowData,
  gridApi,
  operations_view_rows_visibility_prefs,
}: {
  rowData: any;
  setRowData: React.Dispatch<any>;
  gridApi: any;
  operations_view_rows_visibility_prefs: OpsViewRowVisibilityPrefsFragmentFragment;
}) => {
  const operations_view_rows_prefs_parsed: PrefJsonType | null = operations_view_rows_visibility_prefs
    ? JSON.parse(operations_view_rows_visibility_prefs.pref_json)
    : null;
  const operations_view_rows_prefs: PrefJsonItem[] | null = operations_view_rows_prefs_parsed
    ? operations_view_rows_prefs_parsed.sites
    : null;
  console.log('operations_view_rows_prefs in performRowPrefChanges', {
    operations_view_rows_prefs,
    rowData,
  });
  if (operations_view_rows_prefs) {
    const newRowDataAfterPrefs = getNewRowDataAfterPrefs({ rowData, operations_view_rows_prefs });
    console.log('newRowDataAfterRowPrefs', { newRowDataAfterPrefs, rowData });
    setRowData(newRowDataAfterPrefs);
  }
};

const getNewRowDataAfterPrefs = ({
  rowData,
  operations_view_rows_prefs,
}: {
  rowData: any;
  operations_view_rows_prefs: PrefJsonItem[];
}) => {
  const newRowData = operations_view_rows_prefs.reduce((acc: any, sitePref: PrefJsonItem) => {
    const siteRow = rowData.find((it) => it.id === `site-${sitePref.site_id}`);
    siteRow && acc.push(siteRow);
    const binsRows: any[] = [];
    for (const bin of sitePref.bins) {
      const binRow = rowData.find((it) => it.bin_id === bin.bin_id);
      binRow && binsRows.push(binRow);
    }
    acc.push(...binsRows);
    return acc;
  }, []);

  // ?Extra Check: if sites or bins which are not present in operations_view_rows_prefs
  const operations_view_rows_prefs_site_ids = operations_view_rows_prefs.map(
    (site) => site.site_id
  );
  const missingItems = rowData.filter(
    (it) => !operations_view_rows_prefs_site_ids.includes(it.site_id)
  );
  console.log('missingItems', rowData, operations_view_rows_prefs_site_ids, missingItems);
  if (missingItems.length > 0) {
    // add missing items on second last position
    newRowData.length === 0
      ? newRowData.push(...missingItems)
      : newRowData.splice(newRowData.length - 1, 0, ...missingItems);
  }

  return newRowData;
};

export const performColsAlterPrefChanges = (
  params: any,
  operations_view_cols_visibility_prefs: OpsViewVisibilityPrefsFragmentFragment | null,
  setCurrentColumnsOrder: React.Dispatch<
    React.SetStateAction<
      | {
          colId: string;
        }[]
      | null
    >
  >
) => {
  const columnState = params.columnApi.getColumnState();
  console.log('columnState', columnState);
  const columnsAlterPref = operations_view_cols_visibility_prefs
    ? JSON.parse(operations_view_cols_visibility_prefs.pref_json).columns
    : null;
  console.log('columnsAlterPref', { columnState, columnsAlterPref });

  if (columnsAlterPref) {
    const columnStateBasedOnPref = columnsAlterPref.reduce((acc, pref) => {
      const { colId: col_id } = pref;
      const colCurrentState = columnState.filter(({ colId }) => String(colId) === col_id);
      const colNewState = colCurrentState.map((state) => ({
        ...state,
      }));
      acc.push(...colNewState);
      return acc;
    }, []);

    const onlyColIds = columnStateBasedOnPref.map(({ colId }) => ({ colId }));
    console.log('columnStateBasedOnPref', columnStateBasedOnPref, onlyColIds);
    // Order columns, but do nothing else
    params.columnApi.applyColumnState({
      state: onlyColIds,
      applyOrder: true,
    });
    setCurrentColumnsOrder(onlyColIds);
  }
};

export const handleMoveGrainBinToDiffGroup = async (
  movingNode: any,
  overNode: any,
  gridRef: any,
  moveGrainBinIntoSite: (grain_bin_id: number, site_id: number) => Promise<GrainBinSiteLink | null>
): Promise<GrainBinSiteLink | null> => {
  // find out what site we are hovering over
  let destinationSite;
  if (overNode.hasChildren()) {
    destinationSite = overNode.key;
  } else {
    destinationSite = overNode.data.siteHierarchy[0];
  }

  const needToChangeParent = movingNode.data.siteHierarchy[0] !== destinationSite;
  if (needToChangeParent) {
    const destinationNodeData = overNode.data;
    const movingNodeData = movingNode.data;
    movingNodeData.siteHierarchy[0] = destinationSite;
    movingNodeData.site_id = destinationNodeData.site_id;
    gridRef.current &&
      gridRef.current.api.applyTransaction({
        update: [movingNodeData],
      });
    gridRef.current && gridRef.current.api.clearFocusedCell();
    console.log('moving nodes', { movingNodeData, destinationNodeData });
    console.log('moving nodes data', movingNodeData.bin_id, destinationNodeData.site_id);
    gridRef.current.api.refreshCells({
      force: true,
    });
    const result = await moveGrainBinIntoSite(movingNodeData.bin_id, destinationNodeData.site_id);
    return result;
  }
  return null;
};

export const getSitesWithUpdatedVisibility = (
  gridapi: any,
  binsVisibilityPrefs: any,
  binId: number,
  isHide: boolean
): PrefJsonItem[] => {
  console.log('inside updateRowVisibility', { binId, isHide });
  const siteRows: PrefJsonItem[] = [];
  gridapi.getModel().rowsToDisplay.forEach((rowNode, index) => {
    const rowData = rowNode.data;
    // only sites
    if (rowData && rowData.siteHierarchy.length === 1) {
      const childBins: {
        bin_id: number;
        hide: boolean;
      }[] = rowNode.childrenAfterFilter.map((node) => {
        const prevBinVisibilityPref =
          binsVisibilityPrefs.length > 0
            ? binsVisibilityPrefs.find(({ bin_id }) => bin_id === node.data.bin_id)
            : null;
        return {
          bin_id: node.data.bin_id,
          hide:
            node.data.bin_id === binId
              ? isHide
              : prevBinVisibilityPref
              ? prevBinVisibilityPref.hide
              : false,
        };
      });
      siteRows.push({
        site_id: rowData.site_id,
        bins: childBins,
      });
    }
  });
  console.log('siteRows in updateRowVisibility', binsVisibilityPrefs, siteRows);

  return siteRows;
};

export const getSitesWithUpdatedVisibilityInBulk = (
  gridapi: any,
  binsVisibilityPrefs: any,
  binIds: number[],
  isHide: boolean
): PrefJsonItem[] => {
  console.log('inside getSitesWithUpdatedVisibilityInBulk', { binIds, isHide });

  const siteRows: PrefJsonItem[] = [];
  gridapi.getModel().rowsToDisplay.forEach((rowNode, index) => {
    const rowData = rowNode.data;
    // only sites
    if (rowData && rowData.siteHierarchy.length === 1) {
      const childBins = rowNode.childrenAfterFilter.map((node) => {
        const prevBinVisibilityPref =
          binsVisibilityPrefs.length > 0
            ? binsVisibilityPrefs.find(({ bin_id }) => bin_id === node.data.bin_id)
            : null;
        return {
          bin_id: node.data.bin_id,
          hide: binIds.includes(node.data.bin_id)
            ? isHide
            : prevBinVisibilityPref
            ? prevBinVisibilityPref.hide
            : false,
        };
      });
      siteRows.push({
        site_id: rowData.site_id,
        bins: childBins,
      });
    }
  });
  console.log('siteRows in getSitesWithUpdatedVisibilityInBulk', binsVisibilityPrefs, siteRows);

  return siteRows;
};
