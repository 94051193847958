import { DateTime } from 'luxon';
import { amber_blue } from '../../../style';
import { RecommendedOption } from '../RecommendationOptionValue';
import { ExperimentRecommendedScheduledRange } from './RecommendationScheduleBar';

const SCHEDULED_RUNS_COLOR = 'rgb(76, 175, 80)';
const DRYING_COLOR = 'rgb(225, 114, 81)';
const RECONDITIONING_COLOR = 'rgb(192, 93, 174)';
const COOLING_COLOR = amber_blue;

export const SCHEDULED_RUNS_COLOR_GRADIENT_OBJ = {
  linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
  stops: [
    [0, '#9aff9e'],
    [0.2, '#7fe892'],
    [0.4, '#64d087'],
    [0.6, '#4aba7a'],
    [0.8, '#2fa36e'],
    [1, '#2fa36e'],
  ],
};

export const DRYING_COLOR_GRADIENT_OBJ = {
  linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
  stops: [
    [0, '#e99497'],
    [0.2, '#fba189'],
    [0.4, '#ffb479'],
    [0.6, '#fbcb6e'],
    [0.8, '#e8e46e'],
    [1, '#e8e46e'],
  ],
};
export const RECONDITIONING_COLOR_GRADIENT_OBJ = {
  linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
  stops: [
    [0, '#9adcff'],
    [0.2, '#8dd0ff'],
    [0.4, '#97c0ff'],
    [0.6, '#b3abff'],
    [0.8, '#d791ff'],
    [1, '#d791ff'],
  ],
};
export const COOLING_COLOR_GRADIENT_OBJ = {
  linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
  stops: [
    [0, '#94b3fd'],
    [0.2, '#7ec9ff'],
    [0.4, '#75ddff'],
    [0.6, '#7feeff'],
    [0.8, '#99feff'],
    [1, '#99feff'],
  ],
};

export const MANUAL_COLOR = '#FBC02D';
export const CUSTOM_COLOR = '#B3B3B3';

const getRecommendedWindowsColor = (recommendationType: string) => {
  return {
    [RecommendedOption.COOLING]: COOLING_COLOR_GRADIENT_OBJ,
    [RecommendedOption.DRYING]: DRYING_COLOR_GRADIENT_OBJ,
    [RecommendedOption.RECONDITIONING]: RECONDITIONING_COLOR_GRADIENT_OBJ,
  }[recommendationType];
};

const getAllCategories = (hasEnableFanGuidance, include_experiment_periods): string[] => {
  let all_categories: string[];
  if (!include_experiment_periods) {
    all_categories = hasEnableFanGuidance ? ['Scheduled Automations'] : ['Scheduled Runs'];
    all_categories = [
      ...all_categories,
      'Cooling Effect Recommendations',
      'Drying Effect Recommendations',
      'Reconditioning Effect Recommendations',
    ];
  } else {
    all_categories = [
      'Default Drying Effect Recommendations',
      'Cfm Drying Effect Recommendations',
      'Default Reconditioning Effect Recommendations',
      'Cfm Reconditioning Effect Recommendations',
      'Default Cooling Effect Recommendations',
    ];
  }
  return all_categories;
};

const getCategories = (
  recommendationType: string,
  hasEnableFanGuidance: boolean,
  include_experiment_periods: boolean = false,
  experimentPeriodsToPresent: ExperimentRecommendedScheduledRange | undefined
) => {
  const allCategories = getAllCategories(hasEnableFanGuidance, include_experiment_periods);
  if (include_experiment_periods && experimentPeriodsToPresent) {
    const { experiment } = experimentPeriodsToPresent;
    const isDefaultExperiment = experiment.includes('default');
    if (isDefaultExperiment) {
      return {
        [RecommendedOption.DRYING]: [allCategories[0]],
        [RecommendedOption.RECONDITIONING]: [allCategories[2]],
        [RecommendedOption.COOLING]: [allCategories[4]],
      }[recommendationType];
    }
    return {
      [RecommendedOption.DRYING]: [allCategories[1]],
      [RecommendedOption.RECONDITIONING]: [allCategories[3]],
    }[recommendationType];
  }
  return {
    [RecommendedOption.COOLING]: [allCategories[0], allCategories[1]],
    [RecommendedOption.DRYING]: [allCategories[0], allCategories[2]],
    [RecommendedOption.RECONDITIONING]: [allCategories[0], allCategories[3]],
  }[recommendationType];
};

const getFormattedSeriesDataV2 = ({
  recommendation_type,
  scheduledRunsToPresent,
  coolingPeriodsToPresent,
  dryingPeriodsToPresent,
  reconditioningPeriodsToPresent,
  experimentPeriodsToPresent,
  hasEnableFanGuidance,
}) => {
  const run_window_data_builder = new RunWindowsDataBuilder(recommendation_type);
  return experimentPeriodsToPresent
    ? run_window_data_builder.addExperimentRunWindows(experimentPeriodsToPresent).build()
    : run_window_data_builder
        .addNonExperimentRunWindows(
          hasEnableFanGuidance,
          scheduledRunsToPresent,
          coolingPeriodsToPresent,
          dryingPeriodsToPresent,
          reconditioningPeriodsToPresent
        )
        .build();
};

const getFormattedSeriesData = ({
  recommendation_type,
  scheduledRunsToPresent,
  coolingPeriodsToPresent,
  dryingPeriodsToPresent,
  reconditioningPeriodsToPresent,
  experimentPeriodsToPresent,
}) => {
  let formattedScheduledRuns = scheduledRunsToPresent
    .filter((item) => item.text)
    .map(({ startEpoch, endEpoch, text }) => {
      return {
        x: startEpoch,
        x2: endEpoch,
        y: 0,
        label: text,
        color: SCHEDULED_RUNS_COLOR,
      };
    });
  formattedScheduledRuns =
    formattedScheduledRuns.length === 0 ? [{ y: 0 }] : formattedScheduledRuns;

  switch (recommendation_type) {
    case RecommendedOption.COOLING:
      let formattedCoolingPeriods = coolingPeriodsToPresent
        .filter((item) => item.text)
        .map(({ startEpoch, endEpoch, text }) => {
          return {
            x: startEpoch,
            x2: endEpoch,
            y: 1,
            label: text,
            color: COOLING_COLOR_GRADIENT_OBJ,
          };
        });
      formattedCoolingPeriods =
        formattedCoolingPeriods.length === 0 ? [{ y: 1 }] : formattedCoolingPeriods;

      return [...formattedScheduledRuns, ...formattedCoolingPeriods];
    case RecommendedOption.DRYING:
      let formattedDryingPeriods = dryingPeriodsToPresent
        .filter((item) => item.text)
        .map(({ startEpoch, endEpoch, text }) => {
          return {
            x: startEpoch,
            x2: endEpoch,
            y: 1,
            label: text,
            color: DRYING_COLOR_GRADIENT_OBJ,
          };
        });

      let drying_experiment_periods: any[] = [];
      experimentPeriodsToPresent &&
        experimentPeriodsToPresent
          .filter((experiment) => experiment.type === recommendation_type.toUpperCase())
          .forEach((experiment, i) => {
            const y = i + 2;
            const { recommended_schedule_range } = experiment;
            const result: [] = recommended_schedule_range
              .filter((item) => item.text)
              .map((schedule) => {
                const { startEpoch, endEpoch, text } = schedule;
                return {
                  y,
                  x: startEpoch,
                  x2: endEpoch,
                  label: text,
                  color: DRYING_COLOR_GRADIENT_OBJ,
                };
              });
            drying_experiment_periods.push(...result);
          });
      formattedDryingPeriods =
        formattedDryingPeriods.length === 0 ? [{ y: 1 }] : formattedDryingPeriods;
      if (experimentPeriodsToPresent != null) {
        drying_experiment_periods =
          drying_experiment_periods.length === 0 ? [{ y: 2 }, { y: 3 }] : drying_experiment_periods;
        return [...formattedScheduledRuns, ...formattedDryingPeriods, ...drying_experiment_periods];
      }
      return [...formattedScheduledRuns, ...formattedDryingPeriods];
    case RecommendedOption.RECONDITIONING:
      let formattedReconditioningPeriods = reconditioningPeriodsToPresent
        .filter((item) => item.text)
        .map(({ startEpoch, endEpoch, text }) => {
          return {
            x: startEpoch,
            x2: endEpoch,
            y: 1,
            label: text,
            color: RECONDITIONING_COLOR_GRADIENT_OBJ,
          };
        });
      let reconditioning_experiment_periods: any[] = [];
      experimentPeriodsToPresent &&
        experimentPeriodsToPresent
          .filter((experiment) => experiment.type === recommendation_type.toUpperCase())
          .forEach((experiment, i) => {
            const y = i + 2;
            const { recommended_schedule_range } = experiment;
            const result: [] = recommended_schedule_range
              .filter((item) => item.text)
              .map((schedule) => {
                const { startEpoch, endEpoch, text } = schedule;
                return {
                  y,
                  x: startEpoch,
                  x2: endEpoch,
                  label: text,
                  color: DRYING_COLOR_GRADIENT_OBJ,
                };
              });
            reconditioning_experiment_periods.push(...result);
          });
      formattedReconditioningPeriods =
        formattedReconditioningPeriods.length === 0 ? [{ y: 1 }] : formattedReconditioningPeriods;
      if (experimentPeriodsToPresent != null) {
        reconditioning_experiment_periods =
          reconditioning_experiment_periods.length === 0
            ? [{ y: 2 }, { y: 3 }]
            : reconditioning_experiment_periods;
        return [
          ...formattedScheduledRuns,
          ...formattedReconditioningPeriods,
          ...reconditioning_experiment_periods,
        ];
      }
      return [...formattedScheduledRuns, ...formattedReconditioningPeriods];
    default:
      return [...formattedScheduledRuns, ...[{ y: 0 }, { y: 1 }, { y: 2 }, { y: 3 }]];
  }
};

export {
  getCategories,
  getFormattedSeriesData,
  getFormattedSeriesDataV2,
  getAllCategories,
  DRYING_COLOR,
  COOLING_COLOR,
  RECONDITIONING_COLOR,
  RunWindowsDataBuilder,
};

class RunWindowsDataBuilder {
  private allFormmattedRunWindows: any[] = [];
  private y_index = -1;
  private recommendation_option: RecommendedOption;
  private static default_drying_experiment = 'default_drying_experiment';
  private static cfm_drying_experiment = 'cfm_drying_experiment';
  private static default_reconditioning_experiment = 'default_reconditioning_experiment';
  private static cfm_reconditioning_experiment = 'cfm_reconditioning_experiment';
  private static default_cooling_experiment = 'default_cooling_experiment';

  constructor(recommendation_option: RecommendedOption) {
    this.recommendation_option = recommendation_option;
  }

  addNonExperimentRunWindows(
    hasEnableFanGuidance,
    scheduledRunsToPresent,
    coolingPeriodsToPresent?,
    dryingPeriodsToPresent?,
    reconditioningPeriodsToPresent?
  ): RunWindowsDataBuilder {
    this.addScheduleRuns(scheduledRunsToPresent, hasEnableFanGuidance);
    switch (this.recommendation_option) {
      case RecommendedOption.DRYING:
        this.addDryingRunWindows(dryingPeriodsToPresent);
        break;

      case RecommendedOption.COOLING:
        this.addCoolingRunWindows(coolingPeriodsToPresent);
        break;

      case RecommendedOption.RECONDITIONING:
        this.addReconditioningRunWindows(reconditioningPeriodsToPresent);
        break;

      default:
        this.addCoolingRunWindows([])
          .addDryingRunWindows([])
          .addReconditioningRunWindows([]);
    }
    return this;
  }
  addScheduleRuns(scheduledRunsToPresent, hasEnableFanGuidance): RunWindowsDataBuilder {
    this.incrementYIndex();
    let formattedScheduledRuns = scheduledRunsToPresent
      .filter((item) => item.text)
      .map(({ startEpoch, endEpoch, text }) => {
        return {
          x: startEpoch,
          x2: endEpoch,
          y: this.y_index,
          label: text,
          color: hasEnableFanGuidance ? SCHEDULED_RUNS_COLOR_GRADIENT_OBJ : SCHEDULED_RUNS_COLOR,
        };
      });
    formattedScheduledRuns =
      formattedScheduledRuns.length === 0 ? [{ y: this.y_index }] : formattedScheduledRuns;
    this.allFormmattedRunWindows = [...this.allFormmattedRunWindows, ...formattedScheduledRuns];
    return this;
  }
  addCoolingRunWindows(coolingPeriodsToPresent): RunWindowsDataBuilder {
    this.incrementYIndex();

    let formattedCoolingPeriods = coolingPeriodsToPresent
      .filter((item) => item.text)
      .map(({ startEpoch, endEpoch, text }) => {
        return {
          x: startEpoch,
          x2: endEpoch,
          y: this.y_index,
          label: text,
          color: COOLING_COLOR_GRADIENT_OBJ,
        };
      });
    formattedCoolingPeriods =
      formattedCoolingPeriods.length === 0 ? [{ y: this.y_index }] : formattedCoolingPeriods;
    this.allFormmattedRunWindows = [...this.allFormmattedRunWindows, ...formattedCoolingPeriods];
    return this;
  }
  addDryingRunWindows(dryingPeriodsToPresent): RunWindowsDataBuilder {
    this.incrementYIndex();
    let formattedDryingPeriods = dryingPeriodsToPresent
      .filter((item) => item.text)
      .map(({ startEpoch, endEpoch, text }) => {
        return {
          x: startEpoch,
          x2: endEpoch,
          y: this.y_index,
          label: text,
          color: DRYING_COLOR_GRADIENT_OBJ,
        };
      });
    formattedDryingPeriods =
      formattedDryingPeriods.length === 0 ? [{ y: this.y_index }] : formattedDryingPeriods;
    this.allFormmattedRunWindows = [...this.allFormmattedRunWindows, ...formattedDryingPeriods];
    return this;
  }
  addReconditioningRunWindows(reconditioningPeriodsToPresent): RunWindowsDataBuilder {
    this.incrementYIndex();
    let formattedReconditioningPeriods = reconditioningPeriodsToPresent
      .filter((item) => item.text)
      .map(({ startEpoch, endEpoch, text }) => {
        return {
          x: startEpoch,
          x2: endEpoch,
          y: this.y_index,
          label: text,
          color: RECONDITIONING_COLOR_GRADIENT_OBJ,
        };
      });
    formattedReconditioningPeriods =
      formattedReconditioningPeriods.length === 0
        ? [{ y: this.y_index }]
        : formattedReconditioningPeriods;
    this.allFormmattedRunWindows = [
      ...this.allFormmattedRunWindows,
      ...formattedReconditioningPeriods,
    ];
    return this;
  }

  addExperimentRunWindows(experimentPeriodsToPresent): RunWindowsDataBuilder {
    const { experiment: experiment_name } = experimentPeriodsToPresent;
    if (experiment_name === RunWindowsDataBuilder.default_drying_experiment) {
      this.addExperimentDryingDefault(experimentPeriodsToPresent);
    }
    if (experiment_name === RunWindowsDataBuilder.cfm_drying_experiment) {
      this.addExperimentDryingCfm(experimentPeriodsToPresent);
    }
    if (experiment_name === RunWindowsDataBuilder.default_reconditioning_experiment) {
      this.addExperimentReconditioningDefault(experimentPeriodsToPresent);
    }
    if (experiment_name === RunWindowsDataBuilder.cfm_reconditioning_experiment) {
      this.addExperimentReconditioningCfm(experimentPeriodsToPresent);
    }
    if (experiment_name === RunWindowsDataBuilder.default_cooling_experiment) {
      this.addExperimentCoolingDefault(experimentPeriodsToPresent);
    }
    return this;
  }
  addExperimentDryingDefault(experimentPeriodsToPresent): RunWindowsDataBuilder {
    this.addExperimentByRecommendationAndAlgType(
      RecommendedOption.DRYING,
      RunWindowsDataBuilder.default_drying_experiment,
      experimentPeriodsToPresent
    );
    return this;
  }
  addExperimentDryingCfm(experimentPeriodsToPresent): RunWindowsDataBuilder {
    this.addExperimentByRecommendationAndAlgType(
      RecommendedOption.DRYING,
      RunWindowsDataBuilder.cfm_drying_experiment,
      experimentPeriodsToPresent
    );
    return this;
  }
  addExperimentReconditioningDefault(experimentPeriodsToPresent): RunWindowsDataBuilder {
    this.addExperimentByRecommendationAndAlgType(
      RecommendedOption.RECONDITIONING,
      RunWindowsDataBuilder.default_reconditioning_experiment,
      experimentPeriodsToPresent
    );
    return this;
  }
  addExperimentReconditioningCfm(experimentPeriodsToPresent): RunWindowsDataBuilder {
    this.addExperimentByRecommendationAndAlgType(
      RecommendedOption.RECONDITIONING,
      RunWindowsDataBuilder.cfm_reconditioning_experiment,
      experimentPeriodsToPresent
    );
    return this;
  }

  addExperimentCoolingDefault(experimentPeriodsToPresent): RunWindowsDataBuilder {
    this.addExperimentByRecommendationAndAlgType(
      RecommendedOption.COOLING,
      RunWindowsDataBuilder.default_cooling_experiment,
      experimentPeriodsToPresent
    );
    return this;
  }

  private addExperimentByRecommendationAndAlgType(
    recommendation_type: string,
    algorithm_type: string,
    experimentPeriodsToPresent: ExperimentRecommendedScheduledRange
  ): RunWindowsDataBuilder {
    this.incrementYIndex();

    let experimentPeriods: any[] = [];

    if (
      experimentPeriodsToPresent &&
      experimentPeriodsToPresent.type === recommendation_type &&
      experimentPeriodsToPresent.experiment === algorithm_type
    ) {
      const { recommended_schedule_range } = experimentPeriodsToPresent;
      const result = recommended_schedule_range
        .filter((item) => item.text)
        .map((schedule) => {
          const { startEpoch, endEpoch, text } = schedule;
          return {
            y: this.y_index,
            x: startEpoch,
            x2: endEpoch,
            label: text,
            color: getRecommendedWindowsColor(recommendation_type),
          };
        });
      experimentPeriods.push(...result);
    }

    experimentPeriods = experimentPeriods.length === 0 ? [{ y: this.y_index }] : experimentPeriods;
    this.allFormmattedRunWindows = [...this.allFormmattedRunWindows, ...experimentPeriods];
    return this;
  }

  build() {
    return this.allFormmattedRunWindows;
  }

  private incrementYIndex() {
    if (this.y_index < 0) {
      this.y_index = 0;
    } else {
      this.y_index = this.y_index + 1;
    }
  }
}

export const getRemainingAutomationPeriodString = (as_of, fanGuidanceEndDate) => {
  let remainingAutomationPeriodString = '';
  const remainingDays = fanGuidanceEndDate.diff(as_of, 'days').toObject().days;
  if (remainingDays >= 1) {
    remainingAutomationPeriodString = `${Math.round(remainingDays)} Days Remaining`;
  } else {
    const remainingHrs = fanGuidanceEndDate.diff(as_of, 'hours').toObject().hours;
    remainingAutomationPeriodString =
      remainingHrs >= 1
        ? Math.round(remainingHrs) === 24
          ? '1 Day Remaining'
          : `${Math.round(remainingHrs)} Hours Automation Remaining`
        : remainingHrs > 0
        ? '<1 Hour Remaining'
        : '0 Hours Remaining';
  }

  return remainingAutomationPeriodString;
};

export const getRemainingDaysAutomationPeriodString = (as_of, fanGuidanceEndDate: DateTime) => {
  let remainingAutomationPeriodString = '';
  const remainingDays = fanGuidanceEndDate.diff(as_of, 'days').toObject().days;
  if (remainingDays >= 1) {
    remainingAutomationPeriodString = `${Math.round(remainingDays)} Days Automation Remaining`;
  } else {
    const remainingHrs = fanGuidanceEndDate.diff(as_of, 'hours').toObject().hours;
    remainingAutomationPeriodString =
      remainingHrs >= 1
        ? Math.round(remainingHrs) === 24
          ? '1 Day Automation Remaining'
          : `${Math.round(remainingHrs)} Hours Automation Remaining`
        : remainingHrs > 0
        ? '<1 Hour Automation Remaining'
        : '0 Hours Automation Remaining';
  }

  return remainingAutomationPeriodString;
};

export const getRemainingDaysAutomationString = (as_of, fanGuidanceEndDate: DateTime) => {
  let remainingAutomationPeriodString = '';
  const remainingDays = fanGuidanceEndDate.diff(as_of, 'days').toObject().days;
  if (remainingDays >= 1) {
    remainingAutomationPeriodString = `${Math.round(remainingDays)} Days`;
  } else {
    const remainingHrs = fanGuidanceEndDate.diff(as_of, 'hours').toObject().hours;
    remainingAutomationPeriodString =
      remainingHrs >= 1
        ? Math.round(remainingHrs) === 24
          ? '1 Day'
          : `${Math.round(remainingHrs)} Hours`
        : remainingHrs > 0
        ? '<1 Hour'
        : '0 Hour';
  }

  return remainingAutomationPeriodString;
};
