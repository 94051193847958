import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy } from '../../../util';
import { GetPileContainerQuery, withGetPileContainer as HOC } from '../__generated';

export type WithGetPileContainerHocOwnProps = {
  pile_id: number;
  loading?: boolean;
  pollInterval?: number;
  onError?: (error: ApolloError) => void;
};
export type WithGetPileContainerHocChildProps = {
  loading: boolean;
  grain_container: GetPileContainerQuery['grain_container'] | null;
};

export const withGetPileContainerHoc: AmberHoc<
  WithGetPileContainerHocOwnProps,
  WithGetPileContainerHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetPileContainerHocOwnProps,
      WithGetPileContainerHocChildProps,
      typeof component
    >,
    WithGetPileContainerHocChildProps
  >({
    options: ({ pile_id, onError, pollInterval = 1000 * 60 * 30 }) => ({
      pollInterval,
      onError,
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id: pile_id, container_type: ContainerTypeLegacy.pile },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          grain_container: null,
        };
      }
      return {
        loading: loading || false,
        grain_container: data.grain_container || null,
      };
    },
  })(component as any);
