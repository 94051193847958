import { ActionType, getType } from 'typesafe-actions';
import {
  changeLocation,
  endSession,
  pushNav,
  readySession,
  setAccountId,
  setFanCardView,
} from '../action';
import {
  Account,
  FanControllerDiagnosticsFragmentFragment,
  HubDiagnosticsFragmentFragment,
} from '../api';
import { ContainerTypeLegacy } from '../util';

const actions = { pushNav, endSession, readySession, changeLocation, setAccountId, setFanCardView };

export const default_selected_state: SelectedState = {
  account_id: null,
  user_id: null,
  container_id: null,
  container_type: null,
  container_name: '',
  pellet_id: '',
  cable_id: '',
  fan_controller: null,
  hub: null,
};

export type SelectedState = {
  user_id: number | null;
  account_id: number | null;
  container_id: number | null;
  container_type: ContainerTypeLegacy | null;
  container_name: string;
  pellet_id: string;
  cable_id: number | string;
  fan_controller: FanControllerDiagnosticsFragmentFragment | null;
  hub: HubDiagnosticsFragmentFragment | null;
};
export const selectedReducer = (
  state: SelectedState = default_selected_state,
  action: ActionType<typeof actions>
): SelectedState => {
  switch (action.type) {
    case getType(pushNav): {
      const {
        payload: { params },
      } = action;
      if (!params || !params.selected) {
        return state;
      }
      const next = {
        ...state,
        ...params.selected,
      };
      return next;
    }
    case getType(endSession): {
      return default_selected_state;
    }
    case getType(readySession): {
      const {
        payload: { selected },
      } = action;
      if (!selected) {
        return state;
      }
      return selected;
    }
    case getType(changeLocation): {
      const {
        payload: {
          location: { state: router_state },
        },
      } = <any>action;
      if (!router_state || !router_state.selected) {
        return state;
      }
      const result = { ...state, ...router_state.selected };
      return result;
    }
    case getType(setAccountId): {
      return {
        ...state,
        account_id: action.payload.account.account_id,
        container_id: null,
        container_name: '',
        container_type: null,
      };
    }
    default:
      return state;
  }
};
