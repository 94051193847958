import { Card, CardHeader, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { MediumRedAlertIcon, MediumWarnAlertIcon } from '../../../../../core/src/media';
import {
  brown_shade_1,
  brown_shade_2,
  red_shade_4,
  white,
  yellow_shade_4,
} from '../../../../../core/src/style';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    width: 20,
    height: 20,
    marginRight: 10,
  },
  text: { color: white, fontSize: 16 },
});

type BannerType = 'warning' | 'success' | 'error';

export const Banner = ({
  type,
  text,
  styles = {},
  textStyles = {},
}: {
  type: BannerType;
  text: string | React.ReactElement;
  styles?: object;
  textStyles?: object;
}) => {
  const classes = useStyles();

  const getBannerTextColor = (type: BannerType): string => {
    switch (type) {
      case 'warning':
        return brown_shade_1;
      case 'error':
        return brown_shade_2;
      default:
        return white;
    }
  };

  const getBannerBgColor = (type: BannerType): string => {
    switch (type) {
      case 'warning':
        return yellow_shade_4;
      case 'error':
        return red_shade_4;
      default:
        return white;
    }
  };
  const getBannerIcon = (type: BannerType): JSX.Element | null => {
    switch (type) {
      case 'warning':
        return <MediumWarnAlertIcon />;
      case 'error':
        return <MediumRedAlertIcon />;
      default:
        return null;
    }
  };

  return (
    <Card raised style={{ backgroundColor: getBannerBgColor(type), ...styles }}>
      <CardHeader
        title={
          <div className={classes.title}>
            <div className={classes.icon}>{getBannerIcon(type)}</div>
            <Typography
              variant="h6"
              className={classes.text}
              style={{ color: getBannerTextColor(type), ...textStyles }}
            >
              {text}
            </Typography>
          </div>
        }
      />
    </Card>
  );
};
