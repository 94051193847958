import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@material-ui/core';
import { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import { useField, useFormikContext } from 'formik';
import React, { useCallback } from 'react';

export type SelectProps = {
  name: string;
  fullWidth?: boolean;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  labelStyles?: object;
  selectStyles?: object;
  size?: 'small' | 'medium' | undefined;
  disableUnderline?: boolean;
  onChange?: (evt: any) => void;
  showError?: boolean;
} & Pick<
  MuiSelectProps,
  | 'required'
  | 'children'
  | 'native'
  | 'variant'
  | 'autoWidth'
  | 'classes'
  | 'className'
  | 'style'
  | 'renderValue'
  | 'displayEmpty'
>;

export const Select = ({
  name,
  fullWidth,
  label,
  placeholder,
  required,
  disabled,
  children,
  className,
  onChange,
  style,
  labelStyles,
  selectStyles,
  variant = 'standard',
  size = 'medium',
  disableUnderline = false,
  showError = true,
  ...props
}: SelectProps) => {
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();
  const [field, { value, error, touched, initialValue }] = useField({ name });
  const handleChange = useCallback(
    (event) => {
      event.stopPropagation();
      if (onChange) {
        onChange(event.target.value || (initialValue ? null : initialValue));
      }

      setFieldValue(name, event.target.value || (initialValue ? null : initialValue));
    },
    [name, initialValue]
  );
  const handleBlur = useCallback(
    (event) => {
      event.stopPropagation();
      setFieldValue(name, event.target.value || (initialValue ? null : initialValue));
      setFieldTouched(name, true);
    },
    [name, initialValue]
  );
  const has_error = Boolean(touched && error);
  return (
    <FormControl
      fullWidth={fullWidth}
      required={required}
      error={has_error}
      disabled={disabled}
      className={className}
      style={style}
      variant={variant}
      size={size}
    >
      {label && (
        <InputLabel style={labelStyles} shrink htmlFor={name}>
          {label}
        </InputLabel>
      )}

      <MuiSelect
        name={name}
        displayEmpty={Boolean(placeholder)}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value || ''}
        required={required}
        style={selectStyles}
        inputProps={{
          name,
          id: `Select-${name}`,
        }}
        disableUnderline={disableUnderline}
        {...props}
      >
        {placeholder !== undefined ? (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        ) : null}
        {children}
      </MuiSelect>
      {has_error && showError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
