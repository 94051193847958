import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { black_shade_2 } from '../../../../../core/src/style';
import {
  formatBushel,
  formatNumber,
  getHasUserSelectedKilogramUnit,
  UnitsConverter,
} from '../../../../../core/src/util';
import formatTon from '../../../../../core/src/util/format-ton';
import { withSkeleton } from './withCellSkeleton';

const GrainVolumeCellRenderer = withSkeleton((props) => {
  const grain_level = props.value;
  console.log('grain_level.grain_level_percent', grain_level.grain_level_percent);
  const progressBarValue =
    grain_level.grain_level_percent >= 100
      ? 100
      : grain_level.grain_level_percent < 0
      ? 0
      : grain_level.grain_level_percent;
  const hasUserSelectedKilogramUnit = getHasUserSelectedKilogramUnit();
  const grainLevelValue = hasUserSelectedKilogramUnit
    ? formatTon(UnitsConverter.convertBuToMetricTon(grain_level.grain_level_bushels))
    : formatBushel(grain_level.grain_level_bushels);
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
        color: 'black',
        fontWeight: 600,
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>{grainLevelValue}</div>
        <div style={{ fontSize: 10, color: black_shade_2 }}>
          {formatNumber(grain_level.grain_level_percent)}%
        </div>
      </div>

      <div style={{ marginTop: 6, width: '100%' }}>
        <LinearProgress variant="determinate" value={Number(formatNumber(progressBarValue))} />
      </div>
    </div>
  );
});

export { GrainVolumeCellRenderer };
