import * as React from 'react';
export const MediumErrorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.089}
    height={22.089}
    viewBox="0 0 22.089 22.089"
    {...props}
  >
    <path
      id="ErrorIcon"
      d="M81.042,0a11.03,11.03,0,1,1-7.814,3.23A11.018,11.018,0,0,1,81.042,0Zm0,13.81A1.491,1.491,0,0,1,82.536,15.3a1.508,1.508,0,0,1-1.494,1.514A1.525,1.525,0,0,1,79.528,15.3,1.508,1.508,0,0,1,81.042,13.81Zm0-1.514a1.054,1.054,0,0,1-1.01-1.01l-.5-4.5A1.539,1.539,0,0,1,81.042,5.27a1.507,1.507,0,0,1,1.494,1.514l-.485,4.5A1.054,1.054,0,0,1,81.042,12.3Zm6.38-7.652a9.042,9.042,0,1,0,2.645,6.4,9.09,9.09,0,0,0-2.645-6.4Z"
      transform="translate(-69.998)"
      fill="#f44336"
    />
  </svg>
);
