import { Button, ButtonGroup, Grid } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';

import { BargeCoverPelletLinkFragmentFragment, GetBargeContainerQuery } from '../../../api';
import { amber_amber, barge_cover_color_set, white } from '../../../style';
import {
  isValidCoverNumber,
  normalizeCoverNumberShort,
  RelativeTimePeriod,
  Statistic,
} from '../../../util';
import { PelletsTelemetryHistoryPlot } from '../bin-summary-card/TelemetryHistoryPlot';
import { TierButton } from '../bin-summary-card/TierButton';
import { SummaryCard } from './SummaryCard';

const useStyles = makeStyles({
  content_container: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    height: '100%',
  },
  plot_wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  period_radio_wrapper: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tier_button_group: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    height: '100%',
    flexDirection: 'row',
    maxHeight: 375,
    overflowY: 'auto',
  },
  selectedButton: {
    backgroundColor: amber_amber,
    color: white,
    '&:hover': {
      backgroundColor: fade(amber_amber, 0.8),
    },
  },
});

const getPelletIdCoverMap = (barge_cover_pellet_links: BargeCoverPelletLinkFragmentFragment[]) =>
  barge_cover_pellet_links
    .filter((link) => isValidCoverNumber(link.cover_no))
    .reduce((acc, { pellet_id, cover_no }, ix) => {
      acc[pellet_id] = { cover_no, color: barge_cover_color_set[ix] };
      return acc;
    }, {});

export const BargeSummaryCard: FunctionComponent<{
  title: string;
  grain_container: GetBargeContainerQuery['grain_container'];
  barge_cover_pellet_links: BargeCoverPelletLinkFragmentFragment[];
}> = ({ title, barge_cover_pellet_links, grain_container }) => {
  const classes = useStyles();
  const [period, setPeriod] = useState(RelativeTimePeriod.week);
  const [data_source, setDataSource] = useState(
    grain_container.telemetry.pellets
      .filter(({ pellet_id }) => {
        const found = barge_cover_pellet_links.find((link) => link.pellet_id === pellet_id);
        return found && isValidCoverNumber(found.cover_no) && found.cover_no % 10 < 2;
      })
      .map(({ pellet_id }) => `pellet_${pellet_id}`)
  );

  const handleTierClick = useCallback(
    (tier) => {
      const next = data_source.includes(tier)
        ? data_source.filter((item) => item !== tier)
        : [...data_source, tier];
      setDataSource(next);
    },
    [data_source, setDataSource]
  );

  const handleClickPeriodWeek = useCallback(() => setPeriod(RelativeTimePeriod.week), [setPeriod]);
  const handleClickPeriodMonth = useCallback(() => setPeriod(RelativeTimePeriod.month), [
    setPeriod,
  ]);
  const handleClickPeriodQuarter = useCallback(() => setPeriod(RelativeTimePeriod.quarter), [
    setPeriod,
  ]);
  const endEl = useRef(null);
  const pellet_id_cover_map = useMemo(
    () => getPelletIdCoverMap(barge_cover_pellet_links),
    barge_cover_pellet_links
  );

  if (Object.keys(pellet_id_cover_map).length === 0) {
    return null;
  }

  return (
    <SummaryCard title={title} contentStyle={{ padding: 0 }}>
      <Grid container className={classes.content_container} alignItems="center">
        <Grid
          direction="column"
          container
          className={classes.tier_button_group}
          item
          xl={3}
          lg={3}
          md={3}
          sm={12}
          xs={12}
        >
          {grain_container.telemetry.pellets.map(({ pellet_id, is_emc_stable, latest }) => {
            const mapping = pellet_id_cover_map[pellet_id];
            if (!mapping) {
              return null;
            }
            const { color, cover_no } = mapping;
            return (
              <Grid key={pellet_id} item xl={12} lg={12} md={12} sm={6} xs={6}>
                <TierButton
                  tier_name={`${normalizeCoverNumberShort(cover_no)}`}
                  selected={data_source.includes(`pellet_${pellet_id}`)}
                  disabled={data_source.length === 1 && data_source.includes(`pellet_${pellet_id}`)}
                  onClick={() => handleTierClick(`pellet_${pellet_id}`)}
                  grain_status={latest}
                  color={color}
                  low_confidence_warning={
                    is_emc_stable === false
                      ? 'Data suggests this sensor is too shallow in the grain'
                      : undefined
                  }
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          <div className={classes.plot_wrapper} ref={endEl}>
            <div className={classes.period_radio_wrapper}>
              <ButtonGroup color="secondary" size="small">
                <Button
                  className={period === RelativeTimePeriod.week ? classes.selectedButton : ''}
                  onClick={handleClickPeriodWeek}
                  disabled={!data_source.length}
                >
                  1W
                </Button>
                <Button
                  className={period === RelativeTimePeriod.month ? classes.selectedButton : ''}
                  onClick={handleClickPeriodMonth}
                  disabled={!data_source.length}
                >
                  1M
                </Button>
                <Button
                  className={period === RelativeTimePeriod.quarter ? classes.selectedButton : ''}
                  onClick={handleClickPeriodQuarter}
                  disabled={!data_source.length}
                >
                  3M
                </Button>
              </ButtonGroup>
            </div>
            <PelletsTelemetryHistoryPlot
              source={data_source}
              container_id={grain_container.container_id}
              container_type={grain_container.container_type}
              statistic={Statistic.temp_f}
              period={period}
              height={275}
              width={400}
              tier_count={grain_container.telemetry.pellets.length}
              pellet_id_cover_map={pellet_id_cover_map}
              show_temp_threshold={true}
            />
          </div>
        </Grid>
      </Grid>
    </SummaryCard>
  );
};
