import { MenuItem } from '@material-ui/core';
import React, { FunctionComponent, memo } from 'react';
import * as yup from 'yup';

import { BinEventType } from '../../../api/graphql';
import { Select, SelectProps } from './Select';

const EVENT_TYPES = [
  {
    value: BinEventType.MoistureSample,
    label: 'Moisture Sampled',
  },
  {
    value: BinEventType.FanStart,
    label: 'Fans Started',
  },
  {
    value: BinEventType.FanStop,
    label: 'Fans Stopped',
  },
  {
    value: BinEventType.LidOpen,
    label: 'Top Lid Opened',
  },
  {
    value: BinEventType.LidClose,
    label: 'Top Lid Closed',
  },
  {
    value: BinEventType.HeatStart,
    label: 'Heat Activated',
  },
  {
    value: BinEventType.HeatStop,
    label: 'Heat Deactivated',
  },
  {
    value: BinEventType.GrainAdd,
    label: 'Grain Added',
  },
  {
    value: BinEventType.GrainRemove,
    label: 'Grain Removed',
  },
  {
    value: BinEventType.BinEmpty,
    label: 'Bin Emptied',
  },
  {
    value: BinEventType.BinCore,
    label: 'Bin Cored',
  },
  {
    value: BinEventType.FanSeal,
    label: 'Fans Sealed',
  },
  {
    value: BinEventType.FanUnseal,
    label: 'Fans Unsealed',
  },
  {
    value: BinEventType.FumStart,
    label: 'Fumigation Started',
  },
  {
    value: BinEventType.FumStop,
    label: 'Fumigation Completed',
  },
  {
    value: BinEventType.SensorsDeploy,
    label: 'Sensors Deployed',
  },
  {
    value: BinEventType.SensorsRemove,
    label: 'Sensors Removed',
  },
  {
    value: BinEventType.Other,
    label: 'Other',
  },
];

export const yup_bin_event_type = yup
  .string()
  .oneOf([
    null,
    BinEventType.MoistureSample,
    BinEventType.GrainAdd,
    BinEventType.GrainRemove,
    BinEventType.FanStart,
    BinEventType.FanStop,
    BinEventType.FanSeal,
    BinEventType.FanUnseal,
    BinEventType.LidOpen,
    BinEventType.LidClose,
    BinEventType.HeatStart,
    BinEventType.HeatStop,
    BinEventType.GrainAdd,
    BinEventType.GrainRemove,
    BinEventType.BinCore,
    BinEventType.Other,
    BinEventType.BinEmpty,
    BinEventType.SensorsDeploy,
    BinEventType.SensorsRemove,
  ]) as yup.StringSchema<BinEventType | null>;

export const SelectBinEventType: FunctionComponent<SelectProps> = memo((props) => {
  return (
    <Select {...props}>
      {EVENT_TYPES.map(({ value, label }) => (
        <MenuItem value={value} key={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
});
