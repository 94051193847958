import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { weather_icon_image_url_mapping } from '../../../../../../core/src/component/grain-container';
import SkeletonLoader from '../../../../../../core/src/component/util/SkeletonLoader';
import { formatPercent, formatTemp } from '../../../../../../core/src/util';
import { SiteDetails } from '../SiteDetails';
import { withSkeleton } from '../withCellSkeleton';
import { MinMaxWeatherConditionCell, SimpleWeatherConditionCell } from './WeatherCellRenderer';
import { WeatherInfoPeriod } from './WeatherHeaderCellRenderer';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  weatherGroupCol: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 58,
    height: '100%',
    borderRight: '1px dashed var(--ag-border-color, #babfc7)',
  },
}));

const WeatherIconCellRenderer = withSkeleton((props) => {
  const weatherPeriod = props.api['weather_period'] || WeatherInfoPeriod.today;

  if (props.value === undefined) {
    return <SiteSummary {...props} weatherPeriod={weatherPeriod} />;
  }

  const weatherInfo = props.value[weatherPeriod];

  if (!weatherInfo) {
    return <div>-</div>;
  }

  const iconVal = weatherInfo.icon;
  const icon =
    weather_icon_image_url_mapping[iconVal && iconVal.toLowerCase()] ||
    weather_icon_image_url_mapping.clear;

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src={icon} width="80%" height="80%" style={{ objectFit: 'contain' }} />
    </div>
  );
});

const SiteSummary = (props) => {
  const classes = useStyles();
  const areAllChildrenLoaded =
    props &&
    props.node &&
    props.node.childrenAfterFilter &&
    props.node.childrenAfterFilter
      .map(({ data }) => data)
      .every((data) => data['weather'] !== 'loading');

  if (!areAllChildrenLoaded) {
    return <SkeletonLoader />;
  }

  const childrenRowsDataAfterFilter = props.node.childrenAfterFilter.map(({ data }) => data);
  if (childrenRowsDataAfterFilter.length === 0) {
    return <SiteDetails {...props}>-</SiteDetails>;
  }
  const firstBinData = childrenRowsDataAfterFilter[0];
  const weatherInfo = firstBinData.weather[props.weatherPeriod];
  const weatherPeriod = props.api['weather_period'] || WeatherInfoPeriod.today;

  if (!weatherInfo || !weatherPeriod) {
    return <div>-</div>;
  }

  if (weatherPeriod === WeatherInfoPeriod.now) {
    return (
      <>
        <SiteDetails {...props}>
          <NowWeatherForSite weatherInfo={weatherInfo} />
        </SiteDetails>
      </>
    );
  }

  const iconVal = weatherInfo.icon;
  const icon =
    weather_icon_image_url_mapping[iconVal && iconVal.toLowerCase()] ||
    weather_icon_image_url_mapping.clear;
  const {
    temp: { temp_high_f, temp_low_f },
    humidity: { humidity_high_rh, humidity_low_rh },
    emc: { emc_high, emc_low },
  } = weatherInfo;
  return (
    <SiteDetails {...props}>
      <div className={classes.container}>
        <div className={classes.weatherGroupCol}>
          <img src={icon} width="70%" height="70%" style={{ objectFit: 'contain' }} />
        </div>
        <div className={classes.weatherGroupCol}>
          <MinMaxWeatherConditionCell max={formatTemp(temp_high_f)} min={formatTemp(temp_low_f)} />
        </div>
        <div className={classes.weatherGroupCol}>
          <MinMaxWeatherConditionCell
            max={formatPercent(humidity_high_rh)}
            min={formatPercent(humidity_low_rh)}
          />
        </div>
        <div className={classes.weatherGroupCol} style={{ border: 'none' }}>
          <MinMaxWeatherConditionCell max={formatPercent(emc_high)} min={formatPercent(emc_low)} />
        </div>
      </div>
    </SiteDetails>
  );
};

const NowWeatherForSite = ({ weatherInfo }) => {
  const classes = useStyles();
  const iconVal = weatherInfo.icon;
  const icon =
    weather_icon_image_url_mapping[iconVal && iconVal.toLowerCase()] ||
    weather_icon_image_url_mapping.clear;
  const { temp_f, humidity_rh, emc } = weatherInfo;

  return (
    <div className={classes.container}>
      <div className={classes.weatherGroupCol}>
        <img src={icon} width="70%" height="70%" style={{ objectFit: 'contain' }} />
      </div>
      <div className={classes.weatherGroupCol}>
        <SimpleWeatherConditionCell value={formatTemp(temp_f)} />
      </div>
      <div className={classes.weatherGroupCol}>
        <SimpleWeatherConditionCell value={formatPercent(humidity_rh)} />
      </div>
      <div className={classes.weatherGroupCol} style={{ border: 'none' }}>
        <SimpleWeatherConditionCell value={formatPercent(emc)} />
      </div>
    </div>
  );
};

export { WeatherIconCellRenderer };
