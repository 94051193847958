import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { useContext } from 'react';
import { HubType } from '../../../../../../core/src/api';
import formatTon from '../../../../../../core/src/util/format-ton';
import { ActiveHubsWithColorContext, HubWithColorCode } from '../../../../contexts';
import { formatBushel, getHasUserSelectedKilogramUnit } from '../../../../util';
import { RelativePeriods } from '../../../../util/constant';
import { tooltipContainerStyles } from '../../daily-forecast/WeatherForecastPlot';
import {
  getTickValues,
  getXaxisInterval,
  getXMinFromPeriod,
  GRAIN_TICKET_LINE_COLOR,
} from './LevelHistoryPlotHelpers';
import { MONTHS } from './TelemetryHistoryPlot';

declare global {
  interface Window {
    moment: any;
  }
}
window.moment = moment;

const useStyles = makeStyles({
  tooltipContainer: {
    ...tooltipContainerStyles,
  },
});

const formatHourTick = (dt) => `${dt.toFormat('h a')}<br/>${dt.month}/${dt.day}`;

const getFormatedDay = (dt: DateTime, as_of = DateTime.local(), prevent_now = false): string => {
  if (!prevent_now && dt.toMillis() <= as_of.toMillis()) {
    return 'Now';
  }
  if (dt.toMillis() >= DateTime.local(3000, 1, 1).toMillis()) {
    return 'Forever';
  }
  if (dt.hasSame(as_of, 'day')) {
    return 'Today';
  }
  return dt.toFormat('ccc');
};

const formatTimeTick = (dt) =>
  `${getFormatedDay(dt, DateTime.local(), true)}<br/>${dt.month}/${dt.day}`;
const getNearestTon = (value) => {
  return formatTon(value);
};
const getNearestThoundsBushels = (bushels) => formatBushel(bushels);
export const formatToNearestThousands = (value) => Math.round(value / 1000) * 1000;

const getMinMaxBushelValues = (series: any, x_min, x_max) => {
  if (series.length > 0) {
    let max = -Infinity;
    let min = Infinity;
    for (const points of series) {
      const pointsWithinPeriod = points.filter(({ x }) => x >= x_min && x <= x_max);
      for (const { y } of pointsWithinPeriod) {
        if (y > max) max = y;
        if (y < min) min = y;
      }
    }
    return [min, max];
  }
  return [0, 0];
};

export type LevelHistoryPlotProps = {
  period: string;
  grain_bin_location_timezone: string;
  active_hubs_formatted_level_data: {
    hub_id: number;
    bushelsData: { x: number; y: number }[];
  }[];
  active_grain_tickets_level_data: { x: number; y: number }[];
  max_bushels: number;
};

const LevelHistoryPlotV2: React.FunctionComponent<LevelHistoryPlotProps> = ({
  period,
  grain_bin_location_timezone,
  active_hubs_formatted_level_data,
  active_grain_tickets_level_data,
  max_bushels,
}) => {
  const activeHubsWithColorContext = useContext(ActiveHubsWithColorContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const isSmallMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });

  const activeHubsWithColor = activeHubsWithColorContext.activeHubsWithColor;
  const classes = useStyles();
  Highcharts.setOptions({
    time: {
      timezone: grain_bin_location_timezone,
    },
  });
  const today = DateTime.local();
  today.set({ hour: 12 });
  const tick_values = getTickValues(period, today.year, today.month, today.day, today.hour);

  const x_min =
    active_grain_tickets_level_data.length > 0
      ? active_grain_tickets_level_data[0].x
      : getXMinFromPeriod(period);
  const x_max =
    active_grain_tickets_level_data.length > 0
      ? active_grain_tickets_level_data[active_grain_tickets_level_data.length - 1].x
      : tick_values[tick_values.length - 1].toMillis();

  const grainHubsLevelsSeriesData =
    activeHubsWithColor &&
    activeHubsWithColor.length > 0 &&
    active_hubs_formatted_level_data
      .filter((row) => row.bushelsData && row.bushelsData.length > 0)
      .map(({ hub_id, bushelsData }) => {
        const hub: HubWithColorCode | undefined = activeHubsWithColor.find(
          (hub) => hub.hub_id === hub_id
        );

        if (hub) {
          const hubTypeLabel = hub.hub_type === HubType.Headspace ? 'Headspace' : 'Plenum';
          return {
            id: `${hubTypeLabel}-${hub.nickname}`,
            name: `${hubTypeLabel}-${hub.nickname}`,
            yAxis: 1,
            type: 'spline',
            data: bushelsData,
            color: hub.color,
            zIndex: 999,
          };
        }
        return null;
      })
      .filter((data) => data != null);

  const grainHubsLevelsHiddenSeriesData =
    activeHubsWithColor &&
    activeHubsWithColor.length > 0 &&
    active_hubs_formatted_level_data
      .filter((row) => row.bushelsData && row.bushelsData.length > 0)
      .map(({ hub_id, bushelsData }) => {
        const hub: HubWithColorCode | undefined = activeHubsWithColor.find(
          (hub) => hub.hub_id === hub_id
        );

        if (hub) {
          const hubTypeLabel = hub.hub_type === HubType.Headspace ? 'Headspace' : 'Plenum';
          return {
            name: `${hubTypeLabel}-${hub.nickname}`,
            yAxis: 0,
            type: 'spline',
            data: bushelsData,
            enableMouseTracking: false,
            color: 'transparent',
            linkedTo: `${hubTypeLabel}-${hub.nickname}`,
          };
        }
        return null;
      })
      .filter((data) => data != null);

  const grainTicketsSeriesData = {
    id: 'grain_tickets',
    name: 'Grain Tickets',
    yAxis: 1,
    type: 'line',
    step: 'left',
    data: active_grain_tickets_level_data,
    color: GRAIN_TICKET_LINE_COLOR,
    zIndex: 999,
  };

  const grainTicketsHiddenSeriesData = {
    name: 'Grain Tickets',
    yAxis: 0,
    type: 'line',
    data: active_grain_tickets_level_data,
    enableMouseTracking: false,
    color: 'transparent',
    linkedTo: 'grain_tickets',
  };

  const seriesData = [
    ...(grainHubsLevelsSeriesData ? grainHubsLevelsSeriesData : []),
    grainTicketsSeriesData,
  ];

  const seriesDataPoints =
    seriesData && seriesData.length > 0
      ? seriesData.map((series) => (series ? series.data : []))
      : [];

  const [minBushelValue, maxBushelValue] = getMinMaxBushelValues(seriesDataPoints, x_min, x_max);

  const hiddenSeriesData = [
    ...(grainHubsLevelsHiddenSeriesData ? grainHubsLevelsHiddenSeriesData : []),
    grainTicketsHiddenSeriesData,
  ];

  const commonTickIntervalForBothAxis =
    maxBushelValue <= max_bushels && Math.abs(minBushelValue) <= max_bushels
      ? maxBushelValue <= max_bushels * 0.4
        ? max_bushels * 0.1
        : max_bushels * 0.2
      : max_bushels * 0.4;
  const hasUserSelectedKilogramUnit = getHasUserSelectedKilogramUnit();

  // chart options
  const chartOptions = {
    credits: false,
    chart: {
      spacingTop: 8,
      applyLegendFix: true,
      height: 350,
      spacingLeft: isSmallMobile ? 0 : 10,
      spacingRight: isSmallMobile ? 0 : 10,
    },
    title: {
      text: '',
      y: 0,
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: 'rgba(255,255,255,0)',
      borderWidth: 0,
      borderRadius: 0,
      shadow: false,
      style: {
        zIndex: 9999,
      },
      formatter() {
        const tooltipHeading =
          period === RelativePeriods.week || period === RelativePeriods.day
            ? Highcharts.dateFormat('%l:%M %p', this.x)
            : Highcharts.dateFormat('%e %b', this.x);
        const seriesdataPointInfo =
          this.points &&
          this.points
            .filter((category) => category.series.visible)
            .map((point) => {
              if (!point) return '';
              return (
                `<span style="color:${point.color};">\u25CF</span> ` +
                `<b>~${
                  hasUserSelectedKilogramUnit
                    ? getNearestTon(point.y)
                    : getNearestThoundsBushels(point.y)
                }</b><br/>`
              );
            })
            .filter((info) => info !== '')
            .join('');
        return `<div class="${classes.tooltipContainer}">
          <b>${tooltipHeading}</b><br/>${seriesdataPointInfo}
          </div>`;
      },
      followPointer: true,
      followTouchMove: true,
      stickOnContact: true,
      animation: false,
    },
    xAxis: {
      type: 'datetime',
      useHTML: true,
      labels: {
        style: {
          fontSize: 12,
          fontFamily: 'Source Sans Pro,sans-serif',
        },
        format: '{value:%b-%e }', // '{value:%Y-%b-%e %l:%M %p }',
        formatter() {
          const dt = DateTime.fromMillis(this.value);
          return {
            [RelativePeriods.day]: formatHourTick(dt),
            [RelativePeriods.week]: formatTimeTick(dt),
            [RelativePeriods.month]: `${MONTHS[dt.month - 1].substring(0, 3)} ${dt.day}`,
            [RelativePeriods.quarter]: MONTHS[dt.month - 1],
          }[period];
        },
      },
      min: x_min,
      max: x_max,
      tickInterval: getXaxisInterval(period, isMobile),
      crosshair: {
        enabled: true,
        color: 'blue',
      },
    },
    yAxis: [
      {
        title: {
          text: null,
        },
        min: minBushelValue < 0 ? minBushelValue : 0,
        max:
          Math.abs(maxBushelValue) <= commonTickIntervalForBothAxis
            ? 2 * commonTickIntervalForBothAxis // always show minimum 2 ticks
            : Math.abs(maxBushelValue),
        tickInterval: commonTickIntervalForBothAxis,
        tickPositioner() {
          if (this.tickPositions) {
            const formattedTickPositions = this.tickPositions.map((tick) =>
              formatToNearestThousands(tick)
            );
            const dataMax = formatToNearestThousands(this.dataMax);
            let tickJustGreaterThanDataMax: number = dataMax || 0;
            for (let i = 0; i < formattedTickPositions.length; i += 1) {
              if (formattedTickPositions[i] > dataMax) {
                tickJustGreaterThanDataMax = formattedTickPositions[i];
              }
            }

            return this.tickPositions.filter(
              (tick) => formatToNearestThousands(tick) <= tickJustGreaterThanDataMax
            );
          }
        },

        labels: {
          formatter() {
            const percentagVal = Math.round((this.value / max_bushels) * 100);
            return `${percentagVal}%`;
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Source Sans Pro,sans-serif',
          },
        },
        gridLineWidth: 0,
      },
      {
        title: {
          text: null,
        },
        min: minBushelValue < 0 ? minBushelValue : 0,
        max:
          Math.abs(maxBushelValue) <= commonTickIntervalForBothAxis
            ? 2 * commonTickIntervalForBothAxis // always show minimum 2 ticks
            : Math.abs(maxBushelValue),
        tickInterval: commonTickIntervalForBothAxis,
        tickPositioner() {
          if (this.tickPositions) {
            const formattedTickPositions = this.tickPositions.map((tick) =>
              formatToNearestThousands(tick)
            );
            const dataMax = formatToNearestThousands(this.dataMax);
            let tickJustGreaterThanDataMax: number = dataMax || 0;
            for (let i = 0; i < formattedTickPositions.length; i += 1) {
              if (formattedTickPositions[i] > dataMax) {
                tickJustGreaterThanDataMax = formattedTickPositions[i];
              }
            }
            const ticks = this.tickPositions.filter(
              (tick) => formatToNearestThousands(tick) <= tickJustGreaterThanDataMax
            );
            return ticks;
          }
        },
        plotLines: [
          {
            color: 'gray',
            dashStyle: 'dot',
            width: 1,
            value: 0,
          },
        ],
        labels: {
          formatter() {
            return hasUserSelectedKilogramUnit ? formatTon(this.value) : formatBushel(this.value);
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Source Sans Pro,sans-serif',
          },
        },
        gridLineWidth: 0,
        opposite: true,
        crosshair: {
          interpolate: true,
          label: {
            enabled: false,
          },
        },
      },
    ],
    series: seriesData && hiddenSeriesData ? [...seriesData, ...hiddenSeriesData] : [],
    legend: {
      useHTML: true,
      verticalAlign: 'top',
      alignColumns: false,
      itemMarginBottom: 2,
      itemStyle: {
        fontWeight: 600,
        fontFamily: 'Source Sans Pro,sans-serif',
        fontSize: '14px',
      },
    },
    plotOptions: {
      series: {
        turboThreshold: 6000, // max dataPoints allow limit- larger threshold or set to 0 to disable
        states: {
          inactive: {
            opacity: 1,
          },
        },
        marker: {
          enabledThreshold: 6,
        },
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default LevelHistoryPlotV2;
