import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetActivePileCableLinksDocument,
  GetActivePileCableLinksQuery,
  GetActivePileCableLinksQueryVariables,
  LinkPileCableMutationVariables as Variables,
  PileCableLinkFragmentFragment as Result,
  withLinkPileCable as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithLinkPileCableHocChildProps = {
  linkPileCable: (variables: Variables) => Promise<Result>;
};
export const withLinkPileCableHoc: AmberHoc<{}, WithLinkPileCableHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithLinkPileCableHocChildProps, typeof component>,
    WithLinkPileCableHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        linkPileCable: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected error');
          }
          const { pile_id } = variables;
          const result = await mutate({
            variables,
            update: (proxy, { data, errors: errors2 }) => {
              try {
                if (errors2 || !data) {
                  return;
                }
                const { linkPileCable: result } = data;
                const cached = proxy.readQuery<
                  GetActivePileCableLinksQuery,
                  GetActivePileCableLinksQueryVariables
                >({
                  query: GetActivePileCableLinksDocument,
                  variables: { pile_id },
                });
                if (!cached) {
                  return;
                }
                const { active_pile_cable_links } = cached;
                proxy.writeQuery<
                  GetActivePileCableLinksQuery,
                  GetActivePileCableLinksQueryVariables
                >({
                  query: GetActivePileCableLinksDocument,
                  data: {
                    __typename: 'Query',
                    active_pile_cable_links: [...active_pile_cable_links, result],
                  },
                  variables: { pile_id },
                });
              } catch (e) {
                // no-op
              }
            },
          });
          if (!result) {
            throw new Error('Unexpected response from server');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data || !data.linkPileCable) {
            throw new Error('Unexpected response from server');
          }
          return data.linkPileCable;
        },
      };
    },
  })(component as any);
