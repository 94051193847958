import React from "react";
import {UserRole} from "../api";

export const UserContext = React.createContext({});

export const hasDiagnosticAccess = (viewer) => {
    if (viewer){
        const {
            account: { system_account },
            user: { role },
        } = viewer;
        return system_account && role === UserRole.Admin;
    }
    return false;
}
