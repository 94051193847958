import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useState } from 'react';

const useStyles = makeStyles({
  content: {
    fontSize: 24,
    marginBottom: 20,
  },
  confirmButton: {
    fontSize: 14,
    textAlign: 'center',
    backgroundColor: '#28a745',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#218838',
    },
  },
  button: {
    fontSize: 14,
    textAlign: 'center',
  },
  grid: {
    width: '100%',
    padding: 20,
  },
});

type ContentModalProps = {
  ModalContent: React.FunctionComponent<{ setDisableConfirmButton?: Function }>;
  showModal: boolean;
  handleCancel: Function;
  handleConfirm: Function;
  cancelBtnText: string;
  confirmBtbText: string;
  disableConfirmButtonDefault: boolean;
};

const ContentModal: FunctionComponent<ContentModalProps> = ({
  ModalContent,
  showModal,
  cancelBtnText,
  confirmBtbText,
  handleCancel,
  handleConfirm,
  disableConfirmButtonDefault = true,
}) => {
  const classes = useStyles();
  const [disableConfirmButton, setDisableConfirmButton] = useState(disableConfirmButtonDefault);

  return (
    <Dialog maxWidth="xs" open={showModal} onClose={() => handleCancel()}>
      <DialogContent className={classes.grid}>
        <ModalContent setDisableConfirmButton={setDisableConfirmButton} />
        <DialogActions>
          <Button
            variant="contained"
            className={classes.confirmButton}
            disabled={disableConfirmButton}
            onClick={() => handleConfirm()}
          >
            {confirmBtbText}
          </Button>
          <Button className={classes.button} variant="contained" onClick={() => handleCancel()}>
            {cancelBtnText}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ContentModal;
