import { AppBar, IconButton, SwipeableDrawer, Toolbar, Typography } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, memo, useCallback } from 'react';
import { GetProps, useDispatch, useSelector } from 'react-redux';
import { endSession, pushNav, setNavBar } from '../../../action';
import {
  AccountFragmentFragment,
  UserRole,
  ViewerFragmentFragment,
  withGetAccountHoc,
  WithGetAccountHocChildProps,
} from '../../../api';
import { NavBarState, SelectedState } from '../../../reducer';
import { AppNavigation } from '../AppNavigation';
import { GrainContainerSelect } from './GrainContainerSelect';

const useStyles = makeStyles({
  paper: {
    minWidth: 250,
  },
  grow: { textAlign: 'left', flexGrow: 1 },
  alertSvgIcon: {
    fill: 'currentColor',
    height: '1em',
    fontSize: '1.2857142857142856rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: 0,
    stroke: 'rgba(0, 0, 0, 0.54)',
    strokeWidth: '10px',
  },
});

const selectContainerType = ({ selected: { container_type } }: { selected: SelectedState }) =>
  container_type;
const selectOpen = ({ nav_bar: { open } }: { nav_bar: NavBarState }) => open;

const BaseNavMenu: FunctionComponent<
  WithGetAccountHocChildProps & {
    viewer: ViewerFragmentFragment;

    onClickAccounts?: () => void;
    onClickFanControllers?: () => void;
    onClickHubs?: () => void;
    onClickPackaging?: () => void;

    onSelectAccount?: (account: AccountFragmentFragment) => void;
    onClickGrainFanCurves?: () => void;

    title: string;
    email_address: string;
    pathname: string;
    onClickDashboard: (args: any) => void;
    onClickUsers: () => void;
    onClickUser: () => void;
    onClickSettings: () => void;
    onClickOrgSettings: () => void;
    onClickNotifications: () => void;
    onClickPrivacy: () => void;
    onClickBack?: () => void;
    onSelectContainer: (args: any) => void;
    goCreateBarge: () => void;
    goCreatePile: () => void;
    goCreateGrainBin: () => void;
    currentAccountId: number;
  }
> = memo(
  ({
    currentAccountId,
    account,
    viewer,
    onClickAccounts,
    onClickFanControllers,
    onClickHubs,
    onClickPackaging,
    onClickPrivacy,
    onClickSettings,
    onClickOrgSettings,
    onClickNotifications,
    onClickUsers,
    onSelectContainer,
    onSelectAccount,
    onClickGrainFanCurves,
    title,
    email_address,
    pathname,
    onClickDashboard,
    onClickBack,
    goCreateBarge,
    goCreatePile,
    goCreateGrainBin,
  }) => {
    const classes = useStyles();
    const container_type = useSelector(selectContainerType);
    const open = useSelector(selectOpen);

    const dispatch = useDispatch();
    const onClickClose = useCallback(() => {
      dispatch(setNavBar({ open: false }));
    }, [dispatch]);
    const onClickLogout = useCallback(() => dispatch(endSession()), [dispatch]);
    const onClickUser = useCallback(
      () =>
        dispatch(
          pushNav({
            path: '/user/account',
            params: { selected: { container_id: null, container_type: null, pellet_id: '' } },
          })
        ),
      [dispatch]
    );

    const handleSelectGrainContainer = useCallback<
      GetProps<typeof GrainContainerSelect>['onChange']
    >(
      ({ container_id, container_type, container_name }) => {
        if (onSelectContainer) {
          onSelectContainer({
            account,
            container_type,
            container_id,
            container_name,
          });
        }
      },
      [onSelectContainer, account]
    );

    const {
      account: { account_id: viewer_account_id, organization, system_account },
      user: { first_name, last_name, role },
    } = viewer;

    const admin_mode = system_account && role === UserRole.Admin;
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
      <SwipeableDrawer
        anchor="left"
        open={open}
        onOpen={() => dispatch(setNavBar({ open: true }))}
        onClose={onClickClose}
        classes={{ paper: classes.paper }}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <div>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" color="inherit" className={classes.grow}>
                {viewer_account_id === (account && account.account_id)
                  ? organization
                  : `${first_name} ${last_name}`}
              </Typography>
              {viewer_account_id !== (account && account.account_id) && (
                <IconButton onClick={onClickUser}>
                  <Person />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
        </div>
        <AppNavigation
          viewer={viewer}
          title={title}
          email_address={email_address}
          pathname={pathname}
          onClickDashboard={onClickDashboard}
          onClickPrivacy={onClickPrivacy}
          onClickUser={onClickUser}
          onClickUsers={onClickUsers}
          onClickOrgSettings={onClickOrgSettings}
          onClickNotifications={onClickNotifications}
          onClickSettings={onClickSettings}
          onSelectContainer={onSelectContainer}
          goCreateBarge={goCreateBarge}
          goCreatePile={goCreatePile}
          goCreateGrainBin={goCreateGrainBin}
          onClickBack={onClickBack}
          hideHeadBar={true}
          currentAccountId={currentAccountId}
        />
      </SwipeableDrawer>
    );
  }
);

export const NavMenuOnMobile = withGetAccountHoc(BaseNavMenu);
