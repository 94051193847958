import { Card, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { CSSProperties } from 'react';

import { amber_dark_grey } from '../../../style';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon_root: {
    marginTop: -24,
    marginBottom: -24,
  },
  header: {
    margin: 15,
    marginLeft: 25,
  },
  content: {
    flexGrow: 1,
  },
});
export const FlexSummaryCard: React.FunctionComponent<{
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
}> = ({ title, subtitle, children, style, contentStyle }) => {
  const classes = useStyles();
  return (
    <Card raised classes={{ root: classes.root }} style={style}>
      <CardHeader
        className={classes.header}
        subheader={subtitle}
        title={
          <Typography
            variant="h5"
            style={{ fontSize: 20, color: amber_dark_grey, width: 'fit-content' }}
          >
            {title}
          </Typography>
        }
      />
      <Divider />
      <CardContent classes={{ root: classes.content }} style={contentStyle}>
        {children}
      </CardContent>
    </Card>
  );
};
