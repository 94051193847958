import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy } from '../../../util';
import {
  GetGrainContainerHubLinksQuery,
  withGetGrainContainerHubLinks as HOC,
} from '../__generated';

export type WithGetGrainContainerHubLinksHocOwnProps = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainContainerHubLinksHocChildProps = {
  loading: boolean;
  grain_container: GetGrainContainerHubLinksQuery['grain_container'] | null;
};
export const withGetGrainContainerHubLinksHoc: AmberHoc<
  WithGetGrainContainerHubLinksHocOwnProps,
  WithGetGrainContainerHubLinksHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainContainerHubLinksHocOwnProps,
      WithGetGrainContainerHubLinksHocChildProps,
      typeof component
    >,
    WithGetGrainContainerHubLinksHocChildProps
  >({
    options: ({ container_id, container_type, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id, container_type },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          grain_container: null,
        };
      }
      return {
        loading: loading || false,
        grain_container: data.grain_container || null,
      };
    },
  })(component as any);
