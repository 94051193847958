import { Grid } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles';
import { Delete, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import format from 'date-fns/format';
import React, { useCallback, useMemo, useState } from 'react';

import {
  HubType,
  withGetGrainContainerHubLinksHoc,
  WithGetGrainContainerHubLinksHocChildProps,
  withUpdateHubAssignmentHoc,
  WithUpdateHubAssignmentHocChildProps,
} from '../../api';
import { white } from '../../style';
import { DialogSpinner } from '../spinner';
import ConfirmationModal from '../util/ConfirmationModal';
import { ErrorBox } from '../util/form';
import { Button } from '../util/form2/Button';
import { UpdateGrainBinHubDialog } from './aeration/UpdateGrainBinHubDialog';

const formatDate = (value) => format(value, 'YYYY-MM-DD');

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
    minWidth: 340,
  },
  cell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cell_header: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  button_red: {
    backgroundColor: red['500'],
    color: white,
    '&:hover': {
      backgroundColor: fade(red['500'], 0.8),
    },
  },
  icon_button: {
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
  },
});

export type SelectedHubType = {
  link_id: number;
  start_epoch: Date;
  device_alias: string;
  core_id: number;
  nickname: string;
  hub_type: HubType;
  has_level_sensor: boolean;
  hub_offset_ft: number;
};

export const UnassignHubsBase = ({
  loading,
  grain_container,
  updateHubAssignment,
  ...props
}: WithGetGrainContainerHubLinksHocChildProps & WithUpdateHubAssignmentHocChildProps) => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deletingHub, setDeletingHub] = useState<string>('');
  const [deletingHubLinkId, setDeletingHubLinkid] = useState<number>();
  const [selected_hub, setSelectedHub] = useState<SelectedHubType | null>(null);
  const closeModal = useCallback(() => setSelectedHub(null), [setSelectedHub]);

  const initialValues = useMemo(
    () => ({
      link_id: null,
      active_hub_links: grain_container
        ? grain_container.active_hub_links.map((ahl) => {
            return {
              link_id: ahl.hub_container_link_id,
              start_epoch: ahl.start_epoch,
              device_alias: (ahl.hub && ahl.hub.alias) || '',
              core_id: ahl.core_id,
              nickname: ahl.nickname,
              hub_type: ahl.hub_type,
              has_level_sensor: ahl.has_level_sensor,
              hub_offset_ft: ahl.hub_offset_ft,
            };
          })
        : [],
    }),
    [grain_container && grain_container.active_hub_links]
  );
  const updateAssignment = useCallback(
    async (link_id) => {
      try {
        setSaving(true);
        setError('');
        const result = await updateHubAssignment({
          hub_container_link_id: link_id,
          end_epoch: new Date(),
        });
        setSaving(false);
        return result;
      } catch (e) {
        setSaving(false);
        setError(e);
      }
    },
    [updateHubAssignment]
  );

  return loading ? (
    <DialogSpinner title="Loading Hubs..." open />
  ) : grain_container ? (
    <>
      <Grid
        container
        direction="column"
        justify="center"
        spacing={2}
        classes={{ container: classes.root }}
      >
        {initialValues.active_hub_links.length ? (
          <>
            <Grid item container spacing={1} alignItems="center">
              <Grid item xs={3} className={classes.cell_header}>
                Nickname
              </Grid>
              <Grid item xs={3} className={classes.cell_header}>
                Device ID
              </Grid>
              <Grid item xs={2} className={classes.cell_header}>
                Hub Type
              </Grid>

              <Grid item xs={2} className={classes.cell_header}>
                Edit
              </Grid>
              <Grid item xs={2} className={classes.cell_header}>
                Remove
              </Grid>
            </Grid>
            {initialValues.active_hub_links.map((activeHubLink) => {
              const { link_id, device_alias, core_id, nickname, hub_type } = activeHubLink;
              return (
                <Grid item container spacing={1} alignItems="center" key={link_id}>
                  <Grid item xs={3} className={classes.cell}>
                    {nickname}
                  </Grid>
                  <Grid item xs={3} className={classes.cell}>
                    {device_alias || core_id}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{ textTransform: 'capitalize' }}
                    className={classes.cell}
                  >
                    {hub_type}
                  </Grid>
                  <Grid item xs={2} className={classes.cell}>
                    <Button
                      onClick={() => {
                        setSelectedHub((activeHubLink as unknown) as SelectedHubType);
                      }}
                      className={classes.icon_button}
                      fullWidth
                    >
                      <Edit />
                    </Button>
                  </Grid>
                  <Grid item xs={2} className={classes.cell}>
                    <Button
                      onClick={() => {
                        const deletingHubName = nickname
                          ? nickname
                          : device_alias
                          ? device_alias
                          : core_id;
                        setDeletingHubLinkid(link_id);
                        setDeletingHub(deletingHubName);
                        setShowDeleteModal(true);
                      }}
                      className={`${classes.button_red} ${classes.icon_button}`}
                      fullWidth
                    >
                      <Delete />
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
          </>
        ) : (
          <p>No Hubs Assigned</p>
        )}
        <ErrorBox error={error} />
        <DialogSpinner title={'Removing Hub...'} open={saving} />
        <ConfirmationModal
          showModal={showDeleteModal}
          confirmationMessage={
            <>
              Are you sure you want to delete hub <strong>{deletingHub}</strong>?
            </>
          }
          cancelBtnText="cancel"
          confirmBtbText="Delete"
          handleCancel={() => setShowDeleteModal(false)}
          handleConfirm={async () => {
            await updateAssignment(deletingHubLinkId);
            setShowDeleteModal(false);
          }}
        />
      </Grid>
      <UpdateGrainBinHubDialog
        open={Boolean(selected_hub)}
        grain_bin_hub={selected_hub}
        onClose={closeModal}
      />
    </>
  ) : null;
};

export const HubRemovalForm = withGetGrainContainerHubLinksHoc(
  withUpdateHubAssignmentHoc(UnassignHubsBase)
);
