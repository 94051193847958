import { useMediaQuery, useTheme } from '@material-ui/core';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  GrainBinSiteFragmentFragment,
  GrainBinSiteLink,
  OpsViewRowVisibilityPrefsFragmentFragment,
  OpsViewVisibilityPrefsFragmentFragment,
  SaveOperationsViewRowVisibilityPrefsMutation,
  SaveOperationsViewRowVisibilityPrefsMutationVariables,
  SaveOperationsViewUserLevelPrefsMutation,
  SaveOperationsViewUserLevelPrefsMutationVariables,
} from '../../../../../core/src/api';
import { light_gray_10 } from '../../../../../core/src/style';
import {
  ColumnVisibilityComponent,
  InputTextEditor,
  LinePlaceholderRenderer,
  ManageSitesGroupHeader,
} from './manage-sites-table-components';
import { GrainBinCellRenderer } from './manage-sites-table-components/GrainBinCellRenderer';
import { GrainSiteCellRenderer } from './manage-sites-table-components/GrainSiteCellRenderer';
import {
  getSitesWithUpdatedVisibility,
  getSitesWithUpdatedVisibilityInBulk,
  handleMoveGrainBinToDiffGroup,
  onCellEditingStopped,
  performColsAlterPrefChanges,
  performRowPrefChanges,
} from './ManageSitesTableHelpers';
import { useStyles } from './ManageSitesTableStyles';
import { UNASSIGNED_BINS_SITE_ID } from './ManageSitesView';

export const UserPrefType = {
  OPERATIONS_COLUMNS: 1,
  OPERATIONS_ROWS: 2,
};

export type PrefJsonItem = {
  site_id: number;
  bins: { bin_id: number; hide: boolean }[];
};

export type PrefJsonType = {
  sites: PrefJsonItem[];
};

interface IManageSitesTableProps {
  data: any;
  renameGrainSite: (site_id: number, site_name: string) => void;
  deleteGrainSite: (site_id: number) => void;
  moveGrainBinIntoSite: (grain_bin_id: number, site_id: number) => Promise<GrainBinSiteLink | null>;
  createGrainSite: (site_name: string) => Promise<GrainBinSiteFragmentFragment | null>;
  renameGrainBin: (grain_bin_id: number, bin_name: string) => Promise<void>;
  grainSitesRefetch: () => Promise<any>;
  operations_view_cols_visibility_prefs: OpsViewVisibilityPrefsFragmentFragment | null;
  operations_view_rows_visibility_prefs: OpsViewRowVisibilityPrefsFragmentFragment | null;
  saveOperationsViewUserLevelPrefs: (
    variables: SaveOperationsViewUserLevelPrefsMutationVariables
  ) => Promise<SaveOperationsViewUserLevelPrefsMutation['saveOperationsViewUserLevelPrefs']>;
  saveOperationsViewRowVisibilityPrefs: (
    variables: SaveOperationsViewRowVisibilityPrefsMutationVariables
  ) => Promise<
    SaveOperationsViewRowVisibilityPrefsMutation['saveOperationsViewRowVisibilityPrefs']
  >;
  updateOperationsViewRowVisibilityPrefs: (newSiteRows: PrefJsonItem[]) => Promise<void>;
  account_id: number;
  user_id: number;
}

const ManageSitesTable = ({
  data,
  createGrainSite,
  renameGrainSite,
  deleteGrainSite,
  moveGrainBinIntoSite,
  grainSitesRefetch,
  renameGrainBin,
  operations_view_cols_visibility_prefs,
  operations_view_rows_visibility_prefs,
  saveOperationsViewUserLevelPrefs,
  saveOperationsViewRowVisibilityPrefs,
  updateOperationsViewRowVisibilityPrefs,
  account_id,
  user_id,
}: IManageSitesTableProps) => {
  console.log(
    'in ManageSitesTable operations_view_rows_visibility_prefs',
    operations_view_rows_visibility_prefs
  );
  const theme = useTheme();
  const classes = useStyles();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const isWideScreen = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const gridRef = useRef<any>();
  const [gridApi, setGridApi] = useState<any>(null);
  const [rowData, setRowData] = useState<any>(data);
  const [isCellEditing, setIsCellEdting] = useState(false);
  const initSitesVisibilityPrefs = operations_view_rows_visibility_prefs
    ? JSON.parse(operations_view_rows_visibility_prefs.pref_json).sites
    : null;
  const initBinsVisibilityPrefs = initSitesVisibilityPrefs
    ? initSitesVisibilityPrefs.reduce((acc, siteInfo) => {
        acc.push(...siteInfo.bins);
        return acc;
      }, [])
    : [];
  console.log('initSitesVisibilityPrefs', initSitesVisibilityPrefs, initBinsVisibilityPrefs);
  const initColsVisibilityPrefs = operations_view_cols_visibility_prefs
    ? JSON.parse(operations_view_cols_visibility_prefs.pref_json).columns
    : null;
  console.log('initColsVisibilityPrefs', initColsVisibilityPrefs);
  const [colsVisibilityPrefs, setColsVisibilityPrefs] = useState(initColsVisibilityPrefs);
  const [currentColumnsOrder, setCurrentColumnsOrder] = useState<{ colId: string }[] | null>(null);
  const [binsVisibilityPrefs, setBinsVisibilityPrefs] = useState<any[]>(initBinsVisibilityPrefs);

  // initial load
  useEffect(() => {
    if (gridApi && operations_view_rows_visibility_prefs) {
      performRowPrefChanges({
        rowData,
        setRowData,
        gridApi,
        operations_view_rows_visibility_prefs,
      });
    }
  }, [gridApi, operations_view_rows_visibility_prefs]);

  useEffect(() => {
    if (gridApi && colsVisibilityPrefs && currentColumnsOrder) {
      // maintain cols original order
      gridApi.columnApi.applyColumnState({
        state: currentColumnsOrder,
        applyOrder: true,
      });
    }
  }, [gridApi, colsVisibilityPrefs, currentColumnsOrder]);

  const getIsColVisible = useCallback(
    (columId: string): boolean => {
      const pref = colsVisibilityPrefs
        ? colsVisibilityPrefs.find(({ colId }) => colId === columId)
        : null;
      return pref && pref.hide ? false : true;
    },
    [colsVisibilityPrefs]
  );

  const updateColVisibility = async (columnApi: any, columId: string, isHide: boolean) => {
    const columnState = columnApi.getColumnState();
    console.log('inside updateColVisibility', {
      columnState,
      colsVisibilityPrefs,
      columId,
      isHide,
    });
    const newColumnState = colsVisibilityPrefs
      ? colsVisibilityPrefs.map((state) => {
          if (state.colId === columId) {
            return { ...state, hide: isHide };
          }
          return state;
        })
      : columnState.map((state) => {
          if (state.colId === columId) {
            return { ...state, hide: isHide };
          }
          return state;
        });

    console.log('newColumnState', { colsVisibilityPrefs, newColumnState });

    setColsVisibilityPrefs(newColumnState);
    const result = await saveOperationsViewUserLevelPrefs({
      account_id,
      user_id,
      pref_type_key: UserPrefType.OPERATIONS_COLUMNS,
      pref_json: JSON.stringify({ columns: newColumnState }),
    });
    console.log('result saved for saveOperationsViewUserLevelPrefs', { result });
  };

  console.log('colsVisibilityPrefs', colsVisibilityPrefs);

  const updateRowVisibility = async (gridapi: any, binId: number, isHide: boolean) => {
    console.log('inside updateRowVisibility', { binId, isHide });

    const siteRows: PrefJsonItem[] = getSitesWithUpdatedVisibility(
      gridapi,
      binsVisibilityPrefs,
      binId,
      isHide
    );
    console.log('siteRows in updateRowVisibility', binsVisibilityPrefs, siteRows);

    setBinsVisibilityPrefs((prev) => [
      ...prev.map((bin) => (bin.bin_id === binId ? { ...bin, hide: isHide } : bin)),
    ]);

    const result = await saveOperationsViewRowVisibilityPrefs({
      account_id,
      pref_json: JSON.stringify({ sites: siteRows }),
    });
    console.log('result saved for updateRowVisibility', { result });
  };

  const updateRowVisibilityInBulk = async (gridapi: any, binIds: number[], isHide: boolean) => {
    const siteRows: PrefJsonItem[] = getSitesWithUpdatedVisibilityInBulk(
      gridapi,
      binsVisibilityPrefs,
      binIds,
      isHide
    );
    console.log('siteRows in updateRowVisibilityInBulk', binsVisibilityPrefs, siteRows);

    setBinsVisibilityPrefs((prev) => [
      ...prev.map((bin) => (binIds.includes(bin.bin_id) ? { ...bin, hide: isHide } : bin)),
    ]);

    const result = await saveOperationsViewRowVisibilityPrefs({
      account_id,
      pref_json: JSON.stringify({ sites: siteRows }),
    });
    console.log('result saved for saveOperationsViewRowVisibilityPrefs', { result });
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        headerGroupComponent: ColumnVisibilityComponent,
        headerGroupComponentParams: {
          updateColVisibility,
          isColVisible: getIsColVisible('fan_status_col'),
        },
        groupId: 'fanStatus',
        children: [
          { colId: 'fan_status_col', field: 'Fan Status', cellRenderer: LinePlaceholderRenderer },
        ],
      },
      {
        headerName: '',
        headerGroupComponent: ColumnVisibilityComponent,
        headerGroupComponentParams: {
          updateColVisibility,
          isColVisible: getIsColVisible('airspace_col'),
        },
        groupId: 'airspace',
        children: [
          { colId: 'airspace_col', field: 'Airspace', cellRenderer: LinePlaceholderRenderer },
        ],
      },
      {
        headerName: '',
        headerGroupComponent: ColumnVisibilityComponent,
        headerGroupComponentParams: {
          updateColVisibility,
          isColVisible: getIsColVisible('grain_level_col'),
        },
        groupId: 'grainLevel',
        children: [
          { colId: 'grain_level_col', field: 'Grain Level', cellRenderer: LinePlaceholderRenderer },
        ],
      },
      {
        headerName: '',
        headerGroupComponent: ColumnVisibilityComponent,
        headerGroupComponentParams: {
          updateColVisibility,
          isColVisible: getIsColVisible('weather_col'),
        },
        groupId: 'weather',
        children: [
          { colId: 'weather_col', field: 'Weather', cellRenderer: LinePlaceholderRenderer },
        ],
      },
      {
        headerName: '',
        headerGroupComponent: ColumnVisibilityComponent,
        headerGroupComponentParams: {
          updateColVisibility,
          isColVisible: getIsColVisible('fan_schedule_col'),
        },
        groupId: 'fanSchedule',
        children: [
          {
            colId: 'fan_schedule_col',
            field: 'Fan Schedule',
            cellRenderer: LinePlaceholderRenderer,
          },
        ],
      },
      {
        headerName: '',
        headerGroupComponent: ColumnVisibilityComponent,
        headerGroupComponentParams: {
          updateColVisibility,
          isColVisible: getIsColVisible('fan_history_col'),
        },
        groupId: 'fanHistory',
        children: [
          { colId: 'fan_history_col', field: 'Fan History', cellRenderer: LinePlaceholderRenderer },
        ],
      },
    ],
    [colsVisibilityPrefs]
  );
  const defaultColDef = useMemo(() => {
    return {
      width: 114,
      suppressMenu: true,
    };
  }, []);

  const getDataPath = useMemo(() => {
    return (data) => {
      return data.siteHierarchy;
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      rowDrag: (params) => {
        console.log('params', params);
        if (params.data && params.data.id === `site-${UNASSIGNED_BINS_SITE_ID}`) {
          return false;
        }
        return true;
      },
      headerName: 'Manage Table',
      headerComponent: ManageSitesGroupHeader,
      headerComponentParams: {
        isCellEditing,
      },
      minWidth: 331,
      cellRenderer: 'agGroupCellRenderer',
      editable: true,
      cellEditor: InputTextEditor,
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
        suppressEnterExpand: true,
        innerRenderer: GrainSiteCellRenderer,
        innerRendererParams: {
          renameGrainSite,
          deleteGrainSite,
          binsVisibilityPrefs,
          updateRowVisibility,
          updateRowVisibilityInBulk,
          updateOperationsViewRowVisibilityPrefs,
        },
        innerRendererSelector: (params) => {
          const containsBinNameValue = Boolean(params.data && params.data.bin_name);
          console.log('params', params);
          if (containsBinNameValue) {
            return { component: GrainBinCellRenderer };
          }
          return;
        },
        // lockPosition: 'left',
      },
      lockPosition: 'left' as any,
    };
  }, [isCellEditing, binsVisibilityPrefs]);

  console.log('rowData', rowData);

  const onCellEditingStarted = () => {
    setIsCellEdting(true);
  };

  const onColumnMoved = async (params: any) => {
    const columnState = params.columnApi.getColumnState();
    const columnStateColIds = columnState.map(({ colId }) => ({
      colId,
    }));
    setCurrentColumnsOrder(columnStateColIds);
    const updatedColumnState = colsVisibilityPrefs
      ? columnStateColIds.map((it) => {
          const col_id = it.colId;
          const colVisibilityPrefState = colsVisibilityPrefs.find(({ colId }) => {
            return colId === col_id;
          });
          return {
            colId: col_id,
            hide: colVisibilityPrefState ? colVisibilityPrefState.hide : false,
          };
        })
      : columnState.map(({ colId, hide }) => ({ colId, hide }));
    console.log('inside onColumnMoved', {
      columnStateColIds,
      colsVisibilityPrefs,
      updatedColumnState,
    });
    const result = await saveOperationsViewUserLevelPrefs({
      account_id,
      user_id,
      pref_type_key: UserPrefType.OPERATIONS_COLUMNS,
      pref_json: JSON.stringify({ columns: updatedColumnState }),
    });
    console.log('result saved for saveOperationsViewUserLevelPrefs in onColumnMoved', { result });
  };

  const onGridReady = (params: any) => {
    setGridApi(params);
    // only order gets applied
    operations_view_cols_visibility_prefs &&
      performColsAlterPrefChanges(
        params,
        operations_view_cols_visibility_prefs,
        setCurrentColumnsOrder
      );
  };

  const onRowDragMove = (event) => {
    console.log('event in onRowDragMove', event);
    const movingNode = event.node;
    const overNode = event.overNode;
    console.log('inside onRowDragMove', movingNode, overNode, overNode.hasChildren());
    if (movingNode.hasChildren() || movingNode.data.siteHierarchy.length === 1) {
      // for site rows
      return;
    }
    // if (
    //   movingNode.data.siteHierarchy.length === 1 &&
    //   overNode.data.site_id === UNASSIGNED_BINS_SITE_ID
    // ) {
    //   console.log('prevent moving of UNASSIGNED_BINS_SITE');
    //   return;
    // }
  };

  const onRowDragEnd = async (event) => {
    console.log('event in onRowDragEnd', { event, binsVisibilityPrefs });

    const movingNode = event.node;
    const overNode = event.overNode;

    // move a bin to the different site
    if (movingNode.data.siteHierarchy.length === 2) {
      // for bins rows only
      const result = await handleMoveGrainBinToDiffGroup(
        movingNode,
        overNode,
        gridRef,
        moveGrainBinIntoSite
      );
    }

    const siteRows: PrefJsonItem[] = [];
    event.api.getModel().rowsToDisplay.forEach((rowNode, index) => {
      const rowData = rowNode.data;
      // only sites
      if (rowData && rowData.siteHierarchy.length === 1) {
        const childBins = rowNode.childrenAfterFilter.map((node) => {
          const prevBinVisibilityPref =
            binsVisibilityPrefs.length > 0
              ? binsVisibilityPrefs.find(({ bin_id }) => bin_id === node.data.bin_id)
              : null;
          return {
            bin_id: node.data.bin_id,
            hide: prevBinVisibilityPref ? prevBinVisibilityPref.hide : false,
          };
        });
        siteRows.push({
          site_id: rowData.site_id,
          bins: childBins,
        });
      }
    });

    // enforce UNASSIGNED_BINS site to reside on the last index always
    const newSiteRows: PrefJsonItem[] = [
      ...siteRows.filter((sitePref) => sitePref.site_id !== UNASSIGNED_BINS_SITE_ID),
      ...siteRows.filter((sitePref) => sitePref.site_id === UNASSIGNED_BINS_SITE_ID),
    ];

    console.log('siteRows after onRowDragEnd', newSiteRows);
    const result = await saveOperationsViewRowVisibilityPrefs({
      account_id,
      pref_json: JSON.stringify({ sites: newSiteRows }),
    });
    console.log('result saved for saveOperationsViewRowVisibilityPrefs in onRowDragEnd', {
      result,
    });
  };

  const rowStyle = { background: 'white' };
  const getRowStyle = (params) => {
    const isSiteNode =
      params.node && params.node.data && params.node.data.siteHierarchy.length === 1;
    return { background: isSiteNode ? light_gray_10 : 'white' };
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: isMobileScreen ? 'calc(100vh - 136px)' : 'calc(100vh - 210px)',
        flexDirection: 'column',
        marginLeft: 20,
        marginRight: 20,
        width: '100%',
      }}
    >
      <div
        style={{
          height: isMobileScreen ? 'calc(100vh - 136px)' : 'calc(100vh - 210px)',
          width: '100%',
        }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          getRowId={(params) => params.data.id}
          className={classes.conatiner}
          rowHeight={58}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressDragLeaveHidesColumns={true}
          autoGroupColumnDef={autoGroupColumnDef}
          rowDragManaged={true}
          treeData={true}
          getDataPath={getDataPath}
          groupDefaultExpanded={-1}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
          suppressCellSelection={true}
          suppressContextMenu={true}
          groupSelectsChildren={true}
          groupSelectsFiltered={true}
          onGridReady={onGridReady}
          onColumnMoved={onColumnMoved}
          onRowDragMove={onRowDragMove}
          onRowDragEnd={onRowDragEnd}
          onCellEditingStopped={(event: any) => {
            setIsCellEdting(false);
            onCellEditingStopped({
              event,
              renameGrainSite,
              renameGrainBin,
              createGrainSite,
              setRowData,
              binsVisibilityPrefs,
              updateOperationsViewRowVisibilityPrefs,
            });
          }}
          onCellEditingStarted={onCellEditingStarted}
          enableGroupEdit={true}
          suppressClickEdit={true}
          suppressRowHoverHighlight={true}
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          // suppressMoveWhenRowDragging={true}
          // stopEditingWhenCellsLoseFocus={true}
        />
      </div>
    </div>
  );
};

export { ManageSitesTable };
