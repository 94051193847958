import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetOpsViewColsVisibilityPrefsQuery as Result,
  OpsViewVisibilityPrefsFragmentFragment,
  withGetOpsViewColsVisibilityPrefs as HOC,
} from '../__generated';

export type WithGetOpsViewColsVisibilityPrefsHocOwnProps = {
  user_id: number;
  account_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetOpsViewColsVisibilityPrefsHocChildProps = {
  loading: boolean;
  operations_view_cols_visibility_prefs: OpsViewVisibilityPrefsFragmentFragment | null;
  refetch: () => Promise<any>;
};
export const withGetOpsViewColsVisibilityPrefsHoc: AmberHoc<
  WithGetOpsViewColsVisibilityPrefsHocOwnProps,
  WithGetOpsViewColsVisibilityPrefsHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetOpsViewColsVisibilityPrefsHocOwnProps,
      WithGetOpsViewColsVisibilityPrefsHocChildProps,
      typeof component
    >,
    WithGetOpsViewColsVisibilityPrefsHocChildProps
  >({
    options: ({ user_id, account_id, onError }) => ({
      onError,
      variables: { user_id, account_id },
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          operations_view_cols_visibility_prefs: null,
          refetch: () => Promise.resolve(),
        };
      }
      return {
        loading: loading || false,
        operations_view_cols_visibility_prefs: data.operations_view_cols_visibility_prefs as OpsViewVisibilityPrefsFragmentFragment | null,
        refetch: () => data.refetch(),
      };
    },
  })(component as any);
