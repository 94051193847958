import { datadogLogs } from '@datadog/browser-logs';
import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { createTransformerLink } from 'apollo-client-transform';
import { Transformers } from 'apollo-client-transform/dist/defs';
import { split } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { getQueryParameter, USER_UNITS_PREF_KEY } from '../../../../core/src/util';
import {
  DistanceUnitType,
  TempUnitType,
  TestWeightUnitType,
  UserUnitsPrefFragmentFragment,
} from './__generated';
import introspectionQueryResultData from './__introspectionQueryResultData';
import { VariablesLink } from './localeConversions';
import {
  CelsiusTransformer,
  DateTransformer,
  hasUserSelectedKgUnit,
  InchesToCentimetersTransformer,
  KgPerHectoliterTransformer,
  MassTransformer,
  MetersTransformer,
  MetricTonTransformer,
} from './transformers';

function hasUserSelectedMeterUnit() {
  const userUnitsPrefVal = localStorage.getItem(USER_UNITS_PREF_KEY);
  const localeUnitSystem: UserUnitsPrefFragmentFragment | null = userUnitsPrefVal
    ? JSON.parse(userUnitsPrefVal)
    : null;
  const hasUserOptedMeterUnit = localeUnitSystem
    ? localeUnitSystem.distance_unit === DistanceUnitType.Meter
    : false;
  console.log('hasUserOptedMeterUnit', hasUserOptedMeterUnit);
  return hasUserOptedMeterUnit;
}

console.log('hasUserSelectedMeterUnit()', hasUserSelectedMeterUnit());

function hasUserSelectedCelsiusUnit() {
  const userUnitsPrefVal = localStorage.getItem(USER_UNITS_PREF_KEY);
  const localeUnitSystem: UserUnitsPrefFragmentFragment | null = userUnitsPrefVal
    ? JSON.parse(userUnitsPrefVal)
    : null;
  return localeUnitSystem && localeUnitSystem.temp_unit === TempUnitType.Celsius;
}

function hasUserSelectedKgPerHectoliterUnit() {
  const userUnitsPrefVal = localStorage.getItem(USER_UNITS_PREF_KEY);
  const localeUnitSystem: UserUnitsPrefFragmentFragment | null = userUnitsPrefVal
    ? JSON.parse(userUnitsPrefVal)
    : null;
  return (
    localeUnitSystem &&
    localeUnitSystem.test_weight_unit === TestWeightUnitType.KilogramPerHectoliter
  );
}

const transformers: Transformers = {
  Account: {
    updated_at: DateTransformer,
  },
  AerationWindow: {
    start: DateTransformer,
    end: DateTransformer,
  },
  BargeCoverPelletLink: {
    start_epoch: DateTransformer,
    end_epoch: DateTransformer,
  },
  BinEvent: {
    epoch_time: DateTransformer,
  },
  FanController: {
    updated_at: DateTransformer,
  },
  FanControllerResetRequest: {
    request_epoch: DateTransformer,
  },
  FanControllerPingRequest: {
    request_epoch: DateTransformer,
  },
  FanControllerSetConfigRequest: {
    request_epoch: DateTransformer,
  },
  FanControllerResetResponse: {
    response_epoch: DateTransformer,
  },
  FanControllerHeartbeatResponse: {
    request_epoch: DateTransformer,
    response_epoch: DateTransformer,
  },
  FanControllerGetConfigResponse: {
    response_epoch: DateTransformer,
  },
  FanControllerDebugResponse: {
    response_epoch: DateTransformer,
  },
  FanControllerRequest: {
    request_epoch: DateTransformer,
    updated_at: DateTransformer,
  },
  FanControllerRunWindow: {
    start_epoch: DateTransformer,
    end_epoch: DateTransformer,
  },
  FanControllerState: {
    config_epoch: DateTransformer,
    response_epoch: DateTransformer,
  },
  FanControllerStatus: {
    updated_at: DateTransformer,
  },
  ...(hasUserSelectedCelsiusUnit() && {
    ForecastDayV2: {
      temp_high_f: CelsiusTransformer,
      temp_low_f: CelsiusTransformer,
    },
  }),
  GrainBinFanController: {
    start_date: DateTransformer,
    end_date: DateTransformer,
    updated_at: DateTransformer,
  },
  GrainBinFanControllerStatus: {
    updated_at: DateTransformer,
  },
  GrainBinFanSettings: {
    updated_at: DateTransformer,
  },
  GrainBinTelemetry: {
    as_of: DateTransformer,
  },
  GrainBinStoragePeriod: {
    start_epoch: DateTransformer,
    end_epoch: DateTransformer,
    created_at: DateTransformer,
  },
  GrainBinStoragePeriodWithOngoingStorageFlag: {
    start_epoch: DateTransformer,
    end_epoch: DateTransformer,
    created_at: DateTransformer,
  },
  GrainEnvPoint: {
    epoch_time: DateTransformer,
    ...(hasUserSelectedCelsiusUnit() && { temp_f: CelsiusTransformer }),
  },
  GrainEnvPointV2: {
    epoch_time: DateTransformer,
    ...(hasUserSelectedCelsiusUnit() && { temp_f: CelsiusTransformer }),
  },
  WeatherHistoryPoint: {
    epoch_time: DateTransformer,
    ...(hasUserSelectedCelsiusUnit() && { temp_f: CelsiusTransformer }),
  },
  GrainStatus: {
    epoch_time: DateTransformer,
    ...(hasUserSelectedCelsiusUnit() && { temp_f: CelsiusTransformer }),
  },
  GrainStatusV2: {
    epoch_time: DateTransformer,
    ...(hasUserSelectedCelsiusUnit() && {
      temp_f: CelsiusTransformer,
    }),
  },
  GrainTelemetry: {
    as_of: DateTransformer,
  },
  GrainTelemetryHistory: {
    start_epoch: DateTransformer,
    end_epoch: DateTransformer,
  },
  GrainBin: {
    fan_guidance_end_date: DateTransformer,
    fan_guidance_start_date: DateTransformer,
    ...(hasUserSelectedMeterUnit() && {
      height_ft: MetersTransformer,
      diameter_ft: MetersTransformer,
    }),
    ...(hasUserSelectedCelsiusUnit() && {
      current_grain_temp: CelsiusTransformer,
    }),
  },
  ...(hasUserSelectedMeterUnit() && {
    GrainBinFanModelOutput: {
      diameter: InchesToCentimetersTransformer,
    },
    FanModelResultVal: {
      diameter: InchesToCentimetersTransformer,
    },
  }),
  GrainBinTicket: {
    created_at: DateTransformer,
    epoch_time: DateTransformer,
    updated_at: DateTransformer,
    ...(hasUserSelectedKgUnit() && {
      weight_in_lbs: MassTransformer,
    }),
    ...(hasUserSelectedKgPerHectoliterUnit() && {
      test_weight_in_lbs: KgPerHectoliterTransformer,
    }),
  },
  SensorGeneratedGrainTicket: {
    created_at: DateTransformer,
    epoch_time: DateTransformer,
    updated_at: DateTransformer,
    ...(hasUserSelectedKgUnit() && {
      weight_in_lbs: MassTransformer,
    }),
    ...(hasUserSelectedKgPerHectoliterUnit() && {
      test_weight_in_lbs: KgPerHectoliterTransformer,
    }),
  },
  ...(hasUserSelectedCelsiusUnit() && {
    GrainContainerAerationRun: {
      avg_temp_f: CelsiusTransformer,
      start_temp_f: CelsiusTransformer,
      end_temp_f: CelsiusTransformer,
      current_grain_temp: CelsiusTransformer,
    },
  }),
  ...(hasUserSelectedCelsiusUnit() && {
    GrainTemporaryInputConditions: {
      current_grain_temp: CelsiusTransformer,
    },
  }),
  ...(hasUserSelectedKgUnit() && {
    GrainBinLevelSample: {
      bushel: MetricTonTransformer,
    },
    GrainBinLevelHistory: { max_bushels: MetricTonTransformer },
    GrainBinTicketLevelSample: { bushel: MetricTonTransformer },
  }),
  HubContainerLink: {
    start_epoch: DateTransformer,
    end_epoch: DateTransformer,
    ...(hasUserSelectedMeterUnit() && {
      hub_offset_ft: MetersTransformer,
    }),
  },
  HubEventHistoryEdge: {
    start: DateTransformer,
    end: DateTransformer,
  },
  HubEventsEdge: {
    as_of: DateTransformer,
  },
  HubGpsEvent: {
    epoch_time: DateTransformer,
    read_time: DateTransformer,
    updated_at: DateTransformer,
  },
  HubHeadspaceEvent: {
    epoch_time: DateTransformer,
  },
  HubLocation: {
    epoch_time: DateTransformer,
    read_time: DateTransformer,
    updated_at: DateTransformer,
  },
  HubPelletEvent: {
    epoch_time: DateTransformer,
  },
  HubSignalEvent: {
    epoch_time: DateTransformer,
  },
  HubStatusEvent: {
    epoch_time: DateTransformer,
    updated_at: DateTransformer,
  },
  HubWeather: {
    epoch_time: DateTransformer,
  },
  HubWeatherForecastHourly: {
    epoch_time: DateTransformer,
  },
  ParticleDevice: {
    last_heard: DateTransformer,
  },
  Pellet: {
    created_at: DateTransformer,
  },
  PelletGroup: {
    created_at: DateTransformer,
  },
  PelletGroupPelletLink: {
    reassigned_at: DateTransformer,
    created_at: DateTransformer,
  },
  PelletImage: {
    created_at: DateTransformer,
    updated_at: DateTransformer,
  },
  PelletRef: {
    created_at: DateTransformer,
  },
  WeatherData: {
    epoch_time: DateTransformer,
  },
  RunWindow: {
    start_epoch: DateTransformer,
    end_epoch: DateTransformer,
  },
  CO2AlertInfo: {
    co2_alert_sent_on: DateTransformer,
  },
  ...(hasUserSelectedCelsiusUnit() && {
    TempMinMax: {
      temp_low_f: CelsiusTransformer,
      temp_high_f: CelsiusTransformer,
    },
  }),
};

export const createLinks = (host: string) => {
  const transformerLink = createTransformerLink(transformers);

  const auth_link = setContext((_, { headers }) => {
    const hasQueryParamsToken = window.location.href.includes('?token=');
    const customerCareToken = localStorage.getItem('customer_care_token');
    const userToken = customerCareToken ? customerCareToken : localStorage.getItem('token');
    const token = hasQueryParamsToken ? getQueryParameter('token') : userToken;
    return token
      ? {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        }
      : headers;
  });

  let result = transformerLink.concat(auth_link);

  const error_link = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        datadogLogs.logger.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }
    if (networkError) {
      const err = <any>networkError;
      if (err.result && err.result.name) {
        console.error(`[Network server error]: ${err.result}`);
        datadogLogs.logger.error(`[Network server error]: ${err.result}`);
      }
      console.error(`[Network error]: ${networkError}`);
      datadogLogs.logger.error(`[Network error]: ${networkError}`);
    }
    (<any>window).last_error = {
      graphQLErrors,
      networkError,
      operation,
    };
  });
  result = result.concat(error_link);

  // variables link to manipulate operation variables
  const variables_link = new VariablesLink();
  result = result.concat(variables_link);

  // link to use if batching (default)
  const batchHttpLink = new BatchHttpLink({ uri: `${host}/graphql`, batchMax: 20 });
  // link to use if not batching
  const httpLink = new HttpLink({ uri: `${host}/graphql` });

  const http_link = split(
    (operation) => operation.getContext().important === true,
    httpLink, // if the test is true -- debatch
    batchHttpLink // otherwise, batching is fine
  );

  return result.concat(http_link);
};

export const createCache = () =>
  new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    }),
    dataIdFromObject: (obj: any) => {
      // eslint-disable-next-line
      const type_name = obj.__typename;
      switch (type_name) {
        case 'Account':
          return `${type_name}:${obj.account_id}`;
        case 'Barge':
        case 'BargeAccountLink':
          return `${type_name}:${obj.barge_id}`;
        case 'BargeCoverPelletLink':
          return `${type_name}:${obj.barge_cover_pellet_link_id}`;
        case 'FanController':
          return `${type_name}:${obj.fan_controller_id_next}`;
        case 'FanControllerPingRequest':
        case 'FanControllerResetRequest':
        case 'FanControllerSetConfigRequest':
          return `${type_name}:${obj.fan_controller_id}|${new Date(
            obj.request_epoch
          ).toISOString()}`;
        case 'FanControllerResetResponse':
        case 'FanControllerHeartbeatResponse':
        case 'FanControllerGetConfigResponse':
        case 'FanControllerDebugResponse':
          return `${type_name}:${obj.fan_controller_id}|${new Date(
            obj.response_epoch
          ).toISOString()}`;
        case 'FanControllerState':
          return `${type_name}:${obj.fan_controller_id}`;
        case 'FanControllerState2':
          return `${type_name}:${obj.core_id}`;
        case 'GrainBin':
        case 'GrainBinFanSettings':
        case 'GrainBinAccountLink':
          return `${type_name}:${obj.grain_bin_id}`;
        case 'GrainBinStoragePeriod':
          return `${type_name}:${obj.grain_bin_storage_cycle_id}`;
        case 'GrainBinStoragePeriodWithOngoingStorageFlag':
          return `${type_name}:${obj.grain_bin_storage_cycle_id}`;
        case 'GrainBinFanController':
          return `${type_name}:${obj.grain_bin_fan_controller_id}`;
        case 'Hub':
          return `${type_name}:${obj.hub_id}`;
        case 'HubContainerLink':
          return `${type_name}:${obj.hub_container_link_id}`;
        case 'PelletCable':
        case 'PelletGroup':
          return `${type_name}:${obj.pellet_group_id}`;
        case 'PelletGroupPelletLink':
          return `${type_name}:${obj.pellet_group_pellet_link_id}`;
        case 'Pile':
        case 'PileAccountLink':
          return `${type_name}:${obj.pile_id}`;
        case 'PileCableLink':
          return `${type_name}:${obj.pile_cable_link_id}`;
        case 'User':
          return `${type_name}:${obj.user_id}`;
        default:
          return defaultDataIdFromObject(obj);
      }
    },
  });
