import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { GetGrainBinSitesQuery as Result, withGetGrainBinSites as HOC } from '../__generated';

export type WithGetGrainBinSitesHocOwnProps = {
  account_id: number;
  user_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainBinSitesHocChildProps = {
  grain_sites_loading: boolean;
  grain_bin_sites: Result['grain_bin_sites'];
  grainSitesRefetch: () => Promise<any>;
};
export const withGetGrainBinSitesHoc: AmberHoc<
  WithGetGrainBinSitesHocOwnProps,
  WithGetGrainBinSitesHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainBinSitesHocOwnProps,
      WithGetGrainBinSitesHocChildProps,
      typeof component
    >,
    WithGetGrainBinSitesHocChildProps
  >({
    options: ({ user_id, account_id, onError }) => ({
      onError,
      variables: { user_id, account_id },
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          grain_sites_loading: true,
          grain_bin_sites: [] as Result['grain_bin_sites'],
          grainSitesRefetch: () => Promise.resolve(),
        };
      }
      return {
        grain_sites_loading: loading || false,
        grain_bin_sites: (data.grain_bin_sites as Result['grain_bin_sites']) || [],
        grainSitesRefetch: () => data.refetch(),
      };
    },
  })(component as any);
