import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { endSession, pushNav } from '../action';
import { Button } from './util';

const reload = () => window.location.reload();

const useStyles = makeStyles({
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
  },
  centered: { textAlign: 'center' },
});
type ErrorLayoutBaseProps = {
  message?: string;
  disable_reload?: boolean;
  disable_logout?: boolean;
  ok_path?: string;
};
export const ErrorLayout = ({
  message = 'Uh Oh, An Error Has Occurred!',
  disable_reload,
  ok_path,
  disable_logout,
}: ErrorLayoutBaseProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onClickLogout = useCallback(() => {
    if (!disable_logout) {
      dispatch(endSession());
    }
  }, []);
  const onClickOk = useCallback(() => {
    if (ok_path) {
      dispatch(pushNav({ path: ok_path }));
    }
  }, [ok_path]);
  if (!disable_logout) {
  }
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        alignItems="center"
        justify="center"
        spacing={2}
        className={classes.centered}
      >
        <Grid item xs={12}>
          <Typography variant="h5">
            {message}
            <br />
            Please logout and log back in.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {ok_path && <Button onClick={onClickOk}>OK</Button>}
          {!disable_logout && <Button onClick={onClickLogout}>LOGOUT</Button>}
          {/* {!disable_reload && <Button onClick={reload}>RELOAD</Button>} */}
        </Grid>
      </Grid>
    </div>
  );
};
