import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { UserFragmentFragment as Result, withGetAccountUsers as HOC } from '../__generated';

export type WithGetAccountUsersHocOwnProps = {
  account_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetAccountUsersHocChildProps = {
  loading: boolean;
  users: Result[];
  refetchUsers: () => Promise<any>;
};

export const withGetAccountUsersHoc: AmberHoc<
  WithGetAccountUsersHocOwnProps,
  WithGetAccountUsersHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetAccountUsersHocOwnProps,
      WithGetAccountUsersHocChildProps,
      typeof component
    >,
    WithGetAccountUsersHocChildProps
  >({
    options: ({ onError, account_id }) => ({
      onError,
      variables: { account_id },
      errorPolicy: onError ? 'all' : 'none',
      notifyOnNetworkStatusChange: true,
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          refetchUsers: () => Promise.resolve(),
          loading: true,
          users: [],
        };
      }
      return {
        refetchUsers: () => data.refetch(),
        loading: loading || false,
        users: data.account ? data.account.users : [],
      };
    },
  })(component as any);
