import React, { ReactNode } from 'react';

import { withGetIsOtpValidHoc, WithGetIsOtpValidHocChildProps } from '../../api/graphql/hoc';
import { ErrorLayout } from '../ErrorLayout';

type OtpWrapperProps = WithGetIsOtpValidHocChildProps & { children: ReactNode };

const OtpWrapperBase = ({ loading, is_otp_valid, children }: OtpWrapperProps) => {
  if (loading) {
    return null;
  }
  return is_otp_valid ? (
    <>{children}</>
  ) : (
    <ErrorLayout disable_logout disable_reload ok_path="/" message="This link is no longer valid" />
  );
};

export const OtpWrapper = withGetIsOtpValidHoc(OtpWrapperBase);
