import * as React from 'react';
export const ExpandIcon = (props) => (
  <svg
    id="ExpandIcon"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={17.364}
    height={17.364}
    viewBox="0 0 17.364 17.364"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_2426"
          data-name="Rectangle 2426"
          width={17.364}
          height={17.364}
          fill="#333"
        />
      </clipPath>
    </defs>
    <g id="Group_127" data-name="Group 127" clipPath="url(#clip-path)">
      <path
        id="Path_424"
        data-name="Path 424"
        d="M7.562,256.9a.868.868,0,0,0-1.224,0l-4.6,4.6v-3.985a.868.868,0,1,0-1.736,0v6.077a.868.868,0,0,0,.868.868H6.945a.868.868,0,1,0,0-1.736H2.96l4.593-4.593a.868.868,0,0,0,.009-1.233Z"
        transform="translate(0 -247.097)"
        fill="#333"
      />
      <path
        id="Path_425"
        data-name="Path 425"
        d="M262.271,0h-6.077a.868.868,0,1,0,0,1.736h3.985l-4.6,4.593A.868.868,0,1,0,256.8,7.553l4.6-4.593V6.946a.868.868,0,1,0,1.736,0V.868A.868.868,0,0,0,262.271,0Z"
        transform="translate(-245.775)"
        fill="#333"
      />
    </g>
  </svg>
);
