import { Button, Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Cancel } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useGrainBinData } from '../../../../../core/src/contexts';
import { CancelIcon, RedWarningIcon } from '../../../../../core/src/media';
import {
  amber_error_red,
  amber_red,
  black_shade_2,
  light_gray_3,
  light_gray_7,
} from '../../../../../core/src/style';

import EditIcon from '@material-ui/icons/Edit';
import { useSelector } from 'react-redux';
import {
  FanControllerRunWindow,
  FanHeartbeatErrorCode,
  FanRunWindowRecommendedOption,
  GrainContainerAerationRunFragmentFragment,
  withSetFanControllerStateHoc,
  WithSetFanControllerStateHocChildProps,
} from '../../../api';
import { FanControllerConfigReason, renderModeColor } from '../../../util';
import ConfirmationModal from '../../util/ConfirmationModal';
import {
  createNavigationTypeFromNavOption,
  FanControlNavigationOption,
  FanControlsNavigation,
} from '../FanControlNavigationOption';
import { AerationWindow } from './AerationWindow';
import { CancelScheduleDialog } from './CancelScheduleDialog';
import { getRecommedationTypeMode } from './FanSchedule';
import { GrainWeatherInputsCard } from './GrainWeatherInputsCard';
import { UpcomingFanScheduleTable } from './UpcomingFanScheduleTable';

const formatTime = (value) => value.toFormat('h:mm a');

const useStyles = makeStyles((theme: Theme) => ({
  centered_column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: 20,
  },
  top_margin: { margin: 10, marginTop: 25 },
  left_margin: { marginLeft: 10 },
  alertText: {
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 650,
    fontSize: 16,
  },
  errors: {
    border: `2px solid ${amber_error_red}`,
    padding: 10,
    borderRadius: 4,
    [theme.breakpoints.up('sm')]: {
      width: '66%',
    },
  },
  clearErrorBtn: {
    width: 196,
    fontFamily: 'Work Sans,sans-serif',
    boxShadow: '0px 0px 4px #00000029',
    border: '1px solid #999999',
    backgroundColor: light_gray_3,
    fontSize: 16,
    textTransform: 'uppercase',
    textAlign: 'center',
    color: 'black',
    lineHeight: 1.2,
  },
  grainWeatherInputsCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  centerDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    fontFamily: 'Work Sans,sans-serif',
    height: 38,
    borderRadius: 5,
    boxShadow: '0px 0px 4px #00000033',
    [theme.breakpoints.down('xs')]: {
      marginTop: 2,
    },
    margin: 10,
    color: black_shade_2,
    border: '1px solid #999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonTextContainer: {
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    height: 20,
  },
  rightMargin: {
    marginRight: 10,
  },
  divider: { backgroundColor: light_gray_7, height: 1, width: '100%', marginTop: 5 },
}));

export type QualifyingFanHeartbeatErrorCode =
  | FanHeartbeatErrorCode.StartFail
  | FanHeartbeatErrorCode.StopFail
  | FanHeartbeatErrorCode.Vibration;

export const RunWindowFanErrorMessage = {
  [FanHeartbeatErrorCode.StartFail]: 'Fan Failed to Start',
  [FanHeartbeatErrorCode.StopFail]: 'Fan Failed to Stop',
  [FanHeartbeatErrorCode.Vibration]: 'Fan Interrupted by Excessive Vibration',
};

export const isQualifyingFanHeartbeatErrorCode = (
  code: FanHeartbeatErrorCode | null | undefined
): boolean => {
  return Boolean(
    code &&
      (code === FanHeartbeatErrorCode.StartFail ||
        code === FanHeartbeatErrorCode.StopFail ||
        code === FanHeartbeatErrorCode.Vibration)
  );
};

export type FanControllerWithError = {
  alias: string;
  core_id: string;
  error_code: QualifyingFanHeartbeatErrorCode;
};

export const getErrorCodeString = (
  error_code: QualifyingFanHeartbeatErrorCode,
  fan_name: string
): string => {
  switch (error_code) {
    case FanHeartbeatErrorCode.StartFail: {
      return `${fan_name} failed to start. Check fan before clearing errors & restarting.`;
    }
    case FanHeartbeatErrorCode.StopFail: {
      return `${fan_name} failed to stop. Check fan before clearing errors & restarting.`;
    }
    case FanHeartbeatErrorCode.Vibration: {
      return `${fan_name} interrupted by excessive fan vibration. Check fan before restarting.`;
    }
    default:
      return '';
  }
};

const BaseFanControllerErrorDisplay = ({
  fan_controllers_with_errors,
  setFanControllerState,
  fan_count,
  stopFans,
  any_running,
  stop_sent,
  cancelNextRun,
  scheduled_start,
  scheduled_end,
  grain_bin_id,
  aeration_schedule,
  aeration_schedule_type,
  setNavigation,
  navigation,
  opt_in_fan_guidance,
  fan_guidance_start_date,
  fan_guidance_end_date,
  enable_fan_guidance,
  recommendation_type,
  show_fan_guidance_ext_prompt,
  setShowFanGuidanceExtPrompt,
  hasUserReadOnlyAccess,
  hasManualMode,
  onClickEdit,
  cancelAllSchedule,
}: {
  fan_controllers_with_errors: FanControllerWithError[];
  fan_count: number;
  stopFans: () => void;
  any_running: boolean;
  stop_sent: Date | null;
  cancelNextRun: () => void;
  scheduled_start: DateTime | null;
  scheduled_end: DateTime | null;
  grain_bin_id: number;
  aeration_schedule: FanControllerRunWindow[] | null;
  aeration_schedule_type: FanRunWindowRecommendedOption | null;
  setNavigation: Dispatch<SetStateAction<FanControlNavigationOption>>;
  navigation: FanControlNavigationOption;
  opt_in_fan_guidance: boolean;
  fan_guidance_end_date: Date | null;
  fan_guidance_start_date: Date | null;
  enable_fan_guidance: boolean;
  recommendation_type: string | null;
  show_fan_guidance_ext_prompt: boolean;
  setShowFanGuidanceExtPrompt: (show_fan_guidance_ext) => void;
  hasUserReadOnlyAccess: boolean;
  hasManualMode: boolean;
  onClickEdit: () => void;
  cancelAllSchedule: () => void;
} & WithSetFanControllerStateHocChildProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const onClickCancel = () => setShowCancelDialog(true);
  const closeCanceltDialog = () => setShowCancelDialog(false);
  const isNotSmallMobile = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const { current_grain_temp, current_grain_emc, target_grain_emc } = useGrainBinData();
  const lastAerationRun: GrainContainerAerationRunFragmentFragment | null = useSelector(
    ({ global_state: { last_aeration_run } }) => last_aeration_run
  );
  const lastAerationRunMode =
    lastAerationRun && lastAerationRun.fan_runs && lastAerationRun.fan_runs[0]
      ? lastAerationRun.fan_runs[0].start_recommendation_type
      : null;
  const modeType = hasManualMode
    ? FanRunWindowRecommendedOption.Manual
    : aeration_schedule_type || (any_running ? lastAerationRunMode : null);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const showFanStopCommandSentMsg = Boolean(
    any_running && stop_sent && new Date().getTime() - new Date(stop_sent).getTime() < 30 * 1000
  );
  const fanStopCommandSentMsg =
    stop_sent && showFanStopCommandSentMsg
      ? `Please wait, Stop command sent
  ${formatTime(DateTime.fromMillis(new Date(stop_sent).getTime()))}`
      : '';

  const resetErrors = useCallback(
    (core_id) => {
      setFanControllerState({
        core_id,
        config_reason: Number(FanControllerConfigReason.USER),
        enabled: true,
        error_code: FanHeartbeatErrorCode.NoError,
      });
    },
    [setFanControllerState]
  );

  const has_valid_schedule =
    scheduled_start && scheduled_end && scheduled_end.getTime() > new Date().getTime();
  const getClearErrorBtnText = (error_code) => {
    return error_code === FanHeartbeatErrorCode.StopFail
      ? 'Clear Error'
      : aeration_schedule &&
        aeration_schedule.length > 0 &&
        scheduled_start &&
        new Date(scheduled_start).getTime() < new Date().getTime()
      ? `Clear Error and Restart Fan`
      : 'Clear Error';
  };

  // showErrorMsg logic
  const hasFanErrors = fan_controllers_with_errors.length > 0;
  const hasFanStopErrors = Boolean(
    fan_controllers_with_errors.find(
      ({ error_code }) => error_code === FanHeartbeatErrorCode.StopFail
    )
  );

  const now = new Date();
  const hasErrorWithinFirstRunWindow = Boolean(
    aeration_schedule &&
      aeration_schedule.length > 0 &&
      aeration_schedule[0].start_epoch &&
      now.getTime() >= new Date(aeration_schedule[0].start_epoch).getTime() &&
      aeration_schedule[0].end_epoch &&
      now.getTime() < new Date(aeration_schedule[0].end_epoch).getTime()
  );

  const hasFanStopErrorCondition =
    Boolean(
      aeration_schedule &&
        aeration_schedule.length > 0 &&
        aeration_schedule[0].start_epoch &&
        now.getTime() < new Date(aeration_schedule[0].start_epoch).getTime()
    ) || Boolean(aeration_schedule && aeration_schedule.length === 0);

  const showErrorMsgAboveSchedule =
    any_running && hasFanErrors && hasFanStopErrors && hasFanStopErrorCondition;
  const showErrorMsgInFirstRunWindow =
    hasFanErrors &&
    !any_running &&
    Boolean(
      fan_controllers_with_errors.find(
        ({ error_code }) => error_code !== FanHeartbeatErrorCode.StopFail
      )
    ) &&
    hasErrorWithinFirstRunWindow;

  // error message
  const fanStopFailErrors = fan_controllers_with_errors.filter(
    ({ error_code }) => error_code === FanHeartbeatErrorCode.StopFail
  );

  const fanStopErrorMsgs =
    showErrorMsgAboveSchedule && fanStopFailErrors.length > 0
      ? fanStopFailErrors.length === 1
        ? 'Fan Failed to Stop'
        : 'Fans Failed to Stop'
      : undefined;

  let fanErrorMsgsWithoutStopFail = showErrorMsgInFirstRunWindow
    ? fan_controllers_with_errors
        .filter(({ error_code }) => error_code !== FanHeartbeatErrorCode.StopFail)
        .map(({ error_code }) => RunWindowFanErrorMessage[error_code])
    : [];
  // merge same errors
  fanErrorMsgsWithoutStopFail =
    fanErrorMsgsWithoutStopFail.length > 1
      ? fanErrorMsgsWithoutStopFail.every((msg) => msg === fanErrorMsgsWithoutStopFail[0])
        ? [fanErrorMsgsWithoutStopFail[0].replace('Fan', 'Fans')]
        : ['Fans Failed to Start']
      : fanErrorMsgsWithoutStopFail;

  const hasSingleRunWindow = aeration_schedule && aeration_schedule.length === 1;
  const has_current_overlap_runwindow = false;
  const cancelWindowDirectly = (opt_in_fan_guidance && enable_fan_guidance) || hasSingleRunWindow;

  return (
    <div className={classes.centered_column}>
      <div className={classes.grainWeatherInputsCard}>
        <GrainWeatherInputsCard
          current_grain_temp={
            current_grain_temp === null || current_grain_temp === undefined
              ? null
              : current_grain_temp
          }
          current_grain_emc={current_grain_emc || null}
          target_grain_emc={target_grain_emc || null}
          mode={getRecommedationTypeMode(modeType)}
          modeColor={renderModeColor(modeType)}
        />
      </div>
      <div className={classes.errors} style={{ marginBottom: 20 }}>
        {fan_controllers_with_errors.map(({ error_code, alias, core_id }, ix) => (
          <div key={core_id}>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <div style={{ marginRight: 5 }}>
                <RedWarningIcon
                  style={{
                    width: 26,
                    height: 26,
                    fill: amber_red,
                  }}
                />
              </div>
              <p style={{ textAlign: 'start', fontSize: 16, color: amber_error_red, margin: 0 }}>
                {fan_controllers_with_errors.length !== 1 && `Error ${ix + 1}: `}
                {getErrorCodeString(error_code, alias)}
              </p>
            </div>
            <Button
              variant="contained"
              className={classes.clearErrorBtn}
              style={{ backgroundColor: light_gray_3, padding: 0 }}
              onClick={() => resetErrors(core_id)}
            >
              <div className={classes.centerDiv} style={{ padding: '6px 10px' }}>
                {fan_controllers_with_errors.length === 1
                  ? getClearErrorBtnText(error_code)
                  : `${getClearErrorBtnText(error_code)} ${ix + 1}`}
              </div>
            </Button>
            {ix !== fan_controllers_with_errors.length - 1 && (
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            )}
          </div>
        ))}
      </div>

      {any_running && (
        <>
          <Grid container style={{ justifyContent: 'center' }}>
            <Button
              color="default"
              variant="outlined"
              className={classes.button}
              onClick={onClickEdit}
              disabled={hasUserReadOnlyAccess}
              style={{
                width: 145,
                height: 38,
                marginLeft: 0,
                padding: 0,
                marginRight: isNotSmallMobile ? 35 : 15,
              }}
            >
              <div className={classes.centerDiv}>
                <EditIcon className={classes.rightMargin} />
                <div className={classes.buttonTextContainer}>Edit</div>
              </div>
            </Button>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                opt_in_fan_guidance && enable_fan_guidance
                  ? setShowConfirmationModal(true)
                  : stopFans();
              }}
              style={{
                width: 145,
                height: 38,
                marginLeft: 0,
                marginRight: 0,
                padding: 0,
              }}
            >
              <div className={classes.centerDiv}>
                <CancelIcon className={classes.rightMargin} />
                <div className={classes.buttonTextContainer}>STOP</div>
              </div>
            </Button>
          </Grid>
          {showFanStopCommandSentMsg && fanStopCommandSentMsg && (
            <div className={classes.alertText}>{fanStopCommandSentMsg}</div>
          )}
          <div className={classes.divider} style={{ marginTop: 26, marginBottom: 15 }} />
        </>
      )}

      {!any_running && has_valid_schedule && (
        <>
          <AerationWindow
            fan_schedules={aeration_schedule}
            cancel_button_text={'CANCEL'}
            onClickCancel={cancelWindowDirectly ? cancelNextRun : onClickCancel}
            start={scheduled_start as Date}
            end={scheduled_end as Date}
            grain_bin_id={grain_bin_id}
            show_restart_button={false}
            show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
            setShowFanGuidanceExtPrompt={setShowFanGuidanceExtPrompt}
            hasUserReadOnlyAccess={hasUserReadOnlyAccess}
            hasManualMode={hasManualMode}
            showFanStopCommandSentMsg={showFanStopCommandSentMsg}
            fanStopCommandSentMsg={fanStopCommandSentMsg}
            showFanRestartCommandSentMsg={false}
            fanRestartCommandSentMsg={''}
            show_edit_sched_btn={true}
            onClickEdit={onClickEdit}
          />
        </>
      )}

      {aeration_schedule && aeration_schedule.length > 0 && (
        <UpcomingFanScheduleTable
          any_running={any_running}
          fan_schedules={aeration_schedule}
          aeration_schedule_type={aeration_schedule_type}
          loading={false}
          startSettingNavigation={() =>
            setNavigation(
              createNavigationTypeFromNavOption(
                FanControlsNavigation.ShowManualRunWindows,
                navigation
              )
            )
          }
          addRecommendedWindow={() => {
            setNavigation(
              createNavigationTypeFromNavOption(
                FanControlsNavigation.AskCurrentGrainConditions,
                navigation
              )
            );
          }}
          opt_in_fan_guidance={opt_in_fan_guidance}
          enable_fan_guidance={enable_fan_guidance}
          fan_guidance_end_date={fan_guidance_end_date}
          fan_guidance_start_date={fan_guidance_start_date}
          recommendation_type={recommendation_type}
          hasUserReadOnlyAccess={hasUserReadOnlyAccess}
          hasManualMode={hasManualMode}
          showErrorMsgAboveSchedule={showErrorMsgAboveSchedule}
          fanStopErrorMsgs={fanStopErrorMsgs}
          showErrorMsgInFirstRunWindow={showErrorMsgInFirstRunWindow}
          fanErrorMsgsWithoutStopFail={fanErrorMsgsWithoutStopFail}
          show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
        />
      )}

      {/* only for fan stop fail error with no schedule */}
      {showErrorMsgAboveSchedule &&
        any_running &&
        aeration_schedule &&
        aeration_schedule.length === 0 && (
          <UpcomingFanScheduleTable
            any_running={any_running}
            fan_schedules={aeration_schedule}
            aeration_schedule_type={aeration_schedule_type}
            loading={false}
            startSettingNavigation={() =>
              setNavigation(
                createNavigationTypeFromNavOption(
                  FanControlsNavigation.ShowManualRunWindows,
                  navigation
                )
              )
            }
            addRecommendedWindow={() => {
              setNavigation(
                createNavigationTypeFromNavOption(
                  FanControlsNavigation.AskCurrentGrainConditions,
                  navigation
                )
              );
            }}
            opt_in_fan_guidance={opt_in_fan_guidance}
            enable_fan_guidance={enable_fan_guidance}
            fan_guidance_end_date={fan_guidance_end_date}
            fan_guidance_start_date={fan_guidance_start_date}
            recommendation_type={recommendation_type}
            hasUserReadOnlyAccess={hasUserReadOnlyAccess}
            hasManualMode={hasManualMode}
            showErrorMsgAboveSchedule={showErrorMsgAboveSchedule}
            fanStopErrorMsgs={fanStopErrorMsgs}
            showErrorMsgInFirstRunWindow={showErrorMsgInFirstRunWindow}
            fanErrorMsgsWithoutStopFail={fanErrorMsgsWithoutStopFail}
            show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
          />
        )}

      <ConfirmationModal
        showModal={showConfirmationModal}
        confirmationMessage={
          <>
            Are you sure you want to <strong>STOP {fan_count === 1 ? 'FAN' : 'FANS'}</strong> and{' '}
            <strong>END AUTOMATION</strong>?
          </>
        }
        cancelBtnText="No"
        confirmBtbText="Yes"
        handleCancel={() => setShowConfirmationModal(false)}
        handleConfirm={() => {
          stopFans();
          setShowConfirmationModal(false);
        }}
      />

      <CancelScheduleDialog
        open={showCancelDialog}
        onClickClose={closeCanceltDialog}
        has_current_overlap_runwindow={has_current_overlap_runwindow}
        cancelNextRun={() => {
          cancelNextRun();
          closeCanceltDialog();
        }}
        cancelAllRuns={() => {
          cancelAllSchedule();
          closeCanceltDialog();
        }}
      />
    </div>
  );
};

export const FanControllerErrorDisplay = withSetFanControllerStateHoc(
  BaseFanControllerErrorDisplay
);
