import { AmberHoc, AmberHocProps } from '../../../util';
import {
  RequestUserVerificationMutationVariables as Variables,
  withRequestUserVerification as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithRequestUserVerificationHocChildProps = {
  requestUserVerification: (variables: Variables) => Promise<boolean>;
};
export const withRequestUserVerificationHoc: AmberHoc<
  {},
  WithRequestUserVerificationHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithRequestUserVerificationHocChildProps, typeof component>,
    WithRequestUserVerificationHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        requestUserVerification: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({ variables });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.requestUserVerification;
        },
      };
    },
  })(component as any);
