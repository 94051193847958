import { AmberHoc, AmberHocProps } from '../../../util';
import {
  SaveOperationsViewRowVisibilityPrefsMutation,
  SaveOperationsViewRowVisibilityPrefsMutationVariables as Variables,
  withSaveOperationsViewRowVisibilityPrefs as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithSaveOperationsViewRowVisibilityPrefsHocChildProps = {
  saveOperationsViewRowVisibilityPrefs: (
    variables: Variables
  ) => Promise<
    SaveOperationsViewRowVisibilityPrefsMutation['saveOperationsViewRowVisibilityPrefs']
  >;
};
export const withSaveOperationsViewRowVisibilityPrefsHoc: AmberHoc<
  {},
  WithSaveOperationsViewRowVisibilityPrefsHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithSaveOperationsViewRowVisibilityPrefsHocChildProps, typeof component>,
    WithSaveOperationsViewRowVisibilityPrefsHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        saveOperationsViewRowVisibilityPrefs: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: async (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { saveOperationsViewRowVisibilityPrefs: updated } = data;
              if (!updated) {
                return;
              }
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data || !data.saveOperationsViewRowVisibilityPrefs) {
            throw new Error('Unexpected server response');
          }
          return data.saveOperationsViewRowVisibilityPrefs;
        },
      };
    },
  })(component as any);
