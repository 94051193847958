import { AmberHoc, AmberHocProps } from '../../../util';
import {
  FanSettingsFragmentFragment,
  GetGrainBinFanSettingsDocument,
  GetGrainBinFanSettingsQuery,
  GetGrainBinFanSettingsQueryVariables,
  SetGrainBinFanSettingsMutationVariables as Variables,
  withSetGrainBinFanSettings as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';
export type WithSetGrainBinFanSettingsHocChildProps = {
  setGrainBinFanSettings: (variables: Variables) => Promise<FanSettingsFragmentFragment>;
};
export const withSetGrainBinFanSettingsHoc: AmberHoc<
  {},
  WithSetGrainBinFanSettingsHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithSetGrainBinFanSettingsHocChildProps, typeof component>,
    WithSetGrainBinFanSettingsHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        setGrainBinFanSettings: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { setGrainBinFanSettings: updated } = data;
              if (!updated) {
                return;
              }
              proxy.writeQuery<GetGrainBinFanSettingsQuery, GetGrainBinFanSettingsQueryVariables>({
                query: GetGrainBinFanSettingsDocument,
                data: { __typename: 'Query', grain_bin_fan_settings: updated },
                variables: { grain_bin_id: variables.grain_bin_id },
              });
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data || !data.setGrainBinFanSettings) {
            throw new Error('Unexpected server response');
          }
          return data.setGrainBinFanSettings;
        },
      };
    },
  })(component as any);
