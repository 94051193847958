import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  circle: {
    height: 15,
    width: 15,
    borderRadius: '50%',
    marginRight: 5,
  },
  square: {
    height: 15,
    width: 15,
    marginRight: 5,
  },
  label: {
    marginRight: 15,
  },
  boldLabel: {
    fontWeight: 600,
  },
});

type LegendShape = 'square' | 'circle';

export type LegendItem = {
  label: string;
  color: string;
  shape?: LegendShape;
  isBold?: boolean;
};

export const Legend: FunctionComponent<{ legend_items: LegendItem[] }> = ({ legend_items }) => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      {legend_items.map(({ label, color, shape, isBold = false }, ix) => (
        <div className={classes.row} key={ix}>
          <div className={classes.container}>
            <div className={classes[shape || 'circle']} style={{ backgroundColor: color }} />
          </div>
          <Typography
            variant="subtitle1"
            className={classnames(
              isBold && classes.boldLabel,
              ix === legend_items.length - 1 ? '' : classes.label
            )}
          >
            {label}
          </Typography>
        </div>
      ))}
    </div>
  );
};
