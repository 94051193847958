import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { TestWeightUnitType } from '../../../../../core/src/api';

const testWeightUnits = [
  { label: 'Pound Per Bushel[US](lbs/bu)', value: 'pound_per_us_bushel' },
  { label: 'Kilogram Per Hectoliter(kg/hL)', value: 'kilogram_per_hectoliter' },
];

type SelectProps = {
  name: string;
  selectedValue: TestWeightUnitType;
  isDisabled?: boolean;
  onChange: (value: { value: TestWeightUnitType; label: string }) => void;
};

export const SelectTestWeightUnit: FunctionComponent<SelectProps> = ({
  name,
  selectedValue,
  ...props
}) => {
  const defaultOption = testWeightUnits.find((option) => option.value === selectedValue);
  return (
    <Select
      options={testWeightUnits}
      name={name}
      value={defaultOption}
      isSearchable={false}
      {...props}
    />
  );
};
