import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';
import { RadioGroupProps as MuiRadioGroupProps } from '@material-ui/core/RadioGroup';
import { useField } from 'formik';
import React, { FunctionComponent, memo } from 'react';

export type RadioGroupProps = {
  name: string;
  label: string;
} & Pick<MuiRadioGroupProps, 'children' | 'row'> &
  Pick<FormControlProps, 'disabled' | 'fullWidth' | 'required' | 'className'>;

export const RadioGroup: FunctionComponent<RadioGroupProps> = memo(
  ({ name, fullWidth, label, required, disabled, children, className, ...props }) => {
    const [field, { touched, error }] = useField(name);
    const has_error = Boolean(touched && error);
    return (
      <FormControl
        fullWidth={fullWidth}
        required={required}
        error={has_error}
        disabled={disabled}
        className={className}
      >
        <FormLabel component="legend">{label}</FormLabel>
        <MuiRadioGroup {...field} {...props}>
          {children}
        </MuiRadioGroup>
        {has_error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
