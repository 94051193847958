import { makeStyles, Theme } from '@material-ui/core';
import DarkCheckboxSvg from '../../../../core/src/media/DarkCheckboxSvg.svg';
import DropCollapseIconSvg from '../../../../core/src/media/DropCollapseIconSvg.svg';
import DropExpandIconSvg from '../../../../core/src/media/DropExpandIconSvg.svg';
import { black_shade_2, black_shade_8, gray_shade_4 } from '../../../../core/src/style';

export const useStyles = makeStyles((theme: Theme) => ({
  conatiner: {
    fontFamily: 'Source Sans Pro,sans-serif',
    '& .ag-header-cell': {
      borderRight: 'solid 1px var(--ag-border-color, #babfc7)',
      color: black_shade_2,
      fontSize: 10,
      fontWeight: 500,
      paddingLeft: 5,
      paddingRight: 5,
      display: 'flex',
      justifyContent: 'center',
    },
    '& .ag-cell-wrapper': {
      width: '100%',
    },
    '& .ag-header-group-cell': {
      borderRight: 'solid 1px var(--ag-border-color, #babfc7)',
      // borderBottom: 'solid 1px var(--ag-border-color, #babfc7)',
    },

    '& .ag-cell': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: 'solid 1px var(--ag-border-color, #babfc7)',
      paddingLeft: 5,
      paddingRight: 5,
      color: black_shade_8,
      fontSize: 10,
      textAlign: 'center',
    },

    '& .ag-group-value': { flexGrow: 1 },

    '& .ag-header-cell-label': {
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& .ag-header-group-cell-label': {
      justifyContent: 'center',
      alignItems: 'center',
      color: black_shade_8,
      fontSize: 16,
      fontWeight: 600,
    },

    '& .header-row-span': {
      // position: 'absolute',
      top: -48,
      height: 96,
      // borderTop: 'none !important',
    },

    '& .ag-row-group': {
      marginLeft: 0,
      fontSize: 16,
      color: black_shade_8,
      fontWeight: 600,
    },

    '& .ag-header-select-all': {
      marginRight: 5,
      height: '100%!important',
      alignItems: 'start!important',
      paddingTop: '18px',
    },

    '& .ag-checkbox-input-wrapper.ag-checked::after': {
      color: black_shade_2,
      background: `transparent url(${DarkCheckboxSvg}) center/contain no-repeat!important`,
      width: '24px!important',
      height: '24px!important',
      content: '" " !important',
      borderWidth: 0,
    },

    '& .ag-cell-wrap-text': {
      wordBreak: 'break-word',
    },
    '& .ag-checkbox-input-wrapper': {
      backgroundColor: '#fff',
      width: 24,
      height: 24,
      '&::after': {
        width: '24px!important',
        height: '24px!important',
        content: '" " !important',
        border: `1px solid ${gray_shade_4}`,
        borderRadius: 4,
      },
      '& input[type="checkbox"]': {
        width: 24,
        height: 24,
        cursor: 'pointer',
      },
    },

    // '& .ag-pinned-left-header': {
    //   '& .ag-header-row-column': {
    //     height: '96px !important',
    //     top: '-1px !important',
    //     borderTop: 'none',
    //   },
    // },

    // '& .ag-header-viewport ': {
    //   '& .ag-header-row-column': {
    //     height: '96px !important',
    //     top: '0 !important',
    //   },
    // },

    '& .ag-sort-indicator-icon': {
      paddingLeft: '4px !important',
    },

    '& .ag-floating-filter-body': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& .ag-floating-filter': {
      paddingLeft: '0!important',
      paddingRight: '0!important',
      // borderTop: 'none!important',
      borderRight: '1px dashed var(--ag-border-color, #babfc7)!important',
    },

    '& .ag-floating-filter-input': {
      width: 275,
      '& .ag-input-field-input': {
        height: 34,
        width: 275,
        fontSize: '10px !important',
        border: '1px solid #CFD0D4',
        borderRadius: '4px !important',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
      },
    },

    '& .ag-floating-filter-button': {
      marginLeft: 0,
    },

    // '& .ag-header-cell-comp-wrapper': {
    //   alignItems: 'start !important',
    //   [theme.breakpoints.up('lg')]: {
    //     alignItems: 'center !important',
    //   },
    // },
    // '& .ag-header-row-column': {
    //   '& :first-child()': { alignItems: 'start !important' },
    // },

    '& .ag-icon-tree-closed': {
      background: `transparent url(${DropCollapseIconSvg}) center/contain no-repeat`,
      color: 'transparent',
    },
    '& .ag-icon-tree-closed:hover': {
      color: 'transparent !important',
    },
    '& .ag-icon-tree-open': {
      background: `transparent url(${DropExpandIconSvg}) center/contain no-repeat`,
      color: 'transparent',
      '-webkit-transform': 'rotate(180deg)',
      '-moz-transform': 'rotate(180deg)',
      '-o-transform': 'rotate(180deg)',
      ' -ms-transform': 'rotate(180deg)',
      transform: 'rotate(180deg)',
    },
    '& .ag-icon-tree-open:hover': {
      color: 'transparent !important',
      '-webkit-transform': 'rotate(180deg)',
      '-moz-transform': 'rotate(180deg)',
      '-o-transform': 'rotate(180deg)',
      ' -ms-transform': 'rotate(180deg)',
    },
    '& .ag-group-expanded': {
      marginRight: 16,
      marginLeft: 10,
    },
    '& .ag-group-contracted': {
      marginRight: 16,
      marginLeft: 10,
    },
    '& .ag-row-group-indent-1': {
      paddingLeft: 14,
    },
  },
  groupHeader: {
    borderRight: '1px dashed var(--ag-border-color, #babfc7)!important',
  },
  // autoGroupHeader: {
  //   color: 'red',
  //   height: '96px !important',
  // },
}));
