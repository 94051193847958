function newlinelToBrTag(str, is_xhtml) {
  const breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
  return `${str} `.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
}

function parseDeviceCoreId(str: string) {
  const isOldDevice = str.startsWith('4-');
  const isNewDevice = str.startsWith('https://') && str.includes('/devices/');
  let coreId = '';

  if (isNewDevice) {
    const dataParts = str.split('/');
    coreId = dataParts[dataParts.length - 1];
  } else if (isOldDevice) {
    const dataParts = str.split('-');
    coreId = dataParts[dataParts.length - 1];
  }
  return coreId;
}

export { newlinelToBrTag, parseDeviceCoreId };
