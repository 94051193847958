import * as React from 'react';
export const ThreeDotMenuIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={5.854}
    height={20.418}
    viewBox="0 0 5.854 20.418"
    {...props}
  >
    <path
      id="ThreeDotsIcon"
      d="M18.408,11.247A2.427,2.427,0,1,0,15.981,8.82,2.434,2.434,0,0,0,18.408,11.247Zm0,2.427A2.427,2.427,0,1,0,20.835,16.1,2.434,2.434,0,0,0,18.408,13.674Zm0,7.282a2.427,2.427,0,1,0,2.427,2.427A2.434,2.434,0,0,0,18.408,20.956Z"
      transform="translate(-15.481 -5.892)"
      fill="#5f636b"
      stroke="#fff"
      strokeWidth={1}
    />
  </svg>
);
