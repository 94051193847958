import React from 'react';
import { FanRunWindowRecommendedOption } from '../../../../../core/src/api';
import { EndIcon, StartIcon } from '../../../../../core/src/media';
import { amber_error_red } from '../../../../../core/src/style';
import { RecommendedOption } from '../RecommendationOptionValue';
export const renderRecommendationOptionColor = (recommendationOption) => {
  switch (recommendationOption) {
    case FanRunWindowRecommendedOption.Cooling:
      return 'linear-gradient(to bottom, #94b3fd, #7ec9ff, #75ddff, #7feeff, #99feff)';
    case FanRunWindowRecommendedOption.Drying:
      return 'linear-gradient(to bottom, #e99497, #fba189, #ffb479, #fbcb6e, #e8e46e)';
    case FanRunWindowRecommendedOption.Reconditioning:
      return 'linear-gradient(to bottom, #9adcff, #8dd0ff, #97c0ff, #b3abff, #d791ff)';
    case FanRunWindowRecommendedOption.Custom:
      return 'linear-gradient(to right top, #000000, #000000, #000000, #000000, #000000)';
    default:
      return 'linear-gradient(to right top, #000000, #000000, #000000, #000000, #000000)';
  }
};

type RunWindowStartEndIndicatorProps = {
  recommendationOptionValue: FanRunWindowRecommendedOption | RecommendedOption | null;
  blurScheduledRun?: boolean;
  currentRunWindowErrors?: string[];
};

export const RunWindowStartEndIndicator = ({
  recommendationOptionValue,
  blurScheduledRun = false,
  currentRunWindowErrors = [],
}: RunWindowStartEndIndicatorProps) => {
  const hasFanVibrationError =
    currentRunWindowErrors.length > 0 &&
    currentRunWindowErrors.find((msg) => msg.includes('Interrupted by Excessive Vibration'));
  return (
    <div>
      <div style={{ height: 15, backgroundColor: '#fff', borderRadius: '50%' }}>
        <StartIcon />
      </div>
      <div style={{ width: 16, display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            width: 2,
            height: 28,
            backgroundImage: renderRecommendationOptionColor(recommendationOptionValue),
          }}
        />
      </div>
      <div style={{ height: 15, backgroundColor: '#fff', borderRadius: '50%' }}>
        <EndIcon />
      </div>
    </div>
  );
};
