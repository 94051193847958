import formatNumber from './format-number';

export default (value: number): string => {
  if (value < 1000) {
    const formattedVal: String = formatNumber(Math.round(value));
    return `${formattedVal} ton`;
  }
  // 8000 -> 8k ton
  const formattedVal: String = formatNumber(Math.round(value / 1000));
  return `${formattedVal}k ton`;
};
