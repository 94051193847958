import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy } from '../../../util';
import {
  GetGrainBinWeeklyLevelDataQuery,
  withGetGrainBinWeeklyLevelData as HOC,
} from '../__generated';

export type WithGetGrainBinWeeklyLevelDataHocOwnProps = {
  grain_bin_id: number;
  grain_bin_storage_cycle_id?: number;
  loading?: boolean;
  pollInterval?: number;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainBinWeeklyLevelDataHocChildProps = {
  loading: boolean;
  grain_container_level_data: GetGrainBinWeeklyLevelDataQuery['grain_container'] | null;
};
export const withGetGrainBinWeeklyLevelDataHoc: AmberHoc<
  WithGetGrainBinWeeklyLevelDataHocOwnProps,
  WithGetGrainBinWeeklyLevelDataHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainBinWeeklyLevelDataHocOwnProps,
      WithGetGrainBinWeeklyLevelDataHocChildProps,
      typeof component
    >,
    WithGetGrainBinWeeklyLevelDataHocChildProps
  >({
    options: ({
      grain_bin_id,
      grain_bin_storage_cycle_id,
      onError,
      pollInterval = 1000 * 60 * 30,
    }) => ({
      pollInterval,
      onError,
      errorPolicy: onError ? 'all' : 'none',
      variables: {
        grain_bin_storage_cycle_id,
        container_id: grain_bin_id,
        container_type: ContainerTypeLegacy.bin,
      },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          grain_container_level_data: null,
        };
      }
      return {
        loading: loading || false,
        grain_container_level_data: data.grain_container || null,
      };
    },
  })(component as any);
