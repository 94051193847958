import * as React from 'react';
export const DarkCheckBoxIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g id="SelectBox" transform="translate(-485 -208)">
      <g
        id="SelectBox-2"
        data-name="SelectBox"
        transform="translate(485 208)"
        fill="#333"
        stroke="#cfd0d4"
        strokeWidth={1}
      >
        <rect width={24} height={24} rx={4} stroke="none" />
        <rect x={0.5} y={0.5} width={23} height={23} rx={3.5} fill="none" />
      </g>
      <path
        id="check"
        d="M5.689,11.309h0a1.135,1.135,0,0,0,1.6-.005h0l8.1-8.088h0a1.153,1.153,0,0,0,0-1.621,1.135,1.135,0,0,0-1.613,0h0L6.49,8.857,2.8,5.222a1.135,1.135,0,0,0-1.613,0,1.153,1.153,0,0,0,0,1.621h0Z"
        transform="translate(488.712 213.554)"
        fill="#fff"
      />
    </g>
  </svg>
);
