import { AmberHoc, AmberHocProps } from '../../../util';
import { InviteUserMutationVariables as Variables, withInviteUser as HOC } from '../__generated';
import { GraphQLErrors } from './error';

export type WithInviteUserHocChildProps = {
  inviteUser: (variables: Variables) => Promise<boolean>;
};
export const withInviteUserHoc: AmberHoc<{}, WithInviteUserHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithInviteUserHocChildProps, typeof component>,
    WithInviteUserHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        inviteUser: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({ variables });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.inviteUser;
        },
      };
    },
  })(component as any);
