import React from 'react';
import { ComputedForecastHourV2FragmentFragment, ForecastDayV2FragmentFragment } from '../api';

export type WeatherDataType = {
  hourlyForecast: ComputedForecastHourV2FragmentFragment[];
  dailyForecast: ForecastDayV2FragmentFragment[];
  grain_bin_location_timezone: string;
};

export const WeatherDataContext = React.createContext<WeatherDataType | undefined>(undefined);

export const WeatherDataProvider = ({
  weatherData,
  children,
}: React.PropsWithChildren<{
  weatherData: WeatherDataType | undefined;
}>) => {
  return <WeatherDataContext.Provider value={weatherData}>{children}</WeatherDataContext.Provider>;
};

export function useWeatherData() {
  const context = React.useContext(WeatherDataContext);
  if (context === undefined) {
    throw new Error('useWeatherData must be used within a WeatherDataProvider');
  }
  return context;
}
