import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { amber_error_red, light_gray2 } from '../../../../core/src/style';
import { NumberTextField } from '../util/form2/NumberField';

const useStyles = makeStyles((theme: Theme) => ({
  grainInputCardParent: {
    width: '33%',
    transition: 'width 0.5s',
  },

  grainInputCard: {
    backgroundColor: light_gray2,
    borderRadius: 8,
    padding: '8px 12px',
  },

  grainInputCardTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 13,
    textAlign: 'center',
  },
  grainInputIcon: {
    margin: '5px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grainInputIconStyles: {
    [theme.breakpoints.only('xs')]: {
      width: 30,
      height: 30,
    },
  },
  grainInputField: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
  },
  grainInputError: {
    color: amber_error_red,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    textAlign: 'left',
    lineHeight: '1.66',
    padding: '0px 8px',
  },
  numberTextField: {
    '& .MuiInputBase-root': {
      '& input': {
        textAlign: 'right',
        width: '35px',
        [theme.breakpoints.only('xs')]: {
          width: '32px',
        },
      },
    },
    '& .MuiInputBase-input': {
      fontSize: '1rem',
      [theme.breakpoints.only('xs')]: {
        fontSize: 14,
      },
    },
    '& .MuiInputAdornment-root': {
      '& p': {
        fontSize: '1rem',
        [theme.breakpoints.only('xs')]: {
          fontSize: 14,
        },
      },
    },

    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

export const GrainConditionInput = ({
  title,
  name,
  GrainConditionIcon,
  endAdornment,
  hasError,
  inputError,
  containerStyles = {},
}) => {
  const classes = useStyles();

  return (
    <div className={classes.grainInputCardParent} style={{ ...containerStyles }}>
      <div
        className={classes.grainInputCard}
        style={{
          border: hasError ? `1px solid ${amber_error_red}` : undefined,
        }}
      >
        <div className={classes.grainInputCardTitle}>{title}</div>
        <div className={classes.grainInputIcon}>
          <GrainConditionIcon className={classes.grainInputIconStyles} />
        </div>
        <div className={classes.grainInputField}>
          <div>
            <NumberTextField
              name={name}
              className={classes.numberTextField}
              min={0}
              max={100}
              parseValue={(val) => Number(val)}
              inputMode="decimal"
              InputProps={{
                endAdornment: <>{endAdornment}</>,
              }}
              showError={false}
            />
          </div>
        </div>
      </div>
      {hasError && <p className={classes.grainInputError}>{inputError}</p>}
    </div>
  );
};
