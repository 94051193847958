import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { GrainBinFragmentFragment as Result, withGetGrainBin as HOC } from '../__generated';

export type WithGetGrainBinHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainBinHocChildProps = {
  loading: boolean;
  grain_bin: Result | null;
  refetch: () => Promise<any>;
};
export const withGetGrainBinHoc: AmberHoc<
  WithGetGrainBinHocOwnProps,
  WithGetGrainBinHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<WithGetGrainBinHocOwnProps, WithGetGrainBinHocChildProps, typeof component>,
    WithGetGrainBinHocChildProps
  >({
    options: ({ grain_bin_id, onError }) => ({
      onError,
      variables: { grain_bin_id },
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          grain_bin: null,
          refetch: () => Promise.resolve(),
        };
      }
      return {
        loading: loading || false,
        grain_bin: data.grain_bin || null,
        refetch: () => data.refetch(),
      };
    },
  })(component as any);
