import { Button, Grid, Theme, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { RemoveCircleOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import format from 'date-fns/format';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import * as yup from 'yup';

import {
  BargeCoverPelletLinkFragmentFragment,
  withUnlinkBargeCoverPelletHoc,
  WithUnlinkBargeCoverPelletHocChildProps,
} from '../../../api';
import { amber_amber } from '../../../style';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../../util/form2/BaseForm';
import { ErrorBox } from '../../util/form2/ErrorBox';

const formatDate = (value) => format(value, 'YYYY-MM-DD');

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    whiteSpace: 'normal',
    wordWrap: 'normal',
    textAlign: 'center',
    paddingLeft: 12,
    paddingRight: 12,
  },
  button_icon: {
    marginRight: theme.spacing(1),
  },
  cover_no: { paddingLeft: 10, paddingRight: 10 },
  pellet_id: { paddingLeft: 10, paddingRight: 10 },
  divider: { marginTop: 10 },
  yellow: { color: amber_amber },
  red: { color: red['500'] },
  field: { width: 150 },
  root: { display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20 },
  center: { textAlign: 'center' },
  grid: { maxWidth: 500 },
  table: { textAlign: 'center', maxWidth: 500 },
}));

type Values = {
  pellet_id: string;
  cover_no: number | null;
  barge_cover_pellet_links: BargeCoverPelletLinkFragmentFragment[];
  barge_cover_pellet_link_id: number | null;
};

const validationSchema = yup.object().shape({
  barge_cover_pellet_link_id: yup
    .number()
    .typeError('A number is required')
    .integer()
    .required()
    .nullable(false),
});

const Form = () => {
  const classes = useStyles();
  const {
    initialValues: { barge_cover_pellet_links, pellet_id, cover_no },
    values: { barge_cover_pellet_link_id },
    submitForm,
    setFieldValue,
    isSubmitting,
  } = useFormikContext<Values>();
  return (
    <BaseForm submitting_message="Saving changes...">
      <Typography variant="h6">Current Assignments</Typography>
      <Grid container spacing={2} direction="column" classes={{ container: classes.table }}>
        <ErrorBox />
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={3}>
            Cover No
          </Grid>
          <Grid item xs={3}>
            Pellet ID
          </Grid>
          <Grid item xs={3}>
            Start Date
          </Grid>
          <Grid item xs={3}>
            Assign
          </Grid>
        </Grid>
        {barge_cover_pellet_links.map((bcpl) => {
          const highlight_row =
            pellet_id.split(':').pop() === bcpl.pellet_id.split(':').pop() ||
            cover_no === bcpl.cover_no;
          return (
            <Grid
              key={bcpl.barge_cover_pellet_link_id}
              item
              xs={12}
              container
              spacing={2}
              alignItems="center"
              classes={highlight_row ? { item: classes.yellow } : undefined}
            >
              <Grid item xs={3}>{`Cover No. ${bcpl.cover_no}`}</Grid>
              <Grid item xs={3}>
                {bcpl.pellet_id.split(':').pop()}
              </Grid>
              <Grid item xs={3}>
                {formatDate(bcpl.start_epoch)}
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  onClick={async () => {
                    await setFieldValue(
                      'barge_cover_pellet_link_id',
                      bcpl.barge_cover_pellet_link_id
                    );
                    await submitForm();
                  }}
                  disabled={isSubmitting}
                  className={highlight_row ? classes.yellow : undefined}
                >
                  <RemoveCircleOutline classes={{ root: classes.button_icon }} />
                  UNASSIGN
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </BaseForm>
  );
};

export const BargeCoverRemovalFormBase = ({
  barge_cover_pellet_links,
  unlinkBargeCoverPellet,
  cover_no = null,
  pellet_id = '',
  ...props
}: WithUnlinkBargeCoverPelletHocChildProps &
  FormikWrapperHandlerProps<Values, BargeCoverPelletLinkFragmentFragment[]> & {
    barge_cover_pellet_links: BargeCoverPelletLinkFragmentFragment[];
    pellet_id?: string;
    cover_no?: number | null;
  }) => {
  const submitCallback = useCallback(
    async (values) => {
      const { barge_cover_pellet_link_id } = validationSchema.validateSync(values);
      return await unlinkBargeCoverPellet({
        barge_cover_pellet_link_id: barge_cover_pellet_link_id as number,
      });
    },
    [unlinkBargeCoverPellet]
  );
  return (
    <FormikWrapper<Values, BargeCoverPelletLinkFragmentFragment[]>
      {...props}
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        barge_cover_pellet_links,
        cover_no,
        pellet_id: pellet_id.split(':').pop() as string,
        barge_cover_pellet_link_id: null,
      }}
      onSubmit={submitCallback}
    >
      <Form />
    </FormikWrapper>
  );
};

export const BargeCoverRemovalForm = withUnlinkBargeCoverPelletHoc(BargeCoverRemovalFormBase);
