import { Button, LinearProgress, useMediaQuery, useTheme } from '@material-ui/core';
import { ZoomIn } from '@material-ui/icons';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { CenteredSpinner } from '../..';
import { setLastAerationRun } from '../../../../../core/src/action';
import { formatTemp, renderModeColor } from '../../../../../core/src/util';
import {
  FanRunWindowRecommendedOption,
  GrainContainerAerationRunFragmentFragment,
} from '../../../api';
import { ColumnConfig } from '../../util';
import { ItemTableWithInfiniteScroll } from '../../util/ItemTableWithInfiniteScroll';
import {
  AerationEventDetailsDialog,
  getFanStartEndReasonTextColor,
} from './AerationEventDetailsDialog';
import { formatDate } from './BinFanSettings';
import { getRecommedationTypeMode } from './FanSchedule';
import { Mode } from './Mode';

const formatTime = (value) => value.toLocaleString(DateTime.TIME_SIMPLE);

export const formatDuration = (ms: number): string => {
  const hours = ms / (1000 * 60 * 60);
  const h = Math.floor(hours);

  const minutes = (hours - h) * 60;
  const m = Math.floor(minutes);
  return `${h}h ${m}m`;
};

export const formatDateTime = (date: Date) =>
  `${formatDate(DateTime.fromMillis(date), DateTime.local())} ${formatTime(
    DateTime.fromMillis(date)
  )}`;

export const getMs = (date: DateTime): number => {
  return DateTime.fromMillis(date).toMillis();
};
const getRecoTypeAbbreviation = (recommendation_type) =>
  ({
    [FanRunWindowRecommendedOption.Custom]: 'CUST',
    [FanRunWindowRecommendedOption.Drying]: 'DRY',
    [FanRunWindowRecommendedOption.Cooling]: 'COOL',
    [FanRunWindowRecommendedOption.Reconditioning]: 'RECON',
    [FanRunWindowRecommendedOption.Manual]: 'MAN',
  }[recommendation_type]);

const getIdForAerationRun = ({
  container_id,
  start_epoch,
}: GrainContainerAerationRunFragmentFragment) => `${container_id}${start_epoch}`;

const getColumns = (
  isMobile: boolean,
  isSmallMobileOnly: boolean
): ColumnConfig<
  GrainContainerAerationRunFragmentFragment & { alias: string; setSelected: (id: string) => void }
>[] => {
  const columns: any = [
    {
      title: 'Start',
      width: 25,
      align: 'left',
      getValue: ({ start_epoch }) => (start_epoch ? formatDateTime(start_epoch) : `N/A`),
    },
    {
      title: 'Runtime',
      width: 25,
      align: 'left',
      getValue: ({ start_epoch, end_epoch }) =>
        end_epoch
          ? formatDuration(getMs(end_epoch) - getMs(start_epoch))
          : `${formatDuration(getMs(DateTime.local().ts) - getMs(start_epoch))} & running`,
    },
    ...[
      !isMobile
        ? {
            title: 'Last Exhaust Temp',
            width: 25,
            align: 'left',
            getValue: ({ start_temp_f }) => `${start_temp_f ? `${formatTemp(start_temp_f)}` : '-'}`,
          }
        : null,
    ],
    {
      title: 'Avg Weather',
      width: isMobile ? 25 : 20,
      align: 'left',
      getValue: ({ avg_humidity_rh, avg_temp_f }) =>
        `${avg_temp_f ? `${formatTemp(avg_temp_f)}` : '-'} / ${
          avg_humidity_rh ? `${Math.round(avg_humidity_rh * 100)}%` : '-'
        }`,
    },
    {
      title: 'Mode',
      width: isMobile ? 20 : 25,
      align: 'left',
      getValue: ({ fan_runs }) => {
        const start_recommendation_type =
          fan_runs && fan_runs[0] && fan_runs[0].start_recommendation_type
            ? fan_runs[0].start_recommendation_type
            : null;
        const mode = start_recommendation_type
          ? isSmallMobileOnly
            ? getRecoTypeAbbreviation(start_recommendation_type)
            : getRecommedationTypeMode(start_recommendation_type)
          : null;
        const modeColor = renderModeColor(start_recommendation_type);
        return start_recommendation_type && mode ? (
          <Mode
            mode={mode}
            modeColor={modeColor}
            customStyle={isSmallMobileOnly ? { width: 50, fontSize: 12 } : {}}
          />
        ) : (
          '-'
        );
      },
    },
    {
      title: '',
      width: 20,
      align: 'left',
      getValue: ({ container_id, start_epoch, setSelected }) => (
        <Button
          style={{
            minWidth: 25,
            maxWidth: 100,
            width: '100%',
            padding: '6px 0',
            marginRight: 10,
            borderRadius: 20,
          }}
          variant="contained"
          onClick={() =>
            setSelected(
              getIdForAerationRun({
                container_id,
                start_epoch,
              } as GrainContainerAerationRunFragmentFragment)
            )
          }
        >
          <ZoomIn />
        </Button>
      ),
    },
  ];
  return columns.filter((column) => column !== null);
};

export const AerationEventHistoryTable: FunctionComponent<{
  aeration_runs: (GrainContainerAerationRunFragmentFragment & { alias: string })[];
  grain_bin_id: number;
  loading: boolean;
  loadMore: (offset: number) => Promise<any>;
  hasMore: boolean;
  currentOffset: number;
}> = ({ aeration_runs, grain_bin_id, loading, loadMore, hasMore, currentOffset }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const isSmallMobileOnly = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });

  const [aerationRuns, setAerationRuns] = useState<GrainContainerAerationRunFragmentFragment[]>([]);
  const hasMoreRef = useRef(hasMore);
  const currentOffsetRef = useRef(currentOffset);
  const [
    aeration_event,
    setAerationEvent,
  ] = useState<GrainContainerAerationRunFragmentFragment | null>(null);
  const closeEventDetailDialog = useCallback(() => setAerationEvent(null), [setAerationEvent]);
  const setSelected = (id: string) => {
    const found_event = aerationRuns.find((run) => getIdForAerationRun(run) === id);
    setAerationEvent(found_event || null);
  };
  const sorted_aeration_runs = useMemo(() => {
    // sort newest to oldest i.e. highest epoch to lowest
    return [...aerationRuns]
      .sort((a, b) => new Date(b.start_epoch).getTime() - new Date(a.start_epoch).getTime())
      .map((run) => ({ ...run, setSelected }));
  }, [aerationRuns]);

  const handleObserver = useCallback((entries) => {
    const first = entries[0];
    if (first.isIntersecting && hasMoreRef.current) {
      loadMore(currentOffsetRef.current);
    }
  }, []);
  // for infinite scroll pagination
  const [lastElement, setLastElement] = useState<HTMLElement | null>(null);
  const observer = useRef(new IntersectionObserver(handleObserver));

  useEffect(() => {
    if (currentOffset === 0) {
      setAerationRuns([...aeration_runs]);
      console.log('aeration_runs', aeration_runs[0]);
      aeration_runs[0] && dispatch(setLastAerationRun({ run: aeration_runs[0] }));
    } else setAerationRuns((prevAerationRuns) => [...prevAerationRuns, ...aeration_runs]);
  }, [JSON.stringify(aeration_runs)]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  useEffect(() => {
    hasMoreRef.current = hasMore;
    currentOffsetRef.current = currentOffset;
  }, [hasMore, currentOffset]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: 300 }}>
      {loading && aerationRuns.length !== 0 && <LinearProgress color="secondary" />}
      <div style={{ display: 'border-box', width: '100%', overflowX: 'hidden' }}>
        <ItemTableWithInfiniteScroll
          items={sorted_aeration_runs}
          getId={getIdForAerationRun}
          columns={getColumns(isMobile, isSmallMobileOnly)}
          setLastElement={setLastElement}
        />
        {loading && aerationRuns.length !== 0 && (
          <div style={{ textAlign: 'center' }}>
            <CenteredSpinner fadeIn="none" />
          </div>
        )}
      </div>
      {/* <div>{JSON.stringify(aeration_event)}</div> */}
      <AerationEventDetailsDialog
        grain_bin_id={grain_bin_id}
        aeration_event={aeration_event}
        onClickClose={closeEventDetailDialog}
        open={Boolean(aeration_event)}
      />
    </div>
  );
};
