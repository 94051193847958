import { AmberHoc, AmberHocProps } from '../../../util';
import {
  SetNotificationsReadOnMutation as Result,
  SetNotificationsReadOnMutationVariables as Variables,
  withSetNotificationsReadOn as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithSetNotificationsReadOnHocChildProps = {
  setNotificationsReadOn: (variables: Variables) => Promise<Result['setNotificationsReadOn']>;
};

export const withSetNotificationsReadOnHoc: AmberHoc<
  {},
  WithSetNotificationsReadOnHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithSetNotificationsReadOnHocChildProps, typeof component>,
    WithSetNotificationsReadOnHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        setNotificationsReadOn: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }

          const result = await mutate({ variables });

          if (!result) {
            throw new Error('Unexpected server response');
          }

          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.setNotificationsReadOn;
        },
      };
    },
  })(component as any);
