import { Grid, makeStyles, Theme, Tooltip } from '@material-ui/core';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { format, isSameDay, subDays } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { GrainBinNotification } from '../../../../core/src/api';
import { NoMobileIcon } from '../../../../core/src/media';
import { amber_amber, amber_grey, light_gray1, white } from '../../../../core/src/style';
import { newlinelToBrTag } from '../../util/parsing-utils';

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    position: 'relative',
    width: 450,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  alert_content: {
    padding: '10px 14px',
    borderRadius: 7.5,
    color: amber_grey,
    border: `1px solid ${light_gray1}`,
    backgroundColor: white,
    wordWrap: 'break-word',
    fontSize: 14,
    textAlign: 'start',
    lineHeight: 1.5,
  },
  date_time_content: {
    color: 'rgba(0,0,0,.54)',
    fontSize: 12,
    lineHeight: 2,
    textAlign: 'end',
    padding: '0px 2px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
}));

export const GrainBinNotificationCard = ({
  grain_bin_notification,
  cardBorderColor,
  idx,
  hasInternalSiteLoaded,
  user_last_notifications_read_on,
}: {
  grain_bin_notification: GrainBinNotification;
  cardBorderColor: string;
  idx: number;
  hasInternalSiteLoaded: boolean;
  user_last_notifications_read_on: Date;
}) => {
  const classes = useStyles();
  const sentDate = new Date(grain_bin_notification.epoch_time);

  const isUnreadNotification =
    sentDate.getTime() > new Date(user_last_notifications_read_on).getTime();

  const getDateString = (alertSentDate: Date) => {
    const as_of = new Date();
    const yesterday = subDays(as_of, 1);
    return isSameDay(as_of, alertSentDate)
      ? `Today ${format(alertSentDate, 'h:mm A')}`
      : isSameDay(yesterday, alertSentDate)
      ? `Yesterday ${format(alertSentDate, 'h:mm A')}`
      : format(alertSentDate, 'MMM DD, YYYY, h:mm A');
  };
  const sentDateStr = getDateString(sentDate);
  const showNewNotificationBadge = !hasInternalSiteLoaded && isUnreadNotification;

  return (
    <>
      <Grid container className={classes.cardContainer}>
        {showNewNotificationBadge && (
          <span
            style={{
              color: '#fff',
              backgroundColor: amber_amber,
              borderRadius: 4,
              position: 'absolute',
              right: 4,
              top: 4,
              fontSize: 10,
              padding: '1px 4px',
            }}
          >
            New
          </span>
        )}
        <Grid
          item
          className={classes.alert_content}
          dangerouslySetInnerHTML={{
            __html: newlinelToBrTag(grain_bin_notification.alert_content, true),
          }}
          style={{
            marginTop: idx === 0 ? 0 : undefined,
            borderLeft: `4px solid ${cardBorderColor}`,
          }}
        />
        <Grid item className={classes.date_time_content}>
          {grain_bin_notification.has_delivered_to_user ? (
            <Tooltip placement="left" title={<div style={{ fontSize: 12 }}>Delivered</div>} arrow>
              <PhoneIphoneIcon style={{ width: 16, height: 16 }} />
            </Tooltip>
          ) : (
            <Tooltip placement="left" title={<div style={{ fontSize: 12 }}>Disabled</div>} arrow>
              <NoMobileIcon />
            </Tooltip>
          )}
          {sentDateStr}
        </Grid>
      </Grid>
    </>
  );
};
