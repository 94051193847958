import { createAction } from 'typesafe-actions';
import { AccountFragmentFragment, ViewerFragmentFragment } from '../api';
import { SelectedState } from '../reducer';

export const createSession = createAction('SESSION/CREATE', (resolve) => {
  return (payload: { viewer: ViewerFragmentFragment; propagate: boolean }) => resolve(payload);
});

export const readySession = createAction('SESSION/READY', (resolve) => {
  return (payload: { selected?: SelectedState }) => resolve(payload);
});
export const renewSession = createAction('SESSION/RENEW', (resolve) => {
  return (payload: { token: string }) => resolve(payload);
});
export const endSession = createAction('SESSION/END', (resolve) => {
  return (payload?: { propagate: boolean }) => resolve(payload);
});
export const setAccountId = createAction('SESSION/SET_ACCOUNT_ID', (resolve) => {
  // entire account as payload for the nav saga to access the permissions
  return (payload: { account: AccountFragmentFragment }) => resolve(payload);
});
