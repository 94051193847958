import { Grid, Typography } from '@material-ui/core';
import React, {useState} from 'react';
import { RouteComponentProps } from 'react-router';
import * as yup from 'yup';
import {getDiagTestFanControllerApi} from "../../api/rest";
import {amber_red} from "../../style";
import { BaseForm, FormikWrapper } from '../util/form2/BaseForm';
import { ButtonSubmit } from '../util/form2/Button';
import { ErrorBox } from '../util/form2/ErrorBox';
import { TextField } from '../util/form2/TextField';

export type DiagnosticsDashboardProps = { basePath: string } & RouteComponentProps;

export const DiagnosticsDashboard = ({ basePath, history }: DiagnosticsDashboardProps) => {
  const initialValues = { core_id: '' };
  const [showInvalidCoreId, setShowInvalidCoreId] = useState<boolean>(false);
  const [submittedCoreId, setSubmittedCoreId] = useState<string>();

  const diagFormValidationSchema = yup.object().shape({
    core_id: yup
      .string()
      .required()
      .label('Device Core ID'),
  });
  const handleSubmit = async (values) => {
    const { core_id } = values;
    setShowInvalidCoreId(false);
    setSubmittedCoreId(core_id);
    diagFormValidationSchema.validateSync(values);
    const response = await getDiagTestFanControllerApi(core_id);
    const data = response.data.data;
    if (!data.fan_controller_id_next){
        return null;
    }
    return core_id;
  };

  const handleSubmitSuccess = (core_id) => {
    if (core_id) {
        history.push(`${basePath}/fc/${core_id}`);
    } else {
        setShowInvalidCoreId(true);
    }
  };

  return (
    <Grid
      container
      style={{
        display: 'flex',
        maxWidth: 600,
        minWidth: 300,
        justifyContent: 'center',
        margin: 'auto',
      }}
    >
      <Typography variant="h4" style={{ margin: '30px 15px' }}>
        Amber Fan Diagnostics Center
      </Typography>
      <FormikWrapper<{ core_id: string }>
        initialValues={initialValues}
        validationSchema={diagFormValidationSchema}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        render={() => {
          return (
            <BaseForm style={{ width: '100%' }} submitting_message={'Verifying Device Core ID...'}>
              <Grid container spacing={2} style={{ display: 'flex' }}>
                <Grid item xs={12}>
                  <TextField
                    name="core_id"
                    type="text"
                    label="Device Core ID"
                    autoComplete="off"
                    fullWidth
                  />
                </Grid>

                <Grid item style={{ marginTop: 15 }}>
                  <ButtonSubmit
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{
                      margin: 0,
                      maxWidth: '100%',
                      maxHeight: '100%',
                      minWidth: '100%',
                      minHeight: '100%',
                    }}
                    allow_pristine
                    prevent_submit_on_enter
                  >
                    Verify Device
                  </ButtonSubmit>
                </Grid>
                <ErrorBox />
              </Grid>
            {showInvalidCoreId &&
                <Grid item xs={12} style={{marginTop: '16px'}}>
                    <Typography variant="body1">There is no device with core-id <strong style={{color: amber_red}}>'{submittedCoreId}'</strong></Typography>
                </Grid>
            }
            </BaseForm>
          );
        }}
      />
    </Grid>
  );
};
