import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GrainBinNotificationFragmentFragment as Result,
  withGetUserDeviceNotificationsHistory as HOC,
} from '../__generated';

export type WithGetUserDeviceNotificationsHistoryHocOwnProps = {
  user_id: number;
  account_id: number;
  period: string;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetUserDeviceNotificationsHistoryHocChildProps = {
  refecthUserDeviceNotificationsHistory: () => Promise<any>;
  device_notifications_loading: boolean;
  user_device_notifications_history: Result | any;
};
export const withGetUserDeviceNotificationsHistoryHoc: AmberHoc<
  WithGetUserDeviceNotificationsHistoryHocOwnProps,
  WithGetUserDeviceNotificationsHistoryHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetUserDeviceNotificationsHistoryHocOwnProps,
      WithGetUserDeviceNotificationsHistoryHocChildProps,
      typeof component
    >,
    WithGetUserDeviceNotificationsHistoryHocChildProps
  >({
    options: ({ user_id, account_id, period, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      fetchPolicy: 'network-only',
      variables: { user_id, account_id, period },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          refecthUserDeviceNotificationsHistory: () => Promise.resolve(),
          device_notifications_loading: true,
          user_device_notifications_history: [],
        };
      }
      return {
        refecthUserDeviceNotificationsHistory: () => data.refetch(),
        device_notifications_loading: loading || false,
        user_device_notifications_history: data.user_device_notifications_history,
      };
    },
  })(component as any);
