import * as React from 'react';
export const NormalInfoIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={30} width={30} viewBox="0 0 30 30" {...props}>
    <g fill="#4b4b4b">
      <path d="M15 2.813c-6.727 0 -12.187 5.46 -12.187 12.187s5.46 12.187 12.187 12.187 12.187 -5.46 12.187 -12.187 -5.46 -12.187 -12.187 -12.187zm0 1.876c5.693 0 10.313 4.62 10.313 10.313S20.693 25.314 15 25.314s-10.313 -4.621 -10.313 -10.314S9.307 4.687 15 4.687z" />
      <path d="M16.876 9.609a2.109 2.109 0 1 1 -4.22 0 2.109 2.109 0 1 1 4.22 0z" />
      <path d="M17 20.804c-0.013 0.511 0.227 0.657 0.811 0.716l0.939 0.02v0.96H11.424v-0.96l1.031 -0.02c0.617 -0.02 0.766 -0.259 0.811 -0.716v-5.779c0.006 -0.914 -1.18 -0.771 -2.017 -0.744v-0.951l5.75 -0.207" />
    </g>
  </svg>
);
