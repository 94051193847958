import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { FanSettingsFragmentFragment, withGetGrainBinFanSettings as HOC } from '../__generated';

export type WithGetGrainBinFanSettingsHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainBinFanSettingsHocChildProps = {
  loading: boolean;
  grain_bin_fan_settings: FanSettingsFragmentFragment | null;
  as_of: Date;
};
export const withGetGrainBinFanSettingsHoc: AmberHoc<
  WithGetGrainBinFanSettingsHocOwnProps,
  WithGetGrainBinFanSettingsHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainBinFanSettingsHocOwnProps,
      WithGetGrainBinFanSettingsHocChildProps,
      typeof component
    >,
    WithGetGrainBinFanSettingsHocChildProps
  >({
    options: ({ grain_bin_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      fetchPolicy: 'network-only',
      variables: { grain_bin_id },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          grain_bin_fan_settings: null,
          as_of: new Date(),
        };
      }
      return {
        loading: loading || false,
        grain_bin_fan_settings: data.grain_bin_fan_settings || null,
        as_of: new Date(),
      };
    },
  })(component as any);
