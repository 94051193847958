import { createAction } from 'typesafe-actions';
import { GrainContainerAerationRunFragmentFragment } from '../api';
import { FanControlsNavigation } from '../component/grain-container/FanControlNavigationOption';

export const setIsFanStopTriggered = createAction('SET/IS_FAN_STOP_TRIGGERED', (resolve) => {
  return (payload: { isFanStopTriggered: boolean }) => resolve(payload);
});

export const setIsFanStartTriggered = createAction('SET/IS_FAN_START_TRIGGERED', (resolve) => {
  return (payload: { isFanStartTriggered: boolean }) => resolve(payload);
});

export const setIsUserOnTempRecommWindowsPage = createAction(
  'SET/IS_USER_ON_TEMP_RECOMM_WINDOWS_PAGE',
  (resolve) => {
    return (payload: { isUserOnTempRecommWindowsPage: boolean }) => resolve(payload);
  }
);

export const setUnreadNotificationCount = createAction(
  'SET/UNREAD_NOTIFICATION_COUNT',
  (resolve) => {
    return (payload: { unreadNotificationCount: number }) => resolve(payload);
  }
);

export const setLastAerationRun = createAction('SET/LAST_AERATION_RUN', (resolve) => {
  return (payload: { run: GrainContainerAerationRunFragmentFragment }) => resolve(payload);
});

export const setFanCardView = createAction('SET/FAN_CARD_VIEW', (resolve) => {
  return (payload: { initialFanControlCardNavigationType: FanControlsNavigation | undefined }) =>
    resolve(payload);
});

export const setShowGrainTicketWarningBanner = createAction(
  'SET/SHOW_GRAIN_TICKET_WARNING_BANNER',
  (resolve) => {
    return (payload: { showGrainTicketWarningBanner: boolean }) => resolve(payload);
  }
);

export const setShowCO2LevelWarningBanner = createAction(
  'SET/SHOW_CO2_LEVEL_WARNING_BANNER',
  (resolve) => {
    return (
      payload: {
        co2AlertSentOn: Date;
        co2AlertType: string;
      } | null
    ) => resolve(payload);
  }
);
