import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { AccountFragmentFragment, ViewerFragmentFragment } from '../../../../core/src/api';
import { UserContext } from '../../../../core/src/contexts';
import { ErrorLayout } from '../ErrorLayout';
import { GrainOperations, ManageSitesView, OperationsViewHeader } from '../operations';
import { AuthenticatedRoute } from '../util';
import { validateGrainBinSupportAccess } from './GrainBinRoutes';

type GrainOperationsRoutesProps = RouteComponentProps & {
  accessFailureRedirect?: (
    account: AccountFragmentFragment,
    viewer: ViewerFragmentFragment
  ) => string;
  user_id: number;
  account_id;
  grain_bin_ids: number[];
  goContainer: (args: any) => void;
  mobile_width: boolean;
};

const GrainOperationsRoutes = ({
  user_id,
  account_id,
  accessFailureRedirect,
  match: { url },
  goContainer,
  mobile_width,
}: GrainOperationsRoutesProps) => {
  return (
    <AuthenticatedRoute
      account_id={account_id}
      validateAccess={validateGrainBinSupportAccess}
      accessFailureRedirect={accessFailureRedirect}
      render={({ viewer, account }) => {
        console.log('Current Loaded account_id & viewer account_id', {
          currentLoadedAccount: account,
          currentLoadedAccountId: account_id,
          viewerAccountId: viewer.user.account_id,
        });
        const grain_bin_ids = account
          ? account.grain_bin_links
              .filter((it) => !it.grain_bin.archived)
              .map((it) => it.grain_bin_id)
          : [];
        return (
          <UserContext.Provider value={viewer}>
            {mobile_width && <OperationsViewHeader />}
            <Switch>
              <Route
                path={`${url}/manage`}
                render={(props) => {
                  const dash = (
                    <ManageSitesView
                      {...props}
                      account_id={account_id}
                      user_id={viewer.user_id}
                      grain_bin_ids={grain_bin_ids}
                      goContainer={goContainer}
                    />
                  );
                  return dash || <ErrorLayout />;
                }}
              />
              <Route
                path={`${url}/`}
                render={(props) => {
                  const dash = (
                    <GrainOperations
                      {...props}
                      account_id={account_id}
                      user_id={viewer.user_id}
                      grain_bin_ids={grain_bin_ids}
                      goContainer={goContainer}
                    />
                  );
                  return dash || <ErrorLayout />;
                }}
              />
            </Switch>
          </UserContext.Provider>
        );
      }}
    />
  );
};

export { GrainOperationsRoutes };
