import { Drawer, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';
import { ViewerFragmentFragment } from '../../api';
import { white } from '../../style';
import {
  EXTRA_CUSTOM_BREAKPOINT_DIFFERENCE,
  get_container_select_width,
  get_vertical_sidebar_width,
} from '../../util';
import { ContainerSelect } from './ContainerSelect';
import { HeaderBar } from './HeaderBar';
import { VerticalAppBar, VerticalAppBarWithCurrentAccountUsers } from './VerticalAppBar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    maxWidth: '30%',
  },
  drawer: {
    width: get_container_select_width('desktop'),
    flexShrink: 0,
    [theme.breakpoints.down(theme.breakpoints.width('lg') + EXTRA_CUSTOM_BREAKPOINT_DIFFERENCE)]: {
      width: get_container_select_width('tablet'),
    },
    [theme.breakpoints.only('sm')]: {
      width: get_container_select_width('sm-mobile'),
    },
  },
  drawerPaper: {
    left: get_vertical_sidebar_width('desktop'),
    width: get_container_select_width('desktop'),
    backgroundColor: white,
    border: 'none',
    boxShadow: `0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)`,

    [theme.breakpoints.down(theme.breakpoints.width('lg') + EXTRA_CUSTOM_BREAKPOINT_DIFFERENCE)]: {
      left: get_vertical_sidebar_width('tablet'),
      width: get_container_select_width('tablet'),
    },
    [theme.breakpoints.only('sm')]: {
      left: get_vertical_sidebar_width('sm-mobile'),
      width: get_container_select_width('sm-mobile'),
    },
  },
}));

export const AppNavigation: FunctionComponent<{
  viewer: ViewerFragmentFragment;
  title: string;
  email_address: string;
  pathname: string;
  onClickDashboard: (args: any) => void;
  onClickUsers: () => void;
  onClickUser: () => void;
  onClickSettings: () => void;
  onClickOrgSettings: () => void;
  onClickNotifications: () => void;
  onClickPrivacy: () => void;
  onClickBack?: () => void;
  onSelectContainer: (args: any) => void;
  goCreateBarge: () => void;
  goCreatePile: () => void;
  goCreateGrainBin: () => void;
  hideHeadBar?: boolean;
  currentAccountId: number;
}> = ({
  viewer,
  title,
  email_address,
  pathname,
  onClickDashboard,
  onClickPrivacy,
  onClickBack,
  onClickUser,
  onClickUsers,
  onClickSettings,
  onClickOrgSettings,
  onClickNotifications,
  onSelectContainer,
  goCreateBarge,
  goCreatePile,
  goCreateGrainBin,
  hideHeadBar = false,
  currentAccountId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const hasCurrentAccountHolder = currentAccountId === viewer.account_id;

  return (
    <div className={classes.root}>
      {!hideHeadBar && (
        <HeaderBar
          viewer={viewer}
          title={title}
          pathname={pathname}
          email_address={email_address}
          onClickUser={onClickUser}
          onClickPrivacy={onClickPrivacy}
          onClickBack={onClickBack}
        />
      )}

      {hasCurrentAccountHolder ? (
        <VerticalAppBar
          viewer={viewer}
          pathname={pathname}
          onClickDashboard={onClickDashboard}
          onClickUsers={onClickUsers}
          onClickSettings={onClickSettings}
          onClickOrgSettings={onClickOrgSettings}
          onClickNotifications={onClickNotifications}
          unreadNotificationCount={viewer.user.user_new_notifications_count}
        />
      ) : (
        <VerticalAppBarWithCurrentAccountUsers
          account_id={currentAccountId}
          viewer={viewer}
          pathname={pathname}
          onClickDashboard={onClickDashboard}
          onClickUsers={onClickUsers}
          onClickSettings={onClickSettings}
          onClickOrgSettings={onClickOrgSettings}
          onClickNotifications={onClickNotifications}
        />
      )}

      {pathname.includes('dashboard') || pathname.includes('grain/operations') || isMobile ? (
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <ContainerSelect
            viewer={viewer}
            pathname={pathname}
            onSelectContainer={onSelectContainer}
            goCreateBarge={goCreateBarge}
            goCreatePile={goCreatePile}
            goCreateGrainBin={goCreateGrainBin}
          />
        </Drawer>
      ) : null}
    </div>
  );
};
