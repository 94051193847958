import * as React from 'react';

const SuccessTickIcon = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 33.979 33.979"
    style={{
      enableBackground: 'new 0 0 117.72 117.72',
    }}
    xmlSpace="preserve"
    width={80}
    height={80}
    {...props}
  >
    <style type="text/css">{'\n\t.st0{fill:#01A601;}\n'}</style>
    <g>
      <path
        className="st0"
        d="M16.989 0c2.635 0 5.129 0.6 7.358 1.671c-0.912 0.722 -1.758 1.414 -2.546 2.081c-1.501 -0.546 -3.12 -0.843 -4.809 -0.843c-3.888 0 -7.409 1.576 -9.955 4.124c-2.548 2.548 -4.124 6.067 -4.124 9.955c0 3.888 1.576 7.407 4.124 9.955c2.548 2.548 6.067 4.124 9.955 4.124s7.409 -1.576 9.955 -4.124c2.548 -2.548 4.124 -6.067 4.124 -9.955c0 -0.924 -0.09 -1.83 -0.26 -2.705c0.731 -0.953 1.478 -1.902 2.243 -2.843c0.6 1.738 0.926 3.605 0.926 5.547c0 4.69 -1.902 8.939 -4.976 12.014c-3.074 3.074 -7.323 4.976 -12.014 4.976c-4.69 0 -8.939 -1.902 -12.014 -4.976C1.902 25.929 0 21.68 0 16.989c0 -4.69 1.902 -8.939 4.976 -12.014S12.299 0 16.989 0L16.989 0zM9.075 14.198L13.22 14.143l0.309 0.081c0.837 0.482 1.625 1.034 2.361 1.657c0.531 0.451 1.039 0.941 1.521 1.472c1.486 -2.393 3.071 -4.589 4.745 -6.61c1.833 -2.214 3.778 -4.223 5.822 -6.056l0.404 -0.156H32.905l-0.912 1.013C29.191 8.659 26.648 11.878 24.35 15.197C22.052 18.519 19.997 21.948 18.17 25.479l-0.569 1.097l-0.523 -1.117c-0.964 -2.069 -2.119 -3.969 -3.496 -5.666c-1.377 -1.697 -2.979 -3.204 -4.846 -4.485L9.075 14.198L9.075 14.198z"
      />
    </g>
  </svg>
);

export { SuccessTickIcon };
