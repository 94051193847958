import {
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Theme,
  Typography,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { ApolloError } from 'apollo-client';
import React from 'react';

import {
  FanSettingsFragmentFragment,
  HubContainerLinkBaseFragmentFragment,
  withGetContainerHoc,
  WithGetContainerHocChildProps,
  withGetGrainBinFanSettingsHoc,
  WithGetGrainBinFanSettingsHocChildProps,
} from '../../api';
import { ContainerTypeLegacy } from '../../util';
import { AssignFanControllerForm } from './aeration';
import { BargeCoverForm } from './barge/BargeCoverForm';
import { FanControllerRemovalForm } from './FanControllerRemovalForm';
import { HubAssignmentForm } from './HubAssignmentForm';
import { HubRemovalForm } from './HubRemovalForm';
import { PileCableForm } from './pile/PileCableForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
  header: { marginBottom: 15 },
  header_red: { color: red['500'], marginBottom: 0 },
  spacer: {
    marginBottom: 20,
    minWidth: 340,
    maxWidth: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: 15,
    },
  },
  largeGrid: {
    minWidth: 200,
    maxWidth: 200,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 600,
    },
  },
}));

const FanControllerDevices = withGetGrainBinFanSettingsHoc(
  ({
    grain_bin_id,
    grain_bin_fan_settings,
    onAssignFanControllerSuccess,
  }: {
    loading: boolean;
    grain_bin_id: number;
    onAssignFanControllerSuccess: (result: FanSettingsFragmentFragment) => void;
    onError: (error: ApolloError) => void;
    onUnassignFanControllerSuccess: (result: FanSettingsFragmentFragment) => void;
  } & WithGetGrainBinFanSettingsHocChildProps) => {
    const classes = useStyles();
    const fan_controllers = grain_bin_fan_settings
      ? grain_bin_fan_settings.grain_bin.fan_controllers
      : [];
    return (
      <>
        <Divider classes={{ root: classes.divider }} />
        <h2 className={classes.header}>Fan Controllers</h2>
        <div className={classes.spacer}>
          <FanControllerRemovalForm grain_bin_id={grain_bin_id} />
        </div>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">Add Fan Controller</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <AssignFanControllerForm
              grain_bin_id={grain_bin_id}
              onSubmitSuccess={onAssignFanControllerSuccess}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </>
    );
  }
);

type DevicesLayoutProps = {
  container_type?: ContainerTypeLegacy;
  container_id: number;
  onAssignFanControllerSuccess: (result: FanSettingsFragmentFragment) => void;
  onError: (error: ApolloError) => void;
  onHubAssignmentSuccess: (result: HubContainerLinkBaseFragmentFragment) => void;
  onUnassignFanControllerSuccess: (result: FanSettingsFragmentFragment) => void;
} & WithGetContainerHocChildProps;
const DevicesLayoutBase = ({
  container_type = ContainerTypeLegacy.bin,
  container_id,
  grain_container,
  onAssignFanControllerSuccess,
  onError,
  onHubAssignmentSuccess,
  onUnassignFanControllerSuccess,
}: DevicesLayoutProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {grain_container && <h1 className={classes.header}>{grain_container.alias}</h1>}
      <h2 className={classes.header}>Hubs</h2>
      <div className={classes.spacer}>
        <HubRemovalForm container_id={container_id} container_type={container_type} />
      </div>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Add Hub</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <HubAssignmentForm
            container_id={container_id}
            container_type={container_type}
            onError={onError}
            onSubmitSuccess={onHubAssignmentSuccess}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {container_type === ContainerTypeLegacy.bin && (
        <FanControllerDevices
          grain_bin_id={container_id}
          onAssignFanControllerSuccess={onAssignFanControllerSuccess}
          onError={onError}
          onUnassignFanControllerSuccess={onUnassignFanControllerSuccess}
        />
      )}
      {container_type === ContainerTypeLegacy.barge && (
        <>
          <Divider classes={{ root: classes.divider }} /> <BargeCoverForm barge_id={container_id} />
        </>
      )}
      {container_type === ContainerTypeLegacy.pile && (
        <>
          <Divider classes={{ root: classes.divider }} /> <PileCableForm pile_id={container_id} />
        </>
      )}
    </div>
  );
};

export const DevicesLayout = withGetContainerHoc(DevicesLayoutBase);
