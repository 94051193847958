import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({
  root: { textAlign: 'left', padding: 20 },
});
export const PrivacyPolicy: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p>
        Amber Agriculture Privacy Policy
        <br />
        Last Updated: April 1st 2019
      </p>
      <p>
        Amber Agriculture, Inc. and its affiliates (“Amber,” “We”, “Our”, or “Us”) are committed to
        informing you about its data practices and recognizes the importance of your individual
        privacy rights.
      </p>
      <p>
        This Privacy Policy outlines the type of information we gather when you use our website,
        mobile applications, or any service related to an internet-enabled device (“service”) and
        how we use that information.{' '}
      </p>
      <p>
        This Privacy Policy may be updated from time to time. We will notify you of any changes by
        posting the new Privacy Policy on our Website. You are advised to consult this Privacy
        Policy regularly for any changes.
      </p>
      <p>
        By using the Amber Service or browsing our Website, you are consenting to the collection,
        transfer, processing, storage, disclosure and other uses described in this Privacy Policy
        and agree to be bound by its terms.
      </p>

      <p>
        As used in this Privacy Policy, the terms “using” and “processing” information include using
        cookies on a computer, subjecting the information to statistical or other analysis and using
        or handling information in any way, including, but not limited to collecting, storing,
        evaluating, modifying, deleting, using, combining, disclosing and transferring information
        within our organization or among our affiliates.
      </p>

      <h4>Acceptance of the Terms</h4>
      <p>
        By using or accessing the Services and in any other interactions you may have with us in any
        manner, you acknowledge that you accept the practices and policies outlined in this Policy,
        and you hereby consent, to the fullest extent permitted in your jurisdiction, that we will
        collect, use, and share your data in the following ways, which are essential to providing
        you the Services. If you are not in agreement with our terms and conditions, then you should
        refrain from further use of our sites. In addition, your continued use of our website
        following the posting of any updates or changes to our terms and conditions shall mean that
        you are in agreement and acceptance of such changes.
      </p>

      <h4>The Information We Collect</h4>
      <p>
        Information you Provide to Company. We collect Personally Identifiable Information that you
        provide to the Company, such as when you register for our Services. “Personally Identifiable
        Information” is information that individually identifies you, and may include your name,
        physical address, phone number, e-mail address, and any other information that would allow
        someone to identify or contact you. You may choose not to provide Company with Personally
        Identifiable Information. However, this choice may prevent Company from providing you with
        the information or Services you requested or that could be offered to you. We also collect
        information or data about you that is not Personally Identifiable Information
        (“Non-Identifiable Data”). Non-Identifiable Data is data that does not identify you or any
        other individual.
      </p>

      <h4>Information We Collect Automatically When You Use the Services.</h4>
      <p>
        When you access or use the Services, we may automatically collect both Personally
        Identifiable Information about you as well as Non-Identifiable Data, including:
      </p>

      <p>
        Usage, System, and Log Data. Your Internet protocol (“IP”) address, device identifier,
        location information, MAC address, browser, and activity on the Service, including, but not
        limited to, dates and times of use, clicks, page and profile views, and the amount of time
        you spend on each page.
      </p>

      <p>
        Amber servers may automatically collect certain technical information about your use of the
        Amber Services. This may include collecting and logging IP addresses. Amber may collect IP
        addresses for system administration, security and statistical analysis. An IP address is a
        number that is assigned to a computer whenever it is communicating over the Internet. Amber
        and its internet service provider may log these IP addresses and analyze them to understand
        where requests originate. In addition, Amber may use a feature of your web browser called a
        “cookie” to assign an identification number that automatically identifies your computer when
        you visit the Site. In connection with the Amber Services, cookies may be used to store your
        username, password and viewing options, allowing you to log into the Site more conveniently.
        This analysis enables Amber to produce traffic volume statistics that can be helpful in
        understanding online traffic, thus enabling Amber to enhance its users’ online experience.
      </p>

      <p>
        Agricultural and Environmental Data. Our Services that involve use of Internet-enabled
        devices will collect data related to soil and the environment, most of which is
        Non-Identifiable Data.
      </p>

      <h4>Using the Information</h4>
      <p>We may use your general information for a variety of purposes, including to:</p>
      <ul>
        <li>Provide, operate, maintain and improve the Service</li>
        <li>Respond to your comments, questions, and requests and to provide customer service</li>
        <li>Prevent potentially illegal activities</li>
        <li>Fulfill your requests through the Service</li>
        <li>Provide you with information related to managing your grain</li>
        <li>Send you promotional materials and information</li>
        <li>
          Monitor and analyze trends, usage, and activities in connection with the Service and for
          marketing or advertising purposes
        </li>
        <li>To administer the service in general</li>
        <li>For other purposes about which we notify you</li>
      </ul>

      <h4>Use of Aggregate Information</h4>

      <p>
        In addition to the individual data use described in this Privacy Policy, we may aggregate
        information about you and other individual users together, or otherwise de-identify the
        information about you, so that the information does not identify you personally. By removing
        Personally Identifiable Information, we are left with Non-Identifiable Data. We may use
        Non-Identifiable Data in these forms for a variety of purposes, including for research and
        analysis, administration of the Services, and advertising. We own all Non-Identifiable Data
        collected through the Services.
      </p>

      <h4>Sharing and Disclosure of Information</h4>

      <p>
        We will not share Personally Identifiable Information about you with any third parties
        except as described in this Privacy Policy.
      </p>

      <p>
        <span>Service Providers.</span> We may share your Personally Identifiable Information with
        third-party vendors, consultants, and other service providers who are working on behalf of
        Company and require access to your Personally Identifiable Information to carry out that
        work.
      </p>

      <p>
        <span>Compliance with Laws.</span> Amber cooperates with government and law enforcement
        officials and private parties to enforce and comply with the law. We will disclose any
        information about you to government or law enforcement officials or private parties as we,
        in our sole discretion, believe necessary or appropriate to respond to claims and legal
        process (including but not limited to subpoenas), to protect the safety of the public or any
        person, or to prevent or stop activity we may consider to be, or to pose a risk of being,
        illegal, unethical or legally actionable activity.
      </p>

      <p>
        <span>Business Transfers.</span> We may choose to buy or sell assets. If we or our assets
        are acquired, or if we go out of business, enter bankruptcy, or go through some other change
        of ownership, or a grant of a security interest in all or part of us through, for example,
        an asset share or share sale, or some other form of business combination, merger or joint
        venture, your data could be one of the assets transferred to or acquired by a third party.
        If that happens, this Policy (including the provisions governing changes to this Policy)
        will continue to apply to the use of your data.
      </p>

      <p>
        <span>Affiliates.</span> We may share your Personally Identifiable Information with
        affiliates of Company for use as otherwise described in this Privacy Policy.
      </p>

      <h4>Children’s Privacy</h4>
      <p>
        The Website is not directed at children under 13 years of age, and Amber does not knowingly
        collect personally identifiable information from children under 13 years of age via the
        Website.
      </p>

      <h4>Third Parties Privacy Policy</h4>
      <p>
        This Privacy Policy only addresses the use and disclosure of information Amber collects from
        its users. To the extent that you disclose information to other parties while using the
        Amber Service, different rules may apply to the use or disclosure of the personal
        information, which you disclose to them. We do not control the privacy policies of third
        parties and you will be subject to the privacy customs and policies of such third parties.
      </p>

      <h4>Security</h4>
      <p>
        We are very concerned with safeguarding your information. We employ administrative, physical
        and electronic measures designed to protect your information from unauthorized access.
        However, we do not guarantee that unauthorized access will never occur.
      </p>

      <p>
        We will make any legally required disclosures of any breach of the security,
        confidentiality, or integrity of your unencrypted electronically stored “personal data” (as
        defined under applicable law) to you via email or conspicuous posting on the website in the
        most expedient time possible and without unreasonable delay, insofar as consistent with (i)
        the legitimate needs of law enforcement; or (ii) any measures necessary to determine the
        scope of the breach and restore the reasonable integrity of the data system.
      </p>

      <h4>International Transfer</h4>
      <p>
        Your information may be transferred to, and maintained on computers located outside of your
        state, province, country or other governmental jurisdiction where the privacy laws may not
        be as protective as those in your jurisdiction. Your consent to this Privacy Policy followed
        by your submission of such information represents your agreement to that transfer.
      </p>

      <h4>Updates to Our Privacy Policy</h4>
      <p>
        This Policy describes Amber's current data protection policies and practices. We reserve the
        right to update it periodically and without prior notice to you to reflect changes in those
        policies and practices. When we do, we will post the revised version behind the link marked
        'Privacy Policy' at the bottom of each page of our website.
      </p>

      <h4>How to Contact Us</h4>
      <p>
        If you have any further questions concerning the Amber Privacy Policy and the use of your
        Personal Information, please contact us at www.amber.ag or in writing at Amber Agriculture,
        Inc. 935 W Chestnut Chicago, IL 60642
      </p>
    </div>
  );
};
