import * as React from 'react';
export const ErrorIcon = (props) => (
  <svg
    id="ErrorIcon"
    xmlns="http://www.w3.org/2000/svg"
    width={25.699}
    height={25.699}
    viewBox="0 0 25.699 25.699"
    {...props}
  >
    <path
      id="ErrorIcon-2"
      data-name="ErrorIcon"
      d="M82.848,0a12.833,12.833,0,1,1-9.091,3.759A12.819,12.819,0,0,1,82.848,0Zm0,16.068a1.734,1.734,0,0,1,1.738,1.738,1.754,1.754,0,0,1-1.738,1.762,1.775,1.775,0,0,1-1.762-1.762A1.754,1.754,0,0,1,82.848,16.068Zm0-1.762a1.227,1.227,0,0,1-1.175-1.175l-.587-5.238a1.79,1.79,0,0,1,1.762-1.762,1.754,1.754,0,0,1,1.738,1.762l-.564,5.238A1.227,1.227,0,0,1,82.848,14.306Zm7.423-8.9a10.52,10.52,0,1,0,3.077,7.447A10.576,10.576,0,0,0,90.271,5.4Z"
      transform="translate(-69.998)"
      fill="#f44336"
    />
  </svg>
);
