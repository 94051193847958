import { AmberHoc, AmberHocProps } from '../../../util';
import {
  CreateGrainBinMutationVariables as Variables,
  GetAccountDocument,
  GetAccountQuery,
  GetAccountQueryVariables,
  GetGrainBinDocument,
  GetGrainBinQuery,
  GetGrainBinQueryVariables,
  GrainBinFragmentFragment as Result,
  withCreateGrainBin as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithCreateGrainBinHocChildProps = {
  createGrainBin: (variables: Variables) => Promise<Result>;
};

export const withCreateGrainBinHoc: AmberHoc<{}, WithCreateGrainBinHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithCreateGrainBinHocChildProps, typeof component>,
    WithCreateGrainBinHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        createGrainBin: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const { account_id } = variables;
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { createGrainBin: result } = data;
              const { grain_bin_id, __typename, grain_bin } = result;

              try {
                proxy.writeQuery<GetGrainBinQuery, GetGrainBinQueryVariables>({
                  query: GetGrainBinDocument,
                  data: { grain_bin, __typename: 'Query' },
                  variables: { grain_bin_id },
                });
              } catch (err) {
                // no-op
              }

              try {
                const cached = proxy.readQuery<GetAccountQuery, GetAccountQueryVariables>({
                  query: GetAccountDocument,
                  variables: { account_id },
                });
                if (!cached) {
                  return;
                }
                const { account } = cached;
                if (!account) {
                  return;
                }
                proxy.writeQuery<GetAccountQuery, GetAccountQueryVariables>({
                  query: GetAccountDocument,
                  data: {
                    __typename: 'Query',
                    account: {
                      ...account,
                      grain_bin_links: [
                        ...account.grain_bin_links,
                        {
                          __typename,
                          grain_bin_id,
                          grain_bin: {
                            grain_bin_id,
                            __typename: grain_bin.__typename,
                            alias: grain_bin.bin_name,
                            location: grain_bin.location,
                            grain_type: grain_bin.grain_type,
                            archived: false,
                            fan_controllers: grain_bin.fan_controllers,
                          },
                        },
                      ],
                    },
                  },
                  variables: { account_id },
                });
              } catch (err) {
                // no-op
              }
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.createGrainBin.grain_bin;
        },
      };
    },
  })(component as any);
