import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useGrainBinData } from '../../../../../core/src/contexts';
import { AutomationIcon, CancelIcon } from '../../../../../core/src/media';
import { renderModeColor } from '../../../../../core/src/util';
import {
  ContainerType,
  FanControllerRunWindow,
  FanRunWindowRecommendedOption,
  withInitiateGuidedRecommendationsHoc,
  WithInitiateGuidedRecommendationsHocChildProps,
} from '../../../api';
import { amber_dark_grey, amber_green, amber_red, black_shade_2, white } from '../../../style';
import { DialogSpinner } from '../../spinner';
import DividerLine from '../../util/DividerLine';
import {
  createNavigationTypeFromNavOption,
  FanControlNavigationOption,
  FanControlsNavigation,
} from '../FanControlNavigationOption';
import { getRecommedationTypeMode } from './FanSchedule';
import { GrainWeatherInputsCard } from './GrainWeatherInputsCard';
import { UpcomingFanScheduleTable } from './UpcomingFanScheduleTable';

const useStyles = makeStyles((theme: Theme) => ({
  centered_col: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    fontFamily: 'Work Sans,sans-serif',
    height: 38,
    borderRadius: 5,
    boxShadow: '0px 0px 4px #00000033',
    [theme.breakpoints.down('xs')]: {
      marginTop: 2,
    },
    margin: 10,
    color: black_shade_2,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button_text: {
    marginLeft: 10,
  },
  manageAutomationbtn: {
    // backgroundColor: amber_light_grey,
    [theme.breakpoints.down('xs')]: {
      marginTop: 2,
    },
    margin: 10,
    marginBottom: 20,
  },

  endAutomationGrid: {
    [theme.breakpoints.up('md')]: {
      textAlignLast: 'center',
    },
  },
  fanAutomationIconContainer: {
    marginRight: 6,
    width: 16,
    height: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  extendAutomation: {
    fontFamily: 'Work Sans,sans-serif',
    width: 306,
    height: 38,
    fontSize: 16,
    boxShadow: '0px 0px 4px #00000033',
    borderRadius: 5,
    textTransform: 'capitalize',
    border: '1px solid #999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grainWeatherInputsCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 25,
    marginTop: 20,
  },
  centerDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonTextContainer: {
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    height: 20,
  },
  rightMargin: {
    marginRight: 10,
  },
}));

const EndAutomationWhenNoRecommWindows = withInitiateGuidedRecommendationsHoc(
  ({
    hasUserReadOnlyAccess,
    cancelNextRun,
    aeration_schedule,
    aeration_schedule_type,
    setNavigation,
    navigation,
    opt_in_fan_guidance,
    enable_fan_guidance,
    fan_guidance_start_date,
    fan_guidance_end_date,
    recommendation_type,
    initiateGuidedRecommendations,
    grain_bin_id,
    show_fan_guidance_ext_prompt,
    setShowFanGuidanceExtPrompt,
    show_edit_sched_btn = false,
    addRecommendedWindow,
    hasManualMode,
  }: WithInitiateGuidedRecommendationsHocChildProps & {
    aeration_schedule: FanControllerRunWindow[] | null;
    aeration_schedule_type: FanRunWindowRecommendedOption | null;
    setNavigation: Dispatch<SetStateAction<FanControlNavigationOption>>;
    navigation: FanControlNavigationOption;
    cancelNextRun: () => void;
    opt_in_fan_guidance: boolean;
    fan_guidance_start_date: Date | null;
    fan_guidance_end_date: Date | null;
    enable_fan_guidance: boolean;
    recommendation_type: string | null;
    grain_bin_id: number;
    show_fan_guidance_ext_prompt: boolean;
    setShowFanGuidanceExtPrompt: (show_fan_guidance_ext) => void;
    show_edit_sched_btn?: boolean;
    addRecommendedWindow?: () => void;
    hasUserReadOnlyAccess: boolean;
    hasManualMode: boolean;
  }) => {
    const theme = useTheme();
    const classes = useStyles();
    const isNotSmallMobile = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const [is_extending, setIsExtending] = useState(false);
    const { current_grain_temp, current_grain_emc, target_grain_emc } = useGrainBinData();
    const modeType = hasManualMode ? FanRunWindowRecommendedOption.Manual : recommendation_type;
    const onClickExtendAutomation = async () => {
      setIsExtending(true);
      await initiateGuidedRecommendations({
        container_id: grain_bin_id,
        container_type: ContainerType.Bin,
        automated_period: 10,
      });
      setShowFanGuidanceExtPrompt(false);
      setIsExtending(false);
    };
    if (is_extending) {
      return <DialogSpinner title="Extending Automation Schedule..." open={is_extending} />;
    }
    return (
      <div className={classes.centered_col}>
        <div className={classes.grainWeatherInputsCard}>
          <GrainWeatherInputsCard
            current_grain_temp={
              current_grain_temp === null || current_grain_temp === undefined
                ? null
                : current_grain_temp
            }
            current_grain_emc={current_grain_emc || null}
            target_grain_emc={target_grain_emc || null}
            mode={getRecommedationTypeMode(modeType)}
            modeColor={renderModeColor(modeType)}
          />
        </div>
        <div style={{ width: '100%', marginTop: 10 }}>
          {show_fan_guidance_ext_prompt && (
            <Grid container style={{ justifyContent: 'center', marginBottom: 15 }}>
              <Button
                variant="outlined"
                className={classes.extendAutomation}
                onClick={onClickExtendAutomation}
                disabled={hasUserReadOnlyAccess}
                style={{
                  color: white,
                  backgroundColor: amber_green,
                }}
              >
                <div className={classes.fanAutomationIconContainer}>
                  <AutomationIcon />
                </div>
                EXTEND AUTOMATION
              </Button>{' '}
            </Grid>
          )}

          <Grid container>
            <Grid item xs={12} className={classes.btnsContainer}>
              {show_edit_sched_btn && addRecommendedWindow && (
                <Button
                  color="default"
                  variant="outlined"
                  className={classes.button}
                  onClick={() => addRecommendedWindow()}
                  disabled={hasUserReadOnlyAccess}
                  style={{
                    width: 145,
                    height: 38,
                    marginLeft: 0,
                    padding: 0,
                    marginRight: isNotSmallMobile ? 35 : 15,
                  }}
                >
                  <div className={classes.centerDiv}>
                    <EditIcon className={classes.rightMargin} />
                    <div className={classes.buttonTextContainer}>Edit</div>
                  </div>
                </Button>
              )}
              <Button
                variant="outlined"
                className={classes.button}
                onClick={cancelNextRun}
                disabled={hasUserReadOnlyAccess}
                style={{ width: 145, height: 38, marginLeft: 0, marginRight: 0, padding: 0 }}
              >
                <div className={classes.centerDiv}>
                  <CancelIcon className={classes.rightMargin} />
                  <div className={classes.buttonTextContainer}>Cancel</div>
                </div>
              </Button>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginBottom: 15, width: '100%' }}>
          <DividerLine />
        </div>
        {aeration_schedule && (
          <UpcomingFanScheduleTable
            any_running={false}
            fan_schedules={aeration_schedule}
            aeration_schedule_type={aeration_schedule_type}
            loading={false}
            startSettingNavigation={() =>
              setNavigation(
                createNavigationTypeFromNavOption(
                  FanControlsNavigation.ShowManualRunWindows,
                  navigation
                )
              )
            }
            addRecommendedWindow={() => {
              setNavigation(
                createNavigationTypeFromNavOption(
                  FanControlsNavigation.AskCurrentGrainConditions,
                  navigation
                )
              );
            }}
            opt_in_fan_guidance={opt_in_fan_guidance}
            enable_fan_guidance={enable_fan_guidance}
            fan_guidance_start_date={fan_guidance_start_date}
            fan_guidance_end_date={fan_guidance_end_date}
            recommendation_type={recommendation_type}
            hasUserReadOnlyAccess={hasUserReadOnlyAccess}
            hasManualMode={hasManualMode}
            hasNoRecommendedRunsWithinAutoPeriod
            show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
          />
        )}
      </div>
    );
  }
);

export default EndAutomationWhenNoRecommWindows;
