import { Button, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

import {
  amber_amber,
  amber_dark_grey,
  amber_grey,
  amber_medium_grey,
  amber_medium_light_grey,
} from '../../../style';
import { ContainerTypeLegacy, formatNumber, RelativeTimePeriod, Statistic } from '../../../util';
import { PelletHistoryCard, Range } from '../summary-card';
import { Cable } from './PileCablesTable';

const formatTemp = (temp_f) =>
  temp_f === null || isNaN(temp_f) ? 'N/A' : `${formatNumber(temp_f, 1)}\u00b0`;

const height = 250;
const width = 350;

const useStyles = makeStyles((theme: Theme) => ({
  vertical_divider: {
    borderLeft: `3px solid ${amber_grey}`,
    height: 25,
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  border_button: { minWidth: 84, border: `solid 2px ${amber_grey}`, borderRadius: '10px' },
  pellet_container: {
    height,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  strong_text: {
    color: amber_dark_grey,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  subtitle: {
    color: amber_medium_grey,
    fontSize: 12,
    lineHeight: 1,
  },
  text_container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const CableTempDiagram: FunctionComponent<{
  container_id: number;
  cable: Cable;
}> = ({ container_id, cable }) => {
  const classes = useStyles();
  const [period, setPeriod] = useState(RelativeTimePeriod.week);
  const [selected_pellet, setSelectedPellet] = useState(cable.pellets[0]);
  const chart_el = useRef<HTMLDivElement>(null);
  const onClickPelletTemp = useCallback(
    (pellet) => {
      setSelectedPellet(pellet);
      const { current } = chart_el;
      if (!current) {
        return;
      }
      const { top, bottom } = current.getBoundingClientRect();
      if (
        top < 0 ||
        bottom > (window.innerHeight || (document.documentElement as HTMLElement).clientHeight)
      ) {
        current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    },
    [chart_el, cable]
  );
  useEffect(() => {
    setSelectedPellet(cable.pellets[0]);
  }, [cable]);

  if (!cable.pellets || !cable.pellets.length) {
    return <div>No cable pellets found</div>;
  }
  return (
    <Grid container direction="row" justify="space-evenly" alignItems="center">
      <Grid item xs={12} lg={4} container direction="column" justify="center" alignItems="center">
        <Grid item xs={12} className={classes.margin}>
          <Typography variant="h6">Top</Typography>
        </Grid>
        {cable.pellets.map((pellet, ix) => {
          const elements = [
            <Grid key={`pellet-${ix}`} item xs={12}>
              <Button
                className={classes.border_button}
                variant={'text'}
                style={
                  selected_pellet && pellet.pellet_id === selected_pellet.pellet_id
                    ? { borderColor: amber_amber, backgroundColor: amber_medium_light_grey }
                    : {}
                }
                onClick={() => onClickPelletTemp(pellet)}
              >
                <div className={classes.text_container}>
                  {pellet.grain_status ? (
                    <Typography variant="h6" className={classes.strong_text}>
                      {formatTemp(pellet.grain_status.temp_f)}
                    </Typography>
                  ) : (
                    <Typography variant="h6" className={classes.strong_text}>
                      Offline
                    </Typography>
                  )}
                  <Typography className={classes.subtitle}>
                    {pellet.pellet_id.split(':').pop()}
                  </Typography>
                </div>
              </Button>
            </Grid>,
          ];
          if (ix !== cable.pellets.length - 1) {
            elements.push(
              <Grid key={`divider-${ix}`} item xs={12}>
                <div className={classes.vertical_divider} />
              </Grid>
            );
          }
          return elements;
        })}
        <Grid item xs={12} className={classes.margin}>
          <Typography variant="h6">Bottom</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Readings {cable.spacing_ft}ft apart</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={8}>
        <div className={classes.pellet_container} ref={chart_el}>
          <PelletHistoryCard
            container_id={container_id}
            container_type={ContainerTypeLegacy.pile}
            ranges={
              (selected_pellet.grain_status &&
                ((selected_pellet.grain_status.temp_f_ranges as unknown) as Range[])) ||
              []
            }
            period={period}
            pellet_id={selected_pellet.pellet_id}
            onSelectPeriod={setPeriod}
            statistic={Statistic.temp_f}
            height={height}
            width={width}
            show_temp_threshold={false}
          />
        </div>
      </Grid>
    </Grid>
  );
};
