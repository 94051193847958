import { Button, makeStyles, Theme } from '@material-ui/core';
import { Cancel, PlayArrow } from '@material-ui/icons';
import React from 'react';
import { AutomationIcon, EqualizerIcon, FanAutomationIcon } from '../../../../core/src/media';
import {
  amber_green,
  amber_red,
  black_shade_2,
  black_shade_3,
  black_shade_4,
  gray_shade_4,
  light_gray_7,
  white,
} from '../../../../core/src/style';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: white,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    borderRadius: 4,
    border: `1px solid ${gray_shade_4}`,
    padding: '8px 10px',
    width: '95%',
    marginLeft: 20,
    overflowX: 'auto',
    opacity: 0.3,
  },
  customBtn: {
    height: '35px',
    boxShadow: '0px 0px 4px #00000033',
    fontFamily: 'Work Sans,sans-serif',
    border: `1px solid ${amber_green}`,
    borderRadius: 6,
    fontSize: 14,
    textTransform: 'capitalize',
    marginRight: 20,
    padding: 0,
  },
  button: {
    height: '35px',
    boxShadow: '0px 0px 4px #00000029',
    fontFamily: 'Work Sans,sans-serif',
    border: `1px solid ${black_shade_4}`,
    borderRadius: 6,
    fontSize: 14,
    textTransform: 'capitalize',
    marginRight: 20,
    padding: 0,
  },
  fanAutomationIconContainer: {
    marginRight: 4,
    width: 13,
    height: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: amber_green,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionTitle: {
    fontSize: 20,
    fontWeight: 600,
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  verticalDivider: {
    backgroundColor: gray_shade_4,
    height: 40,
    width: '0.75px',
    marginLeft: 10,
    marginRight: 10,
  },
}));
const BulkActionsView = () => {
  const classes = useStyles();
  const allButtonAreDisabled = true;

  return (
    <div className={classes.container}>
      <h3 className={classes.actionTitle}>Bulk Actions</h3>
      <div className={classes.verticalDivider} />
      <div className={classes.buttonContainer}>
        <Button
          size="medium"
          variant="contained"
          className={classes.button}
          style={{
            color: black_shade_2,
            backgroundColor: white,
            minWidth: 184,
          }}
          onClick={() => {}}
          disabled={allButtonAreDisabled}
        >
          <Cancel style={{ color: amber_red, marginRight: 4, width: 20, height: 20 }} />
          Stop/Clear Schedule
        </Button>
        <Button
          size="medium"
          variant="contained"
          className={classes.customBtn}
          style={{
            color: black_shade_2,
            backgroundColor: white,
            minWidth: 168,
          }}
          onClick={() => {}}
          disabled={allButtonAreDisabled}
        >
          <FanAutomationIcon className={classes.fanAutomationIconContainer} />
          Extend Automations
        </Button>
        <Button
          size="medium"
          variant="contained"
          className={classes.customBtn}
          style={{
            color: black_shade_2,
            backgroundColor: white,
            minWidth: 168,
          }}
          onClick={() => {}}
          disabled={allButtonAreDisabled}
        >
          <EqualizerIcon style={{ marginRight: 4 }} />
          Custom Schedule
        </Button>
        <Button
          size="medium"
          variant="contained"
          className={classes.customBtn}
          style={{
            color: black_shade_2,
            backgroundColor: white,
            minWidth: 168,
          }}
          onClick={() => {}}
          disabled={allButtonAreDisabled}
        >
          <PlayArrow style={{ marginRight: 4, color: amber_green }} />
          Run Now
        </Button>
      </div>
    </div>
  );
};

export { BulkActionsView };
