import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { ReactElement, useCallback, useState } from 'react';

import { amber_dark_grey } from '../../style';
import { useMemoRender } from './hook';

export type ColumnConfig<T> = {
  title: string;
  width: number;
  align?: 'left' | 'right' | 'center';
  alignTitle?: 'left' | 'right' | 'center';
  getValue: (item: T) => any;
  formatValue?: (value, item: T) => string | React.ReactNode;
  onClickCell?: (item: T) => void;
};

type ItemTableHeaderProps<T extends {}> = {
  columns: ColumnConfig<T>[];
  num_selected?: number;
  selectable: boolean;
  sort_by: number | null;
  sort_asc: boolean;
  onClickSort: (ix) => void;
  onClickSelect?: () => void;
};

const ItemTableHeaderBase = <T extends {}>({
  columns,
  num_selected = 0,
  selectable,
  sort_by,
  sort_asc,
  onClickSort,
  onClickSelect,
}: ItemTableHeaderProps<T>): ReactElement => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        {selectable && (
          <TableCell align="left" padding="checkbox">
            <Checkbox
              indeterminate={num_selected > 0}
              checked={num_selected > 0}
              disabled={num_selected === 0}
              onChange={onClickSelect}
            />
          </TableCell>
        )}
        {columns.map(({ title, alignTitle = 'left' }, ix) => (
          <TableCell key={ix} align={alignTitle}>
            <Tooltip title="Sort" placement="bottom" enterDelay={300}>
              <TableSortLabel
                active={sort_by === ix}
                direction={sort_asc ? 'asc' : 'desc'}
                onClick={() => onClickSort(ix)}
                className={classes.dark_text}
              >
                {title}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles({
  root: {
    tableLayout: 'fixed',
    '& td,th': {
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 15,
      paddingBottom: 15,
    },
    '& td.clickable:hover': {
      fontWeight: 'bold',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '& tbody': {
      overflowY: 'auto',
      wordBreak: 'break-word',
    },
  },
  body_text: {
    fontSize: 14,
  },
  dark_text: {
    color: amber_dark_grey,
    fontWeight: 600,
    fontSize: 14,
  },
  row_height: { height: 50 },
  noOnSelectStyles: { height: 75 },
});

export const ItemTable = <T extends {}>({
  hide_select_checkbox,
  show_unselected,
  items,
  columns,
  getId,
  onSelect,
  value,
}: {
  hide_select_checkbox?: boolean;
  show_unselected?: boolean;
  items: T[];
  columns: ColumnConfig<T>[];
  getId: (item: T) => string | number;
  onSelect?: (item: T | null) => void;
  value?: T | null;
}) => {
  const ItemTableHeader = <T extends {}>(props: ItemTableHeaderProps<T>) =>
    useMemoRender(props, ItemTableHeaderBase);
  const classes = useStyles();
  const [sort_by, setSortBy] = useState<number>(NaN);
  const [sort_asc, setSortAsc] = useState(true);
  const handleClickSort = useCallback(
    (value: number) => {
      if (sort_by !== value) {
        setSortBy(value);
        setSortAsc(true);
      } else {
        setSortAsc(!sort_asc);
      }
    },
    [sort_by, sort_asc, setSortAsc, setSortBy]
  );
  const getSortedItems = useCallback(
    (items: T[], columns: ColumnConfig<T>[], sort_by: number, sort_asc: boolean): T[] => {
      if (isNaN(sort_by)) {
        return items;
      }
      const col = columns[sort_by];
      if (!col) {
        return items;
      }
      const copied = items.slice();
      return copied.sort((a, b) => {
        const a_val = col.getValue(a);
        const b_val = col.getValue(b);
        if (a_val === b_val) {
          return 0;
        }
        if (a_val < b_val || a_val === undefined) {
          return sort_asc ? -1 : 1;
        }
        return sort_asc ? 1 : -1;
      });
    },
    []
  );

  const selectable = Boolean(onSelect);
  const selected_id = value ? getId(value) : null;
  return (
    <Table padding="default" classes={{ root: classes.root }}>
      <colgroup>
        {onSelect && !hide_select_checkbox && <col style={{ width: 10 }} />}
        {columns.map(({ width }, ix) => (
          <col key={ix} style={{ width }} />
        ))}
      </colgroup>
      <ItemTableHeader<T>
        columns={columns}
        selectable={selectable && !hide_select_checkbox}
        sort_by={sort_by}
        sort_asc={sort_asc}
        num_selected={value ? 1 : 0}
        onClickSort={handleClickSort}
        onClickSelect={onSelect ? () => onSelect(null) : undefined}
      />
      <TableBody>
        {getSortedItems(items, columns, sort_by, sort_asc).map((item) => {
          const key = getId(item);
          const selected = key === selected_id;
          return (
            (show_unselected || selected_id === null || selected) && (
              <TableRow
                key={key}
                aria-checked={selected}
                hover={selectable}
                role={selectable ? 'checkbox' : undefined}
                onClick={onSelect ? () => onSelect(selected ? null : item) : undefined}
                selected={selected}
                className={classes.row_height}
              >
                {onSelect && !hide_select_checkbox && (
                  <TableCell padding="checkbox">
                    <Checkbox checked={selected} />
                  </TableCell>
                )}
                {columns.map(
                  ({ getValue, formatValue, onClickCell, align = 'left' }: ColumnConfig<T>, ix) => {
                    const value = getValue(item);
                    const formatted = formatValue ? formatValue(value, item) : value;
                    const clickable =
                      onClickCell && (value !== undefined && value !== null && formatted);
                    return (
                      <TableCell
                        key={ix}
                        align={align}
                        className={`${classes.body_text} ${clickable ? 'clickable' : ''} ${
                          onSelect ? '' : classes.noOnSelectStyles
                        }`}
                        onClick={clickable && onClickCell ? () => onClickCell(item) : undefined}
                      >
                        {formatted}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            )
          );
        })}
      </TableBody>
    </Table>
  );
};
