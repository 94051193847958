import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Snackbar,
  SnackbarContent,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import { makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import {
  AccountFragmentFragment,
  ArchiveUnArchiveType,
  BargeFragmentFragment,
  ContainerType,
  GrainBinFragmentFragment,
  UserRole,
  withGetAccountBargesHoc,
  WithGetAccountBargesHocChildProps,
  withGetAccountGrainBinsHoc,
  WithGetAccountGrainBinsHocChildProps,
} from '../../api';
import {
  withArchiveUnArchiveGrainContainerHoc,
  withArchiveUnArchiveGrainContainerHocChildProps,
} from '../../api/graphql/hoc/withArchiveUnArchiveGrainContainerHoc';
import { ContainerTypeLegacy, getDistanceUnitLabel, getDistanceUnitSystem } from '../../util';
import { formatContainerType } from '../../util/format-container-type';
import formatGrainType from '../../util/format-grain-type';
import { getUserPermissionTooltipText } from '../grain-container/aeration';
import { DialogSpinner } from '../spinner';
import { ColumnConfig, ItemTable } from '../util';

const useStyles = makeStyles({
  centered: {
    textAlign: 'center',
  },
  grid: {
    width: '100%',
    padding: 20,
  },
  deleteAlert: {
    fontSize: 16,
  },
  snackBarNotif: {
    background: 'rgb(76, 175, 80)',
  },
});

const formatDateTime = (value) => value.toFormat('ff');
const formatDistance = (value): number => Number(value.toFixed(2));

export const UserOrgSettings = ({
  account_id,
  mobile_width,
  account,
  viewer_role,
}: {
  account_id: number;
  mobile_width?: boolean;
  account: AccountFragmentFragment;
  viewer_role: UserRole | undefined;
}) => {
  return (
    <>
      <UserOrgSettingsArchivedContainersLayout
        account_id={account_id}
        mobile_width={mobile_width}
        account={account}
        viewer_role={viewer_role}
      />
    </>
  );
};

export const UserOrgSettingsArchivedContainersBase = ({
  viewer_role,
  archiveUnArchiveGrainContainer,
  grain_bin_links,
  loading = false,
  refetchGrainBinAccountLinks,
  mobile_width = false,
  barge_links,
  refetchBargeAccountLinks,
  account,
}: {
  mobile_width?: boolean;
  viewer_role: UserRole | undefined;
} & withArchiveUnArchiveGrainContainerHocChildProps &
  WithGetAccountGrainBinsHocChildProps &
  WithGetAccountBargesHocChildProps & { account: AccountFragmentFragment }) => {
  const classes = useStyles();
  const [open, setOpen] = useState({ show: false, message: '' });

  const grain_bins: GrainBinFragmentFragment[] = grain_bin_links.map(
    (grain_bin_link) => grain_bin_link.grain_bin
  );
  const filtered_archived_grain_bins: GrainBinFragmentFragment[] = grain_bins.filter(
    (bin) => bin.archived
  );
  const barges = barge_links.map((barge_links) => barge_links.barge);
  const filtered_archived_barges: BargeFragmentFragment[] = barges.filter(
    (barge) => barge.archived
  );
  const expand_bin_accordion =
    filtered_archived_grain_bins && filtered_archived_grain_bins.length > 0;
  const expand_barge_accordion = barges && barges.length > 0;

  if (loading) {
    return <DialogSpinner title="Loading archived grain containers..." open={loading} />;
  }
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen({ show: false, message: '' });
  };
  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {filtered_archived_grain_bins && filtered_archived_grain_bins.length ? (
        <UserOrgSettingsArchivedBins
          archiveUnArchiveGrainContainer={archiveUnArchiveGrainContainer}
          grain_bin_links={grain_bin_links}
          loading={loading}
          mobile_width={mobile_width}
          refetchGrainBinAccountLinks={refetchGrainBinAccountLinks}
          expand_accordion={expand_bin_accordion}
          filtered_archived_grain_bins={filtered_archived_grain_bins}
          setOpen={setOpen}
          viewer_role={viewer_role}
        />
      ) : (
        <UserOrgSettingsArchivedContainerEmpty
          container_type={ContainerTypeLegacy.bin}
          support_container_type={account.grain_bin_support}
        />
      )}
      {filtered_archived_barges && filtered_archived_barges.length ? (
        <UserOrgSettingsArchivedBarges
          archiveUnArchiveGrainContainer={archiveUnArchiveGrainContainer}
          barge_links={barge_links}
          loading={loading}
          mobile_width={mobile_width}
          refetchBargeAccountLinks={refetchBargeAccountLinks}
          expand_accordion={expand_barge_accordion}
          filtered_archived_barges={filtered_archived_barges}
          setOpen={setOpen}
          viewer_role={viewer_role}
        />
      ) : (
        <UserOrgSettingsArchivedContainerEmpty
          container_type={ContainerTypeLegacy.barge}
          support_container_type={account.barge_support}
        />
      )}
      <Snackbar open={open.show} autoHideDuration={6000} onClose={handleClose}>
        <SnackbarContent className={classes.snackBarNotif} message={open.message} action={action} />
      </Snackbar>
    </>
  );
};

export const UserOrgSettingsArchivedContainerEmpty = ({
  container_type,
  support_container_type,
}: {
  container_type: ContainerTypeLegacy;
  support_container_type: boolean;
}) => {
  const classes = useStyles();
  return (
    <>
      {support_container_type && (
        <Grid
          container
          direction="row"
          alignContent="flex-start"
          alignItems="center"
          justify="center"
          spacing={2}
          className={classes.grid}
        >
          <Typography className={classes.centered} variant="h6">
            There are no archived {formatContainerType(container_type)}s
          </Typography>
        </Grid>
      )}
    </>
  );
};

export const UserOrgSettingsArchivedBins = ({
  archiveUnArchiveGrainContainer,
  filtered_archived_grain_bins,
  refetchGrainBinAccountLinks,
  mobile_width = false,
  expand_accordion,
  setOpen,
  viewer_role,
}: {
  mobile_width?: boolean;
  expand_accordion: boolean;
  filtered_archived_grain_bins: GrainBinFragmentFragment[];
  setOpen: (open) => void;
  viewer_role: UserRole | undefined;
} & withArchiveUnArchiveGrainContainerHocChildProps &
  WithGetAccountGrainBinsHocChildProps) => {
  const distanceUnitLabel = getDistanceUnitLabel();
  const [isUpdating, setUpdating] = useState(false);
  const [isExpanded, setExpanded] = useState(expand_accordion);
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const classes = useStyles();
  if (isUpdating) {
    return <DialogSpinner title="Unarchiving grain bin..." open={isUpdating} />;
  }

  const handleIsExpanded = () => {
    setExpanded((prev) => !prev);
  };

  const handleUnArchiveIconClick = async (grain_bin: GrainBinFragmentFragment) => {
    setUpdating(true);
    const variables = {
      container_id: grain_bin.grain_bin_id,
      container_type: ContainerType.Bin,
      archive_unarchive_flag: ArchiveUnArchiveType.Unarchive,
    };

    await archiveUnArchiveGrainContainer(variables)
      .then(refetchGrainBinAccountLinks)
      .then(() => setUpdating(false))
      .then(() => setOpen({ show: true, message: `Bin ${grain_bin.bin_name} unarchived` }));
  };
  const getId = ({ grain_bin_id }) => grain_bin_id;
  const getUserColumn = (handleUnArchiveIconClick, browserTimezone, is_mobile, viewer_role) => {
    const distanceUnitSystem = getDistanceUnitSystem();
    const disabledTooltipText = [UserRole.FanAccess, UserRole.ReadOnly].includes(viewer_role)
      ? getUserPermissionTooltipText(viewer_role)
      : '';
    const user_columns: ColumnConfig<GrainBinFragmentFragment>[] = [];
    user_columns.push({
      title: 'Name',
      width: 20,
      align: 'left',
      getValue: ({ bin_name }) => `${bin_name}`,
    });
    user_columns.push({
      title: 'Grain Type',
      width: 15,
      align: 'left',
      getValue: ({ grain_type }) => `${formatGrainType(grain_type)}`,
    });

    !is_mobile &&
      user_columns.push({
        title: `Eave Height x Bin Diameter (${distanceUnitLabel})`,
        width: 20,
        align: 'left',
        getValue: ({ height_ft, diameter_ft }) =>
          height_ft && diameter_ft
            ? `${formatDistance(height_ft)} x ${formatDistance(diameter_ft)} (${distanceUnitLabel})`
            : `${height_ft} x ${diameter_ft} (${distanceUnitLabel})`,
      });
    user_columns.push({
      title: 'Archived Date',
      width: 20,
      align: 'left',
      getValue: ({ updated_at }) => {
        if (!updated_at) {
          return '';
        }
        const updated_at_date = DateTime.fromMillis(updated_at)
          .setZone(browserTimezone)
          .setLocale('en-US');
        // const updated_at_date = new Date(updated_at);
        return `${formatDateTime(updated_at_date)}`;
      },
    });
    user_columns.push({
      title: 'Unarchive',
      width: 15,
      align: 'left',
      getValue: (grain_bin) => (
        <Tooltip
          enterTouchDelay={0}
          disableFocusListener
          placement="right"
          title={
            disabledTooltipText ? <span style={{ fontSize: 13 }}>{disabledTooltipText}</span> : ''
          }
          arrow
        >
          <div style={{ width: 'fit-content' }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleUnArchiveIconClick(grain_bin);
              }}
              disabled={[UserRole.FanAccess, UserRole.ReadOnly].includes(viewer_role)}
            >
              <UnarchiveOutlinedIcon />
            </IconButton>
          </div>
        </Tooltip>
      ),
    });
    return user_columns;
  };

  return (
    <Grid
      container
      direction="row"
      alignContent="flex-start"
      alignItems="center"
      justify="center"
      spacing={2}
      className={classes.grid}
    >
      <Grid item xs={12}>
        <Accordion expanded={isExpanded} onChange={handleIsExpanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5" className={classes.centered}>
              Archived Grain Bins
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs={12}>
              <div style={{ display: 'border-box', width: '100%' }}>
                <ItemTable
                  items={filtered_archived_grain_bins}
                  columns={getUserColumn(
                    handleUnArchiveIconClick,
                    browserTimezone,
                    !!mobile_width,
                    viewer_role
                  )}
                  getId={getId}
                />
              </div>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export const UserOrgSettingsArchivedBarges = ({
  archiveUnArchiveGrainContainer,
  refetchBargeAccountLinks,
  mobile_width = false,
  expand_accordion,
  filtered_archived_barges,
  setOpen,
  viewer_role,
}: {
  mobile_width?: boolean;
  expand_accordion: boolean;
  filtered_archived_barges: BargeFragmentFragment[];
  setOpen: (open) => void;
  viewer_role: UserRole | undefined;
} & withArchiveUnArchiveGrainContainerHocChildProps &
  WithGetAccountBargesHocChildProps) => {
  const [isUpdating, setUpdating] = useState(false);
  const [isExpanded, setExpanded] = useState(expand_accordion);
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const classes = useStyles();

  if (isUpdating) {
    return <DialogSpinner title="Unarchiving barges..." open={isUpdating} />;
  }

  const handleIsExpanded = () => {
    setExpanded((prev) => !prev);
  };

  const handleUnArchiveIconClick = async (barge: BargeFragmentFragment) => {
    setUpdating(true);
    const variables = {
      container_id: barge.barge_id,
      container_type: ContainerType.Barge,
      archive_unarchive_flag: ArchiveUnArchiveType.Unarchive,
    };

    await archiveUnArchiveGrainContainer(variables)
      .then(refetchBargeAccountLinks)
      .then(() => setUpdating(false))
      .then(() => setOpen({ show: true, message: `Barge ${barge.alias} unarchived` }));
  };
  const getId = ({ barge_id }) => barge_id;
  const getUserColumn = (handleUnArchiveIconClick, browserTimezone, is_mobile, viewer_role) => {
    const user_columns: ColumnConfig<BargeFragmentFragment>[] = [];
    user_columns.push({
      title: 'Name',
      width: 20,
      align: 'left',
      getValue: ({ alias }) => `${alias}`,
    });
    user_columns.push({
      title: 'Grain Type',
      width: 15,
      align: 'left',
      getValue: ({ default_grain_type }) => `${formatGrainType(default_grain_type)}`,
    });
    !is_mobile &&
      user_columns.push({
        title: 'Length x Width x Height (feet)',
        width: 20,
        align: 'left',
        getValue: ({ length_ft, width_ft, height_ft }) =>
          `${length_ft} x ${width_ft} x ${height_ft} (feet)`,
      });
    user_columns.push({
      title: 'Archived Date',
      width: 20,
      align: 'left',
      getValue: ({ updated_at }) => {
        if (!updated_at) {
          return '';
        }
        const updated_at_date = DateTime.fromMillis(updated_at)
          .setZone(browserTimezone)
          .setLocale('en-US');
        // const updated_at_date = new Date(updated_at);
        return `${formatDateTime(updated_at_date)}`;
      },
    });
    user_columns.push({
      title: 'Unarchive',
      width: 15,
      align: 'left',
      getValue: (barge) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleUnArchiveIconClick(barge);
          }}
          disabled={[UserRole.FanAccess, UserRole.ReadOnly].includes(viewer_role)}
        >
          <UnarchiveOutlinedIcon />
        </IconButton>
      ),
    });
    return user_columns;
  };

  return (
    <Grid
      container
      direction="row"
      alignContent="flex-start"
      alignItems="center"
      justify="center"
      spacing={2}
      className={classes.grid}
    >
      <Grid item xs={12}>
        <Accordion expanded={isExpanded} onChange={handleIsExpanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5" className={classes.centered}>
              Archived Barges
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs={12}>
              <div style={{ display: 'border-box', width: '100%' }}>
                <ItemTable
                  items={filtered_archived_barges}
                  columns={getUserColumn(
                    handleUnArchiveIconClick,
                    browserTimezone,
                    mobile_width === true,
                    viewer_role
                  )}
                  getId={getId}
                />
              </div>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export const UserOrgSettingsArchivedContainersLayout = withGetAccountGrainBinsHoc(
  withGetAccountBargesHoc(
    withArchiveUnArchiveGrainContainerHoc(UserOrgSettingsArchivedContainersBase)
  )
);
