import { Button, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { AccessTimeOutlined, ArrowForward, Cancel } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import addDays from 'date-fns/add_days';
import differenceInDays from 'date-fns/difference_in_days';
import format from 'date-fns/format';
import isSameDay from 'date-fns/is_same_day';
import { DateTime } from 'luxon';
import React from 'react';
import { GrainBinFanControllerFragmentFragment } from '../../../api';
import { amber_dark_grey, amber_grey } from '../../../style';
import { RangeState } from '../../../util';
import { Status } from './Status';

const styles = createStyles({
  header: {
    marginBottom: 5,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    height: 20,
  },
  red: {
    color: `${red['500']} !important`,
  },
  green: {
    color: `${green['500']} !important`,
  },
  green_background: {
    backgroundColor: `${green['500']} !important`,
  },
});
const BaseBinWeatherSafety: React.FunctionComponent<
  WithStyles<typeof styles> & {
    safety_weather: boolean;
    onSetFanSafetyWeather: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  }
> = ({ classes, safety_weather, onSetFanSafetyWeather }) => (
  <>
    <h3 className={classes.header}>Bin Settings</h3>
    <FormControlLabel
      classes={{
        root: classes.row,
      }}
      control={
        <Switch
          checked={safety_weather}
          onChange={onSetFanSafetyWeather}
          classes={{
            switchBase: classes.red,
            checked: classes.green,
            track: safety_weather ? classes.green_background : undefined,
          }}
        />
      }
      label={safety_weather ? 'WeatherGuard ON' : 'WeatherGuard OFF'}
      labelPlacement="start"
    />
  </>
);
export const BinWeatherSafety = withStyles(styles)(BaseBinWeatherSafety);

export const formatDate = (dt: DateTime, as_of: DateTime): string => {
  const isItSameDay = (d1, d2) => d1.hasSame(d2, 'day');

  if (isItSameDay(dt, as_of)) {
    return 'Today';
  }

  const diff = as_of.diff(dt, 'days');
  if (dt.toMillis() < as_of.toMillis()) {
    const yesterday = DateTime.local(as_of).plus({ days: -1 });
    if (isItSameDay(dt, yesterday)) {
      return 'Yesterday';
    }
  }

  if (dt.toMillis() > as_of.toMillis()) {
    const tomorrow = DateTime.local(as_of).plus({ days: 1 });
    if (isItSameDay(dt, tomorrow)) {
      return 'Tomorrow';
    }
    if (diff < 6) {
      return dt.toFormat('cccc');
    }
    if (diff === 7 && dt.weekDay !== as_of.weekDay) {
      return dt.toFormat('cccc');
    }
  }

  return dt.toFormat('DD');
};

// TODO Deprecate--was moved to own file and modified
const aeration_styles = createStyles({
  [RangeState.ok]: { marginTop: 0, marginBottom: 5 },
  [RangeState.good]: { marginTop: 0, marginBottom: 5, color: green['500'] },
  card_text: { textAlign: 'left', paddingLeft: 36, paddingRight: 36, paddingTop: 0 },
  item: { display: 'inline-block', verticalAlign: 'middle' },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 10,
  },
  icon: { paddingLeft: 10, paddingRight: 10, height: 36, width: 36 },
  h3: { margin: 0 },
  cancel: {
    marginLeft: 10,
  },
});
const AerationWindow: React.FunctionComponent<
  WithStyles<typeof aeration_styles> & {
    onClickStop: () => void;
    start: Date;
    end: Date;
    as_of: Date;
  }
> = ({ onClickStop, start, end, as_of, classes }) => (
  <div className={classes.row}>
    <div className={classes.item}>
      <div>
        {formatDate(DateTime.fromMillis(start.getTime()), DateTime.fromMillis(as_of.getTime()))}
      </div>
      <h3 className={classes.h3}>{DateTime.fromMillis(start.getTime()).toFormat('h:mm a')}</h3>
    </div>
    <ArrowForward className={classes.icon} />
    <div className={classes.item}>
      <div>
        {formatDate(DateTime.fromMillis(end.getTime()), DateTime.fromMillis(as_of.getTime()))}
      </div>
      <h3 className={classes.h3}>{DateTime.fromMillis(end.getTime()).toFormat('h:mm a')}</h3>
    </div>
    <Button variant="outlined" className={classes.cancel} onClick={onClickStop}>
      <Cancel />
      <Typography variant="h6" style={{ color: amber_dark_grey }} className={classes.cancel}>
        CANCEL
      </Typography>
    </Button>
  </div>
);

const StyledAerationWindow = withStyles(aeration_styles)(AerationWindow);

const bin_aeration_styles = createStyles({
  typography: {
    width: 'fit-content',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 15,
    marginLeft: 0,
    width: 'fit-content',
  },
  icon: {
    marginRight: 10,
  },
});
const BaseBinAeration: React.FunctionComponent<
  WithStyles<typeof bin_aeration_styles> & {
    enabled: boolean;
    grain_bin_fan_controllers: GrainBinFanControllerFragmentFragment[];
    disable_apply_recs: boolean;
    has_recs: boolean;
    run_window: { start_epoch: Date; end_epoch: Date } | null;
    as_of: Date;
    handleSchedule: () => void;
    onClickStop: () => void;
    onClickApplyRecs: () => void;
  }
> = ({
  enabled,
  disable_apply_recs,
  has_recs,
  run_window,
  as_of,
  grain_bin_fan_controllers,
  handleSchedule,
  onClickStop,
  onClickApplyRecs,
  classes,
}) => {
  const has_schedule = run_window && run_window.end_epoch.getTime() > as_of.getTime();
  const fc_count = grain_bin_fan_controllers.length;
  const overrides: string[] = grain_bin_fan_controllers.reduce(
    (result: string[], { fan_controller: { state_next } }, ix) => {
      if (state_next && state_next.is_override) {
        result.push(`Fan ${ix}`);
      }
      return result;
    },
    []
  );
  const override_count = overrides.length;
  let header;
  if (has_schedule) {
    if (overrides.length === 0) {
      header = '';
    } else if (override_count < fc_count) {
      header = 'On-Site Overrides Active';
    } else {
      header = 'On-Site Overrides Active';
    }
  } else {
    header = 'No Aeration Schedule';
  }

  const schedule_button = (title, handle) => (
    <Button className={classes.button} variant="outlined" onClick={handle}>
      <AccessTimeOutlined className={classes.icon} color="secondary" />
      <Typography className={classes.typography} style={{ color: amber_dark_grey }} variant="h6">
        {title}
      </Typography>
    </Button>
  );

  return (
    <div>
      <Status title="AERATION SCHEDULE" status={header} color={amber_grey} />
      {run_window && has_schedule && fc_count > override_count && (
        <StyledAerationWindow
          start={new Date(run_window.start_epoch)}
          end={new Date(run_window.end_epoch)}
          as_of={as_of}
          onClickStop={onClickStop}
        />
      )}
      {has_schedule && override_count > 0 && fc_count === override_count && (
        <Typography variant="h6" style={{ color: amber_grey, width: 'fit-content' }}>
          Schedule Disabled Due to On-Site Overrides
        </Typography>
      )}

      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
        }}
      >
        {run_window
          ? schedule_button('RESCHEDULE', handleSchedule)
          : schedule_button('RUN LATER', handleSchedule)}
        {has_recs && schedule_button('APPLY RECS', () => onClickApplyRecs)}
      </div> */}
    </div>
  );
};
export const BinAeration = withStyles(bin_aeration_styles)(BaseBinAeration);
