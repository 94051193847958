import { Dialog, DialogContent, DialogTitle, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { createSession, pushNav } from '../../action';
import {
  UserToken,
  ViewerFragmentFragment,
  withCreateTokenHoc,
  WithCreateTokenHocChildProps,
} from '../../api';
import { SessionState } from '../../reducer';
import { DialogSpinner } from '../spinner';
import { BaseForm, FormikWrapper } from '../util/form2/BaseForm';
import { ButtonSubmit } from '../util/form2/Button';
import { ErrorBox } from '../util/form2/ErrorBox';
import { TextField } from '../util/form2/TextField';

type Values = {
  email_address: string;
  password: string;
  general?: string;
};

const useStyles = makeStyles({
  centered: { textAlign: 'center' },
  root: { paddingTop: 0 },
});

const validationSchema = yup.object().shape({
  email_address: yup
    .string()
    .label('Email Address')
    .email('Must be a valid email address')
    .required(),
  password: yup
    .string()
    .label('Password')
    .required(),
});

const selectEmailAddress = ({ session: { email_address } }: { session: SessionState }) =>
  email_address;

export const LoginForm = withCreateTokenHoc(
  ({ createToken, ...props }: WithCreateTokenHocChildProps) => {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const onClickResetPassword = useCallback(
      () => dispatch(pushNav({ path: '/reset_password' })),
      []
    );
    const email_address = useSelector(selectEmailAddress);
    const initial_values = useMemo(() => ({ email_address, password: '' }), [email_address]);
    const submitCallback = useCallback(
      (values) => createToken(validationSchema.validateSync(values)),
      [createToken]
    );
    const submitSuccessCallback = (userToken: UserToken) => {
      localStorage.setItem('token', userToken.token);
      setLoading(true);
      // here reload the page to make renewtoken api call
      window.location.reload();
    };

    if (loading) {
      return <DialogSpinner title="Initializing..." open />;
    }

    return (
      <Dialog open maxWidth="xs">
        <DialogTitle>Log In</DialogTitle>
        <DialogContent>
          <FormikWrapper<Values, UserToken>
            {...props}
            enableReinitialize
            initialValues={initial_values}
            validationSchema={validationSchema}
            onSubmit={submitCallback}
            onSubmitSuccess={submitSuccessCallback}
            render={() => (
              <BaseForm>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  alignContent="flex-start"
                  justify="center"
                  spacing={2}
                >
                  <Grid container item xs={12}>
                    <TextField
                      name="email_address"
                      placeholder="Email Address"
                      label="Email Address"
                      fullWidth
                      type="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="password"
                      placeholder="Password"
                      label="Password"
                      fullWidth
                      type="password"
                    />
                  </Grid>
                  <ErrorBox />
                  <Grid item xs={12}>
                    <Link color="textPrimary" onClick={onClickResetPassword}>
                      Forgot your password?
                    </Link>
                  </Grid>
                  <Grid item xs={12} className={classes.centered}>
                    <ButtonSubmit />
                  </Grid>
                </Grid>
              </BaseForm>
            )}
          />
        </DialogContent>
      </Dialog>
    );
  }
);
