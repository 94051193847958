import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetUserDocument,
  GetUserQuery,
  GetUserQueryVariables,
  UpdateUserRoleMutationVariables as Variables,
  UserFragmentFragment as Result,
  withUpdateUserRole as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUpdateUserRoleHocChildProps = {
  updateUserRole: (variables: Variables) => Promise<Result>;
};
export const withUpdateUserRoleHoc: AmberHoc<{}, WithUpdateUserRoleHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithUpdateUserRoleHocChildProps, typeof component>,
    WithUpdateUserRoleHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updateUserRole: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { updateUser: result } = data;
              if (!result) {
                return;
              }
              proxy.writeQuery<GetUserQuery, GetUserQueryVariables>({
                query: GetUserDocument,
                data: { __typename: 'Query', user: result },
                variables: { user_id: variables.user_id },
              });
              (window as any).localStorage.setItem('email_address', result.email_address);
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.updateUser;
        },
      };
    },
  })(component as any);
