import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import {InfoOutlined as InfoOutlined, PlayArrow as PlayArrowIcon, Replay as ReplayIcon,} from '@material-ui/icons';
import {DateTime} from 'luxon';
import React, {useState} from 'react';
import {
  amber_blue,
  amber_brown,
  amber_dark_grey,
  amber_green,
  amber_grey,
  amber_red,
  amber_sand,
} from '../../style';
import {SummaryCard} from '../grain-container/summary-card';
import {Button} from '../util/form2/Button';
import {CellMetricShowInfoComp, CellMetricShowInfoData} from "./CellMetricShowInfoComp";
import {DIAG_TEST_RESULT_STATUSES, DiagTestResultStatus} from './DiagTests';

const useStyles = makeStyles({
  longTestCardContainer: {
    width: '100%',
    marginBottom: 20,
  },
  testResultCell: {
    fontSize: 14,
    borderBottom: '1px solid rgb(224, 224, 224)',
    padding: 10,
    textAlign: 'center',
  },
  status_heading: {
    marginRight: 10,
    color: amber_dark_grey,
    fontSize: 36,
  },
  confirmButton: {},
  testResultStatusIcon: {
    verticalAlign: 'text-top',
  },
  signal_strength:{
    "Poor": {
      color: amber_red
    },
    "Fair": {
      color: amber_sand
    },
    "Good": {
      color: amber_blue
    },
    "Excellent": {
      color: amber_green
    },
  },
  runOnText:{
    fontSize: 'medium',
    color: 'grey',
    textAlign: 'center',
  },
});


type RefreshCellMetricTestCardProps = {
  core_id: string;
  handleStartCellMetricTest: () => void;
  lastCellMetricTestResult: any;
  currentCellMetricTestResultUIStatus: DiagTestResultStatus | null;
  currentQuickTestResultUIStatus: DiagTestResultStatus | null;
  currentLongTestResultUIStatus: DiagTestResultStatus | null;
  isDiagnosticTestEnabled: boolean;
};

type CellMetricTestResultsMetrics = {
  connection_details: string;
  mobile_network_name: string;
  cellular_quality_value: number;
  cellular_strength_value: number;
  mobile_country_code: number;
  mobile_network_code: number;
  location_area_code: number;
  cell_identity: number;
  connection_strength: string;
  test_type: string;
  created_at: Date;
  updated_at: Date;
  connected: boolean;
};

const connectionStrengthBackgroundColor = {};
connectionStrengthBackgroundColor['Poor']=amber_red;
connectionStrengthBackgroundColor['Fair']=amber_brown;
connectionStrengthBackgroundColor['Good']=amber_blue;
connectionStrengthBackgroundColor['Excellent']=amber_green;

export const RefreshCellMetricTestCard = ({
  core_id,
  handleStartCellMetricTest,
  lastCellMetricTestResult,
  currentCellMetricTestResultUIStatus,
  currentQuickTestResultUIStatus,
  currentLongTestResultUIStatus,
  isDiagnosticTestEnabled,
}: RefreshCellMetricTestCardProps) => {
  const fan_diagnostic_test_label = "Connectivity Test";
  const classes = useStyles();
  const [show_info, setShowInfo] = useState<boolean>(false);
  const [show_info_type, setShowInfoType] = useState<string>();
  const [show_info_data, setShowInfoData] = useState<CellMetricShowInfoData>();

  const lastTestRsultsMetrics: CellMetricTestResultsMetrics | null =
    lastCellMetricTestResult && Object.keys(lastCellMetricTestResult).length > 0
      ? { ...lastCellMetricTestResult.test_result, created_at: lastCellMetricTestResult.created_at, updated_at: lastCellMetricTestResult.last_updated_at }
      : null;
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const last_test_result_run_datetime_at_zone: DateTime | null = lastTestRsultsMetrics
    ? DateTime.fromISO(lastTestRsultsMetrics.updated_at).setZone(browserTimezone)
    : null;
  const isTestRunning = currentCellMetricTestResultUIStatus
    ? currentCellMetricTestResultUIStatus.status === DIAG_TEST_RESULT_STATUSES.RUNNING.status
    : false;

  const isQuickTestRunning = currentQuickTestResultUIStatus
    ? currentQuickTestResultUIStatus.status === DIAG_TEST_RESULT_STATUSES.RUNNING.status
    : false;
  const isLongTestRunning = currentLongTestResultUIStatus
    ? currentLongTestResultUIStatus.status === DIAG_TEST_RESULT_STATUSES.RUNNING.status
    : false;

  const ConnectedTableCellComp = lastTestRsultsMetrics && !lastTestRsultsMetrics.connected ?(<TableCell style={{fontSize: 'medium'}}><span style={{color: amber_red}}><strong>No</strong></span></TableCell>):(
      <TableCell style={{fontSize: 'medium'}}><span style={{color: amber_green}}><strong>Yes</strong></span></TableCell>
  );

  const cellMetricTestButtonText = isTestRunning ? (
    <>
      <CircularProgress style={{ width: 16, height: 16 }} />
      &nbsp;&nbsp;&nbsp;Running
    </>
  ) : (
    <>
      {currentCellMetricTestResultUIStatus &&
      [DIAG_TEST_RESULT_STATUSES.TIMED_OUT.status, DIAG_TEST_RESULT_STATUSES.FAIL.status].includes(
        currentCellMetricTestResultUIStatus.status
      ) ? (
        <>
          <ReplayIcon fontSize="small" />
          &nbsp;Retry {fan_diagnostic_test_label}
        </>
      ) : (
        <>
          <PlayArrowIcon fontSize="small" />
          &nbsp;Run {fan_diagnostic_test_label}
        </>
      )}
    </>
  );

  const handleClose = ()=>{
    setShowInfo(false);
    setShowInfoType('');
  }
  const cell_tower_info_string = lastTestRsultsMetrics?`${lastTestRsultsMetrics.mobile_country_code}-${lastTestRsultsMetrics.mobile_network_code}-${lastTestRsultsMetrics.location_area_code}-${lastTestRsultsMetrics.cell_identity}`:'--';
  return (
    <SummaryCard className={classes.longTestCardContainer} title={fan_diagnostic_test_label} subheaderComp={
      <div>
        <Typography variant="h5" align="left" style={{marginTop: '20px'}}>
          Description:
          <Typography paragraph={true} style={{fontSize: 'large'}}>
            The fan controller communicates with the Amber Cloud over cellular networks. The Connectivity test retrieves the current
            connectivity information from the device.
          </Typography>
        </Typography>
      </div>
    }>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Grid item style={{ marginTop: 20 }}>
          {currentCellMetricTestResultUIStatus && (
            <>
              <Typography variant="h3" className={classes.status_heading}>
                {fan_diagnostic_test_label} {' '}
                <span style={{ color: currentCellMetricTestResultUIStatus.color || amber_grey }}>
                  {currentCellMetricTestResultUIStatus.status}
                </span>
              </Typography>
              {currentCellMetricTestResultUIStatus.status ===
                DIAG_TEST_RESULT_STATUSES.TIMED_OUT.status && (
                <Typography variant="body1" style={{ color: amber_red, marginTop: 10 }}>
                  Sorry, Your {fan_diagnostic_test_label} request timed out! Please try again!
                </Typography>
              )}
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ borderRadius: 20, marginTop: 20, width: 200 }}
            onClick={() => handleStartCellMetricTest()}
            disabled={isTestRunning || !isDiagnosticTestEnabled || isQuickTestRunning || isLongTestRunning}
          >
            {cellMetricTestButtonText}
          </Button>
          <Typography variant="caption" display="block" gutterBottom style={{fontSize:"small", fontStyle:"italic"}}>
            (approx. run time 15 secs)
          </Typography>
        </Grid>
        {!isTestRunning && (
          <>
            {lastTestRsultsMetrics && (
              <div>
                <Typography variant="body1" className={classes.runOnText}>
                  Run on - {last_test_result_run_datetime_at_zone.toFormat('ff')}
                </Typography>

                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{fontSize: 'medium', width:'60%'}}>Connected?</TableCell>
                        {ConnectedTableCellComp}
                      </TableRow>
                      <TableRow>
                        <TableCell style={{fontSize: 'medium', width:'60%'}}>Connection Type </TableCell>
                        <TableCell style={{fontSize: 'medium'}}>{lastTestRsultsMetrics.connection_details} ({lastTestRsultsMetrics.mobile_network_name})</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{fontSize: 'medium', width:'60%'}}>
                          <span>Connection Rating</span>
                          <IconButton edge="end" aria-label="info icon" component="label"
                                      onClick={() => {
                                        setShowInfo(true);
                                        setShowInfoType("cell_strength");
                                        if (lastTestRsultsMetrics.connection_strength!==null){
                                          const show_info_data = {
                                            cell_strength_show_info:{
                                              connection_strength: lastTestRsultsMetrics.connection_strength,
                                              connection_strength_background_color: connectionStrengthBackgroundColor,
                                              connection_details: lastTestRsultsMetrics.connection_details,
                                            }
                                          }
                                          setShowInfoData(show_info_data);
                                        }
                                      }}>
                            <InfoOutlined style={{fontSize: "1.3rem"}} color="action" fontSize="large"/>
                          </IconButton>
                        </TableCell>

                        <TableCell style={{fontSize: 'medium'}}>
                          {lastTestRsultsMetrics.connection_strength!==null?
                              <Chip label={lastTestRsultsMetrics.connection_strength} variant="outlined" style={{background: connectionStrengthBackgroundColor[lastTestRsultsMetrics.connection_strength] || amber_grey, textTransform: 'uppercase', fontSize:'small'}}/>:'--'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{fontSize: 'medium', width:'60%'}}>
                          <span>Cellular Quality</span>
                          <IconButton edge="end" aria-label="info icon" component="label"
                                      onClick={() => {
                                        setShowInfo(true);
                                        setShowInfoType("cell_quality");
                                        if (lastTestRsultsMetrics.cellular_quality_value!==null){
                                          const show_info_data = {
                                            cell_quality_show_info:{
                                              cellular_quality_value: lastTestRsultsMetrics.cellular_quality_value,
                                              connection_strength_background_color: connectionStrengthBackgroundColor,
                                              connection_details: lastTestRsultsMetrics.connection_details,
                                            }
                                          }
                                          setShowInfoData(show_info_data);
                                        }
                                      }}>
                            <InfoOutlined style={{fontSize: "1.3rem"}} color="action" fontSize="large"/>
                          </IconButton>
                        </TableCell>
                        <TableCell style={{fontSize: 'medium'}}>{lastTestRsultsMetrics.cellular_quality_value!==null && lastTestRsultsMetrics.cellular_quality_value!==0?lastTestRsultsMetrics.cellular_quality_value:'--'} dB</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{fontSize: 'medium', width:'60%'}}>
                          <span>Cellular Strength</span>
                          <IconButton edge="end" aria-label="info icon" component="label"
                                      onClick={() => {
                                        setShowInfo(true);
                                        setShowInfoType("cell_strength_dbm");
                                        if (lastTestRsultsMetrics.cellular_strength_value!==null){
                                          const show_info_data = {
                                            cell_strength_dbm_show_info:{
                                              cellular_strength_dbm_value: lastTestRsultsMetrics.cellular_strength_value,
                                              connection_strength_background_color: connectionStrengthBackgroundColor,
                                              connection_details: lastTestRsultsMetrics.connection_details,
                                            }
                                          }
                                          setShowInfoData(show_info_data);
                                        }
                                      }}>
                            <InfoOutlined style={{fontSize: "1.3rem"}} color="action" fontSize="large"/>
                          </IconButton>
                        </TableCell>
                        <TableCell style={{fontSize: 'medium'}}>{lastTestRsultsMetrics.cellular_strength_value!==null?lastTestRsultsMetrics.cellular_strength_value:'--'} dBm</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{fontSize: 'medium', width:'60%'}}>
                          <span>Current Cell Tower</span>
                          <IconButton edge="end" aria-label="info icon" component="label"
                                      onClick={() => {
                                        setShowInfo(true);
                                        setShowInfoType("cell_tower");
                                        if (lastTestRsultsMetrics.mobile_country_code!==null){
                                          const show_info_data = {
                                            cell_tower_show_info:{
                                              cell_tower_info: `${cell_tower_info_string}`,
                                              mobile_country_code: lastTestRsultsMetrics.mobile_country_code,
                                              mobile_network_code: lastTestRsultsMetrics.mobile_network_code,
                                              location_area_code: lastTestRsultsMetrics.location_area_code,
                                              cell_identity: lastTestRsultsMetrics.cell_identity,
                                            }
                                          }
                                          setShowInfoData(show_info_data);
                                        }
                                      }}>
                            <InfoOutlined style={{fontSize: "1.3rem"}} color="action" fontSize="large"/>
                          </IconButton>
                        </TableCell>
                        <TableCell style={{fontSize: 'medium'}}>{lastTestRsultsMetrics.mobile_country_code!==null?cell_tower_info_string:'--'}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
            {
              show_info && show_info_type && show_info_type.length && <CellMetricShowInfoComp cell_metric_show_info_data={show_info_data?show_info_data:null} info_type={show_info_type} handleClose={handleClose}/>
            }
          </>
        )}
      </Grid>
    </SummaryCard>
  );
};
