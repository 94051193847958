import { makeStyles, Radio, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import {
  BlueTickRadioIcon,
  CoolConditionIcon,
  DryConditionIcon,
  ReconditionIcon,
  UncheckedRadioIcon,
} from '../../media/icons';
import { blue_shade_2, light_gray2 } from '../../style';
import { createNavigationTypeFromOptionString } from './FanControlNavigationOption';
import { RecommendedOption } from './RecommendationOptionValue';

const useStyles = makeStyles((theme: Theme) => ({
  //   recommedation input cards
  recommTypeInpCardContainer: {
    width: '100%',
    display: 'flex',
    // direct child
    '& > :not(:last-child)': {
      marginRight: 16,
      [theme.breakpoints.only('xs')]: {
        marginRight: 12,
      },
    },
  },
  recommTypeInpCard: {
    position: 'relative',
    width: '33%',
    backgroundColor: light_gray2,
    borderRadius: 8,
    padding: '8px 16px',
    cursor: 'pointer',
    [theme.breakpoints.only('xs')]: {
      padding: '4px 8px',
    },
  },
  selected: {
    backgroundColor: blue_shade_2,
  },
  recommTypeLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.1rem',
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      fontSize: 12,
    },
  },
  recommTypeIcon: {
    margin: '8px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      margin: '26px 0px 12px 0px',
    },
  },
  recommTypeIconStyles: {
    [theme.breakpoints.only('xs')]: {
      width: 33,
      height: 33,
    },
  },
  recommTypeInput: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  recommTypeInputIcon: {
    [theme.breakpoints.only('xs')]: {
      width: 17,
      height: 17,
    },
  },
}));

export const SelectRecommendationType = ({
  defaultRecommendationType,
  setRecommendation,
  navigation,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
  const [recommendationType, setRecommendationType] = useState(defaultRecommendationType);
  const { setFieldValue } = useFormikContext<any>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecommendationType(event.target.value);
    setFieldValue('recommendation_type', event.target.value);
    setRecommendation(
      createNavigationTypeFromOptionString(navigation.navigationType, event.target.value)
    );
  };

  const handleRecommendationTypeClickChange = (value) => {
    setRecommendationType(value);
    setFieldValue('recommendation_type', value);
    setRecommendation(createNavigationTypeFromOptionString(navigation.navigationType, value));
  };

  return (
    <div className={classes.recommTypeInpCardContainer}>
      <div
        className={`${classes.recommTypeInpCard} ${
          recommendationType === RecommendedOption.COOLING ? classes.selected : undefined
        }`}
        onClick={() => handleRecommendationTypeClickChange(RecommendedOption.COOLING)}
      >
        <div className={classes.recommTypeIcon}>
          <CoolConditionIcon className={classes.recommTypeIconStyles} />
        </div>
        <div className={classes.recommTypeInput}>
          <Radio
            checked={recommendationType === RecommendedOption.COOLING}
            onChange={handleChange}
            value={RecommendedOption.COOLING}
            name="recommendation_type"
            inputProps={{ 'aria-label': 'Cool' }}
            icon={<UncheckedRadioIcon className={classes.recommTypeInputIcon} />}
            checkedIcon={<BlueTickRadioIcon className={classes.recommTypeInputIcon} />}
          />
        </div>
        <div className={classes.recommTypeLabel}>Cool</div>
      </div>
      <div
        className={`${classes.recommTypeInpCard} ${
          recommendationType === RecommendedOption.DRYING ? classes.selected : undefined
        }`}
        onClick={() => handleRecommendationTypeClickChange(RecommendedOption.DRYING)}
      >
        <div className={classes.recommTypeIcon}>
          <DryConditionIcon className={classes.recommTypeIconStyles} />
        </div>
        <div className={classes.recommTypeInput}>
          <Radio
            checked={recommendationType === RecommendedOption.DRYING}
            onChange={handleChange}
            value={RecommendedOption.DRYING}
            name="recommendation_type"
            inputProps={{ 'aria-label': 'Dry' }}
            icon={<UncheckedRadioIcon className={classes.recommTypeInputIcon} />}
            checkedIcon={<BlueTickRadioIcon className={classes.recommTypeInputIcon} />}
          />
        </div>
        <div className={classes.recommTypeLabel}>Dry</div>
      </div>
      <div
        className={`${classes.recommTypeInpCard} ${
          recommendationType === RecommendedOption.RECONDITIONING ? classes.selected : undefined
        }`}
        onClick={() => handleRecommendationTypeClickChange(RecommendedOption.RECONDITIONING)}
      >
        <div className={classes.recommTypeIcon}>
          <ReconditionIcon className={classes.recommTypeIconStyles} />
        </div>
        <div className={classes.recommTypeInput}>
          <Radio
            checked={recommendationType === RecommendedOption.RECONDITIONING}
            onChange={handleChange}
            value={RecommendedOption.RECONDITIONING}
            name="recommendation_type"
            inputProps={{ 'aria-label': 'Recondition' }}
            icon={<UncheckedRadioIcon className={classes.recommTypeInputIcon} />}
            checkedIcon={<BlueTickRadioIcon className={classes.recommTypeInputIcon} />}
          />
        </div>
        <div className={classes.recommTypeLabel}>Recondition</div>
      </div>
    </div>
  );
};
