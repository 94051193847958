import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy, RelativePeriods } from '../../../util';
import {
  GrainBinLevelHistory,
  withGetGrainContainerHistoryGrainLevel as HOC,
} from '../__generated';

export type WithGetGrainContainerHistoryGrainLevelHocOwnProps = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  as_of: Date;
  period: RelativePeriods;
  pollInterval?: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainContainerHistoryGrainLevelHocChildProps = {
  loading: boolean;
  grain_bin_level_history: GrainBinLevelHistory;
};
export const withGetGrainContainerHistoryGrainLevelHoc: AmberHoc<
  WithGetGrainContainerHistoryGrainLevelHocOwnProps,
  WithGetGrainContainerHistoryGrainLevelHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainContainerHistoryGrainLevelHocOwnProps,
      WithGetGrainContainerHistoryGrainLevelHocChildProps,
      typeof component
    >,
    WithGetGrainContainerHistoryGrainLevelHocChildProps
  >({
    options: ({
      container_id,
      container_type,
      as_of,
      period,
      onError,
      pollInterval = 1000 * 60 * 30,
    }) => ({
      onError,
      pollInterval,
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id, container_type, as_of, period },
      notifyOnNetworkStatusChange: true,
    }),
    props: ({ data, ownProps: { loading } }) => {
      let grain_bin_level_history: GrainBinLevelHistory = {
        period: '1D',
        max_bushels: 0,
        active_hubs_level_samples: [],
        __typename: 'GrainBinLevelHistory',
      };

      if (!data || data.loading) {
        return {
          grain_bin_level_history,
          loading: true,
        };
      }

      const { grain_container } = data;
      if (grain_container) {
        ({ grain_bin_level_history } = grain_container);
      }

      return {
        grain_bin_level_history,
        loading: loading || false,
      };
    },
  })(component as any);
