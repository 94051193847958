import { Button, DialogContent, Grid, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  BaseDialog,
  ContainerTypeLegacy,
  FanSettingsFragmentFragment,
  GrainBinFragmentFragment,
  pushNav,
  UserRole,
} from '../../../../../core/src';
import { SummaryCard } from '../summary-card';
import { FanControllerWithError } from './FanControllerErrorDisplay';
import { FanControls } from './FanControls';
import { FanRunHistoryMoreInfo } from './FanRunHistoryMoreInfo';

export const USER_ACCESS_TEXT = {
  [UserRole.ReadOnly]: 'Read Only User',
  [UserRole.FanAccess]: 'Fan Access User',
  [UserRole.Admin]: 'Admin User',
  [UserRole.FullAccess]: 'FullAccess User',
};

export const getUserPermissionTooltipText = (userRole) => {
  const userAccessText = USER_ACCESS_TEXT[userRole];
  return userAccessText ? `${userAccessText}: Insufficient Permissions` : '';
};

const useStyles = makeStyles((theme: Theme) => ({
  full_width: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
  min_height: { minHeight: 419 },
}));

export const FanControlsCard: FunctionComponent<{
  fan_running: boolean;
  grain_bin_id: number;
  has_override: boolean;
  mapped_errors: FanControllerWithError[] | null;
  container_type: ContainerTypeLegacy;
  storage_period?: {
    grain_bin_storage_cycle_id: number;
  } | null;
  grain_bin_fan_settings: FanSettingsFragmentFragment | null;
  loading: boolean;
  grain_bin: GrainBinFragmentFragment | null;
  user_id: number;
  current_account_id: number;
  refetchGrainBin: () => Promise<any>;
  show_fan_guidance_ext_prompt: boolean;
  setShowFanGuidanceExtPrompt: (show_fan_guidance_ext) => void;
  role: UserRole;
  url_base: string;
  cfmPerBu: number | null;
  fullBinCfmPerBu: number | null;
}> = ({
  fan_running,
  grain_bin_id,
  has_override,
  mapped_errors,
  container_type,
  storage_period,
  grain_bin_fan_settings,
  loading,
  grain_bin,
  user_id,
  current_account_id,
  refetchGrainBin,
  show_fan_guidance_ext_prompt,
  setShowFanGuidanceExtPrompt,
  role,
  cfmPerBu,
  fullBinCfmPerBu,
  url_base,
}) => {
  const classes = useStyles();
  const disabledTooltipText = role === UserRole.ReadOnly ? getUserPermissionTooltipText(role) : '';
  const dispatch = useDispatch();
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const goToManageGrainTickets = () => dispatch(pushNav({ path: `${url_base}/tickets` }));

  return (
    <Tooltip
      enterTouchDelay={0}
      disableFocusListener
      placement="bottom"
      title={disabledTooltipText ? <span style={{ fontSize: 13 }}>{disabledTooltipText}</span> : ''}
      arrow
    >
      <div>
        <SummaryCard
          title="Fan Controls"
          style={{
            opacity: role === UserRole.ReadOnly ? 0.5 : undefined,
            pointerEvents: role === UserRole.ReadOnly ? 'none' : 'initial',
          }}
          contentStyle={{ paddingBottom: 14 }}
        >
          <Grid container className={classes.min_height}>
            <Grid item xs={12} className={classes.full_width}>
              <FanControls
                loading={loading}
                grain_bin={grain_bin}
                refechGrainBin={refetchGrainBin}
                has_override={has_override}
                any_fans_running={fan_running}
                grain_bin_id={grain_bin_id}
                mapped_errors={mapped_errors}
                container_type2={container_type}
                storage_period={storage_period}
                grain_bin_fan_settings={grain_bin_fan_settings}
                user_id={user_id}
                current_account_id={current_account_id}
                show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
                setShowFanGuidanceExtPrompt={setShowFanGuidanceExtPrompt}
                viewerRole={role}
                cfmPerBu={cfmPerBu}
                goToManageGrainTickets={goToManageGrainTickets}
                fullBinCfmPerBu={fullBinCfmPerBu}
              />
            </Grid>
          </Grid>
          <BaseDialog
            title="Airflow Info"
            open={showMoreInfo}
            handleClose={() => setShowMoreInfo(false)}
          >
            <DialogContent style={{ textAlign: 'start' }}>
              <FanRunHistoryMoreInfo
                grain_bin_id={grain_bin_id}
                cfmPerBu={cfmPerBu}
                goToManageGrainTickets={goToManageGrainTickets}
                fullBinCfmPerBu={fullBinCfmPerBu}
              />
            </DialogContent>
          </BaseDialog>
        </SummaryCard>
      </div>
    </Tooltip>
  );
};
