import { OpsViewVisibilityPrefsFragmentFragment } from '../../api';
import { UNASSIGNED_BINS_SITE_ID } from './manage-sites';
import { PrefJsonItem } from './manage-sites/ManageSitesTable';

const performColsAlterPrefChanges = (
  params: any,
  operations_view_cols_visibility_prefs: OpsViewVisibilityPrefsFragmentFragment | null,
  isWideScreen: boolean
) => {
  const columnState = params.columnApi.getColumnState();
  console.log('columnState', columnState);
  const columnsAlterPref = operations_view_cols_visibility_prefs
    ? JSON.parse(operations_view_cols_visibility_prefs.pref_json).columns
    : null;
  console.log('columnsAlterPref', { columnState, columnsAlterPref });

  if (columnsAlterPref) {
    const columnStateBasedOnPref = columnsAlterPref.reduce((acc, pref) => {
      const { colId: col_id, hide } = pref;
      const colCurrentState = columnState.filter(({ colId }) => String(colId).startsWith(col_id));
      const colNewState = colCurrentState.map((state) => ({
        ...state,
        hide,
        pinned: isWideScreen && state.colId === 'ag-Grid-AutoColumn' ? true : false,
      }));
      acc.push(...colNewState);
      return acc;
    }, []);
    console.log('columnStateBasedOnPref', columnStateBasedOnPref);
    params.columnApi.applyColumnState({ state: columnStateBasedOnPref, applyOrder: true });
  }
};
const performSitesRowPrefChanges = ({
  gridApi,
  groupBySites,
  setRowDataForGroupBySites,
  setRowDataForBinsOnly,
  valid_operations_view_rows_visibility_prefs,
}: {
  gridApi: any;
  groupBySites: boolean;
  setRowDataForGroupBySites: React.Dispatch<any[]>;
  setRowDataForBinsOnly: React.Dispatch<any[]>;
  valid_operations_view_rows_visibility_prefs: PrefJsonItem[];
}) => {
  console.log(
    'valid_operations_view_rows_visibility_prefs',
    valid_operations_view_rows_visibility_prefs
  );
  if (valid_operations_view_rows_visibility_prefs) {
    const newRowDataAfterPrefs = getNewRowDataAfterPrefs({
      gridApi,
      valid_operations_view_rows_visibility_prefs,
      groupBySites,
    });
    console.log('newRowDataAfterPrefs', newRowDataAfterPrefs);
    groupBySites
      ? setRowDataForGroupBySites(newRowDataAfterPrefs)
      : setRowDataForBinsOnly(newRowDataAfterPrefs);
  }
};

const getNewRowDataAfterPrefs = ({
  gridApi,
  valid_operations_view_rows_visibility_prefs,
  groupBySites,
}: {
  gridApi: any;
  valid_operations_view_rows_visibility_prefs: PrefJsonItem[];
  groupBySites: boolean;
}): any[] => {
  const allBinsRows: any[] = [];
  const allSiteRows: any[] = [];
  gridApi &&
    gridApi.forEachNode((rowNode, index) => {
      const rowData = rowNode.data;
      if (rowData.grain_bin_id !== undefined) {
        allBinsRows.push(rowData);
      } else {
        allSiteRows.push(rowData);
      }
    });
  console.log('allBinsRows & allSiteRows', { gridApi, allBinsRows, allSiteRows });
  return valid_operations_view_rows_visibility_prefs.reduce(
    (acc: any[], sitePref: PrefJsonItem) => {
      const binsRows: any[] = [];
      for (const bin of sitePref.bins) {
        if (bin.hide) continue;
        const binRow = allBinsRows.find((it) => it.grain_bin_id === bin.bin_id);
        binRow && binsRows.push(binRow);
      }

      // Don't show UNASSIGNED_BINS site if it's not contain any bins
      if (sitePref.site_id === UNASSIGNED_BINS_SITE_ID && binsRows.length === 0) return acc;
      const site = allSiteRows.find((it) => it.site_id === sitePref.site_id);
      const siteBinsRows = site ? [site, ...binsRows] : [];
      groupBySites ? acc.push(...siteBinsRows) : acc.push(...binsRows);
      return acc;
    },
    []
  );
};

const getHasScheduledRunInterrupted = (original, updated): boolean => {
  const now = new Date().getTime();
  const scheduled_start =
    updated.aeration_schedule && updated.aeration_schedule[0]
      ? updated.aeration_schedule[0].start_epoch
      : null;
  const scheduled_end =
    updated.aeration_schedule && updated.aeration_schedule[0]
      ? updated.aeration_schedule[0].end_epoch
      : null;
  const has_current_overlap_runwindow = Boolean(
    now &&
      scheduled_start &&
      scheduled_end &&
      now > new Date(scheduled_start).getTime() &&
      now < new Date(scheduled_end).getTime()
  );
  const has_scheduled_run_interrupted: boolean = Boolean(
    !original.automation_info.enabled &&
      !original.any_fan_running &&
      has_current_overlap_runwindow &&
      original.has_any_fan_ready
  );

  return has_scheduled_run_interrupted;
};

const getValidOperationsViewRowsVisibilityPrefs = ({
  operations_view_rows_visibility_prefs,
  unassignedBinsIds,
  allActiveSitesIds,
}: {
  operations_view_rows_visibility_prefs: any;
  unassignedBinsIds: number[];
  allActiveSitesIds: number[];
}): PrefJsonItem[] | null => {
  let valid_operations_view_rows_visibility_prefs: PrefJsonItem[] | null = null;
  if (operations_view_rows_visibility_prefs) {
    const operations_view_rows_prefs_parsed = operations_view_rows_visibility_prefs
      ? JSON.parse(operations_view_rows_visibility_prefs.pref_json)
      : null;

    const operations_view_rows_prefs = operations_view_rows_prefs_parsed
      ? operations_view_rows_prefs_parsed.sites
      : null;

    const operations_view_rows_prefs_with_newly_created_bins_pref = operations_view_rows_prefs
      ? operations_view_rows_prefs.map(({ bins, site_id }) => {
          const existingSiteBinIds = bins.map(({ bin_id }) => bin_id);
          if (site_id === UNASSIGNED_BINS_SITE_ID) {
            const newly_created_bins_pref = unassignedBinsIds
              .filter((binId) => !existingSiteBinIds.includes(binId))
              .map((binId) => ({
                hide: false,
                bin_id: binId,
              }));
            return {
              site_id,
              bins: [...newly_created_bins_pref, ...bins],
            };
          }
          return {
            bins,
            site_id,
          };
        })
      : null;

    console.log('operations_view_rows_prefs', {
      operations_view_rows_prefs,
      unassignedBinsIds,
      operations_view_rows_prefs_with_newly_created_bins_pref,
    });

    valid_operations_view_rows_visibility_prefs = operations_view_rows_prefs_with_newly_created_bins_pref
      ? operations_view_rows_prefs_with_newly_created_bins_pref.filter((site) =>
          allActiveSitesIds.includes(site.site_id)
        )
      : null;
  }
  return valid_operations_view_rows_visibility_prefs;
};

export {
  performColsAlterPrefChanges,
  performSitesRowPrefChanges,
  getHasScheduledRunInterrupted,
  getValidOperationsViewRowsVisibilityPrefs,
};
