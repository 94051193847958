import { DateTime } from 'luxon';
import React from 'react';
import { FanControllerRunWindow } from '../../../../../core/src/api';
import { MediumErrorIcon, MediumRunPauseIcon } from '../../../../../core/src/media';
import SkeletonLoader from '../../util/SkeletonLoader';
import { SiteDetails } from './SiteDetails';
import { withSkeleton } from './withCellSkeleton';

const formatTime = (value: DateTime) => value.toLocaleString(DateTime.TIME_SIMPLE);

export const formatDateTime = (
  date: Date,
  tz: string
): { dateString: string; timeString: string } => ({
  dateString: `${DateTime.fromMillis(new Date(date).getTime())
    .setZone(tz)
    .toFormat('DD')}`,
  timeString: `${formatTime(DateTime.fromMillis(new Date(date).getTime()).setZone(tz))}`,
});

const FanScheduleStartCellRenderer = withSkeleton((props) => {
  const aerationSchedule = props.value;
  const grainTimezone = props.data.grain_bin_location_timezone;
  console.log('props in FanScheduleStartCellRenderer', props);

  if (props.value === undefined) {
    return <SiteSummary {...props} />;
  }

  const hasFanFailToStartError = Boolean(props.data.has_fan_start_fail_error);
  const hasFanFailToStopError = Boolean(props.data.has_fan_stop_fail_error);
  const hasNoSchedule = aerationSchedule.length === 0;
  const hasFanStartStopError = hasFanFailToStartError || hasFanFailToStopError;

  const hasScheduledRunInterrupted = props.data.has_scheduled_run_interrupted;
  const showOverlay = Boolean(
    hasFanStartStopError || (hasScheduledRunInterrupted && !hasFanStartStopError)
  );

  if (hasNoSchedule) {
    return (
      <div>
        {hasFanStartStopError && <FanErrorIndicator />}
        {hasScheduledRunInterrupted && !hasFanStartStopError && <RunInterruptedIndicator />}
        <div style={{ opacity: showOverlay ? 0.3 : undefined }}>No Schedule</div>
      </div>
    );
  }

  const nextSchedule = aerationSchedule[0];
  const { dateString, timeString } = formatDateTime(
    new Date(nextSchedule.start_epoch),
    grainTimezone
  );

  return (
    <div>
      {hasFanStartStopError && <FanErrorIndicator />}
      {hasScheduledRunInterrupted && !hasFanStartStopError && <RunInterruptedIndicator />}
      <div style={{ opacity: showOverlay ? 0.3 : undefined }}>
        {dateString}
        <br />
        {timeString}
      </div>
    </div>
  );
});

const SiteSummary = (props) => {
  const areAllChildrenLoadedWithFanScheduleData =
    props &&
    props.node.childrenAfterFilter
      .map(({ data }) => data)
      .every((data) => data['fan_schedule'] !== 'loading');

  if (!areAllChildrenLoadedWithFanScheduleData) {
    return <SkeletonLoader />;
  }

  const childrenRowsDataAfterFilter = props.node.childrenAfterFilter.map(({ data }) => data);
  const childrenUpcomingFanSchedules: FanControllerRunWindow[] = childrenRowsDataAfterFilter.reduce(
    (acc, data) => {
      if (data.fan_schedule.length !== 0) acc.push(data.fan_schedule[0]);
      return acc;
    },
    []
  );
  const siteUpcomingSchedule =
    childrenUpcomingFanSchedules.length > 0
      ? childrenUpcomingFanSchedules.sort(
          (a, b) => new Date(a.start_epoch).getTime() - new Date(b.start_epoch).getTime()
        )[0]
      : null;

  const erroredFanBinsName: string[] = [];
  const { erroredFanBinsCount, runInterruptedBinsCount } = childrenRowsDataAfterFilter.reduce(
    (acc, data) => {
      if (data.has_fan_stop_fail_error || data.has_fan_start_fail_error) {
        erroredFanBinsName.push(data.bin_name);
        acc.erroredFanBinsCount += 1;
      }
      if (data.has_scheduled_run_interrupted) {
        acc.runInterruptedBinsCount += 1;
      }
      return acc;
    },
    { erroredFanBinsCount: 0, runInterruptedBinsCount: 0 }
  );

  console.log('erroredFanBinsCount, erroredFanBinsName & runInterruptedBinsCount', {
    erroredFanBinsCount,
    erroredFanBinsName,
    runInterruptedBinsCount,
  });

  const showOverlay = Boolean(
    erroredFanBinsCount || (runInterruptedBinsCount && erroredFanBinsCount === 0)
  );

  const erroredFanMsg: string | null =
    erroredFanBinsCount > 0
      ? erroredFanBinsCount === 1 && erroredFanBinsName.length === 1
        ? `${erroredFanBinsName[0]}`
        : `${erroredFanBinsCount} Bins`
      : null;

  return (
    <SiteDetails {...props}>
      <div>
        {erroredFanMsg && (
          <div
            style={{
              width: '100%',
              height: '100%',
              fontSize: 12,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              top: '50%',
              left: '50%',
              zIndex: 1,
              fontWeight: 600,
            }}
          >
            <MediumErrorIcon style={{ marginRight: 6 }} />
            <div
              style={{
                fontWeight: 600,
                fontSize: 12,
                width: erroredFanMsg.length >= 25 ? 150 : undefined,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                textAlign: 'start',
              }}
            >
              {erroredFanMsg}
            </div>
          </div>
        )}

        {runInterruptedBinsCount > 0 && erroredFanBinsCount === 0 && (
          <div
            style={{
              width: '100%',
              height: '100%',
              fontSize: 12,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              top: '50%',
              left: '50%',
              zIndex: 1,
              fontWeight: 600,
            }}
          >
            <MediumRunPauseIcon style={{ marginRight: 4 }} />
            <div style={{ fontWeight: 600, fontSize: 12, lineHeight: 1.2 }}>
              Scheduled Run Interrupted ({runInterruptedBinsCount}{' '}
              {runInterruptedBinsCount === 1 ? 'Bin' : 'Bins'})
            </div>
          </div>
        )}

        {!showOverlay && (
          <div
            style={{
              fontSize: 12,
              color: 'black',
              fontWeight: 600,
            }}
          >
            {siteUpcomingSchedule
              ? getScheduleInfoText(siteUpcomingSchedule)
              : 'No Upcoming Schedules'}
          </div>
        )}
      </div>
    </SiteDetails>
  );
};

const getScheduleInfoText = (schedule: FanControllerRunWindow): string => {
  let scheduleInfo = '';
  const { start_epoch, end_epoch } = schedule;
  const start = DateTime.fromMillis(new Date(start_epoch).getTime());
  const end = DateTime.fromMillis(new Date(end_epoch).getTime());
  const asOf = DateTime.local();

  const runDurationInHrs = end.diff(start, 'hours').toObject().hours;
  const runDurationInText =
    runDurationInHrs < 1
      ? `${Math.floor(end.diff(start, 'minutes').toObject().minutes)}m`
      : `${Math.floor(runDurationInHrs)}h`;

  if (asOf.toMillis() < start.toMillis()) {
    const timeRemainForNextRunInHrs = start.diff(asOf, 'hours').toObject().hours;
    const timeRemainForNextRunInText =
      timeRemainForNextRunInHrs < 1
        ? `${Math.floor(start.diff(asOf, 'minutes').toObject().minutes)}m`
        : `${Math.floor(timeRemainForNextRunInHrs)}h`;
    scheduleInfo = `Next Start in ${timeRemainForNextRunInText} | Duration: ${runDurationInText}`;
  } else {
    const fanStopAfterDurationInHrs = end.diff(asOf, 'hours').toObject().hours;
    const fanStopAfterDurationText =
      fanStopAfterDurationInHrs < 1
        ? `${Math.floor(end.diff(asOf, 'minutes').toObject().minutes)}m`
        : `${Math.floor(fanStopAfterDurationInHrs)}h`;
    scheduleInfo = `Next Stop in ${fanStopAfterDurationText}  | Duration: ${runDurationInText}`;
  }
  return scheduleInfo;
};

const FanErrorIndicator = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 2,
        color: '#000000',
      }}
    >
      <span style={{ width: 22, height: 22 }}>
        <MediumErrorIcon style={{ marginRight: 2 }} />
      </span>
    </div>
  );
};

const RunInterruptedIndicator = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 2,
        color: '#000000',
      }}
    >
      <span style={{ width: 22, height: 22 }}>
        <MediumRunPauseIcon style={{ marginRight: 2 }} />
      </span>
    </div>
  );
};

export { FanScheduleStartCellRenderer };
