import { InputAdornment, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { getDistanceUnitLabel } from '../../../../../core/src/util';
import {
  SelectModelCellRenderer,
  SelectModelHeadCellRenderer,
} from './fan-models-table-components';
import { useStyles } from './FanModelsTableStyles';
import { fan_model_type_to_string } from './GrainFanModelSelector';
import { GrainFanModelsItemsType } from './StandardFanModelsSelectorDashboard';

type GenericFanModelsItems = {
  showSearchBox?: boolean;
  id: string;
  selectedFanModelId: number | null;
  setSelectedItem: React.Dispatch<any>;
} & GrainFanModelsItemsType;

export const GenericFanModelsItems = ({
  id,
  selectedFanModelId,
  handleSetCurrentFanModelState,
  setSelectedItem,
  row_data,
  showSearchBox = true,
}: GenericFanModelsItems) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const searchTextRef: any = useRef();
  let updated_rows = row_data.map((data) => {
    return {
      ...data,
      fan_model_type: fan_model_type_to_string[data.fan_model_type],
    };
  });

  console.log('selectedFanModelId in GrainFanModelItems', selectedFanModelId);

  if (selectedFanModelId) {
    const selectedFanModelRow = updated_rows.filter(
      (item) => item.fan_model_id === selectedFanModelId
    );
    const remainingFanModelRows = updated_rows.filter(
      (item) => item.fan_model_id !== selectedFanModelId
    );
    updated_rows = [...selectedFanModelRow, ...remainingFanModelRows];
  }

  console.log('updated_rows', updated_rows);
  const rowStyle = { background: 'white' };
  const gridRef = useRef<any>();

  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      sortable: true,
      suppressMovable: true,
    };
  }, []);
  const onRowSelection = useCallback((rowData) => {
    setSelectedItem({
      fan_model_id: rowData.fan_model_id,
      fan_model_name: rowData.fan_model_name,
      make: rowData.make,
      fan_brand_name: rowData.fan_brand_name,
      fan_model_type: rowData.fan_model_type,
      fan_horsepower: rowData.fan_horsepower,
      diameter: rowData.diameter,
    });
  }, []);
  const distanceUnitLabel = getDistanceUnitLabel({ cgs: true });
  const searchInputId = `${id}-search-text-box`;

  const colDefn = [
    {
      headerName: '',
      headerComponent: SelectModelHeadCellRenderer,
      cellRenderer: SelectModelCellRenderer,
      cellRendererParams: { onRowSelection },
      filter: false,
      sortable: false,
      suppressMenu: true,
      maxWidth: 60,
      minWidth: 60,
    },
    {
      headerName: 'Type',
      field: 'fan_model_type',
      filter: false,
      sortable: true,
      suppressMenu: true,
      maxWidth: 175,
      minWidth: 175,
    },
    {
      headerName: 'Horsepower',
      field: 'fan_horsepower',
      filter: false,
      sortable: true,
      suppressMenu: true,
      maxWidth: 160,
      minWidth: 160,
    },
    {
      headerName: `Diameter (${distanceUnitLabel})`,
      field: 'diameter',
      filter: false,
      sortable: true,
      suppressMenu: true,
      maxWidth: 160,
      minWidth: 160,
    },
  ];
  const onFilterTextBoxChanged = useCallback(() => {
    const searchBox: any = document.getElementById(searchInputId);
    searchBox && gridRef.current.api.setQuickFilter(searchBox.value);
  }, []);

  const handleSearchTextClear = () => {
    const searchBox: any = document.getElementById(searchInputId);
    if (searchBox) {
      searchBox.value = '';
      gridRef.current.api.setQuickFilter(undefined);
    }
  };

  // highlight selected fan model on 1st load
  const onGridReady = () => {
    if (selectedFanModelId) {
      gridRef.current.api.forEachNode((node) => {
        node.setSelected(node.data.fan_model_id === selectedFanModelId);
      });
      gridRef.current.api.refreshCells({ force: true });
    }
  };

  // highlight selected fan model onrow_data change
  useEffect(() => {
    if (gridRef && gridRef.current && gridRef.current.api && selectedFanModelId) {
      gridRef.current.api.forEachNode((node) => {
        node.setSelected(node.data.fan_model_id === selectedFanModelId);
      });
    }
  }, [row_data]);

  return (
    <>
      {
        <div style={{ width: 564 }}>
          {showSearchBox && (
            <div
              style={{
                marginBottom: 10,
                width: '100%',
                display: 'flex',
                justifyContent: isSmallMobile ? 'center' : 'end',
              }}
            >
              <TextField
                ref={searchTextRef}
                id={searchInputId}
                variant="outlined"
                name="searchText"
                type="text"
                placeholder="Search..."
                onInput={onFilterTextBoxChanged}
                style={{ padding: 0, width: 250 }}
                autoComplete="off"
                inputProps={{
                  style: { padding: '10px 14px' },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CloseIcon style={{ cursor: 'pointer' }} onClick={handleSearchTextClear} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
          <div
            className="ag-theme-alpine"
            style={{
              width: '100%',
              height: '250px',
            }}
          >
            <AgGridReact
              key={id}
              className={classes.conatiner}
              ref={gridRef}
              rowHeight={48}
              rowData={updated_rows}
              columnDefs={colDefn}
              defaultColDef={defaultColDef}
              rowSelection="single"
              suppressRowClickSelection={true}
              suppressCellSelection={true}
              rowStyle={rowStyle}
              onGridReady={onGridReady}
              suppressClickEdit={true}
              suppressDragLeaveHidesColumns={true}
              suppressContextMenu={true}
            />
          </div>
        </div>
      }
    </>
  );
};
