import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { GrainBinTickets, withGetGrainBinTickets as HOC } from '../__generated';

export type WithGetGrainBinTicketsHocOwnProps = {
  grain_bin_id: number;
  grain_bin_storage_cycle_id?: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainBinTicketsHocChildProps = {
  get_grain_bin_tickets_loading: boolean;
  grain_bin_tickets: GrainBinTickets | any;
  refetch_grain_bin_tickets: () => Promise<any>;
};
export const withGetGrainBinTicketsHoc: AmberHoc<
  WithGetGrainBinTicketsHocOwnProps,
  WithGetGrainBinTicketsHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainBinTicketsHocOwnProps,
      WithGetGrainBinTicketsHocChildProps,
      typeof component
    >,
    WithGetGrainBinTicketsHocChildProps
  >({
    options: ({ grain_bin_id, grain_bin_storage_cycle_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      fetchPolicy: 'network-only',
      variables: { grain_bin_id, grain_bin_storage_cycle_id },
      notifyOnNetworkStatusChange: true,
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          get_grain_bin_tickets_loading: true,
          refetch_grain_bin_tickets: () => Promise.resolve(),
          grain_bin_tickets: {
            grain_bin_tickets_total_bushels: 0,
            grain_bin_all_tickets: [],
            __typename: 'GrainBinTickets',
          },
        };
      }

      return {
        get_grain_bin_tickets_loading: loading || false,
        refetch_grain_bin_tickets: data.refetch,
        grain_bin_tickets: data.grain_bin_tickets as GrainBinTickets,
      };
    },
  })(component as any);
