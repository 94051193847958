import Skeleton from '@material-ui/lab/Skeleton';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { MediumRedAlertIcon, SmallRedAlertIcon } from '../../../../../core/src/media';
import { RelativeTimePeriod, Statistic } from '../../../../../core/src/util';
import {
  isValidTelemetryData,
  MultiHubTelemetryHistoryMiniPlot,
} from '../../grain-container/summary-card';
import { SiteDetails } from './SiteDetails';
import { withSkeleton } from './withCellSkeleton';

const AirspaceCellRenderer = withSkeleton((props) => {
  console.log('inside AirspaceCellRenderer', props);
  const { data } = props;
  const grain_bin_location_timezone = props.data.grain_bin_location_timezone;

  console.log('grain_bin_location_timezone', grain_bin_location_timezone);

  const containers_last_week_airspace_history = props.value;
  const statistic = props.api['airspace_statistic'] || Statistic.co2_ppm;
  const hasCO2Statistic = statistic === Statistic.co2_ppm;
  console.log('containers_last_week_airspace_history', containers_last_week_airspace_history);

  console.log('custom value', statistic);

  if (props.value === undefined) {
    return <SiteSummary {...props} statistic={statistic} />;
  }
  if (Object.keys(containers_last_week_airspace_history).length === 0) return <>-</>;

  const active_hubs_formatted_telemetry_history = useMemo(
    () =>
      getActiveHubsFormattedTelemetryHistory({ containers_last_week_airspace_history, statistic }),
    [containers_last_week_airspace_history, statistic]
  );
  console.log('active_hubs_formatted_telemetry_history', active_hubs_formatted_telemetry_history);

  let lastCo2AlertSentDaysText = '';
  if (hasCO2Statistic) {
    const lastCo2AlertSentOn: Date | null = data.last_week_co2_alert_sent_on;
    console.log('lastCo2AlertSentOn', lastCo2AlertSentOn);
    if (lastCo2AlertSentOn) {
      const asOf = DateTime.local().setZone(grain_bin_location_timezone);
      const lastCo2AlertSentOnDt = DateTime.fromMillis(
        new Date(lastCo2AlertSentOn).getTime()
      ).setZone(grain_bin_location_timezone);
      const daysDiff = Math.round(asOf.diff(lastCo2AlertSentOnDt, ['days']).toObject().days);
      console.log('daysDiff', daysDiff);
      lastCo2AlertSentDaysText = daysDiff === 0 ? `Today` : `${daysDiff} Days Ago`;
    }
  }

  const applyChartOpacity = hasCO2Statistic && lastCo2AlertSentDaysText;

  return (
    <div style={{ position: 'relative' }}>
      {hasCO2Statistic && lastCo2AlertSentDaysText && (
        <div
          style={{
            width: 70,
            height: 19,
            fontSize: 10,
            border: `1px solid gray`,
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 8,
            left: 6,
            zIndex: 1,
            backgroundColor: '#fff',
            opacity: 0.85,
          }}
        >
          <SmallRedAlertIcon style={{ marginRight: 2 }} />
          {lastCo2AlertSentDaysText}
        </div>
      )}
      <div style={{ opacity: applyChartOpacity ? 0.9 : 1 }}>
        <MultiHubTelemetryHistoryMiniPlot
          width={162}
          height={58}
          history={active_hubs_formatted_telemetry_history}
          run_windows={[]}
          statistic={statistic}
          period={RelativeTimePeriod.week}
          grain_bin_location_timezone={grain_bin_location_timezone}
        />
      </div>
    </div>
  );
});

const getActiveHubsFormattedTelemetryHistory = ({
  containers_last_week_airspace_history,
  statistic,
}) => {
  return containers_last_week_airspace_history
    ? containers_last_week_airspace_history
        .map(({ hub_id, headspace_telemetry_history }) => {
          const data = headspace_telemetry_history
            .filter((obj) => {
              // TODO: activeStoragePeriod filter
              //   if (
              //     obj[statistic] !== undefined &&
              //     activeStoragePeriod &&
              //     activeStoragePeriod.start_date.getTime() <= obj.epoch_time.getTime() &&
              //     obj.epoch_time.getTime() <= (activeStoragePeriod.end_date || Infinity)
              //   ) {
              //     return obj[statistic] >= 0;
              //   }

              //   if (obj[statistic] !== undefined && activeStoragePeriod === null) {
              //     return obj[statistic] >= 0;
              //   }

              if (obj[statistic] !== undefined) {
                return isValidTelemetryData(obj, statistic);
              }
              return false;
            })
            .map((obj) => ({ x: obj.epoch_time, y: obj[statistic] }));

          return {
            hub_id,
            data,
          };
        })
        .filter(({ data }) => data.length > 0)
    : [];
};

const SiteSummary = (props) => {
  const areAllChildrenLoaded =
    props &&
    props.node.childrenAfterFilter
      .map(({ data }) => data)
      .every((data) => data['last_week_airspace_history'] !== 'loading');
  const statistic = props.statistic;
  const hasCO2Statistic = statistic === Statistic.co2_ppm;

  if (!areAllChildrenLoaded) {
    return <Skeleton animation="wave" variant="rect" width={'100%'} height={'100%'} />;
  }

  const childrenRowsDataAfterFilter = props.node.childrenAfterFilter.map(({ data }) => data);

  if (childrenRowsDataAfterFilter.length === 0) {
    return <SiteDetails {...props}>-</SiteDetails>;
  }

  const co2AlertSentBinsName: string[] = [];
  const co2AlertSentBinsCount: number = childrenRowsDataAfterFilter.reduce((acc, data) => {
    if (
      data.last_week_airspace_history &&
      data.last_week_airspace_history.length > 0 &&
      data.last_week_co2_alert_sent_on
    ) {
      co2AlertSentBinsName.push(data.bin_name);
      return acc + 1;
    }
    return acc;
  }, 0);

  console.log('co2AlertSentBinsCount & co2AlertSentBinsName', {
    co2AlertSentBinsCount,
    co2AlertSentBinsName,
  });

  const firstBinLocationTimezone = childrenRowsDataAfterFilter[0].grain_bin_location_timezone;
  const siteBinsLastWeekAirspaceHistory = childrenRowsDataAfterFilter.reduce((acc, data) => {
    if (data.last_week_airspace_history && data.last_week_airspace_history.length > 0) {
      acc.push(...data.last_week_airspace_history);
    }
    return acc;
  }, []);
  console.log('siteBinsLastWeekAirspaceHistory', siteBinsLastWeekAirspaceHistory);
  const siteBinsTelemetryHistory = getActiveHubsFormattedTelemetryHistory({
    statistic,
    containers_last_week_airspace_history: siteBinsLastWeekAirspaceHistory,
  });
  console.log('siteBinsTelemetryHistory', siteBinsTelemetryHistory, firstBinLocationTimezone);

  const applyChartOpacity =
    hasCO2Statistic && (co2AlertSentBinsCount > 0 || co2AlertSentBinsCount === 0);
  const co2AlertMsg: string | null =
    hasCO2Statistic && co2AlertSentBinsCount > 0
      ? co2AlertSentBinsCount === 1 && co2AlertSentBinsName.length === 1
        ? `${co2AlertSentBinsName[0]}`
        : `${co2AlertSentBinsCount} Bins`
      : null;

  return (
    <SiteDetails {...props}>
      <div style={{ position: 'relative' }}>
        {hasCO2Statistic && co2AlertMsg && (
          <div
            style={{
              width: '100%',
              height: '100%',
              fontSize: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              top: '50%',
              left: '50%',
              zIndex: 1,
              opacity: 0.85,
            }}
          >
            <MediumRedAlertIcon style={{ marginRight: 6 }} />
            <div
              style={{
                fontWeight: 600,
                fontSize: 12,
                width:
                  co2AlertSentBinsCount === 1 &&
                  co2AlertSentBinsName.length === 1 &&
                  co2AlertMsg.length >= 20
                    ? 118
                    : undefined,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                textAlign: 'start',
              }}
            >
              {co2AlertMsg}
            </div>
          </div>
        )}
        {hasCO2Statistic && co2AlertSentBinsCount === 0 && (
          <div
            style={{
              width: '100%',
              height: '100%',
              fontSize: 12,
              margin: 0,
              color: 'black',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              top: '50%',
              left: '50%',
              zIndex: 1,
            }}
          >
            No Alerts within last week
          </div>
        )}
        <div style={{ opacity: applyChartOpacity ? 0.5 : 1 }}>
          <MultiHubTelemetryHistoryMiniPlot
            width={162}
            height={58}
            history={siteBinsTelemetryHistory}
            run_windows={[]}
            statistic={statistic}
            period={RelativeTimePeriod.week}
            grain_bin_location_timezone={firstBinLocationTimezone}
          />
        </div>
      </div>
    </SiteDetails>
  );
};

export { AirspaceCellRenderer };
