import { makeStyles, Theme } from '@material-ui/core';
import { black_shade_8, gray_shade_5 } from '../../../style';

export const useStyles = makeStyles((theme: Theme) => ({
  conatiner: {
    fontFamily: 'Source Sans Pro,sans-serif',
    '& .ag-header-cell': {
      borderRight: `solid 1px var(--ag-border-color, ${gray_shade_5})`,
      color: black_shade_8,
      fontSize: 14,
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 600,
    },
    '& .ag-cell-wrapper': {
      width: '100%',
    },
    '& .ag-header-group-cell': {
      borderRight: `solid 1px var(--ag-border-color, ${gray_shade_5})`,
      paddingLeft: 5,
      paddingRight: 5,
    },
    '& .ag-cell': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: `solid 1px var(--ag-border-color, ${gray_shade_5})`,
      paddingLeft: 5,
      paddingRight: 5,
      color: black_shade_8,
      fontSize: 12,
      textAlign: 'center',
    },
    '& .ag-group-value': { flexGrow: 1 },
    '& .ag-header-cell-label': {
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .ag-header-group-cell-label': {
      justifyContent: 'center',
      alignItems: 'center',
      color: black_shade_8,
      fontSize: 14,
      fontWeight: 600,
    },
    '& .ag-cell-wrap-text': {
      wordBreak: 'break-word',
    },
  },
}));
