import { FormControl, FormHelperText, FormLabel, Input, MenuItem, Select } from '@material-ui/core';
import { StandardTextFieldProps } from '@material-ui/core/TextField';
import { useField, useFormikContext } from 'formik';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const phoneUtil = PhoneNumberUtil.getInstance();

type TelephoneFieldProps = Omit<
  StandardTextFieldProps,
  'type' | 'name' | 'value' | 'onChange' | 'onBlur' | 'inputProps' | 'margin'
> & {
  name: string;
  margin?: 'none' | 'dense';
};

type Country = {
  key: string;
  label: string;
  code: string;
};

const countries: Country[] = [
  {
    key: 'AU',
    label: 'Australia',
    code: '61',
  },
  {
    key: 'BR',
    label: 'Brazil',
    code: '55',
  },
  {
    key: 'CA',
    label: 'Canada',
    code: '1',
  },
  {
    key: 'CN',
    label: 'China',
    code: '86',
  },
  {
    key: 'FR',
    label: 'France',
    code: '33',
  },
  {
    key: 'DE',
    label: 'Germany',
    code: '49',
  },
  {
    key: 'JP',
    label: 'Japan',
    code: '81',
  },
  {
    key: 'MX',
    label: 'Mexico',
    code: '52',
  },
  {
    key: 'CH',
    label: 'Switzerland',
    code: '41',
  },
  {
    key: 'GB',
    label: 'United Kingdom',
    code: '44',
  },
  {
    key: 'US',
    label: 'United States',
    code: '1',
  },
];

const country_lookup: { [k: string]: Country } = countries.reduce((acc, country) => {
  acc[country.key] = country;
  return acc;
}, {});

const getPhone = (value: string): PhoneNumber | null => {
  if (!value) {
    return null;
  }
  try {
    return phoneUtil.parse(value);
  } catch {
    return null;
  }
};

const formatPhone = (phone: PhoneNumber, default_country: string): string =>
  phoneUtil.format(
    phone,
    (phoneUtil.getRegionCodeForNumber(phone) || default_country) === 'US'
      ? PhoneNumberFormat.NATIONAL
      : PhoneNumberFormat.INTERNATIONAL
  );

export const TelephoneField = ({
  name,
  fullWidth,
  label,
  helperText,
  required,
  disabled,
  className,
  style,
  onKeyDown,
  onKeyUp,
  ...props
}: TelephoneFieldProps) => {
  const [field] = useField(name);
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext<any>();
  const value = values[name] as string;
  const phone = useMemo(() => getPhone(value), [value]);
  const [country, setCountry] = useState<Country>(
    country_lookup[phone ? phoneUtil.getRegionCodeForNumber(phone) || 'US' : 'US']
  );
  const [value_str, setValueStr] = useState(phone ? formatPhone(phone, 'US') : '');

  useEffect(() => {
    if (phone) {
      const cc = phoneUtil.getRegionCodeForNumber(phone);
      setValueStr(formatPhone(phone, country.key));
      if (cc) {
        setCountry(country_lookup[cc]);
      }
    }
  }, [phone, country]);
  const renderValueCallback = useCallback((value) => value, []);
  const selectChangeCallback = useCallback((e) => {
    const country = country_lookup[e.target.value];
    setCountry(country);
    setValueStr(`+${country.code} `);
    setFieldValue(name, '');
  }, []);
  const blurCallback = useCallback(() => setFieldTouched(name, true), [setFieldTouched]);
  const inputChangeCallback = useCallback(
    ({ target: { value: next_str } }) => {
      try {
        const next = phoneUtil.parse(next_str, country.key);
        setValueStr(formatPhone(next, country.key));
        setFieldValue(name, phoneUtil.format(next, PhoneNumberFormat.E164));
      } catch {
        setValueStr(next_str);
        setFieldValue(name, next_str);
      }
    },
    [country]
  );

  const error = errors[name];
  const has_error = Boolean(touched[name] && error);
  return (
    <FormControl
      fullWidth={fullWidth}
      required={required}
      error={has_error}
      disabled={disabled}
      className={className}
      style={style}
    >
      <FormLabel>{label}</FormLabel>
      <div style={{ display: 'flex' }}>
        <Select
          value={country.key}
          renderValue={renderValueCallback}
          onChange={selectChangeCallback}
        >
          {countries.map(({ key, label }) => (
            <MenuItem value={key} key={key}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <Input
          id={`TelephoneField-${name}`}
          variant="standard"
          {...props}
          {...field}
          name={name}
          value={value_str}
          onBlur={blurCallback}
          onChange={inputChangeCallback}
          onKeyDown={onKeyDown as (e: React.KeyboardEvent<HTMLInputElement>) => void}
          onKeyUp={onKeyUp as (e: React.KeyboardEvent<HTMLInputElement>) => void}
          style={{ flexGrow: 1, marginLeft: 20 }}
        />
      </div>
      {has_error && <FormHelperText>{has_error ? error : helperText}</FormHelperText>}
    </FormControl>
  );
};
