import { DateTime } from 'luxon';
import React from 'react';
import SkeletonLoader from '../../util/SkeletonLoader';
import { formatDateTime } from './FanScheduleStartCellRenderer';
import { SiteDetails } from './SiteDetails';
import { withSkeleton } from './withCellSkeleton';

const FanHistoryStartCellRenderer = withSkeleton((props) => {
  console.log('inside FanHistoryStartCellRenderer', props);
  const lastAeratioRun = props.value;
  const grain_bin_location_timezone = props.data.grain_bin_location_timezone;
  if (props.value === undefined) {
    return <SiteSummary {...props} />;
  }

  if (lastAeratioRun === null) {
    return <>No Previous Fan Run</>;
  }

  const { dateString, timeString } = formatDateTime(
    new Date(lastAeratioRun.start_epoch),
    grain_bin_location_timezone
  );

  return (
    <div>
      {dateString}
      <br />
      {timeString}
    </div>
  );
});

const SiteSummary = (props) => {
  const areAllChildrenLoadedWithLastAerationRunData =
    props &&
    props.node.childrenAfterFilter
      .map(({ data }) => data)
      .every((data) => data['last_aeration_run'] !== 'loading');

  if (!areAllChildrenLoadedWithLastAerationRunData) {
    return <SkeletonLoader />;
  }

  const childrenRowsDataAfterFilter = props.node.childrenAfterFilter.map(({ data }) => data);
  const childrenPrevAerationRuns: {
    container_id: number;
    start_epoch: Date;
    end_epoch: Date;
  }[] = childrenRowsDataAfterFilter
    .filter((data) => data.last_aeration_run !== null)
    .map((data) => data.last_aeration_run);

  const siteLatestAerationRun =
    childrenPrevAerationRuns.length > 0
      ? childrenPrevAerationRuns.sort(
          (a, b) => new Date(b.start_epoch).getTime() - new Date(a.start_epoch).getTime()
        )[0]
      : null;

  return (
    <SiteDetails {...props}>
      <div style={{ fontSize: 12, color: 'black', fontWeight: 600 }}>
        {siteLatestAerationRun ? getLastRunInfoText(siteLatestAerationRun) : 'No Previous Runs'}
      </div>
    </SiteDetails>
  );
};

const getLastRunInfoText = (run: {
  container_id: number;
  start_epoch: Date;
  end_epoch: Date;
}): string => {
  const { end_epoch } = run;
  const end = DateTime.fromMillis(new Date(end_epoch).getTime());
  const asOf = DateTime.local();
  const daysSincePrevRunFinished = asOf.diff(end, 'days').toObject().days;
  const hoursSincePrevRunFinished = asOf.diff(end, 'hours').toObject().hours;
  const durationText =
    daysSincePrevRunFinished < 1
      ? `${Math.floor(hoursSincePrevRunFinished)} Hours`
      : `${Math.floor(daysSincePrevRunFinished)} Days`;
  return `Last Run ${durationText} Ago`;
};

export { FanHistoryStartCellRenderer };
