import { Button, Theme, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { History } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { SelectedState } from 'core/src/reducer';
import React, { FunctionComponent, useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { AccountFragmentFragment, ViewerFragmentFragment } from '../../../api';
import { ActiveStoragePeriodContext } from '../../../contexts';
import { amber_very_faded_black, white } from '../../../style';
import { ContainerTypeLegacy } from '../../../util';
import { DashboardMenu } from '../DashboardMenu';
import { GrainBinGrainType } from '../GrainBinGrainType';
import { GrainBinMenu } from '../GrainBinMenu';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: amber_very_faded_black,
    borderRadius: 15,
    height: 'fit-content',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flex: 1,
    backgroundColor: white,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    marginTop: 10,
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  shadow: {
    boxShadow: `0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)`,
  },
  bottomRow: {
    display: 'block',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
    marginTop: '10px',
    marginBottom: '14px',
  },
  containerName: {
    marginTop: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 230,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
    [theme.breakpoints.up(theme.breakpoints.width('sm') - 250)]: {
      maxWidth: 280,
    },
  },
  dashboardTitle: {
    textAlign: 'start',
  },
}));

export const ContainerBar: FunctionComponent<{
  viewer: ViewerFragmentFragment;
  pathname: string;
  selected_container_id: number | null | undefined;
  selected_container_type: ContainerTypeLegacy | null;
  account: AccountFragmentFragment;
  onClickCreate: () => void;
  handleContainerSelection: (id: number | null, name: string, type: ContainerTypeLegacy) => void;
}> = ({ viewer, pathname, selected_container_id, selected_container_type }) => {
  const classes = useStyles();
  let url_prefix = '';
  if (pathname.includes('bin')) {
    url_prefix = '/grain_bin';
  } else if (pathname.includes('barge')) {
    url_prefix = '/barge';
  } else if (pathname.includes('pile')) {
    url_prefix = '/pile';
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const ctx = useContext(ActiveStoragePeriodContext);
  const is_historical_bin = ctx && ctx.activeStoragePeriod && !ctx.activeStoragePeriod.is_ongoing;
  const container_name_selector = ({ selected: { container_name } }: { selected: SelectedState }) =>
    container_name;
  const container_name = useSelector(container_name_selector);
  const theme = useTheme();
  const isBiggerMobile = useMediaQuery(theme.breakpoints.only('sm'), {
    noSsr: true,
  });
  const [isHover, setIsHover] = useState(false);
  const handleGrainMenuClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const handleGrainMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerRow}>
          <div className={classes.titleRow}>
            {selected_container_type === ContainerTypeLegacy.bin && (
              <Typography variant="h4" className={classes.dashboardTitle}>
                Bin Dashboard
              </Typography>
            )}
            {selected_container_type === ContainerTypeLegacy.barge && (
              <Typography variant="h4" className={classes.dashboardTitle}>
                Barge Dashboard
              </Typography>
            )}
            {selected_container_type === ContainerTypeLegacy.pile && (
              <Typography variant="h4" className={classes.dashboardTitle}>
                Pile Dashboard
              </Typography>
            )}
          </div>
          {is_historical_bin && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant="h5" color={'primary'}>
                Historical
              </Typography>
              <History style={{ marginLeft: '6px' }} htmlColor={'#000000'} fontSize={'default'} />
            </div>
          )}
        </div>
        {isBiggerMobile ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '15px',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <div style={{ textAlign: 'start' }}>
                  <Tooltip
                    open={anchorEl === null && isHover}
                    title={<div style={{ fontSize: 12 }}> Open Grain Bin Menu</div>}
                    placement="right"
                  >
                    <Button
                      onMouseEnter={() => setIsHover(true)}
                      onMouseLeave={() => setIsHover(false)}
                      style={{ textTransform: 'unset', padding: 0 }}
                      onClick={(e) => {
                        setIsHover(false);
                        handleGrainMenuClick(e);
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <Typography variant="h5" className={classes.containerName}>
                          {container_name || ''}
                        </Typography>
                        {/* for grain menu */}
                        {pathname.includes('dashboard') ? (
                          <GrainBinMenu
                            viewer={viewer}
                            url_prefix={url_prefix}
                            anchorEl={anchorEl}
                            handleGrainMenuClose={handleGrainMenuClose}
                          />
                        ) : null}
                      </div>
                    </Button>
                  </Tooltip>
                </div>
                {selected_container_id && selected_container_type === ContainerTypeLegacy.bin ? (
                  <div>
                    <GrainBinGrainType grain_bin_id={selected_container_id} />
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* for storage period select */}
              {pathname.includes('dashboard') ? (
                <div style={{ paddingTop: 10 }}>
                  <DashboardMenu
                    viewer={viewer}
                    url_prefix={url_prefix}
                    anchorEl={anchorEl}
                    handleGrainMenuClose={handleGrainMenuClose}
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <div className={classes.bottomRow}>
              <div>
                <Button
                  style={{ textTransform: 'unset', padding: 0 }}
                  onClick={(e) => {
                    handleGrainMenuClick(e);
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h5" className={classes.containerName}>
                      {container_name || ''}
                    </Typography>
                    {/* for grain menu */}
                    {pathname.includes('dashboard') ? (
                      <GrainBinMenu
                        viewer={viewer}
                        url_prefix={url_prefix}
                        anchorEl={anchorEl}
                        handleGrainMenuClose={handleGrainMenuClose}
                      />
                    ) : null}
                  </div>
                </Button>
              </div>

              {selected_container_id && selected_container_type === ContainerTypeLegacy.bin ? (
                <div>
                  <GrainBinGrainType grain_bin_id={selected_container_id} />
                </div>
              ) : (
                ''
              )}
              {/* for storage period select */}
              {pathname.includes('dashboard') ? (
                <div style={{ marginTop: 25 }}>
                  <DashboardMenu
                    viewer={viewer}
                    url_prefix={url_prefix}
                    anchorEl={anchorEl}
                    handleGrainMenuClose={handleGrainMenuClose}
                  />{' '}
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </>
  );
};
