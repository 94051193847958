import { NormalizedCache } from 'apollo-cache-inmemory';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  FanSettingsFragmentFragment as Result,
  GetGrainBinFanSettingsDocument,
  GetGrainBinFanSettingsQuery,
  GetGrainBinFanSettingsQueryVariables,
  UnassignFanControllerMutationVariables as Variables,
  withUnassignFanController as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUnassignFanControllerHocChildProps = {
  unassignFanController: (variables: Variables) => Promise<Result>;
};
export const withUnassignFanControllerHoc: AmberHoc<{}, WithUnassignFanControllerHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<{}, WithUnassignFanControllerHocChildProps, typeof component>,
    WithUnassignFanControllerHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        unassignFanController: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { unassignFanController: grain_bin_fan_settings } = { ...data };
              try {
                proxy.writeQuery<GetGrainBinFanSettingsQuery, GetGrainBinFanSettingsQueryVariables>(
                  {
                    query: GetGrainBinFanSettingsDocument,
                    data: { grain_bin_fan_settings, __typename: 'Query' },
                    variables: { grain_bin_id: grain_bin_fan_settings.grain_bin_id },
                  }
                );
              } catch (err) {
                // no-op
              }
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.unassignFanController;
        },
      };
    },
  })(component as any);
