import { AmberHoc, AmberHocProps } from '../../../util';
import {
  UpdateGrainBinWeeklyEmailPrefMutation as Result,
  UpdateGrainBinWeeklyEmailPrefMutationVariables as Variables,
  withUpdateGrainBinWeeklyEmailPref as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUpdateGrainBinWeeklyEmailPrefHocChildProps = {
  updateGrainBinWeeklyEmailPref: (
    variables: Variables
  ) => Promise<Result['updateGrainBinWeeklyEmailPref']>;
};

export const withUpdateGrainBinWeeklyEmailPrefHoc: AmberHoc<
  {},
  WithUpdateGrainBinWeeklyEmailPrefHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithUpdateGrainBinWeeklyEmailPrefHocChildProps, typeof component>,
    WithUpdateGrainBinWeeklyEmailPrefHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updateGrainBinWeeklyEmailPref: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }

          const result = await mutate({ variables });

          if (!result) {
            throw new Error('Unexpected server response');
          }

          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.updateGrainBinWeeklyEmailPref;
        },
      };
    },
  })(component as any);
