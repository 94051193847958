import * as React from 'react';
const QrCodeIcon = (props) => (
  <svg
    width="21px"
    height="21px"
    viewBox="0 -2 21 21"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <symbol id="t" overflow="visible">
        <path d="m0.643 -0.039c-0.033 0.017 -0.068 0.03 -0.104 0.039 -0.036 0.009 -0.073 0.013 -0.112 0.013 -0.117 0 -0.209 -0.032 -0.277 -0.098 -0.068 -0.065 -0.102 -0.154 -0.102 -0.265 0 -0.112 0.034 -0.2 0.102 -0.265 0.068 -0.065 0.16 -0.098 0.277 -0.098 0.039 0 0.077 0.005 0.112 0.013 0.036 0.009 0.071 0.021 0.104 0.039v0.145c-0.034 -0.023 -0.067 -0.039 -0.099 -0.05 -0.033 -0.011 -0.067 -0.016 -0.103 -0.016 -0.064 0 -0.115 0.021 -0.152 0.062 -0.037 0.041 -0.055 0.098 -0.055 0.17 0 0.072 0.018 0.129 0.055 0.17 0.037 0.041 0.087 0.062 0.152 0.062 0.036 0 0.07 -0.005 0.103 -0.016 0.032 -0.011 0.065 -0.027 0.099 -0.05z" />
      </symbol>
      <symbol id="b" overflow="visible">
        <path d="m0.471 -0.382c-0.015 -0.007 -0.03 -0.012 -0.044 -0.015 -0.014 -0.003 -0.029 -0.005 -0.043 -0.005 -0.043 0 -0.076 0.014 -0.1 0.042 -0.023 0.028 -0.035 0.067 -0.035 0.118v0.242h-0.168v-0.525h0.168v0.086c0.021 -0.034 0.046 -0.059 0.074 -0.075 0.028 -0.016 0.062 -0.024 0.101 -0.024 0.006 0 0.012 0 0.018 0.001 0.007 0 0.016 0.001 0.029 0.003z" />
      </symbol>
      <symbol id="a" overflow="visible">
        <path d="m0.605 -0.264v0.048h-0.393c0.004 0.039 0.018 0.069 0.043 0.088 0.024 0.02 0.058 0.029 0.102 0.029 0.035 0 0.071 -0.005 0.108 -0.016 0.037 -0.011 0.075 -0.027 0.114 -0.048v0.129c-0.04 0.015 -0.079 0.026 -0.119 0.034 -0.04 0.008 -0.079 0.012 -0.119 0.012 -0.095 0 -0.169 -0.024 -0.222 -0.072 -0.052 -0.048 -0.079 -0.116 -0.079 -0.203 0 -0.086 0.026 -0.153 0.078 -0.202 0.052 -0.049 0.123 -0.074 0.213 -0.074 0.082 0 0.149 0.025 0.198 0.075 0.05 0.05 0.074 0.116 0.074 0.199zm-0.172 -0.056c0 -0.032 -0.009 -0.057 -0.028 -0.077 -0.019 -0.02 -0.043 -0.029 -0.073 -0.029 -0.033 0 -0.059 0.009 -0.079 0.027s-0.033 0.044 -0.038 0.079z" />
      </symbol>
      <symbol id="m" overflow="visible">
        <path d="m0.316 -0.236c-0.035 0 -0.061 0.006 -0.079 0.018 -0.018 0.012 -0.026 0.029 -0.026 0.052 0 0.021 0.007 0.038 0.021 0.05 0.014 0.012 0.034 0.018 0.059 0.018 0.032 0 0.058 -0.011 0.08 -0.034 0.022 -0.023 0.033 -0.051 0.033 -0.085v-0.019zm0.256 -0.063v0.299h-0.169v-0.078c-0.023 0.032 -0.048 0.055 -0.076 0.07 -0.028 0.014 -0.063 0.021 -0.103 0.021 -0.054 0 -0.098 -0.016 -0.132 -0.047 -0.034 -0.032 -0.051 -0.073 -0.051 -0.124 0 -0.061 0.021 -0.106 0.063 -0.135 0.042 -0.029 0.109 -0.043 0.2 -0.043h0.099v-0.013c0 -0.026 -0.011 -0.046 -0.032 -0.058 -0.021 -0.012 -0.054 -0.019 -0.098 -0.019 -0.036 0 -0.07 0.004 -0.101 0.011 -0.031 0.007 -0.059 0.018 -0.086 0.032v-0.128c0.036 -0.009 0.072 -0.015 0.108 -0.02 0.036 -0.005 0.073 -0.007 0.109 -0.007 0.095 0 0.163 0.019 0.205 0.056 0.042 0.037 0.063 0.098 0.063 0.182z" />
      </symbol>
      <symbol id="d" overflow="visible">
        <path d="m0.264 -0.674v0.149h0.173v0.12h-0.173v0.223c0 0.024 0.005 0.041 0.014 0.049s0.029 0.013 0.057 0.013h0.086v0.12h-0.144c-0.066 0 -0.114 -0.014 -0.141 -0.041 -0.028 -0.028 -0.041 -0.075 -0.041 -0.141v-0.223h-0.083v-0.12h0.083v-0.149z" />
      </symbol>
      <symbol id="f" overflow="visible">
        <path d="m0.438 -0.448v-0.281h0.169v0.73h-0.169v-0.076c-0.023 0.031 -0.048 0.054 -0.076 0.068s-0.06 0.021 -0.097 0.021c-0.065 0 -0.118 -0.026 -0.159 -0.077 -0.042 -0.051 -0.062 -0.117 -0.062 -0.198 0 -0.081 0.021 -0.147 0.062 -0.199 0.041 -0.051 0.094 -0.077 0.159 -0.077 0.036 0 0.069 0.007 0.096 0.022 0.028 0.015 0.054 0.037 0.077 0.068zm-0.11 0.34c0.036 0 0.063 -0.013 0.082 -0.039 0.019 -0.026 0.028 -0.065 0.028 -0.115 0 -0.05 -0.01 -0.088 -0.028 -0.114 -0.019 -0.026 -0.046 -0.04 -0.082 -0.04 -0.036 0 -0.063 0.013 -0.082 0.04 -0.019 0.026 -0.028 0.064 -0.028 0.114 0 0.05 0.009 0.088 0.028 0.115 0.019 0.026 0.046 0.039 0.082 0.039z" />
      </symbol>
      <symbol id="l" overflow="visible">
        <path d="m0.36 -0.108c0.036 0 0.063 -0.013 0.082 -0.039 0.019 -0.026 0.028 -0.065 0.028 -0.115 0 -0.05 -0.01 -0.088 -0.028 -0.114 -0.019 -0.026 -0.046 -0.04 -0.082 -0.04 -0.036 0 -0.064 0.013 -0.083 0.04 -0.019 0.027 -0.028 0.065 -0.028 0.114 0 0.05 0.009 0.088 0.028 0.114 0.019 0.027 0.047 0.04 0.083 0.04zm-0.111 -0.34c0.023 -0.03 0.049 -0.053 0.077 -0.068 0.028 -0.015 0.061 -0.022 0.097 -0.022 0.065 0 0.118 0.026 0.16 0.077 0.041 0.051 0.062 0.118 0.062 0.199 0 0.081 -0.021 0.147 -0.062 0.198 -0.042 0.051 -0.095 0.077 -0.16 0.077 -0.036 0 -0.069 -0.007 -0.097 -0.021 -0.028 -0.015 -0.053 -0.037 -0.077 -0.068v0.076h-0.168v-0.73h0.168z" />
      </symbol>
      <symbol id="k" overflow="visible">
        <path d="m0.012 -0.525h0.168l0.141 0.356 0.12 -0.356h0.168l-0.221 0.575c-0.022 0.058 -0.048 0.099 -0.078 0.122 -0.03 0.024 -0.069 0.035 -0.117 0.035h-0.098v-0.11h0.052c0.029 0 0.049 -0.005 0.062 -0.014 0.013 -0.009 0.023 -0.025 0.031 -0.048l0.005 -0.014z" />
      </symbol>
      <symbol id="j" overflow="visible">
        <path d="m0.088 -0.7h0.18v0.7h-0.18z" />
      </symbol>
      <symbol id="e" overflow="visible">
        <path d="m0.505 -0.508v0.137c-0.023 -0.016 -0.045 -0.027 -0.069 -0.035 -0.023 -0.008 -0.047 -0.011 -0.071 -0.011 -0.047 0 -0.083 0.014 -0.11 0.041 -0.026 0.027 -0.039 0.065 -0.039 0.115 0 0.049 0.013 0.087 0.039 0.115 0.026 0.027 0.063 0.041 0.11 0.041 0.026 0 0.051 -0.004 0.074 -0.011 0.024 -0.008 0.045 -0.019 0.065 -0.035v0.137c-0.026 0.01 -0.053 0.017 -0.08 0.021 -0.027 0.005 -0.054 0.008 -0.081 0.008 -0.095 0 -0.169 -0.024 -0.222 -0.073 -0.053 -0.048 -0.08 -0.116 -0.08 -0.202 0 -0.087 0.027 -0.154 0.08 -0.203 0.054 -0.048 0.128 -0.073 0.222 -0.073 0.027 0 0.055 0.003 0.081 0.008 0.027 0.005 0.053 0.012 0.08 0.022z" />
      </symbol>
      <symbol id="c" overflow="visible">
        <path d="m0.331 -0.418c-0.037 0 -0.066 0.013 -0.085 0.04 -0.02 0.027 -0.029 0.065 -0.029 0.116s0.01 0.089 0.029 0.116c0.02 0.027 0.048 0.04 0.085 0.04 0.036 0 0.064 -0.013 0.084 -0.04 0.02 -0.027 0.029 -0.065 0.029 -0.116s-0.01 -0.089 -0.029 -0.116c-0.019 -0.027 -0.047 -0.04 -0.084 -0.04zm0 -0.12c0.09 0 0.161 0.025 0.212 0.073 0.051 0.049 0.076 0.116 0.076 0.202 0 0.086 -0.025 0.154 -0.076 0.202 -0.051 0.049 -0.121 0.073 -0.212 0.073 -0.091 0 -0.162 -0.024 -0.213 -0.073 -0.051 -0.049 -0.077 -0.116 -0.077 -0.202 0 -0.086 0.025 -0.154 0.077 -0.202 0.051 -0.049 0.122 -0.073 0.213 -0.073z" />
      </symbol>
      <symbol id="i" overflow="visible">
        <path d="m0.609 -0.32v0.32h-0.169v-0.245c0 -0.045 -0.001 -0.077 -0.003 -0.094s-0.006 -0.03 -0.011 -0.038c-0.006 -0.011 -0.015 -0.019 -0.027 -0.025 -0.011 -0.006 -0.024 -0.009 -0.038 -0.009 -0.035 0 -0.063 0.014 -0.082 0.041 -0.02 0.027 -0.03 0.064 -0.03 0.112v0.258h-0.168v-0.525h0.168v0.077c0.025 -0.03 0.052 -0.053 0.08 -0.068 0.029 -0.015 0.06 -0.022 0.094 -0.022 0.061 0 0.107 0.019 0.138 0.056 0.031 0.037 0.047 0.091 0.047 0.162z" />
      </symbol>
      <symbol id="s" overflow="visible">
        <path d="m0.491 -0.508v0.128c-0.036 -0.015 -0.07 -0.026 -0.104 -0.034 -0.034 -0.008 -0.065 -0.011 -0.095 -0.011 -0.032 0 -0.056 0.004 -0.071 0.012 -0.015 0.008 -0.023 0.02 -0.023 0.037 0 0.013 0.006 0.023 0.018 0.031 0.012 0.007 0.033 0.013 0.063 0.016l0.029 0.004c0.086 0.011 0.144 0.029 0.174 0.054 0.03 0.025 0.044 0.064 0.044 0.117 0 0.056 -0.021 0.098 -0.062 0.126 -0.041 0.028 -0.103 0.042 -0.184 0.042 -0.035 0 -0.071 -0.003 -0.108 -0.008 -0.037 -0.005 -0.075 -0.014 -0.114 -0.025v-0.128c0.033 0.016 0.067 0.029 0.102 0.037 0.035 0.008 0.071 0.012 0.108 0.012 0.033 0 0.057 -0.004 0.074 -0.013 0.016 -0.009 0.025 -0.023 0.025 -0.041 0 -0.015 -0.006 -0.026 -0.017 -0.033 -0.012 -0.008 -0.034 -0.013 -0.068 -0.017l-0.029 -0.004c-0.075 -0.009 -0.127 -0.027 -0.157 -0.052 -0.03 -0.025 -0.045 -0.064 -0.045 -0.115 0 -0.056 0.019 -0.097 0.057 -0.124 0.038 -0.027 0.097 -0.041 0.176 -0.041 0.031 0 0.063 0.003 0.097 0.008 0.034 0.005 0.071 0.012 0.111 0.022z" />
      </symbol>
      <symbol id="h" overflow="visible">
        <path d="m0.088 -0.7h0.299c0.089 0 0.157 0.02 0.205 0.059 0.048 0.039 0.072 0.096 0.072 0.169 0 0.073 -0.024 0.13 -0.072 0.169 -0.048 0.04 -0.116 0.059 -0.205 0.059h-0.119v0.243h-0.18zm0.18 0.131v0.196h0.1c0.035 0 0.062 -0.009 0.081 -0.026 0.019 -0.017 0.029 -0.041 0.029 -0.072 0 -0.031 -0.01 -0.055 -0.029 -0.072 -0.019 -0.017 -0.046 -0.025 -0.081 -0.025z" />
      </symbol>
      <symbol id="g" overflow="visible">
        <path d="m0.075 -0.205v-0.32h0.169v0.052c0 0.029 0 0.064 -0.001 0.107 0 0.043 -0.001 0.072 -0.001 0.086 0 0.043 0.001 0.073 0.003 0.092 0.002 0.019 0.006 0.032 0.011 0.04 0.007 0.011 0.016 0.02 0.027 0.026 0.011 0.006 0.024 0.009 0.038 0.009 0.035 0 0.062 -0.013 0.082 -0.04 0.02 -0.027 0.03 -0.064 0.03 -0.112v-0.259h0.168v0.525h-0.168v-0.076c-0.025 0.031 -0.052 0.053 -0.08 0.068 -0.028 0.014 -0.059 0.021 -0.094 0.021 -0.06 0 -0.106 -0.018 -0.138 -0.056 -0.032 -0.037 -0.048 -0.091 -0.048 -0.162z" />
      </symbol>
      <symbol id="r" overflow="visible">
        <path d="m0.426 -0.73v0.11h-0.093c-0.024 0 -0.04 0.004 -0.05 0.013 -0.009 0.009 -0.014 0.024 -0.014 0.045v0.036h0.144v0.12h-0.144v0.405h-0.168v-0.405h-0.084v-0.12h0.084v-0.036c0 -0.057 0.016 -0.099 0.048 -0.127 0.032 -0.027 0.081 -0.041 0.148 -0.041z" />
      </symbol>
      <symbol id="q" overflow="visible">
        <path d="m0.567 -0.438c0.021 -0.032 0.046 -0.057 0.076 -0.074 0.029 -0.017 0.061 -0.026 0.096 -0.026 0.06 0 0.106 0.019 0.138 0.056 0.032 0.037 0.048 0.091 0.048 0.162v0.32h-0.169v-0.274c0 -0.005 0.001 -0.011 0.001 -0.019 0 -0.037 -0.006 -0.064 -0.017 -0.08 -0.011 -0.017 -0.028 -0.025 -0.052 -0.025 -0.032 0 -0.057 0.013 -0.074 0.04 -0.018 0.026 -0.027 0.064 -0.027 0.114v0.258h-0.169v-0.274c0 -0.058 -0.005 -0.095 -0.015 -0.112 -0.01 -0.017 -0.028 -0.025 -0.054 -0.025 -0.032 0 -0.057 0.013 -0.074 0.04 -0.018 0.026 -0.026 0.064 -0.026 0.113v0.258h-0.169v-0.525h0.169v0.077c0.021 -0.03 0.044 -0.052 0.071 -0.067 0.027 -0.015 0.056 -0.023 0.088 -0.023 0.036 0 0.069 0.009 0.096 0.026 0.028 0.018 0.049 0.042 0.063 0.074z" />
      </symbol>
      <symbol id="p" overflow="visible">
        <path d="m0.609 -0.32v0.32h-0.169v-0.244c0 -0.046 -0.001 -0.078 -0.003 -0.095s-0.006 -0.03 -0.011 -0.038c-0.006 -0.011 -0.015 -0.019 -0.027 -0.025 -0.011 -0.006 -0.024 -0.009 -0.038 -0.009 -0.035 0 -0.063 0.014 -0.082 0.041 -0.02 0.027 -0.03 0.064 -0.03 0.112v0.258h-0.168v-0.73h0.168v0.281c0.025 -0.03 0.052 -0.053 0.08 -0.068 0.029 -0.015 0.06 -0.022 0.094 -0.022 0.061 0 0.107 0.019 0.138 0.056 0.031 0.037 0.047 0.091 0.047 0.162z" />
      </symbol>
      <symbol id="o" overflow="visible">
        <path d="m0.088 -0.7h0.201l0.254 0.48v-0.48h0.171v0.7h-0.201l-0.254 -0.48v0.48h-0.171z" />
      </symbol>
      <symbol id="n" overflow="visible">
        <path d="m0.081 -0.525h0.168v0.515c0 0.07 -0.017 0.124 -0.051 0.161 -0.034 0.037 -0.082 0.056 -0.147 0.056h-0.083v-0.11h0.029c0.032 0 0.054 -0.007 0.065 -0.021 0.012 -0.014 0.018 -0.043 0.018 -0.085zm0 -0.205h0.168v0.137h-0.168z" />
      </symbol>
    </defs>
    <g>
      <path d="m9.158 12.461h5.683v-5.683h-5.683zm0.768 -4.915h4.147v4.147h-4.147z" />
      <path d="m11.059 8.678h1.882v1.882h-1.882z" />
      <path d="m16.819 8.678h1.882v1.882h-1.882z" />
      <path d="m3.398 6.701h5.683v-5.683h-5.683zm0.768 -4.915h4.147v4.147h-4.147z" />
      <path d="m5.299 2.918h1.882v1.882h-1.882z" />
      <path d="m3.398 18.221h5.683v-5.683h-5.683zm0.768 -4.915h4.147v4.147h-4.147z" />
      <path d="m14.918 1.018v5.683h5.683v-5.683zm4.915 4.915h-4.147v-4.147h4.147v4.147z" />
      <path d="m18.72 12.538h-3.802v3.782h0.768v-3.014h3.034z" />
      <path d="m19.834 17.453h-3.014v0.768h3.782v-3.821h-0.768z" />
      <path d="m6.643 11.885h1.862v-1.958h-0.768v1.19h-1.094z" />
      <path d="m3.955 9.197h0.768v-1.19h1.094v-0.768h-1.862z" />
      <path d="m13.594 3.36h0.768v-1.862h-2.035v0.768h1.267z" />
      <path d="m12.71 13.594h-0.768v1.728h-1.92v0.768h1.267v1.094h0.768v-1.728h1.92v-0.768h-1.267z" />
    </g>
  </svg>
);
export default QrCodeIcon;
