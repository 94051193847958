import { createAction } from 'typesafe-actions';
import { ContainerTypeLegacy } from '../util/constant';

// intermittent background poll to keep the grain bin ui relatively fresh
export const pollBackgroundGrainBinUpdateState = createAction(
  'POLL/BACKGROUND_GRAIN_BIN_UPDATE_STATE',
  (resolve) => {
    return (payload: { container_id: number | null; container_type: ContainerTypeLegacy | null }) =>
      resolve(payload);
  }
);
