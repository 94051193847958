import { Card, FormLabel, Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { GetProps } from 'react-redux';
import * as yup from 'yup';

import { AccountFragmentFragment, FloorType, GrainType } from '../../api/graphql';
import { ContainerTypeLegacy, getDistanceUnitLabel } from '../../util';
import { Checkbox } from '../util/form2/Checkbox';
import { LocationField, LocationPicker, yup_latlng } from '../util/form2/LocationPicker';
import { NumberTextField } from '../util/form2/NumberField';
import {
  SelectContainerId,
  SelectContainerType,
  yup_container_id,
  yup_container_type,
} from '../util/form2/SelectContainer';
import { SelectFloorType, yup_floor_type } from '../util/form2/SelectFloorType';
import { SelectGrainBinMap } from '../util/form2/SelectGrainBinMap';
import { SelectGrainType, yup_grain_type } from '../util/form2/SelectGrainType';
import { TextField } from '../util/form2/TextField';
import { WizardStep } from './WizardStep';

type Values = {
  device_type: string;
  device_id: string;
  container_type: ContainerTypeLegacy | null;
  container_id: number;
  alias: string;
  grain_type: GrainType | null;
  height_ft: number | null;
  diameter_ft: number | null;
  location: { latlng: null | google.maps.LatLngLiteral; latlng_str: string };
  has_grain_spreader: boolean;
  floor_type: FloorType;
};

const getInitialContainerType = ({
  barge_support,
  grain_bin_support,
  pile_support,
}: AccountFragmentFragment) => {
  if (barge_support && !grain_bin_support && !pile_support) {
    return ContainerTypeLegacy.barge;
  }
  if (!barge_support && grain_bin_support && !pile_support) {
    return ContainerTypeLegacy.bin;
  }
  if (!barge_support && !grain_bin_support && pile_support) {
    return ContainerTypeLegacy.pile;
  }
  return null;
};

export const GrainBinFields = () => {
  const classes = useStyles();
  const {
    values: {
      fan_type,
      location: { latlng_str },
    },
  } = useFormikContext();
  const distanceUnitLabel = getDistanceUnitLabel();
  return (
    <>
      <Grid item xs={12}>
        <TextField required fullWidth name="alias" label="Bin Name" />
      </Grid>
      <Grid item xs={12}>
        <SelectGrainType required fullWidth name="grain_type" label="Grain Type" />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          name="height_ft"
          label={`Eave Height (${distanceUnitLabel})`}
          inputProps={{
            inputMode: 'decimal',
            min: 1,
            max: 100,
          }}
          type="number"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          name="diameter_ft"
          label={`Bin Diameter (${distanceUnitLabel})`}
          inputProps={{
            inputMode: 'decimal',
            min: 1,
            max: 100,
          }}
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <SelectFloorType name="floor_type" label="Floor Type" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <Checkbox name="has_grain_spreader" label="Bin Equipped with Grain Spreader" />
      </Grid>
      <Card style={{ width: '100%', padding: '10px' }}>
        <Grid item xs={12}>
          <Hidden only={['xs', 'sm', 'md', 'lg', 'xl']}>
            <LocationField required fullWidth name="location" label="Location" />
          </Hidden>
          <FormLabel className={classes.locationLabel}>Location</FormLabel>
          {latlng_str === '' && (
            <div className={classes.locationInstruction}>
              <ol style={{ marginTop: '0' }}>
                <li>
                  <Typography>
                    Enter your grain bin location's address in the google maps search field below.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Pinpoint the exact location in the map view.
                    <br />
                    (Make changes by dragging the marker or clicking the map.)
                  </Typography>
                </li>
              </ol>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <LocationPicker name="location" />
          <br />
          {latlng_str && (
            <Typography style={{ textAlign: 'center' }}>
              Pin's Lat/Long:
              <strong> {latlng_str}</strong>
            </Typography>
          )}
        </Grid>
      </Card>
    </>
  );
};

const getHasOptions = (
  container_type: ContainerTypeLegacy | null,
  { grain_bin_links, barge_links, pile_links }: AccountFragmentFragment
) => {
  return (
    (container_type === ContainerTypeLegacy.bin && grain_bin_links.length) ||
    (container_type === ContainerTypeLegacy.barge && barge_links.length) ||
    (container_type === ContainerTypeLegacy.pile && pile_links.length)
  );
};

const useStyles = makeStyles({
  grid: {
    minWidth: 300,
    maxWidth: 400,
    margin: 20,
  },
  location: {
    height: 400,
  },
  locationInstruction: {
    color: '#f44336',
  },
  locationLabel: {
    marginBottom: 5,
    display: 'block',
  },
});

const isCreate = (value, schema) => (value === -1 ? schema.required() : schema);

export const validationSchema = yup.object().shape({
  container_id: yup_container_id.label('Container ID').required(),
  container_type: yup_container_type
    .label('Container Type')
    .required()
    .nullable(false),
  alias: yup
    .string()
    .label('Bin Name')
    .when('container_id', isCreate),
  grain_type: yup_grain_type.label('Grain Type').when('container_id', isCreate),
  height_ft: yup
    .number()
    .typeError('A number is required')
    .positive()
    .label('Height')
    .when('container_id', isCreate),
  diameter_ft: yup
    .number()
    .typeError('A number is required')
    .positive()
    .label('Diameter')
    .when(['container_id', 'container_type'], (container_id, container_type, schema) =>
      container_id === -1 && container_type === ContainerTypeLegacy.bin
        ? schema.required().nullable(false)
        : schema
    ),
  location: yup
    .object()
    .label('Location')
    .when('container_id', (value, schema) => (value === -1 ? schema.concat(yup_latlng) : schema)),
  has_grain_spreader: yup.boolean(),
  floor_type: yup_floor_type.label('Floor Type').required(),
});

export const PickGrainContainerStep = ({
  account,
  title = 'Select a Grain Bin',
  validationSchema,
}: Partial<GetProps<typeof WizardStep>> & { account: AccountFragmentFragment }) => {
  const classes = useStyles();
  const {
    values: { container_type, container_id },
    setFieldValue,
  } = useFormikContext<Values>();
  const initial_container_type = getInitialContainerType(account);
  const has_options = getHasOptions(container_type, account);
  useEffect(() => {
    if (initial_container_type) {
      setFieldValue('container_type', initial_container_type);
    }
  }, [initial_container_type]);
  useEffect(() => {
    setFieldValue('container_id', has_options ? '' : -1);
  }, [container_type, has_options]);

  return (
    <WizardStep title="Select a Grain Bin" validationSchema={validationSchema}>
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        alignItems="center"
        justify="center"
        spacing={2}
        className={classes.grid}
      >
        {!initial_container_type ? (
          <Grid item xs={12}>
            <SelectContainerType
              account={account}
              name="container_type"
              label="Grain Container Type"
            />
          </Grid>
        ) : null}
        {has_options && container_type && (
          <Grid container item xs={12} justify="center">
            <SelectContainerId
              name="container_id"
              account={account}
              container_type={container_type}
            />
          </Grid>
        )}
        {has_options &&
          container_type === ContainerTypeLegacy.bin &&
          container_id &&
          container_id !== -1 && (
            <Grid item xs={12}>
              <SelectGrainBinMap
                name="container_id"
                account={account}
                className={classes.location}
              />
            </Grid>
          )}
        {container_id === -1 && <GrainBinFields />}
      </Grid>
    </WizardStep>
  );
};
