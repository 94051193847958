import { ApolloClient } from 'apollo-client';
import { delay } from 'redux-saga';
import { cancel, fork, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ContainerTypeLegacy } from '../util/constant';

import { pollBackgroundRecommWindowsState } from '../action';
import {
  GetGrainBinContainerDocument,
  GetGrainBinContainerQuery,
  GetGrainBinContainerQueryVariables,
} from '../api';

let bg_recomm_windows_poll_task: any;

function* pollForUpdatedRecommendationsLoop(
  apollo_client: ApolloClient<any>,
  container_id: number,
  container_type: ContainerTypeLegacy
) {
  try {
    while (true) {
      // poll every 1 min
      yield delay(1000 * 60);

      // query for GrainBinContainer too in order to update the recommedation windows
      const grain_container_results = yield apollo_client.query<GetGrainBinContainerQuery>({
        query: GetGrainBinContainerDocument,
        variables: { container_id, container_type },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });

      // update cache
      yield apollo_client.writeQuery<GetGrainBinContainerQuery, GetGrainBinContainerQueryVariables>(
        {
          query: GetGrainBinContainerDocument,
          data: grain_container_results.data,
          variables: { container_id, container_type },
        }
      );
    }
  } finally {
    // console.log('pollForUpdatedRecommendationsLoop ended');
  }
}

function* pollBackgroundRecommWindowsSaga(
  apollo_client: ApolloClient<any>,
  action: ReturnType<typeof pollBackgroundRecommWindowsState>
) {
  if (!action.payload) return;

  try {
    const {
      payload: { container_id, container_type },
    } = action;

    // cancel existing poll if there is one
    if (bg_recomm_windows_poll_task) {
      yield cancel(bg_recomm_windows_poll_task);
      bg_recomm_windows_poll_task = null;
    }

    if (container_id && container_type) {
      bg_recomm_windows_poll_task = yield fork(
        pollForUpdatedRecommendationsLoop,
        apollo_client,
        container_id,
        container_type
      );
    }
  } catch (e) {
    console.error(e);
  }
}

export function* backgroundRecommWindowsPollWatcher(apollo_client: ApolloClient<any>) {
  yield takeEvery(
    getType(pollBackgroundRecommWindowsState),
    pollBackgroundRecommWindowsSaga,
    apollo_client
  );
}
