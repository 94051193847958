import { Grid, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AnimatePresence, motion } from 'framer-motion';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import DividerLine from '../../../../../../core/src/component/util/DividerLine';
import { ArrowDropDownNounIcon } from '../../../../../../core/src/media';
import { ContainerType } from '../../../../api';
import { amber_amber, amber_grey, black, light_gray_4 } from '../../../../style';
import { ContainerTypeLegacy, getTemperatureUnitLabel } from '../../../../util';
import { getFormatedDateTimeString } from '../../../../util/format-date';
import { FanControlNavigationOption } from '../../FanControlNavigationOption';
import { RecommendedOption } from '../../RecommendationOptionValue';
import { computeAvgGrainConditions } from '../FanSetMultiSchedule';
import { NoSchedulePlaceholder } from '../NoSchedulePlaceholder';
import { RunWinowReadOnly } from '../RunWinowReadOnly';

type RunWindow = { start_date: string; end_date: string; start_time: string; end_time: string };

type RecommendedRunWindowsProps = {
  grain_bin_id: number;
  container_id: number;
  container_type: ContainerType;
  container_type2: ContainerTypeLegacy;
  storage_period?: {
    grain_bin_storage_cycle_id: number;
  } | null;
  navigation: FanControlNavigationOption;
  setNavigation: (navigation: FanControlNavigationOption) => void;
  isRecommendingWindows: boolean;
  recommendedWindows?: RunWindow[];
  hourlyForecasts?: any;
  onCancel: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  smallIcon: {
    fontSize: 17,
    width: 40,
    height: 24,
    color: amber_amber,
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: 0,
    marginTop: 5,
    width: 'fit-content',
  },
  item: { textAlign: 'center', color: amber_grey, fontWeight: 400, fontSize: 16 },
  small_header: {
    textAlign: 'center',
    color: amber_grey,
    fontSize: 12,
    marginRight: 10,
  },
  icon: {
    marginRight: 10,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  to_separater: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      marginBottom: 8,
    },
  },
  dateLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: 12,
    fontFamily: 'Source Sans Pro,sans-serif',
    fontWeight: 400,
    textAlign: 'start',
  },
  dateField: { fontSize: 14, paddingTop: 6, textAlign: 'start' },
  fanScheduleTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 5px',
    marginBottom: 10,
  },
  schedule_heading: {
    color: black,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  fanScheduleCount: {
    width: 20,
    height: 20,
    background: `${light_gray_4} 0% 0% no-repeat padding-box`,
    color: black,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    marginLeft: 6,
  },
}));

export const RecommendedRunWindows = ({
  navigation,
  recommendedWindows,
  isRecommendingWindows,
  hourlyForecasts,
  onCancel,
}: RecommendedRunWindowsProps) => {
  const [avgGrainConditions, setAvgGrainConditions] = useState<any>([]);
  const [showMoreWindows, setShowMoreWindows] = useState(false);
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);
  let validRecommendedWindows: RunWindow[] = [];
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const DEFAULT_RUN_WINDOWS_TO_SHOW = 1;
  const tempUnit = getTemperatureUnitLabel();
  const toggleShowingRunWindows = () => {
    setShowMoreWindows(!showMoreWindows);
  };

  if (isRecommendingWindows) {
    validRecommendedWindows = recommendedWindows
      ? recommendedWindows.filter(({ start_date, start_time, end_date, end_time }) => {
          if (start_date && start_time && end_date && end_time) {
            const start = DateTime.fromFormat(
              getFormatedDateTimeString(start_date, start_time),
              'MM/dd/yyyy hh:mm'
            ).toMillis();
            const end = DateTime.fromFormat(
              getFormatedDateTimeString(end_date, end_time),
              'MM/dd/yyyy hh:mm'
            ).toMillis();
            return start < end;
          }
          return false;
        })
      : [];
  }
  useEffect(() => {
    const refreshedAverageGrainConditions = validRecommendedWindows.map(
      ({ start_date, start_time, end_date, end_time }) => {
        /// When the user selects 'Now', the start date is always -1 day before the
        /// current datetime
        let start = DateTime.utc().toMillis();
        const now = DateTime.fromFormat(`${start_date}`, 'L/d/yyyy').toMillis();
        if (now > start) {
          start = DateTime.fromFormat(`${start_date} ${start_time}`, 'L/d/yyyy H:mm').toMillis();
        }

        const end = DateTime.fromFormat(`${end_date} ${end_time}`, 'L/d/yyyy H:mm').toMillis();
        const [temp, rh] = computeAvgGrainConditions(start, end, hourlyForecasts);

        return { temp, rh };
      }
    );

    setAvgGrainConditions(refreshedAverageGrainConditions);
  }, [JSON.stringify(validRecommendedWindows)]);

  const renderTitleText = () => {
    switch (navigation.recommendationOptionValue.option) {
      case RecommendedOption.COOLING:
        return 'Cooling';
      case RecommendedOption.DRYING:
        return 'Drying';
      case RecommendedOption.RECONDITIONING:
        return 'Reconditioning';
    }
  };

  const emptyWindowMessageText = () => {
    if (validRecommendedWindows.length === 0) {
      switch (navigation.recommendationOptionValue.option) {
        case RecommendedOption.COOLING:
          return 'No Recommended Cooling Windows';
        case RecommendedOption.DRYING:
          return 'No Recommended Drying Windows';
        case RecommendedOption.RECONDITIONING:
          return 'No Recommended Reconditioning Windows';
      }
    } else {
      return '';
    }
  };

  const calculateRunwindowDuration = ({ startDate, endDate, startTime, endTime }) => {
    const validInput = startDate && endDate && startTime && endTime;
    if (!validInput) return 0;

    const start =
      DateTime.fromFormat(
        getFormatedDateTimeString(startDate, startTime),
        'MM/dd/yyyy hh:mm'
      ).toMillis() < DateTime.local().toMillis()
        ? DateTime.local()
        : DateTime.fromFormat(getFormatedDateTimeString(startDate, startTime), 'MM/dd/yyyy hh:mm');
    const end = DateTime.fromFormat(
      getFormatedDateTimeString(endDate, endTime),
      'MM/dd/yyyy hh:mm'
    );
    const start_epoch = start.toMillis();
    const end_epoch = end.toMillis();

    // handle end date as forever case
    if (Math.round(end.diff(start, 'year').toObject().years) === 1) return Infinity;

    return Math.round(end_epoch / (1000 * 3600) - start_epoch / (1000 * 3600));
  };

  const totalRunHours = useMemo(() => {
    return validRecommendedWindows && validRecommendedWindows.length
      ? validRecommendedWindows.reduce(
          (
            total,
            { start_date: startDate, start_time: startTime, end_date: endDate, end_time: endTime }
          ) => total + calculateRunwindowDuration({ startDate, endDate, startTime, endTime }),
          0
        )
      : 0;
  }, [validRecommendedWindows]);
  const showDropdownButton = validRecommendedWindows.length > 1;

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      alignContent="center"
      justify="space-between"
      spacing={2}
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div className={classes.fanScheduleTitle}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" className={classes.schedule_heading}>
            {renderTitleText()} Schedule
          </Typography>
          <div className={classes.fanScheduleCount}>{validRecommendedWindows.length}</div>
        </div>
        {showDropdownButton && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ArrowDropDownNounIcon
              style={{
                width: 19,
                height: 12,
                transform: showMoreWindows ? 'rotate(180deg)' : 'none',
                marginRight: 10,
                cursor: 'pointer',
              }}
              onClick={toggleShowingRunWindows}
            />
          </div>
        )}
      </div>

      {emptyWindowMessageText() && <NoSchedulePlaceholder message={emptyWindowMessageText()} />}

      <AnimatePresence>
        {validRecommendedWindows &&
          validRecommendedWindows.length > 0 &&
          validRecommendedWindows
            .slice(
              0,
              showMoreWindows ? validRecommendedWindows.length : DEFAULT_RUN_WINDOWS_TO_SHOW
            )
            .map(({ start_date, start_time, end_date, end_time }, ix) => {
              const start =
                DateTime.fromFormat(
                  getFormatedDateTimeString(start_date, start_time),
                  'MM/dd/yyyy hh:mm'
                ).toMillis() < DateTime.local().toMillis()
                  ? DateTime.local()
                  : DateTime.fromFormat(
                      getFormatedDateTimeString(start_date, start_time),
                      'MM/dd/yyyy hh:mm'
                    );
              const end = DateTime.fromFormat(
                getFormatedDateTimeString(end_date, end_time),
                'MM/dd/yyyy hh:mm'
              );
              // calculate run window duration
              const duration = calculateRunwindowDuration({
                startDate: start_date,
                endDate: end_date,
                startTime: start_time,
                endTime: end_time,
              });
              const estimatedTemp = avgGrainConditions
                ? avgGrainConditions[ix]
                  ? `${Math.floor(avgGrainConditions[ix]['temp'])}${tempUnit}`
                  : `- ${tempUnit}`
                : null;
              const estimatedRH = avgGrainConditions
                ? avgGrainConditions[ix]
                  ? `${Math.round(avgGrainConditions[ix]['rh'] * 100)}% RH`
                  : '- RH'
                : null;
              const now = DateTime.local();
              const variants = {
                initial: {
                  opacity: 0,
                  y: 80,
                },
                animate: {
                  opacity: 1,
                  y: 0,
                },
              };

              return (
                <motion.div
                  key={`animated-run-windows-${ix}`}
                  variants={variants}
                  initial="initial"
                  animate="animate"
                  transition={{
                    delay: 0.1 * (ix + 1),
                    duration: 0.1 * (ix + 1),
                    ease: 'easeInOut',
                  }}
                  style={{
                    margin: 0,
                    boxSizing: 'border-box',
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginBottom: 15,
                  }}
                >
                  <RunWinowReadOnly
                    index={ix}
                    key={`run-windows-${ix}`}
                    start={start}
                    end={end}
                    estimatedTemp={estimatedTemp}
                    estimatedRH={estimatedRH}
                    runtime={duration}
                    showLoadingAnimation={showLoadingAnimation}
                    recommendationOptionValue={navigation.recommendationOptionValue.option}
                  />
                </motion.div>
              );
            })}
      </AnimatePresence>

      <div style={{ width: '100%', marginBottom: 20 }}>
        <DividerLine />
      </div>
      {validRecommendedWindows && validRecommendedWindows.length > 0 && (
        <div className={classes.row} style={{ fontSize: 16, marginBottom: 10 }}>
          Total Run Time:&nbsp;
          <strong>{totalRunHours === Infinity ? '\u221e' : totalRunHours} Hours</strong>
        </div>
      )}
    </Grid>
  );
};
