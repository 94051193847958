import { getTimeZones } from '@vvo/tzdb';
import { DateTime } from 'luxon';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';

type SelectProps = {
  name: string;
  selectedValue: string;
  isDisabled?: boolean;
  onChange: (value: { value: string; label: string }) => void;
};

export const SelectTimezone: FunctionComponent<SelectProps> = ({
  name,
  selectedValue,
  ...props
}) => {
  const timeZones = getTimeZones({ includeUtc: false });
  const validTimeZones = timeZones
    .filter(({ name }) => DateTime.local().setZone(name).isValid)
    .map(({ name, abbreviation, group }) => {
      return {
        group,
        value: name,
        label: `${name} - ${abbreviation}`,
      };
    });
  const options = validTimeZones.map(({ label, value }) => ({ label, value }));
  const defaultTimezone = validTimeZones.find(
    ({ value, group }) => value === selectedValue || group.includes(selectedValue)
  );

  const defaultTimezoneOption = defaultTimezone
    ? { value: defaultTimezone.value, label: defaultTimezone.label }
    : options.find(({ value }) => value === 'America/Chicago');

  return <Select options={options} name={name} value={defaultTimezoneOption} {...props} />;
};
