import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, ReactNode } from 'react';
import { ViewerFragmentFragment } from '../../api';
import { ContainerSelect } from '../nav';

const useStyles = makeStyles({ root: { padding: 10 } });

export const ContainerDashboardLayout: FunctionComponent<{
  viewer: ViewerFragmentFragment;
  pathname: string;
  onSelectContainer?: (args: any) => void;
  goCreateBarge?: () => void;
  goCreatePile?: () => void;
  goCreateGrainBin?: () => void;
  children?: ReactNode;
  mobile_width?: boolean;
}> = ({
  viewer,
  pathname,
  onSelectContainer,
  goCreateBarge,
  goCreatePile,
  goCreateGrainBin,
  children = null,
  mobile_width,
}) => {
  const classes = useStyles();
  return (
    <>
      {mobile_width &&
        pathname &&
        onSelectContainer &&
        goCreateBarge &&
        goCreateGrainBin &&
        goCreatePile &&
        pathname.includes('dashboard') && (
          <ContainerSelect
            mobile_layout={true}
            pathname={pathname}
            viewer={viewer}
            onSelectContainer={onSelectContainer}
            goCreateBarge={goCreateBarge}
            goCreatePile={goCreatePile}
            goCreateGrainBin={goCreateGrainBin}
          />
        )}
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        alignContent="flex-start"
        justify="center"
        className={classes.root}
      >
        {children}
      </Grid>
    </>
  );
};
