import { ApolloError } from 'apollo-client';
import _ from 'lodash';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy } from '../../../util';
import {
  GetGrainContainerHistoryNextAerationRunsWithLimitDocument,
  GrainContainerAerationRunFragmentFragment,
  GrainFanPowerUsage,
  withGetGrainContainerHistoryAerationRunsWithLimit as HOC,
} from '../__generated';

export type WithGetGrainContainerHistoryAerationRunsWithLimitHocOwnProps = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  pollInterval?: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
  storage_period?: {
    grain_bin_storage_cycle_id: number;
  } | null;
};
export type WithGetGrainContainerHistoryAerationRunsWithLimitHocChildProps = {
  loading: boolean;
  aeration_runs: GrainContainerAerationRunFragmentFragment[] | null;
  loadMore: (offset: number) => Promise<any>;
  hasMore: boolean;
  currentOffset: number;
  total_runs?: number;
  total_hrs_runtime?: number;
  fan_power_usage?: GrainFanPowerUsage;
};

const PAGE_LIMIT = 10;

export const withGetGrainContainerHistoryAerationRunsWithLimitHoc: AmberHoc<
  WithGetGrainContainerHistoryAerationRunsWithLimitHocOwnProps,
  WithGetGrainContainerHistoryAerationRunsWithLimitHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainContainerHistoryAerationRunsWithLimitHocOwnProps,
      WithGetGrainContainerHistoryAerationRunsWithLimitHocChildProps,
      typeof component
    >,
    WithGetGrainContainerHistoryAerationRunsWithLimitHocChildProps
  >({
    options: ({
      container_id,
      container_type,
      onError,
      pollInterval = 1000 * 60 * 30,
      storage_period,
    }) => ({
      onError,
      pollInterval,
      errorPolicy: onError ? 'all' : 'none',
      fetchPolicy: 'network-only',
      variables: {
        container_id,
        container_type,
        offset: 0,
        limit: PAGE_LIMIT,
        ...(storage_period && {
          current_grain_bin_storage_cycle_id: storage_period.grain_bin_storage_cycle_id,
        }),
      },
      notifyOnNetworkStatusChange: true,
    }),
    props: ({ data, ownProps: { loading, container_id, container_type, storage_period } }) => {
      let aeration_runs: GrainContainerAerationRunFragmentFragment[] | null = null;
      let hasMore: boolean = false;
      let currentOffset: number = 0;
      let total_runs: number = 0;
      let total_hrs_runtime: number = 0;
      let fan_power_usage: any = {
        fan_controllers_with_missing_info: [],
        fan_power_stats: [],
        total_kwh: 0,
      };

      if (!data || (data.loading && !data.grain_container)) {
        return {
          aeration_runs,
          hasMore,
          currentOffset,
          total_runs,
          total_hrs_runtime,
          fan_power_usage,
          loadMore: () => Promise.resolve(),
          loading: true,
        };
      }

      if (data && data.loading && data.grain_container) {
        const {
          grain_container: {
            aeration_run_history: { aeration_runs, current_offset },
          },
        } = data;
        return {
          aeration_runs,
          hasMore,
          fan_power_usage,
          currentOffset: current_offset,
          loadMore: () => Promise.resolve(),
          loading: true,
        };
      }

      const { grain_container } = data;

      if (grain_container && grain_container.aeration_run_history) {
        const { aeration_run_history } = grain_container;
        aeration_runs = aeration_run_history.aeration_runs;
        hasMore = aeration_run_history.has_more || false;
        currentOffset = aeration_run_history.current_offset;
        if (currentOffset === 0) {
          total_runs = aeration_run_history.total_runs!;
          total_hrs_runtime = aeration_run_history.total_hrs_runtime!;
          fan_power_usage = aeration_run_history.fan_power_usage;
        }
      }

      const handleLoadMore = (currOffset) => {
        console.log('new Offset', currOffset + PAGE_LIMIT);
        return data.fetchMore({
          query: GetGrainContainerHistoryNextAerationRunsWithLimitDocument,
          variables: {
            container_id,
            container_type,
            offset: currOffset + PAGE_LIMIT,
            limit: PAGE_LIMIT,
            ...(storage_period && {
              current_grain_bin_storage_cycle_id: storage_period.grain_bin_storage_cycle_id,
            }),
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;
            return fetchMoreResult;
          },
        });
      };

      return currentOffset === 0
        ? {
            aeration_runs,
            hasMore,
            currentOffset,
            total_runs,
            total_hrs_runtime,
            fan_power_usage,
            loadMore: (currOffset) => handleLoadMore(currOffset),
            loading: loading || false,
          }
        : {
            aeration_runs,
            hasMore,
            currentOffset,
            loadMore: (currOffset) => handleLoadMore(currOffset),
            loading: loading || false,
          };
    },
  })(component as any);
