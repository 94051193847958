import { AmberHoc, AmberHocProps } from '../../../util';
import {
  SetUserUnitPrefMutationVariables as Variables,
  UserUnitsPrefFragmentFragment,
  withSetUserUnitPref as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type withSetUserUnitPrefHocChildProps = {
  setUserUnitsPref: (variables: Variables) => Promise<UserUnitsPrefFragmentFragment>;
};

export const withSetUserUnitPrefHoc: AmberHoc<{}, withSetUserUnitPrefHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, withSetUserUnitPrefHocChildProps, typeof component>,
    withSetUserUnitPrefHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        setUserUnitsPref: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }

          const result = await mutate({ variables });

          if (!result) {
            throw new Error('Unexpected server response');
          }

          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.setUserUnitsPref;
        },
      };
    },
  })(component as any);
