import { Grid, Link } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { amber_link } from '../../../../core/src/style';
import { unsubscribeWeeklyGrainEmailsApi } from '../../api/rest/weekly-grain-emails-api-services';
import { InternalErrorIcon, SuccessTickIcon } from '../../media/icons';
import { DialogSpinner } from '../spinner';

export type UnsubscribeWeeklyEmailProps = {
  routeParams: { user_id: number; email: string };
} & RouteComponentProps;

export const UnsubscribeWeeklyEmail = ({ routeParams }: UnsubscribeWeeklyEmailProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const email = routeParams.email;
  const user_id = Number(routeParams.user_id);
  const baseUrl = window.location.origin;
  const notificationSettingsUrl = `${baseUrl}/user/settings`;

  const unsubscribeWeeklyGrainEmails = async () => {
    try {
      setIsUpdating(true);
      const response = await unsubscribeWeeklyGrainEmailsApi(email, user_id);
      console.log(response);
      if (response.data.data.success) {
        setIsUnsubscribed(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpdating(false);
    }
  };
  useEffect(() => {
    unsubscribeWeeklyGrainEmails();
  }, []);

  if (isUpdating) {
    return <DialogSpinner title="Please wait unsubscribing..." open={isUpdating} />;
  }
  return (
    <Grid
      container
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div style={{ minWidth: 300, maxWidth: 600, height: 400, padding: 24 }}>
        {isUnsubscribed ? (
          <div>
            <div>
              <SuccessTickIcon />
            </div>
            <div style={{ fontSize: 20, marginTop: 10 }}>
              Your email <strong>{email}</strong> has successfully unsubscribed from Weekly Grain
              Email
            </div>
            <div style={{ fontSize: 16, marginTop: 35 }}>
              You can re-enable Weekly Grain Emails in your{' '}
              <Link style={{ color: amber_link }} href={notificationSettingsUrl}>
                notification settings
              </Link>
              .
            </div>
          </div>
        ) : (
          <div>
            <div>
              <InternalErrorIcon />
            </div>
            <div style={{ fontSize: 20, marginTop: 10 }}>Sorry! Internal Server Error</div>
          </div>
        )}
      </div>
    </Grid>
  );
};
