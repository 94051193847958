import React from 'react';
import { light_gray_7 } from '../../..../../../../core/src/style';

const DividerLine = (props) => {
  return (
    <div
      style={{
        backgroundColor: light_gray_7,
        height: 1,
        width: '100%',
        marginTop: 5,
      }}
      {...props}
    />
  );
};

export default DividerLine;
