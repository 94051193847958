import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles({
  content: {
    fontSize: 24,
    marginBottom: 20,
  },
  deleteButton: {
    fontSize: 14,
    textAlign: 'center',
    backgroundColor: '#dc3545',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#c82333',
    },
  },
  button: {
    fontSize: 14,
    textAlign: 'center',
  },
  grid: {
    width: '100%',
    padding: 20,
  },
});

type UserDeleteModalProps = {
  user: { first_name: string; last_name: string } | null;
  showModal: boolean;
  handleCancel: Function;
  handleConfirm: Function;
};

const UserDeleteModal: FunctionComponent<UserDeleteModalProps> = ({
  user,
  showModal,
  handleCancel,
  handleConfirm,
}) => {
  if (!user) return null;
  const classes = useStyles();
  const { last_name, first_name } = user;
  return (
    <Dialog maxWidth="xs" open={showModal} onClose={() => handleCancel()}>
      <DialogContent className={classes.grid}>
        <Typography className={classes.content}>
          Are you sure you want to delete user <strong>{`${last_name}, ${first_name}`}</strong>?
        </Typography>
        <DialogActions>
          <Button
            variant="contained"
            className={classes.deleteButton}
            onClick={() => handleConfirm()}
          >
            Delete
          </Button>
          <Button className={classes.button} variant="contained" onClick={() => handleCancel()}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default UserDeleteModal;
