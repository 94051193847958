import * as React from 'react';
export const IntermidiateCheckboxIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g id="SelectBox" transform="translate(-485 -208)">
      <g
        id="SelectBox-2"
        data-name="SelectBox"
        transform="translate(485 208)"
        fill="#333"
        stroke="#cfd0d4"
        strokeWidth={1}
      >
        <rect width={24} height={24} rx={4} stroke="none" />
        <rect x={0.5} y={0.5} width={23} height={23} rx={3.5} fill="none" />
      </g>
      <line
        id="Line_2"
        data-name="Line 2"
        x2={12}
        transform="translate(491 220)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={2.5}
      />
    </g>
  </svg>
);
