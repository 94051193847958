import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { BaseAccountFragmentFragment, withGetAccounts as HOC } from '../__generated';

export type WithGetAccountsHocOwnProps = {
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetAccountsHocChildProps = {
  loading: boolean;
  accounts: BaseAccountFragmentFragment[];
};
export const withGetAccountsHoc: AmberHoc<
  WithGetAccountsHocOwnProps,
  WithGetAccountsHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<WithGetAccountsHocOwnProps, WithGetAccountsHocChildProps, typeof component>,
    WithGetAccountsHocChildProps
  >({
    options: ({ onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          accounts: [],
        };
      }
      return {
        loading: loading || false,
        accounts: data.accounts || [],
      };
    },
  })(component as any);
