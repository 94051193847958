import { Transformer } from 'apollo-client-transform/dist/defs';
import { UnitsConverter, USER_UNITS_PREF_KEY } from '../../../../core/src/util';
import { MassUnitType, UserUnitsPrefFragmentFragment } from './__generated';

export function hasUserSelectedKgUnit() {
  const userUnitsPrefVal = localStorage.getItem(USER_UNITS_PREF_KEY);
  const localeUnitSystem: UserUnitsPrefFragmentFragment | null = userUnitsPrefVal
    ? JSON.parse(userUnitsPrefVal)
    : null;
  return localeUnitSystem && localeUnitSystem.mass_unit === MassUnitType.Kilogram;
}

const DateTransformer: Transformer = {
  parseValue: (value?: number | null) =>
    value === undefined || value === null ? value : new Date(value),
  serialize: (value?: Date | null) =>
    value === undefined || value === null ? value : value.getTime(),
};

const MetersTransformer: Transformer = {
  parseValue: (value?: number | null) => {
    return value === undefined || value === null
      ? value
      : UnitsConverter.convertFeetToMeters(value);
  },
  serialize: (value?: number | null) => value,
};

const InchesToCentimetersTransformer: Transformer = {
  parseValue: (value?: number | null) => {
    return value === undefined || value === null
      ? value
      : UnitsConverter.convertInchesToCentimeters(value);
  },
  serialize: (value?: number | null) => value,
};

const CelsiusTransformer: Transformer = {
  parseValue: (value?: number | null) => {
    return value === undefined || value === null
      ? value
      : UnitsConverter.convertFahrenheitToCelsius(value);
  },
  serialize: (value?: number | null) => value,
};

const MassTransformer: Transformer = {
  parseValue: (value?: number | null) => {
    return value === undefined || value === null
      ? value
      : hasUserSelectedKgUnit()
      ? UnitsConverter.convertPoundToKilogram(value)
      : value;
  },
  serialize: (value?: number | null) => value,
};

const KgPerHectoliterTransformer: Transformer = {
  parseValue: (value?: number | null) => {
    return value === undefined || value === null
      ? value
      : UnitsConverter.convertPoundPerBuToKilogramPerHectoliter(value);
  },
  serialize: (value?: number | null) => value,
};

const MetricTonTransformer: Transformer = {
  parseValue: (value?: number | null) => {
    return value === undefined || value === null
      ? value
      : UnitsConverter.convertBuToMetricTon(value);
  },
  serialize: (value?: number | null) => value,
};

export {
  DateTransformer,
  MetersTransformer,
  CelsiusTransformer,
  MassTransformer,
  KgPerHectoliterTransformer,
  MetricTonTransformer,
  InchesToCentimetersTransformer,
};
