import { Button, makeStyles, Paper, styled } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import React, { useState } from 'react';
import { CancelIcon } from '../../../../../core/src/media';
import { blue_shade_4, blue_shade_5, white } from '../../../../../core/src/style';
import { SelectedBoxName, SelectItem } from './StandardFanModelsSelectorDashboard';

const MuiSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-outlined.MuiSelect-outlined': { padding: '0px 8px', height: 35 },
  '& .MuiSelect-outlined': {
    background: white,
    color: blue_shade_4,
    border: '1px solid #ced4da',
    borderRadius: 6,
  },
}));

const useStyles = makeStyles({
  option: {
    margin: 0,
    padding: '0.75rem 1.25rem',
    border: '0 none',
    color: blue_shade_4,
    transition: 'box-shadow 0.2s',
    borderRadius: 0,
    cursor: 'pointer',
    '&:not(:disabled):hover': {
      color: blue_shade_4,
      background: '#e9ecef',
    },
    '&:checked': {
      color: blue_shade_5,
      background: '#EEF2FF !important',
    },
  },
});

export type SelectorProps = {
  items: SelectItem[];
  select_box_name: SelectedBoxName;
  setSelectedFilter: (selected_box_name: SelectedBoxName, item_value: string) => void;
  show_clear_button: boolean;
  styles?: any;
  width?: number | string;
};
export const Selector = (props: SelectorProps) => {
  const classes = useStyles();
  const { items, select_box_name, setSelectedFilter, show_clear_button, width } = props;
  const [selectValue, setSelectValue] = useState('');
  const handleChange = (event) => {
    const value: string = event.target.value;
    setSelectValue(value);
    setSelectedFilter(select_box_name, value);
  };

  const handleClear = () => {
    const active_item = items.filter((item) => item.enable);
    if (active_item.length === 1) {
      const { item_name } = active_item[0];
      setSelectedFilter(select_box_name, item_name);
      setSelectValue('');
    }
  };

  return (
    <>
      <Paper elevation={3} style={{ color: 'gray' }}>
        <div style={{ textAlign: 'left' }}>
          <InputLabel
            shrink
            htmlFor="select-multiple-native"
            style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}
          >
            {select_box_name}
          </InputLabel>
          <MuiSelect
            native
            onChange={handleChange}
            style={{ padding: 0, width: width ? width : 300 }}
            variant="outlined"
            value={selectValue}
          >
            <option value="">{'<None>'}</option>
            {items.map((item) =>
              item.enable ? (
                <option className={classes.option} key={item.item_name} value={item.item_value}>
                  {item.item_name}
                </option>
              ) : (
                <option
                  className={classes.option}
                  key={item.item_name}
                  value={item.item_value}
                  disabled
                  style={{ color: 'lightgrey' }}
                >
                  {item.item_name}
                </option>
              )
            )}
          </MuiSelect>
          {show_clear_button && (
            <div>
              <Button
                size="small"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  fontWeight: 'bold',
                  color: 'red',
                }}
                onClick={handleClear}
              >
                <CancelIcon style={{ marginRight: 3 }} width={15} height={15} />
                Clear
              </Button>
            </div>
          )}
        </div>
      </Paper>
    </>
  );
};
