import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { GetProps, useDispatch } from 'react-redux';
import * as yup from 'yup';

import { endSession } from '../../action';
import {
  ERROR_BAD_TOKEN,
  exportTelemetry,
  GrainContainerIdFragmentFragment,
  withGetContainerHoc,
  WithGetContainerHocChildProps,
} from '../../api';
import { ContainerTypeLegacy } from '../../util';
import { DialogSpinner } from '../spinner';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../util/form2/BaseForm';
import { ButtonSubmit } from '../util/form2/Button';
import { ErrorBox } from '../util/form2/ErrorBox';
import { yup_container_type } from '../util/form2/SelectContainer';
import { TextField } from '../util/form2/TextField';

const useStyles = makeStyles({
  grid: { minWidth: 180, maxWidth: 250 },
  centered: { textAlign: 'center' },
});

type Values = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  alias: string;
  start: string;
  end: string;
};
const validationSchema = yup.object().shape({
  container_id: yup
    .number()
    .typeError('A number is required')
    .integer()
    .required(),
  container_type: yup_container_type.required(),
  start: yup
    .date()
    .required()
    .label('Period Start'),
  end: yup
    .date()
    .required()
    .label('Period End')
    .min(yup.ref('start'), "Period End can't be before Period Start"),
});

const ExportTelemetryFormBase = ({
  grain_container,
  ...props
}: FormikWrapperHandlerProps<Values, void> & {
  grain_container: GrainContainerIdFragmentFragment;
  exportTelemetry: (values: {
    container_id: number;
    container_type: ContainerTypeLegacy;
    start: string;
    end: string;
  }) => Promise<void>;
}) => {
  const classes = useStyles();
  return (
    <FormikWrapper
      {...props}
      validationSchema={validationSchema}
      initialValues={{
        container_id: grain_container.container_id,
        container_type: grain_container.container_type,
        alias: grain_container.alias,
        start: '',
        end: '',
      }}
      onSubmit={(values) => exportTelemetry(validationSchema.validateSync(values))}
      render={({ values: { alias } }) => {
        return (
          <BaseForm submitting_message="Exporting data...">
            <Grid
              container
              direction="row"
              alignContent="flex-start"
              alignItems="center"
              justify="center"
              spacing={2}
              className={classes.grid}
            >
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.centered}>
                  {alias}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="start"
                  type="date"
                  label="Period Start"
                  placeholder="MM/DD/YYYY"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="end"
                  label="Period End"
                  type="date"
                  placeholder="MM/DD/YYYY"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <ErrorBox />
              <Grid item xs={12} className={classes.centered}>
                <ButtonSubmit>EXPORT</ButtonSubmit>
              </Grid>
            </Grid>
          </BaseForm>
        );
      }}
    />
  );
};

// const MAX_PERIOD_MONTHS = 3;
export const ExportTelemetryForm = withGetContainerHoc(
  ({
    loading,
    grain_container,
  }: Omit<GetProps<typeof ExportTelemetryFormBase>, 'exportTelemetry'> &
    WithGetContainerHocChildProps) => {
    const dispatch = useDispatch();
    if (loading) {
      return <DialogSpinner open title="Loading..." />;
    }
    if (!grain_container) {
      return <Typography>Unable to load grain container</Typography>;
    }
    return (
      <ExportTelemetryFormBase
        grain_container={grain_container}
        exportTelemetry={exportTelemetry}
        onSubmitFailure={(error) => {
          if (error.name === ERROR_BAD_TOKEN) {
            dispatch(endSession());
          }
        }}
      />
    );
  }
);
