import { withWidth } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import {
  AccountFragmentFragment,
  AccountRoutes,
  AlertDialog,
  AppNavigation,
  BargeRoutes,
  BrowserWarning,
  ContainerTypeLegacy,
  DiagnosticsRoutes,
  DialogSpinner,
  ErrorLayout,
  errorNav,
  formatErrors,
  GrainBinRoutes,
  GrainOperations,
  GrainOperationsRoutes,
  ignoreVersion,
  LoginForm,
  mobilebar_height,
  NavMenu,
  NavMenuOnMobile,
  NetworkSnackbar,
  OtpRoutes,
  PileRoutes,
  pollBackgroundNotificationsUpdateState,
  PrivacyPolicy,
  pushNav,
  RefreshSnackbar,
  resetAlertErrors,
  ResetPasswordForm,
  SelectContainerPlaceholder,
  setAccountId,
  setNavBar,
  TopBar,
  topbar_height,
  UnsubscribeWeeklyEmail,
  useNetwork,
  USER_UNITS_PREF_KEY,
  UserPasswordDialog,
  UserRole,
  UserRoutes,
  UserRoutesSuffix,
  ViewerFragmentFragment,
  withGetViewerHoc,
} from '@amber-ui/core/lib';

const pathname_config = {
  '/account/users': {
    title: 'Users',
    back_path: '',
  },
  '/admin/diagnostics': {
    title: 'Diagnostics',
    back_path: '',
  },
  '/barge/cover': {
    title: 'Barge Covers',
    back_path: '/barge/dashboard',
  },
  '/barge/covers': {
    title: 'Barge Covers',
    back_path: '/barge/settings',
  },
  '/barge/create': {
    title: 'Create Barge',
    back_path: '/barge/dashboard',
  },
  '/barge/dashboard': {
    title: 'Barge Dashboard',
    back_path: '',
  },
  '/barge/devices': {
    title: 'Devices',
    back_path: '/barge/dashboard',
  },
  '/barge/export_telemetry': {
    title: 'Export Telemetry',
    back_path: '/barge/dashboard',
  },
  '/barge/settings': {
    title: 'Barge Settings',
    back_path: '/barge/dashboard',
  },
  '/barge/storage_periods': {
    title: 'Storage Periods',
    back_path: '/barge/dashboard',
  },
  '/error': {
    title: '',
    back_path: '',
  },
  '/grain_bin/aeration_schedule': {
    title: 'Bin Dashboard',
    back_path: '/grain_bin/dashboard',
  },
  '/grain_bin/create': {
    title: 'Create Grain Bin',
    back_path: '/grain_bin/dashboard',
  },
  '/grain_bin/dashboard': {
    title: 'Bin Dashboard',
    back_path: '',
  },
  '/grain_bin/devices': {
    title: 'Devices',
    back_path: '/grain_bin/dashboard',
  },
  '/grain_bin/export_telemetry': {
    title: 'Export Telemetry',
    back_path: '/grain_bin/dashboard',
  },
  '/grain_bin/events': {
    title: 'Bin Events',
    back_path: '/grain_bin/dashboard',
  },
  '/grain_bin/settings': {
    title: 'Bin Settings',
    back_path: '/grain_bin/dashboard',
  },
  '/grain_bin/storage_periods': {
    title: 'Storage Periods',
    back_path: '/grain_bin/dashboard',
  },
  '/grain_bin/tickets': {
    title: 'Grain Bin Tickets',
    back_path: '/grain_bin/dashboard',
  },
  '/grain/operations': {
    title: 'Bin Dashboard',
    back_path: '',
  },
  '/pile/cover': {
    title: 'Pile Covers',
    back_path: '/pile/dashboard',
  },
  '/pile/covers': {
    title: 'Pile Covers',
    back_path: '/pile/settings',
  },
  '/pile/create': {
    title: 'Create Pile',
    back_path: '/pile/dashboard',
  },
  '/pile/dashboard': {
    title: 'Pile Dashboard',
    back_path: '',
  },
  '/pile/devices': {
    title: 'Devices',
    back_path: '/pile/dashboard',
  },
  '/pile/cables': {
    title: 'Pile Cables',
    back_path: '/pile/dashboard',
  },
  '/pile/export_telemetry': {
    title: 'Export Telemetry',
    back_path: '/pile/dashboard',
  },
  '/pile/settings': {
    title: 'Pile Settings',
    back_path: '/pile/dashboard',
  },
  '/pile/storage_periods': {
    title: 'Storage Periods',
    back_path: '/pile/dashboard',
  },
  '/user/account': {
    title: 'User Account',
    back_path: '',
  },
  '/user/password': {
    title: 'User Password',
    back_path: '/user/account',
  },
  '/user/notifications': {
    title: 'Notifications',
    back_path: '',
  },
  '/user/settings': {
    title: 'Settings',
    back_path: '',
  },
  '/privacy': {
    title: 'Privacy Policy',
    back_path: '',
  },
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  content: {
    marginTop: topbar_height,
    flexGrow: 1,
  },
  flexGrowcontent: {
    flexGrow: 1,
  },
  mobileContent: {
    marginBottom: mobilebar_height,
  },
});

const default_selected = {
  container_id: null,
  container_type: null,
  container_name: '',
  pellet_id: '',
  cable_id: '',
};

const accessFailureRedirect = (
  selected_account: AccountFragmentFragment,
  viewer: ViewerFragmentFragment
) => {
  if (
    viewer.account.grain_bin_support &&
    (!selected_account || selected_account.grain_bin_support)
  ) {
    return '/grain_bin';
  }
  if (viewer.account.barge_support && (!selected_account || selected_account.barge_support)) {
    return '/barge';
  }
  if (viewer.account.pile_support && (!selected_account || selected_account.pile_support)) {
    return '/pile';
  }
  return `/user/${UserRoutesSuffix.account}`;
};

const selectErrors = ({ alert: { errors } }) => errors;
const selectEmail = ({ session: { email_address } }) => email_address;
const selectDisplayOptionalUpdate = ({ version: { display_optional_update } }) =>
  display_optional_update;

const AuthRoutesBase: FunctionComponent<
  RouteComponentProps & {
    viewer: ViewerFragmentFragment | null;
    width: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  }
> = ({ location: { pathname }, viewer, width }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectSelected = ({ selected }) => selected;
  const {
    container_type,
    container_id,
    container_name,
    pellet_id,
    cable_id,
    account_id,
  } = useSelector(selectSelected);

  console.log('in routes', { container_type, container_id, viewer });

  const recentBinSelected =
    container_id && container_type
      ? { ...default_selected, container_id, container_type, container_name, pellet_id, cable_id }
      : default_selected;

  const goContainer = useCallback(
    (selected) => {
      if (selected.container_type === ContainerTypeLegacy.bin) {
        dispatch(pushNav({ path: '/grain_bin/dashboard', params: { selected } }));
      } else if (selected.container_type === ContainerTypeLegacy.barge) {
        dispatch(pushNav({ path: '/barge/dashboard', params: { selected } }));
      } else if (selected.container_type === ContainerTypeLegacy.pile) {
        dispatch(pushNav({ path: '/pile/dashboard', params: { selected } }));
      } else {
        dispatch(
          pushNav({
            path: '/',
            params: { selected },
          })
        );
      }
    },
    [dispatch]
  );

  const goArchiveOrgSettings = useCallback(
    (selected) => {
      if (
        selected.container_type &&
        [ContainerTypeLegacy.bin, ContainerTypeLegacy.barge].includes(selected.container_type)
      ) {
        dispatch(pushNav({ path: '/user/settings', params: { selected } }));
      } else {
        dispatch(pushNav({ path: '/grain/operations' }));
      }
    },
    [dispatch]
  );

  const goCreateBarge = useCallback(() => dispatch(pushNav({ path: '/barge/create' })), [dispatch]);
  const goCreateGrainBin = useCallback(() => dispatch(pushNav({ path: '/grain_bin/create' })), [
    dispatch,
  ]);
  const goCreatePile = useCallback(() => dispatch(pushNav({ path: '/pile/create' })), [dispatch]);
  const goError = useCallback(() => dispatch(errorNav()), [dispatch]);
  const goPasswordForm = useCallback(
    () =>
      viewer &&
      dispatch(pushNav({ path: `/user/password`, params: { selected: recentBinSelected } })),
    [dispatch, viewer, recentBinSelected]
  );
  const goPath = useCallback((path) => dispatch(pushNav({ path })), [dispatch]);
  const goPrivacy = useCallback(
    () => dispatch(pushNav({ path: '/privacy', params: { selected: recentBinSelected } })),
    [dispatch, recentBinSelected]
  );
  const goUser = useCallback(
    () => dispatch(pushNav({ path: '/user/account', params: { selected: recentBinSelected } })),
    [dispatch, recentBinSelected]
  );
  const goUserSettings = useCallback(
    () => dispatch(pushNav({ path: '/user/settings', params: { selected: recentBinSelected } })),
    [dispatch, recentBinSelected]
  );
  const goOrgSettings = useCallback(
    () => dispatch(pushNav({ path: '/user/settings', params: { selected: recentBinSelected } })),
    [dispatch, recentBinSelected]
  );
  const goNotifications = useCallback(
    () =>
      dispatch(pushNav({ path: '/user/notifications', params: { selected: recentBinSelected } })),
    [dispatch, recentBinSelected]
  );
  const onClickBack = useCallback((path) => dispatch(pushNav({ path })), [dispatch]);
  const goUsers = useCallback(
    () => dispatch(pushNav({ path: '/account/users', params: { selected: recentBinSelected } })),
    [dispatch, recentBinSelected]
  );
  const onClickMenu = useCallback(() => dispatch(setNavBar({ open: true })), [dispatch]);
  const resetErrors = useCallback(() => dispatch(resetAlertErrors()), [dispatch]);
  const setSelectedAccount = useCallback(
    (account: AccountFragmentFragment) => dispatch(setAccountId({ account })),
    [dispatch]
  );

  const email_address = useSelector(selectEmail);
  const errors = useSelector(selectErrors);

  if (!viewer) {
    return null;
  }
  const {
    account: { grain_bin_support, barge_support, pile_support, grain_bin_links },
    user: { role, needs_pw_change, user_units_pref },
    account_id: viewerAccountId,
  } = viewer;
  const current_account_id = account_id || viewer.account_id;

  const route_config = pathname_config[pathname];
  const { title = '', back_path = '' } = route_config || {};
  let redirect_target: string;
  if (pathname === '/privacy') {
    redirect_target = '/privacy';
  }
  if (container_type === ContainerTypeLegacy.bin && grain_bin_support) {
    redirect_target = '/grain_bin';
  } else if (container_type === ContainerTypeLegacy.barge && barge_support) {
    redirect_target = '/barge';
  } else if (container_type === ContainerTypeLegacy.pile && pile_support) {
    redirect_target = '/pile';
  } else {
    if (grain_bin_support) {
      const hasAccountWithNoBins = grain_bin_links.length === 0;
      redirect_target = hasAccountWithNoBins ? '/grain_bin' : '/grain/operations';
    } else if (barge_support) {
      redirect_target = '/barge';
    } else if (pile_support) {
      redirect_target = '/pile';
    } else {
      redirect_target = '/';
    }
  }
  const goDefault = () => goPath(redirect_target);
  const mobile_width = width === 'sm' || width === 'xs';
  const desktop_width = width === 'lg' || width === 'xl';
  const tablet_width = width === 'md';
  const pages_wihtout_sidebar =
    pathname.includes('/weather_forecast') || pathname.includes('/dashboard_analysis');

  useEffect(() => {
    // here polling unread notifications count only for current viewer's account not for other accounts
    if (grain_bin_support && current_account_id === viewer.account_id) {
      dispatch(
        pollBackgroundNotificationsUpdateState({
          user_id: viewer.user_id,
        })
      );
    }
    return () => {
      dispatch(pollBackgroundNotificationsUpdateState({ user_id: null }));
    };
  }, [viewer.user_id, grain_bin_support, current_account_id]);

  useEffect(() => {
    if (user_units_pref) {
      localStorage.setItem(USER_UNITS_PREF_KEY, JSON.stringify(user_units_pref));
    }
  }, [JSON.stringify(user_units_pref)]);

  return (
    <div className={mobile_width ? '' : classes.root}>
      <BrowserWarning />

      {!pages_wihtout_sidebar &&
        (mobile_width ? (
          <TopBar
            account_id={current_account_id}
            goToDashboard={goContainer}
            onClickMenu={onClickMenu}
            onClickPrivacy={goPrivacy}
            onClickUser={goUser}
            onClickBack={back_path ? () => onClickBack(back_path) : undefined}
          />
        ) : (
          <AppNavigation
            viewer={viewer}
            title={title}
            email_address={email_address}
            pathname={pathname}
            onClickDashboard={goContainer}
            onClickPrivacy={goPrivacy}
            onClickUser={goUser}
            onClickUsers={goUsers}
            onClickOrgSettings={goOrgSettings}
            onClickNotifications={goNotifications}
            onClickSettings={goUserSettings}
            onSelectContainer={goContainer}
            goCreateBarge={goCreateBarge}
            goCreatePile={goCreatePile}
            goCreateGrainBin={goCreateGrainBin}
            onClickBack={back_path ? () => onClickBack(back_path) : undefined}
            currentAccountId={current_account_id}
          />
        ))}
      <div
        className={
          mobile_width
            ? classes.mobileContent
            : pathname.includes('/weather_forecast') || pathname.includes('/dashboard_analysis')
            ? classes.flexGrowcontent
            : classes.content
        }
        style={{
          maxWidth:
            tablet_width && !pages_wihtout_sidebar && pathname.includes('/dashboard')
              ? '70%'
              : 1400,
        }}
      >
        {
          <Switch>
            <Route exact path="/" render={() => <Redirect to={redirect_target} />} />
            <Route path="/error" render={() => <ErrorLayout />} />

            <Route
              path="/account"
              render={(props) => (
                <AccountRoutes
                  {...props}
                  account_id={current_account_id}
                  viewerAccountId={viewerAccountId}
                  hasAdminAccess={role === UserRole.Admin}
                />
              )}
            />

            <Route path="/dashboard" render={() => <SelectContainerPlaceholder />} />
            <Route
              path="/barge"
              render={(props) => (
                <BargeRoutes
                  {...props}
                  account_id={current_account_id}
                  pellet_id={pellet_id}
                  barge_id={container_type === ContainerTypeLegacy.barge ? container_id : null}
                  accessFailureRedirect={accessFailureRedirect}
                  onError={goError}
                  pathname={pathname}
                  onSelectContainer={goContainer}
                  goCreateBarge={goCreateBarge}
                  goCreatePile={goCreatePile}
                  goCreateGrainBin={goCreateGrainBin}
                  goOrgSettings={goOrgSettings}
                  mobile_width={mobile_width}
                  onArchiveClick={goArchiveOrgSettings}
                />
              )}
            />
            <Route
              path="/pile"
              render={(props) => (
                <PileRoutes
                  {...props}
                  account_id={current_account_id}
                  cable_id={cable_id}
                  pile_id={container_type === ContainerTypeLegacy.pile ? container_id : null}
                  accessFailureRedirect={accessFailureRedirect}
                  onError={goError}
                  pathname={pathname}
                  onSelectContainer={goContainer}
                  goCreatePile={goCreatePile}
                  goCreateBarge={goCreateBarge}
                  goCreateGrainBin={goCreateGrainBin}
                  mobile_width={mobile_width}
                />
              )}
            />
            <Route
              path="/grain_bin"
              render={(props) => (
                <GrainBinRoutes
                  {...props}
                  account_id={current_account_id}
                  grain_bin_id={container_type === ContainerTypeLegacy.bin ? container_id : null}
                  accessFailureRedirect={accessFailureRedirect}
                  onError={goError}
                  pathname={pathname}
                  onSelectContainer={goContainer}
                  goCreateBarge={goCreateBarge}
                  goCreatePile={goCreatePile}
                  goCreateGrainBin={goCreateGrainBin}
                  goOrgSettings={goOrgSettings}
                  mobile_width={mobile_width}
                  onArchiveClick={goArchiveOrgSettings}
                />
              )}
            />

            <Route
              path="/grain/operations"
              render={(props) => {
                const unarchived_grain_ids = viewer.account.grain_bin_links
                  .filter(({ grain_bin }) => !grain_bin.archived)
                  .map(({ grain_bin_id }) => grain_bin_id);
                const operations = (
                  <GrainOperationsRoutes
                    {...props}
                    mobile_width={mobile_width}
                    account_id={current_account_id}
                    user_id={viewer.user_id}
                    grain_bin_ids={unarchived_grain_ids}
                    accessFailureRedirect={accessFailureRedirect}
                    goContainer={goContainer}
                  />
                );
                return operations || <ErrorLayout />;
              }}
            />

            <Route path="/privacy" render={() => <PrivacyPolicy />} />
            <Route
              path="/user"
              render={(props) => (
                <UserRoutes
                  mobile_width={mobile_width}
                  goPasswordForm={goPasswordForm}
                  onError={goError}
                  onUpdateUserFormSuccess={goDefault}
                  onUserPasswordFormSuccess={goDefault}
                  onUserSettingsFormSuccess={goDefault}
                  onUserOrgSettingsFormSuccess={goDefault}
                  goUserSettings={goUserSettings}
                  {...props}
                />
              )}
            />
            <Route render={() => <Redirect to={redirect_target} />} />
          </Switch>
        }
      </div>
      {
        <NavMenuOnMobile
          account_id={current_account_id}
          viewer={viewer}
          onSelectAccount={setSelectedAccount}
          onClickPrivacy={goPrivacy}
          onClickSettings={goUserSettings}
          onClickOrgSettings={goOrgSettings}
          onClickNotifications={goNotifications}
          onClickUsers={goUsers}
          onSelectContainer={goContainer}
          title={title}
          email_address={email_address}
          pathname={pathname}
          onClickDashboard={goContainer}
          onClickUser={goUser}
          goCreateBarge={goCreateBarge}
          goCreatePile={goCreatePile}
          goCreateGrainBin={goCreateGrainBin}
          onClickBack={back_path ? () => onClickBack(back_path) : undefined}
          currentAccountId={current_account_id}
        />
      }
      <AlertDialog messages={formatErrors(errors)} onClickClose={resetErrors} />
      {needs_pw_change && <UserPasswordDialog viewer={viewer} />}
    </div>
  );
};

const AuthRoutes: React.ComponentType = withRouter(withWidth()(withGetViewerHoc(AuthRoutesBase)));

const selectSession = ({ session }) => session;
export const Routes = withWidth()(
  withRouter(({ width }: RouteComponentProps & { width: 'xs' | 'sm' | 'md' | 'lg' | 'xl' }) => {
    const { ready, account_id } = useSelector(selectSession);
    const mobile_width = width === 'sm' || width === 'xs';
    const display_optional_update = useSelector(selectDisplayOptionalUpdate);
    const { isOnline } = useNetwork();
    const dispatch = useDispatch();
    const handleIgnoreVersion = useCallback(() => dispatch(ignoreVersion()), [dispatch]);

    if (!ready) {
      return <DialogSpinner title="Initializing..." open />;
    }

    if (account_id === null) {
      return (
        <>
          <Switch>
            {/* Public Routes */}
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route path="/error" render={() => <ErrorLayout />} />
            <Route path="/login" render={() => <LoginForm />} />
            <Route path="/reset_password" render={() => <ResetPasswordForm />} />
            <Route path="/privacy" render={() => <PrivacyPolicy />} />
            <Route path="/otp" render={(props) => <OtpRoutes {...props} />} />
            <Route path="/devices" render={(props) => <DiagnosticsRoutes {...props} />} />
            <Route
              path="/unsubscribe_weekly_email/:email/:user_id"
              render={(props) => (
                <UnsubscribeWeeklyEmail routeParams={props.match.params} {...props} />
              )}
            />
            <Route render={() => <Redirect to="/login" />} />
          </Switch>
          <RefreshSnackbar
            mobile_offset={mobile_width}
            open={display_optional_update}
            handleClose={handleIgnoreVersion}
          />
          <NetworkSnackbar mobile_offset={mobile_width} open={!isOnline} />
        </>
      );
    }
    return (
      <>
        <Switch>
          {/* Public Routes */}
          <Route path="/devices" render={(props) => <DiagnosticsRoutes {...props} />} />
          <Route
            path="/unsubscribe_weekly_email/:email/:user_id"
            render={(props) => (
              <UnsubscribeWeeklyEmail routeParams={props.match.params} {...props} />
            )}
          />

          {/* Auth Routes */}
          <AuthRoutes />
        </Switch>
        <RefreshSnackbar
          mobile_offset={mobile_width}
          open={display_optional_update}
          handleClose={handleIgnoreVersion}
        />
        <NetworkSnackbar mobile_offset={mobile_width} open={!isOnline} />
      </>
    );
  })
);
