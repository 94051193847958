import { datadogLogs } from '@datadog/browser-logs';
import { delay } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { readySession } from '../action/session';
import { newVersion } from '../action/version';

// get hash from the script tag
const hash_regex = /.*main.?([a-z0-9]*)?.js$/;
const matching_script = Array.from(document.body.getElementsByTagName('script') || []).find(
  (script) => hash_regex.test(script.src)
);
const current_hash = matching_script ? (matching_script.src.match(hash_regex) || [])[1] : null;

const fetchVersionData = async () => {
  try {
    const response = await fetch(`/version.json?t=${new Date().getTime()}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log('fetch version.json error', error);
  }
};

export function* versionCheck() {
  let hash_changed = false;
  while (true) {
    // run after 1s
    yield call(delay, 1000);

    try {
      const response: any = yield call(fetchVersionData);
      // current hash is not placeholder string and not different than the new hash
      const latest_app_version = response && response.hash ? response.hash.main : 'local env';
      const loaded_app_version = current_hash ? current_hash : 'local env';
      const latest_app_build_number = response && response.build ? response.build : 'local env';
      const current_loaded_app_info = {
        loaded_app_version,
        latest_app_version,
        latest_app_build_number,
        has_loaded_app_match_latest_app: loaded_app_version === latest_app_version,
      };

      datadogLogs.logger.info('CURRENT LOADED APP VERSION INFO:', {
        current_loaded_app_info,
      });

      hash_changed = response
        ? Boolean(current_hash && current_hash !== response.hash.main)
        : false;
    } catch (error) {
      console.log(error);
    }

    if (hash_changed) {
      yield put(newVersion());
    }
    // check for updates for every 1 min period without refresh
    yield call(delay, 1000 * 60);
  }
}

export function* versionWatcher() {
  yield takeLatest(getType(readySession), versionCheck);
}
