import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy } from '../../../util';
import { GetGrainTelemetryQuery, withGetGrainTelemetry as HOC } from '../__generated';

export type WithGetGrainTelemetryHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  pollInterval?: number;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainTelemetryHocChildProps = {
  loading: boolean;
  grain_container: GetGrainTelemetryQuery['grain_container'] | null;
};
export const withGetGrainTelemetryHoc: AmberHoc<
  WithGetGrainTelemetryHocOwnProps,
  WithGetGrainTelemetryHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainTelemetryHocOwnProps,
      WithGetGrainTelemetryHocChildProps,
      typeof component
    >,
    WithGetGrainTelemetryHocChildProps
  >({
    options: ({ grain_bin_id, onError, pollInterval = 1000 * 60 * 30 }) => ({
      pollInterval,
      onError,
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id: grain_bin_id, container_type: ContainerTypeLegacy.bin },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          grain_container: null,
        };
      }
      return {
        loading: loading || false,
        grain_container: data.grain_container || null,
      };
    },
  })(component as any);
