import { DateTime } from 'luxon';
import {
  amber_amber,
  amber_blue,
  amber_brown,
  amber_green,
  amber_maroon,
  amber_sand,
  black,
} from '../../../../style';
import { RelativePeriods } from '../../../../util/constant';

// ?.Assummed Grain Container have atmost 6 hubs
export const LINE_COLORS = [amber_amber, amber_brown, amber_maroon, amber_sand, amber_blue, black];
export const GRAIN_TICKET_LINE_COLOR = amber_green;

export const getXMinFromPeriod = (period: string): DateTime => {
  let xMin;
  switch (period) {
    case RelativePeriods.day:
      xMin = DateTime.local().plus({ days: -1 });
      break;
    case RelativePeriods.week:
      xMin = DateTime.local().plus({ days: -7 });
      break;
    case RelativePeriods.month:
      xMin = DateTime.local().plus({ months: -1 });
      break;
    case RelativePeriods.quarter:
      xMin = DateTime.local().plus({ months: -3 });
      break;
    default:
      throw new Error(`Unexpected period ${period}`);
  }
  return xMin;
};

export const getTickValues = (
  period: string,
  year: number,
  month: number,
  date: number,
  hour: number
): DateTime[] => {
  if (period === RelativePeriods.day) {
    return [
      DateTime.local(year, month, date, hour).plus({ hours: -24 }),
      DateTime.local(year, month, date, hour).plus({ hours: -20 }),
      DateTime.local(year, month, date, hour).plus({ hours: -16 }),
      DateTime.local(year, month, date, hour).plus({ hours: -12 }),
      DateTime.local(year, month, date, hour).plus({ hours: -8 }),
      DateTime.local(year, month, date, hour).plus({ hours: -4 }),
      DateTime.local(),
    ];
  }

  if (period === RelativePeriods.week) {
    return [
      DateTime.local(year, month, date, 12).plus({ days: -6 }),
      DateTime.local(year, month, date, 12).plus({ days: -5 }),
      DateTime.local(year, month, date, 12).plus({ days: -4 }),
      DateTime.local(year, month, date, 12).plus({ days: -3 }),
      DateTime.local(year, month, date, 12).plus({ days: -2 }),
      DateTime.local(year, month, date, 12).plus({ days: -1 }),
      DateTime.local(),
    ];
  }
  if (period === RelativePeriods.month) {
    return [
      DateTime.local(year, month, date, 12).plus({ days: -28 }),
      DateTime.local(year, month, date, 12).plus({ days: -21 }),
      DateTime.local(year, month, date, 12).plus({ days: -14 }),
      DateTime.local(year, month, date, 12).plus({ days: -7 }),
      DateTime.local(),
    ];
  }
  if (period === RelativePeriods.quarter) {
    if (date < 15) {
      return [
        DateTime.local(year, month, date, 12).plus({ months: -3 }),
        DateTime.local(year, month, date, 12).plus({ months: -2 }),
        DateTime.local(year, month, date, 12).plus({ months: -1 }),
        DateTime.local(),
      ];
    }
    return [
      DateTime.local(year, month, 15, 12).plus({ months: -2 }),
      DateTime.local(year, month, 15, 12).plus({ months: -1 }),
      DateTime.local(),
    ];
  }
  throw new Error(`Unexpected period ${period}`);
};

export const isValidDate = (dt, period, today) => {
  const { year, month, day, hour } = today;
  switch (period) {
    case RelativePeriods.day:
      if (
        new Date(dt).getTime() >=
          DateTime.local(year, month, day, hour)
            .plus({ hours: -24 })
            .toMillis() &&
        new Date(dt).getTime() <= today.toMillis()
      ) {
        return true;
      }
      break;
    case RelativePeriods.week:
      if (
        new Date(dt).getTime() >=
          DateTime.local(year, month, day, 12)
            .plus({ days: -6 })
            .toMillis() &&
        new Date(dt).getTime() <= today.toMillis()
      ) {
        return true;
      }
      break;
    case RelativePeriods.month:
      if (
        new Date(dt).getTime() >=
          DateTime.local(year, month, day, 12)
            .plus({ days: -28 })
            .toMillis() &&
        new Date(dt).getTime() <= today.toMillis()
      ) {
        return true;
      }
      break;
    case RelativePeriods.quarter:
      if (
        new Date(dt).getTime() >=
          DateTime.local(year, month, day, 12)
            .plus({ months: -3 })
            .toMillis() &&
        new Date(dt).getTime() <= today.toMillis()
      ) {
        return true;
      }
      break;
    default:
      break;
  }
  return false;
};

export const getXaxisInterval = (period, isMobile): number =>
  ({
    [RelativePeriods.day]: isMobile ? 6 * 3600 * 1000 : 4 * 3600 * 1000,
    [RelativePeriods.week]: 24 * 3600 * 1000, // 24 hrs
    [RelativePeriods.month]: 7 * 24 * 3600 * 1000, // 7 days
    [RelativePeriods.quarter]: 31 * 24 * 3600 * 1000, // 1 month
  }[period]);
