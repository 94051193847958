import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useState } from 'react';
import { GetProps, Omit, useDispatch } from 'react-redux';

import { endSession, pushNav } from '../../action';
import {
  UserFragmentFragment,
  withRequestUserVerificationHoc,
  WithRequestUserVerificationHocChildProps,
} from '../../api';
import { amber_amber } from '../../style';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../util/form2/BaseForm';
import { Button, ButtonSubmit } from '../util/form2/Button';
import { ErrorBox } from '../util/form2/ErrorBox';

type Values = {
  user_id: number;
};

const useStyles = makeStyles({
  centered: { textAlign: 'center' },
  centered_flex: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  centered_col: {
    flexDirection: 'column',
  },
});

export const UserEmailVerificationFormBase = ({
  requestUserVerification,
  onSubmitSuccess,
  onClickUpdateUser,
  user: { user_id, email_address },
  ...props
}: FormikWrapperHandlerProps<Values, boolean> &
  WithRequestUserVerificationHocChildProps & {
    user: UserFragmentFragment;
    onClickUpdateUser: () => void;
  }) => {
  const classes = useStyles();
  const [succeeded, setSucceeded] = useState(false);
  const submitCallback = useCallback((values) => {
    setSucceeded(false);
    return requestUserVerification(values);
  }, []);
  const submitSuccessCallback = useCallback(
    (values, formikHelpers) => {
      // remove token so no weird behavior on refresh
      window.localStorage.removeItem('token');
      // success
      setSucceeded(true);
      onSubmitSuccess && onSubmitSuccess(values, formikHelpers);
    },
    [onSubmitSuccess]
  );
  const dispatch = useDispatch();
  const onClickLogout = useCallback(() => dispatch(endSession()), [dispatch]);
  return (
    <FormikWrapper<Values, boolean>
      {...props}
      initialValues={{
        user_id,
      }}
      onSubmit={submitCallback}
      onSubmitSuccess={submitSuccessCallback}
      render={({ isSubmitting }) => {
        return (
          <Dialog open maxWidth="xs">
            {!succeeded && (
              <DialogTitle>
                The email address for this account has not yet been verified. Please verify your
                email address to sign in:
                <br />
                <span
                  className={classes.centered_flex}
                  style={{
                    color: amber_amber,
                  }}
                >
                  {email_address}
                </span>
              </DialogTitle>
            )}
            <DialogContent>
              {succeeded ? (
                <span className={`${classes.centered_flex} ${classes.centered_col}`}>
                  <p>
                    An email verification link has been sent. Once you verify your email, you will
                    be able to sign in.
                  </p>
                  <Button disabled={isSubmitting} onClick={onClickLogout}>
                    RETURN TO LOGIN
                  </Button>
                </span>
              ) : (
                <BaseForm submitting_message="Requesting verification email...">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    alignContent="flex-start"
                    justify="center"
                    spacing={2}
                  >
                    <ErrorBox />
                    <Grid item xs={12} className={classes.centered}>
                      <ButtonSubmit allow_pristine>
                        SEND ANOTHER EMAIL VERIFICATION LINK
                      </ButtonSubmit>
                    </Grid>
                  </Grid>
                </BaseForm>
              )}
            </DialogContent>
          </Dialog>
        );
      }}
    />
  );
};

export const UserEmailVerificationForm = withRequestUserVerificationHoc(
  (props: Omit<GetProps<typeof UserEmailVerificationFormBase>, 'onClickUpdateUser'>) => {
    const dispatch = useDispatch();
    const clickUpdateUserCallback = useCallback(
      () => dispatch(pushNav({ path: '/user/account' })),
      []
    );
    return <UserEmailVerificationFormBase {...props} onClickUpdateUser={clickUpdateUserCallback} />;
  }
);
