export enum ContainerTypeLegacy {
  bin = 1,
  barge = 2,
  pile = 3,
}

export interface AmberError extends Error {
  code?: number;
  params?: {
    [k: string]: any;
  };
}

export enum RelativeTimePeriod {
  day = '1D',
  week = '1W',
  month = '1M',
  quarter = '3M',
}

export enum RelativePeriods {
  day = '1D',
  week = '1W',
  month = '1M',
  quarter = '3M',
}

export enum LevelStat {
  volume = 'volume',
  bushel = 'bushel',
}

export enum Statistic {
  emc = 'emc',
  humidity_rh = 'humidity_rh',
  temp_f = 'temp_f',
  co2_ppm = 'co2_ppm',
}
export enum RangeState {
  bad = 'BAD',
  ok = 'OK',
  good = 'GOOD',
  unknown = 'UNKNOWN',
}

export enum FanControllerConfigReason {
  UNKNOWN = 0,
  USER = 1,
  SYSTEM_ADMIN = 2,
  WEATHER_SAFETY = 3,
  POWER_DOWN = 4,
  DEFAULT_CONFIG = 5,
}

export const get_vertical_sidebar_width = (device) => {
  const width = {
    'xs-mobile': 50,
    'sm-mobile': 88,
    tablet: 50,
    desktop: 88,
  }[device];
  return width || 88;
};

export const get_container_select_width = (device) => {
  const width = {
    'xs-mobile': 250,
    'sm-mobile': 327,
    tablet: 250,
    desktop: 327,
  }[device];
  return width || 327;
};

export const EXTRA_CUSTOM_BREAKPOINT_DIFFERENCE = 320;
export const topbar_height = 108;
export const mobilebar_height = 42;
export const sidebar_item_height = 72;
