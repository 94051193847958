import * as React from 'react';
const DarkCheckBoxUncheckIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g id="SelectBox" transform="translate(-485 -208)">
      <g
        id="darkCheckBoxUncheckIcon"
        data-name="SelectBox"
        transform="translate(485 208)"
        fill="#fff"
        stroke="#cfd0d4"
        strokeWidth={1}
      >
        <rect width={24} height={24} rx={4} stroke="none" />
        <rect x={0.5} y={0.5} width={23} height={23} rx={3.5} fill="none" />
      </g>
    </g>
  </svg>
);
export { DarkCheckBoxUncheckIcon };
