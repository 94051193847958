import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {FanController, GrainBinTickets, withGetFanController as HOC} from '../__generated';

export type WithGetFanControllerHocOwnProps = {
  fan_controller_id: number;
  onError?: (error: ApolloError) => void;
};
export type WithGetFanControllerHocChildProps = {
  loading?: boolean;
  refetch_fan_controller: ()=>Promise<any>;
  fan_controller: FanController | any;
};

export const withGetFanControllerHoc: AmberHoc<
    WithGetFanControllerHocOwnProps,
    WithGetFanControllerHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
        WithGetFanControllerHocOwnProps,
        WithGetFanControllerHocChildProps,
      typeof component
    >,
      WithGetFanControllerHocChildProps
  >({
    options: ({ fan_controller_id, onError }) => ({
      onError,
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      variables: {fan_controller_id}
    }),
    props: ({data}) =>{
      if (!data || data.loading){
        return {
          loading: true,
          fan_controller: null,
          refetch_fan_controller: () => Promise.resolve(),
        };
      }
      return {
        loading: false,
        fan_controller: data.fan_controller || null,
        refetch_fan_controller: data.refetch,
      };
    },
  })(component as any);
