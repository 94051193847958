import { Grid, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React from 'react';
import { useGrainBinData } from '../../../../../core/src/contexts';
import {
  FanHeartbeatErrorCode,
  FanSettingsFragmentFragment,
  withGetGrainContainerHistoryAerationRunsWithLimitHoc,
  WithGetGrainContainerHistoryAerationRunsWithLimitHocChildProps,
} from '../../../api';
import { amber_amber, amber_grey } from '../../../style';
import { ContainerTypeLegacy, isNoEnd } from '../../../util';
import { ColumnConfig } from '../../util';
import {
  FanControllerWithError,
  isQualifyingFanHeartbeatErrorCode,
  QualifyingFanHeartbeatErrorCode,
  RunWindowFanErrorMessage,
} from '../aeration/FanControllerErrorDisplay';
import { FanStatus } from '../aeration/FanStatus';
import { getSimpleFanStatus, SimpleFanStatus } from '../aeration/FanStatusHelpers';
import { RecommendedScheduledRange } from '../daily-forecast/RecommendationScheduleBar';
import LogFanErrors from '../weather-forecast-analysis/LogFanErrors';
import { CurrentScheduleList } from './CurrentScheduleList';
import { RecommendedScheduleList } from './RecommendedScheduleList';

const useStyles = makeStyles({
  fan_status: {
    fontSize: '1.28rem',
    lineHeight: 1.33,
    textAlign: 'center',
  },
  fan_status_container: {
    textAlign: 'start',
    display: 'block',
  },
  smallIcon: {
    fontSize: 17,
    width: 40,
    height: 24,
    color: amber_amber,
  },
});

const formatDateTime = (date: Date) => {
  const dt = DateTime.fromMillis(new Date(date).getTime());
  return dt.toFormat('ccc t');
};

type ScheduleContainerProps = {
  grain_bin_fan_settings: FanSettingsFragmentFragment;
  scheduledRunsToPresent: RecommendedScheduledRange[];
  recommendationSchedule: RecommendedScheduledRange[];
  showScheduleCaptureTime?: boolean;
  hasManualMode: boolean;
  show_fan_guidance_ext_prompt: boolean;
  container_id: number;
  container_type: ContainerTypeLegacy;
} & WithGetGrainContainerHistoryAerationRunsWithLimitHocChildProps;

const columns: ColumnConfig<{ start_epoch: Date; end_epoch: Date }>[] = [
  {
    title: 'Start',
    width: 20,
    align: 'center',
    alignTitle: 'center',
    getValue: ({ start_epoch }) => (start_epoch ? formatDateTime(start_epoch) : `N/A`),
  },
  {
    title: '',
    width: 10,
    align: 'center',
    alignTitle: 'center',
    getValue: () => (
      <div>
        <Typography variant="subtitle1" style={{ color: amber_grey, width: 'fit-content' }}>
          <ArrowForward style={{ fontSize: 17, width: 40, height: 24, color: amber_amber }} />
        </Typography>
      </div>
    ),
  },
  {
    title: 'End',
    width: 20,
    align: 'center',
    alignTitle: 'center',
    getValue: ({ start_epoch, end_epoch }) =>
      end_epoch && !isNoEnd(start_epoch, end_epoch) ? formatDateTime(end_epoch) : `N/A`,
  },
];

const ScheduleContainer = withGetGrainContainerHistoryAerationRunsWithLimitHoc(
  ({
    grain_bin_fan_settings,
    scheduledRunsToPresent,
    recommendationSchedule,
    showScheduleCaptureTime,
    hasManualMode,
    show_fan_guidance_ext_prompt,
    aeration_runs,
  }: ScheduleContainerProps) => {
    const classes = useStyles();
    const { enable_fan_guidance } = useGrainBinData();
    const getFormattedSchedules = (schedules) => {
      return schedules.length > 0
        ? schedules
            .filter(({ text }) => text)
            .map(({ startEpoch, endEpoch }) => ({
              start_epoch: new Date(startEpoch),
              end_epoch: new Date(endEpoch),
            }))
        : [];
    };

    const currentSchedule: { start_epoch: Date; end_epoch: Date }[] = getFormattedSchedules(
      scheduledRunsToPresent
    );
    const fan_controllers_with_errors: FanControllerWithError[] | null =
      (grain_bin_fan_settings &&
        grain_bin_fan_settings.grain_bin.fan_controllers.reduce((acc, next) => {
          if (
            next.fan_controller &&
            next.fan_controller.state_next &&
            isQualifyingFanHeartbeatErrorCode(next.fan_controller.state_next.error_code)
          ) {
            const error = {
              error_code: next.fan_controller.state_next
                .error_code as QualifyingFanHeartbeatErrorCode,
              core_id: next.fan_controller.core_id,
              alias: next.alias || next.fan_controller.alias,
            };
            const errors = acc || ([] as FanControllerWithError[]);
            return [...errors, error];
          }
          return acc;
        }, null)) ||
      null;

    const scheduled_start =
      (scheduledRunsToPresent &&
        scheduledRunsToPresent[0] &&
        new Date(scheduledRunsToPresent[0].startEpoch)) ||
      null;
    const scheduled_end =
      (scheduledRunsToPresent &&
        scheduledRunsToPresent[0] &&
        new Date(scheduledRunsToPresent[0].endEpoch)) ||
      null;

    const grain_bin_fan_controllers = grain_bin_fan_settings
      ? grain_bin_fan_settings.grain_bin.fan_controllers
      : [];
    const any_fans_running = grain_bin_fan_controllers.some(
      (ctrl) => ctrl.fan_controller.state_next && ctrl.fan_controller.state_next.is_on
    );

    // checking has schedule interrupted?
    let has_current_overlap_runwindow = false;
    if (scheduled_start && scheduled_end) {
      const now = new Date().getTime();
      has_current_overlap_runwindow = Boolean(
        now &&
          scheduled_start &&
          scheduled_end &&
          now > scheduled_start.getTime() &&
          now < scheduled_end.getTime()
      );
    }
    const fan_statuses = grain_bin_fan_controllers.map((ctrl, ix) => {
      return {
        id: ctrl.fan_controller_id_next,
        fan_name: ctrl.alias || `Fan ${ix}`,
        ...getSimpleFanStatus(ctrl),
      };
    });
    const hasAnyFanReady = fan_statuses.some(({ status }) => status === SimpleFanStatus.READY);
    const hasScheduledRunInterrupted =
      !enable_fan_guidance && !any_fans_running && has_current_overlap_runwindow && hasAnyFanReady;
    console.log('hasScheduledRunInterrupted', hasScheduledRunInterrupted);

    // show Error Msg logic
    const hasFanErrors = Boolean(
      fan_controllers_with_errors && fan_controllers_with_errors.length > 0
    );
    const now = new Date();
    const hasErrorWithinFirstRunWindow = Boolean(
      currentSchedule &&
        currentSchedule.length > 0 &&
        currentSchedule[0].start_epoch &&
        now.getTime() >= new Date(currentSchedule[0].start_epoch).getTime() &&
        currentSchedule[0].end_epoch &&
        now.getTime() < new Date(currentSchedule[0].end_epoch).getTime()
    );
    const showErrorMsgInFirstRunWindow =
      hasFanErrors &&
      !any_fans_running &&
      Boolean(
        fan_controllers_with_errors &&
          fan_controllers_with_errors.find(
            ({ error_code }) => error_code !== FanHeartbeatErrorCode.StopFail
          )
      ) &&
      hasErrorWithinFirstRunWindow;
    const hasFanStopErrors = Boolean(
      fan_controllers_with_errors &&
        fan_controllers_with_errors.find(
          ({ error_code }) => error_code === FanHeartbeatErrorCode.StopFail
        )
    );
    const hasFanStopErrorCondition =
      Boolean(
        currentSchedule &&
          currentSchedule.length > 0 &&
          currentSchedule[0].start_epoch &&
          now.getTime() < new Date(currentSchedule[0].start_epoch).getTime()
      ) || Boolean(currentSchedule && currentSchedule.length === 0);
    const showErrorMsgAboveSchedule =
      any_fans_running && hasFanErrors && hasFanStopErrors && hasFanStopErrorCondition;

    // error message
    const fanStopFailErrors = fan_controllers_with_errors
      ? fan_controllers_with_errors.filter(
          ({ error_code }) => error_code === FanHeartbeatErrorCode.StopFail
        )
      : [];

    const fanStopErrorMsgs =
      showErrorMsgAboveSchedule && fanStopFailErrors.length > 0
        ? fanStopFailErrors.length === 1
          ? 'Fan Failed to Stop'
          : 'Fans Failed to Stop'
        : undefined;

    let fanErrorMsgsWithoutStopFail =
      showErrorMsgInFirstRunWindow && fan_controllers_with_errors
        ? fan_controllers_with_errors
            .filter(({ error_code }) => error_code !== FanHeartbeatErrorCode.StopFail)
            .map(({ error_code }) => RunWindowFanErrorMessage[error_code])
        : [];
    // merge same errors
    fanErrorMsgsWithoutStopFail =
      fanErrorMsgsWithoutStopFail.length > 1
        ? fanErrorMsgsWithoutStopFail.every((msg) => msg === fanErrorMsgsWithoutStopFail[0])
          ? [fanErrorMsgsWithoutStopFail[0].replace('Fan', 'Fans')]
          : ['Fans Failed to Start']
        : fanErrorMsgsWithoutStopFail;

    return (
      <>
        <Grid
          style={{
            margin: 15,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {grain_bin_fan_settings && grain_bin_fan_settings.grain_bin.fan_controllers.length > 0 && (
            <Grid style={{ marginBottom: 15, marginTop: 5, display: 'flex', width: '50%' }}>
              <FanStatus
                grain_bin_fan_settings={grain_bin_fan_settings}
                fan_updating={false}
                scheduled_start={scheduled_start}
                scheduled_end={scheduled_end}
                hasManualMode={hasManualMode}
                showRestartButton={hasScheduledRunInterrupted}
              />
            </Grid>
          )}
          {fan_controllers_with_errors && (
            <Grid style={{ display: 'flex', width: '50%' }}>
              <LogFanErrors fan_controllers_with_errors={fan_controllers_with_errors} />
            </Grid>
          )}
        </Grid>

        {showScheduleCaptureTime && (
          <Grid
            style={{
              marginBottom: 15,
              marginLeft: 80,
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h5">
              Now: {DateTime.local().toFormat('ccc LLL d, t ZZZZ')}
            </Typography>
          </Grid>
        )}

        <Grid
          style={{
            margin: 15,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'border-box',
              width: '48%',
            }}
          >
            <CurrentScheduleList
              schedule={currentSchedule}
              hasManualMode={hasManualMode}
              show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
              any_running={any_fans_running}
              showErrorMsgInFirstRunWindow={showErrorMsgInFirstRunWindow}
              fanErrorMsgsWithoutStopFail={fanErrorMsgsWithoutStopFail}
              showErrorMsgAboveSchedule={showErrorMsgAboveSchedule}
              fanStopErrorMsgs={fanStopErrorMsgs}
              aeration_runs={aeration_runs}
              hasScheduledRunInterrupted={hasScheduledRunInterrupted}
            />
          </div>

          <div
            style={{
              display: 'border-box',
              width: '48%',
            }}
          >
            <RecommendedScheduleList schedule={getFormattedSchedules(recommendationSchedule)} />
          </div>
        </Grid>
      </>
    );
  }
);

export default ScheduleContainer;
