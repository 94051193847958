import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Snackbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { ArrayHelpers, FieldArray, useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import { GetProps } from 'react-redux';
import * as yup from 'yup';

import { QRScanDialog } from '..';
import QrCodeIcon from '../../../../core/src/media/icons/QrCodeIcon';
import { GetAreFanControllersAssignedDocument } from '../../api';
import { getDiagTestFanControllerApi } from '../../api/rest';
import { parseDeviceCoreId } from '../../util/parsing-utils';
import { Alert } from '../account/AccountUsersLayout';
import { ALLOWED_NO_OF_FANS } from '../grain-container/aeration/FanControllerFormFields';
import {
  FanModelDetails,
  GrainFanModels,
} from '../grain-container/grain-fan-model/GrainFanModelSelector';
import DividerWithText from '../util/DividerWithText';
import { Checkbox } from '../util/form2/Checkbox';
import { NumberTextField } from '../util/form2/NumberField';
import { Select } from '../util/form2/Select';
import { yup_fan_type } from '../util/form2/SelectFanType';
import { TextField } from '../util/form2/TextField';
import { WizardStep } from './WizardStep';

export const QR_CODE_SCAN_FAILED_MSG = (
  <>
    Sorry! QR code scan failed. Unable to access camera. Try accessing the Amber App (
    <a href="https://app.amber.ag" style={{ color: 'white' }}>
      app.amber.ag
    </a>
    ) using Google Chrome or Safari web browser.
  </>
);
export const DEVICE_NOT_FOUND_MSG = 'Device not found, please contact hank@amber.ag for assistance';

const useStyles = makeStyles({
  grid: {
    minWidth: 300,
    maxWidth: 450,
  },
  section: {
    width: '100%',
    marginBottom: 30,
  },
});

export const hasDuplicateFanControllers = (fan_controllers): boolean => {
  let containsDuplicate = false;
  for (const i in fan_controllers) {
    for (const j in fan_controllers) {
      if (
        i !== j &&
        fan_controllers[i].fan_controller_id === fan_controllers[j].fan_controller_id
      ) {
        containsDuplicate = true;
        break;
      }
    }
    if (containsDuplicate) break;
  }
  return containsDuplicate;
};

export const canFanControllerBeAssigned = async (client, device_ids) => {
  const response = await client.query({
    query: GetAreFanControllersAssignedDocument,
    variables: {
      device_ids,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  const data = response.data;
  if (response.errors || !data || !data.are_fan_controllers_assigned) {
    if (
      response.errors &&
      response.errors[0] &&
      response.errors[0].message &&
      response.errors[0].message.includes('not found')
    ) {
      return 'Fan Controller ID could not be found. Check to make sure the ID was entered correctly. Please contact hank@amber.ag for assistance.';
    }
    return 'Unexpected error, please contact hank@amber.ag for assistance';
  }
  const already_assigned = data.are_fan_controllers_assigned
    .filter((assign_status) => assign_status.is_assigned)
    .map((assign_status) => assign_status.device_id);
  if (already_assigned.length) {
    return `Fan Controller ID${
      already_assigned.length > 1 ? 's' : ''
    } already in use for: ${already_assigned.join(', ')}`;
  }
  return null;
};

export const validation_schema = yup.object().shape({
  fan_controllers: yup.array().of(
    yup.object().shape({
      fan_controller_id: yup
        .string()
        .required()
        .label('Fan Controller ID'),
      fan_alias: yup
        .string()
        .required()
        .label('Fan Controller Name'),
      has_plenum_sensor: yup.boolean(),
      voltage: yup
        .number()
        .typeError('A number is required')
        .nullable()
        .required()
        .label('Voltage'),
      power_factor: yup
        .number()
        .required()
        .typeError('Power Factor requires a decimal')
        .moreThan(0.5)
        .lessThan(1)
        .nullable()
        .label('Power Factor (P.F.)'),
      phase: yup
        .number()
        .typeError('A number is required')
        .oneOf([null, 1, 3])
        .nullable()
        .required()
        .label('Phase'),
      no_of_fans_connected: yup
        .string()
        .required()
        .oneOf(ALLOWED_NO_OF_FANS),
    })
  ),
});

export const AddFanControllerStep = ({
  title = 'Add Fan Controller(s) for this Bin',
  validationSchema,
}: Partial<GetProps<typeof WizardStep>>) => {
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext<any>();
  const array_values = useMemo(() => values['fan_controllers'], [values]);
  const [showQRScannerDialog, setshowQRScannerDialog] = useState(false);
  const [hasQRScanningFailed, setHasQRScanningFailed] = useState(false);
  const [isDeviceNotFound, setIsDeviceNotFound] = useState(false);
  const [targetTextFieldName, setTargetTextFieldName] = useState('');
  const all_fcs_fan_model_filters = useMemo(
    () =>
      array_values.map((fc_values) =>
        fc_values.fan_model_id
          ? { label_name: 'fan_model_id', label_value: fc_values.fan_model_id.toString() }
          : { label_name: 'fan_model_id', label_value: '-99' }
      ),
    [array_values]
  );
  const no_of_fans_connected_options = ALLOWED_NO_OF_FANS;
  const DEFAULT_NO_FANS_CONNECTED = '1';

  console.log('all_fcs_fan_model_filters', all_fcs_fan_model_filters);

  const setFanModel = (ix: number, currentFanModelState: FanModelDetails | null) => {
    if (currentFanModelState) {
      const {
        fan_model_type,
        diameter,
        fan_brand_name,
        fan_horsepower,
        fan_model_created_type,
        make,
        fan_model_id,
        linked_generic_fan_model_id,
      } = currentFanModelState;
      setFieldValue(`fan_controllers.${ix}.fan_model_id`, fan_model_id);
      setFieldValue(`fan_controllers.${ix}.make`, make);
      setFieldValue(`fan_controllers.${ix}.fan_brand_name`, fan_brand_name);
      setFieldValue(`fan_controllers.${ix}.fan_model_type`, fan_model_type);
      setFieldValue(`fan_controllers.${ix}.fan_horsepower`, fan_horsepower);
      setFieldValue(`fan_controllers.${ix}.diameter`, diameter);
      setFieldValue(`fan_controllers.${ix}.fan_model_created_type`, fan_model_created_type);
      setFieldValue(
        `fan_controllers.${ix}.linked_generic_fan_model_id`,
        linked_generic_fan_model_id
      );
    }
  };

  const openQRScannerDialog = (targetTextField) => {
    setTargetTextFieldName(targetTextField);
    setshowQRScannerDialog(true);
  };

  const closeQRScannerDialog = () => {
    setshowQRScannerDialog(false);
  };

  const handleScan = async (data) => {
    if (data) {
      try {
        const coreId = parseDeviceCoreId(data);
        const response = await getDiagTestFanControllerApi(coreId);
        console.log('response', coreId, response.data.data);
        if (Object.keys(response.data.data).length === 0) {
          setIsDeviceNotFound(true);
          return;
        }
        setFieldValue(targetTextFieldName, response.data.data.alias);
        closeQRScannerDialog();
      } catch (error) {
        setHasQRScanningFailed(true);
        console.error(error);
      }
    }
  };

  const handleError = (err) => {
    setHasQRScanningFailed(true);
  };

  const getCurrentFanModelState = (values) => {
    console.log('inside getCurrentFanModelState values', values);
    if (!Object.keys(values).includes('fan_brand_name')) return null;
    return {
      fan_model_type: values.fan_model_type,
      diameter: values.diameter,
      fan_brand_name: values.fan_brand_name,
      fan_horsepower: values.fan_horsepower,
      fan_model_created_type: values.fan_model_created_type,
      make: values.make,
      fan_model_id: values.fan_model_id,
      linked_generic_fan_model_id: values.linked_generic_fan_model_id,
    };
  };

  return (
    <WizardStep title={title} validationSchema={validationSchema}>
      <FieldArray name={'fan_controllers'}>
        {(arrayHelpers: ArrayHelpers) => {
          return (
            <Grid
              container
              direction="column"
              alignContent="flex-start"
              alignItems="center"
              justify="center"
              spacing={2}
              className={classes.grid}
            >
              {array_values && array_values.length
                ? array_values.map((value, ix) => (
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      key={`fan_controllers'-${ix}`}
                    >
                      <DividerWithText
                        centerText={`FAN CONTROLLER ${ix + 1}`}
                        style={{ width: '100%' }}
                      />
                      <Grid
                        container
                        direction="column"
                        item
                        xs={11}
                        style={{
                          border: '1px solid lightgrey',
                          borderRadius: 4,
                          padding: 10,
                          marginTop: 20,
                        }}
                      >
                        <div>
                          <div className={classes.section}>
                            <TextField
                              style={{ marginTop: 10 }}
                              fullWidth
                              label="Fan Controller ID"
                              placeholder="MFLD_00-0-000"
                              name={`fan_controllers.${ix}.fan_controller_id`}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="open QR code scanner"
                                      onClick={() =>
                                        openQRScannerDialog(
                                          `fan_controllers.${ix}.fan_controller_id`
                                        )
                                      }
                                    >
                                      <QrCodeIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              required
                            />
                            <TextField
                              style={{ marginTop: 10 }}
                              fullWidth
                              label="Fan Nickname"
                              placeholder="North Fan"
                              name={`fan_controllers.${ix}.fan_alias`}
                              required
                            />
                          </div>

                          <div className={classes.section}>
                            <GrainFanModels
                              fan_model_filter={
                                all_fcs_fan_model_filters.length > 0
                                  ? [all_fcs_fan_model_filters[ix]]
                                  : []
                              }
                              currentFanModelState={getCurrentFanModelState(array_values[ix])}
                              setCurrentFanModelState={(currentFanModelState: any) =>
                                setFanModel(ix, currentFanModelState)
                              }
                            />
                          </div>
                          <div className={classes.section}>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: 600, textDecoration: 'underline' }}
                            >
                              Fan Power:
                            </Typography>
                            <NumberTextField
                              style={{ marginTop: 10 }}
                              fullWidth
                              label="Power Factor (P.F.)"
                              name={`fan_controllers.${ix}.power_factor`}
                              id="power_factor"
                              parseValue={(val) => Number(val)}
                              step=".01"
                              helperText="Check the label on the fan's motor to find the Power Factor"
                              inputMode="decimal"
                              required
                            />
                            <Select
                              name={`fan_controllers.${ix}.voltage`}
                              label="Voltage"
                              fullWidth
                              style={{ marginTop: 10 }}
                              placeholder="None Selected"
                              required
                            >
                              <MenuItem value={110}>110v</MenuItem>
                              <MenuItem value={230}>230v</MenuItem>
                              <MenuItem value={480}>480v</MenuItem>
                              <MenuItem value={600}>600v</MenuItem>
                            </Select>
                            <Select
                              name={`fan_controllers.${ix}.phase`}
                              label="Phase"
                              fullWidth
                              style={{ marginTop: 10 }}
                              placeholder="None Selected"
                              required
                            >
                              <MenuItem value={1}>Single Phase</MenuItem>
                              <MenuItem value={3}>Three Phase</MenuItem>
                            </Select>
                          </div>
                          <div className={classes.section} style={{ marginBottom: 0 }}>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: 600, textDecoration: 'underline' }}
                            >
                              Advanced Settings:
                            </Typography>
                            <Select
                              name={`fan_controllers.${ix}.no_of_fans_connected`}
                              label="Number of Fans connected to this Fan Controller"
                              fullWidth
                              style={{ marginTop: 10 }}
                            >
                              {no_of_fans_connected_options.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                            <Checkbox
                              style={{ marginTop: 10, width: '100%' }}
                              name={`fan_controllers.${ix}.has_plenum_sensor`}
                              label="Plenum Sensor Add-on Equipped"
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => {
                            // not using array helpers' remove because it precludes empty array as a valid value
                            const new_values = [...array_values];
                            new_values.splice(ix, 1);
                            setFieldValue('fan_controllers', new_values);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))
                : null}
              <Grid container item xs={12} direction="row">
                {/* not using useCallback as this relies on arrayHelpers and the code is conditional. no solid way to guarantee number of hooks does not change */}
                <Button
                  style={{ margin: '10px 0' }}
                  fullWidth
                  onClick={() => {
                    arrayHelpers.push({
                      fan_controller_id: '',
                      fan_alias: '',
                      has_plenum_sensor: false,
                      no_of_fans_connected: DEFAULT_NO_FANS_CONNECTED,
                    });
                  }}
                  variant="outlined"
                >
                  + Add {array_values.length > 0 ? 'Another ' : ''}Fan Controller
                </Button>
              </Grid>
            </Grid>
          );
        }}
      </FieldArray>
      <QRScanDialog
        title="Scan device QR code"
        showDialog={showQRScannerDialog}
        closeDialog={closeQRScannerDialog}
        onQRCodeScan={handleScan}
        onQRCodeError={handleError}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={hasQRScanningFailed}
        onClose={() => {
          () => setHasQRScanningFailed(false);
        }}
        autoHideDuration={6000}
      >
        <Alert
          style={{ fontSize: 16 }}
          severity="error"
          onClose={() => setHasQRScanningFailed(false)}
        >
          {QR_CODE_SCAN_FAILED_MSG}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isDeviceNotFound}
        onClose={() => setIsDeviceNotFound(false)}
        autoHideDuration={6000}
      >
        <Alert style={{ fontSize: 16 }} severity="error" onClose={() => setIsDeviceNotFound(false)}>
          {DEVICE_NOT_FOUND_MSG}
        </Alert>
      </Snackbar>
    </WizardStep>
  );
};
