import React from 'react';
import { TStoragePeriod } from '../component/grain-container/ManageStoragePeriods';

export type TContext =
  | {
      // The index of the storage cycle from list returned; index 0 is considered the
      // 'latest' storage period hence why we can refer this as 'order'.
      order: number;
      start_date: Date;
      end_date?: Date | null;
      is_ongoing: boolean;
      storage_cycle_name: string;
      grain_bin_storage_cycle_id: number;
      grain_bin_id: number;
    }
  | null
  | undefined;

export const ActiveStoragePeriodContext = React.createContext({
  activeStoragePeriod: undefined as TContext,
  setActiveStoragePeriod: (value: TContext) => {},
});

export const ActiveStoragePeriod = ({ children }: React.PropsWithChildren<{}>) => {
  const initialValue = undefined;
  const [context, setContext] = React.useState<TContext>(initialValue);
  const value = {
    activeStoragePeriod: context,
    setActiveStoragePeriod: setContext,
  };

  return (
    <ActiveStoragePeriodContext.Provider value={value}>
      {children}
    </ActiveStoragePeriodContext.Provider>
  );
};
