import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useState } from 'react';

const useStyles = makeStyles({
  root: { marginTop: 10 },
  bold: { fontWeight: 'bold' },
});

export const BrowserWarning: React.FunctionComponent<{}> = () => {
  const classes = useStyles();

  const has_dismissed_warning = Boolean(window.localStorage.getItem('dismissed_browser_warning'));

  // for reference: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
  const is_ie =
    navigator.userAgent.indexOf('MSIE') !== -1 || !!(document as any).documentMode === true;

  const [show_browser_warning, setShowBrowserWarning] = useState(is_ie && !has_dismissed_warning);

  const dismissWarning = useCallback(() => {
    setShowBrowserWarning(false);
    window.localStorage.setItem('dismissed_browser_warning', 'true');
  }, [show_browser_warning]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={show_browser_warning}
      onClose={dismissWarning}
      message={
        'This browser is not fully supported by the Amber app. We recommend using Google Chrome.'
      }
      // disable close by outside click
      ClickAwayListenerProps={{ onClickAway: () => {} }}
      className={classes.root}
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={dismissWarning}>
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
};
