import { Button, ButtonGroup, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useMemo } from 'react';
import { GetProps, Omit } from 'react-redux';
import { ActiveStoragePeriodContext, TContext } from '../../../../contexts/activeStoragePeriod';

import {
  GrainEnvPointV2,
  HistoryPointFragment,
  PelletTelemetryHistory,
  WeatherHistoryPoint,
  withGetGrainContainerHistoryAerationRunsWithinPeriodHoc,
  WithGetGrainContainerHistoryAerationRunsWithinPeriodHocChildProps,
  withGetGrainContainerHistoryHeadspaceHoc,
  WithGetGrainContainerHistoryHeadspaceHocChildProps,
  withGetGrainContainerHistoryHeadspaceWeatherHoc,
  WithGetGrainContainerHistoryHeadspaceWeatherHocChildProps,
  withGetGrainContainerHistoryInteriorHoc,
  WithGetGrainContainerHistoryInteriorHocChildProps,
  withGetGrainContainerHistoryPelletHoc,
  WithGetGrainContainerHistoryPelletHocChildProps,
  withGetGrainContainerHistoryWeatherHoc,
  WithGetGrainContainerHistoryWeatherHocChildProps,
} from '../../../../api';
import { amber_amber, white } from '../../../../style';
import { RelativeTimePeriod, Statistic } from '../../../../util';
import { CenteredSpinner } from '../../../spinner';
import {
  HistoryRecordWithHubID,
  MultiHubTelemetryHistoryPlot,
} from './MultiHubTelemetryHistoryPlot';
import { MultiHubTelemetryHistoryPlotV2 } from './MultiHubTelemetryHistoryPlotV2';
import {
  HistoryRecord,
  Range,
  TelemetryHistoryPlot,
  TelemetryMultiHistoryPlot,
} from './TelemetryHistoryPlot';

const useStyles = makeStyles({
  root_0: {
    paddingBottom: 25,
  },
  root_1: {
    paddingBottom: 0,
  },
  radio: {
    height: 10,
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 196,
    height: '100%',
  },
  selectedButton: {
    backgroundColor: amber_amber,
    color: white,
    '&:hover': {
      backgroundColor: fade(amber_amber, 0.8),
    },
  },
});

export type RunWindow = {
  start_epoch: Date;
  end_epoch?: Date | null;
};

export const HEADSPACE_TELEMETRY_VALID_RANGES = {
  temp_f: { min: -40, max: 160 },
  humidity_rh: { min: 1, max: 100 },
  co2_ppm: { min: 200, max: 10000 },
  pressure_pa: { min: 70000, max: 130000 },
};

export const TelemetryHistoryWithMultiHubsCard: React.FunctionComponent<{
  statistic: Statistic;
  ranges: Range[];
  period: RelativeTimePeriod;
  history: HistoryRecordWithHubID[];
  weather_history: {
    x: Date;
    y: number;
  }[];
  loading: boolean;
  show_temp_threshold?: boolean;
  height?: number;
  width?: number;
  run_windows?: RunWindow[];
  onSelectPeriod?: (value: RelativeTimePeriod) => any;
  grain_bin_location_timezone: string;
  minHeight?: number;
  maxHeight?: number;
}> = ({
  statistic,
  ranges,
  period,
  history,
  weather_history,
  loading,
  show_temp_threshold,
  height,
  width,
  run_windows,
  onSelectPeriod,
  grain_bin_location_timezone,
  minHeight,
  maxHeight,
}) => {
  const classes = useStyles();

  return (
    <div className={`${onSelectPeriod ? classes.root_1 : classes.root_0}`}>
      {loading ? (
        <div
          style={{
            width: width ? width : undefined,
            ...(maxHeight && { maxHeight, height: maxHeight }),
          }}
          className={classes.spinner}
        >
          <CenteredSpinner fadeIn="none" />
        </div>
      ) : (
        <MultiHubTelemetryHistoryPlotV2
          height={height || 150}
          width={width}
          period={period}
          history={history || []}
          weather_history={weather_history}
          statistic={statistic}
          ranges={ranges}
          show_temp_threshold={show_temp_threshold}
          run_windows={run_windows}
          grain_bin_location_timezone={grain_bin_location_timezone}
        />
      )}
      {onSelectPeriod && (
        <ButtonGroup color="secondary" size="small">
          <Button
            className={period === RelativeTimePeriod.day ? classes.selectedButton : ''}
            onClick={() => onSelectPeriod(RelativeTimePeriod.day)}
          >
            1D
          </Button>
          <Button
            className={period === RelativeTimePeriod.week ? classes.selectedButton : ''}
            onClick={() => onSelectPeriod(RelativeTimePeriod.week)}
          >
            1W
          </Button>
          <Button
            className={period === RelativeTimePeriod.month ? classes.selectedButton : ''}
            onClick={() => onSelectPeriod(RelativeTimePeriod.month)}
          >
            1M
          </Button>
          <Button
            className={period === RelativeTimePeriod.quarter ? classes.selectedButton : ''}
            onClick={() => onSelectPeriod(RelativeTimePeriod.quarter)}
          >
            3M
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};

export const TelemetryHistoryCard: React.FunctionComponent<{
  statistic: Statistic;
  ranges: Range[];
  period: RelativeTimePeriod;
  history: HistoryRecord[];
  loading: boolean;
  show_temp_threshold?: boolean;
  height?: number;
  width?: number;
  run_windows?: RunWindow[];
  onSelectPeriod?: (value: RelativeTimePeriod) => any;
  minHeight?: number;
  maxHeight?: number;
}> = ({
  statistic,
  ranges,
  period,
  history,
  loading,
  show_temp_threshold,
  height,
  width,
  run_windows,
  onSelectPeriod,
  minHeight,
  maxHeight,
}) => {
  const classes = useStyles();
  return (
    <div
      className={`${onSelectPeriod ? classes.root_1 : classes.root_0}`}
      style={height && width ? { height, width } : {}}
    >
      {loading ? (
        <div style={maxHeight ? { maxHeight, height: maxHeight } : {}} className={classes.spinner}>
          <CenteredSpinner fadeIn="none" />
        </div>
      ) : (
        <TelemetryHistoryPlot
          height={height || 150}
          width={width}
          period={period}
          history={history || []}
          statistic={statistic}
          ranges={ranges}
          show_temp_threshold={show_temp_threshold}
          run_windows={run_windows}
        />
      )}
      {onSelectPeriod && (
        <ButtonGroup color="secondary" size="small">
          <Button
            className={period === RelativeTimePeriod.week ? classes.selectedButton : ''}
            onClick={() => onSelectPeriod(RelativeTimePeriod.week)}
          >
            1W
          </Button>
          <Button
            className={period === RelativeTimePeriod.month ? classes.selectedButton : ''}
            onClick={() => onSelectPeriod(RelativeTimePeriod.month)}
          >
            1M
          </Button>
          <Button
            className={period === RelativeTimePeriod.quarter ? classes.selectedButton : ''}
            onClick={() => onSelectPeriod(RelativeTimePeriod.quarter)}
          >
            3M
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};

export const TelemetryMultiHistoryCard: React.FunctionComponent<{
  statistic: Statistic;
  ranges: Range[];
  period: RelativeTimePeriod;
  histories: { pellet_id: string; data: HistoryRecord[]; color_ix: number }[];
  colors: string[];
  loading: boolean;
  show_temp_threshold?: boolean;
  height?: number;
  width?: number;
  run_windows?: RunWindow[];
  onSelectPeriod?: (value: RelativeTimePeriod) => any;
}> = ({
  statistic,
  ranges,
  period,
  histories,
  colors,
  loading,
  show_temp_threshold,
  height,
  width,
  run_windows,
  onSelectPeriod,
}) => {
  const classes = useStyles();
  return (
    <div
      className={`${onSelectPeriod ? classes.root_1 : classes.root_0}`}
      style={height && width ? { height, width } : {}}
    >
      {loading ? (
        <div style={height ? { minHeight: height } : {}} className={classes.spinner}>
          <CenteredSpinner fadeIn="none" />
        </div>
      ) : (
        <TelemetryMultiHistoryPlot
          height={height || 150}
          width={width}
          period={period}
          histories={histories || []}
          statistic={statistic}
          ranges={ranges}
          show_temp_threshold={show_temp_threshold}
          run_windows={run_windows}
          colors={colors}
        />
      )}
      {onSelectPeriod && (
        <ButtonGroup color="secondary" size="small">
          <Button
            className={period === RelativeTimePeriod.week ? classes.selectedButton : ''}
            onClick={() => onSelectPeriod(RelativeTimePeriod.week)}
          >
            1W
          </Button>
          <Button
            className={period === RelativeTimePeriod.month ? classes.selectedButton : ''}
            onClick={() => onSelectPeriod(RelativeTimePeriod.month)}
          >
            1M
          </Button>
          <Button
            className={period === RelativeTimePeriod.quarter ? classes.selectedButton : ''}
            onClick={() => onSelectPeriod(RelativeTimePeriod.quarter)}
          >
            3M
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};

type TelemetryHistoryCardProps = GetProps<typeof TelemetryHistoryCard>;
type TelemetryMultiHistoryCardProps = GetProps<typeof TelemetryMultiHistoryCard>;

export const InteriorHistoryCard = withGetGrainContainerHistoryInteriorHoc(
  ({
    interior_history,
    ...props
  }: WithGetGrainContainerHistoryInteriorHocChildProps &
    Omit<TelemetryHistoryCardProps, 'history'>) => {
    const ctx = useContext(ActiveStoragePeriodContext);
    const activeStoragePeriod = ctx.activeStoragePeriod;
    let filtered_headspace: HistoryPointFragment[] = [];
    if (activeStoragePeriod && interior_history) {
      filtered_headspace = interior_history.filter(({ epoch_time }) => {
        const isOverlapping =
          activeStoragePeriod.start_date.getTime() <= epoch_time.getTime() &&
          epoch_time.getTime() <= (activeStoragePeriod.end_date || Infinity);
        return isOverlapping;
      });
    } else if (activeStoragePeriod === null && interior_history) {
      filtered_headspace = interior_history;
    }
    return <TelemetryHistoryCard history={filtered_headspace} {...props} />;
  }
);

const isValidAerationRun = ({
  start_epoch,
  end_epoch,
  activeStoragePeriod,
}: {
  start_epoch: Date;
  end_epoch: Date | null | undefined;
  activeStoragePeriod: TContext;
}): boolean => {
  if (
    start_epoch &&
    end_epoch &&
    activeStoragePeriod &&
    activeStoragePeriod.start_date.getTime() <= new Date(start_epoch).getTime() &&
    new Date(end_epoch).getTime() <=
      ((activeStoragePeriod.end_date && activeStoragePeriod.end_date.getTime()) || Infinity)
  ) {
    console.log('Aeration run with storage period');
    return true;
  }

  if (start_epoch && end_epoch && activeStoragePeriod === null) {
    console.log('Aeration run with no storage period');
    return true;
  }

  // active aeration run and storage period end date is known
  if (
    start_epoch &&
    end_epoch === null &&
    activeStoragePeriod &&
    activeStoragePeriod.end_date &&
    activeStoragePeriod.start_date.getTime() <= new Date(start_epoch).getTime() &&
    new Date().getTime() <= activeStoragePeriod.end_date.getTime()
  ) {
    console.log('Active aeration run and storage period end date is known');
    return true;
  }

  // active aeration run with ongoing period
  if (
    start_epoch &&
    end_epoch === null &&
    activeStoragePeriod &&
    activeStoragePeriod.end_date === null &&
    activeStoragePeriod.start_date.getTime() <= new Date(start_epoch).getTime()
  ) {
    console.log('Active aeration run with ongoing period');
    return true;
  }

  // active aeration run with no storage period
  if (start_epoch && end_epoch === null && activeStoragePeriod === null) {
    console.log('Active aeration run with no storage period');
    return true;
  }

  return false;
};

export const isValidTelemetryData = (obj: GrainEnvPointV2, statistic: Statistic): boolean => {
  const { temp_f, humidity_rh, emc, co2_ppm } = obj;

  if (statistic === Statistic.co2_ppm && co2_ppm !== null && co2_ppm !== undefined) {
    return (
      co2_ppm >= HEADSPACE_TELEMETRY_VALID_RANGES.co2_ppm.min &&
      co2_ppm <= HEADSPACE_TELEMETRY_VALID_RANGES.co2_ppm.max
    );
  }

  if (statistic === Statistic.temp_f && temp_f !== null && temp_f !== undefined) {
    return (
      temp_f >= HEADSPACE_TELEMETRY_VALID_RANGES.temp_f.min &&
      temp_f <= HEADSPACE_TELEMETRY_VALID_RANGES.temp_f.max
    );
  }

  if (statistic === Statistic.humidity_rh && humidity_rh !== null && humidity_rh !== undefined) {
    return (
      humidity_rh * 100 >= HEADSPACE_TELEMETRY_VALID_RANGES.humidity_rh.min &&
      humidity_rh * 100 <= HEADSPACE_TELEMETRY_VALID_RANGES.humidity_rh.max
    );
  }

  if (statistic === Statistic.emc && emc !== null && emc !== undefined) {
    return (
      emc * 100 >= HEADSPACE_TELEMETRY_VALID_RANGES.humidity_rh.min &&
      emc * 100 <= HEADSPACE_TELEMETRY_VALID_RANGES.humidity_rh.max
    );
  }
  return false;
};

export const HeadspaceHistoryCard = withGetGrainContainerHistoryHeadspaceWeatherHoc(
  withGetGrainContainerHistoryAerationRunsWithinPeriodHoc(
    withGetGrainContainerHistoryHeadspaceHoc(
      ({
        active_hubs_grain_telemetry_history,
        weather_history,
        aeration_runs,
        grain_bin_location_timezone,
        ...props
      }: WithGetGrainContainerHistoryHeadspaceHocChildProps &
        WithGetGrainContainerHistoryHeadspaceWeatherHocChildProps &
        WithGetGrainContainerHistoryAerationRunsWithinPeriodHocChildProps &
        Omit<TelemetryHistoryCardProps, 'history'> & { grain_bin_location_timezone: string }) => {
        const ctx = useContext(ActiveStoragePeriodContext);
        const activeStoragePeriod = ctx.activeStoragePeriod;
        const statistic = props.statistic;
        const active_hubs_formatted_telemetry_history = useMemo(
          () =>
            active_hubs_grain_telemetry_history
              ? active_hubs_grain_telemetry_history
                  .map(({ hub_id, headspace_telemetry_history }) => {
                    const data: {
                      x: Date;
                      y: number;
                    }[] = headspace_telemetry_history
                      .filter((obj) => {
                        if (
                          obj[statistic] !== undefined &&
                          activeStoragePeriod &&
                          activeStoragePeriod.start_date.getTime() <= obj.epoch_time.getTime() &&
                          obj.epoch_time.getTime() <=
                            (activeStoragePeriod.end_date
                              ? activeStoragePeriod.end_date.getTime()
                              : Infinity)
                        ) {
                          return isValidTelemetryData(obj, statistic);
                        }

                        if (obj[statistic] !== undefined && activeStoragePeriod === null) {
                          return isValidTelemetryData(obj, statistic);
                        }
                        return false;
                      })
                      .map((obj) => ({ x: obj.epoch_time, y: Number(obj[statistic]) }));
                    return {
                      hub_id,
                      data,
                    };
                  })
                  .filter(({ data }) => data.length > 0)
              : [],
          [active_hubs_grain_telemetry_history, activeStoragePeriod, statistic]
        );

        const formatted_weather_history =
          statistic !== Statistic.co2_ppm
            ? weather_history
                .map((obj) => {
                  return {
                    x: obj.epoch_time,
                    y: obj[statistic],
                  };
                })
                .sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime())
            : [];

        const aeration_runs_within_active_storage_period = aeration_runs
          ? aeration_runs.filter(({ start_epoch, end_epoch }) =>
              isValidAerationRun({ start_epoch, end_epoch, activeStoragePeriod })
            )
          : undefined;

        return (
          <TelemetryHistoryWithMultiHubsCard
            history={active_hubs_formatted_telemetry_history}
            weather_history={formatted_weather_history}
            run_windows={aeration_runs_within_active_storage_period}
            grain_bin_location_timezone={grain_bin_location_timezone}
            {...props}
          />
        );
      }
    )
  )
);

export const WeatherHistoryCard = withGetGrainContainerHistoryWeatherHoc(
  ({
    weather_history,
    ...props
  }: WithGetGrainContainerHistoryWeatherHocChildProps &
    Omit<TelemetryHistoryCardProps, 'history'>) => {
    const ctx = useContext(ActiveStoragePeriodContext);
    const activeStoragePeriod = ctx.activeStoragePeriod;

    let filteredWeatherHistory: HistoryPointFragment[] = [];
    if (activeStoragePeriod && weather_history) {
      filteredWeatherHistory = weather_history.filter(({ epoch_time }) => {
        const isOverlapping =
          activeStoragePeriod.start_date.getTime() <= epoch_time.getTime() &&
          epoch_time.getTime() <= (activeStoragePeriod.end_date || Infinity);
        return isOverlapping;
      });
    } else if (activeStoragePeriod === null && weather_history) {
      filteredWeatherHistory = weather_history;
    }

    return <TelemetryHistoryCard history={filteredWeatherHistory} {...props} />;
  }
);

export const PelletHistoryCard = withGetGrainContainerHistoryPelletHoc(
  ({
    pellet_id,
    pellet_history,
    ...props
  }: WithGetGrainContainerHistoryPelletHocChildProps &
    Omit<TelemetryHistoryCardProps, 'history'> & { pellet_id: string }) => {
    const ctx = useContext(ActiveStoragePeriodContext);
    const activeStoragePeriod = ctx.activeStoragePeriod;

    let filteredPelletHistory: HistoryPointFragment[] = [];
    if (activeStoragePeriod && pellet_history) {
      filteredPelletHistory = pellet_history.filter(({ co2_ppm, epoch_time }) => {
        const isOverlapping =
          activeStoragePeriod.start_date.getTime() <= epoch_time.getTime() &&
          epoch_time.getTime() <= (activeStoragePeriod.end_date || Infinity);
        return isOverlapping;
      });
    } else if (activeStoragePeriod === null && pellet_history) {
      filteredPelletHistory = pellet_history;
    }

    if (pellet_history) {
      return (
        <TelemetryHistoryCard show_temp_threshold history={filteredPelletHistory} {...props} />
      );
    }
    return <TelemetryHistoryCard history={[]} {...props} />;
  }
);

export const MultiPelletHistoryCard = ({
  pellet_histories,
  colors,
  loading,
  ...props
}: Omit<TelemetryMultiHistoryCardProps, 'histories'> & {
  pellet_histories: { pellet_id: string; data: HistoryRecord[]; color_ix: number }[];
  colors: string[];
}) => {
  if (pellet_histories) {
    return (
      <TelemetryMultiHistoryCard
        show_temp_threshold
        histories={pellet_histories}
        colors={colors}
        loading={loading}
        {...props}
      />
    );
  }
  return <TelemetryMultiHistoryCard histories={[]} colors={colors} loading={loading} {...props} />;
};
