import { AmberHoc, AmberHocProps } from '../../../util';
import {
  CreateUserMutationVariables as Variables,
  GetViewerDocument,
  GetViewerQuery,
  ViewerFragmentFragment as Result,
  withCreateUser as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithCreateUserHocChildProps = {
  createUser: (variables: Variables) => Promise<Result>;
};
export const withCreateUserHoc: AmberHoc<{}, WithCreateUserHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithCreateUserHocChildProps, typeof component>,
    WithCreateUserHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        createUser: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { createUser: result } = data;
              if (!result) {
                return;
              }
              proxy.writeQuery<GetViewerQuery>({
                query: GetViewerDocument,
                data: { __typename: 'Query', viewer: result },
              });
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.createUser;
        },
      };
    },
  })(component as any);
