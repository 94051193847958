import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { BinEventFragmentFragment as Result, withGetGrainBinEvents as HOC } from '../__generated';

export type WithGetGrainBinEventsHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainBinEventsHocChildProps = {
  loading: boolean;
  bin_events: Result[];
};
export const withGetGrainBinEventsHoc: AmberHoc<
  WithGetGrainBinEventsHocOwnProps,
  WithGetGrainBinEventsHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainBinEventsHocOwnProps,
      WithGetGrainBinEventsHocChildProps,
      typeof component
    >,
    WithGetGrainBinEventsHocChildProps
  >({
    options: ({ grain_bin_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      variables: { grain_bin_id },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          bin_events: [],
        };
      }
      return {
        loading: loading || false,
        bin_events: data.latest_grain_bin_events || [],
      };
    },
  })(component as any);
