import { Button, Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { GetProps } from 'react-redux';
import * as yup from 'yup';
import { GrainType } from '../../api/graphql';
import { formatGrainType } from '../../util';
import { Checkbox } from '../util/form2/Checkbox';
import { SelectGrainType, yup_grain_type } from '../util/form2/SelectGrainType';
import { TextField } from '../util/form2/TextField';
import { WizardStep } from './WizardStep';

const useStyles = makeStyles({
  grid: {
    minWidth: 300,
    maxWidth: 500,
    margin: 20,
  },
  location: {
    height: 400,
  },
});
export const storage_period_schema = yup.object().shape({
  // storage_period_name: yup
  //   .string()
  //   .label('Storage Period Name')
  //   .required(),
  // grain_type: yup_grain_type.label('Grain Type').required(),
  // start_date: yup
  //   .string()
  //   .typeError('A start date is required')
  //   .label('Start Date')
  //   .required()
});

export const AddStoragePeriodStep = ({
  title = 'Add a Storage Period',
  validationSchema,
}: Partial<GetProps<typeof WizardStep>>) => {
  const classes = useStyles();
  const {
    setFieldValue,
    values: { grain_type, add_storage_period, set_as_ongoing },
  } = useFormikContext<any>();
  const date: string = DateTime.local().toFormat('MM/dd/yyyy');

  return (
    <WizardStep title={title} validationSchema={validationSchema}>
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        alignItems="center"
        justify="center"
        spacing={2}
        className={classes.grid}
      >
        {add_storage_period && (
          <Grid container item xs={11} direction="row">
            <Grid item xs={11}>
              <TextField
                name="storage_period_name"
                label="Storage Period Name"
                placeholder="New Storage Period"
                fullWidth
              />
            </Grid>
            <Grid item xs={1} direction="column">
              <IconButton
                onClick={() => {
                  setFieldValue('add_storage_period', false);
                  setFieldValue('set_as_ongoing', true);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <SelectGrainType name="grain_type" label="Grain Type" fullWidth />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Checkbox style={{ width: '100%' }} name="set_as_ongoing" label="Set as Ongoing" />
            </Grid>
            <Grid item xs={6} sm={6} style={{ paddingRight: 10 }}>
              <TextField
                fullWidth
                name="start_date"
                type="date"
                label="Start Date"
                placeholder="MM/DD/YYYY"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {set_as_ongoing ? (
              <Grid item sm={6} xs={6} />
            ) : (
              <Grid item xs={6} sm={6}>
                <TextField
                  fullWidth
                  name="end_date"
                  label="End Date"
                  type="date"
                  placeholder="MM/DD/YYYY"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Grid container item xs={11} direction="row">
          <Button
            style={{ margin: '10px 0' }}
            fullWidth
            onClick={() => {
              setFieldValue('add_storage_period', true);
              setFieldValue(
                'storage_period_name',
                `${formatGrainType(grain_type as GrainType)} ${date}`
              );
            }}
            variant="outlined"
          >
            + Add Storage Period
          </Button>
        </Grid>
      </Grid>
    </WizardStep>
  );
};
