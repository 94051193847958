import { makeStyles, Theme } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useState } from 'react';
import { amber_grey, black_shade_8 } from '../../../../../../core/src/style';

export enum WeatherInfoPeriod {
  now = 'current_weather',
  today = 'today_weather',
  one_week = 'one_week_weather',
}

const useStyles = makeStyles((theme: Theme) => ({
  updateToggleBtn: {
    width: '100%',
    fontSize: 10,
    lineHeight: '18px',
    textTransform: 'none',
    color: amber_grey,
    padding: 5,
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    marginRight: 5,
    justifyContent: 'center',
    alignItems: 'center',
    color: black_shade_8,
    fontSize: 16,
    fontWeight: 600,
  },
}));

const WeatherHeaderCellRenderer = (props) => {
  const classes = useStyles();
  const initialPeriod = props.api['weather_period'] || WeatherInfoPeriod.today;
  const [period, setPeriod] = useState(initialPeriod);
  const handleChange = (event: React.MouseEvent<HTMLElement>, value: WeatherInfoPeriod) => {
    if (value === null) return;
    setPeriod(value);
    props.api['weather_period'] = value;
    // TODO: only refresh cells for airspace column instead of all cells
    props.api.refreshCells({ force: true });
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>Weather</div>
      <ToggleButtonGroup
        style={{ width: '100%' }}
        value={period}
        exclusive
        onChange={handleChange}
        aria-label="Toggle weather statistic "
      >
        <ToggleButton
          className={classes.updateToggleBtn}
          style={{
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
            border: '1px solid #707070',
          }}
          value={WeatherInfoPeriod.now}
        >
          Now
        </ToggleButton>
        <ToggleButton
          style={{
            border: '1px solid #707070',
          }}
          className={classes.updateToggleBtn}
          value={WeatherInfoPeriod.today}
        >
          Today
        </ToggleButton>
        <ToggleButton
          style={{
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
            border: '1px solid #707070',
          }}
          className={classes.updateToggleBtn}
          value={WeatherInfoPeriod.one_week}
        >
          1 Wk
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export { WeatherHeaderCellRenderer };
