import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  header: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  headerText: {
    fontWeight: 600,
    padding: '5px',
    wordBreak: 'break-word',
    textAlign: 'center',
  },
});

export const PendingGrainTicketsTableHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const xsValForButton = isMobile ? 3 : 2;
  return (
    <Grid container wrap="nowrap" className={classes.header}>
      <Grid item xs={3} className={classes.headerText} style={{ textAlign: 'start' }}>
        Date
      </Grid>
      <Grid item xs={3} className={classes.headerText}>
        Volume (bu)
      </Grid>
      {!isMobile && (
        <Grid item xs={2} className={classes.headerText}>
          Notes
        </Grid>
      )}

      <Grid item xs={xsValForButton} className={classes.headerText} />
      <Grid item xs={xsValForButton} className={classes.headerText} />
    </Grid>
  );
};
