import { Button, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { DateTime } from 'luxon';
import React from 'react';
import {
  amber_green,
  amber_grey,
  green_shade_1,
  light_gray_11,
  light_gray_7,
  red_shade_5,
} from '../../../../../../core/src/style';
import { getActionColorAndSign } from '../GrainBinTicketsTable';
import { GRAIN_TICKET_APPROVE_STATE } from './ManagePendingGrainTickets';

const useStyles = makeStyles({
  row: {
    backgroundColor: 'transparent',
  },
  rowText: {
    padding: '5px',
    textAlign: 'start',
    fontSize: 14,
    wordBreak: 'break-word',
  },
  disabledButton: {
    borderRadius: 20,
  },
  approveButton: {
    borderRadius: 20,
    '&:hover': {
      backgroundColor: amber_green,
    },
  },
});

export const PendingGrainTicketsTableBody = ({ tickets, onTicketApprove, onTicketDismiss }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const xsValForButton = isMobile ? 3 : 2;
  return (
    <div style={{ maxHeight: 300, overflowY: 'auto', overflowX: 'hidden' }}>
      {tickets &&
        tickets.map((grainTicket, index) => {
          const {
            epoch_time,
            action_performed,
            volume,
            grain_bin_ticket_id,
            notes,
            approve_state,
          } = grainTicket;
          const { color, sign } = getActionColorAndSign(action_performed);
          const isDeclinedGrainTicket = approve_state === GRAIN_TICKET_APPROVE_STATE.declined;
          const declineBtnText = isDeclinedGrainTicket ? 'Rejected' : 'Reject';
          return (
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: 56,
                borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                ...(isDeclinedGrainTicket && { backgroundColor: light_gray_11 }),
              }}
              container
              wrap="nowrap"
              key={grain_bin_ticket_id}
            >
              <Grid item xs={3} className={classes.rowText}>
                {DateTime.fromMillis(epoch_time.getTime()).toFormat('LLL d yyyy, t')}
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.rowText}
                style={{ color, fontWeight: 500, textAlign: 'center' }}
              >
                {sign}
                {Math.round(volume)}
              </Grid>
              {!isMobile && (
                <Grid item xs={2} className={classes.rowText} style={{ textAlign: 'center' }}>
                  {notes ? notes : '-'}
                </Grid>
              )}
              <Grid item xs={xsValForButton} style={{ textAlign: 'center' }}>
                <Button
                  className={classes.approveButton}
                  onClick={() => onTicketApprove(grainTicket)}
                  variant="contained"
                  style={{
                    border: isDeclinedGrainTicket ? `1px solid ${green_shade_1}` : 'none',
                    backgroundColor: isDeclinedGrainTicket ? '#fff' : amber_green,
                    color: isDeclinedGrainTicket ? amber_grey : '#fff',
                  }}
                >
                  Approve
                </Button>
              </Grid>
              <Grid item xs={xsValForButton} style={{ textAlign: 'center' }}>
                <Button
                  className={classes.disabledButton}
                  onClick={() => onTicketDismiss(grainTicket)}
                  disabled={isDeclinedGrainTicket}
                  variant={isDeclinedGrainTicket ? 'contained' : 'outlined'}
                  style={{ border: isDeclinedGrainTicket ? 'none' : `1px solid ${red_shade_5}` }}
                >
                  {declineBtnText}
                </Button>
              </Grid>
            </Grid>
          );
        })}
    </div>
  );
};
