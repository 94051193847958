import { Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { EmailSharp, LocationOn, Person, Unarchive } from '@material-ui/icons';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { mobilebar_height } from '../../../../core/src/util';
import {
  AccountFragmentFragment,
  UserFragmentFragment,
  withGetAccountHoc,
  WithGetAccountHocChildProps,
  withGetUserHoc,
  WithGetUserHocChildProps,
} from '../../api';
import { DialogSpinner } from '../spinner';
import { GrainAlertsSettingsForm } from './GrainAlertsSettingForm';
import { GrainWeeklyEmailSettingForm } from './GrainWeeklyEmailSettingForm';
import { UnitsSettingForm } from './UnitsSettingForm';
import { UpdateUserForm } from './UserForm';
import { UserOrgSettingsArchivedContainersLayout } from './UserOrgSettings';
import { UserPasswordForm } from './UserPasswordForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      marginBottom: `-${mobilebar_height}px`,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  tabs: {
    width: '14%',
    borderRight: `1px solid ${theme.palette.divider}`,
    marginRight: 24,
    marginTop: 10,
    maxHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: 10,
      marginTop: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    [theme.breakpoints.only('md')]: {
      width: '20%',
    },
  },
  withDivider: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: 15,
  },
  tabPanel: {
    width: '85%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      width: '100%',
      marginRight: 10,
    },
  },
  tab: {
    minWidth: '20px',
    '& .MuiTab-wrapper': {
      alignItems: 'start',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 10,
      },
    },
  },
}));
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Grid container direction="column" alignItems="center" alignContent="center" justify="center">
        {value === index && <>{children}</>}
      </Grid>
    </div>
  );
};

const AllTabs = ({ tabValue, handleChange, isSmallScreen, grain_bin_support, barge_support }) => {
  const classes = useStyles();
  const containerName = grain_bin_support ? 'bins' : barge_support ? 'barges' : 'bins';
  return (
    <Tabs
      scrollButtons="off"
      variant={isSmallScreen ? 'scrollable' : 'standard'}
      orientation={isSmallScreen ? 'horizontal' : 'vertical'}
      value={tabValue}
      onChange={handleChange}
      aria-label="Grain Bin Alert Settings"
      className={classes.tabs}
      style={{ minHeight: isSmallScreen ? 'auto' : 'calc(100vh - 120px)' }}
    >
      <Tab
        className={classes.tab}
        label={
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <NotificationsIcon
                style={{
                  marginRight: 5,
                }}
              />
            </div>
            <div>Alert</div>
          </div>
        }
        {...a11yProps(0, isSmallScreen)}
      />
      <Tab
        className={classes.tab}
        label={
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <EmailSharp
                style={{
                  marginRight: 5,
                }}
              />
            </div>
            <div>Email</div>
          </div>
        }
        {...a11yProps(1, isSmallScreen)}
      />
      <Tab
        className={classes.tab}
        label={
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <LocationOn
                style={{
                  marginRight: 5,
                }}
              />
            </div>
            <div>Units</div>
          </div>
        }
        {...a11yProps(2, isSmallScreen)}
      />
      <Tab
        className={classes.tab}
        label={
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Person
                style={{
                  marginRight: 5,
                }}
              />
            </div>
            <div>Account</div>
          </div>
        }
        {...a11yProps(3, isSmallScreen)}
      />
      <Tab
        className={`${classes.tab} ${isSmallScreen ? '' : classes.withDivider}`}
        label={
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Unarchive
                style={{
                  marginRight: 5,
                }}
              />
            </div>
            <div>Archived {containerName}</div>
          </div>
        }
        {...a11yProps(4, isSmallScreen)}
      />
    </Tabs>
  );
};

function a11yProps(index: any, isSmallScreen: boolean) {
  return {
    id: `${isSmallScreen ? 'horizontal' : 'vertical'}-tab-${index}`,
    'aria-controls': `${isSmallScreen ? 'horizontal' : 'vertical'}-tabpanel-${index}`,
  };
}

export const UserSettingsFormBase = ({
  account,
  refecthUser,
  selected_user,
  loading,
  onSubmitSuccess,
  onUpdateUserFormSuccess,
  onUserPasswordFormSuccess,
  user,
  mobile_width,
  ...props
}: WithGetUserHocChildProps &
  WithGetAccountHocChildProps & {
    selected_user: UserFragmentFragment;
    account: AccountFragmentFragment;
    onSubmitSuccess?: () => void;
    onUpdateUserFormSuccess?: () => any;
    onUserPasswordFormSuccess?: () => any;
    mobile_width?: boolean;
  }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const [tabValue, setTabValue] = React.useState(0);
  const [isUpdating, setUpdating] = React.useState(false);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const openPasswordForm = () => setShowPasswordForm(true);
  const showUpdateUserForm = () => setShowPasswordForm(false);

  if (loading) {
    return <DialogSpinner title="Loading..." open={loading} />;
  }
  if (isUpdating) {
    return <DialogSpinner title="Updating..." open={isUpdating} />;
  }

  return (
    <div className={classes.root}>
      {isSmallScreen ? (
        <div
          style={{
            width: '100%',
            position: 'sticky',
            backgroundColor: '#ecedf1',
            top: 42,
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" style={{ paddingTop: '15px', marginBottom: 10, fontSize: 18 }}>
            Settings
          </Typography>
          <AllTabs
            tabValue={tabValue}
            handleChange={handleChange}
            isSmallScreen={isSmallScreen}
            grain_bin_support={account.grain_bin_support}
            barge_support={account.barge_support}
          />
        </div>
      ) : (
        <AllTabs
          tabValue={tabValue}
          handleChange={handleChange}
          isSmallScreen={isSmallScreen}
          grain_bin_support={account.grain_bin_support}
          barge_support={account.barge_support}
        />
      )}
      <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
        <GrainAlertsSettingsForm
          selected_user={selected_user}
          refecthUser={refecthUser}
          account={account}
          onSubmitSuccess={onSubmitSuccess}
          setUpdating={setUpdating}
          rootContainerStyles={{
            padding: 0,
            width: '100%',
            display: 'flex',
            alignItems: isSmallScreen ? 'center' : 'start',
          }}
          {...props}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
        <GrainWeeklyEmailSettingForm
          key={JSON.stringify(selected_user)}
          selected_user={selected_user}
          refecthUser={refecthUser}
          account={account}
          loading={loading}
          rootContainerStyles={{
            width: '100%',
            display: 'flex',
            justifyContent: isSmallScreen ? 'center' : 'start',
          }}
          {...props}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2} className={classes.tabPanel}>
        <UnitsSettingForm account_id={selected_user.account_id} user_id={selected_user.user_id} />
      </TabPanel>
      <TabPanel value={tabValue} index={3} className={classes.tabPanel}>
        {user ? (
          <>
            {showPasswordForm ? (
              <>
                <UserPasswordForm
                  token={null}
                  user_id={user.user_id}
                  onSubmitSuccess={onUserPasswordFormSuccess}
                  rootContainerStyles={{
                    width: '100%',
                    display: 'flex',
                    alignItems: isSmallScreen ? 'center' : 'start',
                  }}
                  show_back_button={true}
                  goBack={showUpdateUserForm}
                />
              </>
            ) : (
              <UpdateUserForm
                user={user}
                onSubmitSuccess={onUpdateUserFormSuccess}
                onClickPassword={openPasswordForm}
                rootContainerStyles={{
                  width: '100%',
                  display: 'flex',
                  alignItems: isSmallScreen ? 'center' : 'start',
                }}
              />
            )}
          </>
        ) : null}
      </TabPanel>
      <TabPanel value={tabValue} index={4} className={classes.tabPanel}>
        <UserOrgSettingsArchivedContainersLayout
          viewer_role={user ? user.role : undefined}
          account_id={account.account_id}
          mobile_width={mobile_width}
          account={account}
        />
      </TabPanel>
    </div>
  );
};

export const UserSettingsForm = withGetUserHoc(withGetAccountHoc(UserSettingsFormBase));
