import { Tooltip } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Warning } from '@material-ui/icons';
import React from 'react';

export const DataWarning = ({ warning_text }: { warning_text: string }) => (
  <Tooltip title={warning_text}>
    <Warning style={{ color: red['500'] }} />
  </Tooltip>
);
