import React from 'react';
import { FanRunWindowRecommendedOption } from '../../../../../core/src/api';
import { renderModeColor } from '../../../../../core/src/util';
import { getRecommedationTypeMode } from '../../grain-container/aeration/FanSchedule';
import { Mode } from '../../grain-container/aeration/Mode';
import { withSkeleton } from './withCellSkeleton';

const ModeCellRenderer = withSkeleton((props) => {
  const { value, data } = props;
  console.log('props in ModeCellRenderer', props);
  const hasFanSchedule = data && data.fan_schedule && data.fan_schedule.length > 0;

  if (value === FanRunWindowRecommendedOption.Manual) {
    return (
      <Mode
        mode={getRecommedationTypeMode(value)}
        modeColor={renderModeColor(value)}
        customStyle={{ width: 50, height: 15, fontSize: 10 }}
      />
    );
  }

  return (
    <div>
      {!hasFanSchedule ? (
        <div>No Schedule</div>
      ) : (
        <Mode
          mode={getRecommedationTypeMode(value)}
          modeColor={renderModeColor(value)}
          customStyle={{ width: 50, height: 15, fontSize: 10 }}
        />
      )}
    </div>
  );
});

export { ModeCellRenderer };
