import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy, RelativeTimePeriod } from '../../../util';
import {
  WeatherHistoryPoint,
  withGetGrainContainerHistoryHeadspaceWeather as HOC,
} from '../__generated';

export type WithGetGrainContainerHistoryHeadspaceWeatherHocOwnProps = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  period: RelativeTimePeriod;
  pollInterval?: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainContainerHistoryHeadspaceWeatherHocChildProps = {
  loading: boolean;
  weather_history: WeatherHistoryPoint[];
};
export const withGetGrainContainerHistoryHeadspaceWeatherHoc: AmberHoc<
  WithGetGrainContainerHistoryHeadspaceWeatherHocOwnProps,
  WithGetGrainContainerHistoryHeadspaceWeatherHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainContainerHistoryHeadspaceWeatherHocOwnProps,
      WithGetGrainContainerHistoryHeadspaceWeatherHocChildProps,
      typeof component
    >,
    WithGetGrainContainerHistoryHeadspaceWeatherHocChildProps
  >({
    options: ({
      container_id,
      container_type,
      period,
      onError,
      pollInterval = 1000 * 60 * 30,
    }) => ({
      onError,
      pollInterval,
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id, container_type, period },
    }),
    props: ({ data, ownProps: { loading } }) => {
      let weather_history: any[] = [];
      if (!data || data.loading) {
        return {
          weather_history,
          loading: true,
        };
      }

      const { grain_container } = data;
      if (grain_container) {
        const { telemetry_history_v2 } = grain_container;
        if (telemetry_history_v2) {
          weather_history = telemetry_history_v2.weather_history || [];
        }
      }

      return {
        weather_history,
        loading: loading || false,
      };
    },
  })(component as any);
