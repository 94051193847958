import { Card, FormLabel, Grid, Hidden, Link, Theme, Typography } from '@material-ui/core';
import { ArchiveOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useMemo, useState } from 'react';
import { GetProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import * as yup from 'yup';
import { amber_link } from '../../../../core/src/style';

import {
  ArchiveUnArchiveType,
  ContainerType,
  CreateGrainBinMutationVariables,
  FloorType,
  GrainBinFragmentFragment,
  GrainType,
  UpdateGrainBinMutationVariables,
  UserRole,
  withCreateGrainBinHoc,
  WithCreateGrainBinHocChildProps,
  withGetAccountGrainBinsHoc,
  WithGetAccountGrainBinsHocChildProps,
  withGetAccountHoc,
  WithGetAccountHocChildProps,
  withGetGrainBinHoc,
  WithGetGrainBinHocChildProps,
  withGetGrainContainerHubLinksHoc,
  WithGetGrainContainerHubLinksHocChildProps,
  withGetUserHoc,
  WithGetUserHocChildProps,
  withGetViewerHoc,
  WithGetViewerHocChildProps,
  withUpdateGrainBinHoc,
  WithUpdateGrainBinHocChildProps,
} from '../../api';
import {
  withArchiveUnArchiveGrainContainerHoc,
  withArchiveUnArchiveGrainContainerHocChildProps,
} from '../../api/graphql/hoc/withArchiveUnArchiveGrainContainerHoc';
import { ContainerTypeLegacy, getDistanceUnitLabel, getHasUserSelectedMeterUnit } from '../../util';
import { UiFormError } from '../../util/format-error';
import { DialogSpinner } from '../spinner';
import ConfirmationModal from '../util/ConfirmationModal';
import { BaseForm, FormikWrapper, FormikWrapperProps } from '../util/form2/BaseForm';
import { Button, ButtonSubmit } from '../util/form2/Button';
import { Checkbox } from '../util/form2/Checkbox';
import { ErrorBox } from '../util/form2/ErrorBox';
import {
  formatLatLng,
  LocationField,
  LocationPicker,
  yup_latlng,
} from '../util/form2/LocationPicker';
import { IntTextField, NumberTextField } from '../util/form2/NumberField';
import { Select } from '../util/form2/Select';
import { SelectFloorType, yup_floor_type } from '../util/form2/SelectFloorType';
import { SelectGrainType, yup_grain_type } from '../util/form2/SelectGrainType';
import { TextField } from '../util/form2/TextField';
import { RECOMMENDATION_OPTIONS, RecommendedOption } from './RecommendationOptionValue';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    minWidth: 300,
    maxWidth: 400,
  },
  largeGrid: {
    minWidth: 300,
    maxWidth: 400,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 600,
    },
  },

  centered: {
    textAlign: 'center',
  },
  location: {
    height: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  locationInstruction: {
    color: '#f44336',
  },
  location_picker: { flex: '1 1 auto' },
  locationLabel: {
    marginBottom: 5,
    display: 'block',
  },
  archiveButton: {
    fontSize: 14,
    textAlign: 'center',
    verticalAlign: 'top',
    display: 'inline-block',
  },
}));

export type Values = {
  bin_name: string;
  grain_type: GrainType | null;
  height_ft: number | null;
  diameter_ft: number | null;
  location: {
    latlng_str: string;
    latlng: { lat: number; lng: number } | null;
  };
  target_grain_emc: number | null;
  recommendation_type: string | null;
  current_grain_emc: number | null;
  current_grain_temp: number | null;
  has_grain_spreader: boolean | null;
  floor_type: FloorType;
};

const hasUserOptedMeterUnit = getHasUserSelectedMeterUnit();

const grainBinSettingsValidationSchema = yup.object().shape({
  bin_name: yup
    .string()
    .label('Bin Name')
    .required(),
  grain_type: yup_grain_type
    .label('Grain Type')
    .required()
    .nullable(false),
  height_ft: yup
    .number()
    .typeError('A number is required')
    .label('Eave Height')
    .required()
    .positive()
    .max(hasUserOptedMeterUnit ? 60 : 200),
  diameter_ft: yup
    .number()
    .typeError('A number is required')
    .label('Bin Diameter')
    .required()
    .positive()
    .max(hasUserOptedMeterUnit ? 60 : 200),
  location: yup_latlng.label('Location'),
  has_grain_spreader: yup.boolean(),
  floor_type: yup_floor_type
    .label('Floor Type')
    .required()
    .nullable(false),
});

const maxDimension = 200;
const GOOGLE_MAP_SEARCH_URL = 'https://www.google.com/maps/search/?api=1&query';

export const GrainBinFormBase = ({
  account_id,
  grain_bin,
  createGrainBin,
  updateGrainBin,
  refecthAccount,
  viewer,
  ...props
}: WithCreateGrainBinHocChildProps &
  WithUpdateGrainBinHocChildProps &
  Pick<
    FormikWrapperProps<Values, GrainBinFragmentFragment>,
    'onSubmitSuccess' | 'onSubmitFailure'
  > & {
    grain_bin: GrainBinFragmentFragment | null;
    account_id: number | null;
    refecthAccount?: Function;
    viewer?: any;
  }) => {
  const classes = useStyles();
  const initial_values = useMemo(() => asJSON(grain_bin), [grain_bin]);
  const [loadingAccount, setLoadingAccount] = useState<boolean>(false);
  let diagnostic_mode = false;
  const distanceUnitLabel = getDistanceUnitLabel();
  // only for updateGrainBin flow
  if (account_id === null && viewer) {
    const {
      account: { system_account },
      user: { role },
    } = viewer;
    diagnostic_mode = system_account && role === UserRole.Admin;
  }

  if (loadingAccount) {
    return <DialogSpinner title="Loading Account..." open={loadingAccount} />;
  }
  return (
    <FormikWrapper<Values, GrainBinFragmentFragment>
      {...props}
      enableReinitialize
      validationSchema={grainBinSettingsValidationSchema}
      initialValues={initial_values}
      onSubmit={async (values) => {
        const {
          bin_name,
          grain_type,
          height_ft,
          diameter_ft,
          has_grain_spreader,
          location: {
            latlng: { lat: latitude, lng: longitude },
          },
          floor_type,
        } = grainBinSettingsValidationSchema.validateSync(values);
        const location = { latitude, longitude };
        if (account_id !== null) {
          const variables: CreateGrainBinMutationVariables = {
            // todo type update after backend
            account_id,
            bin_name,
            grain_type,
            height_ft,
            diameter_ft,
            location,
            has_grain_spreader,
            floor_type,
          };
          return await createGrainBin(variables);
        }

        if (!grain_bin) {
          throw new UiFormError('Grain bin not loaded');
        }

        const variables: UpdateGrainBinMutationVariables = {
          bin_name,
          grain_type,
          height_ft,
          diameter_ft,
          location,
          has_grain_spreader,
          floor_type,
          grain_bin_id: grain_bin.grain_bin_id,
        };

        if (refecthAccount) {
          const updateResult = await updateGrainBin(variables);
          setLoadingAccount(true);
          await refecthAccount();
          setLoadingAccount(false);
          return updateResult;
        }
        return await updateGrainBin(variables);
      }}
      render={({
        values: {
          location: { latlng, latlng_str },
        },
      }) => (
        <BaseForm submitting_message={account_id ? 'Creating Grain Bin...' : 'Saving changes...'}>
          <Grid
            container
            direction="row"
            alignContent="flex-start"
            alignItems="center"
            justify="center"
            spacing={2}
            className={classes.largeGrid}
          >
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.centered}>
                {grain_bin ? grain_bin.bin_name : 'Create New Grain Bin'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField name="bin_name" label="Bin Name" placeholder="Bin 01" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <SelectGrainType name="grain_type" label="Grain Type" fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <IntTextField
                fullWidth
                name="height_ft"
                label={`Eave Height (${distanceUnitLabel})`}
                min={1}
                max={maxDimension}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <IntTextField
                name="diameter_ft"
                label={`Bin Diameter (${distanceUnitLabel})`}
                min={1}
                max={maxDimension}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFloorType name="floor_type" label="Floor Type" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Checkbox name="has_grain_spreader" label="Bin Equipped with Grain Spreader" />
            </Grid>
            <Grid item xs={12} className={classes.location}>
              <Card style={{ width: '100%', padding: '10px' }}>
                <Hidden only={['xs', 'sm', 'md', 'lg', 'xl']}>
                  <LocationField
                    name="location"
                    label="Location"
                    fullWidth
                    placeholder="Place a marker on the map below"
                    helperText={
                      latlng
                        ? 'Make changes by dragging the marker or clicking the map.'
                        : `Click the map to add a location marker.`
                    }
                  />
                </Hidden>
                <FormLabel className={classes.locationLabel}>Location</FormLabel>
                {latlng_str === '' && (
                  <div className={classes.locationInstruction}>
                    <ol style={{ marginTop: '0' }}>
                      <li>
                        <Typography>
                          Enter your grain bin location's address in the google maps search field
                          below.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          Pinpoint the exact location in the map view.
                          <br />
                          (Make changes by dragging the marker or clicking the map.)
                        </Typography>
                      </li>
                    </ol>
                  </div>
                )}
                <LocationPicker name="location" className={classes.location_picker} />
                <br />
                {latlng_str && (
                  <Typography style={{ textAlign: 'center' }}>
                    Pin's Lat/Long:&nbsp;&nbsp;
                    {diagnostic_mode ? (
                      <Link
                        target="_blank"
                        href={`${GOOGLE_MAP_SEARCH_URL}=${latlng_str}`}
                        style={{ color: amber_link, textDecoration: 'underline' }}
                      >
                        <strong>{latlng_str}</strong>
                      </Link>
                    ) : (
                      <strong>{latlng_str}</strong>
                    )}
                  </Typography>
                )}
              </Card>
            </Grid>
            <ErrorBox />
            <Grid item xs={12} className={classes.centered}>
              <br />
              <ButtonSubmit prevent_submit_on_enter />
            </Grid>
          </Grid>
        </BaseForm>
      )}
    />
  );
};

export const GrainBinForm = withCreateGrainBinHoc(withUpdateGrainBinHoc(GrainBinFormBase));

export const UpdateGrainBinForm = ({
  grain_bin,
  refecthAccount,
  viewer,
  ...props
}: Omit<GetProps<typeof GrainBinForm>, 'account_id' | 'grain_bin'> & {
  grain_bin: GrainBinFragmentFragment;
  refecthAccount?: Function;
  viewer: any;
}) => (
  <GrainBinForm
    {...props}
    viewer={viewer}
    account_id={null}
    grain_bin={grain_bin}
    refecthAccount={refecthAccount}
  />
);

export const ArchiveGrainBinComponent = withGetAccountGrainBinsHoc(
  withGetUserHoc(
    withGetGrainContainerHubLinksHoc(
      withArchiveUnArchiveGrainContainerHoc(
        ({
          grain_bin,
          url_base,
          history,
          archiveUnArchiveGrainContainer,
          user_id,
          refecthUser,
          user,
          onSelectContainer,
          onArchiveClick,
          goOrgSettings,
          refecthAccount,
          grain_container,
          container_id,
          container_type,
          account_id,
          account,
          refetchGrainBinAccountLinks,
          ...props
        }: Pick<WithGetGrainBinHocChildProps, 'grain_bin'> & {
          url_base: string;
          user_id: number;
          container_id: number;
          container_type: ContainerTypeLegacy;
          account_id: number;
          onSelectContainer: ((args: any) => void) | undefined;
          onArchiveClick: ((args: any) => void) | undefined;
          goOrgSettings: (() => void) | undefined;
        } & Pick<RouteComponentProps<{ account_id?: string }>, 'history'> &
          withArchiveUnArchiveGrainContainerHocChildProps &
          WithGetUserHocChildProps &
          WithGetAccountHocChildProps &
          Pick<WithGetGrainContainerHubLinksHocChildProps, 'grain_container'> &
          WithGetAccountGrainBinsHocChildProps) => {
          const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] = useState<boolean>(
            false
          );
          const [loading, setLoading] = useState(false);
          const classes = useStyles();

          if (loading) {
            return <DialogSpinner title="Archiving Grain Bin..." open={loading} />;
          }
          if (!grain_bin || grain_bin.archived) {
            return <></>;
          }

          let show_archive_alert_modal = false;
          const { grain_bin_id, fan_controllers } = grain_bin;
          if (
            (fan_controllers && fan_controllers.length) ||
            (grain_container &&
              grain_container.active_hub_links &&
              grain_container.active_hub_links.length)
          ) {
            show_archive_alert_modal = true;
          }

          const handleArchiveGrainContainer = async () => {
            const variables = {
              container_id: grain_bin_id,
              container_type: ContainerType.Bin,
              archive_unarchive_flag: ArchiveUnArchiveType.Archive,
            };
            await archiveUnArchiveGrainContainer(variables);
          };

          const handleOrganizationSettingsOnClick = async () => {
            await refecthAccount();
            if (goOrgSettings) {
              await goOrgSettings();
            }
          };

          const onArchive = async () => {
            try {
              setShowArchiveConfirmationModal(false);
              setLoading(true);
              await handleArchiveGrainContainer();
              await refecthAccount();
              await refetchGrainBinAccountLinks();
              const user_data = await refecthUser();
              console.log('user_data', { user_data_val: user_data });
              const selected_grain_bin = user_data
                ? user_data['data']['user']['selected_grain_bin']
                : null;
              if (onArchiveClick) {
                if (selected_grain_bin) {
                  onArchiveClick(selected_grain_bin);
                } else {
                  onArchiveClick({
                    container_id: null,
                    container_name: '',
                    container_type: ContainerTypeLegacy.bin,
                  });
                }
              }
            } catch (error) {
              console.error(error);
            } finally {
              setLoading(false);
            }
          };

          return (
            <>
              <Grid
                container
                direction="row"
                alignContent="flex-start"
                alignItems="center"
                justify="center"
                spacing={2}
                className={classes.centered}
              >
                {show_archive_alert_modal && (
                  <ConfirmationModal
                    showModal={showArchiveConfirmationModal}
                    confirmationMessage={
                      <>
                        All Hubs and Fan Controllers <strong>must</strong> be unassigned before
                        archiving.
                      </>
                    }
                    cancelBtnText="Cancel"
                    confirmBtbText="Go to Configure Devices"
                    handleCancel={() => setShowArchiveConfirmationModal(false)}
                    handleConfirm={() => {
                      // go to device page
                      history.push(`${url_base}/devices`);
                      setShowArchiveConfirmationModal(false);
                    }}
                  />
                )}
                {!show_archive_alert_modal && (
                  <ConfirmationModal
                    showModal={showArchiveConfirmationModal}
                    confirmationMessage={
                      <>
                        Are you sure you want to <strong>Archive</strong> this Grain Bin?
                      </>
                    }
                    cancelBtnText="No"
                    confirmBtbText="Yes"
                    handleCancel={() => setShowArchiveConfirmationModal(false)}
                    handleConfirm={onArchive}
                  />
                )}
                <Grid item xs={5} style={{ display: 'block' }}>
                  <hr style={{ width: '100%' }} />
                  <Typography style={{ display: 'inline-block', textAlign: 'left', width: '70%' }}>
                    To archive your Grain Bin click the 'Archive' button. Grain Bin can be
                    unarchived from{' '}
                    <Link
                      underline="always"
                      style={{ color: 'rgb(32 155 143)' }}
                      onClick={handleOrganizationSettingsOnClick}
                    >
                      'Organization Settings'
                    </Link>
                  </Typography>
                  <Grid item className={classes.archiveButton}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setShowArchiveConfirmationModal(true);
                      }}
                    >
                      <ArchiveOutlined style={{ marginRight: '8px' }} />
                      <Typography variant="h6">Archive</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        }
      )
    )
  )
);

export const UpdateGrainBinFormGql = withGetViewerHoc(
  withGetGrainBinHoc(
    withGetAccountHoc(
      ({
        grain_bin,
        loading,
        refecthAccount,
        history,
        url_base,
        onSelectContainer,
        onArchiveClick,
        goOrgSettings,
        viewer,
        account,
        ...props
      }: Omit<GetProps<typeof UpdateGrainBinForm>, 'grain_bin'> &
        WithGetGrainBinHocChildProps &
        WithGetAccountHocChildProps &
        Pick<RouteComponentProps<{ account_id?: string }>, 'history'> & {
          url_base: string;
          onSelectContainer: ((args: any) => void) | undefined;
          onArchiveClick: ((args: any) => void) | undefined;
          goOrgSettings: (() => void) | undefined;
        } & WithGetViewerHocChildProps) => {
        if (loading) {
          return <DialogSpinner title="Loading Grain Bin..." open={loading} />;
        }
        if (!grain_bin) {
          return <Typography variant="h5">An error occurred while loading grain bin</Typography>;
        }
        return (
          <>
            <UpdateGrainBinForm
              {...props}
              viewer={viewer}
              grain_bin={grain_bin}
              refecthAccount={refecthAccount}
            />
            {viewer && account && (
              <ArchiveGrainBinComponent
                grain_bin={grain_bin}
                container_id={grain_bin.grain_bin_id}
                container_type={ContainerTypeLegacy.bin}
                url_base={url_base}
                history={history}
                user_id={viewer.user_id}
                onSelectContainer={onSelectContainer}
                onArchiveClick={onArchiveClick}
                goOrgSettings={goOrgSettings}
                refecthAccount={refecthAccount}
                account_id={account.account_id}
                account={account}
                {...props}
              />
            )}
          </>
        );
      }
    )
  )
);

export const CreateGrainBinForm = (props: Omit<GetProps<typeof GrainBinForm>, 'grain_bin'>) => (
  <GrainBinForm {...props} grain_bin={null} />
);

/*
 * AskGrainConditionsForm
 */

export const asJSON = (grain_bin: GrainBinFragmentFragment | null) => {
  if (grain_bin === null) {
    return {
      bin_name: '',
      grain_type: null,
      height_ft: null,
      diameter_ft: null,
      location: {
        latlng: null,
        latlng_str: '',
      },
      target_grain_emc: null,
      current_grain_emc: null,
      current_grain_temp: null,
      has_grain_spreader: false,
      floor_type: FloorType.FullFloorAeration,
      recommendation_type: RecommendedOption.COOLING.toString(),
    };
  }

  return {
    bin_name: grain_bin.bin_name,
    grain_type: grain_bin.grain_type || null,
    height_ft: grain_bin.height_ft || null,
    diameter_ft: grain_bin.diameter_ft || null,
    location: {
      latlng: {
        lat: grain_bin.location.latitude,
        lng: grain_bin.location.longitude,
      },
      latlng_str: formatLatLng({
        lat: grain_bin.location.latitude,
        lng: grain_bin.location.longitude,
      }),
    },
    has_grain_spreader: grain_bin.has_grain_spreader || false,
    floor_type: grain_bin.floor_type || FloorType.FullFloorAeration,
    target_grain_emc: grain_bin.target_grain_emc || null,
    recommendation_type: grain_bin.recommendation_type || RecommendedOption.COOLING.toString(),
    current_grain_emc: grain_bin.current_grain_emc || null,
    current_grain_temp:
      grain_bin.current_grain_temp === undefined || grain_bin.current_grain_temp === null
        ? null
        : grain_bin.current_grain_temp,
  };
};
