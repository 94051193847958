/*
refs:
https://www.unitconverters.net/
https://grains.org/markets-tools-data/tools/converting-grain-units/
https://hextobinary.com/unit/density/from/lbpbu/to/kgphl
*/
const UnitsConverter = {
  convertMetersToFeet: (num) => Number((num * 3.2808).toFixed(2)),
  convertFeetToMeters: (num) => Number((num / 3.2808).toFixed(2)),
  convertInchesToCentimeters: (num) => Number((num * 2.54).toFixed(2)),
  convertCentimetersToInches: (num) => Number((num / 2.54).toFixed(2)),
  convertFahrenheitToCelsius: (fTemp) => Number((((fTemp - 32) * 5) / 9).toFixed(2)),
  convertCelsiusToFahrenheit: (cTemp) => Number(((cTemp * 9) / 5 + 32).toFixed(2)),
  convertPoundToKilogram: (num) => Number((num / 2.2046).toFixed(2)),
  convertKilogramToPound: (num) => Number((num * 2.2046).toFixed(2)),
  convertPoundToMetricTon: (num) => Number((num / 2204.6).toFixed(2)),
  convertMetricTonToPound: (num) => Number((num * 2204.6).toFixed(2)),
  convertPoundPerBuToKilogramPerHectoliter: (num) => Number((num * 1.29).toFixed(2)),
  convertKilogramPerHectoliterToPoundPerBu: (num) => Number((num / 1.29).toFixed(2)),
  convertBuToMetricTon: (num) => num * 0.0254,
};

export { UnitsConverter };
