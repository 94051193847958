import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import * as yup from 'yup';

import {
  Orientation,
  PileCableLinkFragmentFragment,
  PileFragmentFragment,
  withLinkPileCableHoc,
  WithLinkPileCableHocChildProps,
} from '../../../api';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../../util/form2/BaseForm';
import { ButtonSubmit } from '../../util/form2/Button';
import { ErrorBox } from '../../util/form2/ErrorBox';
import { NumberTextField } from '../../util/form2/NumberField';
import { TextField } from '../../util/form2/TextField';
import { PileLocationViewer } from './PileLocationViewer';

const useStyles = makeStyles({
  centered: {
    textAlign: 'center',
  },
  grid: {
    maxWidth: 450,
    minWidth: 350,
  },
  divider: { marginBottom: 2 },
});

type Values = {
  alias: string;
  cable_id: number | string;
  x_ft: number | string;
  y_ft: number | string;
};

const validationSchema = yup.object().shape({
  alias: yup
    .string()
    .label('Cable Alias')
    .nullable(),
  cable_id: yup
    .string()
    .label('Cable ID')
    .required()
    .test('cable_id_format', 'Enter a valid Cable ID', (value) => {
      if (!value || !isNaN(value)) {
        return true;
      }
      return (
        value.indexOf('-') !== -1 &&
        value.substring(value.indexOf('-') + 1) &&
        !isNaN(parseInt(value.substring(value.indexOf('-') + 1), 16))
      );
    }),
  // X Y REVERSAL IS NOT A TYPO
  //  SAME REVERSAL LOGIC AS THE PILE LOCATION VIEWER AS THIS FORM IS IN LANDSCAPE MODE READ MORE IN COMMENTS BELOW
  x_ft: yup
    .number()
    .typeError('A number is required')
    .label('Y Coordinate')
    .required(),
  y_ft: yup
    .number()
    .typeError('A number is required')
    .label('X Coordinate')
    .required(),
});

const Form = ({
  pile,
  onChangeCableId,
  pile_cable_links,
}: {
  pile: PileFragmentFragment;
  pile_cable_links: PileCableLinkFragmentFragment[];
  onChangeCableId?: (value: number | string) => void;
}) => {
  const classes = useStyles();
  const { values, setFieldTouched, setFieldValue } = useFormikContext<Values>();
  useEffect(() => {
    if (onChangeCableId && values.cable_id) {
      onChangeCableId(Number(values.cable_id));
    }
  }, [values.cable_id]);

  const parseAndRoundY = useCallback((val: string): number | null => {
    if (!val || isNaN(Number(val))) {
      return null;
    }
    return Math.round(Number(val));
  }, []);

  const parseAndRoundX = useCallback((val: string): number | null => {
    if (!val || isNaN(Number(val))) {
      return null;
    }
    return -Math.round(Number(val));
  }, []);

  const { orientation, shape } = pile;
  if (!shape || !shape.length_ft || !shape.radius_ft) {
    return <div>Configure Pile Dimensions before assigning Cables</div>;
  }
  const axes_message =
    orientation === Orientation.NorthSouth
      ? 'North = Positive X, South = Negative X, East = Negative Y, West = Positive Y'
      : 'North = Positive Y, South = Negative Y, East = Positive X, West = Negative X';
  const { length_ft, radius_ft } = shape;
  return (
    <BaseForm submitting_message="Assigning Cable..." style={{ width: '100%' }}>
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        alignItems="center"
        justify="center"
        spacing={2}
        className={classes.grid}
      >
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.centered}>
            Assign Pile Cable
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth label="Cable ID" name="cable_id" required />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth label="Cable Name (Optional)" name="alias" />
        </Grid>
        {/* Same confusing math as the pile location viewer for landscape support. See pile location viewer for more details. CHANGE IF LANDSCAPE CHANGES */}
        {/* Thus X in the chart = the saved y val and y in the chart is the negative saved x value */}
        <Grid item xs={6}>
          <NumberTextField
            fullWidth
            label="Cable X Coordinate"
            name="y_ft"
            parseValue={parseAndRoundY}
            formatValue={(val) =>
              val === undefined || val === null ? '' : Math.round(val).toString()
            }
            required
          />
        </Grid>
        <Grid item xs={6}>
          {/* very confusing: the "y" displayed to the user is in landscape so it needs to be saved as the X value */}
          {/* this x value also needs to be stored as the negative of the entered value, but displayed as entered */}
          <NumberTextField
            fullWidth
            label="Cable Y Coordinate"
            name="x_ft"
            parseValue={parseAndRoundX}
            formatValue={(val) =>
              val === undefined || val === null ? '' : Math.round(-val).toString()
            }
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.centered}>{axes_message}</Typography>
          <Typography variant="h6" className={classes.centered}>
            Cable Location can also be selected in the chart below
          </Typography>
        </Grid>
        <Card raised>
          <CardHeader
            title={`Cable Location *`}
            titleTypographyProps={{
              color: 'secondary',
            }}
          />
          <Divider classes={{ root: classes.divider }} />
          <CardContent>
            <Grid container justify="space-around" alignItems="center">
              <Grid container item xs={12} justify="center">
                <PileLocationViewer
                  pile_cables={pile_cable_links}
                  orientation={orientation}
                  length={length_ft}
                  diameter={radius_ft * 2}
                  landscape={true} // IF THIS CHANGES CHANGE THE X Y MATH IN THE INPUTS ABOVE
                  selected_x_ft={(values && Number(values.x_ft)) || undefined}
                  selected_y_ft={(values && Number(values.y_ft)) || undefined}
                  onLocationSelect={({ x_ft, y_ft }) => {
                    setFieldValue('x_ft', x_ft);
                    setFieldValue('y_ft', y_ft);
                    // required error pops from setting touched before the values are completely set
                    setTimeout(() => {
                      setFieldTouched('x_ft', true);
                      setFieldTouched('y_ft', true);
                    }, 100);
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <ErrorBox />
        <Grid item xs={12} className={classes.centered}>
          <ButtonSubmit />
        </Grid>
      </Grid>
    </BaseForm>
  );
};

export const AssignPileCableFormBase = ({
  initial_values,
  onChangeCableId,
  linkPileCable,
  pile,
  pile_cable_links,
  ...props
}: WithLinkPileCableHocChildProps & {
  initial_values?: Values;
  pile: PileFragmentFragment;
  pile_cable_links: PileCableLinkFragmentFragment[];
  onChangeCableId?: (value: Values['cable_id']) => void;
} & (
    | (FormikWrapperHandlerProps<Values, {}> & {})
    | (FormikWrapperHandlerProps<Values, {}> & {}))) => {
  const submitCallback = useCallback(
    async (values) => {
      const { alias, cable_id, x_ft, y_ft } = validationSchema.validateSync(values);
      const parsed_cable_id =
        cable_id.indexOf('-') !== -1
          ? parseInt(cable_id.substring(cable_id.indexOf('-') + 1), 16)
          : parseInt(cable_id, 10);
      const result = await linkPileCable({
        pile_id,
        cable_id: parsed_cable_id,
        x_ft: Number(x_ft),
        y_ft: Number(y_ft),
        alias: alias || '',
      });
      return Boolean(result);
    },
    [pile]
  );
  const initialValues = initial_values || {
    alias: '',
    cable_id: '',
    x_ft: '',
    y_ft: '',
  };

  const { pile_id } = pile;
  return (
    <FormikWrapper<Values, any>
      {...props}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={submitCallback}
      onSubmitSuccess={(result, { resetForm }) => resetForm()}
      render={() => (
        <Form pile={pile} pile_cable_links={pile_cable_links} onChangeCableId={onChangeCableId} />
      )}
    />
  );
};

export const AssignPileCableForm = withLinkPileCableHoc(AssignPileCableFormBase);
