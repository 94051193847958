import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useState } from 'react';

import {
  PileCableLinkFragmentFragment,
  PileFragmentFragment,
  withGetActivePileCableLinksHoc,
  WithGetActivePileCableLinksHocChildProps,
  withGetPileHoc,
  WithGetPileHocChildProps,
} from '../../../api';
import { AssignPileCableForm } from './AssignPileCableForm';
import { PileCableRemovalForm } from './PileCableRemovalForm';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 350,
    minWidth: 350,
  },
});

type PileCableFormBaseProps = {
  pile_cable_link_id?: number | null;
  pile: PileFragmentFragment;
  pile_cable_links: PileCableLinkFragmentFragment[];
  pile_cable?: PileCableLinkFragmentFragment | null;
  onChangeCableId?: (value: number | string) => void;
};

type PileCableFormProps = WithGetPileHocChildProps &
  WithGetActivePileCableLinksHocChildProps & {
    pile_id: number;
    pile_cable_link_id?: number | null;
    alias?: string;
    cable_id?: number | string;
  };

export const PileCableFormBase = ({
  pile_cable,
  pile,
  pile_cable_links,
  ...props
}: PileCableFormBaseProps) => {
  const classes = useStyles();
  const [selected_cable_id, setSelectedCableId] = useState<number | string>(
    (pile_cable && pile_cable.cable_id) || ''
  );
  return (
    <div className={classes.root}>
      <AssignPileCableForm
        {...props}
        pile={pile}
        initial_values={
          pile_cable
            ? {
                cable_id: pile_cable.cable_id,
                alias: pile_cable.alias || '',
                x_ft: pile_cable.x_ft,
                y_ft: pile_cable.y_ft,
              }
            : undefined
        }
        pile_cable_links={pile_cable_links}
        onChangeCableId={setSelectedCableId}
      />
      <PileCableRemovalForm pile_cable_links={pile_cable_links} cable_id={selected_cable_id} />
    </div>
  );
};

const PileCableFormInner: FunctionComponent<PileCableFormProps> = ({
  cable_id = '',
  pile_id,
  pile,
  loading,
  active_pile_cable_links,
  pile_cable_link_id,
  ...props
}) => {
  if (loading) {
    return null;
  }
  if (!pile) {
    return <Typography>An error occurred while loading the pile</Typography>;
  }
  if (!active_pile_cable_links) {
    return <Typography>An error occurred while loading current assignments</Typography>;
  }
  let existing: PileCableLinkFragmentFragment | null = null;
  if (pile_cable_link_id) {
    const found = active_pile_cable_links.find(
      (bcpl) => bcpl.pile_cable_link_id === pile_cable_link_id
    );
    if (!found) {
      return <Typography>Pile pellet assignment not found</Typography>;
    }
    existing = found;
  }
  return (
    <>
      <PileCableFormBase
        {...props}
        pile={pile}
        pile_cable={existing}
        pile_cable_links={active_pile_cable_links}
      />
    </>
  );
};
export const PileCableForm = withGetPileHoc(withGetActivePileCableLinksHoc(PileCableFormInner));
