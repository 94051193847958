import * as React from 'react';
export const CancelIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" {...props}>
    <path
      id="StopIcon"
      d="M4979.059,501.683a10,10,0,1,0,10,10A9.991,9.991,0,0,0,4979.059,501.683Zm5,13.59-1.41,1.41-3.59-3.59-3.59,3.59-1.41-1.41,3.59-3.59-3.59-3.59,1.41-1.41,3.59,3.59,3.59-3.59,1.41,1.41-3.59,3.59Z"
      transform="translate(-4969.059 -501.683)"
      fill="#f44336"
    />
  </svg>
);
