import { DateTime } from 'luxon';
import { FanHeartbeatErrorCode } from '../../../../core/src/api';
import {
  getRunningWindowTimeRemainHrs,
  SimpleFanStatus,
} from '../grain-container/aeration/FanStatusHelpers';
import { getFanStatusInfo } from './ops-table-components';
import { WeatherInfoPeriod } from './ops-table-components/weather';

const grainVolumeCellComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  console.log('in grainVolumeCellComparator', { valueA, valueB, nodeA, nodeB, isDescending });
  if (valueA !== undefined && valueB !== undefined) {
    return valueA.grain_level_bushels - valueB.grain_level_bushels;
  }
};

const durationComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  console.log('in durationComparator', { valueA, valueB, nodeA, nodeB, isDescending });

  const valueADuration = valueA
    ? new Date(valueA.end_epoch).getTime() - new Date(valueA.start_epoch).getTime()
    : 0;
  const valueBDuration = valueB
    ? new Date(valueB.end_epoch).getTime() - new Date(valueB.start_epoch).getTime()
    : 0;
  return valueADuration - valueBDuration;
};

const startDateComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  console.log('in startDateComparator', { valueA, valueB, nodeA, nodeB, isDescending });

  return (
    (valueA ? new Date(valueA.start_epoch).getTime() : new Date(0).getTime()) -
    (valueB ? new Date(valueB.start_epoch).getTime() : new Date(0).getTime())
  );
};

const endDateComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  console.log('in endDateComparator', { valueA, valueB, nodeA, nodeB, isDescending });
  return (
    (valueA ? new Date(valueA.end_epoch).getTime() : new Date(0).getTime()) -
    (valueB ? new Date(valueB.end_epoch).getTime() : new Date(0).getTime())
  );
};

const automationPeriodLeftComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  console.log('in automationPeriodLeftComparator', { valueA, valueB, nodeA, nodeB, isDescending });
  const as_of = DateTime.local();

  const remainingAutomationPeriodForA: number =
    valueA && valueA.enabled && valueA.fan_guidance_end_date
      ? DateTime.fromMillis(new Date(valueA.fan_guidance_end_date).getTime())
          .diff(as_of, 'days')
          .toObject().days
      : 0;
  const remainingAutomationPeriodForB: number =
    valueB && valueB.enabled && valueB.fan_guidance_end_date
      ? DateTime.fromMillis(new Date(valueB.fan_guidance_end_date).getTime())
          .diff(as_of, 'days')
          .toObject().days
      : 0;
  return remainingAutomationPeriodForA - remainingAutomationPeriodForB;
};

const getTimeRemainInHrs = ({
  isAnyFanRunning,
  scheduled_start,
  scheduled_end,
  hasManualMode,
  hasFanStopFailError,
}: {
  isAnyFanRunning: boolean;
  scheduled_start: Date;
  scheduled_end: Date | null;
  hasManualMode: boolean;
  hasFanStopFailError: boolean;
}): number => {
  const endEpochDt = scheduled_end && DateTime.fromMillis(scheduled_end.getTime());
  const startEpochDt = scheduled_start && DateTime.fromMillis(scheduled_start.getTime());
  const hasManualModeWithNoScheduleEnd = hasManualMode && scheduled_end === null;
  const hasFanStopFailErrorWithNoScheduleEnd = hasFanStopFailError && scheduled_end === null;
  const hasInfiniteEndTime =
    (startEpochDt &&
      endEpochDt &&
      Math.round(endEpochDt.diff(startEpochDt, 'year').toObject().years)) ||
    hasManualModeWithNoScheduleEnd ||
    hasFanStopFailErrorWithNoScheduleEnd;
  const show_remaining =
    Boolean(
      scheduled_start && scheduled_end && new Date(scheduled_start).getTime() < new Date().getTime()
    ) ||
    hasManualMode ||
    hasFanStopFailError;
  const infiniteHrs = 87660;
  const runningWindowTimeRemain =
    isAnyFanRunning && show_remaining
      ? hasInfiniteEndTime
        ? infiniteHrs
        : getRunningWindowTimeRemainHrs(scheduled_end)
      : 0;
  return runningWindowTimeRemain;
};

const aerationWindowTimeRemainComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  console.log('in aerationWindowTimeRemainComparator', {
    valueA,
    valueB,
    nodeA,
    nodeB,
    isDescending,
  });
  let aerationWindowTimeRemainForA = 0;
  let aerationWindowTimeRemainForB = 0;

  const isAnyFanRunningForA =
    valueA && valueA.length > 0
      ? valueA.some(
          (fanStatus) =>
            getFanStatusInfo(fanStatus.state, fanStatus.is_on).status === SimpleFanStatus.RUNNING
        )
      : false;
  const hasFanStopFailErrorForA =
    valueA && valueA.length > 0
      ? valueA.some((fanStatus) => fanStatus.error_code === FanHeartbeatErrorCode.StopFail)
      : false;

  const isAnyFanRunningForB =
    valueB && valueB.length > 0
      ? valueB.some(
          (fanStatus) =>
            getFanStatusInfo(fanStatus.state, fanStatus.is_on).status === SimpleFanStatus.RUNNING
        )
      : false;
  const hasFanStopFailErrorForB =
    valueB && valueB.length > 0
      ? valueB.some((fanStatus) => fanStatus.error_code === FanHeartbeatErrorCode.StopFail)
      : false;

  if (isAnyFanRunningForA) {
    const aerationSchedule = nodeA.data.fan_schedule;
    const currentSchedule = aerationSchedule.length > 0 ? aerationSchedule[0] : null;
    const hasManualMode = isAnyFanRunningForA && isAnyFanRunningForA;
    if (currentSchedule) {
      aerationWindowTimeRemainForA = getTimeRemainInHrs({
        hasManualMode,
        hasFanStopFailError: hasFanStopFailErrorForA,
        isAnyFanRunning: isAnyFanRunningForA,
        scheduled_start: currentSchedule.start_epoch,
        scheduled_end: currentSchedule.end_epoch,
      });
    }
  }

  if (isAnyFanRunningForB) {
    const aerationSchedule = nodeB.data.fan_schedule;
    const currentSchedule = aerationSchedule.length > 0 ? aerationSchedule[0] : null;
    const hasManualMode = isAnyFanRunningForB && isAnyFanRunningForB;
    if (currentSchedule) {
      aerationWindowTimeRemainForB = getTimeRemainInHrs({
        hasManualMode,
        hasFanStopFailError: hasFanStopFailErrorForB,
        isAnyFanRunning: isAnyFanRunningForB,
        scheduled_start: currentSchedule.start_epoch,
        scheduled_end: currentSchedule.end_epoch,
      });
    }
  }

  console.log('time remain values', { aerationWindowTimeRemainForA, aerationWindowTimeRemainForB });
  return aerationWindowTimeRemainForA - aerationWindowTimeRemainForB;
};

const numbersComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  if (valueA !== undefined && valueB !== undefined) {
    return valueA - valueB;
  }
};

const stringsComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  console.log('in stringsComparator', valueA, valueB, nodeA, nodeB);
  if (valueA !== undefined && valueB !== undefined) {
    return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
  }
};

const weatherTempComparator = (valueA, valueB, nodeA, nodeB, isDescending, weather_period) => {
  console.log('in weatherTempComparator', weather_period, valueA, valueB);
  let newValueA = 0;
  let newValueB = 0;
  if (valueA && valueB) {
    if (
      weather_period === WeatherInfoPeriod.today ||
      weather_period === WeatherInfoPeriod.one_week
    ) {
      newValueA = valueA[weather_period] ? valueA[weather_period].temp.temp_high_f : 0;
      newValueB = valueB[weather_period] ? valueB[weather_period].temp.temp_high_f : 0;
    } else if (weather_period === WeatherInfoPeriod.now) {
      newValueA = valueA[WeatherInfoPeriod.now] ? valueA[WeatherInfoPeriod.now].temp_f : 0;
      newValueB = valueB[WeatherInfoPeriod.now] ? valueB[WeatherInfoPeriod.now].temp_f : 0;
    }
  }
  return numbersComparator(newValueA, newValueB, nodeA, nodeB, isDescending);
};

const weatherHumidityComparator = (valueA, valueB, nodeA, nodeB, isDescending, weather_period) => {
  let newValueA = 0;
  let newValueB = 0;
  if (valueA && valueB) {
    if (
      weather_period === WeatherInfoPeriod.today ||
      weather_period === WeatherInfoPeriod.one_week
    ) {
      newValueA = valueA[weather_period] ? valueA[weather_period].humidity.humidity_high_rh : 0;
      newValueB = valueB[weather_period] ? valueB[weather_period].humidity.humidity_low_rh : 0;
    } else if (weather_period === WeatherInfoPeriod.now) {
      newValueA = valueA[WeatherInfoPeriod.now] ? valueA[WeatherInfoPeriod.now].humidity_rh : 0;
      newValueB = valueB[WeatherInfoPeriod.now] ? valueB[WeatherInfoPeriod.now].humidity_rh : 0;
    }
  }
  return numbersComparator(newValueA, newValueB, nodeA, nodeB, isDescending);
};

const weatherEmcComparator = (valueA, valueB, nodeA, nodeB, isDescending, weather_period) => {
  let newValueA = 0;
  let newValueB = 0;
  if (valueA && valueB) {
    if (
      weather_period === WeatherInfoPeriod.today ||
      weather_period === WeatherInfoPeriod.one_week
    ) {
      newValueA = valueA[weather_period] ? valueA[weather_period].emc.emc_high : 0;
      newValueB = valueB[weather_period] ? valueB[weather_period].emc.emc_low : 0;
    } else if (weather_period === WeatherInfoPeriod.now) {
      newValueA = valueA[WeatherInfoPeriod.now] ? valueA[WeatherInfoPeriod.now].emc : 0;
      newValueB = valueB[WeatherInfoPeriod.now] ? valueB[WeatherInfoPeriod.now].emc : 0;
    }
  }
  return numbersComparator(newValueA, newValueB, nodeA, nodeB, isDescending);
};

const weatherIconComparator = (valueA, valueB, nodeA, nodeB, isDescending, weather_period) => {
  if (valueA && valueB) {
    return stringsComparator(
      valueA[weather_period].icon,
      valueB[weather_period].icon,
      nodeA,
      nodeB,
      isDescending
    );
  }
};

export {
  grainVolumeCellComparator,
  durationComparator,
  endDateComparator,
  startDateComparator,
  automationPeriodLeftComparator,
  aerationWindowTimeRemainComparator,
  weatherTempComparator,
  weatherHumidityComparator,
  weatherEmcComparator,
  weatherIconComparator,
};
