import { ViewerFragmentFragment } from '../api';

export enum SourceType {
  fc = 1,
  user_account = 10,
  hub = 20,
}

export type FanControllerAction = 'start' | 'stop' | 'offline' | 'online';
export type UserAccountAction = 'password' | 'email_address';
export type HubAction = 'status' | 'headspace' | 'pellet' | 'gps';

type BaseNotification<T extends SourceType, P extends {}> = {
  source_type: T;
  epoch_time: Date;
  alert: string | null;
  params: P;
};
export type FcNotification = BaseNotification<
  SourceType.fc,
  {
    core_id: string;
    fan_controller_id: string;
    grain_bin_id: number;
    account_id: number;
    action: FanControllerAction;
  }
>;
export type UserAccountNotification = BaseNotification<
  SourceType.user_account,
  {
    user_id: number;
    action: UserAccountAction;
  }
>;

export type HubNotification = BaseNotification<
  SourceType.hub,
  {
    core_id: string;
    action: HubAction;
    grain_bin_id: number;
    account_id: number;
  }
>;

export type AmberNotification = FcNotification | UserAccountNotification | HubNotification;

export enum WorkerMessageType {
  auth = 'auth',
  logout = 'logout',
  notify = 'notify',
  init_push = 'init_push',
  activated = 'activated',
  ios_register = 'ios_register',
  new_version = 'new_version',
}
export type BaseWorkerMessage<T extends WorkerMessageType, P extends {}> = {
  message_type: T;
  payload: P;
};
export type AuthMessage = BaseWorkerMessage<
  WorkerMessageType.auth,
  { viewer: ViewerFragmentFragment; propagate: boolean }
>;
export type InitPushMessage = BaseWorkerMessage<WorkerMessageType.init_push, {}>;
export type LogoutMessage = BaseWorkerMessage<WorkerMessageType.logout, { propagate: boolean }>;
export type NotifyMessage = BaseWorkerMessage<WorkerMessageType.notify, AmberNotification>;
export type ActivatedMessage = BaseWorkerMessage<WorkerMessageType.activated, {}>;
export type NewVersionMessage = BaseWorkerMessage<WorkerMessageType.new_version, {}>;
export type IosRegisterMessage = BaseWorkerMessage<
  WorkerMessageType.ios_register,
  { device_token }
>;
export type WorkerMessage =
  | AuthMessage
  | InitPushMessage
  | LogoutMessage
  | NotifyMessage
  | ActivatedMessage
  | NewVersionMessage
  | IosRegisterMessage;
