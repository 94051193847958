import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  DialogContent,
  Divider,
  FormControlLabel,
  makeStyles,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { BaseDialog, CountBadge } from '../../../../../../core/src/component/util';
import { ActiveStoragePeriodContext } from '../../../../../../core/src/contexts';
import { amber_dark_grey } from '../../../../../../core/src/style';
import { MathUtils } from '../../../../../../core/src/util';
import {
  GrainSurfaceShapeType,
  GrainTicketActionType,
  GrainType,
  SensorGeneratedGrainTicket,
  withApproveSensorGeneratedGrainTicketHoc,
  WithApproveSensorGeneratedGrainTicketHocChildProps,
  withDeclineSensorGeneratedGrainTicketHoc,
  WithDeclineSensorGeneratedGrainTicketHocChildProps,
  WithGetGrainContainerHubLinksHocChildProps,
} from '../../../../api';
import ConfirmationModal from '../../../util/ConfirmationModal';
import ApproveGrainTicketDialog from './ApproveGrainTicketDialog';
import { PendingGrainTicketsTable } from './PendingGrainTicketsTable';

export const GRAIN_TICKET_APPROVE_STATE = {
  approved: 'approved',
  declined: 'declined',
  pending: 'pending',
};
const getGrainLevelDiffThresholdInBu = (
  grainLevelDiffThresholdInMm: number,
  diameter_ft: number
): number => {
  const grainLevelDiffThresholdInFeet = MathUtils.mmToFeet(grainLevelDiffThresholdInMm);
  return MathUtils.heightDiamInFtToBushels(diameter_ft, grainLevelDiffThresholdInFeet);
};
const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    fontSize: 16,
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      minHeight: 'fit-content',
      margin: 0,
    },
  },
  infoText: {
    fontSize: 16,
    textAlign: 'start',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}));

type ManagePendingGrainTicketsProps = {
  grain_bin_id: number;
  grain_type: GrainType;
  bin_diameter_ft: number | null;
  sensor_generated_grain_tickets: SensorGeneratedGrainTicket[];
  refetch_sensor_generated_grain_tickets: () => Promise<any>;
  refetchGrainBinTickets: () => Promise<any>;
  refetchGetShowGrainTicketLevelDeviationWarning: () => Promise<any>;
  setUpdating: (shouldUpdate: boolean) => void;
} & WithApproveSensorGeneratedGrainTicketHocChildProps &
  WithDeclineSensorGeneratedGrainTicketHocChildProps;

export type UpdateGrainTicket = {
  grain_bin_ticket_id: number;
  grain_bin_id: number;
  epoch_time: Date;
  volume: number;
  grain_moisture_pct: number;
  weight_in_lbs: number;
  test_weight_in_lbs: number;
  action_performed: GrainTicketActionType;
  notes: string;
};

export const ManagePendingGrainTickets = withApproveSensorGeneratedGrainTicketHoc(
  withDeclineSensorGeneratedGrainTicketHoc(
    ({
      grain_bin_id,
      grain_type,
      bin_diameter_ft,
      sensor_generated_grain_tickets,
      approveSensorGeneratedGrainTicket,
      declineSensorGeneratedGrainTicket,
      refetchGrainBinTickets,
      refetch_sensor_generated_grain_tickets,
      refetchGetShowGrainTicketLevelDeviationWarning,
      setUpdating,
    }: ManagePendingGrainTicketsProps) => {
      const classes = useStyles();
      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
      const activeStoragePeriodContext = useContext(ActiveStoragePeriodContext);
      const activeStoragePeriod = activeStoragePeriodContext.activeStoragePeriod;
      const [showApproveTicketDialog, setShowApproveTicketDialog] = useState<boolean>(false);
      const [showDismissTicketDialog, setShowDismissTicketDialog] = useState<boolean>(false);
      const [hideRejectedSensorTickets, setHideRejectedSensorTickets] = useState<boolean>(true);
      const [
        currentGrainTicket,
        setCurrentGrainTicket,
      ] = useState<SensorGeneratedGrainTicket | null>(null);
      const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
      const sortedGrainBinTickets = useMemo(() => {
        return sensor_generated_grain_tickets.sort(
          (a, b) => new Date(b.epoch_time).getTime() - new Date(a.epoch_time).getTime()
        );
      }, [JSON.stringify(sensor_generated_grain_tickets)]);
      const showRejectedTicketsToggle = sortedGrainBinTickets.length > 0;
      const pendingGrainTicketsOnly = sortedGrainBinTickets.filter(
        ({ approve_state }) => approve_state === GRAIN_TICKET_APPROVE_STATE.pending
      );
      const pendingGrainTicketsCount = pendingGrainTicketsOnly.length;
      const [isPendingTicketsSectionOpen, setPendingTicketsSectionOpen] = useState(
        Boolean(pendingGrainTicketsCount)
      );
      const BIN_LEVEL_DIFF_THRESHOLD_IN_MM = 600;
      const BIN_LEVEL_DIFF_WITH_LAST_GRAIN_TICKET_THRESHOLD_IN_MM = 750;
      const grainLevelDiffThresholdInBu = bin_diameter_ft
        ? getGrainLevelDiffThresholdInBu(BIN_LEVEL_DIFF_THRESHOLD_IN_MM, bin_diameter_ft)
        : null;

      const getGrainSurfaceShapeByActionPerformed = (
        action: GrainTicketActionType | undefined
      ): GrainSurfaceShapeType => {
        return action && action === GrainTicketActionType.Removed
          ? GrainSurfaceShapeType.Inverted
          : GrainSurfaceShapeType.Peaked;
      };
      const curr_ticket_action_performed = useMemo(() => {
        return currentGrainTicket ? currentGrainTicket.action_performed : undefined;
      }, [currentGrainTicket]);

      const defaultGrainSurfaceShape = getGrainSurfaceShapeByActionPerformed(
        curr_ticket_action_performed
      );
      const [grainSurfaceShape, setGrainSurfaceShape] = useState(defaultGrainSurfaceShape);
      const grainLevelDiffWithGrainTicketsThresholdInBu = bin_diameter_ft
        ? getGrainLevelDiffThresholdInBu(
            BIN_LEVEL_DIFF_WITH_LAST_GRAIN_TICKET_THRESHOLD_IN_MM,
            bin_diameter_ft
          )
        : null;

      const handleGrainSurfaceShapeChange = (
        event: React.MouseEvent<HTMLElement>,
        grainSurfaceShapeVal: GrainSurfaceShapeType
      ) => {
        if (grainSurfaceShapeVal !== null) setGrainSurfaceShape(grainSurfaceShapeVal);
      };

      const handleGrainActionChange = (action: GrainTicketActionType) => {
        const updatedGrainSurfaceShape = getGrainSurfaceShapeByActionPerformed(action);
        setGrainSurfaceShape(updatedGrainSurfaceShape);
      };

      const handleTicketApprove = async (updatedGrainTicket: UpdateGrainTicket) => {
        if (currentGrainTicket) {
          try {
            setUpdating(true);
            await approveSensorGeneratedGrainTicket({
              ...updatedGrainTicket,
              grain_surface_shape: grainSurfaceShape,
              grain_bin_storage_cycle_id: activeStoragePeriod
                ? activeStoragePeriod.grain_bin_storage_cycle_id
                : null,
            });
            await Promise.all([
              refetchGrainBinTickets(),
              refetch_sensor_generated_grain_tickets(),
              refetchGetShowGrainTicketLevelDeviationWarning(),
            ]);
          } catch (error) {
            console.error(error);
          } finally {
            setUpdating(false);
          }
        }
      };

      const handleTicketDecline = async () => {
        if (currentGrainTicket) {
          try {
            setUpdating(true);
            await declineSensorGeneratedGrainTicket({
              grain_bin_id,
              grain_bin_ticket_id: currentGrainTicket.grain_bin_ticket_id,
            });
            await Promise.all([
              refetch_sensor_generated_grain_tickets(),
              refetchGetShowGrainTicketLevelDeviationWarning(),
            ]);
          } catch (error) {
            console.error(error);
          } finally {
            setUpdating(false);
          }
        }
      };

      const onTicketApprove = (selectedGrainTicket: SensorGeneratedGrainTicket) => {
        setCurrentGrainTicket(selectedGrainTicket);
        setShowApproveTicketDialog(true);
      };

      const onTicketDismiss = (selectedGrainTicket: SensorGeneratedGrainTicket) => {
        setCurrentGrainTicket(selectedGrainTicket);
        setShowDismissTicketDialog(true);
      };

      const handleHideRejectedSensorTicketsOnChange = () => {
        setHideRejectedSensorTickets((prev) => !prev);
      };

      useEffect(() => {
        setGrainSurfaceShape(defaultGrainSurfaceShape);
      }, [defaultGrainSurfaceShape]);

      return (
        <>
          <Accordion
            style={{ width: '100%', padding: 15 }}
            expanded={isPendingTicketsSectionOpen}
            onChange={() => {
              setPendingTicketsSectionOpen(!isPendingTicketsSectionOpen);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-pending-grain-tickets"
              id="panel-pending-grain-tickets-header"
              style={{ padding: 0 }}
              className={classes.accordionSummary}
            >
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  variant="h5"
                  style={{
                    fontSize: 20,
                    color: amber_dark_grey,
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Pending Tickets
                  <CountBadge>{pendingGrainTicketsCount}</CountBadge>
                </Typography>
                <Button
                  style={{ maxHeight: 26 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowMoreInfo(true);
                  }}
                >
                  MORE INFO
                </Button>
              </div>
            </AccordionSummary>{' '}
            <Divider style={{ marginTop: 10 }} />
            <AccordionDetails style={{ padding: 0, width: '100%' }}>
              <div style={{ width: '100%' }}>
                <div style={{ textAlign: 'center', marginBottom: 10 }}>
                  <Typography className={classes.infoText} style={{ marginTop: 15 }}>
                    Accept level sensor change events to convert them to grain tickets.
                  </Typography>
                  {grainLevelDiffThresholdInBu && (
                    <Typography
                      className={classes.infoText}
                      style={{ marginBottom: 15, marginTop: isMobile ? 5 : 0 }}
                    >
                      Sensor-Generated Ticket Threshold: approx.{' '}
                      {Math.round(grainLevelDiffThresholdInBu)} bu
                    </Typography>
                  )}
                  {showRejectedTicketsToggle && (
                    <div style={{ textAlign: 'start' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={hideRejectedSensorTickets}
                            onChange={handleHideRejectedSensorTicketsOnChange}
                            inputProps={{ 'aria-label': 'Hide Rejected Tickets' }}
                            name="hide_rejected_tickets"
                          />
                        }
                        label={'Hide Rejected Tickets'}
                      />
                    </div>
                  )}
                  <PendingGrainTicketsTable
                    sortedGrainBinTickets={
                      hideRejectedSensorTickets ? pendingGrainTicketsOnly : sortedGrainBinTickets
                    }
                    onTicketApprove={onTicketApprove}
                    onTicketDismiss={onTicketDismiss}
                  />
                </div>
              </div>
              <ConfirmationModal
                showModal={showDismissTicketDialog}
                confirmationMessage={
                  <>
                    Are you sure you want to <strong>reject</strong> this grain ticket?
                  </>
                }
                cancelBtnText="No"
                confirmBtbText="Yes"
                handleCancel={() => setShowDismissTicketDialog(false)}
                handleConfirm={() => {
                  handleTicketDecline();
                  setShowDismissTicketDialog(false);
                }}
              />
              {currentGrainTicket && (
                <ApproveGrainTicketDialog
                  open={showApproveTicketDialog}
                  onClickClose={() => setShowApproveTicketDialog(false)}
                  currentGrainTicket={currentGrainTicket}
                  handleTicketApprove={handleTicketApprove}
                  grain_type={grain_type}
                  grainSurfaceShape={grainSurfaceShape}
                  handleGrainSurfaceShapeChange={handleGrainSurfaceShapeChange}
                  handleGrainActionChange={handleGrainActionChange}
                />
              )}
            </AccordionDetails>
          </Accordion>
          <BaseDialog
            title="More Info"
            open={showMoreInfo}
            handleClose={() => setShowMoreInfo(false)}
          >
            <DialogContent style={{ textAlign: 'start', paddingBottom: 14, paddingTop: 0 }}>
              <div style={{ lineHeight: 1.6, fontSize: 14 }}>
                <Typography variant="subtitle1" style={{ lineHeight: 1.6, fontSize: 14 }}>
                  Pending Tickets are generated when either:
                </Typography>
                <ol style={{ paddingInlineStart: 16, margin: '8px 0px' }}>
                  <li style={{ marginBottom: 5 }}>
                    Grain Level change of at least <strong>2ft</strong> is detected
                    {grainLevelDiffThresholdInBu && (
                      <>
                        {' '}
                        (equivalent to about{' '}
                        <strong>{Math.round(grainLevelDiffThresholdInBu)} bu</strong>)
                      </>
                    )}
                    .
                  </li>
                  <li>
                    Grain Level difference between existing Grain Tickets and the Hardware Level
                    Data is more than <strong>2.5ft</strong>
                    {grainLevelDiffWithGrainTicketsThresholdInBu && (
                      <>
                        {' '}
                        or approximately{' '}
                        <strong>
                          {Math.round(grainLevelDiffWithGrainTicketsThresholdInBu)} bu
                        </strong>
                        .
                      </>
                    )}
                  </li>
                </ol>
              </div>
            </DialogContent>
          </BaseDialog>
        </>
      );
    }
  )
);
