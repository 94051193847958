import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import { isTouchDevice } from '../../util';

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  marker: {
    backgroundColor: 'white',
    borderRadius: 10,
    border: '1px solid black',
    paddingLeft: 5,
    paddingRight: 5,
    maxWidth: 100,
  },
});

type LocationOption = { value: number | string; label: string; lat: number; lng: number };
type LocationMapSelectProps = {
  value: number | string;
  options: LocationOption[];
  onChange?: (value) => void;
} & Pick<google.maps.MapOptions, 'mapTypeControl'>;
const is_touch = isTouchDevice();
const selected_zoom = 18;

const BaseLocationMapSelect = ({
  onChange,
  mapTypeControl = false,
  ...props
}: LocationMapSelectProps) => {
  const [zoom, setZoom] = useState(4);
  const [[lat, lng], setLatLng] = useState([37.09024, -95.712891]);
  const [stateValue, setStateValue] = useState(props.value);
  const classes = useStyles();
  const ref = useRef<any>();
  const onZoomChanged = useCallback(() => {
    const current = ref.current;
    const next = current && current.getZoom();
    if (next && next !== zoom) {
      setZoom(next);
    }
  }, [ref, zoom, setZoom]);

  const center = { lat, lng };

  useEffect(() => {
    const selected = props.options.find((option) => option.value === props.value);
    if (!selected) {
      return;
    }
    setLatLng([selected.lat, selected.lng]);
    setZoom(selected_zoom);
  }, []);

  useEffect(() => {
    if (props.value === stateValue) {
      return;
    }
    const selected = props.options.find((option) => option.value === props.value);
    if (!selected) {
      return;
    }
    setLatLng([selected.lat, selected.lng]);
    setZoom(selected_zoom);
    setStateValue(props.value);
  }, [props.value]);

  return (
    <GoogleMap
      options={{
        mapTypeControl,
        clickableIcons: false,
        controlSize: 30,
        fullscreenControl: false,
        mapTypeId: google.maps.MapTypeId.HYBRID,

        mapTypeControlOptions: mapTypeControl
          ? {
              mapTypeIds: [google.maps.MapTypeId.HYBRID, google.maps.MapTypeId.ROADMAP],
              style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: google.maps.ControlPosition.LEFT_BOTTOM,
            }
          : undefined,
        panControl: false,
        rotateControl: false,
        streetViewControl: false,
        zoomControl: !is_touch,
      }}
      ref={ref}
      zoom={zoom}
      center={center}
      clickableIcons={false}
      onZoomChanged={onZoomChanged}
    >
      {props.options.map(({ value, label, lat, lng }) =>
        value === props.value ? (
          <MarkerWithLabel
            position={{ lat, lng }}
            labelAnchor={new google.maps.Point(24, 55)}
            key={value}
            icon={{
              url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            }}
          >
            <div className={classes.marker}>
              <Typography variant="body2">{label}</Typography>
            </div>
          </MarkerWithLabel>
        ) : (
          <Marker
            key={value}
            position={{ lat, lng }}
            icon={{
              url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
            }}
            onClick={(e) => {
              setStateValue(value);
              onChange && onChange(value);
            }}
          />
        )
      )}
    </GoogleMap>
  );
};

const LocationMapSelectWrapper = withScriptjs(withGoogleMap(BaseLocationMapSelect));

export const LocationMapSelect: FunctionComponent<
  LocationMapSelectProps & {
    className?: string;
    style?: CSSProperties;
  }
> = (props) => (
  <LocationMapSelectWrapper
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${
      window['app_config'].google_api_key
    }&v=3.exp&libraries=geometry,drawing,places`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={
      <div style={{ height: '300px', width: '100%', ...props.style }} className={props.className} />
    }
    mapElement={<div style={{ height: `100%` }} />}
    options={props.options}
    value={props.value}
    onChange={props.onChange}
  />
);
