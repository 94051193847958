import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { CreateTokenMutation, RenewTokenMutation } from '../../api';

/**
 *
 * Current implementation of user monitor tracking is with datadog RUM tracking
 *
 * https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#identify-user-sessions
 * Setting the recommended common attributes of id, email, and name.
 *
 */
class UserMonitor {
  setUser(data: {
    id: string;
    email: string;
    name: string;
    account_id: number;
    organization: string;
  }) {
    datadogLogs.logger.setContext(data);

    datadogLogs.logger.log('Current User Token Renew Complete', {
      ...data,
    });

    datadogRum.setUser(data);
  }

  removeUser() {
    datadogRum.removeUser();
  }
}

export const userMonitor = new UserMonitor();
