import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { FanControllerStateValueNext, FanRunWindowRecommendedOption } from '../../../api';
import { amber_blue, amber_green, amber_grey, amber_red, yellow_shade_1 } from '../../../style';

// Styles
// ---------------------------------------
const defaultRed = '#ff4500';
const RECOMMENDED_OPTION_RINGS_COLORS = {
  [FanRunWindowRecommendedOption.Cooling]: {
    first: '#b8eeff',
    second: '#1ec8ff',
    third: '#1e90ff',
  },
  [FanRunWindowRecommendedOption.Drying]: { first: '#eedc82', second: '#ccae88', third: '#e8a6a6' },
  [FanRunWindowRecommendedOption.Reconditioning]: {
    first: '#ddaeff',
    second: '#aeaeff',
    third: '#b9e3f3',
  },
  [FanRunWindowRecommendedOption.Custom]: {
    first: '#00d600',
    second: '#007000',
    third: '#004700',
  },
  [FanRunWindowRecommendedOption.Manual]: {
    first: '#00d600',
    second: '#007000',
    third: '#004700',
  },
  Unknown: { first: defaultRed, second: defaultRed, third: defaultRed },
};

const ROTATING_WAVE_COLORS = {
  [FanRunWindowRecommendedOption.Cooling]:
    RECOMMENDED_OPTION_RINGS_COLORS[FanRunWindowRecommendedOption.Cooling].first,
  [FanRunWindowRecommendedOption.Drying]:
    RECOMMENDED_OPTION_RINGS_COLORS[FanRunWindowRecommendedOption.Drying].first,
  [FanRunWindowRecommendedOption.Reconditioning]:
    RECOMMENDED_OPTION_RINGS_COLORS[FanRunWindowRecommendedOption.Reconditioning].first,
  [FanRunWindowRecommendedOption.Custom]:
    RECOMMENDED_OPTION_RINGS_COLORS[FanRunWindowRecommendedOption.Custom].first,
  [FanRunWindowRecommendedOption.Manual]:
    RECOMMENDED_OPTION_RINGS_COLORS[FanRunWindowRecommendedOption.Manual].first,
  Unknown: defaultRed,
};

const getRingBoxShadow = ({
  recommendationType,
  isAnyFanRunning,
  isAnyFanUpdating,
  areAllFansReady,
  hasFansWithError,
  hasSomeReadyState,
  hasUpcomingAerationSchedule,
}: makeStylesProps) => {
  let { third: color } = RECOMMENDED_OPTION_RINGS_COLORS[recommendationType];
  if (isAnyFanUpdating || (hasSomeReadyState && !hasUpcomingAerationSchedule && !isAnyFanRunning)) {
    color = '#ffffff';
  } else if ((!isAnyFanRunning && !hasSomeReadyState) || hasFansWithError) {
    color = defaultRed;
  }
  return `0 0 25px 4px ${color}59`; // converted hex to alpha(here 59 is 35%)-> `0 0 25px 4px rgb(232 166 166 / 35%)`
};

type makeStylesProps = {
  isAnyFanRunning: boolean;
  isAnyFanUpdating: boolean;
  recommendationType: string;
  areAllFansReady: boolean;
  hasFansWithError: boolean;
  hasUpcomingAerationSchedule: boolean;
  hasAutomationEnabled: boolean;
  areAllFansOffline: boolean;
  hasSomeReadyState: boolean;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  singleFanContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  status_heading: {
    fontWeight: 400,
    fontSize: '1.2857142857142856rem',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: 'white',
    marginBottom: 14,
    fontFamily: 'Work Sans,sans-serif',
  },
  status_text: {
    letterSpacing: 6,
    fontSize: 12,
    color: 'white',
    marginTop: 10,
    marginBottom: 10,
    fontFamily: 'Work Sans,sans-serif',
  },
  fanStatus: {
    width: '100%',
    height: 32,
    borderRadius: '20px',
    // fontFamily: 'MyriadPro-Semibold,sans-serif',
    fontFamily: 'Work Sans,sans-serif',
    padding: '10px',
    letterSpacing: '0.36px',
    textAlign: 'left',
    fontSize: 21,
    fontWeight: 600,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fanStatusAnimationContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  parent: {
    width: '250px',
    height: '250px',
    border: '4px solid #000',
    borderRadius: '50%',
    position: 'relative',
    top: '0',
    left: '0',
    backgroundColor: '#000',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  },
  blueDashImg: {
    width: '100%',
    height: '100%',
    position: 'relative',
    top: '0',
    left: '0',
    zIndex: 2,
    display: ({ isAnyFanUpdating }: makeStylesProps) => {
      return isAnyFanUpdating ? 'none' : 'block';
    },
  },
  spinAnimation: { animation: '$spin 2.5s linear infinite' },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  dashCircleImg: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px',
    zIndex: 1,
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0.9 },
    '20%': { opacity: 0.5 },
    '25%': { opacity: 0.1 },
    '30%': { opacity: 0 },
    '40%': { opacity: 0.1 },
    '45%': { opacity: 0.3 },
    '60%': { opacity: 0.5 },
    '80%': { opacity: 0.9 },
    '100%': { opacity: 1 },
  },
  hudCircle1: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    zIndex: 4,
    display: 'flex',
    width: '80%',
    height: '80%',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    border: ({
      recommendationType,
      isAnyFanRunning,
      isAnyFanUpdating,
      areAllFansReady,
      hasFansWithError,
      hasUpcomingAerationSchedule,
      areAllFansOffline,
    }: makeStylesProps) => {
      // const fanAreReadyWithoutErrors = areAllFansReady && !hasFansWithError;
      const { first } = RECOMMENDED_OPTION_RINGS_COLORS[recommendationType];
      let color = 'white';
      const hasNormalReadyState =
        recommendationType === FanRunWindowRecommendedOption.Custom &&
        !hasUpcomingAerationSchedule &&
        !hasFansWithError &&
        !areAllFansOffline;

      if (hasNormalReadyState) {
        color = 'white';
      }

      if (isAnyFanRunning || hasUpcomingAerationSchedule) {
        color = first;
      }

      if (isAnyFanUpdating) {
        color = 'white';
      }

      if (hasFansWithError || areAllFansOffline) {
        color = defaultRed;
      }
      return `2px solid ${color}`;
    },
    borderRadius: '50%',
    boxShadow: (props: makeStylesProps) => getRingBoxShadow(props),
    animation: '$fadeIn 5s infinite ease-in-out',
  },
  hudCircle2: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    zIndex: 2,
    display: ({ isAnyFanRunning, isAnyFanUpdating }: makeStylesProps) => {
      return isAnyFanRunning || isAnyFanUpdating ? 'flex' : 'none';
    },
    width: '74%',
    height: '74%',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    border: ({
      recommendationType,
      isAnyFanRunning,
      isAnyFanUpdating,
      areAllFansReady,
      hasFansWithError,
    }: makeStylesProps) => {
      const fanAreReadyWithoutErrors = areAllFansReady && !hasFansWithError;
      let { second: color } = RECOMMENDED_OPTION_RINGS_COLORS[recommendationType];

      if (isAnyFanUpdating) {
        color = '#ffffff';
      } else if (!isAnyFanRunning && !fanAreReadyWithoutErrors) {
        color = defaultRed;
      }
      return `2px solid ${color}`;
    },
    borderRadius: '50%',
    boxShadow: (props: makeStylesProps) => getRingBoxShadow(props),
    animation: '$fadeIn 4s infinite ease-in-out',
  },
  hudCircle3: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    zIndex: 2,
    display: ({
      isAnyFanRunning,
      isAnyFanUpdating,
      areAllFansReady,
      hasFansWithError,
    }: makeStylesProps) => {
      return isAnyFanRunning || isAnyFanUpdating ? 'flex' : 'none';
    },
    width: '68%',
    height: '68%',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    border: ({
      recommendationType,
      isAnyFanRunning,
      isAnyFanUpdating,
      areAllFansReady,
      hasFansWithError,
    }: makeStylesProps) => {
      const fanAreReadyWithoutErrors = areAllFansReady && !hasFansWithError;
      let { third: color } = RECOMMENDED_OPTION_RINGS_COLORS[recommendationType];

      if (isAnyFanUpdating) {
        color = '#ffffff';
      } else if (!isAnyFanRunning && !fanAreReadyWithoutErrors) {
        color = defaultRed;
      }
      return `2px solid ${color}`;
    },
    borderRadius: '50%',
    boxShadow: (props: makeStylesProps) => getRingBoxShadow(props),
    animation: '$fadeIn 4s infinite ease-in-out',
  },
  mainTextContent: {
    color: 'white',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  timeRemain: {
    color: yellow_shade_1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Work Sans,sans-serif',
  },
  timeRemainLabel: {
    fontSize: 10,
    textTransform: 'uppercase',
    fontFamily: 'Work Sans,sans-serif',
    marginBottom: 4,
  },
  timeRemainLabel2: {
    fontSize: 10,
    textTransform: 'uppercase',
    fontFamily: 'Work Sans,sans-serif',
    marginBottom: 4,
  },
  timeRemainValue: {
    fontSize: 20,
    letterSpacing: '1.46px',
    lineHeight: '16px',
    fontFamily: 'Digital-7-Mono,sans-serif',
  },
  timeRemainValue2: {
    fontSize: 20,
    letterSpacing: '1.46px',
    lineHeight: '16px',
    fontFamily: 'Digital-7-Mono,sans-serif',
  },
  timeRemainValueSeparator: {
    fontFamily: 'Digital-7-Regular,sans-serif',
  },

  // dissimilar States UI css
  parent2: {
    width: '308px',
    // height: '233px',
    padding: '20px 8px',
    borderRadius: 10,
    backgroundColor: '#000',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainTextContent2: {
    color: 'white',
  },
  statusText: {
    lineHeight: '14.4px',
    letterSpacing: 6,
    fontSize: 12,
    margin: 0,
    marginBottom: 16,
    fontFamily: 'Work Sans,sans-serif',
  },
  circularUI: {
    width: 187,
    height: 187,
    position: 'relative',
    top: '0',
    left: '0',
    marginLeft: 8,
  },
  circularUIContent: {
    color: 'white',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  statusLabel: {
    color: 'white',
    fontFamily: 'Work Sans,sans-serif',
    fontSize: 14,
    width: 96,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: 6,
    textAlign: 'left',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  fanStatus2: {
    width: 98,
    height: 28,
    borderRadius: '20px',
    // fontFamily: 'MyriadPro-Semibold,sans-serif',
    padding: '10px',
    fontFamily: 'Work Sans,sans-serif',
    letterSpacing: '0.24px',
    textAlign: 'left',
    fontSize: '18.48px',
    fontWeight: 600,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pauseBtnPulse: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    animation: '$pulse 2s infinite',
  },

  '@keyframes pulse': {
    '0%': {
      boxShadow: '0 0 0 0px rgba(255, 255, 255, 0.6)',
    },
    '100%': {
      boxShadow: '0 0 0 10px rgba(255, 255, 255, 0)',
    },
  },
});

// Helpers
// ---------------------------------------
enum SimpleFanStatus {
  OFFLINE = 'OFFLINE',
  READY = 'READY',
  RUNNING = 'RUNNING',
  UPDATING = 'UPDATING',
}
// navigate gbfc object to get fan and controller statuses
const getSimpleFanStatus = (fan_ctrl): { status: SimpleFanStatus; color: string } => {
  if (
    !fan_ctrl ||
    !fan_ctrl.fan_controller ||
    !fan_ctrl.fan_controller.state_next ||
    fan_ctrl.fan_controller.state_next.value_next === FanControllerStateValueNext.Offline
  ) {
    return { status: SimpleFanStatus.OFFLINE, color: amber_red };
  }
  if (
    fan_ctrl.fan_controller.state_next &&
    fan_ctrl.fan_controller.state_next.value_next === FanControllerStateValueNext.Updating
  ) {
    return { status: SimpleFanStatus.UPDATING, color: amber_grey };
  }
  if (fan_ctrl.fan_controller.state_next && fan_ctrl.fan_controller.state_next.is_on) {
    return { status: SimpleFanStatus.RUNNING, color: amber_green };
  }
  return { status: SimpleFanStatus.READY, color: amber_blue };
};

const getRunningWindowTimeRemain = (scheduled_end: Date | null) => {
  const total_hours: string | null = scheduled_end
    ? DateTime.fromMillis(scheduled_end.getTime())
        .diff(DateTime.local(), ['hours', 'minutes'])
        .toFormat('hh:mm')
    : null;

  return total_hours;
};

const getRunningWindowTimeRemainHrs = (scheduled_end: Date | null): number => {
  const total_hours: number = scheduled_end
    ? DateTime.fromMillis(scheduled_end.getTime())
        .diff(DateTime.local(), 'hours')
        .toObject().hours
    : 0;

  return total_hours;
};

export {
  makeStylesProps,
  useStyles,
  ROTATING_WAVE_COLORS,
  RECOMMENDED_OPTION_RINGS_COLORS,
  defaultRed,
  getSimpleFanStatus,
  SimpleFanStatus,
  getRunningWindowTimeRemain,
  getRunningWindowTimeRemainHrs,
};
