import { AppBar as MuiAppBar, Button, IconButton, Toolbar } from '@material-ui/core';
import { ArrowBack, Menu } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { withGetAccountHoc, WithGetAccountHocChildProps } from '../../../api';
import { SelectedState } from '../../../reducer';
import { black_shade_6, white } from '../../../style';
import { mobilebar_height } from '../../../util';
import { UserMenu } from '../UserMenu';

const useStyles = makeStyles({
  title: {
    textAlign: 'left',
    flexGrow: 1,
  },
  logo: {
    width: 24,
    height: 24,
    // TODO REPLACE WITH HIGH QUALITY ICON
    // es6 import breaks storyshots
    backgroundImage: `url(${require('../../../media/favicon.png')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    height: mobilebar_height,
    minHeight: mobilebar_height,
  },
});

const selector = ({ selected }: { selected: SelectedState }) => selected;

const BaseTopBar: FunctionComponent<
  WithGetAccountHocChildProps & {
    goToDashboard: (args: any) => void;
    onClickMenu: () => void;
    onClickPrivacy: () => void;
    onClickUser: () => void;
    onClickBack?: () => void;
  }
> = memo(({ account, goToDashboard, onClickMenu, onClickPrivacy, onClickUser, onClickBack }) => {
  const classes = useStyles();
  const selected = useSelector(selector);
  const { container_name, container_id, container_type } = selected;
  const handleLogoClick = useCallback(() => {
    if (account) {
      goToDashboard({
        account,
        container_name,
        container_id,
        container_type,
      });
    }
  }, [goToDashboard, selected]);
  return (
    <MuiAppBar position="sticky" style={{ backgroundColor: black_shade_6 }}>
      <Toolbar disableGutters className={classes.toolbar}>
        <div>
          {onClickBack && (
            <IconButton onClick={onClickBack} style={{ color: 'white' }}>
              <ArrowBack />
            </IconButton>
          )}
          <Button onClick={onClickMenu}>
            <Menu style={{ color: white }} />
          </Button>
        </div>
        <div onClick={handleLogoClick} className={classes.logo} />
        <UserMenu icon_color={white} onClickUser={onClickUser} onClickPrivacy={onClickPrivacy} />
      </Toolbar>
    </MuiAppBar>
  );
});

export const TopBar = withGetAccountHoc(BaseTopBar);
