import {
  PerformGrainBinSelectionMutationVariables as Variables,
  UserSelectedGrainBinFragmentFragment as Result,
  withPerformGrainBinSelection as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithPerformGrainBinSelectionHocChildProps = {
  performGrainBinSelection: (variables: Variables) => Promise<Result>;
};
export const withPerformGrainBinSelectionHoc = HOC<any, WithPerformGrainBinSelectionHocChildProps>({
  options: { errorPolicy: 'all' },
  props: (props) => {
    const { mutate } = props;
    return {
      performGrainBinSelection: async (variables) => {
        if (!mutate) {
          throw new Error('Unexpected UI error');
        }
        const result = await mutate({
          variables,
          update: (proxy, { data, errors }) => {
            if (errors || !data) {
              return;
            }
            const { performGrainBinSelection: result } = data;
            if (!result) {
              return;
            }
          },
        });
        if (!result) {
          throw new Error('Unexpected server response');
        }
        const { errors, data } = result;
        if (errors) {
          throw new GraphQLErrors(errors);
        }
        if (!data) {
          throw new Error('Unexpected server response');
        }
        return data.performGrainBinSelection;
      },
    };
  },
});
