import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy, RelativeTimePeriod } from '../../../util';
import { HistoryPointFragment, withGetGrainContainerHistoryInterior as HOC } from '../__generated';

export type WithGetGrainContainerHistoryInteriorHocOwnProps = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  period: RelativeTimePeriod;
  pollInterval?: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainContainerHistoryInteriorHocChildProps = {
  loading: boolean;
  interior_history: HistoryPointFragment[] | null;
};
export const withGetGrainContainerHistoryInteriorHoc: AmberHoc<
  WithGetGrainContainerHistoryInteriorHocOwnProps,
  WithGetGrainContainerHistoryInteriorHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainContainerHistoryInteriorHocOwnProps,
      WithGetGrainContainerHistoryInteriorHocChildProps,
      typeof component
    >,
    WithGetGrainContainerHistoryInteriorHocChildProps
  >({
    options: ({
      container_id,
      container_type,
      period,
      onError,
      pollInterval = 1000 * 60 * 30,
    }) => ({
      onError,
      pollInterval,
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id, container_type, period },
    }),
    props: ({ data, ownProps: { loading } }) => {
      let interior_history: HistoryPointFragment[] | null = null;

      if (!data || data.loading) {
        return {
          interior_history,
          loading: true,
        };
      }

      const { grain_container } = data;
      if (grain_container) {
        const { telemetry_history } = grain_container;
        if (telemetry_history) {
          ({ interior: interior_history } = telemetry_history);
        }
      }

      return {
        interior_history,
        loading: loading || false,
      };
    },
  })(component as any);
