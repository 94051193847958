import { Grid, Typography } from '@material-ui/core';
import { AccessTimeOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { DateTime, Settings } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setShowCO2LevelWarningBanner,
  setShowGrainTicketWarningBanner,
  setUserTimeZone,
} from '../../../action';
import {
  AccountFragmentFragment,
  FanControllerStateValueNext,
  ForecastDayV2FragmentFragment,
  GetGrainBinContainerQuery,
  GrainBinFragmentFragment,
  RecommendationWindowsFragmentFragment,
  ViewerFragmentFragment,
  withGetAccountHoc,
  withGetGrainBinContainerHoc,
  withGetGrainBinFanSettingsHoc,
  WithGetGrainBinFanSettingsHocChildProps,
  withGetGrainBinHoc,
  withGetGrainBinStoragePeriodHoc,
  WithGetGrainBinStoragePeriodHocChildProps,
  withGetGrainBinWeeklyLevelDataHoc,
  WithGetGrainBinWeeklyLevelDataHocChildProps,
  withGetGrainContainerMonthlyCO2HistoryWithAerationsHoc,
  WithGetGrainContainerMonthlyCO2HistoryWithAerationsHocChildProps,
  withGetGrainContainerWeeklyCO2HistoryWithAerationsHoc,
  WithGetGrainContainerWeeklyCO2HistoryWithAerationsHocChildProps,
  withGetPromptFanGuidanceExtensionHoc,
  WithGetPromptFanGuidanceExtensionHocChildProps,
  withGetShowCo2LevelWarningHoc,
  WithGetShowCo2LevelWarningHocChildProps,
  withGetShowGrainTicketLevelDeviationWarningHoc,
  WithGetShowGrainTicketLevelDeviationWarningHocChildProps,
} from '../../../api';
import {
  ActiveHubsWithColorContext,
  ActiveStoragePeriodContext,
  GrainBinDataProvider,
  HubWithColorCode,
  WeatherDataProvider,
} from '../../../contexts';
import { DialogSpinner } from '../../spinner';

import {
  ContainerTypeLegacy,
  estimateAndFormatBushels,
  formatGrainType,
  RelativePeriods,
} from '../../../util';
import { NextAerationCard } from '../aeration';
import { FanStatusValues } from '../aeration/FanControls';
import {
  DailyForecastTable,
  presentViewFromRanges,
  produceDryingPeriods,
  produceReconditioningPeriods,
  RunWindowKind,
} from '../daily-forecast';
import { RecommendedScheduledRange } from '../daily-forecast/RecommendationScheduleBar';
import GrainBinBanners from '../GrainBinBanners';
import { RecommendedOption } from '../RecommendationOptionValue';
import { SelectContainerPlaceholder } from '../SelectContainerPlaceholder';
import { LINE_COLORS } from '../summary-card/charts/LevelHistoryPlotHelpers';
import { CO2Analysis } from './CO2Analysis';
import ScheduleContainer from './ScheduleContainer';
import { WeeklyGrainLevelChart } from './WeeklyGrainLevelChart';

type WeeklyGrainDashboardAnalysisProps = {
  grain_bin_id?: number | null | undefined;
  account_id: number;
  mobile_width?: boolean;
  pathname: string;
  viewer: ViewerFragmentFragment;
  url_base: string;
  routeParams: { user_id: string; account_id: string; grain_bin_id: string };
};
const SYS_USER_USED_FOR_WEEKLY_NEWS_LETTER = [71, 248]; // dev-71 prod-248
const useStyles = makeStyles({
  root: { width: 990, backgroundColor: '#fff', border: '4px solid lightgray', margin: 10 },
  full_width: { width: '100%' },
  min_height: { minHeight: 419 },
  forecast: {
    overflowX: 'auto',
    display: 'flex',
    marginTop: 15,
    marginLeft: 0,
    marginRight: 0,
    justifyContent: 'center',
  },
  daily_forecast_table_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  icon: {
    marginBottom: -5,
    width: 24,
    height: 24,
  },
  fan_status: {
    fontSize: 25,
  },
  inp_grain_conditions: {
    marginBottom: 15,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 14,
  },
  grain_conditions_table: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    '& caption': {
      padding: 5,
      fontWeight: 600,
    },
    '& tr': {
      '& td': {
        border: '1px solid black',
        padding: 4,
      },
      '& th': {
        border: '1px solid black',
        padding: 4,
      },
    },
  },
  container_section: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
  },
  section_heading: {
    textDecoration: 'underline',
    fontWeight: 600,
  },
});

export const WeeklyGrainDashboardAnalysis = ({
  grain_bin_id,
  mobile_width,
  viewer,
  routeParams,
  account_id,
  url_base,
}: WeeklyGrainDashboardAnalysisProps) => {
  const ctx = useContext(ActiveStoragePeriodContext);
  const activeStoragePeriod = ctx.activeStoragePeriod;

  return (
    <>
      {grain_bin_id && account_id ? (
        <WeeklyGrainDashboardAnalysisInner
          viewer={viewer}
          grain_bin_id={grain_bin_id}
          container_id={grain_bin_id}
          account_id={account_id}
          mobile_width={mobile_width}
          routeParams={routeParams}
          grain_bin_storage_cycle_id={
            activeStoragePeriod ? activeStoragePeriod.grain_bin_storage_cycle_id : undefined
          }
          url_base={url_base}
        />
      ) : (
        <SelectContainerPlaceholder />
      )}
    </>
  );
};

type WeeklyGrainDashboardAnalysisInnerProps = Pick<GetGrainBinContainerQuery, 'grain_container'> &
  WithGetGrainBinFanSettingsHocChildProps &
  WithGetGrainBinStoragePeriodHocChildProps &
  WithGetGrainBinWeeklyLevelDataHocChildProps &
  WithGetGrainContainerMonthlyCO2HistoryWithAerationsHocChildProps &
  WithGetPromptFanGuidanceExtensionHocChildProps &
  WithGetGrainContainerWeeklyCO2HistoryWithAerationsHocChildProps &
  WithGetShowCo2LevelWarningHocChildProps &
  WithGetShowGrainTicketLevelDeviationWarningHocChildProps & {
    grain_bin: GrainBinFragmentFragment;
    account: AccountFragmentFragment;
    loading?: boolean;
    viewer: ViewerFragmentFragment;
    mobile_width?: boolean;
    routeParams: { user_id: string; account_id: string; grain_bin_id: string };
    url_base: string;
  };

const getUniformCtrlStatus = ({ grain_bin_fan_controllers }) => {
  let uniform_ctrl_status: FanControllerStateValueNext | null | undefined;
  let uniform_is_on: boolean | null | undefined;
  if (grain_bin_fan_controllers.length > 1) {
    uniform_ctrl_status = grain_bin_fan_controllers.reduce((acc, ctrl) => {
      if (acc === null) {
        return null;
      }
      if (acc === undefined && ctrl.fan_controller.state_next) {
        return ctrl.fan_controller.state_next.value_next;
      }
      if (ctrl.fan_controller.state_next && acc === ctrl.fan_controller.state_next.value_next) {
        return acc;
      }
      return null;
    }, undefined);

    uniform_is_on = grain_bin_fan_controllers.reduce((acc, ctrl) => {
      if (acc === null) {
        return null;
      }
      if (acc === undefined && ctrl.fan_controller.state_next) {
        return ctrl.fan_controller.state_next.is_on;
      }
      if (ctrl.fan_controller.state_next && acc === ctrl.fan_controller.state_next.is_on) {
        return acc;
      }
      return null;
    }, undefined);
  } else if (
    grain_bin_fan_controllers.length === 1 &&
    grain_bin_fan_controllers[0].fan_controller.state_next
  ) {
    uniform_ctrl_status = grain_bin_fan_controllers[0].fan_controller.state_next.value_next;
    uniform_is_on = grain_bin_fan_controllers[0].fan_controller.state_next.is_on;
  }

  return { uniform_ctrl_status, uniform_is_on };
};

const WeeklyGrainDashboardAnalysisInner = withGetGrainBinStoragePeriodHoc(
  withGetAccountHoc(
    withGetGrainBinHoc(
      withGetGrainBinContainerHoc(
        withGetGrainBinFanSettingsHoc(
          withGetGrainBinWeeklyLevelDataHoc(
            withGetGrainContainerWeeklyCO2HistoryWithAerationsHoc(
              withGetGrainContainerMonthlyCO2HistoryWithAerationsHoc(
                withGetPromptFanGuidanceExtensionHoc(
                  withGetShowCo2LevelWarningHoc(
                    withGetShowGrainTicketLevelDeviationWarningHoc(
                      ({
                        account: targetAccount,
                        grain_bin,
                        grain_container,
                        loading = false,
                        viewer,
                        routeParams,
                        grain_bin_fan_settings,
                        mobile_width,
                        grain_bin_storage_periods: { grain_bin_storage_periods },
                        grain_container_level_data,
                        grain_container_monthly_headspace_data,
                        grain_container_weekly_headspace_data,
                        prompt_fan_guidance_extension,
                        show_co2_level_warning,
                        show_co2_level_warning_loading,
                        show_grain_ticket_level_deviation_warning,
                        get_show_ticket_warning_loading,
                        url_base,
                      }: WeeklyGrainDashboardAnalysisInnerProps) => {
                        console.log('WEEKLY_GRAIN_DASHBOARD_ANALYSIS_INFO', {
                          targetAccount,
                          grain_bin,
                          grain_container,
                          grain_bin_storage_periods,
                          grain_container_level_data,
                          grain_container_monthly_headspace_data,
                          grain_container_weekly_headspace_data,
                        });
                        const dispatch = useDispatch();
                        const classes = useStyles();
                        const [
                          show_fan_guidance_ext_prompt,
                          setShowFanGuidanceExtPrompt,
                        ] = useState(false);
                        const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        const activeStoragePeriodContext = useContext(ActiveStoragePeriodContext);
                        const activeStoragePeriod = activeStoragePeriodContext.activeStoragePeriod;
                        const activeHubsWithColorContext = useContext(ActiveHubsWithColorContext);
                        const { account_id, user_id } = routeParams;
                        const hideExpCharts = SYS_USER_USED_FOR_WEEKLY_NEWS_LETTER.includes(
                          Number(user_id)
                        );
                        const ongoing_period = grain_bin_storage_periods.find(
                          (storagePeriod) => storagePeriod.is_ongoing_storage_period === true
                        );

                        // required condtion to get data on dashboard even if Grain bin has future storage period
                        const has_ongoing_period =
                          ongoing_period !== undefined &&
                          ongoing_period.start_epoch.getTime() <= new Date().getTime();

                        const handleActiveStoragePeriodSet = () => {
                          if (grain_bin_storage_periods.length === 0 || !has_ongoing_period) {
                            activeStoragePeriodContext.setActiveStoragePeriod(null);
                          } else if (ongoing_period) {
                            console.log('Setting storage period to on-going...', ongoing_period);
                            activeStoragePeriodContext.setActiveStoragePeriod({
                              grain_bin_id: ongoing_period.grain_bin_id,
                              is_ongoing: true,
                              grain_bin_storage_cycle_id: ongoing_period.grain_bin_storage_cycle_id,
                              storage_cycle_name: ongoing_period.storage_cycle_name,
                              order: 0,
                              start_date: ongoing_period.start_epoch,
                              end_date: ongoing_period.end_epoch,
                            });
                          }
                        };

                        const next_aeration = useMemo(() => {
                          return grain_bin ? grain_bin.container.aeration_schedule[0] : null;
                        }, [grain_bin]);

                        const fan_statuses = useMemo(() => {
                          const grain_bin_fan_controllers = grain_bin_fan_settings
                            ? grain_bin_fan_settings.grain_bin.fan_controllers
                            : [];

                          const fan_statuses: FanStatusValues[] = grain_bin_fan_controllers.map(
                            (ctrl, ix) => {
                              const status =
                                ctrl && ctrl.fan_controller && ctrl.fan_controller.state_next
                                  ? ctrl.fan_controller.state_next.value_next
                                  : FanControllerStateValueNext.Offline;
                              return {
                                status,
                                id: ctrl.fan_controller_id_next,
                                fan_name: ctrl.alias || `Fan ${ix}`,
                              };
                            }
                          );

                          return fan_statuses;
                        }, [grain_bin_fan_settings]);

                        useEffect(() => {
                          if (!loading) {
                            handleActiveStoragePeriodSet();
                          }
                        }, [loading]);

                        useEffect(() => {
                          dispatch(
                            // Set user browser's local timezone
                            setUserTimeZone({
                              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                              offset: -new Date().getTimezoneOffset(),
                            })
                          );
                        }, []);

                        useEffect(() => {
                          if (!loading) {
                            if (
                              grain_container &&
                              grain_container.active_hub_links_including_fc_plenum_hubs &&
                              grain_container.active_hub_links_including_fc_plenum_hubs.length > 0
                            ) {
                              const new_active_hub_links: HubWithColorCode[] = grain_container.active_hub_links_including_fc_plenum_hubs.map(
                                (hub, idx) => ({
                                  order: idx,
                                  color: LINE_COLORS[idx],
                                  ...hub,
                                })
                              );
                              activeHubsWithColorContext.setActiveHubsWithColor(
                                new_active_hub_links
                              );
                            } else {
                              activeHubsWithColorContext.setActiveHubsWithColor(null);
                            }
                          }
                        }, [loading, grain_container]);

                        useEffect(() => {
                          setShowFanGuidanceExtPrompt(prompt_fan_guidance_extension);
                        }, [prompt_fan_guidance_extension]);

                        useEffect(() => {
                          if (!show_co2_level_warning_loading) {
                            dispatch(
                              setShowCO2LevelWarningBanner(
                                show_co2_level_warning
                                  ? {
                                      co2AlertSentOn: show_co2_level_warning.co2_alert_sent_on,
                                      co2AlertType: show_co2_level_warning.co2_alert_type,
                                    }
                                  : null
                              )
                            );
                          }
                        }, [show_co2_level_warning, show_co2_level_warning_loading]);

                        useEffect(() => {
                          if (grain_bin && !get_show_ticket_warning_loading) {
                            dispatch(
                              setShowGrainTicketWarningBanner({
                                showGrainTicketWarningBanner: show_grain_ticket_level_deviation_warning,
                              })
                            );
                          }
                        }, [
                          show_grain_ticket_level_deviation_warning,
                          get_show_ticket_warning_loading,
                          grain_bin,
                        ]);

                        // Setting grain bin storage timezone
                        if (grain_bin) {
                          Settings.defaultZone = grain_bin.tz;
                          Settings.defaultLocale = 'en-US';
                        }

                        //
                        // If the bin doesn't match the storage cycle then set the active one to be undefined.
                        // This appears to reinitialize the storage period context, and base it off the current bin.
                        //
                        if (
                          grain_bin &&
                          activeStoragePeriod &&
                          activeStoragePeriod.grain_bin_id !== grain_bin.grain_bin_id
                        ) {
                          activeStoragePeriodContext.setActiveStoragePeriod(undefined);
                        }

                        if (loading) {
                          return <DialogSpinner title="Loading..." open={loading} />;
                        }

                        if (
                          !viewer ||
                          !grain_bin ||
                          !grain_container ||
                          !targetAccount ||
                          !grain_container_level_data
                        ) {
                          return null;
                        }

                        const {
                          container_id,
                          container_type,
                          telemetry: { weather_forecast = null },
                          active_hub_links_including_fc_plenum_hubs,
                        } = grain_container;
                        const weatherData = weather_forecast
                          ? {
                              hourlyForecast: weather_forecast.hourly_forecast,
                              dailyForecast: weather_forecast.daily_forecast as ForecastDayV2FragmentFragment[],
                              grain_bin_location_timezone: grain_bin.tz
                                ? grain_bin.tz
                                : browserTimezone,
                            }
                          : undefined;
                        const {
                          grain_bin_level_history,
                          grain_bin_tickets_level_history,
                        } = grain_container_level_data;

                        const { grain_type, diameter_ft, height_ft, tz } = grain_bin;
                        const bu =
                          diameter_ft && height_ft
                            ? estimateAndFormatBushels(diameter_ft, height_ft)
                            : null;

                        const recommendation_windows: RecommendationWindowsFragmentFragment | null =
                          grain_container.telemetry.recommendation_windows || null;

                        const { organization } = targetAccount;

                        const shouldShowFanRunCard =
                          activeStoragePeriod === null ||
                          activeStoragePeriod === undefined ||
                          activeStoragePeriod.is_ongoing;

                        const hourlyForecast = weather_forecast
                          ? weather_forecast.hourly_forecast
                          : null;
                        const dailyForecast = weather_forecast
                          ? (weather_forecast.daily_forecast as ForecastDayV2FragmentFragment[])
                          : null;

                        if (container_type !== ContainerTypeLegacy.bin) {
                          return null;
                        }

                        if (grain_bin && grain_bin.account_id !== Number(account_id)) {
                          return (
                            <h1>
                              Sorry, provided account id does not match with Grain Bin's account id.{' '}
                            </h1>
                          );
                        }

                        if (viewer && viewer.user_id !== Number(user_id)) {
                          return <h1>Sorry, provided user id does not match with your user id.</h1>;
                        }

                        const dryingPeriodsToPresent: RecommendedScheduledRange[] = produceDryingPeriods(
                          recommendation_windows
                        );
                        const reconditioningPeriodsToPresent: RecommendedScheduledRange[] = produceReconditioningPeriods(
                          recommendation_windows
                        );

                        const scheduledRunsToPresent = presentViewFromRanges(
                          (grain_bin.container.aeration_schedule || []).map((aeration) => {
                            const start = aeration.start_epoch.getTime();
                            const end = aeration.end_epoch.getTime();
                            return {
                              // @ts-ignore
                              start: start as number,
                              end: end as number,
                              type: RunWindowKind.Scheduled,
                            };
                          })
                        );

                        let coolingPeriodsToPresent: RecommendedScheduledRange[] = [];
                        if (
                          recommendation_windows == null ||
                          recommendation_windows.temp_ranges == null
                        ) {
                          console.error(
                            'Failed to read psychometric temp data, got null or undefined',
                            recommendation_windows
                          );
                        } else {
                          coolingPeriodsToPresent = presentViewFromRanges(
                            recommendation_windows.temp_ranges.map((value) => {
                              return {
                                // @ts-ignore
                                start: value.start as number,
                                // @ts-ignore
                                end: value.end as number,
                                type: RunWindowKind.Cooling,
                              };
                            })
                          );
                        }

                        const recommendationSchedule: RecommendedScheduledRange[] = grain_bin.recommendation_type
                          ? {
                              [RecommendedOption.DRYING]: dryingPeriodsToPresent,
                              [RecommendedOption.COOLING]: coolingPeriodsToPresent,
                              [RecommendedOption.RECONDITIONING]: reconditioningPeriodsToPresent,
                            }[grain_bin.recommendation_type] || []
                          : [];

                        const has_fan_controller =
                          grain_bin_fan_settings &&
                          grain_bin_fan_settings.grain_bin.fan_controllers.length > 0;

                        const has_override = Boolean(
                          grain_bin_fan_settings &&
                            grain_bin_fan_settings.grain_bin.fan_controllers.some(
                              (ctrl) =>
                                ctrl.fan_controller &&
                                ctrl.fan_controller.state_next &&
                                ctrl.fan_controller.state_next.is_override
                            )
                        );

                        const grain_bin_fan_controllers = grain_bin_fan_settings
                          ? grain_bin_fan_settings.grain_bin.fan_controllers
                          : [];

                        const any_fans_running = grain_bin_fan_controllers.some(
                          (ctrl) =>
                            ctrl.fan_controller.state_next && ctrl.fan_controller.state_next.is_on
                        );
                        const hasManualMode = any_fans_running && has_override;

                        const any_disabled = grain_bin_fan_controllers.some(
                          (ctrl) =>
                            ctrl.fan_controller.state_next &&
                            !ctrl.fan_controller.state_next.config.enabled
                        );
                        const {
                          uniform_ctrl_status,
                          uniform_is_on,
                        }: {
                          uniform_ctrl_status: FanControllerStateValueNext | null | undefined;
                          uniform_is_on: boolean | null | undefined;
                        } = getUniformCtrlStatus({
                          grain_bin_fan_controllers,
                        });

                        const any_offline = grain_bin_fan_controllers.some(
                          (fc) =>
                            fc.fan_controller &&
                            fc.fan_controller.state_next &&
                            fc.fan_controller.state_next.value_next ===
                              FanControllerStateValueNext.Offline
                        );

                        return (
                          <GrainBinDataProvider grainBin={grain_bin}>
                            <Grid
                              item
                              xs={12}
                              spacing={2}
                              container
                              direction="row"
                              alignItems="flex-start"
                              alignContent="flex-start"
                              className={classes.root}
                            >
                              {/* Grain Bin Info */}
                              <Grid style={{ margin: '30px 0px 15px 0px', width: '100%' }}>
                                <Typography variant="h5" style={{ fontSize: 28 }}>
                                  {grain_bin.bin_name}
                                </Typography>
                                <Typography variant="h6" style={{ fontSize: '1.1rem' }}>
                                  {formatGrainType(grain_type)}
                                  {bu ? ` | ${bu} bin` : ''} |{' '}
                                  <AccessTimeOutlined className={classes.icon} />{' '}
                                  {DateTime.local().offsetNameShort}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <GrainBinBanners url_base={url_base} />
                              </Grid>
                              {has_fan_controller &&
                                uniform_ctrl_status &&
                                uniform_is_on !== null &&
                                uniform_is_on !== undefined &&
                                ((activeStoragePeriod && activeStoragePeriod.is_ongoing) ||
                                  !activeStoragePeriod) &&
                                !any_offline && (
                                  <NextAerationCard
                                    fan_count={grain_bin_fan_controllers.length}
                                    fan_running={uniform_is_on}
                                    run_window={next_aeration}
                                    override={has_override}
                                    any_disabled={any_disabled}
                                    fan_statuses={fan_statuses}
                                  />
                                )}

                              {/* Fan Run & Recommended Schedule */}
                              {!loading && grain_bin_fan_settings && (
                                <Grid
                                  container
                                  className={classes.container_section}
                                  style={{ marginTop: 0 }}
                                >
                                  <Grid item>
                                    <WeatherDataProvider weatherData={weatherData}>
                                      <ScheduleContainer
                                        scheduledRunsToPresent={scheduledRunsToPresent}
                                        recommendationSchedule={recommendationSchedule}
                                        grain_bin_fan_settings={grain_bin_fan_settings}
                                        showScheduleCaptureTime={false}
                                        hasManualMode={hasManualMode}
                                        show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
                                        container_id={container_id}
                                        container_type={container_type}
                                      />
                                    </WeatherDataProvider>
                                  </Grid>
                                </Grid>
                              )}
                              {/* CO2 Chart 1M & 1W Views */}
                              {active_hub_links_including_fc_plenum_hubs.length > 0 && (
                                <Grid container className={classes.container_section}>
                                  <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant="h5" className={classes.section_heading}>
                                      CO2 Analysis
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <CO2Analysis
                                      container_id={container_id}
                                      minHeight={mobile_width ? undefined : 419}
                                      maxHeight={mobile_width ? undefined : 530}
                                      width={430}
                                      grain_bin_location_timezone={
                                        grain_bin.tz ? grain_bin.tz : browserTimezone
                                      }
                                      grain_container_monthly_headspace_data={
                                        grain_container_monthly_headspace_data
                                      }
                                      grain_container_weekly_headspace_data={
                                        grain_container_weekly_headspace_data
                                      }
                                      loading={loading}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                              {/* Weather Forecast at Site */}
                              <Grid
                                className={classes.forecast}
                                container
                                direction={'column'}
                                spacing={3}
                              >
                                <Grid>
                                  <Typography
                                    variant="h5"
                                    style={{ textAlign: 'center' }}
                                    className={classes.section_heading}
                                  >
                                    Weather Forecast at Site
                                  </Typography>
                                </Grid>
                                <Grid item className={classes.daily_forecast_table_container}>
                                  {weather_forecast &&
                                    recommendation_windows &&
                                    shouldShowFanRunCard && (
                                      <Grid item xs={12}>
                                        {recommendation_windows &&
                                        shouldShowFanRunCard &&
                                        dailyForecast &&
                                        dailyForecast.length > 0 &&
                                        hourlyForecast ? (
                                          <DailyForecastTable
                                            dailyForecast={dailyForecast}
                                            hourlyForecast={hourlyForecast}
                                            recommendation_windows_data={recommendation_windows}
                                            aeration_schedule={
                                              grain_bin.container.aeration_schedule
                                            }
                                            grain_bin_location_timezone={
                                              grain_bin.tz ? grain_bin.tz : browserTimezone
                                            }
                                            recommendation_type={
                                              grain_bin.recommendation_type
                                                ? grain_bin.recommendation_type
                                                : null
                                            }
                                            has_enable_fan_guidance={
                                              grain_bin.enable_fan_guidance || false
                                            }
                                            fan_guidance_start_date={
                                              grain_bin.fan_guidance_start_date || null
                                            }
                                            fan_guidance_end_date={
                                              grain_bin.fan_guidance_end_date || null
                                            }
                                            container_type={ContainerTypeLegacy.bin}
                                            hideExpCharts={hideExpCharts}
                                            goPrevWeek={() => {}}
                                            goNextWeek={() => {}}
                                            prevWeekNo={0}
                                            goToPresentView={() => {}}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                    )}
                                </Grid>
                              </Grid>
                              {/* Grain Level Chart for 1W */}

                              {activeStoragePeriodContext.activeStoragePeriod !== undefined && (
                                <Grid
                                  container
                                  className={classes.container_section}
                                  style={{ alignItems: 'center' }}
                                >
                                  <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant="h5" className={classes.section_heading}>
                                      Grain Level Analysis
                                    </Typography>
                                  </Grid>
                                  <Grid item style={{ width: 500, alignItems: 'center' }}>
                                    <WeeklyGrainLevelChart
                                      height={150}
                                      grain_bin_location_timezone={
                                        grain_bin.tz ? grain_bin.tz : browserTimezone
                                      }
                                      minHeight={mobile_width ? undefined : 419}
                                      maxHeight={mobile_width ? undefined : 530}
                                      period={RelativePeriods.week}
                                      loading={loading}
                                      grain_bin_tickets_level_history={
                                        grain_bin_tickets_level_history
                                      }
                                      grain_bin_level_history={grain_bin_level_history}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </GrainBinDataProvider>
                        );
                      }
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )
);

export default WeeklyGrainDashboardAnalysis;
