import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { RecommRunWindowsExperiments } from '../../../api';
import { amber_alternate_grey } from '../../../style';
import { RecommendedOption } from '../RecommendationOptionValue';
import { BoundValuesForm } from './BoundValuesForm';
import { ExperimentRunWindowsComp, ExperimentRunWindowsForm } from './ExperimentRunWindowsComp';

const useStyles = makeStyles((theme: Theme) => ({
  experiment_run_windows_container: {
    marginTop: '10px',
    border: '1px solid red',
    paddingTop: '5px',
    borderRadius: 5,
  },
}));

type ExperimentsChartsProps = {
  recommendation_windows_for_experiments?: RecommRunWindowsExperiments[];
  grain_bin_location_timezone: string;
  recommendation_type: string | null;
  has_enable_fan_guidance: boolean;
  fan_guidance_start_date: Date | null;
  fan_guidance_end_date: Date | null;
  refetch_grain_bin_container?: () => void;
  refetch_experiment_run_windows?: (variables) => void;
  setCfmValues?: (cfm_values) => void;
  syncronizeCrossHairs: any;
  minDate: any;
  maxDate: any;
  setExperimentChart: (chart: any, index: number) => void;
  experimentsRunWindowsFormValues?: {
    cfm_scaling: number | undefined;
    cfm_offset: number | undefined;
    cfm_min: number | undefined;
    cfm_max: number | undefined;
    cfm: number | undefined;
  };
  expExpandedStates?: any;
  setExpExpandedStates?: React.Dispatch<React.SetStateAction<any>>;
  setBoundValues?: (bound_values) => void;
  boundFormValues?: {
    lower_bound: number | undefined;
    upper_bound: number | undefined;
  };
};

export const ExperimentsCharts = ({
  recommendation_type,
  recommendation_windows_for_experiments,
  syncronizeCrossHairs,
  minDate,
  maxDate,
  has_enable_fan_guidance,
  fan_guidance_end_date,
  fan_guidance_start_date,
  refetch_grain_bin_container,
  refetch_experiment_run_windows,
  setCfmValues,
  setExperimentChart,
  grain_bin_location_timezone,
  experimentsRunWindowsFormValues,
  expExpandedStates,
  setExpExpandedStates,
  setBoundValues,
  boundFormValues,
}: ExperimentsChartsProps) => {
  const classes = useStyles();
  const initialExpandedStates = recommendation_windows_for_experiments
    ? expExpandedStates
      ? expExpandedStates
      : recommendation_windows_for_experiments.reduce((acc, { experiment }) => {
          acc[experiment] = false;
          return acc;
        }, {})
    : {};
  const [expandedStates, setExpandedStates] = useState<any>(initialExpandedStates);

  const handleAccordionChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpandedStates((prev) => ({ ...prev, [panel]: isExpanded }));
    setExpExpandedStates && setExpExpandedStates((prev) => ({ ...prev, [panel]: isExpanded }));
  };

  console.log('recommendation_windows_for_experiments', recommendation_windows_for_experiments);
  return (
    <>
      <Grid item style={{ padding: '0 0.5rem', width: '100%' }}>
        <Typography variant="h5">Experimental Run Windows</Typography>
      </Grid>
      {recommendation_windows_for_experiments &&
        recommendation_windows_for_experiments.map(
          (recommendation_windows_for_experiment: RecommRunWindowsExperiments, index) => {
            const { name, experiment, hasCfmPresent } = recommendation_windows_for_experiment;
            // TODO: introduce default flag for this in recommendation_windows_for_experiments
            const isDefaultExperiment = experiment.includes('default');
            return (
              <Grid
                key={`${experiment}-${index}`}
                item
                className={classes.experiment_run_windows_container}
              >
                <Accordion
                  style={{ width: '100%' }}
                  expanded={expandedStates[experiment]}
                  onChange={handleAccordionChange(experiment)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={{ fontWeight: 600, fontSize: 16 }}>{name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0, width: '100%' }}>
                    <div style={{ width: '100%' }}>
                      {!isDefaultExperiment && !hasCfmPresent ? (
                        <Typography variant="h6">
                          Sorry! CFM value is not present to produce run windows
                        </Typography>
                      ) : (
                        <ExperimentRunWindowsComp
                          syncronizeCrossHairs={syncronizeCrossHairs}
                          setRecommendationChart={(chart) => setExperimentChart(chart, index)}
                          minDate={minDate}
                          maxDate={maxDate}
                          has_enable_fan_guidance={has_enable_fan_guidance}
                          fan_guidance_start_date={fan_guidance_start_date}
                          fan_guidance_end_date={fan_guidance_end_date}
                          recommendation_type={recommendation_type}
                          recommendation_windows_for_experiment={
                            recommendation_windows_for_experiment
                          }
                          grain_bin_location_timezone={grain_bin_location_timezone}
                        />
                      )}

                      {isDefaultExperiment ? (
                        <BoundValuesForm
                          refetch_grain_bin_container={refetch_grain_bin_container}
                          refetch_experiment_run_windows={refetch_experiment_run_windows}
                          recommendation_type={recommendation_type}
                          hasExperimentExpanded={expandedStates[experiment]}
                          setBoundValues={setBoundValues}
                          boundFormValues={boundFormValues}
                        />
                      ) : (
                        <ExperimentRunWindowsForm
                          refetch_grain_bin_container={refetch_grain_bin_container}
                          refetch_experiment_run_windows={refetch_experiment_run_windows}
                          recommendation_type={recommendation_type}
                          setCfmValues={setCfmValues}
                          experimentsRunWindowsFormValues={experimentsRunWindowsFormValues}
                          hasExperimentExpanded={expandedStates[experiment]}
                        />
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          }
        )}
    </>
  );
};
