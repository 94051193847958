import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy, RelativePeriods } from '../../../util';
import {
  GrainBinTicketsLevelHistory,
  withGetGrainContainerHistoryGrainTicketsLevel as HOC,
} from '../__generated';

export type WithGetGrainContainerHistoryGrainTicketsLevelHocOwnProps = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  as_of: Date;
  period: RelativePeriods;
  grain_bin_storage_cycle_id?: number;
  pollInterval?: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainContainerHistoryGrainTicketsLevelHocChildProps = {
  is_bin_tickets_history_loading: boolean;
  grain_bin_tickets_level_history: GrainBinTicketsLevelHistory;
};
export const withGetGrainContainerHistoryGrainTicketsLevelHoc: AmberHoc<
  WithGetGrainContainerHistoryGrainTicketsLevelHocOwnProps,
  WithGetGrainContainerHistoryGrainTicketsLevelHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainContainerHistoryGrainTicketsLevelHocOwnProps,
      WithGetGrainContainerHistoryGrainTicketsLevelHocChildProps,
      typeof component
    >,
    WithGetGrainContainerHistoryGrainTicketsLevelHocChildProps
  >({
    options: ({
      container_id,
      container_type,
      as_of,
      period,
      grain_bin_storage_cycle_id,
      onError,
      pollInterval = 1000 * 60 * 30,
    }) => ({
      onError,
      pollInterval,
      errorPolicy: onError ? 'all' : 'none',
      fetchPolicy: 'network-only',
      variables: {
        container_id,
        container_type,
        as_of,
        period,
        grain_bin_storage_cycle_id,
      },
      notifyOnNetworkStatusChange: true,
    }),
    props: ({ data, ownProps: { loading } }) => {
      let grain_bin_tickets_level_history: GrainBinTicketsLevelHistory = {
        period: '1D',
        no_grain_tickets_created: true,
        grain_tickets_bushel_level_on_period_start: null,
        grain_tickets_bushel_level_on_period_end: null,
        grain_tickets_level_samples: [],
        __typename: 'GrainBinTicketsLevelHistory',
      };

      if (!data || data.loading) {
        return {
          grain_bin_tickets_level_history,
          is_bin_tickets_history_loading: true,
        };
      }

      const { grain_container } = data;
      if (grain_container) {
        ({ grain_bin_tickets_level_history } = grain_container);
      }

      return {
        grain_bin_tickets_level_history,
        is_bin_tickets_history_loading: loading || false,
      };
    },
  })(component as any);
