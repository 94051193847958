import { Button, DialogContent, Grid, makeStyles, Theme } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import React from 'react';
import { amber_error_red, amber_red, black, white } from '../../../../../core/src/style';
import { SimpleDialog } from '../../util/SimpleDialog';

const useStyles = makeStyles((theme: Theme) => ({
  editScheduledRunBtn: {
    width: 250,
    color: black,
    height: 39,
    boxShadow: '0px 0px 6px #00000029',
    border: '1px solid #707070',
    borderRadius: 6,
    textTransform: 'capitalize',
    fontSize: 20,
  },
  dialogContent: {
    padding: '36px 26px',
  },
}));

export const CancelScheduleDialog: React.FunctionComponent<{
  open: boolean;
  onClickClose: () => void;
  cancelNextRun: () => void;
  cancelAllRuns: () => void;
  has_current_overlap_runwindow: boolean;
}> = ({ open, onClickClose, cancelNextRun, cancelAllRuns, has_current_overlap_runwindow }) => {
  const classes = useStyles();

  return (
    <SimpleDialog open={open} handleClose={onClickClose}>
      <DialogContent className={classes.dialogContent}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <Button
              variant="outlined"
              className={classes.editScheduledRunBtn}
              onClick={() => {
                cancelNextRun();
              }}
            >
              <Cancel style={{ color: amber_red, marginRight: 10, width: 20, height: 20 }} />
              {has_current_overlap_runwindow ? 'Cancel This Run' : 'Cancel Next Run'}
            </Button>

            <div>
              <p style={{ fontSize: 20, margin: 15 }}>or</p>
            </div>
            <Button
              variant="outlined"
              className={classes.editScheduledRunBtn}
              style={{ backgroundColor: amber_error_red, color: white }}
              onClick={() => cancelAllRuns()}
            >
              <Cancel style={{ color: white, marginRight: 10, width: 20, height: 20 }} />
              Cancel All Runs
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </SimpleDialog>
  );
};
