export enum GrainBinRoutesSuffix {
  create = 'create',
  aeration_schedule = 'aeration_schedule',
  dashboard = 'dashboard',
  devices = 'devices',
  events = 'events',
  export_telemetry = 'export_telemetry',
  settings = 'settings',
  storage_periods = 'storage_periods',
  weather_forecast = 'weather_forecast',
  dashboard_analysis = 'dashboard_analysis',
  tickets = 'tickets',
}

export enum BargeRouteSuffix {
  cover = 'cover',
  covers = 'covers',
  create = 'create',
  dashboard = 'dashboard',
  devices = 'devices',
  export_telemetry = 'export_telemetry',
  settings = 'settings',
  storage_periods = 'storage_periods',
}

export enum PileRouteSuffix {
  cable = 'cable',
  cables = 'cables',
  create = 'create',
  dashboard = 'dashboard',
  devices = 'devices',
  export_telemetry = 'export_telemetry',
  settings = 'settings',
  storage_periods = 'storage_periods',
}
