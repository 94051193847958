import { Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import React, { useMemo, useState } from 'react';
import {
  VictoryAxis,
  VictoryChart,
  VictoryCursorContainer,
  VictoryScatter,
  VictoryTooltip,
} from 'victory';

import { Orientation, PileCableLinkFragmentFragment } from '../../../api';
import {
  amber_amber,
  amber_dark_grey,
  amber_grey,
  amber_light_grey,
  getColorForTemp,
  temp_gradient_colors,
  TempGradientColor,
  victory_theme,
} from '../../../style';
import { formatNumber } from '../../../util';
import { Cable } from './PileCablesTable';

const formatTemp = (temp_f) =>
  temp_f === null || isNaN(temp_f) ? 'N/A' : `${formatNumber(temp_f)}\u00b0`;

const PileShadow = ({
  pile_area_y_offset_px,
  pile_area_x_offset_px,
  pile_area_y_px,
  pile_area_x_px,
}) => (
  <g>
    <rect
      y={pile_area_y_offset_px}
      x={pile_area_x_offset_px}
      height={pile_area_y_px}
      width={pile_area_x_px}
      rx="15%"
      style={{ fill: fade(amber_amber, 0.3) }}
    />
  </g>
);

const ColorLegendItem = ({
  temp_gradient_color,
  ix,
}: {
  temp_gradient_color: TempGradientColor;
  ix: number;
}) => (
  <div
    key={ix}
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
  >
    <div
      style={{ height: 10, width: 20, backgroundColor: temp_gradient_color.color, marginRight: 5 }}
    />
    <Typography>{temp_gradient_color.temp_limit_text}</Typography>
  </div>
);

const ColorLegend = () => (
  <div>
    {temp_gradient_colors.map((color, ix) => (
      <ColorLegendItem temp_gradient_color={color} ix={ix} />
    ))}
    <ColorLegendItem
      temp_gradient_color={{ color: amber_grey, temp_lower_limit: NaN, temp_limit_text: 'N/A' }}
      ix={temp_gradient_colors.length}
    />
  </div>
);

export const PileLocationViewer = ({
  length,
  diameter,
  pile_cables,
  orientation,
  landscape,
  selected_cable_id,
  selected_x_ft,
  selected_y_ft,
  setSelectedCable,
  onLocationSelect,
}: {
  length: number;
  diameter: number;
  pile_cables: Cable[] | PileCableLinkFragmentFragment[];
  orientation: Orientation;
  // if landscape, length = x and width = y
  landscape: Boolean;
  selected_cable_id?: number | null;
  selected_x_ft?: number;
  selected_y_ft?: number;
  setSelectedCable?: (item: Cable | null) => void;
  onLocationSelect?: (coords: { x_ft: number; y_ft: number }) => void;
}) => {
  const [coordinates, setCoordinates] = useState<{ x: number; y: number } | null>(null);
  const selected_coords = useMemo(() => {
    if (
      selected_y_ft === undefined ||
      selected_x_ft === undefined ||
      isNaN(selected_y_ft) ||
      isNaN(selected_x_ft)
    ) {
      return {};
    }
    return landscape
      ? { y: -Number(selected_x_ft), x: selected_y_ft, configured: true }
      : { x: selected_x_ft, y: selected_y_ft, configured: true };
  }, [selected_x_ft, selected_y_ft]);
  let pos_y_direction;
  let pos_x_direction;
  let neg_y_direction;
  let neg_x_direction;
  if (landscape) {
    switch (orientation) {
      case Orientation.NorthSouth: {
        pos_y_direction = 'W';
        pos_x_direction = 'N';
        neg_y_direction = 'E';
        neg_x_direction = 'S';
        break;
      }
      // case 'SN': {
      //   pos_y_direction = 'E';
      //   pos_x_direction = 'S';
      //   neg_y_direction = 'W';
      //   neg_x_direction = 'N';
      //   break;
      // }
      case Orientation.EastWest: {
        pos_y_direction = 'N';
        pos_x_direction = 'E';
        neg_y_direction = 'S';
        neg_x_direction = 'W';
        break;
      }
      // case 'WE': {
      //   pos_y_direction = 'S';
      //   pos_x_direction = 'W';
      //   neg_y_direction = 'N';
      //   neg_x_direction = 'E';
      //   break;
      // }
    }
  } else {
    switch (orientation) {
      case Orientation.NorthSouth: {
        pos_y_direction = 'N';
        pos_x_direction = 'E';
        neg_y_direction = 'S';
        neg_x_direction = 'W';
        break;
      }
      // case 'SN': {
      //   pos_y_direction = 'S';
      //   pos_x_direction = 'W';
      //   neg_y_direction = 'N';
      //   neg_x_direction = 'E';
      //   break;
      // }
      case Orientation.EastWest: {
        pos_y_direction = 'E';
        pos_x_direction = 'S';
        neg_y_direction = 'W';
        neg_x_direction = 'N';
        break;
      }
      // case 'WE': {
      //   pos_y_direction = 'W';
      //   pos_x_direction = 'N';
      //   neg_y_direction = 'E';
      //   neg_x_direction = 'S';
      //   break;
      // }
    }
  }

  const location_data = pile_cables
    ? (pile_cables as any[]).map((cable: PileCableLinkFragmentFragment | Cable) => {
        // x is always the lateral postion i.e. width of the pile
        // y is always the vertical position i.e. lenght of the pile
        if (landscape) {
          return {
            y: cable.x_ft && -cable.x_ft,
            x: cable.y_ft,
            datum: { cable, selected_cable_id },
          };
        }
        return {
          x: cable.x_ft,
          y: cable.y_ft,
          datum: { cable, selected_cable_id },
        };
      })
    : [];
  const styles = {
    chart_padding: { top: 10, bottom: 10, left: 10, right: 10 },
    axis1: {
      ticks: { stroke: 'transparent' },
    },
    axis2: {
      grid: { stroke: 'none' },
    },
  };

  // convert depending orientation and save to variables that hopefully make sense
  const y_var = landscape ? diameter : length;
  const x_var = landscape ? length : diameter;

  const y_x_ratio = y_var / x_var;
  const x_length_px = 400;
  // optimize height if landscape
  const y_length_px = landscape ? Math.min(400, Math.max(200, x_length_px * y_x_ratio)) : 400;

  const domain_padding = 50;
  const aspect_ratio_y = y_length_px / (y_var + 100);
  const aspect_ratio_x = x_length_px / (x_var + 100);

  // victory padding factor
  const victory_padding_px = 20;

  // length and width of pile vis
  const pile_area_y_px = y_var * aspect_ratio_y - victory_padding_px / 2;
  const pile_area_x_px = x_var * aspect_ratio_x - victory_padding_px / 2;

  const domain_y = y_var / 2 + domain_padding;
  const domain_x = x_var / 2 + domain_padding;

  // need domain px to calculate placement of the pile vis
  const domain_length_px = domain_y * aspect_ratio_y;
  const domain_width_px = domain_x * aspect_ratio_x;

  // offset is the dimesion of the rectangle then any adjustment for over/underflow
  // i.e. move the pile down by half the pile vis length
  // if the pile vis is the same size of the domain it will be in the center
  // if the pile vis is larger than the domain it will need to be moved back up half the distance of the difference in size
  // vice versa for pile vis smaller than domain--it will need to be moved down further
  let pile_area_y_offset_px = pile_area_y_px / 2;
  if (pile_area_y_px > domain_length_px) {
    pile_area_y_offset_px -= pile_area_y_px - domain_length_px;
  } else if (pile_area_y_px < domain_length_px) {
    pile_area_y_offset_px += domain_length_px - pile_area_y_px;
  }

  let pile_area_x_offset_px = pile_area_x_px / 2;
  if (pile_area_x_px > domain_width_px) {
    pile_area_x_offset_px -= pile_area_x_px - domain_width_px;
  } else if (pile_area_x_px < domain_width_px) {
    pile_area_x_offset_px += domain_width_px - pile_area_x_px;
  }

  // remove the negative symbol and add direction instead
  const y_axis_ticks = (t) =>
    t < 0 ? `${t.toString().substring(1)}' ${neg_y_direction}` : `${t}' ${pos_y_direction}`;

  const x_axis_ticks = (t) =>
    t < 0 ? `${t.toString().substring(1)}' ${neg_x_direction}` : `${t}' ${pos_x_direction}`;

  // viewing mode
  if (!onLocationSelect) {
    return (
      // <div
      //   style={{
      //     height: y_length_px + 75 + Number(!landscape || 0) * 30,
      //     width: x_length_px + 200 + Number(landscape || 0) * 30,
      //     backgroundColor: 'white',
      //     display: 'flex',
      //     justifyContent: 'space-between',
      //     alignItems: 'center',
      //     flexDirection: 'row',
      //   }}
      // >
      <>
        <div
          style={{
            height: y_length_px + 50 + Number(!landscape || 0) * 30,
            width: x_length_px + 100 + Number(landscape || 0) * 30,
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            flexDirection: landscape ? 'row' : 'column',
          }}
        >
          <Typography variant="h5">{landscape ? neg_x_direction : pos_y_direction}</Typography>
          {/* sides were a bit cut off so 5px got added to the width */}
          <svg viewBox={`0 0 ${x_length_px + 5} ${y_length_px}`} style={{ flex: 1 }}>
            <PileShadow
              pile_area_y_offset_px={pile_area_y_offset_px}
              pile_area_x_offset_px={pile_area_x_offset_px}
              pile_area_y_px={pile_area_y_px}
              pile_area_x_px={pile_area_x_px}
            />
            <VictoryChart
              height={y_length_px}
              width={x_length_px}
              theme={victory_theme}
              domain={{ y: [-domain_y, domain_y], x: [-domain_x, domain_x] }}
              standalone={false}
              padding={styles.chart_padding}
            >
              <VictoryAxis
                padding={50}
                style={styles.axis1}
                tickFormat={x_axis_ticks}
                standalone={false}
              />
              <VictoryAxis
                padding={{ top: 10, bottom: 30, left: 50, right: 50 }}
                dependentAxis
                crossAxis
                style={styles.axis2}
                tickFormat={y_axis_ticks}
                standalone={false}
              />
              <VictoryScatter
                size={3}
                labelComponent={
                  <VictoryTooltip
                    style={{ top: 15, fontSize: 14, color: amber_dark_grey, padding: 0, margin: 0 }}
                    renderInPortal={false}
                  />
                }
                labels={({ datum }) =>
                  `${datum.cable.package_alias} ${formatTemp(datum.cable.max)}`
                }
                style={
                  {
                    data: {
                      stroke: ({ datum }) =>
                        datum &&
                        datum.cable &&
                        selected_cable_id &&
                        selected_cable_id !== datum.cable.cable_id
                          ? amber_light_grey
                          : amber_dark_grey,
                      strokeWidth: 1,
                      fill: ({ datum }) =>
                        datum && datum.cable ? getColorForTemp(datum.cable.max) : amber_dark_grey,
                      fillOpacity: ({ datum }) =>
                        datum &&
                        datum.cable &&
                        selected_cable_id &&
                        selected_cable_id !== datum.cable.cable_id
                          ? 0.5
                          : 1,
                    },
                  } as any // the function styles are not included the the ts definition
                }
                data={location_data}
                standalone={false}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onClick: () => {
                        return [
                          {
                            target: 'data',
                            mutation: ({ datum }) => {
                              if (setSelectedCable && datum && datum.datum && datum.datum.cable) {
                                setSelectedCable(datum.datum.cable);
                              }
                              return null;
                            },
                          },
                        ];
                      },
                    },
                  },
                ]}
              />
            </VictoryChart>
          </svg>
          <Typography variant="h5">{landscape ? pos_x_direction : neg_y_direction}</Typography>
        </div>
        <Typography>Axis units in feet from center of the pile</Typography>
      </>
      //   <ColorLegend />
      // </div>
    );
  }

  // location select mode
  // used for configuring location
  return (
    <>
      {/* click event on the top level because victory swallows click events like it is its job */}
      <div
        style={{
          height: y_length_px + 50 + Number(!landscape || 0) * 30,
          width: x_length_px + 100 + Number(landscape || 0) * 30,
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          flexDirection: landscape ? 'row' : 'column',
        }}
        onMouseDown={(evt) => {
          if (coordinates) {
            // just like the value transformation above for the rotation of the axes to landscape, we have to switch and make the vlaues negative for landscape mode except opposite
            onLocationSelect(
              landscape
                ? { x_ft: -Number(coordinates.y), y_ft: coordinates.x }
                : { x_ft: coordinates.x, y_ft: coordinates.y }
            );
          }
          evt.stopPropagation();
        }}
      >
        <Typography variant="h5">{landscape ? neg_x_direction : pos_y_direction}</Typography>
        {/* sides were a bit cut off so 5px got added to the width */}
        <VictoryChart
          height={y_length_px}
          width={x_length_px}
          theme={victory_theme}
          domain={{ y: [-domain_y, domain_y], x: [-domain_x, domain_x] }}
          padding={styles.chart_padding}
          containerComponent={
            <VictoryCursorContainer
              cursorLabel={(datum) =>
                `${x_axis_ticks(formatNumber(datum.x))}, ${y_axis_ticks(formatNumber(datum.y))}`
              }
              onCursorChange={(evt) => {
                if (evt && evt.x && evt.y) {
                  setCoordinates({ x: evt.x, y: evt.y });
                }
              }}
            />
          }
        >
          <PileShadow
            pile_area_y_offset_px={pile_area_y_offset_px}
            pile_area_x_offset_px={pile_area_x_offset_px}
            pile_area_y_px={pile_area_y_px}
            pile_area_x_px={pile_area_x_px}
          />
          <VictoryAxis
            padding={50}
            style={styles.axis1}
            tickFormat={x_axis_ticks}
            standalone={false}
          />
          <VictoryAxis
            padding={{ top: 10, bottom: 30, left: 50, right: 50 }}
            dependentAxis
            crossAxis
            style={styles.axis2}
            tickFormat={y_axis_ticks}
            standalone={false}
          />
          <VictoryScatter
            size={3}
            style={
              {
                data: {
                  stroke: (datum) =>
                    datum && datum.configured ? amber_dark_grey : amber_light_grey,
                  strokeWidth: 1,
                  fill: amber_dark_grey,
                  fillOpacity: (datum) => (datum && datum.configured ? 1 : 0.5),
                },
              } as any // the function styles are not included the the ts definition
            }
            data={[...location_data, selected_coords]}
            standalone={false}
          />
        </VictoryChart>
        <Typography variant="h5">{landscape ? pos_x_direction : neg_y_direction}</Typography>
      </div>
      <Typography>Axis units in feet from center of the pile</Typography>
    </>
  );
};
