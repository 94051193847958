import { fade, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { amber_grey, black_shade_6, blue_shade_1 } from '../../../../../core/src/style';
import { Button, ButtonSubmit } from './Button';

const useStyles = makeStyles((theme: Theme) => ({
  generateSchedule: {
    margin: 0,
    backgroundColor: black_shade_6,
    height: 45,
    color: 'white',
    fontSize: 16,
    borderRadius: 8,
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: fade(black_shade_6, 0.3),
    },
    '&:hover': {
      backgroundColor: fade(black_shade_6, 0.8),
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
  },
  cancelSchedule: {
    color: amber_grey,
    margin: 0,
    height: 45,
    borderRadius: 8,
    textTransform: 'none',
    fontSize: 16,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
  },
}));

export const SubmitButtonsGroup = ({
  onCancel,
  cancelText,
  submitButtonText,
  cancelContainerStyles = {},
  submitContainerStyles = {},
}) => {
  const theme = useTheme();
  const isTooSmallMobile = useMediaQuery(theme.breakpoints.between(340, 375), { noSsr: true });
  const classes = useStyles();
  return (
    <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginBottom: 10 }}>
      <div
        style={{
          width: isTooSmallMobile ? '48%' : '50%',
          marginRight: 16,
          ...cancelContainerStyles,
        }}
      >
        <Button variant="outlined" fullWidth onClick={onCancel} className={classes.cancelSchedule}>
          {cancelText}
        </Button>
      </div>
      <div
        style={{
          width: isTooSmallMobile ? '52%' : '50%',
          ...submitContainerStyles,
        }}
      >
        <ButtonSubmit
          variant="contained"
          className={classes.generateSchedule}
          color="primary"
          fullWidth
          allow_pristine
        >
          {submitButtonText}
        </ButtonSubmit>
      </div>
    </div>
  );
};
