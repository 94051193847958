import { ListItem, ListItemIcon, Tooltip } from '@material-ui/core';
import { fade, Theme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';
import { amber_grey_accent, amber_light_grey, amber_medium_grey } from '../../style';
import {
  EXTRA_CUSTOM_BREAKPOINT_DIFFERENCE,
  get_vertical_sidebar_width,
  sidebar_item_height,
} from '../../util';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: get_vertical_sidebar_width('desktop'),
    height: sidebar_item_height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: fade(amber_grey_accent, 0.5),
    },
    '&$selected': { backgroundColor: amber_grey_accent },
    '&$selected:hover': { backgroundColor: amber_grey_accent },
    '&$selected:focus': { backgroundColor: amber_grey_accent },
    [theme.breakpoints.down(theme.breakpoints.width('lg') + EXTRA_CUSTOM_BREAKPOINT_DIFFERENCE)]: {
      width: get_vertical_sidebar_width('tablet'),
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.only('sm')]: {
      width: get_vertical_sidebar_width('sm-mobile'),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  selected: {},
  icon: {
    minWidth: 0,
    marginRight: 0,
  },
}));

export const VerticalAppBarItem: FunctionComponent<{
  children: any;
  selected: boolean;
  handleClick: () => void;
  tooltipTitle?: string;
}> = ({ selected, handleClick, children, tooltipTitle }) => {
  const classes = useStyles();

  if (tooltipTitle) {
    return (
      <Tooltip
        title={
          <div
            style={{
              fontSize: 14,
              height: 30,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {tooltipTitle}
          </div>
        }
        placement="right"
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 400 }}
        leaveDelay={100}
        arrow
      >
        <ListItem
          button
          selected={selected}
          onClick={handleClick}
          classes={{ root: classes.root, selected: classes.selected }}
        >
          <ListItemIcon
            style={selected ? { color: amber_light_grey } : { color: amber_medium_grey }}
            className={classes.icon}
          >
            {children}
          </ListItemIcon>
        </ListItem>
      </Tooltip>
    );
  }

  return (
    <ListItem
      button
      selected={selected}
      onClick={handleClick}
      classes={{ root: classes.root, selected: classes.selected }}
    >
      <ListItemIcon
        style={selected ? { color: amber_light_grey } : { color: amber_medium_grey }}
        className={classes.icon}
      >
        {children}
      </ListItemIcon>
    </ListItem>
  );
};
