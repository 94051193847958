import { Checkbox, makeStyles, Theme } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { CollapseIcon, ExpandIcon } from '../../../../../core/src/media';
import {
  black_shade_2,
  black_shade_4,
  gray_shade_4,
  light_gray_9,
  white,
} from '../../../../../core/src/style';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // borderTop: '1px solid var(--ag-border-color, #babfc7)',
  },
  filterContainer: {
    width: 280,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterBox: {
    width: 270,
    height: 34,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    border: `1px solid ${gray_shade_4}`,
    backgroundColor: white,
    margin: '0px 10px',
  },
  selectedItems: {
    width: '85%',
    height: 20,
    backgroundColor: light_gray_9,
    borderRadius: 2,
    display: 'flex',
    padding: '0px 5px',
    alignItems: 'center',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    fontSize: 14,
    color: black_shade_2,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  toggler: {
    height: '100%',
    width: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px dashed var(--ag-border-color, #babfc7)',
  },
  togglerBtn: {
    width: 24,
    height: 26,
    boxShadow: '0px 1px 4px #00000029',
    borderRadius: 4,
    border: `1px solid ${black_shade_4}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
  seperator: {
    backgroundColor: light_gray_9,
    width: 1,
    height: 20,
    marginRight: 5,
    marginLeft: 5,
  },
}));

const SiteBinsFilterComponent = forwardRef((props: any, ref) => {
  console.log('props in SiteBinsFilterComponent', props);
  const classes = useStyles();
  const [expandAllToggle, setExpandAllToggle] = useState(false);
  const hasBinsOnlyView = props.filterParams.itemsType === 'binsOnly';
  const defaultPlaceholder = hasBinsOnlyView ? 'All bins visible' : 'All bins and sites visible';
  const noSitesSelected = hasBinsOnlyView ? 'No bins visible' : 'No bins and sites visible';
  const [selectedOptions, setSelectedOptions] = useState<any>([defaultPlaceholder]);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          setSelectedOptions([defaultPlaceholder]);
        } else {
          setSelectedOptions(
            parentModel.values.length > 0 ? parentModel.values : [noSitesSelected]
          );
        }
        console.log('parentModel', parentModel, selectedOptions);
      },
    };
  });

  const selectedItemsText = selectedOptions.join(', ');
  const toggleExpandCollapse = ({ target: { checked } }) => {
    setExpandAllToggle(checked);
    checked ? props.api.expandAll() : props.api.collapseAll();
  };

  return (
    <div className={classes.container}>
      {!hasBinsOnlyView && (
        <div className={classes.toggler}>
          <div className={classes.togglerBtn}>
            <Checkbox
              icon={<ExpandIcon />}
              checkedIcon={<CollapseIcon />}
              onChange={toggleExpandCollapse}
              checked={expandAllToggle}
              style={{ padding: 0 }}
            />
          </div>
        </div>
      )}
      <div className={classes.filterContainer}>
        <div className={classes.filterBox}>
          <div className={classes.selectedItems}>{selectedItemsText}</div>
          <div className={classes.seperator} />
          <FilterListIcon
            color="primary"
            onClick={() => {
              props.showParentFilter();
            }}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
    </div>
  );
});

export { SiteBinsFilterComponent };
