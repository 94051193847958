import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { DistanceUnitType } from '../../../../../core/src/api';

const DistanceUnits = [{ label: 'Feet', value: 'feet' }, { label: 'Meter', value: 'meter' }];

type SelectProps = {
  name: string;
  selectedValue: DistanceUnitType;
  isDisabled?: boolean;
  onChange: (value: { value: DistanceUnitType; label: string }) => void;
};

export const SelectDistanceUnit: FunctionComponent<SelectProps> = ({
  name,
  selectedValue,
  ...props
}) => {
  const defaultOption = DistanceUnits.find((option) => option.value === selectedValue);
  return (
    <Select
      options={DistanceUnits}
      name={name}
      value={defaultOption}
      isSearchable={false}
      {...props}
    />
  );
};
