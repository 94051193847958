import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { ErrorIcon, MediumErrorIcon } from '../../../../../core/src/media';
import { black_shade_4, light_gray_3 } from '../../../../../core/src/style';

export const NoSchedulePlaceholder = ({ message, containerStyles = {}, hasErrorIcon = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 98,
        borderRadius: 6,
        backgroundColor: light_gray_3,
        border: `1px solid ${black_shade_4}`,
        marginBottom: 10,
        ...containerStyles,
      }}
    >
      <div
        style={{
          fontSize: 16,
          color: 'black',
          fontWeight: 600,
          width: '85%',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {hasErrorIcon && (
          <div style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>
            <MediumErrorIcon />
          </div>
        )}
        {message}
      </div>
    </div>
  );
};
