import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { amber_light_grey, amber_medium_grey } from '../../../style';
import formatNumber from '../../../util/format-number';

const useStyles = makeStyles({
  container: {
    height: 40,
    width: 80,
    border: `1px solid ${amber_medium_grey}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
  },
  text: {
    fontSize: 20,
  },
  margin: {
    margin: 20,
    width: '100%',
  },
});

const formatTemp = (temp_f) => (temp_f === null ? 'N/A' : formatNumber(temp_f));

export const BargeTemperatureDiagramCell: React.FunctionComponent<{
  temperature?: number;
  temp_change?: number;
}> = ({ temperature, temp_change }) => {
  const { container, text } = useStyles();
  if (temperature !== undefined) {
    return (
      <div className={container}>
        <span />
        <span className={text} style={temperature >= 110 ? { color: red['500'] } : {}}>
          {`${formatTemp(temperature)}\u00b0 ${
            temp_change && Math.abs(temp_change) >= 1
              ? `(${temp_change > 0 ? '+' : ''}${temp_change})`
              : ''
          }`}
        </span>
      </div>
    );
  }
  return <div className={container} style={{ backgroundColor: amber_light_grey }} />;
};
