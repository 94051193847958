import { DateTime } from 'luxon';
import React from 'react';
import { getFormattedDurationText } from './FanScheduleDurationCellRenderer';
import { withSkeleton } from './withCellSkeleton';

const FanHistoryDurationCellRenderer = withSkeleton((props) => {
  const lastAeratioRun = props.value;

  if (!lastAeratioRun) {
    return <>No Previous Fan Run</>;
  }

  const start = DateTime.fromMillis(new Date(lastAeratioRun.start_epoch).getTime());
  const end = DateTime.fromMillis(new Date(lastAeratioRun.end_epoch).getTime());
  const diff = end.diff(start, ['days', 'hours', 'minutes']).toObject();

  return <>{getFormattedDurationText(diff)}</>;
});

export { FanHistoryDurationCellRenderer };
