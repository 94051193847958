import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { endSession } from '../../action';

export const EmailVerificationSent = () => {
  const dispatch = useDispatch();

  const onClickLogout = useCallback(() => dispatch(endSession()), [dispatch]);

  return (
    <Dialog open={true}>
      <DialogTitle>
        An email verification link has been sent. Once you verify your email, you will be able to
        sign in.
      </DialogTitle>
      <DialogContent>
        <Button variant="outlined" onClick={onClickLogout}>
          RETURN TO LOGIN
        </Button>
      </DialogContent>
    </Dialog>
  );
};
