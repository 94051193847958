import * as React from 'react';
export const DoubleArrowIcon = (props) => (
  <svg
    width={20}
    height={13}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    overflow="hidden"
    {...props}
  >
    <defs>
      <clipPath id="da_clip0">
        <rect x={850} y={410} width={20} height={13} />
      </clipPath>
    </defs>
    <g clipPath="url(#da_clip0)" transform="translate(-850 -410)">
      <path
        d="M8.125 0 8.125 11.375"
        stroke="#7C7C7C"
        strokeWidth={0.666667}
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
        transform="matrix(-1.83697e-16 -1 1.00882 -1.85317e-16 850.82 422.188)"
      />
      <path
        d="M4.875 3.25 8.125 0"
        stroke="#7C7C7C"
        strokeWidth={0.666667}
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
        transform="matrix(-1.83697e-16 -1 1.00882 -1.85317e-16 850.82 422.188)"
      />
      <path
        d="M8.125 0 11.375 3.25"
        stroke="#7C7C7C"
        strokeWidth={0.666667}
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
        transform="matrix(-1.83697e-16 -1 1.00882 -1.85317e-16 850.82 422.188)"
      />
      <path
        d="M3.25 17.875 3.25 6.5"
        stroke="#7C7C7C"
        strokeWidth={0.666667}
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
        transform="matrix(-1.83697e-16 -1 1.00882 -1.85317e-16 850.82 422.188)"
      />
      <path
        d="M6.5 14.625 3.25 17.875"
        stroke="#7C7C7C"
        strokeWidth={0.666667}
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
        transform="matrix(-1.83697e-16 -1 1.00882 -1.85317e-16 850.82 422.188)"
      />
      <path
        d="M3.25 17.875 0 14.625"
        stroke="#7C7C7C"
        strokeWidth={0.666667}
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
        transform="matrix(-1.83697e-16 -1 1.00882 -1.85317e-16 850.82 422.188)"
      />
      <text
        fontFamily="Helvetica,Helvetica_MSFontService,sans-serif"
        fontWeight={700}
        fontSize={5}
        transform="matrix(-1.49254e-16 -0.8125 0.819665 -1.5057e-16 881.967 423)"
      >
        {'Created by Smashicons'}
      </text>
      <text
        fontFamily="Helvetica,Helvetica_MSFontService,sans-serif"
        fontWeight={700}
        fontSize={5}
        transform="matrix(-1.49254e-16 -0.8125 0.819665 -1.5057e-16 886.065 423)"
      >
        {'from the Noun Project'}
      </text>
    </g>
  </svg>
);
