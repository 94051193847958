import { green, red, yellow } from '@material-ui/core/colors';
import { darken, fade } from '@material-ui/core/styles';
import format from 'date-fns/format';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { DomainTuple, VictoryArea, VictoryAxis, VictoryChart, VictoryLine } from 'victory';

import {
  amber_faded_green,
  amber_grey,
  amber_line_color_sets,
  amber_red,
  amber_yellow,
  victory_theme,
} from '../../../../style';
import {
  formatNumber,
  formatPercent,
  RangeState,
  RelativeTimePeriod,
  Statistic,
} from '../../../../util';
import { RunWindow } from './TelemetryHistoryCard';

export const getFormatedDay = (
  dt: DateTime,
  as_of = DateTime.local(),
  prevent_now = false
): string => {
  if (!prevent_now && dt.toMillis() <= as_of.toMillis()) {
    return 'Now';
  }
  if (dt.toMillis() >= DateTime.local(3000, 1, 1).toMillis()) {
    return 'Forever';
  }
  if (dt.hasSame(as_of, 'day')) {
    return 'Today';
  }
  return dt.toFormat('ccc');
};

export const formatTimeTick = (dt) =>
  `${getFormatedDay(dt, DateTime.local(), true)}\n${dt.month}/${dt.day}`;

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export type Range = {
  high: number;
  low: number;
  state: RangeState;
};

export type HistoryRecord = {
  epoch_time: Date;
  [Statistic.emc]: number;
  [Statistic.humidity_rh]: number;
  [Statistic.temp_f]: number;
  [Statistic.co2_ppm]: number;
};

const formatTime = {
  [RelativeTimePeriod.week]: (d) => formatTimeTick(d),
  [RelativeTimePeriod.month]: (d) => `${MONTHS[d.month - 1].substring(0, 3)} ${d.day}`,
  [RelativeTimePeriod.quarter]: (d) => MONTHS[d.month - 1],
};

const TICK_FORMATTER = {
  [Statistic.emc]: (y) => formatPercent(y, 1),
  [Statistic.humidity_rh]: (y) => formatPercent(y),
  [Statistic.temp_f]: (y) => `${formatNumber(y, 0)} F`,
  [Statistic.co2_ppm]: (y) => y,
};
const MIN_RANGE = {
  [Statistic.temp_f]: 10,
  [Statistic.humidity_rh]: 0.05,
  [Statistic.emc]: 0.03,
  [Statistic.co2_ppm]: 800,
};

export const getTickValues = (
  period: RelativeTimePeriod,
  year: number,
  month: number,
  date: number
): DateTime[] => {
  if (period === RelativeTimePeriod.week) {
    return [
      DateTime.local(year, month, date, 12).plus({ days: -6 }),
      DateTime.local(year, month, date, 12).plus({ days: -5 }),
      DateTime.local(year, month, date, 12).plus({ days: -4 }),
      DateTime.local(year, month, date, 12).plus({ days: -3 }),
      DateTime.local(year, month, date, 12).plus({ days: -2 }),
      DateTime.local(year, month, date, 12).plus({ days: -1 }),
      DateTime.local(year, month, date, 12),
    ];
  }
  if (period === RelativeTimePeriod.month) {
    return [
      DateTime.local(year, month, date, 12).plus({ days: -28 }),
      DateTime.local(year, month, date, 12).plus({ days: -21 }),
      DateTime.local(year, month, date, 12).plus({ days: -14 }),
      DateTime.local(year, month, date, 12).plus({ days: -7 }),
      DateTime.local(year, month, date, 0),
    ];
  }
  if (period === RelativeTimePeriod.quarter) {
    if (date < 15) {
      return [
        DateTime.local(year, month, date, 12).plus({ months: -3 }),
        DateTime.local(year, month, date, 12).plus({ months: -2 }),
        DateTime.local(year, month, date, 12).plus({ months: -1 }),
        DateTime.local(year, month, date, 0),
      ];
    }
    return [
      DateTime.local(year, month, 15, 12).plus({ months: -2 }),
      DateTime.local(year, month, 15, 12).plus({ months: -1 }),
      DateTime.local(year, month, 15, 12),
    ];
  }
  throw new Error(`Unexpected period ${period}`);
};

export const hasRangeOverlap = (domain: DomainTuple, { high, low }: Range): boolean =>
  (low <= domain[0] && domain[0] <= high) ||
  (low <= domain[1] && domain[1] <= high) ||
  (domain[0] <= low && low <= domain[1]) ||
  (domain[0] <= high && high <= domain[1]);

const range_colors = {
  [RangeState.bad]: darken(fade(red['500'], 0.9), 0.7),
  [RangeState.ok]: darken(fade(yellow['500'], 0.8), 0.7),
  [RangeState.good]: darken(fade(green['500'], 0.7), 0.6),
};

export const getRanges = ({ ranges, history, tick_values, domain }) => {
  if (!ranges || history.length === 0) {
    return null;
  }

  const x_min = Math.min(
    DateTime.fromMillis(new Date(history[0].epoch_time).getTime()).toMillis(),
    tick_values[0].toMillis()
  );
  const x_max = Math.max(
    DateTime.fromMillis(new Date(history[history.length - 1].epoch_time).getTime()).toMillis(),
    tick_values[tick_values.length - 1].toMillis()
  );
  const [min, max] = domain;
  return (
    <>
      {ranges
        .filter((range) => hasRangeOverlap(domain, range))
        .map(({ state, high, low }, ix) => {
          const fill = range_colors[state];
          if (!fill) {
            return null;
          }
          return (
            <VictoryArea
              key={ix} // eslint-disable-line
              style={{ data: { fill } }}
              data={[
                {
                  x: x_min,
                  y: Math.min(max as number, high),
                  y0: Math.max(min as number, low),
                },
                {
                  x: x_max,
                  y: Math.min(max as number, high),
                  y0: Math.max(min as number, low),
                },
              ]}
            />
          );
        })}
    </>
  );
};

const getDefaultDomain = (statistic: Statistic): DomainTuple => {
  switch (statistic) {
    case Statistic.humidity_rh:
      return [0, 1];
    case Statistic.temp_f:
      return [0, 90];
    case Statistic.emc:
      return [0.1, 0.25];
    case Statistic.co2_ppm:
      // wider range to minimize concern from minimal variation
      return [300, 2000];
    default:
      return [0, 1];
  }
};

export const getDomain = (history: HistoryRecord[], statistic: Statistic): DomainTuple => {
  if (history.length) {
    // const y_values = history[statistic];
    let { min, max } = history.reduce(
      ({ min: _min, max: _max }, row) => ({
        min: Math.min(row[statistic], _min),
        max: Math.max(row[statistic], _max),
      }),
      { min: history[0] && history[0][statistic], max: history[0] && history[0][statistic] }
    );
    const diff = max - min;
    const min_range = MIN_RANGE[statistic];
    if (diff < min_range) {
      const extra = (min_range - diff) / 2;
      min -= extra;
      max += extra;
    }
    return [min, max];
  }
  return getDefaultDomain(statistic);
};

const styles = {
  chart_padding: { top: 10, bottom: 40, left: 50, right: 50 },
  axis1: {
    ticks: { stroke: 'transparent' },
  },
  axis2: {
    grid: { stroke: 'none' },
  },
};

export const TelemetryHistoryPlot: React.FunctionComponent<{
  period: RelativeTimePeriod;
  height: number;
  width?: number;
  statistic: Statistic;
  ranges: Range[];
  history: HistoryRecord[];
  run_windows?: RunWindow[];
  show_temp_threshold?: boolean;
}> = ({ period, height, width, history, statistic, ranges, run_windows, show_temp_threshold }) => {
  const today = DateTime.local();
  today.set({ hour: 12 });

  const tick_values = getTickValues(period, today.year, today.month, today.day);
  const domain = getDomain(history, statistic);
  const data = history
    .map((row) => {
      if (row[statistic] !== undefined) {
        return { x: row.epoch_time, y: row[statistic] };
      }
      return null;
    })
    .filter((datum) => datum !== null);
  let x_min;
  let x_max;
  if (history[0] && history[history.length - 1]) {
    x_min = Math.min(
      DateTime.fromMillis(new Date(history[0].epoch_time).getTime()).toMillis(),
      tick_values[0].toMillis()
    );
    x_max = Math.max(
      DateTime.fromMillis(new Date(history[history.length - 1].epoch_time).getTime()).toMillis(),
      tick_values[tick_values.length - 1].toMillis()
    );
  } else {
    x_min = tick_values[0].toMillis();
    x_max = tick_values[tick_values.length - 1].toMillis();
  }
  const [min, max] = domain;
  return (
    <svg viewBox={`0 0 ${width || 350} ${height}`}>
      {/* 350 width determined by calculating pre-existing aspect ration */}
      <VictoryChart
        standalone={false}
        // animate={{ duration: 500 }}
        theme={victory_theme}
        height={height}
        width={width || 350}
        padding={styles.chart_padding}
      >
        <VictoryAxis
          padding={50}
          style={styles.axis1}
          tickFormat={formatTime[period]}
          tickValues={tick_values}
        />
        <VictoryAxis
          padding={{ top: 10, bottom: 30, left: 50, right: 50 }}
          dependentAxis
          crossAxis={false}
          tickFormat={TICK_FORMATTER[statistic]}
          style={styles.axis2}
          domain={domain}
        />
        {getRanges({ ranges, history, tick_values, domain })}
        {show_temp_threshold && statistic === Statistic.temp_f && (
          <VictoryArea
            style={{ data: { fill: fade(amber_red, 0.3) } }}
            data={[
              {
                x: x_min,
                y: Math.max(max as number, 120),
                y0: 110, // todo make temp threshold value dynamic?
              },
              {
                x: x_max,
                y: Math.max(max as number, 120),
                y0: 110,
              },
            ]}
          />
        )}
        {statistic === Statistic.co2_ppm && (
          <VictoryArea
            style={{ data: { fill: fade(amber_yellow, 0.3) } }}
            data={[
              {
                x: x_min,
                y: 1500,
                y0: 1000,
              },
              {
                x: x_max,
                y: 1500,
                y0: 1000,
              },
            ]}
          />
        )}
        {statistic === Statistic.co2_ppm && (
          <VictoryArea
            style={{ data: { fill: fade(amber_red, 0.3) } }}
            data={[
              {
                x: x_min,
                y: Math.max(max as number, 2000),
                y0: 1500,
              },
              {
                x: x_max,
                y: Math.max(max as number, 2000),
                y0: 1500,
              },
            ]}
          />
        )}

        {/* green for run windows & grey for ignoore for co2 */}
        {run_windows &&
          run_windows
            .filter(({ start_epoch }) => DateTime.fromMillis(start_epoch).toMillis() > x_min)
            .map(({ start_epoch, end_epoch }, ix) => (
              <VictoryArea
                key={ix}
                style={{
                  data: {
                    fill:
                      statistic === Statistic.co2_ppm ? fade(amber_grey, 0.3) : amber_faded_green,
                  },
                }}
                data={[
                  {
                    x: DateTime.fromMillis(start_epoch).toMillis(),
                    y: statistic === Statistic.co2_ppm ? Math.max(max as number, 2000) : max,
                    y0: min,
                  },
                  {
                    x: end_epoch ? DateTime.fromMillis(end_epoch).toMillis() : x_max,
                    y: statistic === Statistic.co2_ppm ? Math.max(max as number, 2000) : max,
                    y0: min,
                  },
                ]}
              />
            ))}
        {history.length > 0 ? <VictoryLine padding={50} height={250} data={data} /> : null}
      </VictoryChart>
    </svg>
  );
};

export const TelemetryMultiHistoryPlot: React.FunctionComponent<{
  period: RelativeTimePeriod;
  height: number;
  width?: number;
  statistic: Statistic;
  ranges: Range[];
  histories: { pellet_id: string; data: HistoryRecord[]; color_ix: number }[];
  run_windows?: RunWindow[];
  show_temp_threshold?: boolean;
  colors: string[];
}> = ({
  period,
  height,
  width,
  histories,
  statistic,
  ranges,
  run_windows,
  show_temp_threshold,
  colors,
}) => {
  const flat_histories = useMemo(() => [].concat.apply([], histories.map(({ data }) => data)), [
    histories,
  ]);
  const today = DateTime.local();
  today.set({ hour: 12 });

  const tick_values = getTickValues(period, today.year, today.month, today.day);
  const domain = getDomain(flat_histories, statistic);
  const data_sets = histories.map(({ data }) => {
    return data
      .map((row) => {
        if (row[statistic]) {
          return { x: row.epoch_time, y: row[statistic] };
        }
        return null;
      })
      .filter((datum) => datum !== null);
  });
  let x_min;
  let x_max;
  // if (history[0] && history[history.length - 1]) {
  //   x_min = Math.min(history[0].epoch_time.getTime(), tick_values[0].getTime());
  //   x_max = Math.max(
  //     history[history.length - 1].epoch_time.getTime(),
  //     tick_values[tick_values.length - 1].getTime()
  //   );
  // } else {
  x_min = tick_values[0].toMillis();
  x_max = tick_values[tick_values.length - 1].toMillis();
  // }
  const [min, max] = domain;
  return (
    <svg viewBox={`0 0 ${width || 350} ${height}`}>
      {/* 350 width determined by calculating pre-existing aspect ration */}
      <VictoryChart
        standalone={false}
        // animate={{ duration: 500 }}
        theme={victory_theme}
        height={height}
        width={width || 350}
        padding={styles.chart_padding}
      >
        <VictoryAxis
          padding={50}
          style={styles.axis1}
          tickFormat={formatTime[period]}
          tickValues={tick_values}
        />
        <VictoryAxis
          padding={{ top: 10, bottom: 30, left: 50, right: 50 }}
          dependentAxis
          crossAxis={false}
          tickFormat={TICK_FORMATTER[statistic]}
          style={styles.axis2}
          domain={domain}
        />
        {getRanges({
          ranges,
          tick_values,
          domain,
          history: flat_histories,
        })}
        {show_temp_threshold && statistic === Statistic.temp_f && (
          <VictoryArea
            style={{ data: { fill: fade(amber_red, 0.3) } }}
            data={[
              {
                x: x_min,
                y: Math.max(max as number, 120),
                y0: 110, // todo make temp threshold value dynamic?
              },
              {
                x: x_max,
                y: Math.max(max as number, 120),
                y0: 110,
              },
            ]}
          />
        )}
        {histories.map(({ data, pellet_id, color_ix }, ix) =>
          data.length > 0 ? (
            <VictoryLine
              key={pellet_id}
              padding={50}
              height={250}
              data={data_sets[ix]}
              style={{ data: { stroke: colors[color_ix] } }}
            />
          ) : null
        )}
      </VictoryChart>
    </svg>
  );
};
