import { Drawer, List, Theme } from '@material-ui/core';
import { HomeOutlined, PeopleOutlined, SettingsOutlined } from '@material-ui/icons';

import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ViewerFragmentFragment,
  withGetAccountUsersHoc,
  WithGetAccountUsersHocChildProps,
} from '../../api';
import { AlertBellSvgIcon } from '../../media';
import { SelectedState } from '../../reducer';
import { amber_amber, amber_grey, black_shade_6 } from '../../style';
import {
  ContainerTypeLegacy,
  EXTRA_CUSTOM_BREAKPOINT_DIFFERENCE,
  get_vertical_sidebar_width,
} from '../../util';
import { VerticalAppBarItem } from './VerticalAppBarItem';

// TODO REPLACE WITH HIGH QUALITY ICON
import { setUnreadNotificationCount } from '../../../../core/src/action';
import logo from '../../media/favicon.png';
import { DialogSpinner } from '../spinner';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: get_vertical_sidebar_width('desktop'),
    flexShrink: 0,
    [theme.breakpoints.down(theme.breakpoints.width('lg') + EXTRA_CUSTOM_BREAKPOINT_DIFFERENCE)]: {
      width: get_vertical_sidebar_width('tablet'),
    },
    [theme.breakpoints.only('sm')]: {
      width: get_vertical_sidebar_width('sm-mobile'),
    },
  },
  drawerPaper: {
    width: get_vertical_sidebar_width('desktop'),
    backgroundColor: black_shade_6,
    border: 'none',
    boxShadow: `0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)`,
    zIndex: 1210,
    [theme.breakpoints.down(theme.breakpoints.width('lg') + EXTRA_CUSTOM_BREAKPOINT_DIFFERENCE)]: {
      width: get_vertical_sidebar_width('tablet'),
    },
    [theme.breakpoints.only('sm')]: {
      width: get_vertical_sidebar_width('sm-mobile'),
    },
  },
  sidebarIcon: { width: 24, height: 24 },
  logo: {
    width: 24,
    height: 24,
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    margin: 32,
    [theme.breakpoints.down(theme.breakpoints.width('lg') + EXTRA_CUSTOM_BREAKPOINT_DIFFERENCE)]: {
      margin: '32px auto',
    },
  },
  notificationsCountBadge: {
    height: 16,
    width: 16,
    position: 'relative',
    top: '-6px',
    left: '-10px',
    borderRadius: '50%',
    backgroundColor: amber_amber,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 10,
    color: '#FFFDFA',
  },
}));

const selector = ({ selected }: { selected: SelectedState }) => selected;

export const VerticalAppBar: FunctionComponent<{
  unreadNotificationCount: number;
  viewer: ViewerFragmentFragment;
  pathname: string;
  onClickDashboard: (args: any) => void;
  onClickUsers: () => void;
  onClickSettings: () => void;
  onClickOrgSettings: () => void;
  onClickNotifications: () => void;
}> = ({
  viewer,
  pathname,
  unreadNotificationCount,
  onClickDashboard,
  onClickUsers,
  onClickSettings,
  onClickOrgSettings,
  onClickNotifications,
}) => {
  const classes = useStyles();
  const selected = useSelector(selector);
  const dispatch = useDispatch();
  const unreadNotificationCountStoredValue = useSelector(
    ({ global_state: { unreadNotificationCount } }) => unreadNotificationCount
  );

  const goToDashboard = useCallback(
    () =>
      onClickDashboard({
        container_name: selected.container_name,
        container_id: selected.container_id,
        container_type: selected.container_type,
        account_id: selected.account_id || viewer.account_id,
      }),
    [onClickDashboard, selected]
  );

  useEffect(() => {
    dispatch(setUnreadNotificationCount({ unreadNotificationCount }));
  }, [unreadNotificationCount]);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div onClick={goToDashboard} className={`${classes.logo} ${classes.sidebarIcon}`} />
      <List>
        <VerticalAppBarItem
          selected={pathname.includes('dashboard') || pathname.includes('grain/operations')}
          handleClick={goToDashboard}
          tooltipTitle="Dashboard"
        >
          <HomeOutlined className={classes.sidebarIcon} />
        </VerticalAppBarItem>
        {viewer && viewer.account.grain_bin_support && (
          <VerticalAppBarItem
            selected={pathname.includes('/notifications')}
            handleClick={onClickNotifications}
            tooltipTitle="Notifications"
          >
            <AlertBellSvgIcon
              style={{
                height: 21,
                marginLeft: unreadNotificationCountStoredValue ? 10 : undefined,
              }}
              className={classes.sidebarIcon}
            />
            {Boolean(unreadNotificationCountStoredValue) && (
              <span className={classes.notificationsCountBadge}>
                {unreadNotificationCountStoredValue}
              </span>
            )}
          </VerticalAppBarItem>
        )}
        <VerticalAppBarItem
          selected={pathname.includes('users')}
          handleClick={onClickUsers}
          tooltipTitle="Users"
        >
          <PeopleOutlined className={classes.sidebarIcon} />
        </VerticalAppBarItem>
        <VerticalAppBarItem
          selected={pathname.includes('/settings')}
          handleClick={onClickSettings}
          tooltipTitle="Settings"
        >
          <SettingsOutlined className={classes.sidebarIcon} />
        </VerticalAppBarItem>
      </List>
    </Drawer>
  );
};

const VerticalAppBarWithCurrentAccountUsersBase: FunctionComponent<
  {
    account_id: number;
    viewer: ViewerFragmentFragment;
    pathname: string;
    onClickDashboard: (args: any) => void;
    onClickUsers: () => void;
    onClickSettings: () => void;
    onClickOrgSettings: () => void;
    onClickNotifications: () => void;
  } & WithGetAccountUsersHocChildProps
> = ({
  account_id,
  viewer,
  pathname,
  onClickDashboard,
  onClickUsers,
  onClickSettings,
  onClickOrgSettings,
  onClickNotifications,
  loading,
  users,
}) => {
  if (loading) return <DialogSpinner title="Loading..." open={loading} />;
  const user = users[0];

  return (
    <VerticalAppBar
      viewer={viewer}
      pathname={pathname}
      onClickDashboard={onClickDashboard}
      onClickUsers={onClickUsers}
      onClickSettings={onClickSettings}
      onClickOrgSettings={onClickOrgSettings}
      onClickNotifications={onClickNotifications}
      unreadNotificationCount={user ? user.user_new_notifications_count : 0}
    />
  );
};

export const VerticalAppBarWithCurrentAccountUsers = withGetAccountUsersHoc(
  VerticalAppBarWithCurrentAccountUsersBase
);
