import { Slider as MuiSlider, SliderProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';
import React, { useRef } from 'react';

// slider requires additional styling of left: 0 otherwise it's extremely offset
const useStyles = makeStyles({
  rail: {
    left: 0,
  },
  slider: {
    marginTop: 35,
  },
  valueLabelOverride: {
    fontSize: 20,
  },
});

export const Slider = ({
  name,
  className,
  ...props
}: Omit<SliderProps, 'classes' | 'value' | 'valueLabelDisplay' | 'onChange'> & {
  name: string;
}) => {
  const classes = useStyles();
  const ref = useRef(null);
  const { setFieldValue, values } = useFormikContext<any>();

  return (
    <MuiSlider
      className={className || classes.slider}
      {...props}
      value={values[name]}
      valueLabelDisplay="on"
      classes={{
        rail: classes.rail,
        valueLabel: classes.valueLabelOverride,
      }}
      onChange={(event, val) => {
        setFieldValue(name, val);
      }}
      innerRef={ref}
    />
  );
};
