import * as React from 'react';
export const SmallRedAlertIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.266}
    height={12.691}
    viewBox="0 0 14.266 12.691"
    {...props}
  >
    <path
      id="AlertIcon"
      d="M8.163.621a1.224,1.224,0,0,0-2.131,0L.173,10.777a1.2,1.2,0,0,0,0,1.243,1.2,1.2,0,0,0,1.065.621H12.956a1.243,1.243,0,0,0,1.065-1.864Zm-1.7,3.054a.639.639,0,0,1,1.278,0V7.51a.639.639,0,0,1-1.278,0Zm.639,6.64a.871.871,0,0,1-.87-.87.887.887,0,0,1,.87-.888.879.879,0,0,1,0,1.758"
      transform="translate(0.036 0.025)"
      fill="#f44336"
      stroke="#fff"
      strokeWidth={0.05}
    />
  </svg>
);
