import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useGrainBinData } from '../../../contexts';
import { light_gray_3 } from '../../../style';
import { RecommendedOption } from '../RecommendationOptionValue';

const TARGET_GRAIN_EMC = 20;
const TARGET_GRAIN_EMC_WARMING_ADDER = 1;
const useStyles = makeStyles((theme: Theme) => ({
  experiment_cfm_calculations: {
    backgroundColor: light_gray_3,
    fontSize: 14,
    lineHeight: 1,
    borderRadius: 4,
    padding: 8,
  },
}));

type ExperimentCfmCalculationsProps = {
  recommendation_type: string;
};

type LowerBoundCalculationProps = {
  recommendation_type: string;
  current_grain_emc: number | undefined;
  target_grain_emc_warming: number | undefined;
  target_grain_emc: number;
};
type UpperBoundCalculationProps = {
  recommendation_type: string;
  current_grain_emc: number | undefined;
  target_grain_emc_warming: number | undefined;
  target_grain_emc: number;
};

export const ExperimentBoundsCalculations = ({
  recommendation_type,
}: ExperimentCfmCalculationsProps) => {
  const classes = useStyles();
  const grainBinData = useGrainBinData();
  console.log('grainBinData', grainBinData);
  const { current_grain_emc, target_grain_emc } = grainBinData || {
    current_grain_emc: undefined,
    target_grain_emc: undefined,
  };
  const target_grain_emc_warming =
    target_grain_emc !== undefined
      ? Number(target_grain_emc) + TARGET_GRAIN_EMC_WARMING_ADDER
      : target_grain_emc;

  return (
    <div className={classes.experiment_cfm_calculations}>
      <LowerBoundCalculation
        recommendation_type={recommendation_type}
        current_grain_emc={
          current_grain_emc !== undefined ? Number(current_grain_emc) : current_grain_emc
        }
        target_grain_emc_warming={target_grain_emc_warming}
        target_grain_emc={target_grain_emc ? target_grain_emc : TARGET_GRAIN_EMC}
      />
      <UpperBoundCalculation
        recommendation_type={recommendation_type}
        current_grain_emc={
          current_grain_emc !== undefined ? Number(current_grain_emc) : current_grain_emc
        }
        target_grain_emc_warming={target_grain_emc_warming}
        target_grain_emc={target_grain_emc ? target_grain_emc : TARGET_GRAIN_EMC}
      />
    </div>
  );
};

const LowerBoundCalculation = ({
  recommendation_type,
  current_grain_emc,
  target_grain_emc_warming,
  target_grain_emc,
}: LowerBoundCalculationProps) => {
  const hasDryingRecommType = recommendation_type === RecommendedOption.DRYING;
  const hasReconditioningRecommType = recommendation_type === RecommendedOption.RECONDITIONING;
  const hasCoolingRecommType = recommendation_type === RecommendedOption.COOLING;
  const evalReconditioningLowerBound =
    target_grain_emc_warming !== undefined ? target_grain_emc_warming - 0.5 : undefined;
  const evalReconditioningLowerBoundText = `${target_grain_emc_warming} - 0.5`;

  const evalDryingLowerBound =
    target_grain_emc_warming !== undefined && current_grain_emc !== undefined
      ? target_grain_emc_warming - (current_grain_emc - target_grain_emc) - 0.5
      : undefined;
  const evalDryingLowerBoundText = `${target_grain_emc_warming} - (${current_grain_emc} - ${target_grain_emc}) - 0.5`;

  return (
    <>
      <h3 style={{ marginTop: 30 }}>Lower Bound Calculation:</h3>
      {hasReconditioningRecommType && (
        <>
          <p>target_grain_emc_warming - 0.5 = lower_bound</p>
          <p>
            {evalReconditioningLowerBoundText}&nbsp;<b>=&nbsp;{evalReconditioningLowerBound}</b>
          </p>
        </>
      )}
      {hasDryingRecommType && (
        <>
          <p>
            target_grain_emc_warming - (current_grain_emc - target_grain_emc) - 0.5 = lower_bound
          </p>
          <p>
            {evalDryingLowerBoundText}&nbsp;<b>=&nbsp;{evalDryingLowerBound}</b>
          </p>
        </>
      )}
      {hasCoolingRecommType && <p>No Lower Bound Calculation Added </p>}
    </>
  );
};

const UpperBoundCalculation = ({
  recommendation_type,
  current_grain_emc,
  target_grain_emc_warming,
  target_grain_emc,
}: UpperBoundCalculationProps) => {
  const hasDryingRecommType = recommendation_type === RecommendedOption.DRYING;
  const hasReconditioningRecommType = recommendation_type === RecommendedOption.RECONDITIONING;
  const hasCoolingRecommType = recommendation_type === RecommendedOption.COOLING;
  const evalReconditioningUpperBound =
    target_grain_emc_warming !== undefined && current_grain_emc !== undefined
      ? target_grain_emc_warming + (target_grain_emc - current_grain_emc) + 0.5
      : undefined;
  const evalReconditioningUpperBoundText = `${target_grain_emc_warming} + (${target_grain_emc} - ${current_grain_emc}) + 0.5`;

  const evalDryingUpperBound =
    target_grain_emc_warming !== undefined ? target_grain_emc_warming + 0.5 : undefined;
  const evalDryingUpperBoundText = `${target_grain_emc_warming} + 0.5`;

  return (
    <>
      <h3 style={{ marginTop: 30 }}>Upper Bound Calculation:</h3>
      {hasReconditioningRecommType && (
        <>
          <p>
            target_grain_emc_warming + (target_grain_emc - current_grain_emc) + 0.5 = upper_bound
          </p>
          <p>
            {evalReconditioningUpperBoundText}&nbsp;<b>=&nbsp;{evalReconditioningUpperBound}</b>
          </p>
        </>
      )}
      {hasDryingRecommType && (
        <>
          <p>target_grain_emc_warming + 0.5 = upper_bound</p>
          <p>
            {evalDryingUpperBoundText}&nbsp;<b>=&nbsp;{evalDryingUpperBound}</b>
          </p>
        </>
      )}
      {hasCoolingRecommType && <p>No Upper Bound Calculation Added </p>}
    </>
  );
};
