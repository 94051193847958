import { ApolloClient } from 'apollo-client';
import { delay } from 'redux-saga';
import { cancel, fork, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { pollBackgroundAccountUpdateState } from '../action';
import { GetAccountDocument, GetAccountQuery, GetAccountQueryVariables } from '../api';

let bg_account_update_poll_task: any;

function* pollForUpdatedAccountLoop(apollo_client: ApolloClient<any>, account_id: number) {
  try {
    while (true) {
      // poll every 1 min
      yield delay(1000 * 60);

      // query for accounts in order to update the sidebar
      const account_result = yield apollo_client.query<GetAccountQuery>({
        query: GetAccountDocument,
        variables: { account_id },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });

      // update cache
      yield apollo_client.writeQuery<GetAccountQuery, GetAccountQueryVariables>({
        query: GetAccountDocument,
        data: account_result.data,
        variables: { account_id },
      });
    }
  } finally {
    // console.log('pollForUpdatedAccountLoop ended');
  }
}

function* pollBackgroundAccountUpdateSaga(
  apollo_client: ApolloClient<any>,
  action: ReturnType<typeof pollBackgroundAccountUpdateState>
) {
  if (!action.payload) return;

  try {
    const {
      payload: { account_id },
    } = action;

    // cancel existing poll if there is one
    if (bg_account_update_poll_task) {
      yield cancel(bg_account_update_poll_task);
      bg_account_update_poll_task = null;
    }

    if (account_id) {
      bg_account_update_poll_task = yield fork(
        pollForUpdatedAccountLoop,
        apollo_client,
        account_id
      );
    }
  } catch (e) {
    console.error(e);
  }
}

export function* backgroundAccountUpdatePollWatcher(apollo_client: ApolloClient<any>) {
  yield takeEvery(
    getType(pollBackgroundAccountUpdateState),
    pollBackgroundAccountUpdateSaga,
    apollo_client
  );
}
