import { Grid, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import React, { useEffect, useState } from 'react';

import {
  UserRole,
  ViewerFragmentFragment,
  withGetActivePileCableLinksHoc,
  WithGetActivePileCableLinksHocChildProps,
  withGetPileContainerHoc,
  WithGetPileContainerHocChildProps,
  withGetPileHoc,
  WithGetPileHocChildProps,
} from '../../../api';
import { DialogSpinner } from '../../spinner';
import { ContainerDashboardLayout } from '../BaseLayout';
import {
  NoContainerFoundPlaceholder,
  SelectContainerPlaceholder,
} from '../SelectContainerPlaceholder';
import { PileCableDetailsCard } from './PileCableDetailsCard';
import { Cable } from './PileCablesTable';
import { PileCablesTableCard } from './PileCablesTableCard';
import { PileDetailsCard } from './PileDetailsCard';

type PileDashboardProps = {
  pile_id?: number | null | undefined;
  mobile_width?: boolean;
  pathname: string;
  viewer: ViewerFragmentFragment;
  onError?: any;
  goPileCableForm: (arg: { cable_id: number }) => void;
  goCreatePile?: () => void;
  goCreateBarge?: () => void;
  goCreateGrainBin?: () => void;
  onSelectContainer?: (args: any) => void;
};
export const PileDashboard = ({
  pile_id,
  mobile_width,
  pathname,
  viewer,
  onError,
  goPileCableForm,
  goCreatePile,
  goCreateBarge,
  goCreateGrainBin,
  onSelectContainer,
}: PileDashboardProps) => {
  return (
    <ContainerDashboardLayout
      viewer={viewer}
      pathname={pathname}
      onSelectContainer={onSelectContainer}
      goCreatePile={goCreatePile}
      goCreateBarge={goCreateBarge}
      goCreateGrainBin={goCreateGrainBin}
      mobile_width={mobile_width}
    >
      {pile_id ? (
        <PileDashboardInner
          viewer={viewer}
          pile_id={pile_id}
          onError={onError}
          goPileCableForm={goPileCableForm}
        />
      ) : (
        <SelectContainerPlaceholder />
      )}
    </ContainerDashboardLayout>
  );
};

type PileDashboardInnerProps = WithGetPileContainerHocChildProps &
  WithGetPileHocChildProps &
  WithGetActivePileCableLinksHocChildProps & {
    viewer: ViewerFragmentFragment;
    goPileCableForm: (arg: { cable_id: number }) => void;
  };

export const PileDashboardInnerBase = ({
  grain_container,
  pile,
  active_pile_cable_links,
  loading,
  viewer,
  goPileCableForm,
}: PileDashboardInnerProps) => {
  if (loading) {
    return <DialogSpinner title="Loading Dashboard..." open={loading} />;
  }
  if (!grain_container || !pile) {
    return <NoContainerFoundPlaceholder />;
  }

  const [selected_cable, setSelectedCable] = useState<Cable | null>(null);

  useEffect(() => {
    setSelectedCable(null);
  }, [grain_container, pile, active_pile_cable_links]);

  const selectCable = (selection: Cable | null) => {
    if (selection && selected_cable && selection.cable_id === selected_cable.cable_id) {
      setSelectedCable(null);
    } else {
      setSelectedCable(selection);
    }
  };

  const {
    account: { system_account },
    user: { role },
  } = viewer;
  const diagnostic_mode = system_account && role === UserRole.Admin;

  const { active_hub_links } = grain_container;

  const pellet_map = new Map(
    grain_container.telemetry.pellets.map((pellet) => [pellet.pellet_id, pellet])
  );
  const pile_cables =
    active_pile_cable_links &&
    active_pile_cable_links.map(
      ({
        cable_id,
        alias,
        x_ft,
        y_ft,
        cable: {
          spacing_ft,
          pellet_group: { pellet_links },
          pellet_group: { package_alias },
        },
      }) => {
        const { min, max, pellets } = pellet_links
          .filter((pellet) => !pellet.reassigned_at)
          .reverse() // reverse to put the cable index 0 on bottom (cables returned in order sorted by position_no)
          .reduce(
            (acc, { pellet_id }) => {
              const data = pellet_map.get(pellet_id);
              if (!data) {
                acc.pellets.push({ pellet_id });
                return acc;
              }
              acc.pellets.push({ pellet_id, grain_status: (data as any).latest });
              const { latest } = data as any;
              if (!latest) {
                return acc;
              }
              const { temp_f } = latest;
              if (temp_f === undefined || temp_f === null) {
                return acc;
              }
              return {
                min: isNaN(acc.min) ? temp_f : Math.min(acc.min, temp_f),
                max: isNaN(acc.max) ? temp_f : Math.max(acc.max, temp_f),
                pellets: acc.pellets,
              };
            },
            {
              min: NaN,
              max: NaN,
              pellets: [] as { pellet_id: string; grain_status?: any }[],
            }
          );
        return {
          cable_id,
          min,
          max,
          pellets,
          x_ft,
          y_ft,
          spacing_ft,
          alias,
          package_alias,
        };
      }
    );

  return (
    <>
      {diagnostic_mode &&
        active_hub_links &&
        active_hub_links.map(({ core_id, hub }) => (
          <Grid key={core_id} item xs={12}>
            <Typography variant="h6">
              Hub {(hub && hub.alias) || ''} - {core_id}
            </Typography>
          </Grid>
        ))}
      {active_hub_links && active_hub_links.length === 0 && (
        <Grid item xs={12}>
          <h3
            style={{
              color: red['500'],
              marginBottom: 0,
              marginTop: 10,
            }}
          >
            No Hub Assigned
          </h3>
        </Grid>
      )}
      {active_hub_links.length === 0 ? null : (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={2}
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <PileDetailsCard
              pile={pile}
              pile_cables={pile_cables || []}
              selected_cable_id={selected_cable && selected_cable.cable_id}
              setSelectedCable={selectCable}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <PileCablesTableCard
              cables={pile_cables || []}
              selected_cable={selected_cable}
              loading={loading}
              setSelectedCable={selectCable}
            />
          </Grid>
          {selected_cable && (
            <Grid item xs={12} lg={6}>
              <PileCableDetailsCard
                selected_cable={selected_cable}
                container_id={grain_container && grain_container.container_id}
                goPileCableForm={goPileCableForm}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

const PileDashboardInner = withGetPileHoc(
  withGetActivePileCableLinksHoc(withGetPileContainerHoc(PileDashboardInnerBase))
);
