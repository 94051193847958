import * as React from 'react';
const UncheckedRadioIcon = (props) => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.1667 0C4.64667 0 0.166672 4.48 0.166672 10C0.166672 15.52 4.64667 20 10.1667 20C15.6867 20 20.1667 15.52 20.1667 10C20.1667 4.48 15.6867 0 10.1667 0ZM10.1667 18C5.74667 18 2.16667 14.42 2.16667 10C2.16667 5.58 5.74667 2 10.1667 2C14.5867 2 18.1667 5.58 18.1667 10C18.1667 14.42 14.5867 18 10.1667 18Z"
      fill="#D1D3D9"
    />
  </svg>
);
export { UncheckedRadioIcon };
