import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Spinner, { SpinnerProps } from 'react-spinkit';

import { amber_amber } from '../../style';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'stretch',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: 50,
  },
});

export const CenteredSpinner: React.FunctionComponent<SpinnerProps & { className?: string }> = ({
  className,
  color,
  ...other
}) => {
  const classes = useStyles();
  return (
    <div className={className || classes.root}>
      <Spinner {...other} color={amber_amber} />
    </div>
  );
};
