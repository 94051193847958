import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import format from 'date-fns/format';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { GetProps } from 'react-redux';
import * as yup from 'yup';

import {
  BinEventFragmentFragment,
  BinEventType,
  GrainBinFragmentFragment,
  withCreateBinEventHoc,
  WithCreateBinEventHocChildProps,
  withGetGrainBinEventsHoc,
  WithGetGrainBinEventsHocChildProps,
  withGetGrainBinHoc,
  WithGetGrainBinHocChildProps,
  withRemoveBinEventHoc,
  WithRemoveBinEventHocChildProps,
} from '../../api';
import { formatNumber, formatPercent, normalizeFloat, normalizeInt } from '../../util';
import { DialogSpinner } from '../spinner';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../util/form2/BaseForm';
import { ButtonSubmit } from '../util/form2/Button';
import { ErrorBox } from '../util/form2/ErrorBox';
import { SelectBinEventType, yup_bin_event_type } from '../util/form2/SelectBinEventType';
import { TextField } from '../util/form2/TextField';

const FIELDS = {
  date: 'date',
  time: 'time',
  event_type: 'event_type',
  event_value: 'event_value',
  selected_bin_event_row: 'selected_bin_event_row',
};
const null_formatter = () => '';
const numberFormatter = (x) => formatNumber(x);

const number_fields = [
  BinEventType.GrainAdd,
  BinEventType.GrainRemove,
  BinEventType.SensorsDeploy,
  BinEventType.SensorsRemove,
];
 
const with_field = [...number_fields, BinEventType.MoistureSample, BinEventType.Other];
const EventTypeValueField = ({ name }: { name: string }) => {
  const [{ value }] = useField<Values>('event_type');
  return with_field.indexOf(value) === -1 ? null : (
    <Grid item xs={12}>
      {value === BinEventType.MoistureSample && (
        <TextField
          name={name}
          label="Moisture (%)"
          placeholder="'15.2' for 15.2%"
          inputProps={{ inputMode: 'numeric' }}
        />
      )}
      {value === BinEventType.GrainAdd && (
        <TextField
          name={name}
          label="Bushels Added"
          placeholder="Bushels Added"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
      )}
      {value === BinEventType.GrainRemove && (
        <TextField
          name={name}
          label="Bushels Removed"
          placeholder="Bushels Removed"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
      )}
      {(value === BinEventType.SensorsDeploy || value === BinEventType.SensorsRemove) && (
        <TextField
          name={name}
          label="Count (#)"
          placeholder="Count (#)"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
      )}
      {value === BinEventType.Other && <TextField name={name} label="Description" />}
    </Grid>
  );
};

const EVENT_TYPES = [
  {
    value: BinEventType.MoistureSample,
    label: 'Moisture Sampled',
    value_formatter: (x) => formatPercent(x, 1),
  },
  {
    value: BinEventType.FanStart,
    label: 'Fans Started',
    value_formatter: null_formatter,
  },
  {
    value: BinEventType.FanStop,
    label: 'Fans Stopped',
    value_formatter: null_formatter,
  },
  {
    value: BinEventType.LidOpen,
    label: 'Top Lid Opened',
    value_formatter: null_formatter,
  },
  {
    value: BinEventType.LidClose,
    label: 'Top Lid Closed',
    value_formatter: null_formatter,
  },
  {
    value: BinEventType.HeatStart,
    label: 'Heat Activated',
    value_formatter: null_formatter,
  },
  {
    value: BinEventType.HeatStop,
    label: 'Heat Deactivated',
    value_formatter: null_formatter,
  },
  {
    value: BinEventType.GrainAdd,
    label: 'Grain Added',
    value_formatter: numberFormatter,
  },
  {
    value: BinEventType.GrainRemove,
    label: 'Grain Removed',
    value_formatter: numberFormatter,
  },
  {
    value: BinEventType.BinEmpty,
    label: 'Bin Emptied',
    value_formatter: null_formatter,
  },
  {
    value: BinEventType.BinCore,
    label: 'Bin Cored',
    value_formatter: null_formatter,
  },
  {
    value: BinEventType.FanSeal,
    label: 'Fans Sealed',
    value_formatter: null_formatter,
  },
  {
    value: BinEventType.FanUnseal,
    label: 'Fans Unsealed',
    value_formatter: null_formatter,
  },
  {
    value: BinEventType.FumStart,
    label: 'Fumigation Started',
    value_formatter: null_formatter,
  },
  {
    value: BinEventType.FumStop,
    label: 'Fumigation Completed',
    value_formatter: null_formatter,
  },
  {
    value: BinEventType.SensorsDeploy,
    label: 'Sensors Deployed',

    value_formatter: numberFormatter,
  },
  {
    value: BinEventType.SensorsRemove,
    label: 'Sensors Removed',

    value_formatter: numberFormatter,
  },
  {
    value: BinEventType.Other,
    label: 'Other',

    value_formatter: (x) => x,
  },
];
const EVENT_TYPE_LOOKUP = EVENT_TYPES.reduce((result, event_type) => {
  // eslint-disable-next-line
  result[event_type.value] = event_type;
  return result;
}, {});

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    textAlign: 'center',
  },
  double_field: { display: 'flex', justifyContent: 'center', flexWrap: 'wrap' },
  picker: { display: 'inline-block', width: '95%', minWidth: 100, maxWidth: 125 },
  button_wrapper: { marginTop: 10 },
  table: {
    maxWidth: 500,
  },
  row: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    cursor: 'pointer',
  },
  row_active: { backgroundColor: theme.palette.action.hover },
  cell: {
    whiteSpace: 'normal',
    wordWrap: 'normal',
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

type Values = {
  grain_bin_id: number;
  date: string;
  time: string;
  event_type: BinEventType | null;
  selected_row: number | null;
  bin_events: BinEventFragmentFragment[];
  event_value: string;
};
const validationSchema = yup.object().shape({
  grain_bin_id: yup
    .number()
    .typeError('A number is required')
    .integer()
    .required(),
  date: yup
    .string()
    .when('selected_row', (value, schema) => (value !== schema ? schema : schema.required())),
  time: yup
    .string()
    .when('selected_row', (value, schema) => (value !== schema ? schema : schema.required())),
  event_type: yup_bin_event_type.when('selected_row', (value, schema) =>
    value !== schema ? schema : schema.required().nullable(false)
  ),
  selected_row: yup
    .number()
    .typeError('A number is required')
    .integer()
    .nullable(true),
  event_value: yup.mixed().when('selected_row', (value, schema) =>
    value !== schema
      ? schema
      : schema.when('event_type', (value, schema) => {
          if (with_field.indexOf(value)) {
            return schema;
          }
          if (value === BinEventType.Other) {
            return yup
              .string()
              .required()
              .label('Event Value');
          }
          return yup
            .number()
            .typeError('A number is required')
            .required()
            .label('Event Value');
        })
  ),
});

export const BinEventsFormBase = ({
  bin_events,
  createBinEvent,
  removeBinEvent,
  grain_bin: { bin_name, grain_bin_id },
  ...props
}: FormikWrapperHandlerProps<Values, BinEventFragmentFragment[]> &
  WithCreateBinEventHocChildProps &
  WithRemoveBinEventHocChildProps & {
    grain_bin: GrainBinFragmentFragment;
    bin_events: BinEventFragmentFragment[];
  }) => {
  const classes = useStyles();
  const submitCallback = useCallback(async ({ bin_events, ...values }) => {
    const {
      grain_bin_id,
      selected_row,
      date,
      time,
      event_type,
      event_value,
    } = validationSchema.validateSync(values);
    if (selected_row !== null) {
      const { epoch_time, event_type } = bin_events[selected_row];
      return await removeBinEvent({
        grain_bin_id,
        epoch_time,
        event_type,
      });
    }
    const epoch_time = new Date(`${date} ${time}`);
    let event_val;
    if (event_type === BinEventType.MoistureSample) {
      event_val = (normalizeFloat(event_value) as number) / 100;
    } else if (event_type === BinEventType.GrainAdd || event_type === BinEventType.GrainRemove) {
      event_val = normalizeInt(event_value);
    } else {
      event_val = event_value;
    }
    return await createBinEvent({
      grain_bin_id,
      epoch_time,
      event_type: event_type as BinEventType,
      event_value: JSON.stringify(event_val),
    });
  }, []);
  return (
    <FormikWrapper<Values, BinEventFragmentFragment[]>
      {...props}
      validationSchema={validationSchema}
      initialValues={{
        grain_bin_id,
        bin_events,
        selected_row: null,
        date: '',
        time: '',
        event_value: '',
        event_type: null,
      }}
      onSubmit={submitCallback}
      render={({ values: { selected_row, bin_events }, setFieldValue }) => {
        return (
          <BaseForm>
            {/*<DialogSpinner title="Loading Bin Events..." open={loading} />*/}
            <Grid container spacing={2} classes={{ container: classes.table }}>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.header}>
                  {bin_name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="date"
                  label="Date"
                  placeholder="MM/DD/YYYY"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={selected_row !== null}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="time"
                  label="Time"
                  placeholder="--:-- --"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={selected_row !== null}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <SelectBinEventType
                  displayEmpty
                  fullWidth
                  name={FIELDS.event_type}
                  label="Event Type"
                  disabled={selected_row !== null}
                />
              </Grid>
              <EventTypeValueField name="event_value" />
              <ErrorBox />
              <Grid item xs={12}>
                <ButtonSubmit disabled={selected_row !== null}>Create Event</ButtonSubmit>
                <ButtonSubmit disabled={selected_row === null}>Remove Selected</ButtonSubmit>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
              justify="center"
              // onRowSelection={onSelectRow}
            >
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={5}>
                  Time
                </Grid>
                <Grid item xs={3}>
                  Event Type
                </Grid>
                <Grid item xs={4}>
                  Value
                </Grid>
              </Grid>
              {bin_events.map(({ epoch_time, event_type, event_value }, key) => {
                const epoch_time2 = new Date(epoch_time);
                const { label } = EVENT_TYPE_LOOKUP[event_type];
                return (
                  <Grid
                    key={key}
                    item
                    xs={12}
                    container
                    spacing={2}
                    onClick={() => setFieldValue('selected_row', key)}
                    classes={{
                      item: `${classes.row}${key === selected_row ? ` ${classes.row_active}` : ''}`,
                    }}
                  >
                    <Grid item xs={5} className={classes.cell}>
                      {format(epoch_time2, 'MM/DD/YYYY hh:mm A')}
                    </Grid>
                    <Grid item xs={3} className={classes.cell}>
                      {label}
                    </Grid>
                    <Grid item xs={4} className={classes.cell}>
                      {event_type === BinEventType.MoistureSample &&
                        formatPercent(JSON.parse(event_value))}
                      {number_fields.indexOf(event_type) === -1 &&
                        formatNumber(JSON.parse(event_value))}
                      {event_type === BinEventType.Other && JSON.parse(event_value)}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </BaseForm>
        );
      }}
    />
  );
};

export const BinEventsForm = withGetGrainBinHoc(
  withGetGrainBinEventsHoc(
    withCreateBinEventHoc(
      withRemoveBinEventHoc(
        ({
          loading,
          grain_bin,
          bin_events,
          ...props
        }: WithGetGrainBinHocChildProps &
          WithGetGrainBinEventsHocChildProps &
          GetProps<typeof BinEventsFormBase>) => {
          if (loading) {
            return <DialogSpinner open title="Loading..." />;
          }
          if (!grain_bin || !bin_events) {
            return <Typography>Unable to load data from the server</Typography>;
          }
          return <BinEventsFormBase {...props} grain_bin={grain_bin} bin_events={bin_events} />;
        }
      )
    )
  )
);
