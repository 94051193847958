import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetContainerGrainLevelPercentQuery as Result,
  withGetContainerGrainLevelPercent as HOC,
} from '../__generated';

export type WithGetContainerGrainLevelPercentHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetContainerGrainLevelPercentHocChildProps = {
  container_grain_level_percent_loading: boolean;
  container_grain_level_percent: Result['container_grain_level_percent'];
};
export const withGetContainerGrainLevelPercentHoc: AmberHoc<
  WithGetContainerGrainLevelPercentHocOwnProps,
  WithGetContainerGrainLevelPercentHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetContainerGrainLevelPercentHocOwnProps,
      WithGetContainerGrainLevelPercentHocChildProps,
      typeof component
    >,
    WithGetContainerGrainLevelPercentHocChildProps
  >({
    options: ({ grain_bin_id, onError }) => ({
      onError,
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      variables: { grain_bin_id },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          container_grain_level_percent_loading: true,
          container_grain_level_percent: null,
        };
      }
      return {
        container_grain_level_percent_loading: loading || false,
        container_grain_level_percent: data.container_grain_level_percent as Result['container_grain_level_percent'],
      };
    },
  })(component as any);
