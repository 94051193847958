import React, { ReactNode } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import {
  AccountFragmentFragment,
  ViewerFragmentFragment,
  withGetAccountHoc,
  WithGetAccountHocChildProps,
  WithGetAccountHocOwnProps,
  withGetViewerHoc,
  WithGetViewerHocChildProps,
} from '../../api';
import { DialogSpinner } from '../spinner';

export type AuthenticatedRouteProps = {
  loading?: boolean;
  children?: React.ReactNode;
  validateAccess?: (account: AccountFragmentFragment, viewer: ViewerFragmentFragment) => boolean;
  accessFailureRedirect?: (
    account: AccountFragmentFragment,
    viewer: ViewerFragmentFragment
  ) => string;
  render?: (props: {
    viewer: ViewerFragmentFragment;
    account: AccountFragmentFragment;
  }) => ReactNode;
};

const AuthenticatedRouteBase: React.FunctionComponent<
  AuthenticatedRouteProps &
    RouteComponentProps &
    WithGetViewerHocChildProps &
    WithGetAccountHocChildProps
> = ({
  account,
  location: { pathname },
  loading,
  viewer,
  children,
  validateAccess,
  accessFailureRedirect,
  render,
}) => {
  if (loading) {
    return <DialogSpinner title="Initializing..." open />;
  }
  if (!viewer || !account) {
    return (
      <Switch>
        <Route render={() => <Redirect to="/login" from={pathname} />} />
      </Switch>
    );
  }
  if (validateAccess && !validateAccess(account, viewer)) {
    const redirect = accessFailureRedirect ? accessFailureRedirect(account, viewer) : '/login';
    return (
      <Switch>
        <Route render={() => <Redirect to={redirect} from={pathname} />} />
      </Switch>
    );
  }
  if (render) {
    return <>{render({ viewer, account })}</>;
  }
  return <>{children || null}</>;
};
export const AuthenticatedRoute: React.ComponentType<
  AuthenticatedRouteProps & WithGetAccountHocOwnProps
> = withRouter(withGetAccountHoc(withGetViewerHoc(AuthenticatedRouteBase)));
