import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetContainersLastAerationRunQuery as Result,
  withGetContainersLastAerationRun as HOC,
} from '../__generated';

export type WithGetContainersLastAerationRunProps = {
  grain_bin_ids: number[];
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetContainersLastAerationRunHocChildProps = {
  containers_last_aeration_run_loading: boolean;
  containers_last_aeration_run: Result['containers_last_aeration_run'];
  containersLastAerationRunRefetch: () => Promise<any>;
};
export const withGetContainersLastAerationRunHoc: AmberHoc<
  WithGetContainersLastAerationRunProps,
  WithGetContainersLastAerationRunHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetContainersLastAerationRunProps,
      WithGetContainersLastAerationRunHocChildProps,
      typeof component
    >,
    WithGetContainersLastAerationRunHocChildProps
  >({
    options: ({ grain_bin_ids, onError }) => ({
      onError,
      variables: { grain_bin_ids },
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      context: { important: true }, // flag to prevent from graphql batching
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          containers_last_aeration_run_loading: true,
          containers_last_aeration_run: [] as Result['containers_last_aeration_run'],
          containersLastAerationRunRefetch: () => Promise.resolve(),
        };
      }
      return {
        containers_last_aeration_run_loading: loading || false,
        containers_last_aeration_run:
          (data.containers_last_aeration_run as Result['containers_last_aeration_run']) || [],
        containersLastAerationRunRefetch: () => data.refetch(),
      };
    },
  })(component as any);
