import { AmberHoc, AmberHocProps } from '../../../util';
import {
  SubscribeWeeklyGrainEmailMutation as Result,
  SubscribeWeeklyGrainEmailMutationVariables as Variables,
  withSubscribeWeeklyGrainEmail as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithSubscribeWeeklyGrainEmailHocChildProps = {
  subscribeWeeklyGrainEmail: (variables: Variables) => Promise<Result['subscribeWeeklyGrainEmail']>;
};

export const withSubscribeWeeklyGrainEmailHoc: AmberHoc<
  {},
  WithSubscribeWeeklyGrainEmailHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithSubscribeWeeklyGrainEmailHocChildProps, typeof component>,
    WithSubscribeWeeklyGrainEmailHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        subscribeWeeklyGrainEmail: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }

          const result = await mutate({ variables });

          if (!result) {
            throw new Error('Unexpected server response');
          }

          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.subscribeWeeklyGrainEmail;
        },
      };
    },
  })(component as any);
