import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { MassUnitType } from '../../../../../core/src/api';

const massUnits = [
  { label: 'Pound(lbs)', value: 'pound' },
  { label: 'Kilogram(kg)', value: 'kilogram' },
];

type SelectProps = {
  name: string;
  selectedValue: MassUnitType;
  isDisabled?: boolean;
  onChange: (value: { value: MassUnitType; label: string }) => void;
};

export const SelectMassUnit: FunctionComponent<SelectProps> = ({
  name,
  selectedValue,
  ...props
}) => {
  const defaultOption = massUnits.find((option) => option.value === selectedValue);
  return (
    <Select options={massUnits} name={name} value={defaultOption} isSearchable={false} {...props} />
  );
};
