import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { ExperimentCfmValues, withGetGrainBinExperimentCfmValues as HOC } from '../__generated';

export type WithGetGrainBinExperimentCfmValuesHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainBinExperimentCfmValuesHocChildProps = {
  grain_bin_exp_cfm_values_loading: boolean;
  grain_bin_experirment_cfm_values: ExperimentCfmValues | null;
};
export const withGetGrainBinExperimentCfmValuesHoc: AmberHoc<
  WithGetGrainBinExperimentCfmValuesHocOwnProps,
  WithGetGrainBinExperimentCfmValuesHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainBinExperimentCfmValuesHocOwnProps,
      WithGetGrainBinExperimentCfmValuesHocChildProps,
      typeof component
    >,
    WithGetGrainBinExperimentCfmValuesHocChildProps
  >({
    options: ({ grain_bin_id, onError }) => ({
      onError,
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      variables: { grain_bin_id },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          grain_bin_exp_cfm_values_loading: true,
          grain_bin_experirment_cfm_values: null,
        };
      }
      return {
        grain_bin_exp_cfm_values_loading: loading || false,
        grain_bin_experirment_cfm_values: data.grain_bin_experiment_cfm_values || null,
      };
    },
  })(component as any);
