import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import React from 'react';

const DD_BASE_URL = 'https://app.datadoghq.com';
const DD_LOGS_BASE_URL = `${DD_BASE_URL}/logs`;
const DD_DASHBOARD_BASE_URL = `${DD_BASE_URL}/dashboard`;
const DD_FAN_DASHBOARD_URL = `${DD_DASHBOARD_BASE_URL}/2bg-3ys-bxg/mcfarland-micro-dashboard`;
const DD_HUB_DASHBOARD_URL = `${DD_DASHBOARD_BASE_URL}/tev-jmp-g3r/davenport-micro-dashboard`;
const INTERNAL_SITE_PROD_URL = 'https://d37xg6hkr7x5tm.cloudfront.net';
const INTERNAL_SITE_DEV_URL = 'https://d3br1tj0an32t7.cloudfront.net';

type DiagnosticModeInfoSectionProps = {
  account_id: number;
  grain_bin_id: number;
  grain_bin_fan_controllers: any;
  grain_bin_cfm_solution: any;
  active_hub_links: any;
};

const DiagnosticModeInfoSection = ({
  account_id,
  grain_bin_id,
  grain_bin_fan_controllers,
  grain_bin_cfm_solution,
  active_hub_links,
}: DiagnosticModeInfoSectionProps) => {
  const stage = window['app_config'] ? window['app_config'].env : 'local';
  const INTERNAL_SITE_BASE_URL = ['prod', 'beta'].includes(stage)
    ? INTERNAL_SITE_PROD_URL
    : INTERNAL_SITE_DEV_URL;
  console.log('active_hub_links in DiagnosticModeInfoSection', active_hub_links);
  const accountIdDDLink = `${DD_LOGS_BASE_URL}?query=%22account_id%20${account_id}%22%20`;
  const grainBinIdDDLink = `${DD_LOGS_BASE_URL}?query=%22grain_bin_id%20${grain_bin_id}%22%20`;
  const hasCFMInfoPresent =
    grain_bin_cfm_solution &&
    grain_bin_cfm_solution.grain_bin_height_solution &&
    grain_bin_cfm_solution.grain_bin_solution;
  const noOfFans = grain_bin_fan_controllers
    ? grain_bin_fan_controllers.reduce((acc, gbfc) => acc + gbfc.no_of_fans_connected, 0)
    : 0;
  const getCurrentGrainLevelPercent = (currentDepthTotalBushels, maxDepthTotalBushels) => {
    console.log('currentDepthTotalBushels & maxDepthTotalBushels', {
      currentDepthTotalBushels,
      maxDepthTotalBushels,
    });
    return maxDepthTotalBushels
      ? ((currentDepthTotalBushels / maxDepthTotalBushels) * 100).toFixed(1)
      : 0;
  };
  const currentGrainLevelPercent =
    grain_bin_cfm_solution &&
    grain_bin_cfm_solution.grain_bin_solution &&
    grain_bin_cfm_solution.grain_bin_height_solution
      ? getCurrentGrainLevelPercent(
          grain_bin_cfm_solution.grain_bin_solution.total_bushels,
          grain_bin_cfm_solution.grain_bin_height_solution.total_bushels
        )
      : 0;
  return (
    <ExpansionPanel
      style={{ margin: '10px 0px', border: '1px solid red', textAlign: 'start', borderRadius: 5 }}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5">Diagnostic Info</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          <Grid key={account_id} item xs={12} style={{ paddingBottom: 10 }}>
            <Typography variant="h6">
              <a href={accountIdDDLink} target="_blank">
                Account Id
              </a>
              &nbsp;: {account_id}
            </Typography>
            <Typography variant="h6">
              <a href={grainBinIdDDLink} target="_blank">
                Grain Bin Id
              </a>
              &nbsp;: {grain_bin_id}
            </Typography>
          </Grid>
          {active_hub_links &&
            active_hub_links.map(({ core_id, nickname, hub, hub_container_link_id, hub_id }) => {
              const amberID = (hub && hub.alias) || '';
              const hubDDLink = `${DD_LOGS_BASE_URL}?query=%28${amberID}%20OR%20${core_id}%20OR%20%22hub_container_link_id%20${hub_container_link_id}%22%20OR%20%22hub_id%20${hub_id}%22%20OR%20host%3A${core_id}%29%20`;
              const hubCoreIdDDLink = `${DD_HUB_DASHBOARD_URL}?tpl_var_CoreID%5B0%5D=${core_id}`;
              return (
                <Grid key={core_id} item xs={12} style={{ paddingBottom: 10 }}>
                  <Typography variant="h6">
                    <a href={hubDDLink} target="_blank">
                      Hub
                    </a>
                    &nbsp;: {nickname || ''} | {amberID} |{' '}
                    <a href={hubCoreIdDDLink} target="_blank">
                      {core_id}
                    </a>{' '}
                    | hub_container_link_id : {hub_container_link_id} | hub_id : {hub_id}
                  </Typography>
                </Grid>
              );
            })}
          {grain_bin_fan_controllers &&
            grain_bin_fan_controllers.map(
              ({ alias, fan_controller, grain_bin_fan_controller_id, fan_controller_id_next }) => {
                const internalSiteFcURL = `${INTERNAL_SITE_BASE_URL}/internal/fan_controllers/${fan_controller_id_next}`;
                const amberID = (fan_controller && fan_controller.alias) || '';
                const fanControllerDDLink = `${DD_LOGS_BASE_URL}?query=%28${amberID}%20OR%20${
                  fan_controller.core_id
                }%20OR%20%22grain_bin_fan_controller_id%20${grain_bin_fan_controller_id}%22%20OR%20%22fan_controller_id%20${fan_controller_id_next}%22%20OR%20host%3A${
                  fan_controller.core_id
                }%29%20`;
                const fanCoreIdDDLink = `${DD_FAN_DASHBOARD_URL}?tpl_var_CoreID%5B0%5D=${
                  fan_controller.core_id
                }`;

                return (
                  <Grid
                    key={String(fan_controller ? fan_controller.core_id : alias)}
                    item
                    xs={12}
                    style={{
                      paddingBottom: 10,
                    }}
                  >
                    <Typography variant="h6">
                      <a href={fanControllerDDLink} target="_blank">
                        Fan
                      </a>
                      &nbsp;: {alias || ''} | {amberID} |{' '}
                      {fan_controller && (
                        <a href={fanCoreIdDDLink} target="_blank">
                          {fan_controller.core_id}
                        </a>
                      )}{' '}
                      | grain_bin_fan_controller_id : {grain_bin_fan_controller_id} |{' '}
                      <a href={internalSiteFcURL} target="_blank">
                        fan_controller_id : {fan_controller_id_next}
                      </a>
                    </Typography>
                  </Grid>
                );
              }
            )}

          {hasCFMInfoPresent ? (
            <Grid item xs={12} style={{ paddingBottom: 10 }}>
              {grain_bin_cfm_solution.grain_bin_height_solution && (
                <Typography variant="h6">
                  CFM (Full Bin) : {noOfFans} Fan, {grain_bin_cfm_solution.grain_type}, 100% fill,{' '}
                  {grain_bin_cfm_solution.grain_bin_height_solution.grain_depth_ft.toFixed(1)} ft
                  grain depth,{' '}
                  {grain_bin_cfm_solution.grain_bin_height_solution.total_bushels.toFixed(1)} bu -{' '}
                  {grain_bin_cfm_solution.fan_model.fan_model_name} -{' '}
                  {grain_bin_cfm_solution.grain_bin_height_solution.cfm_per_bushel.toFixed(3)}{' '}
                  cfm/bu-{' '}
                  {grain_bin_cfm_solution.grain_bin_height_solution.static_pressure.toFixed(2)} in.
                  H2O
                </Typography>
              )}
              {grain_bin_cfm_solution.grain_bin_solution && (
                <Typography variant="h6">
                  CFM (Current Level) : {noOfFans} Fan, {grain_bin_cfm_solution.grain_type},{' '}
                  {currentGrainLevelPercent}% fill,{' '}
                  {grain_bin_cfm_solution.grain_bin_solution.grain_depth_ft.toFixed(1)} ft grain
                  depth, {grain_bin_cfm_solution.grain_bin_solution.total_bushels.toFixed(1)} bu -{' '}
                  {grain_bin_cfm_solution.fan_model.fan_model_name} -{' '}
                  {grain_bin_cfm_solution.grain_bin_solution.cfm_per_bushel.toFixed(3)} cfm/bu-{' '}
                  {grain_bin_cfm_solution.grain_bin_solution.static_pressure.toFixed(2)} in. H2O
                </Typography>
              )}
            </Grid>
          ) : (
            <Typography variant="h6">Cfm - No nearest fan curve present</Typography>
          )}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default DiagnosticModeInfoSection;
