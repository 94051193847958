import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAccountId } from '../../action';
import { AccountFragmentFragment, UserRole, ViewerFragmentFragment } from '../../api';
import { ContainerList } from './ContainerList';

const selectAccountId = ({ selected: { account_id } }) => account_id;
const sessionAccountId = ({ session: { account_id } }) => account_id;

export const ContainerSelect: FunctionComponent<{
  mobile_layout?: boolean;
  pathname: string;
  viewer: ViewerFragmentFragment;
  onSelectContainer: (args: any) => void;
  goCreateBarge: () => void;
  goCreatePile: () => void;
  goCreateGrainBin: () => void;
}> = ({
  mobile_layout = false,
  pathname,
  viewer,
  onSelectContainer,
  goCreateBarge,
  goCreatePile,
  goCreateGrainBin,
}) => {
  const dispatch = useDispatch();
  const account_id = useSelector(selectAccountId) || viewer.account_id;
  const setSelectedAccountId = useCallback(
    (account: AccountFragmentFragment) => dispatch(setAccountId({ account })),
    [dispatch]
  );
  if (!account_id) {
    setSelectedAccountId(viewer.account as AccountFragmentFragment);
  }
  const {
    account: { system_account },
    user: { role },
  } = viewer;
  const admin_mode = system_account && role === UserRole.Admin;
  return (
    <ContainerList
      viewer={viewer}
      mobile_layout={mobile_layout}
      pathname={pathname}
      admin_mode={Boolean(admin_mode)}
      account_id={account_id}
      onSelectContainer={onSelectContainer}
      goCreateBarge={goCreateBarge}
      goCreatePile={goCreatePile}
      goCreateGrainBin={goCreateGrainBin}
      setSelectedAccountId={setSelectedAccountId}
    />
  );
};
