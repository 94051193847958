import React, { FunctionComponent } from 'react';

import { GrainStatusFragmentFragment } from '../../../api/graphql';
import { formatNumber } from '../../../util';
import { DialogSpinner } from '../../spinner';
import { ColumnConfig, ItemTable } from '../../util';

const formatTemp = (temp_f) => (temp_f === null || isNaN(temp_f) ? 'N/A' : formatNumber(temp_f, 1));
const getId = ({ cable_id }: Cable) => cable_id;

export type Cable = {
  cable_id: number;
  alias: string;
  package_alias: string;
  min: number;
  max: number;
  x_ft?: number;
  y_ft?: number;
  spacing_ft?: number;
  pellets: { pellet_id: string; grain_status?: GrainStatusFragmentFragment }[];
};

const columns: ColumnConfig<Cable>[] = [
  {
    title: 'ID',
    width: 30,
    align: 'left',
    getValue: ({ package_alias, alias }) => (
      <>
        <div>{package_alias}</div>
        {alias && <div>{alias}</div>}
      </>
    ),
  },
  {
    title: 'Max Temp (F)',
    width: 20,
    align: 'left',
    getValue: ({ max }) => formatTemp(max),
  },
  {
    title: 'Min Temp (F)',
    width: 20,
    align: 'left',
    getValue: ({ min }) => formatTemp(min),
  },
];

export const PileCablesTable: FunctionComponent<{
  cables: Cable[];
  loading: boolean;
  selected_cable: Cable | null;
  onSelectPileCable: (item: Cable | null) => void;
}> = ({ cables, loading, selected_cable, onSelectPileCable }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <DialogSpinner open={loading} title="Loading..." />
      <div style={{ display: 'border-box', width: '100%', overflowX: 'auto' }}>
        <ItemTable<Cable>
          items={cables}
          getId={getId}
          columns={columns}
          onSelect={onSelectPileCable}
          value={selected_cable}
        />
      </div>
    </div>
  );
};
