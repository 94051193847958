import axios from 'axios';

/* Last test result apis */
const getDiagQuickTestLastResultApi = (core_id: string) => {
  const baseUrl = `${window['app_config'].rest_api_host_name}`;
  console.log('REST api baseUrl:', baseUrl);
  return axios.get(`${baseUrl}/diag-quick-test-last-result/${core_id}`);
};

const getDiagLongTestLastResultApi = (core_id: string) => {
  const baseUrl = `${window['app_config'].rest_api_host_name}`;
  console.log('REST api baseUrl:', baseUrl);
  return axios.get(`${baseUrl}/diag-long-test-last-result/${core_id}`);
};

const getDiagCellMetricTestLastResultApi = (core_id: string) => {
  const baseUrl = `${window['app_config'].rest_api_host_name}`;
  console.log('REST api baseUrl:', baseUrl);
  return axios.get(`${baseUrl}/diag-cell-metric-test-last-result/${core_id}`);
};

/* Run diagnostics tests apis */

const runDiagQuickTestApi = (core_id: string) => {
  const baseUrl = `${window['app_config'].rest_api_host_name}`;
  console.log('REST api baseUrl:', baseUrl);
  return axios.put(`${baseUrl}/run-diag-quick-test`, { core_id });
};

const runDiagLongTestApi = (core_id: string) => {
  const baseUrl = `${window['app_config'].rest_api_host_name}`;
  console.log('REST api baseUrl:', baseUrl);
  return axios.put(`${baseUrl}/run-diag-long-test`, { core_id });
};

const runDiagCellMetricTestApi = (core_id: string) => {
  const baseUrl = `${window['app_config'].rest_api_host_name}`;
  console.log('REST api baseUrl:', baseUrl);
  return axios.put(`${baseUrl}/run-diag-cell-metric-test`, { core_id });
};

/* Get latest test status apis */

const getDiagQuickTestLatestTestStatusApi = (core_id: string) => {
  const baseUrl = `${window['app_config'].rest_api_host_name}`;
  console.log('REST api baseUrl:', baseUrl);
  return axios.get(`${baseUrl}/diag-quick-test-latest-test-status/${core_id}`);
};

const getDiagLongTestLatestTestStatusApi = (core_id: string) => {
  const baseUrl = `${window['app_config'].rest_api_host_name}`;
  console.log('REST api baseUrl: ', baseUrl);
  return axios.get(`${baseUrl}/diag-long-test-latest-test-status/${core_id}`);
};

const getDiagCellMetricTestLatestTestStatusApi = (core_id: string) => {
  const baseUrl = `${window['app_config'].rest_api_host_name}`;
  console.log('REST api baseUrl:', baseUrl);
  return axios.get(`${baseUrl}/diag-cell-metric-test-latest-test-status/${core_id}`);
};

/* Sign off diagnostics tests */
const signOffDiagnosticTestApi = (core_id: string) => {
  const baseUrl = `${window['app_config'].rest_api_host_name}`;
  console.log('REST api baseUrl:', baseUrl);
  return axios.post(`${baseUrl}/sign-off-diag-test`, { core_id });
};

const getSignedOffDiagnosticTestApi = (core_id: string) => {
  const baseUrl = `${window['app_config'].rest_api_host_name}`;
  console.log('REST api baseUrl:', baseUrl);
  return axios.get(`${baseUrl}/sign-off-diag-test/${core_id}`);
};

const getDiagTestFanControllerApi = (core_id: string) => {
  const baseUrl = `${window['app_config'].rest_api_host_name}`;
  console.log('REST api baseUrl:', baseUrl);
  return axios.get(`${baseUrl}/diag-test-fan-controller/${core_id}`);
};

const getDiagTestHubApi = (core_id: string) => {
  const baseUrl = `${window['app_config'].rest_api_host_name}`;
  console.log('REST api baseUrl:', baseUrl);
  return axios.get(`${baseUrl}/diag-test-hub/${core_id}`);
};

export type GetCellTowerGeocodesApiRequest = {
  homeMobileCountryCode: number;
  homeMobileNetworkCode: number;
  locationAreaCode: number;
  cellId: number;
};

export type ApiResponseBase = {
  status_code: number;
};
export type GetCellTowerGeocodesApiResponse = {
  lat: number | null;
  lng: number | null;
} & ApiResponseBase;
const getCellTowerGeocodesApi = async (
  request: GetCellTowerGeocodesApiRequest
): Promise<GetCellTowerGeocodesApiResponse | null> => {
  const { homeMobileCountryCode, homeMobileNetworkCode, locationAreaCode, cellId } = request;
  const base_url = `https://www.googleapis.com/geolocation/v1/geolocate?key=${
    window['app_config'].google_api_key
  }`;
  const request_body = {
    homeMobileCountryCode,
    homeMobileNetworkCode,
    cellTowers: [
      {
        cellId,
        locationAreaCode,
        mobileCountryCode: request.homeMobileCountryCode,
        mobileNetworkCode: request.homeMobileNetworkCode,
      },
    ],
  };
  const response = await axios.post(`${base_url}`, request_body);
  // const response = {
  //   data: {
  //     location:{
  //       lat: 41.918355,
  //       lng: -87.691302
  //     }
  //   },
  //   status: 200,
  // }
  if (response === null) {
    return null;
  }
  const api_response: GetCellTowerGeocodesApiResponse = {
    lat: response.data.location.lat,
    lng: response.data.location.lng,
    status_code: response.status,
  };
  return api_response;
};
export {
  getDiagQuickTestLastResultApi,
  getDiagLongTestLastResultApi,
  getDiagCellMetricTestLastResultApi,
  runDiagQuickTestApi,
  runDiagLongTestApi,
  runDiagCellMetricTestApi,
  getDiagQuickTestLatestTestStatusApi,
  getDiagLongTestLatestTestStatusApi,
  getDiagCellMetricTestLatestTestStatusApi,
  signOffDiagnosticTestApi,
  getSignedOffDiagnosticTestApi,
  getDiagTestFanControllerApi,
  getCellTowerGeocodesApi,
  getDiagTestHubApi,
};
