import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GrainBinStoragePeriodValidationResult as Result,
  ValidateStoragePeriodOverlapsMutationVariables as Variables,
  withValidateStoragePeriodOverlaps as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithGrainBinStoragePeriodValidationHocChildProps = {
  validateStoragePeriodOverlaps: (variables: Variables) => Promise<Result>;
};
export const withValidateStoragePeriodOverlapsHoc: AmberHoc<
  {},
  WithGrainBinStoragePeriodValidationHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithGrainBinStoragePeriodValidationHocChildProps, typeof component>,
    WithGrainBinStoragePeriodValidationHocChildProps
  >({
    options: { errorPolicy: 'all' },
    skip: true,
    withRef: false,
    props: (props) => {
      const { mutate } = props;
      return {
        validateStoragePeriodOverlaps: async (variables) => {
          console.log('storage validation vars', variables);
          // @ts-ignore
          const result = await mutate({ variables });

          // @ts-ignore
          const { errors, data } = result;

          if (errors) {
            throw new GraphQLErrors(errors);
          }

          return data.validateStoragePeriodOverlaps;
        },
      };
    },
  })(component as any);
