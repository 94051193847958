import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { BargeTemperatureDiagramCell } from './BargeTemperatureDiagramCell';

const useStyles = makeStyles({
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
  },
  verticalTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    height: '100%',
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
  },
});

const covers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

// changed to only show the low pellet i.e. xx0

const doorValues = (
  door_code: number,
  temperatures: { [door_no: number]: number },
  temp_changes: { [door_no: number]: number }
) => {
  const door_side = door_code ? 'Stbd' : 'Pt';
  if (temperatures) {
    return (
      <Grid container direction={'column'} justify="space-evenly">
        {covers.map((cover_number, ix) => {
          const temp = temperatures[Number(`${cover_number}${door_code}`)];
          return (
            <Grid
              item
              key={ix}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {door_code === 0 && <span>{`${cover_number}${door_side}`}</span>}
              <BargeTemperatureDiagramCell
                temperature={temp}
                temp_change={Math.round(temp_changes[Number(`${cover_number}${door_code}`)])}
              />
              {door_code === 1 && <span>{`${cover_number}${door_side}`}</span>}
            </Grid>
          );
        })}
      </Grid>
    );
  }
};

export const BargeTemperatureDiagram: React.FunctionComponent<{
  temperatures: { [door_no: number]: number };
  temp_changes: { [door_no: number]: number };
}> = ({ temperatures, temp_changes }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item>
        <div className={classes.textContainer}>
          <span>Deepest Pellet Readings</span>
        </div>
        <div className={classes.textContainer}>
          <span className={classes.title}>Bow</span>
        </div>
      </Grid>
      <Grid container item direction="row" justify="center">
        {/* Port values */}
        <Grid item>{doorValues(0, temperatures, temp_changes)}</Grid>
        {/* Starboard values */}
        <Grid item>{doorValues(1, temperatures, temp_changes)}</Grid>
      </Grid>
      <Grid item>
        <div className={classes.textContainer}>
          <span className={classes.title}>Stern</span>
        </div>
      </Grid>
    </Grid>
  );
};
