import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { withGetIsOtpValid as HOC } from '../__generated';

export type WithGetIsOtpValidHocOwnProps = {
  token: string;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetIsOtpValidHocChildProps = {
  loading: boolean;
  is_otp_valid: boolean;
};

export const withGetIsOtpValidHoc: AmberHoc<
  WithGetIsOtpValidHocOwnProps,
  WithGetIsOtpValidHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<WithGetIsOtpValidHocOwnProps, WithGetIsOtpValidHocChildProps, typeof component>,
    WithGetIsOtpValidHocChildProps
  >({
    options: ({ onError, token }) => ({
      onError,
      variables: { token },
      errorPolicy: onError ? 'all' : 'none',
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          is_otp_valid: false,
        };
      }
      return {
        loading: loading || false,
        is_otp_valid: Boolean(data.isOtpValid),
      };
    },
  })(component as any);
