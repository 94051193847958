import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DateTime, Settings } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  amber_dark_grey,
  Banner,
  ContainerTypeLegacy,
  estimateAndFormatBushels,
  formatGrainType,
  pushNav,
  setShowGrainTicketWarningBanner,
} from '../../../../../core/src';
import {
  UserRole,
  withGetGrainBinHoc,
  WithGetGrainBinHocChildProps,
  withGetGrainBinTicketsHoc,
  WithGetGrainBinTicketsHocChildProps,
  withGetGrainContainerHubLinksHoc,
  WithGetGrainContainerHubLinksHocChildProps,
  withGetSensorGeneratedGrainTicketsHoc,
  WithGetSensorGeneratedGrainTicketsHocChildProps,
  withGetShowGrainTicketLevelDeviationWarningHoc,
  WithGetShowGrainTicketLevelDeviationWarningHocChildProps,
} from '../../../api';
import { DialogSpinner } from '../../spinner';
import { getGrainTicketWarningMessage } from '../GrainBinBanners';
import LevelSensingCard from '../summary-card/LevelSensingCard';
import GrainBinTicketsContainer from './GrainBinTicketsContainer';
import { ManagePendingGrainTickets } from './manage-pending-grain-tickets';

const useStyles = makeStyles({
  grid: {
    minWidth: 300,
    maxWidth: 700,
  },
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
  },
});

type ManageGrainBinTicketsProps = {
  grain_bin_id: number;
  viewer_role: UserRole;
  url_base: string;
} & WithGetGrainBinHocChildProps &
  WithGetGrainBinTicketsHocChildProps &
  WithGetSensorGeneratedGrainTicketsHocChildProps &
  WithGetGrainContainerHubLinksHocChildProps &
  WithGetShowGrainTicketLevelDeviationWarningHocChildProps;

export const ManageGrainBinTickets = withGetGrainBinHoc(
  withGetGrainContainerHubLinksHoc(
    withGetSensorGeneratedGrainTicketsHoc(
      withGetGrainBinTicketsHoc(
        withGetShowGrainTicketLevelDeviationWarningHoc(
          ({
            grain_bin_id,
            grain_bin,
            refetch: refetchGrainBin,
            grain_bin_tickets,
            sensor_generated_grain_tickets,
            refetch_grain_bin_tickets: refetchGrainBinTickets,
            get_grain_bin_tickets_loading,
            get_sensor_generated_grain_tickets_loading,
            refetch_sensor_generated_grain_tickets,
            loading,
            viewer_role,
            grain_container,
            show_grain_ticket_level_deviation_warning,
            refetchGetShowGrainTicketLevelDeviationWarning,
            get_show_ticket_warning_loading,
            url_base,
          }: ManageGrainBinTicketsProps) => {
            const classes = useStyles();
            const dispatch = useDispatch();
            const {
              showGrainTicketWarningBanner,
            }: {
              showGrainTicketWarningBanner: boolean;
            } = useSelector(({ global_state: { showGrainTicketWarningBanner } }) => ({
              showGrainTicketWarningBanner,
            }));
            const [isUpdating, setUpdating] = useState(false);
            const [showSensorPendingTickets, setShowSensorPendingTickets] = useState(false);
            const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const gotoManageTickets = useCallback(
              () => dispatch(pushNav({ path: `${url_base}/tickets` })),
              [url_base]
            );
            const grainTicketWarningMsg = getGrainTicketWarningMessage({
              gotoManageTickets,
              showLink: false,
            });

            useEffect(() => {
              if (grain_container) {
                const { active_hub_links } = grain_container;
                const hasAnyActiveLevelSensorHub = active_hub_links.some(
                  ({ has_level_sensor }) => has_level_sensor
                );
                setShowSensorPendingTickets(hasAnyActiveLevelSensorHub);
              }
            }, [grain_container]);

            useEffect(() => {
              if (!get_show_ticket_warning_loading) {
                dispatch(
                  setShowGrainTicketWarningBanner({
                    showGrainTicketWarningBanner: show_grain_ticket_level_deviation_warning,
                  })
                );
              }
            }, [show_grain_ticket_level_deviation_warning, get_show_ticket_warning_loading]);

            if (
              loading ||
              get_grain_bin_tickets_loading ||
              get_sensor_generated_grain_tickets_loading
            ) {
              return <DialogSpinner open title="Loading..." />;
            }

            if (isUpdating) {
              return <DialogSpinner title="Updating..." open={isUpdating} />;
            }

            if (!grain_bin || !grain_bin_tickets) return null;

            const { tz, grain_type, bin_name, diameter_ft, height_ft } = grain_bin;
            const grainType = formatGrainType(grain_type);
            const grainBinCapacity =
              diameter_ft && height_ft
                ? estimateAndFormatBushels(diameter_ft, height_ft, 'space')
                : null;
            const {
              grain_bin_tickets_total_bushels,
              grain_bin_tickets_total_weight_in_lbs,
              grain_bin_all_tickets,
            } = grain_bin_tickets;

            console.log('sensor_generated_grain_tickets', sensor_generated_grain_tickets);

            // Setting grain bin location timezone for luxon dates
            if (tz) {
              Settings.defaultZone = tz;
              Settings.defaultLocale = 'en-US';
            }

            return (
              <div className={classes.root}>
                <Grid
                  container
                  direction="row"
                  alignContent="flex-start"
                  alignItems="center"
                  justify="center"
                  spacing={2}
                  className={classes.grid}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: 0,
                      marginBottom: 20,
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: amber_dark_grey,
                        fontSize: 22,
                        fontWeight: 600,
                      }}
                    >
                      {bin_name}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      {grainType} {grainBinCapacity ? ` | ${grainBinCapacity} bin` : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ padding: 0, marginBottom: 20 }}>
                    <LevelSensingCard
                      container_id={grain_bin_id}
                      container_type={ContainerTypeLegacy.bin}
                      title="Grain Level"
                      url_base={''}
                      minHeight={419}
                      maxHeight={465}
                      grain_bin_location_timezone={grain_bin.tz ? grain_bin.tz : browserTimezone}
                      show_grain_ticket_level_deviation_warning={
                        show_grain_ticket_level_deviation_warning
                      }
                      hideManageGrainTicketsBtn={true}
                    />
                  </Grid>
                  {showSensorPendingTickets && (
                    <>
                      {showGrainTicketWarningBanner && (
                        <Grid item xs={12} style={{ padding: 0 }} id="grainTicketWarningBanner">
                          <Banner
                            type="warning"
                            text={grainTicketWarningMsg}
                            textStyles={{ fontSize: 16 }}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} style={{ padding: 0, marginTop: 10, marginBottom: 20 }}>
                        <ManagePendingGrainTickets
                          grain_bin_id={grain_bin_id}
                          grain_type={grain_type}
                          bin_diameter_ft={diameter_ft ? Number(diameter_ft) : null}
                          sensor_generated_grain_tickets={sensor_generated_grain_tickets}
                          setUpdating={setUpdating}
                          refetchGrainBinTickets={refetchGrainBinTickets}
                          refetch_sensor_generated_grain_tickets={
                            refetch_sensor_generated_grain_tickets
                          }
                          refetchGetShowGrainTicketLevelDeviationWarning={
                            refetchGetShowGrainTicketLevelDeviationWarning
                          }
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} style={{ padding: 0, marginBottom: 20 }}>
                    <GrainBinTicketsContainer
                      viewer_role={viewer_role}
                      grain_bin_id={grain_bin_id}
                      setUpdating={setUpdating}
                      grain_type={grain_type}
                      grainBinCapacity={grainBinCapacity}
                      grain_bin_all_tickets={grain_bin_all_tickets}
                      refetchGrainBin={refetchGrainBin}
                      refetchGrainBinTickets={refetchGrainBinTickets}
                      grain_bin_tickets_total_bushels={grain_bin_tickets_total_bushels}
                      grain_bin_tickets_total_weight_in_lbs={grain_bin_tickets_total_weight_in_lbs}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          }
        )
      )
    )
  )
);
