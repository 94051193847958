import React from 'react';

export const DragIcon = (props) => {
  return (
    <svg
      id="DragIcon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={13.679}
      height={21}
      viewBox="0 0 13.679 21"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_2422"
            data-name="Rectangle 2422"
            width={13.679}
            height={21}
            fill="#242a33"
          />
        </clipPath>
      </defs>
      <g id="Group_124" data-name="Group 124" clipPath="url(#clip-path)">
        <path
          id="Path_398"
          data-name="Path 398"
          d="M9.928,7.769A2.323,2.323,0,1,1,7.605,5.446,2.323,2.323,0,0,1,9.928,7.769"
          transform="translate(-5.066 -5.224)"
          fill="#242a33"
        />
        <path
          id="Path_399"
          data-name="Path 399"
          d="M4.639,2.546A2.119,2.119,0,0,1,3.259,4.519a2.128,2.128,0,0,1-2.343-.64A2.1,2.1,0,0,1,2.94.484a2.126,2.126,0,0,1,1.7,2.062.223.223,0,0,0,.446,0A2.58,2.58,0,0,0,3.407.151,2.558,2.558,0,0,0,.584.919,2.571,2.571,0,0,0,.363,3.865a2.578,2.578,0,0,0,2.7,1.174A2.574,2.574,0,0,0,5.087,2.546a.224.224,0,0,0-.449,0Z"
          transform="translate(0 0)"
          fill="#242a33"
        />
        <path
          id="Path_400"
          data-name="Path 400"
          d="M9.928,202.73a2.323,2.323,0,1,1-2.323-2.323,2.323,2.323,0,0,1,2.323,2.323"
          transform="translate(-5.066 -192.229)"
          fill="#242a33"
        />
        <path
          id="Path_401"
          data-name="Path 401"
          d="M4.639,197.507a2.119,2.119,0,0,1-1.379,1.973,2.1,2.1,0,1,1-.319-4.035,2.125,2.125,0,0,1,1.7,2.062.223.223,0,0,0,.446,0,2.58,2.58,0,0,0-1.678-2.395,2.558,2.558,0,0,0-2.823.768A2.546,2.546,0,0,0,3.061,200a2.574,2.574,0,0,0,2.026-2.493.224.224,0,0,0-.449,0Z"
          transform="translate(0 -187.005)"
          fill="#242a33"
        />
        <path
          id="Path_402"
          data-name="Path 402"
          d="M9.928,397.69a2.323,2.323,0,1,1-2.323-2.323,2.323,2.323,0,0,1,2.323,2.323"
          transform="translate(-5.066 -379.233)"
          fill="#242a33"
        />
        <path
          id="Path_403"
          data-name="Path 403"
          d="M4.639,392.467a2.119,2.119,0,0,1-1.379,1.973A2.1,2.1,0,1,1,2.94,390.4a2.125,2.125,0,0,1,1.7,2.062.223.223,0,0,0,.446,0,2.58,2.58,0,0,0-1.678-2.395,2.558,2.558,0,0,0-2.823.768,2.546,2.546,0,0,0,2.477,4.12,2.574,2.574,0,0,0,2.026-2.493.224.224,0,0,0-.449,0Z"
          transform="translate(0 -374.009)"
          fill="#242a33"
        />
        <path
          id="Path_404"
          data-name="Path 404"
          d="M220.468,7.769a2.323,2.323,0,1,1-2.323-2.323,2.323,2.323,0,0,1,2.323,2.323"
          transform="translate(-207.015 -5.224)"
          fill="#242a33"
        />
        <path
          id="Path_405"
          data-name="Path 405"
          d="M215.179,2.546a2.1,2.1,0,1,1-3.892-1.093,2.1,2.1,0,0,1,3.892,1.094.223.223,0,0,0,.446,0A2.545,2.545,0,1,0,210.9,3.865a2.546,2.546,0,0,0,4.725-1.319.224.224,0,0,0-.449,0Z"
          transform="translate(-201.948 0)"
          fill="#242a33"
        />
        <path
          id="Path_406"
          data-name="Path 406"
          d="M220.468,202.73a2.323,2.323,0,1,1-2.323-2.323,2.323,2.323,0,0,1,2.323,2.323"
          transform="translate(-207.015 -192.229)"
          fill="#242a33"
        />
        <path
          id="Path_407"
          data-name="Path 407"
          d="M215.179,197.507a2.1,2.1,0,1,1-1.7-2.062,2.119,2.119,0,0,1,1.7,2.062.223.223,0,0,0,.446,0A2.545,2.545,0,1,0,213.6,200a2.58,2.58,0,0,0,2.024-2.493.224.224,0,0,0-.449,0Z"
          transform="translate(-201.948 -187.005)"
          fill="#242a33"
        />
        <path
          id="Path_408"
          data-name="Path 408"
          d="M220.468,397.69a2.323,2.323,0,1,1-2.323-2.323,2.323,2.323,0,0,1,2.323,2.323"
          transform="translate(-207.015 -379.233)"
          fill="#242a33"
        />
        <path
          id="Path_409"
          data-name="Path 409"
          d="M215.179,392.467a2.1,2.1,0,1,1-1.7-2.062,2.119,2.119,0,0,1,1.7,2.062.223.223,0,0,0,.446,0,2.545,2.545,0,1,0-2.024,2.493,2.58,2.58,0,0,0,2.024-2.493.224.224,0,0,0-.449,0Z"
          transform="translate(-201.948 -374.009)"
          fill="#242a33"
        />
      </g>
    </svg>
  );
};
