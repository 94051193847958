import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  SensorGeneratedGrainTicket,
  withGetSensorGeneratedGrainTickets as HOC,
} from '../__generated';

export type WithGetSensorGeneratedGrainTicketsHocOwnProps = {
  grain_bin_id: number;
  grain_bin_storage_cycle_id?: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetSensorGeneratedGrainTicketsHocChildProps = {
  get_sensor_generated_grain_tickets_loading: boolean;
  sensor_generated_grain_tickets: SensorGeneratedGrainTicket[] | any;
  refetch_sensor_generated_grain_tickets: () => Promise<any>;
};
export const withGetSensorGeneratedGrainTicketsHoc: AmberHoc<
  WithGetSensorGeneratedGrainTicketsHocOwnProps,
  WithGetSensorGeneratedGrainTicketsHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetSensorGeneratedGrainTicketsHocOwnProps,
      WithGetSensorGeneratedGrainTicketsHocChildProps,
      typeof component
    >,
    WithGetSensorGeneratedGrainTicketsHocChildProps
  >({
    options: ({ grain_bin_id, grain_bin_storage_cycle_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      fetchPolicy: 'network-only',
      variables: { grain_bin_id, grain_bin_storage_cycle_id },
      notifyOnNetworkStatusChange: true,
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          get_sensor_generated_grain_tickets_loading: true,
          refetch_sensor_generated_grain_tickets: () => Promise.resolve(),
          sensor_generated_grain_tickets: [],
        };
      }

      return {
        get_sensor_generated_grain_tickets_loading: loading || false,
        refetch_sensor_generated_grain_tickets: data.refetch,
        sensor_generated_grain_tickets: data.sensor_generated_grain_tickets,
      };
    },
  })(component as any);
