import { ActionType, getType } from 'typesafe-actions';
import {
  setFanCardView,
  setIsFanStartTriggered,
  setIsFanStopTriggered,
  setIsUserOnTempRecommWindowsPage,
  setLastAerationRun,
  setShowCO2LevelWarningBanner,
  setShowGrainTicketWarningBanner,
  setUnreadNotificationCount,
} from '../action';
import { GrainContainerAerationRunFragmentFragment } from '../api';
import { FanControlsNavigation } from '../component/grain-container/FanControlNavigationOption';

const actions = {
  setIsFanStopTriggered,
  setIsFanStartTriggered,
  setIsUserOnTempRecommWindowsPage,
  setUnreadNotificationCount,
  setLastAerationRun,
  setFanCardView,
  setShowGrainTicketWarningBanner,
  setShowCO2LevelWarningBanner,
};

export const defaul_state: GlobalState = {
  isFanStopTriggered: false,
  isFanStartTriggered: false,
  isUserOnTempRecommWindowsPage: false,
  userLastNotificationsReadOn: null,
  unreadNotificationCount: 0,
  last_aeration_run: null,
  initialFanControlCardNavigationType: undefined,
  showGrainTicketWarningBanner: false,
  showCO2WarningBanner: null,
};

export type GlobalState = {
  isFanStopTriggered: boolean;
  isFanStartTriggered: boolean;
  isUserOnTempRecommWindowsPage: boolean;
  userLastNotificationsReadOn: Date | null;
  unreadNotificationCount: number;
  last_aeration_run: GrainContainerAerationRunFragmentFragment | null;
  initialFanControlCardNavigationType: FanControlsNavigation | undefined;
  showGrainTicketWarningBanner: boolean;
  showCO2WarningBanner: {
    co2AlertSentOn: Date;
    co2AlertType: string;
  } | null;
};
export const globalStateReducer = (
  state: GlobalState = defaul_state,
  action: ActionType<typeof actions>
): GlobalState => {
  switch (action.type) {
    case getType(setIsFanStopTriggered): {
      return {
        ...state,
        isFanStopTriggered: action.payload.isFanStopTriggered,
      };
    }
    case getType(setIsFanStartTriggered): {
      return {
        ...state,
        isFanStartTriggered: action.payload.isFanStartTriggered,
      };
    }
    case getType(setIsUserOnTempRecommWindowsPage): {
      return {
        ...state,
        isUserOnTempRecommWindowsPage: action.payload.isUserOnTempRecommWindowsPage,
      };
    }
    case getType(setUnreadNotificationCount): {
      return {
        ...state,
        unreadNotificationCount: action.payload.unreadNotificationCount,
      };
    }
    case getType(setLastAerationRun): {
      return {
        ...state,
        last_aeration_run: action.payload.run,
      };
    }
    case getType(setFanCardView): {
      const {
        payload: { initialFanControlCardNavigationType },
      } = action;
      return {
        ...state,
        initialFanControlCardNavigationType,
      };
    }
    case getType(setShowGrainTicketWarningBanner): {
      const {
        payload: { showGrainTicketWarningBanner },
      } = action;
      return {
        ...state,
        showGrainTicketWarningBanner,
      };
    }
    case getType(setShowCO2LevelWarningBanner): {
      const { payload } = action;
      const showCO2WarningBanner = payload
        ? {
            co2AlertSentOn: payload.co2AlertSentOn,
            co2AlertType: payload.co2AlertType,
          }
        : null;
      return {
        ...state,
        showCO2WarningBanner,
      };
    }
    default:
      return state;
  }
};
