import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { FanRunWindowRecommendedOption } from '../../../../../core/src/api';
import { amber_blue, amber_green } from '../../../style';
import { RunWindowKind } from '../daily-forecast';
import {
  COOLING_COLOR_GRADIENT_OBJ,
  CUSTOM_COLOR,
  DRYING_COLOR_GRADIENT_OBJ,
  MANUAL_COLOR,
  RECONDITIONING_COLOR_GRADIENT_OBJ,
} from '../daily-forecast/AerationRecommnedationPlotHelpers';
import { RecommendedScheduledRange } from '../daily-forecast/RecommendationScheduleBar';
import { RecommendedOption } from '../RecommendationOptionValue';

type PresentedRunWindow = {
  start: number;
  end: number;
  mode: FanRunWindowRecommendedOption;
};

type AerationsRunsRange = {
  startEpoch: number;
  endEpoch: number;
  text: string;
  backgroundColor: any;
  mode: FanRunWindowRecommendedOption;
};

const DRYING_COLOR = 'rgb(225, 114, 81)';
const RECONDITIONING_COLOR = 'rgb(192, 93, 174)';
const COOLING_COLOR = amber_blue;
export const getRunWindowType = (recommendationType) => {
  return {
    [RecommendedOption.COOLING]: RunWindowKind.Cooling,
    [RecommendedOption.DRYING]: RunWindowKind.Drying,
    [RecommendedOption.RECONDITIONING]: RunWindowKind.RECONDITIONING,
  }[recommendationType];
};
const getAllCategories = () => ['Fan Run History'];
const getFormattedSeriesData = ({
  scheduledRunsToPresent,
}: {
  scheduledRunsToPresent: AerationsRunsRange[];
}) => {
  let formattedScheduledRuns: any = scheduledRunsToPresent
    .filter((item) => item.text)
    .map(({ startEpoch, endEpoch, text, backgroundColor, mode }) => {
      return {
        mode: mode ? capitalize(mode) : '',
        x: startEpoch,
        x2: endEpoch,
        y: 0,
        label: text,
        color: backgroundColor,
      };
    });
  formattedScheduledRuns =
    formattedScheduledRuns.length === 0 ? [{ y: 0 }] : formattedScheduledRuns;

  return [...formattedScheduledRuns];
};

const getRunWindowColor = (mode: FanRunWindowRecommendedOption) => {
  return {
    [FanRunWindowRecommendedOption.Cooling]: COOLING_COLOR_GRADIENT_OBJ,
    [FanRunWindowRecommendedOption.Drying]: DRYING_COLOR_GRADIENT_OBJ,
    [FanRunWindowRecommendedOption.Reconditioning]: RECONDITIONING_COLOR_GRADIENT_OBJ,
    [FanRunWindowRecommendedOption.Manual]: MANUAL_COLOR,
    [FanRunWindowRecommendedOption.Custom]: CUSTOM_COLOR,
  }[mode];
};

const getAerationsRunsToPresent = (temp_ranges: PresentedRunWindow[]): AerationsRunsRange[] => {
  const sorted = temp_ranges.sort((x, y) => {
    return x.start - y.start;
  });
  const aerationRunsToShow = sorted.filter(({ start, end }) => {
    const durationInHrs = Math.round(end / (1000 * 3600) - start / (1000 * 3600));
    if (durationInHrs < 1) {
      const duration_in_mins = Math.round(end / 60000 - start / 60000);
      if (duration_in_mins < 15) return false;
    }
    return true;
  });

  const res: AerationsRunsRange[] = [];
  aerationRunsToShow.forEach((temp_range, i) => {
    const startEpoch = temp_range.start;
    const endEpoch = temp_range.end;
    const startEpochDt = DateTime.fromMillis(startEpoch);
    const endEpochDt = DateTime.fromMillis(endEpoch);

    if (i === 0) {
      res.push({
        startEpoch,
        endEpoch,
        text: '',
        backgroundColor: 'transparent',
        mode: FanRunWindowRecommendedOption.Custom,
      });
    }

    const duration = Math.round(temp_range.end / (1000 * 3600) - temp_range.start / (1000 * 3600));
    const backgroundColor = temp_range.mode ? getRunWindowColor(temp_range.mode) : CUSTOM_COLOR;

    let text;
    if (duration <= 10) {
      if (duration < 1) {
        const duration_in_mins = Math.round(temp_range.end / 60000 - temp_range.start / 60000);
        text = `${duration_in_mins}m`;
      } else {
        text = `${duration}h`;
      }
    } else {
      text =
        Math.round(endEpochDt.diff(startEpochDt, 'year').toObject().years) === 1
          ? 'Forever'
          : `${duration} hrs`;
    }

    res.push({
      startEpoch,
      endEpoch,
      backgroundColor,
      text,
      mode: temp_range.mode,
    });
  });
  return res;
};

export {
  AerationsRunsRange,
  getFormattedSeriesData,
  getAerationsRunsToPresent,
  getAllCategories,
  DRYING_COLOR,
  COOLING_COLOR,
  RECONDITIONING_COLOR,
};
