import { Button as MuiButton, Theme } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';
import React, { memo } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),
  },
}));
export const Button = memo(({ variant = 'outlined', className, ...props }: ButtonProps) => {
  const classes = useStyles();
  return <MuiButton variant={variant} {...props} className={className || classes.root} />;
});

export const ButtonSubmit = ({
  allow_pristine = false,
  allow_btn_disabled_for_validation = true,
  disabled,
  children,
  prevent_submit_on_enter = false,
  ...props
}: ButtonProps & {
  allow_btn_disabled_for_validation?: boolean;
  allow_pristine?: boolean;
  prevent_submit_on_enter?: boolean;
}) => {
  const { isValid, isSubmitting, dirty, handleSubmit, submitCount } = useFormikContext();

  return (
    <Button
      type={prevent_submit_on_enter ? 'button' : 'submit'}
      disabled={
        disabled ||
        isSubmitting ||
        (allow_btn_disabled_for_validation && !isValid) ||
        (submitCount > 0 && !isValid) ||
        (!allow_pristine && !dirty)
      }
      onClick={prevent_submit_on_enter ? () => handleSubmit() : undefined}
      {...props}
    >
      {children || 'SUBMIT'}
    </Button>
  );
};
