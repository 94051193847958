import { AmberHoc, AmberHocProps } from '../../../util';
import {
  BargeFragmentFragment as Result,
  CreateBargeMutationVariables as Variables,
  GetAccountDocument,
  GetAccountQuery,
  GetAccountQueryVariables,
  GetBargeDocument,
  GetBargeQuery,
  GetBargeQueryVariables,
  withCreateBarge as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithCreateBargeHocChildProps = {
  createBarge: (variables: Variables) => Promise<Result>;
};
export const withCreateBargeHoc: AmberHoc<{}, WithCreateBargeHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithCreateBargeHocChildProps, typeof component>,
    WithCreateBargeHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        createBarge: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const { account_id } = variables;
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { createBarge: result } = data;
              const { barge, __typename } = result;
              const { barge_id, alias, default_grain_type } = barge;

              try {
                proxy.writeQuery<GetBargeQuery, GetBargeQueryVariables>({
                  query: GetBargeDocument,
                  data: { __typename: 'Query', barge: result.barge },
                  variables: { barge_id },
                });
              } catch (err) {
                // no-op
              }

              try {
                const cached = proxy.readQuery<GetAccountQuery, GetAccountQueryVariables>({
                  query: GetAccountDocument,
                  variables: { account_id },
                });
                if (!cached) {
                  return;
                }
                const { account } = cached;
                if (!account) {
                  return;
                }
                proxy.writeQuery<GetAccountQuery, GetAccountQueryVariables>({
                  query: GetAccountDocument,
                  data: {
                    __typename: 'Query',
                    account: {
                      ...account,
                      barge_links: [
                        ...account.barge_links,
                        {
                          __typename,
                          barge_id,
                          barge: {
                            barge_id,
                            alias,
                            default_grain_type,
                            archived: false,
                            __typename: barge.__typename,
                          },
                        },
                      ],
                    },
                  },
                  variables: { account_id },
                });
              } catch (err) {
                // no-op
              }
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.createBarge.barge;
        },
      };
    },
  })(component as any);
