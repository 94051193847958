import { NormalizedCache } from 'apollo-cache-inmemory';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy } from '../../../util';
import {
  BargeFragmentFragment as Result,
  GetBargeDocument,
  GetBargeQuery,
  GetBargeQueryVariables,
  UpdateBargeMutationVariables as Variables,
  withUpdateBarge as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUpdateBargeHocChildProps = {
  updateBarge: (variables: Variables) => Promise<Result>;
};
export const withUpdateBargeHoc: AmberHoc<{}, WithUpdateBargeHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithUpdateBargeHocChildProps, typeof component>,
    WithUpdateBargeHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updateBarge: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors: errors2 }) => {
              if (errors2 || !data) {
                return;
              }
              const { updateBarge: result } = data;

              const cache: NormalizedCache = (proxy as any).data;
              const container_key = `GrainContainer:${ContainerTypeLegacy.bin}|${
                variables.barge_id
              }`;
              cache.delete(container_key);
              const cache_data = (cache as any).data;
              const container_match = `$${container_key}.`;
              Object.keys(cache_data).forEach((key) => {
                if (key.startsWith(container_match)) {
                  delete cache_data[key];
                }
              });

              proxy.writeQuery<GetBargeQuery, GetBargeQueryVariables>({
                query: GetBargeDocument,
                data: { __typename: 'Query', barge: result },
                variables: { barge_id: variables.barge_id },
              });
              // TODO: Clear barge telemetry
            },
          });
          if (!result) {
            throw new Error('Unexpected response from server');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected response from server');
          }
          return data.updateBarge;
        },
      };
    },
  })(component as any);
