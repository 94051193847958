import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { createAction } from 'typesafe-actions';
import { SelectedState } from '../reducer';

export const pushNav = createAction('NAV/PUSH', (resolve) => {
  return (payload: {
    path: string;
    params?: {
      selected?: Partial<SelectedState>;
    };
  }) => resolve(payload);
});

export const errorNav = createAction('NAV/ERROR', (resolve) => {
  return () => resolve();
});

export const changeLocation = createAction(LOCATION_CHANGE, (resolve) => (payload: RouterState) =>
  resolve(payload)
);
