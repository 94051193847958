import { Grid, ListItem, Theme, Tooltip, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { RedWarningIcon } from '../../../../core/src/media';

import { GrainBinFanController } from '../../api';
import {
  amber_faded_black,
  amber_medium_grey,
  amber_medium_light_grey,
  amber_red,
  amber_very_faded_black,
} from '../../style';
import { isQualifyingFanHeartbeatErrorCode } from '../grain-container/aeration/FanControllerErrorDisplay';
import { getSimpleFanStatus } from '../grain-container/aeration/FanStatusHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    paddingRight: theme.spacing(2),
    color: amber_medium_grey,
    borderBottom: `solid 1px ${amber_very_faded_black}`,
    '&:hover': {
      backgroundColor: fade(amber_medium_light_grey, 0.5),
    },
    '&$selected': { backgroundColor: amber_medium_light_grey },
    '&$selected:hover': {
      backgroundColor: amber_medium_light_grey,
    },
    '&$selected:focus': {
      backgroundColor: amber_medium_light_grey,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
  },
  selected: {
    color: amber_faded_black,
    backgroundColor: amber_medium_light_grey,
  },
  smallIcon: {
    fontSize: 17,
    marginRight: -4,
  },
  dot_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  color_dot: {
    height: 10,
    width: 10,
    borderRadius: '50%',
  },
}));

export const ContainerListItem = ({
  selected,
  containerName,
  fan_controllers,
  handleClick,
}: {
  selected: boolean;
  containerName: string;
  fan_controllers?: GrainBinFanController[];
  handleClick: () => void;
}) => {
  const classes = useStyles();
  const hasFanError = (fc) =>
    fc.fan_controller &&
    fc.fan_controller.state_next &&
    isQualifyingFanHeartbeatErrorCode(fc.fan_controller.state_next.error_code);

  return (
    <ListItem
      button
      selected={selected}
      onClick={handleClick}
      classes={{ root: classes.root, selected: classes.selected }}
    >
      <div style={{ paddingRight: 15 }}>
        <Typography variant="h6" color="inherit">
          {containerName}
        </Typography>
      </div>
      <Grid
        container
        style={{
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
        spacing={2}
      >
        {fan_controllers && fan_controllers.some(hasFanError) && (
          <Tooltip title={<span style={{ fontSize: 13 }}>Fan Error Detected</span>}>
            <Grid item className={classes.dot_container}>
              <RedWarningIcon
                style={{
                  width: 20,
                  height: 20,
                  fill: selected ? amber_red : fade(amber_red, 0.5),
                }}
              />
            </Grid>
          </Tooltip>
        )}
        {fan_controllers &&
          fan_controllers.map((fc, ix) => {
            const { color, status } = getSimpleFanStatus(fc);
            const { alias } = fc;
            return (
              <Tooltip
                key={ix}
                title={<span style={{ fontSize: 13 }}>{`${alias} ${status.toLowerCase()}`}</span>}
              >
                <Grid item className={classes.dot_container}>
                  <div
                    className={classes.color_dot}
                    style={{ backgroundColor: selected ? color : fade(color, 0.5) }}
                  />
                </Grid>
              </Tooltip>
            );
          })}
        <Grid
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 24,
          }}
        >
          <ArrowForward className={classes.smallIcon} />
        </Grid>
      </Grid>
    </ListItem>
  );
};
