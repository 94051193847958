import { ActionType, getType } from 'typesafe-actions';
import { changeLocation, setNavBar } from '../action';

const actions = { setNavBar, changeLocation };
export type NavBarState = {
  open: boolean;
};
export const navBarReducer = (
  state: NavBarState = {
    open: false,
  },
  action: ActionType<typeof actions>
): NavBarState => {
  switch (action.type) {
    case getType(setNavBar): {
      return {
        ...state,
        ...action.payload,
      };
    }
    case getType(changeLocation): {
      if (!state.open) {
        return state;
      }
      return { ...state, open: false };
    }
    default:
      return state;
  }
};
