import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { FanRunWindowRecommendedOption } from '../../../../../core/src/api';
import {
  BlurTargetMoistureIcon,
  GrainMoistureIconWithoutShade,
  GrainTemperatureWithoutShade,
} from '../../../../../core/src/media/icons';
import {
  black,
  black_shade_2,
  light_gray_4,
  light_gray_6,
} from '../../../../../core/src/style/color';
import { getRecommedationTypeMode } from './FanSchedule';
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
  },
  label: {
    fontSize: 12,
    textTransform: 'uppercase',
    color: light_gray_4,
    marginBottom: 4,
  },
  inputValueConatiner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
  },
  inputValue: {
    fontSize: 16,
    marginRight: 5,
    color: light_gray_6,
  },
  modeBadge: {
    width: 71,
    height: 21,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    fontSize: 15,
    opacity: 1,
    letterSpacing: '0.15px',
    color: black_shade_2,
    fontFamily: 'Work Sans,sans-serif',
  },
  centerStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

type GrainWeatherInputsCardProps = {
  current_grain_temp: number | null;
  target_grain_emc: number | null;
  current_grain_emc: number | null;
  mode: string;
  modeColor: string;
};
export const GrainWeatherInputsCard = ({
  current_grain_temp,
  target_grain_emc,
  current_grain_emc,
  mode,
  modeColor,
}: GrainWeatherInputsCardProps) => {
  const classes = useStyles();
  const currentGrainTemp = current_grain_temp ? `${current_grain_temp}º` : 'NA';
  const currentGrainEmc = current_grain_emc ? `${current_grain_emc}%` : 'NA';
  const targetGrainEmc = target_grain_emc ? `${target_grain_emc}%` : 'NA';
  const hasNonCoolingMode = mode !== 'Cool';
  const hasCoolingMode = mode === 'Cool';
  const hasRecommendedMode = [
    getRecommedationTypeMode(FanRunWindowRecommendedOption.Drying),
    getRecommedationTypeMode(FanRunWindowRecommendedOption.Reconditioning),
    getRecommedationTypeMode(FanRunWindowRecommendedOption.Cooling),
  ].includes(mode);
  return (
    <div className={classes.container}>
      {mode !== 'NA' && (
        <div
          className={classes.centerStyles}
          style={{ marginRight: hasCoolingMode ? 60 : hasRecommendedMode ? 30 : 0 }}
        >
          <div className={classes.label}>Mode</div>
          <div className={classes.modeBadge} style={{ background: modeColor }}>
            {mode}
          </div>
        </div>
      )}
      {hasRecommendedMode && (
        <>
          <div className={classes.centerStyles} style={{ marginRight: 30 }}>
            <div className={classes.label}>Input</div>
            <div className={classes.inputValueConatiner}>
              <div className={classes.inputValue}>
                {hasCoolingMode ? currentGrainTemp : currentGrainEmc}
              </div>
              <div>
                {hasCoolingMode ? (
                  <GrainTemperatureWithoutShade style={{ width: 10, height: 20 }} />
                ) : (
                  <GrainMoistureIconWithoutShade style={{ width: 16, height: 18 }} />
                )}
              </div>
            </div>
          </div>
          {hasNonCoolingMode && (
            <div className={classes.centerStyles}>
              <div className={classes.label}>Target</div>
              <div className={classes.inputValueConatiner}>
                <div className={classes.inputValue}>{targetGrainEmc}</div>
                <div style={{ display: 'flex' }}>
                  <BlurTargetMoistureIcon style={{ width: 25, height: 25 }} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
