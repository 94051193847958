import { Step, StepLabel, Stepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormikConfig, isInteger } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { GetProps } from 'react-redux';

import { amber_light_grey } from '../../style';
import { Button } from '../util/form';
import { BaseForm, FormikWrapper } from '../util/form2/BaseForm';
import { ButtonSubmit } from '../util/form2/Button';
import { ErrorBox } from '../util/form2/ErrorBox';
import { WizardStep } from './WizardStep';

const useStyles = makeStyles({
  root: {
    padding: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: 10,
  },
  stepper: {
    width: '100%',
  },
});

export const Wizard = <Values extends {}>({
  initialValues,
  children,
  onSubmit,
  onSubmitSuccess,
  session_storage_key,
}: Partial<
  Omit<FormikConfig<Values>, 'onSubmit' | 'validate' | 'render' | 'component' | 'children'>
> & {
  children: React.ReactElement<GetProps<typeof WizardStep>>[];
  session_storage_key?: string;
  onSubmit: (values: any, formikHelpers) => any;
  onSubmitSuccess: (result: any) => void;
}) => {
  const [step, setStep] = useState(0);
  const [values, setValues] = useState<Values>(
    typeof initialValues === 'function' ? initialValues() : initialValues
  );
  const [loaded, setLoaded] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (session_storage_key) {
      try {
        const stored = sessionStorage.getItem(session_storage_key);
        if (stored) {
          const parsed = JSON.parse(stored);
          if (isInteger(parsed.step)) {
            setStep(parsed.step);
            setValues(parsed.values);
          }
        }
      } catch {
        sessionStorage.removeItem(session_storage_key);
      }
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    if (session_storage_key) {
      sessionStorage.setItem(session_storage_key, JSON.stringify({ step, values }));
    }
  }, [session_storage_key, step, values, loaded]);

  const [onClickPrevious, active, is_last, handleSubmit] = useMemo(() => {
    const active = React.Children.toArray(children)[step];
    const children_count = React.Children.count(children);
    const is_last = step === children_count - 1;
    const onClickNext = (values: Values) => {
      setStep(Math.min(step + 1, children_count));
      setValues(values);
    };
    const handleSubmit = async (values: Values, bag) => {
      if (active.props && active.props.validateOnNext) {
        const validation_error = await active.props.validateOnNext(values);
        if (validation_error) {
          // throw error
          bag.setFieldError('general', validation_error);
          return;
        }
      }
      if (is_last) {
        return onSubmit && onSubmit(values, bag);
      }
      bag.setTouched({});
      bag.setSubmitting(false);
      onClickNext(values);
      return false;
    };
    return [() => setStep(Math.max(step - 1, 0)), active, is_last, handleSubmit];
  }, [step, children]);
  return (
    <FormikWrapper<Values>
      initialValues={values}
      validate={active.props.validate}
      validationSchema={active.props.validationSchema}
      onSubmit={handleSubmit}
      onSubmitSuccess={onSubmitSuccess}
      validateOnBlur={false}
      render={(props) => {
        const { isSubmitting, isValidating, isValid, setTouched, setErrors, validateForm } = props;

        useEffect(() => {
          // This will be called everytime `step` changes:
          validateForm();
        }, [step]);

        return (
          <BaseForm className={classes.root}>
            <Stepper
              activeStep={step}
              alternativeLabel
              className={classes.stepper}
              style={{ backgroundColor: amber_light_grey }}
            >
              {children.map((child, ix) => (
                <Step key={ix}>
                  <StepLabel>{child.props.title}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {active}
            <div className="buttons">
              {step > 0 && (
                <Button
                  type="button"
                  className="secondary"
                  onClick={() => {
                    setErrors({});
                    setTouched({});
                    onClickPrevious();
                  }}
                >
                  Back
                </Button>
              )}
              <ButtonSubmit
                prevent_submit_on_enter
                allow_pristine
                disabled={isSubmitting || isValidating || !isValid}
              >
                {is_last ? 'SUBMIT' : 'NEXT'}
              </ButtonSubmit>
            </div>
            <ErrorBox />
          </BaseForm>
        );
      }}
    />
  );
};
