import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import React, { ReactNode } from 'react';

type TimelineItem = {
  id?: number;
  content: ReactNode;
  timeLineDotIcon?: ReactNode;
  timelineItemColor?: string;
};

export const BasicTimeline = ({
  items,
  align = 'left',
}: {
  items: TimelineItem[];
  align?: 'left' | 'right' | 'alternate';
}) => {
  return (
    <>
      {items.map(({ id, content, timeLineDotIcon, timelineItemColor }, idx) => (
        <Timeline
          key={id ? id : idx}
          style={{ padding: 0, paddingLeft: 10, marginBottom: 0, marginTop: 0 }}
          align={align}
        >
          <TimelineItem>
            <TimelineSeparator>
              {/* <TimelineConnector /> */}
              <TimelineDot
                color="primary"
                style={{
                  backgroundColor: timelineItemColor ? timelineItemColor : undefined,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                {timeLineDotIcon ? timeLineDotIcon : null}
              </TimelineDot>
              {/* <TimelineConnector style={{ height: 54, flexGrow: 'unset' }} /> */}
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineOppositeContent style={{ paddingTop: 0, paddingRight: 0 }}>
              {content}
            </TimelineOppositeContent>
          </TimelineItem>
        </Timeline>
      ))}
    </>
  );
};
