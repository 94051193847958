import { Divider, Grid, Theme, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Add, RemoveCircleOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import {
  BargeCoverPelletLinkFragmentFragment,
  BargeFragmentFragment,
  withGetBargeCoverPelletLinksHoc,
  WithGetBargeCoverPelletLinksHocChildProps,
  withGetBargeHoc,
  WithGetBargeHocChildProps,
  withLinkBargeCoverPelletHoc,
  WithLinkBargeCoverPelletHocChildProps,
  withUnlinkBargeCoverPelletHoc,
  WithUnlinkBargeCoverPelletHocChildProps,
} from '../../../api';
import { amber_amber } from '../../../style';
import { ContainerTypeLegacy } from '../../../util';
import { DialogSpinner } from '../../spinner';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../../util/form2/BaseForm';
import { ButtonSubmit } from '../../util/form2/Button';
import { ErrorBox } from '../../util/form2/ErrorBox';
import { IntTextField } from '../../util/form2/NumberField';
import { TextField } from '../../util/form2/TextField';
import { BargeCoverRemovalFormBase } from './BargeCoverRemovalForm';

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    whiteSpace: 'normal',
    wordWrap: 'normal',
    textAlign: 'center',
    paddingLeft: 12,
    paddingRight: 12,
  },
  button_icon: {
    marginRight: theme.spacing(1),
  },
  cover_no: { paddingLeft: 10, paddingRight: 10 },
  pellet_id: { paddingLeft: 10, paddingRight: 10 },
  divider: { marginTop: 10 },
  yellow: { color: amber_amber },
  red: { color: red['500'] },
  field: { width: 150 },
  root: { display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20 },
  center: { textAlign: 'center' },
  grid: { maxWidth: 500 },
  table: { textAlign: 'center', maxWidth: 500 },
}));

type Values = {
  pellet_id: string;
  cover_no: number | null;
  barge_cover_pellet_links: BargeCoverPelletLinkFragmentFragment[];
  barge_cover_pellet_link_id: number | null;
};

type BargeCoverFormBaseProps = WithLinkBargeCoverPelletHocChildProps &
  WithUnlinkBargeCoverPelletHocChildProps &
  FormikWrapperHandlerProps<
    Values,
    { container_id: number; container_type: ContainerTypeLegacy }
  > & {
    barge: BargeFragmentFragment;
    barge_cover_pellet_link_id?: number | null;
    barge_cover_pellet_links: BargeCoverPelletLinkFragmentFragment[];
    pellet_id?: string;
    cover_no?: number | null;

    onChangePelletId?: (value: Values['pellet_id']) => void;
    onChangeCoverNo?: (value: Values['cover_no']) => void;
  };

const Form = ({
  title,
  onChangePelletId,
  onChangeCoverNo,
}: {
  title: string;
  onChangePelletId?: (value: Values['pellet_id']) => void;
  onChangeCoverNo?: (value: Values['cover_no']) => void;
}) => {
  const classes = useStyles();
  const {
    initialValues: { barge_cover_pellet_link_id, pellet_id, cover_no },
    values,
  } = useFormikContext<Values>();
  useEffect(() => onChangePelletId && onChangePelletId(values.pellet_id), [values.pellet_id]);
  useEffect(() => onChangeCoverNo && onChangeCoverNo(values.cover_no), [values.cover_no]);
  return (
    <BaseForm submitting_message="Saving changes...">
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        alignItems="center"
        justify="center"
        spacing={2}
        className={classes.grid}
      >
        <Grid item xs={12} className={classes.center}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Grid>
        {cover_no ? (
          <Grid item xs={12} className={classes.center}>
            <Typography variant="h5">Cover No. {cover_no}</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} className={classes.center}>
            <IntTextField name="cover_no" label="Cover No." placeholder="Cover No." fullWidth />
          </Grid>
        )}
        {pellet_id ? (
          <Grid item xs={12} className={classes.center}>
            <Typography variant="h5">Pellet {pellet_id.split(':').pop()}</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} className={classes.center}>
            <TextField name="pellet_id" label="Pellet ID" placeholder="Pellet ID" fullWidth />
          </Grid>
        )}
        <ErrorBox />
        <Grid item xs={12} className={classes.center}>
          <ButtonSubmit
            allow_pristine={Boolean(barge_cover_pellet_link_id || (pellet_id && cover_no))}
            className={`${classes.divider}${
              barge_cover_pellet_link_id ? ` ${classes.yellow}` : ''
            }`}
          >
            {barge_cover_pellet_link_id ? (
              <RemoveCircleOutline classes={{ root: classes.button_icon }} />
            ) : (
              <Add classes={{ root: classes.button_icon }} />
            )}
            {barge_cover_pellet_link_id ? 'UNASSIGN' : 'ASSIGN'}
          </ButtonSubmit>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </BaseForm>
  );
};

export const BargeCoverSingleFormBase = ({
  barge,
  barge_cover_pellet_links,
  pellet_id = '',
  cover_no = null,
  barge_cover_pellet_link_id = null,
  linkBargeCoverPellet,
  unlinkBargeCoverPellet,
  onChangeCoverNo,
  onChangePelletId,
  ...props
}: BargeCoverFormBaseProps) => {
  const validationSchema = useMemo(() => {
    const cover_nos = barge_cover_pellet_links.map(({ cover_no }) => cover_no);
    const pellet_ids = barge_cover_pellet_links.map(({ pellet_id }) => pellet_id);
    return yup.object().shape({
      pellet_id: yup
        .string()
        .label('Pellet ID')
        .when('barge_cover_pellet_link_id', (value, schema) =>
          value
            ? schema
            : schema
                .notOneOf(pellet_ids, `Pellet's current assignment must first be removed`)
                .required()
                .nullable(false)
        ),
      cover_no: yup
        .number()
        .typeError('A number is required')
        .label('Cover No')
        .when('barge_cover_pellet_link_id', (value, schema) =>
          value
            ? schema
            : schema
                .integer()
                .required()
                .nullable(false)
                .notOneOf(cover_nos, `Cover's current assignment must first be removed`)
        ),
      barge_cover_pellet_link_id: yup
        .number()
        .typeError('A number is required')
        .integer()
        .nullable(true),
    });
  }, [barge_cover_pellet_links]);
  const submitCallback = useCallback(
    async (values, { setFieldValue }) => {
      const { cover_no, pellet_id, barge_cover_pellet_link_id } = validationSchema.validateSync(
        values
      );
      const { barge_id } = barge;
      if (barge_cover_pellet_link_id) {
        await unlinkBargeCoverPellet({
          barge_cover_pellet_link_id,
        });
      } else {
        await linkBargeCoverPellet({ barge_id, pellet_id, cover_no });
      }
      setFieldValue('barge_cover_pellet_link_id', null);
      return { container_id: barge_id, container_type: ContainerTypeLegacy.barge };
    },
    [validationSchema, linkBargeCoverPellet, unlinkBargeCoverPellet]
  );
  return (
    <FormikWrapper<Values, { container_id: number; container_type: ContainerTypeLegacy }>
      {...props}
      validationSchema={validationSchema}
      initialValues={{
        barge_cover_pellet_links,
        barge_cover_pellet_link_id,
        cover_no,
        pellet_id,
      }}
      onSubmit={submitCallback}
      onSubmitSuccess={(result, { resetForm }) => resetForm()}
    >
      <Form
        title={barge.alias}
        onChangeCoverNo={onChangeCoverNo}
        onChangePelletId={onChangePelletId}
      />
    </FormikWrapper>
  );
};

export const BargeCoverFormBase = ({
  cover_no = null,
  pellet_id = '',
  barge_cover_pellet_links,
  unlinkBargeCoverPellet,
  ...props
}: BargeCoverFormBaseProps) => {
  const [selected_cover_no, setSelectedCoverNo] = useState(cover_no);
  const [selected_pellet_id, setSelectedPelletId] = useState(pellet_id);
  return (
    <>
      <BargeCoverSingleFormBase
        {...props}
        pellet_id={pellet_id}
        cover_no={cover_no}
        barge_cover_pellet_links={barge_cover_pellet_links}
        unlinkBargeCoverPellet={unlinkBargeCoverPellet}
        onChangePelletId={setSelectedPelletId}
        onChangeCoverNo={setSelectedCoverNo}
      />
      <BargeCoverRemovalFormBase
        unlinkBargeCoverPellet={unlinkBargeCoverPellet}
        barge_cover_pellet_links={barge_cover_pellet_links}
        pellet_id={selected_pellet_id}
        cover_no={selected_cover_no}
      />
    </>
  );
};

export const BargeCoverForm = withGetBargeHoc(
  withGetBargeCoverPelletLinksHoc(
    withUnlinkBargeCoverPelletHoc(
      withLinkBargeCoverPelletHoc(
        ({
          pellet_id = '',
          cover_no = null,
          barge,
          loading,
          barge_cover_pellet_links,
          barge_cover_pellet_link_id,
          unlinkBargeCoverPellet,
          ...props
        }: WithGetBargeHocChildProps &
          WithGetBargeCoverPelletLinksHocChildProps &
          WithUnlinkBargeCoverPelletHocChildProps &
          WithLinkBargeCoverPelletHocChildProps &
          FormikWrapperHandlerProps<
            Values,
            { container_id: number; container_type: ContainerTypeLegacy }
          > & {
            barge_cover_pellet_link_id?: number | null;
            pellet_id?: string;
            cover_no?: number | null;
          }) => {
          if (loading) {
            return <DialogSpinner title="Loading assignments..." open={loading} />;
          }
          if (!barge || !barge_cover_pellet_links) {
            return <Typography>An error occurred while loading current assignments</Typography>;
          }
          let existing: BargeCoverPelletLinkFragmentFragment | null = null;
          if (barge_cover_pellet_link_id) {
            const found = barge_cover_pellet_links.find(
              (bcpl) => bcpl.barge_cover_pellet_link_id === barge_cover_pellet_link_id
            );
            if (!found) {
              return <Typography>Barge pellet assignment not found</Typography>;
            }
            existing = found;
          }
          return (
            <>
              <BargeCoverFormBase
                {...props}
                pellet_id={existing ? existing.pellet_id : pellet_id}
                cover_no={existing ? existing.cover_no : cover_no}
                barge={barge}
                barge_cover_pellet_links={barge_cover_pellet_links}
                unlinkBargeCoverPellet={unlinkBargeCoverPellet}
              />
            </>
          );
        }
      )
    )
  )
);
