import { Grid, makeStyles, Typography } from '@material-ui/core';
import { HomeOutlined } from "@material-ui/icons";
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  getDiagCellMetricTestLastResultApi,
  getDiagCellMetricTestLatestTestStatusApi,
  getDiagLongTestLastResultApi,
  getDiagLongTestLatestTestStatusApi,
  getDiagQuickTestLastResultApi,
  getDiagQuickTestLatestTestStatusApi,
  getDiagTestFanControllerApi,
  getSignedOffDiagnosticTestApi,
  runDiagCellMetricTestApi,
  runDiagLongTestApi,
  runDiagQuickTestApi,
} from '../../api/rest';
import { amber_blue, amber_green, amber_grey, amber_red } from '../../style';
import { DialogSpinner } from '../spinner';
import {Button} from "../util/form2/Button";
import { DiagTestApproveCard } from './DiagTestApproveCard';
import { LongTestCard } from './LongTestCard';
import { QuickTestCard } from './QuickTestCard';
import { RefreshCellMetricTestCard } from './RefreshCellMetricsCard';

const useStyles = makeStyles({
  diagTestsContainer: {
    maxWidth: 700,
    minWidth: 300,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  diagTestsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
  },
});

export type DiagTestResultStatus = {
  status: string;
  color: string;
  has_expired: boolean;
};

export type SignedOffDiagnosticTestResult = {
  core_id: string;
  fan_controller_id_next: number;
  signed_off_on: Date;
};

export const DIAG_TEST_RESULT_STATUSES = {
  PASS: { status: 'PASSED', color: amber_green },
  FAIL: { status: 'FAILED', color: amber_red },
  TIMED_OUT: { status: 'TIMED OUT', color: amber_grey },
  RUNNING: { status: 'RUNNING', color: amber_blue },
};

export const DIAG_TEST_CELL_METRIC_RESULT_STATUSES = {
  COMPLETED: { status: 'COMPLETED', color: amber_green },
  FAILED: { status: 'FAILED', color: amber_red },
  TIMED_OUT: { status: 'TIMED OUT', color: amber_grey },
  RUNNING: { status: 'RUNNING', color: amber_blue },
};

export const DIAG_TEST_STATUSES = {
  SUBMITTED: 'SUBMITTED',
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  REJECTED: 'REJECTED',
};

export type DiagTestsProps = { routeParams: { core_id: string } } & RouteComponentProps;

export const DiagTests = ({ routeParams, ...props }: DiagTestsProps) => {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const classes = useStyles();
  const { core_id } = routeParams;
  const [error, setError] = useState<string>('');
  const [diagTestFanController, setDiagTestFanController] = useState<any>(null);
  const [isLastTestResultLoading, setIsLastTestResultLoading] = useState<boolean>(false);
  const [isDiagnosticTestEnabled, setDiagnosticTestEnabled] = useState<boolean>(false);
  const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
  const [lastQuickTestResult, setLastQuickTestResult] = useState<any>(null);
  const [lastLongTestResult, setLastLongTestResult] = useState<any>(null);
  const [lastCellMetricTestResult, setLastCellMetricTestResult] = useState<any>(null);
  const [signedOffDiagnosticTestResultDate, setSignedOffDiagnosticTestResultDate] = useState<
    DateTime
  >(null);
  const [
    currentQuickTestResultUIStatus,
    setCurrentQuickTestResultUIStatus,
  ] = useState<DiagTestResultStatus | null>(null);
  const [
    currentLongTestResultUIStatus,
    setCurrentLongTestResultUIStatus,
  ] = useState<DiagTestResultStatus | null>(null);

  const [
    currentCellMetricTestResultUIStatus,
    setCurrentCellMetricTestResultUIStatus,
  ] = useState<DiagTestResultStatus | null>(null);

  const getDiagTestFanController = async () => {
    try {
      const response = await getDiagTestFanControllerApi(core_id);
      const { data } = response.data;
      if (data) {
        const { fan_controller_id_next } = data;
        if (!fan_controller_id_next) {
          throw new Error(`No device found for core-id ${core_id}`);
        }
        setDiagTestFanController(data);
      }
    } catch (error) {
      console.error('my errror', error.response.data.errors[0].error_message);
      setError(error.response.data.errors[0].error_message);
      throw new Error(error);
    }
  };

  const getQuickTestStatus = async () => {
    try {
      const response = await getDiagQuickTestLatestTestStatusApi(core_id);

      // TIMED_OUT
      if (response.data.data.has_expired) {
        fetchLastQuickTestResult();
        setCurrentQuickTestResultUIStatus({
          ...DIAG_TEST_RESULT_STATUSES.TIMED_OUT,
          has_expired: response.data.data.has_expired,
        });
        return;
      }

      if (response.data.data.status) {
        switch (response.data.data.status) {
          case DIAG_TEST_STATUSES.COMPLETED:
            fetchLastQuickTestResult(true);
            return;
          case DIAG_TEST_STATUSES.FAILED:
          case DIAG_TEST_STATUSES.REJECTED:
            // FAIL
            setCurrentQuickTestResultUIStatus({
              ...DIAG_TEST_RESULT_STATUSES.FAIL,
              has_expired: response.data.data.has_expired,
            });
            return;
          default:
            setCurrentQuickTestResultUIStatus({
              ...DIAG_TEST_RESULT_STATUSES.RUNNING,
              has_expired: response.data.data.has_expired,
            });
            // Polling
            setTimeout(() => {
              getQuickTestStatus();
            }, 3000);
            break;
        }
      }
    } catch (error) {
      console.error('my errror', error.response.data.errors[0].error_message);
      setError(error.response.data.errors[0].error_message);
      throw new Error(error);
    }
  };

  const getLongTestStatus = async () => {
    try {
      const response = await getDiagLongTestLatestTestStatusApi(core_id);

      // TIMED_OUT
      if (response.data.data.has_expired) {
        fetchLastLongTestResult();
        setCurrentLongTestResultUIStatus({
          ...DIAG_TEST_RESULT_STATUSES.TIMED_OUT,
          has_expired: response.data.data.has_expired,
        });
        return;
      }

      if (response.data.data.status) {
        switch (response.data.data.status) {
          case DIAG_TEST_STATUSES.COMPLETED:
            fetchLastLongTestResult(true);
            return;
          case DIAG_TEST_STATUSES.FAILED:
          case DIAG_TEST_STATUSES.REJECTED:
            // FAIL
            setCurrentLongTestResultUIStatus({
              ...DIAG_TEST_RESULT_STATUSES.FAIL,
              has_expired: response.data.data.has_expired,
            });
            return;
          default:
            setCurrentLongTestResultUIStatus({
              ...DIAG_TEST_RESULT_STATUSES.RUNNING,
              has_expired: response.data.data.has_expired,
            });
            // Polling
            setTimeout(() => {
              getLongTestStatus();
            }, 3000);
            break;
        }
      }
    } catch (error) {
      setError(error.response.data.errors[0].error_message);
      throw new Error(error);
    }
  };

  const getCellMetricTestStatus = async () => {
    try {
      const response = await getDiagCellMetricTestLatestTestStatusApi(core_id);

      // TIMED_OUT
      if (response.data.data.has_expired) {
        setCurrentCellMetricTestResultUIStatus({
          ...DIAG_TEST_RESULT_STATUSES.TIMED_OUT,
          has_expired: response.data.data.has_expired,
        });
        return;
      }

      if (response.data.data.status) {
        switch (response.data.data.status) {
          case DIAG_TEST_STATUSES.COMPLETED:
            fetchLastCellMetricTestResult();
            // PASS/FAIL
            setCurrentCellMetricTestResultUIStatus(
              DIAG_TEST_CELL_METRIC_RESULT_STATUSES[response.data.data.status]
            );
            return;
          case DIAG_TEST_STATUSES.REJECTED:
            // FAIL
            fetchLastCellMetricTestResult();
            setCurrentCellMetricTestResultUIStatus({
              ...DIAG_TEST_CELL_METRIC_RESULT_STATUSES.FAILED,
              has_expired: response.data.data.has_expired,
            });
            return;
          default:
            setCurrentCellMetricTestResultUIStatus({
              ...DIAG_TEST_CELL_METRIC_RESULT_STATUSES.RUNNING,
              has_expired: response.data.data.has_expired,
            });
            // Polling
            setTimeout(() => {
              getCellMetricTestStatus();
            }, 3000);
            break;
        }
      }
    } catch (error) {
      setError(error.response.data.errors[0].error_message);
      throw new Error(error);
    }
  };

  const startQuickTest = async () => {
    // RUNNING
    setCurrentQuickTestResultUIStatus({ ...DIAG_TEST_RESULT_STATUSES.RUNNING, has_expired: false });
    try {
      const response = await runDiagQuickTestApi(core_id);
      if (response.status === 200) {
        switch (response.data.data.status) {
          case DIAG_TEST_STATUSES.COMPLETED:
            // PASS/FAIL
            setCurrentQuickTestResultUIStatus(
              DIAG_TEST_RESULT_STATUSES[lastQuickTestResult.test_result.test_result]
            );
            fetchLastQuickTestResult(true);
            return;
          case DIAG_TEST_STATUSES.FAILED:
          case DIAG_TEST_STATUSES.REJECTED:
            // FAIL
            setCurrentQuickTestResultUIStatus({
              ...DIAG_TEST_RESULT_STATUSES.FAIL,
              has_expired: response.data.data.has_expired,
            });
            fetchLastQuickTestResult(true);
            return;
          default:
            // poll for test status
            getQuickTestStatus();
            break;
        }
      } else if (response.status === 500) {
        setCurrentQuickTestResultUIStatus({
          ...DIAG_TEST_RESULT_STATUSES.FAIL,
          has_expired: false,
        });
        return;
      }
    } catch (error) {
      console.error(error);
      setCurrentQuickTestResultUIStatus({
        ...DIAG_TEST_RESULT_STATUSES.FAIL,
        has_expired: false,
      });
      return;
    }
  };

  const startLongTest = async () => {
    // RUNNING
    setCurrentLongTestResultUIStatus({ ...DIAG_TEST_RESULT_STATUSES.RUNNING, has_expired: false });
    try {
      const response = await runDiagLongTestApi(core_id);
      if (response.status === 200) {
        switch (response.data.data.status) {
          case DIAG_TEST_STATUSES.COMPLETED:
            fetchLastLongTestResult(true);
            return;
          case DIAG_TEST_STATUSES.FAILED:
          case DIAG_TEST_STATUSES.REJECTED:
            // FAIL
            setCurrentLongTestResultUIStatus({
              ...DIAG_TEST_RESULT_STATUSES.FAIL,
              has_expired: response.data.data.has_expired,
            });
            fetchLastLongTestResult(true);
            return;
          default:
            // poll for test status
            getLongTestStatus();
            break;
        }
      } else if (response.status === 500) {
        setCurrentLongTestResultUIStatus({
          ...DIAG_TEST_RESULT_STATUSES.FAIL,
          has_expired: false,
        });
        return;
      }
    } catch (error) {
      console.error(error);
      setCurrentLongTestResultUIStatus({
        ...DIAG_TEST_RESULT_STATUSES.FAIL,
        has_expired: false,
      });
      return;
    }
  };

  const startCellMetricTest = async () => {
    // RUNNING
    setCurrentCellMetricTestResultUIStatus({
      ...DIAG_TEST_CELL_METRIC_RESULT_STATUSES.RUNNING,
      has_expired: false,
    });
    try {
      const response = await runDiagCellMetricTestApi(core_id);
      if (response.status === 200) {
        switch (response.data.data.status) {
          case DIAG_TEST_STATUSES.COMPLETED:
            fetchLastCellMetricTestResult();
            // PASS/FAIL
            setCurrentCellMetricTestResultUIStatus(
              DIAG_TEST_CELL_METRIC_RESULT_STATUSES[response.data.data.status]
            );
            return;
          case DIAG_TEST_STATUSES.REJECTED:
            // FAIL
            fetchLastCellMetricTestResult();
            setCurrentCellMetricTestResultUIStatus({
              ...DIAG_TEST_CELL_METRIC_RESULT_STATUSES.FAILED,
              has_expired: response.data.data.has_expired,
            });
            return;
          default:
            // poll for test status
            getCellMetricTestStatus();
            break;
        }
      } else if (response.status === 500) {
        setCurrentCellMetricTestResultUIStatus({
          ...DIAG_TEST_RESULT_STATUSES.FAIL,
          has_expired: false,
        });
        return;
      }
    } catch (error) {
      console.error(error);
      setCurrentCellMetricTestResultUIStatus({
        ...DIAG_TEST_RESULT_STATUSES.FAIL,
        has_expired: false,
      });
      return;
    }
  };

  const getSignedOffDiagnosticTest = async () => {
    try {
      const response = await getSignedOffDiagnosticTestApi(core_id);
      if (response.status === 200) {
        if (response.data.data) {
          const signed_off_datetime: DateTime | null = response.data.data.signed_off_on
            ? DateTime.fromISO(response.data.data.signed_off_on).setZone(browserTimezone)
            : null;
          const is_diag_test_enabled: boolean = response.data.data.diag_test_is_enable;
          setSignedOffDiagnosticTestResultDate(signed_off_datetime);
          setDiagnosticTestEnabled(is_diag_test_enabled);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLastQuickTestResult = useCallback(
    async (update_result_ui_status?: boolean) => {
      const result_ui_status = update_result_ui_status ? update_result_ui_status : false;
      setIsLastTestResultLoading(true);
      try {
        const response = await getDiagQuickTestLastResultApi(core_id);
        if (response.status === 200) {
          setLastQuickTestResult(response.data.data);
          if (
            response.data.data &&
            response.data.data.status === DIAG_TEST_STATUSES.COMPLETED &&
            result_ui_status
          ) {
            setCurrentQuickTestResultUIStatus(
              DIAG_TEST_RESULT_STATUSES[response.data.data.test_result.test_result]
            );
          }
        }
      } catch (error) {
        console.error(error);
        throw new Error(error);
      } finally {
        setIsLastTestResultLoading(false);
      }
    },
    [lastQuickTestResult]
  );

  const fetchLastLongTestResult = useCallback(
    async (update_result_ui_status?: boolean) => {
      const result_ui_status = update_result_ui_status ? update_result_ui_status : false;
      setIsLastTestResultLoading(true);
      try {
        const response = await getDiagLongTestLastResultApi(core_id);
        if (response.status === 200) {
          setLastLongTestResult(response.data.data);
          if (
            response.data.data &&
            response.data.data.status === DIAG_TEST_STATUSES.COMPLETED &&
            result_ui_status
          ) {
            setCurrentLongTestResultUIStatus(
              DIAG_TEST_RESULT_STATUSES[response.data.data.test_result.test_result]
            );
          }
        }
      } catch (error) {
        console.error(error);
        throw new Error(error);
      } finally {
        setIsLastTestResultLoading(false);
      }
    },
    [lastLongTestResult]
  );

  const fetchLastCellMetricTestResult = useCallback(async () => {
    setIsLastTestResultLoading(true);
    try {
      const response = await getDiagCellMetricTestLastResultApi(core_id);
      if (response.status === 200) setLastCellMetricTestResult(response.data.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    } finally {
      setIsLastTestResultLoading(false);
    }
  }, []);

  useEffect(() => {
    async function init() {
      try {
        await getDiagTestFanController();
        await getCellMetricTestStatus();
        await getSignedOffDiagnosticTest();
        await getQuickTestStatus();
        await getLongTestStatus();
      } catch (error) {
        console.error(error);
      } finally {
        setIsInitLoading(false);
      }
    }
    init();
  }, []);

  if (!core_id) {
    return (
      <div>
        <h2> Sorry! Invalid Core ID!</h2>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <h2> Sorry! {error}</h2>
      </div>
    );
  }

  if (isLastTestResultLoading || isInitLoading) {
    return <DialogSpinner title="Loading..." open={isLastTestResultLoading || isInitLoading} />;
  }
  const { origin } = location;
  const device_page = `${origin}/grain_bin/devices`;
  return (
    <Grid container className={classes.diagTestsContainer}>
      <Grid container className={classes.diagTestsHeader}>
        <Grid item xs={12} style={{textAlign: 'left', fontSize: '1.285714rem', marginTop: '20px'}}>
          <Button
              color="secondary"
              variant="contained"
              style={{color: "black"}}
              onClick={() => {
                window.location.href = window.location.origin;
              }}
          >
            <HomeOutlined></HomeOutlined>
            <Typography variant="body2" style={{marginLeft: '8px'}}>Go to Amber Dashboard</Typography>
          </Button>
        </Grid>
        <Grid item xs={8} style={{ textAlign: 'start', paddingLeft: 15 }}>
          <h1 style={{ fontWeight: 500 }}> Fan Controller Diagnostics</h1>
          {!isDiagnosticTestEnabled ? (
            <>
              <Typography variant="h5" style={{ color: amber_grey, marginBottom: '10px' }}>
                <strong style={{ color: amber_red }}>Diagnostic Test is disabled.</strong> It can be
                re-enabled from the bin's Devices page within the Amber Dashboard.
              </Typography>
            </>
          ) : null}
          {signedOffDiagnosticTestResultDate ? (
            <>
              <Typography variant="body1" style={{ color: amber_green, marginBottom: '10px' }}>
                Approved on {signedOffDiagnosticTestResultDate.toFormat('ff')}
              </Typography>
            </>
          ) : null}
          {diagTestFanController ? (
            <>
              <Typography variant="body1" style={{ marginBottom: '3px' }}>
                DEVICE INFO:{' '}
              </Typography>
              <Typography variant="body2" gutterBottom display="block">
                <strong>Core ID: </strong>
                {diagTestFanController.core_id}
              </Typography>
              <Typography variant="body2" gutterBottom display="block">
                <strong>Fan Controller ID: </strong>
                {diagTestFanController.alias}
              </Typography>
            </>
          ) : null}
        </Grid>
      </Grid>
      <Grid container style={{ padding: 8 }}>
        <RefreshCellMetricTestCard
          core_id={core_id}
          handleStartCellMetricTest={startCellMetricTest}
          lastCellMetricTestResult={lastCellMetricTestResult}
          currentCellMetricTestResultUIStatus={currentCellMetricTestResultUIStatus}
          currentQuickTestResultUIStatus={currentQuickTestResultUIStatus}
          currentLongTestResultUIStatus={currentLongTestResultUIStatus}
          isDiagnosticTestEnabled={isDiagnosticTestEnabled}
        />
      </Grid>
      <Grid container style={{ padding: 8, minHeight: 419 }}>
        <QuickTestCard
          core_id={core_id}
          lastQuickTestResult={lastQuickTestResult}
          handleStartQuickTest={startQuickTest}
          currentCellMetricTestResultUIStatus={currentCellMetricTestResultUIStatus}
          currentQuickTestResultUIStatus={currentQuickTestResultUIStatus}
          currentLongTestResultUIStatus={currentLongTestResultUIStatus}
          isDiagnosticTestEnabled={isDiagnosticTestEnabled}
        />
      </Grid>
      <Grid container style={{ padding: 8, minHeight: 419 }}>
        <LongTestCard
          core_id={core_id}
          lastLongTestResult={lastLongTestResult}
          handleStartLongTest={startLongTest}
          currentCellMetricTestResultUIStatus={currentCellMetricTestResultUIStatus}
          currentLongTestResultUIStatus={currentLongTestResultUIStatus}
          currentQuickTestResultUIStatus={currentQuickTestResultUIStatus}
          isDiagnosticTestEnabled={isDiagnosticTestEnabled}
        />
      </Grid>
      <DiagTestApproveCard
        core_id={core_id}
        isDiagnosticTestEnabled={isDiagnosticTestEnabled}
        {...props}
      />
    </Grid>
  );
};
