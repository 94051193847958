import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { PileFragmentFragment, withGetPile as HOC } from '../__generated';

export type WithGetPileHocOwnProps = {
  pile_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetPileHocChildProps = { loading: boolean; pile: PileFragmentFragment | null };
export const withGetPileHoc: AmberHoc<WithGetPileHocOwnProps, WithGetPileHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<WithGetPileHocOwnProps, WithGetPileHocChildProps, typeof component>,
    WithGetPileHocChildProps
  >({
    options: ({ pile_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      variables: { pile_id },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          pile: null,
        };
      }
      return {
        loading: loading || false,
        pile: data.pile || null,
      };
    },
  })(component as any);
