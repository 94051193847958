import * as React from 'react';
const DryConditionIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={40}
    height={40}
    viewBox="0 0 40 40"
    {...props}
  >
    <defs>
      <linearGradient
        id="dry-linear-gradient"
        x1={0.164}
        y1={0.168}
        x2={0.86}
        y2={0.834}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f6848a" />
        <stop offset={1} stopColor="#e5dd4e" />
      </linearGradient>
    </defs>
    <rect id="DryIcon" width={40} height={40} rx={20} fill="url(#dry-linear-gradient)" />
  </svg>
);
export { DryConditionIcon };
