import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy } from '../../../util';
import { GetBargeContainerQuery, withGetBargeContainer as HOC } from '../__generated';

export type WithGetBargeContainerHocOwnProps = {
  barge_id: number;
  loading?: boolean;
  pollInterval?: number;
  onError?: (error: ApolloError) => void;
};
export type WithGetBargeContainerHocChildProps = {
  loading: boolean;
  grain_container: GetBargeContainerQuery['grain_container'] | null;
};

export const withGetBargeContainerHoc: AmberHoc<
  WithGetBargeContainerHocOwnProps,
  WithGetBargeContainerHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetBargeContainerHocOwnProps,
      WithGetBargeContainerHocChildProps,
      typeof component
    >,
    WithGetBargeContainerHocChildProps
  >({
    options: ({ barge_id, onError, pollInterval = 1000 * 60 * 30 }) => ({
      pollInterval,
      onError,
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id: barge_id, container_type: ContainerTypeLegacy.barge },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          grain_container: null,
        };
      }
      return {
        loading: loading || false,
        grain_container: data.grain_container || null,
      };
    },
  })(component as any);
