import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { formatDateTime } from './FanScheduleStartCellRenderer';
import { withSkeleton } from './withCellSkeleton';

const useStyles = makeStyles((theme: Theme) => ({
  overlayMsg: {
    width: '100%',
    height: '100%',
    fontSize: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    zIndex: 2,
    color: '#000000',
    fontWeight: 600,
    lineHeight: 1.2,
  },
}));
const FanScheduleEndCellRenderer = withSkeleton((props) => {
  const aerationSchedule = props.value;
  const grain_bin_location_timezone = props.data.grain_bin_location_timezone;

  const hasFanFailToStartError = Boolean(props.data.has_fan_start_fail_error);
  const hasFanFailToStopError = Boolean(props.data.has_fan_stop_fail_error);
  const hasNoSchedule = aerationSchedule.length === 0;
  const hasFanStartStopError = hasFanFailToStartError || hasFanFailToStopError;
  const erroMessage = hasFanStartStopError
    ? hasFanFailToStartError
      ? 'Failed to Start'
      : 'Failed to Stop'
    : '';
  const hasScheduledRunInterrupted = props.data.has_scheduled_run_interrupted;
  const showOverlay = Boolean(hasFanStartStopError || (hasScheduledRunInterrupted && !erroMessage));

  if (hasNoSchedule) {
    return (
      <div>
        {erroMessage && <FanErrorMessage erroMessage={erroMessage} />}
        {hasScheduledRunInterrupted && !erroMessage && <RunInterruptedMessage />}
        <div style={{ opacity: showOverlay ? 0.3 : undefined }}>No Schedule</div>
      </div>
    );
  }
  const nextSchedule = aerationSchedule[0];

  if (!nextSchedule.end_epoch) return <div>Forever</div>;

  const { dateString, timeString } = formatDateTime(
    new Date(nextSchedule.end_epoch),
    grain_bin_location_timezone
  );

  return (
    <div>
      {erroMessage && <FanErrorMessage erroMessage={erroMessage} />}
      {hasScheduledRunInterrupted && !erroMessage && <RunInterruptedMessage />}
      <div style={{ opacity: showOverlay ? 0.3 : undefined }}>
        {dateString}
        <br />
        {timeString}
      </div>
    </div>
  );
});

const FanErrorMessage = ({ erroMessage }: { erroMessage: string }) => {
  const classes = useStyles();
  return <div className={classes.overlayMsg}>{erroMessage}</div>;
};

const RunInterruptedMessage = () => {
  const classes = useStyles();
  return <div className={classes.overlayMsg}>Scheduled Run Interrupted</div>;
};

export { FanScheduleEndCellRenderer };
