import { Button, DialogContent, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FunctionComponent as FC, useState } from 'react';
import AceAirFanController from '../../../../../../site-public-webapp/media/ace_air_fan_controller.png';
import { ContainerTypeLegacy } from '../../../../../src/util';
import { BaseDialog } from '../../../../component';
import { SummaryCard } from '../../summary-card';
import { RecommendationSchedule } from './RecommendationSchedule';
const useStyles = makeStyles((theme: Theme) => ({
  full_width: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  min_height: { minHeight: 419, [theme.breakpoints.down('sm')]: { minHeight: 150 } },
  fan_controller_img: {
    objectFit: 'contain',
    width: '100%',
    height: 200,
    [theme.breakpoints.up('sm')]: { marginTop: 10 },
  },
  contact_text: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'left',
    marginLeft: 10,
  },
}));

export const RecommendationScheduleCard: FC<{
  grain_bin_id: number;
  storage_period?: {
    grain_bin_storage_cycle_id: number;
  } | null;
  container_type: ContainerTypeLegacy;
}> = ({ grain_bin_id, storage_period, container_type }) => {
  const classes = useStyles();
  const [show_help, setShowHelp] = useState<boolean>(false);

  return (
    <SummaryCard
      title="Recommended Schedule"
      header_action={
        <Button style={{ maxHeight: 26 }} onClick={() => setShowHelp(true)}>
          MORE INFO
        </Button>
      }
      contentStyle={{ paddingBottom: 14 }}
    >
      <Grid container className={classes.min_height}>
        <Grid item xs={12} className={classes.full_width}>
          <RecommendationSchedule
            grain_bin_id={grain_bin_id}
            container_type2={container_type}
            storage_period={storage_period}
          />
        </Grid>
      </Grid>
      <BaseDialog
        title="Need an Ace Air Fan Controller?"
        open={show_help}
        handleClose={() => setShowHelp(false)}
      >
        <DialogContent>
          <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.contact_text}>
                Contact <a href="mailto:hank@amber.ag">hank@amber.ag</a>
              </Typography>
              <img
                className={classes.fan_controller_img}
                src={AceAirFanController}
                alt="ace air fan controller"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </BaseDialog>
    </SummaryCard>
  );
};
