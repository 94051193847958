import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import React, { useCallback, useMemo, useState } from 'react';
import { GetProps, Omit, useDispatch } from 'react-redux';
import * as yup from 'yup';

import { createSession } from '../../action';
import {
  UserFragmentFragment,
  ViewerFragmentFragment,
  withCreateUserHoc,
  WithCreateUserHocChildProps,
  withUpdateUserHoc,
  WithUpdateUserHocChildProps,
} from '../../api';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../util/form2/BaseForm';
import { Button, ButtonSubmit } from '../util/form2/Button';
import { ErrorBox } from '../util/form2/ErrorBox';
import { TelephoneField } from '../util/form2/TelephoneField';
import { TextField } from '../util/form2/TextField';
import { EmailVerificationSent } from './EmailVerificationSent';

const phoneUtil = PhoneNumberUtil.getInstance();

const useStyles = makeStyles({
  centered: {
    textAlign: 'center',
  },
  grid: {
    minWidth: 250,
    maxWidth: 250,
  },
});

type Values = {
  user_id: number;
  first_name: string;
  last_name: string;
  telephone: string;
  email_address: string;
  confirm_email_address?: string;
  password: string;
  password2: string;
};

const validationSchema = yup.object().shape({
  user_id: yup
    .number()
    .typeError('A number is required')
    .integer()
    .required(),
  first_name: yup
    .string()
    .label('First Name')
    .required(),
  last_name: yup
    .string()
    .label('Last Name')
    .required(),
  telephone: yup
    .string()
    .label('Telephone')
    .required()
    .test({
      message: 'Must be a valid telephone number',
      test: (value: Values['telephone']): boolean => {
        try {
          return phoneUtil.isValidNumber(phoneUtil.parse(value));
        } catch {
          return false;
        }
      },
    } as yup.TestOptions),
  email_address: yup
    .string()
    .label('Email Address')
    .email()
    .required(),
  confirm_email_address: yup
    .string()
    .label('Email Address Confirmation')
    .when('user_id', (value, schema) =>
      value !== -1
        ? schema
        : schema
            .email()
            .required()
            .when('email_address', (email, schema) =>
              schema.test({
                message: 'Emails do not match',
                test: (value) => value === email,
              } as yup.TestOptions)
            )
    ),
  password: yup
    .string()
    .label('Password')
    .when('user_id', (value, schema) => (value !== -1 ? schema : schema.required())),
  password2: yup
    .string()
    .label('Verify Password')
    .when('user_id', (value, schema) =>
      value !== -1
        ? schema
        : schema.required().when('password', (password, schema) =>
            schema.test({
              message: 'Password does not match',
              test: (value) => value === password,
            } as yup.TestOptions)
          )
    ),
});

export const UserFormBase = ({
  token,
  user,
  createUser,
  updateUser,
  onClickPassword,
  ...props
}: WithCreateUserHocChildProps &
  WithUpdateUserHocChildProps &
  (
    | (FormikWrapperHandlerProps<Values, ViewerFragmentFragment> & {
        token: string;
        user: null;
      })
    | (FormikWrapperHandlerProps<Values, ViewerFragmentFragment> & {
        token: null;
        user: UserFragmentFragment;
      })) & { onClickPassword?: () => void; rootContainerStyles?: object }) => {
  const classes = useStyles();
  const [succeeded, setSucceeded] = useState(false);
  const [name, setName] = useState(
    user
      ? {
          first_name: user.first_name,
          last_name: user.last_name,
        }
      : { first_name: '', last_name: '' }
  );
  const initialValues = useMemo<Values>(() => {
    if (!user) {
      return {
        user_id: -1,
        first_name: '',
        last_name: '',
        telephone: '',
        email_address: '',
        confirm_email_address: '',
        password: '',
        password2: '',
      };
    }
    let telephone: string = '';
    try {
      telephone = phoneUtil.format(phoneUtil.parse(user.telephone), PhoneNumberFormat.E164);
    } catch {
      try {
        telephone = phoneUtil.format(phoneUtil.parse(user.telephone, 'US'), PhoneNumberFormat.E164);
      } catch {}
    }
    return {
      telephone,
      user_id: user.user_id,
      first_name: user.first_name,
      last_name: user.last_name,
      email_address: user.email_address,
      password: '',
      password2: '',
    };
  }, [user]);
  const submitCallback = useCallback(
    async (values) => {
      setSucceeded(false);
      const {
        first_name,
        last_name,
        telephone,
        email_address,
        password,
      } = validationSchema.validateSync(values);
      const result = user
        ? updateUser({
            first_name,
            last_name,
            email_address,
            telephone,
            user_id: user.user_id,
          })
        : createUser({
            first_name,
            last_name,
            email_address,
            password,
            telephone,
            token: token as string,
          });
      setSucceeded(true);
      setName({
        first_name,
        last_name,
      });
      return result;
    },
    [token, user]
  );

  return (
    <FormikWrapper<Values, UserFragmentFragment | ViewerFragmentFragment>
      {...props}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={submitCallback}
      render={({ isSubmitting, initialValues: { user_id, first_name, last_name } }) => (
        <BaseForm
          submitting_message="Saving user..."
          style={props.rootContainerStyles ? props.rootContainerStyles : undefined}
        >
          <Grid
            container
            direction="row"
            alignContent="flex-start"
            alignItems="center"
            justify="center"
            spacing={2}
            className={classes.grid}
          >
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.centered}>
                {user_id === -1
                  ? 'Register a New User Account'
                  : `${name.first_name} ${name.last_name}`}
              </Typography>
            </Grid>
            {succeeded ? (
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.centered}>
                  {user_id === -1 ? 'User Registration Successful!' : 'Changes Saved'}
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="first_name"
                    placeholder="John"
                    required={user_id === -1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="last_name"
                    placeholder="Doe"
                    required={user_id === -1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TelephoneField
                    fullWidth
                    name="telephone"
                    label="Phone Number"
                    required={user_id === -1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email_address"
                    placeholder="john.doe@gmail.com"
                    type="email"
                    required={user_id === -1}
                  />
                </Grid>
                {user_id === -1 && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Confirm Email Address"
                      name="confirm_email_address"
                      placeholder="john.doe@gmail.com"
                      type="email"
                      required={user_id === -1}
                    />
                  </Grid>
                )}
                {!user && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Password"
                        name="password"
                        placeholder="Password"
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Verify Password"
                        name="password2"
                        placeholder="Verify Password"
                        type="password"
                      />
                    </Grid>
                  </>
                )}
                <ErrorBox />
                <Grid item xs={12} className={classes.centered}>
                  <ButtonSubmit />
                </Grid>
                {onClickPassword && (
                  <>
                    <Divider />
                    <Grid item xs={12} className={classes.centered}>
                      <Button disabled={isSubmitting} onClick={onClickPassword}>
                        CHANGE PASSWORD
                      </Button>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </BaseForm>
      )}
    />
  );
};

export const UserForm = withCreateUserHoc(withUpdateUserHoc(UserFormBase));

export const RegisterUserForm = ({
  onSubmitSuccess,
  ...props
}: Omit<GetProps<typeof UserForm>, 'user'>) => {
  const dispatch = useDispatch();
  const [verify_sent, setVerifySent] = useState(false);
  const submitSuccessCallback = useCallback(
    (result, formikHelpers) => {
      onSubmitSuccess && onSubmitSuccess(result, formikHelpers);
      // if account needs verification
      if (result && !result.user && !result.verified_email) {
        // remove token to prevent use of otp
        window.localStorage.removeItem('token');
        // show dialog to verify email
        setVerifySent(true);
      } else {
        dispatch(createSession({ viewer: result, propagate: true }));
      }
    },
    [dispatch, onSubmitSuccess]
  );
  return verify_sent ? (
    <EmailVerificationSent />
  ) : (
    <UserForm {...props} user={null} onSubmitSuccess={submitSuccessCallback} />
  );
};

export const UpdateUserForm = ({
  user,
  ...props
}: Omit<GetProps<typeof UserForm>, 'token'> & {
  user: UserFragmentFragment;
  rootContainerStyles?: object;
}) => <UserForm {...props} token={null} user={user} />;
