import React from 'react';
export const CollapseIcon = (props) => (
  <svg
    id="CollapseIcon"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={16.718}
    height={16.71}
    viewBox="0 0 16.718 16.71"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_2425"
          data-name="Rectangle 2425"
          width={16.718}
          height={16.71}
          fill="#333"
        />
      </clipPath>
    </defs>
    <g id="Group_126" data-name="Group 126" clipPath="url(#clip-path)">
      <path
        id="Path_422"
        data-name="Path 422"
        d="M1.475,263.944l4.409-4.417v3.825a.833.833,0,0,0,1.667,0v-5.834a.834.834,0,0,0-.833-.833H.883a.833.833,0,0,0,0,1.667H4.708L.291,262.76a.833.833,0,1,0,1.175,1.175Z"
        transform="translate(0 -247.517)"
        fill="#333"
      />
      <path
        id="Path_423"
        data-name="Path 423"
        d="M265.319.242a.833.833,0,0,0-1.175,0l-4.417,4.417V.834a.833.833,0,0,0-1.667,0V6.668a.834.834,0,0,0,.833.833h5.834a.833.833,0,0,0,0-1.667H260.9l4.409-4.409a.833.833,0,0,0,.008-1.183Z"
        transform="translate(-248.843)"
        fill="#333"
      />
    </g>
  </svg>
);
