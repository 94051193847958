import { Divider, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { RedWarningIcon } from '../../../../../core/src/media';
import { amber_error_red, amber_red } from '../../../../../core/src/style';
import { FanControllerWithError, getErrorCodeString } from '../aeration/FanControllerErrorDisplay';

const useStyles = makeStyles((theme: Theme) => ({
  centered_column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 20,
  },
  errors: {
    border: `2px solid ${amber_error_red}`,
    padding: 10,
    borderRadius: 4,
    [theme.breakpoints.up('sm')]: {
      width: '66%',
    },
  },
}));
type LogFanErrorsProps = {
  fan_controllers_with_errors: FanControllerWithError[];
};
const LogFanErrors = ({ fan_controllers_with_errors }: LogFanErrorsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.centered_column}>
      <div className={classes.errors}>
        {fan_controllers_with_errors.map(({ error_code, alias, core_id }, ix) => (
          <div key={core_id}>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <div style={{ marginRight: 5 }}>
                <RedWarningIcon
                  style={{
                    width: 26,
                    height: 26,
                    fill: amber_red,
                  }}
                />
              </div>
              <p style={{ textAlign: 'start', fontSize: 16, color: amber_error_red, margin: 0 }}>
                {fan_controllers_with_errors.length !== 1 && `Error ${ix + 1}: `}
                {getErrorCodeString(error_code, alias)}
              </p>
            </div>
            {ix !== fan_controllers_with_errors.length - 1 && (
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogFanErrors;
