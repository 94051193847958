import { Button, ButtonGroup, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React, { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  ActiveHubsLevelSamples,
  GrainBinLevelSample,
  GrainBinTicketLevelSample,
  withGetGrainContainerHistoryGrainLevelHoc,
  WithGetGrainContainerHistoryGrainLevelHocChildProps,
  withGetGrainContainerHistoryGrainTicketsLevelHoc,
  WithGetGrainContainerHistoryGrainTicketsLevelHocChildProps,
  withGetSensorGeneratedGrainTicketsHoc,
  WithGetSensorGeneratedGrainTicketsHocChildProps,
} from '../../../../api';

import { ActiveStoragePeriodContext } from '../../../../contexts/activeStoragePeriod';
import { amber_amber, white } from '../../../../style';
import { ContainerTypeLegacy, RelativePeriods } from '../../../../util/constant';
import { CenteredSpinner } from '../../../spinner';
import { GRAIN_TICKET_APPROVE_STATE } from '../../manage-grain-tickets/manage-pending-grain-tickets';
import { SummaryCard } from '../SummaryCard';
import { LevelHistoryCardHeaderAction } from './LevelHistoryCardHeaderAction';
import { isValidDate } from './LevelHistoryPlotHelpers';
import LevelHistoryPlotV2 from './LevelHistoryPlotV2';

const useStyles = makeStyles({
  root_0: {
    paddingBottom: 25,
  },
  root_1: {
    paddingBottom: 0,
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 196,
    height: '100%',
  },
  selectedButton: {
    backgroundColor: amber_amber,
    color: white,
    '&:hover': {
      backgroundColor: fade(amber_amber, 0.8),
    },
  },
});

export type LevelHistoryCardPropsType = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  as_of: Date;
  period: string;
  onSelectPeriod?: (value: RelativePeriods) => any;
  height?: number;
  width?: number;
  grain_bin_location_timezone: string;
  url_base: string;
  minHeight?: number;
  maxHeight?: number;
  title: string;
  showWithoutSummaryCard?: boolean;
  show_grain_ticket_level_deviation_warning: boolean;
  hideManageGrainTicketsBtn?: boolean;
} & WithGetGrainContainerHistoryGrainLevelHocChildProps &
  WithGetSensorGeneratedGrainTicketsHocChildProps &
  WithGetGrainContainerHistoryGrainTicketsLevelHocChildProps;

export const LevelHistoryCard = withGetSensorGeneratedGrainTicketsHoc(
  withGetGrainContainerHistoryGrainLevelHoc(
    withGetGrainContainerHistoryGrainTicketsLevelHoc(
      ({
        container_id,
        container_type,
        url_base,
        as_of,
        period,
        title,
        onSelectPeriod,
        loading,
        height,
        width,
        minHeight,
        maxHeight,
        grain_bin_location_timezone,
        grain_bin_level_history,
        grain_bin_tickets_level_history,
        is_bin_tickets_history_loading,
        showWithoutSummaryCard,
        show_grain_ticket_level_deviation_warning,
        hideManageGrainTicketsBtn = false,
        get_sensor_generated_grain_tickets_loading,
        sensor_generated_grain_tickets,
      }: LevelHistoryCardPropsType) => {
        console.log('Grain Bin Level History:', grain_bin_level_history);
        console.log('Grain Bin Tickets History:', grain_bin_tickets_level_history);

        console.log('sensor_generated_grain_tickets', sensor_generated_grain_tickets);

        const classes = useStyles();
        const dispatch = useDispatch();
        const ctx = useContext(ActiveStoragePeriodContext);
        const activeStoragePeriod = ctx.activeStoragePeriod;
        const today = DateTime.local();
        today.set({ hour: 12 });
        const { max_bushels, active_hubs_level_samples } = grain_bin_level_history;
        const { grain_tickets_level_samples } = grain_bin_tickets_level_history;
        const {
          no_grain_tickets_created,
          grain_tickets_bushel_level_on_period_start,
          grain_tickets_bushel_level_on_period_end,
        } = grain_bin_tickets_level_history;

        const active_hubs_formatted_level_data =
          active_hubs_level_samples.length > 0
            ? active_hubs_level_samples.map(({ hub_id, level_samples }: ActiveHubsLevelSamples) => {
                const bushelsData =
                  level_samples.length > 0
                    ? level_samples
                        .filter(({ epoch_time: dt }: GrainBinLevelSample) => {
                          if (
                            activeStoragePeriod &&
                            activeStoragePeriod.start_date.getTime() <= new Date(dt).getTime() &&
                            new Date(dt).getTime() <=
                              (activeStoragePeriod.end_date
                                ? activeStoragePeriod.end_date.getTime()
                                : Infinity)
                          ) {
                            return isValidDate(dt, period, today);
                          }
                          if (activeStoragePeriod === null) {
                            return isValidDate(dt, period, today);
                          }
                          return false;
                        })
                        .map(({ epoch_time, bushel }: GrainBinLevelSample) => ({
                          x: DateTime.fromMillis(new Date(epoch_time).getTime())
                            .set({ second: 0 })
                            .toMillis(),
                          y: bushel,
                        }))
                        .sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime())
                    : [];
                return { hub_id, bushelsData };
              })
            : [];

        // Grain Tickets within Active Storage Period
        let active_grain_tickets_level_data =
          grain_tickets_level_samples.length > 0
            ? grain_tickets_level_samples
                .filter(({ epoch_time: dt }: GrainBinTicketLevelSample) => {
                  if (
                    activeStoragePeriod &&
                    activeStoragePeriod.start_date.getTime() <= new Date(dt).getTime() &&
                    new Date(dt).getTime() <=
                      (activeStoragePeriod.end_date
                        ? activeStoragePeriod.end_date.getTime()
                        : Infinity)
                  ) {
                    return true;
                  }
                  if (activeStoragePeriod === null) {
                    return true;
                  }
                  return false;
                })
                .map(({ epoch_time, bushel }: GrainBinTicketLevelSample) => ({
                  x: DateTime.fromMillis(new Date(epoch_time).getTime()).toMillis(),
                  y: bushel,
                }))
                .sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime())
            : [];

        // added grain bushel level on period start and end
        if (
          grain_tickets_bushel_level_on_period_start &&
          grain_tickets_bushel_level_on_period_end
        ) {
          active_grain_tickets_level_data = [
            {
              x: DateTime.fromMillis(
                new Date(grain_tickets_bushel_level_on_period_start.epoch_time).getTime()
              ).toMillis(),
              y: grain_tickets_bushel_level_on_period_start.bushel,
            },
            ...active_grain_tickets_level_data,
            {
              x: DateTime.fromMillis(
                new Date(grain_tickets_bushel_level_on_period_end.epoch_time).getTime()
              ).toMillis(),
              y: grain_tickets_bushel_level_on_period_end.bushel,
            },
          ];
        }

        console.log('active_hubs_formatted_level_data', active_hubs_formatted_level_data);
        console.log('active_grain_tickets_level_data', active_grain_tickets_level_data);

        const applyHeaderStyles =
          show_grain_ticket_level_deviation_warning && !hideManageGrainTicketsBtn;

        // last Pending Grain Ticket Generated Days Text
        const sortedGrainBinTickets = useMemo(() => {
          return sensor_generated_grain_tickets.sort(
            (a, b) => new Date(b.epoch_time).getTime() - new Date(a.epoch_time).getTime()
          );
        }, [JSON.stringify(sensor_generated_grain_tickets)]);
        const pendingGrainTickets = useMemo(() => {
          return sortedGrainBinTickets.filter(
            ({ approve_state }) => approve_state === GRAIN_TICKET_APPROVE_STATE.pending
          );
        }, [JSON.stringify(sortedGrainBinTickets)]);
        const lastPendingGrainTicket =
          pendingGrainTickets.length > 0 ? pendingGrainTickets[0] : null;
        const lastPendingGrainTicketGeneratedDaysText: string = useMemo(() => {
          if (
            grain_bin_location_timezone &&
            show_grain_ticket_level_deviation_warning &&
            lastPendingGrainTicket
          ) {
            const lastPendingGrainTicketGeneratedOn: Date = new Date(
              lastPendingGrainTicket.epoch_time
            );
            if (lastPendingGrainTicketGeneratedOn) {
              const asOf = DateTime.local().setZone(grain_bin_location_timezone);
              const lastPendingGrainTicketGeneratedOnDt = DateTime.fromMillis(
                new Date(lastPendingGrainTicketGeneratedOn).getTime()
              ).setZone(grain_bin_location_timezone);
              const daysDiff = Math.round(
                asOf.diff(lastPendingGrainTicketGeneratedOnDt, ['days']).toObject().days
              );
              return daysDiff === 0 ? `Today` : `${daysDiff} Days Ago`;
            }
          }
          return '';
        }, [
          grain_bin_location_timezone,
          show_grain_ticket_level_deviation_warning,
          lastPendingGrainTicket,
        ]);

        const hasEmptyPlaceHolder =
          !loading &&
          !is_bin_tickets_history_loading &&
          (grain_bin_level_history &&
            grain_bin_level_history.active_hubs_level_samples.length === 0 &&
            no_grain_tickets_created);

        const EmptyPlaceholder = () => (
          <div
            style={{
              height: '100%',
              minHeight: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              No Grain Level Data
              <br /> Add a Grain Ticket to start tracking
            </Typography>
          </div>
        );

        const RenderLevelChart = () => (
          <div
            style={{
              minHeight: minHeight && minHeight,
              maxHeight: maxHeight && maxHeight,
              padding: '10px 0px',
            }}
          >
            {hasEmptyPlaceHolder ? (
              <EmptyPlaceholder />
            ) : (
              <div className={`${onSelectPeriod ? classes.root_1 : classes.root_0}`}>
                {loading ||
                is_bin_tickets_history_loading ||
                get_sensor_generated_grain_tickets_loading ? (
                  <div style={height ? { minHeight: height } : {}} className={classes.spinner}>
                    <CenteredSpinner fadeIn="none" />
                  </div>
                ) : (
                  <>
                    <LevelHistoryPlotV2
                      grain_bin_location_timezone={grain_bin_location_timezone}
                      period={period}
                      active_hubs_formatted_level_data={active_hubs_formatted_level_data}
                      active_grain_tickets_level_data={active_grain_tickets_level_data}
                      max_bushels={max_bushels}
                    />
                  </>
                )}
                {onSelectPeriod && (
                  <ButtonGroup
                    color="secondary"
                    size="small"
                    style={{ marginTop: 15, display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      className={period === RelativePeriods.day ? classes.selectedButton : ''}
                      onClick={() => onSelectPeriod(RelativePeriods.day)}
                    >
                      1D
                    </Button>
                    <Button
                      className={period === RelativePeriods.week ? classes.selectedButton : ''}
                      onClick={() => onSelectPeriod(RelativePeriods.week)}
                    >
                      1W
                    </Button>
                    <Button
                      className={period === RelativePeriods.month ? classes.selectedButton : ''}
                      onClick={() => onSelectPeriod(RelativePeriods.month)}
                    >
                      1M
                    </Button>
                    <Button
                      className={period === RelativePeriods.quarter ? classes.selectedButton : ''}
                      onClick={() => onSelectPeriod(RelativePeriods.quarter)}
                    >
                      3M
                    </Button>
                  </ButtonGroup>
                )}
              </div>
            )}
          </div>
        );
        return showWithoutSummaryCard ? (
          <RenderLevelChart />
        ) : (
          <SummaryCard
            title={title}
            headerStyle={applyHeaderStyles ? { display: 'flex', alignItems: 'start' } : undefined}
            header_action={
              !loading &&
              !is_bin_tickets_history_loading && (
                <LevelHistoryCardHeaderAction
                  url_base={url_base}
                  showGrainTicketLevelDeviationWarning={show_grain_ticket_level_deviation_warning}
                  hideManageGrainTicketsBtn={hideManageGrainTicketsBtn}
                  noGrainTicketsCreated={no_grain_tickets_created}
                  lastPendingGrainTicketGeneratedDaysText={lastPendingGrainTicketGeneratedDaysText}
                />
              )
            }
          >
            <RenderLevelChart />
          </SummaryCard>
        );
      }
    )
  )
);
