import { Link } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pushNav } from '../../../../core/src/action';
import { LaunchLinkIcon } from '../../../../core/src/media';
import { amber_link } from '../../../../core/src/style';
import { Banner } from '../util';

export const getGrainTicketWarningMessage = ({ gotoManageTickets, showLink = true }) => (
  <>
    Hardware Level Sensor and Grain Tickets differ.{' '}
    {showLink ? (
      <>
        Review{' '}
        <Link
          style={{
            color: amber_link,
            textDecoration: 'underline',
            display: 'inline-flex',
            alignItems: 'center',
          }}
          onClick={gotoManageTickets}
        >
          Pending Grain Tickets
          <LaunchLinkIcon width={20} height={18} fill={amber_link} />
        </Link>
      </>
    ) : (
      <>Review Pending Grain Tickets</>
    )}
    .
  </>
);

const CO2_ACTIONS = {
  co2_inspect: 'co2_inspect',
  co2_take_action: 'co2_take_action',
};

const getCO2LevelWarningMessage = (co2_alert_type: string) => {
  if (co2_alert_type === CO2_ACTIONS.co2_inspect) {
    return 'INSPECT: A CO2 level above 1000ppm was detected. Review CO2 data or visit bin.';
  }
  if (co2_alert_type === CO2_ACTIONS.co2_take_action) {
    return 'TAKE ACTION: A CO2 level above 1500ppm was detected. Review CO2 data or visit bin.';
  }
  return '';
};

const GrainBinBanners = ({ url_base }: { url_base: string }) => {
  const {
    showGrainTicketWarningBanner,
    showCO2WarningBanner,
  }: {
    showGrainTicketWarningBanner: boolean;
    showCO2WarningBanner: {
      co2AlertSentOn: Date;
      co2AlertType: string;
    } | null;
  } = useSelector(({ global_state: { showGrainTicketWarningBanner, showCO2WarningBanner } }) => ({
    showGrainTicketWarningBanner,
    showCO2WarningBanner,
  }));
  const dispatch = useDispatch();
  const gotoManageTickets = useCallback(() => dispatch(pushNav({ path: `${url_base}/tickets` })), [
    url_base,
  ]);
  const co2AlertMsg = showCO2WarningBanner
    ? getCO2LevelWarningMessage(showCO2WarningBanner.co2AlertType)
    : '';
  const grainTicketWarningMsg = getGrainTicketWarningMessage({ gotoManageTickets });

  return (
    <>
      {co2AlertMsg && (
        <Banner
          type="error"
          text={co2AlertMsg}
          styles={{ marginBottom: showGrainTicketWarningBanner ? 10 : 0 }}
        />
      )}
      {showGrainTicketWarningBanner && <Banner type="warning" text={grainTicketWarningMsg} />}
    </>
  );
};

export default GrainBinBanners;
