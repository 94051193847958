import { makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { amber_very_faded_black, white } from '../../../../../core/src/style';
import { OperationsViewInfo } from '../../nav/OperationsViewInfo';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: amber_very_faded_black,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    height: 'fit-content',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flex: 1,
    backgroundColor: white,
    alignItems: 'center',
  },
}));

const OperationsViewHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant="h5" style={{ textAlign: 'center', margin: '10px 0px' }}>
        Operations View
      </Typography>

      <OperationsViewInfo width={250} />
    </div>
  );
};

export { OperationsViewHeader };
