import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 100,
  },
});

export const SelectContainerPlaceholder: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h5">Select an Asset</Typography>
    </div>
  );
};

export const NoContainerFoundPlaceholder: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h5">No Assets Found</Typography>
    </div>
  );
};
