import { Button, DialogContent } from '@material-ui/core';
import React, { FunctionComponent as FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pushNav } from '../../../../../core/src/action';
import {
  withGetContainerGrainLevelPercentHoc,
  WithGetContainerGrainLevelPercentHocChildProps,
} from '../../../../../core/src/api';
import { ContainerTypeLegacy } from '../../../../../core/src/util';
import { CenteredSpinner } from '../../spinner';
import { BaseDialog } from '../../util';
import { SummaryCard } from '../summary-card';
import { AerationRuntimeSection } from './AerationRuntimeSection';
import { FanRunHistoryMoreInfo } from './FanRunHistoryMoreInfo';

const FanRunHistoryCardBase: FC<{
  storage_period?: {
    grain_bin_storage_cycle_id: number;
  } | null;
  grain_bin_id: number;
  container_type: ContainerTypeLegacy;
  cfmPerBu: number | null;
  fullBinCfmPerBu: number | null;
  url_base: string;
}> = ({ grain_bin_id, container_type, storage_period, cfmPerBu, fullBinCfmPerBu, url_base }) => {
  const dispatch = useDispatch();
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const goToManageGrainTickets = () => dispatch(pushNav({ path: `${url_base}/tickets` }));

  return (
    <SummaryCard
      title="Fan Run History"
      header_action={
        <Button style={{ maxHeight: 26 }} onClick={() => setShowMoreInfo(true)}>
          MORE INFO
        </Button>
      }
      contentStyle={{ paddingBottom: 14 }}
    >
      <AerationRuntimeSection
        storage_period={storage_period}
        container_id={grain_bin_id}
        container_type={container_type}
      />
      <BaseDialog title="More Info" open={showMoreInfo} handleClose={() => setShowMoreInfo(false)}>
        <DialogContent style={{ textAlign: 'start' }}>
          <FanRunHistoryMoreInfo
            grain_bin_id={grain_bin_id}
            cfmPerBu={cfmPerBu}
            goToManageGrainTickets={goToManageGrainTickets}
            fullBinCfmPerBu={fullBinCfmPerBu}
          />
        </DialogContent>
      </BaseDialog>
    </SummaryCard>
  );
};

export const FanRunHistoryCard = withGetContainerGrainLevelPercentHoc(FanRunHistoryCardBase);
