import { Button, Dialog, DialogActions, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({ root: { padding: 20 } });
export const AlertDialog: React.FunctionComponent<{
  messages: string[];
  onClickClose: () => void;
}> = ({ messages, onClickClose }) => {
  const classes = useStyles();
  const has_messages = messages.length > 0;
  return (
    <Dialog open={has_messages} maxWidth="xs" style={{ padding: 10 }}>
      {has_messages &&
        messages.map((message, ix) => (
          <DialogContentText key={ix} classes={classes}>
            {message}
          </DialogContentText>
        ))}
      <DialogActions>
        <Button variant="outlined" onClick={onClickClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
