import { NormalizedCache } from 'apollo-cache-inmemory';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetGrainContainerHubLinksDocument,
  GetGrainContainerHubLinksQuery,
  GetGrainContainerHubLinksQueryVariables,
  HubContainerLinkBaseFragmentFragment as Result,
  UpdateHubAssignmentMutationVariables as Variables,
  withUpdateHubAssignment as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUpdateHubAssignmentHocChildProps = {
  updateHubAssignment: (variables: Variables) => Promise<Result>;
};
export const withUpdateHubAssignmentHoc: AmberHoc<{}, WithUpdateHubAssignmentHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<{}, WithUpdateHubAssignmentHocChildProps, typeof component>,
    WithUpdateHubAssignmentHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updateHubAssignment: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { updateHubAssignment: updated } = { ...data };

              const { grain_container = null } =
                proxy.readQuery<
                  GetGrainContainerHubLinksQuery,
                  GetGrainContainerHubLinksQueryVariables
                >({
                  query: GetGrainContainerHubLinksDocument,
                  variables: {
                    container_id: updated.container_id,
                    container_type: updated.container_type,
                  },
                }) || {};

              if (grain_container) {
                proxy.writeQuery<
                  GetGrainContainerHubLinksQuery,
                  GetGrainContainerHubLinksQueryVariables
                >({
                  query: GetGrainContainerHubLinksDocument,
                  data: {
                    __typename: 'Query',
                    grain_container: {
                      ...grain_container,
                      active_hub_links: (grain_container.active_hub_links || []).filter(
                        (link) => link.end_epoch.getTime() > new Date().getTime()
                      ),
                    },
                  },
                  variables: {
                    container_id: updated.container_id,
                    container_type: updated.container_type,
                  },
                });
              }
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.updateHubAssignment;
        },
      };
    },
  })(component as any);
