import { VictoryThemeDefinition } from 'victory';
import { amber_alternate_grey, amber_amber, amber_light_grey } from './color';

const yellow200 = '#FFF59D';
const deepOrange600 = '#F4511E';
const lime300 = '#DCE775';
const lightGreen500 = '#8BC34A';
const teal700 = '#00796B';
const cyan900 = '#006064';
const colors = [deepOrange600, yellow200, lime300, lightGreen500, teal700, cyan900];
// *
// * Typography
// *
const sansSerif = "'Source Sans Pro', sans-serif";
const letterSpacing = 'normal';
const fontSize = 10;
// *
// * Layout
// *
const padding = 8;
const baseProps = {
  width: 350,
  height: 350,
  padding: 50,
};
// *
// * Labels
// *
const baseLabelStyles = {
  fontSize,
  letterSpacing,
  padding,
  fontFamily: sansSerif,
  fill: amber_alternate_grey,
  stroke: 'transparent',
  strokeWidth: 0,
};

const centeredLabelStyles = Object.assign({ textAnchor: 'middle' }, baseLabelStyles);
// *
// * Strokes
// *
const strokeDasharray = '3, 3';
const strokeLinecap = 'round';
const strokeLinejoin = 'round';

export const victory_theme: VictoryThemeDefinition = <VictoryThemeDefinition>(<unknown>{
  area: Object.assign(
    {
      style: {
        data: {
          fill: amber_amber,
          stroke: 'transparent',
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps
  ),
  axis: Object.assign(
    {
      style: {
        axis: {
          strokeLinecap,
          strokeLinejoin,
          fill: 'transparent',
          stroke: amber_light_grey,
          strokeWidth: 1,
        },
        axisLabel: Object.assign({}, centeredLabelStyles, {
          padding,
          stroke: 'transparent',
        }),
        grid: {
          strokeDasharray,
          strokeLinecap,
          strokeLinejoin,
          fill: 'none',
          stroke: amber_light_grey,
          pointerEvents: 'none',
        },
        ticks: {
          strokeLinecap,
          strokeLinejoin,
          fill: 'transparent',
          size: 5,
          stroke: amber_light_grey,
          strokeWidth: 1,
        },
        tickLabels: Object.assign({}, baseLabelStyles, {
          fill: amber_alternate_grey,
        }),
      },
    },
    baseProps
  ),
  bar: Object.assign(
    {
      style: {
        data: {
          padding,
          fill: amber_alternate_grey,
          strokeWidth: 0,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps
  ),
  candlestick: Object.assign(
    {
      style: {
        data: {
          stroke: amber_alternate_grey,
        },
        labels: centeredLabelStyles,
      },
      candleColors: {
        positive: amber_alternate_grey,
        negative: amber_alternate_grey,
      },
    },
    baseProps
  ),
  chart: baseProps,
  errorbar: Object.assign(
    {
      style: {
        data: {
          fill: 'transparent',
          opacity: 1,
          stroke: amber_alternate_grey,
          strokeWidth: 2,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps
  ),
  group: Object.assign(
    {
      colorScale: colors,
    },
    baseProps
  ),
  line: Object.assign(
    {
      style: {
        data: {
          fill: 'transparent',
          opacity: 1,
          stroke: amber_amber,
          strokeWidth: 2,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps
  ),
  pie: Object.assign(
    {
      colorScale: colors,
      style: {
        data: {
          padding,
          stroke: amber_alternate_grey,
          strokeWidth: 1,
        },
        labels: Object.assign({}, baseLabelStyles, { padding: 20 }),
      },
    },
    baseProps
  ),
  scatter: Object.assign(
    {
      style: {
        data: {
          fill: amber_alternate_grey,
          opacity: 1,
          stroke: 'transparent',
          strokeWidth: 0,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps
  ),
  stack: Object.assign(
    {
      colorScale: colors,
    },
    baseProps
  ),
  tooltip: {
    style: Object.assign({}, centeredLabelStyles, { padding: 5, pointerEvents: 'none' }),
    flyoutStyle: {
      stroke: amber_alternate_grey,
      strokeWidth: 1,
      fill: '#f0f0f0',
      pointerEvents: 'none',
    },
    cornerRadius: 5,
    pointerLength: 10,
  },
  voronoi: Object.assign(
    {
      style: {
        data: {
          fill: 'transparent',
          stroke: 'transparent',
          strokeWidth: 0,
        },
        labels: Object.assign({}, centeredLabelStyles, { padding: 5, pointerEvents: 'none' }),
        flyout: {
          stroke: amber_alternate_grey,
          strokeWidth: 1,
          fill: '#f0f0f0',
          pointerEvents: 'none',
        },
      },
    },
    baseProps
  ),
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: 'vertical',
    titleOrientation: 'top',
    style: {
      data: {
        type: 'circle',
      },
      labels: baseLabelStyles,
      title: Object.assign({}, baseLabelStyles, { padding: 5 }),
    },
  },
});
