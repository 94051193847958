import imperial from './imperial';
import metric from './metric';

const translations = {
  imperial,
  metric,
};

const UNIT_SYSTEM = {
  imperial: 'imperial',
  metric: 'metric',
};

const defaultUnitLocale = imperial;

const getTranslatedUnitName = (key, localeUnitSystem = UNIT_SYSTEM.imperial) => {
  const currTranslation = translations[localeUnitSystem]
    ? translations[localeUnitSystem]
    : defaultUnitLocale;
  const transWord = currTranslation[key]
    ? currTranslation[key]
    : defaultUnitLocale[key]
    ? defaultUnitLocale[key]
    : key;

  return transWord;
};

export { getTranslatedUnitName, translations, UNIT_SYSTEM };
