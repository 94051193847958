import { Grid, Typography } from '@material-ui/core';
import { AccessTimeOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { DateTime, Settings } from 'luxon';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserTimeZone } from '../../../action';
import {
  AccountFragmentFragment,
  ContainerType,
  ForecastDayV2FragmentFragment,
  GetGrainTelemetryQuery,
  GrainBinFragmentFragment,
  RecommendationWindowsFragmentFragment,
  ViewerFragmentFragment,
  withGetAccountHoc,
  withGetGrainBinFanSettingsHoc,
  WithGetGrainBinFanSettingsHocChildProps,
  withGetGrainBinHoc,
  withGetGrainTelemetryHoc,
} from '../../../api';
import { ActiveStoragePeriodContext, GrainBinDataProvider } from '../../../contexts';
import { DialogSpinner } from '../../spinner';

import { ContainerTypeLegacy } from '../../../../../core/src/util';
import {
  DailyForecastTable,
  presentViewFromRanges,
  produceDryingPeriods,
  produceReconditioningPeriods,
  RunWindowKind,
} from '../daily-forecast';
import { RecommendedScheduledRange } from '../daily-forecast/RecommendationScheduleBar';
import { RecommendedOption } from '../RecommendationOptionValue';
import { SelectContainerPlaceholder } from '../SelectContainerPlaceholder';
import AerationScheduleDetails from './AerationScheduleDetails';

type WeatherForecastProps = {
  grain_bin_id?: number | null | undefined;
  account_id: number;
  mobile_width?: boolean;
  pathname: string;
  viewer: ViewerFragmentFragment;
  routeParams: { user_id: string; account_id: string; grain_bin_id: string };
};

const useStyles = makeStyles({
  full_width: { width: '100%' },
  min_height: { minHeight: 419 },
  forecast: {
    overflowX: 'auto',
    display: 'flex',
  },
  daily_forecast_table_container: {
    width: '970px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  icon: {
    marginBottom: -5,
    width: 24,
    height: 24,
  },
  fan_status: {
    fontSize: 25,
  },
  inp_grain_conditions: {
    marginBottom: 15,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 14,
  },
  grain_conditions_table: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    '& caption': {
      padding: 5,
      fontWeight: 600,
      color: 'indigo',
      fontSize: 18,
    },
    '& tr': {
      '& td': {
        border: '1px solid black',
        padding: 4,
      },
      '& th': {
        border: '1px solid black',
        padding: 4,
      },
    },
  },
});

export const WeatherForecast = ({
  grain_bin_id,
  mobile_width,
  viewer,
  routeParams,
  account_id,
}: WeatherForecastProps) => {
  return (
    <>
      {grain_bin_id && account_id ? (
        <WeatherForecastInner
          viewer={viewer}
          grain_bin_id={grain_bin_id}
          account_id={account_id}
          mobile_width={mobile_width}
          routeParams={routeParams}
        />
      ) : (
        <SelectContainerPlaceholder />
      )}
    </>
  );
};

type WeatherForecastInnerProps = Pick<GetGrainTelemetryQuery, 'grain_container'> &
  WithGetGrainBinFanSettingsHocChildProps & {
    grain_bin: GrainBinFragmentFragment;
    account: AccountFragmentFragment;
    loading?: boolean;
    viewer: ViewerFragmentFragment;
    mobile_width?: boolean;
    routeParams: { user_id: string; account_id: string; grain_bin_id: string };
  };

const WeatherForecastInner = withGetAccountHoc(
  withGetGrainBinHoc(
    withGetGrainTelemetryHoc(
      withGetGrainBinFanSettingsHoc(
        ({
          account: targetAccount,
          grain_bin,
          grain_container,
          loading = false,
          viewer,
          routeParams,
          grain_bin_fan_settings,
        }: WeatherForecastInnerProps) => {
          const dispatch = useDispatch();
          const classes = useStyles();
          const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const activeStoragePeriodContext = useContext(ActiveStoragePeriodContext);
          const activeStoragePeriod = activeStoragePeriodContext.activeStoragePeriod;
          const { account_id, user_id } = routeParams;

          useEffect(() => {
            dispatch(
              // Set user browser's local timezone
              setUserTimeZone({
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                offset: -new Date().getTimezoneOffset(),
              })
            );
          }, []);

          // Setting grain bin storage timezone
          if (grain_bin) {
            Settings.defaultZone = grain_bin.tz;
            Settings.defaultLocale = 'en-US';
          }

          //
          // If the bin doesn't match the storage cycle then set the active one to be undefined.
          // This appears to reinitialize the storage period context, and base it off the current bin.
          //
          if (
            grain_bin &&
            activeStoragePeriod &&
            activeStoragePeriod.grain_bin_id !== grain_bin.grain_bin_id
          ) {
            activeStoragePeriodContext.setActiveStoragePeriod(undefined);
          }

          if (loading) {
            return <DialogSpinner title="Loading Weather Forecast..." open={loading} />;
          }

          if (!viewer || !grain_bin || !grain_container || !targetAccount) {
            return null;
          }

          const {
            telemetry: { weather_forecast = null },
          } = grain_container;

          const { current_grain_emc, current_grain_temp, target_grain_emc } = grain_bin;

          const recommendation_windows: RecommendationWindowsFragmentFragment | null =
            grain_container.telemetry.recommendation_windows || null;

          const { organization } = targetAccount;

          const shouldShowFanRunCard =
            activeStoragePeriod === null ||
            activeStoragePeriod === undefined ||
            activeStoragePeriod.is_ongoing;

          const hourlyForecast = weather_forecast ? weather_forecast.hourly_forecast : null;
          const dailyForecast = weather_forecast
            ? (weather_forecast.daily_forecast as ForecastDayV2FragmentFragment[])
            : null;

          if (grain_bin && grain_bin.account_id !== Number(account_id)) {
            return <h1>Sorry, provided account id does not match with Grain Bin's account id. </h1>;
          }

          if (viewer && viewer.user_id !== Number(user_id)) {
            return <h1>Sorry, provided user id does not match with your user id.</h1>;
          }

          const dryingPeriodsToPresent: RecommendedScheduledRange[] = produceDryingPeriods(
            recommendation_windows
          );
          const reconditioningPeriodsToPresent: RecommendedScheduledRange[] = produceReconditioningPeriods(
            recommendation_windows
          );

          const scheduledRunsToPresent = presentViewFromRanges(
            (grain_bin.container.aeration_schedule || []).map((aeration) => {
              const start = aeration.start_epoch.getTime();
              const end = aeration.end_epoch.getTime();
              return {
                // @ts-ignore
                start: start as number,
                end: end as number,
                type: RunWindowKind.Scheduled,
              };
            })
          );

          let coolingPeriodsToPresent: RecommendedScheduledRange[] = [];
          if (recommendation_windows == null || recommendation_windows.temp_ranges == null) {
            console.error(
              'Failed to read psychometric temp data, got null or undefined',
              recommendation_windows
            );
          } else {
            coolingPeriodsToPresent = presentViewFromRanges(
              recommendation_windows.temp_ranges.map((value) => {
                return {
                  // @ts-ignore
                  start: value.start as number,
                  // @ts-ignore
                  end: value.end as number,
                  type: RunWindowKind.Cooling,
                };
              })
            );
          }

          const recommendationSchedule: RecommendedScheduledRange[] = grain_bin.recommendation_type
            ? {
                [RecommendedOption.DRYING]: dryingPeriodsToPresent,
                [RecommendedOption.COOLING]: coolingPeriodsToPresent,
                [RecommendedOption.RECONDITIONING]: reconditioningPeriodsToPresent,
              }[grain_bin.recommendation_type] || []
            : [];

          const has_override = Boolean(
            grain_bin_fan_settings &&
              grain_bin_fan_settings.grain_bin.fan_controllers.some(
                (ctrl) =>
                  ctrl.fan_controller &&
                  ctrl.fan_controller.state_next &&
                  ctrl.fan_controller.state_next.is_override
              )
          );

          const grain_bin_fan_controllers = grain_bin_fan_settings
            ? grain_bin_fan_settings.grain_bin.fan_controllers
            : [];

          const any_fans_running = grain_bin_fan_controllers.some(
            (ctrl) => ctrl.fan_controller.state_next && ctrl.fan_controller.state_next.is_on
          );
          const hasManualMode = any_fans_running && has_override;

          return (
            <GrainBinDataProvider grainBin={grain_bin}>
              <Grid
                item
                xs={12}
                spacing={2}
                container
                direction="row"
                alignItems="flex-start"
                alignContent="flex-start"
                style={{ width: 990, backgroundColor: '#fff' }}
              >
                <Grid style={{ margin: '30px 0px 15px 0px', width: '100%' }}>
                  <Typography variant="h5">
                    Org: {organization} | Grain Bin: {grain_bin.bin_name} |{' '}
                    <AccessTimeOutlined className={classes.icon} />{' '}
                    {DateTime.local().offsetNameShort}
                  </Typography>
                </Grid>

                <Grid className={classes.inp_grain_conditions}>
                  <table className={classes.grain_conditions_table}>
                    <caption>Inputted Conditions</caption>
                    <tr>
                      <th>Current Grain Moisture</th>
                      <th>Current Grain Temp</th>
                      <th>Target Grain Moisture</th>
                    </tr>
                    <tr>
                      <td>{current_grain_emc}%</td>
                      <td>{current_grain_temp}F</td>
                      <td>{target_grain_emc}%</td>
                    </tr>
                  </table>
                </Grid>

                <Grid className={classes.forecast} container direction={'column'} spacing={3}>
                  <Grid item className={classes.daily_forecast_table_container}>
                    {weather_forecast && recommendation_windows && shouldShowFanRunCard && (
                      <Grid item xs={12}>
                        {recommendation_windows &&
                        shouldShowFanRunCard &&
                        dailyForecast &&
                        dailyForecast.length > 0 &&
                        hourlyForecast ? (
                          <DailyForecastTable
                            dailyForecast={dailyForecast}
                            hourlyForecast={hourlyForecast}
                            recommendation_windows_data={recommendation_windows}
                            aeration_schedule={grain_bin.container.aeration_schedule}
                            grain_bin_location_timezone={
                              grain_bin.tz ? grain_bin.tz : browserTimezone
                            }
                            recommendation_type={
                              grain_bin.recommendation_type ? grain_bin.recommendation_type : null
                            }
                            has_enable_fan_guidance={grain_bin.enable_fan_guidance || false}
                            fan_guidance_start_date={grain_bin.fan_guidance_start_date || null}
                            fan_guidance_end_date={grain_bin.fan_guidance_end_date || null}
                            container_type={ContainerTypeLegacy.bin}
                            goPrevWeek={() => {}}
                            goNextWeek={() => {}}
                            prevWeekNo={0}
                            goToPresentView={() => {}}
                          />
                        ) : (
                          <></>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {grain_bin_fan_settings && (
                  <AerationScheduleDetails
                    scheduledRunsToPresent={scheduledRunsToPresent}
                    recommendationSchedule={recommendationSchedule}
                    grain_bin_fan_settings={grain_bin_fan_settings}
                    hasManualMode={hasManualMode}
                  />
                )}
              </Grid>
            </GrainBinDataProvider>
          );
        }
      )
    )
  )
);

export default WeatherForecast;
