import * as React from 'react';
export const HideShowIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26.656}
    height={24.02}
    viewBox="0 0 26.656 24.02"
    {...props}
  >
    <g id="VisibilityIcon" transform="translate(0.071 0.081)">
      <path
        id="Path_384"
        data-name="Path 384"
        d="M18.542,23.9a.874.874,0,0,1-.639-.232.843.843,0,0,1,0-1.22L40.094.261a.843.843,0,0,1,1.22,0,.843.843,0,0,1,0,1.22L19.124,23.672a.673.673,0,0,1-.581.232Z"
        transform="translate(-15.812)"
        fill="#333"
        stroke="#333"
        strokeWidth={0.07}
      />
      <path
        id="Path_385"
        data-name="Path 385"
        d="M80.668,70.192a.874.874,0,0,1-.639-.232A5.808,5.808,0,0,1,78.4,65.893,5.7,5.7,0,0,1,84.095,60.2,5.488,5.488,0,0,1,88.1,61.884a.874.874,0,0,1,.232.639,1.221,1.221,0,0,1-.232.639l-6.8,6.8A.874.874,0,0,1,80.668,70.192Zm3.427-8.249a3.992,3.992,0,0,0-3.95,3.95,4.171,4.171,0,0,0,.581,2.091l5.461-5.461a4.357,4.357,0,0,0-2.092-.581Z"
        transform="translate(-70.269 -53.955)"
        fill="#333"
        stroke="#333"
        strokeWidth={0.07}
      />
      <path
        id="Path_386"
        data-name="Path 386"
        d="M76.169,64.259h-.174a.871.871,0,0,1,0-1.742h.116a5.015,5.015,0,0,0,.93-.058.847.847,0,1,1,.174,1.685A6.512,6.512,0,0,1,76.169,64.259Zm-4.705-1.046a.738.738,0,0,1-.348-.058c-.349-.174-.755-.348-1.1-.523a.858.858,0,1,1,.813-1.51,7.576,7.576,0,0,0,.988.465.876.876,0,0,1,.465,1.162.863.863,0,0,1-.813.464Zm10.108-.348a.851.851,0,0,1-.348-1.627c.348-.174.639-.348.987-.523a.887.887,0,0,1,.93,1.511,9.407,9.407,0,0,1-1.045.581C81.862,62.806,81.688,62.864,81.571,62.864Zm4.763-3.369A.934.934,0,0,1,85.7,59.2a.843.843,0,0,1,.058-1.22c.291-.291.581-.523.813-.755a.863.863,0,1,1,1.22,1.22c-.291.29-.581.523-.871.813A.831.831,0,0,1,86.335,59.495Zm-13.187,0a.874.874,0,0,1-.639-.232.843.843,0,0,1,0-1.22l.813-.813a.863.863,0,1,1,1.22,1.22l-.813.813A.674.674,0,0,1,73.148,59.495Zm4.124-4.124a.874.874,0,0,1-.639-.232.843.843,0,0,1,0-1.22l.813-.813a.863.863,0,1,1,1.22,1.22l-.813.813A.747.747,0,0,1,77.272,55.371ZM88.019,55.2a.933.933,0,0,1-.639-.291c-.232-.232-.465-.523-.755-.813a.863.863,0,0,1,1.22-1.22,11.184,11.184,0,0,1,.813.871.843.843,0,0,1-.058,1.22A.746.746,0,0,1,88.019,55.2Zm-4.357-3.834a.659.659,0,0,1-.465-.174l-.871-.523-.348.349a.863.863,0,0,1-1.22-1.22l.813-.813a.843.843,0,0,1,1.22,0l.058.058c.116,0,.174.058.291.116.348.232.7.407,1.045.639a.86.86,0,0,1,.232,1.22A.936.936,0,0,1,83.662,51.362Z"
        transform="translate(-62.343 -43.665)"
        fill="#333"
        stroke="#333"
        strokeWidth={0.07}
      />
      <path
        id="Path_387"
        data-name="Path 387"
        d="M7.785,47.711a1.074,1.074,0,0,1-.407-.116,24.7,24.7,0,0,1-7.2-6.274.872.872,0,0,1,0-1.045C.407,39.927,6.332,32.2,13.826,32.2A13.91,13.91,0,0,1,20.274,34a.9.9,0,0,1,.465.639.916.916,0,0,1-.232.755L8.366,47.479a.674.674,0,0,1-.581.233ZM1.976,40.8a25.039,25.039,0,0,0,5.693,4.938L18.415,34.989a11.3,11.3,0,0,0-4.589-1.1C8.25,33.944,3.37,39.114,1.976,40.8Z"
        transform="translate(0 -28.861)"
        fill="#333"
        stroke="#333"
        strokeWidth={0.07}
      />
    </g>
  </svg>
);
