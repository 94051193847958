import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetGrainBinDocument,
  GetGrainBinQuery,
  GetGrainBinQueryVariables,
  StopGrainContainerAerationMutation,
  StopGrainContainerAerationMutationVariables as Variables,
  withStopGrainContainerAeration as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';
export type WithStopGrainContainerAerationHocChildProps = {
  stopGrainContainerAeration: (
    variables: Variables
  ) => Promise<StopGrainContainerAerationMutation['stopGrainContainerAeration']>;
};
export const withStopGrainContainerAerationHoc: AmberHoc<
  {},
  WithStopGrainContainerAerationHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithStopGrainContainerAerationHocChildProps, typeof component>,
    WithStopGrainContainerAerationHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        stopGrainContainerAeration: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: async (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { stopGrainContainerAeration: updated } = data;
              if (!updated) {
                return;
              }
              const cached = await proxy.readQuery<GetGrainBinQuery, GetGrainBinQueryVariables>({
                query: GetGrainBinDocument,
                variables: { grain_bin_id: variables.container_id },
              });
              if (!cached) {
                return updated;
              }
              proxy.writeQuery<GetGrainBinQuery, GetGrainBinQueryVariables>({
                query: GetGrainBinDocument,
                data: {
                  __typename: 'Query',
                  grain_bin: { ...cached.grain_bin, container: updated },
                },
                variables: { grain_bin_id: variables.container_id },
              });
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data || !data.stopGrainContainerAeration) {
            throw new Error('Unexpected server response');
          }
          return data.stopGrainContainerAeration;
        },
      };
    },
  })(component as any);
