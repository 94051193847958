import { InputBase, Paper, TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Search as SearchIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';
import React, {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import SearchBox from 'react-google-maps/lib/components/places/SearchBox';
import { GetProps } from 'react-redux';
import * as yup from 'yup';

// tslint:disable-next-line
import { TestContext } from 'yup';

import { isTouchDevice } from '../../../util';

const is_touch = isTouchDevice();

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    margin: '7px',
    marginTop: '20px',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  marker: {
    backgroundColor: 'white',
    borderRadius: 10,
    border: '1px solid black',
    paddingLeft: 5,
    paddingRight: 5,
    maxWidth: 100,
  },
});

export type LocationValue = { latlng: google.maps.LatLngLiteral | null; latlng_str: string };

export const yup_latitude = yup
  .number()
  .typeError('A number is required')
  .min(-90)
  .max(90);
export const yup_longitude = yup
  .number()
  .typeError('A number is required')
  .min(-180)
  .max(180);

export const yup_latlngstr = yup
  .string()
  .required()
  .test('latlngstr-len', 'Invalid format, expected [latitude],[longitude]', (value) => {
    if (!value) {
      return true;
    }
    return value.split(',').length === 2;
  })
  .test('latlngstr-lat', 'Invalid latitude', (value) => {
    if (!value) {
      return true;
    }
    const lat = parseFloat(value.split(',')[0]);
    return !isNaN(lat) && lat >= -90 && lat <= 90;
  })
  .test('latlngstr-lng', 'Invalid longitude', (value) => {
    if (!value) {
      return true;
    }
    const lng = parseFloat(value.split(',')[1]);
    return !isNaN(lng) && lng >= -180 && lng <= 180;
  });

const yup_coordinates = yup
  .object()
  .shape({
    lat: yup_latitude.required(),
    lng: yup_longitude.required(),
  })
  .required();

export const yup_latlng = yup
  .object()
  .shape({
    latlng: yup
      .object()
      .shape({
        lat: yup_latitude.required(),
        lng: yup_longitude.required(),
      })
      .required(),
    latlng_str: yup_latlngstr.label('Location').required(),
  })
  .test('latlng-sync', 'Lat/Lng does not match string', (context: TestContext, value?: any) => {
    if (!value || value.length !== 1) {
      return true;
    }
    return value.latlng_str === formatLatLng(value.latlng);
  });

export const formatLatLng = (value: LocationValue['latlng']) => {
  try {
    return value ? `${value.lat.toFixed(6)},${value.lng.toFixed(6)}` : '';
  } catch (e) {
    console.log([value]);
    return '';
  }
};

export const parseLatLngStr = (value: string): LocationValue['latlng'] => {
  try {
    const [lat, lng] = value.split(',').map(parseFloat);
    return yup_coordinates.validateSync({
      lat,
      lng,
    });
  } catch {
    return null;
  }
};

const BaseLocationPicker = ({ name }: { name: string }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<any>();
  const value: LocationValue = values[name];
  const ref_search = useRef<SearchBox>(null);
  const ref_map = useRef<GoogleMap>(null);

  const [stateValue, setStateValue] = useState(value.latlng);
  const [center, setCenter] = useState(
    stateValue ? stateValue : { lat: 37.09024, lng: -95.712891 }
  );
  const [zoom, setZoom] = useState(value ? 4 : 18);

  const onPlacesChanged = useCallback(() => {
    const { current } = ref_search;
    if (!current) {
      return;
    }
    const places = current.getPlaces();
    const geometry = places[0].geometry;
    if (geometry) {
      const latlng = geometry.location.toJSON();
      setZoom(18);
      setCenter(latlng);
      setStateValue(latlng);
      setFieldValue(name, {
        latlng,
        latlng_str: formatLatLng(latlng),
      });
    }
  }, [name]);

  const onMarkerMove = useCallback(
    (e: google.maps.MouseEvent) => {
      const latlng = e.latLng.toJSON();
      setStateValue(latlng);
      setFieldValue(name, {
        latlng,
        latlng_str: formatLatLng(latlng),
      });
    },
    [name]
  );

  const onZoomChanged = useCallback(() => {
    const { current } = ref_map;
    current && setZoom(current.getZoom());
  }, []);

  useEffect(() => {
    const { latlng } = value;
    if (latlng === stateValue) {
      return;
    }
    setStateValue(latlng);
    if (latlng) {
      setCenter(latlng);
      setZoom(18);
    }
  }, [value.latlng]);

  return (
    <GoogleMap
      options={{
        clickableIcons: false,
        controlSize: 30,
        fullscreenControl: false,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        mapTypeControl: true,
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        panControl: false,
        rotateControl: false,
        streetViewControl: false,
        zoomControl: !is_touch,
      }}
      ref={ref_map}
      zoom={zoom}
      center={center}
      clickableIcons={false}
      onClick={onMarkerMove}
      onZoomChanged={onZoomChanged}
    >
      <SearchBox
        ref={ref_search}
        controlPosition={google.maps.ControlPosition.LEFT_TOP}
        onPlacesChanged={onPlacesChanged}
      >
        <Paper className={classes.root} style={{ backgroundColor: 'white' }} elevation={5}>
          <InputBase
            className={classes.input}
            style={{ color: 'black' }}
            placeholder="Input Grain Bin Address"
          />
          <SearchIcon />
        </Paper>
      </SearchBox>
      {stateValue && <Marker position={stateValue} draggable onDragEnd={onMarkerMove} />}
    </GoogleMap>
  );
};

const LocationPickerWrapper = withScriptjs(withGoogleMap(BaseLocationPicker));

export const LocationPicker: FunctionComponent<
  GetProps<typeof BaseLocationPicker> & {
    className?: string;
    style?: CSSProperties;
  }
> = ({ name, style, className }) => (
  <LocationPickerWrapper
    name={name}
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${
      window['app_config'].google_api_key
    }&v=3.exp&libraries=geometry,drawing,places`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={
      <div style={{ height: '300px', width: '100%', ...style }} className={className} />
    }
    mapElement={<div style={{ height: `100%` }} />}
  />
);

export const LocationField: FunctionComponent<
  Omit<TextFieldProps, 'onBlur' | 'onChange' | 'value' | 'name' | 'error' | 'id'> & {
    name: string;
  }
> = ({ name, helperText, ...props }) => {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<any>();
  const handleChange = useCallback(
    ({ target: { value: latlng_str } }: React.ChangeEvent<any>) => {
      setFieldValue(name, {
        latlng_str,
        latlng: parseLatLngStr(latlng_str),
      });
      if (!touched[name]) {
        setFieldTouched('location', true);
      }
    },
    [setFieldValue]
  );
  const value: LocationValue = values[name];

  const error = errors[name] as { latlng?: { lat?: string; lng?: string }; latlng_str?: string };
  const has_error = Boolean(touched[name] && error && error.latlng_str);

  return (
    <TextField
      id={`LocationField-${name}`}
      label="Location"
      name={name}
      error={has_error}
      helperText={has_error ? error.latlng_str : helperText}
      {...props}
      value={value.latlng_str}
      onBlur={handleChange}
      onChange={handleChange}
      variant="standard"
    />
  );
};
