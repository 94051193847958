import React, { FunctionComponent, useCallback, useContext, useState } from 'react';
import { ActiveStoragePeriodContext } from '../../../contexts';
import { ContainerTypeLegacy, RelativePeriods } from '../../../util';
import { LevelHistoryCard } from './charts/LevelHistoryCard';

const LevelSensingCard: FunctionComponent<{
  container_id: number;
  container_type: ContainerTypeLegacy;
  title: string;
  minHeight?: number;
  maxHeight?: number;
  grain_bin_location_timezone: string;
  trackPeriodChange?: (args: any) => any;
  url_base: string;
  show_grain_ticket_level_deviation_warning: boolean;
  hideManageGrainTicketsBtn?: boolean;
}> = ({
  container_id,
  container_type,
  title,
  minHeight,
  maxHeight,
  grain_bin_location_timezone,
  trackPeriodChange,
  url_base,
  show_grain_ticket_level_deviation_warning,
  hideManageGrainTicketsBtn = false,
}) => {
  const ctx = useContext(ActiveStoragePeriodContext);
  const activeStoragePeriod = ctx.activeStoragePeriod;

  const [state, setState] = useState<{
    period: RelativePeriods;
  }>({
    period: RelativePeriods.week,
  });
  const { period } = state;

  const onSelectPeriod = useCallback(
    (period) => {
      if (trackPeriodChange) {
        trackPeriodChange({ period });
      }
      setState({ ...state, period });
    },
    [setState, trackPeriodChange]
  );

  return (
    <LevelHistoryCard
      title={title}
      container_id={container_id}
      container_type={container_type}
      as_of={new Date()}
      period={period}
      height={150}
      onSelectPeriod={onSelectPeriod}
      grain_bin_location_timezone={grain_bin_location_timezone}
      url_base={url_base}
      minHeight={minHeight}
      maxHeight={maxHeight}
      grain_bin_id={container_id}
      grain_bin_storage_cycle_id={
        activeStoragePeriod ? activeStoragePeriod.grain_bin_storage_cycle_id : undefined
      }
      show_grain_ticket_level_deviation_warning={show_grain_ticket_level_deviation_warning}
      hideManageGrainTicketsBtn={hideManageGrainTicketsBtn}
    />
  );
};

export default React.memo(LevelSensingCard);
