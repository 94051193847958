import { Grid } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { useField } from 'formik';
import React from 'react';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  message: { color: red['500'] },
});

export const ErrorBox = ({ withoutGrid = false }: { withoutGrid?: boolean }) => {
  const classes = useStyles();
  const [, { error }] = useField('general');
  if (!error) {
    return null;
  }

  return withoutGrid ? (
    <div className={classes.root}>
      <span style={{ color: red['500'] }}>{error}</span>
    </div>
  ) : (
    <Grid item xs={12}>
      <div className={classes.root}>
        <span style={{ color: red['500'] }}>{error}</span>
      </div>
    </Grid>
  );
};
