import { Snackbar, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import * as yup from 'yup';
import { QRScanDialog } from '..';
import { getDiagTestHubApi } from '../..../../../../../core/src/api/rest';
import { parseDeviceCoreId } from '../..../../../../../core/src/util/parsing-utils';

import {
  HubContainerLinkBaseFragmentFragment,
  HubType,
  withAssignHubHoc,
  WithAssignHubHocChildProps,
  withGetGrainContainerHubLinksHoc,
  WithGetGrainContainerHubLinksHocChildProps,
} from '../../api';
import { Alert } from '../account/AccountUsersLayout';
import {
  DEVICE_NOT_FOUND_MSG,
  QR_CODE_SCAN_FAILED_MSG,
} from '../device-wizard/AddFanControllerStep';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../util/form2/BaseForm';
import { ButtonSubmit } from '../util/form2/Button';
import { ErrorBox } from '../util/form2/ErrorBox';
import { SelectHubType, yup_hub_type } from '../util/form2/SelectHubType';
import { TextField } from '../util/form2/TextField';
import { AssignHubFormFields } from './aeration/AssignHubFormFields';

const useStyles = makeStyles((theme: Theme) => ({
  header: { marginBottom: 0 },
  button_row: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 15,
  },
  largeGrid: {
    textAlign: 'initial',
    padding: 20,
    minWidth: 300,
    width: 300,
    [theme.breakpoints.up('sm')]: {
      width: 570,
    },
  },
}));

type Values = {
  core_id: string;
};

const validationSchema = yup.object().shape({
  core_id: yup
    .string()
    .required()
    .label('Hub ID'),
  hub_nickname: yup
    .string()
    .label('Hub Nickname')
    .required(),
  hub_type: yup_hub_type.label('Hub Type').required(),
  has_level_sensor: yup.boolean(),
  hub_offset_ft: yup
    .number()
    .typeError('A number is required')
    .label('Hub Offset')
    .positive()
    .min(0),
});

export type UpdateValues = {
  hub_nickname: string;
  hub_type: HubType;
  has_level_sensor: boolean;
  hub_offset_ft: number;
};

export const updateValidationSchema = yup.object().shape({
  hub_nickname: yup
    .string()
    .label('Hub Nickname')
    .required(),
  hub_type: yup_hub_type.label('Hub Type').required(),
  has_level_sensor: yup.boolean(),
  hub_offset_ft: yup
    .number()
    .typeError('A number is required')
    .label('Hub Offset')
    .positive()
    .min(0),
});

const initialValues = {
  core_id: '',
  hub_nickname: '',
  hub_type: '',
  has_level_sensor: false,
  hub_offset_ft: 0,
};
export const HubAssignmentFormBase = ({
  loading = false,
  grain_container,
  container_id,
  container_type,
  assignHub,
  ...props
}: { container_id: number; container_type: number } & WithGetGrainContainerHubLinksHocChildProps &
  WithAssignHubHocChildProps &
  FormikWrapperHandlerProps<Values, HubContainerLinkBaseFragmentFragment>) => {
  const classes = useStyles();
  const [showQRScannerDialog, setshowQRScannerDialog] = useState(false);
  const [hasQRScanningFailed, setHasQRScanningFailed] = useState(false);
  const [isDeviceNotFound, setIsDeviceNotFound] = useState(false);

  const openQRScannerDialog = () => {
    setshowQRScannerDialog(true);
  };

  const closeQRScannerDialog = () => {
    setshowQRScannerDialog(false);
  };

  const handleScan = async (data, setFieldValue) => {
    if (data) {
      try {
        const coreId = parseDeviceCoreId(data);
        const response = await getDiagTestHubApi(coreId);
        console.log('response', response.data.data);
        if (Object.keys(response.data.data).length === 0) {
          setIsDeviceNotFound(true);
          return;
        }
        setFieldValue('core_id', response.data.data.alias);
        closeQRScannerDialog();
      } catch (error) {
        setHasQRScanningFailed(true);
        console.error(error);
      }
    }
  };

  const handleError = (err) => {
    setHasQRScanningFailed(true);
  };

  return (
    <FormikWrapper<Values, HubContainerLinkBaseFragmentFragment>
      {...props}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        const {
          core_id,
          hub_nickname: nickname,
          hub_type,
          has_level_sensor,
          hub_offset_ft,
        } = validationSchema.validateSync(values);
        const result = await assignHub({
          core_id,
          nickname,
          hub_type,
          container_id,
          container_type,
          has_level_sensor,
          hub_offset_ft,
        });
        resetForm();
        return result;
      }}
      render={({ setFieldValue }) => (
        <BaseForm submitting_message="Adding Hub..." className={classes.largeGrid}>
          <ErrorBox />
          <div>
            <AssignHubFormFields openQRScannerDialog={openQRScannerDialog} />
            <div className={classes.button_row}>
              <ButtonSubmit allow_btn_disabled_for_validation={false}>ADD</ButtonSubmit>
            </div>
          </div>
          <QRScanDialog
            title="Scan device QR code"
            showDialog={showQRScannerDialog}
            closeDialog={closeQRScannerDialog}
            onQRCodeScan={(e) => handleScan(e, setFieldValue)}
            onQRCodeError={handleError}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={hasQRScanningFailed}
            onClose={() => setHasQRScanningFailed(false)}
            autoHideDuration={6000}
          >
            <Alert
              style={{ fontSize: 16 }}
              severity="error"
              onClose={() => setHasQRScanningFailed(false)}
            >
              {QR_CODE_SCAN_FAILED_MSG}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isDeviceNotFound}
            onClose={() => setIsDeviceNotFound(false)}
            autoHideDuration={6000}
          >
            <Alert
              style={{ fontSize: 16 }}
              severity="error"
              onClose={() => setIsDeviceNotFound(false)}
            >
              {DEVICE_NOT_FOUND_MSG}
            </Alert>
          </Snackbar>
        </BaseForm>
      )}
    />
  );
};

export const HubAssignmentForm = withGetGrainContainerHubLinksHoc(
  withAssignHubHoc(HubAssignmentFormBase)
);
