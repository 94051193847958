import { ApolloClient } from 'apollo-client';
import { delay } from 'redux-saga';
import { cancel, fork, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import {pollBackgroundFanGuidanceExtension} from "../action/fan-guidance-extension";
import {GetPromptFanGuidanceExtensionDocument, GetPromptFanGuidanceExtensionQuery, GetPromptFanGuidanceExtensionQueryVariables} from "../api";

let bg_fan_guid_extension_poll_task: any;

function* pollForFanGuidanceExtensionLoop(
    apollo_client: ApolloClient<any>,
    grain_bin_id: number,
) {
    try {
        while (true) {
            // poll every 1 min
            yield delay(1000 * 20);

            // query for GrainBinContainer too in order to update the recommedation windows
            const prompt_fan_guidance_ext = yield apollo_client.query<GetPromptFanGuidanceExtensionQuery>({
                query: GetPromptFanGuidanceExtensionDocument,
                variables: { grain_bin_id },
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
            });

            // update cache
            yield apollo_client.writeQuery<GetPromptFanGuidanceExtensionQuery, GetPromptFanGuidanceExtensionQueryVariables>(
                {
                    query: GetPromptFanGuidanceExtensionDocument,
                    data: prompt_fan_guidance_ext.data,
                    variables: { grain_bin_id },
                }
            );
        }
    } finally {
    }
}

function* pollBackgroundFanGuidanceExtensionSaga(
    apollo_client: ApolloClient<any>,
    action: ReturnType<typeof pollBackgroundFanGuidanceExtension>
) {
    if (!action.payload) return;

    try {
        const {
            payload: { grain_bin_id },
        } = action;

        // cancel existing poll if there is one
        if (bg_fan_guid_extension_poll_task) {
            yield cancel(bg_fan_guid_extension_poll_task);
            bg_fan_guid_extension_poll_task = null;
        }

        if (grain_bin_id) {
            bg_fan_guid_extension_poll_task = yield fork(
                pollForFanGuidanceExtensionLoop,
                apollo_client,
                grain_bin_id
            );
        }
    } catch (e) {
        console.error(e);
    }
}


export function* backgroundFanGuidanceExtensionPollWatcher(apollo_client: ApolloClient<any>){
    yield takeEvery(
        getType(pollBackgroundFanGuidanceExtension),
        pollBackgroundFanGuidanceExtensionSaga,
        apollo_client
    );
}