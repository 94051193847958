import { ApolloError } from 'apollo-client';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import {
  AccountFragmentFragment,
  ViewerFragmentFragment,
  withGetAccountUsersHoc,
  WithGetAccountUsersHocChildProps,
} from '../../../../core/src/api';
import { DialogSpinner } from '../spinner';

import { History } from 'history';
import { AuthenticatedRoute } from '../util';
import ConfirmationModal from '../util/ConfirmationModal';
import { UserEmailVerificationForm } from './UserEmailVerificationForm';
import { UpdateUserForm } from './UserForm';
import { UserGrainBinNotifications } from './UserGrainBinNotifications';
import { UserPasswordForm } from './UserPasswordForm';
import { UserSettingsForm } from './UserSettingsForm';

export enum UserRoutesSuffix {
  account = 'account',
  settings = 'settings',
  password = 'password',
  email_verification = 'email_verification',
  orgsettings = 'orgsettings',
  notifications = 'notifications',
}
export type UserRoutesProps = RouteComponentProps & {
  mobile_width?: boolean;
  goPasswordForm?: () => any;
  goUserSettings?: () => any;
  onError: (error: ApolloError) => void;
  onUpdateUserFormSuccess?: () => void;
  onUserPasswordFormSuccess?: () => void;
  onUserSettingsFormSuccess?: () => void;
  onUserOrgSettingsFormSuccess?: () => void;
};

const selectAccountId = ({ selected: { account_id } }) => account_id;
const sessionAccountId = ({ session: { account_id } }) => account_id;

export const UserRoutes = ({
  history,
  location,
  match,
  staticContext,
  mobile_width,
  goPasswordForm,
  goUserSettings,
  onError,
  onUpdateUserFormSuccess,
  onUserPasswordFormSuccess,
  onUserSettingsFormSuccess,
}: UserRoutesProps) => {
  const account_id = useSelector(selectAccountId) || useSelector(sessionAccountId);
  return (
    <AuthenticatedRoute
      account_id={account_id}
      render={({ viewer, account }) => {
        const { user } = viewer;
        const hasCurrentAccountHolder = account.account_id === viewer.account_id;

        return (
          <Switch>
            <Route
              path={`${match.url}/${UserRoutesSuffix.account}`}
              render={() => (
                <UpdateUserForm
                  user={user}
                  onSubmitSuccess={onUpdateUserFormSuccess}
                  onClickPassword={goPasswordForm}
                />
              )}
            />
            <Route
              path={`${match.url}/${UserRoutesSuffix.password}`}
              render={() => (
                <UserPasswordForm
                  token={null}
                  user_id={user.user_id}
                  onSubmitSuccess={onUserPasswordFormSuccess}
                />
              )}
            />
            <Route
              path={`${match.url}/${UserRoutesSuffix.notifications}`}
              render={() =>
                hasCurrentAccountHolder ? (
                  <UserGrainBinNotifications
                    account_id={account_id}
                    user_id={user.user_id}
                    history={history}
                    account={account}
                    goUserSettings={goUserSettings}
                    hasCurrentAccountHolder={hasCurrentAccountHolder}
                    period={'2W'}
                  />
                ) : (
                  <UserGrainBinNotificationsWithAccountUsers
                    account_id={account_id}
                    account={account}
                    goUserSettings={goUserSettings}
                    viewer={viewer}
                    history={history}
                    hasCurrentAccountHolder={hasCurrentAccountHolder}
                  />
                )
              }
            />
            <Route
              path={`${match.url}/${UserRoutesSuffix.settings}`}
              render={() => (
                <UserSettingsForm
                  account_id={user.account_id}
                  selected_user={user}
                  user_id={user.user_id}
                  onUpdateUserFormSuccess={onUpdateUserFormSuccess}
                  onUserPasswordFormSuccess={onUserPasswordFormSuccess}
                  onSubmitSuccess={onUserSettingsFormSuccess}
                  mobile_width={mobile_width}
                />
              )}
            />
            {!user.verified_email && (
              <Route
                path={`${match.url}/${UserRoutesSuffix.email_verification}`}
                render={() => <UserEmailVerificationForm user={user} />}
              />
            )}
            <Route
              render={() => (
                <Redirect
                  to={`${match.url}/${
                    user.verified_email
                      ? UserRoutesSuffix.account
                      : UserRoutesSuffix.email_verification
                  }`}
                />
              )}
            />
          </Switch>
        );
      }}
    />
  );
};

const UserGrainBinNotificationsWithAccountUsersBase = ({
  hasCurrentAccountHolder,
  account_id: currentAccountId,
  loading,
  users,
  viewer,
  account,
  goUserSettings,
  history,
}: {
  account_id: number;
  viewer: ViewerFragmentFragment;
  account: AccountFragmentFragment;
  goUserSettings?: () => any;
  history: History<any>;
  hasCurrentAccountHolder: boolean;
} & WithGetAccountUsersHocChildProps) => {
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const user = viewer.account_id === currentAccountId ? viewer.user : users[0];
  console.log('user', user);

  useEffect(() => {
    if (!loading && !user) {
      setShowAddUserDialog(true);
    }
  }, [user, loading]);

  if (loading) return <DialogSpinner title="Loading..." open={loading} />;

  return (
    <>
      {user && (
        <UserGrainBinNotifications
          account_id={currentAccountId}
          user_id={user.user_id}
          account={account}
          goUserSettings={goUserSettings}
          history={history}
          hasCurrentAccountHolder={hasCurrentAccountHolder}
          period={'2W'}
        />
      )}
      <ConfirmationModal
        showModal={showAddUserDialog}
        confirmationMessage={<>Please add a user to view notifications for this account</>}
        cancelBtnText="Go to Dashboard"
        confirmBtbText="Add New User"
        handleCancel={() => {
          setShowAddUserDialog(false);
          const path =
            account && account.grain_bin_support
              ? '/grain_bin/dashboard'
              : account && account.barge_support
              ? '/barge/dashboard'
              : '/dashboard';
          history.replace(path);
        }}
        handleConfirm={() => {
          setShowAddUserDialog(false);
          history.replace(`/account/users`);
        }}
      />
    </>
  );
};

const UserGrainBinNotificationsWithAccountUsers = withGetAccountUsersHoc(
  UserGrainBinNotificationsWithAccountUsersBase
);
