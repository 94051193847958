import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

interface ISkeletonLoaderProps {
  width?: number;
  height?: number;
}

const SkeletonLoader = ({ width, height }: ISkeletonLoaderProps) => {
  return (
    <Skeleton animation="wave" variant="rect" width={width || '100%'} height={height || '50%'} />
  );
};

export default SkeletonLoader;
