import { createAction } from 'typesafe-actions';
import { WorkerMessage } from '../util';

export const workerMessage = createAction('WORKER/MESSAGE', (resolve) => {
  return (payload: { message: WorkerMessage }) => resolve(payload);
});

export const workerBroadcast = createAction('WORKER/BROADCAST', (resolve) => {
  return (payload: { message: WorkerMessage }) => resolve(payload);
});
