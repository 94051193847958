import {
  DistanceUnitType,
  MassUnitType,
  TempUnitType,
  TestWeightUnitType,
  UserUnitsPrefFragmentFragment,
} from '../api';
import { getTranslatedUnitName as ut, UNIT_SYSTEM } from '../translations';

export const USER_UNITS_PREF_KEY = 'user_units_pref';

export const getUserUnitsPref = (): UserUnitsPrefFragmentFragment | null => {
  const userUnitsPrefVal = localStorage.getItem(USER_UNITS_PREF_KEY);
  const userUnitsPref: UserUnitsPrefFragmentFragment | null = userUnitsPrefVal
    ? JSON.parse(userUnitsPrefVal)
    : null;
  return userUnitsPref;
};

// distance metric
export const getDistanceUnitSystem = (): string => {
  const localeUnitSystem = getUserUnitsPref();
  return localeUnitSystem && localeUnitSystem.distance_unit === DistanceUnitType.Meter
    ? UNIT_SYSTEM.metric
    : UNIT_SYSTEM.imperial;
};
export const getHasUserSelectedMeterUnit = (): boolean => {
  const localeUnitSystem = getUserUnitsPref();
  return localeUnitSystem ? localeUnitSystem.distance_unit === DistanceUnitType.Meter : false;
};
export const getDistanceUnitLabel = ({ cgs } = { cgs: false }): string => {
  const distanceUnitSystem = getDistanceUnitSystem();
  return ut(cgs ? 'distance_cgs' : 'distance', distanceUnitSystem);
};

// temperature metric
export const getTemperatureUnitSystem = (): string => {
  const localeUnitSystem = getUserUnitsPref();
  return localeUnitSystem && localeUnitSystem.temp_unit === TempUnitType.Celsius
    ? UNIT_SYSTEM.metric
    : UNIT_SYSTEM.imperial;
};
export const getHasUserSelectedCelsiusUnit = (): boolean => {
  const localeUnitSystem = getUserUnitsPref();
  return localeUnitSystem ? localeUnitSystem.temp_unit === TempUnitType.Celsius : false;
};
export const getTemperatureUnitLabel = (): string => {
  const temperatureUnitSystem = getTemperatureUnitSystem();
  const tempUnit = ut('temperature', temperatureUnitSystem);
  return tempUnit;
};
export const getTemperatureUnitLabelWithDegreeSuffix = (): string => {
  const temperatureUnitSystem = getTemperatureUnitSystem();
  const tempUnit = ut('temperatureWithDegreeSuffix', temperatureUnitSystem);
  return tempUnit;
};

// mass metric
export const getHasUserSelectedKilogramUnit = (): boolean => {
  const localeUnitSystem = getUserUnitsPref();
  return localeUnitSystem ? localeUnitSystem.mass_unit === MassUnitType.Kilogram : false;
};
export const getUserSelectedMassUnit = (): MassUnitType => {
  const userUnitsPrefVal = getUserUnitsPref();
  return userUnitsPrefVal ? userUnitsPrefVal.mass_unit : MassUnitType.Pound;
};
export const getMassUnitLabel = (): string => {
  const userUnitsPrefVal = getUserUnitsPref();
  if (userUnitsPrefVal) {
    switch (userUnitsPrefVal.mass_unit) {
      case MassUnitType.Kilogram:
        return 'kg';
      case MassUnitType.Pound:
        return 'lbs';
    }
  }
  return 'lbs';
};

// test weight metric
export const getHasUserSelectedKgPerHectoliterUnit = (): boolean => {
  const localeUnitSystem = getUserUnitsPref();
  return localeUnitSystem
    ? localeUnitSystem.test_weight_unit === TestWeightUnitType.KilogramPerHectoliter
    : false;
};
export const getUserSelectedTestWeightUnit = (): TestWeightUnitType => {
  const userUnitsPrefVal = getUserUnitsPref();
  return userUnitsPrefVal ? userUnitsPrefVal.test_weight_unit : TestWeightUnitType.PoundPerUsBushel;
};
export const getTestWeightLabel = (): string => {
  const userUnitsPrefVal = getUserUnitsPref();
  if (userUnitsPrefVal) {
    switch (userUnitsPrefVal.test_weight_unit) {
      case TestWeightUnitType.PoundPerUsBushel:
        return 'lbs/bu';
      case TestWeightUnitType.KilogramPerHectoliter:
        return 'kg/hL';
    }
  }
  return 'lbs/bu';
};
