import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy, RelativeTimePeriod } from '../../../util';
import { HistoryPointFragment, withGetGrainContainerHistoryPellet as HOC } from '../__generated';

export type WithGetGrainContainerHistoryPelletHocOwnProps = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  pellet_id: string;
  period: RelativeTimePeriod;
  pollInterval?: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainContainerHistoryPelletHocChildProps = {
  loading: boolean;
  pellet_history: HistoryPointFragment[] | null;
};
export const withGetGrainContainerHistoryPelletHoc: AmberHoc<
  WithGetGrainContainerHistoryPelletHocOwnProps,
  WithGetGrainContainerHistoryPelletHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainContainerHistoryPelletHocOwnProps,
      WithGetGrainContainerHistoryPelletHocChildProps,
      typeof component
    >,
    WithGetGrainContainerHistoryPelletHocChildProps
  >({
    options: ({
      container_id,
      container_type,
      pellet_id,
      period,
      onError,
      pollInterval = 1000 * 60 * 30,
    }) => ({
      onError,
      pollInterval,
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id, container_type, period, pellet_id },
    }),
    props: ({ data, ownProps: { loading } }) => {
      let pellet_history: HistoryPointFragment[] | null = null;

      if (!data || data.loading) {
        return {
          pellet_history,
          loading: true,
        };
      }

      const { grain_container } = data;
      if (grain_container) {
        const { telemetry_history } = grain_container;
        if (telemetry_history) {
          ({ pellet: pellet_history } = telemetry_history);
        }
      }

      return {
        pellet_history,
        loading: loading || false,
      };
    },
  })(component as any);
