import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, compose, createStore, DeepPartial, Store } from 'redux';
import createSagaMiddleWare from 'redux-saga';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import { createRootReducer, State } from './reducer';
import saga from './saga';

export const configureStore = (history: History, initialState?: DeepPartial<State>) => {
  let store: Store<State>;

  if ((module as any).hot) {
    (module as any).hot.accept('./reducer', () => {
      // eslint-disable-next-line
      const next_reducer = require('./reducer').default;
      store.replaceReducer(next_reducer);
    });
  }

  const sagaMiddleware = createSagaMiddleWare();
  if (window['app_config'].env === 'prod') {
    store = createStore<State, any, any, any>(
      createRootReducer(history),
      initialState,
      applyMiddleware(sagaMiddleware, routerMiddleware(history))
    );
    sagaMiddleware.run(saga);
    sagaMiddleware.run(routinePromiseWatcherSaga);
  } else {
    // add support for Redux dev tools
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const reduxImmutableStateInvariant = require('redux-immutable-state-invariant').default;
    store = createStore<State, any, any, any>(
      createRootReducer(history),
      initialState,
      composeEnhancers(
        applyMiddleware(sagaMiddleware, routerMiddleware(history), reduxImmutableStateInvariant())
      )
    );
    sagaMiddleware.run(saga);
    sagaMiddleware.run(routinePromiseWatcherSaga);
  }
  return store;
};
