import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  makeStyles,
} from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import React from 'react';
import { amber_dark_grey, light_gray2 } from '../../../../../core/src/style';

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: light_gray2,
    color: amber_dark_grey,
    border: `1.5px solid #fff`,
    borderLeft: 0,
    borderRight: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:first-child': {
      borderTop: 0,
    },
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
    paddingLeft: 8,
    minHeight: '30px !important',

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'none !important',
      transition: 'none !important',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: 20,
      marginTop: 0,
      marginBottom: 0,
    },
    '& .MuiIconButton-root': { width: 15, height: 15 },

    '& .MuiAccordionSummary-expandIcon': {
      transform: 'none !important',
    },

    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
  },
  accordianDetails: {
    paddingLeft: 40,
  },
  customExpandIcon: {
    '.Mui-expanded & > .collapsIconWrapper': {
      display: 'none',
    },
    '& .expandIconWrapper': {
      display: 'none',
    },
    '.Mui-expanded & > .expandIconWrapper': {
      display: 'block',
    },
  },
}));

const CustomExpandIcon = () => {
  const classes = useStyles();

  return (
    <Box className={classes.customExpandIcon}>
      <div className="expandIconWrapper">
        <RemoveIcon style={{ width: 15, height: 15 }} />
      </div>
      <div className="collapsIconWrapper">
        <AddIcon style={{ width: 15, height: 15 }} />
      </div>
    </Box>
  );
};
const Accordion = (props) => {
  const classes = useStyles();

  return <MuiAccordion elevation={0} square className={classes.accordion} {...props} />;
};

const AccordionSummary = (props) => {
  const classes = useStyles();

  return (
    <MuiAccordionSummary
      expandIcon={<CustomExpandIcon />}
      className={classes.accordionSummary}
      {...props}
    />
  );
};

const AccordionDetails = (props) => {
  const classes = useStyles();

  return <MuiAccordionDetails className={classes.accordianDetails} {...props} />;
};

export type ExpandInfoAccordionProps = {
  items: { id: string; summary: React.ReactElement; details: React.ReactElement }[];
};
export const ExpandInfoAccordion = ({ items }: ExpandInfoAccordionProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div>
      {items.map((item) => {
        const { id, summary, details } = item;

        return (
          <Accordion key={id} expanded={expanded === id} onChange={handleChange(id)}>
            <AccordionSummary aria-controls={`${id}-content`} id={`${id}-header`}>
              {summary}
            </AccordionSummary>
            <AccordionDetails>{details}</AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
