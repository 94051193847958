import { MenuItem } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import * as yup from 'yup';

import { HubType } from '../../../api/graphql';

import { Select, SelectProps } from './Select';

export const yup_hub_type = yup
  .string()
  .nullable()
  .oneOf([null, HubType.Headspace, HubType.Plenum]) as yup.StringSchema<HubType>;

export const SelectHubType: FunctionComponent<SelectProps> = (props) => {
  return (
    <Select {...props}>
      <MenuItem value={HubType.Headspace}>Headspace</MenuItem>
      <MenuItem value={HubType.Plenum}>Plenum</MenuItem>
    </Select>
  );
};
