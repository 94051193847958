import { IconButton } from '@material-ui/core';
import React from 'react';
import { DarkCheckBoxIcon, DarkCheckBoxUncheckIcon } from '../../../../../../core/src/media';

export const SelectModelCellRenderer = (props) => {
  const { data, node } = props;
  const handleSelectCurrentRow = () => {
    if (!props.node.selected) {
      node.setSelected(true, true);
      props.api.refreshCells({ force: true });
      props.onRowSelection(data);
    }
  };

  return (
    <div>
      <IconButton onClick={handleSelectCurrentRow}>
        {props.node.selected ? <DarkCheckBoxIcon /> : <DarkCheckBoxUncheckIcon />}
      </IconButton>
    </div>
  );
};
