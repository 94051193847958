import {
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Tooltip,
} from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { EditNoteSvgIcon } from '../../../../core/src/media/icons/NounProjectIcon';
import {
  GetGrainBinStoragePeriodQuery,
  UserRole,
  withGetGrainBinStoragePeriodHoc,
} from '../../api';
import { ActiveStoragePeriodContext } from '../../contexts';

const useStyles = makeStyles((theme: Theme) => ({
  storage_period_select: {
    maxWidth: 175,
    minWidth: 140,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
}));

type DropDownType = {
  option: string;
  displayName: string;
};

type StoragePeriodSelectProps = {
  grain_bin_storage_periods: GetGrainBinStoragePeriodQuery;
  loading?: boolean;
  viewer_role: UserRole;
};

const StoragePeriodSelect = ({
  loading,
  grain_bin_storage_periods: { grain_bin_storage_periods },
  history,
  viewer_role,
}: StoragePeriodSelectProps & RouteComponentProps) => {
  const classes = useStyles();
  const ctx = useContext(ActiveStoragePeriodContext);
  const ongoing_period = grain_bin_storage_periods.find(
    (storagePeriod) => storagePeriod.is_ongoing_storage_period === true
  );

  // required condition to get data on dashboard even if Grain bin has future storage period
  const has_ongoing_period =
    ongoing_period !== undefined && ongoing_period.start_epoch.getTime() <= new Date().getTime();

  const handleActiveStoragePeriodSet = () => {
    if (grain_bin_storage_periods.length === 0 || !has_ongoing_period) {
      ctx.setActiveStoragePeriod(null);
    } else if (ongoing_period) {
      console.log('Setting storage period to on-going...', ongoing_period);
      ctx.setActiveStoragePeriod({
        grain_bin_id: ongoing_period.grain_bin_id,
        is_ongoing: true,
        grain_bin_storage_cycle_id: ongoing_period.grain_bin_storage_cycle_id,
        storage_cycle_name: ongoing_period.storage_cycle_name,
        order: 0,
        start_date: ongoing_period.start_epoch,
        end_date: ongoing_period.end_epoch,
      });
    }
  };

  useEffect(() => {
    if (!loading) {
      handleActiveStoragePeriodSet();
    }
  }, [loading]);

  if (loading) {
    return null;
  }

  const handleSelectChange = (event) => {
    if (!event.target) {
      console.error('No target found.');
      return;
    }

    const idx = event.target.value;

    if (idx === '-1') {
      ctx.setActiveStoragePeriod(null);
      return;
    }

    const selected = grain_bin_storage_periods[idx];
    console.log('Selecting: ', selected);
    ctx.setActiveStoragePeriod({
      grain_bin_id: selected.grain_bin_id,
      grain_bin_storage_cycle_id: selected.grain_bin_storage_cycle_id,
      storage_cycle_name: selected.storage_cycle_name,
      order: Number(idx),
      is_ongoing: selected.is_ongoing_storage_period,
      start_date: selected.start_epoch,
      end_date: selected.end_epoch,
    });
  };

  let defaultDropdownIndex = ctx.activeStoragePeriod ? ctx.activeStoragePeriod.order : 0;
  defaultDropdownIndex =
    !has_ongoing_period && !ctx.activeStoragePeriod ? -1 : defaultDropdownIndex;

  const first_item: DropDownType = { option: '-1', displayName: '(no ongoing period)' };

  const dropdownItems: DropDownType[] = grain_bin_storage_periods.map((storagePeriod, idx) => {
    return {
      option: idx.toString(),
      displayName: storagePeriod.storage_cycle_name,
    };
  });

  const goToManageStoragePeriods = () => {
    history.push('/grain_bin/storage_periods');
  };

  if (!has_ongoing_period) {
    dropdownItems.unshift(first_item);
  }

  console.log('Rendering Store Period Dropdown ', { defaultDropdownIndex, dropdownItems });

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <FormControl>
        <InputLabel shrink id="storage-period-label">
          Storage Period
        </InputLabel>
        <Select
          className={classes.storage_period_select}
          name="storage_period"
          label={`Storage Period`}
          onChange={handleSelectChange}
          value={defaultDropdownIndex.toString()}
        >
          {dropdownItems.map((dropdownItem) => {
            return (
              <MenuItem key={dropdownItem.option} value={dropdownItem.option}>
                {dropdownItem.displayName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Tooltip title={<div style={{ fontSize: 12 }}>Manage Storage Periods</div>} arrow>
        <IconButton
          style={{
            marginRight: 15,
            marginTop: 12,
            paddingBottom: 0,
            paddingTop: 0,
            alignItems: 'center',
            display: 'flex',
          }}
          onClick={goToManageStoragePeriods}
        >
          <EditNoteSvgIcon color="primary" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default withGetGrainBinStoragePeriodHoc(withRouter(StoragePeriodSelect));
