import { WorkerMessage, WorkerMessageType } from '@amber-ui/core/lib';
import { ViewerFragmentFragment } from '@amber-ui/core/lib/api/graphql/__generated';
import runtime from 'serviceworker-webpack-plugin/lib/runtime';

const urlB64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

let newWorker;
let registration: ServiceWorkerRegistration | null = null;

if (navigator && navigator.serviceWorker) {
  runtime.register().then((r) => {
    registration = r;
    if (registration) {
      registration.onupdatefound = (ev: Event): any => {
        newWorker = (registration as ServiceWorkerRegistration).installing;
        (newWorker as ServiceWorker).onstatechange = () => {
          switch (newWorker.state) {
            case 'installed':
              if (navigator.serviceWorker.controller) {
                // new update available
                newWorker.postMessage({ message_type: 'skip_waiting' });
              }
              // No update available
              break;
          }
        };
      };
    }
  });
}
export const sendMessage = async (message: WorkerMessage) => {
  if (!registration) {
    return;
  }
  const worker = registration.active;
  if (worker) {
    worker.postMessage(message);
    return;
  }
};

export const setServiceWorkerViewer = async (
  viewer: ViewerFragmentFragment,
  propagate: boolean
) => {
  if (!registration) {
    return;
  }
  if (Notification && 'PushManager' in window) {
    Notification.requestPermission().then(
      async (permission) => {
        if (!registration) {
          return;
        }
        if (permission === 'granted') {
          let subscription = await registration.pushManager.getSubscription();
          if (!subscription) {
            subscription = await registration.pushManager.subscribe({
              applicationServerKey: urlB64ToUint8Array(
                window['app_config'].web_push_public_key || ''
              ),
              userVisibleOnly: true,
            });
          }
          await fetch(`${window['app_config'].api_host_name}/updateWebPush`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/zip,*/*',
              Authorization: `Bearer ${viewer.token}`,
            },
            body: JSON.stringify({ user_id: viewer.user_id, subscription: subscription.toJSON() }),
          });
        }
      },
      (permission) => {}
    );
  }

  await sendMessage({
    message_type: WorkerMessageType.auth,
    payload: { viewer, propagate },
  });
};
