import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy, RelativeTimePeriod } from '../../../util';
import {
  HeadspaceHubTelemetryHistory,
  withGetGrainContainerHistoryHeadspace as HOC,
} from '../__generated';

export type WithGetGrainContainerHistoryHeadspaceHocOwnProps = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  period: RelativeTimePeriod;
  pollInterval?: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainContainerHistoryHeadspaceHocChildProps = {
  loading: boolean;
  active_hubs_grain_telemetry_history: HeadspaceHubTelemetryHistory[] | null;
};
export const withGetGrainContainerHistoryHeadspaceHoc: AmberHoc<
  WithGetGrainContainerHistoryHeadspaceHocOwnProps,
  WithGetGrainContainerHistoryHeadspaceHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainContainerHistoryHeadspaceHocOwnProps,
      WithGetGrainContainerHistoryHeadspaceHocChildProps,
      typeof component
    >,
    WithGetGrainContainerHistoryHeadspaceHocChildProps
  >({
    options: ({
      container_id,
      container_type,
      period,
      onError,
      pollInterval = 1000 * 60 * 30,
    }) => ({
      onError,
      pollInterval,
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id, container_type, period },
      context: { important: true }, // flag to prevent from graphql batching
    }),
    props: ({ data, ownProps: { loading } }) => {
      let active_hubs_grain_telemetry_history: any = null;
      if (!data || data.loading) {
        return {
          active_hubs_grain_telemetry_history,
          loading: true,
        };
      }

      const { grain_container } = data;
      if (grain_container) {
        const { telemetry_history_v2 } = grain_container;
        if (telemetry_history_v2) {
          ({ active_hubs_grain_telemetry_history } =
            telemetry_history_v2.headspace_history || null);
        }
      }

      return {
        active_hubs_grain_telemetry_history,
        loading: loading || false,
      };
    },
  })(component as any);
