import { ReactElement, useRef } from 'react';

const shallowEqual = <Props extends object>(left: Props, right: Props) => {
  if (left === right) {
    return true;
  }

  const leftKeys = Object.keys(left);
  const rightKeys = Object.keys(right);

  if (leftKeys.length !== rightKeys.length) {
    return false;
  }

  return leftKeys.every((key) => (left as any)[key] === (right as any)[key]);
};

export const useMemoRender = <Props extends object>(
  props: Props,
  render: (props: Props) => ReactElement
): ReactElement => {
  const propsRef = useRef<Props>();
  const elementRef = useRef<ReactElement>();

  if (!propsRef.current || !shallowEqual(propsRef.current, props)) {
    elementRef.current = render(props);
  }

  propsRef.current = props;

  return elementRef.current as ReactElement;
};
