import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy } from '../../../util';
import { GetGrainBinContainerQuery, withGetGrainBinContainer as HOC } from '../__generated';

export type WithGetGrainBinContainerHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  pollInterval?: number;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainBinContainerHocChildProps = {
  loading: boolean;
  grain_container: GetGrainBinContainerQuery['grain_container'] | null;
  refetch_grain_bin_container: () => Promise<any>;
};
export const withGetGrainBinContainerHoc: AmberHoc<
  WithGetGrainBinContainerHocOwnProps,
  WithGetGrainBinContainerHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainBinContainerHocOwnProps,
      WithGetGrainBinContainerHocChildProps,
      typeof component
    >,
    WithGetGrainBinContainerHocChildProps
  >({
    options: ({ grain_bin_id, onError, pollInterval = 1000 * 60 * 30 }) => ({
      pollInterval,
      onError,
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id: grain_bin_id, container_type: ContainerTypeLegacy.bin },
      context: { important: true }, // flag to prevent from graphql batching
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          refetch_grain_bin_container: () => Promise.resolve(),
          grain_container: null,
        };
      }
      return {
        loading: loading || false,
        refetch_grain_bin_container: data.refetch,
        grain_container: data.grain_container || null,
      };
    },
  })(component as any);
