import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy, RelativeTimePeriod } from '../../../util';
import { HistoryPointFragment, withGetGrainContainerHistoryWeather as HOC } from '../__generated';

export type WithGetGrainContainerHistoryWeatherHocOwnProps = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  period: RelativeTimePeriod;
  pollInterval?: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainContainerHistoryWeatherHocChildProps = {
  loading: boolean;
  weather_history: HistoryPointFragment[] | null;
};
export const withGetGrainContainerHistoryWeatherHoc: AmberHoc<
  WithGetGrainContainerHistoryWeatherHocOwnProps,
  WithGetGrainContainerHistoryWeatherHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainContainerHistoryWeatherHocOwnProps,
      WithGetGrainContainerHistoryWeatherHocChildProps,
      typeof component
    >,
    WithGetGrainContainerHistoryWeatherHocChildProps
  >({
    options: ({
      container_id,
      container_type,
      period,
      onError,
      pollInterval = 1000 * 60 * 30,
    }) => ({
      onError,
      pollInterval,
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id, container_type, period },
    }),
    props: ({ data, ownProps: { loading } }) => {
      let weather_history: HistoryPointFragment[] | null = null;

      if (!data || data.loading) {
        return {
          weather_history,
          loading: true,
        };
      }

      const { grain_container } = data;
      if (grain_container) {
        const { telemetry_history } = grain_container;
        if (telemetry_history) {
          ({ weather: weather_history } = telemetry_history);
        }
      }

      return {
        weather_history,
        loading: loading || false,
      };
    },
  })(component as any);
