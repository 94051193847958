import { AmberHoc, AmberHocProps } from '../../../util';
import {
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables as Variables,
  withUpdateUserPassword as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUpdateUserPasswordHocChildProps = {
  updateUserPassword: (
    variables: Variables
  ) => Promise<UpdateUserPasswordMutation['updateUserPassword']>;
};
export const withUpdateUserPasswordHoc: AmberHoc<{}, WithUpdateUserPasswordHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<{}, WithUpdateUserPasswordHocChildProps, typeof component>,
    WithUpdateUserPasswordHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updateUserPassword: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({ variables });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.updateUserPassword;
        },
      };
    },
  })(component as any);
