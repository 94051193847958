import { fade } from '@material-ui/core/styles';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  AutomationDisabledIcon,
  AutomationEnabledIcon,
  DashCircleIcon,
  ErrorIcon,
  RotatingWaveIcon,
  RunPauseIcon,
  ThreeDotsIcon,
} from '../../../../../core/src/media/icons';
import {
  FanHeartbeatErrorCode,
  FanRunWindowRecommendedOption,
  FanSettingsFragmentFragment,
} from '../../../api';
import { useGrainBinData } from '../../../contexts/grainBinContext';
import { amber_grey, white } from '../../../style';
import { isQualifyingFanHeartbeatErrorCode } from './FanControllerErrorDisplay';
import {
  getRunningWindowTimeRemain,
  getSimpleFanStatus,
  ROTATING_WAVE_COLORS,
  SimpleFanStatus,
  useStyles,
} from './FanStatusHelpers';
type FanStatusProps = {
  grain_bin_fan_settings: FanSettingsFragmentFragment | null;
  fan_updating: boolean;
  className?: string;
  fanStatusContainer?: string;
  scheduled_start: Date | null;
  scheduled_end: Date | null;
  hasManualMode: boolean;
  showRestartButton?: boolean;
};

export const FanStatus = ({
  grain_bin_fan_settings,
  fan_updating,
  className = '',
  fanStatusContainer = '',
  scheduled_start,
  scheduled_end,
  hasManualMode,
  showRestartButton = false,
}: FanStatusProps) => {
  const [isFanStartTriggered] = useSelector(({ global_state: { isFanStartTriggered } }) => [
    isFanStartTriggered,
  ]);
  const {
    enable_fan_guidance,
    recommendation_type,
    container: { aeration_schedule_type, aeration_schedule },
  } = useGrainBinData();
  const hasUpcomingAerationSchedule = aeration_schedule && aeration_schedule.length > 0;
  const recommendationType: string = aeration_schedule_type
    ? aeration_schedule_type === FanRunWindowRecommendedOption.Custom
      ? enable_fan_guidance && recommendation_type
        ? recommendation_type
        : hasManualMode
        ? FanRunWindowRecommendedOption.Manual
        : aeration_schedule_type
      : aeration_schedule_type
    : 'Unknown';

  const recommendationOptions = [
    FanRunWindowRecommendedOption.Cooling,
    FanRunWindowRecommendedOption.Drying,
    FanRunWindowRecommendedOption.Reconditioning,
  ];
  const showAutomationIcons =
    (hasUpcomingAerationSchedule &&
      recommendationType &&
      recommendationOptions.includes(recommendationType as FanRunWindowRecommendedOption)) ||
    enable_fan_guidance;
  const [fan_statuses, is_single_status, fans_with_errors] = useMemo(() => {
    const grain_bin_fan_controllers = grain_bin_fan_settings
      ? grain_bin_fan_settings.grain_bin.fan_controllers
      : [];

    // condense the statuses into simple fan status
    const fan_statuses = grain_bin_fan_controllers.map((ctrl, ix) => {
      return {
        id: ctrl.fan_controller_id_next,
        fan_name: ctrl.alias || `Fan ${ix}`,
        ...getSimpleFanStatus(ctrl),
      };
    });

    // is there uniform status
    const is_single_status = new Set(fan_statuses.map((fs) => fs.status)).size === 1;

    // find fans with errors if any
    const fans_with_errors = grain_bin_fan_controllers.filter(
      (ctrl) =>
        ctrl &&
        ctrl.fan_controller &&
        ctrl.fan_controller.state_next &&
        ctrl.fan_controller.state_next.config &&
        isQualifyingFanHeartbeatErrorCode(ctrl.fan_controller.state_next.error_code)
    );

    return [fan_statuses, is_single_status, fans_with_errors];
  }, [grain_bin_fan_settings]);

  const isAnyFanRunning = fan_statuses.some((fc) => fc.status === SimpleFanStatus.RUNNING);
  const isAnyFanUpdating =
    fan_updating || fan_statuses.some((fc) => fc.status === SimpleFanStatus.UPDATING);
  const areAllFansReady = fan_statuses.every((fc) => fc.status === SimpleFanStatus.READY);
  const areAllFansOffline = fan_statuses.every((fc) => fc.status === SimpleFanStatus.OFFLINE);
  const hasFansWithError = fans_with_errors && fans_with_errors.length > 0;
  const hasFanStopFailError =
    fans_with_errors &&
    fans_with_errors.some(
      ({ fan_controller: { state_next } }) =>
        state_next && state_next.error_code === FanHeartbeatErrorCode.StopFail
    );
  const show_remaining =
    Boolean(
      scheduled_start && scheduled_end && new Date(scheduled_start).getTime() < new Date().getTime()
    ) ||
    hasManualMode ||
    hasFanStopFailError;

  const endEpochDt = scheduled_end && DateTime.fromMillis(scheduled_end.getTime());
  const startEpochDt = scheduled_start && DateTime.fromMillis(scheduled_start.getTime());
  const hasManualModeWithNoScheduleEnd = hasManualMode && scheduled_end === null;
  const hasFanStopFailErrorWithNoScheduleEnd = hasFanStopFailError && scheduled_end === null;
  const hasInfiniteEndTime =
    (startEpochDt &&
      endEpochDt &&
      Math.round(endEpochDt.diff(startEpochDt, 'year').toObject().years)) ||
    hasManualModeWithNoScheduleEnd ||
    hasFanStopFailErrorWithNoScheduleEnd;
  // const hasManualModeWithSchedule = hasManualMode && scheduled_start;
  const runningWindowTimeRemain =
    isAnyFanRunning && show_remaining
      ? hasInfiniteEndTime
        ? '∞'
        : getRunningWindowTimeRemain(scheduled_end)
      : '';
  const hasAutomationEnabled = enable_fan_guidance ? enable_fan_guidance : false;
  const hasSomeReadyState = fan_statuses.some((fc) => fc.status === SimpleFanStatus.READY);

  const rotatingWaveBaseColor =
    hasSomeReadyState && !hasUpcomingAerationSchedule && !isAnyFanRunning
      ? white
      : ROTATING_WAVE_COLORS[recommendationType];
  const hideRotatingWave = !isAnyFanRunning && (isAnyFanUpdating || areAllFansOffline);
  const areAllFansWithSameState = fan_statuses.every((fc) => fc.status === fan_statuses[0].status);
  const showDissimilarStatesUI = !is_single_status && !areAllFansWithSameState;
  const now = new Date();
  const has_current_overlap_runwindow = Boolean(
    now && scheduled_start && scheduled_end && now > scheduled_start && now < scheduled_end
  );

  const hasFanScheduleInterrupted =
    has_current_overlap_runwindow &&
    showRestartButton &&
    !hasFansWithError &&
    !fan_updating &&
    hasSomeReadyState &&
    !enable_fan_guidance &&
    !isAnyFanRunning &&
    !isFanStartTriggered;
  console.log('hasFanScheduleInterrupted', hasFanScheduleInterrupted);

  const classes = useStyles({
    isAnyFanRunning,
    isAnyFanUpdating,
    recommendationType,
    areAllFansReady,
    hasFansWithError,
    hasUpcomingAerationSchedule,
    hasAutomationEnabled,
    areAllFansOffline,
    hasSomeReadyState,
  });

  console.log('fans_with_errors', fans_with_errors);
  const hasErroredFan = fans_with_errors && fans_with_errors.length > 0;

  const _fanStatusContent = (
    <>
      {is_single_status || fan_updating ? (
        <div
          className={classnames(classes.singleFanContainer, {
            [fanStatusContainer]: fanStatusContainer,
          })}
        >
          <p
            className={classnames(classes.status_text, {
              [className]: className,
            })}
          >
            STATUS
          </p>
          <div
            className={classes.fanStatus}
            style={{
              color: fan_updating ? amber_grey : fan_statuses[0].color,
              backgroundColor: fan_updating
                ? fade(amber_grey, 0.33)
                : fade(fan_statuses[0].color, 0.33),
            }}
          >
            {fan_updating ? 'UPDATING' : fan_statuses[0].status}
          </div>

          {isAnyFanRunning && runningWindowTimeRemain && (
            <div className={classes.timeRemain} style={{ marginTop: 10 }}>
              <div className={classes.timeRemainLabel}>Time Remain</div>
              <div className={classes.timeRemainValue}>
                {runningWindowTimeRemain === '∞' ? (
                  runningWindowTimeRemain
                ) : (
                  <>
                    {runningWindowTimeRemain.split(':')[0]}
                    <span style={{ fontFamily: 'Digital-7-Regular,sans-serif' }}>:</span>
                    {runningWindowTimeRemain.split(':')[1]}
                  </>
                )}
              </div>
            </div>
          )}

          {!showAutomationIcons && hasErroredFan && (
            <div style={{ marginTop: 10 }}>
              <ErrorIcon />
            </div>
          )}

          {showAutomationIcons && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
              }}
            >
              {enable_fan_guidance ? (
                <AutomationEnabledIcon style={{ width: 24, height: 24 }} />
              ) : (
                <AutomationDisabledIcon style={{ width: 20, height: 20 }} />
              )}
              {hasErroredFan && <ErrorIcon style={{ marginLeft: 15 }} />}
            </div>
          )}

          {hasFanScheduleInterrupted && (
            <div
              style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10 }}
            >
              <div className={classes.pauseBtnPulse}>
                <RunPauseIcon style={{ width: 20, height: 20 }} />
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );

  const _fanStatusContentForDissimilarStates = (
    <>
      <p className={classes.statusText}>STATUS</p>
      {fan_statuses.map((fan_status, idx) => {
        const hasErroredFan = fans_with_errors
          .map(({ alias }, ix) => alias || `Fan ${ix}`)
          .includes(fan_status.fan_name);

        return (
          <div
            className={classnames(classes.container, {
              [fanStatusContainer]: fanStatusContainer,
            })}
            style={{ marginBottom: idx === fan_statuses.length - 1 ? 0 : 10 }}
            key={`fs-${fan_status.id}`}
          >
            <div
              className={classnames(classes.statusLabel, {
                [className]: className,
              })}
              style={{ marginBottom: 4, marginTop: 0 }}
            >
              {fan_status.fan_name}
            </div>
            <div
              className={classes.fanStatus2}
              style={{
                color: fan_status.color,
                backgroundColor: fade(fan_status.color, 0.33),
              }}
            >
              {fan_status.status}
            </div>
            {hasErroredFan && (
              <div style={{ marginTop: 5 }}>
                <ErrorIcon />
              </div>
            )}
          </div>
        );
      })}
    </>
  );

  const _sameStatesUI = (
    <>
      <div className={classes.parent}>
        {!hideRotatingWave && (
          <RotatingWaveIcon
            basecolor={rotatingWaveBaseColor}
            className={`${classes.blueDashImg} ${isAnyFanRunning ? classes.spinAnimation : ''}`}
          />
        )}
        <DashCircleIcon className={classes.dashCircleImg} />
        <div
          className={classes.hudCircle1}
          style={{
            animation:
              !hasFansWithError && (isAnyFanRunning || isAnyFanUpdating) ? undefined : 'none',
          }}
        />
        {!hasFansWithError && (
          <>
            <div
              className={classes.hudCircle2}
              style={{ animation: areAllFansReady && !hasFansWithError ? 'none' : undefined }}
            />
            <div
              className={classes.hudCircle3}
              style={{ animation: areAllFansReady && !hasFansWithError ? 'none' : undefined }}
            />
          </>
        )}
        <div className={classes.mainTextContent}>{_fanStatusContent}</div>
      </div>
    </>
  );

  const _dissimilarStatesUI = (
    <>
      <div className={classes.parent2}>
        {/* Content */}
        <div className={classes.mainTextContent2}>{_fanStatusContentForDissimilarStates}</div>

        {/* Circular UI */}
        <div className={classes.circularUI}>
          {!hideRotatingWave && (
            <RotatingWaveIcon
              basecolor={rotatingWaveBaseColor}
              className={`${classes.blueDashImg} ${isAnyFanRunning ? classes.spinAnimation : ''}`}
            />
          )}
          <DashCircleIcon className={classes.dashCircleImg} />
          <div
            className={classes.hudCircle1}
            style={{
              animation:
                !hasFansWithError && (isAnyFanRunning || isAnyFanUpdating) ? undefined : 'none',
            }}
          />
          {!hasFansWithError && (
            <>
              <div
                className={classes.hudCircle2}
                style={{ animation: areAllFansReady && !hasFansWithError ? 'none' : undefined }}
              />
              <div
                className={classes.hudCircle3}
                style={{ animation: areAllFansReady && !hasFansWithError ? 'none' : undefined }}
              />
            </>
          )}

          <div className={classes.circularUIContent}>
            {isAnyFanRunning && runningWindowTimeRemain && (
              <div className={classes.timeRemain}>
                <div className={classes.timeRemainLabel2}>Time Remain</div>
                <div className={classes.timeRemainValue2}>
                  {runningWindowTimeRemain === '∞' ? (
                    runningWindowTimeRemain
                  ) : (
                    <>
                      {runningWindowTimeRemain.split(':')[0]}
                      <span style={{ fontFamily: 'Digital-7-Regular,sans-serif' }}>:</span>
                      {runningWindowTimeRemain.split(':')[1]}
                    </>
                  )}
                </div>
              </div>
            )}
            {showAutomationIcons && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 8,
                }}
              >
                {enable_fan_guidance ? (
                  <AutomationEnabledIcon style={{ width: 24, height: 24 }} />
                ) : (
                  <AutomationDisabledIcon style={{ width: 20, height: 20 }} />
                )}
              </div>
            )}
            {hasFanScheduleInterrupted && (
              <div
                style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10 }}
              >
                <div className={classes.pauseBtnPulse}>
                  <RunPauseIcon style={{ width: 20, height: 20 }} />
                </div>
              </div>
            )}

            {!isAnyFanRunning && !showAutomationIcons && !hasFanScheduleInterrupted && (
              <ThreeDotsIcon style={{ width: 100, height: 100 }} />
            )}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className={classes.fanStatusAnimationContainer}>
      {showDissimilarStatesUI ? <>{_dissimilarStatesUI}</> : <>{_sameStatesUI}</>}
    </div>
  );
};
