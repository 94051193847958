import { AmberHoc, AmberHocProps } from '../../../util';
import {
  BargeCoverPelletLinkFragmentFragment as Result,
  GetBargeCoverPelletLinksDocument,
  GetBargeCoverPelletLinksQuery,
  GetBargeCoverPelletLinksQueryVariables,
  LinkBargeCoverPelletMutationVariables as Variables,
  withLinkBargeCoverPellet as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

// TODO: Modify return results to include barge id, and remove it from the parent props;

export type WithLinkBargeCoverPelletHocChildProps = {
  linkBargeCoverPellet: (variables: Variables) => Promise<Result[]>;
};
export const withLinkBargeCoverPelletHoc: AmberHoc<{}, WithLinkBargeCoverPelletHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<{}, WithLinkBargeCoverPelletHocChildProps, typeof component>,
    WithLinkBargeCoverPelletHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        linkBargeCoverPellet: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected error');
          }
          const { barge_id } = variables;
          const result = await mutate({
            variables,
            update: (proxy, { data, errors: errors2 }) => {
              if (errors2 || !data) {
                return;
              }
              const { linkBargeCoverPellet: result } = data;
              proxy.writeQuery<
                GetBargeCoverPelletLinksQuery,
                GetBargeCoverPelletLinksQueryVariables
              >({
                query: GetBargeCoverPelletLinksDocument,
                data: { __typename: 'Query', barge_cover_pellet_links: result },
                variables: { barge_id },
              });
            },
          });
          if (!result) {
            throw new Error('Unexpected response from server');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data || !data.linkBargeCoverPellet) {
            throw new Error('Unexpected response from server');
          }
          return data.linkBargeCoverPellet;
        },
      };
    },
  })(component as any);
