import React from 'react';
import { GrainBinFragmentFragment } from '../api';

export type GrainBinDataType = {
  grainBin: GrainBinFragmentFragment;
};

export const GrainBinContext = React.createContext<GrainBinFragmentFragment | undefined>(undefined);

export const GrainBinDataProvider = ({
  grainBin,
  children,
}: React.PropsWithChildren<{ grainBin: GrainBinFragmentFragment }>) => {
  return <GrainBinContext.Provider value={grainBin}>{children}</GrainBinContext.Provider>;
};

export function useGrainBinData() {
  const context = React.useContext(GrainBinContext);
  if (context === undefined) {
    throw new Error('useGrainBinData must be used within a GrainBinDataProvider');
  }
  return context;
}
