import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React from 'react';
import { FanRunWindowRecommendedOption } from '../../../../../core/src/api';
import {
  black_shade_2,
  black_shade_5,
  light_gray_9,
  maroon_shade_1,
  red_shade_1,
} from '../../../../../core/src/style';
import { getRemainingDaysAutomationString } from '../../grain-container/daily-forecast/AerationRecommnedationPlotHelpers';
const useStyles = makeStyles((theme: Theme) => ({
  automationRemainDays: {
    width: 'fit-content',
    height: 15,
    backgroundColor: light_gray_9,
    borderRadius: 4,
    padding: '3px 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10,
    color: black_shade_5,
  },
}));
const AutoCellRenderer = (props) => {
  const classes = useStyles();
  console.log('inside AutoCellRenderer', props);
  const automation_info = props.value;
  console.log('automation_info', automation_info);
  const mode = props.node.data.mode;
  console.log('mode', mode);
  const hasFanSchedule = props.node.data.fan_schedule && props.node.data.fan_schedule.length > 0;

  if (automation_info && automation_info.enabled && automation_info.fan_guidance_end_date) {
    const as_of = DateTime.local();
    const fanGuidanceEndDate = DateTime.fromMillis(
      new Date(automation_info.fan_guidance_end_date).getTime()
    );
    const remainingAutomationPeriodString = getRemainingDaysAutomationString(
      as_of,
      fanGuidanceEndDate
    );

    const remainingAutomationPeriodInDays = fanGuidanceEndDate.diff(as_of, 'days').toObject().days;
    const showRedColorPeriod =
      remainingAutomationPeriodInDays !== null && remainingAutomationPeriodInDays <= 1;

    return (
      <div
        className={classes.automationRemainDays}
        style={{
          color: showRedColorPeriod ? maroon_shade_1 : undefined,
          backgroundColor: showRedColorPeriod ? red_shade_1 : undefined,
        }}
      >
        {remainingAutomationPeriodString}
      </div>
    );
  }

  return <div>{!hasFanSchedule ? 'No Schedule' : 'Disabled'}</div>;
};

export { AutoCellRenderer };
