import { Button, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { AutomationIcon } from '../../../../../../core/src/media';
import { CenteredSpinner } from '../../../../../src/component/spinner';
import { amber_green, light_gray2, white } from '../../../../../src/style';
import { ContainerTypeLegacy, renderModeColor } from '../../../../../src/util';
import { ContainerType, withGetGrainBinHoc, WithGetGrainBinHocChildProps } from '../../../../api';
import {
  createNavigationType,
  createNavigationTypeFromNavOption,
  createNavigationTypeFromOptionString,
  createShowGenerateRecommScheduleNavigation,
  FanControlNavigationOption,
  FanControlsNavigation,
} from '../../FanControlNavigationOption';
import { getRecommedationTypeMode } from '../FanSchedule';
import { GrainInputsWithMode } from '../GrainInputsWithMode';
import { ReadOnlyRecommSchedule } from './ReadOnlyRecommSchedule';
import { RecommScheduleAskGrainCondtionsForm } from './RecommScheduleAskGrainCondtionsForm';

const useStyles = makeStyles((theme: Theme) => ({
  spinner: { height: 254, display: 'flex', alignItems: 'center', justifyContent: 'center' },
  fanAutomationIconContainer: {
    marginRight: 6,
    width: 16,
    height: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  generateAutomation: {
    fontFamily: 'Work Sans,sans-serif',
    width: 306,
    height: 43,
    fontSize: 13,
    color: white,
    backgroundColor: amber_green,
    boxShadow: '0px 0px 4px #00000033',
    borderRadius: 6,
    textTransform: 'uppercase',
    [theme.breakpoints.down(375)]: {
      width: 298,
      fontSize: 12,
    },
  },
  grainWeatherInputsCardContainer: {
    borderRadius: 8,
    width: '100%',
    background: light_gray2,
    display: 'flex',
    marginBottom: 8,
    padding: 10,
  },
}));

type RecommendationScheduleProps = WithGetGrainBinHocChildProps & {
  grain_bin_id: number;
  storage_period?: {
    grain_bin_storage_cycle_id: number;
  } | null;
  container_type2: ContainerTypeLegacy;
};
export const RecommendationSchedule = withGetGrainBinHoc(
  ({
    grain_bin_id,
    grain_bin,
    loading,
    storage_period,
    container_type2,
  }: RecommendationScheduleProps) => {
    const classes = useStyles();
    const isLoading = loading;
    const defaultNav = createShowGenerateRecommScheduleNavigation();
    // @ts-ignore
    const [navigation, setNavigation] = useState(defaultNav);
    const showRecomendationsFlow = () =>
      setNavigation(
        createNavigationTypeFromNavOption(
          FanControlsNavigation.AskCurrentGrainConditions,
          navigation
        )
      );
    const hasUserSetRecomendationsType = grain_bin && grain_bin.recommendation_type !== null;
    //
    // Ensure that the navigation has the connected grain_bin_id, which indicates the grain_bin
    // has been fully created.
    //
    if (
      !navigation.grain_bin_id &&
      grain_bin &&
      grain_bin.recommendation_type &&
      grain_bin.grain_bin_id
    ) {
      setNavigation(
        createNavigationTypeFromOptionString(
          defaultNav.navigationType,
          grain_bin.recommendation_type,
          grain_bin.grain_bin_id
        )
      );
      return <CenteredSpinner className={classes.spinner} fadeIn="none" />;
    }
    if (isLoading) {
      return <CenteredSpinner className={classes.spinner} fadeIn="none" />;
    }

    const renderComponent = (navigation: FanControlNavigationOption) => {
      switch (navigation.navigationType) {
        case FanControlsNavigation.ShowGenerateRecommSchedule: {
          return (
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Grid item style={{ width: '100%' }}>
                {hasUserSetRecomendationsType ? (
                  <div className={classes.grainWeatherInputsCardContainer}>
                    <GrainInputsWithMode
                      current_grain_temp={
                        grain_bin && grain_bin.current_grain_temp !== undefined
                          ? grain_bin.current_grain_temp
                          : null
                      }
                      current_grain_emc={
                        grain_bin && grain_bin.current_grain_emc !== undefined
                          ? grain_bin.current_grain_emc
                          : null
                      }
                      target_grain_emc={grain_bin ? grain_bin.target_grain_emc || null : null}
                      mode={getRecommedationTypeMode(
                        grain_bin ? grain_bin.recommendation_type : null
                      )}
                      modeColor={renderModeColor(grain_bin ? grain_bin.recommendation_type : null)}
                      showRecomendationsFlow={showRecomendationsFlow}
                    />
                  </div>
                ) : (
                  <Button
                    size="medium"
                    variant="contained"
                    className={classes.generateAutomation}
                    style={{
                      color: white,
                      backgroundColor: amber_green,
                    }}
                    onClick={showRecomendationsFlow}
                  >
                    <div className={classes.fanAutomationIconContainer}>
                      <AutomationIcon />
                    </div>
                    Generate Recommended Schedule
                  </Button>
                )}
              </Grid>
              {grain_bin && grain_bin.recommendation_type && (
                <ReadOnlyRecommSchedule
                  grain_bin_id={grain_bin_id}
                  container_id={grain_bin_id}
                  container_type={ContainerType.Bin}
                  container_type2={container_type2}
                  storage_period={storage_period}
                  navigation={navigation}
                  setNavigation={(nav) =>
                    setNavigation(
                      createNavigationType(
                        FanControlsNavigation.ShowGenerateRecommSchedule,
                        nav.recommendationOptionValue,
                        grain_bin_id
                      )
                    )
                  }
                  isRecommendingWindows={true}
                  onCancel={() =>
                    setNavigation(
                      createNavigationType(
                        FanControlsNavigation.ShowGenerateRecommSchedule,
                        navigation.recommendationOptionValue,
                        grain_bin_id
                      )
                    )
                  }
                />
              )}
            </Grid>
          );
        }
        case FanControlsNavigation.AskCurrentGrainConditions: {
          return (
            <RecommScheduleAskGrainCondtionsForm
              grain_bin={grain_bin!}
              navigation={navigation}
              setRecommendation={(nav) =>
                setNavigation(
                  createNavigationType(
                    FanControlsNavigation.AskCurrentGrainConditions,
                    nav.recommendationOptionValue,
                    grain_bin_id
                  )
                )
              }
              setNavigation={(nav) =>
                setNavigation(
                  createNavigationType(
                    FanControlsNavigation.ShowGenerateRecommSchedule,
                    nav.recommendationOptionValue,
                    grain_bin_id
                  )
                )
              }
              onCancel={() =>
                setNavigation(
                  createNavigationType(
                    FanControlsNavigation.ShowGenerateRecommSchedule,
                    navigation.recommendationOptionValue,
                    grain_bin_id
                  )
                )
              }
            />
          );
        }
      }
    };
    return <>{renderComponent(navigation)}</>;
  }
);
