import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { ViewerFragmentFragment as Result, withGetViewer as HOC } from '../__generated';

export type WithGetViewerHocOwnProps = {
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetViewerHocChildProps = { loading: boolean; viewer: Result | null };
export const withGetViewerHoc: AmberHoc<WithGetViewerHocOwnProps, WithGetViewerHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<WithGetViewerHocOwnProps, WithGetViewerHocChildProps, typeof component>,
    WithGetViewerHocChildProps
  >({
    options: ({ onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          viewer: null,
        };
      }
      return {
        loading: loading || false,
        viewer: data.viewer || null,
      };
    },
  })(component as any);
