import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  OpsViewRowVisibilityPrefsFragmentFragment,
  withGetOpsViewRowsVisibilityPrefs as HOC,
} from '../__generated';

export type WithGetOpsViewRowsVisibilityPrefsHocOwnProps = {
  account_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetOpsViewRowsVisibilityPrefsHocChildProps = {
  rows_visibility_prefs_loading: boolean;
  operations_view_rows_visibility_prefs: OpsViewRowVisibilityPrefsFragmentFragment | null;
  refetch: () => Promise<any>;
};
export const withGetOpsViewRowsVisibilityPrefsHoc: AmberHoc<
  WithGetOpsViewRowsVisibilityPrefsHocOwnProps,
  WithGetOpsViewRowsVisibilityPrefsHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetOpsViewRowsVisibilityPrefsHocOwnProps,
      WithGetOpsViewRowsVisibilityPrefsHocChildProps,
      typeof component
    >,
    WithGetOpsViewRowsVisibilityPrefsHocChildProps
  >({
    options: ({ account_id, onError }) => ({
      onError,
      variables: { account_id },
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          rows_visibility_prefs_loading: true,
          operations_view_rows_visibility_prefs: null,
          refetch: () => Promise.resolve(),
        };
      }
      return {
        rows_visibility_prefs_loading: loading || false,
        operations_view_rows_visibility_prefs: data.operations_view_rows_visibility_prefs as OpsViewRowVisibilityPrefsFragmentFragment | null,
        refetch: () => data.refetch(),
      };
    },
  })(component as any);
