import { Grid, Theme } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { amber_dark_grey, amber_grey } from '../../../style';

const useStyles = makeStyles((theme: Theme) => ({
  heading_text: {
    fontSize: 42,
    fontWeight: 600,
    color: amber_dark_grey,
  },
  small_text: {
    fontSize: 24,
    fontWeight: 400,
    marginLeft: 5,
    color: amber_grey,
  },
  power_card_heading_text: {
    fontSize: 42,
    fontWeight: 600,
    color: amber_dark_grey,

    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
  },
  power_card_small_text: {
    fontSize: 24,
    fontWeight: 400,
    marginLeft: 5,
    color: amber_grey,

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  centered_text: { textAlign: 'center' },
  text_row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: { height: 28, width: 28 },
  bottom_margin: { marginBottom: 10 },
}));

export const StatLine: React.FunctionComponent<{
  heading: string;
  small?: string;
}> = ({ heading, small }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.text_row}>
        <span className={classes.heading_text}>{heading}</span>
        {small && <span className={classes.small_text}>{small}</span>}
      </div>
    </>
  );
};

export const PowerCardStatLine: React.FunctionComponent<{
  heading: string;
  small?: string;
}> = ({ heading, small }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.text_row}>
        <span className={classes.power_card_heading_text}>{heading}</span>
        {small && <span className={classes.power_card_small_text}>{small}</span>}
      </div>
    </>
  );
};

export const AerationRuntimeStats: React.FunctionComponent<{
  total_hrs_runtime: number;
  total_runs: number;
  current_temp?: number;
  current_emc?: number;
  temp_diff?: number;
  emc_diff?: number;
}> = ({ total_hrs_runtime, total_runs, current_temp, current_emc, temp_diff, emc_diff }) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        className={classes.bottom_margin}
        container
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid
          className={classes.centered_text}
          item
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <StatLine heading={`${total_hrs_runtime}`} small="hrs" />
          <div className={classes.small_text} style={{ textAlign: 'center', margin: '0 15px' }}>
            over
          </div>
          <StatLine heading={`${total_runs}`} small={`run${total_runs === 1 ? '' : 's'}`} />
        </Grid>
        {current_temp && temp_diff && (
          <Grid
            className={classes.centered_text}
            container
            item
            xs={12}
            md={5}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              {temp_diff > 0 && (
                <ArrowDropUp style={{ color: green['500'] }} className={classes.icon} />
              )}
            </Grid>
            <Grid item>
              {temp_diff < 0 && (
                <ArrowDropDown style={{ color: red['500'] }} className={classes.icon} />
              )}
            </Grid>
            <Grid item>
              <StatLine
                heading={`${current_temp}\u00b0`}
                small={`${temp_diff >= 0 ? '+' : ''}${temp_diff}\u00b0`}
              />
            </Grid>
          </Grid>
        )}
        {current_emc && emc_diff && (
          <Grid
            className={classes.centered_text}
            container
            item
            xs={12}
            md={7}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              {emc_diff > 0 && (
                <ArrowDropUp style={{ color: green['500'] }} className={classes.icon} />
              )}
            </Grid>
            <Grid item>
              {emc_diff < 0 && (
                <ArrowDropDown style={{ color: red['500'] }} className={classes.icon} />
              )}
            </Grid>
            <Grid item>
              <StatLine
                heading={`${current_emc}%`}
                small={`${emc_diff >= 0 ? '+' : ''}${emc_diff}%`}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
