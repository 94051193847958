import { Button, DialogContent, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { amber_error_red, black, white } from '../../../../core/src/style';
import { SimpleDialog } from './SimpleDialog';

const useStyles = makeStyles((theme: Theme) => ({
  editScheduledRunBtn: {
    width: 250,
    color: black,
    height: 39,
    boxShadow: '0px 0px 6px #00000029',
    border: '1px solid #707070',
    borderRadius: 6,
    textTransform: 'capitalize',
    fontSize: 20,
  },
  dialogContent: {
    padding: '36px 26px',
  },
  content: { fontSize: 24, marginBottom: 20 },
}));

export const ConfirmationDialog: React.FunctionComponent<{
  open: boolean;
  confirmationMessage: string | JSX.Element;
  confirmBtbText: string;
  onClickClose: () => void;
  onConfirmClick: () => void;
}> = ({ open, onClickClose, onConfirmClick, confirmationMessage, confirmBtbText }) => {
  const classes = useStyles();

  return (
    <SimpleDialog open={open} handleClose={onClickClose}>
      <DialogContent className={classes.dialogContent}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography className={classes.content}>{confirmationMessage}</Typography>
            <Button
              variant="outlined"
              className={classes.editScheduledRunBtn}
              style={{ backgroundColor: amber_error_red, color: white }}
              onClick={() => {
                onConfirmClick();
                onClickClose();
              }}
            >
              {confirmBtbText}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </SimpleDialog>
  );
};
