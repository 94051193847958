import { ApolloError } from 'apollo-client';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { UserContext } from '../../../../../core/src/contexts';

import { pushNav } from '../../../action';
import { AccountFragmentFragment, ViewerFragmentFragment } from '../../../api';
import { ContainerTypeLegacy } from '../../../util';
import { AuthenticatedRoute } from '../../util';
import { BargeRouteSuffix } from '../constant';
import { DevicesLayout } from '../DevicesLayout';
import { ExportTelemetryForm } from '../ExportTelemetryForm';
import { BargeCoverForm } from './BargeCoverForm';
import { BargeDashboard } from './BargeDashboard';
import { CreateBargeForm, UpdateBargeFormGql } from './BargeForm';

type BargeRoutesProps = RouteComponentProps & {
  account_id: number;
  barge_id: number | null;
  mobile_width?: boolean;
  pathname: string;
  pellet_id: string;
  accessFailureRedirect?: (
    account: AccountFragmentFragment,
    viewer: ViewerFragmentFragment
  ) => string;
  onError: (error: ApolloError) => void;
  goCreateBarge?: () => void;
  goCreatePile?: () => void;
  goCreateGrainBin?: () => void;
  goOrgSettings?: () => void;
  onSelectContainer?: (args: any) => void;
  onArchiveClick?: (args: any) => void;
};

const validateBargeSupportAccess = (
  account: AccountFragmentFragment,
  { account: { barge_support } }: ViewerFragmentFragment
) => account && account.barge_support && barge_support;

export const BargeRoutes = ({
  match: { url },
  account_id,
  barge_id,
  pellet_id,
  accessFailureRedirect,
  onError,
  mobile_width,
  pathname,
  goCreateBarge,
  goCreatePile,
  goCreateGrainBin,
  goOrgSettings,
  onSelectContainer,
  onArchiveClick,
}: BargeRoutesProps) => {
  const dispatch = useDispatch();
  const goDashboard = useCallback(
    (selected?: {
      container_id?: number;
      container_type?: ContainerTypeLegacy;
      container_name?: string;
    }) => dispatch(pushNav({ path: `${url}/${BargeRouteSuffix.dashboard}`, params: { selected } })),
    [url, dispatch]
  );
  const goBargeCoverForm = useCallback(
    (selected?: { pellet_id: string; container_id: number; container_type: ContainerTypeLegacy }) =>
      dispatch(pushNav({ path: `${url}/${BargeRouteSuffix.covers}`, params: { selected } })),
    [url, dispatch]
  );
  return (
    <AuthenticatedRoute
      account_id={account_id}
      validateAccess={validateBargeSupportAccess}
      accessFailureRedirect={accessFailureRedirect}
      render={({ viewer }) => (
        <UserContext.Provider value={viewer}>
          <Switch>
            <Route
              path={`${url}/${BargeRouteSuffix.create}`}
              render={() =>
                account_id ? (
                  <CreateBargeForm
                    account_id={account_id}
                    onSubmitSuccess={({ barge_id, alias }) =>
                      goDashboard({
                        container_name: alias,
                        container_id: barge_id,
                        container_type: ContainerTypeLegacy.barge,
                      })
                    }
                  />
                ) : null
              }
            />
            {barge_id && (
              <Route
                path={`${url}/${BargeRouteSuffix.cover}`}
                render={() =>
                  pellet_id ? (
                    <BargeCoverForm barge_id={barge_id} pellet_id={pellet_id} onError={onError} />
                  ) : (
                    <Redirect to={`${url}/${BargeRouteSuffix.cover}`} />
                  )
                }
              />
            )}
            {barge_id && (
              <Route
                path={`${url}/${BargeRouteSuffix.covers}`}
                render={() => <BargeCoverForm barge_id={barge_id} onError={onError} />}
              />
            )}
            {barge_id && (
              <Route
                path={`${url}/${BargeRouteSuffix.devices}`}
                render={() => (
                  <div
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                  >
                    <DevicesLayout
                      container_id={barge_id}
                      container_type={ContainerTypeLegacy.barge}
                      onError={onError}
                      onHubAssignmentSuccess={goDashboard}
                      onAssignFanControllerSuccess={() => {}}
                      onUnassignFanControllerSuccess={() => {}}
                    />
                  </div>
                )}
              />
            )}
            {barge_id && (
              <Route
                path={`${url}/${BargeRouteSuffix.export_telemetry}`}
                render={() => (
                  <ExportTelemetryForm
                    container_id={barge_id}
                    container_type={ContainerTypeLegacy.barge}
                    onError={onError}
                  />
                )}
              />
            )}
            {barge_id && (
              <Route
                path={`${url}/${BargeRouteSuffix.settings}`}
                render={(props) => (
                  <UpdateBargeFormGql
                    barge_id={barge_id}
                    account_id={account_id}
                    onError={onError}
                    onSubmitSuccess={({ barge_id, alias }) =>
                      goDashboard({
                        container_name: alias,
                        container_id: barge_id,
                        container_type: ContainerTypeLegacy.barge,
                      })
                    }
                    onClickBargeCoverLinks={({ pellet_id }) => {
                      barge_id &&
                        goBargeCoverForm({
                          pellet_id,
                          container_id: barge_id,
                          container_type: ContainerTypeLegacy.barge,
                        });
                    }}
                    {...props}
                    url_base={url}
                    onSelectContainer={onSelectContainer}
                    onArchiveClick={onArchiveClick}
                    goOrgSettings={goOrgSettings}
                  />
                )}
              />
            )}
            <Route
              path={`${url}/${BargeRouteSuffix.dashboard}`}
              render={() => (
                <BargeDashboard
                  pathname={pathname}
                  onSelectContainer={onSelectContainer}
                  goCreateBarge={goCreateBarge}
                  goCreateGrainBin={goCreateGrainBin}
                  goCreatePile={goCreatePile}
                  barge_id={barge_id}
                  onError={onError}
                  viewer={viewer}
                  mobile_width={mobile_width}
                  goBargeCoverForm={({ pellet_id }) => {
                    barge_id &&
                      goBargeCoverForm({
                        pellet_id,
                        container_id: barge_id,
                        container_type: ContainerTypeLegacy.barge,
                      });
                  }}
                />
              )}
            />
            <Route
              render={() => (
                <Redirect
                  to={`${url}/${barge_id ? BargeRouteSuffix.dashboard : BargeRouteSuffix.create}`}
                />
              )}
            />
          </Switch>
        </UserContext.Provider>
      )}
    />
  );
};
