import { Grid, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { DateTime, Settings } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import {
  ActiveHubsWithColorContext,
  ActiveStoragePeriodContext,
  HubWithColorCode,
} from '../../../contexts';

import {
  BargeCoverPelletLinkFragmentFragment,
  ContainerType,
  GetBargeContainerQuery,
  UserRole,
  ViewerFragmentFragment,
  withGetBargeContainerHoc,
  withGetBargeCoverPelletLinksHoc,
} from '../../../api';
import { ContainerTypeLegacy, Statistic } from '../../../util';
import { LocationMap } from '../../location';
import { DialogSpinner } from '../../spinner';
import { BinWeatherForecastCard } from '../aeration';
import { formatDate } from '../aeration/BinFanSettings';
import { ContainerDashboardLayout } from '../BaseLayout';
import { BargeTemperatureCard } from '../container-layout-card';
import { SelectContainerPlaceholder } from '../SelectContainerPlaceholder';
import { BargeSummaryCard, BinInteriorCard, FlexSummaryCard } from '../summary-card';
import { LINE_COLORS } from '../summary-card/charts/LevelHistoryPlotHelpers';
import { BargePellets } from './BargePellets';

const formatTime = (value) => value.toFormat('h:mm a');

type BargeDashboardProps = {
  barge_id?: number | null | undefined;
  mobile_width?: boolean;
  pathname: string;
  viewer: ViewerFragmentFragment;
  onError?: any;
  goBargeCoverForm: (arg: { pellet_id: string }) => void;
  goCreateBarge?: () => void;
  goCreatePile?: () => void;
  goCreateGrainBin?: () => void;
  onSelectContainer?: (args: any) => void;
};
export const BargeDashboard = ({
  barge_id,
  mobile_width,
  pathname,
  viewer,
  onError,
  goBargeCoverForm,
  goCreateBarge,
  goCreatePile,
  goCreateGrainBin,
  onSelectContainer,
}: BargeDashboardProps) => {
  return (
    <ContainerDashboardLayout
      viewer={viewer}
      pathname={pathname}
      onSelectContainer={onSelectContainer}
      goCreateBarge={goCreateBarge}
      goCreatePile={goCreatePile}
      goCreateGrainBin={goCreateGrainBin}
      mobile_width={mobile_width}
    >
      {barge_id ? (
        <BargeDashboardInner
          viewer={viewer}
          barge_id={barge_id}
          onError={onError}
          goBargeCoverForm={goBargeCoverForm}
          mobile_width={mobile_width}
        />
      ) : (
        <SelectContainerPlaceholder />
      )}
    </ContainerDashboardLayout>
  );
};

type BargeDashboardInnerProps = {
  barge_cover_pellet_links: BargeCoverPelletLinkFragmentFragment[];
  grain_container: GetBargeContainerQuery['grain_container'];
  loading?: boolean;
  viewer: ViewerFragmentFragment;
  goBargeCoverForm: (arg: { pellet_id: string }) => void;
  mobile_width?: boolean;
};

const BargeDashboardInner = withGetBargeCoverPelletLinksHoc(
  withGetBargeContainerHoc(
    ({
      barge_cover_pellet_links,
      grain_container,
      loading = false,
      viewer,
      goBargeCoverForm,
      mobile_width,
    }: BargeDashboardInnerProps) => {
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (loading) {
        return <DialogSpinner title="Loading Dashboard..." open={loading} />;
      }
      if (!grain_container || !barge_cover_pellet_links) {
        return null;
      }
      const {
        account: {
          system_account,
          // organization
        },
        user: { role },
      } = viewer;

      const diagnostic_mode = system_account && role === UserRole.Admin;
      const [prevWeekNo, setPrevWeekNo] = useState(0);
      const grainContainerId = grain_container ? grain_container.container_id : null;

      const {
        active_hub_links,
        container_id,
        container_type,
        active_hub_links_including_fc_plenum_hubs,
        telemetry,
      } = grain_container;

      if (container_type !== ContainerTypeLegacy.barge) {
        return null;
      }
      const ctx = useContext(ActiveStoragePeriodContext);
      ctx.setActiveStoragePeriod(null);

      const has_hubs_including_fc_plenum_hubs =
        active_hub_links_including_fc_plenum_hubs.length > 0;
      const { headspace = null, weather = null, location_next = null, weather_forecast = null } =
        telemetry || {};
      let location_with_timezone: string = browserTimezone;
      if (
        telemetry &&
        telemetry.location_with_timezone &&
        telemetry.location_with_timezone.timezone
      ) {
        location_with_timezone = telemetry.location_with_timezone.timezone;
        Settings.defaultZone = location_with_timezone;
        Settings.defaultLocale = 'en-US';
      }
      // Hubs data with order and colorCode mapping for graphs
      const activeHubsWithColorContext = useContext(ActiveHubsWithColorContext);
      useEffect(() => {
        if (!loading) {
          if (
            grain_container &&
            grain_container.active_hub_links &&
            grain_container.active_hub_links.length > 0
          ) {
            const new_active_hub_links: HubWithColorCode[] = grain_container.active_hub_links.map(
              (hub, idx) => ({
                order: idx,
                color: LINE_COLORS[idx],
                ...hub,
              })
            );
            activeHubsWithColorContext.setActiveHubsWithColor(new_active_hub_links);
          } else {
            activeHubsWithColorContext.setActiveHubsWithColor(null);
          }
        }
      }, [loading, grain_container]);

      useEffect(() => {
        // reset to present weather view
        if (grainContainerId) {
          setPrevWeekNo(0);
        }
      }, [grainContainerId]);

      return (
        <>
          {diagnostic_mode &&
            active_hub_links &&
            active_hub_links.map(({ core_id, hub }) => (
              <Grid key={core_id} item xs={12}>
                <Typography variant="h6">
                  Hub {(hub && hub.alias) || ''} - {core_id}
                </Typography>
              </Grid>
            ))}
          {active_hub_links && active_hub_links.length === 0 && (
            <Grid item xs={12}>
              <h3
                style={{
                  color: red['500'],
                  marginBottom: 0,
                  marginTop: 10,
                }}
              >
                No Hub Assigned
              </h3>
            </Grid>
          )}
          {active_hub_links.length === 0 ? null : (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
              spacing={2}
              alignContent="flex-start"
            >
              <Grid
                item
                xs={12}
                container
                direction="row"
                justify="center"
                alignItems="stretch"
                spacing={2}
                alignContent="flex-start"
              >
                <Grid item xl={6} lg={6} md={10} sm={12} xs={12}>
                  <FlexSummaryCard title="Location" style={{ height: '100%', minHeight: 400 }}>
                    <LocationMap
                      position={
                        location_next
                          ? {
                              lat: location_next.latitude,
                              lng: location_next.longitude,
                            }
                          : undefined
                      }
                    />
                    {location_next && location_next.epoch_time && (
                      <Typography style={{ marginTop: 10 }}>
                        Last updated:{' '}
                        {formatDate(
                          DateTime.fromMillis(new Date(location_next.epoch_time).getTime()),
                          DateTime.local()
                        )}{' '}
                        {formatTime(
                          DateTime.fromMillis(new Date(location_next.epoch_time).getTime())
                        )}
                      </Typography>
                    )}
                  </FlexSummaryCard>
                </Grid>
                <Grid item xl={6} lg={6} md={10} sm={12} xs={12}>
                  {has_hubs_including_fc_plenum_hubs && (
                    <BinInteriorCard
                      container_id={container_id}
                      container_type={container_type}
                      grain_status={
                        headspace
                          ? {
                              ...headspace,
                              temp_f_ranges: [],
                              humidity_rh_ranges: [],
                              emc_ranges: [],
                            }
                          : null
                      }
                      telemetry_type="headspace"
                      title="Airspace"
                      minHeight={mobile_width ? undefined : 419}
                      maxHeight={mobile_width ? undefined : 530}
                      grain_bin_location_timezone={location_with_timezone}
                      overlay_aeration
                      selectable_period
                    />
                  )}
                </Grid>
                <Grid item xl={6} lg={6} md={10} sm={12} xs={12}>
                  <BinInteriorCard
                    container_id={container_id}
                    container_type={container_type}
                    grain_status={weather || undefined}
                    telemetry_type="weather"
                    title="Weather History at Barge"
                    selectable_period={false}
                    exclude_statistics={[Statistic.co2_ppm]}
                  />
                </Grid>
                {weather_forecast && weather_forecast.daily_forecast && (
                  <Grid item xs={12}>
                    <BinWeatherForecastCard
                      grain_bin_id={container_id}
                      weather_forecast={weather_forecast}
                      recommendation_windows_data={{
                        emc_ranges: [],
                        emc_reconditioning_ranges: [],
                        temp_ranges: [],
                        __typename: 'RecommendationWindows',
                      }}
                      weather={weather}
                      mobile_width={mobile_width}
                      grain_bin_location_timezone={location_with_timezone}
                      recommendation_type={null}
                      has_enable_fan_guidance={false}
                      fan_guidance_start_date={null}
                      fan_guidance_end_date={null}
                      container_type={ContainerTypeLegacy.barge}
                      title="Weather Forecast at Barge"
                      prev_week_no={prevWeekNo}
                      setPrevWeekNo={setPrevWeekNo}
                      from_weather_history={prevWeekNo !== 0}
                    />
                  </Grid>
                )}
                <Grid item xl={4} lg={4} md={10} sm={12} xs={12}>
                  <BargeTemperatureCard
                    grain_container={grain_container}
                    barge_cover_pellet_links={barge_cover_pellet_links}
                  />
                </Grid>
                {telemetry && (
                  <Grid item xs={12}>
                    <BargeSummaryCard
                      barge_cover_pellet_links={barge_cover_pellet_links}
                      title="Temperature History"
                      grain_container={grain_container}
                    />
                  </Grid>
                )}
                {/*temporarily hiding interior graph for clarkson due to air-positioned sensors*/}
                {/*{organization.toLowerCase().includes('clarkson') && (*/}
                {/*<Grid item xl={6} lg={6} md={10} sm={12} xs={12}>*/}
                {/*<BinInteriorCard*/}
                {/*container_id={container_id}*/}
                {/*container_type={container_type}*/}
                {/*grain_status={interior || undefined}*/}
                {/*telemetry_type="interior"*/}
                {/*title={formatGrainType(default_grain_type)}*/}
                {/*selectable_period={false}*/}
                {/*/>*/}
                {/*</Grid>*/}
                {/*)}*/}
              </Grid>
              <BargePellets
                diagnostic_mode={diagnostic_mode}
                barge_id={container_id}
                barge_cover_pellet_links={barge_cover_pellet_links}
                goBargeCoverForm={goBargeCoverForm}
                grain_container={grain_container}
              />
            </Grid>
          )}
        </>
      );
    }
  )
);
