import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { CSSProperties, MouseEventHandler } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    paddingBottom: 8,
    paddingTop: 4,
  },
  root_active: {
    backgroundColor: theme.palette.action.hover,
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 26,
  },
}));

export const TileButtonToggle: React.FunctionComponent<{
  title: string;
  subtitle: string;
  toggled: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  titleStyle?: CSSProperties;
}> = ({ onClick, subtitle, title, titleStyle, toggled }) => {
  const classes = useStyles();
  return (
    <button
      onClick={onClick}
      className={`${classes.root}${toggled ? ` ${classes.root_active}` : ''}`}
    >
      <h1 className={classes.title} style={titleStyle}>
        {title}
      </h1>
      <small>{subtitle}</small>
    </button>
  );
};
