import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  UserRole,
  withGetGrainContainerHistoryAerationRunsWithLimitHoc,
  WithGetGrainContainerHistoryAerationRunsWithLimitHocChildProps,
} from '../../../../api';
import { CenteredSpinner } from '../../../spinner';
import { SummaryCard } from '../../summary-card';
import { MissingFanPowerInfo } from './MissingFanPowerInfo';
import { PowerConsumption } from './PowerConsumption';
import { PowerConsumptionTable } from './PowerConsumptionTable';

const BasePowerConsumptionCard: FunctionComponent<
  {
    grain_bin_id: number;
    viewer_role: UserRole;
    storage_period?: {
      grain_bin_storage_cycle_id: number;
    } | null;
  } & WithGetGrainContainerHistoryAerationRunsWithLimitHocChildProps
> = ({ storage_period, grain_bin_id, fan_power_usage, loading, viewer_role }) => {
  const [fanPowerUsage, setFanPowerUsage] = useState(fan_power_usage);
  const { fan_controllers_with_missing_info, fan_power_stats, total_kwh }: any = fanPowerUsage;

  useEffect(() => {
    if (fan_power_usage) {
      setFanPowerUsage(fan_power_usage);
    }
  }, [JSON.stringify(fan_power_usage)]);

  return (
    <SummaryCard title="Fan Power Usage">
      <div
        style={{
          minHeight: 419,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
        }}
      >
        {fan_controllers_with_missing_info.length ? (
          <MissingFanPowerInfo fan_controllers={fan_controllers_with_missing_info} />
        ) : (
          <PowerConsumptionTable fan_power_stats={fan_power_stats} />
        )}
        {loading ? (
          <CenteredSpinner fadeIn="none" />
        ) : (
          <PowerConsumption
            grain_bin_id={grain_bin_id}
            total_kwh={total_kwh}
            viewer_role={viewer_role}
          />
        )}
      </div>
    </SummaryCard>
  );
};

export const PowerConsumptionCard = withGetGrainContainerHistoryAerationRunsWithLimitHoc(
  BasePowerConsumptionCard
);
