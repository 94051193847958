import { AmberHoc, AmberHocProps } from '../../../util';
import {
  DeleteGrainBinTicketMutation as Result,
  DeleteGrainBinTicketMutationVariables as Variables,
  withDeleteGrainBinTicket as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithDeleteGrainBinTicketHocChildProps = {
  deleteGrainBinTicket: (variables: Variables) => Promise<Result['deleteGrainBinTicket']>;
};
export const withDeleteGrainBinTicketHoc: AmberHoc<{}, WithDeleteGrainBinTicketHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<{}, WithDeleteGrainBinTicketHocChildProps, typeof component>,
    WithDeleteGrainBinTicketHocChildProps
  >({
    options: {
      errorPolicy: 'all',
    },
    props: (props) => {
      const { mutate, data } = props;
      return {
        deleteGrainBinTicket: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.deleteGrainBinTicket;
        },
      };
    },
  })(component as any);
