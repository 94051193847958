import { Checkbox, IconButton, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core';
import { DarkCheckBoxIcon, GearIcon, HideIcon } from '../../../../../../core/src/media';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { light_gray_7 } from '../../../../../../core/src/style';
import { ContainerTypeLegacy } from '../../../../../../core/src/util';

const useStyles = makeStyles((theme: Theme) => ({
  binText: {
    textAlign: 'left',
    lineHeight: '20px',
    width: 170,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

type GrainBinCellMenuProps = {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleGrainMenuClose: () => void;
  renameGrainBin: () => void;
};

export const GrainBinCellRenderer = (props) => {
  const classes = useStyles();
  const { value: container_name, data } = props;
  console.log('props in GrainBinCellRenderer', props);
  const binsVisibilityPrefs = props.binsVisibilityPrefs;
  console.log('binsVisibilityPrefs in GrainBinCellRenderer', binsVisibilityPrefs);
  const bin_id = data.bin_id;
  const binVisibilityInfo = binsVisibilityPrefs
    ? binsVisibilityPrefs.find((bin) => bin.bin_id === bin_id)
    : null;
  const initVisibilityVal: boolean = binVisibilityInfo ? !binVisibilityInfo.hide : true;
  console.log('initVisibilityVal', initVisibilityVal);
  const [hasVisible, setHasVisible] = useState(initVisibilityVal);
  const currentBinVisibilityState: boolean =
    props.api.binsVisibilityState !== undefined &&
    props.api.binsVisibilityState[bin_id] !== undefined
      ? props.api.binsVisibilityState[bin_id]
      : initVisibilityVal;

  console.log('init currentBinVisibilityState', {
    bin_id,
    hasVisible,
    currentBinVisibilityState,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleGrainMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleGrainMenuClose = () => {
    setAnchorEl(null);
  };

  const editCell = useCallback(() => {
    const rowIndex = props.node.rowIndex;
    console.log('rowIndex in editCell', { rowIndex });
    props.api.setFocusedCell(rowIndex, 'ag-Grid-AutoColumn');
    props.api.startEditingCell({
      rowIndex,
      colKey: 'ag-Grid-AutoColumn',
    });
  }, []);

  const toggleRowVisibility = ({ target: { checked } }) => {
    setHasVisible(checked);
    props.api['binsVisibilityState'][bin_id] = checked;
    if (props.updateRowVisibility) {
      props.updateRowVisibility(props.api, bin_id, !checked);
    }
    props.api.refreshCells({ force: true });
  };

  // initial binsVisibilityState saving
  useEffect(() => {
    if (props.api.binsVisibilityState) {
      if (props.api.binsVisibilityState[bin_id] === undefined) {
        props.api.binsVisibilityState[bin_id] = initVisibilityVal;
        console.log('binsVisibilityState added for bin_id', bin_id);
      }
    } else {
      console.log('binsVisibilityState created with bin_id', bin_id);
      props.api.binsVisibilityState = { [bin_id]: initVisibilityVal };
    }
  }, []);

  // update the checkbox state after parent site's visibility updated
  useEffect(() => {
    setHasVisible(currentBinVisibilityState);
  }, [currentBinVisibilityState]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Checkbox
        checked={hasVisible}
        icon={<HideIcon />}
        checkedIcon={<DarkCheckBoxIcon />}
        onChange={toggleRowVisibility}
        style={{ marginRight: 10 }}
      />

      <div className={classes.binText}>{container_name}</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          width: 41,
        }}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <IconButton
            onClick={handleGrainMenuClick}
            aria-haspopup="true"
            aria-controls={open ? 'grain-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="more"
            style={{ padding: 5, width: 30, height: 30 }}
          >
            <GearIcon />
          </IconButton>
          <GrainBinCellMenu
            anchorEl={anchorEl}
            open={open}
            handleGrainMenuClose={handleGrainMenuClose}
            renameGrainBin={editCell}
          />
        </div>
      </div>
    </div>
  );
};

const GrainBinCellMenu = ({
  anchorEl,
  open,
  handleGrainMenuClose,
  renameGrainBin,
}: GrainBinCellMenuProps) => {
  const menuItemStyles = { minHeight: 22 };
  return (
    <Menu
      id="grain-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleGrainMenuClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        style: {
          border: `1px solid ${light_gray_7}`,
          marginLeft: 10,
        },
      }}
      MenuListProps={{
        disablePadding: true,
      }}
    >
      <MenuItem style={menuItemStyles} onClick={renameGrainBin}>
        Rename Bin
      </MenuItem>
    </Menu>
  );
};
