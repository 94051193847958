import { Checkbox, FormControlLabel, Grid, MenuItem, Switch, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import {
  AccountFragmentFragment,
  UserFragmentFragment,
  withUpdateGrainBinNotificationPrefHoc,
  withUpdateGrainBinNotificationPrefHocChildProps,
  withUpdateGrainBinSpecificAlertPrefHoc,
  withUpdateGrainBinSpecificAlertPrefHocChildProps,
  withUpdateUserSettingsHoc,
  WithUpdateUserSettingsHocChildProps,
} from '../../api';
import {
  withConfigureAlertPrefHoc,
  withConfigureAlertPrefHocChildProps,
} from '../../api/graphql/hoc/withConfigureAlertPref';
import { DialogSpinner } from '../spinner';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../util/form2/BaseForm';
import { Select } from '../util/form2/Select';

export enum AlertType {
  PUSH_AND_TEXT = 'PUSH_AND_TEXT',
  PUSH_ONLY = 'PUSH_ONLY',
  TEXT_ONLY = 'TEXT_ONLY',
  NONE = 'NONE',
}

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    minWidth: 250,
    maxWidth: 600,
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  border: {
    borderBottomColor: 'rgba(0, 0, 0, 0.12)',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
  },
  centered: { textAlign: 'start' },
  alertHeader: {
    marginTop: '0px',
    marginBottom: '10px',
    fontWeight: 'inherit',
    textAlign: 'left',
    fontSize: '18px',
  },
  tabHeader: {
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
}));

type Values = {
  user_alert_type: AlertType;
};

export const GrainAlertsSettingsFormBase = ({
  account,
  refecthUser,
  selected_user,
  updateGrainBinNotificationPref,
  updateSpecificAlertPref,
  configureAlertPref,
  setUpdating,
  ...props
}: FormikWrapperHandlerProps<Values, UserFragmentFragment> &
  WithUpdateUserSettingsHocChildProps &
  withConfigureAlertPrefHocChildProps &
  withUpdateGrainBinNotificationPrefHocChildProps &
  withUpdateGrainBinSpecificAlertPrefHocChildProps & {
    refecthUser: () => void;
    selected_user: UserFragmentFragment;
    account: AccountFragmentFragment;
    setUpdating: Dispatch<SetStateAction<boolean>>;
    rootContainerStyles?: object;
  }) => {
  const {
    user_id,
    settings: {},
    grain_notification_opt_ins = [],
    alert_pref = { alert_type: AlertType.PUSH_AND_TEXT },
    grain_specific_alert_prefs = {
      disable_fan_guidance_alerts: true,
      fan_error_alerts: true,
      fan_start_stop_alerts: true,
      grain_bin_co2_alerts: true,
      grain_level_alerts: true,
    },
  } = selected_user;

  const { account_id = 0, grain_bin_links = [] } = account || {};

  const classes = useStyles();
  const SPECIFIC_ALERT_BUTTONS = [
    'fan_start_stop_alerts',
    'fan_error_alerts',
    'disable_fan_guidance_alerts',
    'grain_bin_co2_alerts',
    'grain_level_alerts',
  ];

  const SPECIFIC_ALERT_PREF_LABELS = {
    fan_start_stop_alerts: 'Fan Start/Stop',
    fan_error_alerts: 'Fan Error',
    disable_fan_guidance_alerts: 'Fan Automation',
    grain_bin_co2_alerts: 'Airspace CO2',
    grain_level_alerts: 'Grain Level',
  };

  const grain_bin_id_to_name = grain_bin_links.reduce((acc, curr) => {
    acc.set(curr.grain_bin_id, curr.grain_bin.alias);
    return acc;
  }, new Map<number, string>());

  const grain_notification_opt_form_obj: any = {};
  const grain_bin_checkbox_options: any[] = [];

  grain_notification_opt_ins.forEach((grain_notification_opt_in, idx) => {
    if (grain_bin_id_to_name.has(grain_notification_opt_in.grain_bin_id)) {
      const name = grain_bin_id_to_name.get(grain_notification_opt_in.grain_bin_id);
      grain_notification_opt_form_obj[grain_notification_opt_in.grain_bin_id] =
        grain_notification_opt_in.opt_in;

      grain_bin_checkbox_options.push(
        <Grid item xs={12} key={`${name}${idx}`}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={grain_notification_opt_in.opt_in}
                onChange={async (_, checked) => {
                  setUpdating(true);
                  await updateGrainBinNotificationPref({
                    user_id,
                    account_id,
                    grain_bin_id: grain_notification_opt_in.grain_bin_id,
                    opt_in: checked,
                  })
                    .then(refecthUser)
                    .then(() => {
                      setUpdating(false);
                    });
                }}
              />
            }
            label={name!}
            disabled={alert_pref.alert_type === AlertType.NONE}
          />
        </Grid>
      );
    }
  });

  const handleSpecificAlertChange = async (event) => {
    try {
      setUpdating(true);
      await updateSpecificAlertPref({
        user_id,
        ...grain_specific_alert_prefs,
        [event.target.name]: event.target.checked,
      });
      await refecthUser();
    } catch (error) {
      console.error(error);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <FormikWrapper<Values, any>
      {...props}
      initialValues={{ user_alert_type: alert_pref.alert_type as AlertType }}
      onSubmit={(_) => Promise.resolve()}
      render={() => {
        return (
          <BaseForm
            style={props.rootContainerStyles ? props.rootContainerStyles : undefined}
            submitting_message="Saving user settings..."
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              alignContent="flex-start"
              justify="center"
              className={classes.grid}
              spacing={2}
            >
              {
                <>
                  <Grid item xs={12} className={classes.centered}>
                    <Typography
                      variant="h5"
                      style={{ marginTop: '20px' }}
                      className={classes.tabHeader}
                    >
                      Notification Settings - Select bin to receive alerts
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.border}>
                    <Typography variant="h6" className={classes.alertHeader}>
                      Alert Channels
                    </Typography>
                    <Select
                      onChange={async (val) => {
                        setUpdating(true);
                        await configureAlertPref({ user_id, alert_type: val as string })
                          .then(refecthUser)
                          .then(() => {
                            setUpdating(false);
                          });
                      }}
                      label={''}
                      name={'user_alert_type'}
                      style={{ minWidth: 200, paddingBottom: 20 }}
                      selectStyles={{ marginTop: '0px' }}
                    >
                      <MenuItem value={AlertType.PUSH_AND_TEXT}>
                        Both Push and Text Messages
                      </MenuItem>
                      <MenuItem value={AlertType.PUSH_ONLY}>Push Notifications Only</MenuItem>
                      <MenuItem value={AlertType.TEXT_ONLY}>Text Messages Only</MenuItem>
                      <MenuItem value={AlertType.NONE}>None</MenuItem>
                    </Select>
                    <Typography
                      variant="h6"
                      className={classes.alertHeader}
                      style={{ marginTop: '10px' }}
                    >
                      Alert Types
                    </Typography>
                    <Grid style={{ marginBottom: 10 }}>
                      {SPECIFIC_ALERT_BUTTONS.map((pref) => (
                        <FormControlLabel
                          key={pref}
                          control={
                            <Switch
                              defaultChecked={grain_specific_alert_prefs[pref]}
                              name={pref}
                              onChange={handleSpecificAlertChange}
                              disabled={alert_pref.alert_type === AlertType.NONE}
                            />
                          }
                          label={SPECIFIC_ALERT_PREF_LABELS[pref]}
                        />
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.alertHeader}>
                    <Typography
                      variant="h6"
                      className={classes.alertHeader}
                      style={{ marginTop: '10px' }}
                    >
                      Grain Bins
                    </Typography>
                    {grain_bin_checkbox_options}
                  </Grid>
                </>
              }
            </Grid>
          </BaseForm>
        );
      }}
    />
  );
};

export const GrainAlertsSettingsForm = withUpdateUserSettingsHoc(
  withConfigureAlertPrefHoc(
    withUpdateGrainBinNotificationPrefHoc(
      withUpdateGrainBinSpecificAlertPrefHoc(GrainAlertsSettingsFormBase)
    )
  )
);
