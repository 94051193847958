import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { GetShowCo2LevelWarningQuery, withGetShowCo2LevelWarning as HOC } from '../__generated';

export type WithGetShowCo2LevelWarningHocOwnProps = {
  container_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetShowCo2LevelWarningHocChildProps = {
  show_co2_level_warning_loading: boolean;
  show_co2_level_warning: GetShowCo2LevelWarningQuery['show_co2_level_warning'];
  refetch: () => Promise<any>;
};
export const withGetShowCo2LevelWarningHoc: AmberHoc<
  WithGetShowCo2LevelWarningHocOwnProps,
  WithGetShowCo2LevelWarningHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetShowCo2LevelWarningHocOwnProps,
      WithGetShowCo2LevelWarningHocChildProps,
      typeof component
    >,
    WithGetShowCo2LevelWarningHocChildProps
  >({
    options: ({ container_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      fetchPolicy: 'network-only',
      variables: { container_id },
      notifyOnNetworkStatusChange: true,
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          show_co2_level_warning_loading: true,
          refetch: () => Promise.resolve(),
          show_co2_level_warning: null,
        };
      }

      return {
        show_co2_level_warning_loading: loading || false,
        refetch: data.refetch,
        show_co2_level_warning: data.show_co2_level_warning || null,
      };
    },
  })(component as any);
