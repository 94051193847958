import { Dialog, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import React from 'react';

export type DialogSpinnerProps = { open: boolean; title: string };
export const DialogSpinner: React.FunctionComponent<DialogSpinnerProps> = (props) => (
  <Dialog
    open={props.open}
    PaperProps={{
      style: {
        boxShadow: ' rgba(0, 0, 0, 0.35) 0px 5px 15px',
      },
    }}
  >
    <DialogTitle style={{ paddingBottom: 0, paddingTop: 10 }}>{props.title}</DialogTitle>
    <DialogContent style={{ paddingBottom: 16 }}>
      <LinearProgress color="secondary" />
    </DialogContent>
  </Dialog>
);
