import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import xrange from 'highcharts/modules/xrange';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { hasDiagnosticAccess, UserContext } from '../../../contexts';
import { amber_grey, gray_shade_3 } from '../../../style';
import {
  getAllCategories,
  getCategories,
  getFormattedSeriesData,
  getFormattedSeriesDataV2,
  getRemainingAutomationPeriodString,
} from './AerationRecommnedationPlotHelpers';
import {
  ExperimentRecommendedScheduledRange,
  RecommendedScheduledRange,
} from './RecommendationScheduleBar';

declare global {
  interface Window {
    moment: any;
  }
}
window.moment = moment;
xrange(Highcharts);
type AerationRecommendationPlotProps = {
  has_enable_fan_guidance: boolean;
  scheduledRunsToPresent: RecommendedScheduledRange[];
  coolingPeriodsToPresent: RecommendedScheduledRange[];
  dryingPeriodsToPresent: RecommendedScheduledRange[];
  reconditioningPeriodsToPresent: RecommendedScheduledRange[];
  experimentPeriodsToPresent?: ExperimentRecommendedScheduledRange;
  grain_bin_location_timezone: string;
  recommendation_type: string | null;
  setRecommendationChart: Function;
  syncronizeCrossHairs: Function;
  minDate: DateTime | null;
  maxDate: DateTime | null;
  fan_guidance_start_date: Date | null;
  fan_guidance_end_date: Date | null;
  show_experiments?: boolean;
};

const AerationRecommendationPlot = ({
  has_enable_fan_guidance,
  scheduledRunsToPresent,
  coolingPeriodsToPresent,
  dryingPeriodsToPresent,
  reconditioningPeriodsToPresent,
  experimentPeriodsToPresent,
  grain_bin_location_timezone,
  recommendation_type,
  setRecommendationChart,
  syncronizeCrossHairs,
  minDate,
  maxDate,
  fan_guidance_start_date,
  fan_guidance_end_date,
  show_experiments = false,
}: AerationRecommendationPlotProps) => {
  const [chart2, setChart2] = useState(null);

  useEffect(() => {
    syncronizeCrossHairs(chart2);
  }, [chart2]);

  Highcharts.setOptions({
    time: {
      timezone: grain_bin_location_timezone,
    },
  });

  console.log('Recommendation things:', {
    scheduledRunsToPresent,
    coolingPeriodsToPresent,
    dryingPeriodsToPresent,
    reconditioningPeriodsToPresent,
    experimentPeriodsToPresent,
  });
  const has_diagnostic_access = hasDiagnosticAccess(React.useContext(UserContext));

  const as_of = DateTime.local();
  const fanGuidanceEndDate =
    has_enable_fan_guidance &&
    fan_guidance_end_date &&
    DateTime.fromMillis(new Date(fan_guidance_end_date).getTime());
  const remainingAutomationPeriodInDays =
    has_enable_fan_guidance && fanGuidanceEndDate
      ? fanGuidanceEndDate.diff(as_of, 'days').toObject().days
      : null;
  const remainingAutomationPeriodString =
    has_enable_fan_guidance &&
    fanGuidanceEndDate &&
    getRemainingAutomationPeriodString(as_of, fanGuidanceEndDate)
      ? `(${getRemainingAutomationPeriodString(as_of, fanGuidanceEndDate)})`
      : '';

  console.log('Remaining Automation Period:', remainingAutomationPeriodString);

  const allCategories = getAllCategories(
    has_enable_fan_guidance,
    has_diagnostic_access && show_experiments
  );
  const categories = recommendation_type
    ? getCategories(
        recommendation_type,
        has_enable_fan_guidance,
        has_diagnostic_access && show_experiments,
        experimentPeriodsToPresent
      ) || allCategories
    : allCategories;

  console.log('categories', categories, experimentPeriodsToPresent);

  // const formattedSeriesData = getFormattedSeriesData({
  //   recommendation_type,
  //   scheduledRunsToPresent,
  //   coolingPeriodsToPresent,
  //   dryingPeriodsToPresent,
  //   reconditioningPeriodsToPresent,
  //   experimentPeriodsToPresent: has_diagnostic_access && show_experiments?experimentPeriodsToPresent:null,
  // });

  const formattedSeriesDataV2 =
    has_diagnostic_access && show_experiments
      ? getFormattedSeriesDataV2({
          recommendation_type,
          scheduledRunsToPresent,
          coolingPeriodsToPresent,
          dryingPeriodsToPresent,
          reconditioningPeriodsToPresent,
          experimentPeriodsToPresent,
          hasEnableFanGuidance: has_enable_fan_guidance,
        })
      : getFormattedSeriesDataV2({
          recommendation_type,
          scheduledRunsToPresent,
          coolingPeriodsToPresent,
          dryingPeriodsToPresent,
          reconditioningPeriodsToPresent,
          experimentPeriodsToPresent: undefined,
          hasEnableFanGuidance: has_enable_fan_guidance,
        });

  console.log('categories', categories, categories.length);
  const chartHeight = experimentPeriodsToPresent ? 120 : categories.length === 4 ? 340 : 196;

  const chartOptions = {
    credits: false,
    chart: {
      type: 'xrange',
      marginLeft: 75,
      marginRight: 145,
      height: chartHeight,
      events: {
        load() {
          setChart2(this);
          setRecommendationChart(this);
          syncronizeCrossHairs(this);
        },
        render() {
          // If placeholders already exist, clear them
          if (this.categoryEmptyPlaceholders && this.categoryEmptyPlaceholders.length) {
            this.categoryEmptyPlaceholders.forEach((emptyPlaceholder) => {
              emptyPlaceholder.destroy();
            });
          }
          this.categoryEmptyPlaceholders = [];
          // Which categories are without any data?
          const categoriesToShowPlotData: any = [];

          this.series[0].points.forEach((point) => {
            if (
              categoriesToShowPlotData.indexOf(point.y) === -1 &&
              point.x !== undefined &&
              point.x2 !== undefined
            ) {
              categoriesToShowPlotData.push(point.y);
            }
          });

          const getEmptyPlaceholderMessage = (category, show_experiments) => {
            if (show_experiments) {
              return {
                [allCategories[0]]: 'No Recommended Drying Windows',
                [allCategories[1]]: 'No Recommended Drying Windows',
                [allCategories[2]]: 'No Recommended Reconditioning Windows',
                [allCategories[3]]: 'No Recommended Reconditioning Windows',
              }[category];
            }
            return {
              [allCategories[0]]: 'No Scheduled Runs',
              [allCategories[1]]: 'No Recommended Cooling Windows',
              [allCategories[2]]: 'No Recommended Drying Windows',
              [allCategories[3]]: 'No Recommended Reconditioning Windows',
            }[category];
          };

          for (let i = 0; i <= this.yAxis[0].dataMax; i += 1) {
            if (categoriesToShowPlotData.indexOf(i) === -1) {
              const message = getEmptyPlaceholderMessage(
                categories[i],
                has_diagnostic_access && show_experiments
              );

              this.categoryEmptyPlaceholders.push(
                this.renderer
                  .text(message, 0, this.yAxis[0].toPixels(i) + 4)
                  .css({ fontSize: 12, fontWeight: 'bold' })
                  .add()
                  .toFront()
              );
            }
          }
          // Correct placeholders positions (translateX -50%)
          this.categoryEmptyPlaceholders.forEach((label) => {
            label.attr({
              x: this.plotLeft + 10,
            });
          });

          // Draw Gray Background Plot
          const xAxis = this.xAxis[0];
          const lastKey = xAxis.extKey ? xAxis.extKey.split(',')[1] : xAxis.max;
          const width = Math.round(xAxis.toPixels(lastKey)) - this.plotLeft;
          const firstTickOnXaxisInPx = minDate
            ? Math.round(xAxis.toPixels(minDate.toMillis()))
            : Math.round(xAxis.toPixels(xAxis.tickPositions[0]));

          for (let i = 0; i <= this.yAxis[0].dataMax; i += 1) {
            this.renderer
              .rect(firstTickOnXaxisInPx, this.yAxis[0].toPixels(i) - this.plotTop, width, 20)
              .attr({
                zIndex: ' -10',
                stroke: 'rgb(211,211,211)',
                fill: 'rgb(211,211,211)',
                'stroke-width': 1,
              })
              .add();
          }

          // clear previous automation period plot
          if (this.automationPeriodBorderPlot && this.automationPeriodPlot) {
            this.automationPeriodBorderPlot.element.remove();
            this.automationPeriodPlot.element.remove();
          }

          // for automation period plot
          if (has_enable_fan_guidance && fan_guidance_start_date && fan_guidance_end_date) {
            const automationStartDate =
              new Date(fan_guidance_start_date).getTime() < minDate.toMillis()
                ? minDate.toMillis()
                : new Date(fan_guidance_start_date).getTime();
            const automationEndDate =
              new Date(fan_guidance_end_date).getTime() > maxDate.toMillis()
                ? lastKey
                : new Date(fan_guidance_end_date).getTime();
            const startPxls = Math.round(xAxis.toPixels(automationStartDate));
            const width = Math.round(xAxis.toPixels(automationEndDate)) - startPxls;
            // draw new automation period plot
            this.automationPeriodPlot = this.renderer
              .rect(startPxls, this.yAxis[0].toPixels(0) - this.plotTop, width, 20)
              .attr({
                zIndex: '-10',
                stroke: 'rgb(169,169,169)',
                fill: 'rgb(169,169,169)',
                'stroke-width': 0,
              })
              .add();

            // border rectangle to indicate start and end of automation period
            this.automationPeriodBorderPlot = this.renderer
              .rect(startPxls, this.yAxis[0].toPixels(0) - this.plotTop, width, 20)
              .attr({
                zIndex: '3',
                stroke: 'green',
                'stroke-width': 2.5,
              })
              .add();
          }
        },
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      // tickLength: 0,
      min: minDate ? minDate.toMillis() : null,
      max: maxDate ? maxDate.toMillis() : null,
      type: 'datetime',
      labels: {
        // enabled: false,
        format: '{value:%a}', // '{value:%Y-%b-%e %l:%M %p }',
        style: {
          fontFamily: 'Source Sans Pro,sans-serif',
          fontSize: 12,
        },
      },
      crosshair: false,
      tickInterval: 24 * 3600 * 1000, // 24 hrs
    },
    yAxis: {
      categories,
      title: {
        text: '',
      },
      labels: {
        useHTML: true,
        align: 'left',
        x: 0,
        y: -18,
        style: {
          fontFamily: 'Source Sans Pro,sans-serif',
          color: amber_grey,
          fontSize: 16,
          lineHeight: 0.5,
          fontWeight: 400,
          width: '600px',
        },
        formatter() {
          if (this.value === 'Scheduled Automations') {
            return `<span style="color:${amber_grey};font-size:16px; font-weight: 400;width:600px">${
              this.value
            } <span style="color:${
              remainingAutomationPeriodInDays !== null && remainingAutomationPeriodInDays <= 1
                ? 'red'
                : ''
            }">${remainingAutomationPeriodString}<span></span>`;
          }
          return `<span style="color:${amber_grey};font-size:16px; font-weight: 400;width:600px">${
            this.value
          }</span>`;
        },
      },
      reversed: true,
    },

    tooltip: {
      useHTML: true,
      formatter() {
        const tooltipHeading = `${Highcharts.dateFormat('%a %l:%M %p', this.x)}-${
          this.point.label === 'Forever' ? 'Forever' : Highcharts.dateFormat('%a %l:%M %p', this.x2)
        }`;

        return `<div style='background-color:#fff;padding:4px;margin:0px;border:1px solid gray;border-bottom:none;border-radius:3px'>
        <b>${tooltipHeading}</b>
        </div>`;
      },
      padding: 0,
      borderWidth: 1,
      borderRadius: 8,
      borderColor: 'gray',
      backgroundColor: '#fff',
      style: {
        opacity: 0.85,
        zIndex: 9999,
      },
    },

    series: [
      {
        name: 'Recommendation windows schedule',
        backgroundColor: 'gray',
        zIndex: 999,
        showInLegend: false,
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: 'rgb(66,66,66)',
        pointWidth: 20,
        data: formattedSeriesDataV2,
        dataLabels: {
          useHTML: true,
          enabled: true,
          formatter() {
            return `<div style="width:100%;color:black;font-weight:600">${this.point.label}</div>`;
          },
        },
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default AerationRecommendationPlot;
