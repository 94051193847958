import { LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import {
  FanRunWindowRecommendedOption,
  GrainContainerAerationRunFragmentFragment,
} from '../../../../../core/src/api';
import {
  black_shade_5,
  light_gray2,
  light_gray_4,
  light_gray_7,
  maroon_shade_1,
  red_shade_1,
} from '../../../../../core/src/style';
import { useGrainBinData, useWeatherData } from '../../../contexts';
import {
  calculateRunwindowDuration,
  formatNumber,
  getTemperatureUnitLabel,
  renderModeColor,
} from '../../../util';
import { formatDuration } from '../aeration/AerationEventHistoryTable';
import { getRecommedationTypeMode } from '../aeration/FanSchedule';
import { computeAvgGrainConditions } from '../aeration/FanSetMultiSchedule';
import { GrainWeatherInputsCard } from '../aeration/GrainWeatherInputsCard';
import { NoSchedulePlaceholder } from '../aeration/NoSchedulePlaceholder';
import { RunWinowReadOnly } from '../aeration/RunWinowReadOnly';
import {
  getMs,
  getRecommendationOptionColor,
  getRunWindowProgress,
} from '../aeration/UpcomingFanScheduleTable';
import { getRemainingDaysAutomationPeriodString } from '../daily-forecast/AerationRecommnedationPlotHelpers';
import { RecommendedOption } from '../RecommendationOptionValue';

type makeStylesProps = {
  aeration_schedule_type: string | null;
  runWindowsProgress: number;
  hasScheduledRunInterrupted: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  grainWeatherInputsCard: {
    borderRadius: 8,
    width: '100%',
    background: light_gray2,
    display: 'flex',
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 10,
    height: 64,
  },
  wrapper: {
    boxSizing: 'border-box',
    flexWrap: 'wrap',
    width: '100%',
    display: 'flex',
    margin: 0,
    marginBottom: 20,
    '&:first-child': {
      marginBottom: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  runwindowCountBadge: {
    width: 20,
    height: 20,
    fontSize: 16,
    fontWeight: 600,
    color: 'black',
    background: light_gray_4,
    borderRadius: '50% 50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  automationRemainDays: {
    width: 'fit-content',
    height: 21,
    backgroundColor: light_gray_7,
    borderRadius: 7,
    padding: '3px 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    color: black_shade_5,
  },
  linearProgressContainer: {
    width: '100%',
    marginTop: 6,
    [theme.breakpoints.only('xs')]: {
      width: '98%',
    },
    [theme.breakpoints.between(405, 600)]: {
      width: '88%',
    },
    [theme.breakpoints.between(1280, 1580)]: {
      width: '100%',
    },
  },
  linearProgress: {
    backgroundColor: '#E6E6E6',
    '& .MuiLinearProgress-barColorPrimary': {
      zIndex: 0,
      background: ({ aeration_schedule_type }: makeStylesProps) => {
        return aeration_schedule_type
          ? `linear-gradient(120deg,rgba(255,255,255, 0) 30%,rgba(255,255,255, .8),rgba(255,255,255, 0) 70% ) ${getRecommendationOptionColor(
              aeration_schedule_type
            )}`
          : '';
      },
      backgroundSize: '300% 100%!important',
      animation: '$shine 2s infinite',
      animationPlayState: ({ hasScheduledRunInterrupted }: makeStylesProps) =>
        hasScheduledRunInterrupted ? 'paused' : 'running',
    },
  },
  '@keyframes shine': {
    '0%': { backgroundPosition: 'right' },
  },
  placeholderContainer: {
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      width: '98%',
    },
    [theme.breakpoints.between(405, 600)]: {
      width: '88%',
    },
    [theme.breakpoints.between(1280, 1580)]: {
      width: '100%',
    },
  },
}));

const renderTitleText = (recommendationType: string) => {
  switch (recommendationType) {
    case RecommendedOption.COOLING:
      return 'Cooling';
    case RecommendedOption.DRYING:
      return 'Drying';
    case RecommendedOption.RECONDITIONING:
      return 'Reconditioning';
  }
};

const emptyWindowMessageText = (recommendationType: string) => {
  switch (recommendationType) {
    case RecommendedOption.COOLING:
      return 'No Recommended Cooling Windows';
    case RecommendedOption.DRYING:
      return 'No Recommended Drying Windows';
    case RecommendedOption.RECONDITIONING:
      return 'No Recommended Reconditioning Windows';
  }
};

type CurrentScheduleListProps = {
  schedule: { start_epoch: Date; end_epoch: Date }[];
  hasManualMode: boolean;
  show_fan_guidance_ext_prompt: boolean;
  any_running: boolean;
  showErrorMsgInFirstRunWindow?: boolean;
  fanErrorMsgsWithoutStopFail?: string[];
  fanStopErrorMsgs?: string;
  showErrorMsgAboveSchedule?: boolean;
  aeration_runs: GrainContainerAerationRunFragmentFragment[] | null;
  hasScheduledRunInterrupted: boolean;
};

export const CurrentScheduleList = ({
  schedule,
  hasManualMode,
  show_fan_guidance_ext_prompt,
  any_running,
  showErrorMsgInFirstRunWindow = false,
  fanErrorMsgsWithoutStopFail = [],
  fanStopErrorMsgs = '',
  showErrorMsgAboveSchedule = false,
  aeration_runs,
  hasScheduledRunInterrupted,
}: CurrentScheduleListProps) => {
  const [runWindowsProgress, setRunWindowsProgress] = useState(0);
  const [manualRunProgress, setManualRunProgress] = useState(0);
  const [avgGrainConditions, setAvgGrainConditions] = useState<any>([]);
  const weatherData = useWeatherData();
  const {
    current_grain_temp,
    current_grain_emc,
    target_grain_emc,
    recommendation_type,
    container: { aeration_schedule_type = null },
    enable_fan_guidance,
    fan_guidance_end_date,
    opt_in_fan_guidance,
  } = useGrainBinData();
  const lastAerationRun = schedule.length > 0 && aeration_runs ? aeration_runs[0] : null;
  const classes = useStyles({
    aeration_schedule_type,
    runWindowsProgress,
    hasScheduledRunInterrupted,
  });
  console.log('schedule', schedule);
  const as_of = DateTime.local();
  const fanGuidanceEndDate = fan_guidance_end_date
    ? DateTime.fromMillis(new Date(fan_guidance_end_date).getTime())
    : null;
  const hasOngoingFanGuidance = Boolean(
    opt_in_fan_guidance && enable_fan_guidance && fanGuidanceEndDate
  );
  const hasScheduledRunWindowExistAfterFanGuidanceEnded =
    opt_in_fan_guidance &&
    enable_fan_guidance &&
    schedule.length > 0 &&
    as_of.toMillis() > fanGuidanceEndDate.toMillis();
  const remainingAutomationPeriodInDays =
    enable_fan_guidance && fanGuidanceEndDate
      ? fanGuidanceEndDate.diff(as_of, 'days').toObject().days
      : null;
  const showRedColorPeriod =
    remainingAutomationPeriodInDays !== null &&
    (show_fan_guidance_ext_prompt || remainingAutomationPeriodInDays <= 1);

  const remainingAutomationPeriodString =
    enable_fan_guidance && fanGuidanceEndDate
      ? getRemainingDaysAutomationPeriodString(as_of, fanGuidanceEndDate)
      : '';
  console.log('remainingAutomationPeriodString', remainingAutomationPeriodString);

  const modeType = hasManualMode
    ? FanRunWindowRecommendedOption.Manual
    : aeration_schedule_type || null;

  const hideModeSection =
    schedule.length === 0 &&
    (modeType === null || modeType === FanRunWindowRecommendedOption.Custom);
  const tempUnit = getTemperatureUnitLabel();

  useEffect(() => {
    const refreshedAverageGrainConditions = schedule.map(({ start_epoch, end_epoch }) => {
      /// When the user selects 'Now', the start date is always -1 day before the
      /// current datetime
      let start = DateTime.utc().toMillis();
      const now = start_epoch.getTime();
      if (now > start) {
        start = start_epoch.getTime();
      }

      const end = end_epoch.getTime();
      const [temp, rh] = computeAvgGrainConditions(start, end, weatherData.hourlyForecast);

      return { temp, rh };
    });
    if (weatherData) setAvgGrainConditions(refreshedAverageGrainConditions);
  }, [schedule, weatherData]);

  // for non manual mode
  useEffect(() => {
    let runWindowProgressTimer;
    if (!hasManualMode && schedule && schedule[0]) {
      const { start_epoch, end_epoch } = schedule[0];
      const hasFanRunningSchedule = any_running && start_epoch.getTime() < new Date().getTime();
      if (start_epoch && end_epoch && hasFanRunningSchedule) {
        setRunWindowsProgress(getRunWindowProgress(start_epoch, end_epoch));
        runWindowProgressTimer = setInterval(() => {
          setRunWindowsProgress((oldProgress) => {
            return getRunWindowProgress(start_epoch, end_epoch);
          });
        }, 1000 * 30); // update after every 30 sec
      }
    } else if (runWindowProgressTimer) {
      console.log('in else block of non ManualMode useEffect');
      clearInterval(runWindowProgressTimer);
    }
    return () => {
      runWindowProgressTimer && clearInterval(runWindowProgressTimer);
    };
  }, [any_running, hasManualMode]);

  // for manual mode
  useEffect(() => {
    let progressTimer;
    if (hasManualMode && schedule && schedule[0]) {
      const { start_epoch, end_epoch } = schedule[0];
      const hasManualModeWithNextSchedule = Boolean(
        any_running &&
          hasManualMode &&
          start_epoch &&
          lastAerationRun &&
          lastAerationRun.start_epoch
      );
      if (start_epoch && end_epoch && lastAerationRun && hasManualModeWithNextSchedule) {
        setManualRunProgress(getRunWindowProgress(lastAerationRun.start_epoch, start_epoch));
        progressTimer = setInterval(() => {
          setManualRunProgress((oldProgress) => {
            return getRunWindowProgress(lastAerationRun.start_epoch, start_epoch);
          });
        }, 1000 * 30); // update after every 30 sec
      }
    } else if (progressTimer) {
      console.log('in else block of ManualMode useEffect');
      clearInterval(progressTimer);
    }
    return () => {
      progressTimer && clearInterval(progressTimer);
    };
  }, [any_running, hasManualMode, lastAerationRun]);

  return (
    <div>
      <Typography
        variant="h6"
        style={{ width: '100%', padding: '0px', marginBottom: hideModeSection ? 15 : 0 }}
      >
        Fan Run Schedule
      </Typography>

      {
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!hideModeSection && (
            <>
              <div className={classes.grainWeatherInputsCard}>
                <GrainWeatherInputsCard
                  current_grain_temp={
                    current_grain_temp === null || current_grain_temp === undefined
                      ? null
                      : current_grain_temp
                  }
                  current_grain_emc={current_grain_emc || null}
                  target_grain_emc={target_grain_emc || null}
                  mode={getRecommedationTypeMode(modeType)}
                  modeColor={renderModeColor(modeType)}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '16px',
                    width: '100%',
                    paddingLeft: 10,
                  }}
                >
                  <div>
                    <Typography
                      variant="h6"
                      style={{
                        marginTop: '0px',
                        marginBottom: '0px',
                        paddingLeft: 4,
                        fontSize: 17,
                        color: 'black',
                        lineHeight: '20.4px',
                      }}
                      gutterBottom={true}
                    >
                      Schedule{' '}
                    </Typography>
                  </div>
                  <div className={classes.runwindowCountBadge}>
                    {schedule ? schedule.length : '-'}
                  </div>
                </div>
              </div>
            </>
          )}

          {/* placeholder when one fan has errror and other is running*/}
          {showErrorMsgAboveSchedule && fanStopErrorMsgs && (
            <div
              className={classes.placeholderContainer}
              style={{ marginBottom: 20, paddingLeft: 10 }}
            >
              <NoSchedulePlaceholder
                message={fanStopErrorMsgs}
                containerStyles={{ marginBottom: 0 }}
                hasErrorIcon
              />
            </div>
          )}

          {/* placeholder when Manual Mode */}
          {hasManualMode && (
            <div className={classes.placeholderContainer} style={{ padding: '0px 5px' }}>
              <NoSchedulePlaceholder
                message="On Site Manual Run"
                containerStyles={{ marginBottom: 0 }}
              />
            </div>
          )}
          {hasManualMode && schedule && schedule[0] && (
            <div
              className={classes.linearProgressContainer}
              style={{ padding: '0px 5px', marginBottom: 15 }}
            >
              <LinearProgress
                className={classes.linearProgress}
                variant="determinate"
                value={manualRunProgress}
              />
            </div>
          )}

          <div style={{ width: '100%', paddingLeft: 10 }}>
            {schedule &&
              schedule.length > 0 &&
              schedule.map(({ start_epoch, end_epoch }, ix) => {
                const hasFirstRunWindow = ix === 0;
                const start = DateTime.fromMillis(start_epoch.getTime());
                const end = DateTime.fromMillis(end_epoch.getTime());
                const duration = calculateRunwindowDuration({ start, end });
                const runtimeInText = end_epoch
                  ? formatDuration(getMs(end_epoch) - getMs(start_epoch))
                  : `${formatDuration(getMs(new Date()) - getMs(start_epoch))} & running`;
                const estimatedTemp = avgGrainConditions
                  ? avgGrainConditions[ix]
                    ? `${formatNumber(avgGrainConditions[ix]['temp'], 0)}${tempUnit}`
                    : `- ${tempUnit}`
                  : null;
                const estimatedRH = avgGrainConditions
                  ? avgGrainConditions[ix]
                    ? `${formatNumber(avgGrainConditions[ix]['rh'] * 100, 0)}% RH`
                    : '- RH'
                  : null;

                const addMarginBottom =
                  hasFirstRunWindow &&
                  (showErrorMsgAboveSchedule || (hasManualMode && schedule && schedule[0]));
                return (
                  <div
                    className={classes.wrapper}
                    key={`current-run-windows-${ix}`}
                    style={{
                      marginBottom: addMarginBottom ? 20 : undefined,
                    }}
                  >
                    {hasFirstRunWindow ? (
                      <RunWinowReadOnly
                        index={ix}
                        start={start}
                        end={end}
                        estimatedTemp={estimatedTemp}
                        estimatedRH={estimatedRH}
                        runtime={duration}
                        runtimeInText={runtimeInText}
                        showLoadingAnimation={true}
                        recommendationOptionValue={aeration_schedule_type}
                        hasScheduledRunInterrupted={hasScheduledRunInterrupted}
                        showErrorMsgInFirstRunWindow={showErrorMsgInFirstRunWindow}
                        fanErrorMsgsWithoutStopFail={fanErrorMsgsWithoutStopFail}
                        showInitialSeriesAnimation={false}
                        preventNow
                      />
                    ) : (
                      <RunWinowReadOnly
                        index={ix}
                        start={start}
                        end={end}
                        estimatedTemp={estimatedTemp}
                        estimatedRH={estimatedRH}
                        runtime={duration}
                        runtimeInText={runtimeInText}
                        showLoadingAnimation={false}
                        recommendationOptionValue={aeration_schedule_type || null}
                        showInitialSeriesAnimation={false}
                        preventNow
                      />
                    )}

                    {hasFirstRunWindow && !hasManualMode && !showErrorMsgAboveSchedule && (
                      <div className={classes.linearProgressContainer}>
                        <LinearProgress
                          className={classes.linearProgress}
                          variant="determinate"
                          value={runWindowsProgress}
                          style={{
                            marginBottom: 10,
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}

            {schedule && schedule.length === 0 && (
              <div
                className={classes.placeholderContainer}
                style={{ marginBottom: 15, paddingLeft: 0 }}
              >
                <NoSchedulePlaceholder
                  message={'No fan schedule configured'}
                  containerStyles={{ marginBottom: 0 }}
                />
              </div>
            )}

            {recommendation_type &&
              hasOngoingFanGuidance &&
              !hasScheduledRunWindowExistAfterFanGuidanceEnded &&
              remainingAutomationPeriodString && (
                <div style={{ width: '100%', marginTop: '-10px' }}>
                  <div
                    className={classes.automationRemainDays}
                    style={{
                      color: showRedColorPeriod ? maroon_shade_1 : undefined,
                      backgroundColor: showRedColorPeriod ? red_shade_1 : undefined,
                    }}
                  >
                    {remainingAutomationPeriodString}
                  </div>
                </div>
              )}
          </div>
        </div>
      }
    </div>
  );
};
