import * as React from 'react';
export const BackArrowIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" {...props}>
    <path
      id="BackArrowIcon"
      d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z"
      transform="translate(20 20) rotate(180)"
      fill="rgba(0,0,0,0.87)"
    />
  </svg>
);
