const FEET_TO_INCH: number = 12;
const FEET_TO_MM: number = 304.8;

export class MathUtils {
  public static inchToFeet(inches, precision = null): number {
    return MathUtils.toPrecision((inches * 1) / FEET_TO_INCH, precision);
  }

  public static mmToFeet(mm, precision = null): number {
    return MathUtils.toPrecision((mm * 1) / FEET_TO_MM, precision);
  }

  public static feetToMm(feet, precision = null): number {
    return MathUtils.toPrecision(feet * FEET_TO_MM, precision);
  }

  public static bushelsToHeightInFt(
    bushels: number,
    diameter_ft: number,
    precision = null
  ): number {
    return MathUtils.toPrecision(
      bushels / (MathUtils.circleAreaInFt(diameter_ft) * 0.8036),
      precision
    );
  }

  public static heightDiamInFtToBushels(
    diameter_ft: number,
    height_ft: number,
    precision = null
  ): number {
    return MathUtils.toPrecision(
      0.8036 * MathUtils.binVolumeInFt(diameter_ft, height_ft),
      precision
    );
  }

  public static binVolumeInFt(diameter_ft: number, height_ft: number, precision = null): number {
    return MathUtils.toPrecision(
      MathUtils.circleAreaInFt(diameter_ft, precision) * height_ft,
      precision
    );
  }

  public static circleAreaInFt(diameter_ft: number, precision = null): number {
    return MathUtils.toPrecision((22 / 7) * Math.pow(diameter_ft / 2, 2), precision);
  }

  public static average(numbers: number[], precision = null): number {
    return MathUtils.toPrecision(numbers.reduce((n1, n2) => n1 + n2) / numbers.length, precision);
  }

  private static toPrecision(result, precision = null): number {
    if (precision) {
      return result.toPrecision(precision);
    }
    return result;
  }

  public static toFixed(result: number, digits_after_decimal: number): number {
    return Number(result.toFixed(digits_after_decimal));
  }

  public static secondsToMinute(seconds: number, precision = null): number {
    return MathUtils.toPrecision(seconds / 60);
  }
  public static secondsToHour(seconds: number, precision = null): number {
    return MathUtils.toPrecision(MathUtils.secondsToMinute(seconds, precision) / 60);
  }
}
