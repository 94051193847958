import * as React from 'react';
export const DashCircleIcon = (props) => (
  <svg id="dash circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501 501" {...props}>
    <defs>
      <style>{'.dash-circle-cls-1{stroke-miterlimit:10;}.dash-circle-cls-2{fill:#222632;}'}</style>
    </defs>
    <rect className="dash-circle-cls-1" x={0.5} y={0.5} width={500} height={500} fill="none" />
    <g>
      <g id="_Radial_Repeat_">
        <path
          id="middle-2"
          className="dash-circle-cls-2"
          d="m9.71,250.71v-.39c0-.6.15-1.17.42-1.67.6-1.11,1.77-1.86,3.11-1.86h5.08c2.38,0,5.96,0,8.34,0h5.08c1.35,0,2.52.75,3.11,1.86.27.5.42,1.06.42,1.67v.39c0,.6-.15,1.17-.42,1.67-.6,1.11-1.77,1.86-3.11,1.86h-5.08c-2.38,0-5.96,0-8.34,0h-5.08c-1.35,0-2.52-.75-3.11-1.86-.27-.5-.42-1.06-.42-1.67Z"
        />
      </g>
      <g id="_Radial_Repeat_-2">
        <path
          id="middle-2-2"
          className="dash-circle-cls-2"
          d="m10.44,231.82l.03-.39c.05-.6.24-1.16.55-1.63.68-1.06,1.91-1.72,3.25-1.61l5.07.4,8.32.65,5.07.4c1.34.11,2.45.95,2.96,2.1.23.52.33,1.09.29,1.7l-.03.39c-.05.6-.24,1.16-.55,1.63-.68,1.06-1.91,1.72-3.25,1.61l-5.07-.4-8.32-.65-5.07-.4c-1.34-.11-2.45-.95-2.96-2.1-.23-.52-.33-1.09-.29-1.7Z"
        />
      </g>
      <g id="_Radial_Repeat_-3">
        <path
          id="middle-2-3"
          className="dash-circle-cls-2"
          d="m12.65,213.04l.06-.39c.09-.6.33-1.13.67-1.58.76-1,2.04-1.56,3.37-1.35l5.02.8,8.24,1.3,5.02.8c1.33.21,2.37,1.14,2.78,2.33.19.53.25,1.12.15,1.71l-.06.39c-.09.6-.33,1.13-.67,1.58-.76,1-2.04,1.56-3.37,1.35l-5.02-.8-8.24-1.3-5.02-.8c-1.33-.21-2.37-1.14-2.78-2.33-.19-.53-.25-1.12-.15-1.71Z"
        />
      </g>
      <g id="_Radial_Repeat_-4">
        <path
          id="middle-2-4"
          className="dash-circle-cls-2"
          d="m16.32,194.49l.09-.38c.14-.59.42-1.1.8-1.52.84-.94,2.15-1.4,3.46-1.08l4.94,1.19,8.11,1.95,4.94,1.19c1.31.31,2.27,1.32,2.59,2.54.14.55.16,1.13.02,1.72l-.09.38c-.14.59-.42,1.1-.8,1.52-.84.94-2.15,1.4-3.46,1.08l-4.94-1.19-8.11-1.95-4.94-1.19c-1.31-.31-2.27-1.32-2.59-2.54-.14-.55-.16-1.13-.02-1.72Z"
        />
      </g>
      <g id="_Radial_Repeat_-5">
        <path
          id="middle-2-5"
          className="dash-circle-cls-2"
          d="m21.43,176.29l.12-.37c.19-.57.51-1.07.91-1.46.91-.87,2.25-1.23,3.54-.81l4.84,1.57,7.93,2.58,4.84,1.57c1.28.42,2.16,1.49,2.38,2.73.1.55.07,1.14-.12,1.72l-.12.37c-.19.57-.51,1.07-.91,1.46-.91.87-2.25,1.23-3.54.81l-4.84-1.57-7.93-2.58-4.84-1.57c-1.28-.42-2.16-1.49-2.38-2.73-.1-.55-.07-1.14.12-1.72Z"
        />
      </g>
      <g id="_Radial_Repeat_-6">
        <path
          id="middle-2-6"
          className="dash-circle-cls-2"
          d="m27.96,158.55l.15-.36c.23-.56.59-1.02,1.02-1.38.97-.8,2.34-1.05,3.59-.53l4.7,1.95,7.71,3.19,4.7,1.95c1.24.52,2.04,1.66,2.16,2.91.06.56-.02,1.14-.25,1.7l-.15.36c-.23.56-.59,1.02-1.02,1.38-.97.8-2.34,1.05-3.59.53l-4.7-1.95-7.71-3.19-4.7-1.95c-1.24-.52-2.04-1.66-2.16-2.91-.06-.56.02-1.14.25-1.7Z"
        />
      </g>
      <g id="_Radial_Repeat_-7">
        <path
          id="middle-2-7"
          className="dash-circle-cls-2"
          d="m35.86,141.37l.18-.35c.27-.54.67-.97,1.13-1.3,1.03-.72,2.42-.86,3.62-.25l4.53,2.31,7.43,3.79,4.53,2.31c1.2.61,1.9,1.81,1.93,3.07.01.56-.11,1.14-.38,1.68l-.18.35c-.27.54-.67.97-1.13,1.3-1.03.72-2.42.86-3.62.25l-4.53-2.31-7.43-3.79-4.53-2.31c-1.2-.61-1.9-1.81-1.93-3.07-.01-.56.11-1.14.38-1.68Z"
        />
      </g>
      <g id="_Radial_Repeat_-8">
        <path
          id="middle-2-8"
          className="dash-circle-cls-2"
          d="m45.09,124.87l.2-.33c.32-.51.74-.92,1.23-1.2,1.09-.63,2.48-.67,3.63.04l4.34,2.66,7.11,4.36,4.34,2.66c1.15.7,1.75,1.96,1.68,3.21-.03.56-.2,1.13-.51,1.64l-.2.33c-.32.51-.74.92-1.23,1.2-1.09.63-2.48.67-3.63-.04l-4.34-2.66-7.11-4.36-4.34-2.66c-1.15-.7-1.75-1.96-1.68-3.21.03-.56.2-1.13.51-1.64Z"
        />
      </g>
      <g id="_Radial_Repeat_-9">
        <path
          id="middle-2-9"
          className="dash-circle-cls-2"
          d="m55.58,109.14l.23-.32c.35-.49.81-.86,1.32-1.1,1.13-.55,2.52-.47,3.61.32l4.11,2.99,6.75,4.9,4.11,2.99c1.09.79,1.59,2.09,1.42,3.34-.08.56-.29,1.11-.64,1.59l-.23.32c-.35.49-.81.86-1.32,1.1-1.13.55-2.52.47-3.61-.32l-4.11-2.99-6.75-4.9-4.11-2.99c-1.09-.79-1.59-2.09-1.42-3.34.08-.56.29-1.11.64-1.59Z"
        />
      </g>
      <g id="_Radial_Repeat_-10">
        <path
          id="middle-2-10"
          className="dash-circle-cls-2"
          d="m67.27,94.28l.25-.3c.39-.46.88-.79,1.4-1,1.17-.46,2.55-.27,3.58.6l3.87,3.3,6.34,5.42,3.87,3.3c1.02.87,1.42,2.21,1.16,3.44-.12.55-.37,1.08-.77,1.54l-.25.3c-.39.46-.88.79-1.4,1-1.17.46-2.55.27-3.58-.6l-3.87-3.3-6.34-5.42-3.87-3.3c-1.02-.87-1.42-2.21-1.16-3.44.12-.55.37-1.08.77-1.54Z"
        />
      </g>
      <g id="_Radial_Repeat_-11">
        <path
          id="middle-2-11"
          className="dash-circle-cls-2"
          d="m80.09,80.39l.28-.28c.43-.43.93-.72,1.47-.88,1.2-.36,2.57-.07,3.52.88l3.6,3.6,5.9,5.9,3.6,3.6c.95.95,1.25,2.31.88,3.52-.16.54-.46,1.05-.88,1.47l-.28.28c-.43.43-.93.72-1.47.88-1.2.36-2.57.07-3.52-.88l-3.6-3.6-5.9-5.9-3.6-3.6c-.95-.95-1.25-2.31-.88-3.52.16-.54.46-1.05.88-1.47Z"
        />
      </g>
      <g id="_Radial_Repeat_-12">
        <path
          id="middle-2-12"
          className="dash-circle-cls-2"
          d="m93.96,67.54l.3-.25c.46-.39.99-.65,1.54-.77,1.23-.27,2.56.13,3.44,1.16l3.3,3.87,5.42,6.34,3.3,3.87c.87,1.02,1.06,2.4.61,3.58-.2.53-.54,1.01-1,1.4l-.3.25c-.46.39-.99.65-1.54.77-1.23.27-2.56-.13-3.44-1.16l-3.3-3.87-5.42-6.34-3.3-3.87c-.87-1.02-1.06-2.4-.61-3.58.2-.53.54-1.01,1-1.4Z"
        />
      </g>
      <g id="_Radial_Repeat_-13">
        <path
          id="middle-2-13"
          className="dash-circle-cls-2"
          d="m108.8,55.82l.32-.23c.49-.35,1.04-.57,1.59-.64,1.25-.17,2.54.33,3.34,1.42l2.99,4.11,4.9,6.75,2.99,4.11c.79,1.09.87,2.48.32,3.61-.24.51-.62.96-1.1,1.32l-.32.23c-.49.35-1.04.57-1.59.64-1.25.17-2.54-.33-3.34-1.42l-2.99-4.11-4.9-6.75-2.99-4.11c-.79-1.09-.87-2.48-.32-3.61.24-.51.62-.96,1.1-1.32Z"
        />
      </g>
      <g id="_Radial_Repeat_-14">
        <path
          id="middle-2-14"
          className="dash-circle-cls-2"
          d="m124.51,45.31l.33-.2c.51-.32,1.08-.48,1.64-.52,1.26-.07,2.51.53,3.21,1.68l2.66,4.34,4.36,7.11,2.66,4.34c.7,1.15.67,2.54.04,3.63-.28.49-.69.91-1.2,1.23l-.33.2c-.51.32-1.08.48-1.64.52-1.26.07-2.51-.53-3.21-1.68l-2.66-4.34-4.36-7.11-2.66-4.34c-.7-1.15-.67-2.54-.04-3.63.28-.49.69-.91,1.2-1.23Z"
        />
      </g>
      <g id="_Radial_Repeat_-15">
        <path
          id="middle-2-15"
          className="dash-circle-cls-2"
          d="m141,36.05l.35-.18c.54-.27,1.11-.4,1.68-.38,1.26.03,2.46.73,3.07,1.93l2.31,4.53,3.79,7.43,2.31,4.53c.61,1.2.47,2.58-.25,3.62-.32.46-.76.86-1.3,1.13l-.35.18c-.54.27-1.11.4-1.68.38-1.26-.03-2.46-.73-3.07-1.93l-2.31-4.53-3.79-7.43-2.31-4.53c-.61-1.2-.47-2.58.25-3.62.32-.46.76-.86,1.3-1.13Z"
        />
      </g>
      <g id="_Radial_Repeat_-16">
        <path
          id="middle-2-16"
          className="dash-circle-cls-2"
          d="m158.16,28.12l.36-.15c.56-.23,1.14-.31,1.7-.25,1.25.13,2.4.92,2.91,2.16l1.95,4.7,3.19,7.71,1.95,4.7c.52,1.24.27,2.61-.53,3.59-.36.44-.82.79-1.38,1.02l-.36.15c-.56.23-1.14.31-1.7.25-1.25-.13-2.4-.92-2.91-2.16l-1.95-4.7-3.19-7.71-1.95-4.7c-.52-1.24-.27-2.61.53-3.59.36-.44.82-.79,1.38-1.02Z"
        />
      </g>
      <g id="_Radial_Repeat_-17">
        <path
          id="middle-2-17"
          className="dash-circle-cls-2"
          d="m175.89,21.56l.37-.12c.57-.19,1.16-.22,1.72-.12,1.24.22,2.32,1.1,2.73,2.38l1.57,4.84,2.58,7.93,1.57,4.84c.42,1.28.06,2.63-.81,3.54-.39.41-.88.73-1.46.91l-.37.12c-.57.19-1.16.22-1.72.12-1.24-.22-2.32-1.1-2.73-2.38l-1.57-4.84-2.58-7.93-1.57-4.84c-.42-1.28-.06-2.63.81-3.54.39-.41.88-.73,1.46-.91Z"
        />
      </g>
      <g id="_Radial_Repeat_-18">
        <path
          id="middle-2-18"
          className="dash-circle-cls-2"
          d="m194.09,16.42l.38-.09c.59-.14,1.17-.13,1.72.02,1.22.32,2.22,1.28,2.54,2.59l1.19,4.94,1.95,8.11,1.19,4.94c.31,1.31-.15,2.62-1.08,3.46-.42.38-.94.65-1.52.8l-.38.09c-.59.14-1.17.13-1.72-.02-1.22-.32-2.22-1.28-2.54-2.59l-1.19-4.94-1.95-8.11-1.19-4.94c-.31-1.31.15-2.62,1.08-3.46.42-.38.94-.65,1.52-.8Z"
        />
      </g>
      <g id="_Radial_Repeat_-19">
        <path
          id="middle-2-19"
          className="dash-circle-cls-2"
          d="m212.63,12.71l.39-.06c.6-.09,1.18-.03,1.71.15,1.19.41,2.12,1.45,2.33,2.78l.8,5.02,1.31,8.24.8,5.02c.21,1.33-.35,2.6-1.35,3.37-.45.34-.99.58-1.58.67l-.39.06c-.6.09-1.18.03-1.71-.15-1.19-.41-2.12-1.45-2.33-2.78l-.8-5.02-1.31-8.24-.8-5.02c-.21-1.33.35-2.6,1.35-3.37.45-.34.99-.58,1.58-.67Z"
        />
      </g>
      <g id="_Radial_Repeat_-20">
        <path
          id="middle-2-20"
          className="dash-circle-cls-2"
          d="m231.4,10.47l.39-.03c.6-.05,1.18.06,1.7.29,1.15.51,2,1.61,2.1,2.96l.4,5.07.66,8.32.4,5.07c.11,1.34-.55,2.57-1.61,3.25-.47.3-1.03.5-1.63.55l-.39.03c-.6.05-1.18-.06-1.7-.29-1.15-.51-2-1.61-2.1-2.96l-.4-5.07-.66-8.32-.4-5.07c-.11-1.34.55-2.57,1.61-3.25.47-.3,1.03-.5,1.63-.55Z"
        />
      </g>
      <g id="_Radial_Repeat_-21">
        <path
          id="middle-2-21"
          className="dash-circle-cls-2"
          d="m250.29,9.71h.39c.6,0,1.17.15,1.67.42,1.11.6,1.86,1.77,1.86,3.11v5.08c0,2.38,0,5.96,0,8.34v5.08c0,1.35-.75,2.52-1.86,3.11-.5.27-1.06.42-1.67.42h-.39c-.6,0-1.17-.15-1.67-.42-1.11-.6-1.86-1.77-1.86-3.11v-5.08c0-2.38,0-5.96,0-8.34v-5.08c0-1.35.75-2.52,1.86-3.11.5-.27,1.06-.42,1.67-.42Z"
        />
      </g>
      <g id="_Radial_Repeat_-22">
        <path
          id="middle-2-22"
          className="dash-circle-cls-2"
          d="m269.18,10.44l.39.03c.6.05,1.16.24,1.63.55,1.06.68,1.72,1.91,1.61,3.25l-.4,5.07-.65,8.32-.4,5.07c-.11,1.34-.95,2.45-2.1,2.96-.52.23-1.09.33-1.7.29l-.39-.03c-.6-.05-1.16-.24-1.63-.55-1.06-.68-1.72-1.91-1.61-3.25l.4-5.07.65-8.32.4-5.07c.11-1.34.95-2.45,2.1-2.96.52-.23,1.09-.33,1.7-.29Z"
        />
      </g>
      <g id="_Radial_Repeat_-23">
        <path
          id="middle-2-23"
          className="dash-circle-cls-2"
          d="m287.96,12.65l.39.06c.6.09,1.13.33,1.58.67,1,.76,1.56,2.04,1.35,3.37l-.8,5.02c-.37,2.35-.93,5.89-1.3,8.24l-.8,5.02c-.21,1.33-1.14,2.37-2.33,2.78-.53.19-1.12.25-1.71.15l-.39-.06c-.6-.09-1.13-.33-1.58-.67-1-.76-1.56-2.04-1.35-3.37l.8-5.02c.37-2.35.93-5.89,1.3-8.24l.8-5.02c.21-1.33,1.14-2.37,2.33-2.78.53-.19,1.12-.25,1.71-.15Z"
        />
      </g>
      <g id="_Radial_Repeat_-24">
        <path
          id="middle-2-24"
          className="dash-circle-cls-2"
          d="m306.51,16.32l.38.09c.59.14,1.1.42,1.52.8.94.84,1.4,2.15,1.08,3.46l-1.19,4.94-1.95,8.11-1.19,4.94c-.31,1.31-1.32,2.27-2.54,2.59-.55.14-1.13.16-1.72.02l-.38-.09c-.59-.14-1.1-.42-1.52-.8-.94-.84-1.4-2.15-1.08-3.46l1.19-4.94,1.95-8.11,1.19-4.94c.31-1.31,1.32-2.27,2.54-2.59.55-.14,1.13-.16,1.72-.02Z"
        />
      </g>
      <g id="_Radial_Repeat_-25">
        <path
          id="middle-2-25"
          className="dash-circle-cls-2"
          d="m324.71,21.43l.37.12c.57.19,1.07.51,1.46.91.87.91,1.23,2.25.81,3.54l-1.57,4.84-2.58,7.93-1.57,4.84c-.42,1.28-1.49,2.16-2.73,2.38-.55.1-1.14.07-1.72-.12l-.37-.12c-.57-.19-1.07-.51-1.46-.91-.87-.91-1.23-2.25-.81-3.54l1.57-4.84,2.58-7.93,1.57-4.84c.42-1.28,1.49-2.16,2.73-2.38.55-.1,1.14-.07,1.72.12Z"
        />
      </g>
      <g id="_Radial_Repeat_-26">
        <path
          id="middle-2-26"
          className="dash-circle-cls-2"
          d="m342.45,27.96l.36.15c.56.23,1.02.59,1.38,1.02.8.97,1.05,2.34.53,3.59l-1.95,4.7c-.91,2.2-2.28,5.51-3.19,7.71l-1.95,4.7c-.52,1.24-1.66,2.04-2.91,2.16-.56.06-1.14-.02-1.7-.25l-.36-.15c-.56-.23-1.02-.59-1.38-1.02-.8-.97-1.05-2.34-.53-3.59l1.95-4.7,3.19-7.71,1.95-4.7c.52-1.24,1.66-2.04,2.91-2.16.56-.06,1.14.02,1.7.25Z"
        />
      </g>
      <g id="_Radial_Repeat_-27">
        <path
          id="middle-2-27"
          className="dash-circle-cls-2"
          d="m359.63,35.86l.35.18c.54.27.97.67,1.3,1.13.72,1.03.86,2.42.25,3.62l-2.31,4.53-3.79,7.43-2.31,4.53c-.61,1.2-1.81,1.9-3.07,1.93-.56.01-1.14-.11-1.68-.38l-.35-.18c-.54-.27-.97-.67-1.3-1.13-.72-1.03-.86-2.42-.25-3.62l2.31-4.53,3.79-7.43,2.31-4.53c.61-1.2,1.81-1.9,3.07-1.93.56-.01,1.14.11,1.68.38Z"
        />
      </g>
      <g id="_Radial_Repeat_-28">
        <path
          id="middle-2-28"
          className="dash-circle-cls-2"
          d="m376.13,45.09l.33.2c.51.32.92.74,1.2,1.23.63,1.09.67,2.48-.04,3.63l-2.66,4.34-4.36,7.11-2.66,4.34c-.7,1.15-1.96,1.75-3.21,1.68-.56-.03-1.13-.2-1.64-.51l-.33-.2c-.51-.32-.92-.74-1.2-1.23-.63-1.09-.67-2.48.04-3.63l2.66-4.34,4.36-7.11,2.66-4.34c.7-1.15,1.96-1.75,3.21-1.68.56.03,1.13.2,1.64.51Z"
        />
      </g>
      <g id="_Radial_Repeat_-29">
        <path
          id="middle-2-29"
          className="dash-circle-cls-2"
          d="m391.86,55.58l.32.23c.49.35.86.81,1.1,1.32.55,1.13.47,2.52-.32,3.61l-2.99,4.11-4.9,6.75-2.99,4.11c-.79,1.09-2.09,1.59-3.34,1.42-.56-.08-1.11-.29-1.59-.64l-.32-.23c-.49-.35-.86-.81-1.1-1.32-.55-1.13-.47-2.52.32-3.61l2.99-4.11,4.9-6.75,2.99-4.11c.79-1.09,2.09-1.59,3.34-1.42.56.08,1.11.29,1.59.64Z"
        />
      </g>
      <g id="_Radial_Repeat_-30">
        <path
          id="middle-2-30"
          className="dash-circle-cls-2"
          d="m406.72,67.27l.3.25c.46.39.79.88,1,1.4.46,1.17.27,2.55-.6,3.58l-3.3,3.87-5.42,6.34-3.3,3.87c-.87,1.02-2.21,1.42-3.44,1.16-.55-.12-1.08-.37-1.54-.77l-.3-.25c-.46-.39-.79-.88-1-1.4-.46-1.17-.27-2.55.6-3.58l3.3-3.87,5.42-6.34,3.3-3.87c.87-1.02,2.21-1.42,3.44-1.16.55.12,1.08.37,1.54.77Z"
        />
      </g>
      <g id="_Radial_Repeat_-31">
        <path
          id="middle-2-31"
          className="dash-circle-cls-2"
          d="m420.61,80.09l.28.28c.43.43.72.93.88,1.47.36,1.2.07,2.57-.88,3.52l-3.6,3.6-5.9,5.9-3.6,3.6c-.95.95-2.31,1.25-3.52.88-.54-.16-1.05-.46-1.47-.88l-.28-.28c-.43-.43-.72-.93-.88-1.47-.36-1.2-.07-2.57.88-3.52l3.6-3.6,5.9-5.9,3.6-3.6c.95-.95,2.31-1.25,3.52-.88.54.16,1.05.46,1.47.88Z"
        />
      </g>
      <g id="_Radial_Repeat_-32">
        <path
          id="middle-2-32"
          className="dash-circle-cls-2"
          d="m433.46,93.96l.25.3c.39.46.65.99.77,1.54.27,1.23-.13,2.56-1.16,3.44l-3.87,3.3-6.34,5.42-3.87,3.3c-1.02.87-2.4,1.06-3.58.61-.53-.2-1.01-.54-1.4-1l-.25-.3c-.39-.46-.65-.99-.77-1.54-.27-1.23.13-2.56,1.16-3.44l3.87-3.3,6.34-5.42,3.87-3.3c1.02-.87,2.4-1.06,3.58-.61.53.2,1.01.54,1.4,1Z"
        />
      </g>
      <g id="_Radial_Repeat_-33">
        <path
          id="middle-2-33"
          className="dash-circle-cls-2"
          d="m445.18,108.8l.23.32c.35.49.57,1.04.64,1.59.17,1.25-.33,2.54-1.42,3.34l-4.11,2.99-6.75,4.9-4.11,2.99c-1.09.79-2.48.87-3.61.32-.51-.24-.96-.62-1.32-1.1l-.23-.32c-.35-.49-.57-1.04-.64-1.59-.17-1.25.33-2.54,1.42-3.34l4.11-2.99,6.75-4.9,4.11-2.99c1.09-.79,2.48-.87,3.61-.32.51.24.96.62,1.32,1.1Z"
        />
      </g>
      <g id="_Radial_Repeat_-34">
        <path
          id="middle-2-34"
          className="dash-circle-cls-2"
          d="m455.69,124.51l.2.33c.32.51.48,1.08.52,1.64.07,1.26-.53,2.51-1.68,3.21l-4.34,2.66-7.11,4.36-4.34,2.66c-1.15.7-2.54.67-3.63.04-.49-.28-.91-.69-1.23-1.2l-.2-.33c-.32-.51-.48-1.08-.52-1.64-.07-1.26.53-2.51,1.68-3.21l4.34-2.66,7.11-4.36,4.34-2.66c1.15-.7,2.54-.67,3.63-.04.49.28.91.69,1.23,1.2Z"
        />
      </g>
      <g id="_Radial_Repeat_-35">
        <path
          id="middle-2-35"
          className="dash-circle-cls-2"
          d="m464.95,141l.18.35c.27.54.4,1.11.38,1.68-.03,1.26-.73,2.46-1.93,3.07l-4.53,2.31-7.43,3.79-4.53,2.31c-1.2.61-2.58.47-3.62-.25-.46-.32-.86-.76-1.13-1.3l-.18-.35c-.27-.54-.4-1.11-.38-1.68.03-1.26.73-2.46,1.93-3.07l4.53-2.31,7.43-3.79,4.53-2.31c1.2-.61,2.58-.47,3.62.25.46.32.86.76,1.13,1.3Z"
        />
      </g>
      <g id="_Radial_Repeat_-36">
        <path
          id="middle-2-36"
          className="dash-circle-cls-2"
          d="m472.88,158.16l.15.36c.23.56.31,1.14.25,1.7-.13,1.25-.92,2.4-2.16,2.91l-4.7,1.95-7.71,3.19-4.7,1.95c-1.24.52-2.61.27-3.59-.53-.44-.36-.79-.82-1.02-1.38l-.15-.36c-.23-.56-.31-1.14-.25-1.7.13-1.25.92-2.4,2.16-2.91l4.7-1.95,7.71-3.19,4.7-1.95c1.24-.52,2.61-.27,3.59.53.44.36.79.82,1.02,1.38Z"
        />
      </g>
      <g id="_Radial_Repeat_-37">
        <path
          id="middle-2-37"
          className="dash-circle-cls-2"
          d="m479.44,175.89l.12.37c.19.57.22,1.16.12,1.72-.22,1.24-1.1,2.32-2.38,2.73l-4.84,1.57-7.93,2.58-4.84,1.57c-1.28.42-2.63.06-3.54-.81-.41-.39-.73-.88-.91-1.46l-.12-.37c-.19-.57-.22-1.16-.12-1.72.22-1.24,1.1-2.32,2.38-2.73l4.84-1.57,7.93-2.58,4.84-1.57c1.28-.42,2.63-.06,3.54.81.41.39.73.88.91,1.46Z"
        />
      </g>
      <g id="_Radial_Repeat_-38">
        <path
          id="middle-2-38"
          className="dash-circle-cls-2"
          d="m484.58,194.09l.09.38c.14.59.13,1.17-.02,1.72-.32,1.22-1.28,2.22-2.59,2.54l-4.94,1.19-8.11,1.95-4.94,1.19c-1.31.31-2.62-.15-3.46-1.08-.38-.42-.65-.94-.8-1.52l-.09-.38c-.14-.59-.13-1.17.02-1.72.32-1.22,1.28-2.22,2.59-2.54l4.94-1.19,8.11-1.95,4.94-1.19c1.31-.31,2.62.15,3.46,1.08.38.42.65.94.8,1.52Z"
        />
      </g>
      <g id="_Radial_Repeat_-39">
        <path
          id="middle-2-39"
          className="dash-circle-cls-2"
          d="m488.29,212.63l.06.39c.09.6.03,1.18-.15,1.71-.41,1.19-1.45,2.12-2.78,2.33l-5.02.8-8.24,1.31-5.02.8c-1.33.21-2.6-.35-3.37-1.35-.34-.45-.58-.99-.67-1.58l-.06-.39c-.09-.6-.03-1.18.15-1.71.41-1.19,1.45-2.12,2.78-2.33l5.02-.8,8.24-1.31,5.02-.8c1.33-.21,2.6.35,3.37,1.35.34.45.58.99.67,1.58Z"
        />
      </g>
      <g id="_Radial_Repeat_-40">
        <path
          id="middle-2-40"
          className="dash-circle-cls-2"
          d="m490.53,231.4l.03.39c.05.6-.06,1.18-.29,1.7-.51,1.15-1.61,2-2.96,2.1l-5.07.4-8.32.66-5.07.4c-1.34.11-2.57-.55-3.25-1.61-.3-.47-.5-1.03-.55-1.63l-.03-.39c-.05-.6.06-1.18.29-1.7.51-1.15,1.61-2,2.96-2.1l5.07-.4,8.32-.66,5.07-.4c1.34-.11,2.57.55,3.25,1.61.3.47.5,1.03.55,1.63Z"
        />
      </g>
      <g id="_Radial_Repeat_-41">
        <path
          id="middle-2-41"
          className="dash-circle-cls-2"
          d="m491.29,250.29v.39c0,.6-.15,1.17-.42,1.67-.6,1.11-1.77,1.86-3.11,1.86h-5.08c-2.38,0-5.96,0-8.34,0h-5.08c-1.35,0-2.52-.75-3.11-1.86-.27-.5-.42-1.06-.42-1.67v-.39c0-.6.15-1.17.42-1.67.6-1.11,1.77-1.86,3.11-1.86h5.08c2.38,0,5.96,0,8.34,0h5.08c1.35,0,2.52.75,3.11,1.86.27.5.42,1.06.42,1.67Z"
        />
      </g>
      <g id="_Radial_Repeat_-42">
        <path
          id="middle-2-42"
          className="dash-circle-cls-2"
          d="m490.56,269.18l-.03.39c-.05.6-.24,1.16-.55,1.63-.68,1.06-1.91,1.72-3.25,1.61l-5.07-.4-8.32-.65-5.07-.4c-1.34-.11-2.45-.95-2.96-2.1-.23-.52-.33-1.09-.29-1.7l.03-.39c.05-.6.24-1.16.55-1.63.68-1.06,1.91-1.72,3.25-1.61l5.07.4,8.32.65,5.07.4c1.34.11,2.45.95,2.96,2.1.23.52.33,1.09.29,1.7Z"
        />
      </g>
      <g id="_Radial_Repeat_-43">
        <path
          id="middle-2-43"
          className="dash-circle-cls-2"
          d="m488.36,287.96l-.06.39c-.09.6-.33,1.13-.67,1.58-.76,1-2.04,1.56-3.37,1.35l-5.02-.8c-2.35-.37-5.89-.93-8.24-1.3l-5.02-.8c-1.33-.21-2.37-1.14-2.78-2.33-.19-.53-.25-1.12-.15-1.71l.06-.39c.09-.6.33-1.13.67-1.58.76-1,2.04-1.56,3.37-1.35l5.02.8c2.35.37,5.89.93,8.24,1.3l5.02.8c1.33.21,2.37,1.14,2.78,2.33.19.53.25,1.12.15,1.71Z"
        />
      </g>
      <g id="_Radial_Repeat_-44">
        <path
          id="middle-2-44"
          className="dash-circle-cls-2"
          d="m484.68,306.51l-.09.38c-.14.59-.42,1.1-.8,1.52-.84.94-2.15,1.4-3.46,1.08l-4.94-1.19-8.11-1.95-4.94-1.19c-1.31-.31-2.27-1.32-2.59-2.54-.14-.55-.16-1.13-.02-1.72l.09-.38c.14-.59.42-1.1.8-1.52.84-.94,2.15-1.4,3.46-1.08l4.94,1.19,8.11,1.95,4.94,1.19c1.31.31,2.27,1.32,2.59,2.54.14.55.16,1.13.02,1.72Z"
        />
      </g>
      <g id="_Radial_Repeat_-45">
        <path
          id="middle-2-45"
          className="dash-circle-cls-2"
          d="m479.57,324.71l-.12.37c-.19.57-.51,1.07-.91,1.46-.91.87-2.25,1.23-3.54.81l-4.84-1.57-7.93-2.58-4.84-1.57c-1.28-.42-2.16-1.49-2.38-2.73-.1-.55-.07-1.14.12-1.72l.12-.37c.19-.57.51-1.07.91-1.46.91-.87,2.25-1.23,3.54-.81l4.84,1.57,7.93,2.58,4.84,1.57c1.28.42,2.16,1.49,2.38,2.73.1.55.07,1.14-.12,1.72Z"
        />
      </g>
      <g id="_Radial_Repeat_-46">
        <path
          id="middle-2-46"
          className="dash-circle-cls-2"
          d="m473.04,342.45l-.15.36c-.23.56-.59,1.02-1.02,1.38-.97.8-2.34,1.05-3.59.53l-4.7-1.95-7.71-3.19-4.7-1.95c-1.24-.52-2.04-1.66-2.16-2.91-.06-.56.02-1.14.25-1.7l.15-.36c.23-.56.59-1.02,1.02-1.38.97-.8,2.34-1.05,3.59-.53l4.7,1.95c2.2.91,5.51,2.28,7.71,3.19l4.7,1.95c1.24.52,2.04,1.66,2.16,2.91.06.56-.02,1.14-.25,1.7Z"
        />
      </g>
      <g id="_Radial_Repeat_-47">
        <path
          id="middle-2-47"
          className="dash-circle-cls-2"
          d="m465.14,359.63l-.18.35c-.27.54-.67.97-1.13,1.3-1.03.72-2.42.86-3.62.25l-4.53-2.31-7.43-3.79-4.53-2.31c-1.2-.61-1.9-1.81-1.93-3.07-.01-.56.11-1.14.38-1.68l.18-.35c.27-.54.67-.97,1.13-1.3,1.03-.72,2.42-.86,3.62-.25l4.53,2.31,7.43,3.79,4.53,2.31c1.2.61,1.9,1.81,1.93,3.07.01.56-.11,1.14-.38,1.68Z"
        />
      </g>
      <g id="_Radial_Repeat_-48">
        <path
          id="middle-2-48"
          className="dash-circle-cls-2"
          d="m455.91,376.13l-.2.33c-.32.51-.74.92-1.23,1.2-1.09.63-2.48.67-3.63-.04l-4.34-2.66-7.11-4.36-4.34-2.66c-1.15-.7-1.75-1.96-1.68-3.21.03-.56.2-1.13.51-1.64l.2-.33c.32-.51.74-.92,1.23-1.2,1.09-.63,2.48-.67,3.63.04l4.34,2.66,7.11,4.36,4.34,2.66c1.15.7,1.75,1.96,1.68,3.21-.03.56-.2,1.13-.51,1.64Z"
        />
      </g>
      <g id="_Radial_Repeat_-49">
        <path
          id="middle-2-49"
          className="dash-circle-cls-2"
          d="m445.42,391.86l-.23.32c-.35.49-.81.86-1.32,1.1-1.13.55-2.52.47-3.61-.32l-4.11-2.99c-1.92-1.4-4.82-3.5-6.75-4.9l-4.11-2.99c-1.09-.79-1.59-2.09-1.42-3.34.08-.56.29-1.11.64-1.59l.23-.32c.35-.49.81-.86,1.32-1.1,1.13-.55,2.52-.47,3.61.32l4.11,2.99c1.92,1.4,4.82,3.5,6.75,4.9l4.11,2.99c1.09.79,1.59,2.09,1.42,3.34-.08.56-.29,1.11-.64,1.59Z"
        />
      </g>
      <g id="_Radial_Repeat_-50">
        <path
          id="middle-2-50"
          className="dash-circle-cls-2"
          d="m433.73,406.72l-.25.3c-.39.46-.88.79-1.4,1-1.17.46-2.55.27-3.58-.6l-3.87-3.3-6.34-5.42-3.87-3.3c-1.02-.87-1.42-2.21-1.16-3.44.12-.55.37-1.08.77-1.54l.25-.3c.39-.46.88-.79,1.4-1,1.17-.46,2.55-.27,3.58.6l3.87,3.3c1.81,1.55,4.53,3.87,6.34,5.42l3.87,3.3c1.02.87,1.42,2.21,1.16,3.44-.12.55-.37,1.08-.77,1.54Z"
        />
      </g>
      <g id="_Radial_Repeat_-51">
        <path
          id="middle-2-51"
          className="dash-circle-cls-2"
          d="m420.91,420.61l-.28.28c-.43.43-.93.72-1.47.88-1.2.36-2.57.07-3.52-.88l-3.6-3.6-5.9-5.9-3.6-3.6c-.95-.95-1.25-2.31-.88-3.52.16-.54.46-1.05.88-1.47l.28-.28c.43-.43.93-.72,1.47-.88,1.2-.36,2.57-.07,3.52.88l3.6,3.6,5.9,5.9,3.6,3.6c.95.95,1.25,2.31.88,3.52-.16.54-.46,1.05-.88,1.47Z"
        />
      </g>
      <g id="_Radial_Repeat_-52">
        <path
          id="middle-2-52"
          className="dash-circle-cls-2"
          d="m407.04,433.46l-.3.25c-.46.39-.99.65-1.54.77-1.23.27-2.56-.13-3.44-1.16l-3.3-3.87-5.42-6.34-3.3-3.87c-.87-1.02-1.06-2.4-.61-3.58.2-.53.54-1.01,1-1.4l.3-.25c.46-.39.99-.65,1.54-.77,1.23-.27,2.56.13,3.44,1.16l3.3,3.87,5.42,6.34,3.3,3.87c.87,1.02,1.06,2.4.61,3.58-.2.53-.54,1.01-1,1.4Z"
        />
      </g>
      <g id="_Radial_Repeat_-53">
        <path
          id="middle-2-53"
          className="dash-circle-cls-2"
          d="m392.2,445.18l-.32.23c-.49.35-1.04.57-1.59.64-1.25.17-2.54-.33-3.34-1.42l-2.99-4.11-4.9-6.75-2.99-4.11c-.79-1.09-.87-2.48-.32-3.61.24-.51.62-.96,1.1-1.32l.32-.23c.49-.35,1.04-.57,1.59-.64,1.25-.17,2.54.33,3.34,1.42l2.99,4.11,4.9,6.75,2.99,4.11c.79,1.09.87,2.48.32,3.61-.24.51-.62.96-1.1,1.32Z"
        />
      </g>
      <g id="_Radial_Repeat_-54">
        <path
          id="middle-2-54"
          className="dash-circle-cls-2"
          d="m376.49,455.69l-.33.2c-.51.32-1.08.48-1.64.52-1.26.07-2.51-.53-3.21-1.68l-2.66-4.34-4.36-7.11-2.66-4.34c-.7-1.15-.67-2.54-.04-3.63.28-.49.69-.91,1.2-1.23l.33-.2c.51-.32,1.08-.48,1.64-.52,1.26-.07,2.51.53,3.21,1.68l2.66,4.34,4.36,7.11,2.66,4.34c.7,1.15.67,2.54.04,3.63-.28.49-.69.91-1.2,1.23Z"
        />
      </g>
      <g id="_Radial_Repeat_-55">
        <path
          id="middle-2-55"
          className="dash-circle-cls-2"
          d="m360,464.95l-.35.18c-.54.27-1.11.4-1.68.38-1.26-.03-2.46-.73-3.07-1.93l-2.31-4.53-3.79-7.43-2.31-4.53c-.61-1.2-.47-2.58.25-3.62.32-.46.76-.86,1.3-1.13l.35-.18c.54-.27,1.11-.4,1.68-.38,1.26.03,2.46.73,3.07,1.93l2.31,4.53,3.79,7.43,2.31,4.53c.61,1.2.47,2.58-.25,3.62-.32.46-.76.86-1.3,1.13Z"
        />
      </g>
      <g id="_Radial_Repeat_-56">
        <path
          id="middle-2-56"
          className="dash-circle-cls-2"
          d="m342.84,472.88l-.36.15c-.56.23-1.14.31-1.7.25-1.25-.13-2.4-.92-2.91-2.16l-1.95-4.7-3.19-7.71-1.95-4.7c-.52-1.24-.27-2.61.53-3.59.36-.44.82-.79,1.38-1.02l.36-.15c.56-.23,1.14-.31,1.7-.25,1.25.13,2.4.92,2.91,2.16l1.95,4.7,3.19,7.71,1.95,4.7c.52,1.24.27,2.61-.53,3.59-.36.44-.82.79-1.38,1.02Z"
        />
      </g>
      <g id="_Radial_Repeat_-57">
        <path
          id="middle-2-57"
          className="dash-circle-cls-2"
          d="m325.11,479.44l-.37.12c-.57.19-1.16.22-1.72.12-1.24-.22-2.32-1.1-2.73-2.38l-1.57-4.84-2.58-7.93-1.57-4.84c-.42-1.28-.06-2.63.81-3.54.39-.41.88-.73,1.46-.91l.37-.12c.57-.19,1.16-.22,1.72-.12,1.24.22,2.32,1.1,2.73,2.38l1.57,4.84c.74,2.26,1.84,5.67,2.58,7.93l1.57,4.84c.42,1.28.06,2.63-.81,3.54-.39.41-.88.73-1.46.91Z"
        />
      </g>
      <g id="_Radial_Repeat_-58">
        <path
          id="middle-2-58"
          className="dash-circle-cls-2"
          d="m306.91,484.58l-.38.09c-.59.14-1.17.13-1.72-.02-1.22-.32-2.22-1.28-2.54-2.59l-1.19-4.94-1.95-8.11-1.19-4.94c-.31-1.31.15-2.62,1.08-3.46.42-.38.94-.65,1.52-.8l.38-.09c.59-.14,1.17-.13,1.72.02,1.22.32,2.22,1.28,2.54,2.59l1.19,4.94,1.95,8.11,1.19,4.94c.31,1.31-.15,2.62-1.08,3.46-.42.38-.94.65-1.52.8Z"
        />
      </g>
      <g id="_Radial_Repeat_-59">
        <path
          id="middle-2-59"
          className="dash-circle-cls-2"
          d="m288.37,488.29l-.39.06c-.6.09-1.18.03-1.71-.15-1.19-.41-2.12-1.45-2.33-2.78l-.8-5.02-1.31-8.24-.8-5.02c-.21-1.33.35-2.6,1.35-3.37.45-.34.99-.58,1.58-.67l.39-.06c.6-.09,1.18-.03,1.71.15,1.19.41,2.12,1.45,2.33,2.78l.8,5.02,1.31,8.24.8,5.02c.21,1.33-.35,2.6-1.35,3.37-.45.34-.99.58-1.58.67Z"
        />
      </g>
      <g id="_Radial_Repeat_-60">
        <path
          id="middle-2-60"
          className="dash-circle-cls-2"
          d="m269.6,490.53l-.39.03c-.6.05-1.18-.06-1.7-.29-1.15-.51-2-1.61-2.1-2.96l-.4-5.07-.66-8.32-.4-5.07c-.11-1.34.55-2.57,1.61-3.25.47-.3,1.03-.5,1.63-.55l.39-.03c.6-.05,1.18.06,1.7.29,1.15.51,2,1.61,2.1,2.96l.4,5.07.66,8.32.4,5.07c.11,1.34-.55,2.57-1.61,3.25-.47.3-1.03.5-1.63.55Z"
        />
      </g>
      <g id="_Radial_Repeat_-61">
        <path
          id="middle-2-61"
          className="dash-circle-cls-2"
          d="m250.71,491.29h-.39c-.6,0-1.17-.15-1.67-.42-1.11-.6-1.86-1.77-1.86-3.11v-5.08c0-2.38,0-5.96,0-8.34v-5.08c0-1.35.75-2.52,1.86-3.11.5-.27,1.06-.42,1.67-.42h.39c.6,0,1.17.15,1.67.42,1.11.6,1.86,1.77,1.86,3.11v5.08c0,2.38,0,5.96,0,8.34v5.08c0,1.35-.75,2.52-1.86,3.11-.5.27-1.06.42-1.67.42Z"
        />
      </g>
      <g id="_Radial_Repeat_-62">
        <path
          id="middle-2-62"
          className="dash-circle-cls-2"
          d="m231.82,490.56l-.39-.03c-.6-.05-1.16-.24-1.63-.55-1.06-.68-1.72-1.91-1.61-3.25l.4-5.07.65-8.32.4-5.07c.11-1.34.95-2.45,2.1-2.96.52-.23,1.09-.33,1.7-.29l.39.03c.6.05,1.16.24,1.63.55,1.06.68,1.72,1.91,1.61,3.25l-.4,5.07-.65,8.32-.4,5.07c-.11,1.34-.95,2.45-2.1,2.96-.52.23-1.09.33-1.7.29Z"
        />
      </g>
      <g id="_Radial_Repeat_-63">
        <path
          id="middle-2-63"
          className="dash-circle-cls-2"
          d="m213.04,488.36l-.39-.06c-.6-.09-1.13-.33-1.58-.67-1-.76-1.56-2.04-1.35-3.37l.8-5.02,1.3-8.24.8-5.02c.21-1.33,1.14-2.37,2.33-2.78.53-.19,1.12-.25,1.71-.15l.39.06c.6.09,1.13.33,1.58.67,1,.76,1.56,2.04,1.35,3.37l-.8,5.02-1.3,8.24-.8,5.02c-.21,1.33-1.14,2.37-2.33,2.78-.53.19-1.12.25-1.71.15Z"
        />
      </g>
      <g id="_Radial_Repeat_-64">
        <path
          id="middle-2-64"
          className="dash-circle-cls-2"
          d="m194.49,484.68l-.38-.09c-.59-.14-1.1-.42-1.52-.8-.94-.84-1.4-2.15-1.08-3.46l1.19-4.94,1.95-8.11,1.19-4.94c.31-1.31,1.32-2.27,2.54-2.59.55-.14,1.13-.16,1.72-.02l.38.09c.59.14,1.1.42,1.52.8.94.84,1.4,2.15,1.08,3.46l-1.19,4.94-1.95,8.11-1.19,4.94c-.31,1.31-1.32,2.27-2.54,2.59-.55.14-1.13.16-1.72.02Z"
        />
      </g>
      <g id="_Radial_Repeat_-65">
        <path
          id="middle-2-65"
          className="dash-circle-cls-2"
          d="m176.29,479.57l-.37-.12c-.57-.19-1.07-.51-1.46-.91-.87-.91-1.23-2.25-.81-3.54l1.57-4.84,2.58-7.93,1.57-4.84c.42-1.28,1.49-2.16,2.73-2.38.55-.1,1.14-.07,1.72.12l.37.12c.57.19,1.07.51,1.46.91.87.91,1.23,2.25.81,3.54l-1.57,4.84-2.58,7.93-1.57,4.84c-.42,1.28-1.49,2.16-2.73,2.38-.55.1-1.14.07-1.72-.12Z"
        />
      </g>
      <g id="_Radial_Repeat_-66">
        <path
          id="middle-2-66"
          className="dash-circle-cls-2"
          d="m158.55,473.04l-.36-.15c-.56-.23-1.02-.59-1.38-1.02-.8-.97-1.05-2.34-.53-3.59l1.95-4.7,3.19-7.71,1.95-4.7c.52-1.24,1.66-2.04,2.91-2.16.56-.06,1.14.02,1.7.25l.36.15c.56.23,1.02.59,1.38,1.02.8.97,1.05,2.34.53,3.59l-1.95,4.7-3.19,7.71-1.95,4.7c-.52,1.24-1.66,2.04-2.91,2.16-.56.06-1.14-.02-1.7-.25Z"
        />
      </g>
      <g id="_Radial_Repeat_-67">
        <path
          id="middle-2-67"
          className="dash-circle-cls-2"
          d="m141.37,465.14l-.35-.18c-.54-.27-.97-.67-1.3-1.13-.72-1.03-.86-2.42-.25-3.62l2.31-4.53,3.79-7.43,2.31-4.53c.61-1.2,1.81-1.9,3.07-1.93.56-.01,1.14.11,1.68.38l.35.18c.54.27.97.67,1.3,1.13.72,1.03.86,2.42.25,3.62l-2.31,4.53-3.79,7.43-2.31,4.53c-.61,1.2-1.81,1.9-3.07,1.93-.56.01-1.14-.11-1.68-.38Z"
        />
      </g>
      <g id="_Radial_Repeat_-68">
        <path
          id="middle-2-68"
          className="dash-circle-cls-2"
          d="m124.87,455.91l-.33-.2c-.51-.32-.92-.74-1.2-1.23-.63-1.09-.67-2.48.04-3.63l2.66-4.34,4.36-7.11,2.66-4.34c.7-1.15,1.96-1.75,3.21-1.68.56.03,1.13.2,1.64.51l.33.2c.51.32.92.74,1.2,1.23.63,1.09.67,2.48-.04,3.63l-2.66,4.34-4.36,7.11-2.66,4.34c-.7,1.15-1.96,1.75-3.21,1.68-.56-.03-1.13-.2-1.64-.51Z"
        />
      </g>
      <g id="_Radial_Repeat_-69">
        <path
          id="middle-2-69"
          className="dash-circle-cls-2"
          d="m109.14,445.42l-.32-.23c-.49-.35-.86-.81-1.1-1.32-.55-1.13-.47-2.52.32-3.61l2.99-4.11,4.9-6.75,2.99-4.11c.79-1.09,2.09-1.59,3.34-1.42.56.08,1.11.29,1.59.64l.32.23c.49.35.86.81,1.1,1.32.55,1.13.47,2.52-.32,3.61l-2.99,4.11-4.9,6.75-2.99,4.11c-.79,1.09-2.09,1.59-3.34,1.42-.56-.08-1.11-.29-1.59-.64Z"
        />
      </g>
      <g id="_Radial_Repeat_-70">
        <path
          id="middle-2-70"
          className="dash-circle-cls-2"
          d="m94.28,433.73l-.3-.25c-.46-.39-.79-.88-1-1.4-.46-1.17-.27-2.55.6-3.58l3.3-3.87,5.42-6.34,3.3-3.87c.87-1.02,2.21-1.42,3.44-1.16.55.12,1.08.37,1.54.77l.3.25c.46.39.79.88,1,1.4.46,1.17.27,2.55-.6,3.58l-3.3,3.87-5.42,6.34-3.3,3.87c-.87,1.02-2.21,1.42-3.44,1.16-.55-.12-1.08-.37-1.54-.77Z"
        />
      </g>
      <g id="_Radial_Repeat_-71">
        <path
          id="middle-2-71"
          className="dash-circle-cls-2"
          d="m80.39,420.91l-.28-.28c-.43-.43-.72-.93-.88-1.47-.36-1.2-.07-2.57.88-3.52l3.6-3.6,5.9-5.9,3.6-3.6c.95-.95,2.31-1.25,3.52-.88.54.16,1.05.46,1.47.88l.28.28c.43.43.72.93.88,1.47.36,1.2.07,2.57-.88,3.52l-3.6,3.6-5.9,5.9-3.6,3.6c-.95.95-2.31,1.25-3.52.88-.54-.16-1.05-.46-1.47-.88Z"
        />
      </g>
      <g id="_Radial_Repeat_-72">
        <path
          id="middle-2-72"
          className="dash-circle-cls-2"
          d="m67.54,407.04l-.25-.3c-.39-.46-.65-.99-.77-1.54-.27-1.23.13-2.56,1.16-3.44l3.87-3.3,6.34-5.42,3.87-3.3c1.02-.87,2.4-1.06,3.58-.61.53.2,1.01.54,1.4,1l.25.3c.39.46.65.99.77,1.54.27,1.23-.13,2.56-1.16,3.44l-3.87,3.3-6.34,5.42-3.87,3.3c-1.02.87-2.4,1.06-3.58.61-.53-.2-1.01-.54-1.4-1Z"
        />
      </g>
      <g id="_Radial_Repeat_-73">
        <path
          id="middle-2-73"
          className="dash-circle-cls-2"
          d="m55.82,392.2l-.23-.32c-.35-.49-.57-1.04-.64-1.59-.17-1.25.33-2.54,1.42-3.34l4.11-2.99c1.92-1.4,4.82-3.51,6.75-4.9l4.11-2.99c1.09-.79,2.48-.87,3.61-.32.51.24.96.62,1.32,1.1l.23.32c.35.49.57,1.04.64,1.59.17,1.25-.33,2.54-1.42,3.34l-4.11,2.99-6.75,4.9-4.11,2.99c-1.09.79-2.48.87-3.61.32-.51-.24-.96-.62-1.32-1.1Z"
        />
      </g>
      <g id="_Radial_Repeat_-74">
        <path
          id="middle-2-74"
          className="dash-circle-cls-2"
          d="m45.31,376.49l-.2-.33c-.32-.51-.48-1.08-.52-1.64-.07-1.26.53-2.51,1.68-3.21l4.34-2.66,7.11-4.36,4.34-2.66c1.15-.7,2.54-.67,3.63-.04.49.28.91.69,1.23,1.2l.2.33c.32.51.48,1.08.52,1.64.07,1.26-.53,2.51-1.68,3.21l-4.34,2.66-7.11,4.36-4.34,2.66c-1.15.7-2.54.67-3.63.04-.49-.28-.91-.69-1.23-1.2Z"
        />
      </g>
      <g id="_Radial_Repeat_-75">
        <path
          id="middle-2-75"
          className="dash-circle-cls-2"
          d="m36.05,360l-.18-.35c-.27-.54-.4-1.11-.38-1.68.03-1.26.73-2.46,1.93-3.07l4.53-2.31,7.43-3.79,4.53-2.31c1.2-.61,2.58-.47,3.62.25.46.32.86.76,1.13,1.3l.18.35c.27.54.4,1.11.38,1.68-.03,1.26-.73,2.46-1.93,3.07l-4.53,2.31-7.43,3.79-4.53,2.31c-1.2.61-2.58.47-3.62-.25-.46-.32-.86-.76-1.13-1.3Z"
        />
      </g>
      <g id="_Radial_Repeat_-76">
        <path
          id="middle-2-76"
          className="dash-circle-cls-2"
          d="m28.12,342.84l-.15-.36c-.23-.56-.31-1.14-.25-1.7.13-1.25.92-2.4,2.16-2.91l4.7-1.95,7.71-3.19,4.7-1.95c1.24-.52,2.61-.27,3.59.53.44.36.79.82,1.02,1.38l.15.36c.23.56.31,1.14.25,1.7-.13,1.25-.92,2.4-2.16,2.91l-4.7,1.95-7.71,3.19-4.7,1.95c-1.24.52-2.61.27-3.59-.53-.44-.36-.79-.82-1.02-1.38Z"
        />
      </g>
      <g id="_Radial_Repeat_-77">
        <path
          id="middle-2-77"
          className="dash-circle-cls-2"
          d="m21.56,325.11l-.12-.37c-.19-.57-.22-1.16-.12-1.72.22-1.24,1.1-2.32,2.38-2.73l4.84-1.57,7.93-2.58,4.84-1.57c1.28-.42,2.63-.06,3.54.81.41.39.73.88.91,1.46l.12.37c.19.57.22,1.16.12,1.72-.22,1.24-1.1,2.32-2.38,2.73l-4.84,1.57-7.93,2.58-4.84,1.57c-1.28.42-2.63.06-3.54-.81-.41-.39-.73-.88-.91-1.46Z"
        />
      </g>
      <g id="_Radial_Repeat_-78">
        <path
          id="middle-2-78"
          className="dash-circle-cls-2"
          d="m16.42,306.91l-.09-.38c-.14-.59-.13-1.17.02-1.72.32-1.22,1.28-2.22,2.59-2.54l4.94-1.19,8.11-1.95,4.94-1.19c1.31-.31,2.62.15,3.46,1.08.38.42.65.94.8,1.52l.09.38c.14.59.13,1.17-.02,1.72-.32,1.22-1.28,2.22-2.59,2.54l-4.94,1.19-8.11,1.95-4.94,1.19c-1.31.31-2.62-.15-3.46-1.08-.38-.42-.65-.94-.8-1.52Z"
        />
      </g>
      <g id="_Radial_Repeat_-79">
        <path
          id="middle-2-79"
          className="dash-circle-cls-2"
          d="m12.71,288.37l-.06-.39c-.09-.6-.03-1.18.15-1.71.41-1.19,1.45-2.12,2.78-2.33l5.02-.8,8.24-1.31,5.02-.8c1.33-.21,2.6.35,3.37,1.35.34.45.58.99.67,1.58l.06.39c.09.6.03,1.18-.15,1.71-.41,1.19-1.45,2.12-2.78,2.33l-5.02.8c-2.35.37-5.89.93-8.24,1.31l-5.02.8c-1.33.21-2.6-.35-3.37-1.35-.34-.45-.58-.99-.67-1.58Z"
        />
      </g>
      <g id="_Radial_Repeat_-80">
        <path
          id="middle-2-80"
          className="dash-circle-cls-2"
          d="m10.47,269.6l-.03-.39c-.05-.6.06-1.18.29-1.7.51-1.15,1.61-2,2.96-2.1l5.07-.4,8.32-.66,5.07-.4c1.34-.11,2.57.55,3.25,1.61.3.47.5,1.03.55,1.63l.03.39c.05.6-.06,1.18-.29,1.7-.51,1.15-1.61,2-2.96,2.1l-5.07.4-8.32.66-5.07.4c-1.34.11-2.57-.55-3.25-1.61-.3-.47-.5-1.03-.55-1.63Z"
        />
      </g>
    </g>
  </svg>
);
