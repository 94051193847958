import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { GetProps, Omit } from 'react-redux';
import * as yup from 'yup';

import {
  GrainType,
  Orientation,
  PileFragmentFragment,
  ShapeType,
  withCreatePileHoc,
  WithCreatePileHocChildProps,
  withGetPileHoc,
  WithGetPileHocChildProps,
  withUpdatePileHoc,
  WithUpdatePileHocChildProps,
} from '../../../api';
import { UiFormError } from '../../../util/format-error';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../../util/form2/BaseForm';
import { Button, ButtonSubmit } from '../../util/form2/Button';
import { ErrorBox } from '../../util/form2/ErrorBox';
import { IntTextField } from '../../util/form2/NumberField';
import { RadioGroup } from '../../util/form2/RadioGroup';
import { SelectGrainType, yup_grain_type } from '../../util/form2/SelectGrainType';
import { TextField } from '../../util/form2/TextField';
import { PileLocationViewer } from './PileLocationViewer';

const useStyles = makeStyles({
  grid: { maxWidth: 450 },
  centered: { textAlign: 'center' },
  button_row: { margin: '0.5em', textAlign: 'center' },
  divider: { marginBottom: 2 },
});
const maxDimension = 5000;
const minDimension = 25;

export type Values = {
  alias: string;
  default_grain_type: GrainType | string;
  length_ft: number | string;
  diameter_ft: number | string;
  orientation: Orientation;
};
const validationSchema = yup.object().shape({
  alias: yup
    .string()
    .label('Pile Name')
    .required(),
  default_grain_type: yup_grain_type
    .label('Grain Type')
    .required()
    .nullable(false),
  length_ft: yup
    .number()
    .typeError('A number is required')
    .label('Length')
    .required()
    .positive()
    .max(maxDimension)
    .min(minDimension)
    .nullable(false),
  diameter_ft: yup
    .number()
    .typeError('A number is required')
    .label('Diameter')
    .required()
    .positive()
    .max(maxDimension)
    .min(minDimension)
    .nullable(false),
  orientation: yup
    .string()
    .label('Orientation')
    .required()
    .oneOf([Orientation.NorthSouth, Orientation.EastWest]),
});

export const PileFormBase: FunctionComponent<
  WithCreatePileHocChildProps &
    WithUpdatePileHocChildProps &
    FormikWrapperHandlerProps<Values, PileFragmentFragment> & {
      account_id: number | null;
      pile: PileFragmentFragment | null;
      loading?: boolean;
      onClickPileCableLinks?: (arg: any) => any;
    }
> = ({ pile, onClickPileCableLinks, createPile, updatePile, account_id, ...props }) => {
  const classes = useStyles();
  const initial_values = useMemo(
    () =>
      pile
        ? {
            alias: pile.alias,
            default_grain_type: pile.default_grain_type || '',
            length_ft: (pile.shape && pile.shape.length_ft) || '',
            diameter_ft: (pile.shape && pile.shape.radius_ft && pile.shape.radius_ft * 2) || '',
            orientation: pile.orientation || Orientation.NorthSouth,
          }
        : {
            alias: '',
            default_grain_type: '',
            length_ft: '',
            diameter_ft: '',
            orientation: Orientation.NorthSouth,
          },
    [pile]
  );
  const submitCallback = useCallback(
    async (values): Promise<PileFragmentFragment> => {
      const {
        alias,
        default_grain_type,
        length_ft,
        diameter_ft,
        orientation,
      } = validationSchema.validateSync(values);
      if (account_id !== null) {
        return await createPile({
          account_id,
          alias,
          default_grain_type,
          orientation: orientation as Orientation,
          shape: {
            length_ft: Number(length_ft),
            radius_ft: Number(diameter_ft) / 2,
            shape_type: ShapeType.HalfCylinder,
          },
        });
      }
      if (!pile) {
        throw new UiFormError('Pile and account_id were both null');
      }
      return await updatePile({
        alias,
        default_grain_type,
        orientation: orientation as Orientation,
        shape: {
          length_ft: Number(length_ft),
          radius_ft: Number(diameter_ft) / 2,
          shape_type: ShapeType.HalfCylinder,
        },
        pile_id: pile.pile_id,
      });
    },
    [createPile, updatePile, pile]
  );
  return (
    <FormikWrapper<Values, PileFragmentFragment>
      {...props}
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initial_values}
      onSubmit={submitCallback}
      render={({ isSubmitting, isValid, values: { diameter_ft, length_ft, orientation } }) => (
        <BaseForm submitting_message="Saving Pile...">
          <Grid
            container
            direction="row"
            alignContent="flex-start"
            alignItems="center"
            justify="center"
            spacing={2}
            className={classes.grid}
          >
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.centered}>
                {pile ? pile.alias : 'Create New Pile'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField name="alias" label="Pile Name" placeholder="Pile 01" fullWidth required />
            </Grid>
            <Grid item xs={12}>
              <SelectGrainType name="default_grain_type" fullWidth label="Grain Type" required />
            </Grid>
            <Grid item xs={12}>
              <IntTextField
                name="length_ft"
                label="Length (feet)"
                min={minDimension}
                max={maxDimension}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <IntTextField
                name="diameter_ft"
                label="Diameter (feet)"
                min={minDimension}
                max={maxDimension}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.centered}>
              <RadioGroup name="orientation" label="Pile Orientation">
                <FormControlLabel
                  value={Orientation.NorthSouth}
                  control={<Radio />}
                  label="North/South"
                />
                <FormControlLabel
                  value={Orientation.EastWest}
                  control={<Radio />}
                  label="East/West"
                />
              </RadioGroup>
            </Grid>
            {orientation && length_ft && diameter_ft && isValid && (
              <Card raised>
                <CardHeader
                  title={`Pile Preview`}
                  titleTypographyProps={{
                    color: 'secondary',
                  }}
                />
                <Divider classes={{ root: classes.divider }} />
                <CardContent>
                  <Grid container justify="space-around" alignItems="center">
                    <Grid container item xs={12} justify="center">
                      <PileLocationViewer
                        pile_cables={[]}
                        orientation={orientation as Orientation}
                        length={Number(length_ft)}
                        diameter={Number(diameter_ft)}
                        landscape={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
            {(!orientation || !length_ft || !diameter_ft || !isValid) && (
              <Grid item xs={12} className={classes.centered}>
                <Typography variant="h6">Complete the form for a pile preview</Typography>
              </Grid>
            )}
            <ErrorBox />
            <Grid item xs={12} className={classes.centered}>
              <ButtonSubmit />
            </Grid>
            {pile && onClickPileCableLinks && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} className={classes.button_row}>
                  <Button disabled={isSubmitting} onClick={onClickPileCableLinks}>
                    Pile Cables
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </BaseForm>
      )}
    />
  );
};

export const PileForm = withUpdatePileHoc(withCreatePileHoc(PileFormBase));

export const CreatePileForm = (
  props: Omit<GetProps<typeof PileForm>, 'pile'> & { account_id: number }
) => <PileForm {...props} pile={null} />;

export const UpdatePileForm = ({
  pile,
  ...props
}: Omit<GetProps<typeof PileForm>, 'account_id'> & { pile: PileFragmentFragment }) => (
  <PileForm {...props} account_id={null} pile={pile} />
);

export const UpdatePileFormGql = withGetPileHoc(
  ({
    loading,
    pile,
    ...props
  }: Omit<GetProps<typeof UpdatePileForm>, 'pile'> & WithGetPileHocChildProps) => {
    if (!pile) {
      return <Typography variant="h5">Unable to load pile</Typography>;
    }
    return <UpdatePileForm {...props} pile={pile} />;
  }
);
