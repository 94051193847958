import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GrainSpecificAlertPrefs,
  UpdateGrainBinSpecificAlertPrefMutationVariables as Variables,
  withUpdateGrainBinSpecificAlertPref as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type withUpdateGrainBinSpecificAlertPrefHocChildProps = {
  updateSpecificAlertPref: (
    variables: Variables
  ) => Promise<{ __typename: 'GrainSpecificAlertPrefs' } & GrainSpecificAlertPrefs>;
};

export const withUpdateGrainBinSpecificAlertPrefHoc: AmberHoc<
  {},
  withUpdateGrainBinSpecificAlertPrefHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, withUpdateGrainBinSpecificAlertPrefHocChildProps, typeof component>,
    withUpdateGrainBinSpecificAlertPrefHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updateSpecificAlertPref: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }

          const result = await mutate({ variables });

          if (!result) {
            throw new Error('Unexpected server response');
          }

          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.updateSpecificAlertPref;
        },
      };
    },
  })(component as any);
