export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}

const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'ContainerAccountLink',
        possibleTypes: [
          {
            name: 'BargeAccountLink',
          },
          {
            name: 'GrainBinAccountLink',
          },
          {
            name: 'PileAccountLink',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'FanControllerRequest',
        possibleTypes: [
          {
            name: 'FanControllerPingRequest',
          },
          {
            name: 'FanControllerResetRequest',
          },
          {
            name: 'FanControllerSetConfigRequest',
          },
          {
            name: 'FanControllerDiagnosticTestRequest',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'FanControllerResponse',
        possibleTypes: [
          {
            name: 'FanControllerResetResponse',
          },
          {
            name: 'FanControllerGetConfigResponse',
          },
          {
            name: 'FanControllerHeartbeatResponse',
          },
          {
            name: 'FanControllerDebugResponse',
          },
          {
            name: 'FanControllerDiagnosticTestStatusResponse',
          },
          {
            name: 'FanControllerDiagnosticTestSelfTestResultResponse',
          },
          {
            name: 'FanControllerDiagnosticTestCellMetricTestResultResponse',
          },
        ],
      },
    ],
  },
};

export default result;
