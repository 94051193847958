import { AmberHoc, AmberHocProps } from '../../../util';
import {
  ApproveSensorGeneratedGrainTicketMutation as Result,
  ApproveSensorGeneratedGrainTicketMutationVariables as Variables,
  withApproveSensorGeneratedGrainTicket as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithApproveSensorGeneratedGrainTicketHocChildProps = {
  approveSensorGeneratedGrainTicket: (
    variables: Variables
  ) => Promise<Result['approveSensorGeneratedGrainTicket']>;
};

export const withApproveSensorGeneratedGrainTicketHoc: AmberHoc<
  {},
  WithApproveSensorGeneratedGrainTicketHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithApproveSensorGeneratedGrainTicketHocChildProps, typeof component>,
    WithApproveSensorGeneratedGrainTicketHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        approveSensorGeneratedGrainTicket: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }

          const result = await mutate({ variables });

          if (!result) {
            throw new Error('Unexpected server response');
          }

          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.approveSensorGeneratedGrainTicket;
        },
      };
    },
  })(component as any);
