import { AmberHoc, AmberHocProps } from '../../../util';
import {
  ResetPasswordMutationVariables as Variables,
  withResetPassword as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithResetPasswordHocChildProps = {
  resetPassword: (variables: Variables) => Promise<boolean>;
};
export const withResetPasswordHoc: AmberHoc<{}, WithResetPasswordHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithResetPasswordHocChildProps, typeof component>,
    WithResetPasswordHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        resetPassword: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({ variables });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.requestPasswordReset;
        },
      };
    },
  })(component as any);
