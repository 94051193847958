import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { event, pageview, set } from 'react-ga';
import { takeEvery } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import { createSession, EventCategory, trackEvent } from '../action';

function* createSessionFlow({ payload: { viewer } }: ActionType<typeof createSession>) {
  if (viewer) {
    yield set({ user_id: viewer.user_id });
  }
}

function* trackEventFlow({ payload }: ActionType<typeof trackEvent>) {
  yield event(payload);
}

function* locationChangeFlow(action) {
  const { location, action: nav_action } = <RouterState>action.payload;
  event({
    category: EventCategory.nav,
    action: nav_action,
  });
  set({ page: window.location.pathname });
  pageview(location.pathname);
}

export function* uiAnalyticsWatcher() {
  takeEvery(getType(createSession), createSessionFlow);
  takeEvery(LOCATION_CHANGE, locationChangeFlow);
  takeEvery(getType(trackEvent), trackEventFlow);
}
