import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EditNoteSvgIcon } from '../../../../..//core/src/media';
import { pushNav } from '../../../../../core/src/action';
import {
  amber_amber,
  black_shade_2,
  black_shade_4,
  black_shade_8,
} from '../../../../../core/src/style';

const useStyles = makeStyles((theme: Theme) => ({
  customHeaderMenuButton: { float: 'left', margin: '0 0 0 3px' },
  customHeaderLabel: {
    float: 'left',
    margin: '0 10px 0 3px',
    fontSize: 20,
    color: black_shade_8,
    fontWeight: 600,
  },
  customSortDownLabel: {
    float: 'left',
    margin: '0 0 0 3px',
  },
  customSortUpLabel: {
    float: 'left',
    margin: '0',
  },
  customSortRemoveLabel: {
    float: 'left',
    margin: '0 0 0 3px',
    fontSize: 11,
  },
  groupbyBtn: {
    marginRight: 6,
    width: 110,
    border: `1px solid ${black_shade_4}`,
    borderRadius: 4,
    padding: '2px',
    height: 24,
    display: 'flex',
    alignItems: 'center',
  },
  manageBtn: {
    width: 84,
    padding: '4px',
    border: `1px solid ${black_shade_4}`,
    height: 24,
    borderRadius: 4,
    color: black_shade_2,
    fontSize: 14,
    textTransform: 'capitalize',
    fontFamily: 'Work Sans,sans-serif',
  },
}));
const OperationsHeader = (props) => {
  console.log('props in OperationsHeader', props);
  const classes = useStyles();
  const [ascSort, setAscSort] = useState('inactive');
  const [descSort, setDescSort] = useState('inactive');
  const [noSort, setNoSort] = useState('inactive');
  const refButton = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme();

  const onMenuClicked = () => {
    props.showColumnMenu(refButton.current);
  };

  const onSortChanged = () => {
    setAscSort(props.column.isSortAscending() ? 'active' : 'inactive');
    setDescSort(props.column.isSortDescending() ? 'active' : 'inactive');
    setNoSort(
      !props.column.isSortAscending() && !props.column.isSortDescending() ? 'active' : 'inactive'
    );
  };

  const onSortRequested = (order, event) => {
    props.setSort(order, event.shiftKey);
  };

  const goToManageSites = () => {
    dispatch(pushNav({ path: '/grain/operations/manage' }));
  };

  // useEffect(() => {
  //   props.column.addEventListener('sortChanged', onSortChanged);
  //   onSortChanged();
  // }, []);

  let menu: any = null;
  if (props.enableMenu) {
    menu = (
      <div
        ref={refButton}
        className={classes.customHeaderMenuButton}
        onClick={() => onMenuClicked()}
      >
        <i className={`fa ${props.menuIcon}`} />
      </div>
    );
  }

  // let sort: any = null;
  // if (props.enableSorting) {
  //   sort = (
  //     <div style={{ display: 'inline-block' }}>
  //       <div
  //         onClick={(event) => onSortRequested('asc', event)}
  //         onTouchEnd={(event) => onSortRequested('asc', event)}
  //         className={`${classes.customSortDownLabel} ${ascSort}`}
  //       >
  //         V
  //       </div>
  //       <div
  //         onClick={(event) => onSortRequested('desc', event)}
  //         onTouchEnd={(event) => onSortRequested('desc', event)}
  //         className={`${classes.customSortUpLabel}  ${descSort}`}
  //       >
  //         ^
  //       </div>
  //       <div
  //         onClick={(event) => onSortRequested('', event)}
  //         onTouchEnd={(event) => onSortRequested('', event)}
  //         className={`${classes.customSortRemoveLabel} ${noSort}`}
  //       >
  //         x
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        width: '100%',
        height: '50%',
        marginLeft: 5,
      }}
    >
      {menu}
      <div className={classes.customHeaderLabel} style={{ marginRight: 65 }}>
        {props.displayName}
      </div>
      {/* {sort} */}
      <div style={{ display: 'flex' }}>
        <div className={classes.groupbyBtn}>
          <FormGroup
            style={{
              padding: 0,
            }}
          >
            <FormControlLabel
              style={{ margin: 0, fontSize: 14 }}
              control={
                <Checkbox
                  size="small"
                  style={{ padding: 0, color: black_shade_2 }}
                  checked={props.groupBySites}
                  onChange={(e: any) => {
                    // props.api.setAutoGroupColumnDef([]);
                    // props.api.setAutoGroupColumnDef(undefined);
                    props.setGroupBySites(e.target.checked);
                    // props.api.columnApi.setRowGroupColumns([]);
                    // props.api.redrawRows();
                  }}
                />
              }
              label={
                <span
                  style={{
                    fontFamily: 'Work Sans,sans-serif',
                    fontSize: 14,
                    padding: 0,
                    letterSpacing: '-0.7px',
                    color: black_shade_2,
                  }}
                >
                  Group by Site
                </span>
              }
            />
          </FormGroup>
        </div>
        <Button variant="outlined" className={classes.manageBtn} onClick={goToManageSites}>
          <EditNoteSvgIcon color={amber_amber} style={{ width: 18, height: 18, marginRight: 4 }} />{' '}
          Manage
        </Button>
      </div>
    </div>
  );
};

export { OperationsHeader };
