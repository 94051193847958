import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { GetGrainBinStoragePeriodQuery, withGetGrainBinStoragePeriod as HOC } from '../__generated';

export type WithGetGrainBinStoragePeriodHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainBinStoragePeriodHocChildProps = {
  loading: boolean;
  grain_bin_storage_periods: GetGrainBinStoragePeriodQuery;
};
export const withGetGrainBinStoragePeriodHoc: AmberHoc<
  WithGetGrainBinStoragePeriodHocOwnProps,
  WithGetGrainBinStoragePeriodHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainBinStoragePeriodHocOwnProps,
      WithGetGrainBinStoragePeriodHocChildProps,
      typeof component
    >,
    WithGetGrainBinStoragePeriodHocChildProps
  >({
    options: ({ grain_bin_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      fetchPolicy: 'network-only',
      variables: { grain_bin_id },
      notifyOnNetworkStatusChange: true,
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          grain_bin_storage_periods: {
            __typename: 'Query',
            grain_bin_storage_periods: [],
          },
        };
      }

      return {
        loading: loading || false,
        refetch_grain_bin_storage_periods: data.refetch,
        grain_bin_storage_periods: {
          __typename: 'Query',
          grain_bin_storage_periods: data.grain_bin_storage_periods || [],
        },
      };
    },
  })(component as any);
