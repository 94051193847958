import { datadogLogs } from '@datadog/browser-logs';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import {
  ContainerTypeLegacy,
  RunwindowWeatherPlot,
  useWeatherData,
} from '../../../../../../core/src';
import DividerLine from '../../../../../../core/src/component/util/DividerLine';
import {
  ContainerType,
  GetGrainBinContainerQuery,
  GrainBinTelemetryFragmentFragment,
  GrainContainerHubLinksFragmentFragment,
  PsychrometricRangeFragmentFragment,
  withGetGrainBinContainerHoc,
} from '../../../../api';
import { CenteredSpinner } from '../../../spinner';
import { FanControlNavigationOption } from '../../FanControlNavigationOption';
import { RecommendedOption } from '../../RecommendationOptionValue';
import { RecommendedRunWindows } from './RecommendedRunWindows';

const getTimeFromDate = (dt: DateTime) =>
  `${dt.hour}:${`${dt.minute < 10 ? '0' : ''}${dt.minute}`}`;

function selectRecommendationWindows(
  navigation: FanControlNavigationOption,
  grain_container: { __typename: 'GrainContainer' } & {
    telemetry: { __typename: 'GrainTelemetry' } & GrainBinTelemetryFragmentFragment;
  } & GrainContainerHubLinksFragmentFragment
): PsychrometricRangeFragmentFragment[] {
  const {
    telemetry: {
      recommendation_windows: {
        // @ts-ignore Property `temp_ranges` does not exist on type (2339)
        temp_ranges = [],
        // @ts-ignore Property `temp_ranges` does not exist on type (2339)
        emc_ranges = [],
        // @ts-ignore Property `temp_ranges` does not exist on type (2339)
        emc_reconditioning_ranges = [],
      },
    },
  } = grain_container;

  switch (navigation.recommendationOptionValue.option) {
    case RecommendedOption.RECONDITIONING:
      return emc_reconditioning_ranges;
    case RecommendedOption.DRYING:
      return emc_ranges;
    case RecommendedOption.COOLING:
    default:
      return temp_ranges;
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  runwindowWeatherPlotContainer: {
    width: '100%',
    overflowX: 'scroll',
    marginBottom: 5,
    padding: '0 0px 0px 0px !important',
  },
}));

export const ReadOnlyRecommSchedule = withGetGrainBinContainerHoc(
  ({
    grain_container,
    loading,
    grain_bin_id,
    container_id,
    container_type,
    container_type2,
    storage_period,
    navigation,
    setNavigation,
    isRecommendingWindows = false,
    onCancel,
    ...props
  }: Pick<GetGrainBinContainerQuery, 'grain_container'> & {
    loading?: boolean;
    grain_bin_id: number;
    container_id: number;
    container_type: ContainerType;
    container_type2: ContainerTypeLegacy;
    storage_period?: {
      grain_bin_storage_cycle_id: number;
    } | null;
    navigation: FanControlNavigationOption;
    setNavigation: (navigation: FanControlNavigationOption) => void;
    isRecommendingWindows: boolean;
    onCancel: () => void;
  }) => {
    if (loading) {
      return <CenteredSpinner fadeIn="none" />;
    }

    datadogLogs.logger.info('grain_container value in selectRecommendationWindows', {
      grain_container_value: grain_container,
    });

    const classes = useStyles();
    const {
      telemetry: {
        // @ts-ignore Property `temp_ranges` does not exist on type (2339)
        weather_forecast: { hourly_forecast = [] },
      },
    } = grain_container;
    const weatherData = useWeatherData();

    const results = useMemo(() => {
      return isRecommendingWindows && grain_container
        ? selectRecommendationWindows(navigation, grain_container).map(({ start, end }) => {
            return {
              start_date: DateTime.fromMillis(start).toFormat('MM/dd/yyyy'),
              start_time: getTimeFromDate(DateTime.fromMillis(start)),
              end_date: DateTime.fromMillis(end).toFormat('MM/dd/yyyy'),
              end_time: getTimeFromDate(DateTime.fromMillis(end)),
            };
          })
        : [];
    }, [isRecommendingWindows, navigation, isRecommendingWindows]);

    const current_run_windows: { start: DateTime; end: DateTime }[] = useMemo(() => {
      return isRecommendingWindows && grain_container
        ? selectRecommendationWindows(navigation, grain_container).map(({ start, end }) => {
            return {
              start: DateTime.fromMillis(start),
              end: DateTime.fromMillis(end),
            };
          })
        : [];
    }, [isRecommendingWindows, navigation, isRecommendingWindows]);

    return (
      <Grid container style={{ display: 'flex', padding: 0, justifyContent: 'center' }}>
        <Grid item className={classes.runwindowWeatherPlotContainer}>
          <RunwindowWeatherPlot
            grain_bin_location_timezone={weatherData.grain_bin_location_timezone}
            hourly_forecast_data={weatherData.hourlyForecast}
            days={weatherData.dailyForecast}
            runWindows={current_run_windows}
          />
        </Grid>
        <div style={{ marginBottom: 15, width: '100%' }}>
          <DividerLine />
        </div>

        <RecommendedRunWindows
          {...props}
          grain_bin_id={grain_bin_id}
          container_id={container_id}
          container_type={container_type}
          container_type2={container_type2}
          storage_period={storage_period}
          navigation={navigation}
          setNavigation={setNavigation}
          recommendedWindows={results}
          isRecommendingWindows={isRecommendingWindows}
          hourlyForecasts={hourly_forecast}
          onCancel={onCancel}
        />
      </Grid>
    );
  }
);
