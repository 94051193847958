import { Typography } from '@material-ui/core';
import React from 'react';
import { SensorGeneratedGrainTicket } from '../../../../../../core/src/api';
import { PendingGrainTicketsTableBody } from './PendingGrainTicketsTableBody';
import { PendingGrainTicketsTableHeader } from './PendingGrainTicketsTableHeader';

export const PendingGrainTicketsTable = ({
  onTicketApprove,
  onTicketDismiss,
  sortedGrainBinTickets,
}: {
  onTicketApprove: (selectedGrainTicket: SensorGeneratedGrainTicket) => void;
  onTicketDismiss: (selectedGrainTicket: SensorGeneratedGrainTicket) => void;
  sortedGrainBinTickets: SensorGeneratedGrainTicket[];
}) => {
  const hasPendingGrainTickets = sortedGrainBinTickets.length > 0;
  return (
    <>
      {hasPendingGrainTickets ? (
        <>
          <PendingGrainTicketsTableHeader />
          <PendingGrainTicketsTableBody
            tickets={sortedGrainBinTickets}
            onTicketApprove={onTicketApprove}
            onTicketDismiss={onTicketDismiss}
          />
        </>
      ) : (
        <Typography variant="h6">No Pending Tickets</Typography>
      )}
    </>
  );
};
