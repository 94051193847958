import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { withGetShowGrainTicketLevelDeviationWarning as HOC } from '../__generated';

export type WithGetShowGrainTicketLevelDeviationWarningHocOwnProps = {
  grain_bin_id: number;
  grain_bin_storage_cycle_id?: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetShowGrainTicketLevelDeviationWarningHocChildProps = {
  get_show_ticket_warning_loading: boolean;
  show_grain_ticket_level_deviation_warning: boolean;
  refetchGetShowGrainTicketLevelDeviationWarning: () => Promise<any>;
};
export const withGetShowGrainTicketLevelDeviationWarningHoc: AmberHoc<
  WithGetShowGrainTicketLevelDeviationWarningHocOwnProps,
  WithGetShowGrainTicketLevelDeviationWarningHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetShowGrainTicketLevelDeviationWarningHocOwnProps,
      WithGetShowGrainTicketLevelDeviationWarningHocChildProps,
      typeof component
    >,
    WithGetShowGrainTicketLevelDeviationWarningHocChildProps
  >({
    options: ({ grain_bin_id, grain_bin_storage_cycle_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      fetchPolicy: 'network-only',
      variables: { grain_bin_id, grain_bin_storage_cycle_id },
      notifyOnNetworkStatusChange: true,
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          get_show_ticket_warning_loading: true,
          refetchGetShowGrainTicketLevelDeviationWarning: () => Promise.resolve(),
          show_grain_ticket_level_deviation_warning: false,
        };
      }

      return {
        get_show_ticket_warning_loading: loading || false,
        refetchGetShowGrainTicketLevelDeviationWarning: data.refetch,
        show_grain_ticket_level_deviation_warning:
          data.show_grain_ticket_level_deviation_warning || false,
      };
    },
  })(component as any);
