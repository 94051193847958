import {
  Button,
  Grid,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/styles';
import { ArrayHelpers, FieldArray, useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useWeatherData } from '../../../../../core/src/contexts';
import { AutomationDisabledIcon, AutomationEnabledIcon } from '../../../../../core/src/media';
import AutomationDurationIcon from '../../../../../core/src/media/icons/AutomationDurationIcon';
import { setFanScheduleDrafts } from '../../../action';
import {
  amber_amber,
  amber_dark_grey,
  amber_grey,
  amber_red,
  black_shade_5,
  blue_shade_1,
  blue_shade_2,
  gray_shade_1,
  light_gray2,
  light_gray_4,
  light_gray_7,
  maroon_shade_1,
  red_shade_1,
} from '../../../style';
import {
  checkValidSchedule,
  collapseWindows,
  formatNumber,
  getDurationText,
  getTemperatureUnitLabel,
  renderModeColor,
} from '../../../util';
import { getFormatedDateTimeString } from '../../../util/format-date';
import DividerLine from '../../util/DividerLine';
import { Select } from '../../util/form2/Select';
import { getRemainingDaysAutomationPeriodString } from '../daily-forecast/AerationRecommnedationPlotHelpers';
import { RecommendedOption } from '../RecommendationOptionValue';
import { RunwindowWeatherPlot } from '../run-window-weather-chart';
import { GrainCondiFormSubmittedValuesType } from './FanControls';
import { getRecommedationTypeMode } from './FanSchedule';
import {
  computeAvgGrainConditions,
  findNearestTimeFloor,
  getNextStart,
  OVERLAP_ERROR,
  TIMES_ARRAY,
} from './FanSetMultiSchedule';
import { GrainInputsWithMode } from './GrainInputsWithMode';
import { NoSchedulePlaceholder } from './NoSchedulePlaceholder';
import { RunWindow } from './RunWindow';
import { RunWinowReadOnly } from './RunWinowReadOnly';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: 0,
    marginTop: 5,
    width: 'fit-content',
  },
  item: {
    textAlign: 'center',
    color: amber_grey,
    fontWeight: 400,
    fontSize: 16,
  },
  small_header: {
    textAlign: 'center',
    color: amber_grey,
    fontSize: 12,
    marginRight: 10,
  },
  icon: {
    marginRight: 10,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  date_row: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: 15,
    marginTop: 10,
    minWidth: 260,
  },
  time_label: {
    paddingRight: 10,
  },
  time_select: { width: 150 },
  to_separater: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      marginBottom: 8,
    },
  },
  arrowIcon: {
    fontSize: 17,
    width: 40,
    height: 24,
    color: amber_amber,
  },
  closeButton: {
    padding: 0,
    marginLeft: 5,
    marginTop: -5,
    [theme.breakpoints.up('md')]: { marginLeft: 20, marginTop: -10 },
  },
  dateLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: 12,
    fontFamily: 'Source Sans Pro,sans-serif',
    fontWeight: 400,
    textAlign: 'start',
  },
  dateField: { fontSize: 14, paddingTop: 6, textAlign: 'start' },
  toggleAutomationSection: {
    width: '100%',
    background: light_gray2,
    display: 'flex',
    padding: 10,
  },
  automationPeriodSelectContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    marginTop: 1.5,
    background: light_gray2,
    borderRadius: '0px 0px 8px 8px',
  },
  toggleAutomationCheckbox: {
    '& .MuiSvgIcon-root': { fontSize: 24, color: blue_shade_1 },
    marginRight: 0,
  },
  runwindowCountBadge: {
    width: 20,
    height: 20,
    fontSize: 16,
    fontWeight: 600,
    color: 'black',
    background: light_gray_4,
    borderRadius: '50% 50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  runwindowWeatherPlotContainer: {
    width: '100%',
    overflowX: 'scroll',
    marginBottom: 5,
    padding: '0 0px 0px 0px !important',
  },
  fanGuidanceDuration: {
    '& .MuiSelect-select.MuiSelect-select': {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#EFF1F4',
    },
  },
  animatedRunWindow: {
    margin: 0,
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  grainWeatherInputsCardContainer: {
    borderRadius: 8,
    width: '100%',
    background: light_gray2,
    display: 'flex',
    marginBottom: 8,
    padding: 10,
  },
  autoUpdateToggle: {
    width: '100%',
    '& .Mui-selected, & .Mui-selected:hover': {
      backgroundColor: blue_shade_2,
    },
  },
  autoUpdateToggleBtn: {
    width: '100%',
    fontSize: 14,
    lineHeight: '18px',
    textTransform: 'none',
    color: amber_grey,
    padding: 5,
  },
  automationRemainDays: {
    width: 'fit-content',
    height: 21,
    backgroundColor: light_gray_7,
    borderRadius: 7,
    padding: '3px 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    color: black_shade_5,
  },
  autoToggleText1: {
    [theme.breakpoints.between(1280, 1490)]: {
      width: 135,
    },
  },
  autoToggleText2: {
    [theme.breakpoints.between(1280, 1490)]: {
      width: 134,
    },
  },
}));

type FanControllerScheduleDateFieldsType = {
  title: string;
  hourlyForecasts: any;
  isRecommendingWindows: any;
  navigation: any;
  intial_windows: any;
  all_recommended_windows: any;
  fan_guidance_start_date: any;
  showRecomendationsFlow: () => void;
  grainCondiFormSubmittedValues: GrainCondiFormSubmittedValuesType;
};

const FanControllerScheduleDateFields = ({
  title,
  hourlyForecasts,
  isRecommendingWindows,
  navigation,
  intial_windows,
  all_recommended_windows,
  fan_guidance_start_date,
  showRecomendationsFlow,
  grainCondiFormSubmittedValues,
}: FanControllerScheduleDateFieldsType) => {
  const weatherData = useWeatherData();
  const { setFieldValue, values } = useFormikContext<any>();
  const array_values = useMemo(() => values['run_windows'], [values]);
  const fan_guidance_values = useMemo(() => values['fan_guidance'], [values]);
  const autoUpdateToggleDefaultValue = fan_guidance_values.has_opt_in_fan_guidance
    ? 'autoUpdate'
    : 'noAutoUpdate';
  const [autoUpdateToggle, setAutoUpdateToggle] = useState(autoUpdateToggleDefaultValue);
  const getWindowsWithinDuration = (duration) => {
    let automationPeriodEndDate = DateTime.local().plus({
      days: duration,
    });
    if (duration >= 7) {
      automationPeriodEndDate = DateTime.local()
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .plus({
          days: 7,
        });
    }
    const runWindowsWithinAutomationPeriod = all_recommended_windows.filter(
      ({ start_date, start_time }) =>
        automationPeriodEndDate &&
        start_date &&
        start_time &&
        DateTime.fromFormat(
          getFormatedDateTimeString(start_date, start_time),
          'MM/dd/yyyy hh:mm'
        ).toMillis() <= automationPeriodEndDate.toMillis()
    );
    return runWindowsWithinAutomationPeriod;
  };

  const handleAutoUpdateToggleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value !== null) {
      setAutoUpdateToggle(value);
      setFieldValue('fan_guidance.has_opt_in_fan_guidance', value === 'autoUpdate');
      if (value === 'autoUpdate') {
        const duration = fan_guidance_values.fan_guidance_duration;
        const runWindowsWithinAutomationPeriod = getWindowsWithinDuration(duration);
        setFieldValue('run_windows', runWindowsWithinAutomationPeriod);
      } else {
        setFieldValue('run_windows', all_recommended_windows);
      }
    }
  };

  const [avgGrainConditions, setAvgGrainConditions] = useState([]);
  const [overlap_error, setOverlapError] = useState('');
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);
  const durationOptions = [10, 6, 5, 4, 3, 2, 1];
  const editableRunWindows = !(
    isRecommendingWindows && fan_guidance_values.has_opt_in_fan_guidance
  );
  const classes = useStyles({ editableRunWindows });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const dispatch = useDispatch();
  const current_run_windows: { start: DateTime; end: DateTime }[] = array_values
    .filter((value) => {
      const { start_date, start_time, end_date, end_time } = value;
      return Boolean(start_date && start_time && end_date && end_time);
    })
    .map((value) => {
      const { start_date, start_time, end_date, end_time } = value;
      const start =
        DateTime.fromFormat(
          getFormatedDateTimeString(start_date, start_time),
          'MM/dd/yyyy hh:mm'
        ).toMillis() < DateTime.local().toMillis()
          ? DateTime.local()
          : DateTime.fromFormat(
              getFormatedDateTimeString(start_date, start_time),
              'MM/dd/yyyy hh:mm'
            );
      const end = DateTime.fromFormat(
        getFormatedDateTimeString(end_date, end_time),
        'MM/dd/yyyy hh:mm'
      );
      return { start, end };
    });

  useEffect(() => {
    console.log({ isRecommendingWindows });

    // map to windows and remove any invalid schedules
    const windows = values.run_windows
      .map((window) => ({
        start_epoch: `${window.start_date} ${window.start_time}`,
        end_epoch: `${window.end_date} ${window.end_time}`,
      }))
      .filter(checkValidSchedule);
    const collapsed_windows = collapseWindows(windows);

    dispatch(setFanScheduleDrafts(collapsed_windows));
    collapsed_windows.length === windows.length
      ? setOverlapError('')
      : setOverlapError(OVERLAP_ERROR);

    const refreshedAverageGrainConditions = values.run_windows.map(
      ({ start_date, start_time, end_date, end_time }) => {
        /// When the user selects 'Now', the start date is always -1 day before the
        /// current datetime
        let start = DateTime.utc().toMillis();
        const now = DateTime.fromFormat(`${start_date}`, 'L/d/yyyy').toMillis();
        if (now > start) {
          start = DateTime.fromFormat(`${start_date} ${start_time}`, 'L/d/yyyy H:mm').toMillis();
        }

        const end = DateTime.fromFormat(`${end_date} ${end_time}`, 'L/d/yyyy H:mm').toMillis();
        const [temp, rh] = computeAvgGrainConditions(start, end, hourlyForecasts);

        return { temp, rh };
      }
    );

    setAvgGrainConditions(refreshedAverageGrainConditions);
  }, [values.run_windows]);

  useEffect(() => {
    setAutoUpdateToggle(autoUpdateToggleDefaultValue);
  }, [autoUpdateToggleDefaultValue]);

  const renderTitleText = () => {
    switch (navigation.recommendationOptionValue.option) {
      case RecommendedOption.COOLING:
        return 'Cooling';
      case RecommendedOption.DRYING:
        return 'Drying';
      case RecommendedOption.RECONDITIONING:
        return 'Reconditioning';
    }
  };

  const emptyWindowMessageText = () => {
    if (array_values.length === 0) {
      switch (navigation.recommendationOptionValue.option) {
        case RecommendedOption.COOLING:
          return 'No Recommended Cooling Windows';
        case RecommendedOption.DRYING:
          return 'No Recommended Drying Windows';
        case RecommendedOption.RECONDITIONING:
          return 'No Recommended Reconditioning Windows';
      }
    } else {
      return '';
    }
  };

  const handleDurationBasedRecommWindows = (value) => {
    const runWindowsWithinAutomationPeriod = getWindowsWithinDuration(value);
    setFieldValue('run_windows', runWindowsWithinAutomationPeriod);
  };

  const fanGuidanceEndDate = DateTime.local().plus({
    days: fan_guidance_values.fan_guidance_duration || 10,
  });

  const asOf = DateTime.local();

  const isTodayDate = (date: DateTime) =>
    date && date.day === asOf.day && date.month === asOf.month && date.year === asOf.year;

  const tomorrow = asOf.plus({ days: 1 });
  const isTomorrowDate = (date: DateTime) =>
    date &&
    date.day === tomorrow.day &&
    date.month === tomorrow.month &&
    date.year === tomorrow.year;

  const calculateRunwindowDuration = ({ startDate, endDate, startTime, endTime }) => {
    const validInput = startDate && endDate && startTime && endTime;
    if (!validInput) return 0;

    const start =
      DateTime.fromFormat(
        getFormatedDateTimeString(startDate, startTime),
        'MM/dd/yyyy hh:mm'
      ).toMillis() < DateTime.local().toMillis()
        ? DateTime.local()
        : DateTime.fromFormat(getFormatedDateTimeString(startDate, startTime), 'MM/dd/yyyy hh:mm');
    const end = DateTime.fromFormat(
      getFormatedDateTimeString(endDate, endTime),
      'MM/dd/yyyy hh:mm'
    );
    const start_epoch = start.toMillis();
    const end_epoch = end.toMillis();

    // handle end date as forever case
    if (Math.round(end.diff(start, 'year').toObject().years) === 1) return Infinity;

    const hrs = end_epoch / (1000 * 3600) - start_epoch / (1000 * 3600);
    if (hrs < 1) return hrs;
    return Math.round(hrs);
  };

  const totalRunHours = useMemo(() => {
    return array_values && array_values.length
      ? array_values.reduce(
          (
            total,
            { start_date: startDate, start_time: startTime, end_date: endDate, end_time: endTime }
          ) =>
            total +
            calculateRunwindowDuration({
              startDate,
              endDate,
              startTime,
              endTime,
            }),
          0
        )
      : 0;
  }, [array_values]);

  const totalRunHoursText = getDurationText(totalRunHours);
  console.log('total Run Hours', totalRunHours, totalRunHoursText);

  const getAutomationEndPeriodText = (days) => {
    let automationPeriodEndDate = DateTime.local().plus({
      days,
    });

    if (days >= 7) {
      automationPeriodEndDate = DateTime.local()
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .plus({
          days: 7,
        });
    }

    const addNextKeyword =
      automationPeriodEndDate.has_opt_in_fan_guidance &&
      automationPeriodEndDate.fan_guidance_duration === 10;

    return (
      <>
        (until {addNextKeyword && 'next'}{' '}
        {isTodayDate(automationPeriodEndDate)
          ? 'Today'
          : isTomorrowDate(automationPeriodEndDate)
          ? 'Tomorrow'
          : automationPeriodEndDate.toFormat('ccc')}{' '}
        {fanGuidanceEndDate.toFormat('t')})
      </>
    );
  };

  if (!weatherData) return null;

  const showAutomationPeriodSelectSection =
    fan_guidance_values && fan_guidance_values.has_opt_in_fan_guidance;
  const as_of = DateTime.local();
  const remainingAutomationPeriodString =
    showAutomationPeriodSelectSection && fanGuidanceEndDate
      ? getRemainingDaysAutomationPeriodString(as_of, fanGuidanceEndDate)
      : '';

  const hasScheduledRunWindowExistAfterFanGuidanceEnded =
    fan_guidance_values &&
    fan_guidance_values.has_opt_in_fan_guidance &&
    array_values.length > 0 &&
    as_of.toMillis() > fanGuidanceEndDate.toMillis();

  const tempUnit = getTemperatureUnitLabel();

  return (
    <>
      <Typography
        variant="h5"
        style={{ fontWeight: 600, width: '100%', padding: '0px', marginBottom: 24 }}
      >
        {title}
      </Typography>
      {isRecommendingWindows && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className={classes.grainWeatherInputsCardContainer}>
            <GrainInputsWithMode
              current_grain_temp={
                grainCondiFormSubmittedValues
                  ? grainCondiFormSubmittedValues.current_grain_temp
                  : null
              }
              current_grain_emc={
                grainCondiFormSubmittedValues
                  ? grainCondiFormSubmittedValues.current_grain_emc
                  : null
              }
              target_grain_emc={
                grainCondiFormSubmittedValues
                  ? grainCondiFormSubmittedValues.target_grain_emc || null
                  : null
              }
              mode={getRecommedationTypeMode(
                grainCondiFormSubmittedValues
                  ? grainCondiFormSubmittedValues.recommendation_type
                  : null
              )}
              modeColor={renderModeColor(
                grainCondiFormSubmittedValues
                  ? grainCondiFormSubmittedValues.recommendation_type
                  : null
              )}
              showRecomendationsFlow={showRecomendationsFlow}
            />
          </div>

          <Grid
            item
            xs={12}
            style={{
              width: '100%',
            }}
          >
            <div
              className={classes.toggleAutomationSection}
              style={{
                borderRadius: showAutomationPeriodSelectSection ? '8px 8px 0px 0px' : 8,
                marginBottom: showAutomationPeriodSelectSection ? 0 : 10,
              }}
            >
              <ToggleButtonGroup
                value={autoUpdateToggle}
                exclusive
                onChange={handleAutoUpdateToggleChange}
                aria-label="Toggle Automation"
                className={classes.autoUpdateToggle}
              >
                <ToggleButton
                  className={classes.autoUpdateToggleBtn}
                  style={{
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                    border: '1px solid #707070',
                  }}
                  value="noAutoUpdate"
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
                      <AutomationDisabledIcon style={{ width: 20, height: 20 }} />
                    </div>
                    <div className={classes.autoToggleText1}> Don't update if weather changes</div>
                  </div>
                </ToggleButton>
                <ToggleButton
                  style={{
                    borderTopRightRadius: 6,
                    borderBottomRightRadius: 6,
                    border: '1px solid #707070',
                  }}
                  className={classes.autoUpdateToggleBtn}
                  value="autoUpdate"
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
                      <AutomationEnabledIcon style={{ width: 24, height: 24 }} />
                    </div>
                    <div className={classes.autoToggleText2}> Auto-update if weather changes</div>
                  </div>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            {showAutomationPeriodSelectSection && (
              <Grid
                container
                className={classes.automationPeriodSelectContainer}
                style={{ marginBottom: showAutomationPeriodSelectSection ? 10 : 0 }}
              >
                <Grid
                  item
                  xs={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: '15px',
                    letterSpacing: '0.1px',
                  }}
                >
                  <AutomationDurationIcon style={{ marginRight: 10 }} />
                  Automation Period
                </Grid>
                <Grid
                  item
                  xs={7}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                  }}
                >
                  <Select
                    name="fan_guidance.fan_guidance_duration"
                    // fullWidth
                    onChange={handleDurationBasedRecommWindows}
                    className={classes.fanGuidanceDuration}
                  >
                    {durationOptions.map((day) => {
                      if (day === 1) {
                        return (
                          <MenuItem key={day} value={day}>
                            {day} day {getAutomationEndPeriodText(day)}
                          </MenuItem>
                        );
                      }
                      if (day === 10) {
                        return (
                          <MenuItem key={day} value={day}>
                            Continuous (Weekly Extension Required)
                          </MenuItem>
                        );
                      }
                      return (
                        <MenuItem key={day} value={day}>
                          {day} days {getAutomationEndPeriodText(day)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      )}
      {/* Runwindow chart starts */}
      <Grid item className={classes.runwindowWeatherPlotContainer}>
        <RunwindowWeatherPlot
          grain_bin_location_timezone={weatherData.grain_bin_location_timezone}
          hourly_forecast_data={weatherData.hourlyForecast}
          days={weatherData.dailyForecast}
          runWindows={current_run_windows}
        />
      </Grid>
      {/* Runwindow chart ends */}
      <div style={{ marginBottom: 15, width: '100%' }}>
        <DividerLine />
      </div>
      {isRecommendingWindows && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '16px',
              width: '100%',
            }}
          >
            <div>
              <Typography
                variant="h6"
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                  paddingLeft: 4,
                  fontSize: 17,
                  color: 'black',
                  lineHeight: '20.4px',
                }}
                gutterBottom={true}
              >
                {renderTitleText()} Schedule{' '}
              </Typography>
            </div>
            <div className={classes.runwindowCountBadge}>
              {array_values ? array_values.length : '-'}
            </div>
          </div>
          {array_values.length === 0 && (
            <div style={{ marginBottom: '20px', width: '100%' }}>
              <NoSchedulePlaceholder
                message={
                  isRecommendingWindows && fan_guidance_values.has_opt_in_fan_guidance
                    ? `${emptyWindowMessageText()} within selected Automation Period`
                    : emptyWindowMessageText()
                }
              />
            </div>
          )}
        </div>
      )}
      <FieldArray name="run_windows">
        {(arrayHelpers: ArrayHelpers) => {
          return (
            <>
              <div style={{ width: '100%' }}>
                <AnimatePresence>
                  {array_values && array_values.length
                    ? array_values.map((value, ix) => {
                        const { start_date, start_time, end_date, end_time } = value;
                        const start =
                          DateTime.fromFormat(
                            getFormatedDateTimeString(start_date, start_time),
                            'MM/dd/yyyy hh:mm'
                          ).toMillis() < DateTime.local().toMillis()
                            ? DateTime.local()
                            : DateTime.fromFormat(
                                getFormatedDateTimeString(start_date, start_time),
                                'MM/dd/yyyy hh:mm'
                              );
                        const end = DateTime.fromFormat(
                          getFormatedDateTimeString(end_date, end_time),
                          'MM/dd/yyyy hh:mm'
                        );
                        // calculate run window duration
                        const duration = calculateRunwindowDuration({
                          startDate: value.start_date,
                          endDate: value.end_date,
                          startTime: value.start_time,
                          endTime: value.end_time,
                        });

                        const estimatedTemp = avgGrainConditions
                          ? avgGrainConditions[ix]
                            ? `${formatNumber(avgGrainConditions[ix]['temp'], 0)}${tempUnit}`
                            : `- ${tempUnit}`
                          : null;
                        const estimatedRH = avgGrainConditions
                          ? avgGrainConditions[ix]
                            ? `${formatNumber(avgGrainConditions[ix]['rh'] * 100, 0)}% RH`
                            : '- RH'
                          : null;
                        const now = DateTime.local();
                        // the select option's value for now will be yesterday (since otherwise the date of "now" and "today" are identical)

                        const is_now =
                          value.start_date &&
                          DateTime.fromFormat(`${value.start_date}`, 'MM/dd/yyyy').toMillis() <
                            DateTime.fromFormat(
                              now.toFormat('MM/dd/yyyy'),
                              'MM/dd/yyyy'
                            ).toMillis();

                        const addedYearDate = now.plus({ years: 1 });
                        const NO_END = DateTime.fromFormat(
                          addedYearDate.toFormat('MM/dd/yyyy'),
                          'MM/dd/yyyy'
                        );
                        const is_no_end = Boolean(
                          NO_END &&
                            NO_END.toMillis() <=
                              DateTime.fromFormat(
                                getFormatedDateTimeString(value.end_date, value.end_time),
                                'MM/dd/yyyy hh:mm'
                              ).toMillis()
                        );

                        console.log('is_no_end', { is_no_end, value, NO_END });
                        const first_custom_option_dt = getNextStart(DateTime.local());
                        const first_dt = first_custom_option_dt.startOf('day');

                        const handleDeleteRunwindow = () => {
                          // not using array helpers' remove because it precludes empty array as a valid value
                          const new_values = [...array_values];
                          new_values.splice(ix, 1);
                          setFieldValue('run_windows', new_values);
                        };

                        // if today is the selected date, times may need to be filtered
                        let filtered_start_times = TIMES_ARRAY;
                        let filtered_end_times = TIMES_ARRAY;
                        // if date selected is today
                        if (value.start_date === DateTime.local().toFormat('MM/dd/yyyy')) {
                          // filtered start times
                          filtered_start_times = TIMES_ARRAY.slice(
                            findNearestTimeFloor(TIMES_ARRAY, now.plus({ minutes: 30 }))
                          );

                          // if value missing, add it
                          if (
                            value.start_time &&
                            filtered_start_times.findIndex((time) => time === value.start_time) ===
                              -1
                          ) {
                            filtered_start_times = [value.start_time, ...filtered_start_times];
                            // setFieldValue(`run_windows.${ix}.start_time`, value.start_time);
                          }
                        }
                        if (value.end_date === DateTime.local().toFormat('MM/dd/yyyy')) {
                          // filtered end times
                          filtered_end_times = TIMES_ARRAY.slice(
                            findNearestTimeFloor(TIMES_ARRAY, now.plus({ hours: 1 }))
                          );
                        }

                        // if end_time value is missing, then add it
                        if (
                          value.end_time &&
                          filtered_end_times.findIndex((time) => time === value.end_time) === -1
                        ) {
                          filtered_end_times = [value.end_time, ...filtered_end_times];
                        }

                        const variants = {
                          initial: {
                            opacity: 0,
                            y: 60,
                          },
                          animate: {
                            opacity: 1,
                            y: 0,
                            transition: {
                              delay: 0.1,
                              duration: 0.1,
                              ease: 'easeInOut',
                            },
                          },
                        };
                        return (
                          <motion.div
                            key={`animated-run-windows-${ix}`}
                            variants={variants}
                            initial="initial"
                            animate="animate"
                            className={classes.animatedRunWindow}
                          >
                            {editableRunWindows ? (
                              <RunWindow
                                index={ix}
                                key={`run-windows-${ix}`}
                                runwindowCurrentValue={value}
                                estimatedTemp={estimatedTemp}
                                estimatedRH={estimatedRH}
                                runtime={duration}
                                is_now={is_now}
                                is_no_end={is_no_end}
                                filtered_start_times={filtered_start_times}
                                filtered_end_times={filtered_end_times}
                                handleDeleteRunwindow={handleDeleteRunwindow}
                                showLoadingAnimation={showLoadingAnimation}
                                recommendationOptionValue={
                                  isRecommendingWindows
                                    ? navigation.recommendationOptionValue.option
                                    : 'Custom'
                                }
                              />
                            ) : (
                              <RunWinowReadOnly
                                index={ix}
                                key={`run-windows-${ix}`}
                                start={start}
                                end={end}
                                estimatedTemp={estimatedTemp}
                                estimatedRH={estimatedRH}
                                runtime={duration}
                                showLoadingAnimation={showLoadingAnimation}
                                recommendationOptionValue={
                                  navigation.recommendationOptionValue.option
                                }
                              />
                            )}
                          </motion.div>
                        );
                      })
                    : null}
                </AnimatePresence>

                {isRecommendingWindows &&
                  fan_guidance_values.has_opt_in_fan_guidance &&
                  !hasScheduledRunWindowExistAfterFanGuidanceEnded &&
                  remainingAutomationPeriodString && (
                    <Grid
                      style={{ width: '100%', marginTop: -10, marginBottom: 16, paddingLeft: 4 }}
                    >
                      <div className={classes.automationRemainDays}>
                        {remainingAutomationPeriodString}
                      </div>
                    </Grid>
                  )}

                <div style={{ width: '100%', marginBottom: 20 }}>
                  <DividerLine />
                </div>

                {editableRunWindows && array_values && array_values.length > 0 && (
                  <Grid item xs={12} style={{ fontSize: 16, marginBottom: 10 }}>
                    <div className={classes.row}>
                      Total Run Time:&nbsp;
                      <strong>{totalRunHoursText}</strong>
                    </div>
                  </Grid>
                )}

                {overlap_error && (
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <div className={classes.row} style={{ color: amber_red }}>
                      {overlap_error}
                    </div>
                  </Grid>
                )}

                {!(isRecommendingWindows && fan_guidance_values.has_opt_in_fan_guidance) &&
                  array_values.length < 6 && (
                    <Grid item xs={12} style={{ padding: '8px 0px' }}>
                      <Button
                        style={{ margin: '10px 0', borderRadius: 4 }}
                        fullWidth
                        onClick={() => {
                          if (showLoadingAnimation) setShowLoadingAnimation(false);
                          arrayHelpers.push({
                            start_date: '',
                            end_date: '',
                            start_time: '',
                            end_time: '',
                          });
                        }}
                        variant="outlined"
                      >
                        + Add Another Run Window
                      </Button>
                    </Grid>
                  )}
              </div>

              {!editableRunWindows && array_values && array_values.length > 0 && (
                <Grid item xs={12} style={{ fontSize: 16, marginBottom: 10 }}>
                  <div className={classes.row}>
                    Total Run Time:&nbsp;
                    <strong>{totalRunHoursText}</strong>
                  </div>
                </Grid>
              )}
            </>
          );
        }}
      </FieldArray>
    </>
  );
};

export { FanControllerScheduleDateFields };
