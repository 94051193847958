export enum RecommendedOption {
  COOLING = 'COOLING',
  DRYING = 'DRYING',
  RECONDITIONING = 'RECONDITIONING',
}

export interface RecommendedOptionValue {
  displayName: string;
  option: RecommendedOption;
}

export const RECOMMENDATION_OPTIONS: RecommendedOptionValue[] = [
  { displayName: 'Cooling', option: RecommendedOption.COOLING },
  { displayName: 'Drying', option: RecommendedOption.DRYING },
  { displayName: 'Reconditioning', option: RecommendedOption.RECONDITIONING },
];
