import * as React from 'react';
const EndIcon = (props) => (
  <svg
    width={16}
    height={15}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.875 14.96C11.915 14.96 15.1949 11.68 15.1949 7.64001C15.1949 3.60001 11.915 0.319946 7.875 0.319946C3.835 0.319946 0.554993 3.60001 0.554993 7.64001C0.554993 11.68 3.835 14.96 7.875 14.96ZM13.865 7.64001C13.865 4.33001 11.185 1.65002 7.875 1.65002C4.565 1.65002 1.88495 4.33001 1.88495 7.64001C1.88495 10.95 4.565 13.63 7.875 13.63C11.185 13.63 13.865 10.95 13.865 7.64001ZM5.54498 5.30994H10.205V9.96997H5.54498V5.30994Z"
      fill="#E25950"
    />
  </svg>
);
export { EndIcon };
