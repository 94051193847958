import { Button, makeStyles, Theme } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react';
import { FanRunWindowRecommendedOption } from '../../../../../core/src/api';
import {
  BlurTargetMoistureIcon,
  GrainMoistureIconWithoutShade,
  GrainTemperatureWithoutShade,
} from '../../../../../core/src/media/icons';
import { black_shade_2, light_gray_4, light_gray_6 } from '../../../../../core/src/style/color';
import { getRecommedationTypeMode } from './FanSchedule';
import { Mode } from './Mode';
const useStyles = makeStyles((theme: Theme) => ({
  grainWeatherInputsCard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'start',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'space-between',
    },
  },
  label: {
    fontSize: 12,
    textTransform: 'uppercase',
    color: light_gray_4,
    marginBottom: 4,
  },
  inputValueConatiner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
  },
  inputValue: {
    fontSize: 16,
    marginRight: 5,
    color: light_gray_6,
  },
  centerStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  editWeatherInptsBtn: {
    width: 37,
    minWidth: 37,
    height: 37,
    borderRadius: 8,
    border: '1px solid #717172',
    padding: 10,
  },
}));

type GrainInputsWithModeProps = {
  current_grain_temp: number | null;
  target_grain_emc: number | null;
  current_grain_emc: number | null;
  mode: string;
  modeColor: string;
  showRecomendationsFlow: () => void;
};

export const GrainInputsWithMode = ({
  current_grain_temp,
  target_grain_emc,
  current_grain_emc,
  mode,
  modeColor,
  showRecomendationsFlow,
}: GrainInputsWithModeProps) => {
  const classes = useStyles();
  const currentGrainTemp =
    current_grain_temp !== undefined && current_grain_temp !== null
      ? `${current_grain_temp}º`
      : 'NA';
  const currentGrainEmc = current_grain_emc ? `${current_grain_emc}%` : 'NA';
  const targetGrainEmc = target_grain_emc ? `${target_grain_emc}%` : 'NA';
  const hasNonCoolingMode = mode !== 'Cool';
  const hasCoolingMode = mode === 'Cool';
  return (
    <div className={classes.grainWeatherInputsCard}>
      <div className={classes.centerStyles}>
        <div className={classes.label}>Mode</div>
        <Mode mode={mode} modeColor={modeColor} customStyle={{ height: 21, width: 71 }} />
      </div>
      <div className={classes.centerStyles}>
        <div className={classes.label}>Input</div>
        <div className={classes.inputValueConatiner}>
          <div className={classes.inputValue}>
            {hasCoolingMode ? currentGrainTemp : currentGrainEmc}
          </div>
          <div>
            {hasCoolingMode ? (
              <GrainTemperatureWithoutShade style={{ width: 10, height: 20 }} />
            ) : (
              <GrainMoistureIconWithoutShade style={{ width: 16, height: 18 }} />
            )}
          </div>
        </div>
      </div>
      {hasNonCoolingMode && (
        <div className={classes.centerStyles}>
          <div className={classes.label}>Target</div>
          <div className={classes.inputValueConatiner}>
            <div className={classes.inputValue}>{targetGrainEmc}</div>
            <div style={{ display: 'flex' }}>
              <BlurTargetMoistureIcon style={{ width: 25, height: 25 }} />
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          height: '100%',
        }}
      >
        <Button
          className={classes.editWeatherInptsBtn}
          style={{ backgroundColor: 'white' }}
          onClick={showRecomendationsFlow}
        >
          <Edit style={{ width: 20, height: 20, color: 'black' }} />
        </Button>
      </div>
    </div>
  );
};
