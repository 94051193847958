import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  PileCableLinkFragmentFragment as Result,
  withGetActivePileCableLinks as HOC,
} from '../__generated';

export type WithGetActivePileCableLinksHocOwnProps = {
  pile_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetActivePileCableLinksHocChildProps = {
  loading: boolean;
  active_pile_cable_links: Result[] | null;
};
export const withGetActivePileCableLinksHoc: AmberHoc<
  WithGetActivePileCableLinksHocOwnProps,
  WithGetActivePileCableLinksHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetActivePileCableLinksHocOwnProps,
      WithGetActivePileCableLinksHocChildProps,
      typeof component
    >,
    WithGetActivePileCableLinksHocChildProps
  >({
    options: ({ pile_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      variables: { pile_id },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          active_pile_cable_links: null,
        };
      }
      return {
        loading: loading || false,
        active_pile_cable_links: data.active_pile_cable_links || null,
      };
    },
  })(component as any);
