import React from 'react';
import { formatDateTime } from './FanScheduleStartCellRenderer';
import { withSkeleton } from './withCellSkeleton';

const FanHistoryEndCellRenderer = withSkeleton((props) => {
  const lastAeratioRun = props.value;
  const grain_bin_location_timezone = props.data.grain_bin_location_timezone;

  if (!lastAeratioRun) {
    return <>No Previous Fan Run</>;
  }

  if (!lastAeratioRun.end_epoch) return <div>Forever</div>;

  const { dateString, timeString } = formatDateTime(
    new Date(lastAeratioRun.end_epoch),
    grain_bin_location_timezone
  );

  return (
    <div>
      {dateString}
      <br />
      {timeString}
    </div>
  );
});

export { FanHistoryEndCellRenderer };
