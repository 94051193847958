import { ActionType, getType } from 'typesafe-actions';
import { setUserTimeZone } from '../action';

const actions = { setUserTimeZone };

export const default_user_timezone_state: UserTimezoneState = {
  timezone: null,
  offset: null,
};

export type UserTimezoneState = {
  timezone: string | null;
  offset: number | null;
};
export const userTimezoneReducer = (
  state: UserTimezoneState = default_user_timezone_state,
  action: ActionType<typeof actions>
): UserTimezoneState => {
  switch (action.type) {
    case getType(setUserTimeZone): {
      return {
        ...state,
        timezone: action.payload.timezone,
        offset: action.payload.offset,
      };
    }
    default:
      return state;
  }
};
