import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { getUserUnitsPref, USER_UNITS_PREF_KEY } from '../../../../core/src/util';
import {
  DistanceUnitType,
  MassUnitType,
  TempUnitType,
  TestWeightUnitType,
  withSetUserUnitPrefHoc,
  withSetUserUnitPrefHocChildProps,
} from '../../api';
import { amber_amber } from '../../style';
import { DialogSpinner } from '../spinner';
import { Button } from '../util';
import { SelectDistanceUnit } from '../util/form2/SelectDistanceUnit';
import { SelectMassUnit } from '../util/form2/SelectMassUnit';
import { SelectTemperatureUnit } from '../util/form2/SelectTemperatureUnit';
import { SelectTestWeightUnit } from '../util/form2/SelectTestWeightUnit';

const useStyles = makeStyles((theme: Theme) => ({
  centered: { textAlign: 'start', marginBottom: 10 },
  tabHeader: {
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  container: {
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    padding: '0px 20px',
    marginTop: 20,
  },
  grid: {
    minWidth: 250,
    maxWidth: 600,
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
}));

type UnitsSettingFormProps = {
  account_id: number;
  user_id: number;
} & withSetUserUnitPrefHocChildProps;

export const UnitsSettingForm = withSetUserUnitPrefHoc(
  ({ account_id, user_id, setUserUnitsPref }: UnitsSettingFormProps) => {
    const classes = useStyles();
    const [isUpdating, setUpdating] = React.useState(false);
    const localeUnitSystem = getUserUnitsPref();
    console.log('localeUnitSystem', localeUnitSystem);
    const initUnitsSettings = {
      distance_unit: localeUnitSystem ? localeUnitSystem.distance_unit : DistanceUnitType.Feet,
      temp_unit: localeUnitSystem ? localeUnitSystem.temp_unit : TempUnitType.Fahrenheit,
      mass_unit: localeUnitSystem ? localeUnitSystem.mass_unit : MassUnitType.Pound,
      test_weight_unit: localeUnitSystem
        ? localeUnitSystem.test_weight_unit
        : TestWeightUnitType.PoundPerUsBushel,
    };
    const [unitsSetting, setUnitsSetting] = useState(initUnitsSettings);

    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log('unitsSetting in handleSubmit', unitsSetting);
      try {
        setUpdating(true);
        const response = await setUserUnitsPref({
          account_id,
          user_id,
          distance_unit: unitsSetting.distance_unit,
          temp_unit: unitsSetting.temp_unit,
          mass_unit: unitsSetting.mass_unit,
          test_weight_unit: unitsSetting.test_weight_unit,
        });
        if (response) {
          localStorage.setItem(USER_UNITS_PREF_KEY, JSON.stringify(response));
          window.location.href = window.location.origin;
        }
      } catch (error) {
        console.error(error);
        setUpdating(false);
      }
    };

    const handleUnitChange = ({
      unitName,
      value,
    }: {
      unitName: string;
      value: DistanceUnitType | TempUnitType | MassUnitType | TestWeightUnitType;
    }) => {
      setUnitsSetting((prev) => ({ ...prev, [unitName]: value }));
    };

    if (isUpdating) {
      return <DialogSpinner title="Saving..." open={isUpdating} />;
    }

    return (
      <form
        onSubmit={handleSubmit}
        className={classes.container}
        style={{ margin: 0, padding: 0, width: '100%' }}
      >
        <Grid container direction="column" spacing={2} className={classes.grid}>
          <Grid
            item
            xs={12}
            className={classes.centered}
            style={{ marginTop: 20, marginBottom: 0 }}
          >
            <Typography variant="h5" className={classes.tabHeader}>
              Localization - Select local units
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ textAlign: 'start', marginBottom: 5 }}>
              <label htmlFor="distance_unit">Distance</label>
            </div>
            <SelectDistanceUnit
              name="distance_unit"
              selectedValue={unitsSetting.distance_unit}
              onChange={({ value }) => handleUnitChange({ value, unitName: 'distance_unit' })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ textAlign: 'start', marginBottom: 5 }}>
              <label htmlFor="temp_unit">Temperature</label>
            </div>
            <SelectTemperatureUnit
              name="temp_unit"
              selectedValue={unitsSetting.temp_unit}
              onChange={({ value }) => handleUnitChange({ value, unitName: 'temp_unit' })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ textAlign: 'start', marginBottom: 5 }}>
              <label htmlFor="mass_unit">Mass</label>
            </div>
            <SelectMassUnit
              name="mass_unit"
              selectedValue={unitsSetting.mass_unit}
              onChange={({ value }) => handleUnitChange({ value, unitName: 'mass_unit' })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ textAlign: 'start', marginBottom: 5 }}>
              <label htmlFor="test_weight_unit">Test Weight</label>
            </div>
            <SelectTestWeightUnit
              name="test_weight_unit"
              selectedValue={unitsSetting.test_weight_unit}
              onChange={({ value }) => handleUnitChange({ value, unitName: 'test_weight_unit' })}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'start', marginTop: 20 }}>
            <Button type="submit" style={{ margin: 0 }}>
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
);
