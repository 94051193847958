import { AmberHoc, AmberHocProps } from '../../../util';
import {
  UnsubscribeWeeklyGrainEmailMutation as Result,
  UnsubscribeWeeklyGrainEmailMutationVariables as Variables,
  withUnsubscribeWeeklyGrainEmail as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUnsubscribeWeeklyGrainEmailHocChildProps = {
  unsubscribeWeeklyGrainEmail: (
    variables: Variables
  ) => Promise<Result['unsubscribeWeeklyGrainEmail']>;
};

export const withUnsubscribeWeeklyGrainEmailHoc: AmberHoc<
  {},
  WithUnsubscribeWeeklyGrainEmailHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithUnsubscribeWeeklyGrainEmailHocChildProps, typeof component>,
    WithUnsubscribeWeeklyGrainEmailHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        unsubscribeWeeklyGrainEmail: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }

          const result = await mutate({ variables });

          if (!result) {
            throw new Error('Unexpected server response');
          }

          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.unsubscribeWeeklyGrainEmail;
        },
      };
    },
  })(component as any);
