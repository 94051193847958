import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetViewerDocument,
  GetViewerQuery,
  SetUserPasswordMutationVariables as Variables,
  ViewerFragmentFragment as Result,
  withSetUserPassword as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithSetUserPasswordHocChildProps = {
  setUserPassword: (variables: Variables) => Promise<Result>;
};
export const withSetUserPasswordHoc: AmberHoc<{}, WithSetUserPasswordHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithSetUserPasswordHocChildProps, typeof component>,
    WithSetUserPasswordHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        setUserPassword: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { setUserPassword: result } = data;
              if (!result) {
                return;
              }
              proxy.writeQuery<GetViewerQuery>({
                query: GetViewerDocument,
                data: { __typename: 'Query', viewer: result },
              });
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.setUserPassword;
        },
      };
    },
  })(component as any);
