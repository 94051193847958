import { makeStyles } from '@material-ui/core';
import React from 'react';
import { NormalInfoIcon } from '../../../../core/src/media';
import { amber_light_grey } from '../../../../core/src/style';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    padding: 8,
    border: `1px solid ${amber_light_grey}`,
  },
  infoIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
  },
  infoContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    textAlign: 'start',
  },
});

type InfoProps = {
  children: React.ReactNode;
  style?: object;
};

const Info = ({ children, style = {} }: InfoProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      <div className={classes.infoIcon}>
        <NormalInfoIcon />
      </div>
      <div className={classes.infoContent}>{children}</div>
    </div>
  );
};

export default Info;
