import { DialogContent } from '@material-ui/core';
import React from 'react';

import {
  GrainBinFanControllerFragmentFragment,
  withGetFanControllerHoc,
  WithGetFanControllerHocChildProps,
} from '../../../api';
import { DialogSpinner } from '../../spinner';
import { BaseDialog } from '../../util';
import { UpdateGrainBinFanControllerForm } from './UpdateGrainBinFanControllerForm';

export type UpdateGrainBinFanControllerDialogProps = {
  open: boolean;
  grain_bin_fan_controller: GrainBinFanControllerFragmentFragment | null;
  onClose: () => void;
};

export const UpdateGrainBinFanControllerDialogBase: React.FunctionComponent<
  UpdateGrainBinFanControllerDialogProps & WithGetFanControllerHocChildProps
> = ({ open, grain_bin_fan_controller, onClose, fan_controller, loading }) => {
  if (!grain_bin_fan_controller) {
    return null;
  }
  if (loading) {
    return <DialogSpinner title="Loading fan controllers..." open={loading} />;
  }

  return (
    <BaseDialog
      open={open}
      handleClose={onClose}
      title={`Update ${grain_bin_fan_controller.fan_controller &&
        grain_bin_fan_controller.fan_controller.alias}`}
      showCloseButton={false}
    >
      <DialogContent style={{ paddingBottom: 16 }}>
        <UpdateGrainBinFanControllerForm
          grain_bin_fan_controller={grain_bin_fan_controller}
          fan_controller={fan_controller}
          // using set timeout to put the close on the event loop to allow formik to finish up and avoid an error
          onSubmitSuccess={() => setTimeout(onClose, 0)}
          handleClose={onClose}
        />
      </DialogContent>
    </BaseDialog>
  );
};

export const UpdateGrainBinFanControllerDialog = withGetFanControllerHoc(
  UpdateGrainBinFanControllerDialogBase
);
