import {
  alertReducer,
  AlertState,
  GlobalState,
  globalStateReducer,
  navBarReducer,
  NavBarState,
  scheduleReducer,
  ScheduleState,
  selectedReducer,
  SelectedState,
  sessionReducer,
  SessionState,
  userTimezoneReducer,
  UserTimezoneState,
  versionReducer,
  VersionState,
} from '@amber-ui/core/lib';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { FormStateMap, reducer as form } from 'redux-form';

export type State = {
  router: RouterState;
  selected: SelectedState;
  nav_bar: NavBarState;
  alert: AlertState;
  session: SessionState;
  form: FormStateMap;
  version: VersionState;
  schedule: ScheduleState;
  user_browser_timezone: UserTimezoneState;
  global_state: GlobalState;
};
export const createRootReducer = (history: History) =>
  combineReducers<State>({
    form,
    router: connectRouter(history),
    selected: selectedReducer,
    nav_bar: navBarReducer,
    alert: alertReducer,
    session: sessionReducer,
    version: versionReducer,
    schedule: scheduleReducer,
    user_browser_timezone: userTimezoneReducer,
    global_state: globalStateReducer,
  });

export default createRootReducer;
