import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetContainersWeatherQuery as Result,
  withGetContainersWeather as HOC,
} from '../__generated';

export type WithGetContainersWeatherProps = {
  initial_grain_bin_ids: number[];
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetContainersWeatherHocChildProps = {
  containers_weather_loading: boolean;
  containers_weather: Result['containers_weather'];
  containersWeatherRefetch: () => Promise<any>;
  loadMoreContainersWeather: (grain_bin_ids: number[]) => Promise<any>;
};
export const withGetContainersWeatherHoc: AmberHoc<
  WithGetContainersWeatherProps,
  WithGetContainersWeatherHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetContainersWeatherProps,
      WithGetContainersWeatherHocChildProps,
      typeof component
    >,
    WithGetContainersWeatherHocChildProps
  >({
    options: ({ initial_grain_bin_ids, onError }) => ({
      onError,
      variables: { grain_bin_ids: initial_grain_bin_ids },
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      notifyOnNetworkStatusChange: true,
      context: { important: true }, // flag to prevent from graphql batching
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          containers_weather_loading: true,
          containers_weather: [] as Result['containers_weather'],
          containersWeatherRefetch: () => Promise.resolve(),
          loadMoreContainersWeather: () => Promise.resolve(),
        };
      }

      const handleLoadMore = (grain_bin_ids: number[]) => {
        console.log('inside handleLoadMore', grain_bin_ids);
        return data.fetchMore({
          // query: GetContainersWeatherDocument,
          variables: {
            grain_bin_ids,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            console.log('fetchMoreResult', previousResult, fetchMoreResult);
            if (!fetchMoreResult) return previousResult;
            return fetchMoreResult;
          },
        });
      };

      return {
        containers_weather_loading: loading || false,
        containers_weather: (data.containers_weather as Result['containers_weather']) || [],
        containersWeatherRefetch: () => data.refetch(),
        loadMoreContainersWeather: (grain_bin_ids) => handleLoadMore(grain_bin_ids),
      };
    },
  })(component as any);
