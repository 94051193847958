import { AmberHoc, AmberHocProps } from '../../../util';
import {
  CreateGrainBinSiteMutationVariables as Variables,
  GrainBinSiteFragmentFragment as Result,
  withCreateGrainBinSite as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithCreateGrainBinSiteHocChildProps = {
  createGrainBinSite: (variables: Variables) => Promise<Result>;
};

export const withCreateGrainBinSiteHoc: AmberHoc<{}, WithCreateGrainBinSiteHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<{}, WithCreateGrainBinSiteHocChildProps, typeof component>,
    WithCreateGrainBinSiteHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        createGrainBinSite: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data || !data.createGrainBinSite) {
            throw new Error('Unexpected server response');
          }
          return data.createGrainBinSite;
        },
      };
    },
  })(component as any);
