import { Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import React, { CSSProperties, FunctionComponent } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import { isTouchDevice } from '../../util';

type Position = {
  lat: number;
  lng: number;
};
type LocationMapState = { center: Position; zoom: number };
type LocationMapProps = {
  position?: Position;
  defaultZoom?: number;
  label?: string;
};
const styles = {
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  marker: {
    backgroundColor: 'white',
    borderRadius: 10,
    border: '1px solid black',
    paddingLeft: 5,
    paddingRight: 5,
    maxWidth: 100,
  },
};

const is_touch = isTouchDevice();

class BaseLocationMap extends React.PureComponent<
  WithStyles<typeof styles> & LocationMapProps,
  LocationMapState
> {
  ref_map: google.maps.Map;

  constructor(props) {
    super(props);
    this.state = {
      center: props.position || { lat: 37.09024, lng: -95.712891 },
      zoom: props.position ? 14 : 4,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const position = this.props.position;
    if (position === prevProps.position) {
      return;
    }
    if (position) {
      if (
        !prevProps.position ||
        Math.abs(prevProps.position.lat - position.lat) > 0.000005 ||
        Math.abs(prevProps.position.lng - position.lng) > 0.000005
      ) {
        this.setState({ center: position, zoom: prevProps.positions ? this.state.zoom : 14 });
        return;
      }
    } else {
      this.setState({ center: { lat: 37.09024, lng: -95.712891 }, zoom: 4 });
      return;
    }
  }

  handleZoomChanged = () => {
    this.setState({ zoom: this.ref_map.getZoom() });
  };

  componentWillMount() {
    const position = this.props.position;
    if (position) {
      this.setState({ center: position });
    }
  }

  render() {
    const { center, zoom } = this.state;
    const { classes, label, position } = this.props;
    return (
      <GoogleMap
        options={{
          clickableIcons: false,
          controlSize: 30,
          fullscreenControl: false,
          mapTypeId: google.maps.MapTypeId.HYBRID,
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
          panControl: false,
          rotateControl: false,
          streetViewControl: false,
          zoomControl: !is_touch,
        }}
        ref={(ref: any) => (this.ref_map = ref)}
        zoom={zoom}
        center={center}
        clickableIcons={false}
        onZoomChanged={this.handleZoomChanged}
      >
        {position &&
          (label ? (
            <MarkerWithLabel
              position={position}
              labelAnchor={new google.maps.Point(-18, 42)}
              opacity={1}
            >
              <div className={classes.marker}>
                <Typography variant="body2">{label}</Typography>
              </div>
            </MarkerWithLabel>
          ) : (
            <Marker position={position} />
          ))}
      </GoogleMap>
    );
  }
}

const LocationMapWrapper = withScriptjs(withGoogleMap(withStyles(styles)(BaseLocationMap)));

export const LocationMap: FunctionComponent<
  LocationMapProps & {
    className?: string;
    style?: CSSProperties;
  }
> = (props) => (
  <LocationMapWrapper
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${
      window['app_config'].google_api_key
    }&v=3.exp&libraries=geometry,drawing,places`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={
      <div style={{ height: '300px', width: '100%', ...props.style }} className={props.className} />
    }
    mapElement={<div style={{ height: `100%` }} />}
    position={props.position}
    label={props.label}
  />
);
