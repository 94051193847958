import {
  Card,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useState } from 'react';

import LaunchIcon from '@material-ui/icons/Launch';
import { History } from 'history';
import {
  AccountFragmentFragment,
  UserModelFragmentFragment,
  UserRole,
  withCreateCustomerCareTokenHoc,
  WithCreateCustomerCareTokenHocChildProps,
  withDeleteUserHoc,
  WithDeleteUserHocChildProps,
  withGetAccountHoc,
  withGetOrganizationUsersListHoc,
  WithGetOrganizationUsersListHocChildProps,
} from '../../api';
import { DialogSpinner } from '../spinner';
import { ColumnConfig, ItemTable } from '../util';
import { UserAdminForm } from './UserAdminForm';
import UserDeleteModal from './UserDeleteModal';

export const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const getUserColumn = ({
  isMobile,
  system_account,
  handleDeleteIconClick,
  hasAdminAccess,
  viewerAccountId,
  accountId,
  handleLoginAsCustomer,
}) => {
  const hasSystemUserChangedAccount = hasAdminAccess && viewerAccountId !== accountId;
  const hasSuperUserLoggedInAsCustomerCare = Boolean(localStorage.getItem('customer_care_token'));
  const showLoginAsCustomerColumn =
    !hasSuperUserLoggedInAsCustomerCare &&
    ((system_account && hasAdminAccess) || hasSystemUserChangedAccount);
  const user_columns: ColumnConfig<UserModelFragmentFragment>[] = [
    {
      title: 'Name',
      width: hasAdminAccess || isMobile ? 20 : 30,
      align: 'left',
      getValue: ({ last_name, first_name }) => `${last_name}, ${first_name}`,
    },
    {
      title: 'Email',
      width: hasAdminAccess || isMobile ? 25 : 40,
      align: 'left',
      getValue: ({ email_address }) => email_address,
    },
    {
      title: 'Role',
      width: hasAdminAccess || isMobile ? 14 : 30,
      align: 'left',
      getValue: ({ role }) => role,
      formatValue: (value: UserRole) => {
        switch (value) {
          case UserRole.Admin:
            return 'Admin';
          case UserRole.FullAccess:
            return 'Write';
          case UserRole.FanAccess:
            return 'Fan Control';
          case UserRole.ReadOnly:
            return 'Read';
          default:
            return `?<${value}>`;
        }
      },
    },
  ];

  if (hasAdminAccess) {
    const deleteUserColumn: ColumnConfig<UserModelFragmentFragment> = {
      title: 'Delete',
      width: 15,
      align: 'left',
      getValue: (user) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteIconClick(user);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    };

    if (viewerAccountId === accountId) {
      user_columns.push(deleteUserColumn);
    }
  }

  if (showLoginAsCustomerColumn) {
    const loginAsColumn: ColumnConfig<UserModelFragmentFragment> = {
      title: 'Login As',
      width: 10,
      align: 'left',
      getValue: (user) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleLoginAsCustomer(user.email_address);
          }}
        >
          <LaunchIcon />
        </IconButton>
      ),
    };
    user_columns.push(loginAsColumn);
  }

  return user_columns;
};

const useStyles = makeStyles({
  centered: {
    textAlign: 'center',
  },
  grid: {
    width: '100%',
    padding: 20,
  },
  deleteAlert: {
    fontSize: 16,
  },
});
const getId = ({ user_id }) => user_id;

const AccountUsersLayoutBase: FunctionComponent<
  WithGetOrganizationUsersListHocChildProps &
    WithDeleteUserHocChildProps &
    WithCreateCustomerCareTokenHocChildProps & {
      account: AccountFragmentFragment;
      hasAdminAccess: boolean;
      viewerAccountId: number;
      history?: History<any>;
    }
> = ({
  history,
  account,
  organization_users_list,
  refetchOrgUsersList,
  loading = false,
  deleteUser,
  createCustomerCareToken,
  hasAdminAccess,
  viewerAccountId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const [selected, setSelected] = useState<UserModelFragmentFragment | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deletingUser, setDeletingUser] = useState<UserModelFragmentFragment | null>(null);
  const [deleteUserLoading, setDeleteUserLoading] = useState<boolean>(false);
  const [deleteUserStatus, setDeleteUserStatus] = useState<string | null>(null);
  const [loginAsloading, setLoginAsloading] = useState<boolean>(false);
  if (loading) {
    return <DialogSpinner title="Loading users..." open={loading} />;
  }

  if (deleteUserLoading) {
    return <DialogSpinner title="Deleting user..." open={deleteUserLoading} />;
  }

  if (loginAsloading) {
    return <DialogSpinner title="Loading..." open={loginAsloading} />;
  }

  const { account_id, organization, system_account, grain_bin_support, barge_support } = account;
  let user: UserModelFragmentFragment | null = null;
  if (selected) {
    const { user_id } = selected;
    const found = organization_users_list.find((existing) => existing.user_id === user_id);
    if (found) {
      user = found;
    }
  }

  const handleDeleteIconClick = (user) => {
    setDeletingUser(user);
    setShowDeleteModal(true);
  };

  const handleConfirmDeleteUser = async () => {
    if (deletingUser) {
      try {
        setShowDeleteModal(false);
        setDeleteUserLoading(true);
        const result = await deleteUser({
          user_id: deletingUser.user_id,
          account_id: deletingUser.account_id,
        });
        if (result.user_id === deletingUser.user_id) {
          await refetchOrgUsersList();
          setDeleteUserStatus('success');
        }
      } catch (err) {
        setDeleteUserStatus('error');
        console.error(err);
      } finally {
        setDeleteUserLoading(false);
      }
    }
  };

  const closeDeleteModal = () => setShowDeleteModal(false);
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteUserStatus(null);
  };

  const handleLoginAsCustomer = async (email_address) => {
    setLoginAsloading(true);
    const loggedInSuperUserToken = localStorage.getItem('token') || '';
    const path = grain_bin_support
      ? '/grain_bin/dashboard'
      : barge_support
      ? '/barge/dashboard'
      : '/dashboard';
    try {
      const response = await createCustomerCareToken({
        username: email_address,
        auth_token: loggedInSuperUserToken,
      });
      localStorage.setItem('customer_care_token', response.token);
      history && history.push(path);
      window.location.reload();
    } catch (error) {
      console.error(error);
    } finally {
      setLoginAsloading(false);
    }
  };

  const handleFormSubmitSuccess = async (results) => {
    if (results) await refetchOrgUsersList();
  };

  return (
    <Grid
      container
      direction="row"
      alignContent="flex-start"
      alignItems="center"
      justify="center"
      spacing={2}
      className={classes.grid}
    >
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.centered}>
          {organization}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: 'border-box', width: '100%', overflowX: 'auto' }}>
          <ItemTable<UserModelFragmentFragment>
            items={organization_users_list}
            getId={getId}
            columns={getUserColumn({
              isMobile,
              handleDeleteIconClick,
              hasAdminAccess,
              system_account,
              viewerAccountId,
              handleLoginAsCustomer,
              accountId: account_id,
            })}
            onSelect={hasAdminAccess ? setSelected : undefined}
            value={selected}
          />
          {hasAdminAccess && showDeleteModal && (
            <UserDeleteModal
              user={
                deletingUser
                  ? { first_name: deletingUser.first_name, last_name: deletingUser.last_name }
                  : null
              }
              showModal={showDeleteModal}
              handleConfirm={handleConfirmDeleteUser}
              handleCancel={closeDeleteModal}
            />
          )}
        </div>
      </Grid>
      {hasAdminAccess && (
        <Grid item xs={12} style={{ padding: 20 }}>
          <Card>
            <UserAdminForm
              account_id={account_id}
              user={user}
              onSubmitSuccess={handleFormSubmitSuccess}
              account={account}
            />
          </Card>
        </Grid>
      )}
      <Snackbar open={!!deleteUserStatus} onClose={handleAlertClose} autoHideDuration={6000}>
        <Alert
          className={classes.deleteAlert}
          severity={String(deleteUserStatus)}
          onClose={handleAlertClose}
        >
          {deleteUserStatus === 'success'
            ? 'User deleted successfully'
            : 'Something went wrong! Please Try again.'}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export const AccountUsersLayout = withGetAccountHoc(
  withGetOrganizationUsersListHoc(
    withDeleteUserHoc(withCreateCustomerCareTokenHoc(AccountUsersLayoutBase))
  )
);
