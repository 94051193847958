import {
  AppBar as MuiAppBar,
  Button,
  Hidden,
  IconButton,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ArrowBack, ExitToApp, History, HomeOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActiveStoragePeriodContext } from '../../contexts';
import StoragePeriodSelect from './StoragePeriodSelect';

import { ViewerFragmentFragment } from '../../api';
import { SelectedState } from '../../reducer';
import {
  amber_amber,
  amber_medium_light_grey,
  amber_very_faded_black,
  fullWhite,
} from '../../style';
import {
  ContainerTypeLegacy,
  EXTRA_CUSTOM_BREAKPOINT_DIFFERENCE,
  get_container_select_width,
  get_vertical_sidebar_width,
  topbar_height,
} from '../../util';
import { DashboardMenu } from './DashboardMenu';
import { GrainBinGrainType } from './GrainBinGrainType';
import { OperationsViewInfo } from './OperationsViewInfo';
import { UserMenu } from './UserMenu';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    height: topbar_height,
    backgroundColor: amber_medium_light_grey,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: amber_very_faded_black,
  },
  appBarHistorical: {
    height: topbar_height,
    backgroundColor: fullWhite,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: amber_very_faded_black,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    marginTop: 12,
    marginBottom: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 300,
  },
  binNameheader: {
    marginTop: 12,
    marginBottom: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 300,
    [theme.breakpoints.only('md')]: {
      marginTop: 10,
      marginBottom: 10,
      fontSize: 20,
      maxWidth: 250,
    },
    [theme.breakpoints.up(theme.breakpoints.width('lg') - 80)]: {
      maxWidth: 450,
    },
  },
  breadcrumb: {
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
    alignItems: 'center',
  },
  breadcrumbText: {
    marginRight: 15,
    marginBottom: 0,
    marginTop: 0,
    color: theme.palette.text.primary,
  },
  alignment: {
    // 8 top margin to align with grain assets title in container list
    marginTop: 8,
  },
  leftHeader: {
    marginBottom: 20,
  },
  userIcon: {
    marginRight: theme.spacing(1),
  },
}));

const container_name_selector = ({ selected: { container_name } }: { selected: SelectedState }) =>
  container_name;
const container_id_selector = ({ selected: { container_id } }: { selected: SelectedState }) =>
  container_id;
const container_type_selector = ({ selected: { container_type } }: { selected: SelectedState }) =>
  container_type;
export const HeaderBar: FunctionComponent<{
  viewer: ViewerFragmentFragment;
  title: string;
  pathname: string;
  email_address: string;
  onClickUser: () => void;
  onClickPrivacy: () => void;
  onClickBack?: () => void;
}> = ({
  viewer,
  pathname,
  title = '',
  email_address,
  onClickUser,
  onClickPrivacy,
  onClickBack,
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(
    `@media (max-width:${1280 + EXTRA_CUSTOM_BREAKPOINT_DIFFERENCE}px) and (min-width:960px)`,
    {
      noSsr: true,
    }
  );
  const isSmallMobile = useMediaQuery(theme.breakpoints.only('sm'), {
    noSsr: true,
  });
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isHover, setIsHover] = useState(false);
  const container_name = useSelector(container_name_selector);
  const container_id = useSelector(container_id_selector);
  const container_type = useSelector(container_type_selector);
  const hasOperationsViewLoaded = pathname.includes('/grain/operations');
  let url_prefix = '';
  if (pathname.includes('bin')) {
    url_prefix = '/grain_bin';
  } else if (pathname.includes('barge')) {
    url_prefix = '/barge';
  } else if (pathname.includes('pile')) {
    url_prefix = '/pile';
  }

  const ctx = useContext(ActiveStoragePeriodContext);
  const is_historical_period = Boolean(
    ctx &&
      ctx.activeStoragePeriod &&
      ctx.activeStoragePeriod.end_date &&
      ctx.activeStoragePeriod.end_date.getTime() <= new Date().getTime()
  );
  const is_historical_bin = Boolean(
    ctx && ctx.activeStoragePeriod && !ctx.activeStoragePeriod.is_ongoing && is_historical_period
  );
  const valid_grain_bin = container_id && container_type === ContainerTypeLegacy.bin;
  const appBar = is_historical_bin ? classes.appBarHistorical : classes.appBar;
  const showLeaveAccountButton = Boolean(localStorage.getItem('customer_care_token'));
  const username = viewer ? viewer.user.first_name : '';

  const stubSubmit = () => {};
  const exclude_menu_item_storage_period = [ContainerTypeLegacy.barge];
  const verticalSidebarWidth =
    !isSmallMobile && isTablet
      ? get_vertical_sidebar_width('tablet')
      : isSmallMobile
      ? get_vertical_sidebar_width('sm-mobile')
      : get_vertical_sidebar_width('desktop');
  const containerSelectWidth =
    !isSmallMobile && isTablet
      ? get_container_select_width('tablet')
      : isSmallMobile
      ? get_container_select_width('sm-mobile')
      : get_container_select_width('desktop');

  const handleGrainMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleGrainMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLeavingLoggedInAccount = () => {
    localStorage.removeItem('customer_care_token');
    window.location.reload();
  };

  return (
    <MuiAppBar
      position="fixed"
      className={appBar}
      style={
        pathname.includes('dashboard') || hasOperationsViewLoaded
          ? {
              width: `calc(100% - ${verticalSidebarWidth + containerSelectWidth}px)`,
              marginLeft: verticalSidebarWidth + containerSelectWidth,
            }
          : {
              width: `calc(100% - ${verticalSidebarWidth}px)`,
              marginLeft: verticalSidebarWidth,
            }
      }
    >
      <Toolbar className={classes.toolbar}>
        <div className={`${classes.leftHeader} ${classes.alignment}`}>
          {/* todo make responsive to more than one level of navigation */}
          {/* add extra margin for grain bins because of grain type */}
          <div
            className={classes.breadcrumb}
            style={valid_grain_bin || hasOperationsViewLoaded ? { marginTop: 20 } : {}}
          >
            {onClickBack && (
              <IconButton onClick={onClickBack}>
                <ArrowBack />
              </IconButton>
            )}
            <HomeOutlined className={classes.breadcrumbText} style={{ fontSize: 15 }} />
            <p className={classes.breadcrumbText}>/</p>
            <p className={classes.breadcrumbText}>{title}</p>
          </div>
          <div className={classes.headerRow} style={{ height: 40 }}>
            {pathname.includes('dashboard') ? (
              <Tooltip
                open={anchorEl === null && isHover}
                title={<div style={{ fontSize: 12 }}> Open Grain Bin Menu</div>}
                placement="right"
              >
                <Button
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                  style={{ textTransform: 'unset', padding: 0 }}
                  onClick={(e) => {
                    setIsHover(false);
                    handleGrainMenuClick(e);
                  }}
                >
                  <Typography
                    variant="h4"
                    className={classes.binNameheader}
                    style={onClickBack ? { marginTop: 0 } : {}}
                  >
                    {container_name || title}
                  </Typography>
                  {/* For grain menu */}
                  <DashboardMenu
                    viewer={viewer}
                    url_prefix={url_prefix}
                    anchorEl={anchorEl}
                    handleGrainMenuClose={handleGrainMenuClose}
                  />
                </Button>
              </Tooltip>
            ) : (
              <Typography
                variant="h4"
                className={classes.header}
                style={onClickBack ? { marginTop: 0 } : {}}
              >
                {hasOperationsViewLoaded ? 'Operations View' : title}
              </Typography>
            )}

            {is_historical_bin && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: isTablet ? 20 : 50,
                }}
              >
                <Typography variant="h5" color={'primary'}>
                  Historical
                </Typography>
                <History style={{ marginLeft: '6px' }} htmlColor={'#000000'} fontSize={'default'} />
              </div>
            )}
          </div>
          {valid_grain_bin && container_id !== null && pathname.includes('dashboard') && (
            <GrainBinGrainType grain_bin_id={container_id} fit_content />
          )}

          {hasOperationsViewLoaded && <OperationsViewInfo />}
        </div>
        <div className={`${classes.headerRow} ${classes.alignment}`}>
          {container_type && !exclude_menu_item_storage_period.includes(container_type) && (
            <Hidden smDown>
              {container_id && (
                <StoragePeriodSelect grain_bin_id={container_id} viewer_role={viewer.user.role} />
              )}
            </Hidden>
          )}
          <div>
            <UserMenu
              email_address={email_address}
              viewer_role={viewer.user.role}
              onClickUser={onClickUser}
              onClickPrivacy={onClickPrivacy}
            />

            {showLeaveAccountButton && (
              <>
                <br />
                <Button
                  size="small"
                  variant="contained"
                  style={{ backgroundColor: amber_amber, color: '#fff' }}
                  onClick={handleLeavingLoggedInAccount}
                >
                  <div>
                    <ExitToApp style={{ marginRight: 5 }} />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      Leave <u>{username}'s</u> Account
                    </div>
                    <div>{`(${email_address})`}</div>
                  </div>
                </Button>
              </>
            )}
          </div>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};
