import React, { useContext, useMemo } from 'react';
import { ActiveStoragePeriodContext } from '../../../../src/contexts';
import { Statistic } from '../../../../src/util';
import { isValidTelemetryData, TelemetryHistoryWithMultiHubsCard } from '../summary-card';

export const HeadspaceCo2HistoryChart = ({
  active_hubs_grain_telemetry_history,
  aeration_runs,
  grain_bin_location_timezone,
  ...props
}) => {
  const ctx = useContext(ActiveStoragePeriodContext);
  const activeStoragePeriod = ctx.activeStoragePeriod;
  const statistic = Statistic.co2_ppm;
  const active_hubs_formatted_telemetry_history = useMemo(
    () =>
      active_hubs_grain_telemetry_history
        ? active_hubs_grain_telemetry_history
            .map(({ hub_id, headspace_telemetry_history }) => {
              const data = headspace_telemetry_history
                .filter((obj) => {
                  if (
                    obj[statistic] !== undefined &&
                    activeStoragePeriod &&
                    activeStoragePeriod.start_date.getTime() <= obj.epoch_time.getTime() &&
                    obj.epoch_time.getTime() <= (activeStoragePeriod.end_date || Infinity)
                  ) {
                    return isValidTelemetryData(obj, statistic);
                  }
                  if (obj[statistic] !== undefined && activeStoragePeriod === null) {
                    return isValidTelemetryData(obj, statistic);
                  }
                  return false;
                })
                .map((obj) => ({ x: obj.epoch_time, y: Number(obj[statistic]) }));

              return {
                hub_id,
                data,
              };
            })
            .filter(({ data }) => data.length > 0)
        : [],
    [active_hubs_grain_telemetry_history, activeStoragePeriod, statistic]
  );

  return (
    <TelemetryHistoryWithMultiHubsCard
      weather_history={[]}
      statistic={statistic}
      ranges={[]}
      period={props.period}
      loading={props.loading}
      history={active_hubs_formatted_telemetry_history}
      run_windows={aeration_runs || undefined}
      grain_bin_location_timezone={grain_bin_location_timezone}
      {...props}
    />
  );
};
