import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { setUnreadNotificationCount } from '../../../../core/src/action';
import { AlertSettingsIcon } from '../../../../core/src/media';
import {
  AccountFragmentFragment,
  GrainBinNotification,
  UserFragmentFragment,
  withGetUserDeviceNotificationsHistoryHoc,
  WithGetUserDeviceNotificationsHistoryHocChildProps,
  withGetUserHoc,
  WithGetUserHocChildProps,
  withSetNotificationsReadOnHoc,
  WithSetNotificationsReadOnHocChildProps,
} from '../../api';
import { DialogSpinner } from '../spinner';
import {
  allOption,
  OptionValueType,
  SelectGrainBinsFilter,
} from '../util/form2/SelectGrainBinsFilter';
import { GrainBinNotifications } from './GrainBinNotifications';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: string;
}

const REMAINING_SECTION_HEIGHT = 300;
const ON_SMALL_SCREEN_REMAINING_SECTION_HEIGHT = 240;

const useStyles = makeStyles((theme: Theme) => ({
  tabPanel: {
    height: `calc(100vh - ${REMAINING_SECTION_HEIGHT}px)`,
    maxHeight: `calc(100vh - ${REMAINING_SECTION_HEIGHT}px)`,
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      height: `calc(100vh - ${ON_SMALL_SCREEN_REMAINING_SECTION_HEIGHT}px)`,
      maxHeight: `calc(100vh - ${ON_SMALL_SCREEN_REMAINING_SECTION_HEIGHT}px)`,
      paddingRight: 10,
    },
  },
  notificationsSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: '0px 15px 15px 15px',
    [theme.breakpoints.only('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      justifyContent: 'center',
    },
    [theme.breakpoints.only('lg')]: {
      width: '60%',
      paddingLeft: 0,
    },
  },

  tab: {
    [theme.breakpoints.only('xs')]: {
      minWidth: 60,
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: 70,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 13,
    },
  },
  notificationCategory: {
    margin: '10px 20px 0px 0px',
    fontSize: '1rem',
  },
  selectGrainBinsFilterContainer: {
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: 450,
    },
  },
  tabHeader: {
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
}));

type notificationCategoryType = {
  index: number;
  title: string;
  value: string;
  source_type: number;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`notification-category-tabpanel-${index}`}
      aria-labelledby={`notification-category-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export const UserGrainBinNotificationsBase = ({
  account,
  user,
  loading,
  goUserSettings,
  setNotificationsReadOn,
  hasInternalSiteLoaded = false,
  refecthUser,
  refecthUserDeviceNotificationsHistory,
  history,
  hasCurrentAccountHolder,
  device_notifications_loading,
  user_device_notifications_history = [],
}: {
  account: AccountFragmentFragment;
  user: UserFragmentFragment;
  account_id: number;
  user_id: number;
  mobile_width?: boolean;
  hasInternalSiteLoaded?: boolean;
  goUserSettings?: () => any;
  history: History<any>;
  hasCurrentAccountHolder: boolean;
} & WithSetNotificationsReadOnHocChildProps &
  WithGetUserHocChildProps &
  WithGetUserDeviceNotificationsHistoryHocChildProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const [userNewNotificationsCountAfterFetch, setUserNewNotificationsCountAfterFetch] = useState<
    number | undefined
  >();
  const { grain_bin_links = [], account_id } = account;
  const notificationCategories: notificationCategoryType[] = [
    { index: 0, title: 'All', value: 'all', source_type: -1 },
    { index: 1, title: 'CO2', value: 'co2_alerts', source_type: 20 },
    { index: 2, title: 'Fan Status', value: 'fan_alerts', source_type: 1 },
    { index: 3, title: 'Automation', value: 'automation_ended_alerts', source_type: 30 },
    {
      index: 4,
      title: 'Grain Level',
      value: 'grain_level_alerts',
      source_type: 40,
    },
  ];
  const all = notificationCategories[0];
  const [notificationCategoryFilter, setNotificationCategoryFilter] = useState<
    notificationCategoryType
  >(all);
  const [selectedGrainBinFilterValues, setSelectedGrainBinFilterValues] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const { user_last_notifications_read_on = new Date() } = user || {};

  const hasSuperUserLoggedInAsCustomerCare = Boolean(localStorage.getItem('customer_care_token'));
  const grain_bin_id_to_name = grain_bin_links.reduce((acc, curr) => {
    acc.set(curr.grain_bin_id, curr.grain_bin.alias);
    return acc;
  }, new Map<number, string>());

  const unarchived_grain_bin_ids: number[] = grain_bin_links
    .filter(({ grain_bin: { archived } }) => !archived)
    .map(({ grain_bin_id }) => grain_bin_id);

  const grainBinFilterOptions: any = unarchived_grain_bin_ids.map((grain_bin_id) => {
    if (grain_bin_id_to_name.has(grain_bin_id)) {
      const bin_name = grain_bin_id_to_name.get(grain_bin_id);
      return {
        label: bin_name,
        value: bin_name,
      };
    }
  });

  const grainBinFilterValuesLabels = selectedGrainBinFilterValues.map(({ label }) => label);

  const getFilteredGrainBinNotificationsHistory = (
    grain_notifications_history: GrainBinNotification[]
  ) => {
    return notificationCategoryFilter.value === all.value
      ? grain_notifications_history.filter(({ grain_bin_id }) => {
          const bin_name = grain_bin_id_to_name.get(grain_bin_id);
          return bin_name ? grainBinFilterValuesLabels.includes(bin_name) : true;
        })
      : grain_notifications_history.filter(({ source_type, grain_bin_id }) => {
          const bin_name = grain_bin_id_to_name.get(grain_bin_id);

          return (
            source_type === notificationCategoryFilter.source_type &&
            (bin_name ? grainBinFilterValuesLabels.includes(bin_name) : true)
          );
        });
  };

  const filteredGrainBinNotificationsHistory = user_device_notifications_history
    ? getFilteredGrainBinNotificationsHistory(user_device_notifications_history)
    : [];

  const handleAlertCategoryClick = (event, tabIndex) => {
    const notificationCategory = notificationCategories.find(({ index }) => index === tabIndex);
    if (notificationCategory) setNotificationCategoryFilter(notificationCategory);
  };

  const handleSwipeableViewChangeIndex = (tabIndex: number) => {
    const notificationCategory = notificationCategories.find(({ index }) => index === tabIndex);
    if (notificationCategory) setNotificationCategoryFilter(notificationCategory);
  };

  const handleGrainBinsFilterChange = (selectedOption: OptionValueType) => {
    setSelectedGrainBinFilterValues(selectedOption);
  };

  const updateNotificationsReadOnDate = async () => {
    try {
      const response = await setNotificationsReadOn({ last_notifications_read_on: new Date() });
    } catch (err) {
      console.error(err);
    }
  };

  const reloadNotifications = async () => {
    await refecthUserDeviceNotificationsHistory();
  };
  const reloadUser = async () => {
    await refecthUser();
  };

  useEffect(() => {
    setSelectedGrainBinFilterValues([allOption, ...grainBinFilterOptions]);
    if (!hasInternalSiteLoaded && !hasSuperUserLoggedInAsCustomerCare && hasCurrentAccountHolder) {
      dispatch(setUnreadNotificationCount({ unreadNotificationCount: 0 }));
      updateNotificationsReadOnDate();
    }
  }, []);

  useEffect(() => {
    if (!hasInternalSiteLoaded && user) {
      setUserNewNotificationsCountAfterFetch(user.user_new_notifications_count);
    }
  }, [user]);

  useEffect(() => {
    if (!hasInternalSiteLoaded && userNewNotificationsCountAfterFetch) {
      reloadNotifications();
    }
  }, [userNewNotificationsCountAfterFetch]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      console.log('Route changed', { location, action });
      // Apply route change logic
      if (
        !hasInternalSiteLoaded &&
        !hasSuperUserLoggedInAsCustomerCare &&
        hasCurrentAccountHolder
      ) {
        dispatch(setUnreadNotificationCount({ unreadNotificationCount: 0 }));
        updateNotificationsReadOnDate();
        reloadUser();
      }
    });
    return unlisten;
  }, []);

  if (loading) {
    return <DialogSpinner title="Loading Notifications..." open={loading} />;
  }

  if (device_notifications_loading) {
    return <DialogSpinner title="Loading Notifications..." open={device_notifications_loading} />;
  }

  if (!user || !account) return null;

  return (
    <Grid container justify="center">
      <Grid item className={classes.notificationsSection}>
        <Box
          style={{
            borderBottom: 1,
            borderColor: 'divider',
            width: isWideScreen ? 'fit-content' : undefined,
          }}
        >
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <div>
              <Typography
                variant="h5"
                className={classes.tabHeader}
                style={{
                  marginTop: 10,
                  marginBottom: 16,
                }}
              >
                Notifications (Last 2 Weeks)
                <Tooltip
                  placement="right"
                  title={<div style={{ fontSize: 12 }}>Alert Settings</div>}
                  arrow
                >
                  <IconButton
                    onClick={(e) => {
                      goUserSettings && goUserSettings();
                    }}
                  >
                    <AlertSettingsIcon
                      style={{
                        width: 35,
                        height: 35,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Typography>
            </div>
            <div className={classes.selectGrainBinsFilterContainer}>
              <SelectGrainBinsFilter
                name="grain_bins_filter"
                selectedValue={selectedGrainBinFilterValues}
                options={grainBinFilterOptions}
                onChange={handleGrainBinsFilterChange}
                allowSelectAll={true}
                placeholder="Filter by Grain Bins"
              />
            </div>
          </Grid>
          <Tabs
            variant={isMobileScreen ? 'scrollable' : 'standard'}
            value={notificationCategoryFilter.index}
            onChange={handleAlertCategoryClick}
            aria-label="notification category tab"
          >
            {notificationCategories.map(({ title, index }) => (
              <Tab classes={{ root: classes.tab }} key={index} value={index} label={title} />
            ))}
          </Tabs>
          {isMobileScreen ? (
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={notificationCategoryFilter.index}
              onChangeIndex={handleSwipeableViewChangeIndex}
            >
              {notificationCategories.map(({ index }) => {
                return (
                  <TabPanel
                    className={classes.tabPanel}
                    key={index}
                    value={notificationCategoryFilter.index}
                    index={index}
                  >
                    {filteredGrainBinNotificationsHistory.length > 0 ? (
                      <GrainBinNotifications
                        user_device_notifications_history={filteredGrainBinNotificationsHistory}
                        user_last_notifications_read_on={user_last_notifications_read_on}
                        hasInternalSiteLoaded={hasInternalSiteLoaded}
                      />
                    ) : (
                      <Grid
                        container
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 30,
                        }}
                      >
                        <Typography variant="body2" style={{ fontSize: 14 }}>
                          No{' '}
                          {notificationCategoryFilter.value !== all.value &&
                            notificationCategoryFilter.title}{' '}
                          notifications in the last 2 weeks
                        </Typography>
                      </Grid>
                    )}
                  </TabPanel>
                );
              })}
            </SwipeableViews>
          ) : (
            notificationCategories.map(({ index, title }) => {
              return (
                <TabPanel
                  className={classes.tabPanel}
                  key={index}
                  value={notificationCategoryFilter.index}
                  index={index}
                >
                  {filteredGrainBinNotificationsHistory.length > 0 ? (
                    <GrainBinNotifications
                      user_device_notifications_history={filteredGrainBinNotificationsHistory}
                      hasInternalSiteLoaded={hasInternalSiteLoaded}
                      user_last_notifications_read_on={user_last_notifications_read_on}
                    />
                  ) : (
                    <Grid
                      container
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 30,
                      }}
                    >
                      <Typography variant="body2" style={{ fontSize: 14 }}>
                        No{' '}
                        {notificationCategoryFilter.value !== all.value &&
                          notificationCategoryFilter.title}{' '}
                        notifications in the last 2 weeks
                      </Typography>
                    </Grid>
                  )}
                </TabPanel>
              );
            })
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export const UserGrainBinNotifications = withGetUserDeviceNotificationsHistoryHoc(
  withSetNotificationsReadOnHoc(withGetUserHoc(UserGrainBinNotificationsBase))
);
