import * as React from 'react';
const GrainMoistureIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} viewBox="0 0 40 40" {...props}>
    <g id="CurrMoistureIcon" transform="translate(-0.25)">
      <rect
        id="Rectangle_292"
        data-name="Rectangle 292"
        width={40}
        height={40}
        rx={20}
        transform="translate(0.25)"
        fill="#eff1f4"
      />
      <path
        id="Path_315"
        data-name="Path 315"
        d="M25.91,16l-4.95-4.94a1,1,0,0,0-1.41,0L14.59,16a8,8,0,1,0,11.32,0ZM14.25,22a5.694,5.694,0,0,1,1.76-4.4l4.24-4.33,4.24,4.38A5.568,5.568,0,0,1,26.25,22Z"
        fill="#242a33"
      />
    </g>
  </svg>
);

const GrainMoistureIconWithoutShade = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.171}
    height={17.915}
    viewBox="0 0 15.171 17.915"
    {...props}
  >
    <path
      id="Path_62"
      data-name="Path 62"
      d="M4555.768,442.9l-4.694-4.684a.944.944,0,0,0-1.337,0l-4.7,4.684a7.585,7.585,0,1,0,10.734,0Zm-11.056,5.689a5.366,5.366,0,0,1,1.668-4.172l4.021-4.105,4.02,4.153a5.279,5.279,0,0,1,1.669,4.124Z"
      transform="translate(-4542.815 -437.94)"
      fill="gray"
    />
  </svg>
);
export { GrainMoistureIcon, GrainMoistureIconWithoutShade };
