import { NormalizedCache } from 'apollo-cache-inmemory';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  UpdateGrainBinFanControllerMutation,
  UpdateGrainBinFanControllerMutationVariables as Variables,
  withUpdateGrainBinFanController as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUpdateGrainBinFanControllerHocChildProps = {
  updateGrainBinFanController: (
    variables: Variables
  ) => Promise<UpdateGrainBinFanControllerMutation['updateGrainBinFanController']>;
};
export const withUpdateGrainBinFanControllerHoc: AmberHoc<
  {},
  WithUpdateGrainBinFanControllerHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithUpdateGrainBinFanControllerHocChildProps, typeof component>,
    WithUpdateGrainBinFanControllerHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updateGrainBinFanController: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.updateGrainBinFanController;
        },
      };
    },
  })(component as any);
