import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const withSkeleton = (Component) => (props) => {
  const isLoading = props && props.value === 'loading';
  if (isLoading) return <Skeleton animation="wave" variant="rect" width={'100%'} height={'50%'} />;

  return <Component {...props} />;
};

export { withSkeleton };
