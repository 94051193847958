import {
  Fab,
  fade,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, ArrowForward, HomeOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { pushNav } from '../../../../core/src/action';

import { OpsViewIcon } from '../../../../core/src/media';
import { default_selected_state } from '../../../../core/src/reducer';
import {
  AccountFragmentFragment,
  ContainerType,
  GrainBinFanController,
  PerformGrainBinSelectionMutationVariables,
  PerformGrainBinUnselectionMutationVariables,
  UserRole,
  UserSelectedGrainBinFragmentFragment,
  UserSelectedGrainContainerFragmentFragment,
} from '../../api';
import {
  amber_amber,
  amber_faded_black,
  amber_medium_grey,
  amber_medium_light_grey,
  amber_very_faded_black,
  black_shade_8,
} from '../../style';
import { ContainerTypeLegacy, topbar_height } from '../../util';
import { NoContainerFoundPlaceholder } from '../grain-container';
import { getUserPermissionTooltipText } from '../grain-container/aeration';
import { ContainerListItem } from './ContainerListItem';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: amber_very_faded_black,
    height: topbar_height,
    minHeight: topbar_height,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
  list: {
    paddingTop: 0,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  shadow: {
    boxShadow: `0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)`,
  },
  fab: {
    '&:disabled': {
      backgroundColor: fade(amber_amber, 0.3),
    },
  },
  tab_margin: {
    marginTop: 10,
    borderTop: `solid 1px ${amber_very_faded_black}`,
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    paddingRight: theme.spacing(2),
    color: amber_medium_grey,
    borderBottom: `solid 1px ${amber_very_faded_black}`,
    '&:hover': {
      backgroundColor: fade(amber_medium_light_grey, 0.5),
    },
    '&$selected': { backgroundColor: amber_medium_light_grey },
    '&$selected:hover': {
      backgroundColor: amber_medium_light_grey,
    },
    '&$selected:focus': {
      backgroundColor: amber_medium_light_grey,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
  },
  selected: {
    color: amber_faded_black,
    backgroundColor: amber_medium_light_grey,
  },
  smallIcon: {
    fontSize: 17,
    marginRight: -4,
  },
}));

export const ContainerListInner: FunctionComponent<{
  loading: boolean;
  selected_container_id: number | null;
  selected_container_type: ContainerTypeLegacy | null;
  account: AccountFragmentFragment;
  pathname: string;
  onClickCreate: () => void;
  handleContainerSelection: (
    id: number | null,
    name: string | null,
    type: ContainerTypeLegacy
  ) => void;
  account_select: any;
  viewerRole: UserRole;
  viewerUserId: number;
  onSelectContainer: (args: any) => void;
  performGrainBinUnselection: (
    variables: PerformGrainBinUnselectionMutationVariables
  ) => Promise<UserSelectedGrainContainerFragmentFragment>;
  showOperationViewItem: boolean;
}> = ({
  loading,
  selected_container_id,
  selected_container_type,
  account,
  pathname,
  onClickCreate,
  onSelectContainer,
  handleContainerSelection,
  viewerUserId,
  performGrainBinUnselection,
  account_select,
  viewerRole,
  showOperationViewItem,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleContainerTypeSelect = (event: React.ChangeEvent<{ value: ContainerTypeLegacy }>) => {
    handleContainerSelection(null, null, event.target.value);
  };
  const {
    grain_bin_support,
    barge_support,
    pile_support,
    grain_bin_links,
    barge_links,
    pile_links,
  } = account;
  const support_count = Number(grain_bin_support) + Number(barge_support) + Number(pile_support);
  const disabledTooltipText = [UserRole.FanAccess, UserRole.ReadOnly].includes(viewerRole)
    ? getUserPermissionTooltipText(viewerRole)
    : '';
  const getAddContainerText = (grainContainerType: ContainerTypeLegacy): string => {
    let text = '';
    switch (grainContainerType) {
      case ContainerTypeLegacy.bin:
        text = 'Create a Grain Bin';
        break;
      case ContainerTypeLegacy.barge:
        text = 'Create a Barge';
        break;
      case ContainerTypeLegacy.pile:
        text = 'Create a Pile';
        break;
      default:
        break;
    }
    return text;
  };
  const addContainerTooltipText = (
    <span style={{ fontSize: 13 }}>
      {selected_container_type && getAddContainerText(selected_container_type)}
    </span>
  );

  const handleOperationsItemSelection = async () => {
    try {
      onSelectContainer({
        container_id: default_selected_state.container_id,
        container_name: default_selected_state.container_name,
        container_type: default_selected_state.container_type,
      });
      dispatch(
        pushNav({
          path: '/grain/operations',
        })
      );
      await performGrainBinUnselection({
        account_id: account.account_id,
        user_id: viewerUserId,
      });
    } catch (error) {
      console.error('Error while performGrainBinUnselection', error);
    }
  };

  console.log('selected_container_id values', { selected_container_id, selected_container_type });

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h4">Grain Assets</Typography>
        {!loading && (
          <Tooltip
            enterTouchDelay={0}
            disableFocusListener
            title={
              disabledTooltipText ? (
                <span style={{ fontSize: 13 }}>{disabledTooltipText}</span>
              ) : (
                addContainerTooltipText
              )
            }
            arrow
          >
            <div>
              <Fab
                size="small"
                color="secondary"
                aria-label="Add"
                className={classes.shadow}
                classes={{ root: classes.fab }}
                onClick={onClickCreate}
                disabled={[UserRole.FanAccess, UserRole.ReadOnly].includes(viewerRole)}
              >
                <Add />
              </Fab>
            </div>
          </Tooltip>
        )}
      </div>

      {support_count > 1 && (
        <Select
          value={selected_container_type || ''}
          onChange={handleContainerTypeSelect}
          inputProps={{
            name: 'container-type',
            id: 'container-type',
          }}
          style={{ minHeight: 50 }}
        >
          {grain_bin_support && <MenuItem value={ContainerTypeLegacy.bin}>Grain Bins</MenuItem>}
          {barge_support && <MenuItem value={ContainerTypeLegacy.barge}>Barges</MenuItem>}
          {pile_support && <MenuItem value={ContainerTypeLegacy.pile}>Piles</MenuItem>}
        </Select>
      )}
      {!loading && (
        <List
          className={`${classes.list}${
            barge_support || grain_bin_support || pile_support ? `` : ` ${classes.tab_margin}`
          }`}
        >
          {showOperationViewItem && (
            <ListItem
              button
              selected={selected_container_id === default_selected_state.container_id}
              onClick={handleOperationsItemSelection}
              classes={{ root: classes.root, selected: classes.selected }}
            >
              <div style={{ paddingRight: 15, display: 'flex' }}>
                <OpsViewIcon style={{ marginRight: 10 }} />
                <Typography
                  color="inherit"
                  style={{
                    textDecoration: 'underline',
                    fontSize: 17,
                    fontWeight: 600,
                    color: black_shade_8,
                  }}
                >
                  Operations View
                </Typography>
              </div>
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 24,
                }}
              >
                <ArrowForward className={classes.smallIcon} />
              </Grid>
            </ListItem>
          )}

          {grain_bin_links &&
            selected_container_type === ContainerTypeLegacy.bin &&
            (grain_bin_links.length ? (
              grain_bin_links
                .filter((grain_bin_link) => !grain_bin_link.grain_bin.archived)
                .map(({ grain_bin: { grain_bin_id, alias, fan_controllers } }, index) => (
                  <ContainerListItem
                    key={`bin${index}`}
                    containerName={alias}
                    handleClick={() =>
                      handleContainerSelection(grain_bin_id, alias, ContainerTypeLegacy.bin)
                    }
                    // selected and on tab OR
                    selected={
                      selected_container_id === grain_bin_id &&
                      selected_container_type === ContainerTypeLegacy.bin
                    }
                    fan_controllers={fan_controllers as GrainBinFanController[]}
                  />
                ))
            ) : (
              <NoContainerFoundPlaceholder />
            ))}
          {barge_links &&
            selected_container_type === ContainerTypeLegacy.barge &&
            (barge_links.length ? (
              barge_links
                .filter((barge_link) => !barge_link.barge.archived)
                .map(({ barge: { barge_id, alias } }, index) => (
                  <ContainerListItem
                    key={`barge${index}`}
                    containerName={alias}
                    handleClick={() =>
                      handleContainerSelection(barge_id, alias, ContainerTypeLegacy.barge)
                    }
                    // selected & correct type
                    selected={
                      selected_container_id === barge_id &&
                      selected_container_type === ContainerTypeLegacy.barge
                    }
                  />
                ))
            ) : (
              <NoContainerFoundPlaceholder />
            ))}
          {pile_links &&
            selected_container_type === ContainerTypeLegacy.pile &&
            (pile_links.length ? (
              pile_links.map(({ pile: { pile_id, alias } }, index) => (
                <ContainerListItem
                  key={`pile${index}`}
                  containerName={alias}
                  handleClick={() =>
                    handleContainerSelection(pile_id, alias, ContainerTypeLegacy.pile)
                  }
                  // selected & correct type
                  selected={
                    selected_container_id === pile_id &&
                    selected_container_type === ContainerTypeLegacy.pile
                  }
                />
              ))
            ) : (
              <NoContainerFoundPlaceholder />
            ))}
        </List>
      )}
      {account_select}
    </>
  );
};
