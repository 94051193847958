import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GrainBinSiteFragmentFragment as Result,
  RenameGrainBinSiteMutationVariables as Variables,
  withRenameGrainBinSite as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithRenameGrainBinSiteHocChildProps = {
  renameGrainBinSite: (variables: Variables) => Promise<Result | null>;
};

export const withRenameGrainBinSiteHoc: AmberHoc<{}, WithRenameGrainBinSiteHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<{}, WithRenameGrainBinSiteHocChildProps, typeof component>,
    WithRenameGrainBinSiteHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        renameGrainBinSite: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.renameGrainBinSite;
        },
      };
    },
  })(component as any);
