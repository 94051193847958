import { Divider } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pushNav } from '../../../../core/src/action';

import {
  AccountFragmentFragment,
  ViewerFragmentFragment,
  withGetAccountHoc,
  WithGetAccountHocChildProps,
  withPerformGrainBinSelectionHoc,
  WithPerformGrainBinSelectionHocChildProps,
  withPerformGrainBinUnselectionHoc,
  WithPerformGrainBinUnselectionHocChildProps,
} from '../../api';
import { SelectedState } from '../../reducer';
import { ContainerTypeLegacy } from '../../util';
import { ContainerListInner } from './ContainerListInner';
import { AccountList } from './Legacy/AccountList';
import { ContainerBar } from './Mobile';

const selectContainerId = ({ selected: { container_id } }: { selected: SelectedState }) =>
  container_id;
const selectContainerType = ({ selected: { container_type } }: { selected: SelectedState }) =>
  container_type;

const getDefaultContainerType = (
  pathname: string,
  account: AccountFragmentFragment | null
): ContainerTypeLegacy | null => {
  if (account) {
    if (pathname.includes('bin') && account.grain_bin_support) {
      return ContainerTypeLegacy.bin;
    }
    if (pathname.includes('barge') && account.barge_support) {
      return ContainerTypeLegacy.barge;
    }
    if (pathname.includes('pile') && account.pile_support) {
      return ContainerTypeLegacy.pile;
    }
    if (account.grain_bin_support) {
      return ContainerTypeLegacy.bin;
    }
    if (account.barge_support) {
      return ContainerTypeLegacy.barge;
    }
    if (account.pile_support) {
      return ContainerTypeLegacy.pile;
    }
  }
  return null;
};

const getFirstContainerFromContainerType = (
  container_type: ContainerTypeLegacy,
  account: AccountFragmentFragment | null
): {
  container_id: number | null;
  container_name: string | null;
  container_type: ContainerTypeLegacy;
} => {
  const base_select = {
    container_type,
    container_id: null,
    container_name: null,
  };
  if (!account) {
    return base_select;
  }
  const { grain_bin_links, barge_links, pile_links } = account;
  switch (container_type) {
    case ContainerTypeLegacy.bin: {
      if (grain_bin_links && grain_bin_links.length !== 0) {
        const unarchived_bins = grain_bin_links.filter((link) => !link.grain_bin.archived);
        if (unarchived_bins && unarchived_bins.length) {
          return {
            container_id: unarchived_bins[0].grain_bin_id,
            container_name: unarchived_bins[0].grain_bin.alias,
            container_type: ContainerTypeLegacy.bin,
          };
        }
      }
      return { ...base_select, container_type: ContainerTypeLegacy.bin };
    }
    case ContainerTypeLegacy.barge: {
      if (barge_links && barge_links.length !== 0) {
        const unarchived_barges = barge_links.filter((link) => !link.barge.archived);
        if (unarchived_barges && unarchived_barges.length) {
          return {
            container_id: unarchived_barges[0].barge_id,
            container_name: unarchived_barges[0].barge.alias,
            container_type: ContainerTypeLegacy.barge,
          };
        }
      }
      return { ...base_select, container_type: ContainerTypeLegacy.barge };
    }
    case ContainerTypeLegacy.pile: {
      if (pile_links && pile_links.length !== 0) {
        return {
          container_id: pile_links[0].pile_id,
          container_name: pile_links[0].pile.alias,
          container_type: ContainerTypeLegacy.pile,
        };
      }
      return { ...base_select, container_type: ContainerTypeLegacy.pile };
    }
    default:
      return base_select;
  }
};

const ContainerListBase: FunctionComponent<
  WithGetAccountHocChildProps &
    WithPerformGrainBinSelectionHocChildProps &
    WithPerformGrainBinUnselectionHocChildProps & {
      viewer: ViewerFragmentFragment;
      mobile_layout?: boolean;
      pathname: string;
      admin_mode: boolean;
      onSelectContainer: (args: any) => void;
      goCreateBarge: () => void;
      goCreateGrainBin: () => void;
      goCreatePile: () => void;
      setSelectedAccountId: (account: AccountFragmentFragment) => void;
      account_id: number;
    }
> = ({
  viewer,
  loading,
  account,
  mobile_layout = false,
  pathname,
  admin_mode,
  goCreateBarge,
  goCreateGrainBin,
  goCreatePile,
  onSelectContainer,
  setSelectedAccountId,
  account_id,
  performGrainBinSelection,
  performGrainBinUnselection,
}) => {
  const selected_container_id = useSelector(selectContainerId);
  const selected_container_type = useSelector(selectContainerType);
  const dispatch = useDispatch();
  const showOperationViewItem =
    getDefaultContainerType(pathname, account) === ContainerTypeLegacy.bin;

  const handleContainerSelection = async (
    container_id: number | null,
    container_name: string | null,
    container_type: ContainerTypeLegacy
  ) => {
    if (selected_container_type !== container_type || selected_container_id !== container_id) {
      onSelectContainer({
        container_id,
        container_name,
        container_type,
      });
      if (container_id && container_type === ContainerTypeLegacy.bin && account) {
        try {
          await performGrainBinSelection({
            container_id,
            account_id: account.account_id,
            user_id: viewer.user_id,
          });
        } catch (error) {
          console.error('Error while performGrainBinSelection', error);
        }
      }
    }
  };

  const sorted_account = useMemo(() => {
    if (account) {
      const { grain_bin_links, barge_links, pile_links } = account;

      return {
        ...account,
        grain_bin_links: [...grain_bin_links].sort((a, b) => {
          if (a.grain_bin.alias === b.grain_bin.alias) {
            return a.grain_bin_id - b.grain_bin_id;
          }
          return a.grain_bin.alias.toUpperCase() > b.grain_bin.alias.toUpperCase() ? 1 : -1;
        }),
        barge_links: [...barge_links].sort((a, b) => {
          if (a.barge.alias === b.barge.alias) {
            return a.barge_id - b.barge_id;
          }
          return a.barge.alias.toUpperCase() > b.barge.alias.toUpperCase() ? 1 : -1;
        }),
        pile_links: [...pile_links].sort((a, b) => {
          if (a.pile.alias === b.pile.alias) {
            return a.pile_id - b.pile_id;
          }
          return a.pile.alias.toUpperCase() > b.pile.alias.toUpperCase() ? 1 : -1;
        }),
      };
    }
    return null;
  }, [account]);

  useEffect(() => {
    const url_container_type = getDefaultContainerType(pathname, account);
    if (
      url_container_type &&
      url_container_type !== ContainerTypeLegacy.bin &&
      (selected_container_type !== url_container_type || !selected_container_id)
    ) {
      // for barges and piles only
      const newly_selected = getFirstContainerFromContainerType(url_container_type, sorted_account);
      handleContainerSelection(
        newly_selected.container_id,
        newly_selected.container_name,
        newly_selected.container_type
      );
    }
  }, [account_id, selected_container_id, selected_container_type]);

  const defaultContainerType: ContainerTypeLegacy | null =
    (account &&
      ((account.grain_bin_support && ContainerTypeLegacy.bin) ||
        (account.barge_support && ContainerTypeLegacy.barge) ||
        (account.pile_support && ContainerTypeLegacy.pile))) ||
    null;

  const handleCreateGrainContainer = () => {
    const container_type = selected_container_type || getDefaultContainerType(pathname, account);
    switch (container_type) {
      case ContainerTypeLegacy.bin:
        goCreateGrainBin();
        break;
      case ContainerTypeLegacy.barge:
        goCreateBarge();
        break;
      case ContainerTypeLegacy.pile:
        goCreatePile();
        break;
    }
  };

  const account_select = admin_mode && (
    <div>
      <Divider />
      {onSelectContainer && <AccountList value={account_id} onChange={setSelectedAccountId} />}
      <Divider />
    </div>
  );

  return (
    <>
      {!mobile_layout && sorted_account && (
        <ContainerListInner
          selected_container_id={selected_container_id}
          selected_container_type={selected_container_type || defaultContainerType}
          account={sorted_account}
          handleContainerSelection={handleContainerSelection}
          account_select={account_select}
          pathname={pathname}
          onClickCreate={handleCreateGrainContainer}
          loading={loading}
          viewerRole={viewer.user.role}
          onSelectContainer={onSelectContainer}
          performGrainBinUnselection={performGrainBinUnselection}
          viewerUserId={viewer.user_id}
          showOperationViewItem={showOperationViewItem}
        />
      )}
      {mobile_layout && sorted_account && (
        <ContainerBar
          viewer={viewer}
          pathname={pathname}
          selected_container_id={Number(selected_container_id)}
          selected_container_type={selected_container_type}
          account={sorted_account}
          handleContainerSelection={handleContainerSelection}
          onClickCreate={handleCreateGrainContainer}
        />
      )}
    </>
  );
};

export const ContainerList = withGetAccountHoc(
  withPerformGrainBinUnselectionHoc(withPerformGrainBinSelectionHoc(ContainerListBase))
);
