import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import * as yup from 'yup';
import {
  FanModelCreatedType,
  FanModelFilter,
  FanModelResult,
  withGetGrainFanModelsHoc,
  WithGetGrainFanModelsHocChildProps,
} from '../../../api';

import Info from '../../info-section/Info';
import { CenteredSpinner } from '../../spinner';
import ConfirmationModal from '../../util/ConfirmationModal';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../../util/form2/BaseForm';
import { Button, ButtonSubmit } from '../../util/form2/Button';
import { TextField } from '../../util/form2/TextField';
import { GenericFanModelsItems } from './GenericFanModelsItems';
import {
  fan_model_type_to_string,
  FanModelDetails,
  string_to_fan_type,
} from './GrainFanModelSelector';
import { Selector, SelectorProps } from './Selector';
import { sortFanHorsepowers } from './StandardFanModelsSelectorDashboard';

export enum SelectedBoxName {
  FAN_MAKE_NAME = 'Brand',
  FAN_MODEL_TYPE = 'Type',
  FAN_HORSEPOWER = 'Horsepower',
}
export type SelectItem = {
  item_name: string;
  enable: boolean;
  item_value: string;
};

export type GrainFanModelsItemsType = {
  handleSetCurrentFanModelState: (current_fan_model) => void;
  row_data: {
    fan_model_id: number;
    fan_model_name: string;
    make: string;
    fan_brand_name: string;
    fan_model_type: string;
    fan_horsepower: string;
    diameter: string;
  }[];
};

type Values = {
  brand: string;
  model: string;
};

export type GenericFanModelsSelectorDashboardProps = {
  selectedFanModelId: number | null;
  handleSetCurrentFanModelState: (current_fan_model) => void;
  currentGenericCustomFanModelState: FanModelDetails | null;
} & WithGetGrainFanModelsHocChildProps &
  FormikWrapperHandlerProps<Values, any>;

const validationSchema = yup.object().shape({
  brand: yup.string().required(),
  model: yup.string().required(),
});

export const GenericFanModelsSelectorDashboard = withGetGrainFanModelsHoc(
  (props: GenericFanModelsSelectorDashboardProps) => {
    const {
      currentGenericCustomFanModelState,
      handleSetCurrentFanModelState,
      grain_fan_models: fan_models,
      get_grain_fan_models_loading,
      selectedFanModelId,
    } = props;
    console.log(
      'currentGenericCustomFanModelState GenericFanModelsSelectorDashboard',
      currentGenericCustomFanModelState
    );
    if (get_grain_fan_models_loading) {
      return <CenteredSpinner fadeIn="none" />;
    }
    const hasUpdateFlow =
      currentGenericCustomFanModelState !== null && currentGenericCustomFanModelState.fan_model_id;
    const [filters, setFilters] = useState<FanModelFilter[]>([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

    const prevSelected = currentGenericCustomFanModelState
      ? {
          fan_model_id: currentGenericCustomFanModelState.linked_generic_fan_model_id,
          fan_model_type:
            fan_model_type_to_string[currentGenericCustomFanModelState.fan_model_type],
          fan_horsepower: currentGenericCustomFanModelState.fan_horsepower,
          diameter: currentGenericCustomFanModelState.diameter,
        }
      : null;
    const [selected_item, setSelectedItem] = useState<any>(prevSelected);
    const fan_model_results: FanModelResult[] = fan_models
      .filter((fan_model) => fan_model.label === 'all_fan_models')
      .flatMap((c) => c.values)
      .map((c) => c.label)
      .filter(
        (c) => c.fan_model_created_type === FanModelCreatedType.SystemCreated && c.is_generic
      );

    console.log('fan_model_results', fan_model_results);
    const [fan_make_select_items, setFanMakeSelectItems] = useState<SelectItem[]>(
      getSelectItems(fan_model_results, 'make', filters)
    );
    const [fan_model_types_select_items, setFanModelTypeSelectItems] = useState<SelectItem[]>(
      getFanModelSelectItems(fan_model_results, 'fan_model_type', filters)
    );
    const [fan_horsepowers_select_items, setFanHorsepowerSelectItems] = useState<SelectItem[]>(
      getSelectItems(fan_model_results, 'fan_horsepower', filters)
    );
    const [fan_models_to_display, setFanModelsToDisplay] = useState<any[]>(fan_model_results);
    const [show_clear_button_fan_make_name, setShowClearButtonFanMakeName] = useState<boolean>(
      false
    );
    const [show_clear_btn_fan_model_type, setShowClearButtonFanModelType] = useState<boolean>(
      false
    );
    const [show_clear_btn_fan_horsepower, setShowClearButtonFanHorsepower] = useState<boolean>(
      false
    );

    function filterFanModels(fan_model_results: any[], filters: FanModelFilter[]): any[] {
      let filtered_fan_models: any[] = fan_model_results;
      for (const filter of filters) {
        const { label_name, label_value } = filter;
        filtered_fan_models = filtered_fan_models.filter((model) => {
          if (!model[label_name]) {
            console.warn('invalid value for make', model);
          }
          return model[label_name] && model[label_name].toString() === label_value;
        });
      }
      return filtered_fan_models;
    }

    function getFanModelSelectItems(
      original_fan_models: any[],
      label: string,
      filters: FanModelFilter[]
    ): SelectItem[] {
      const select_items = getSelectItems(original_fan_models, label, filters);
      return select_items.map((item) => {
        return {
          ...item,
          item_name: fan_model_type_to_string[item.item_value],
        };
      });
    }

    function getSelectItems(
      original_fan_models: any[],
      label: string,
      filters: FanModelFilter[]
    ): SelectItem[] {
      const filter_fan_models = filterFanModels(original_fan_models, filters);
      const unique_label_vals = new Set(original_fan_models.map((orig) => orig[label]));
      const filtered_label_vals = new Set(filter_fan_models.map((orig) => orig[label]));
      const select_item: SelectItem[] = [];
      for (const unique_label_val of unique_label_vals) {
        if (filtered_label_vals.has(unique_label_val)) {
          select_item.push({
            item_name: unique_label_val,
            item_value: unique_label_val,
            enable: true,
          });
        } else {
          select_item.push({
            item_name: unique_label_val,
            item_value: unique_label_val,
            enable: false,
          });
        }
      }
      return select_item;
    }

    const setSelectedFilter = async (selected_box_name: SelectedBoxName, item_value: string) => {
      console.log('select_box_name, value', selected_box_name, item_value, filters);
      let filters_to_set: FanModelFilter[] = [];
      switch (selected_box_name) {
        case SelectedBoxName.FAN_MAKE_NAME: {
          const fan_brand_name = filters.filter((filter) => filter.label_name === 'make');
          if (fan_brand_name.length > 0) {
            filters_to_set = filters.filter((filter) => filter.label_name !== 'make');
            setShowClearButtonFanMakeName(false);
          } else if (item_value) {
            setShowClearButtonFanMakeName(true);
            filters_to_set = [
              ...filters,
              {
                label_name: 'make',
                label_value: item_value,
              },
            ];
          }
          setFilters(filters_to_set);
          break;
        }
        case SelectedBoxName.FAN_MODEL_TYPE: {
          const fan_brand_name = filters.filter((filter) => filter.label_name === 'fan_model_type');
          if (fan_brand_name.length > 0) {
            filters_to_set = filters.filter((filter) => filter.label_name !== 'fan_model_type');
            setShowClearButtonFanModelType(false);
          } else if (item_value) {
            setShowClearButtonFanModelType(true);
            filters_to_set = [
              ...filters,
              {
                label_name: 'fan_model_type',
                label_value: item_value,
              },
            ];
          }
          setFilters(filters_to_set);
          break;
        }
        case SelectedBoxName.FAN_HORSEPOWER: {
          const fan_brand_name = filters.filter((filter) => filter.label_name === 'fan_horsepower');
          if (fan_brand_name.length > 0) {
            filters_to_set = filters.filter((filter) => filter.label_name !== 'fan_horsepower');
            setShowClearButtonFanHorsepower(false);
          } else if (item_value) {
            setShowClearButtonFanHorsepower(true);
            filters_to_set = [
              ...filters,
              {
                label_name: 'fan_horsepower',
                label_value: item_value,
              },
            ];
          }
          setFilters(filters_to_set);
          break;
        }
        default:
          break;
      }
      setFanMakeSelectItems(getSelectItems(fan_model_results, 'make', filters_to_set));
      setFanModelTypeSelectItems(
        getFanModelSelectItems(fan_model_results, 'fan_model_type', filters_to_set)
      );
      setFanHorsepowerSelectItems(
        getSelectItems(fan_model_results, 'fan_horsepower', filters_to_set)
      );
      setFanModelsToDisplay(filterFanModels(fan_model_results, filters_to_set));
    };
    const fan_make_props: SelectorProps = {
      setSelectedFilter,
      items: fan_make_select_items.sort((item1, item2) =>
        item1.item_name < item2.item_name ? -1 : 1
      ),
      select_box_name: SelectedBoxName.FAN_MAKE_NAME,
      show_clear_button: show_clear_button_fan_make_name,
    };
    const fan_model_types_props: SelectorProps = {
      setSelectedFilter,
      items: fan_model_types_select_items.sort((item1, item2) =>
        item1.item_name < item2.item_name ? -1 : 1
      ),
      select_box_name: SelectedBoxName.FAN_MODEL_TYPE,
      show_clear_button: show_clear_btn_fan_model_type,
    };
    const fan_horsepowers_props: SelectorProps = {
      setSelectedFilter,
      items: sortFanHorsepowers(fan_horsepowers_select_items),
      select_box_name: SelectedBoxName.FAN_HORSEPOWER,
      show_clear_button: show_clear_btn_fan_horsepower,
    };

    const grain_fan_models: GrainFanModelsItemsType = {
      handleSetCurrentFanModelState,
      row_data: fan_models_to_display,
    };

    const initial_values: { brand: string; model: string } = {
      brand: currentGenericCustomFanModelState ? currentGenericCustomFanModelState.make : '',
      model: currentGenericCustomFanModelState
        ? currentGenericCustomFanModelState.fan_brand_name
        : '',
    };

    const submitCallback = async (values: Values) => {
      console.log('values in submitCallback', values);
      setSelectedItem((prev) => ({
        ...prev,
        make: values.brand,
        fan_brand_name: values.model,
      }));
      setShowConfirmationModal(true);
    };

    console.log('selected_item', selected_item);
    return (
      <FormikWrapper<Values, any>
        initialValues={initial_values}
        validationSchema={validationSchema}
        onSubmit={submitCallback}
        render={() => (
          <BaseForm submitting_message="Saving changes..." style={{ width: '100%', padding: 0 }}>
            <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={12}>
                <Info>
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    Can't find a Generic Fan that closely matches your Fan? Go to "Create a Custom
                    Fan". Type in the Fan Type, HP, and Diameter that matches your fan.
                  </Typography>
                </Info>
                <Info style={{ marginTop: 8 }}>
                  <Typography variant="body1" style={{ fontStyle: 'italic', marginBottom: 6 }}>
                    *If your fan motor has a Horse Power (HP) range and you don't see that option,
                    instead choose the HP at the top of the range if it exists as an option.
                  </Typography>
                  <Typography variant="body1" style={{ fontStyle: 'italic' }}>
                    For instance, if the HP is rated at "10-15" but you don't see "10-15" as an
                    option, instead choose "15" if it exists as an option.
                  </Typography>
                </Info>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Grid item xs={12} sm={4}>
                    <TextField name="brand" label="Brand" placeholder="Brand" fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name="model" label="Model" placeholder="Model" fullWidth />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Selector {...fan_model_types_props} width={'100%'} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Selector {...fan_horsepowers_props} width={'100%'} />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}
              >
                <GenericFanModelsItems
                  id={'generic-model-flow'}
                  selectedFanModelId={selectedFanModelId}
                  setSelectedItem={setSelectedItem}
                  showSearchBox={false}
                  {...grain_fan_models}
                />
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonSubmit
                  style={{
                    margin: 0,
                    width: 100,
                  }}
                  variant="contained"
                  color="primary"
                  disabled={currentGenericCustomFanModelState === null && selected_item === null}
                  allow_pristine
                >
                  {hasUpdateFlow ? 'Update' : 'Add'}
                </ButtonSubmit>
              </Grid>
            </Grid>
            {showConfirmationModal && (
              <ConfirmationModal
                showModal={showConfirmationModal}
                confirmationMessage={
                  <>
                    Are you sure you want to add fan model{' '}
                    <strong>
                      {selected_item.make} {selected_item.fan_brand_name}
                    </strong>
                    ?
                  </>
                }
                cancelBtnText="Cancel"
                confirmBtbText="Add"
                handleCancel={() => setShowConfirmationModal(false)}
                handleConfirm={() => {
                  setShowConfirmationModal(false);

                  handleSetCurrentFanModelState({
                    fan_model_id: currentGenericCustomFanModelState
                      ? currentGenericCustomFanModelState.fan_model_id
                      : null,
                    make: selected_item.make, // got from form
                    fan_brand_name: selected_item.fan_brand_name, // got from form
                    fan_model_type: string_to_fan_type[selected_item.fan_model_type],
                    fan_horsepower: selected_item.fan_horsepower,
                    diameter: selected_item.diameter,
                    fan_model_created_type: FanModelCreatedType.GenericCustomCreated,
                    linked_generic_fan_model_id: selected_item.fan_model_id,
                  });
                }}
              />
            )}
          </BaseForm>
        )}
      />
    );
  }
);
