import { makeStyles } from '@material-ui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { getTemperatureUnitLabel } from '../../../../../core/src/util';
import {
  ComputedForecastHourV2FragmentFragment,
  ForecastDayV2FragmentFragment,
} from '../../../api';
import {
  drawBlocksToShowDaysLabels,
  getNightPlotBandsPostions,
  getTooltipInfo,
  SERIES,
  WEATHER_CHART_COLORS,
} from './ForecastPlotHelpers';
declare global {
  interface Window {
    moment: any;
  }
}
window.moment = moment;

export const tooltipContainerStyles = {
  zIndex: 9999,
  backgroundColor: '#FAFCFF',
  padding: 5,
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  borderRadius: 4,
  border: '1px solid gray',
  opacity: 0.9,
};

const useStyles = makeStyles({
  tooltipContainer: {
    ...tooltipContainerStyles,
  },
  legendBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 25,
    width: 98,
    padding: '0px 10px',
    border: 'none',
    borderRadius: 4,
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#e0e0e0',
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#d5d5d5',
    },
  },
  legendBtnContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
  },
  colorDot: {
    width: '12px',
    height: '12px',
    borderRadius: '50% 50%',
  },
  legendLabel: {
    marginLeft: 5,
  },
});

const WeatherForecastPlot = ({
  grain_bin_location_timezone,
  days,
  hourly_forecast_data,
  setWeatherChart,
  syncronizeCrossHairs,
  minDate,
  maxDate,
}: {
  grain_bin_location_timezone: string;
  days: ForecastDayV2FragmentFragment[];
  hourly_forecast_data: ComputedForecastHourV2FragmentFragment[];
  weatherChart: any;
  setWeatherChart: Function;
  syncronizeCrossHairs: Function;
  minDate: DateTime | null;
  maxDate: DateTime | null;
}) => {
  const [chart1, setChart1] = useState(null);

  useEffect(() => {
    syncronizeCrossHairs(chart1);
  }, [chart1]);

  Highcharts.setOptions({
    time: {
      timezone: grain_bin_location_timezone,
    },
  });
  const classes = useStyles();

  const startOfTheWeek = DateTime.fromISO(new Date(days[0].epoch_time).toISOString()).startOf(
    'day'
  );
  const endOfTheWeek = DateTime.fromISO(
    new Date(days[days.length - 1].epoch_time).toISOString()
  ).endOf('day');

  const seven_days_hourly_forecast_data = hourly_forecast_data.filter(
    (forecastHour) =>
      forecastHour.epoch_time.getTime() >= startOfTheWeek.toMillis() &&
      forecastHour.epoch_time.getTime() <= endOfTheWeek.toMillis()
  );

  const { utcHours, hours, temps, humidities, emcs, precip_intensity_inph_list } = useMemo(() => {
    const hours: DateTime[] = [];
    const utcHours: number[] = [];
    const temps: number[] = [];
    const humidities: number[] = [];
    const emcs: number[] = [];
    const precip_intensity_inph_list: number[] = [];
    for (const hourlyForcast of seven_days_hourly_forecast_data) {
      const { epoch_time, temp_f, humidity_rh, emc, precip_intensity_inph } = hourlyForcast;

      if (
        epoch_time &&
        _.isNumber(temp_f) &&
        _.isNumber(humidity_rh) &&
        _.isNumber(emc) &&
        _.isNumber(precip_intensity_inph)
      ) {
        const dt = DateTime.fromMillis(epoch_time.getTime());
        utcHours.push(epoch_time.getTime());
        hours.push(dt);
        temps.push(temp_f);
        humidities.push(humidity_rh);
        emcs.push(emc);
        precip_intensity_inph_list.push(precip_intensity_inph);
      }
    }
    return {
      utcHours,
      hours,
      temps,
      humidities,
      emcs,
      precip_intensity_inph_list,
    };
  }, [seven_days_hourly_forecast_data]);

  const precip_intensity_inph_max = _.max(precip_intensity_inph_list) || 0;
  const asOfDate = DateTime.local();
  let asOfTickValue = asOfDate;

  hours.forEach((dt) => {
    const hasSameDate =
      asOfDate.day === dt.day &&
      asOfDate.hour === dt.hour &&
      asOfDate.month === dt.month &&
      asOfDate.year === dt.year;
    if (hasSameDate) {
      asOfTickValue = dt;
    }
  });

  const nightPlotBandsPostions = getNightPlotBandsPostions(hours, days);
  const tempUnit = getTemperatureUnitLabel();
  const tempUnitWithoutDegreeSymbol = tempUnit[1];

  const options = {
    credits: false,
    chart: {
      type: 'spline',
      spacingTop: 150,
      marginLeft: 75,
      marginRight: 145,
      width: 922,
      height: 454,
      events: {
        load() {
          setChart1(this);
          setWeatherChart(this);
          drawBlocksToShowDaysLabels(this, days);
          syncronizeCrossHairs(this);
          addLegendButtons(this);
        },
      },
      reflow: false,
      ignoreHiddenSeries: false,
      animation: false,
    },
    title: {
      text: '',
      y: 0,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        enabled: false,
        format: '{value:%l:%M %p }', // '{value:%Y-%b-%e %l:%M %p }',
      },
      min: minDate ? minDate.toMillis() : null,
      max: maxDate ? maxDate.toMillis() : null,
      gridLineWidth: 1,
      tickLength: 0,
      gridLineColor: WEATHER_CHART_COLORS.GRID_LINE_COLOR,
      plotBands: nightPlotBandsPostions,
      crosshair: false,
      plotLines: [
        {
          color: WEATHER_CHART_COLORS.AS_OF_HOUR_VERTICAL_LINE,
          value: asOfTickValue.toMillis(),
          zIndex: 1,
          width: 1,
          dashStyle: 'longdashdot',
          label: {
            text: 'Now',
            verticalAlign: 'bottom',
            textAlign: 'center',
            y: 15,
            x: 0,
            rotation: 0,
            style: {
              fontSize: 10,
            },
          },
        },
      ],
    },
    yAxis: [
      {
        title: {
          text: 'Temperature',
          style: {
            color: WEATHER_CHART_COLORS.TEMP_SERIES_COLOR,
            fontFamily: 'Source Sans Pro,sans-serif',
            fontSize: '13px',
          },
        },
        labels: {
          format: `{value} ${tempUnitWithoutDegreeSymbol}`,
          style: {
            fontSize: '12px',
            fontFamily: 'Source Sans Pro,sans-serif',
            color: WEATHER_CHART_COLORS.TEMP_SERIES_COLOR,
          },
        },
        gridLineColor: WEATHER_CHART_COLORS.GRID_LINE_COLOR,
        gridZIndex: 3,
      },
      {
        title: {
          text: 'Humidity',
          style: {
            color: WEATHER_CHART_COLORS.HUMIDITY_SERIES_COLOR,
            fontFamily: 'Source Sans Pro,sans-serif',
            fontSize: '13px',
          },
        },
        labels: {
          formatter() {
            return `${Number(this.value * 100).toFixed(1)} %`;
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Source Sans Pro,sans-serif',
            color: WEATHER_CHART_COLORS.HUMIDITY_SERIES_COLOR,
          },
          x: 5,
        },
        min: 0,
        max: 1,
        tickInterval: 0.2,
        opposite: true,
      },
      {
        title: {
          text: 'EMC',
          style: {
            color: WEATHER_CHART_COLORS.EMC_SERIES_COLOR,
            fontFamily: 'Source Sans Pro,sans-serif',
            fontSize: '13px',
          },
        },
        labels: {
          formatter() {
            return `${Number(this.value * 100).toFixed(1)} %`;
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Source Sans Pro,sans-serif',
            color: WEATHER_CHART_COLORS.EMC_SERIES_COLOR,
          },
        },
        opposite: true,
      },
      {
        visible: false,
        title: {
          text: 'Liquid Precip.',
        },
        min: null,
        max: precip_intensity_inph_max <= 0.3 ? 0.3 : null,
        labels: {
          formatter() {
            return this.value === 0 ? '0 in' : `${Number(this.value).toFixed(2)} in`;
          },
          style: {
            fontFamily: 'Source Sans Pro,sans-serif',
            fontSize: '12px',
          },
        },
      },
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: 'rgba(255,255,255,0)',
      borderWidth: 0,
      borderRadius: 0,
      shadow: false,
      style: {
        zIndex: 9999,
      },
      formatter() {
        const tooltipHeading = Highcharts.dateFormat('%l:%M %p', this.x);
        const seriesdataPointInfo =
          this.points &&
          this.points
            .filter((category) => category.series.visible)
            .map((category) => getTooltipInfo(category))
            .filter((info) => info !== '')
            .join('');
        return `<div class="${classes.tooltipContainer}">
        <b>${tooltipHeading}</b><br/>${seriesdataPointInfo}
        </div>`;
      },
      followPointer: true,
      followTouchMove: true,
      stickOnContact: true,
    },
    series: [
      {
        name: SERIES.TEMPRATURE,
        yAxis: 0,
        data: utcHours.map((timestamp, idx) => ({ x: timestamp, y: temps[idx] })),
        color: WEATHER_CHART_COLORS.TEMP_SERIES_COLOR,
        zoneAxis: 'x',
        zones: [
          {
            value: asOfTickValue.toMillis(),
            color: WEATHER_CHART_COLORS.HIST_TEMP_SERIES_COLOR,
          },
        ],
      },
      {
        name: SERIES.HUMIDITY,
        yAxis: 1,
        data: utcHours.map((timestamp, idx) => ({ x: timestamp, y: humidities[idx] })),
        color: WEATHER_CHART_COLORS.HUMIDITY_SERIES_COLOR,
        zoneAxis: 'x',
        zones: [
          {
            value: asOfTickValue.toMillis(),
            color: WEATHER_CHART_COLORS.HIST_HUMIDITY_SERIES_COLOR,
          },
        ],
      },
      {
        name: SERIES.EMC,
        yAxis: 2,
        data: utcHours.map((timestamp, idx) => ({ x: timestamp, y: emcs[idx] })),
        color: WEATHER_CHART_COLORS.EMC_SERIES_COLOR,
        zoneAxis: 'x',
        zones: [
          {
            value: asOfTickValue.toMillis(),
            color: WEATHER_CHART_COLORS.HIST_EMC_SERIES_COLOR,
          },
        ],
      },
      {
        name: SERIES.PRECIPITAION,
        yAxis: 3,
        type: 'area',
        data: utcHours.map((timestamp, idx) => ({
          x: timestamp,
          y: precip_intensity_inph_list[idx],
        })),
        color: WEATHER_CHART_COLORS.LIQUID_PRECIP_SERIES_COLOR,
        fillOpacity: 0.25,
      },
    ],
    plotOptions: {
      series: {
        showInLegend: false,
        selected: true,
      },
    },
  };

  const addLegendButtons = (chart) => {
    const legendArea = document.querySelector('.weatherChartLegendArea');
    if (legendArea && legendArea.children.length > 0) {
      // return if already drawn legend buttons
      return;
    }
    chart.series.forEach((item, idx) => {
      const legendBtn = document.createElement('div');
      legendBtn.setAttribute('class', classes.legendBtn);

      const legendBtnContent = document.createElement('div');
      legendBtnContent.setAttribute('class', classes.legendBtnContent);

      const colorDot = document.createElement('div');
      colorDot.style.backgroundColor = item.color;
      colorDot.setAttribute('class', classes.colorDot);

      const legendLabel = document.createElement('span');
      legendLabel.setAttribute('class', classes.legendLabel);
      legendLabel.textContent = item.name;

      legendBtnContent.appendChild(colorDot);
      legendBtnContent.appendChild(legendLabel);
      legendBtn.appendChild(legendBtnContent);

      if (item.name === SERIES.EMC) {
        legendBtn.style.width = '75px';
      } else if (item.name === SERIES.HUMIDITY) {
        legendBtn.style.width = '80px';
      } else if (item.name === SERIES.PRECIPITAION) {
        legendBtn.style.width = '178px';
      }

      if (idx > 0) {
        legendBtn.style.marginLeft = '12px';
      }
      legendBtn.addEventListener('click', () => {
        item.setVisible(!item.visible);
        if (!item.visible) {
          item.stateMarkerGraphic.hide();
          item.markerGroup.hide();
          item.halo.hide();
        }
        if (legendBtnContent.style.opacity === '' || legendBtnContent.style.opacity === '1') {
          legendBtnContent.style.opacity = '0.4';
        } else if (legendBtnContent.style.opacity === '0.4') legendBtnContent.style.opacity = '1';
      });
      legendArea && legendArea.appendChild(legendBtn);
    });
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default WeatherForecastPlot;
