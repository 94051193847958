import * as React from 'react';
export const RunPauseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={18.901}
    height={18.901}
    viewBox="0 0 18.901 18.901"
    {...props}
  >
    <defs>
      <radialGradient
        id="radial-gradient"
        cx={2.569}
        cy={0.292}
        r={0.51}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#e6ce00" />
        <stop offset={1} stopColor="#fde94a" />
      </radialGradient>
    </defs>
    <g id="PauseIcon" transform="translate(0.75 0.75)">
      <circle
        id="CircleBG"
        cx={8.7}
        cy={8.7}
        r={8.7}
        transform="translate(17.401 17.401) rotate(180)"
        stroke="#000"
        strokeWidth={1.5}
        fill="url(#radial-gradient)"
      />
      <rect id="rect4182" width={1.628} height={7.688} transform="translate(6.136 4.857)" />
      <rect id="rect4184" width={1.628} height={7.688} transform="translate(9.637 4.857)" />
    </g>
  </svg>
);

export const MediumRunPauseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={21.901}
    height={21.901}
    viewBox="0 0 21.901 21.901"
    {...props}
  >
    <defs>
      <radialGradient
        id="radial-gradient"
        cx={2.569}
        cy={0.292}
        r={0.51}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#e6ce00" />
        <stop offset={1} stopColor="#fde94a" />
      </radialGradient>
    </defs>
    <g id="PauseIcon" transform="translate(0.75 0.75)">
      <circle
        id="CircleBG"
        cx={10.2}
        cy={10.2}
        r={10.2}
        transform="translate(20.401 20.401) rotate(180)"
        stroke="#000"
        strokeWidth={1.5}
        fill="url(#radial-gradient)"
      />
      <rect id="rect4182" width={2.628} height={9.688} transform="translate(6.386 5.357)" />
      <rect id="rect4184" width={2.628} height={9.688} transform="translate(11.386 5.357)" />
    </g>
  </svg>
);
