import React from 'react';

export type info = {
  sitesCount: number;
  binsCount: number;
  fansCount: number;
  allSitesGrainLevelCount: number;
};

type TContext = info | undefined;

export const OperationsViewInfoContext = React.createContext({
  info: undefined as TContext,
  setInfo: (value: TContext) => {},
});

export const OperationsViewInfoProvider = ({
  allSiteInfo,
  children,
}: {
  allSiteInfo?: info;
  children: React.PropsWithChildren<any>;
}) => {
  const initialValue = allSiteInfo || undefined;
  const [context, setContext] = React.useState<TContext>(initialValue);
  const value = {
    info: context,
    setInfo: setContext,
  };

  return (
    <OperationsViewInfoContext.Provider value={value}>
      {children}
    </OperationsViewInfoContext.Provider>
  );
};
