import React, { useEffect, useState } from 'react';
import { LinePlaceholder } from './LinePlaceholder';

const SiteDetails = (props) => {
  const { node } = props;
  const [expanded, setExpanded] = useState(node.expanded);

  useEffect(() => {
    const expandListener = (event) => setExpanded(event.node.expanded);

    node.addEventListener('expandedChanged', expandListener);

    return () => {
      node.removeEventListener('expandedChanged', expandListener);
    };
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {expanded ? <LinePlaceholder /> : props.children}
    </div>
  );
};

const SiteDetailsWithoutPlaceholder = (props) => {
  const { node } = props;
  const [expanded, setExpanded] = useState(node.expanded);

  useEffect(() => {
    const expandListener = (event) => setExpanded(event.node.expanded);

    node.addEventListener('expandedChanged', expandListener);

    return () => {
      node.removeEventListener('expandedChanged', expandListener);
    };
  }, []);

  if (expanded) return null;

  return (
    <div
      style={{
        display: 'flex',
        paddingRight: 5,
        paddingLeft: 10,
      }}
    >
      {props.children}
    </div>
  );
};

export { SiteDetails, SiteDetailsWithoutPlaceholder };
