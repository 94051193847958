import { makeStyles, Theme } from '@material-ui/core';
import DarkCheckboxSvg from '../../../../../core/src/media/DarkCheckboxSvg.svg';
import DragIconSvg from '../../../../../core/src/media/DragIconSvg.svg';
import DropCollapseIconSvg from '../../../../../core/src/media/DropCollapseIconSvg.svg';
import DropExpandIconSvg from '../../../../../core/src/media/DropExpandIconSvg.svg';
import { black_shade_2, black_shade_8, gray_shade_4 } from '../../../../../core/src/style';

export const useStyles = makeStyles((theme: Theme) => ({
  conatiner: {
    fontFamily: 'Source Sans Pro,sans-serif',
    '& .ag-header-cell': {
      borderRight: 'solid 1px var(--ag-border-color, #babfc7)',
      color: black_shade_8,
      fontSize: 16,
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 600,
    },
    '& .ag-cell-wrapper': {
      width: '100%',
    },
    '& .ag-header-group-cell': {
      borderRight: 'solid 1px var(--ag-border-color, #babfc7)',
      paddingLeft: 5,
      paddingRight: 5,
    },
    '& .ag-cell': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: 'solid 1px var(--ag-border-color, #babfc7)',
      paddingLeft: 5,
      paddingRight: 5,
      color: black_shade_8,
      fontSize: 10,
      textAlign: 'center',
    },
    '& .ag-group-value': { flexGrow: 1 },
    '& .ag-header-cell-label': {
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .ag-header-group-cell-label': {
      justifyContent: 'center',
      alignItems: 'center',
      color: black_shade_8,
      fontSize: 16,
      fontWeight: 600,
    },
    '& .ag-cell-wrap-text': {
      wordBreak: 'break-word',
    },

    '& .ag-checkbox-input-wrapper': {
      backgroundColor: '#fff',
      width: 24,
      height: 24,
      '&::after': {
        width: '24px!important',
        height: '24px!important',
        content: '" " !important',
        border: `1px solid ${gray_shade_4}`,
        borderRadius: 4,
      },
      '& input[type="checkbox"]': {
        width: 24,
        height: 24,
        cursor: 'pointer',
      },
    },

    '& .ag-checkbox-input-wrapper.ag-checked::after': {
      color: black_shade_2,
      background: `transparent url(${DarkCheckboxSvg}) center/contain no-repeat!important`,
      width: '24px!important',
      height: '24px!important',
      content: '" " !important',
      borderWidth: 0,
    },
    '& .ag-row-group-leaf-indent': {
      marginLeft: 0,
      fontSize: 14,
      color: black_shade_8,
    },
    '& .ag-text-field-input-wrapper': {
      fontSize: 14,
    },

    '& .ag-icon-grip': {
      background: `transparent url(${DragIconSvg}) center/contain no-repeat`,
      color: 'transparent',
      width: 14,
      height: 20,
    },
    '& .ag-icon-tree-closed': {
      background: `transparent url(${DropCollapseIconSvg}) center/contain no-repeat`,
      color: 'transparent',
    },
    '& .ag-icon-tree-closed:hover': {
      color: 'transparent !important',
    },
    '& .ag-icon-tree-open': {
      background: `transparent url(${DropExpandIconSvg}) center/contain no-repeat`,
      color: 'transparent',
      '-webkit-transform': 'rotate(180deg)',
      '-moz-transform': 'rotate(180deg)',
      '-o-transform': 'rotate(180deg)',
      ' -ms-transform': 'rotate(180deg)',
      transform: 'rotate(180deg)',
    },
    '& .ag-icon-tree-open:hover': {
      color: 'transparent !important',
      '-webkit-transform': 'rotate(180deg)',
      '-moz-transform': 'rotate(180deg)',
      '-o-transform': 'rotate(180deg)',
      ' -ms-transform': 'rotate(180deg)',
    },

    '& .ag-row-drag': {
      marginLeft: 16,
      marginRight: 16,
    },
    '& .ag-group-expanded': {
      marginRight: 10,
    },
    '& . ag-group-contracted': {
      marginRight: 10,
    },
    '& .ag-row-group-indent-1': {
      paddingLeft: 25,
    },
    '& .ag-row-selected::before': {
      backgroundColor: 'unset !important',
    },

    '& .ag-cell-inline-editing': {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },

    // '& .ag-cell-edit-wrapper': {
    //   width: "90% !important"
    // }
    // '& .ag-header-row': {
    //   height: '44px!important',
    //   // top: '44px!important',
    // },
  },
}));
