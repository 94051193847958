import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { UserFragmentFragment as Result, withGetUser as HOC } from '../__generated';

export type WithGetUserHocOwnProps = {
  user_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetUserHocChildProps = {
  refecthUser: () => Promise<any>;
  loading: boolean;
  user: Result | null;
};
export const withGetUserHoc: AmberHoc<WithGetUserHocOwnProps, WithGetUserHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<WithGetUserHocOwnProps, WithGetUserHocChildProps, typeof component>,
    WithGetUserHocChildProps
  >({
    options: ({ user_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      fetchPolicy: 'network-only',
      variables: { user_id },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          refecthUser: () => Promise.resolve(),
          loading: true,
          user: null,
        };
      }
      return {
        refecthUser: () => data.refetch(),
        loading: loading || false,
        user: data.user || null,
      };
    },
  })(component as any);
