import { Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { AccessTimeOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useContext } from 'react';
import { useSelector } from 'react-redux';
import { withGetGrainBinHoc, WithGetGrainBinHocChildProps } from '../../api';
import { ActiveStoragePeriodContext } from '../../contexts';
import { amber_dark_grey } from '../../style';
import { estimateAndFormatBushels, formatGrainType } from '../../util';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginBottom: -5,
    width: 24,
    height: 24,
    [theme.breakpoints.down('xs')]: {
      width: 18,
      height: 20,
    },
  },
  grainInfoHeading: {
    marginBottom: '0.8rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: 0,
    },
  },
}));

const TimeZone = ({ tz, userBrowserTimezone: { offset } }) => {
  const classes = useStyles();
  const grainBinTimezoneDate = DateTime.local()
    .setZone(tz)
    .setLocale('en-US');
  const grainBinTimezoneOffset = grainBinTimezoneDate.offset;
  const grainBinTimezoneOffsetNameShort = grainBinTimezoneDate.offsetNameShort;
  const timezoneDiffInHrs = Math.round(grainBinTimezoneOffset - offset) / 60;

  return (
    <>
      &nbsp;|&nbsp;
      <AccessTimeOutlined className={classes.icon} />
      {` ${grainBinTimezoneOffsetNameShort}, `}
      {timezoneDiffInHrs > 0 ? `+${timezoneDiffInHrs}` : timezoneDiffInHrs}
      {` Hrs`}
    </>
  );
};

const GrainBinGrainTypeBase: FunctionComponent<
  WithGetGrainBinHocChildProps & {
    grain_bin_id: number;
    fit_content?: boolean;
  }
> = ({ grain_bin, fit_content }) => {
  if (!grain_bin) {
    return null;
  }
  const userBrowserTimezone = useSelector(({ user_browser_timezone }) => user_browser_timezone);
  const classes = useStyles();
  const ctx = useContext(ActiveStoragePeriodContext);
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  if (ctx.activeStoragePeriod && !ctx.activeStoragePeriod.is_ongoing) {
    return null;
  }

  const { grain_type, diameter_ft, height_ft, tz } = grain_bin;
  const bu = diameter_ft && height_ft ? estimateAndFormatBushels(diameter_ft, height_ft) : null;
  return (
    <Typography
      className={classes.grainInfoHeading}
      variant="h5"
      style={{
        textAlign: isWideScreen ? 'start' : 'center',
        color: amber_dark_grey,
        fontWeight: 400,
        ...(fit_content ? { width: 'fit-content' } : {}),
      }}
    >
      {formatGrainType(grain_type)}
      {bu ? ` | ${bu} bin` : ''}
      {tz && userBrowserTimezone.timezone !== tz && (
        <TimeZone tz={tz} userBrowserTimezone={userBrowserTimezone} />
      )}
    </Typography>
  );
};

export const GrainBinGrainType = withGetGrainBinHoc(GrainBinGrainTypeBase);
