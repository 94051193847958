import { DateTime } from 'luxon';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';

import { UiFormError } from '../../../../core/src/util/format-error';
import {
  CreateGrainBinSystemMutationVariables,
  FanType,
  FloorType,
  GrainType,
  HubType,
  withCreateBinStoragePeriodHoc,
  withCreateGrainBinSystemHoc,
  WithCreateGrainBinSystemHocChildProps,
} from '../../api/graphql';
import { LocationValue } from '../util/form2/LocationPicker';
import {
  AddFanControllerStep,
  canFanControllerBeAssigned,
  hasDuplicateFanControllers,
  validation_schema as fan_validation_schema,
} from './AddFanControllerStep';
import {
  AddHubStep,
  canHubBeAssigned,
  hasDuplicateHubs,
  validation_schema as hub_validation_schema,
} from './AddHubStep';
import { AddStoragePeriodStep, storage_period_schema } from './AddStoragePeriodStep';
import { CreateGrainContainerStep, validationSchema } from './CreateGrainContainerStep';
import { Wizard } from './Wizard';

type Values = {
  hubs: {
    core_id: string;
    nickname: string;
    hub_type: HubType;
    has_level_sensor: boolean;
    hub_offset_ft: number;
  }[];
  fan_controllers: {
    fan_controller_id: string;
    fan_type: FanType;
    fan_power_hp: number;
    fan_alias: string;
    phase?: number;
    power_factor?: number;
    voltage?: number;
    no_of_fans_connected: number;
  }[];
  add_storage_period: boolean;
  alias: string;
  grain_type: GrainType | '';
  height_ft: number | '';
  diameter_ft: number | '';
  has_grain_spreader: boolean;
  location: LocationValue;
  storage_period_name: string | null;
  start_date: string;
  end_date: string | null;
  set_as_ongoing: boolean;
  floor_type: FloorType;
};

const CreateGrainBinWizardBase: FunctionComponent<
  {
    account_id: number;
    onCreateSuccess: (result: any) => any;
  } & WithApolloClient<any> &
    WithCreateGrainBinSystemHocChildProps
> = ({ account_id, client, onCreateSuccess, createGrainBinSystem, createBinStoragePeriod }) => {
  const [add_hub_validation, add_fc_validation, storage_period_validation] = useMemo(
    () => [hub_validation_schema, fan_validation_schema, storage_period_schema],
    []
  );
  const submitSuccessCallback = useCallback(
    (result) => {
      if (result) {
        onCreateSuccess(result);
      }
    },
    [onCreateSuccess]
  );

  return (
    <Wizard<Values>
      initialValues={{
        hubs: [],
        add_storage_period: false,
        fan_controllers: [],
        alias: '',
        grain_type: '',
        height_ft: '',
        diameter_ft: '',
        has_grain_spreader: false,
        floor_type: FloorType.FullFloorAeration,
        location: {
          latlng: null,
          latlng_str: '',
        },

        storage_period_name: '',
        start_date: DateTime.local().toFormat('yyyy-MM-dd'),
        end_date: '',
        set_as_ongoing: true,
      }}
      onSubmit={async (values) => {
        console.log('values in onSubmit', values);
        const mutation_vars: CreateGrainBinSystemMutationVariables = {
          ...values,
          account_id,
          grain_type: values.grain_type || GrainType.Corn,
          floor_type: values.floor_type || FloorType.FullFloorAeration,
          height_ft: Number(values.height_ft),
          diameter_ft: Number(values.diameter_ft),
          location: {
            latitude: Number(values.location.latlng && values.location.latlng.lat),
            longitude: Number(values.location.latlng && values.location.latlng.lng),
          },
          hubs:
            values.hubs.length > 0
              ? values.hubs.map((hub) => ({ ...hub, core_id: String(hub.core_id).trim() }))
              : [],
          fan_controllers:
            values.fan_controllers.length > 0
              ? values.fan_controllers.map((fc) => ({
                  ...fc,
                  fan_controller_id: String(fc.fan_controller_id).trim(),
                  no_of_fans_connected: Number(fc.no_of_fans_connected),
                }))
              : [],
        };
        // fan model validation
        if (mutation_vars.fan_controllers.length > 0) {
          mutation_vars.fan_controllers.forEach((fan_controller, idx) => {
            if (fan_controller) {
              const presentKeys = Object.keys(fan_controller);
              const hasFanModelNotSelected =
                !presentKeys.includes('make') ||
                !presentKeys.includes('fan_brand_name') ||
                !presentKeys.includes('fan_model_type') ||
                !presentKeys.includes('fan_horsepower') ||
                !presentKeys.includes('diameter') ||
                !presentKeys.includes('fan_model_created_type') ||
                !presentKeys.includes('linked_generic_fan_model_id') ||
                !presentKeys.includes('fan_model_id');

              if (hasFanModelNotSelected) {
                console.error(`Please Select Valid Fan Model for Fan Controller-${idx + 1}`);
                throw new UiFormError(
                  `Please Select Valid Fan Model for Fan Controller-${idx + 1}`
                );
              }
            }
          });
        }
        console.log('values & mutation_vars', { values, mutation_vars });
        const grain_bin_results = await createGrainBinSystem(mutation_vars);
        const { grain_bin_id } = grain_bin_results;

        if (values.add_storage_period && grain_bin_id) {
          const startDate = DateTime.fromFormat(values.start_date, 'yyyy-MM-dd').toJSDate();
          const endDate =
            !values.set_as_ongoing && values.end_date
              ? DateTime.fromFormat(values.end_date, 'yyyy-MM-dd').toJSDate()
              : null;
          await createBinStoragePeriod({
            grain_bin_id,
            storage_cycle_name: values.storage_period_name,
            grain_type: values.grain_type,
            start_epoch: startDate,
            end_epoch: endDate,
          });
        }

        return grain_bin_results;
      }}
      onSubmitSuccess={submitSuccessCallback}
    >
      <CreateGrainContainerStep validationSchema={validationSchema} />
      <AddStoragePeriodStep
        validationSchema={storage_period_validation}
        validateOnNext={async (values: any) => {
          if (values.storage_period_name && values.storage_period_name.trim().length > 0) {
            if (!values.start_date) {
              return 'Please specify a valid start date';
            }
            const startDate = DateTime.fromFormat(values.start_date, 'yyyy-MM-dd').toJSDate();

            let endDate: Date | null = null;
            if (!values.set_as_ongoing) {
              {
                if (values.end_date) {
                  endDate = DateTime.fromFormat(values.end_date, 'yyyy-MM-dd').toJSDate();
                } else {
                  return 'Please specify a valid end date';
                }
              }
            }

            if (startDate && endDate && startDate.getTime() > endDate.getTime()) {
              return 'The start date cannot be greater than the end date';
            }
          }

          return null;
        }}
      />
      <AddHubStep
        validationSchema={add_hub_validation}
        validateOnNext={async (values: any) => {
          if (
            !(values as any).hubs ||
            (values as any).hubs.filter((val) => val && val.core_id).length === 0
          ) {
            return null;
          }

          if (values.hubs && values.hubs.length > 0) {
            const hubs =
              values.hubs.length > 0
                ? values.hubs.map((hub) => ({ ...hub, core_id: String(hub.core_id).trim() }))
                : [];
            if (hasDuplicateHubs(hubs)) {
              throw new UiFormError(
                'Hubs with duplicate Hub IDs were entered for this grain container. Each Hub ID for each Device should be unique. Check to make sure the IDs were entered correctly.'
              );
            }
          }

          return await canHubBeAssigned(
            client,
            (values as any).hubs && (values as any).hubs.map((hub) => String(hub.core_id).trim())
          );
        }}
      />

      <AddFanControllerStep
        validationSchema={add_fc_validation}
        validateOnNext={async (values: any) => {
          if (
            !(values as any).fan_controllers ||
            (values as any).fan_controllers.filter((val) => val && val.fan_controller_id).length ===
              0
          ) {
            return null;
          }

          if (values.fan_controllers && values.fan_controllers.length > 0) {
            const fan_controllers =
              values.fan_controllers.length > 0
                ? values.fan_controllers.map((fan_controller) => ({
                    ...fan_controller,
                    fan_controller_id: String(fan_controller.fan_controller_id).trim(),
                  }))
                : [];
            if (hasDuplicateFanControllers(fan_controllers)) {
              throw new UiFormError(
                'Fan Controllers with duplicate Fan Controller IDs were entered for this grain container. Each Fan Controller ID for each Device should be unique.  Check to make sure the IDs were entered correctly.'
              );
            }
          }

          return await canFanControllerBeAssigned(
            client,
            (values as any).fan_controllers &&
              (values as any).fan_controllers.map((fc) => String(fc.fan_controller_id).trim())
          );
        }}
      />
    </Wizard>
  );
};

export const CreateGrainBinWizard = withCreateGrainBinSystemHoc(
  withCreateBinStoragePeriodHoc(withApollo(CreateGrainBinWizardBase))
);
