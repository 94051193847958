import { Typography } from '@material-ui/core';
import React from 'react';
import { formatTemp } from '../../util';

export const HighestLowestTemp = ({ highest, lowest }: { highest: number; lowest: number }) => {
  if (isNaN(highest) || isNaN(lowest)) {
    return (
      <Typography variant="h4" color="textPrimary" style={{ textAlign: 'center' }}>
        No Temp Data Found
      </Typography>
    );
  }
  return (
    <>
      <Typography variant="h2" color="textPrimary" style={{ textAlign: 'center', fontWeight: 500 }}>
        {formatTemp(highest)} / {formatTemp(lowest)}
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" style={{ textAlign: 'center' }}>
        Highest and Lowest Recorded Temperature Readings
      </Typography>
    </>
  );
};
