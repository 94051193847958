import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy } from '../../../util';
import {
  ContainerType,
  RecommRunWindowsExperiments,
  withGetRecommendedRunWindowsExperiments as HOC,
} from '../__generated';

export type GetRecommendedRunWindowsExperimentsHocOwnProps = {
  grain_bin_id: number;
  recommendation_type: string | null;
  cfm_scaling?: number;
  cfm_offset?: number;
  cfm_min?: number;
  cfm_max?: number;
  cfm?: number;
  upper_bound?: number;
  lower_bound?: number;
  from_weather_history?: boolean;
  prev_week_no?: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetRecommendedRunWindowsExperimentsHocChildProps = {
  loading: boolean;
  experiment_run_windows: RecommRunWindowsExperiments[];
  refetch_experiment_run_windows: (variables) => Promise<any>;
};
export const withGetRecommendedRunWindowsExperimentsHoc: AmberHoc<
  GetRecommendedRunWindowsExperimentsHocOwnProps,
  WithGetRecommendedRunWindowsExperimentsHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      GetRecommendedRunWindowsExperimentsHocOwnProps,
      WithGetRecommendedRunWindowsExperimentsHocChildProps,
      typeof component
    >,
    WithGetRecommendedRunWindowsExperimentsHocChildProps
  >({
    options: ({
      grain_bin_id,
      recommendation_type,
      cfm_scaling,
      cfm_offset,
      cfm_min,
      cfm_max,
      cfm,
      upper_bound,
      lower_bound,
      from_weather_history,
      prev_week_no,
      onError,
    }) => ({
      onError,
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      variables: {
        recommendation_type,
        cfm_scaling,
        cfm_offset,
        cfm_min,
        cfm_max,
        cfm,
        upper_bound,
        lower_bound,
        from_weather_history,
        prev_week_no,
        container_id: grain_bin_id,
        container_type: ContainerTypeLegacy.bin,
      },
      notifyOnNetworkStatusChange: true,
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          experiment_run_windows: [],
          refetch_experiment_run_windows: (variables) => Promise.resolve(),
        };
      }
      return {
        loading: loading || false,
        experiment_run_windows: data.recommended_run_windows_experiments || [],
        refetch_experiment_run_windows: (variables) => data.refetch(variables),
      };
    },
  })(component as any);
