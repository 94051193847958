import { ApolloClient } from 'apollo-client';
import { delay } from 'redux-saga';
import { cancel, cancelled, fork, take, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ContainerTypeLegacy } from '../util/constant';

import { pollBackgroundFanControllerState, pollFanControllerState } from '../action';
import {
  FanControllerStateFragmentFragment,
  FanControllerStateValueNext,
  GetFanControllerStateDocument,
  GetFanControllerStateQuery,
  GetFanControllerStateQueryVariables,
  GetGrainContainerHistoryAerationRunsWithLimitDocument,
  GetGrainContainerHistoryAerationRunsWithLimitQuery,
  GetGrainContainerHistoryAerationRunsWithLimitQueryVariables,
  PingFanControllerDocument,
  PingFanControllerMutation,
} from '../api';

const fan_controller_ids: Map<number, any> = new Map();
let bg_fan_controller_poll_task: any;

function* pollFanControllerStateSaga(
  apollo_client: ApolloClient<any>,
  action: ReturnType<typeof pollFanControllerState>
) {
  try {
    const {
      payload: { fan_controller_id, container_id, container_type, storage_period },
    } = action;
    const existing_task = fan_controller_ids.get(fan_controller_id);
    if (existing_task) {
      yield cancel(existing_task);
    }

    const new_task = yield fork(
      progressivePingAndPoll,
      apollo_client,
      fan_controller_id,
      container_id,
      container_type,
      storage_period
    );
    fan_controller_ids.set(fan_controller_id, new_task);
  } catch (e) {
    console.error(e);
  }
}

function* pollBackgroundFanControllerStateSaga(
  apollo_client: ApolloClient<any>,
  action: ReturnType<typeof pollBackgroundFanControllerState>
) {
  try {
    const {
      payload: { fan_controller_ids },
    } = action;

    // cancel existing poll if there is one
    if (bg_fan_controller_poll_task) {
      yield cancel(bg_fan_controller_poll_task);
      bg_fan_controller_poll_task = null;
    }

    // if non-null ids start new poll
    // ids may be null if navigating to an account or asset without f
    if (fan_controller_ids) {
      bg_fan_controller_poll_task = yield fork(
        pollForFanEventsLoop,
        apollo_client,
        fan_controller_ids
      );
    }
  } catch (e) {
    console.error(e);
  }
}

// TODO: revisit
// periodic checks over 30s
// a little confusing, but testing out a more efficient ping/poll strategy
// at this time (2/14/20), the current first pass of vibration completely blocks the fan controller for the first 7-10s
// ix 0, 1, 5 pertain to polling
// ix 2, 3, 4 pertain to pingging
// ideally we move ot a messaging/websockets and then just deal
const poll_delays = [5000, 5000, 5000, 5000, 5000, 5000];

const isReady = ({ value_next, is_on, config }: FanControllerStateFragmentFragment): boolean => {
  if (!config) {
    return false;
  }
  const now = new Date().getTime();
  const { run_window } = config;
  return (
    ((value_next === FanControllerStateValueNext.Ready ||
      value_next === FanControllerStateValueNext.Scheduled) &&
      (run_window &&
        run_window.start_epoch.getTime() <= now &&
        now < run_window.end_epoch.getTime() &&
        is_on)) ||
    ((!run_window || now < run_window.start_epoch.getTime()) && !is_on)
  );
};

function* progressivePingAndPoll(
  apollo_client: ApolloClient<any>,
  fan_controller_id: number,
  container_id: number,
  container_type: ContainerTypeLegacy,
  storage_period?: {
    grain_bin_storage_cycle_id: number;
  } | null
) {
  let attempts = 0;

  while (attempts < poll_delays.length) {
    yield delay(poll_delays[attempts]);
    // todo put back? bombing on trying to access non-existent value in the cache
    // try {
    //   const current = apollo_client.readQuery<
    //     GetFanControllerStateQuery,
    //     GetFanControllerStateQueryVariables
    //   >({
    //     query: GetFanControllerStateDocument,
    //     variables: { fan_controller_id },
    //   });
    //   if (current && isReady(current.fan_controller_state)) {
    //     return;
    //   }
    //   attempts += 1;
    // } catch (e) {
    //   attempts += 1;
    //   console.log('poll');
    //   console.log(e);
    // }

    // send ping in case fan controller is offline
    if (attempts >= 2 && attempts <= 4) {
      yield apollo_client.mutate<PingFanControllerMutation>({
        mutation: PingFanControllerDocument,
        variables: { fan_controller_id },
        errorPolicy: 'all',
      });
    }
    // poll for fan state
    if (attempts < 2 || attempts > 4) {
      const result = yield apollo_client.query<GetFanControllerStateQuery>({
        query: GetFanControllerStateDocument,
        variables: { fan_controller_id },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });
      yield apollo_client.writeQuery<
        GetFanControllerStateQuery,
        GetFanControllerStateQueryVariables
      >({
        query: GetFanControllerStateDocument,
        data: result.data,
        variables: { fan_controller_id },
      });
    }
    // poll for updated aeration runs history
    if (attempts === 0 || attempts === 5) {
      const aerationHistoryResult = yield apollo_client.query<
        GetGrainContainerHistoryAerationRunsWithLimitQuery
      >({
        query: GetGrainContainerHistoryAerationRunsWithLimitDocument,
        variables: {
          container_id,
          container_type,
          ...(storage_period && {
            current_grain_bin_storage_cycle_id: storage_period.grain_bin_storage_cycle_id,
          }),
          limit: 10,
          offset: 0,
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });
      yield apollo_client.writeQuery<
        GetGrainContainerHistoryAerationRunsWithLimitQuery,
        GetGrainContainerHistoryAerationRunsWithLimitQueryVariables
      >({
        query: GetGrainContainerHistoryAerationRunsWithLimitDocument,
        data: aerationHistoryResult.data,
        variables: {
          container_id,
          container_type,
          ...(storage_period && {
            current_grain_bin_storage_cycle_id: storage_period.grain_bin_storage_cycle_id,
          }),
          limit: 10,
          offset: 0,
        },
      });
    }

    attempts += 1;
    // if (isReady(result.data.fan_controller_state)) {
    //   return;
    // }
  }
}

function* pollForFanEventsLoop(apollo_client: ApolloClient<any>, bg_fan_controller_ids: number[]) {
  try {
    while (true) {
      // poll every 10 sec
      yield delay(1000 * 10);
      const state_results: any[] = [];

      for (let i = 0; i < bg_fan_controller_ids.length; i += 1) {
        const result = yield apollo_client.query<GetFanControllerStateQuery>({
          query: GetFanControllerStateDocument,
          variables: { fan_controller_id: bg_fan_controller_ids[i] },
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
        });
        state_results.push(result);
      }

      // update cache in unison
      for (let i = 0; i < bg_fan_controller_ids.length; i += 1) {
        if (state_results[i]) {
          yield apollo_client.writeQuery<
            GetFanControllerStateQuery,
            GetFanControllerStateQueryVariables
          >({
            query: GetFanControllerStateDocument,
            data: state_results[i].data,
            variables: { fan_controller_id: state_results[i].fan_controller_id },
          });
        }
      }
    }
  } finally {
    // console.log('Event poll loop ended');
  }
}

export function* backgroundFanStatusPollWatcher(apollo_client: ApolloClient<any>) {
  yield takeEvery(
    getType(pollBackgroundFanControllerState),
    pollBackgroundFanControllerStateSaga,
    apollo_client
  );
}

export function* pollWatcher(apollo_client: ApolloClient<any>) {
  yield takeEvery(getType(pollFanControllerState), pollFanControllerStateSaga, apollo_client);
}
