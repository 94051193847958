import { Grid, LinearProgress, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { DateTime } from 'luxon';
import React from 'react';
import {
  FanControllerStateValueNext,
  FanHeartbeatErrorCode,
  FanRunWindowRecommendedOption,
} from '../../.././../../core/src/api';
import {
  amber_blue,
  amber_green,
  amber_grey,
  amber_red,
  orange_shade_1,
} from '../../.././../../core/src/style';
import {
  getRunningWindowTimeRemain,
  SimpleFanStatus,
} from '../../grain-container/aeration/FanStatusHelpers';
import {
  getRecommendationOptionColor,
  getRunWindowProgress,
} from '../../grain-container/aeration/UpcomingFanScheduleTable';
import SkeletonLoader from '../../util/SkeletonLoader';
import { GridContextType } from '../OperationsViewTable';
import { SiteDetails } from './SiteDetails';

type makeStylesProps = {
  aeration_schedule_type: string | null;
};

const useStyles = makeStyles((theme: Theme) => ({
  dot_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 10,
  },
  color_dot: {
    height: 10,
    width: 10,
    borderRadius: '50%',
  },
  stateCountBlock: {
    width: 20,
    height: 16,
    fontSize: 12,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  stateCountsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 128,
    height: '100%',
    borderRight: '1px dashed var(--ag-border-color, #babfc7)',
  },

  modeCountsText: {
    color: 'black',
    fontSize: 12,
    fontWeight: 600,
    width: 130,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linearProgressContainer: {
    width: '100%',
    marginTop: 5,
  },
  linearProgress: {
    backgroundColor: '#E6E6E6',
    '& .MuiLinearProgress-barColorPrimary': {
      zIndex: 0,
      background: ({ aeration_schedule_type }: makeStylesProps) => {
        return aeration_schedule_type
          ? `linear-gradient(120deg,rgba(255,255,255, 0) 30%,rgba(255,255,255, .8),rgba(255,255,255, 0) 70% ) ${getRecommendationOptionColor(
              aeration_schedule_type
            )}`
          : '';
      },
      backgroundSize: '300% 100%!important',
    },
  },
}));

export const getFanStatusInfo = (
  state: FanControllerStateValueNext,
  is_on: boolean
): { status: SimpleFanStatus; color: string } => {
  if (state === FanControllerStateValueNext.Offline) {
    return { status: SimpleFanStatus.OFFLINE, color: amber_red };
  }
  if (state === FanControllerStateValueNext.Updating) {
    return { status: SimpleFanStatus.UPDATING, color: amber_grey };
  }
  if (is_on) {
    return { status: SimpleFanStatus.RUNNING, color: amber_green };
  }
  return { status: SimpleFanStatus.READY, color: amber_blue };
};

const getTimeRemain = ({
  isAnyFanRunning,
  scheduled_start,
  scheduled_end,
  hasManualMode,
  hasFanStopFailError,
}: {
  isAnyFanRunning: boolean;
  scheduled_start: Date;
  scheduled_end: Date | null;
  hasManualMode: boolean;
  hasFanStopFailError: boolean;
}): string => {
  const endEpochDt = scheduled_end && DateTime.fromMillis(scheduled_end.getTime());
  const startEpochDt = scheduled_start && DateTime.fromMillis(scheduled_start.getTime());
  const hasManualModeWithNoScheduleEnd = hasManualMode && scheduled_end === null;
  const hasFanStopFailErrorWithNoScheduleEnd = hasFanStopFailError && scheduled_end === null;
  const hasInfiniteEndTime =
    (startEpochDt &&
      endEpochDt &&
      Math.round(endEpochDt.diff(startEpochDt, 'year').toObject().years)) ||
    hasManualModeWithNoScheduleEnd ||
    hasFanStopFailErrorWithNoScheduleEnd;
  const show_remaining =
    Boolean(
      scheduled_start && scheduled_end && new Date(scheduled_start).getTime() < new Date().getTime()
    ) ||
    hasManualMode ||
    hasFanStopFailError;
  const runningWindowTimeRemain =
    isAnyFanRunning && show_remaining
      ? hasInfiniteEndTime
        ? '∞'
        : getRunningWindowTimeRemain(scheduled_end) || ''
      : '';
  return runningWindowTimeRemain;
};

const StatusCellRenderer = (props) => {
  const rowData = props.node.data;
  const classes = useStyles({ aeration_schedule_type: props.node.data.mode });
  const fan_statuses = props && props.value ? props.value : [];

  if (props.value === undefined) {
    return <SiteSummary {...props} />;
  }

  if (fan_statuses.length === 0) {
    return <div>-</div>;
  }

  let timeRemain = '';
  let runwindowProgress = 0;
  const isAnyFanRunning = fan_statuses.some(
    (fanStatus) =>
      getFanStatusInfo(fanStatus.state, fanStatus.is_on).status === SimpleFanStatus.RUNNING
  );
  const isAnyFanOverride = fan_statuses.some((fanStatus) => fanStatus.has_override);
  const hasFanStopFailError = fan_statuses.some(
    (fanStatus) => fanStatus.error_code === FanHeartbeatErrorCode.StopFail
  );

  if (isAnyFanRunning) {
    const aerationSchedule = rowData.fan_schedule;
    const currentSchedule = aerationSchedule.length > 0 ? aerationSchedule[0] : null;
    const hasManualMode = isAnyFanRunning && isAnyFanOverride;
    if (currentSchedule) {
      timeRemain = getTimeRemain({
        hasManualMode,
        hasFanStopFailError,
        isAnyFanRunning,
        scheduled_start: currentSchedule.start_epoch,
        scheduled_end: currentSchedule.end_epoch,
      });
      runwindowProgress = getRunWindowProgress(
        currentSchedule.start_epoch,
        currentSchedule.end_epoch
      );
    }
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {!timeRemain && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <StatusIndicators {...props} fan_statuses={fan_statuses} />
        </div>
      )}

      {timeRemain && (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: timeRemain ? 'space-between' : 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <StatusIndicators {...props} fan_statuses={fan_statuses} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                justifyContent: 'center',
                lineHeight: 1.2,
              }}
            >
              <span style={{ fontSize: 10, color: amber_grey }}>Time Remain</span>
              <span
                style={{
                  fontSize: 10,
                  color: orange_shade_1,
                  letterSpacing: '0.9px',
                }}
              >
                {timeRemain}
              </span>
            </div>
          </div>
          <div className={classes.linearProgressContainer}>
            <LinearProgress
              className={classes.linearProgress}
              variant="determinate"
              value={runwindowProgress}
            />
          </div>
        </>
      )}
    </div>
  );
};

const StatusIndicators = (props) => {
  const classes = useStyles({ aeration_schedule_type: props.node.data.mode });
  return (
    <>
      {props.fan_statuses.map(({ state, is_on, fan_name, grain_bin_fan_controller_id }) => {
        const { color, status } = getFanStatusInfo(state, is_on);
        return (
          <Tooltip
            key={grain_bin_fan_controller_id}
            title={<span style={{ fontSize: 13 }}>{`${fan_name} ${status.toLowerCase()}`}</span>}
          >
            <div className={classes.dot_container}>
              <div className={classes.color_dot} style={{ backgroundColor: color }} />
            </div>
          </Tooltip>
        );
      })}
    </>
  );
};

const SiteSummary = (props) => {
  const classes = useStyles({ aeration_schedule_type: props.node.data.mode });
  const areAllChildrenLoaded =
    props &&
    props.node &&
    props.node.childrenAfterFilter &&
    props.node.childrenAfterFilter
      .map(({ data }) => data)
      .every((data) => data['bin_fan_status'] !== 'loading');

  if (!areAllChildrenLoaded) {
    return <SkeletonLoader />;
  }

  const childrenRowsDataAfterFilter = props.node.childrenAfterFilter.map(({ data }) => data);

  const siteFanStatusCounts: {
    ready: number;
    running: number;
    offline: number;
  } = childrenRowsDataAfterFilter.reduce(
    (acc, data) => {
      if (data.bin_fan_status.length > 0) {
        for (const fanStatus of data.bin_fan_status) {
          const { state, is_on } = fanStatus;
          const { status } = getFanStatusInfo(state, is_on);
          if (status === SimpleFanStatus.OFFLINE) {
            acc.offline += 1;
          } else {
            if (status === SimpleFanStatus.RUNNING) acc.running += 1;
            else if (status === SimpleFanStatus.READY) {
              acc.ready += 1;
            }
          }
        }
      }
      return acc;
    },
    { ready: 0, running: 0, offline: 0 }
  );

  const siteAerationModesCounts: {
    automation: number;
    custom: number;
    manual: number;
  } = childrenRowsDataAfterFilter.reduce(
    (acc, data) => {
      if (data.mode) {
        const mode = data.mode;
        const hasAutomationEnabled = data.automation_info.enabled || false;
        const hasFanSchedule = data && data.fan_schedule && data.fan_schedule.length > 0;

        if (hasFanSchedule && mode === FanRunWindowRecommendedOption.Custom) acc.custom += 1;
        else if (mode === FanRunWindowRecommendedOption.Manual) acc.manual += 1;
        else if (
          hasAutomationEnabled &&
          [
            FanRunWindowRecommendedOption.Drying,
            FanRunWindowRecommendedOption.Cooling,
            FanRunWindowRecommendedOption.Reconditioning,
          ].includes(mode)
        ) {
          acc.automation += 1;
        }
      }
      return acc;
    },
    {
      automation: 0,
      custom: 0,
      manual: 0,
    }
  );

  const modeCountsText = `${siteAerationModesCounts.automation} Auto. | ${
    siteAerationModesCounts.custom
  }  Cust. | ${siteAerationModesCounts.manual}  Man.`;

  return (
    <SiteDetails {...props}>
      <div className={classes.container}>
        <div className={classes.stateCountsContainer}>
          <div
            className={classes.stateCountBlock}
            style={{
              backgroundColor: '#CBF4C9',
              color: '#257355',
              marginRight: 2,
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            {siteFanStatusCounts.running}
          </div>
          <div
            className={classes.stateCountBlock}
            style={{ backgroundColor: '#D7EBFF', color: '#494CAE', marginRight: 2 }}
          >
            {siteFanStatusCounts.ready}
          </div>
          <div
            className={classes.stateCountBlock}
            style={{
              backgroundColor: '#FDE2DD',
              color: '#AD2F5C',
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            {siteFanStatusCounts.offline}
          </div>
        </div>
        <div className={classes.modeCountsText}>{modeCountsText}</div>
      </div>
    </SiteDetails>
  );
};

export { StatusCellRenderer };
