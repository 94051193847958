import {
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import * as yup from 'yup';
import QrCodeIcon from '../../../../../core/src/media/icons/QrCodeIcon';

import { FanType } from '../../../api';
import { Checkbox } from '../../util/form2/Checkbox';
import { NumberTextField } from '../../util/form2/NumberField';
import { Select } from '../../util/form2/Select';
import { yup_fan_type } from '../../util/form2/SelectFanType';
import { TextField } from '../../util/form2/TextField';
import { FanModelDetails, GrainFanModels } from '../grain-fan-model/GrainFanModelSelector';

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    width: '100%',
    marginBottom: 30,
  },
}));

export const ALLOWED_NO_OF_FANS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
const base_validation = {
  fan_alias: yup
    .string()
    .label('Fan Controller Name')
    .required(),
  has_plenum_sensor: yup.boolean(),
  voltage: yup
    .number()
    .typeError('A number is required')
    .nullable()
    .required()
    .label('Voltage'),
  power_factor: yup
    .number()
    .typeError('Power Factor requires a decimal')
    .moreThan(0.5)
    .lessThan(1)
    .nullable()
    .required()
    .label('Power Factor (P.F.)'),
  phase: yup
    .number()
    .typeError('A number is required')
    .oneOf([null, 1, 3])
    .nullable()
    .required()
    .label('Phase'),
  diag_test_is_enable: yup
    .boolean()
    .typeError('A boolean is required')
    .oneOf([null, false, true])
    .nullable()
    .notRequired()
    .label('Enable Diagnostic Test'),
  no_of_fans_connected: yup
    .string()
    .required()
    .oneOf(ALLOWED_NO_OF_FANS),
};
export const updateValidationSchema = yup.object().shape({
  ...base_validation,
});
export const validationSchema = yup.object().shape({
  core_id: yup
    .string()
    .label('Device ID')
    .required(),
  ...base_validation,
});

export type Values = {
  core_id: string;
  fan_alias: string;
  has_plenum_sensor: boolean;
  phase?: number | null;
  power_factor?: number | null;
  voltage?: number | null;
  diag_test_is_enable: boolean;
  no_of_fans_connected: string;
};

export type UpdateValues = {
  fan_alias: string;
  has_plenum_sensor: boolean;
  phase?: number | null;
  power_factor?: number | null;
  voltage?: number | null;
  diag_test_is_enable: boolean;
  fan_model_id: number | null;
  no_of_fans_connected: string;
};

export const FanControllerFormFields: React.FunctionComponent<{
  update?: boolean;
  core_id?: string;
  fan_model_id?: number | null;
  openQRScannerDialog?: () => void;
  currentFanModelState: FanModelDetails | null;
  setCurrentFanModelState: React.Dispatch<React.SetStateAction<FanModelDetails | null>>;
}> = ({
  fan_model_id,
  update,
  openQRScannerDialog,
  currentFanModelState,
  setCurrentFanModelState,
}) => {
  const classes = useStyles();
  const fan_model_filter = fan_model_id
    ? {
        label_name: 'fan_model_id',
        label_value: fan_model_id.toString(), // upadate fan model flow
      }
    : {
        label_name: 'fan_model_id',
        label_value: '-99', // add fan model flow
      };
  const no_of_fans_connected_options = ALLOWED_NO_OF_FANS;

  return (
    <>
      <div className={classes.section}>
        {!update && (
          <TextField
            name="core_id"
            label="Fan Controller ID"
            placeholder="MFLD_00-0-000"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="open QR code scanner"
                    onClick={() => openQRScannerDialog && openQRScannerDialog()}
                  >
                    <QrCodeIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
            required
          />
        )}
        <TextField
          style={{ marginTop: 10 }}
          name="fan_alias"
          label="Fan Nickname"
          placeholder="North Fan"
          fullWidth
          required
        />
      </div>
      <div className={classes.section}>
        <GrainFanModels
          fan_model_filter={[fan_model_filter]}
          currentFanModelState={currentFanModelState}
          setCurrentFanModelState={setCurrentFanModelState}
        />
      </div>
      <div className={classes.section}>
        <Typography variant="h6" style={{ fontWeight: 600, textDecoration: 'underline' }}>
          Fan Power:
        </Typography>
        <NumberTextField
          fullWidth
          label="Power Factor (P.F.)"
          name="power_factor"
          id="power_factor"
          parseValue={(val) => Number(val)}
          inputMode="decimal"
          step=".01"
          helperText="Check the label on the fan's motor to find the Power Factor"
          style={{ marginTop: 10 }}
          required
        />
        <Select
          name="voltage"
          label="Voltage"
          fullWidth
          style={{ marginTop: 10 }}
          placeholder="None Selected"
          required
        >
          <MenuItem value={110}>110v</MenuItem>
          <MenuItem value={230}>230v</MenuItem>
          <MenuItem value={480}>480v</MenuItem>
          <MenuItem value={600}>600v</MenuItem>
        </Select>
        <Select
          name="phase"
          label="Phase"
          fullWidth
          style={{ marginTop: 10 }}
          placeholder="None Selected"
          required
        >
          <MenuItem value={1}>Single Phase</MenuItem>
          <MenuItem value={3}>Three Phase</MenuItem>
        </Select>
      </div>

      <div className={classes.section} style={{ marginBottom: 0 }}>
        <Typography variant="h6" style={{ fontWeight: 600, textDecoration: 'underline' }}>
          Advanced Settings:
        </Typography>
        {/* <DividerWithText centerText="Advanced Settings" style={{ width: '100%', marginTop: 0 }} /> */}
        <Select
          name="no_of_fans_connected"
          label="Number of Fans connected to this Fan Controller"
          fullWidth
          style={{ marginTop: 10 }}
        >
          {no_of_fans_connected_options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <Checkbox
          style={{ marginTop: 10, width: '100%' }}
          name="has_plenum_sensor"
          label="Plenum Sensor Add-on Equipped"
        />
        {update && (
          <Checkbox
            style={{ width: '100%' }}
            name="diag_test_is_enable"
            label="Enable Diagnostic Test"
          />
        )}
      </div>
    </>
  );
};
