import {DialogContent, Grid, makeStyles, Typography} from "@material-ui/core";
import React from "react";
import {amber_grey} from "../../style";
import {BaseDialog} from "../util";
import {CellTowerInfo, CellTowerLocationPicker} from "./CellTowerLocationPicker";

const useStyles = makeStyles({
    showMoreInfoHeader:{
        fontSize: '13px',
        textAlign:'left',
        paddingTop: '0px'
    },
    cellTowerInfo:{
        fontWeight: 'bold',
        marginLeft:'6px'
    },
    cellTowerInfoDetail:{
        fontStyle: 'italic',
        marginLeft:'6px'
    },
    cellularStrengthPoor:{
        color: (props:{show_more_info_data?: any, default_color?: string})=>props.show_more_info_data?props.show_more_info_data.connection_strength_background_color['Poor']:props.default_color?props.default_color:amber_grey,
    },
    cellularStrengthFair:{
        color: (props:{show_more_info_data?: any, default_color?: string})=>props.show_more_info_data?props.show_more_info_data.connection_strength_background_color['Fair']:props.default_color?props.default_color:amber_grey,
    },
    cellularStrengthGood:{
        color: (props:{show_more_info_data?: any, default_color?: string})=>props.show_more_info_data?props.show_more_info_data.connection_strength_background_color['Good']:props.default_color?props.default_color:amber_grey,
    },
    cellularStrengthExcellent:{
        color: (props:{show_more_info_data?: any, default_color?: string})=>props.show_more_info_data?props.show_more_info_data.connection_strength_background_color['Excellent']:props.default_color?props.default_color:amber_grey,
    },
});

export type CellMetricShowInfoData = {
    cell_tower_show_info?: {
        cell_tower_info: string;
        mobile_country_code: number;
        mobile_network_code: number;
        location_area_code: number;
        cell_identity: number;
    },
    cell_strength_show_info?: {
        connection_strength: string;
        connection_strength_background_color: any;
        connection_details: string;
    },
    cell_strength_dbm_show_info?: {
        cellular_strength_dbm_value: number;
        connection_strength_background_color: any;
        connection_details: string;
    },
    cell_quality_show_info?: {
        cellular_quality_value: number;
        connection_strength_background_color: any;
        connection_details: string;
    }
}

const CellTowerShowInfoComp = ({cell_metric_show_info_data, handleClose}:{cell_metric_show_info_data: CellMetricShowInfoData | null, handleClose: ()=>void}) => {
    const classes = useStyles({});
    const cell_tower_show_info = cell_metric_show_info_data? cell_metric_show_info_data.cell_tower_show_info: null;
    const cell_tower_info: CellTowerInfo | null = cell_tower_show_info? {
        mobile_country_code: cell_tower_show_info.mobile_country_code,
        mobile_network_code: cell_tower_show_info.mobile_network_code,
        location_area_code: cell_tower_show_info.location_area_code,
        cell_identity: cell_tower_show_info.cell_identity,
    }: null;

    return (
        <>
            <BaseDialog
                title="Cell Tower Information"
                open={true}
                handleClose={() => handleClose()}
            >
                <DialogContent>
                    <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.showMoreInfoHeader}>
                                <p>This value provides the cell tower information in the format <span style={{textDecoration:'underline'}}>MCC-MNC-LAC-Cell_ID</span>. Current value: <span><strong>{cell_tower_show_info?cell_tower_show_info.cell_tower_info:'--'}</strong></span>.</p>
                                <section><span className={classes.cellTowerInfo}>MCC:</span><span className={classes.cellTowerInfoDetail}>Mobile Country Code</span></section>
                                <section><span className={classes.cellTowerInfo}>MNC:</span><span className={classes.cellTowerInfoDetail}>Mobile Network Code</span></section>
                                <section><span className={classes.cellTowerInfo}>LAC:</span><span className={classes.cellTowerInfoDetail}>Location Area Code</span></section>
                                <section><span className={classes.cellTowerInfo}>Cell ID:</span><span className={classes.cellTowerInfoDetail}>Cell Identity</span></section>
                            </Typography>
                        </Grid>
                    </Grid>
                    {/*<Grid container style={{marginTop: '20px', marginBottom: '20px'}}>*/}
                    {/*    <Grid item xs={12}>*/}
                    {/*        {cell_tower_info && <CellTowerLocationPicker cell_tower_info={cell_tower_info}/>}*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </DialogContent>
            </BaseDialog>
        </>
    );
}

const CellStrengthDBMShowInfoComp = ({cell_metric_show_info_data, handleClose}:{cell_metric_show_info_data: CellMetricShowInfoData | null, handleClose: ()=>void}) => {
    const cell_strength_dbm_show_info = cell_metric_show_info_data? cell_metric_show_info_data.cell_strength_dbm_show_info: null;
    const connection_details = cell_strength_dbm_show_info?cell_strength_dbm_show_info.connection_details: null;
    const classes = cell_strength_dbm_show_info?useStyles({show_more_info_data: cell_strength_dbm_show_info}):useStyles({});

    return (
        <>
            <BaseDialog
                title="Cell Strength Information"
                open={true}
                handleClose={() => handleClose()}
            >
                <DialogContent>
                    <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.showMoreInfoHeader}>
                                <p>This value provides the cell strength in dbM units.</p>
                                <p>Current value: <span><strong>{cell_strength_dbm_show_info?cell_strength_dbm_show_info.cellular_strength_dbm_value:'--'}</strong></span>.</p>
                                <Typography variant="body1" style={{marginBottom:'5px'}}>For reference the ranges for this connection type are as follows:</Typography>
                                {
                                    connection_details && ['2G', 'EDGE'].includes(connection_details) &&
                                    <>
                                        <Typography variant="body1">RXLEV &lt;= -105 is <span className={classes.cellularStrengthPoor}>Poor</span></Typography>
                                        <Typography variant="body1">-105 &lt; RXLEV &lt;= -95 is <span className={classes.cellularStrengthFair}>Fair</span></Typography>
                                        <Typography variant="body1">-95 &lt; RXLEV &lt;= -80 is <span className={classes.cellularStrengthGood}>Good</span></Typography>
                                        <Typography variant="body1">RXLEV &gt; -80 is <span className={classes.cellularStrengthExcellent}>Excellent</span></Typography>
                                    </>
                                }
                                {
                                    connection_details && ['3G'].includes(connection_details) &&
                                    <>
                                        <Typography variant="body1">RSCP &lt;= -120  is <span className={classes.cellularStrengthPoor}>Poor</span></Typography>
                                        <Typography variant="body1">-120  &lt; RSCP &lt;= -100  is <span className={classes.cellularStrengthFair}>Fair</span></Typography>
                                        <Typography variant="body1">-100   &lt; RSCP &lt;= -75  is <span className={classes.cellularStrengthGood}>Good</span></Typography>
                                        <Typography variant="body1">RSCP &gt; -75  is <span className={classes.cellularStrengthExcellent}>Excellent</span></Typography>
                                    </>
                                }
                                {
                                    connection_details && ['LTE', 'LTE Cat-M1'].includes(connection_details) &&
                                    <>
                                        <Typography variant="body1">RSRP &lt;= -115  is <span className={classes.cellularStrengthPoor}>Poor</span></Typography>
                                        <Typography variant="body1">-115 &lt; RSRP &lt;= -105  is <span className={classes.cellularStrengthFair}>Fair</span></Typography>
                                        <Typography variant="body1">-105  &lt; RSRP &lt;= -90 is <span className={classes.cellularStrengthGood}>Good</span></Typography>
                                        <Typography variant="body1">RSRP &gt; -90 is <span className={classes.cellularStrengthExcellent}>Excellent</span></Typography>
                                    </>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BaseDialog>
        </>
    );
}

const CellQualityShowInfoComp = ({cell_metric_show_info_data, handleClose}:{cell_metric_show_info_data: CellMetricShowInfoData | null, handleClose: ()=>void}) => {
    const cell_quality_show_info = cell_metric_show_info_data? cell_metric_show_info_data.cell_quality_show_info: null;
    const connection_details = cell_quality_show_info?cell_quality_show_info.connection_details: null;
    const classes = cell_quality_show_info?useStyles({show_more_info_data: cell_quality_show_info}):useStyles({});
    return (
        <>
            <BaseDialog
                title="Cell Quality Information"
                open={true}
                handleClose={() => handleClose()}
            >
                <DialogContent>
                    <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.showMoreInfoHeader}>
                                <p>This value provides the cell quality in db units.</p>
                                <p>Current value: <span><strong>{cell_quality_show_info?cell_quality_show_info.cellular_quality_value:'--'}</strong></span>.</p>
                                <Typography variant="body1" style={{marginBottom:'5px'}}>For reference the ranges for this connection type are as follows:</Typography>
                                {
                                    connection_details && ['2G', 'EDGE'].includes(connection_details) &&
                                    <>
                                        <Typography variant="body1">Signal quality range not available.</Typography>
                                    </>
                                }
                                {
                                    connection_details && ['3G'].includes(connection_details) &&
                                    <>
                                        <Typography variant="body1">Ec/No &lt;= -18  is <span className={classes.cellularStrengthPoor}>Poor</span></Typography>
                                        <Typography variant="body1">-18  &lt; Ec/No &lt;= -14  is <span className={classes.cellularStrengthFair}>Fair</span></Typography>
                                        <Typography variant="body1">-14   &lt; Ec/No &lt;= -10  is <span className={classes.cellularStrengthGood}>Good</span></Typography>
                                        <Typography variant="body1">Ec/No &gt; -10  is <span className={classes.cellularStrengthExcellent}>Excellent</span></Typography>
                                    </>
                                }
                                {
                                    connection_details && ['LTE', 'LTE Cat-M1'].includes(connection_details) &&
                                    <>
                                        <Typography variant="body1">RSRQ &lt;= -20  is <span className={classes.cellularStrengthPoor}>Poor</span></Typography>
                                        <Typography variant="body1">-20 &lt; RSRQ &lt;= -16  is <span className={classes.cellularStrengthFair}>Fair</span></Typography>
                                        <Typography variant="body1">-16  &lt; RSRQ &lt;= -12 is <span className={classes.cellularStrengthGood}>Good</span></Typography>
                                        <Typography variant="body1">RSRQ &gt; -12 is <span className={classes.cellularStrengthExcellent}>Excellent</span></Typography>
                                    </>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BaseDialog>
        </>
    );
}

const CellStrengthShowInfoComp = ({cell_metric_show_info_data, handleClose}:{cell_metric_show_info_data: CellMetricShowInfoData | null, handleClose: ()=>void}) => {
    const cell_strength_show_info = cell_metric_show_info_data? cell_metric_show_info_data.cell_strength_show_info: null;
    const connection_strength = cell_strength_show_info?cell_strength_show_info.connection_strength:'--';
    const connection_strength_color = cell_strength_show_info?cell_strength_show_info.connection_strength_background_color[connection_strength]:amber_grey
    const connection_details = cell_strength_show_info?cell_strength_show_info.connection_details: null;
    const classes = cell_strength_show_info?useStyles({show_more_info_data: cell_strength_show_info}):useStyles({});
    return (
        <>
            <BaseDialog
                title="Connection Rating"
                open={true}
                handleClose={() => handleClose()}
            >
                <DialogContent>
                    <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.showMoreInfoHeader}>
                                <Typography variant="body1" style={{marginBottom:'10px'}}>Current Value: <span style={{color: connection_strength_color}}>{connection_strength}</span></Typography>
                                <Typography variant="body1" style={{marginBottom:'5px'}}>For reference the ranges for this connection type are as follows:</Typography>
                                {
                                    connection_details && ['2G', 'EDGE'].includes(connection_details) &&
                                    <>
                                        <Typography variant="body1">RXLEV &lt;= -105 is <span className={classes.cellularStrengthPoor}>Poor</span></Typography>
                                        <Typography variant="body1">-105 &lt; RXLEV &lt;= -95 is <span className={classes.cellularStrengthFair}>Fair</span></Typography>
                                        <Typography variant="body1">-95 &lt; RXLEV &lt;= -80 is <span className={classes.cellularStrengthGood}>Good</span></Typography>
                                        <Typography variant="body1">RXLEV &gt; -80 is <span className={classes.cellularStrengthExcellent}>Excellent</span></Typography>
                                    </>
                                }
                                {
                                    connection_details && ['3G'].includes(connection_details) &&
                                    <>
                                        <Typography variant="body1">Ec/No &lt;= -18  is <span className={classes.cellularStrengthPoor}>Poor</span></Typography>
                                        <Typography variant="body1">-18  &lt; Ec/No &lt;= -14  is <span className={classes.cellularStrengthFair}>Fair</span></Typography>
                                        <Typography variant="body1">-14   &lt; Ec/No &lt;= -10  is <span className={classes.cellularStrengthGood}>Good</span></Typography>
                                        <Typography variant="body1">Ec/No &gt; -10  is <span className={classes.cellularStrengthExcellent}>Excellent</span></Typography>
                                    </>
                                }
                                {
                                    connection_details && ['LTE', 'LTE Cat-M1'].includes(connection_details) &&
                                    <>
                                        <Typography variant="body1">RSRQ &lt;= -20  is <span className={classes.cellularStrengthPoor}>Poor</span></Typography>
                                        <Typography variant="body1">-20 &lt; RSRQ &lt;= -16  is <span className={classes.cellularStrengthFair}>Fair</span></Typography>
                                        <Typography variant="body1">-16  &lt; RSRQ &lt;= -12 is <span className={classes.cellularStrengthGood}>Good</span></Typography>
                                        <Typography variant="body1">RSRQ &gt; -12 is <span className={classes.cellularStrengthExcellent}>Excellent</span></Typography>
                                    </>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BaseDialog>
        </>
    );
}

export const CellMetricShowInfoComp = ({cell_metric_show_info_data, info_type, handleClose}:{cell_metric_show_info_data: CellMetricShowInfoData | null, info_type: string, handleClose: ()=>void})=>{
    return (
        <>
            {
            (()=>{
                switch (info_type) {
                    case 'cell_tower':
                        return <CellTowerShowInfoComp cell_metric_show_info_data={cell_metric_show_info_data} handleClose={handleClose}/>
                    case 'cell_strength_dbm':
                        return <CellStrengthDBMShowInfoComp cell_metric_show_info_data={cell_metric_show_info_data} handleClose={handleClose}/>
                    case 'cell_quality':
                        return <CellQualityShowInfoComp cell_metric_show_info_data={cell_metric_show_info_data} handleClose={handleClose}/>
                    case 'cell_strength':
                        return <CellStrengthShowInfoComp cell_metric_show_info_data={cell_metric_show_info_data} handleClose={handleClose}/>
                }
            })()}
        </>
    );
}