import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { GetProps } from 'react-redux';
import * as yup from 'yup';

import { AccountFragmentFragment, DistanceUnitType, GrainType } from '../../api/graphql';
import { ContainerTypeLegacy, getHasUserSelectedMeterUnit } from '../../util';
import { yup_latlng } from '../util/form2/LocationPicker';
import { yup_floor_type } from '../util/form2/SelectFloorType';
import { yup_grain_type } from '../util/form2/SelectGrainType';
import { GrainBinFields } from './PickGrainContainerStep';
import { WizardStep } from './WizardStep';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    minWidth: 300,
    maxWidth: 400,
    margin: 20,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 600,
    },
  },
  location: {
    height: 400,
  },
}));

const hasUserOptedMeterUnit = getHasUserSelectedMeterUnit();

export const validationSchema = yup.object().shape({
  alias: yup
    .string()
    .label('Bin Name')
    .required(),
  grain_type: yup_grain_type.label('Grain Type').required(),
  height_ft: yup
    .number()
    .typeError('A number is required')
    .positive()
    .label('Eave Height')
    .required()
    .max(hasUserOptedMeterUnit ? 60 : 200),
  diameter_ft: yup
    .number()
    .typeError('A number is required')
    .positive()
    .label('Bin Diameter')
    .required()
    .max(hasUserOptedMeterUnit ? 60 : 200),
  location: yup_latlng.label('Location'),
  has_grain_spreader: yup.boolean(),
  floor_type: yup_floor_type.label('Floor Type').required(),
});

export const CreateGrainContainerStep = ({
  validationSchema,
}: Partial<GetProps<typeof WizardStep>>) => {
  const classes = useStyles();

  return (
    <WizardStep title="Create a Grain Bin" validationSchema={validationSchema}>
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        alignItems="center"
        justify="center"
        spacing={2}
        className={classes.grid}
      >
        <GrainBinFields />
      </Grid>
    </WizardStep>
  );
};
