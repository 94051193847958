import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Group } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { ApolloClient } from 'apollo-client';
import memoize from 'memoize-one';
import React, { FunctionComponent, memo, useCallback, useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { GetProps, Omit } from 'react-redux';

import {
  AccountFragmentFragment,
  BaseAccountFragmentFragment,
  withGetAccountsHoc,
  WithGetAccountsHocChildProps,
} from '../../../api';

const useStyles = makeStyles((theme: Theme) => ({
  nested: { paddingLeft: theme.spacing(4) },
  left: { textAlign: 'left', padding: 0 },
}));

const getSortedAccounts = memoize((accounts: BaseAccountFragmentFragment[]) =>
  accounts.slice(0).sort(({ organization: a1 }, { organization: b1 }) => a1.localeCompare(b1))
);

const AccountMenuItem: FunctionComponent<{
  account: BaseAccountFragmentFragment;
  selected: boolean;
  onClick: (account: AccountFragmentFragment) => void;
}> = memo(({ account, selected, onClick }) => {
  const classes = useStyles();
  const selectMenuItemCallback = useCallback(() => onClick(account as AccountFragmentFragment), [
    onClick,
    account,
  ]);
  return (
    <MenuItem
      button
      selected={selected}
      key={account.account_id}
      className={classes.nested}
      onClick={selectMenuItemCallback}
    >
      <ListItemIcon>
        <Group />
      </ListItemIcon>
      {account.organization}
    </MenuItem>
  );
});

const AccountListBase: FunctionComponent<
  WithGetAccountsHocChildProps & {
    value: number | null;
    onChange: (value: AccountFragmentFragment) => void;
    client: ApolloClient<any>;
  }
> = ({ accounts, onChange, client, value = null }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClickOpen = useCallback(() => setOpen(!open), [open, setOpen]);
  const handleSelect = useCallback(
    (account: AccountFragmentFragment) => {
      setOpen(false);
      onChange(account);
    },
    [onChange, setOpen]
  );
  useEffect(() => {
    return () => {
      // @ts-ignore
      const rootQuery = client.cache.data.data.ROOT_QUERY;
      if (rootQuery && rootQuery.accounts) {
        delete rootQuery.accounts;
      }
    };
  }, []);
  const selected = accounts.find(({ account_id }) => account_id === value);

  return (
    <>
      <ListItem button onClick={handleClickOpen}>
        <ListItemIcon>
          <Group />
        </ListItemIcon>
        <ListItemText
          inset
          primary={selected ? selected.organization : 'Accounts'}
          className={classes.left}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component={'div' as 'ul'} disablePadding>
          {getSortedAccounts(accounts).map((account) => (
            <AccountMenuItem
              key={account.account_id}
              account={account}
              onClick={handleSelect}
              selected={account.account_id === value}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};
const AccountListBase2 = withGetAccountsHoc(AccountListBase);
export const AccountList = withApollo<Omit<GetProps<typeof AccountListBase2>, 'client'>>(
  AccountListBase2
);
