import { ApolloClient } from 'apollo-client';
import { createBrowserHistory, History } from 'history';
import React from 'react';
import ReactGA from 'react-ga';
import { hot } from 'react-hot-loader/root';
import { Store } from 'redux';

import {
  ActiveHubsWithColor,
  ActiveStoragePeriod,
  createCache,
  createLinks,
  default_session_state,
  initStore,
  OperationsViewInfoProvider,
} from '@amber-ui/core/lib';
import { RootApp } from '@amber-ui/core/lib/component/RootApp';
import '@amber-ui/font/digital-7/digital-7.css';
import '@amber-ui/font/source-sans-pro/source-sans-pro.css';
import '@amber-ui/font/work-sans/work-sans.css';
import { initApp } from '../action';
import { configureStore } from '../store';
import { Routes } from './Routes';

class App extends React.Component<
  {},
  {
    store: Store;
    history: History;
    apollo_client: ApolloClient<any>;
  }
> {
  constructor(props) {
    super(props);
    const history = createBrowserHistory();
    const initial_state = {
      session: {
        ...default_session_state,
        email_address: window.localStorage.getItem('email_address') || '',
      },
    };
    const store = configureStore(history, initial_state);

    const host = window['app_config'].api_host_name || 'http://localhost:3100';

    if (window['app_config'].env === 'prod') {
      ReactGA.initialize('UA-108251686-1');
    }
    const apollo_client = new ApolloClient({
      link: createLinks(host),
      cache: createCache(),
    });
    this.state = { history, store, apollo_client };
    initStore(store).then(() =>
      store.dispatch(
        initApp({
          history,
          apollo_client,
        })
      )
    );
  }

  render() {
    return (
      <RootApp {...this.state}>
        <ActiveStoragePeriod>
          <ActiveHubsWithColor>
            <OperationsViewInfoProvider>
              <Routes />
            </OperationsViewInfoProvider>
          </ActiveHubsWithColor>
        </ActiveStoragePeriod>
      </RootApp>
    );
  }
}

export default hot(() => <App />) as React.ComponentType;
