import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { getHasUserSelectedKilogramUnit, UnitsConverter } from '../../../../../core/src/util';
import SkeletonLoader from '../../util/SkeletonLoader';
import { SiteDetails } from './SiteDetails';
import { withSkeleton } from './withCellSkeleton';

const GrainTypeCellRenderer = withSkeleton((props) => {
  const grain_type = props.value;

  if (props.value === undefined) {
    return <SiteSummary {...props} />;
  }
  return <div>{grain_type}</div>;
});

const SiteSummary = (props) => {
  const areAllChildrenLoadedWithGrainLevelData =
    props &&
    props.node.childrenAfterFilter
      .map(({ data }) => data)
      .every((data) => data['grain_level'] !== 'loading');

  if (!areAllChildrenLoadedWithGrainLevelData) {
    return <SkeletonLoader />;
  }

  const childrenRowsDataAfterFilter = props.node.childrenAfterFilter.map(({ data }) => data);
  const siteTotalGrainLevelInfo = childrenRowsDataAfterFilter.reduce(
    (acc, data) => {
      if (data.grain_level) {
        acc.siteTotalGrainLevelBushels += data.grain_level.grain_level_bushels;
        acc.siteTotalGrainLevelPercent += data.grain_level.grain_level_percent;
      }
      return acc;
    },
    {
      siteTotalGrainLevelBushels: 0,
      siteTotalGrainLevelPercent: 0,
    }
  );
  const siteTotalGrainLevelProgress =
    siteTotalGrainLevelInfo.siteTotalGrainLevelPercent === 0 ||
    childrenRowsDataAfterFilter.length === 0
      ? 0
      : Math.round(
          siteTotalGrainLevelInfo.siteTotalGrainLevelPercent / childrenRowsDataAfterFilter.length
        );
  const progressBarValue =
    siteTotalGrainLevelProgress >= 100
      ? 100
      : siteTotalGrainLevelProgress < 0
      ? 0
      : siteTotalGrainLevelProgress;
  const hasUserSelectedKilogramUnit = getHasUserSelectedKilogramUnit();
  const siteTotalGrainLevelValue = hasUserSelectedKilogramUnit
    ? `${Math.round(
        UnitsConverter.convertBuToMetricTon(siteTotalGrainLevelInfo.siteTotalGrainLevelBushels)
      )} ton`
    : `${Math.round(siteTotalGrainLevelInfo.siteTotalGrainLevelBushels)} bu`;

  return (
    <SiteDetails {...props}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 14,
          color: 'black',
          fontWeight: 600,
        }}
      >
        <div>{siteTotalGrainLevelValue}</div>
        <div style={{ marginTop: 6, width: '100%' }}>
          <LinearProgress variant="determinate" value={progressBarValue} />
        </div>
      </div>
    </SiteDetails>
  );
};

export { GrainTypeCellRenderer };
