import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import addMinutes from 'date-fns/add_minutes';
// import getMinutes from 'date-fns/get_minutes';
// import startOfHour from 'date-fns/start_of_hour';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { GetProps, Omit } from 'react-redux';

import { AerationForm } from './AerationForm';

const useStyles = makeStyles({
  header: { marginBottom: 0, marginTop: 10, width: '100%', textAlign: 'center' },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabs: {
    justifyContent: 'center',
    maxWidth: 500,
  },
});

// const getNextStart = (as_of) => {
//   const mins = getMinutes(as_of);
//   const next_start_mins = (Math.floor(mins / 15) + 1) * 15;
//   return addMinutes(startOfHour(as_of), next_start_mins);
// };

export const AerationScheduleLayout: FunctionComponent<
  Omit<GetProps<typeof AerationForm>, 'run_later'>
> = ({ grain_bin_id, onSubmitSuccess, onError }) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const handleTabClick = useCallback(
    (event, value) => {
      setTab(value);
    },
    [setTab]
  );

  return (
    <div className={classes.root}>
      <h2 className={classes.header}>Aeration Schedule</h2>
      <Tabs
        value={tab}
        onChange={handleTabClick}
        classes={{
          flexContainer: classes.tabs,
        }}
      >
        <Tab label="Run Now" />
        <Tab label="Run Later" />
      </Tabs>
      <AerationForm
        grain_bin_id={grain_bin_id}
        onError={onError}
        onSubmitSuccess={onSubmitSuccess}
        run_later={Boolean(tab)}
      />
    </div>
  );
};
