import { DialogContent, Grid } from '@material-ui/core';
import React from 'react';
import QrReader from 'react-qr-reader';
import { BaseDialog } from './BaseDialog';

export const QRScanDialog = ({
  title = 'Scan your QR code',
  showDialog,
  closeDialog,
  onQRCodeScan,
  onQRCodeError,
}) => {
  return (
    <BaseDialog title={title} open={showDialog} handleClose={closeDialog}>
      <DialogContent>
        <Grid container justify="center" alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <QrReader
              // delay={300}
              onError={onQRCodeError}
              onScan={onQRCodeScan}
              style={{ width: '100%' }}
              facingMode="environment"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};
