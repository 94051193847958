import { parse } from 'query-string';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { ErrorLayout } from '../ErrorLayout';
import { RegisterUserForm } from '../user/UserForm';
import { UserPasswordForm } from '../user/UserPasswordForm';
import { VerifyUser } from '../user/VerifyUser';
import { OtpWrapper } from './OtpWrapper';

enum OtpRouteSuffix {
  register = 'register',
  reset_password = 'reset_password',
  error = 'error',
  verify_email = 'verify_email',
}

export const OtpRoutes = ({ match: { url }, location: { search } }: RouteComponentProps) => {
  const { otp } = parse(search);
  let token = '';
  if (otp) {
    token = Array.isArray(otp) ? otp[0] : (otp as string);
  }
  return (
    <Switch>
      {token && (
        <>
          <Route
            path={`${url}/${OtpRouteSuffix.register}`}
            render={() => (
              <OtpWrapper token={token}>
                <RegisterUserForm token={token} />
              </OtpWrapper>
            )}
          />
          <Route
            path={`${url}/${OtpRouteSuffix.reset_password}`}
            render={() => (
              <OtpWrapper token={token}>
                <UserPasswordForm token={token} user_id={null} />
              </OtpWrapper>
            )}
          />
          <Route
            path={`${url}/${OtpRouteSuffix.verify_email}`}
            render={() => (
              <OtpWrapper token={token}>
                <VerifyUser token={token} />
              </OtpWrapper>
            )}
          />
        </>
      )}
      <Route
        path={`${url}/${OtpRouteSuffix.error}`}
        render={() => (
          <ErrorLayout
            disable_logout
            disable_reload
            ok_path="/"
            message="This link is no longer valid"
          />
        )}
      />
      <Redirect to={`${url}/${OtpRouteSuffix.error}`} />
    </Switch>
  );
};
