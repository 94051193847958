import * as React from 'react';
export const MediumRedAlertIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.129}
    height={19.683}
    viewBox="0 0 22.129 19.683"
    {...props}
  >
    <path
      id="AlertIcon"
      d="M12.678.965a1.9,1.9,0,0,0-3.309,0L.269,16.738a1.866,1.866,0,0,0,0,1.93,1.868,1.868,0,0,0,1.655.965h18.2a1.93,1.93,0,0,0,1.655-2.9ZM10.031,5.708a.993.993,0,0,1,1.985,0v5.956a.993.993,0,0,1-1.985,0Zm.993,10.313A1.353,1.353,0,0,1,9.672,14.67a1.377,1.377,0,0,1,1.351-1.379,1.365,1.365,0,0,1,0,2.73"
      transform="translate(0.041 0.025)"
      fill="#f44336"
      stroke="#fff"
      strokeWidth={0.05}
    />
  </svg>
);
