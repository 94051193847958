import { MenuItem } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import * as yup from 'yup';

import { UserRole } from '../../../api/graphql';
import { Select, SelectProps } from './Select';

export const yup_user_role = yup
  .string()
  .oneOf([
    null,
    UserRole.Admin,
    UserRole.FullAccess,
    UserRole.FanAccess,
    UserRole.ReadOnly,
  ]) as yup.StringSchema<UserRole | null>;

export const SelectUserRole: FunctionComponent<SelectProps> = (props) => {
  return (
    <Select label="User Role" {...props}>
      <MenuItem value={UserRole.Admin}>Administrator</MenuItem>
      <MenuItem value={UserRole.FullAccess}>Write Access</MenuItem>
      <MenuItem value={UserRole.FanAccess}>Fan Controls</MenuItem>
      <MenuItem value={UserRole.ReadOnly}>Read-Only</MenuItem>
    </Select>
  );
};
