import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy, RelativeTimePeriod } from '../../../util';
import {
  GrainContainerAerationRun,
  withGetGrainContainerHistoryAerationRunsWithinPeriod as HOC,
} from '../__generated';

export type WithGetGrainContainerHistoryAerationRunsWithinPeriodHocOwnProps = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  period: RelativeTimePeriod;
  pollInterval?: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainContainerHistoryAerationRunsWithinPeriodHocChildProps = {
  loading: boolean;
  aeration_runs: GrainContainerAerationRun[] | null;
  loadMore: () => Promise<any>;
};
export const withGetGrainContainerHistoryAerationRunsWithinPeriodHoc: AmberHoc<
  WithGetGrainContainerHistoryAerationRunsWithinPeriodHocOwnProps,
  WithGetGrainContainerHistoryAerationRunsWithinPeriodHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainContainerHistoryAerationRunsWithinPeriodHocOwnProps,
      WithGetGrainContainerHistoryAerationRunsWithinPeriodHocChildProps,
      typeof component
    >,
    WithGetGrainContainerHistoryAerationRunsWithinPeriodHocChildProps
  >({
    options: ({
      container_id,
      container_type,
      period,
      onError,
      pollInterval = 1000 * 60 * 30,
    }) => ({
      onError,
      pollInterval,
      errorPolicy: onError ? 'all' : 'none',
      fetchPolicy: 'network-only',
      variables: { container_id, container_type, period },
    }),
    props: ({ data, ownProps: { loading } }) => {
      let aeration_runs: any = null;

      if (!data || data.loading) {
        return {
          aeration_runs,
          loadMore: () => Promise.resolve(),
          loading: true,
        };
      }

      const { grain_container } = data;
      if (grain_container && grain_container.aeration_runs_within_period) {
        const { aeration_runs_within_period } = grain_container;
        if (aeration_runs_within_period && aeration_runs_within_period.aeration_runs) {
          aeration_runs = aeration_runs_within_period.aeration_runs;
        }
      }

      const handleLoadMore = () => {
        return data.refetch();
      };

      return {
        aeration_runs,
        loadMore: () => handleLoadMore(),
        loading: loading || false,
      };
    },
  })(component as any);
