import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useState } from 'react';

import {
  BargeCoverPelletLinkFragmentFragment,
  GrainStatusFragmentFragment,
  WithGetBargeContainerHocChildProps,
} from '../../../api';
import {
  ContainerTypeLegacy,
  isValidCoverNumber,
  normalizeCoverNumber,
  RangeState,
  RelativeTimePeriod,
} from '../../../util';
import { BargeMultiPelletCard } from './BargeMultiPelletCard';
import { BargePelletCard } from './BargePelletCard';

const useStyles = makeStyles({
  centered: { textAlign: 'center' },
});

export const BargePellets = ({
  diagnostic_mode,
  barge_id,
  barge_cover_pellet_links,
  grain_container,
  goBargeCoverForm,
}: {
  diagnostic_mode: boolean;
  barge_id: number;
  barge_cover_pellet_links: BargeCoverPelletLinkFragmentFragment[];
  grain_container: WithGetBargeContainerHocChildProps['grain_container'];
  goBargeCoverForm: (arg: { pellet_id: string }) => void;
}) => {
  const classes = useStyles();
  const [period, setPeriod] = useState(RelativeTimePeriod.week);
  const selectPeriodCallback = useCallback(
    (value: RelativeTimePeriod) => {
      setPeriod(value);
    },
    [setPeriod]
  );
  const {
    telemetry: { pellets = [] },
  } = grain_container || { telemetry: {} };
  if (pellets.length === 0 && barge_cover_pellet_links.length === 0) {
    return null;
  }

  const found = new Set();
  // filter to valid cover numbers
  // group by cover number to display multiple pellets per cover if they exist
  const assigned_group = barge_cover_pellet_links
    .filter(({ cover_no }) => isValidCoverNumber(cover_no))
    .reduce((acc, next) => {
      // first two numbers are cover number and port/starboard
      const key = next.cover_no.toString().slice(0, 2);
      (acc[key] = acc[key] || []).push(next);
      return acc;
    }, {});

  const assigned = (Object.values(assigned_group) as BargeCoverPelletLinkFragmentFragment[][])
    .filter((grp) => grp.length)
    .map((group_pellets) => {
      const pellet_positions = {};
      const { cover_no } = group_pellets[0];
      const normalized_cover_no = normalizeCoverNumber(cover_no);
      const assigned_pellets = pellets.filter((a_pellet) =>
        group_pellets.find(({ pellet_id }) => pellet_id === a_pellet.pellet_id)
      );
      group_pellets.forEach((pellet) => {
        pellet_positions[pellet.pellet_id] = pellet.cover_no % 10;
      });
      const subtitle = assigned_pellets
        .map(({ pellet_id }) => (pellet_id.includes(':') ? pellet_id.split(':').pop() : pellet_id))
        .join(', ');
      const pellet_ids = assigned_pellets.map(({ pellet_id }) => {
        found.add(pellet_id);
        return pellet_id;
      });
      const is_emc_stable =
        assigned_pellets.find(({ is_emc_stable }) => !is_emc_stable) === undefined;

      const grain_status = {
        temp_f: null,
        temp_state: RangeState.unknown,
        humidity_rh: null,
        humidity_state: RangeState.unknown,
        emc: null,
        emc_state: RangeState.unknown,
      } as GrainStatusFragmentFragment;

      assigned_pellets.reduce(
        (acc, { latest: { temp_f, temp_state, humidity_rh, humidity_state, emc, emc_state } }) => {
          if (
            temp_f !== null &&
            temp_f !== undefined &&
            (acc.temp_f === null || acc.temp_f === undefined || temp_f > acc.temp_f)
          ) {
            acc.temp_f = temp_f;
            acc.temp_state = temp_state;
          }
          if (
            humidity_rh !== null &&
            humidity_rh !== undefined &&
            (acc.humidity_rh === null ||
              acc.humidity_rh === undefined ||
              humidity_rh > acc.humidity_rh)
          ) {
            acc.humidity_rh = humidity_rh;
            acc.humidity_state = humidity_state;
          }
          if (
            emc !== null &&
            emc !== undefined &&
            (acc.emc === null || acc.emc === undefined || emc > acc.emc)
          ) {
            acc.emc = emc;
            acc.emc_state = emc_state;
          }
          return acc;
        },
        grain_status
      );

      return (
        <Grid key={subtitle} item xl={3} lg={4} md={6} sm={12} xs={12}>
          <BargeMultiPelletCard
            container_id={barge_id}
            container_type={ContainerTypeLegacy.barge}
            grain_status={grain_status}
            pellet_ids={pellet_ids}
            subtitle={subtitle}
            title={`Cover ${normalized_cover_no}`}
            goBargeCoverForm={goBargeCoverForm}
            is_emc_stable={is_emc_stable}
            period={period}
            selectPeriodCallback={selectPeriodCallback}
            pellet_positions={pellet_positions} // map pellet_id to top mid or low
          />
        </Grid>
      );
    });

  const air_sensors = barge_cover_pellet_links
    .filter(({ cover_no }) => !isValidCoverNumber(cover_no))
    .map(({ pellet_id, cover_no }) => {
      const pellet = pellets.find((a_pellet) => a_pellet.pellet_id === pellet_id);
      const subtitle = pellet_id.includes(':') ? pellet_id.split(':').pop() : pellet_id;
      if (pellet) {
        found.add(pellet_id);
      }
      return (
        <Grid key={pellet_id} item xl={3} lg={4} md={6} sm={12} xs={12}>
          <BargePelletCard
            container_id={barge_id}
            container_type={ContainerTypeLegacy.barge}
            grain_status={pellet ? pellet.latest : null}
            pellet_id={pellet_id}
            subtitle={subtitle}
            title={`Cover No. ${cover_no}`}
            goBargeCoverForm={goBargeCoverForm}
            is_emc_stable={pellet ? pellet.is_emc_stable : null}
          />
        </Grid>
      );
    });

  const unassigned = pellets
    .filter(({ pellet_id }) => !found.has(pellet_id))
    .map(({ pellet_id, is_emc_stable, latest }) => (
      <Grid key={pellet_id} item xl={3} lg={4} md={6} sm={12} xs={12}>
        <BargePelletCard
          container_id={barge_id}
          container_type={ContainerTypeLegacy.barge}
          grain_status={latest}
          pellet_id={pellet_id}
          subtitle={pellet_id.includes(':') ? pellet_id.split(':').pop() : pellet_id}
          title="Unassigned"
          goBargeCoverForm={goBargeCoverForm}
          is_emc_stable={is_emc_stable}
        />
      </Grid>
    ));
  const has_assigned = assigned.length > 0;
  const has_unassigned = unassigned.length > 0;
  const has_air_sensors = air_sensors.length > 0;
  return (
    <>
      {has_assigned && (
        <>
          <Grid key={0} item xs={12} className={classes.centered}>
            <Typography variant="h6">Barge Covers</Typography>
          </Grid>
          <Grid
            key={1}
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={2}
            alignContent="flex-start"
          >
            {assigned}
          </Grid>
        </>
      )}
      {/* unassigned and air sensors only show up for admins */}
      {diagnostic_mode && has_air_sensors && (
        <>
          <Grid key={2} item xs={12} className={classes.centered}>
            <Typography variant="h6">Air Sensors</Typography>
          </Grid>
          <Grid
            key={3}
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={2}
            alignContent="flex-start"
          >
            {air_sensors}
          </Grid>
        </>
      )}
      {diagnostic_mode && has_unassigned && (
        <>
          <Grid key={2} item xs={12} className={classes.centered}>
            <Typography variant="h6">Unassigned Sensors</Typography>
          </Grid>
          <Grid
            key={3}
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={2}
            alignContent="flex-start"
          >
            {unassigned}
          </Grid>
        </>
      )}
    </>
  );
};
