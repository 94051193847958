import { FanRunWindowRecommendedOption } from '../api';
import { amber_blue } from '../style';

export const renderModeColor = (recommendationOption) => {
  switch (recommendationOption) {
    case FanRunWindowRecommendedOption.Cooling:
      return 'linear-gradient(to right, #94b3fd, #99feff)';
    case FanRunWindowRecommendedOption.Drying:
      return 'linear-gradient(to right, #e99497, #e8e46e)';
    case FanRunWindowRecommendedOption.Reconditioning:
      return 'linear-gradient(to right, #9adcff, #d791ff)';
    case FanRunWindowRecommendedOption.Custom:
      return '#B3B3B3';
    case FanRunWindowRecommendedOption.Manual:
      return '#FBC02D';
    default:
      return '#B3B3B3';
  }
};

const DRYING_COLOR = 'rgb(225, 114, 81)';
const RECONDITIONING_COLOR = 'rgb(192, 93, 174)';
const COOLING_COLOR = amber_blue;

export const renderFanAutomationPeriodBorderColor = (recommendationOption) => {
  switch (recommendationOption) {
    case FanRunWindowRecommendedOption.Cooling:
      return COOLING_COLOR;
    case FanRunWindowRecommendedOption.Drying:
      return DRYING_COLOR;
    case FanRunWindowRecommendedOption.Reconditioning:
      return RECONDITIONING_COLOR;
    case FanRunWindowRecommendedOption.Custom:
      return '#B3B3B3';
    case FanRunWindowRecommendedOption.Manual:
      return '#FBC02D';
    default:
      return '#B3B3B3';
  }
};
