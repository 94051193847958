import { MenuItem } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import * as yup from 'yup';
import { GrainTicketActionType } from '../../../api/graphql';
import { Select, SelectProps } from './Select';

export const yup_grain_ticket_action_type = yup
  .string()
  .nullable()
  .oneOf([
    null,
    GrainTicketActionType.Added,
    GrainTicketActionType.Removed,
  ]) as yup.StringSchema<GrainTicketActionType | null>;

export const SelectGrainTicketActionType: FunctionComponent<SelectProps> = (props) => {
  const { values } = useFormikContext<any>();
  const selectedItemColor =
    values && values.grain_ticket_action
      ? values.grain_ticket_action === GrainTicketActionType.Added
        ? 'green'
        : 'red'
      : undefined;
  return (
    <Select {...props} selectStyles={{ color: selectedItemColor }}>
      <MenuItem value={GrainTicketActionType.Added}>Added</MenuItem>
      <MenuItem value={GrainTicketActionType.Removed}>Removed</MenuItem>
    </Select>
  );
};
