import { fade, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { useContext, useState } from 'react';
import { ActiveHubsWithColorContext, HubWithColorCode } from '../../../../contexts';

import { useMediaQuery } from '@material-ui/core';
import { HubType } from '../../../../../src/api';
import {
  amber_faded_green,
  amber_grey,
  gray_shade_3,
  red_shade_2,
  yellow_shade_2,
} from '../../../../style';
import {
  formatNumber,
  formatPercent,
  getTemperatureUnitLabel,
  RangeState,
  RelativeTimePeriod,
  Statistic,
} from '../../../../util';
import { tooltipContainerStyles } from '../../daily-forecast/WeatherForecastPlot';
import { getXMinFromPeriod } from './LevelHistoryPlotHelpers';
import {
  getDomain,
  getHorizontalPlotBands,
  getRunWindowsPlotBands,
  getTickInterval,
  getTickValues,
  getTooltipInfo,
  getXaxisInterval,
  WEATHER_STATISTIC_LINE_COLOR,
} from './multiHubTelemetryHistoryPlotHelpers';
import { RunWindow } from './TelemetryHistoryCard';

declare global {
  interface Window {
    moment: any;
  }
}
window.moment = moment;

/**
 * Interpolating multi-series values for a shared tooltip.
 * This code will applicalble only if interpolate:true is set inside crosshair options.
 * References: 
https://github.com/highcharts/highcharts/issues/9183
https://github.com/highcharts/highcharts/issues/647
 */
(function(H: any) {
  const Axis = H.Axis;
  const noop = H.noop;
  const wrap = H.wrap;

  wrap(Axis.prototype, 'drawCrosshair', function(proceed, e, point) {
    if (!this.isXAxis && e) {
      H.each(this.chart.series, (series) => {
        const points = series && series.points ? series.points : [];
        const axis = series && series.yAxis;
        let prev;
        let next;
        let i;
        let newX;
        let interpolate;
        const xAxisPos = series && series.xAxis.pos;
        const pointProto = series.pointClass.prototype;

        if (
          axis &&
          series &&
          series.visible &&
          axis.options.crosshair &&
          axis.options.crosshair.interpolate
        ) {
          for (i = 0; i < points.length; i += 1) {
            if (points[i].plotX + xAxisPos > e.chartX) {
              prev = points[i - 1];
              next = points[i];
              break;
            }
          }

          if (prev && next) {
            if (series.options.step === 'left') {
              interpolate = function(prop) {
                return prev[prop];
              };
            } else {
              interpolate = function(prop) {
                const factor = (e.chartX - prev.plotX - xAxisPos) / (next.plotX - prev.plotX);
                return prev[prop] + (next[prop] - prev[prop]) * factor;
              };
            }

            newX = interpolate('x');

            const customPoint = {
              series,
              x: newX,
              category: newX,
              y: H.defined(prev.y) ? interpolate('y') : axis.toValue(interpolate('plotY'), true),
              plotX: interpolate('plotX'),
              plotY: interpolate('plotY'),

              // for tooltip
              setState: noop,
              getLabelConfig: prev.getLabelConfig || pointProto.getLabelConfig,
              tooltipFormatter: prev.tooltipFormatter || pointProto.tooltipFormatter,
              colorIndex: prev.colorIndex,
              color: prev.color,
            };

            if (!axis.chart.customPointsFinished) {
              axis.chart.customPoints = axis.chart.customPoints || [];
              axis.chart.customPoints.push(customPoint);
            }
          }
        }

        // proceed.call(axis, e, point);
      });
      this.chart.customPointsFinished = true;
    } else {
      proceed.call(this, e, point);
    }
  });

  wrap(H.Tooltip.prototype, 'refresh', function(proceed, pointOrPoints, e) {
    // use customPoints if available
    let points = pointOrPoints;
    if (this.chart.customPoints) {
      points = this.chart.customPoints;
      this.chart.customPoints = false;
    }

    proceed.call(this, H.splat(points), e);
  });

  // runPointActions
  wrap(H.Pointer.prototype, 'runPointActions', function(proceed, e, p) {
    // clear customPoints if available
    this.chart.customPoints = false;
    this.chart.customPointsFinished = false;

    proceed.apply(this, [].slice.call(arguments, 1));

    // recall tooltip with new points
    const chart = this.chart;
    const tooltip = chart.tooltip && chart.tooltip.options.enabled ? chart.tooltip : undefined;
    // if available
    if (tooltip && this.chart.customPoints) {
      tooltip.refresh(p, e);
      setTimeout(() => {
        this.chart.tooltip.hide(3000); // tooltip hide delay
      });
    }
  });
})(Highcharts);

/**
 * Highcharts.Legend.prototype.layoutItem - this is a function from our library (from the Highcharts code).
 * I'm editing this function to add line breaks in the legend.
 * The only edited line is this one: "|| item.userOptions.newLine"
 * This code will applicalble only if  'applyLegendFix: true' is set inside chart options.
 * ref:https://www.highcharts.com/docs/extending-highcharts/extending-highcharts
 */
(function(H) {
  H.wrap(H.Legend.prototype, 'layoutItem', function(proceed, item) {
    if (this.chart.options.chart.applyLegendFix) {
      const options = this.options;
      const padding = this.padding;
      const horizontal = options.layout === 'horizontal';
      const itemHeight = item.itemHeight;
      const itemMarginBottom = options.itemMarginBottom || 0;
      const itemMarginTop = this.itemMarginTop;
      const itemDistance = horizontal ? H.pick(options.itemDistance, 20) : 0;
      const maxLegendWidth = this.maxLegendWidth;
      const itemWidth =
        options.alignColumns && this.totalItemWidth > maxLegendWidth
          ? this.maxItemWidth
          : item.itemWidth;

      // If the item exceeds the width, start a new line
      if (
        horizontal &&
        (this.itemX - padding + itemWidth > maxLegendWidth || item.userOptions.newLine)

        // (this.itemX - padding + itemWidth > maxLegendWidth || item.userOptions.newLine)
      ) {
        this.itemX = padding;
        this.itemY += itemMarginTop + this.lastLineHeight + itemMarginBottom;
        this.lastLineHeight = 0; // reset for next line (#915, #3976)
      }

      // Set the edge positions
      this.lastItemY = itemMarginTop + this.itemY + itemMarginBottom;
      this.lastLineHeight = Math.max(
        // #915
        itemHeight,
        this.lastLineHeight
      );

      // cache the position of the newly generated or reordered items
      item._legendItemPos = [this.itemX, this.itemY];

      // advance
      if (horizontal) {
        this.itemX += itemWidth;
      } else {
        this.itemY += itemMarginTop + itemHeight + itemMarginBottom;
        this.lastLineHeight = itemHeight;
      }

      // the width of the widest item
      this.offsetWidth =
        this.widthOption ||
        Math.max(
          (horizontal
            ? this.itemX -
              padding -
              (item.checkbox
                ? // decrease by itemDistance only when no checkbox #4853
                  0
                : itemDistance)
            : itemWidth) + padding,
          this.offsetWidth
        );
    } else {
      proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    }
  });
})(Highcharts);

const useStyles = makeStyles({
  tooltipContainer: {
    ...tooltipContainerStyles,
  },
  pulseAnimation: {
    display: 'block',
    width: 20,
    height: 20,
    borderRadius: '50%',
    animation: '$pulse 2s infinite',
    color: 'red ',
  },

  '@keyframes pulse': {
    '0%': {
      boxShadow: '0 0 0 0px rgba(255, 255, 255, 0.6)',
    },
    '100%': {
      boxShadow: '0 0 0 10px rgba(255, 255, 255, 0)',
    },
  },
});

const getFormatedDay = (dt: DateTime, as_of = DateTime.local(), prevent_now = false): string => {
  if (!prevent_now && dt.toMillis() <= as_of.toMillis()) {
    return 'Now';
  }
  if (dt.toMillis() >= DateTime.local(3000, 1, 1).toMillis()) {
    return 'Forever';
  }
  if (dt.hasSame(as_of, 'day')) {
    return 'Today';
  }
  return dt.toFormat('ccc');
};
const formatHourTick = (dt) => `${dt.toFormat('h a')}<br/>${dt.month}/${dt.day}`;

const formatTimeTick = (dt) =>
  `${getFormatedDay(dt, DateTime.local(), true)}<br/>${dt.month}/${dt.day}`;

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const PLOT_BANDS = {
  INSPECT: 'Inspect',
  TAKE_ACTION: 'Take Action',
  IGNORE: 'Ignore (Fan Running)',
  FAN_RUN: 'Fan Run',
};

export type Range = {
  high: number;
  low: number;
  state: RangeState;
};

export type HistoryRecord = {
  epoch_time: Date;
  [Statistic.emc]: number;
  [Statistic.humidity_rh]: number;
  [Statistic.temp_f]: number;
  [Statistic.co2_ppm]: number;
};

export type HistoryPlotPoint = {
  x: Date;
  y: number;
};

export type HistoryRecordWithHubID = {
  hub_id: number;
  data: HistoryPlotPoint[];
};

const TICK_FORMATTER = {
  [Statistic.emc]: (y) => formatPercent(y, 1),
  [Statistic.humidity_rh]: (y) => formatPercent(y),
  [Statistic.temp_f]: (y) => {
    const tempUnit = getTemperatureUnitLabel();
    const tempUnitWithoutDegreeSymbol = tempUnit[1];
    return `${formatNumber(y, 0)} ${tempUnitWithoutDegreeSymbol}`;
  },
  [Statistic.co2_ppm]: (y) => y,
};
export const MIN_RANGE = {
  [Statistic.temp_f]: 10,
  [Statistic.humidity_rh]: 0.05,
  [Statistic.emc]: 0.03,
  [Statistic.co2_ppm]: 800,
};

export const MultiHubTelemetryHistoryPlotV2: React.FunctionComponent<{
  period: RelativeTimePeriod;
  height: number;
  width?: number;
  statistic: Statistic;
  ranges: Range[];
  history: HistoryRecordWithHubID[];
  weather_history: HistoryPlotPoint[];
  run_windows?: RunWindow[];
  show_temp_threshold?: boolean;
  grain_bin_location_timezone: string;
}> = ({
  period,
  height,
  width,
  history,
  weather_history,
  statistic,
  ranges,
  run_windows,
  show_temp_threshold,
  grain_bin_location_timezone,
}) => {
  const [chart, setChart] = useState<any>(null);
  const [hasWeatherSeriesVisible, setHasWeatherSeriesVisible] = useState(
    [RelativeTimePeriod.day, RelativeTimePeriod.week, RelativeTimePeriod.month].includes(period)
  );
  const activeHubsWithColorContext = useContext(ActiveHubsWithColorContext);
  const activeHubsWithColor = activeHubsWithColorContext.activeHubsWithColor;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const isSmallMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
  const hasCO2ChartSelected = statistic === Statistic.co2_ppm;
  Highcharts.setOptions({
    time: {
      timezone: grain_bin_location_timezone,
    },
  });

  const today = DateTime.local();
  today.set({ hour: 12 });

  const tick_values = getTickValues(period, today.year, today.month, today.day, today.hour);
  const domain = getDomain(history, weather_history, hasWeatherSeriesVisible, statistic);

  const x_min = getXMinFromPeriod(period).toMillis();
  const x_max = tick_values[tick_values.length - 1].toMillis();
  // tslint:disable-next-line
  let [y_min, y_max] = domain;
  let hasSeriesContainMaxValue = false;

  const seriesData: any =
    activeHubsWithColor &&
    activeHubsWithColor.length > 0 &&
    history.length > 0 &&
    history
      .map(({ hub_id, data }) => {
        const hub: HubWithColorCode | undefined = activeHubsWithColor.find(
          (hub) => hub.hub_id === hub_id
        );

        if (hub) {
          const hubTypeLabel = hub.hub_type === HubType.Headspace ? 'Headspace' : 'Plenum';
          return {
            name: `${hubTypeLabel}-${hub.nickname}`,
            type: 'spline',
            marker: {
              symbol: undefined,
            },
            data: data.map((dataPoint) => {
              if (hasCO2ChartSelected && dataPoint.y === y_max) {
                hasSeriesContainMaxValue = true;
              }
              return {
                x: new Date(dataPoint.x).getTime(),
                y: dataPoint.y,
              };
            }),
            color: hub.color,
            zIndex: 999,
          };
        }
        return null;
      })
      .filter((data) => data != null);

  const weatherHistorySeriesData =
    statistic !== Statistic.co2_ppm
      ? {
          name: 'Weather',
          type: 'spline',
          marker: {
            symbol: undefined,
          },
          data: weather_history.map((dataPoint) => {
            return {
              x: new Date(dataPoint.x).getTime(),
              y: dataPoint.y,
            };
          }),
          color: WEATHER_STATISTIC_LINE_COLOR[statistic],
          zIndex: 1,
          visible: hasWeatherSeriesVisible,
          showInLegend: true,
          lineWidth: 1,
        }
      : null;

  if (hasSeriesContainMaxValue) {
    y_max = (y_max as number) + 1000;
  }

  const plotBandsSeries = [
    {
      marker: {
        symbol: 'square',
      },
      type: 'scatter',
      name: statistic === Statistic.co2_ppm ? PLOT_BANDS.IGNORE : PLOT_BANDS.FAN_RUN,
      color: statistic === Statistic.co2_ppm ? fade(amber_grey, 0.3) : amber_faded_green,
      newLine: statistic !== Statistic.co2_ppm,
      showInLegend: true,
    },
  ];

  const isFanRunBetweenPastPeriodAndCurrentSelectedPerid = ({ start_epoch, end_epoch }) => {
    return (
      DateTime.fromMillis(start_epoch).toMillis() < x_min &&
      (end_epoch === null || DateTime.fromMillis(end_epoch).toMillis() > x_min)
    );
  };

  const validRunWindows = run_windows
    ? run_windows.filter(
        ({ start_epoch, end_epoch }) =>
          DateTime.fromMillis(start_epoch).toMillis() > x_min ||
          isFanRunBetweenPastPeriodAndCurrentSelectedPerid({ start_epoch, end_epoch })
      )
    : [];

  console.log('run_windows got from CLOUD', run_windows);
  console.log('valid run_windows for selected Airspace view period', validRunWindows);

  const runWindowsPlotBands = getRunWindowsPlotBands(statistic, validRunWindows, x_max);
  const horizontalPlotBands = getHorizontalPlotBands(statistic, y_max, chart);

  const hasWithinPast24Hrs = (epoch) => {
    const asOf = DateTime.local();
    const dt = asOf.minus({ day: 1 });
    console.log('inside hasWithinPast24Hrs', asOf, dt);
    return new Date(epoch).getTime() > dt.toMillis();
  };
  if (chart) {
    console.log('MultiHubTelemetryHistoryPlotV2 y axis interval', chart.yAxis[0].tickInterval);
  }

  const allSeries = seriesData ? [...seriesData, ...plotBandsSeries] : [...plotBandsSeries];

  if (weatherHistorySeriesData) {
    allSeries.push(weatherHistorySeriesData);
  }

  // chart options
  const chartOptions = {
    credits: false,
    chart: {
      plotBackgroundColor: hasCO2ChartSelected ? gray_shade_3 : undefined,
      plotBorderColor: hasCO2ChartSelected ? gray_shade_3 : undefined,
      plotBorderWidth: hasCO2ChartSelected ? 2 : undefined,
      spacingTop: 8,
      applyLegendFix: true,
      width: width ? width : undefined,
      height: 350,
      spacingLeft: isSmallMobile ? 0 : 10,
      spacingRight: isSmallMobile ? 0 : 10,
      marginLeft: hasCO2ChartSelected ? 0 : undefined,
      marginRight: 0,

      events: {
        load() {
          setChart(this);
          for (const series of this.series) {
            const points = series.points;
            const lastPoint = points[points.length - 1];
            console.log('lastPoint', lastPoint);
            let big = true;
            if (lastPoint && hasWithinPast24Hrs(lastPoint.x)) {
              setInterval(() => {
                big = !big;
                lastPoint.update &&
                  lastPoint.update({
                    marker: {
                      enabled: true,
                      radius: big ? 3 : 5,
                    },
                  });
              }, 900);
            }
          }
        },
        render() {
          // custom grid lines
          if (hasCO2ChartSelected) {
            const yAxis = this.yAxis[0];
            const yAxisTickPositions = yAxis.tickPositions.slice(0, yAxis.tickPositions.length - 1);
            for (const i in yAxisTickPositions) {
              const y = Math.round(yAxis.toPixels(yAxisTickPositions[i]));
              const offset = isSmallMobile ? 10 : 20;
              const d = ['M', 0, y, 'L', this.chartWidth - this.spacing[2] - offset, y];
              const lineName = `customGridLine${i}`;
              if (this[lineName] && this[lineName].element) {
                this[lineName].destroy();
              }
              this[lineName] = this.renderer
                .path(d)
                .attr({
                  'stroke-width': 1,
                  stroke: '#c6c6c6',
                  zIndex: 1,
                })
                .add();
            }
          } else {
            const customGridLines = Object.keys(this).filter((keyName) =>
              keyName.startsWith('customGridLine')
            );
            for (const customGridLine of customGridLines) {
              this && this[customGridLine].element && this[customGridLine].destroy();
            }
          }
        },
      },
    },
    title: {
      text: '',
      y: 0,
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: 'rgba(255,255,255,0)',
      borderWidth: 0,
      borderRadius: 0,
      shadow: false,
      style: {
        zIndex: 9999,
      },
      formatter() {
        const tooltipHeading =
          period === RelativeTimePeriod.week || period === RelativeTimePeriod.day
            ? Highcharts.dateFormat('%l:%M %p', this.x)
            : Highcharts.dateFormat('%e %b', this.x);
        const seriesdataPointInfo =
          this.points &&
          this.points
            .filter((category) => category.series.visible)
            .map((hub) => getTooltipInfo(hub, statistic))
            .filter((info) => info !== '')
            .join('');
        return `<div class="${classes.tooltipContainer}">
        <b>${tooltipHeading}</b><br/>${seriesdataPointInfo}
        </div>`;
      },
      followPointer: true,
      followTouchMove: true,
      stickOnContact: true,
    },
    xAxis: {
      type: 'datetime',
      width: hasCO2ChartSelected ? (isSmallMobile ? '90%' : '92%') : '96%',
      useHTML: true,
      labels: {
        style: {
          fontSize: 12,
          fontFamily: 'Source Sans Pro,sans-serif',
        },
        format: '{value:%b-%e }', // '{value:%Y-%b-%e %l:%M %p }',
        formatter() {
          const dt = DateTime.fromMillis(this.value);
          if (period === RelativeTimePeriod.day) {
            return formatHourTick(dt);
          }
          if (period === RelativeTimePeriod.week) {
            return formatTimeTick(dt);
          }
          if (period === RelativeTimePeriod.month) {
            return `${MONTHS[dt.month - 1].substring(0, 3)} ${dt.day}`;
          }
          if (period === RelativeTimePeriod.quarter) {
            return MONTHS[dt.month - 1];
          }
        },
      },
      min: x_min,
      max: x_max,
      tickInterval: getXaxisInterval(period, isMobile),
      crosshair: {
        enabled: true,
        color: 'blue',
      },
      plotBands: runWindowsPlotBands,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
    },
    yAxis: {
      offset: hasCO2ChartSelected ? (isSmallMobile ? -40 : -45) : 10,
      opposite: hasCO2ChartSelected,
      width: hasCO2ChartSelected ? '100%' : '99%',
      title: {
        text: null,
      },
      labels: {
        align: hasCO2ChartSelected ? 'center' : '',
        // x: hasCO2ChartSelected ? (isSmallMobile ? -5 : -10) : 0,
        y: hasCO2ChartSelected ? 2 : 0,
        formatter() {
          if (statistic) {
            let lastTickValue: number | undefined;
            if (this && this.axis && this.axis.paddedTicks && this.axis.paddedTicks.length > 0) {
              const yaxisTicks = this.axis.paddedTicks;
              lastTickValue = yaxisTicks[yaxisTicks.length - 1];
            }
            return hasCO2ChartSelected
              ? `${
                  lastTickValue && lastTickValue === this.value
                    ? ''
                    : TICK_FORMATTER[statistic](this.value)
                }`
              : `${TICK_FORMATTER[statistic](this.value)}`;
          }
        },
        style: {
          fontSize: '12px',
          fontFamily: 'Source Sans Pro,sans-serif',
        },
      },
      min: y_min,
      max: y_max,
      tickInterval: getTickInterval(statistic),
      plotBands: horizontalPlotBands,
      // gridZIndex: -9999,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      crosshair: {
        color: 'transparent',
        interpolate: true,
        label: {
          enabled: false,
        },
      },
    },
    series: allSeries,
    legend: {
      useHTML: true,
      verticalAlign: 'top',
      alignColumns: false,
      itemMarginBottom: 4,

      labelFormatter() {
        if (Object.values(PLOT_BANDS).includes(this.name)) {
          return `<span style="font-size:14px;font-weight:normal;font-family:Source Sans Pro,sans-serif;line-height:1;cursor:default">${
            this.name
          }</span>`;
        }
        return `<span style="font-size:14px;font-weight:600;font-family:Source Sans Pro,sans-serif;line-height: 1;">${
          this.name
        }</span>`;
      },
    },
    plotOptions: {
      series: {
        turboThreshold: 3000, // max dataPoints allow limit- larger threshold or set to 0 to disable
        events: {
          legendItemClick() {
            if (Object.values(PLOT_BANDS).includes(this.name)) {
              return false;
            }
            if (this.name === 'Weather') {
              setTimeout(() => {
                setHasWeatherSeriesVisible(!hasWeatherSeriesVisible);
              }, 0);
            }
          },
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
        marker: {
          enabledThreshold: 6,
        },
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};
