const convertHrIntoMinuts = (hr) => Math.round(hr * 60);
const getDurationText = (runtime, short = false) => {
  const durationText = `${
    runtime
      ? runtime === Infinity
        ? '\u221e'
        : runtime < 1 && runtime > 0
        ? convertHrIntoMinuts(runtime)
        : Math.round(runtime)
      : 0
  } ${runtime === 1 ? 'Hour' : runtime < 1 && runtime > 0 ? 'Minutes' : 'Hours'}`;
  const durationTextInShort = `${
    runtime
      ? runtime === Infinity
        ? '\u221e'
        : runtime < 1 && runtime > 0
        ? convertHrIntoMinuts(runtime)
        : Math.round(runtime)
      : 0
  } ${runtime > 1 ? 'hrs' : runtime === 1 ? 'hr' : 'm'}`;

  return short ? durationTextInShort : durationText;
};

export { getDurationText };
