import { AmberHoc, AmberHocProps } from '../../../util';
import {
  AssignFanControllerMutationVariables as Variables,
  FanSettingsFragmentFragment as Result,
  GetGrainBinFanSettingsDocument,
  GetGrainBinFanSettingsQuery,
  GetGrainBinFanSettingsQueryVariables,
  withAssignFanController as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithAssignFanControllerHocChildProps = {
  assignFanController: (variables: Variables) => Promise<Result>;
};
export const withAssignFanControllerHoc: AmberHoc<{}, WithAssignFanControllerHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<{}, WithAssignFanControllerHocChildProps, typeof component>,
    WithAssignFanControllerHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        assignFanController: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const { grain_bin_id } = variables;
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { assignFanController: result } = { ...data };
              proxy.writeQuery<GetGrainBinFanSettingsQuery, GetGrainBinFanSettingsQueryVariables>({
                query: GetGrainBinFanSettingsDocument,
                data: { __typename: 'Query', grain_bin_fan_settings: result },
                variables: { grain_bin_id },
              });
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.assignFanController;
        },
      };
    },
  })(component as any);
