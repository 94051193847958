import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { black_shade_2 } from '../../../../../core/src/style';

const useStyles = makeStyles((theme: Theme) => ({
  modeBadge: {
    width: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    fontSize: 15,
    opacity: 1,
    letterSpacing: '0.15px',
    color: black_shade_2,
    fontFamily: 'Work Sans,sans-serif',
  },
}));
type ModeProps = {
  modeColor: string;
  mode: string;
  customStyle?: any;
};
export const Mode = ({ mode, modeColor, customStyle = {} }: ModeProps) => {
  const classes = useStyles();

  return (
    <div className={classes.modeBadge} style={{ background: modeColor, ...customStyle }}>
      {mode}
    </div>
  );
};
