import { Typography } from '@material-ui/core';
import React from 'react';
import { amber_medium_grey } from '../../../style';

type StatusProps = {
  title: string;
  status: string;
  color: string;
};

export const Status = ({ title, status, color }: StatusProps) => (
  <div>
    <Typography variant="subtitle1" style={{ color: amber_medium_grey, width: 'fit-content' }}>
      {title}
    </Typography>
    <Typography variant="h6" style={{ color, width: 'fit-content' }}>
      {status}
    </Typography>
  </div>
);
