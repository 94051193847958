import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useMemo } from 'react';

import { Orientation, PileFragmentFragment } from '../../../api';
import { HighestLowestTemp } from '../HighestLowestTemp';
import { Cable } from './PileCablesTable';
import { PileLocationViewer } from './PileLocationViewer';

const useStyles = makeStyles({
  action: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: { marginBottom: 2 },
});

export const PileDetailsCard: FunctionComponent<{
  pile: PileFragmentFragment;
  pile_cables: Cable[];
  selected_cable_id: number | null;
  setSelectedCable: (item: Cable | null) => void;
}> = ({ pile, pile_cables, selected_cable_id, setSelectedCable }) => {
  const classes = useStyles();
  const { highest, lowest } = useMemo(
    () =>
      pile_cables.reduce(
        (acc, cable) => {
          if (!cable || isNaN(cable.max) || isNaN(cable.min)) {
            return acc;
          }
          return {
            highest: isNaN(acc.highest) ? cable.max : Math.max(acc.highest, cable.max),
            lowest: isNaN(acc.lowest) ? cable.min : Math.min(acc.lowest, cable.min),
          };
        },
        {
          highest: NaN,
          lowest: NaN,
        }
      ),
    [pile_cables]
  );
  const { orientation, shape } = pile;
  if (!shape || !shape.length_ft || !shape.radius_ft) {
    return <Typography>Must configure Pile Shape for Pile Summary</Typography>;
  }
  const { length_ft, radius_ft } = shape;

  return (
    <Card raised>
      <CardHeader
        title={`Pile Summary`}
        titleTypographyProps={{
          color: 'secondary',
        }}
        classes={{ action: classes.action }}
      />
      <Divider classes={{ root: classes.divider }} />
      <CardContent>
        <Grid container justify="space-around" alignItems="center">
          <Grid container direction="column" item xs={12} md={3} spacing={2}>
            <Grid item xs={12}>
              <HighestLowestTemp highest={highest} lowest={lowest} />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} justify="center">
            <PileLocationViewer
              length={length_ft}
              diameter={2 * radius_ft}
              orientation={orientation}
              pile_cables={pile_cables}
              landscape={true}
              selected_cable_id={selected_cable_id}
              setSelectedCable={setSelectedCable}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
