import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  ExperimentBoundValuesFragmentFragment as Result,
  withGetGrainBinDefaultExpValues as HOC,
} from '../__generated';

export type WithGetGrainBinDefaultExpValueHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainBinDefaultExpValueHocChildProps = {
  default_exp_values_loading: boolean;
  grain_bin_default_experiment_bound_values: Result | null;
};
export const withGetGrainBinDefaultExpValuesHoc: AmberHoc<
  WithGetGrainBinDefaultExpValueHocOwnProps,
  WithGetGrainBinDefaultExpValueHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainBinDefaultExpValueHocOwnProps,
      WithGetGrainBinDefaultExpValueHocChildProps,
      typeof component
    >,
    WithGetGrainBinDefaultExpValueHocChildProps
  >({
    options: ({ grain_bin_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      variables: { grain_bin_id },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          default_exp_values_loading: true,
          grain_bin_default_experiment_bound_values: null,
        };
      }
      return {
        default_exp_values_loading: loading || false,
        grain_bin_default_experiment_bound_values:
          data.grain_bin_default_experiment_bound_values || null,
      };
    },
  })(component as any);
