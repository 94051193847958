import { Divider, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArchiveOutlined } from '@material-ui/icons';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { GetProps, Omit } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import * as yup from 'yup';

import {
  ArchiveUnArchiveType,
  BargeFragmentFragment,
  ContainerType,
  GrainType,
  withCreateBargeHoc,
  WithCreateBargeHocChildProps,
  withGetAccountBargesHoc,
  WithGetAccountBargesHocChildProps,
  withGetAccountHoc,
  WithGetAccountHocChildProps,
  withGetBargeHoc,
  WithGetBargeHocChildProps,
  withGetGrainContainerHubLinksHoc,
  WithGetGrainContainerHubLinksHocChildProps,
  withGetUserHoc,
  WithGetUserHocChildProps,
  withGetViewerHoc,
  WithGetViewerHocChildProps,
  withUpdateBargeHoc,
  WithUpdateBargeHocChildProps,
} from '../../../api';
import {
  withArchiveUnArchiveGrainContainerHoc,
  withArchiveUnArchiveGrainContainerHocChildProps,
} from '../../../api/graphql/hoc/withArchiveUnArchiveGrainContainerHoc';
import { ContainerTypeLegacy } from '../../../util';
import { UiFormError } from '../../../util/format-error';
import { DialogSpinner } from '../../spinner';
import ConfirmationModal from '../../util/ConfirmationModal';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../../util/form2/BaseForm';
import { Button, ButtonSubmit } from '../../util/form2/Button';
import { ErrorBox } from '../../util/form2/ErrorBox';
import { IntTextField } from '../../util/form2/NumberField';
import { SelectGrainType, yup_grain_type } from '../../util/form2/SelectGrainType';
import { TextField } from '../../util/form2/TextField';

const useStyles = makeStyles({
  grid: { minWidth: 180, maxWidth: 250 },
  centered: { textAlign: 'center' },
  button_row: { margin: '0.5em', textAlign: 'center' },
  archiveButton: {
    fontSize: 14,
    textAlign: 'center',
    verticalAlign: 'top',
    display: 'inline-block',
  },
});
const maxDimension = 200;

export type Values = {
  alias: string;
  default_grain_type: GrainType | string;
  height_ft: number | string;
  width_ft: number | string;
  length_ft: number | string;
};
const validationSchema = yup.object().shape({
  alias: yup
    .string()
    .label('Barge Name')
    .required(),
  default_grain_type: yup_grain_type
    .label('Grain Type')
    .required()
    .nullable(false),
  height_ft: yup
    .number()
    .typeError('A number is required')
    .label('Height')
    .required()
    .positive()
    .max(maxDimension)
    .nullable(false),
  width_ft: yup
    .number()
    .typeError('A number is required')
    .label('Width')
    .required()
    .positive()
    .max(maxDimension)
    .nullable(false),
  length_ft: yup
    .number()
    .typeError('A number is required')
    .label('Length')
    .required()
    .positive()
    .max(maxDimension)
    .nullable(false),
});

export const BargeFormBase: FunctionComponent<
  WithCreateBargeHocChildProps &
    WithUpdateBargeHocChildProps &
    FormikWrapperHandlerProps<Values, BargeFragmentFragment> & {
      account_id: number | null;
      barge: BargeFragmentFragment | null;
      loading?: boolean;
      onClickBargeCoverLinks?: (arg: any) => any;
    }
> = ({ barge, onClickBargeCoverLinks, createBarge, updateBarge, account_id, ...props }) => {
  const classes = useStyles();
  const initial_values = useMemo(
    () =>
      barge
        ? {
            alias: barge.alias,
            default_grain_type: barge.default_grain_type || '',
            height_ft: barge.height_ft || '',
            length_ft: barge.length_ft || '',
            width_ft: barge.width_ft || '',
          }
        : {
            alias: '',
            default_grain_type: '',
            height_ft: '',
            length_ft: '',
            width_ft: '',
          },
    [barge]
  );
  const submitCallback = useCallback(
    async (values): Promise<BargeFragmentFragment> => {
      const {
        alias,
        default_grain_type,
        height_ft,
        length_ft,
        width_ft,
      } = validationSchema.validateSync(values);
      if (account_id !== null) {
        return await createBarge({
          account_id,
          alias,
          default_grain_type,
          height_ft: Number(height_ft),
          length_ft: Number(length_ft),
          width_ft: Number(width_ft),
        });
      }
      if (!barge) {
        throw new UiFormError('Barge and account_id were both null');
      }
      return await updateBarge({
        alias,
        default_grain_type,
        height_ft: Number(height_ft),
        length_ft: Number(length_ft),
        width_ft: Number(width_ft),
        barge_id: barge.barge_id,
      });
    },
    [createBarge, updateBarge, barge]
  );
  return (
    <FormikWrapper<Values, BargeFragmentFragment>
      {...props}
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initial_values}
      onSubmit={submitCallback}
      render={({ isSubmitting }) => (
        <BaseForm submitting_message="Saving Barge...">
          <Grid
            container
            direction="row"
            alignContent="flex-start"
            alignItems="center"
            justify="center"
            spacing={2}
            className={classes.grid}
          >
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.centered}>
                {barge ? barge.alias : 'Create New Barge'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField name="alias" label="Barge Name" placeholder="Barge 01" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <SelectGrainType name="default_grain_type" fullWidth label="Grain Type" />
            </Grid>
            <Grid item xs={12}>
              <IntTextField
                name="length_ft"
                label="Length (feet)"
                min={1}
                max={maxDimension}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <IntTextField
                name="width_ft"
                label="Width (feet)"
                min={1}
                max={maxDimension}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <IntTextField
                name="height_ft"
                label="Height (feet)"
                min={1}
                max={maxDimension}
                fullWidth
              />
            </Grid>
            <ErrorBox />
            <Grid item xs={12} className={classes.centered}>
              <ButtonSubmit />
            </Grid>
            {barge && onClickBargeCoverLinks && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} className={classes.button_row}>
                  <Button disabled={isSubmitting} onClick={onClickBargeCoverLinks}>
                    Barge Covers
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </BaseForm>
      )}
    />
  );
};

export const BargeForm = withUpdateBargeHoc(withCreateBargeHoc(BargeFormBase));

export const CreateBargeForm = (
  props: Omit<GetProps<typeof BargeForm>, 'barge'> & { account_id: number }
) => <BargeForm {...props} barge={null} />;

export const UpdateBargeForm = ({
  barge,
  ...props
}: Omit<GetProps<typeof BargeForm>, 'account_id'> & { barge: BargeFragmentFragment }) => (
  <BargeForm {...props} account_id={null} barge={barge} />
);

export const ArchiveBargeComponent = withGetAccountBargesHoc(
  withGetUserHoc(
    withGetGrainContainerHubLinksHoc(
      withArchiveUnArchiveGrainContainerHoc(
        ({
          barge,
          url_base,
          history,
          archiveUnArchiveGrainContainer,
          user_id,
          refecthUser,
          user,
          onSelectContainer,
          onArchiveClick,
          goOrgSettings,
          refecthAccount,
          grain_container,
          container_id,
          container_type,
          refetchBargeAccountLinks,
          account_id,
          account,
          ...props
        }: Pick<WithGetBargeHocChildProps, 'barge'> & {
          url_base: string;
          user_id: number;
          container_id: number;
          account_id: number;
          container_type: ContainerTypeLegacy;
          onSelectContainer: ((args: any) => void) | undefined;
          onArchiveClick: ((args: any) => void) | undefined;
          goOrgSettings: (() => void) | undefined;
        } & Pick<RouteComponentProps<{ account_id?: string }>, 'history'> &
          withArchiveUnArchiveGrainContainerHocChildProps &
          WithGetUserHocChildProps &
          WithGetAccountHocChildProps &
          Pick<WithGetGrainContainerHubLinksHocChildProps, 'grain_container'> &
          WithGetAccountBargesHocChildProps) => {
          const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] = useState<boolean>(
            false
          );
          const [loading, setLoading] = useState(false);
          const classes = useStyles();

          if (loading) {
            return <DialogSpinner title="Archiving Barge..." open={loading} />;
          }
          if (!barge || barge.archived) {
            return <></>;
          }

          let show_archive_alert_modal = false;
          const { barge_id } = barge;
          if (
            grain_container &&
            grain_container.active_hub_links &&
            grain_container.active_hub_links.length
          ) {
            show_archive_alert_modal = true;
          }

          const handleArchiveGrainContainer = async () => {
            const variables = {
              container_id: barge_id,
              container_type: ContainerType.Barge,
              archive_unarchive_flag: ArchiveUnArchiveType.Archive,
            };
            await archiveUnArchiveGrainContainer(variables);
          };

          const handleOrganizationSettingsOnClick = async () => {
            await refecthAccount();
            if (goOrgSettings) {
              await goOrgSettings();
            }
          };
          return (
            <>
              <Grid
                container
                direction="row"
                alignContent="flex-start"
                alignItems="center"
                justify="center"
                spacing={2}
                className={classes.centered}
              >
                {show_archive_alert_modal && (
                  <ConfirmationModal
                    showModal={showArchiveConfirmationModal}
                    confirmationMessage={
                      <>
                        All Hubs <strong>must</strong> be unassigned before archiving.
                      </>
                    }
                    cancelBtnText="Cancel"
                    confirmBtbText="Go to Configure Devices"
                    handleCancel={() => setShowArchiveConfirmationModal(false)}
                    handleConfirm={() => {
                      // go to device page
                      history.push(`${url_base}/devices`);
                      setShowArchiveConfirmationModal(false);
                    }}
                  />
                )}
                {!show_archive_alert_modal && (
                  <ConfirmationModal
                    showModal={showArchiveConfirmationModal}
                    confirmationMessage={
                      <>
                        Are you sure you want to <strong>Archive</strong> this Barge?
                      </>
                    }
                    cancelBtnText="No"
                    confirmBtbText="Yes"
                    handleCancel={() => setShowArchiveConfirmationModal(false)}
                    handleConfirm={async () => {
                      setShowArchiveConfirmationModal(false);
                      setLoading(true);
                      await handleArchiveGrainContainer();
                      const account_data = await refecthAccount();
                      await refetchBargeAccountLinks();
                      const selected_barges: any[] = account_data['data']['account']['barge_links'];
                      if (onArchiveClick) {
                        if (selected_barges && selected_barges.length) {
                          const unarchived_barges = selected_barges
                            .map((barge) => barge.barge)
                            .filter((barge) => !barge.archived);
                          if (unarchived_barges && unarchived_barges.length) {
                            const first_barge = unarchived_barges[0];
                            onArchiveClick({
                              container_id: first_barge['barge_id'],
                              container_type: ContainerTypeLegacy.barge,
                              container_name: first_barge['alias'],
                            });
                          } else {
                            onArchiveClick({
                              container_id: null,
                              container_type: ContainerTypeLegacy.barge,
                              container_name: '',
                            });
                          }
                        } else {
                          onArchiveClick({
                            container_id: null,
                            container_type: ContainerTypeLegacy.barge,
                            container_name: '',
                          });
                        }
                      }
                    }}
                  />
                )}
                <Grid item xs={5} style={{ display: 'block' }}>
                  <hr style={{ width: '100%' }} />
                  <Typography style={{ display: 'inline-block', textAlign: 'left', width: '70%' }}>
                    To archive your Barge click the 'Archive' button. Barges can be unarchived from{' '}
                    <Link
                      underline="always"
                      style={{ color: 'rgb(32 155 143)' }}
                      onClick={handleOrganizationSettingsOnClick}
                    >
                      'Organization Settings'
                    </Link>
                  </Typography>
                  <Grid item className={classes.archiveButton}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setShowArchiveConfirmationModal(true);
                      }}
                    >
                      <ArchiveOutlined style={{ marginRight: '8px' }} />
                      <Typography variant="h6">Archive</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        }
      )
    )
  )
);

export const UpdateBargeFormGql = withGetViewerHoc(
  withGetBargeHoc(
    withGetAccountHoc(
      ({
        loading,
        barge,
        refecthAccount,
        history,
        url_base,
        onSelectContainer,
        onArchiveClick,
        goOrgSettings,
        viewer,
        account,
        ...props
      }: Omit<GetProps<typeof UpdateBargeForm>, 'barge'> &
        WithGetBargeHocChildProps &
        WithGetAccountHocChildProps &
        Pick<RouteComponentProps<{ account_id?: string }>, 'history'> & {
          url_base: string;
          onSelectContainer: ((args: any) => void) | undefined;
          onArchiveClick: ((args: any) => void) | undefined;
          goOrgSettings: (() => void) | undefined;
        } & WithGetViewerHocChildProps) => {
        if (loading) {
          return <DialogSpinner title="Loading Barge..." open={loading} />;
        }
        if (!barge) {
          return <Typography variant="h5">Unable to load barge</Typography>;
        }
        return (
          <>
            <UpdateBargeForm {...props} barge={barge} />
            {viewer && account && (
              <ArchiveBargeComponent
                barge={barge}
                container_id={barge.barge_id}
                container_type={ContainerTypeLegacy.barge}
                url_base={url_base}
                history={history}
                user_id={viewer.user_id}
                onSelectContainer={onSelectContainer}
                onArchiveClick={onArchiveClick}
                goOrgSettings={goOrgSettings}
                refecthAccount={refecthAccount}
                account_id={account.account_id}
                account={account}
                {...props}
              />
            )}
          </>
        );
      }
    )
  )
);
