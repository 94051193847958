import React, { useEffect, useMemo, useState } from 'react';
import { GetProps } from 'react-redux';
import * as yup from 'yup';

import { GetAreHubsAssignedDocument } from '../../api';
import { WizardStep } from './WizardStep';

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { ArrayHelpers, FieldArray, useFormikContext } from 'formik';
import { QRScanDialog } from '..';
import { getDiagTestHubApi } from '../../../../core/src/api/rest';
import QrCodeIcon from '../../../../core/src/media/icons/QrCodeIcon';
import { getDistanceUnitLabel } from '../../../../core/src/util';
import { parseDeviceCoreId } from '../../../../core/src/util/parsing-utils';
import { Alert } from '../account/AccountUsersLayout';
import { HUB_OFFSET_HELPER_TEXT } from '../grain-container/aeration/AssignHubFormFields';
import DividerWithText from '../util/DividerWithText';
import { Checkbox } from '../util/form2/Checkbox';
import { SelectHubType, yup_hub_type } from '../util/form2/SelectHubType';
import { TextField } from '../util/form2/TextField';
import { DEVICE_NOT_FOUND_MSG, QR_CODE_SCAN_FAILED_MSG } from './AddFanControllerStep';

export const hasDuplicateHubs = (hubs): boolean => {
  let containsDuplicate = false;
  for (const i in hubs) {
    for (const j in hubs) {
      if (i !== j && hubs[i].core_id === hubs[j].core_id) {
        containsDuplicate = true;
        break;
      }
    }
    if (containsDuplicate) break;
  }
  return containsDuplicate;
};

export const canHubBeAssigned = async (client, device_ids) => {
  const response = await client.query({
    query: GetAreHubsAssignedDocument,
    variables: {
      device_ids,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  const data = response.data;
  if (response.errors || !data || !data.are_hubs_assigned) {
    if (
      response.errors &&
      response.errors[0] &&
      response.errors[0].message &&
      response.errors[0].message.includes('not found')
    ) {
      return 'Hub ID could not be found. Check to make sure the ID was entered correctly. Please contact hank@amber.ag for assistance.';
    }
    return 'Unexpected error, please contact hank@amber.ag for assistance';
  }
  const already_assigned = data.are_hubs_assigned
    .filter((assign_status) => assign_status.is_assigned)
    .map((assign_status) => assign_status.device_id);
  if (already_assigned.length) {
    return `Hub ID${
      already_assigned.length > 1 ? 's' : ''
    } already in use for: ${already_assigned.join(', ')}`;
  }
  return null;
};

export const validation_schema = yup.object().shape({
  hubs: yup.array().of(
    yup.object().shape({
      core_id: yup
        .string()
        .required()
        .label('Hub ID'),
      nickname: yup
        .string()
        .label('Hub Nickname')
        .required(),
      hub_type: yup_hub_type.label('Hub Type').required(),
      has_level_sensor: yup.boolean(),
      hub_offset_ft: yup
        .number()
        .typeError('A number is required')
        .label('Hub Offset')
        .positive()
        .min(0),
    })
  ),
});

const useStyles = makeStyles({
  grid: {
    minWidth: 300,
    maxWidth: 400,
  },
  button_margin: { margin: '10px 0' },
});

export const AddHubStep = ({
  title = 'Add Hub(s) for this Bin',
  validationSchema,
}: Partial<GetProps<typeof WizardStep>>) => {
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext<any>();
  const array_values = useMemo(() => values['hubs'], [values]);
  const [showQRScannerDialog, setshowQRScannerDialog] = useState(false);
  const [hasQRScanningFailed, setHasQRScanningFailed] = useState(false);
  const [isDeviceNotFound, setIsDeviceNotFound] = useState(false);
  const [targetTextFieldName, setTargetTextFieldName] = useState('');
  const distanceUnitLabel = getDistanceUnitLabel();

  const openQRScannerDialog = (targetTextField) => {
    setTargetTextFieldName(targetTextField);
    setshowQRScannerDialog(true);
  };

  const closeQRScannerDialog = () => {
    setshowQRScannerDialog(false);
  };

  const handleScan = async (data) => {
    if (data) {
      try {
        const coreId = parseDeviceCoreId(data);
        const response = await getDiagTestHubApi(coreId);
        console.log('response', coreId, response.data.data);
        if (Object.keys(response.data.data).length === 0) {
          setIsDeviceNotFound(true);
          return;
        }
        setFieldValue(targetTextFieldName, response.data.data.alias);
        closeQRScannerDialog();
      } catch (error) {
        setHasQRScanningFailed(true);
        console.error(error);
      }
    }
  };

  const handleError = (err) => {
    setHasQRScanningFailed(true);
  };

  useEffect(() => {
    // reset hub_offset_ft when has_level_sensor is unchecked
    for (const idx in array_values) {
      if (!array_values[idx].has_level_sensor) {
        setFieldValue(`hubs.${idx}.hub_offset_ft`, 0);
      }
    }
  }, [array_values]);

  return (
    <WizardStep title={title} validationSchema={validationSchema}>
      <FieldArray name={'hubs'}>
        {(arrayHelpers: ArrayHelpers) => {
          return (
            <Grid
              container
              direction="column"
              alignContent="flex-start"
              alignItems="center"
              justify="center"
              spacing={2}
              className={classes.grid}
            >
              {array_values && array_values.length
                ? array_values.map((value, ix) => (
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      key={`hubs'-${ix}`}
                    >
                      <DividerWithText centerText={`HUB ${ix + 1}`} style={{ width: '100%' }} />
                      <Grid
                        container
                        direction="column"
                        item
                        xs={11}
                        style={{
                          border: '1px solid lightgrey',
                          borderRadius: 4,
                          padding: 10,
                          marginTop: 20,
                        }}
                      >
                        <div>
                          <TextField
                            style={{ marginTop: 10 }}
                            name={`hubs.${ix}.core_id`}
                            id="assign-hub"
                            label="Hub ID"
                            placeholder="AmAg_00-0-000"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="open QR code scanner"
                                    onClick={() => openQRScannerDialog(`hubs.${ix}.core_id`)}
                                  >
                                    <QrCodeIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            required
                          />
                          <TextField
                            style={{ marginTop: 10 }}
                            name={`hubs.${ix}.nickname`}
                            label="Hub Nickname"
                            placeholder="Headspace Hub"
                            fullWidth
                            required
                          />
                          <SelectHubType
                            style={{ marginTop: 10 }}
                            name={`hubs.${ix}.hub_type`}
                            label="Hub Type"
                            placeholder="None Selected"
                            fullWidth
                            required
                          />
                          <Checkbox
                            style={{ marginTop: 10, width: '100%' }}
                            name={`hubs.${ix}.has_level_sensor`}
                            label="Level Sensor Add-on Equipped"
                          />
                          {value.has_level_sensor && (
                            <>
                              <Typography
                                variant="subtitle2"
                                style={{ marginBottom: 5, marginTop: 5 }}
                              >
                                {HUB_OFFSET_HELPER_TEXT}
                              </Typography>
                              <TextField
                                required
                                fullWidth
                                name={`hubs.${ix}.hub_offset_ft`}
                                label={`Hub Offset (${distanceUnitLabel})`}
                                inputProps={{
                                  inputMode: 'decimal',
                                }}
                                type="number"
                              />
                            </>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={async () => {
                            // not using array helpers' remove because it precludes empty array as a valid value
                            const new_values = [...array_values];
                            new_values.splice(ix, 1);
                            setFieldValue('hubs', new_values);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))
                : null}
              <Grid container item xs={12} direction="row">
                {/* not using useCallback as this relies on arrayHelpers and the code is conditional. no solid way to guarantee number of hooks does not change */}
                <Button
                  className={classes.button_margin}
                  fullWidth
                  onClick={() => {
                    arrayHelpers.push({
                      core_id: '',
                      nickname: '',
                      hub_type: null,
                      has_level_sensor: false,
                      hub_offset_ft: 0,
                    });
                  }}
                  variant="outlined"
                >
                  + Add {array_values && array_values.length ? `another ` : ``}
                  Hub
                </Button>
              </Grid>
            </Grid>
          );
        }}
      </FieldArray>
      <QRScanDialog
        title="Scan device QR code"
        showDialog={showQRScannerDialog}
        closeDialog={closeQRScannerDialog}
        onQRCodeScan={handleScan}
        onQRCodeError={handleError}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={hasQRScanningFailed}
        onClose={() => {
          () => setHasQRScanningFailed(false);
        }}
        autoHideDuration={6000}
      >
        <Alert
          style={{ fontSize: 16 }}
          severity="error"
          onClose={() => setHasQRScanningFailed(false)}
        >
          {QR_CODE_SCAN_FAILED_MSG}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isDeviceNotFound}
        onClose={() => setIsDeviceNotFound(false)}
        autoHideDuration={6000}
      >
        <Alert style={{ fontSize: 16 }} severity="error" onClose={() => setIsDeviceNotFound(false)}>
          {DEVICE_NOT_FOUND_MSG}
        </Alert>
      </Snackbar>
    </WizardStep>
  );
};
