import { NormalizedCache } from 'apollo-cache-inmemory';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  AssignHubMutationVariables as Variables,
  GetGrainContainerHubLinksDocument,
  GetGrainContainerHubLinksQuery,
  GetGrainContainerHubLinksQueryVariables,
  HubContainerLinkBaseFragmentFragment as Result,
  withAssignHub as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithAssignHubHocChildProps = {
  assignHub: (variables: Variables) => Promise<Result>;
};
export const withAssignHubHoc: AmberHoc<{}, WithAssignHubHocChildProps> = (component) =>
  HOC<AmberHocProps<{}, WithAssignHubHocChildProps, typeof component>, WithAssignHubHocChildProps>({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        assignHub: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { assignHub: created } = { ...data };
              if (!created) {
                return;
              }

              const { grain_container = null } =
                proxy.readQuery<
                  GetGrainContainerHubLinksQuery,
                  GetGrainContainerHubLinksQueryVariables
                >({
                  query: GetGrainContainerHubLinksDocument,
                  variables: {
                    container_id: variables.container_id,
                    container_type: variables.container_type,
                  },
                }) || {};

              if (grain_container) {
                proxy.writeQuery<
                  GetGrainContainerHubLinksQuery,
                  GetGrainContainerHubLinksQueryVariables
                >({
                  query: GetGrainContainerHubLinksDocument,
                  data: {
                    __typename: 'Query',
                    grain_container: {
                      ...grain_container,
                      active_hub_links: [...(grain_container.active_hub_links || []), created],
                    },
                  },
                  variables: {
                    container_id: variables.container_id,
                    container_type: variables.container_type,
                  },
                });
              }
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data || !data.assignHub) {
            throw new Error('Unexpected server response');
          }
          return data.assignHub;
        },
      };
    },
  })(component as any);
