import { fade, Grid, makeStyles, Theme } from '@material-ui/core';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  pollFanControllerState,
  setFanScheduleDrafts,
  setIsFanStartTriggered,
  setIsFanStopTriggered,
  setIsUserOnTempRecommWindowsPage,
} from '../../../action';
import {
  ContainerType,
  FanControllerRunWindow,
  FanRunWindowRecommendedOption,
  GrainBinFragmentFragment,
  UpdateGrainBinMutationVariables,
  withGetGrainBinContainerHoc,
  WithGetGrainBinContainerHocChildProps,
  withGetGrainBinHoc,
  WithGetGrainBinHocChildProps,
  withInitiateGuidedRecommendationsHoc,
  WithInitiateGuidedRecommendationsHocChildProps,
  withSetGrainContainerAerationScheduleHoc,
  WithSetGrainContainerAerationScheduleHocChildProps,
  withUpdateGrainBinHoc,
  WithUpdateGrainBinHocChildProps,
} from '../../../api';
import { useWeatherData } from '../../../contexts';
import { blue_shade_1 } from '../../../style';
import { checkValidSchedule, collapseWindows, ContainerTypeLegacy } from '../../../util';
import { getFormatedDateTimeString } from '../../../util/format-date';
import { CenteredSpinner } from '../../spinner';
import { BaseForm, FormikWrapper } from '../../util/form2/BaseForm';
import { ErrorBox } from '../../util/form2/ErrorBox';
import { SubmitButtonsGroup } from '../../util/form2/SubmitButtonsGroup';
import {
  createNavigationType,
  FanControlNavigationOption,
  FanControlsNavigation,
} from '../FanControlNavigationOption';
import { FanControllerScheduleDateFields } from './FanControllerScheduleDateFields';
import { GrainCondiFormSubmittedValuesType } from './FanControls';

const useStyles = makeStyles((theme: Theme) => ({
  submitSchedule: {
    margin: 0,
    backgroundColor: blue_shade_1,
    height: 56,
    color: 'white',
    fontSize: 16,
    borderRadius: 8,
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: fade(blue_shade_1, 0.3),
    },
    '&:hover': {
      backgroundColor: fade(blue_shade_1, 0.8),
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
  },
  cancelSchedule: {
    margin: 0,
    height: 56,
    borderRadius: 8,
    textTransform: 'none',
    fontSize: 16,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
  },
}));

// specific to this file, "yesterday" is used for "now"
// use datestring to compare the days/months to each other
export const formatDayNow = (
  dt: DateTime,
  as_of = DateTime.local(),
  prevent_now = false
): string => {
  if (!prevent_now && dt.toMillis() < as_of.toMillis()) {
    return 'Now';
  }
  const tz = as_of.zoneName;
  const NOW = DateTime.local();
  const addedYearDate = NOW.plus({ years: 1 })
    .setZone(tz)
    .setLocale('en-US');
  const NO_END = DateTime.fromFormat(addedYearDate.toFormat('MM/dd/yyyy'), 'MM/dd/yyyy');

  if (dt.toMillis() >= NO_END.toMillis()) {
    return 'Forever';
  }

  if (dt.hasSame(as_of, 'day')) {
    return 'Today';
  }
  return dt.toFormat('ccc');
};

const getTimeFromDate = (dt) => `${dt.hour}:${`${dt.minute < 10 ? '0' : ''}${dt.minute}`}`;

// used to filter out times later than time of a date
const isTimeAfterReference = (reference_time: string) => (time: string) =>
  createDateTime(new Date(), time).getTime() >=
  createDateTime(new Date(), reference_time).getTime();

const createDateTime = (date: Date, time: string) =>
  new Date(`${new Date(date).toDateString()} ${time}`);

export const getNextStart = (as_of) => {
  const mins = as_of.minute;
  const next_start_mins = (Math.floor(mins / 15) + 1) * 15;
  return as_of.startOf('hour').plus({ minutes: next_start_mins });
};

// given an array of time strings, find the nearst date less than or equal to the
export const findNearestTimeFloor = (times: string[], now: DateTime): number => {
  const current_hours = now.hour;
  const current_minutes = now.minute;
  const current_time = `${current_hours}:${current_minutes >= 30 ? '30' : '00'}`;
  return times.findIndex((time) => time === current_time) || 0;
};

export const DATE_IX_ARRAY = [0, 1, 2, 3, 4, 5, 6];
export const TIMES_ARRAY = [
  '0:00',
  '0:30',
  '1:00',
  '1:30',
  '2:00',
  '2:30',
  '3:00',
  '3:30',
  '4:00',
  '4:30',
  '5:00',
  '5:30',
  '6:00',
  '6:30',
  '7:00',
  '7:30',
  '8:00',
  '8:30',
  '9:00',
  '9:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

export const OVERLAP_ERROR =
  'Overlapping windows will be combined into a single run window on submit';

type RunWindow = { start_date: string; end_date: string; start_time: string; end_time: string };
type Values = {
  fan_guidance: {
    has_opt_in_fan_guidance: boolean;
    fan_guidance_duration: number;
  };
  run_windows: RunWindow[];
};

const validationSchema = yup.object().shape({
  fan_guidance: yup.object().shape({
    has_opt_in_fan_guidance: yup.boolean().required(),
    fan_guidance_duration: yup
      .number()
      .typeError('A number is required')
      .positive()
      .required()
      .max(10)
      .min(1),
  }),
  run_windows: yup.array().of(
    yup.object().shape({
      start_date: yup
        .string()
        .label('Start Date')
        .required(),
      start_time: yup
        .string()
        .label('Start Time')
        // start time not required if "now" selected
        // now selected defined by start date being yesterday, comparing date strings
        .when('start_date', (value, schema) =>
          value &&
          DateTime.fromFormat(value, 'MM/dd/yyyy').toMillis() >=
            DateTime.fromFormat(DateTime.local().toFormat('MM/dd/yyyy'), 'MM/dd/yyyy').toMillis()
            ? schema.required()
            : schema
        ),
      end_date: yup
        .string()
        .label('End Date')
        .required(),
      end_time: yup
        .string()
        .label('End Time')
        // end time not required if "forever" selected
        .when('end_date', (value, schema) => {
          const NOW = DateTime.local();
          const tz = NOW.zoneName;
          const addedYearDate = NOW.plus({ years: 1 })
            .setZone(tz)
            .setLocale('en-US');
          const NO_END = DateTime.fromFormat(addedYearDate.toFormat('MM/dd/yyyy'), 'MM/dd/yyyy');

          return value && DateTime.fromFormat(value, 'MM/dd/yyyy').year < NO_END.year
            ? schema.required()
            : schema;
        })
        .test('after-start', 'Negative runtime', function(value) {
          const { start_date, start_time, end_date } = this.parent;
          const isNow =
            start_date &&
            DateTime.fromFormat(start_date, 'MM/dd/yyyy').toMillis() <
              DateTime.fromFormat(DateTime.local().toFormat('MM/dd/yyyy'), 'MM/dd/yyyy').toMillis();

          // handle now case
          if (isNow) {
            return (
              !end_date ||
              (end_date &&
                value &&
                DateTime.local().toMillis() <
                  DateTime.fromFormat(
                    getFormatedDateTimeString(end_date, value),
                    'MM/dd/yyyy hh:mm'
                  ).toMillis())
            );
          }

          const hasValidRunTime =
            start_date &&
            start_time &&
            end_date &&
            value &&
            DateTime.fromFormat(
              getFormatedDateTimeString(start_date, start_time),
              'MM/dd/yyyy hh:mm'
            ).toMillis() <
              DateTime.fromFormat(
                getFormatedDateTimeString(end_date, value),
                'MM/dd/yyyy hh:mm'
              ).toMillis();

          return !end_date || hasValidRunTime;
        }),
    })
  ),
});

function generateInitialWindows(grain_bin: GrainBinFragmentFragment | null): RunWindow[] {
  return useMemo(() => {
    const initial = {
      start_date: DateTime.local()
        .plus({ days: -1 })
        .toFormat('MM/dd/yyyy'),
      start_time:
        TIMES_ARRAY[findNearestTimeFloor(TIMES_ARRAY, DateTime.local().plus({ hours: 1 }))],
      end_date: DateTime.local()
        .plus({ hours: 13 })
        .toFormat('MM/dd/yyyy'),
      end_time:
        TIMES_ARRAY[findNearestTimeFloor(TIMES_ARRAY, DateTime.local().plus({ hours: 13 }))],
    };
    return grain_bin &&
      grain_bin.container &&
      grain_bin.container.aeration_schedule &&
      grain_bin.container.aeration_schedule.length
      ? grain_bin.container.aeration_schedule
          .filter(
            ({ end_epoch }) =>
              DateTime.fromMillis(new Date(end_epoch).getTime()).toMillis() >
              DateTime.local().toMillis()
          )
          .map(({ start_epoch, end_epoch }) => ({
            start_date: DateTime.fromMillis(new Date(start_epoch).getTime()).toFormat('MM/dd/yyyy'),
            start_time: getTimeFromDate(DateTime.fromMillis(new Date(start_epoch).getTime())),
            end_date: DateTime.fromMillis(new Date(end_epoch).getTime()).toFormat('MM/dd/yyyy'),
            end_time: getTimeFromDate(DateTime.fromMillis(new Date(end_epoch).getTime())),
          }))
      : [initial];
  }, [grain_bin]);
}

export const FanSetMultiSchedule = withInitiateGuidedRecommendationsHoc(
  withSetGrainContainerAerationScheduleHoc(
    withGetGrainBinHoc(
      withUpdateGrainBinHoc(
        withGetGrainBinContainerHoc(
          ({
            updateGrainBin,
            setGrainContainerAerationSchedule,
            initiateGuidedRecommendations,
            refetch: refetchGrainBin,
            refetch_grain_bin_container,
            grain_bin,
            loading,
            grain_bin_id,
            container_id,
            container_type,
            container_type2,
            storage_period,
            setNavigation,
            navigation,
            recommendedWindows,
            isRecommendingWindows,
            hourlyForecasts,
            setShowFanGuidanceExtPrompt,
            showRecomendationsFlow,
            grainCondiFormSubmittedValues,
            ...props
          }: WithInitiateGuidedRecommendationsHocChildProps &
            WithGetGrainBinHocChildProps &
            WithUpdateGrainBinHocChildProps &
            WithSetGrainContainerAerationScheduleHocChildProps &
            WithGetGrainBinContainerHocChildProps & {
              grain_bin_id: number;
              container_id: number;
              container_type: ContainerType;
              container_type2: ContainerTypeLegacy;
              storage_period?: {
                grain_bin_storage_cycle_id: number;
              } | null;
              navigation: FanControlNavigationOption;
              setNavigation: (navigation: FanControlNavigationOption) => void;
              isRecommendingWindows: boolean;
              recommendedWindows?: RunWindow[];
              hourlyForecasts?: any;
              setShowFanGuidanceExtPrompt?: (show_fan_guidance_ext) => void;
              showRecomendationsFlow: () => void;
              grainCondiFormSubmittedValues: GrainCondiFormSubmittedValuesType;
            }) => {
            const classes = useStyles();
            const dispatch = useDispatch();
            const weatherData = useWeatherData();
            const [scheduledAutomationInfo, setScheduledAutomationInfo] = useState<{
              opt_in_fan_guidance: boolean;
              enable_fan_guidance: boolean;
              fan_guidance_start_date: Date | null;
              fan_guidance_end_date: Date | null;
            } | null>(null);
            const [showLoading, setShowLoading] = useState(false);

            useEffect(() => {
              if (grain_bin) {
                setScheduledAutomationInfo({
                  opt_in_fan_guidance: grain_bin.opt_in_fan_guidance || false,
                  enable_fan_guidance: grain_bin.enable_fan_guidance || false,
                  fan_guidance_start_date: grain_bin.fan_guidance_start_date || null,
                  fan_guidance_end_date: grain_bin.fan_guidance_end_date || null,
                });
              } else {
                setScheduledAutomationInfo({
                  opt_in_fan_guidance: false,
                  enable_fan_guidance: false,
                  fan_guidance_start_date: null,
                  fan_guidance_end_date: null,
                });
              }
            }, [grain_bin]);

            const endEditing = useCallback(() => {
              dispatch(setFanScheduleDrafts([]));
              setNavigation(
                createNavigationType(
                  FanControlsNavigation.ShowFanStatus,
                  navigation.recommendationOptionValue
                )
              );
            }, [setNavigation, dispatch]);
            const submitCallback = useCallback(
              async (values) => {
                try {
                  dispatch(setIsFanStopTriggered({ isFanStopTriggered: false }));
                  dispatch(setIsFanStartTriggered({ isFanStartTriggered: true }));
                  const windows = values.run_windows
                    .map((window) => ({
                      start_epoch:
                        DateTime.fromFormat(
                          getFormatedDateTimeString(window.start_date, window.start_time),
                          'MM/dd/yyyy hh:mm'
                        ).toMillis() < DateTime.local().toMillis()
                          ? DateTime.local().toMillis()
                          : DateTime.fromFormat(
                              getFormatedDateTimeString(window.start_date, window.start_time),
                              'MM/dd/yyyy hh:mm'
                            ).toMillis(),
                      end_epoch: DateTime.fromFormat(
                        getFormatedDateTimeString(window.end_date, window.end_time),
                        'MM/dd/yyyy hh:mm'
                      ).toMillis(),
                    }))
                    .filter(checkValidSchedule);
                  const run_windows = collapseWindows(windows);

                  // for Recommended Scheduling
                  if (isRecommendingWindows) {
                    const {
                      fan_guidance: { has_opt_in_fan_guidance, fan_guidance_duration },
                    } = validationSchema.validateSync(values);
                    const fan_guidance_start_date = DateTime.local().toMillis();
                    const fan_guidance_end_date = DateTime.local()
                      .plus({
                        days: fan_guidance_duration || 10,
                      })
                      .toMillis();

                    if (has_opt_in_fan_guidance) {
                      const result = await initiateGuidedRecommendations({
                        container_id,
                        container_type,
                        automated_period: fan_guidance_duration,
                      });
                      setShowFanGuidanceExtPrompt && setShowFanGuidanceExtPrompt(false);
                      return result.aeration_schedule;
                    }

                    const updateGrainBinVariables: UpdateGrainBinMutationVariables = {
                      grain_bin_id,
                      opt_in_fan_guidance: has_opt_in_fan_guidance,
                      enable_fan_guidance: has_opt_in_fan_guidance,
                      fan_guidance_start_date: new Date(fan_guidance_start_date), // to persist last fan_guidance_duration in dropdown after user enables opt_in_fan_guidance
                      fan_guidance_end_date: new Date(fan_guidance_end_date),
                    };
                    await updateGrainBin(updateGrainBinVariables);
                  }
                  console.log('navigation', navigation);

                  const current_windows = values.run_windows.map((run_window) => {
                    if (run_window.start_date && run_window.start_time) {
                      const dt = DateTime.fromFormat(
                        getFormatedDateTimeString(run_window.start_date, run_window.start_time),
                        'MM/dd/yyyy hh:mm'
                      );
                      if (dt && dt.toMillis() < DateTime.local().toMillis()) {
                        return {
                          ...run_window,
                          start_date: dt.plus({ days: 1 }).toFormat('MM/dd/yyyy'),
                        };
                      }
                    }
                    return run_window;
                  });

                  console.log('final windows', { intial_windows, curr_windows: current_windows });
                  const start_recommendation_type =
                    isRecommendingWindows && navigation && isEqual(intial_windows, current_windows)
                      ? ((navigation.recommendationOptionValue
                          .option as unknown) as FanRunWindowRecommendedOption)
                      : FanRunWindowRecommendedOption.Custom;

                  const end_recommendation_type =
                    isRecommendingWindows && navigation && isEqual(intial_windows, current_windows)
                      ? ((navigation.recommendationOptionValue
                          .option as unknown) as FanRunWindowRecommendedOption)
                      : FanRunWindowRecommendedOption.Custom;

                  const result = await setGrainContainerAerationSchedule({
                    container_id,
                    container_type,
                    run_windows,
                    start_recommendation_type,
                    end_recommendation_type,
                  });
                  return result.aeration_schedule;
                } catch (err) {
                  throw new Error(err);
                }
              },
              [setGrainContainerAerationSchedule, grain_bin, isRecommendingWindows]
            );

            const submitSuccessCallback = useCallback(
              async (result, formikHelpers) => {
                setShowLoading(true);
                dispatch(
                  setIsUserOnTempRecommWindowsPage({
                    isUserOnTempRecommWindowsPage: false,
                  })
                );
                await Promise.all([refetchGrainBin(), refetch_grain_bin_container()]);
                if (grain_bin && grain_bin.fan_controllers) {
                  grain_bin.fan_controllers.forEach(
                    ({ fan_controller: { fan_controller_id_next } }) =>
                      dispatch(
                        pollFanControllerState({
                          storage_period,
                          container_type: container_type2,
                          fan_controller_id: fan_controller_id_next,
                          container_id: grain_bin_id,
                        })
                      )
                  );
                }
                setShowLoading(false);
                endEditing();
              },
              [endEditing, dispatch, grain_bin]
            );

            const getValidDuration = (duration) =>
              duration >= 1 && duration <= 10 ? duration : 10;
            let intial_windows: RunWindow[] = [];
            let all_recommended_windows: RunWindow[] = [];
            let userPrevFanGuidanceDuration = 10;
            if (
              scheduledAutomationInfo &&
              scheduledAutomationInfo.fan_guidance_start_date &&
              scheduledAutomationInfo.fan_guidance_end_date
            ) {
              const { fan_guidance_start_date, fan_guidance_end_date } = scheduledAutomationInfo;
              const startDate = DateTime.fromMillis(new Date(fan_guidance_start_date).getTime());
              const endDate = DateTime.fromMillis(new Date(fan_guidance_end_date).getTime());
              userPrevFanGuidanceDuration = getValidDuration(
                Math.round(Number(endDate.diff(startDate, 'days').toObject().days))
              );
            }

            if (isRecommendingWindows) {
              const now_in_local_zone = DateTime.local().toMillis();
              const lastDayForecast =
                weatherData && weatherData.dailyForecast
                  ? weatherData.dailyForecast[weatherData.dailyForecast.length - 1]
                  : null;
              const lastDayForecastEndHour = lastDayForecast
                ? DateTime.fromMillis(new Date(lastDayForecast.epoch_time).getTime()).set({
                    hour: 23,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  })
                : null;
              const validRecommendedWindows = recommendedWindows
                ? recommendedWindows.filter(({ start_date, start_time, end_date, end_time }) => {
                    if (start_date && start_time && end_date && end_time) {
                      const start = DateTime.fromFormat(
                        getFormatedDateTimeString(start_date, start_time),
                        'MM/dd/yyyy hh:mm'
                      ).toMillis();
                      const end = DateTime.fromFormat(
                        getFormatedDateTimeString(end_date, end_time),
                        'MM/dd/yyyy hh:mm'
                      ).toMillis();

                      if (lastDayForecastEndHour) {
                        return (
                          start < lastDayForecastEndHour.toMillis() &&
                          start < end &&
                          end >= now_in_local_zone
                        );
                      }
                      return start < end && end >= now_in_local_zone;
                    }
                    return false;
                  })
                : [];

              const automationPeriodEndDate = DateTime.local().plus({
                days: userPrevFanGuidanceDuration,
              });

              intial_windows = validRecommendedWindows
                ? scheduledAutomationInfo && scheduledAutomationInfo.opt_in_fan_guidance
                  ? validRecommendedWindows.filter(
                      ({ start_date, start_time }) =>
                        automationPeriodEndDate &&
                        start_date &&
                        start_time &&
                        DateTime.fromFormat(
                          getFormatedDateTimeString(start_date, start_time),
                          'MM/dd/yyyy hh:mm'
                        ).toMillis() <= automationPeriodEndDate.toMillis()
                    )
                  : validRecommendedWindows
                : [];

              all_recommended_windows = validRecommendedWindows ? validRecommendedWindows : [];
            } else {
              intial_windows = generateInitialWindows(grain_bin);
              all_recommended_windows = generateInitialWindows(grain_bin);
            }

            const onCancel = () => {
              dispatch(
                setIsUserOnTempRecommWindowsPage({
                  isUserOnTempRecommWindowsPage: false,
                })
              );
              endEditing();
            };

            if (loading || showLoading) {
              return <CenteredSpinner fadeIn="none" />;
            }

            return (
              <>
                <FormikWrapper<Values, FanControllerRunWindow[]>
                  {...props}
                  enableReinitialize
                  initialValues={{
                    fan_guidance: {
                      has_opt_in_fan_guidance: scheduledAutomationInfo
                        ? scheduledAutomationInfo.opt_in_fan_guidance
                        : false,
                      fan_guidance_duration: userPrevFanGuidanceDuration,
                    },
                    run_windows: intial_windows,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={submitCallback}
                  onSubmitSuccess={submitSuccessCallback}
                  render={({
                    values: {
                      fan_guidance: { has_opt_in_fan_guidance },
                      run_windows,
                    },
                  }) => (
                    <BaseForm submitting_message="Saving Schedule..." style={{ padding: 0 }}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        alignContent="center"
                        justify="space-between"
                        style={{ maxWidth: 520 }}
                      >
                        <FanControllerScheduleDateFields
                          title={
                            isRecommendingWindows
                              ? 'Recommended Schedule'
                              : 'Setting Custom Schedule'
                          }
                          navigation={navigation}
                          hourlyForecasts={hourlyForecasts}
                          isRecommendingWindows={isRecommendingWindows}
                          intial_windows={intial_windows}
                          all_recommended_windows={all_recommended_windows}
                          fan_guidance_start_date={
                            scheduledAutomationInfo &&
                            scheduledAutomationInfo.fan_guidance_start_date
                          }
                          grainCondiFormSubmittedValues={grainCondiFormSubmittedValues}
                          showRecomendationsFlow={showRecomendationsFlow}
                        />

                        <SubmitButtonsGroup
                          onCancel={onCancel}
                          cancelText="Cancel"
                          submitButtonText={
                            isRecommendingWindows && has_opt_in_fan_guidance
                              ? 'Start Automation'
                              : 'Set Schedule'
                          }
                          cancelContainerStyles={{ paddingLeft: 0 }}
                          submitContainerStyles={{ paddingRight: 0 }}
                        />
                      </Grid>
                      <ErrorBox />
                    </BaseForm>
                  )}
                />
              </>
            );
          }
        )
      )
    )
  )
);

export const computeAvgGrainConditions = (start_epoch: number, end_epoch: number, hours: any) => {
  // if the end hour was set and there is no end epoch, set the end epoch to a default
  // also make sure the end epoch is after the start epoch
  if (
    start_epoch &&
    end_epoch &&
    hours &&
    hours.length &&
    new Date(start_epoch).getTime() < new Date(end_epoch).getTime()
  ) {
    // limit forecast to the future
    const start = new Date(start_epoch).getTime() > new Date().getTime() ? start_epoch : new Date();

    const sorted_hours = [...hours]
      // sort oldest to newest i.e. lowest epoch to highest
      .sort((a, b) => new Date(a.epoch_time).getTime() - new Date(b.epoch_time).getTime());

    const fan_run_duration =
      (new Date(end_epoch).getTime() - new Date(start_epoch).getTime()) / (1000 * 60 * 60);
    const one_hour = 1;
    let filtered_hours;

    if (fan_run_duration < one_hour) {
      // filter to nearest hourly forecast hour
      filtered_hours = sorted_hours.filter((hr) => {
        const hr_date = new Date(hr.epoch_time);
        const start_date = new Date(start);
        return (
          hr_date.getDate() === start_date.getDate() &&
          hr_date.getHours() === start_date.getHours() &&
          hr.humidity_rh !== null &&
          hr.humidity_rh !== undefined &&
          hr.temp_f !== null &&
          hr.temp_f !== undefined
        );
      });
    } else {
      // filter out any hours outside the runtime && items with null forecast
      filtered_hours = sorted_hours.filter((hr) => {
        const hr_epoch_time = new Date(hr.epoch_time).getTime();
        return (
          hr.humidity_rh !== null &&
          hr.humidity_rh !== undefined &&
          hr.temp_f !== null &&
          hr.temp_f !== undefined &&
          hr_epoch_time - new Date(start).getTime() >= 0 &&
          hr_epoch_time - new Date(end_epoch).getTime() <= 0
        );
      });
    }

    const [total_temp, total_hum] = filtered_hours.reduce(
      (acc, curr) => {
        // safe to assume number and not null because null values were filtered out above
        return [acc[0] + (curr.temp_f as number), acc[1] + (curr.humidity_rh as number)];
      },
      [0, 0]
    );
    return [total_temp / filtered_hours.length, total_hum / filtered_hours.length];
  }
  return [null, null];
};
