import { Button, Grid, Theme } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import * as yup from 'yup';
import { RecommRunWindowsExperiments } from '../../../api';
import { amber_amber, light_gray_3 } from '../../../style';
import { BaseForm, FormikWrapper } from '../../util/form2/BaseForm';
import { SubmitButtonsGroup } from '../../util/form2/SubmitButtonsGroup';
import { TextField } from '../../util/form2/TextField';
import { RecommendedOption } from '../RecommendationOptionValue';
import AerationRecommendationPlot from './AerationRecommendationPlot';
import { presentViewFromRanges, RunWindowKind } from './DailyForecastTable';
import { ExperimentCfmCalculations } from './ExperimentCfmCalculations';
import {
  ExperimentRecommendedScheduledRange,
  RecommendedScheduledRange,
} from './RecommendationScheduleBar';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '10px',
    border: 'dotted',
    borderWidth: '10px',
    paddingTop: '5px',
  },
  formContainer: {
    marginTop: '10px',
    paddingTop: '5px',
    marginBottom: '20px',
  },
  largeGrid: {
    margin: '0px auto',
    padding: 20,
    minWidth: 200,
    width: 570,
  },
  tooltip: { marginLeft: 15, verticalAlign: 'middle' },
  centered_row: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  experiment_cfm_calculations: {
    backgroundColor: light_gray_3,
    fontSize: 14,
    lineHeight: 1,
  },
}));

type ExperimentRunWindowsFormCompProps = {
  syncronizeCrossHairs: Function;
  setRecommendationChart: Function;
  minDate: Date;
  maxDate: Date;
  recommendation_windows_for_experiment: RecommRunWindowsExperiments;
  has_enable_fan_guidance: boolean;
  recommendation_type: string | null;
  fan_guidance_start_date: Date | null;
  fan_guidance_end_date: Date | null;
  loading?: boolean;
  grain_bin_location_timezone: string;
};

function produceAllExperimentRecommendedPeriods(
  recommendation_windows_for_experiment: RecommRunWindowsExperiments
): ExperimentRecommendedScheduledRange {
  const { name, experiment, type } = recommendation_windows_for_experiment;
  const recommended_schedule_range: RecommendedScheduledRange[] = [];
  let recommended_type;
  switch (type.toUpperCase()) {
    case RecommendedOption.DRYING: {
      const { emc_ranges } = recommendation_windows_for_experiment;
      if (emc_ranges) {
        const presented_run_windows = emc_ranges
          .filter((emc_range) => {
            return emc_range.emc_diff != null;
          })
          .map((value) => {
            return {
              // @ts-ignore
              start: value.start as number,
              // @ts-ignore
              end: value.end as number,
              type: RunWindowKind.Drying,
            };
          });
        recommended_schedule_range.push(...presentViewFromRanges(presented_run_windows));
        recommended_type = RecommendedOption.DRYING;
      }
      break;
    }
    case RecommendedOption.RECONDITIONING: {
      const { emc_reconditioning_ranges } = recommendation_windows_for_experiment;
      if (emc_reconditioning_ranges) {
        const presented_run_windows = emc_reconditioning_ranges
          .filter((emc_reconditioning_ranges) => {
            return emc_reconditioning_ranges.emc_diff != null;
          })
          .map((value) => {
            return {
              // @ts-ignore
              start: value.start as number,
              // @ts-ignore
              end: value.end as number,
              type: RunWindowKind.RECONDITIONING,
            };
          });
        recommended_schedule_range.push(...presentViewFromRanges(presented_run_windows));
        recommended_type = RecommendedOption.RECONDITIONING;
      }
      break;
    }
    case RecommendedOption.COOLING:
      const { temp_ranges } = recommendation_windows_for_experiment;
      if (temp_ranges) {
        const presented_run_windows = temp_ranges.map((value) => {
          return {
            // @ts-ignore
            start: value.start as number,
            // @ts-ignore
            end: value.end as number,
            type: RunWindowKind.Cooling,
          };
        });
        recommended_schedule_range.push(...presentViewFromRanges(presented_run_windows));
        recommended_type = RecommendedOption.COOLING;
      }
    default:
      break;
  }
  return {
    name,
    experiment,
    recommended_schedule_range,
    type: recommended_type ? recommended_type : null,
  };
}

export const ExperimentRunWindowsComp = ({
  syncronizeCrossHairs,
  setRecommendationChart,
  minDate,
  maxDate,
  recommendation_windows_for_experiment,
  has_enable_fan_guidance,
  recommendation_type,
  fan_guidance_start_date,
  fan_guidance_end_date,
  grain_bin_location_timezone,
}: ExperimentRunWindowsFormCompProps) => {
  const experimentPeriodsToPresent:
    | ExperimentRecommendedScheduledRange
    | undefined = recommendation_windows_for_experiment
    ? produceAllExperimentRecommendedPeriods(recommendation_windows_for_experiment)
    : undefined;
  return (
    <AerationRecommendationPlot
      has_enable_fan_guidance={has_enable_fan_guidance}
      scheduledRunsToPresent={[]}
      coolingPeriodsToPresent={[]}
      dryingPeriodsToPresent={[]}
      reconditioningPeriodsToPresent={[]}
      experimentPeriodsToPresent={experimentPeriodsToPresent}
      grain_bin_location_timezone={grain_bin_location_timezone}
      recommendation_type={recommendation_type}
      setRecommendationChart={setRecommendationChart}
      syncronizeCrossHairs={syncronizeCrossHairs}
      minDate={minDate}
      maxDate={maxDate}
      fan_guidance_start_date={fan_guidance_start_date}
      fan_guidance_end_date={fan_guidance_end_date}
      show_experiments={true}
    />
  );
};

export const validation_schema = yup.object().shape({
  cfm_scaling: yup
    .number()
    .positive()
    .min(0)
    .typeError('Cfm Scaling must >=0'),
  cfm_offset: yup
    .number()
    .positive()
    .min(0)
    .typeError('Cfm Offset must >=0'),
  cfm_min: yup
    .number()
    .positive()
    .min(0)
    .typeError('Cfm Min must >=0'),
  cfm_max: yup
    .number()
    .positive()
    .min(0)
    .typeError('Cfm Max must >=0'),
  cfm: yup
    .number()
    .positive()
    .min(0)
    .typeError('Cfm must >=0'),
});

type ExperimentRunWindowsFormInput = {
  cfm_scaling: string | number;
  cfm_offset: string | number;
  cfm_min: string | number;
  cfm_max: string | number;
  cfm?: string | number | null;
  weather_forecast_start_date?: Date | string;
  weather_forecast_end_date?: Date | string;
};

type ExperimentRunWindowsFormProps = {
  weather_forecast_start_date?: Date;
  weather_forecast_end_date?: Date;
  refetch_grain_bin_container?: () => void;
  refetch_experiment_run_windows?: (variables) => void;
  recommendation_type: string | null;
  setCfmValues?: (cfm_values) => void;
  experimentsRunWindowsFormValues?: {
    cfm_scaling: number | undefined;
    cfm_offset: number | undefined;
    cfm_min: number | undefined;
    cfm_max: number | undefined;
    cfm: number | undefined;
  };
  hasExperimentExpanded: boolean;
};

export const ExperimentRunWindowsForm = ({
  weather_forecast_start_date,
  weather_forecast_end_date,
  refetch_grain_bin_container,
  refetch_experiment_run_windows,
  recommendation_type,
  setCfmValues,
  experimentsRunWindowsFormValues,
  hasExperimentExpanded,
}: ExperimentRunWindowsFormProps) => {
  const classes = useStyles();
  let initialValues: ExperimentRunWindowsFormInput = {
    cfm_scaling: '',
    cfm_offset: '',
    cfm_min: '',
    cfm_max: '',
    cfm: '',
    weather_forecast_start_date: weather_forecast_start_date || '',
    weather_forecast_end_date: weather_forecast_end_date || '',
  };

  if (experimentsRunWindowsFormValues) {
    initialValues = {
      cfm_scaling:
        experimentsRunWindowsFormValues.cfm_scaling !== undefined
          ? experimentsRunWindowsFormValues.cfm_scaling
          : '',
      cfm_offset:
        experimentsRunWindowsFormValues.cfm_offset !== undefined
          ? experimentsRunWindowsFormValues.cfm_offset
          : '',
      cfm_min:
        experimentsRunWindowsFormValues.cfm_min !== undefined
          ? experimentsRunWindowsFormValues.cfm_min
          : '',
      cfm_max:
        experimentsRunWindowsFormValues.cfm_max !== undefined
          ? experimentsRunWindowsFormValues.cfm_max
          : '',
      cfm:
        experimentsRunWindowsFormValues.cfm !== undefined
          ? experimentsRunWindowsFormValues.cfm
          : '',
      weather_forecast_start_date: weather_forecast_start_date || '',
      weather_forecast_end_date: weather_forecast_end_date || '',
    };
  }

  const [expanded, setExpanded] = useState(hasExperimentExpanded ? -1 : null);

  const handleExpandChange = (index) => (isExpanded) => {
    setExpanded(isExpanded ? index : null);
  };
  const handleCloseForm = () => {
    setExpanded(null);
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    const { cfm_scaling, cfm_offset, cfm_min, cfm_max, cfm } = values;
    if (refetch_grain_bin_container && refetch_experiment_run_windows) {
      try {
        const [grain_bin_container, experiment_run_windows] = await Promise.all([
          refetch_grain_bin_container(),
          refetch_experiment_run_windows({
            recommendation_type,
            cfm_scaling: ![undefined, null].includes(cfm_scaling) ? cfm_scaling : undefined,
            cfm_offset: ![undefined, null].includes(cfm_offset) ? cfm_offset : undefined,
            cfm_min: ![undefined, null].includes(cfm_min) ? cfm_min : undefined,
            cfm_max: ![undefined, null].includes(cfm_max) ? cfm_max : undefined,
            cfm: ![undefined, null].includes(cfm) ? cfm : undefined,
          }),
        ]);
        setCfmValues && setCfmValues({ ...values });
        console.log('After submitting Run Windows', {
          grain_bin_container,
          experiment_run_windows,
        });
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
    return false;
  };
  const submitSuccessCallback = (hasRefetchExperimentRunWindows: boolean) => {
    // hasRefetchExperimentRunWindows && handleCloseForm();
  };

  return (
    <Grid item className={classes.formContainer}>
      <Button
        variant="outlined"
        onClick={() => handleExpandChange(-1)(expanded !== -1)}
        style={{ margin: '10px 0', width: 'fit-content', borderRadius: 4 }}
      >
        <AddCircle
          style={{
            color: amber_amber,
          }}
        />
        &nbsp; Run Experimental Run Windows
      </Button>

      <FormikWrapper<ExperimentRunWindowsFormInput, boolean>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSubmitSuccess={submitSuccessCallback}
        validationSchema={validation_schema}
        validateOnBlur={false}
        render={({ values }) => {
          const { cfm_max, cfm_min, cfm_offset, cfm_scaling, cfm } = values;
          return expanded === -1 ? (
            <BaseForm submitting_message={'Loading Run Windows...'} className={classes.largeGrid}>
              {recommendation_type && (
                <Grid
                  container
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  style={{ marginBottom: 20 }}
                >
                  <Grid item xs={12}>
                    <ExperimentCfmCalculations
                      recommendation_type={recommendation_type}
                      cfm_min={cfm_min}
                      cfm_max={cfm_max}
                      cfm_offset={cfm_offset}
                      cfm_scaling={cfm_scaling}
                      cfm={cfm}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Cfm Scaling"
                    name="cfm_scaling"
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Cfm Offset"
                    name="cfm_offset"
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Cfm Min"
                    name="cfm_min"
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Cfm Max"
                    name="cfm_max"
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Cfm"
                    name="cfm"
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>
                <Grid item xs={6} />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <SubmitButtonsGroup
                  onCancel={handleCloseForm}
                  cancelText="Cancel"
                  submitButtonText="Run Windows"
                />
              </Grid>
            </BaseForm>
          ) : null;
        }}
      />
    </Grid>
  );
};
