import { Hidden } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { amber_amber } from '../../../../core/src/style';
import { ViewerFragmentFragment } from '../../api';
import { SelectedState } from '../../reducer';
import { ContainerTypeLegacy } from '../../util';
import { Button } from '../util/form2/Button';
import { GrainBinMenu } from './GrainBinMenu';
import StoragePeriodSelect from './StoragePeriodSelect';

const selector = ({ selected }: { selected: SelectedState }) => selected;

export const DashboardMenu: FunctionComponent<{
  viewer: ViewerFragmentFragment;
  url_prefix: string;
  handleGrainMenuClose: any;
  anchorEl: null | HTMLElement;
}> = ({ viewer, url_prefix, anchorEl, handleGrainMenuClose }) => {
  const selected = useSelector(selector);
  const username = viewer ? viewer.user.first_name : '';
  const userEmail = viewer ? viewer.user.email_address : '';

  const showLeaveAccountButton = Boolean(localStorage.getItem('customer_care_token'));
  const handleLeavingLoggedInAccount = () => {
    localStorage.removeItem('customer_care_token');
    window.location.reload();
  };
  let settings_type = '';
  if (selected) {
    if (selected.container_type === ContainerTypeLegacy.bin) {
      settings_type = 'Grain Bin ';
    } else if (selected.container_type === ContainerTypeLegacy.barge) {
      settings_type = 'Barge ';
    } else if (selected.container_type === ContainerTypeLegacy.pile) {
      settings_type = 'Pile ';
    }
  }
  return (
    <>
      <Hidden mdUp>
        {selected.container_id && (
          <StoragePeriodSelect
            grain_bin_id={selected.container_id}
            viewer_role={viewer.user.role}
          />
        )}
        {showLeaveAccountButton && (
          <>
            <br />
            <Button
              size="small"
              variant="contained"
              style={{
                backgroundColor: amber_amber,
                color: '#fff',
              }}
              onClick={handleLeavingLoggedInAccount}
            >
              <div>
                <ExitToAppIcon style={{ marginRight: 5 }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  Leave <u>{username}'s</u> Account
                </div>
                <div>{`(${userEmail})`}</div>
              </div>
            </Button>
          </>
        )}
      </Hidden>
      <Hidden smDown>
        <GrainBinMenu
          viewer={viewer}
          url_prefix={url_prefix}
          anchorEl={anchorEl}
          handleGrainMenuClose={handleGrainMenuClose}
        />
      </Hidden>
    </>
  );
};
