import * as React from 'react';

const InternalErrorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 80 67.367"
    width={80}
    height={67.367}
    {...props}
  >
    <g fillRule="nonzero">
      <path
        fill="#D92D27"
        d="M76.963 67.367H3.034v-0.008c-2.3 0.005 -3.802 -2.512 -2.622 -4.55L36.642 1.506c1.148 -1.964 4.023 -2.039 5.222 -0.036l37.564 61.087c1.425 1.975 0.031 4.809 -2.466 4.809z"
      />
      <path
        fill="#fff"
        d="M6.43 62.442h67.481L39.486 7.356c-0.098 -0.155 -0.216 -0.153 -0.308 0L6.253 62.172c-0.098 0.175 -0.048 0.272 0.177 0.27z"
      />
      <path d="M37.997 38.528h3.978v3.175h-3.978v-3.175zm-12.328 16.063h-2.723v1.23h3.336v1.984h-6.059v-8.511h5.992l-0.341 1.983h-2.928v1.341h2.723v1.973zm9.087 3.214h-2.994l-1.117 -2.533h-0.781v2.533h-2.528v-8.511h4.289c1.952 0 2.928 0.994 2.928 2.981 0 1.363 -0.422 2.261 -1.267 2.697l1.47 2.833zm-4.892 -6.528v1.898h0.822c0.327 0 0.566 -0.036 0.714 -0.103 0.148 -0.067 0.225 -0.225 0.225 -0.469v-0.753c0 -0.245 -0.077 -0.403 -0.225 -0.47 -0.148 -0.067 -0.389 -0.103 -0.714 -0.103h-0.822zm13.164 6.528h-2.995l-1.117 -2.533h-0.78v2.533h-2.528v-8.511h4.289c1.952 0 2.928 0.994 2.928 2.981 0 1.363 -0.422 2.261 -1.267 2.697l1.47 2.833zm-4.892 -6.528v1.898h0.82c0.328 0 0.567 -0.036 0.716 -0.103 0.148 -0.067 0.225 -0.225 0.225 -0.469v-0.753c0 -0.245 -0.077 -0.403 -0.225 -0.47 -0.148 -0.067 -0.391 -0.103 -0.716 -0.103h-0.82zm5.334 2.28c0 -1.553 0.291 -2.684 0.872 -3.397 0.58 -0.714 1.63 -1.07 3.145 -1.07s2.564 0.356 3.145 1.07c0.581 0.712 0.872 1.844 0.872 3.397 0 0.772 -0.063 1.42 -0.184 1.947 -0.122 0.527 -0.334 0.984 -0.633 1.375 -0.3 0.391 -0.714 0.677 -1.239 0.858 -0.527 0.181 -1.18 0.272 -1.961 0.272 -0.78 0 -1.434 -0.091 -1.961 -0.272 -0.527 -0.181 -0.939 -0.467 -1.239 -0.858s-0.511 -0.848 -0.633 -1.375c-0.123 -0.527 -0.184 -1.175 -0.184 -1.947zm2.928 -1.417v3.541h1.13c0.372 0 0.642 -0.042 0.811 -0.13 0.167 -0.086 0.252 -0.283 0.252 -0.592v-3.541h-1.145c-0.362 0 -0.628 0.044 -0.795 0.13 -0.167 0.086 -0.252 0.283 -0.252 0.592zm13.378 5.666H56.781l-1.117 -2.533h-0.78v2.533h-2.528v-8.511h4.288c1.953 0 2.93 0.994 2.93 2.981 0 1.363 -0.423 2.261 -1.267 2.697l1.47 2.833zm-4.892 -6.528v1.898h0.82c0.328 0 0.567 -0.036 0.716 -0.103 0.148 -0.067 0.223 -0.225 0.223 -0.469v-0.753c0 -0.245 -0.075 -0.403 -0.223 -0.47 -0.148 -0.067 -0.391 -0.103 -0.716 -0.103h-0.82zm-12.909 -14.531h-3.978c-0.284 -3.483 -0.739 -4.103 -0.93 -6.372 -0.195 -2.283 -0.331 -5.559 2.927 -5.559 3.416 0 3.141 3.65 2.883 6.011 -0.214 1.945 -0.633 2.681 -0.902 5.92z" />
    </g>
  </svg>
);

export { InternalErrorIcon };
