import * as React from 'react';
export const AutomationDisabledIcon = (props) => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.06 21.06" {...props}>
    <defs>
      <style>{'.auto-dis-cls-1{fill:#fff;stroke:#000;stroke-miterlimit:10;}'}</style>
    </defs>
    <g id="Recond_no_Auto">
      <g id="Dial">
        <g id="DialText">
          <g id="Details">
            <g id="AutoOffIcon">
              <path
                className="auto-dis-cls-1"
                d="m2.21.79c-.39-.39-1.03-.39-1.42,0s-.39,1.03,0,1.42l18.06,18.06c.39.39,1.03.39,1.42,0,.39-.39.39-1.03,0-1.42l-5.27-5.27,4.18-3.26c.34-.26.47-.71.33-1.12-.14-.4-.52-.68-.95-.68h-5.11l1.8-6.76c.11-.42-.06-.87-.43-1.11-.37-.23-.85-.2-1.19.08l-6.19,5.28L2.21.79Z"
              />
              <path
                className="auto-dis-cls-1"
                d="m1.86,10.77l2.96-2.53,7.46,7.46-5.95,4.65c-.36.28-.86.28-1.22.01-.36-.27-.5-.75-.33-1.17l2.66-6.65H2.51c-.42,0-.8-.26-.94-.66-.15-.39-.03-.84.29-1.11h0Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
