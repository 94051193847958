import { AmberHoc, AmberHocProps } from '../../../util';
import {
  SetFanControllerStateMutation,
  SetFanControllerStateMutationVariables as Variables,
  withSetFanControllerState as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithSetFanControllerStateHocChildProps = {
  setFanControllerState: (
    variables: Variables
  ) => Promise<SetFanControllerStateMutation['setFanControllerState']>;
};
export const withSetFanControllerStateHoc: AmberHoc<{}, WithSetFanControllerStateHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<{}, WithSetFanControllerStateHocChildProps, typeof component>,
    WithSetFanControllerStateHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        setFanControllerState: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({ variables });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.setFanControllerState;
        },
      };
    },
  })(component as any);
