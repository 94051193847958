import axios from 'axios';

const unsubscribeWeeklyGrainEmailsApi = (email: string, user_id: number) => {
  return axios.put(`${window['app_config'].svc_emails_rest_api_host_name}/unsubscribeWeeklyEmail`, {
    email,
    user_id,
  });
};

export { unsubscribeWeeklyGrainEmailsApi };
