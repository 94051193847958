import { Button, FormControl, MenuItem } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { getMinutes } from 'date-fns';
import addDays from 'date-fns/add_days';
import addHours from 'date-fns/add_hours';
import addMinutes from 'date-fns/add_minutes';
import format from 'date-fns/format';
import startOfDay from 'date-fns/start_of_day';
import startOfHour from 'date-fns/start_of_hour';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { GetProps, useDispatch } from 'react-redux';
import * as yup from 'yup';

import { pollFanControllerState } from '../../../../action';
import {
  FanSettingsFragmentFragment,
  withGetGrainBinFanSettingsHoc,
  withSetGrainBinFanSettingsHoc,
  WithSetGrainBinFanSettingsHocChildProps,
} from '../../../../api';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../../../util/form2/BaseForm';
import { ButtonSubmit } from '../../../util/form2/Button';
import { ErrorBox } from '../../../util/form2/ErrorBox';
import { Select } from '../../../util/form2/Select';
import { Slider } from '../../../util/form2/Slider';

// const violation_msg = 'Bad weather is currently in effect.  Disable weather safety to override.';
const date_ix_array = [0, 1, 2, 3, 4, 5, 6];
const hour_array = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
];

const min_array = [0, 15, 30, 45];

const useStyles = makeStyles({
  header: { marginBottom: 0, marginTop: 0, width: '100%' },
  header_start: { marginBottom: 0, marginTop: 10, width: '100%' },
  double_field: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
  },
  menu_item: { textAlign: 'left' },
  menu_item_sub: { textAlign: 'left', paddingLeft: 20 },
  picker: { display: 'inline-block', width: '95%', minWidth: 100, maxWidth: 125 },
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
    maxWidth: 300,
    alignItems: 'center',
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
    width: '100%',
  },
  safeties_list: { maxWidth: 250, width: '100%', marginTop: 0, marginBottom: 0, padding: 0 },
  safeties_list_item: { paddingTop: 0, paddingRight: 0, paddingLeft: 36, paddingBottom: 0 },
  slider: {
    maxWidth: 250,
    width: '100%',
  },
  slider_inner: { marginTop: 0, marginBottom: 0 },
  dialog: {
    minHeight: 500,
    minWidth: 250,
    maxWidth: 500,
  },
  time_label: {
    // verticalAlign: 'bottom',
    paddingRight: 10,
  },
  start_date_row: {
    display: 'flex',
    paddingTop: 10,
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  safeties_row: {
    maxWidth: 250,
  },
  green_button: { color: green['500'], margin: '0.5em' },
  red_button: { color: red['500'], margin: '0.5em' },
  green_header: { color: green['500'], paddingRight: 4, fontWeight: 'bold' },
  red_header: { color: red['500'], paddingRight: 4, fontWeight: 'bold' },
});

type Values = {
  grain_bin_id: number;
  start_epoch: Date | null;
  start_date: Date | null;
  start_hour: Date | null;
  duration: number;
  safety_weather: boolean;
  run_later: boolean;
  stop: boolean;
};
const validationSchema = yup.object().shape({
  grain_bin_id: yup
    .number()
    .typeError('A number is required')
    .label('Grain Bin')
    .integer()
    .required(),
  start_epoch: yup
    .date()
    .label('Start')
    .when(['stop', 'run_later'], (stop, run_later, schema) =>
      !stop && run_later ? schema.required().nullable(false) : schema.nullable(true)
    ),
  safety_weather: yup.boolean().label('WeatherGuard'),
  stop: yup.boolean().required(),
  duration: yup
    .number()
    .typeError('A number is required')
    .label('Duration')
    .required(),
  run_later: yup.boolean().required(),
});

const getNextStart = (as_of) => {
  const mins = getMinutes(as_of);
  const next_start_mins = (Math.floor(mins / 15) + 1) * 15;
  return addMinutes(startOfHour(as_of), next_start_mins);
};

const Form = () => {
  const classes = useStyles();
  const {
    isSubmitting,
    setFieldValue,
    submitForm,
    values: { start_date, start_hour, duration, run_later, stop },
  } = useFormikContext<Values>();
  // const safety_violation = false;
  // if (!loading && safety_weather && grain_bin_telemetry && grain_bin_telemetry.weather) {
  //   const { humidity_state } = grain_bin_telemetry.weather;
  //   // TODO: Get this added back into grain bin telemetry query
  //   const aeration_state = RangeState.ok;
  //   if (humidity_state === RangeState.bad || aeration_state === RangeState.ok) {
  //     safety_violation = true;
  //   }
  // }
  useEffect(() => {
    setFieldValue('start_hour', null);
  }, [start_date]);
  useEffect(() => {
    setFieldValue('start_epoch', null);
  }, [start_hour]);
  useEffect(() => {
    stop && submitForm();
  }, [stop]);
  const stopFanCallback = useCallback(() => setFieldValue('stop', true), [setFieldValue]);
  const first_custom_option_dt = getNextStart(new Date());
  const first_dt = startOfDay(first_custom_option_dt);
  const start_date_hour = start_date ? start_date.getHours() : 0;
  const start_hour_min = start_hour ? start_hour.getMinutes() : 0;
  return (
    <BaseForm submitting_message="Updating Run Schedule...">
      {run_later && <h3 className={classes.header_start}>Start</h3>}
      {run_later && (
        <div className={classes.start_date_row}>
          <Select
            name="start_date"
            label="Day"
            style={{ minWidth: 80 }}
            renderValue={(val: Date | null) => (val ? format(val, 'ddd') : '')}
          >
            {date_ix_array.map((ix) => {
              const dt = ix === 0 ? first_custom_option_dt : addDays(first_dt, ix);
              return (
                <MenuItem key={ix} value={(dt as unknown) as string}>
                  {ix === 0
                    ? `Today (${format(dt, 'YYYY-MM-DD')})`
                    : `${format(dt, 'ddd')} (${format(dt, 'YYYY-MM-DD')})`}
                </MenuItem>
              );
            })}
          </Select>
          <Select
            name="start_hour"
            label="Hour"
            style={{ paddingLeft: 4, minWidth: 40 }}
            disabled={start_date === null}
            renderValue={(val: Date | null) => (val ? format(val, 'hh') : '')}
          >
            {start_date
              ? hour_array.slice(start_date_hour).map((hour, ix) => {
                  const dt =
                    ix === 0
                      ? start_date
                      : addHours(startOfHour(start_date), hour - start_date_hour);
                  return (
                    <MenuItem key={hour} value={(dt as unknown) as string}>
                      {format(dt, 'h A')}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
          <span className={classes.time_label}>:</span>
          <Select
            name="start_epoch"
            label="Minute"
            style={{ minWidth: 56 }}
            disabled={!start_hour}
            renderValue={(val: Date | null) => (val ? format(val, 'mm') : '')}
          >
            {start_hour
              ? min_array.slice(Math.floor(start_hour_min / 15)).map((ix) => {
                  const dt = addMinutes(start_hour, ix - start_hour_min);
                  return (
                    <MenuItem key={ix} value={(dt as unknown) as string}>
                      {format(dt, 'mm')}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
          {start_hour && <span>{format(start_hour, 'A')}</span>}
        </div>
      )}
      <br />
      <h3 className={classes.header}>Run Time - {`${duration} Hours`}</h3>
      <div className={classes.double_field}>
        <FormControl fullWidth>
          <Slider name="duration" min={1} max={36} step={1} />
        </FormControl>
      </div>
      {/*<div className={classes.safeties_row}>*/}
      {/*<FormControlLabel*/}
      {/*control={<Field name="safety_weather" component={Checkbox} />}*/}
      {/*label={*/}
      {/*<p className={classes.safeties_row}>*/}
      {/*<span className={safety_weather ? classes.green_header : classes.red_header}>*/}
      {/*WeatherGuard - {safety_weather ? 'ON' : 'OFF'}*/}
      {/*</span>*/}
      {/*<br />*/}
      {/*Automatically cancel schedule in high moisture conditions*/}
      {/*</p>*/}
      {/*}*/}
      {/*/>*/}
      {/*</div>*/}
      <ErrorBox withoutGrid />
      <div>
        <Button
          variant="outlined"
          onClick={stopFanCallback}
          disabled={isSubmitting}
          classes={{ root: classes.red_button }}
        >
          STOP FAN / CLEAR SCHEDULE
        </Button>
        <ButtonSubmit allow_pristine classes={{ root: classes.green_button }}>
          SUBMIT
        </ButtonSubmit>
      </div>
    </BaseForm>
  );
};

export const AerationFormBase = ({
  grain_bin_id,
  setGrainBinFanSettings,
  onSubmitSuccess,
  run_later,
  ...props
}: WithSetGrainBinFanSettingsHocChildProps &
  FormikWrapperHandlerProps<Values, FanSettingsFragmentFragment> & {
    grain_bin_id: number;
    run_later: boolean;
  }) => {
  const submitCallback = useCallback(
    async (values, { setFieldValue }) => {
      if (values.stop) {
        try {
          return await setGrainBinFanSettings({
            grain_bin_id: values.grain_bin_id,
            safety_weather: values.safety_weather,
            clear_run_window: true,
          });
        } finally {
          setFieldValue('stop', false);
        }
      }
      const {
        grain_bin_id,
        start_epoch,
        safety_weather,
        duration,
        run_later,
      } = validationSchema.validateSync(values);
      return setGrainBinFanSettings({
        grain_bin_id,
        safety_weather,
        run_window: {
          start_epoch: run_later ? start_epoch : new Date(),
          end_epoch: addHours(run_later ? start_epoch : new Date(), duration),
        },
      });
    },
    [setGrainBinFanSettings]
  );
  return (
    <FormikWrapper<Values, FanSettingsFragmentFragment>
      {...props}
      initialValues={{
        grain_bin_id,
        run_later,
        start_epoch: null,
        start_date: null,
        start_hour: null,
        duration: 8,
        safety_weather: false,
        stop: false,
      }}
      onSubmit={submitCallback}
    >
      <Form />
    </FormikWrapper>
  );
};

export const AerationForm = withGetGrainBinFanSettingsHoc(
  withSetGrainBinFanSettingsHoc(
    ({ onSubmitSuccess, ...props }: GetProps<typeof AerationFormBase>) => {
      const dispatch = useDispatch();
      const submitSuccessCallback = useCallback(
        (result, formikHelpers) => {
          // ?commented the below code as AerationForm component is not used anywhere
          // result.grain_bin.fan_controllers.forEach(
          //   ({ fan_controller: { fan_controller_id_next } }) =>
          //     dispatch(pollFanControllerState({ fan_controller_id: fan_controller_id_next }))
          // );
          return onSubmitSuccess && onSubmitSuccess(result, formikHelpers);
        },
        [dispatch]
      );
      return <AerationFormBase {...props} onSubmitSuccess={submitSuccessCallback} />;
    }
  )
);
