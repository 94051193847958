import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetViewerDocument,
  GetViewerQuery,
  GetViewerQueryVariables,
  UpdateUserVerificationMutationVariables as Variables,
  ViewerFragmentFragment as Result,
  withUpdateUserVerification as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUpdateUserVerificationHocChildProps = {
  updateUserVerification: (variables: Variables) => Promise<Result>;
};
export const withUpdateUserVerificationHoc: AmberHoc<
  {},
  WithUpdateUserVerificationHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithUpdateUserVerificationHocChildProps, typeof component>,
    WithUpdateUserVerificationHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updateUserVerification: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { updateUserVerification: result } = data;
              if (!result) {
                return;
              }
              proxy.writeQuery<GetViewerQuery, GetViewerQueryVariables>({
                query: GetViewerDocument,
                data: { __typename: 'Query', viewer: result },
                variables: { user_id: result.user_id },
              });
              (window as any).localStorage.setItem('token', result.token);
              (window as any).localStorage.setItem('email_address', result.user.email_address);
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.updateUserVerification;
        },
      };
    },
  })(component as any);
