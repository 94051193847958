import * as React from 'react';
const StartIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.90497 15.39C12.045 15.39 15.395 12.03 15.395 7.90002C15.395 3.77002 12.035 0.409912 7.90497 0.409912C3.77497 0.409912 0.414978 3.77002 0.414978 7.90002C0.414978 12.03 3.77497 15.39 7.90497 15.39ZM14.035 7.90002C14.035 4.51002 11.285 1.77002 7.90497 1.77002C4.52497 1.77002 1.77496 4.52002 1.77496 7.90002C1.77496 11.28 4.52497 14.03 7.90497 14.03C11.285 14.03 14.035 11.28 14.035 7.90002ZM6.19495 4.82996L10.965 7.90002L6.19495 10.97V4.83997V4.82996Z"
      fill="#24B47E"
    />
  </svg>
);
export { StartIcon };
