import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {GrainBinCfmSolutionOutput, withGetGrainBinCfmSolution as HOC} from "../__generated";

export type WithGetGrainBinCfmSolutionHocOwnProps = {
    grain_bin_id: number,
    loading?: boolean;
    onError?: (error: ApolloError) => void;
};
export type WithGetGrainBinCfmSolutionHocChildProps = {
    get_grain_bin_cfm_solution_loading: boolean;
    grain_bin_cfm_solution: GrainBinCfmSolutionOutput | any;
    refetch_grain_bin_cfm_solution: () => Promise<any>;
};
export const withGetGrainBinCfmSolutionHoc: AmberHoc<
    WithGetGrainBinCfmSolutionHocOwnProps,
    WithGetGrainBinCfmSolutionHocChildProps
    > = (component) =>
    HOC<
        AmberHocProps<
            WithGetGrainBinCfmSolutionHocOwnProps,
            WithGetGrainBinCfmSolutionHocChildProps,
            typeof component
            >,
        WithGetGrainBinCfmSolutionHocChildProps
        >({
        options: ({ grain_bin_id, onError }) => ({
            onError,
            errorPolicy: onError ? 'all' : 'none',
            fetchPolicy: 'network-only',
            variables: { grain_bin_id },
        }),
        props: ({ data, ownProps: { loading } }) => {
            if (!data || data.loading) {
                return {
                    get_grain_bin_cfm_solution_loading: true,
                    refetch_grain_bin_cfm_solution: () => Promise.resolve(),
                    grain_bin_cfm_solution: null,
                };
            }

            return {
                get_grain_bin_cfm_solution_loading: loading || false,
                refetch_grain_bin_cfm_solution: data.refetch,
                grain_bin_cfm_solution: data.grain_bin_cfm_solution || null,
            };
        },
    })(component as any);
