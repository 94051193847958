import { Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useCallback, useState } from 'react';

import { GrainBinFanControllerFragmentFragment } from '../../../../api';
import { Button } from '../../../util/form2/Button';
import { UpdateGrainBinFanControllerDialog } from '../UpdateGrainBinFanControllerDialog';

const useStyles = makeStyles({
  cell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cell_header: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  root: {
    minWidth: 320,
  },
});

export const MissingFanPowerInfo: FunctionComponent<{
  fan_controllers: GrainBinFanControllerFragmentFragment[];
}> = ({ fan_controllers }) => {
  const classes = useStyles();

  const [selected_gbfc, setSelectedGBFC] = useState<GrainBinFanControllerFragmentFragment | null>(
    null
  );
  const closeModal = useCallback(() => setSelectedGBFC(null), [setSelectedGBFC]);

  return (
    <>
      <Grid
        container
        direction="column"
        justify="center"
        classes={{ container: classes.root }}
        spacing={2}
      >
        <Grid item container alignItems="center">
          <Grid item xs={12} className={classes.cell_header}>
            Device(s) Missing Power-Related Fields (Phase, Power Factor, & Voltage)
          </Grid>
        </Grid>
        {fan_controllers.map((device) => {
          const {
            alias,
            fan_controller: { alias: device_alias },
            fan_controller_id_next,
          } = device;
          return (
            <Grid item container alignItems="center" key={fan_controller_id_next}>
              <Grid item xs={6} className={classes.cell}>
                {alias || device_alias}
              </Grid>
              <Grid item xs={6} className={classes.cell}>
                <Button
                  onClick={() => {
                    setSelectedGBFC((device as unknown) as GrainBinFanControllerFragmentFragment);
                  }}
                  style={{ margin: 0, maxWidth: 250 }}
                  fullWidth
                >
                  <Edit /> Add Missing Info
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {selected_gbfc && <UpdateGrainBinFanControllerDialog
        open={Boolean(selected_gbfc)}
        grain_bin_fan_controller={selected_gbfc}
        fan_controller_id={selected_gbfc.fan_controller_id_next}
        onClose={closeModal}
      />}
    </>
  );
};
