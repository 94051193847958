import { AmberHoc, AmberHocProps } from '../../../util';
import {
  CreateCustomerCareTokenMutationVariables as Variables,
  Token as Result,
  withCreateCustomerCareToken as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithCreateCustomerCareTokenHocChildProps = {
  createCustomerCareToken: (variables: Variables) => Promise<Result>;
};

export const withCreateCustomerCareTokenHoc: AmberHoc<
  {},
  WithCreateCustomerCareTokenHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithCreateCustomerCareTokenHocChildProps, typeof component>,
    WithCreateCustomerCareTokenHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        createCustomerCareToken: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data || !data.createCustomerCareToken) {
            throw new Error('Unexpected server response');
          }
          return data.createCustomerCareToken;
        },
      };
    },
  })(component as any);
