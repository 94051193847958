import { createAction } from 'typesafe-actions';
import { ContainerTypeLegacy } from '../util/constant';

// pinging and polling used on action
export const pollFanControllerState = createAction('POLL/FAN_CONTROLLER_STATE', (resolve) => {
  return (payload: {
    fan_controller_id: number;
    container_id: number;
    container_type: ContainerTypeLegacy;
    storage_period?: {
      grain_bin_storage_cycle_id: number;
    } | null;
  }) => resolve(payload);
});

// intermittent background poll to keep the ui relatively fresh
export const pollBackgroundFanControllerState = createAction(
  'POLL/BACKGROUND_FAN_CONTROLLER_STATE',
  (resolve) => {
    return (payload: { fan_controller_ids: number[] | null }) => resolve(payload);
  }
);
