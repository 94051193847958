export default (value?: string, precision?: number): null | number => {
  if (!value) {
    return null;
  }
  const value_float = parseFloat(value);
  if (isNaN(value_float)) {
    return null;
  }
  if (precision === undefined) {
    return value_float;
  }
  const exp = 10 ** precision;
  return Math.round(parseFloat(value) * exp) / exp;
};
