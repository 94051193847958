import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import {
  DomainTuple,
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  // voronoi not in the defined types for some reason
  // @ts-ignore
  VictoryVoronoiContainer,
} from 'victory';

import { DateTime } from 'luxon';
import {
  GetGrainContainerHistoryQuery,
  withGetGrainContainerHistoryBinSummaryHoc,
  WithGetGrainContainerHistoryHocChildProps,
  withGetGrainContainerHistoryPelletsHoc,
} from '../../../api';
import { barge_cover_color_set, getColorForLine, victory_theme } from '../../../style';
import {
  ContainerTypeLegacy,
  formatNumber,
  formatPercent,
  normalizeCoverNumberShort,
  RelativeTimePeriod,
  Statistic,
} from '../../../util';
import { CenteredSpinner } from '../../spinner';
import { getDomain, getTickValues, HistoryRecord } from '../summary-card/charts';

const DAYS = ['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su'];
const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const formatTime = {
  [RelativeTimePeriod.week]: (d) => DAYS[d.weekday - 1],
  [RelativeTimePeriod.month]: (d) => `${MONTHS[d.month - 1].substring(0, 3)} ${d.day}`,
  [RelativeTimePeriod.quarter]: (d) => MONTHS[d.month - 1],
};

const TICK_FORMATTER = {
  [Statistic.emc]: (y) => formatPercent(y, 1),
  [Statistic.humidity_rh]: (y) => formatPercent(y),
  [Statistic.temp_f]: (y) => `${formatNumber(y, 0)} F`,
};

const styles = {
  axis1: { ticks: { stroke: 'transparent' } },
  axis2: { grid: { stroke: 'none' } },
};

const useStyles = makeStyles({
  spinner: {
    height: 215,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const getVoronaiContainer = (
  statistic: Statistic,
  grain_container: GetGrainContainerHistoryQuery['grain_container'] | null,
  pellet_id_cover_map: any
) => {
  if (!grain_container) {
    return {};
  }
  const { telemetry_history, container_type } = grain_container;
  if (container_type !== ContainerTypeLegacy.barge || !telemetry_history) {
    return {};
  }
  const { pellets } = telemetry_history;
  // todo come up with solution for pile cable amount and colors
  // only 19 colors, so labels will be wrong with any more data than that
  if (!pellets || pellets.length > 19) {
    return {};
  }
  return !pellets
    ? {}
    : {
        containerComponent: (
          <VictoryVoronoiContainer
            labels={(d) => {
              const pellet =
                telemetry_history.pellets[
                  barge_cover_color_set.findIndex((c) => c === d.style.data.stroke)
                ];
              const value = TICK_FORMATTER[statistic](d.y);
              if (!pellet || !pellet_id_cover_map[pellet.pellet_id]) {
                return `${value}`;
              }
              return `Cover ${normalizeCoverNumberShort(
                pellet_id_cover_map[pellet.pellet_id].cover_no
              )}, ${value}`;
            }}
          />
        ),
      };
};

const getHistories = (
  grain_container: GetGrainContainerHistoryQuery['grain_container'] | null,
  source: string[],
  tier_count: number,
  pellet_id_cover_map
) => {
  const histories: { records: HistoryRecord[]; color: string }[] = [];
  if (grain_container && grain_container.telemetry_history) {
    if (grain_container.container_type === ContainerTypeLegacy.bin) {
      if (source.includes('headspace')) {
        histories.push({
          records: grain_container.telemetry_history.headspace,
          color: getColorForLine(tier_count + 1, tier_count),
        });
      }
      const { tiers } = grain_container.telemetry_history;
      if (tiers) {
        histories.push(
          ...source
            .filter((src) => src !== 'headspace')
            .map((tier_name) => {
              const tier_index = parseInt(tier_name.split('_')[1], 10);
              return {
                records: tiers[tier_index],
                color: getColorForLine(tier_count + 1, tier_index),
              };
            })
        );
      }
    } else {
      const { pellets } = grain_container.telemetry_history;
      if (pellets) {
        histories.push(
          ...source.map((pellet_name) => {
            const pellet_index =
              pellets.findIndex((p) => p.pellet_id === pellet_name.split('_')[1], 10) || 0;
            if (pellets[pellet_index] && pellet_id_cover_map[pellets[pellet_index].pellet_id]) {
              return {
                color: pellet_id_cover_map[pellets[pellet_index].pellet_id].color,
                records: pellets[pellet_index].data.map((d) => ({
                  ...d,
                  epoch_time: new Date(d.epoch_time),
                })),
              };
            }
            return { records: [], color: null };
          })
        );
      }
    }
  }

  return histories;
};

const timeRangeReducer = ([start, end], history) => {
  // use comparison since type is either date OR number
  return [
    history.epoch_time && history.epoch_time.getTime() < start ? history.epoch_time : start,
    history.epoch_time && history.epoch_time.getTime() > end ? history.epoch_time : end,
  ] as [number, number];
};

const getDomainY = (
  histories: { records: HistoryRecord[]; color: string }[],
  statistic
): DomainTuple => {
  const domain_histories = histories.filter((h) => h.records && h.records.length);
  return domain_histories.length
    ? domain_histories
        .map((history) => getDomain(history.records, statistic))
        .reduce(
          ([start, end], domain) => {
            // return tuple of widest range
            // use comparison since type is either date OR number
            return [domain[0] < start ? domain[0] : start, domain[1] > end ? domain[1] : end] as
              | [number, number]
              | [Date, Date];
          },
          [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER] // number comparison works for both numbers and dates
        )
    : getDomain([], statistic);
};

const getDomainX = (
  histories: { records: HistoryRecord[]; color: string }[],
  period: RelativeTimePeriod
) => {
  const today = DateTime.local();
  today.set({ hour: 12 });

  const tick_values = getTickValues(period, today.year, today.month, today.day);

  const [min_history_timestamp, max_history_timestamp]: [number, number] = histories
    .map((h) =>
      h.records.reduce(timeRangeReducer, [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER])
    )
    .reduce(
      ([start, end], timestamps: [number, number]): [number, number] => {
        return [Math.min(timestamps[0], start), Math.max(timestamps[1], end)];
      },
      [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER]
    );
  const x_min = Math.min(min_history_timestamp, tick_values[0].toMillis());
  const x_max = Math.max(max_history_timestamp, tick_values[tick_values.length - 1].toMillis());

  return { tick_values, x_min, x_max };
};

type TelemetryHistoryPlotProps = {
  period: RelativeTimePeriod;
  height: number;
  width: number;
  source: string[];
  statistic: Statistic;
  tier_count: number;
  pellet_id_cover_map?: any;
  show_temp_threshold?: boolean;
};
const TelemetryHistoryPlotInner: React.FunctionComponent<
  WithGetGrainContainerHistoryHocChildProps & TelemetryHistoryPlotProps
> = ({
  period,
  source,
  grain_container,
  statistic,
  height,
  tier_count,

  width,
  loading,
  pellet_id_cover_map,
  show_temp_threshold,
}) => {
  const classes = useStyles();
  const histories = useMemo(
    () => getHistories(grain_container, source, tier_count, pellet_id_cover_map),
    [grain_container, source, tier_count, pellet_id_cover_map]
  );

  const { tick_values, x_min, x_max } = useMemo(() => getDomainX(histories, period), [
    histories,
    period,
  ]);
  const domain = useMemo(() => getDomainY(histories, statistic), [histories, statistic]);
  const chart_props = useMemo(
    () => getVoronaiContainer(statistic, grain_container, pellet_id_cover_map),
    [statistic, grain_container, pellet_id_cover_map]
  );
  const history_lines = useMemo(
    () =>
      histories.map((history, ix) => {
        const data = history.records
          .map((row) => {
            if (row[statistic]) {
              return { x: row.epoch_time, y: row[statistic] };
            }
            return null;
          })
          .filter((datum) => datum !== null);
        return data.length > 0 ? (
          <VictoryLine key={ix} data={data} style={{ data: { stroke: history.color } }} />
        ) : null;
      }),
    [histories, statistic]
  );
  const today = new Date();
  today.setHours(12);

  const [, max] = domain;
  return loading ? (
    <CenteredSpinner className={classes.spinner} fadeIn="none" />
  ) : (
    <div style={{ margin: 10 }}>
      <svg viewBox={`0 0 ${width} ${height}`}>
        <VictoryChart
          standalone={false}
          // animate={{ duration: 500 }}
          theme={victory_theme}
          height={height}
          width={width}
          padding={{ left: 40, bottom: 30, right: 16, top: 16 }}
          {...chart_props}
        >
          <VictoryAxis
            style={styles.axis1}
            tickFormat={formatTime[period]}
            tickValues={tick_values}
          />
          <VictoryAxis
            dependentAxis
            crossAxis={false}
            tickFormat={TICK_FORMATTER[statistic]}
            style={styles.axis2}
            domain={domain}
          />
          {/* ranges not yet supported for multiple lines {getRanges({ ranges, history, tick_values, domain })} */}
          {show_temp_threshold && statistic === Statistic.temp_f && (
            <VictoryArea
              style={{ data: { fill: 'rgba(255, 0, 0, .3)' } }}
              data={[
                {
                  x: x_min,
                  y: Math.max(max as number, 120),
                  y0: 110, // todo make temp threshold value dynamic?
                },
                {
                  x: x_max,
                  y: Math.max(max as number, 120),
                  y0: 110,
                },
              ]}
            />
          )}
          {history_lines}
        </VictoryChart>
      </svg>
    </div>
  );
};

export const BinSummaryTelemetryHistoryPlot = withGetGrainContainerHistoryBinSummaryHoc(
  TelemetryHistoryPlotInner
);
export const PelletsTelemetryHistoryPlot = withGetGrainContainerHistoryPelletsHoc(
  TelemetryHistoryPlotInner
);
