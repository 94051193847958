const win = window as any;

let seed = 0;
const handlers: {
  [k: string]: { resolve: (data: any) => void; reject: (error: string) => void };
} = {};

export const callIos = async (params: any) => {
  if (!win.webkit) {
    return;
  }

  try {
    const js_call_id = `m${seed}`;
    seed += 1;
    return new Promise((resolve, reject) => {
      handlers[js_call_id] = { resolve, reject };
      win.webkit.messageHandlers.js_call.postMessage({ js_call_id, params });
    });
  } catch (e) {
    // todo come up with more elegant solution
    // the actual error came from below, but adding a try/catch here as well as a precaution
  }
};

export const notifyIos = (params: any) => {
  if (!win.webkit) {
    return;
  }
  try {
    win.webkit.messageHandlers.js_notify.postMessage({ params });
  } catch (e) {
    // todo come up with more elegant solution
  }
};

type JsCallResponse = {
  js_call_id: string;
  data?: any;
  error?: any;
};

export const initIos = (onIosMessage: (params: any) => void) => {
  win.onIosMessage = (params: any) => {
    if (params.js_call_id !== undefined) {
      const { data, error, js_call_id } = <JsCallResponse>params;
      const handler = handlers[js_call_id];
      if (!handler) {
        const handler = handlers[js_call_id];
        if (!handler) {
          return;
        }
        if (error) {
          handler.reject(error);
        } else {
          handler.resolve(data);
        }
        delete handler[js_call_id];
      }
    } else {
      onIosMessage(params);
    }
  };
};
