import React from 'react';

import { BargeCoverPelletLinkFragmentFragment, GetBargeContainerQuery } from '../../../api';
import { isValidCoverNumber, normalizeCoverNumber } from '../../../util';
import { FlexSummaryCard } from '../summary-card';
import { BargeTemperatureDiagram } from './BargeTemperatureDiagram';

const getGroupedCoverNumber = (cover_no: number) => {
  return Number(cover_no.toString().substring(0, 2));
};

const isLowPellet = (cover_no: number) => {
  const cover_str = cover_no.toString();
  return cover_str.length === 3 && cover_str[2] === '0';
};

export const BargeTemperatureCard = ({
  barge_cover_pellet_links,
  grain_container,
}: {
  grain_container: GetBargeContainerQuery['grain_container'];
  barge_cover_pellet_links: BargeCoverPelletLinkFragmentFragment[];
}) => {
  const {
    telemetry: { pellets },
  } = grain_container;
  if (pellets.length === 0) {
    return null;
  }

  const [temp_map, temp_changes] = barge_cover_pellet_links
    .filter(({ cover_no }) => isLowPellet(cover_no))
    .reduce(
      (acc, next) => {
        const pellet = pellets.find((p) => p.pellet_id === next.pellet_id);
        if (pellet) {
          // first two numbers are cover number and port/starboard
          const key = next.cover_no.toString().slice(0, 2);
          acc[0][key] = pellet.latest.temp_f;
          acc[1][key] = pellet.dod_temp_diff_f;
        }
        return acc;
      },
      [[], []]
    );

  // const assigned_group = barge_cover_pellet_links
  //   .filter(({ cover_no }) => isValidCoverNumber(cover_no))
  //   .reduce((acc, next) => {
  //     // first two numbers are cover number and port/starboard
  //     const key = next.cover_no.toString().slice(0, 2);
  //     (acc[key] = acc[key] || []).push(next);
  //     return acc;
  //   }, {});

  // if (Object.keys(assigned_group).length === 0) {
  //   return null;
  // }
  // const temp_changes = {};
  // const temp_map = {};
  // (Object.values(assigned_group) as BargeCoverPelletLinkFragmentFragment[][])
  //   .filter((grp) => grp.length)
  //   .forEach((group_pellets) => {
  //     const { cover_no } = group_pellets[0];
  //     const grouped_cover_no = getGroupedCoverNumber(cover_no);
  //     const assigned_pellets = pellets.filter((a_pellet) =>
  //       group_pellets.find(({ pellet_id }) => pellet_id === a_pellet.pellet_id)
  //     );
  //     assigned_pellets.forEach((pellet) => {
  //       if (
  //         pellet &&
  //         pellet.latest &&
  //         pellet.latest.temp_f &&
  //         (temp_map[grouped_cover_no] === null ||
  //           pellet.latest.temp_f > temp_map[grouped_cover_no] ||
  //           -100)
  //       ) {
  //         temp_map[grouped_cover_no] = pellet.latest.temp_f;
  //         temp_changes[grouped_cover_no] = pellet.dod_temp_diff_f;
  //       }
  //     });
  //   });
  return (
    <>
      <FlexSummaryCard title="Inspection Door Temperatures (F)">
        <BargeTemperatureDiagram temperatures={temp_map} temp_changes={temp_changes} />
      </FlexSummaryCard>
    </>
  );
};
