import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetContainersGrainLevelQuery as Result,
  withGetContainersGrainLevel as HOC,
} from '../__generated';

export type WithGetContainersGrainLevelHocProps = {
  grain_bin_ids: number[];
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetContainersGrainLevelHocChildProps = {
  containers_grain_level_loading: boolean;
  containers_grain_level: Result['containers_grain_level'];
  containersGrainLevelRefetch: () => Promise<any>;
};
export const withGetContainersGrainLevelHoc: AmberHoc<
  WithGetContainersGrainLevelHocProps,
  WithGetContainersGrainLevelHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetContainersGrainLevelHocProps,
      WithGetContainersGrainLevelHocChildProps,
      typeof component
    >,
    WithGetContainersGrainLevelHocChildProps
  >({
    options: ({ grain_bin_ids, onError }) => ({
      onError,
      variables: { grain_bin_ids },
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      context: { important: true }, // flag to prevent from graphql batching
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          containers_grain_level_loading: true,
          containers_grain_level: [] as Result['containers_grain_level'],
          containersGrainLevelRefetch: () => Promise.resolve(),
        };
      }
      return {
        containers_grain_level_loading: loading || false,
        containers_grain_level:
          (data.containers_grain_level as Result['containers_grain_level']) || [],
        containersGrainLevelRefetch: () => data.refetch(),
      };
    },
  })(component as any);
