import { ThemeProvider } from '@material-ui/styles';
import { ApolloClient } from 'apollo-client';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';

import React, { ReactNode } from 'react';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import { mui_theme } from '../style';
import '../style/main.css';

type RootAppProps = {
  store: Store;
  history: History;
  apollo_client: ApolloClient<any>;
  children: ReactNode;
};

export const RootApp: React.FunctionComponent<{
  store: Store;
  history: History;
  apollo_client: ApolloClient<any>;
  children: ReactNode;
}> = ({ store, history, apollo_client, children }: RootAppProps) => {
  return (
    <ApolloProvider client={apollo_client}>
      <ThemeProvider theme={mui_theme}>
        <Provider store={store}>
          <ConnectedRouter history={history}>{children}</ConnectedRouter>
        </Provider>
      </ThemeProvider>
    </ApolloProvider>
  );
};
