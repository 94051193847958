import { Checkbox, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import { DarkCheckBoxIcon, DragIcon, HideIcon } from '../../../../../../core/src/media';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dragButton: {
    '-webkit-transform': 'rotate(90deg)',
    '-moz-transform': 'rotate(90deg)',
    '-o-transform': 'rotate(90deg)',
    ' -ms-transform': 'rotate(90deg)',
    transform: 'rotate(90deg)',
    cursor: 'pointer',
  },
}));
const ColumnVisibilityComponent = (props) => {
  console.log('props in ColumnVisibilityComponent', props);
  const columId = props.columnGroup.children[0].colId;
  const isColVisible: boolean = Boolean(props.isColVisible);
  const [hasVisible, setHasVisible] = useState(isColVisible);
  const classes = useStyles();
  const toggleColumnVisibility = ({ target: { checked } }) => {
    setHasVisible(checked);
    if (props.updateColVisibility) {
      props.updateColVisibility(props.columnApi, columId, !checked);
    }
  };
  return (
    <div className={classes.container}>
      <div
        style={{
          width: 21,
          height: 24,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DragIcon className={classes.dragButton} />
      </div>
      <div style={{ marginLeft: 18 }}>
        <Checkbox
          checked={hasVisible}
          icon={<HideIcon />}
          checkedIcon={<DarkCheckBoxIcon />}
          onChange={toggleColumnVisibility}
          style={{ padding: 0 }}
        />
      </div>
    </div>
  );
};

export { ColumnVisibilityComponent };
