import {Grid} from '@material-ui/core';
import {red} from '@material-ui/core/colors';
import {fade} from '@material-ui/core/styles';
import {Delete, Edit} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';
import format from 'date-fns/format';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';

import {
  GrainBinFanControllerFragmentFragment,
  withGetGrainBinFanSettingsHoc,
  WithGetGrainBinFanSettingsHocChildProps,
  withUnassignFanControllerHoc,
  WithUnassignFanControllerHocChildProps,
  withUpdateFanControllerHoc,
  WithUpdateFanControllerHocChildProps,
} from '../../api';
import {DiagnosticIconSVGComponent} from "../../media";
import {white} from '../../style';
import {DialogSpinner} from '../spinner';
import {ErrorBox} from '../util';
import ConfirmationModal from '../util/ConfirmationModal';
import {Button} from '../util/form2/Button';
import {UpdateGrainBinFanControllerDialog} from './aeration/UpdateGrainBinFanControllerDialog';

const formatDate = (value) => format(value, 'YYYY-MM-DD');

const useStyles = makeStyles({
  cell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cell_header: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  root: {
    minWidth: 340,
  },
  button_red: {
    backgroundColor: red['500'],
    color: white,
    '&:hover': {
      backgroundColor: fade(red['500'], 0.8),
    },
  },
  icon_button: {
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
  },
});

export const FanControllerRemovalFormBase: FunctionComponent<
  { className?: string } &
    WithGetGrainBinFanSettingsHocChildProps &
    WithUnassignFanControllerHocChildProps &
    WithUpdateFanControllerHocChildProps
> = (
    { className,
      loading,
      grain_bin_fan_settings,
      unassignFanController,
      updateFanController,
    }) => {
  const classes = useStyles();

  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showDiagTestModal, setShowDiagTestModal] = useState<boolean>(false);
  const [deletingFanController, setDeletingFanController] = useState<string>('');
  const [deletingFCLinkId, setDeletingFCLinkId] = useState<number>();
  const [updatingDiagTestFC, setUpdatingDiagTestFC] = useState<{fan_controller_id_next: number; core_id: string} | null>(null);
  const [selected_gbfc, setSelectedGBFC] = useState<GrainBinFanControllerFragmentFragment | null>(
    null
  );
  const closeModal = useCallback(() => setSelectedGBFC(null), [setSelectedGBFC]);

  const removeFanController = useCallback(
    async (link_id) => {
      try {
        setSaving(true);
        setError('');
        const result = await unassignFanController({
          grain_bin_fan_controller_id: link_id,
        });
        setSaving(false);
        return result;
      } catch (e) {
        setError(e);
        setSaving(false);
      }
    },
    [unassignFanController]
  );
  const updateDiagTestOfFanController = useCallback(async (fan_controller_id)=>{
    try {
      setError('');
      return await updateFanController({
            fan_controller_id,
            diag_test_is_enable: true,
          }
      );
    } catch (e){
      setError(e);
    }
  }, [updateFanController]);

  const initialValues = useMemo(
    () => ({
      devices: grain_bin_fan_settings
        ? grain_bin_fan_settings.grain_bin.fan_controllers.map((gbfc) => {
            const {
              grain_bin_fan_controller_id,
              start_date,
              fan_model_id,
              fan_controller: { alias: device_alias, core_id, fan_controller_id_next, diag_test_is_enable },
            } = gbfc;
            return {
              ...gbfc,
              device_alias,
              core_id,
              start_date,
              fan_controller_id_next,
              diag_test_is_enable,
              fan_model_id,
              link_id: grain_bin_fan_controller_id,
            };
          })
        : [],
      link_id: null,
    }),
    [grain_bin_fan_settings && grain_bin_fan_settings.grain_bin.fan_controllers]
  );
  return loading ? (
    <DialogSpinner title="Loading Fan Controllers..." open />
  ) : grain_bin_fan_settings ? (
    <>
      <Grid
        container
        direction="column"
        justify="center"
        classes={{ container: classes.root }}
        spacing={2}
      >
        {initialValues.devices.length ? (
          <>
            <Grid item container alignItems="center">
              <Grid item xs={3} className={classes.cell_header}>
                Nickname
              </Grid>
              <Grid item xs={3} className={classes.cell_header}>
                Device ID
              </Grid>
              <Grid item xs={2} className={classes.cell_header}>
                Diagnostics
              </Grid>
              <Grid item xs={2} className={classes.cell_header}>
                Edit
              </Grid>
              <Grid item xs={2} className={classes.cell_header}>
                Remove
              </Grid>
            </Grid>
            {initialValues.devices.map((device) => {
              const { link_id, device_alias, core_id, start_date, fan_model_id } = device;
              return (
                <Grid item container alignItems="center" key={link_id}>
                  <Grid item xs={3} className={classes.cell}>
                    {device.alias}
                  </Grid>
                  <Grid item xs={3} className={classes.cell}>
                    {device_alias || core_id}
                  </Grid>
                  <Grid item xs={2} className={classes.cell}>
                    <Button onClick={async ()=>{
                      const {fan_controller_id_next, core_id, diag_test_is_enable} = device;
                      if (diag_test_is_enable){
                        window.location.href = `${window.location.origin}/devices/fc/${core_id}`;
                      } else {
                        setUpdatingDiagTestFC({fan_controller_id_next, core_id});
                        setShowDiagTestModal(true);
                      }
                    }}
                            className={classes.icon_button}>
                      <DiagnosticIconSVGComponent is_mobile={'false'} />
                    </Button>
                  </Grid>
                  <Grid item xs={2} className={classes.cell}>
                    <Button
                      onClick={() => {
                        setSelectedGBFC(
                          (device as unknown) as GrainBinFanControllerFragmentFragment
                        );
                      }}
                      fullWidth
                      className={classes.icon_button}
                    >
                      <Edit />
                    </Button>
                  </Grid>
                  <Grid item xs={2} className={classes.cell}>
                    <Button
                        onClick={async () => {
                          const deletingFCName = device.alias ? device.alias : device_alias;
                          setDeletingFCLinkId(link_id);
                          setDeletingFanController(deletingFCName);
                          setShowDeleteModal(true);
                        }}
                        className={`${classes.button_red} ${classes.icon_button}`}
                        fullWidth
                    >
                      <Delete />
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
          </>
        ) : (
          <p>No Fan Controllers Assigned</p>
        )}
        <ErrorBox error={error} />
        <DialogSpinner title={'Removing Fan Controller...'} open={saving} />
        <ConfirmationModal
          showModal={showDeleteModal}
          confirmationMessage={
            <>
              Are you sure you want to delete fan controller{' '}
              <strong>{deletingFanController}</strong>?
            </>
          }
          cancelBtnText="cancel"
          confirmBtbText="Delete"
          handleCancel={() => setShowDeleteModal(false)}
          handleConfirm={async () => {
            await removeFanController(deletingFCLinkId);
            setShowDeleteModal(false);
          }}
        />
        {updatingDiagTestFC && <ConfirmationModal
            confirmationMessage={
              <>
                Diagnostic Test is <strong>disabled</strong>. Click 'Enable and Go' to continue.
              </>
            }
            showModal={showDiagTestModal}
            handleCancel={()=>setShowDiagTestModal(false)}
            handleConfirm={async()=>{
              const {fan_controller_id_next} = updatingDiagTestFC;
              await updateDiagTestOfFanController(fan_controller_id_next);
              // go to diag page
              window.location.href = updatingDiagTestFC ? `${window.location.origin}/devices/fc/${updatingDiagTestFC.core_id}` : `${window.location.origin}/devices/fc`;
              setShowDiagTestModal(false);
            }} cancelBtnText="Cancel" confirmBtbText="Enable and Go"/>}
      </Grid>
      {selected_gbfc && <UpdateGrainBinFanControllerDialog
        open={Boolean(selected_gbfc)}
        grain_bin_fan_controller={selected_gbfc}
        fan_controller_id={selected_gbfc.fan_controller_id_next}
        onClose={closeModal}
      />}
    </>
  ) : null;
};

export const FanControllerRemovalForm = withUpdateFanControllerHoc(withGetGrainBinFanSettingsHoc(
  withUnassignFanControllerHoc(FanControllerRemovalFormBase)
));
