import { AmberHoc, AmberHocProps } from '../../../util';
import {
  CreatePileMutationVariables as Variables,
  GetAccountDocument,
  GetAccountQuery,
  GetAccountQueryVariables,
  GetPileDocument,
  GetPileQuery,
  GetPileQueryVariables,
  PileFragmentFragment as Result,
  withCreatePile as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithCreatePileHocChildProps = {
  createPile: (variables: Variables) => Promise<Result>;
};
export const withCreatePileHoc: AmberHoc<{}, WithCreatePileHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithCreatePileHocChildProps, typeof component>,
    WithCreatePileHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        createPile: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const { account_id } = variables;
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { createPile: result } = data;
              const { pile, __typename } = result;
              const { pile_id, alias, default_grain_type, __typename: pile_type } = pile;

              try {
                proxy.writeQuery<GetPileQuery, GetPileQueryVariables>({
                  query: GetPileDocument,
                  data: { pile, __typename: 'Query' },
                  variables: { pile_id },
                });
              } catch (err) {
                // no-op
              }

              try {
                const cached = proxy.readQuery<GetAccountQuery, GetAccountQueryVariables>({
                  query: GetAccountDocument,
                  variables: { account_id },
                });
                if (!cached) {
                  return;
                }
                const { account } = cached;
                if (!account) {
                  return;
                }
                proxy.writeQuery<GetAccountQuery, GetAccountQueryVariables>({
                  query: GetAccountDocument,
                  data: {
                    __typename: 'Query',
                    account: {
                      ...account,
                      pile_links: [
                        ...account.pile_links,
                        {
                          __typename,
                          pile_id,
                          pile: { pile_id, alias, default_grain_type, __typename: pile_type },
                        },
                      ],
                    },
                  },
                  variables: { account_id },
                });
              } catch (err) {
                // no-op
              }
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.createPile.pile;
        },
      };
    },
  })(component as any);
