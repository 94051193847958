import { Tooltip } from '@material-ui/core';
import React from 'react';
import { MediumErrorIcon, MediumRedAlertIcon } from '../../../../../core/src/media';
import { black_shade_8 } from '../../../../../core/src/style';
import { UNASSIGNED_SITE_DISPLAY_NAME, UNASSIGNED_SITE_NAME } from '../manage-sites';
import { SiteDetailsWithoutPlaceholder } from './SiteDetails';

export const SiteCellRenderer = (props) => {
  const { value, node } = props;
  const binsCount = node.childrenAfterFilter.length;
  const hasSiteContainsFanErrorsWithAnyBin =
    props &&
    props.node.childrenAfterFilter.map(({ data }) => data).some((data) => data.has_errored_fan);

  const hasSiteContainsCO2AlertsSentWithAnyBin =
    props &&
    props.node.childrenAfterFilter
      .map(({ data }) => data)
      .some((data) => data.last_week_co2_alert_sent_on);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          color: black_shade_8,
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        <div
          style={{
            maxWidth: 170,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginRight: 4,
          }}
        >
          {value === UNASSIGNED_SITE_NAME ? UNASSIGNED_SITE_DISPLAY_NAME : value}
        </div>
        ({binsCount})
      </div>
      <SiteDetailsWithoutPlaceholder {...props}>
        {hasSiteContainsFanErrorsWithAnyBin && (
          <Tooltip placement="bottom" title={<div style={{ fontSize: 12 }}>Fan Errors</div>} arrow>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: 24,
                height: 24,
                marginRight: hasSiteContainsCO2AlertsSentWithAnyBin ? 10 : undefined,
              }}
            >
              <MediumErrorIcon />
            </div>
          </Tooltip>
        )}
        {hasSiteContainsCO2AlertsSentWithAnyBin && (
          <Tooltip placement="bottom" title={<div style={{ fontSize: 12 }}>CO2 Alerts</div>} arrow>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: 24,
                height: 24,
              }}
            >
              <MediumRedAlertIcon />
            </div>
          </Tooltip>
        )}
      </SiteDetailsWithoutPlaceholder>
    </div>
  );
};
