import { DateTime } from 'luxon';
const calculateRunwindowDuration = ({ start, end }: { start: DateTime; end: DateTime }) => {
  const validInput = start && end;
  if (!validInput) return 0;
  const start_epoch = start.toMillis();
  const end_epoch = end.toMillis();
  // handle end date as forever case
  if (Math.round(end.diff(start, 'year').toObject().years) === 1) return Infinity;
  const hrs = end_epoch / (1000 * 3600) - start_epoch / (1000 * 3600);
  if (hrs < 1) return hrs;
  return Math.round(hrs);
};

export { calculateRunwindowDuration };
