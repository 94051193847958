import * as React from 'react';
export const ThreeDotsIcon = (props) => (
  <svg
    width="200pt"
    height="200pt"
    viewBox="0 -20 200 200"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <symbol id="x" overflow="visible">
        <path d="m6.701 -0.402c-0.346 0.179 -0.705 0.315 -1.081 0.406s-0.767 0.139 -1.174 0.139c-1.214 0 -2.177 -0.339 -2.884 -1.018 -0.709 -0.679 -1.063 -1.599 -1.063 -2.763 0 -1.163 0.354 -2.085 1.063 -2.763 0.707 -0.681 1.669 -1.022 2.884 -1.022 0.407 0 0.799 0.047 1.174 0.139 0.375 0.093 0.735 0.228 1.081 0.406v1.509c-0.348 -0.238 -0.692 -0.412 -1.031 -0.522 -0.339 -0.109 -0.697 -0.165 -1.071 -0.165 -0.669 0 -1.197 0.214 -1.581 0.643s-0.576 1.021 -0.576 1.777c0 0.753 0.192 1.346 0.576 1.777 0.384 0.429 0.911 0.643 1.581 0.643 0.375 0 0.732 -0.055 1.071 -0.165 0.339 -0.113 0.683 -0.288 1.031 -0.527z" />
      </symbol>
      <symbol id="b" overflow="visible">
        <path d="m4.902 -3.978c-0.152 -0.071 -0.303 -0.123 -0.455 -0.156 -0.152 -0.036 -0.303 -0.053 -0.455 -0.053 -0.45 0 -0.797 0.145 -1.04 0.433 -0.241 0.286 -0.361 0.699 -0.361 1.237v2.518h-1.75v-5.469h1.75v0.897c0.223 -0.357 0.48 -0.617 0.772 -0.781 0.291 -0.166 0.641 -0.25 1.049 -0.25 0.056 0 0.118 0.003 0.187 0.009 0.068 0.007 0.167 0.017 0.299 0.031z" />
      </symbol>
      <symbol id="a" overflow="visible">
        <path d="m6.299 -2.75v0.5h-4.089c0.045 0.411 0.193 0.719 0.447 0.924 0.252 0.203 0.607 0.303 1.063 0.303 0.368 0 0.745 -0.053 1.129 -0.161 0.386 -0.109 0.783 -0.275 1.192 -0.495v1.348c-0.414 0.155 -0.828 0.272 -1.241 0.353 -0.414 0.081 -0.828 0.121 -1.241 0.121 -0.989 0 -1.758 -0.251 -2.308 -0.755 -0.548 -0.502 -0.821 -1.207 -0.821 -2.116 0 -0.893 0.269 -1.595 0.808 -2.107 0.538 -0.511 1.279 -0.768 2.223 -0.768 0.859 0 1.547 0.259 2.063 0.777 0.518 0.518 0.777 1.21 0.777 2.076zm-1.799 -0.581c0 -0.333 -0.097 -0.601 -0.29 -0.803 -0.194 -0.202 -0.447 -0.303 -0.759 -0.303 -0.337 0 -0.611 0.096 -0.826 0.286 -0.212 0.191 -0.343 0.464 -0.393 0.821z" />
      </symbol>
      <symbol id="d" overflow="visible">
        <path d="m3.29 -2.46c-0.364 0 -0.637 0.063 -0.821 0.187 -0.185 0.123 -0.277 0.303 -0.277 0.545 0 0.221 0.073 0.393 0.223 0.518 0.149 0.125 0.354 0.187 0.616 0.187 0.331 0 0.607 -0.117 0.831 -0.353 0.225 -0.235 0.339 -0.529 0.339 -0.884v-0.201zm2.669 -0.661v3.121h-1.759v-0.813c-0.235 0.334 -0.5 0.576 -0.795 0.728 -0.293 0.152 -0.647 0.228 -1.067 0.228 -0.566 0 -1.025 -0.165 -1.379 -0.495 -0.355 -0.331 -0.531 -0.76 -0.531 -1.29 0 -0.639 0.22 -1.108 0.661 -1.406 0.443 -0.3 1.136 -0.451 2.081 -0.451h1.031v-0.139c0 -0.277 -0.111 -0.479 -0.331 -0.607 -0.217 -0.127 -0.557 -0.192 -1.018 -0.192 -0.375 0 -0.725 0.038 -1.049 0.111 -0.321 0.075 -0.621 0.187 -0.897 0.335v-1.331c0.375 -0.091 0.751 -0.162 1.129 -0.21 0.377 -0.047 0.755 -0.071 1.134 -0.071 0.985 0 1.697 0.195 2.134 0.585 0.437 0.391 0.656 1.022 0.656 1.897z" />
      </symbol>
      <symbol id="c" overflow="visible">
        <path d="m2.75 -7.022v1.553h1.799v1.25h-1.799v2.321c0 0.253 0.05 0.424 0.152 0.513 0.101 0.089 0.3 0.134 0.598 0.134h0.897v1.25h-1.495c-0.691 0 -1.181 -0.144 -1.469 -0.433 -0.289 -0.288 -0.433 -0.777 -0.433 -1.464v-2.321h-0.866v-1.25h0.866v-1.553z" />
      </symbol>
      <symbol id="l" overflow="visible">
        <path d="m4.563 -4.67v-2.929h1.755v7.598h-1.755v-0.79c-0.241 0.321 -0.508 0.558 -0.799 0.71 -0.289 0.149 -0.624 0.223 -1.005 0.223 -0.673 0 -1.227 -0.268 -1.661 -0.803 -0.432 -0.536 -0.647 -1.225 -0.647 -2.067 0 -0.845 0.215 -1.536 0.647 -2.071 0.434 -0.536 0.988 -0.803 1.661 -0.803 0.377 0 0.712 0.076 1.005 0.228 0.291 0.152 0.558 0.387 0.799 0.705zm-1.156 3.54c0.375 0 0.661 -0.135 0.857 -0.406 0.199 -0.273 0.299 -0.671 0.299 -1.192 0 -0.52 -0.101 -0.917 -0.299 -1.192 -0.197 -0.273 -0.482 -0.411 -0.857 -0.411 -0.369 0 -0.654 0.137 -0.853 0.411 -0.197 0.275 -0.295 0.672 -0.295 1.192 0 0.521 0.098 0.919 0.295 1.192 0.199 0.271 0.483 0.406 0.853 0.406z" />
      </symbol>
      <symbol id="k" overflow="visible">
        <path d="m3.75 -1.129c0.375 0 0.661 -0.135 0.857 -0.406 0.197 -0.273 0.295 -0.671 0.295 -1.192 0 -0.52 -0.098 -0.917 -0.295 -1.192 -0.197 -0.273 -0.482 -0.411 -0.857 -0.411s-0.663 0.139 -0.861 0.415c-0.2 0.275 -0.299 0.669 -0.299 1.187 0 0.515 0.099 0.911 0.299 1.187 0.199 0.275 0.487 0.411 0.861 0.411zm-1.161 -3.54c0.241 -0.318 0.507 -0.553 0.799 -0.705 0.295 -0.152 0.631 -0.228 1.009 -0.228 0.675 0 1.229 0.268 1.661 0.803 0.434 0.536 0.652 1.227 0.652 2.071 0 0.843 -0.217 1.531 -0.652 2.067 -0.432 0.536 -0.985 0.803 -1.661 0.803 -0.379 0 -0.714 -0.076 -1.009 -0.228 -0.293 -0.152 -0.558 -0.386 -0.799 -0.705v0.79h-1.75v-7.598h1.75z" />
      </symbol>
      <symbol id="j" overflow="visible">
        <path d="m0.121 -5.469h1.75l1.469 3.71 1.25 -3.71h1.75l-2.299 5.987c-0.232 0.607 -0.503 1.031 -0.813 1.272 -0.307 0.243 -0.712 0.366 -1.214 0.366h-1.013v-1.147h0.549c0.295 0 0.509 -0.048 0.643 -0.143 0.136 -0.093 0.242 -0.261 0.317 -0.505l0.049 -0.152z" />
      </symbol>
      <symbol id="i" overflow="visible">
        <path d="m3.839 -4.469c0.295 0 0.518 -0.063 0.669 -0.192 0.154 -0.131 0.232 -0.323 0.232 -0.581 0 -0.25 -0.078 -0.44 -0.232 -0.571 -0.152 -0.131 -0.375 -0.197 -0.669 -0.197h-1.04v1.54zm0.063 3.187c0.377 0 0.662 -0.078 0.853 -0.237 0.19 -0.161 0.286 -0.402 0.286 -0.723 0 -0.315 -0.096 -0.551 -0.286 -0.71 -0.187 -0.157 -0.472 -0.237 -0.853 -0.237h-1.103v1.906zm1.745 -2.621c0.404 0.116 0.717 0.334 0.937 0.652 0.223 0.316 0.335 0.703 0.335 1.161 0 0.703 -0.239 1.227 -0.714 1.571 -0.477 0.346 -1.199 0.518 -2.165 0.518h-3.121v-7.29h2.821c1.011 0 1.743 0.154 2.197 0.46 0.455 0.307 0.683 0.797 0.683 1.469 0 0.355 -0.084 0.656 -0.25 0.906 -0.164 0.25 -0.405 0.435 -0.723 0.553z" />
      </symbol>
      <symbol id="h" overflow="visible">
        <path d="m0.839 -5.469h1.75v5.469h-1.75zm0 -2.129h1.75v1.424h-1.75z" />
      </symbol>
      <symbol id="g" overflow="visible">
        <path d="m6.339 -3.33v3.33h-1.759v-2.549c0 -0.473 -0.011 -0.799 -0.031 -0.978 -0.021 -0.179 -0.058 -0.309 -0.111 -0.393 -0.069 -0.116 -0.161 -0.205 -0.277 -0.268s-0.25 -0.094 -0.402 -0.094c-0.364 0 -0.649 0.142 -0.857 0.424 -0.209 0.28 -0.313 0.669 -0.313 1.165v2.692h-1.75v-5.469h1.75v0.799c0.261 -0.318 0.54 -0.553 0.835 -0.705 0.297 -0.152 0.626 -0.228 0.987 -0.228 0.631 0 1.109 0.195 1.437 0.585 0.327 0.387 0.491 0.95 0.491 1.687z" />
      </symbol>
      <symbol id="w" overflow="visible">
        <path d="m0.299 -7.29h1.799l1.263 5.299 1.25 -5.299h1.808l1.25 5.299 1.259 -5.299h1.79l-1.719 7.29h-2.169l-1.321 -5.54 -1.308 5.54h-2.169z" />
      </symbol>
      <symbol id="f" overflow="visible">
        <path d="m6.339 -3.33v3.33h-1.759v-2.54c0 -0.479 -0.011 -0.808 -0.031 -0.987 -0.021 -0.179 -0.058 -0.309 -0.111 -0.393 -0.069 -0.116 -0.161 -0.205 -0.277 -0.268s-0.25 -0.094 -0.402 -0.094c-0.364 0 -0.649 0.142 -0.857 0.424 -0.209 0.28 -0.313 0.669 -0.313 1.165v2.692h-1.75v-7.598h1.75v2.929c0.261 -0.318 0.54 -0.553 0.835 -0.705 0.297 -0.152 0.626 -0.228 0.987 -0.228 0.631 0 1.109 0.195 1.437 0.585 0.327 0.387 0.491 0.95 0.491 1.687z" />
      </symbol>
      <symbol id="v" overflow="visible">
        <path d="m0.049 -7.29h1.889l1.933 5.379 1.929 -5.379h1.893l-2.701 7.29h-2.241z" />
      </symbol>
      <symbol id="u" overflow="visible">
        <path d="m4.563 -0.929c-0.241 0.319 -0.508 0.553 -0.799 0.705 -0.289 0.149 -0.624 0.223 -1.005 0.223 -0.667 0 -1.219 -0.261 -1.656 -0.786 -0.435 -0.527 -0.652 -1.197 -0.652 -2.013 0 -0.815 0.217 -1.483 0.652 -2.005 0.437 -0.523 0.989 -0.786 1.656 -0.786 0.381 0 0.715 0.075 1.005 0.223 0.291 0.149 0.558 0.386 0.799 0.71v-0.813h1.755v4.915c0 0.881 -0.279 1.551 -0.835 2.013 -0.553 0.464 -1.357 0.697 -2.411 0.697 -0.343 0 -0.673 -0.027 -0.991 -0.081 -0.319 -0.051 -0.641 -0.129 -0.964 -0.237v-1.361c0.306 0.175 0.605 0.306 0.897 0.393 0.295 0.086 0.591 0.129 0.889 0.129 0.571 0 0.991 -0.125 1.259 -0.375s0.402 -0.643 0.402 -1.179zm-1.156 -3.402c-0.361 0 -0.642 0.134 -0.844 0.402 -0.203 0.265 -0.303 0.642 -0.303 1.129 0 0.503 0.098 0.884 0.295 1.143s0.48 0.389 0.853 0.389c0.366 0 0.649 -0.134 0.853 -0.402 0.202 -0.268 0.303 -0.644 0.303 -1.129 0 -0.488 -0.101 -0.864 -0.303 -1.129 -0.203 -0.268 -0.487 -0.402 -0.853 -0.402z" />
      </symbol>
      <symbol id="t" overflow="visible">
        <path d="m5.112 -5.299v1.331c-0.375 -0.157 -0.737 -0.275 -1.085 -0.353 -0.348 -0.077 -0.677 -0.116 -0.987 -0.116 -0.331 0 -0.576 0.043 -0.737 0.125 -0.161 0.084 -0.241 0.212 -0.241 0.384 0 0.141 0.06 0.248 0.183 0.321 0.121 0.075 0.341 0.129 0.656 0.165l0.308 0.045c0.893 0.114 1.493 0.301 1.803 0.563 0.309 0.259 0.464 0.667 0.464 1.223 0 0.584 -0.217 1.022 -0.647 1.317 -0.429 0.293 -1.069 0.437 -1.919 0.437 -0.364 0 -0.739 -0.03 -1.125 -0.089 -0.384 -0.056 -0.779 -0.141 -1.183 -0.255v-1.326c0.348 0.169 0.705 0.297 1.071 0.379 0.366 0.084 0.738 0.125 1.116 0.125 0.339 0 0.595 -0.046 0.768 -0.139 0.172 -0.095 0.259 -0.235 0.259 -0.419 0 -0.157 -0.06 -0.273 -0.179 -0.348 -0.119 -0.077 -0.356 -0.139 -0.71 -0.183l-0.308 -0.036c-0.777 -0.098 -1.321 -0.278 -1.634 -0.54 -0.313 -0.265 -0.469 -0.666 -0.469 -1.205 0 -0.577 0.197 -1.005 0.594 -1.286 0.399 -0.283 1.007 -0.424 1.826 -0.424 0.323 0 0.663 0.026 1.018 0.076 0.354 0.048 0.739 0.124 1.156 0.228z" />
      </symbol>
      <symbol id="s" overflow="visible">
        <path d="m4.437 -7.598v1.147h-0.964c-0.248 0 -0.419 0.045 -0.518 0.134s-0.147 0.245 -0.147 0.469v0.379h1.495v1.25h-1.495v4.219h-1.75v-4.219h-0.866v-1.25h0.866v-0.379c0 -0.595 0.165 -1.036 0.495 -1.321 0.333 -0.286 0.849 -0.429 1.549 -0.429z" />
      </symbol>
      <symbol id="e" overflow="visible">
        <path d="m3.442 -4.353c-0.387 0 -0.683 0.141 -0.889 0.419 -0.203 0.277 -0.303 0.679 -0.303 1.205 0 0.525 0.101 0.927 0.303 1.205 0.205 0.277 0.501 0.415 0.889 0.415 0.381 0 0.672 -0.139 0.875 -0.415 0.202 -0.279 0.303 -0.681 0.303 -1.205 0 -0.527 -0.101 -0.929 -0.303 -1.205 -0.203 -0.279 -0.495 -0.419 -0.875 -0.419zm0 -1.25c0.94 0 1.675 0.255 2.205 0.763 0.529 0.509 0.795 1.213 0.795 2.111 0 0.896 -0.265 1.598 -0.795 2.107 -0.53 0.509 -1.265 0.763 -2.205 0.763 -0.944 0 -1.682 -0.255 -2.214 -0.763 -0.533 -0.509 -0.799 -1.211 -0.799 -2.107 0 -0.899 0.265 -1.603 0.799 -2.111 0.533 -0.509 1.27 -0.763 2.214 -0.763z" />
      </symbol>
      <symbol id="r" overflow="visible">
        <path d="m5.906 -4.563c0.223 -0.336 0.487 -0.594 0.79 -0.772s0.639 -0.268 1.005 -0.268c0.627 0 1.105 0.195 1.433 0.585 0.331 0.387 0.495 0.95 0.495 1.687v3.33h-1.759v-2.853c0.002 -0.041 0.005 -0.085 0.005 -0.129 0.002 -0.047 0.005 -0.113 0.005 -0.197 0 -0.386 -0.057 -0.666 -0.169 -0.839 -0.114 -0.175 -0.298 -0.263 -0.553 -0.263 -0.331 0 -0.587 0.137 -0.768 0.411 -0.182 0.275 -0.276 0.669 -0.281 1.183v2.687h-1.755v-2.853c0 -0.604 -0.053 -0.992 -0.156 -1.165 -0.105 -0.175 -0.29 -0.263 -0.558 -0.263 -0.337 0 -0.596 0.139 -0.777 0.415 -0.182 0.275 -0.272 0.665 -0.272 1.174v2.692h-1.759v-5.469h1.759v0.799c0.214 -0.309 0.46 -0.541 0.737 -0.697 0.279 -0.157 0.587 -0.237 0.924 -0.237 0.375 0 0.707 0.093 0.995 0.277 0.291 0.182 0.511 0.437 0.661 0.763z" />
      </symbol>
      <symbol id="q" overflow="visible">
        <path d="m0.919 -7.29h2.098l2.652 5v-5h1.781v7.29h-2.098l-2.652 -5v5h-1.781z" />
      </symbol>
      <symbol id="p" overflow="visible">
        <path d="m0.781 -2.129v-3.339h1.759v0.545c0 0.298 -0.002 0.672 -0.005 1.121 -0.003 0.447 -0.005 0.745 -0.005 0.893 0 0.441 0.01 0.758 0.031 0.951 0.023 0.194 0.063 0.334 0.116 0.419 0.073 0.116 0.167 0.205 0.281 0.268 0.116 0.06 0.25 0.089 0.402 0.089 0.363 0 0.649 -0.139 0.857 -0.419 0.207 -0.279 0.313 -0.667 0.313 -1.165v-2.701h1.75v5.469h-1.75v-0.79c-0.265 0.319 -0.545 0.553 -0.839 0.705s-0.619 0.228 -0.973 0.228c-0.632 0 -1.111 -0.193 -1.442 -0.581 -0.331 -0.389 -0.495 -0.953 -0.495 -1.692z" />
      </symbol>
      <symbol id="o" overflow="visible">
        <path d="m0.919 -7.29h3.121c0.925 0 1.636 0.207 2.134 0.621 0.497 0.411 0.745 0.998 0.745 1.759 0 0.765 -0.249 1.353 -0.745 1.763 -0.498 0.411 -1.209 0.616 -2.134 0.616h-1.241v2.531h-1.879zm1.879 1.361v2.036h1.04c0.363 0 0.644 -0.087 0.844 -0.263 0.199 -0.179 0.299 -0.43 0.299 -0.755 0 -0.327 -0.101 -0.578 -0.299 -0.755 -0.2 -0.175 -0.481 -0.263 -0.844 -0.263z" />
      </symbol>
      <symbol id="n" overflow="visible">
        <path d="m0.839 -5.469h1.75v5.371c0 0.732 -0.177 1.29 -0.531 1.674 -0.351 0.386 -0.861 0.581 -1.527 0.581h-0.861v-1.147h0.299c0.333 0 0.56 -0.075 0.683 -0.223 0.125 -0.149 0.187 -0.444 0.187 -0.884zm0 -2.129h1.75v1.424h-1.75z" />
      </symbol>
      <symbol id="m" overflow="visible">
        <path d="m5.259 -5.299v1.429c-0.239 -0.163 -0.478 -0.283 -0.719 -0.361 -0.239 -0.081 -0.485 -0.121 -0.741 -0.121 -0.489 0 -0.869 0.143 -1.143 0.429 -0.271 0.286 -0.406 0.685 -0.406 1.197 0 0.509 0.135 0.906 0.406 1.192 0.273 0.286 0.654 0.429 1.143 0.429 0.273 0 0.533 -0.04 0.777 -0.121 0.247 -0.083 0.475 -0.205 0.683 -0.366v1.433c-0.275 0.101 -0.553 0.177 -0.835 0.228 -0.28 0.05 -0.561 0.076 -0.844 0.076 -0.989 0 -1.761 -0.252 -2.317 -0.759 -0.557 -0.505 -0.835 -1.21 -0.835 -2.111s0.278 -1.605 0.835 -2.111c0.556 -0.509 1.328 -0.763 2.317 -0.763 0.286 0 0.567 0.026 0.844 0.076 0.279 0.051 0.558 0.127 0.835 0.228z" />
      </symbol>
    </defs>
    <g>
      <path
        d="m60.8 80c0 8.395 -6.806 15.2 -15.2 15.2 -8.395 0 -15.201 -6.805 -15.201 -15.2s6.806 -15.2 15.201 -15.2c8.394 0 15.2 6.805 15.2 15.2"
        fill="#FFFFFF"
      />
      <path
        d="m115.2 80c0 8.395 -6.805 15.2 -15.2 15.2s-15.2 -6.805 -15.2 -15.2 6.805 -15.2 15.2 -15.2 15.2 6.805 15.2 15.2"
        fill="#FFFFFF"
      />
      <path
        d="m169.6 80c0 8.395 -6.806 15.2 -15.201 15.2 -8.394 0 -15.2 -6.805 -15.2 -15.2s6.806 -15.2 15.2 -15.2c8.395 0 15.201 6.805 15.201 15.2"
        fill="#FFFFFF"
      />
    </g>
  </svg>
);
