import {
  Button,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getDistanceUnitLabel } from '../../../../../core/src/util';
import {
  FanModelCreatedType,
  FanModelFilter,
  FanType,
  withGetGrainFanModelsHoc,
  WithGetGrainFanModelsHocChildProps,
} from '../../../api';
import { CenteredSpinner } from '../../spinner';
import { GrainFanModelsSettingsModal } from './GrainFanModelsSettingsModal';

const centrifugal_high_string = 'Centrifugal High(3500 RPM)';
const centrifugal_low_string = 'Centrifugal Low(1750 RPM)';
const axial_flow_string = 'Axial';
const centrifugal_inline_string = 'Centrifugal Inline';

export const fan_model_type_to_string = {
  [FanType.AxialFlow]: axial_flow_string,
  [FanType.Centrifugal]: centrifugal_low_string,
  [FanType.CentrifugalHigh]: centrifugal_high_string,
  [FanType.CentrifugalInline]: centrifugal_inline_string,
};

export const string_to_fan_type = {
  [axial_flow_string]: FanType.AxialFlow,
  [centrifugal_low_string]: FanType.Centrifugal,
  [centrifugal_high_string]: FanType.CentrifugalHigh,
  [centrifugal_inline_string]: FanType.CentrifugalInline,
};

export const string_to_fan_model_type = {
  [axial_flow_string]: 'axial_flow',
  [centrifugal_low_string]: 'centrifugal',
  [centrifugal_high_string]: 'centrifugal_high',
  [centrifugal_inline_string]: 'centrifugal_inline',
};

export type FanModelDetails = {
  fan_model_id: number | null;
  make: string;
  fan_brand_name: string;
  fan_model_type: string;
  fan_horsepower: number;
  diameter: number;
  fan_model_created_type: FanModelCreatedType;
  linked_generic_fan_model_id: number | null;
};

const useStyles = makeStyles({
  detailsTable: {
    maxWidth: 300,
    '& .MuiTableCell-root': {
      padding: 6,
    },
  },
});

type GrainFanModelBaseProps = {
  fan_model_filter: FanModelFilter[];
  currentFanModelState: FanModelDetails | null;
  setCurrentFanModelState: React.Dispatch<React.SetStateAction<FanModelDetails | null>>;
} & WithGetGrainFanModelsHocChildProps;

const GrainFanModelsBase = (props: GrainFanModelBaseProps) => {
  const distanceUnitLabel = getDistanceUnitLabel({ cgs: true });
  const {
    grain_fan_models,
    get_grain_fan_models_loading,
    currentFanModelState,
    setCurrentFanModelState,
  } = props;
  console.log('currentFanModelState', currentFanModelState);
  console.log('fan_model_filter & grain_fan_models', {
    grain_fan_models,
    fan_model_filter: props.fan_model_filter,
  });

  if (get_grain_fan_models_loading) {
    return <CenteredSpinner fadeIn="none" />;
  }

  const classes = useStyles();
  const [openGrainFanModelsModal, setOpenGrainFanModelsModal] = useState<boolean>(false);
  let fan_model_type_string: string = '';
  if (currentFanModelState) {
    fan_model_type_string = fan_model_type_to_string[currentFanModelState.fan_model_type];
  }
  const fan_make = !currentFanModelState ? ' - ' : currentFanModelState.make;
  const fan_brand = !currentFanModelState ? ' - ' : currentFanModelState.fan_brand_name;
  const fan_model_type = !currentFanModelState ? ' - ' : fan_model_type_string;
  const fan_horsepower = !currentFanModelState ? ' - ' : currentFanModelState.fan_horsepower;
  const fan_diameter = !currentFanModelState ? ' - ' : currentFanModelState.diameter;
  const tableCellStyles = { fontWeight: 600 };

  const handleClickOpen = () => {
    setOpenGrainFanModelsModal(true);
  };

  const handleClose = () => {
    setOpenGrainFanModelsModal(false);
  };

  const handleSetCurrentFanModelState = (currentFanModel) => {
    console.log('currentFanModel in handleSetCurrentFanModelState', currentFanModel);
    setCurrentFanModelState(currentFanModel);
    setOpenGrainFanModelsModal(false);
  };

  const getCurrentFanModel = (grain_fan_models) => {
    const all_fan_models_values = grain_fan_models.filter(
      (fan_model) => fan_model.label === 'all_fan_models'
    );
    let current_fan_model: any = null;
    if (all_fan_models_values.length === 1 && all_fan_models_values[0].values.length === 1) {
      const {
        fan_model_id,
        fan_model_name,
        make,
        fan_brand_name,
        fan_model_type,
        fan_horsepower,
        diameter,
        fan_model_created_type,
        linked_generic_fan_model_id,
      } = all_fan_models_values[0].values[0].label;
      current_fan_model = {
        fan_model_id,
        fan_model_name,
        make,
        fan_brand_name,
        fan_model_type,
        fan_horsepower,
        diameter,
        fan_model_created_type,
        linked_generic_fan_model_id,
      };
    }
    return current_fan_model;
  };

  useEffect(() => {
    if (get_grain_fan_models_loading === false) {
      const currentFanModel = getCurrentFanModel(grain_fan_models);
      console.log('currentFanModel & currentFanModelState', currentFanModel, currentFanModelState);
      setCurrentFanModelState(currentFanModel);
    }
  }, [get_grain_fan_models_loading]);

  return (
    <>
      <Grid container>
        <Typography variant="h6" style={{ fontWeight: 600, textDecoration: 'underline' }}>
          Fan Model:
        </Typography>
        <Grid item xs={12} style={{ marginTop: 4 }}>
          <Table className={classes.detailsTable} style={{ padding: 6 }}>
            <TableBody>
              <TableRow>
                <TableCell style={tableCellStyles}>Brand</TableCell>
                <TableCell> {fan_make}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={tableCellStyles}>Model</TableCell>
                <TableCell> {fan_brand}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={tableCellStyles}>Type</TableCell>
                <TableCell> {fan_model_type}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={tableCellStyles}>Horsepower</TableCell>
                <TableCell> {fan_horsepower}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={tableCellStyles}>Diameter ({distanceUnitLabel})</TableCell>
                <TableCell> {fan_diameter}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            style={{ marginTop: 15, textTransform: 'capitalize', fontSize: 12, height: 28 }}
            onClick={handleClickOpen}
          >
            {!currentFanModelState ? 'Select Fan model' : 'Update Fan model'}
          </Button>
        </Grid>
        {openGrainFanModelsModal && (
          <GrainFanModelsSettingsModal
            openGrainFanModelsModal={openGrainFanModelsModal}
            handleClose={handleClose}
            handleSetCurrentFanModelState={handleSetCurrentFanModelState}
            currentFanModelState={currentFanModelState}
          />
        )}
      </Grid>
    </>
  );
};

export const GrainFanModels = withGetGrainFanModelsHoc(GrainFanModelsBase);
