import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy, RelativeTimePeriod } from '../../../util';
import {
  GetGrainContainerHistoryQuery,
  withGetGrainContainerHistoryBinSummary as HOC,
} from '../__generated';

export type WithGetGrainContainerHistoryBinSummaryHocOwnProps = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  pollInterval?: number;
  period: RelativeTimePeriod;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainContainerHistoryBinSummaryHocChildProps = {
  loading: boolean;
  grain_container: GetGrainContainerHistoryQuery['grain_container'] | null;
};
export const withGetGrainContainerHistoryBinSummaryHoc: AmberHoc<
  WithGetGrainContainerHistoryBinSummaryHocOwnProps,
  WithGetGrainContainerHistoryBinSummaryHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainContainerHistoryBinSummaryHocOwnProps,
      WithGetGrainContainerHistoryBinSummaryHocChildProps,
      typeof component
    >,
    WithGetGrainContainerHistoryBinSummaryHocChildProps
  >({
    options: ({
      container_id,
      container_type,
      period,
      onError,
      pollInterval = 1000 * 60 * 30,
    }) => ({
      onError,
      pollInterval,
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id, container_type, period },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          grain_container: null,
        };
      }
      return {
        loading: loading || false,
        grain_container: data.grain_container
          ? {
              ...data.grain_container,
              telemetry_history: {
                weather: [],
                interior: [],
                headspace: [],
                tiers: null,
                pellets: [],
                fan: [],
                ...data.grain_container.telemetry_history,
              },
            }
          : null,
      };
    },
  })(component as any);
