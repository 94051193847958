import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const InputTextEditor = forwardRef((props: any, ref: any) => {
  const originalValue = props.value;
  console.log('originalValue', originalValue);
  const [inputText, setInputText] = useState(props.value);
  const [hasEditCancel, setHasEditCancel] = useState(false);
  const refContainer = useRef(null);
  const onTextChange = ({ target }) => {
    setInputText(target.value);
  };

  const saveInputText = (event) => {
    hasEditCancel && setHasEditCancel(false);
    event.stopPropagation();
    props.stopEditing();
  };

  const cancelEditInputText = (event) => {
    setInputText(originalValue);
    setTimeout(() => {
      props.stopEditing();
    }, 0);
  };

  useEffect(() => {
    const node: any = refContainer.current && ReactDOM.findDOMNode(refContainer.current);
    if (node) {
      node.focus();
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return inputText;
      },
    };
  });

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
      <input
        ref={refContainer}
        type="text"
        name="inputText"
        value={inputText}
        onChange={onTextChange}
        style={{ width: 246, height: 40, fontSize: 14 }}
      />
      <button
        onClick={saveInputText}
        style={{ width: 41, height: '100%', borderLeft: 'none', borderRight: 'none' }}
      >
        <DoneIcon />
      </button>
      <button onClick={cancelEditInputText} style={{ width: 41, height: '100%' }}>
        <CloseIcon />
      </button>
    </div>
  );
});

export { InputTextEditor };
