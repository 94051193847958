import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { DiagnosticsDashboard } from './DiagnosticsDashboard';
import { DiagTests } from './DiagTests';

export const DiagnosticsRoutes = (props: RouteComponentProps) => {
  const {
    match: { url },
  } = props;
  return (
    <Switch>
      <Route
        exact
        path={`${url}`}
        render={() => <DiagnosticsDashboard basePath="/devices" {...props} />}
      />
      <Route
        exact
        path={`${url}/fc/:core_id`}
        render={(props) => <DiagTests routeParams={props.match.params} {...props} />}
      />
      <Route render={() => <Redirect to={`${url}`} />} />
    </Switch>
  );
};
