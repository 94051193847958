import { Button, Grid, Theme, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { RemoveCircleOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import format from 'date-fns/format';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import * as yup from 'yup';

import {
  PileCableLinkFragmentFragment,
  withUnlinkPileCableHoc,
  WithUnlinkPileCableHocChildProps,
} from '../../../api';
import { amber_amber } from '../../../style';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../../util/form2/BaseForm';
import { ErrorBox } from '../../util/form2/ErrorBox';

const formatDate = (value) => format(value, 'YYYY-MM-DD');

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    whiteSpace: 'normal',
    wordWrap: 'normal',
    textAlign: 'center',
    paddingLeft: 12,
    paddingRight: 12,
  },
  button_icon: {
    marginRight: theme.spacing(1),
  },
  alias: { paddingLeft: 10, paddingRight: 10 },
  cable_id: { paddingLeft: 10, paddingRight: 10 },
  divider: { marginTop: 10 },
  yellow: { color: amber_amber },
  red: { color: red['500'] },
  field: { width: 150 },
  root: { display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20 },
  center: { textAlign: 'center' },
  grid: { maxWidth: 500 },
  table: { textAlign: 'center', maxWidth: 500 },
}));

type Values = {
  pile_cable_link_id: number | null;
};

const validationSchema = yup.object().shape({
  pile_cable_link_id: yup
    .number()
    .typeError('A number is required')
    .integer()
    .required(),
});
const Form = ({
  cable_id,
  pile_cable_links,
}: {
  cable_id?: number | string;
  pile_cable_links: PileCableLinkFragmentFragment[];
}) => {
  const classes = useStyles();
  const { values, submitForm, setFieldValue, isSubmitting } = useFormikContext<Values>();
  return (
    <BaseForm submitting_message="Saving changes...">
      <Typography variant="h6">Current Assignments</Typography>
      <Grid container spacing={2} direction="column" classes={{ container: classes.table }}>
        <ErrorBox />
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={3}>
            Cable ID
          </Grid>
          <Grid item xs={3}>
            Cable Name
          </Grid>
          <Grid item xs={3}>
            Start Date
          </Grid>
          <Grid item xs={3}>
            Unassign
          </Grid>
        </Grid>
        {pile_cable_links.map((pcl: PileCableLinkFragmentFragment) => {
          const highlight_row = cable_id && Number(cable_id) === pcl.cable_id;
          const package_alias =
            (pcl.cable && pcl.cable.pellet_group && pcl.cable.pellet_group.package_alias) || '';
          return (
            <Grid
              key={pcl.pile_cable_link_id}
              item
              xs={12}
              container
              spacing={2}
              alignItems="center"
              classes={highlight_row ? { item: classes.yellow } : undefined}
            >
              {/* on-th-label-readable "L-55"-esque hash of the id  */}
              <Grid item xs={3}>
                {package_alias}
              </Grid>
              <Grid item xs={3}>
                {pcl.alias}
              </Grid>
              <Grid item xs={3}>
                {formatDate(pcl.start_epoch)}
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  onClick={async () => {
                    await setFieldValue('pile_cable_link_id', pcl.pile_cable_link_id);
                    await submitForm();
                  }}
                  disabled={isSubmitting}
                  className={highlight_row ? classes.yellow : undefined}
                >
                  <RemoveCircleOutline classes={{ root: classes.button_icon }} />
                  UNASSIGN
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </BaseForm>
  );
};

export const PileCableRemovalFormBase = ({
  pile_cable_links,
  unlinkPileCable,
  cable_id,
  ...props
}: WithUnlinkPileCableHocChildProps &
  FormikWrapperHandlerProps<Values, PileCableLinkFragmentFragment> & {
    pile_cable_links: PileCableLinkFragmentFragment[];
    cable_id?: number | string;
  }) => {
  const submitCallback = useCallback(
    async (values) => {
      const { pile_cable_link_id } = validationSchema.validateSync(values);
      return await unlinkPileCable({
        pile_cable_link_id: pile_cable_link_id as number,
      });
    },
    [unlinkPileCable]
  );
  return (
    <FormikWrapper<Values, PileCableLinkFragmentFragment>
      {...props}
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        pile_cable_link_id: null,
      }}
      onSubmit={submitCallback}
    >
      <Form cable_id={cable_id} pile_cable_links={pile_cable_links} />
    </FormikWrapper>
  );
};

export const PileCableRemovalForm = withUnlinkPileCableHoc(PileCableRemovalFormBase);
