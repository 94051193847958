import { ApolloError } from 'apollo-client';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { pushNav } from '../../../action';
import { AccountFragmentFragment, ViewerFragmentFragment } from '../../../api';
import { ContainerTypeLegacy } from '../../../util';
import { AuthenticatedRoute } from '../../util';
import { PileRouteSuffix } from '../constant';
import { DevicesLayout } from '../DevicesLayout';
import { ExportTelemetryForm } from '../ExportTelemetryForm';
import { PileCableForm } from './PileCableForm';
import { PileDashboard } from './PileDashboard';
import { CreatePileForm, UpdatePileFormGql } from './PileForm';

type PileRoutesProps = RouteComponentProps & {
  account_id: number;
  pile_id: number | null;
  mobile_width?: boolean;
  pathname: string;
  cable_id: string | number;
  accessFailureRedirect?: (
    account: AccountFragmentFragment,
    viewer: ViewerFragmentFragment
  ) => string;
  onError: (error: ApolloError) => void;
  goCreateBarge?: () => void;
  goCreatePile?: () => void;
  goCreateGrainBin?: () => void;
  onSelectContainer?: (args: any) => void;
};

const validatePileSupportAccess = (
  account: AccountFragmentFragment,
  { account: { pile_support } }: ViewerFragmentFragment
) => account && account.pile_support && pile_support;

export const PileRoutes = ({
  match: { url },
  account_id,
  pile_id,
  cable_id,
  accessFailureRedirect,
  onError,
  mobile_width,
  pathname,
  goCreateBarge,
  goCreatePile,
  goCreateGrainBin,
  onSelectContainer,
}: PileRoutesProps) => {
  const dispatch = useDispatch();
  const goDashboard = useCallback(
    (selected?: {
      container_id?: number;
      container_type?: ContainerTypeLegacy;
      container_name?: string;
    }) => dispatch(pushNav({ path: `${url}/${PileRouteSuffix.dashboard}`, params: { selected } })),
    [url, dispatch]
  );
  const goPileCableForm = useCallback(
    (selected?: { cable_id: number; container_id: number; container_type: ContainerTypeLegacy }) =>
      dispatch(pushNav({ path: `${url}/${PileRouteSuffix.cables}`, params: { selected } })),
    [url, dispatch]
  );
  return (
    <AuthenticatedRoute
      account_id={account_id}
      validateAccess={validatePileSupportAccess}
      accessFailureRedirect={accessFailureRedirect}
      render={({ viewer }) => (
        <Switch>
          <Route
            path={`${url}/${PileRouteSuffix.create}`}
            render={() =>
              account_id ? (
                <CreatePileForm
                  account_id={account_id}
                  onSubmitSuccess={({ pile_id, alias }) =>
                    goDashboard({
                      container_name: alias,
                      container_id: pile_id,
                      container_type: ContainerTypeLegacy.pile,
                    })
                  }
                />
              ) : null
            }
          />
          {pile_id && (
            <Route
              path={`${url}/${PileRouteSuffix.cable}`}
              render={() =>
                cable_id ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <PileCableForm pile_id={pile_id} cable_id={cable_id} />
                  </div>
                ) : (
                  <Redirect to={`${url}/${PileRouteSuffix.cables}`} />
                )
              }
            />
          )}
          {pile_id && (
            <Route
              path={`${url}/${PileRouteSuffix.cables}`}
              render={() => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <PileCableForm pile_id={pile_id} />
                </div>
              )}
            />
          )}
          {pile_id && (
            <Route
              path={`${url}/${PileRouteSuffix.devices}`}
              render={() => (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <DevicesLayout
                    container_id={pile_id}
                    container_type={ContainerTypeLegacy.pile}
                    onError={onError}
                    onHubAssignmentSuccess={goDashboard}
                    onAssignFanControllerSuccess={() => {}}
                    onUnassignFanControllerSuccess={() => {}}
                  />
                </div>
              )}
            />
          )}
          {pile_id && (
            <Route
              path={`${url}/${PileRouteSuffix.export_telemetry}`}
              render={() => (
                <ExportTelemetryForm
                  container_id={pile_id}
                  container_type={ContainerTypeLegacy.pile}
                  onError={onError}
                />
              )}
            />
          )}
          {pile_id && (
            <Route
              path={`${url}/${PileRouteSuffix.settings}`}
              render={() => (
                <UpdatePileFormGql
                  pile_id={pile_id}
                  onError={onError}
                  onSubmitSuccess={({ pile_id, alias }) =>
                    goDashboard({
                      container_name: alias,
                      container_id: pile_id,
                      container_type: ContainerTypeLegacy.pile,
                    })
                  }
                  onClickPileCableLinks={({ cable_id }) => {
                    pile_id &&
                      goPileCableForm({
                        cable_id,
                        container_id: pile_id,
                        container_type: ContainerTypeLegacy.pile,
                      });
                  }}
                />
              )}
            />
          )}
          <Route
            path={`${url}/${PileRouteSuffix.dashboard}`}
            render={() => (
              <PileDashboard
                pathname={pathname}
                onSelectContainer={onSelectContainer}
                goCreatePile={goCreatePile}
                goCreateGrainBin={goCreateGrainBin}
                goCreateBarge={goCreateBarge}
                pile_id={pile_id}
                onError={onError}
                viewer={viewer}
                mobile_width={mobile_width}
                goPileCableForm={({ cable_id }) => {
                  pile_id &&
                    goPileCableForm({
                      cable_id,
                      container_id: pile_id,
                      container_type: ContainerTypeLegacy.pile,
                    });
                }}
              />
            )}
          />
          <Route
            render={() => (
              <Redirect
                to={`${url}/${pile_id ? PileRouteSuffix.dashboard : PileRouteSuffix.create}`}
              />
            )}
          />
        </Switch>
      )}
    />
  );
};
