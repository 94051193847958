import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  BargeCoverPelletLinkFragmentFragment as Result,
  withGetBargeCoverPelletLinks as HOC,
} from '../__generated';

export type WithGetBargeCoverPelletLinksHocOwnProps = {
  barge_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetBargeCoverPelletLinksHocChildProps = {
  loading: boolean;
  barge_cover_pellet_links: Result[] | null;
};
export const withGetBargeCoverPelletLinksHoc: AmberHoc<
  WithGetBargeCoverPelletLinksHocOwnProps,
  WithGetBargeCoverPelletLinksHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetBargeCoverPelletLinksHocOwnProps,
      WithGetBargeCoverPelletLinksHocChildProps,
      typeof component
    >,
    WithGetBargeCoverPelletLinksHocChildProps
  >({
    options: ({ barge_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      variables: { barge_id },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          barge_cover_pellet_links: null,
        };
      }
      return {
        loading: loading || false,
        barge_cover_pellet_links: data.barge_cover_pellet_links || null,
      };
    },
  })(component as any);
