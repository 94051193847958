import { useMediaQuery, useTheme } from '@material-ui/core';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { chunk } from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  FanRunWindowRecommendedOption,
  OpsViewVisibilityPrefsFragmentFragment,
  withGetContainersAerationScheduleHoc,
  WithGetContainersAerationScheduleHocChildProps,
  withGetContainersGrainLevelHoc,
  WithGetContainersGrainLevelHocChildProps,
  withGetContainersLastAerationRunHoc,
  WithGetContainersLastAerationRunHocChildProps,
  withGetContainersLastWeekAirspaceHistoryHoc,
  WithGetContainersLastWeekAirspaceHistoryHocChildProps,
  withGetContainersWeatherHoc,
  WithGetContainersWeatherHocChildProps,
} from '../../../../core/src/api';
import { OperationsViewInfoContext } from '../../../../core/src/contexts';
import { light_gray_10 } from '../../../../core/src/style';
import {
  aerationWindowTimeRemainComparator,
  automationPeriodLeftComparator,
  durationComparator,
  endDateComparator,
  grainVolumeCellComparator,
  startDateComparator,
  weatherEmcComparator,
  weatherHumidityComparator,
  weatherIconComparator,
  weatherTempComparator,
} from './comparators';
import { OpsViewVisibilityPrefsType } from './GrainOperations';
import { PrefJsonItem } from './manage-sites/ManageSitesTable';
import {
  getHasScheduledRunInterrupted,
  performColsAlterPrefChanges,
  performSitesRowPrefChanges,
} from './OperationsViewTableHelpers';
import { useStyles } from './OperationsViewTableStyles';
import {
  AirspaceCellRenderer,
  AirspaceHeaderCellRenderer,
  AutoCellRenderer,
  BinCellRenderer,
  FanHistoryDurationCellRenderer,
  FanHistoryEndCellRenderer,
  FanHistoryStartCellRenderer,
  FanScheduleDurationCellRenderer,
  FanScheduleEndCellRenderer,
  FanScheduleStartCellRenderer,
  GrainTypeCellRenderer,
  GrainVolumeCellRenderer,
  ModeCellRenderer,
  OperationsHeader,
  SiteBinsFilterComponent,
  SiteCellRenderer,
  StatusCellRenderer,
} from './ops-table-components';
import {
  WeatherCellRenderer,
  WeatherHeaderCellRenderer,
  WeatherIconCellRenderer,
  WeatherInfoPeriod,
} from './ops-table-components/weather';

const isExapndRowColumn = (params, colField): boolean =>
  params.data && !Object.keys(params.data).includes(colField);

export type GridContextType = {
  isAerationSchedulesLoading: boolean;
  isContainersLastAerationRunLoading: boolean;
  isContainersGrainLevelLoading: boolean;
  isLastWeekAirspaceHistoryLoading: boolean;
  hasGridApiReady: boolean;
};

const OperationsViewTableBase: FunctionComponent<
  {
    operations_view_cols_visibility_prefs: OpsViewVisibilityPrefsFragmentFragment | null;
    valid_operations_view_rows_visibility_prefs: PrefJsonItem[] | null;
    grain_bin_ids: number[];
    sitesHierarchyData: any;
    sortedGrainBinsHierarchyData: any;
    goContainer: (args: any) => void;
    operationsViewInfo: {
      sitesCount: number;
      binsCount: number;
      fansCount: number;
      allSitesGrainLevelCount: number;
    };
    groupBySites: boolean;
    setGroupBySites: Function;
  } & WithGetContainersAerationScheduleHocChildProps &
    WithGetContainersGrainLevelHocChildProps &
    WithGetContainersLastWeekAirspaceHistoryHocChildProps &
    WithGetContainersLastAerationRunHocChildProps &
    WithGetContainersWeatherHocChildProps
> = ({
  sitesHierarchyData,
  sortedGrainBinsHierarchyData,
  groupBySites,
  setGroupBySites,
  goContainer,
  operationsViewInfo,
  aeration_schedules_loading,
  containers_aeration_schedule,
  containers_grain_level_loading,
  containers_grain_level,
  containers_last_week_airspace_history_loading,
  containers_last_week_airspace_history,
  containers_last_aeration_run_loading,
  containers_last_aeration_run,
  containers_weather_loading,
  containers_weather,
  operations_view_cols_visibility_prefs,
  valid_operations_view_rows_visibility_prefs,
  loadMoreContainersWeather,
  grain_bin_ids,
}) => {
  console.log('inside OperationsViewTable', {
    containers_aeration_schedule,
    aeration_schedules_loading,
    containers_last_week_airspace_history_loading,
    containers_weather_loading,
    containers_weather,
  });
  console.log('sitesHierarchyData', sitesHierarchyData);
  console.log('sortedGrainBinsHierarchyData', sortedGrainBinsHierarchyData);

  const classes = useStyles();
  const theme = useTheme();
  const [rowDataForGroupBySites, setRowDataForGroupBySites] = useState<any>(sitesHierarchyData);
  const [rowDataForBinsOnly, setRowDataForBinsOnly] = useState<any>(sortedGrainBinsHierarchyData);
  const { setInfo: setOperationsViewInfo } = useContext(OperationsViewInfoContext);
  const isWideScreen = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const gridRef = useRef<any>();
  const [gridApi, setGridApi] = useState<any>(null);
  const [hasInitialGrainIdsWeatherLoaded, setHasInitialGrainIdsWeatherLoaded] = useState(false);
  const rowStyle = { background: 'white' };
  const getRowStyle = (params) => {
    const isSiteNode =
      params.node && params.node.data && params.node.data.siteHierarchy.length === 1;
    return { background: isSiteNode ? light_gray_10 : 'white' };
  };

  const columnDefs = useMemo(() => {
    return [
      ...(groupBySites
        ? []
        : [
            {
              colId: 'ag-Grid-AutoColumn',
              headerName: 'Bins',
              // spanHeaderHeight: true,
              field: 'bin_name',
              cellRenderer: BinCellRenderer,
              cellRendererParams: {
                goContainer,
              },
              headerComponent: OperationsHeader,
              headerComponentParams: {
                groupBySites,
                setGroupBySites,
              },
              maxWidth: 331,
              minWidth: 331,
              pinned: isWideScreen ? ('left' as any) : false,
              suppressMovable: true,
              // headerCheckboxSelection: true,
              checkboxSelection: true,
              sortable: false,
              floatingFilter: true,
              filter: 'agSetColumnFilter',
              floatingFilterComponent: SiteBinsFilterComponent,
              floatingFilterComponentParams: {
                suppressFilterButton: true,
              },
              filterParams: {
                itemsType: 'binsOnly',
              },
              cellStyle: () => {
                return {
                  fontSize: '14px',
                  paddingLeft: 18,
                };
              },
            },
          ]),
      {
        headerName: 'Fan Status',
        groupId: 'fanStatus',
        children: [
          {
            colId: 'fan_status_col_1',
            headerName: 'Status',
            headerClass: classes.groupHeader,
            field: 'bin_fan_status',
            columnGroupShow: 'open',
            colSpan: (params) => {
              console.log('params in Fan Status', params);
              return isExapndRowColumn(params, 'bin_fan_status') ? 3 : 1;
            },
            cellRenderer: StatusCellRenderer,
            cellStyle: (params) => {
              return {
                borderRight: isExapndRowColumn(params, 'bin_fan_status')
                  ? '1px solid var(--ag-border-color, #babfc7)'
                  : '1px dashed var(--ag-border-color, #babfc7)',
                padding: isExapndRowColumn(params, 'bin_fan_status') ? 0 : undefined,
              };
            },
            width: 128,
            comparator: aerationWindowTimeRemainComparator,
          },
          {
            colId: 'fan_status_col_2',
            headerName: 'Mode',
            headerClass: classes.groupHeader,
            field: 'mode',
            columnGroupShow: 'open',
            cellRenderer: ModeCellRenderer,
            cellStyle: (params) => {
              return {
                borderRight: '1px dashed var(--ag-border-color, #babfc7)',
              };
            },
            width: 65,
          },
          {
            colId: 'fan_status_col_3',
            headerName: 'Auto.',
            field: 'automation_info',
            columnGroupShow: 'open',
            cellRenderer: AutoCellRenderer,
            width: 65,
            comparator: automationPeriodLeftComparator,
          },
        ],
      },
      {
        headerName: 'Airspace',
        groupId: 'airspace',
        children: [
          {
            colId: 'airspace_col_1',
            headerName: 'Airspace',
            field: 'last_week_airspace_history',
            headerComponent: AirspaceHeaderCellRenderer,
            cellRenderer: AirspaceCellRenderer,
            width: 162,
            cellStyle: (params) => {
              return {
                padding: 0,
              };
            },
            suppressMovable: true,
          },
        ],
      },
      {
        headerName: 'Grain Level',
        groupId: 'grainLevel',
        children: [
          {
            colId: 'grain_level_col_1',
            headerName: 'Type',
            headerClass: classes.groupHeader,
            field: 'grain_type',
            columnGroupShow: 'open',
            cellRenderer: GrainTypeCellRenderer,
            colSpan: (params) => {
              return isExapndRowColumn(params, 'grain_level') ? 2 : 1;
            },
            cellStyle: (params) => {
              return {
                lineHeight: '20px',
                borderRight: isExapndRowColumn(params, 'grain_level')
                  ? '1px solid var(--ag-border-color, #babfc7)'
                  : '1px dashed var(--ag-border-color, #babfc7)',
              };
            },
            width: 52,
          },
          {
            colId: 'grain_level_col_2',
            headerName: 'Volume',
            field: 'grain_level',
            columnGroupShow: 'open',
            cellRenderer: GrainVolumeCellRenderer,
            width: 110,
            cellStyle: (params) => {
              return {
                lineHeight: '20px',
              };
            },
            comparator: grainVolumeCellComparator,
          },
        ],
      },
      {
        headerName: 'Fan Schedule',
        groupId: 'fanSchedule',
        children: [
          {
            colId: 'fan_schedule_col_1',
            headerName: 'Start',
            headerClass: classes.groupHeader,
            field: 'fan_schedule',
            columnGroupShow: 'open',
            cellRenderer: FanScheduleStartCellRenderer,
            colSpan: (params) => {
              return isExapndRowColumn(params, 'fan_schedule') ? 3 : 1;
            },
            cellStyle: (params) => {
              return {
                lineHeight: '20px',
                borderRight: isExapndRowColumn(params, 'fan_schedule')
                  ? '1px solid var(--ag-border-color, #babfc7)'
                  : '1px dashed var(--ag-border-color, #babfc7)',
              };
            },
            width: 78,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
              const newValueA = valueA && valueA.length > 0 ? valueA[0] : null;
              const newValueB = valueB && valueB.length > 0 ? valueB[0] : null;
              return startDateComparator(newValueA, newValueB, nodeA, nodeB, isDescending);
            },
          },
          {
            colId: 'fan_schedule_col_2',
            headerName: 'Stop',
            headerClass: classes.groupHeader,
            field: 'fan_schedule',
            columnGroupShow: 'open',
            cellRenderer: FanScheduleEndCellRenderer,
            cellStyle: (params) => {
              return {
                lineHeight: '20px',
                borderRight: isExapndRowColumn(params, 'fan_schedule')
                  ? '1px solid var(--ag-border-color, #babfc7)'
                  : '1px dashed var(--ag-border-color, #babfc7)',
              };
            },
            width: 78,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
              const newValueA = valueA && valueA.length > 0 ? valueA[0] : null;
              const newValueB = valueB && valueB.length > 0 ? valueB[0] : null;
              return endDateComparator(newValueA, newValueB, nodeA, nodeB, isDescending);
            },
          },
          {
            colId: 'fan_schedule_col_3',
            headerName: 'Duration',
            field: 'fan_schedule',
            columnGroupShow: 'open',
            cellRenderer: FanScheduleDurationCellRenderer,
            cellStyle: (params) => {
              return {
                lineHeight: '20px',
              };
            },
            width: 78,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
              const newValueA = valueA && valueA.length > 0 ? valueA[0] : null;
              const newValueB = valueB && valueB.length > 0 ? valueB[0] : null;
              return durationComparator(newValueA, newValueB, nodeA, nodeB, isDescending);
            },
          },
        ],
      },
      {
        headerName: 'Fan History',
        groupId: 'fanHistory',
        children: [
          {
            colId: 'fan_history_col_1',
            headerName: 'Prev. Start',
            headerClass: classes.groupHeader,
            field: 'last_aeration_run',
            columnGroupShow: 'open',
            cellRenderer: FanHistoryStartCellRenderer,
            colSpan: (params) => {
              return isExapndRowColumn(params, 'last_aeration_run') ? 3 : 1;
            },
            cellStyle: (params) => {
              return {
                lineHeight: '20px',
                borderRight: isExapndRowColumn(params, 'last_aeration_run')
                  ? '1px solid var(--ag-border-color, #babfc7)'
                  : '1px dashed var(--ag-border-color, #babfc7)',
              };
            },
            width: 78,
            comparator: startDateComparator,
          },
          {
            colId: 'fan_history_col_2',
            headerName: 'Prev. Stop',
            headerClass: classes.groupHeader,
            field: 'last_aeration_run',
            columnGroupShow: 'open',
            cellRenderer: FanHistoryEndCellRenderer,
            cellStyle: (params) => {
              return {
                lineHeight: '20px',
                borderRight: isExapndRowColumn(params, 'last_aeration_run')
                  ? '1px solid var(--ag-border-color, #babfc7)'
                  : '1px dashed var(--ag-border-color, #babfc7)',
              };
            },
            width: 78,
            comparator: endDateComparator,
          },
          {
            colId: 'fan_history_col_3',
            headerName: 'Duration',
            field: 'last_aeration_run',
            columnGroupShow: 'open',
            cellRenderer: FanHistoryDurationCellRenderer,
            cellStyle: (params) => {
              return {
                lineHeight: '20px',
              };
            },
            width: 78,
            comparator: durationComparator,
          },
        ],
      },
      {
        headerName: 'Weather',
        headerGroupComponent: WeatherHeaderCellRenderer,
        headerGroupComponentParams: {},
        groupId: 'weather',
        children: [
          {
            colId: 'weather_col_1',
            headerName: 'Icon',
            headerClass: classes.groupHeader,
            field: 'weather',
            columnGroupShow: 'open',
            colSpan: (params) => {
              return isExapndRowColumn(params, 'weather') ? 4 : 1;
            },
            cellRenderer: WeatherIconCellRenderer,
            cellStyle: (params) => {
              return {
                borderRight: isExapndRowColumn(params, 'weather')
                  ? '1px solid var(--ag-border-color, #babfc7)'
                  : '1px dashed var(--ag-border-color, #babfc7)',
                padding: isExapndRowColumn(params, 'weather') ? 0 : undefined,
              };
            },
            width: 58,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
              const weather_period =
                gridApi && gridApi.weather_period
                  ? gridApi.weather_period
                  : WeatherInfoPeriod.today;
              return weatherIconComparator(
                valueA,
                valueB,
                nodeA,
                nodeB,
                isDescending,
                weather_period
              );
            },
          },
          {
            colId: 'weather_col_2',
            headerName: 'Temp.',
            headerClass: classes.groupHeader,
            field: 'weather',
            columnGroupShow: 'open',
            cellRenderer: WeatherCellRenderer,
            cellRendererParams: {
              weatherParam: 'temperature',
            },
            cellStyle: (params) => {
              return {
                borderRight: '1px dashed var(--ag-border-color, #babfc7)',
              };
            },
            width: 58,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
              const weather_period =
                gridApi && gridApi.weather_period
                  ? gridApi.weather_period
                  : WeatherInfoPeriod.today;
              return weatherTempComparator(
                valueA,
                valueB,
                nodeA,
                nodeB,
                isDescending,
                weather_period
              );
            },
          },
          {
            colId: 'weather_col_3',
            headerName: 'Hum.',
            headerClass: classes.groupHeader,
            field: 'weather',
            columnGroupShow: 'open',
            cellRenderer: WeatherCellRenderer,
            cellRendererParams: {
              weatherParam: 'humidity',
            },
            cellStyle: (params) => {
              return {
                borderRight: '1px dashed var(--ag-border-color, #babfc7)',
              };
            },
            width: 58,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
              const weather_period =
                gridApi && gridApi.weather_period
                  ? gridApi.weather_period
                  : WeatherInfoPeriod.today;
              return weatherHumidityComparator(
                valueA,
                valueB,
                nodeA,
                nodeB,
                isDescending,
                weather_period
              );
            },
          },
          {
            colId: 'weather_col_4',
            headerName: 'EMC',
            field: 'weather',
            columnGroupShow: 'open',
            cellRenderer: WeatherCellRenderer,
            cellRendererParams: {
              weatherParam: 'emc',
            },
            width: 58,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
              const weather_period =
                gridApi && gridApi.weather_period
                  ? gridApi.weather_period
                  : WeatherInfoPeriod.today;
              return weatherEmcComparator(
                valueA,
                valueB,
                nodeA,
                nodeB,
                isDescending,
                weather_period
              );
            },
          },
        ],
      },
    ];
  }, [groupBySites]);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'Sites',
      // spanHeaderHeight: true,
      // headerClass: isWideScreen ? '' : classes.autoGroupHeader,
      maxWidth: 331,
      minWidth: 331,
      headerComponent: OperationsHeader,
      headerComponentParams: {
        groupBySites,
        setGroupBySites,
      },
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        suppressEnterExpand: true,
        innerRenderer: SiteCellRenderer,
        innerRendererParams: { goContainer },
        innerRendererSelector: (params) => {
          const containsFanStatusValue = Boolean(params.data.bin_fan_status);
          console.log('params', params);
          if (containsFanStatusValue) {
            return { component: BinCellRenderer };
          }
          return;
        },
      },
      //   headerCheckboxSelection: true,
      floatingFilter: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        itemsType: 'sitesAndbins',
        treeList: true,
        keyCreator: (params) => {
          console.log('params in keyCreator', params);
          return params.value.join('#');
        },
      },

      floatingFilterComponent: SiteBinsFilterComponent,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      sortable: true,
      pinned: isWideScreen ? ('left' as any) : false,
    };
  }, [groupBySites]);

  const defaultColDef = useMemo(
    () => ({
      cellStyle: { textAlign: 'center' },
      sortable: true,
      suppressMenu: true,
      wrapText: true,
      unSortIcon: true,
      suppressMovable: true,
      wrapHeaderText: true,
      floatingFilter: false,
      // autoHeaderHeight: false,
      // enableCellChangeFlash: false,
      // autoHeight: false,
    }),
    []
  );

  const getDataPath = useMemo(() => {
    return (data) => {
      return data.siteHierarchy;
    };
  }, []);

  const onGridReady = (params) => {
    console.log('onGridReady', params);
    setGridApi(params.api);
    operations_view_cols_visibility_prefs &&
      performColsAlterPrefChanges(params, operations_view_cols_visibility_prefs, isWideScreen);
  };

  const getAerationMode = (
    scheduleType: FanRunWindowRecommendedOption | undefined | null,
    rowData: any
  ) => {
    if (!scheduleType || rowData.bin_fan_status.length === 0) return null;

    console.log(' rowData.bin_fan_status', rowData.bin_fan_status);
    const any_fans_running: boolean = rowData.bin_fan_status.some(
      (fanStatusObj) => fanStatusObj.is_on
    );
    const has_override: boolean = rowData.bin_fan_status.some(
      (fanStatusObj) => fanStatusObj.has_override
    );
    const hasManualMode = any_fans_running && has_override;

    return hasManualMode ? FanRunWindowRecommendedOption.Manual : scheduleType;
  };

  // For aeration schedule
  useEffect(() => {
    console.log('aeration_schedules_loading', aeration_schedules_loading);
    if (!aeration_schedules_loading && gridApi) {
      gridApi.forEachNode((rowNode) => {
        const original = rowNode.data;
        const updated = containers_aeration_schedule.find(
          (schedule) => schedule && schedule.grain_bin_id === original.grain_bin_id
        );
        console.log('original', original, updated);
        if (updated) {
          rowNode.setData({
            ...original,
            has_scheduled_run_interrupted: getHasScheduledRunInterrupted(original, updated),
            mode: getAerationMode(updated.aeration_schedule_type, original),
            fan_schedule: updated.aeration_schedule,
          });
        }
      });
      gridApi.refreshCells({ force: true });
    }
  }, [gridApi, aeration_schedules_loading]);

  // For grain level
  useEffect(() => {
    console.log('containers_grain_level_loading', containers_grain_level_loading);
    if (!containers_grain_level_loading && gridApi) {
      let allSitesGrainLevelCount = 0;
      gridApi.forEachNode((rowNode) => {
        const original = rowNode.data;
        const updated = containers_grain_level.find(
          (grainLevel) => grainLevel && grainLevel.grain_bin_id === original.grain_bin_id
        );
        console.log('original', original, updated);
        if (updated) {
          allSitesGrainLevelCount += updated.grain_level
            ? updated.grain_level.grain_level_bushels
            : 0;
          rowNode.setData({
            ...original,
            grain_level: {
              ...updated.grain_level,
            },
          });
        }
      });
      gridApi.refreshCells({ force: true });
      console.log('operationsViewInfo', operationsViewInfo);
      setOperationsViewInfo({
        ...operationsViewInfo,
        allSitesGrainLevelCount,
      });
    }
  }, [gridApi, containers_grain_level_loading]);

  // For Airspace
  useEffect(() => {
    console.log(
      'containers_last_week_airspace_history_loading',
      containers_last_week_airspace_history_loading
    );
    if (!containers_last_week_airspace_history_loading && gridApi) {
      gridApi.forEachNode((rowNode) => {
        const original = rowNode.data;
        const updated = containers_last_week_airspace_history.find(
          (history) => history && history.grain_bin_id === original.grain_bin_id
        );
        console.log('original', original, updated);
        if (updated) {
          rowNode.setData({
            ...original,
            last_week_co2_alert_sent_on: updated.last_week_co2_alert_sent_on,
            last_week_airspace_history: [...updated.last_week_airspace_history],
          });
        }
      });
      gridApi.refreshCells({ force: true });
    }
  }, [gridApi, containers_last_week_airspace_history_loading]);

  // For Fan History
  useEffect(() => {
    console.log('containers_last_aeration_run_loading', containers_last_aeration_run_loading);

    if (!containers_last_aeration_run_loading && gridApi) {
      gridApi.forEachNode((rowNode) => {
        const original = rowNode.data;
        const updated = containers_last_aeration_run.find(
          (run) => run && run.grain_bin_id === original.grain_bin_id
        );
        console.log('original', original, updated);
        if (updated) {
          rowNode.setData({
            ...original,
            last_aeration_run: updated.last_aeration_run,
          });
        }
      });
      gridApi.refreshCells({ force: true });
    }
  }, [gridApi, containers_last_aeration_run_loading]);

  // For Weather
  useEffect(() => {
    console.log('containers_weather_loading', { containers_weather_loading });

    if (!containers_weather_loading && gridApi) {
      console.log('loaded containers_weather', { containers_weather });
      gridApi.forEachNode((rowNode) => {
        const original = rowNode.data;
        const updated = containers_weather.find(
          (weather) => weather && weather.grain_bin_id === original.grain_bin_id
        );
        if (updated) {
          rowNode.setData({
            ...original,
            weather: updated,
          });
        }
      });
      gridApi.refreshCells({ force: true });
      !hasInitialGrainIdsWeatherLoaded && setHasInitialGrainIdsWeatherLoaded(true);
    }
  }, [gridApi, containers_weather_loading, JSON.stringify(containers_weather)]);

  // For Weather
  useEffect(() => {
    if (hasInitialGrainIdsWeatherLoaded) {
      console.log(
        'hasInitialGrainIdsWeatherLoaded loaded',
        hasInitialGrainIdsWeatherLoaded,
        containers_weather
      );
      const grain_ids_groups = chunk(grain_bin_ids, 5);
      const loadMore = async (grain_ids_group) => {
        await loadMoreContainersWeather(grain_ids_group);
      };
      for (const grain_ids_group of grain_ids_groups) {
        loadMore(grain_ids_group);
      }
    }
  }, [hasInitialGrainIdsWeatherLoaded, groupBySites]);

  useEffect(() => {
    if (gridApi && valid_operations_view_rows_visibility_prefs) {
      performSitesRowPrefChanges({
        groupBySites,
        setRowDataForGroupBySites,
        setRowDataForBinsOnly,
        gridApi,
        valid_operations_view_rows_visibility_prefs,
      });
    }
  }, [gridApi]);

  console.log('aeration_schedules_loading', aeration_schedules_loading, gridApi);
  console.log('rowDataForGroupBySites in ops view', rowDataForGroupBySites);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: isMobileScreen ? 'calc(100vh - 136px)' : 'calc(100vh - 210px)',
        flexDirection: 'column',
        marginLeft: 20,
        marginRight: 20,
        width: '95%',
      }}
    >
      <div
        className="ag-theme-alpine"
        style={{
          height: isMobileScreen ? 'calc(100vh - 136px)' : 'calc(100vh - 210px)',
          width: '100%',
        }}
      >
        {groupBySites ? (
          <AgGridReact
            key="sitesBinsView"
            className={classes.conatiner}
            rowHeight={58}
            ref={gridRef}
            rowData={rowDataForGroupBySites}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            treeData={true}
            getDataPath={getDataPath}
            suppressRowTransform={true}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            suppressCellSelection={true}
            groupSelectsChildren={true}
            groupSelectsFiltered={true}
            onGridReady={onGridReady}
            suppressMakeColumnVisibleAfterUnGroup={true}
            suppressContextMenu={true}
            localeText={{ noRowsToShow: 'No Sites To Show' }}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
            // excludeChildrenWhenTreeDataFiltering={true}
          />
        ) : (
          <AgGridReact
            key="binsViewOnly"
            className={classes.conatiner}
            rowHeight={58}
            rowData={rowDataForBinsOnly}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressRowTransform={true}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            suppressCellSelection={true}
            onGridReady={onGridReady}
            suppressContextMenu={true}
            localeText={{ noRowsToShow: 'No Bins To Show' }}
            rowStyle={rowStyle}
          />
        )}
      </div>
    </div>
  );
};

export const OperationsViewTable = withGetContainersWeatherHoc(
  withGetContainersGrainLevelHoc(
    withGetContainersLastWeekAirspaceHistoryHoc(
      withGetContainersLastAerationRunHoc(
        withGetContainersAerationScheduleHoc(OperationsViewTableBase)
      )
    )
  )
);
