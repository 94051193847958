import { MassUnitType, TestWeightUnitType } from '../../../../../core/src/api';
import {
  getUserSelectedMassUnit,
  getUserSelectedTestWeightUnit,
  UnitsConverter,
} from '../../../../../core/src/util';

export const getWeightInSelectedUnit = (weight_in_lbs: number) => {
  const massUnit = getUserSelectedMassUnit();
  if (massUnit === MassUnitType.Kilogram) {
    return UnitsConverter.convertPoundToKilogram(weight_in_lbs);
  }
  return weight_in_lbs;
};

export const getTestWeightInSelectedUnit = (test_weight_in_lbs: number) => {
  const testWeightUnit = getUserSelectedTestWeightUnit();
  if (testWeightUnit === TestWeightUnitType.KilogramPerHectoliter) {
    return UnitsConverter.convertPoundPerBuToKilogramPerHectoliter(test_weight_in_lbs);
  }
  return test_weight_in_lbs;
};

export const convertWeightInLbsFromSelectedUnit = (current_weight_value: number) => {
  const massUnit = getUserSelectedMassUnit();
  if (massUnit === MassUnitType.Kilogram) {
    return UnitsConverter.convertKilogramToPound(current_weight_value);
  }
  return current_weight_value;
};

export const convertTestWeightInLbsPerBuFromSelectedUnit = (current_test_weight_value: number) => {
  const testWeightUnit = getUserSelectedTestWeightUnit();
  if (testWeightUnit === TestWeightUnitType.KilogramPerHectoliter) {
    return UnitsConverter.convertKilogramPerHectoliterToPoundPerBu(current_test_weight_value);
  }
  return current_test_weight_value;
};
