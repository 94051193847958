import { Button, Menu, MenuItem } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { UserRole } from 'core/src/api';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { endSession } from '../../action';
import { amber_grey } from '../../style';
import { USER_ACCESS_TEXT } from '../grain-container/aeration';

export const UserMenu: FunctionComponent<{
  icon_color?: string;
  email_address?: string;
  viewer_role?: UserRole;
  onClickUser: () => void;
  onClickPrivacy: () => void;
}> = ({
  icon_color = amber_grey,
  email_address = '',
  onClickUser,
  onClickPrivacy,
  viewer_role,
}) => {
  const dispatch = useDispatch();
  const onClickLogout = useCallback(() => dispatch(endSession()), [dispatch]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <>
      <Button onClick={handleMenuClick}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Person style={{ color: icon_color }} />
            <p style={{ margin: 0 }}>{email_address}</p>
          </div>
          {viewer_role && (
            <div
              style={{
                display: 'block',
                color: amber_grey,
                fontSize: 10,
                fontWeight: 600,
                textTransform: 'none',
                textAlign: 'end',
                textDecoration: 'underline',
              }}
            >
              {USER_ACCESS_TEXT[viewer_role] && `${USER_ACCESS_TEXT[viewer_role]}`}
            </div>
          )}
        </div>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {/* first menu item is automatically selected fro some reason  */}
        <MenuItem key="placeholder" style={{ display: 'none' }} />
        <MenuItem
          onClick={() => {
            handleMenuClose();
            onClickUser();
          }}
        >
          User Account
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            onClickLogout();
          }}
        >
          Logout
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            onClickPrivacy();
          }}
        >
          Privacy Policy
        </MenuItem>
      </Menu>
    </>
  );
};
