import { TouchApp } from '@material-ui/icons';
import React from 'react';

export const SelectModelHeadCellRenderer = () => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <TouchApp color="primary" style={{ width: 24, height: 24 }} />
    </div>
  );
};
