import * as React from 'react';
const BlueTickRadioIcon = (props) => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM7.79 14.29L4.2 10.7C3.81 10.31 3.81 9.68 4.2 9.29C4.59 8.9 5.22 8.9 5.61 9.29L8.5 12.17L15.38 5.29C15.77 4.9 16.4 4.9 16.79 5.29C17.18 5.68 17.18 6.31 16.79 6.7L9.2 14.29C8.82 14.68 8.18 14.68 7.79 14.29Z"
      fill="#2C98F0"
    />
  </svg>
);
export { BlueTickRadioIcon };
