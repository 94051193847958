import { ButtonBase, Checkbox, Grid, Typography } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { DataWarning } from '..';
import { GrainStatusFragmentFragment } from '../../../api';
import { amber_amber } from '../../../style';
import { formatNumber, formatPercent, RangeState } from '../../../util';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    flexGrow: 1,
    alignItems: 'center',
  },
  dot_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: 30,
    paddingBottom: 30,
  },
  color_dot: {
    height: 15,
    width: 15,
    borderRadius: '50%',
  },
  tier_border: {},
  text_container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  text_row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  button_container: {
    display: 'flex',
    flex: 1,
    height: '100%',
  },
  [RangeState.bad]: { color: red['500'], marginTop: 0, marginBottom: 0, marginRight: 5 },
  [RangeState.ok]: { color: amber_amber, marginTop: 0, marginBottom: 0, marginRight: 5 },
  [RangeState.good]: { color: green['500'], marginTop: 0, marginBottom: 0, marginRight: 5 },
  [RangeState.unknown]: { marginTop: 0, marginBottom: 0, marginRight: 5 },
});

export const TierButton: React.FunctionComponent<{
  tier_name: string;
  color: string;
  selected: boolean;
  disabled: boolean;
  border?: boolean;
  grain_status: GrainStatusFragmentFragment | null;
  low_confidence_warning?: string;
  onClick: () => any;
}> = ({
  tier_name,
  color,
  selected,
  disabled,
  border = true,
  onClick,
  grain_status,
  low_confidence_warning,
}) => {
  const classes = useStyles();
  const {
    temp_f = null,
    temp_state = RangeState.unknown,
    emc = null,
    emc_state = RangeState.unknown,
  } = grain_status || {};
  return (
    <div className={classes.button_container}>
      <ButtonBase
        className={`${classes.root} ${classes.tier_border}`}
        onClick={onClick}
        disabled={disabled}
      >
        <div className={classes.root}>
          {/* checkbox */}
          <Checkbox
            color="primary"
            checked={selected}
            style={{ width: 14, height: 14 }}
            icon={<CheckBoxOutlineBlank style={{ fontSize: 14 }} />}
            checkedIcon={<CheckBox style={{ fontSize: 14 }} />}
          />

          {/* tiername and percentages */}
          <div className={classes.text_container}>
            {/* tier name */}
            <Typography variant="h6">{tier_name}</Typography>
            <div className={classes.text_row}>
              {/* temperature and humidity percentages */}
              {temp_f === null ? (
                <Typography variant="body1" className={classes[RangeState.unknown]}>
                  'N/A'
                </Typography>
              ) : (
                <Typography variant="body1" className={classes[temp_state || RangeState.unknown]}>
                  {formatNumber(temp_f, 0)}&#8457;
                </Typography>
              )}
              <Typography variant="body1" className={classes[emc_state || RangeState.unknown]}>
                {emc === null ? 'N/A' : formatPercent(emc, 1)}
              </Typography>
            </div>
          </div>
          {/* color dot */}
          <Grid item xs={3}>
            <div className={classes.dot_container}>
              <div className={classes.color_dot} style={{ backgroundColor: color }} />
            </div>
          </Grid>
          {low_confidence_warning && <DataWarning warning_text={low_confidence_warning} />}
        </div>
      </ButtonBase>
    </div>
  );
};
