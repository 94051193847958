import { Button as MuiButton } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { createStyles, withStyles } from '@material-ui/styles';
import React from 'react';

const styles = createStyles({
  root: {
    margin: '0.5em',
  },
});
export const Button = withStyles(styles)(({ variant = 'outlined', ...props }: ButtonProps) => (
  <MuiButton variant={variant} {...props} />
));

export const ButtonSubmit = ({ type = 'submit', children, ...props }: ButtonProps) => (
  <Button type={type || 'submit'} {...props}>
    {children || 'SUBMIT'}
  </Button>
);
