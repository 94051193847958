import { GrainType } from '../api/graphql/__generated';

const GRAIN_TYPE_LABELS = {
  [GrainType.Barley]: 'Barley',
  [GrainType.Buckwheat]: 'Buckwheat',
  [GrainType.Corn]: 'Corn - Generic',
  [GrainType.CornYellow]: 'Yellow Dent Corn',
  [GrainType.CornWhite]: 'White Corn',
  [GrainType.CornWaxy]: 'Waxy Corn',
  [GrainType.CornPopcorn]: 'Popcorn (Yellow)',
  [GrainType.CornPopcornWhite]: 'Popcorn (White)',
  [GrainType.RiceLongGrain]: 'Long Grain Rice',
  [GrainType.RiceMediumGrain]: 'Medium Grain Rice',
  [GrainType.RiceShortGrain]: 'Short Grain Rice',
  [GrainType.Soybean]: 'Soybeans',
  [GrainType.Sunflower]: 'Sunflower (Oil)',
  [GrainType.SunflowerConfectionary]: 'Sunflower (Confectionary)',
  [GrainType.WheatDurum]: 'Durum Wheat ',
  [GrainType.WheatHardRedSpring]: 'Hard Red Spring Wheat',
  [GrainType.WheatHardRedWinter]: 'Hard Red Winter Wheat',
  [GrainType.Ddg]: 'DDG - 15% Solubles',
  [GrainType.Oats]: 'Oats',
  [GrainType.Rye]: 'Rye',
  [GrainType.Sorghum]: 'Sorghum',
  [GrainType.PistachioNutHull]: 'Pistachio Nut Hull',
  [GrainType.Peanuts]: 'Peanuts',
  [GrainType.Canola]: 'Canola - Generic',
  [GrainType.CanolaTobinPolish]: 'Canola - (Tobin / Polish)',
  [GrainType.CanolaWestarArgentine]: 'Canola - (Westar / Argentine)',
  [GrainType.Rapeseed]: 'Rapeseed',
  [GrainType.BeanRed]: 'Red Beans',
  [GrainType.BeanPinto]: 'Pinto Beans',
  [GrainType.BeanBlack]: 'Black Beans',
  [GrainType.BeanWhite]: 'White Beans',
  [GrainType.BeanLentil]: 'Lentil Beans',
  [GrainType.DdgSolubles_10]: 'DDG - 10% Solubles',
  [GrainType.DdgSolubles_20]: 'DDG - 20% Solubles',
  [GrainType.DdgSolubles_25]: 'DDG - 25% Solubles',
};

export default (grain_type: GrainType): string => {
  if (grain_type) {
    const label = GRAIN_TYPE_LABELS[grain_type];
    return label || `${grain_type[0].toUpperCase()}${grain_type.slice(1).toLowerCase()}`;
  }
  return 'Unknown Grain Type';
};

export const formatGrainTypeFun = (grain_type: GrainType | null) => {
  switch (grain_type) {
    case GrainType.Barley: {
      return '🌾 Barley';
    }
    case GrainType.Corn: {
      return '🌽 Generic Corn';
    }
    case GrainType.CornWhite: {
      return '🌽 White Corn';
    }
    case GrainType.CornYellow: {
      return '🌽 Yellow Dent Corn';
    }
    case GrainType.Ddg: {
      return '🐄 DDG';
    }
    case GrainType.Soybean: {
      return '🌱 Soybeans';
    }
    case GrainType.Sunflower: {
      return '🌻 Sunflower Seeds';
    }
    case GrainType.WheatHardRedSpring: {
      return '🌾 Hard Red Spring Wheat';
    }
    default:
      return '';
  }
};
