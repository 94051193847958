export const formatNumberWithCommas = (value: number, precision?: number): string => {
  let [head, tail = ''] = value.toString().split('.');
  head = head.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (precision === undefined) {
    return tail ? `${head}.${tail}` : head;
  }
  tail = `${tail}000000000`.slice(0, precision);
  return tail ? `${head}.${tail}` : head;
};

export default (value: number, precision = 0, commas = true): string => {
  const scale = 10 ** precision;
  const rounded = Math.round(value * scale) / scale;
  return commas ? formatNumberWithCommas(rounded, precision) : rounded.toString();
};
