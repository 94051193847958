import * as React from 'react';
export const RotatingWaveIcon = (props) => {
  const baseColor = props.basecolor || '#1e91ff';
  return (
    <svg id="ColorVector" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 499" {...props}>
      <defs>
        <style>
          {`\n      .rwcls-1{opacity:.94;}.rwcls-1,.rwcls-2,.rwcls-3,.rwcls-4,.rwcls-5{fill:${baseColor};}.rwcls-2{opacity:.59;}.rwcls-4{opacity:.8;}.rwcls-5{opacity:.31;}\n    `}
        </style>
      </defs>
      <g id="BOTTOM">
        <rect
          id="end"
          x={317.61}
          y={18.77}
          width={7.44}
          height={26.79}
          rx={3.53}
          ry={3.53}
          transform="translate(620.31 154.26) rotate(-163.5)"
        />
        <rect
          x={300.6}
          y={12.22}
          width={7.44}
          height={28.64}
          rx={3.53}
          ry={3.53}
          transform="translate(596.48 115.77) rotate(-168)"
        />
        <rect
          x={283.01}
          y={6.8}
          width={7.44}
          height={30.14}
          rx={3.53}
          ry={3.53}
          transform="translate(567.63 83.44) rotate(-172)"
        />
        <rect
          x={265.07}
          y={3.02}
          width={7.44}
          height={31.95}
          rx={3.53}
          ry={3.53}
          transform="translate(535.93 54.35) rotate(-176.5)"
        />
        <rect id="middle" x={246.94} width={7.45} height={34.46} rx={3.53} ry={3.53} />
        <rect
          x={228.75}
          y={3.08}
          width={7.44}
          height={31.95}
          rx={3.53}
          ry={3.53}
          transform="translate(-.73 14.23) rotate(-3.5)"
        />
        <rect
          x={210.81}
          y={6.87}
          width={7.44}
          height={30.14}
          rx={3.53}
          ry={3.53}
          transform="translate(-.97 30.07) rotate(-8)"
        />
        <rect
          x={193.22}
          y={12.29}
          width={7.44}
          height={28.64}
          rx={3.53}
          ry={3.53}
          transform="translate(-1.23 41.53) rotate(-12)"
        />
        <rect
          id="start"
          x={176.21}
          y={18.83}
          width={7.44}
          height={26.79}
          rx={3.53}
          ry={3.53}
          transform="translate(-1.74 52.43) rotate(-16.5)"
        />
      </g>
      <g id="TOP">
        <rect
          id="end-2"
          className="rwcls-5"
          x={317.61}
          y={18.77}
          width={7.44}
          height={26.79}
          rx={3.53}
          ry={3.53}
          transform="translate(620.31 154.26) rotate(-163.5)"
        />
        <rect
          className="rwcls-2"
          x={300.6}
          y={12.22}
          width={7.44}
          height={28.64}
          rx={3.53}
          ry={3.53}
          transform="translate(596.48 115.77) rotate(-168)"
        />
        <rect
          className="rwcls-4"
          x={283.01}
          y={6.8}
          width={7.44}
          height={30.14}
          rx={3.53}
          ry={3.53}
          transform="translate(567.63 83.44) rotate(-172)"
        />
        <rect
          className="rwcls-1"
          x={265.07}
          y={3.02}
          width={7.44}
          height={31.95}
          rx={3.53}
          ry={3.53}
          transform="translate(535.93 54.35) rotate(-176.5)"
        />
        <rect
          id="middle-2"
          className="rwcls-3"
          x={246.94}
          width={7.45}
          height={34.46}
          rx={3.53}
          ry={3.53}
        />
        <rect
          className="rwcls-1"
          x={228.75}
          y={3.08}
          width={7.44}
          height={31.95}
          rx={3.53}
          ry={3.53}
          transform="translate(-.73 14.23) rotate(-3.5)"
        />
        <rect
          className="rwcls-4"
          x={210.81}
          y={6.87}
          width={7.44}
          height={30.14}
          rx={3.53}
          ry={3.53}
          transform="translate(-.97 30.07) rotate(-8)"
        />
        <rect
          className="rwcls-2"
          x={193.22}
          y={12.29}
          width={7.44}
          height={28.64}
          rx={3.53}
          ry={3.53}
          transform="translate(-1.23 41.53) rotate(-12)"
        />
        <rect
          id="start-2"
          className="rwcls-5"
          x={176.21}
          y={18.83}
          width={7.44}
          height={26.79}
          rx={3.53}
          ry={3.53}
          transform="translate(-1.74 52.43) rotate(-16.5)"
        />
      </g>
    </svg>
  );
};
