import { ApolloError } from 'apollo-client';
import { GraphQLError } from 'graphql';

import { isGraphQLErrors } from '../api/graphql/hoc';
import { AmberError } from './constant';

interface NetworkError2 extends Error {
  result?: AmberError;
}

export class UiFormError extends Error {
  constructor(props) {
    super(props);
    this.name = 'UiFormError';
  }
}

interface IApolloError2 extends ApolloError {
  networkError: NetworkError2 | null;
  graphQLErrors: (AmberError & GraphQLError)[];
}

export class MockApolloGraphQLError extends ApolloError {
  constructor(message, params?: any) {
    const error = new GraphQLError(message);
    // @ts-ignore
    error.params = params;
    super({
      errorMessage: message,
      graphQLErrors: [error],
    });
    this.name = 'ApolloError';
  }
}

const isApolloError2 = (err: any): err is IApolloError2 =>
  err.hasOwnProperty('graphQLErrors') || err.hasOwnProperty('networkError');

const formatAmberError = (err: AmberError): string => {
  const { code, message } = err;
  switch (code) {
    case 400:
      return message;
    case 401:
      return 'Session expired';
    case 403:
      return 'Insufficient entitlements';
    case 500:
    case 501:
      return message;
    default:
      return 'Unexpected error, please contact hank@amber.ag for assistance';
  }
};

const formatNetworkError = ({ message, result }: Error & { result?: AmberError }): string => {
  if (result) {
    return formatAmberError(result);
  }
  return 'Unexpected network error, please contact hank@amber.ag for assistance';
};

export default (err: string | Error): string[] => {
  if (typeof err === 'string') {
    return [err];
  }
  if (isApolloError2(err)) {
    const { networkError, graphQLErrors } = err;
    if (!networkError && !graphQLErrors) {
      return [];
    }
    const lines: string[] = [];
    if (networkError) {
      lines.push(formatNetworkError(networkError));
    }
    return graphQLErrors.reduce((accumulator, err) => {
      accumulator.push(formatAmberError(err));
      return accumulator;
    }, lines);
  }
  if (isGraphQLErrors(err)) {
    return err.errors.map(formatAmberError);
  }
  if (err.name === 'SubmissionError' && (err as any).errors && (err as any).errors._error) {
    return [(err as any).errors._error];
  }
  if (err instanceof UiFormError || err.name === 'UiFormError') {
    return [err.message];
  }
  return ['Unexpected error, please contact hank@amber.ag for assistance'];
};
