import React from 'react';
import { LinePlaceholder } from '../../ops-table-components';

const LinePlaceholderRenderer = (props) => {
  const isSiteRowCell = Boolean(props && props.data && props.data.siteHierarchy.length === 1);
  if (isSiteRowCell) return <LinePlaceholder />;
  return null;
};

export { LinePlaceholderRenderer };
