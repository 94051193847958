import { createAction } from 'typesafe-actions';
import { GraphQLErrors } from '../api/graphql/hoc';
import { AmberError } from '../util';

export const appendAlertErrors = createAction('ALERT/ERRORS_APPEND', (resolve) => {
  return (payload: { errors: (AmberError | GraphQLErrors | string)[] }) => resolve(payload);
});

export const resetAlertErrors = createAction('ALERT/ERRORS_RESET', (resolve) => {
  return () => resolve();
});
