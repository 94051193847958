import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useState } from 'react';
import { ActiveStoragePeriodContext } from '../../../../core/src/contexts';
import {
  GrainType,
  withCreateBinStoragePeriodHoc,
  WithCreateBinStoragePeriodHocChildProps,
  withDeleteBinStoragePeriodHoc,
  WithDeleteBinStoragePeriodHocChildProps,
  withUpdateBinStoragePeriodHoc,
  WithUpdateBinStoragePeriodHocChildProps,
} from '../../api';
import {
  WithGrainBinStoragePeriodValidationHocChildProps,
  withValidateStoragePeriodOverlapsHoc,
} from '../../api/graphql/hoc/withValidateStoragePeriodOverlapsHoc';
import { amber_red } from '../../style/color';
import { formatGrainType } from '../../util';
import { UiFormError } from '../../util/format-error';
import ConfirmationModal from '../util/ConfirmationModal';
import { FormikWrapper } from '../util/form2/BaseForm';
import { Button, ButtonSubmit } from '../util/form2/Button';
import { CheckboxWithOnChange as Checkbox } from '../util/form2/CheckboxWithOnChange';
import { ErrorBox } from '../util/form2/ErrorBox';
import { SelectGrainType } from '../util/form2/SelectGrainType';
import { TextField } from '../util/form2/TextField';

type StoragePeriodInput = {
  storage_period_name: string | null;
  grain_type: string;
  set_as_ongoing: boolean;
  start_date: string;
  end_date: string | null;
};

type CreateOrUpdateStoragePeriodFormProps = WithCreateBinStoragePeriodHocChildProps &
  WithUpdateBinStoragePeriodHocChildProps &
  WithGrainBinStoragePeriodValidationHocChildProps &
  WithDeleteBinStoragePeriodHocChildProps & {
    grain_bin_id: number;
    /// If present, 'saving' will update instead of create
    grain_bin_storage_cycle_id?: number;
    storagePeriodName: string | null;
    storagePeriodGrainType: string;
    storagePeriodStartDate: string;
    storagePeriodEndDate: string | null;
    is_ongoing_storage_period: boolean;
    handleCloseForm: () => void;
    setUpdating: (shouldUpdate: boolean) => void;
    refetchStoragePeriods: () => void;
  };

export const CreateOrUpdateStoragePeriodForm = withValidateStoragePeriodOverlapsHoc(
  withDeleteBinStoragePeriodHoc(
    withUpdateBinStoragePeriodHoc(
      withCreateBinStoragePeriodHoc(
        ({
          createBinStoragePeriod,
          updateBinStoragePeriod,
          validateStoragePeriodOverlaps,
          deleteBinStoragePeriod,
          refetchStoragePeriods,
          grain_bin_id,
          grain_bin_storage_cycle_id,
          setUpdating,
          storagePeriodName,
          storagePeriodGrainType,
          storagePeriodStartDate,
          storagePeriodEndDate,
          is_ongoing_storage_period,
          handleCloseForm,
        }: CreateOrUpdateStoragePeriodFormProps) => {
          const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
          const activeStoragePeriodContext = useContext(ActiveStoragePeriodContext);
          const activeStoragePeriod = activeStoragePeriodContext.activeStoragePeriod;

          const date = DateTime.local().toFormat('MM/dd/yyyy');
          const initialValues: StoragePeriodInput = {
            storage_period_name:
              storagePeriodName ||
              `${formatGrainType(storagePeriodGrainType as GrainType)} ${date}`,
            grain_type: storagePeriodGrainType,
            set_as_ongoing: is_ongoing_storage_period || false,
            start_date: storagePeriodStartDate || DateTime.local().toFormat('yyyy-MM-dd'),
            end_date: storagePeriodEndDate || '',
          };

          const is_storage_period_ongoing = (end_epoch: Date | null) => {
            const currentDate = new Date();
            return end_epoch === null || (end_epoch && end_epoch.getTime() > currentDate.getTime());
          };

          const handleSubmit = async (values) => {
            if (!values.start_date) {
              throw new UiFormError('Please specify a valid start date');
            }
            const startDate = DateTime.fromFormat(values.start_date, 'yyyy-MM-dd').toJSDate();

            let endDate: Date | null = null;
            if (!values.set_as_ongoing) {
              {
                if (values.end_date) {
                  endDate = DateTime.fromFormat(values.end_date, 'yyyy-MM-dd').toJSDate();
                } else {
                  throw new UiFormError('Please specify a valid end date');
                }
              }
            }

            if (startDate && endDate && startDate.getTime() > endDate.getTime()) {
              throw new UiFormError('The start date cannot be greater than the end date');
            }

            const validation_result = await validateStoragePeriodOverlaps({
              grain_bin_storage_cycle_id,
              grain_bin_id,
              grain_type: values.grain_type,
              storage_cycle_name: values.storage_period_name,
              start_epoch: startDate,
              end_epoch: endDate,
            });

            if (validation_result.failed_validation) {
              console.log('validation_result.error_message', validation_result.error_message);
              throw new UiFormError(validation_result.error_message);
            }

            setUpdating(true);

            if (grain_bin_storage_cycle_id) {
              console.log('Updating...', values);
              try {
                const res = await updateBinStoragePeriod({
                  grain_bin_storage_cycle_id,
                  grain_bin_id,
                  grain_type: values.grain_type,
                  storage_cycle_name: values.storage_period_name,
                  start_epoch: startDate,
                  end_epoch: endDate,
                });

                await refetchStoragePeriods();

                console.log('Updated: ', res);

                if (activeStoragePeriod === null && is_storage_period_ongoing(endDate)) {
                  const updatedFields = {
                    storage_cycle_name: res.storage_cycle_name,
                    start_date: res.start_epoch,
                    end_date: res.end_epoch,
                    is_ongoing: is_storage_period_ongoing(endDate),
                    grain_bin_id: res.grain_bin_id,
                    grain_bin_storage_cycle_id: res.grain_bin_storage_cycle_id,
                  };
                  // update ActiveStoragePeriod Context with updated values
                  activeStoragePeriodContext.setActiveStoragePeriod({
                    order: 0,
                    ...updatedFields,
                  });
                } else if (
                  activeStoragePeriod &&
                  res &&
                  activeStoragePeriod.grain_bin_storage_cycle_id === res.grain_bin_storage_cycle_id
                ) {
                  const updatedFields = {
                    storage_cycle_name: res.storage_cycle_name,
                    start_date: res.start_epoch,
                    end_date: res.end_epoch,
                    is_ongoing: is_storage_period_ongoing(endDate),
                  };
                  // update ActiveStoragePeriod Context with updated values
                  activeStoragePeriod.is_ongoing && !is_storage_period_ongoing(endDate)
                    ? activeStoragePeriodContext.setActiveStoragePeriod(null)
                    : activeStoragePeriodContext.setActiveStoragePeriod({
                        ...activeStoragePeriod,
                        ...updatedFields,
                      });
                }

                handleCloseForm();
              } catch (err) {
                console.error('Update failed. ', err);
                throw new Error();
              }
            } else {
              try {
                const res = await createBinStoragePeriod({
                  grain_bin_id,
                  storage_cycle_name: values.storage_period_name,
                  grain_type: values.grain_type,
                  start_epoch: startDate,
                  end_epoch: endDate,
                });
                await refetchStoragePeriods();
                console.log('Created: ', res);
                if (activeStoragePeriod === null && is_storage_period_ongoing(endDate)) {
                  const newFields = {
                    storage_cycle_name: res.storage_cycle_name,
                    start_date: res.start_epoch,
                    end_date: res.end_epoch,
                    is_ongoing: is_storage_period_ongoing(endDate),
                    grain_bin_id: res.grain_bin_id,
                    grain_bin_storage_cycle_id: res.grain_bin_storage_cycle_id,
                  };
                  // update ActiveStoragePeriod Context with updated values
                  activeStoragePeriodContext.setActiveStoragePeriod({
                    order: 0,
                    ...newFields,
                  });
                }
                handleCloseForm();
              } catch (err) {
                console.error('Create failed. ', err);
                throw new Error();
              }
            }
            setUpdating(false);
          };

          const handleDelete = useCallback(async () => {
            setUpdating(true);
            try {
              if (grain_bin_storage_cycle_id === undefined || grain_bin_storage_cycle_id === null) {
                console.warn('No grain_bin_storage_cycle_id found.');
                return;
              }
              const res = await deleteBinStoragePeriod({
                grain_bin_storage_cycle_id,
                grain_bin_id,
              });
              await refetchStoragePeriods();
              console.log('Deleted.', res);
              setUpdating(false);
              handleCloseForm();
              return res;
            } catch (err) {
              setUpdating(false);
              console.error('Delete failed: ', err);
              throw new Error();
            }
          }, []);

          return (
            <FormikWrapper<StoragePeriodInput>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              render={({ values, setFieldValue }) => {
                const { storage_period_name, set_as_ongoing, end_date } = values;
                return (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        name="storage_period_name"
                        label="Storage Period Name"
                        placeholder="New Storage Period"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectGrainType
                        style={{ marginTop: 10 }}
                        name="grain_type"
                        label="Grain Type"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Checkbox
                        style={{ width: '100%' }}
                        name="set_as_ongoing"
                        label="Set as Ongoing"
                        checked={values.set_as_ongoing}
                        onChange={(_, checked) => {
                          setFieldValue('set_as_ongoing', checked);
                          checked && setFieldValue('end_date', '');
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        name="start_date"
                        type="date"
                        label="Start Date"
                        placeholder="MM/DD/YYYY"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {set_as_ongoing && end_date === '' ? (
                      <Grid item sm={6} xs={6} />
                    ) : (
                      <Grid item xs={6} sm={6}>
                        <TextField
                          fullWidth
                          name="end_date"
                          label="End Date"
                          type="date"
                          placeholder="MM/DD/YYYY"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        fullWidth
                        style={{ margin: 0 }}
                        onClick={handleCloseForm}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={grain_bin_storage_cycle_id ? 7 : 9}>
                      <ButtonSubmit
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ margin: 0 }}
                        allow_pristine
                        prevent_submit_on_enter
                      >
                        Save Changes
                      </ButtonSubmit>
                    </Grid>
                    {grain_bin_storage_cycle_id && (
                      <Grid item xs={2}>
                        <Button
                          fullWidth
                          style={{
                            margin: 0,
                            color: 'white',
                            backgroundColor: amber_red,
                          }}
                          onClick={() => {
                            setShowConfirmationModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </Grid>
                    )}
                    <ErrorBox />
                    <ConfirmationModal
                      showModal={showConfirmationModal}
                      confirmationMessage={
                        <>
                          Are you sure you want to delete Storage Period{' '}
                          <strong>{storage_period_name && storage_period_name}</strong>?
                        </>
                      }
                      cancelBtnText="Cancel"
                      confirmBtbText="Delete"
                      handleCancel={() => setShowConfirmationModal(false)}
                      handleConfirm={() => {
                        handleDelete();
                        setShowConfirmationModal(false);
                      }}
                    />
                  </Grid>
                );
              }}
            />
          );
        }
      )
    )
  )
);
