import downloadjs from 'downloadjs';

export const ERROR_BAD_PARAMS = 'BAD_PARAMS';
export const ERROR_SERVER = 'ERROR_SERVER';
export const ERROR_UNKNOWN = 'ERROR_UNKNOWN';
export const ERROR_BAD_TOKEN = 'ERROR_BAD_TOKEN';

class BadTokenError extends Error {
  name = 'BadTokenError';
}
class BadArgumentsError extends Error {
  name = 'BadArgumentsError';
}

const validateResponse = async (response) => {
  switch (response.status) {
    case 200: {
      return;
    }
    case 422: {
      // Problem with one of the parameters
      const result = await response.json();
      throw new BadArgumentsError(result.error);
    }
    case 430: {
      const result = await response.json();
      throw new BadTokenError(result.error);
    }
    case 500: {
      const result = await response.json();
      throw new Error(result.error);
    }
    default: {
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
        // throw new Error({ name: ERROR_SERVER, message: result.error });
      } else {
        throw new Error('Unexpected server error');
        // throw new Error({ name: ERROR_UNKNOWN, message: 'Unexpected server error' });
      }
    }
  }
};

const filename_regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
export const exportTelemetry = async ({ container_id, container_type, start, end }) => {
  const token = (window as any).localStorage.getItem('token');
  const response = await fetch(`${window['app_config'].api_host_name}/exportTelemetry`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/zip,*/*',
      authorization: token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify({ container_id, container_type, start, end }),
  });
  await validateResponse(response);
  const blob = await response.blob();
  const disposition = response.headers.get('Content-Disposition');
  if (!disposition) {
    throw new Error('Unexpected server error');
  }
  const matches = filename_regex.exec(disposition);
  downloadjs(
    blob,
    matches !== null && matches[1] ? matches[1].replace(/['"]/g, '') : undefined,
    response.headers.get('Content-Type') || undefined
  );
  return;
};

export const updateApnsConnection = async ({
  device_token,
  user_id,
}: {
  device_token: string;
  user_id: number;
}) => {
  const token = (window as any).localStorage.getItem('token');
  await fetch(`${window['app_config'].api_host_name}/updateApnsConnection`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      user_id,
      subscription: { device_token },
    }),
  });
};
