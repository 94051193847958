import { DialogContent } from '@material-ui/core';
import React from 'react';

import { BaseDialog } from '../../util';
import { SelectedHubType } from '../HubRemovalForm';
import { UpdateGrainBinHubForm } from './UpdateGrainBinHubForm';

export type UpdateGrainBinHubDialogProps = {
  open: boolean;
  grain_bin_hub: SelectedHubType | null;
  onClose: () => void;
};

export const UpdateGrainBinHubDialog: React.FunctionComponent<UpdateGrainBinHubDialogProps> = ({
  open,
  grain_bin_hub,
  onClose,
}) => {
  if (!grain_bin_hub) {
    return null;
  }

  return (
    <BaseDialog
      open={open}
      handleClose={onClose}
      title={`Update ${grain_bin_hub.nickname}`}
      showCloseButton={false}
    >
      <DialogContent>
        <UpdateGrainBinHubForm
          grain_bin_hub={grain_bin_hub}
          // using set timeout to put the close on the event loop to allow formik to finish up and avoid an error
          onSubmitSuccess={() => setTimeout(onClose, 0)}
          handleClose={onClose}
        />
      </DialogContent>
    </BaseDialog>
  );
};
