import { MenuItem } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import * as yup from 'yup';
import { FloorType } from '../../../api/graphql';
import { Select, SelectProps } from './Select';

export const yup_floor_type = yup
  .string()
  .nullable()
  .oneOf([
    null,
    FloorType.Ducts,
    FloorType.FullFloorAeration,
  ]) as yup.StringSchema<FloorType | null>;

export const SelectFloorType: FunctionComponent<SelectProps> = (props) => {
  return (
    <Select {...props}>
      <MenuItem value={FloorType.FullFloorAeration}>Full Floor Aeration</MenuItem>
      <MenuItem value={FloorType.Ducts}>Ducts</MenuItem>
    </Select>
  );
};
