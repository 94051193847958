import { Checkbox, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { DateTime, SystemZone } from 'luxon';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  AccountFragmentFragment,
  UserFragmentFragment,
  withSubscribeWeeklyGrainEmailHoc,
  WithSubscribeWeeklyGrainEmailHocChildProps,
  withUnsubscribeWeeklyGrainEmailHoc,
  WithUnsubscribeWeeklyGrainEmailHocChildProps,
  withUpdateGrainBinWeeklyEmailPrefHoc,
  WithUpdateGrainBinWeeklyEmailPrefHocChildProps,
  withUpdateWeeklyGrainEmailScheduleHoc,
  WithUpdateWeeklyGrainEmailScheduleHocChildProps,
} from '../../api';
import { DialogSpinner } from '../spinner';
import { SelectScheduleDay } from '../util/form2/SelectScheduleDay';
import { SelectScheduleHour } from '../util/form2/SelectScheduleHour';
import { SelectTimezone } from '../util/form2/SelectTimezone';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    minWidth: 250,
    maxWidth: 600,
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  centered: { textAlign: 'start', marginBottom: 10 },
  alertHeader: {
    marginTop: '0px',
    marginBottom: '10px',
    fontWeight: 'inherit',
    textAlign: 'left',
    fontSize: '18px',
  },
  tabHeader: {
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
}));

type WeeklyEmailSettingFormValues = {
  has_weekly_email_subscribed: boolean;
  schedule_day: string;
  schedule_hour: number;
  schedule_timezone: string;
};

export const GrainWeeklyEmailSettingFormBase = ({
  account,
  refecthUser,
  selected_user,
  subscribeWeeklyGrainEmail,
  unsubscribeWeeklyGrainEmail,
  updateWeeklyGrainEmailSchedule,
  updateGrainBinWeeklyEmailPref,
  loading,
  ...props
}: WithUpdateGrainBinWeeklyEmailPrefHocChildProps &
  WithSubscribeWeeklyGrainEmailHocChildProps &
  WithUnsubscribeWeeklyGrainEmailHocChildProps &
  WithUpdateWeeklyGrainEmailScheduleHocChildProps & {
    refecthUser: () => void;
    selected_user: UserFragmentFragment;
    account: AccountFragmentFragment;
    loading: boolean;
    rootContainerStyles?: object;
  }) => {
  const [isUpdating, setUpdating] = React.useState(false);
  const { user_id, weekly_grain_email_schedule, grain_weekly_email_opt_pref } = selected_user;
  const { account_id = 0, grain_bin_links = [] } = account || {};
  const systemZone = new SystemZone();
  console.log('SYSTEM AND GRAIN_BIN TIMEZONES:', systemZone.name, DateTime.local().zoneName);
  const getInitialWeeklyEmailSettingFormValues = (): WeeklyEmailSettingFormValues => {
    if (weekly_grain_email_schedule === null) {
      return {
        has_weekly_email_subscribed: false,
        schedule_day: 'saturday',
        schedule_hour: 10,
        schedule_timezone: systemZone.name,
      };
    }
    return {
      has_weekly_email_subscribed: weekly_grain_email_schedule.subscribed,
      schedule_day: weekly_grain_email_schedule.schedule_day,
      schedule_hour: weekly_grain_email_schedule.schedule_hour,
      schedule_timezone: weekly_grain_email_schedule.schedule_timezone,
    };
  };
  const classes = useStyles();
  const [weeklyEmailSettingFormValues, setWeeklyEmailSettingFormValues] = useState<
    WeeklyEmailSettingFormValues
  >(getInitialWeeklyEmailSettingFormValues());
  const {
    has_weekly_email_subscribed,
    schedule_day,
    schedule_hour,
    schedule_timezone,
  } = weeklyEmailSettingFormValues;

  const grain_bin_id_to_name = grain_bin_links
    .filter(({ grain_bin }) => !grain_bin.archived)
    .reduce((acc, curr) => {
      acc.set(curr.grain_bin_id, curr.grain_bin.alias);
      return acc;
    }, new Map<number, string>());

  const grain_weekly_emails_opt_form_obj: any = {};
  const grain_bin_checkbox_options: any[] = [];

  const handleUpdateGrainBinWeeklyEmailPref = async (pref, checked) => {
    try {
      setUpdating(true);
      await updateGrainBinWeeklyEmailPref({
        user_id,
        account_id,
        grain_bin_id: pref.grain_bin_id,
        opt_in: checked,
      });
      await refecthUser();
    } catch (err) {
      console.log('error', err);
    } finally {
      setUpdating(false);
    }
  };

  grain_weekly_email_opt_pref.forEach((pref, idx) => {
    if (grain_bin_id_to_name.has(pref.grain_bin_id)) {
      const name = grain_bin_id_to_name.get(pref.grain_bin_id);
      grain_weekly_emails_opt_form_obj[pref.grain_bin_id] = pref.opt_in;

      grain_bin_checkbox_options.push(
        <Grid item xs={12} key={`${name}${idx}`}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={pref.opt_in}
                onChange={async (_, checked) => {
                  handleUpdateGrainBinWeeklyEmailPref(pref, checked);
                }}
              />
            }
            label={name!}
            disabled={!has_weekly_email_subscribed}
          />
        </Grid>
      );
    }
  });

  const handleSubscibeToggleChange = async ({ target }) => {
    if (target.checked) {
      // subscribe
      try {
        setUpdating(true);
        const response = await subscribeWeeklyGrainEmail({
          account_id,
          user_id,
          schedule_day,
          schedule_hour,
          schedule_timezone,
        });
        console.log('subscribe response', response);
        await refecthUser();
        setUpdating(false);
      } catch (error) {
        console.error('Error while subscribing weekly grain email:', error);
        setUpdating(false);
      }
    } else {
      // unsubscribe
      try {
        setUpdating(true);
        const response = await unsubscribeWeeklyGrainEmail({ user_id });
        console.log('unsubscribe response', response);
        await refecthUser();
        setUpdating(false);
      } catch (error) {
        console.error('Error while unsubscribing weekly grain email:', error);
        setUpdating(false);
      }
    }
  };

  const handleScheduleDayChange = async ({ value }: { value: string; label: string }) => {
    try {
      setUpdating(true);
      const response = await updateWeeklyGrainEmailSchedule({
        schedule_hour,
        schedule_timezone,
        user_id,
        schedule_day: value,
      });

      console.log('handleScheduleDayChange response', response);
      await refecthUser();
      setUpdating(false);
    } catch (error) {
      console.error('Error while Schedule Day Change for weekly grain email:', error);
      setUpdating(false);
    }
  };

  const handleScheduleHourChange = async ({ value }: { value: number; label: string }) => {
    try {
      setUpdating(true);
      const response = await updateWeeklyGrainEmailSchedule({
        schedule_day,
        schedule_timezone,
        user_id,
        schedule_hour: value,
      });

      console.log('handleScheduleHourChange response', response);
      await refecthUser();
      setUpdating(false);
    } catch (error) {
      console.error('Error while Schedule Hour Change for weekly grain email:', error);
      setUpdating(false);
    }
  };

  const handleScheduleTimezoneChange = async ({ value }: { value: string; label: string }) => {
    try {
      setUpdating(true);
      const response = await updateWeeklyGrainEmailSchedule({
        schedule_day,
        schedule_hour,
        user_id,
        schedule_timezone: value,
      });

      console.log('handleScheduleTimezoneChange response', response);
      await refecthUser();
      setUpdating(false);
    } catch (error) {
      console.error('Error while Schedule Timezone Change for weekly grain email:', error);
      setUpdating(false);
    }
  };

  if (isUpdating) {
    return <DialogSpinner title="Updating..." open={isUpdating} />;
  }

  if (loading) {
    return <DialogSpinner title="Loading..." open={loading} />;
  }

  return (
    <div style={props.rootContainerStyles ? props.rootContainerStyles : undefined}>
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="flex-start"
        justify="center"
        className={classes.grid}
        spacing={2}
      >
        {
          <>
            <Grid
              item
              xs={12}
              className={classes.centered}
              style={{ marginTop: 20, marginBottom: 0 }}
            >
              <Typography variant="h5" className={classes.tabHeader}>
                Weekly Email Settings - Select bin to receive weekly email
              </Typography>
            </Grid>

            <Grid container style={{ display: 'flex', padding: 8 }}>
              <FormControlLabel
                control={
                  <Switch
                    name="has_weekly_email_subscribed"
                    checked={has_weekly_email_subscribed}
                    onChange={handleSubscibeToggleChange}
                  />
                }
                label="Enable Weekly Bin Update Email"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ textAlign: 'start', marginBottom: 5 }}>
                <label className="" htmlFor="schedule_timezone">
                  Schedule Day
                </label>
              </div>
              <SelectScheduleDay
                name="schedule_day"
                selectedValue={schedule_day}
                onChange={handleScheduleDayChange}
                isDisabled={!has_weekly_email_subscribed}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ textAlign: 'start', marginBottom: 5 }}>
                <label className="" htmlFor="schedule_timezone">
                  Schedule Hour
                </label>
              </div>
              <SelectScheduleHour
                name="schedule_hour"
                selectedValue={schedule_hour}
                onChange={handleScheduleHourChange}
                isDisabled={!has_weekly_email_subscribed}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ textAlign: 'start', marginBottom: 5 }}>
                <label className="" htmlFor="schedule_timezone">
                  Schedule Timezone
                </label>
              </div>
              <SelectTimezone
                name="schedule_timezone"
                selectedValue={schedule_timezone}
                onChange={handleScheduleTimezoneChange}
                isDisabled={!has_weekly_email_subscribed}
              />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} className={classes.alertHeader}>
              <Typography
                variant="h6"
                className={classes.alertHeader}
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                Choose Grain Bins
              </Typography>
              <div>{grain_bin_checkbox_options}</div>
            </Grid>
          </>
        }
      </Grid>
    </div>
  );
};

export const GrainWeeklyEmailSettingForm = withUpdateGrainBinWeeklyEmailPrefHoc(
  withUpdateWeeklyGrainEmailScheduleHoc(
    withUnsubscribeWeeklyGrainEmailHoc(
      withSubscribeWeeklyGrainEmailHoc(GrainWeeklyEmailSettingFormBase)
    )
  )
);
