import { AmberHoc, AmberHocProps } from '../../../util';
import {
  UpdateFanControllerMutation,
  UpdateFanControllerMutationVariables as Variables,
  withUpdateFanController as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUpdateFanControllerHocChildProps = {
  updateFanController: (
    variables: Variables
  ) => Promise<UpdateFanControllerMutation['updateFanController']>;
};
export const withUpdateFanControllerHoc: AmberHoc<{}, WithUpdateFanControllerHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<{}, WithUpdateFanControllerHocChildProps, typeof component>,
    WithUpdateFanControllerHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updateFanController: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({ variables });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.updateFanController;
        },
      };
    },
  })(component as any);
