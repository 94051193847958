import { Button, Grid, Theme } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import * as yup from 'yup';
import { amber_amber } from '../../../style';
import { BaseForm, FormikWrapper } from '../../util/form2/BaseForm';
import { SubmitButtonsGroup } from '../../util/form2/SubmitButtonsGroup';
import { TextField } from '../../util/form2/TextField';
import { ExperimentBoundsCalculations } from './ExperimentBoundsCalculations';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '10px',
    border: 'dotted',
    borderWidth: '10px',
    paddingTop: '5px',
  },
  formContainer: {
    marginTop: '10px',
    paddingTop: '5px',
    marginBottom: '20px',
  },
  largeGrid: {
    margin: '0px auto',
    padding: 20,
    minWidth: 200,
    width: 570,
  },
}));

type BoundValuesFormInputsType = {
  lowerBound: string | number;
  upperBound: string | number;
};

type BoundValuesFormProps = {
  refetch_grain_bin_container?: () => void;
  refetch_experiment_run_windows?: (variables) => void;
  hasExperimentExpanded: boolean;
  recommendation_type: string | null;
  setBoundValues?: (bound_values) => void;
  boundFormValues?: {
    lower_bound: number | undefined;
    upper_bound: number | undefined;
  };
};

const validation_schema = yup.object().shape({
  lowerBound: yup
    .number()
    .label('Lower Bound')
    .positive()
    .min(0)
    .typeError('Lower Bound must >=0'),
  upperBound: yup
    .number()
    .label('Upper Bound')
    .positive()
    .min(0)
    .typeError('Upper Bound must >=0'),
});

export const BoundValuesForm = ({
  refetch_grain_bin_container,
  refetch_experiment_run_windows,
  recommendation_type,
  hasExperimentExpanded,
  setBoundValues,
  boundFormValues,
}: BoundValuesFormProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(hasExperimentExpanded ? -1 : null);
  const handleExpandChange = (index) => (isExpanded) => {
    setExpanded(isExpanded ? index : null);
  };
  const handleCloseForm = () => {
    setExpanded(null);
  };

  let initialValues: BoundValuesFormInputsType = {
    lowerBound: '',
    upperBound: '',
  };

  if (boundFormValues) {
    initialValues = {
      lowerBound: boundFormValues.lower_bound !== undefined ? boundFormValues.lower_bound : '',
      upperBound: boundFormValues.upper_bound !== undefined ? boundFormValues.upper_bound : '',
    };
  }

  const handleSubmit = async (values) => {
    console.log('submiited values from BoundValuesForm', values);
    const { lowerBound, upperBound } = values;
    if (refetch_grain_bin_container && refetch_experiment_run_windows) {
      try {
        const [grain_bin_container, experiment_run_windows] = await Promise.all([
          refetch_grain_bin_container(),
          refetch_experiment_run_windows({
            recommendation_type,
            lower_bound: ![undefined, null, ''].includes(lowerBound) ? lowerBound : undefined,
            upper_bound: ![undefined, null, ''].includes(upperBound) ? upperBound : undefined,
          }),
        ]);
        setBoundValues &&
          setBoundValues({ lower_bound: values.lowerBound, upper_bound: values.upperBound });
        console.log('After submitting Run Windows from BoundValuesForm', {
          grain_bin_container,
          experiment_run_windows,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Grid item className={classes.formContainer}>
      <Button
        variant="outlined"
        onClick={() => handleExpandChange(-1)(expanded !== -1)}
        style={{ margin: '10px 0', width: 'fit-content', borderRadius: 4 }}
      >
        <AddCircle
          style={{
            color: amber_amber,
          }}
        />
        &nbsp; Set Lower & Upper Bounds
      </Button>

      <FormikWrapper<BoundValuesFormInputsType>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validation_schema}
        validateOnBlur={false}
        render={({ values }) => {
          return expanded === -1 ? (
            <BaseForm submitting_message={'Loading Run Windows...'} className={classes.largeGrid}>
              {recommendation_type && (
                <Grid
                  container
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  style={{ marginBottom: 20 }}
                >
                  <Grid item xs={12}>
                    <ExperimentBoundsCalculations recommendation_type={recommendation_type} />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Lower Bound"
                    name="lowerBound"
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Upper Bound"
                    name="upperBound"
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <SubmitButtonsGroup
                  onCancel={handleCloseForm}
                  cancelText="Cancel"
                  submitButtonText="Set"
                />
              </Grid>
            </BaseForm>
          ) : null;
        }}
      />
    </Grid>
  );
};
