import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../action/alert';

type ActionTypes = ActionType<typeof actions>;
export type AlertType = Error | string;
export type AlertState = {
  errors: AlertType[];
};

export const alertReducer = (
  state: AlertState = {
    errors: [],
  },
  action: ActionTypes
): AlertState => {
  switch (action.type) {
    case getType(actions.appendAlertErrors): {
      return {
        ...state,
        errors: [...state.errors, ...action.payload.errors],
      };
    }
    case getType(actions.resetAlertErrors): {
      return {
        ...state,
        errors: [],
      };
    }
    default:
      return state;
  }
};
