import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useGrainBinData } from '../../../contexts';
import { light_gray_3 } from '../../../style';
import { RecommendedOption } from '../RecommendationOptionValue';

const SAFETY_MARGIN = 0.5;
const TARGET_GRAIN_EMC = 20;
const TARGET_GRAIN_EMC_WARMING_ADDER = 1;
const formatDeciamls = (value: number) => Number(value.toFixed(4));
const useStyles = makeStyles((theme: Theme) => ({
  experiment_cfm_calculations: {
    backgroundColor: light_gray_3,
    fontSize: 14,
    lineHeight: 1,
    borderRadius: 4,
    padding: 8,
  },
}));

type ExperimentCfmCalculationsProps = {
  recommendation_type: string;
  cfm_max: string | number;
  cfm_min: string | number;
  cfm_offset: string | number;
  cfm_scaling: string | number;
  cfm: string | number | null | undefined;
};

type LowerBoundCalculationProps = {
  recommendation_type: string;
  current_grain_emc: number | undefined;
  target_grain_emc_warming: number | undefined;
  safety_margin: number;
  target_grain_emc: number;
  eval_cfm: number | undefined;
};
type UpperBoundCalculationProps = {
  recommendation_type: string;
  current_grain_emc: number | undefined;
  target_grain_emc_warming: number | undefined;
  safety_margin: number;
  target_grain_emc: number;
  eval_cfm: number | undefined;
};

export const ExperimentCfmCalculations = ({
  recommendation_type,
  cfm_max,
  cfm_min,
  cfm_offset,
  cfm_scaling,
  cfm,
}: ExperimentCfmCalculationsProps) => {
  const classes = useStyles();
  const grainBinData = useGrainBinData();
  console.log('grainBinData', grainBinData);
  const { current_grain_emc, target_grain_emc } = grainBinData || {
    current_grain_emc: undefined,
    target_grain_emc: undefined,
  };
  const target_grain_emc_warming =
    target_grain_emc !== undefined
      ? Number(target_grain_emc) + TARGET_GRAIN_EMC_WARMING_ADDER
      : target_grain_emc;
  const experimentRecommendationTypeText =
    {
      [RecommendedOption.RECONDITIONING]: 'Reconditioning',
      [RecommendedOption.DRYING]: 'Drying',
    }[recommendation_type] || '';

  const cfmCalculationText = `Min(Max((${cfm_offset} + ( ${
    cfm ? cfm : ''
  } * ${cfm_scaling})), ${cfm_min}), ${cfm_max})`;

  let evalCfm: number | undefined;
  if (cfm !== undefined && cfm !== null) {
    evalCfm = formatDeciamls(
      Math.min(
        Math.max(Number(cfm_offset) + Number(cfm) * Number(cfm_scaling), Number(cfm_min)),
        Number(cfm_max)
      )
    );
  }

  return (
    <div className={classes.experiment_cfm_calculations}>
      <h3>Cfm {experimentRecommendationTypeText} Formula:</h3>
      <p>lower_bound&#60;&#61;current_value&#60;&#61;upper_bound</p>
      <h3>Cfm Calculation:</h3>
      <p>Min(Max((cfm_offset + ( cfm * cfm_scaling)), cfm_min), cfm_max) = eval_cfm</p>
      <p>
        {cfmCalculationText}&nbsp;<b>=&nbsp;{evalCfm}</b>
      </p>
      <LowerBoundCalculation
        recommendation_type={recommendation_type}
        current_grain_emc={
          current_grain_emc !== undefined ? Number(current_grain_emc) : current_grain_emc
        }
        target_grain_emc_warming={target_grain_emc_warming}
        safety_margin={SAFETY_MARGIN}
        target_grain_emc={target_grain_emc ? target_grain_emc : TARGET_GRAIN_EMC}
        eval_cfm={evalCfm}
      />
      <UpperBoundCalculation
        recommendation_type={recommendation_type}
        current_grain_emc={
          current_grain_emc !== undefined ? Number(current_grain_emc) : current_grain_emc
        }
        target_grain_emc_warming={target_grain_emc_warming}
        safety_margin={SAFETY_MARGIN}
        target_grain_emc={target_grain_emc ? target_grain_emc : TARGET_GRAIN_EMC}
        eval_cfm={evalCfm}
      />
    </div>
  );
};

const LowerBoundCalculation = ({
  recommendation_type,
  current_grain_emc,
  target_grain_emc_warming,
  safety_margin,
  target_grain_emc,
  eval_cfm,
}: LowerBoundCalculationProps) => {
  const hasDryingRecommType = recommendation_type === RecommendedOption.DRYING;
  const hasReconditioningRecommType = recommendation_type === RecommendedOption.RECONDITIONING;
  const evalReconditioningLowerBound =
    target_grain_emc_warming !== undefined
      ? formatDeciamls(Math.max(target_grain_emc_warming - safety_margin, 0))
      : undefined;
  const evalReconditioningLowerBoundText = `Max(${target_grain_emc_warming} - ${safety_margin}, 0)`;

  const evalDryingLowerBound =
    target_grain_emc_warming !== undefined &&
    current_grain_emc !== undefined &&
    eval_cfm !== undefined
      ? formatDeciamls(
          Math.max(
            target_grain_emc_warming -
              (current_grain_emc - target_grain_emc) / eval_cfm -
              safety_margin,
            0
          )
        )
      : undefined;
  const evalDryingLowerBoundText = `Max(${target_grain_emc_warming} - ((${current_grain_emc} - ${target_grain_emc}) / ${eval_cfm}) - ${safety_margin}, 0)`;

  return (
    <>
      <h3 style={{ marginTop: 30 }}>Lower Bound Calculation:</h3>
      {hasReconditioningRecommType && (
        <>
          <p>Max(target_grain_emc_warming - safety_margin, 0)= lower_bound</p>
          <p>
            {evalReconditioningLowerBoundText}&nbsp;<b>=&nbsp;{evalReconditioningLowerBound}</b>
          </p>
        </>
      )}
      {hasDryingRecommType && (
        <>
          <p>
            Max(target_grain_emc_warming - ((current_grain_emc - target_grain_emc) / eval_cfm) -
            safety_margin, 0) = lower_bound
          </p>
          <p>
            {evalDryingLowerBoundText}&nbsp;<b>=&nbsp;{evalDryingLowerBound}</b>
          </p>
        </>
      )}
    </>
  );
};

const UpperBoundCalculation = ({
  recommendation_type,
  current_grain_emc,
  target_grain_emc_warming,
  safety_margin,
  target_grain_emc,
  eval_cfm,
}: UpperBoundCalculationProps) => {
  const hasDryingRecommType = recommendation_type === RecommendedOption.DRYING;
  const hasReconditioningRecommType = recommendation_type === RecommendedOption.RECONDITIONING;

  const evalReconditioningUpperBound =
    target_grain_emc_warming !== undefined &&
    current_grain_emc !== undefined &&
    eval_cfm !== undefined
      ? formatDeciamls(
          target_grain_emc_warming +
            (target_grain_emc - current_grain_emc) / eval_cfm +
            safety_margin
        )
      : undefined;
  const evalReconditioningUpperBoundText = `${target_grain_emc_warming} + ((${target_grain_emc} - ${current_grain_emc}) / ${eval_cfm}) + ${safety_margin}`;

  const evalDryingUpperBound =
    target_grain_emc_warming !== undefined
      ? formatDeciamls(target_grain_emc_warming + safety_margin)
      : undefined;
  const evalDryingUpperBoundText = `${target_grain_emc_warming} + ${safety_margin}`;

  return (
    <>
      <h3 style={{ marginTop: 30 }}>Upper Bound Calculation:</h3>
      {hasReconditioningRecommType && (
        <>
          <p>
            target_grain_emc_warming + ((target_grain_emc - current_grain_emc) / eval_cfm) +
            safety_margin = upper_bound
          </p>
          <p>
            {evalReconditioningUpperBoundText}&nbsp;<b>=&nbsp;{evalReconditioningUpperBound}</b>
          </p>
        </>
      )}
      {hasDryingRecommType && (
        <>
          <p>target_grain_emc_warming + safety_margin = upper_bound</p>
          <p>
            {evalDryingUpperBoundText}&nbsp;<b>=&nbsp;{evalDryingUpperBound}</b>
          </p>
        </>
      )}
    </>
  );
};
