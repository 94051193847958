import { ApolloClient } from 'apollo-client';
import { delay } from 'redux-saga';
import { cancel, fork, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ContainerTypeLegacy } from '../util/constant';

import { pollBackgroundGrainBinUpdateState } from '../action';
import { GetGrainBinDocument, GetGrainBinQuery, GetGrainBinQueryVariables } from '../api';

let bg_grain_bin_update_poll_task: any;

function* pollForUpdatedGrainBinInfoLoop(
  apollo_client: ApolloClient<any>,
  container_id: number,
  container_type: ContainerTypeLegacy
) {
  try {
    while (true) {
      // poll every 10 sec
      yield delay(1000 * 10);

      // query for GrainBin in order to update the scheduled aeration windows
      const grain_bin_results = yield apollo_client.query<GetGrainBinQuery>({
        query: GetGrainBinDocument,
        variables: { grain_bin_id: container_id },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });

      // update cache
      yield apollo_client.writeQuery<GetGrainBinQuery, GetGrainBinQueryVariables>({
        query: GetGrainBinDocument,
        data: grain_bin_results.data,
        variables: { grain_bin_id: container_id },
      });
    }
  } finally {
    // console.log('pollForUpdatedGrainBinInfoLoop ended');
  }
}

function* pollBackgroundGrainBinUpdateSaga(
  apollo_client: ApolloClient<any>,
  action: ReturnType<typeof pollBackgroundGrainBinUpdateState>
) {
  if (!action.payload) return;

  try {
    const {
      payload: { container_id, container_type },
    } = action;

    // cancel existing poll if there is one
    if (bg_grain_bin_update_poll_task) {
      yield cancel(bg_grain_bin_update_poll_task);
      bg_grain_bin_update_poll_task = null;
    }

    if (container_id && container_type) {
      bg_grain_bin_update_poll_task = yield fork(
        pollForUpdatedGrainBinInfoLoop,
        apollo_client,
        container_id,
        container_type
      );
    }
  } catch (e) {
    console.error(e);
  }
}

export function* backgroundGrainBinUpdatePollWatcher(apollo_client: ApolloClient<any>) {
  yield takeEvery(
    getType(pollBackgroundGrainBinUpdateState),
    pollBackgroundGrainBinUpdateSaga,
    apollo_client
  );
}
