import * as React from 'react';
const GrainTemperature = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} viewBox="0 0 40 40" {...props}>
    <g id="CurrTemperatureIcon" transform="translate(-0.75)">
      <rect
        id="Rectangle_293"
        data-name="Rectangle 293"
        width={40}
        height={40}
        rx={20}
        transform="translate(0.75)"
        fill="#eff1f4"
      />
      <path
        id="Path_316"
        data-name="Path 316"
        d="M23.75,21V13a3,3,0,0,0-6,0v8a5,5,0,1,0,6,0Zm-4-8a1,1,0,0,1,2,0h-1v1h1v2h-1v1h1v2h-2Z"
        fill="#242a33"
      />
    </g>
  </svg>
);

const GrainTemperatureWithoutShade = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={10} height={20} viewBox="0 0 10 20" {...props}>
    <path
      id="ThermoIcon"
      d="M4994.359,448.421v-8a3,3,0,0,0-6,0v8a5,5,0,1,0,6,0Zm-4-8a1,1,0,0,1,2,0h-1v1h1v2h-1v1h1v2h-2Z"
      transform="translate(-4986.359 -437.421)"
      fill="gray"
    />
  </svg>
);
export { GrainTemperature, GrainTemperatureWithoutShade };
