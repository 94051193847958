import { Button, Chip, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { pushNav } from '../../../../../../core/src/action';
import { MediumWarnAlertIcon } from '../../../../../../core/src/media';
import { yellow_shade_5 } from '../../../../../../core/src/style';

export const LevelHistoryCardHeaderAction = ({
  url_base,
  hideManageGrainTicketsBtn,
  showGrainTicketLevelDeviationWarning,
  noGrainTicketsCreated,
  lastPendingGrainTicketGeneratedDaysText,
}: {
  url_base: string;
  hideManageGrainTicketsBtn: boolean;
  showGrainTicketLevelDeviationWarning: boolean;
  noGrainTicketsCreated: boolean;
  lastPendingGrainTicketGeneratedDaysText: string;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const dispatch = useDispatch();

  const gotoManageTickets = () => dispatch(pushNav({ path: `${url_base}/tickets` }));

  const _renderButtonWithBadgeSection = () => {
    return isMobile ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'end',
        }}
      >
        <Button
          variant="outlined"
          style={{ maxHeight: 26, padding: 10 }}
          onClick={gotoManageTickets}
        >
          {noGrainTicketsCreated ? 'Add Grain Ticket' : 'Manage Grain Tickets'}
          <ArrowForwardIos style={{ height: 15, width: 10, marginLeft: 5 }} />
        </Button>
        <Tooltip
          placement="bottom"
          title={<div style={{ fontSize: 12 }}>Pending Grain Tickets</div>}
          arrow
        >
          <div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
            <Chip
              icon={<MediumWarnAlertIcon />}
              size="small"
              variant="outlined"
              label={lastPendingGrainTicketGeneratedDaysText}
              style={{ border: `1px solid ${yellow_shade_5}`, padding: '0px 4px' }}
            />
          </div>
        </Tooltip>
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Tooltip
          placement="bottom"
          title={<div style={{ fontSize: 12 }}>Pending Grain Tickets</div>}
          arrow
        >
          <Chip
            icon={<MediumWarnAlertIcon />}
            size="small"
            variant="outlined"
            label={lastPendingGrainTicketGeneratedDaysText}
            style={{ border: `1px solid ${yellow_shade_5}`, padding: '0px 4px', marginRight: 8 }}
          />
        </Tooltip>
        <Button
          variant="outlined"
          style={{ maxHeight: 26, padding: 10 }}
          onClick={gotoManageTickets}
        >
          {noGrainTicketsCreated ? 'Add Grain Ticket' : 'Manage Grain Tickets'}
          <ArrowForwardIos style={{ height: 15, width: 10, marginLeft: 5 }} />
        </Button>
      </div>
    );
  };

  return (
    <>
      {hideManageGrainTicketsBtn && showGrainTicketLevelDeviationWarning && (
        <Tooltip
          placement="bottom"
          title={<div style={{ fontSize: 12 }}>Pending Grain Tickets</div>}
          arrow
        >
          <div>
            <Chip
              icon={<MediumWarnAlertIcon />}
              size="small"
              variant="outlined"
              label={lastPendingGrainTicketGeneratedDaysText}
              style={{ border: `1px solid ${yellow_shade_5}`, padding: '0px 4px' }}
              onClick={() => {
                const element = document.getElementById('grainTicketWarningBanner');
                element &&
                  element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
              }}
            />
          </div>
        </Tooltip>
      )}
      {url_base && !hideManageGrainTicketsBtn && (
        <>
          {showGrainTicketLevelDeviationWarning ? (
            _renderButtonWithBadgeSection()
          ) : (
            <Button
              variant="outlined"
              style={{ maxHeight: 26, padding: 10 }}
              onClick={gotoManageTickets}
            >
              {noGrainTicketsCreated ? 'Add Grain Ticket' : 'Manage Grain Tickets'}
              <ArrowForwardIos style={{ height: 15, width: 10, marginLeft: 5 }} />
            </Button>
          )}
        </>
      )}
    </>
  );
};
