import { Card, CardContent, CardHeader, Divider, Grid, IconButton } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';

import { CableTempDiagram } from './CableTempDiagram';
import { Cable } from './PileCablesTable';

const useStyles = makeStyles({
  subheader: {
    textAlign: 'left',
  },
  action: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: { marginBottom: 2 },
  icon_root: {
    margin: -10,
  },
});

export const PileCableDetailsCard: FunctionComponent<{
  selected_cable: Cable;
  container_id: number;
  goPileCableForm: (arg: { cable_id: number }) => void;
}> = ({ selected_cable, container_id, goPileCableForm }) => {
  const classes = useStyles();

  const { alias, cable_id, package_alias } = selected_cable;
  const actions = [
    <IconButton
      key="settings"
      onClick={() => goPileCableForm({ cable_id })}
      color="secondary"
      className={classes.icon_root}
    >
      <Settings />
    </IconButton>,
  ];
  // TODO: low confidences

  return (
    <Card raised>
      <CardHeader
        title={`${alias || package_alias}`}
        titleTypographyProps={{
          color: 'secondary',
        }}
        action={actions}
        classes={{ subheader: classes.subheader, action: classes.action }}
      />
      <Divider classes={{ root: classes.divider }} />
      <CardContent>
        <Grid container justify="space-around" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <CableTempDiagram cable={selected_cable} container_id={container_id} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
