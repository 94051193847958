import { datadogLogs } from '@datadog/browser-logs';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../action/version';

type ActionTypes = ActionType<typeof actions>;
export type VersionState = {
  display_optional_update: boolean;
};
export const default_version_state: Readonly<VersionState> = Object.freeze({
  display_optional_update: false,
});

export const versionReducer = (
  state: VersionState = default_version_state,
  action: ActionTypes
) => {
  switch (action.type) {
    case getType(actions.newVersion): {
      return {
        ...state,
        display_optional_update: true,
      };
    }
    case getType(actions.ignoreVersion): {
      return {
        ...state,
        display_optional_update: false,
      };
    }
    default:
      return state;
  }
};
