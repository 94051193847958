import { Grow, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { DateTime, Interval } from 'luxon';
import React, { useEffect, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { FanRunWindowRecommendedOption } from '../../../../../core/src/api';
import { useWeatherData } from '../../../../../core/src/contexts';
import RightArrowIcon from '../../../../../core/src/media/icons/RightArrowIcon';
import {
  black,
  black_shade_1,
  black_shade_4,
  black_shade_7,
  light_gray_8,
  light_gray_shade_1,
} from '../../../../../core/src/style';
import { getDurationText } from '../../../../../core/src/util';
import { RecommendedOption } from '../RecommendationOptionValue';
import { IndividualRunwindowWeatherPlot, RunwindowWeatherPlot } from '../run-window-weather-chart';
import { ErrorIcon, RunPauseIcon } from './../../../../../core/src/media';
import { formatDayNow } from './FanSetMultiSchedule';
import { RunWindowStartEndIndicator } from './RunWindowStartEndIndicator';

type makeStylesProps = {
  blurScheduledRun: boolean;
};
const useStyles = makeStyles((theme: Theme) => ({
  // mobile styles
  mobileRunWindowRoot: {
    width: '100%',
    height: 102,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    borderRadius: 8,
    [theme.breakpoints.only('xs')]: {
      width: '98%',
    },
    [theme.breakpoints.between(405, 600)]: {
      width: '88%',
    },
  },
  avgWeatherContainer: {
    marginLeft: 8,
    [theme.breakpoints.between(370, 600)]: {
      marginLeft: 24,
    },
  },
  runtimeContainer: {
    marginLeft: 8,
    [theme.breakpoints.between(370, 600)]: {
      marginLeft: 24,
    },
  },
  mobileRunWindow: {
    width: '100%',
    height: 102,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    padding: 12,
    paddingRight: 8,
    border: `1px solid ${black_shade_4}`,
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  mobileRunWindowFieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  // wide screen styles
  runWindowRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 4px',
    borderRadius: 10,
    border: '1px solid lightgray',
    [theme.breakpoints.between(1280, 1580)]: {
      width: '100%',
    },
  },
  centerItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  moreInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.between(1550, 1580)]: {
      marginLeft: 24,
    },
  },

  day: {
    width: 68,
    height: 32,
    padding: 8,
    backgroundColor: '#EFF1F4',
    borderRadius: 8,
    fontSize: 14,
    color: ({ blurScheduledRun }: makeStylesProps) =>
      blurScheduledRun ? black_shade_7 : '#242a33',
    lineHeight: '18px',
    marginRight: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      width: 68,
      padding: 8,
      height: 32,
    },
    // [theme.breakpoints.between(theme.breakpoints.width('lg'), 1550)]: {
    //   width: 52,
    // },
  },
  time: {
    width: 84,
    padding: 8,
    height: 32,
    backgroundColor: '#EFF1F4',
    borderRadius: 8,
    fontSize: 14,
    color: ({ blurScheduledRun }: makeStylesProps) =>
      blurScheduledRun ? black_shade_7 : '#242a33',
    lineHeight: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  moreInfoLabels: {
    fontSize: 10,
    letterSpacing: 2,
    textTransform: 'uppercase',
    color: ({ blurScheduledRun }: makeStylesProps) =>
      blurScheduledRun ? black_shade_7 : light_gray_shade_1,
  },
  moreInfoValues: {
    color: ({ blurScheduledRun }: makeStylesProps) =>
      blurScheduledRun ? black_shade_7 : black_shade_1,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.1,
  },
  separater: {
    display: 'flex',
    justifyContent: 'center',
  },
  dashPlaceholder: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    height: 16,
  },
  runwindowWeatherPlotContainer: {
    padding: '0 0px 0px 0px !important',
    marginLeft: 10,
    width: 'calc(100% - 275px)',
  },
}));
type RunWinowReadOnlyProps = {
  showLoadingAnimation: boolean;
  start: DateTime;
  end: DateTime;
  runtime: number;
  runtimeInText?: string;
  estimatedTemp: string | null;
  estimatedRH: string | null;
  index: number;
  recommendationOptionValue: FanRunWindowRecommendedOption | RecommendedOption | null;
  preventNow?: boolean;
  hasScheduledRunInterrupted?: boolean;
  showErrorMsgInSchedule?: boolean;
  currentRunWindowErrors?: string[];
  showErrorMsgInFirstRunWindow?: boolean;
  fanErrorMsgsWithoutStopFail?: string[];
  showInitialSeriesAnimation?: boolean;
};
export const RunWinowReadOnly = ({
  showLoadingAnimation,
  start,
  end,
  runtime,
  runtimeInText,
  estimatedTemp,
  estimatedRH,
  index: ix,
  recommendationOptionValue,
  preventNow = false,
  hasScheduledRunInterrupted = false,
  showErrorMsgInFirstRunWindow = false,
  fanErrorMsgsWithoutStopFail = [],
  showInitialSeriesAnimation = true,
}: RunWinowReadOnlyProps) => {
  const blurScheduledRun =
    showErrorMsgInFirstRunWindow || (hasScheduledRunInterrupted && !showErrorMsgInFirstRunWindow);
  console.log(
    'blurScheduledRun',
    blurScheduledRun,
    showErrorMsgInFirstRunWindow,
    hasScheduledRunInterrupted
  );
  const classes = useStyles({ blurScheduledRun });
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
  const isStartOfLgScreen = useMediaQuery(
    theme.breakpoints.between('lg', theme.breakpoints.width('lg') + 220),
    { noSsr: true }
  );
  const isSpecificLgScreen = useMediaQuery(
    theme.breakpoints.between(theme.breakpoints.width('lg') + 220, 1580),
    { noSsr: true }
  );
  const weatherData = useWeatherData();
  const durationText = getDurationText(runtime);
  const as_of = DateTime.local();
  const isStartDateIsNow = start && formatDayNow(start, as_of, preventNow) === 'Now';
  // animation states
  const flipSpeedBackToFront = 0.6;
  const flipDirection = 'vertical';
  const showWithoutSkeleton = !showLoadingAnimation;
  const [isField1Flipped, setIsField1Flipped] = useState<boolean>(showWithoutSkeleton);
  const [isField2Flipped, setIsField2Flipped] = useState<boolean>(showWithoutSkeleton);
  const [isField3Flipped, setIsField3Flipped] = useState<boolean>(showWithoutSkeleton);
  const [isField4Flipped, setIsField4Flipped] = useState<boolean>(showWithoutSkeleton);
  const [hasMoreInfoLoaded, setHasMoreInfoLoaded] = useState<boolean>(showWithoutSkeleton);
  const adder = ix * 1000;
  const runwindowFieldsTimeouts = [adder + 100, adder + 300, adder + 500, adder + 700, adder + 900];
  const runtimeTextContent = runtimeInText ? <div>{runtimeInText}</div> : <div>{durationText}</div>;

  const nowBlockStyles = isStartDateIsNow
    ? {
        width: 156,
        marginRight: 0,
      }
    : {};
  const foreverBlockStyles =
    start && formatDayNow(end) === 'Forever' ? { width: 156, marginRight: 0 } : {};

  useEffect(() => {
    let id1;
    let id2;
    let id3;
    let id4;
    let id5;
    if (showLoadingAnimation) {
      id1 = setTimeout(() => {
        setIsField1Flipped(true);
      }, runwindowFieldsTimeouts[0]);

      id2 = setTimeout(() => {
        setIsField2Flipped(true);
      }, runwindowFieldsTimeouts[1]);

      id3 = setTimeout(() => {
        setIsField3Flipped(true);
      }, runwindowFieldsTimeouts[2]);

      id4 = setTimeout(() => {
        setIsField4Flipped(true);
      }, runwindowFieldsTimeouts[3]);

      id5 = setTimeout(() => {
        setHasMoreInfoLoaded(true);
      }, runwindowFieldsTimeouts[4]);
    }

    return () => {
      if (showLoadingAnimation) {
        clearTimeout(id1);
        clearTimeout(id2);
        clearTimeout(id3);
        clearTimeout(id4);
        clearTimeout(id5);
      }
    };
  }, [showLoadingAnimation]);

  const __editableStartSection = (
    <>
      <ReactCardFlip
        isFlipped={isField1Flipped}
        flipSpeedBackToFront={flipSpeedBackToFront}
        flipDirection={flipDirection}
      >
        <div className={classes.day} style={{ ...nowBlockStyles }} />
        <div className={classes.day} style={{ ...nowBlockStyles }}>
          {start && formatDayNow(start, as_of, preventNow)}
        </div>
      </ReactCardFlip>
      <ReactCardFlip
        isFlipped={isField2Flipped}
        flipSpeedBackToFront={flipSpeedBackToFront}
        flipDirection={flipDirection}
      >
        {!isStartDateIsNow && <div className={classes.time}> </div>}
        {!isStartDateIsNow && <div className={classes.time}> {start && start.toFormat('t')}</div>}
      </ReactCardFlip>
    </>
  );

  const __editableEndSection = (
    <>
      <ReactCardFlip
        isFlipped={isField3Flipped}
        flipSpeedBackToFront={flipSpeedBackToFront}
        flipDirection={flipDirection}
      >
        <div className={classes.day} style={{ ...foreverBlockStyles }} />
        <div className={classes.day} style={{ ...foreverBlockStyles }}>
          {end && formatDayNow(end)}
        </div>
      </ReactCardFlip>
      <ReactCardFlip
        isFlipped={isField4Flipped}
        flipSpeedBackToFront={flipSpeedBackToFront}
        flipDirection={flipDirection}
      >
        {end && formatDayNow(end) !== 'Forever' && <div className={classes.time}> </div>}
        {end && formatDayNow(end) !== 'Forever' && (
          <div className={classes.time}> {end && end.toFormat('t')}</div>
        )}
      </ReactCardFlip>
    </>
  );

  const runtimeTextOnMobile = showLoadingAnimation ? (
    <Grow in={true}>{runtimeTextContent}</Grow>
  ) : (
    <div>{runtimeTextContent}</div>
  );

  const avgWeatherTextOnMobile = showLoadingAnimation ? (
    <Grow in={true}>
      <div>
        {estimatedTemp} · {estimatedRH}
      </div>
    </Grow>
  ) : (
    <div>
      {estimatedTemp} · {estimatedRH}
    </div>
  );

  const scheduleInterruptedsMessage = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 5,
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <RunPauseIcon />
      </div>
      <div
        style={{
          marginLeft: 5,
          fontSize: 16,
          color: black,
          fontWeight: 600,
          width: 186,
        }}
      >
        Scheduled Run Interrupted
      </div>
    </div>
  );

  const scheduleErroredMessage = (
    <div
      style={{
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
      }}
    >
      {fanErrorMsgsWithoutStopFail.map((errrMsg, id) => (
        <div
          key={id}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            marginBottom: 5,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ErrorIcon />
          </div>
          <div
            style={{
              width: 136,
              marginLeft: 5,
              fontSize: 16,
              color: black,
              fontWeight: 600,
              textAlign: 'start',
            }}
          >
            {errrMsg}
          </div>
        </div>
      ))}
    </div>
  );

  const _mobileViewRunWindow = (
    <div className={classes.mobileRunWindowRoot}>
      <div style={{ position: 'absolute', left: -7 }}>
        <RunWindowStartEndIndicator
          recommendationOptionValue={recommendationOptionValue}
          blurScheduledRun={blurScheduledRun}
          currentRunWindowErrors={fanErrorMsgsWithoutStopFail}
        />
      </div>
      <div
        className={classes.mobileRunWindow}
        style={{ backgroundColor: blurScheduledRun ? light_gray_8 : undefined }}
      >
        <div>
          {/* start section*/}
          <div className={classes.mobileRunWindowFieldsContainer} style={{ marginBottom: 12 }}>
            <div className={classes.centerItem}>{__editableStartSection}</div>
            <div className={classes.runtimeContainer}>
              <div className={classes.moreInfoLabels}>Runtime</div>
              <div className={classes.moreInfoValues}>
                {hasMoreInfoLoaded ? (
                  runtimeTextOnMobile
                ) : (
                  <div className={classes.dashPlaceholder}>——</div>
                )}
              </div>
            </div>
          </div>
          {showErrorMsgInFirstRunWindow && <>{scheduleErroredMessage}</>}
          {!showErrorMsgInFirstRunWindow && hasScheduledRunInterrupted && (
            <>{scheduleInterruptedsMessage}</>
          )}
          {/* end section */}
          <div className={classes.mobileRunWindowFieldsContainer}>
            <div className={classes.centerItem}>{__editableEndSection}</div>
            <div className={classes.avgWeatherContainer}>
              <div className={classes.moreInfoLabels}>Avg weather</div>
              <div className={classes.moreInfoValues}>
                {hasMoreInfoLoaded ? (
                  avgWeatherTextOnMobile
                ) : (
                  <div className={classes.dashPlaceholder}>—— • ——</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  console.log('weatherData', weatherData);
  if (!weatherData) return null;

  console.log('weatherData.dailyForecast', weatherData.dailyForecast);
  // runwindow weather chart inputs
  const lastDailyForecastDate = new Date(
    weatherData.dailyForecast[weatherData.dailyForecast.length - 1].epoch_time
  );
  const lastDailyForecastEndDate = DateTime.fromMillis(lastDailyForecastDate.getTime()).endOf(
    'day'
  );

  const asOf = DateTime.local();
  const startDayDate = start.startOf('day');
  const endDayDate = end ? end.endOf('day') : lastDailyForecastEndDate;
  const chartDaysInterval = Interval.fromDateTimes(startDayDate, endDayDate);

  const hourlyForecast = weatherData.hourlyForecast.filter(({ epoch_time }) =>
    chartDaysInterval.contains(DateTime.fromMillis(new Date(epoch_time).getTime()))
  );
  const dailyForecast = weatherData.dailyForecast.filter(({ epoch_time }) =>
    chartDaysInterval.contains(DateTime.fromMillis(new Date(epoch_time).getTime()))
  );
  const hasDailyForecast = dailyForecast.length > 0;

  console.log('hourlyForecast & dailyForecast', {
    hourlyForecast,
    dailyForecast,
  });

  return (
    <>
      {isSmallMobile ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {_mobileViewRunWindow}
        </div>
      ) : (
        <div style={{ display: 'flex', width: '100%' }}>
          <div
            style={{
              width: 275,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {_mobileViewRunWindow}
          </div>
          {hasDailyForecast && (
            <div className={classes.runwindowWeatherPlotContainer}>
              <IndividualRunwindowWeatherPlot
                grain_bin_location_timezone={weatherData.grain_bin_location_timezone}
                hourly_forecast_data={hourlyForecast}
                days={dailyForecast}
                runWindows={[{ start, end }]}
                showInitialSeriesAnimation={showInitialSeriesAnimation}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
