import { Store } from 'redux';

import { workerMessage } from './action';
import { initIos } from './api';

export const initStore = async (store: Store) => {
  initIos((message) => {
    store.dispatch(workerMessage({ message }));
  });
  if (!navigator.serviceWorker) {
    return;
  }
  navigator.serviceWorker.onmessage = (ev: MessageEvent) => {
    store.dispatch(workerMessage({ message: ev.data }));
  };
};
