import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';
import { CloseRoundedBtnIcon } from '../../../../core/src/media';

const useStyles = makeStyles({
  paper: {
    margin: 20,
    width: '100%',
    overflowY: 'initial',
  },
  content: {
    fontSize: 24,
    marginBottom: 20,
  },
  confirmButton: {
    fontSize: 14,
    textAlign: 'center',
    backgroundColor: '#F44336',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#c82333',
    },
  },
  button: {
    fontSize: 14,
    textAlign: 'center',
  },
  grid: {
    width: '100%',
    padding: 20,
  },
  close: {
    position: 'absolute',
    right: -15,
    top: -15,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    border: '1px solid #707070',
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: 'white',
  },
});

type ConfirmationModalProps = {
  confirmationMessage: JSX.Element;
  showModal: boolean;
  handleCancel: Function;
  handleConfirm: Function;
  cancelBtnText: string;
  confirmBtbText: string;
};

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  confirmationMessage,
  showModal,
  cancelBtnText,
  confirmBtbText,
  handleCancel,
  handleConfirm,
}) => {
  const classes = useStyles();
  const defaultConfirmationMessage = 'Are you sure?';
  return (
    <Dialog
      maxWidth="xs"
      classes={{ paper: classes.paper }}
      open={showModal}
      onClose={() => handleCancel()}
    >
      <div className={classes.close} onClick={() => handleCancel()}>
        <CloseRoundedBtnIcon />
      </div>
      <DialogContent className={classes.grid}>
        <Typography className={classes.content}>
          {confirmationMessage || defaultConfirmationMessage}
        </Typography>
        <DialogActions>
          <Button
            variant="contained"
            className={classes.confirmButton}
            onClick={() => handleConfirm()}
          >
            {confirmBtbText}
          </Button>
          <Button className={classes.button} variant="contained" onClick={() => handleCancel()}>
            {cancelBtnText}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
