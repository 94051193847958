import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy } from '../../../util';
import {
  GetGrainContainerWeeklyCo2HistoryWithAerationsQuery,
  withGetGrainContainerWeeklyCo2HistoryWithAerations as HOC,
} from '../__generated';

export type WithGetGrainContainerWeeklyCO2HistoryWithAerationsHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  pollInterval?: number;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainContainerWeeklyCO2HistoryWithAerationsHocChildProps = {
  loading: boolean;
  grain_container_weekly_headspace_data:
    | GetGrainContainerWeeklyCo2HistoryWithAerationsQuery['grain_container']
    | null;
};
export const withGetGrainContainerWeeklyCO2HistoryWithAerationsHoc: AmberHoc<
  WithGetGrainContainerWeeklyCO2HistoryWithAerationsHocOwnProps,
  WithGetGrainContainerWeeklyCO2HistoryWithAerationsHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainContainerWeeklyCO2HistoryWithAerationsHocOwnProps,
      WithGetGrainContainerWeeklyCO2HistoryWithAerationsHocChildProps,
      typeof component
    >,
    WithGetGrainContainerWeeklyCO2HistoryWithAerationsHocChildProps
  >({
    options: ({ grain_bin_id, onError, pollInterval = 1000 * 60 * 30 }) => ({
      pollInterval,
      onError,
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id: grain_bin_id, container_type: ContainerTypeLegacy.bin },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          grain_container_weekly_headspace_data: null,
        };
      }
      return {
        loading: loading || false,
        grain_container_weekly_headspace_data: data.grain_container || null,
      };
    },
  })(component as any);
