import { makeStyles, Theme, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { light_gray2, light_gray_4 } from '../../../../../core/src/style';
import { useGrainBinData, useWeatherData } from '../../../contexts';
import {
  calculateRunwindowDuration,
  formatNumber,
  getTemperatureUnitLabel,
  renderModeColor,
} from '../../../util';
import { getRecommedationTypeMode } from '../aeration/FanSchedule';
import { computeAvgGrainConditions } from '../aeration/FanSetMultiSchedule';
import { GrainWeatherInputsCard } from '../aeration/GrainWeatherInputsCard';
import { NoSchedulePlaceholder } from '../aeration/NoSchedulePlaceholder';
import { RunWinowReadOnly } from '../aeration/RunWinowReadOnly';
import { RecommendedOption } from '../RecommendationOptionValue';

const useStyles = makeStyles((theme: Theme) => ({
  grainWeatherInputsCard: {
    borderRadius: 8,
    width: '100%',
    background: light_gray2,
    display: 'flex',
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 10,
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    marginBottom: 20,
  },
  runwindowCountBadge: {
    width: 20,
    height: 20,
    fontSize: 16,
    fontWeight: 600,
    color: 'black',
    background: light_gray_4,
    borderRadius: '50% 50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeholderContainer: {
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      width: '98%',
    },
    [theme.breakpoints.between(405, 600)]: {
      width: '88%',
    },
    [theme.breakpoints.between(1280, 1580)]: {
      width: '100%',
    },
  },
}));

const renderTitleText = (recommendationType: string) => {
  switch (recommendationType) {
    case RecommendedOption.COOLING:
      return 'Cooling';
    case RecommendedOption.DRYING:
      return 'Drying';
    case RecommendedOption.RECONDITIONING:
      return 'Reconditioning';
  }
};

const emptyWindowMessageText = (recommendationType: string) => {
  switch (recommendationType) {
    case RecommendedOption.COOLING:
      return 'No Recommended Cooling Windows';
    case RecommendedOption.DRYING:
      return 'No Recommended Drying Windows';
    case RecommendedOption.RECONDITIONING:
      return 'No Recommended Reconditioning Windows';
  }
};

type RecommendedScheduleListProps = {
  schedule: { start_epoch: Date; end_epoch: Date }[];
};

export const RecommendedScheduleList = ({ schedule }: RecommendedScheduleListProps) => {
  const classes = useStyles();
  const [avgGrainConditions, setAvgGrainConditions] = useState<any>([]);
  const weatherData = useWeatherData();
  const {
    current_grain_temp,
    current_grain_emc,
    target_grain_emc,
    recommendation_type,
  } = useGrainBinData();
  console.log('RecommendedScheduleList', schedule, weatherData);
  const { dailyForecast } = weatherData;
  const validRecommendedSchedule =
    dailyForecast && dailyForecast.length > 0
      ? schedule.filter(({ start_epoch, end_epoch }) => {
          const firstDt = DateTime.fromMillis(new Date(dailyForecast[0].epoch_time).getTime());
          const lastDt = DateTime.fromMillis(
            new Date(dailyForecast[dailyForecast.length - 1].epoch_time).getTime()
          );
          return (
            new Date(end_epoch).getTime() > firstDt.startOf('day').toMillis() &&
            new Date(start_epoch).getTime() <= lastDt.endOf('day')
          );
        })
      : [];
  console.log('validRecommendedSchedule', validRecommendedSchedule);
  const tempUnit = getTemperatureUnitLabel();

  useEffect(() => {
    const refreshedAverageGrainConditions = validRecommendedSchedule.map(
      ({ start_epoch, end_epoch }) => {
        /// When the user selects 'Now', the start date is always -1 day before the
        /// current datetime
        let start = DateTime.utc().toMillis();
        const now = start_epoch.getTime();
        if (now > start) {
          start = start_epoch.getTime();
        }

        const end = end_epoch.getTime();
        const [temp, rh] = computeAvgGrainConditions(start, end, weatherData.hourlyForecast);

        return { temp, rh };
      }
    );
    if (weatherData) setAvgGrainConditions(refreshedAverageGrainConditions);
  }, [schedule, weatherData]);

  return (
    <div>
      <Typography variant="h6" style={{ width: '100%', padding: '0px' }}>
        Recommended Schedule
      </Typography>

      {recommendation_type ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className={classes.grainWeatherInputsCard}>
            <GrainWeatherInputsCard
              current_grain_temp={
                current_grain_temp === null || current_grain_temp === undefined
                  ? null
                  : current_grain_temp
              }
              current_grain_emc={current_grain_emc || null}
              target_grain_emc={target_grain_emc || null}
              mode={getRecommedationTypeMode(recommendation_type)}
              modeColor={renderModeColor(recommendation_type)}
            />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
                width: '100%',
                paddingLeft: 10,
              }}
            >
              <div>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: '0px',
                    marginBottom: '0px',
                    paddingLeft: 4,
                    fontSize: 17,
                    color: 'black',
                    lineHeight: '20.4px',
                  }}
                  gutterBottom={true}
                >
                  {renderTitleText(recommendation_type)} Schedule{' '}
                </Typography>
              </div>
              <div className={classes.runwindowCountBadge}>
                {validRecommendedSchedule ? validRecommendedSchedule.length : '-'}
              </div>
            </div>
            {validRecommendedSchedule.length === 0 && (
              <div style={{ marginBottom: '20px', width: '100%' }}>
                <NoSchedulePlaceholder message={emptyWindowMessageText(recommendation_type)} />
              </div>
            )}
          </div>
          <div style={{ width: '100%', paddingLeft: 10 }}>
            {validRecommendedSchedule &&
              validRecommendedSchedule.length > 0 &&
              validRecommendedSchedule.map(({ start_epoch, end_epoch }, ix) => {
                const start = DateTime.fromMillis(start_epoch.getTime());
                const end = DateTime.fromMillis(end_epoch.getTime());
                const duration = calculateRunwindowDuration({ start, end });
                const estimatedTemp = avgGrainConditions
                  ? avgGrainConditions[ix]
                    ? `${formatNumber(avgGrainConditions[ix]['temp'], 0)}${tempUnit}`
                    : `- ${tempUnit}`
                  : null;
                const estimatedRH = avgGrainConditions
                  ? avgGrainConditions[ix]
                    ? `${formatNumber(avgGrainConditions[ix]['rh'] * 100, 0)}% RH`
                    : '- RH'
                  : null;
                return (
                  <div className={classes.wrapper} key={`recomm-run-windows-${ix}`}>
                    <RunWinowReadOnly
                      index={ix}
                      start={start}
                      end={end}
                      estimatedTemp={estimatedTemp}
                      estimatedRH={estimatedRH}
                      runtime={duration}
                      showLoadingAnimation={false}
                      recommendationOptionValue={recommendation_type as any}
                      showInitialSeriesAnimation={false}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <>
          {schedule && schedule.length === 0 && (
            <div className={classes.placeholderContainer} style={{ marginTop: 15, paddingLeft: 0 }}>
              <NoSchedulePlaceholder
                message={'No recommendation type selected'}
                containerStyles={{ marginBottom: 0 }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
