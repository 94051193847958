import { useMediaQuery, useTheme } from '@material-ui/core';
import { green, yellow } from '@material-ui/core/colors';
import ToysIcon from '@material-ui/icons/Toys';
import React, { useMemo } from 'react';
import { GrainBinNotification } from '../../../../core/src/api';
import { CO2Icon, FanAutomationIcon, GrainLevelIcon } from '../../../../core/src/media';
import { BasicTimeline } from '../util/timeline/BasicTimeline';
import { GrainBinNotificationCard } from './GrainBinNotificationCard';

const NOTIFICATION_SOURCE_TYPES = {
  co2: 20,
  fan: 1,
  automation: 30,
  grain_level: 40,
};

const GET_NOTIFICATION_TYPE_ICON_AND_COLOR = {
  [NOTIFICATION_SOURCE_TYPES.co2]: {
    color: 'tomato',
    icon: <CO2Icon />,
  },
  [NOTIFICATION_SOURCE_TYPES.fan]: { color: '#971efa', icon: <ToysIcon /> },
  [NOTIFICATION_SOURCE_TYPES.automation]: { color: yellow['700'], icon: <FanAutomationIcon /> },
  [NOTIFICATION_SOURCE_TYPES.grain_level]: { color: green['700'], icon: <GrainLevelIcon /> },
};

export const GrainBinNotifications = ({
  user_device_notifications_history,
  hasInternalSiteLoaded,
  user_last_notifications_read_on,
}: {
  user_device_notifications_history: GrainBinNotification[];
  hasInternalSiteLoaded: boolean;
  user_last_notifications_read_on: Date;
}) => {
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const items = useMemo(() => {
    return user_device_notifications_history.map((grain_bin_notification, idx) => {
      const timeLineDotIcon =
        GET_NOTIFICATION_TYPE_ICON_AND_COLOR[grain_bin_notification.source_type].icon || undefined;
      const timelineItemColor =
        GET_NOTIFICATION_TYPE_ICON_AND_COLOR[grain_bin_notification.source_type].color;
      return {
        timelineItemColor,
        timeLineDotIcon,
        id: grain_bin_notification.notification_id,
        content: (
          <GrainBinNotificationCard
            key={grain_bin_notification.notification_id}
            idx={idx}
            grain_bin_notification={grain_bin_notification}
            cardBorderColor={timelineItemColor}
            hasInternalSiteLoaded={hasInternalSiteLoaded}
            user_last_notifications_read_on={user_last_notifications_read_on}
          />
        ),
      };
    });
  }, [JSON.stringify(user_device_notifications_history)]);

  return (
    <div style={{ paddingLeft: isWideScreen ? '30px' : undefined, paddingTop: 15 }}>
      <BasicTimeline items={items} />
    </div>
  );
};
