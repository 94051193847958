import { fade, Grid, Tooltip, Typography } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { DateTime, Settings } from 'luxon';
import React, { useContext, useMemo, useState } from 'react';
import { GetProps } from 'react-redux';
import * as yup from 'yup';
import { amber_amber } from '../../../../core/src/style';
import {
  CreateGrainBinMutationVariables,
  FloorType,
  GrainBinFragmentFragment,
  GrainType,
  UpdateGrainBinMutationVariables,
  UserRole,
  withCreateGrainBinHoc,
  WithCreateGrainBinHocChildProps,
  withGetGrainBinHoc,
  WithGetGrainBinHocChildProps,
  withGetGrainBinStoragePeriodHoc,
  withUpdateGrainBinHoc,
  WithUpdateGrainBinHocChildProps,
} from '../../api';
import { ActiveStoragePeriodContext } from '../../contexts';
import { UiFormError } from '../../util/format-error';
import { DialogSpinner } from '../spinner';
import { BaseForm, FormikWrapper, FormikWrapperProps } from '../util/form2/BaseForm';
import { Button } from '../util/form2/Button';
import { ErrorBox } from '../util/form2/ErrorBox';
import { formatLatLng, yup_latlng } from '../util/form2/LocationPicker';
import { yup_floor_type } from '../util/form2/SelectFloorType';
import { yup_grain_type } from '../util/form2/SelectGrainType';
import { getUserPermissionTooltipText } from './aeration';
import { CreateOrUpdateStoragePeriodForm } from './CreateOrUpdateStoragePeriodForm';
import { RecommendedOption } from './RecommendationOptionValue';
import { StoragePeriodsTable } from './StoragePeriodsTable';

const useStyles = makeStyles({
  grid: {
    minWidth: 300,
    maxWidth: 600,
  },
  centered: {
    textAlign: 'center',
  },
  location: {
    height: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  location_picker: { flex: '1 1 auto' },
});

export type TStoragePeriod = {
  grain_bin_storage_cycle_id?: number;
  storage_period_name: string;
  grain_type: string;
  start_date: string;
  end_date: string | null;
  is_ongoing_storage_period: boolean;
};

type Values = {
  bin_name: string;
  grain_type: GrainType | null;
  height_ft: number | null;
  diameter_ft: number | null;
  location: {
    latlng_str: string;
    latlng: { lat: number; lng: number } | null;
  };
  target_grain_emc: number | null;
  recommendation_type: string | null;
  current_grain_emc: number | null;
  current_grain_temp: number | null;
  has_grain_spreader: boolean | null;
  floor_type: FloorType;
};
const validationSchema = yup.object().shape({
  bin_name: yup
    .string()
    .label('Storage Period Name')
    .required(),
  grain_type: yup_grain_type
    .label('Grain Type')
    .required()
    .nullable(false),
  height_ft: yup
    .number()
    .typeError('A number is required')
    .label('Height')
    .required()
    .positive()
    .max(200),
  diameter_ft: yup
    .number()
    .typeError('A number is required')
    .label('Diameter')
    .required()
    .positive()
    .max(200),
  location: yup_latlng.label('Location'),
  has_grain_spreader: yup.boolean(),
  floor_type: yup_floor_type
    .label('Floor Type')
    .required()
    .nullable(false),
  target_grain_emc: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .label('Target Grain Moisture %')
    .positive()
    .min(0)
    .max(100),
  recommendation_type: yup.string().required(),
  current_grain_emc: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required()
    .label('Current Grain Moisture %')
    .positive()
    .min(0)
    .max(100),
  current_grain_temp: yup
    .number()
    .typeError('A number is required')
    .required()
    .transform((value) => (isNaN(value) ? undefined : value))
    .label('Current Grain Temp F'),
});

// Formats to  3/22/2020 from Date type
const formatDateFromISOString = (date: Date | null) => {
  return (date && DateTime.fromISO(date.toISOString()).toLocaleString()) || null;
};

const maxDimension = 200;

const transform = (v) => {
  return v.map((period) => {
    const startDate = formatDateFromISOString(period.start_epoch);
    const maybeEndDate = formatDateFromISOString(period.end_epoch);
    return {
      grain_bin_storage_cycle_id: period.grain_bin_storage_cycle_id,
      storage_period_name: period.storage_cycle_name,
      grain_type: period.grain_type,
      start_date: startDate,
      end_date: maybeEndDate,
      is_ongoing_storage_period: period.is_ongoing_storage_period,
    };
  });
};

const StoragePeriodFormBase = withGetGrainBinStoragePeriodHoc(
  ({
    account_id,
    grain_bin,
    createGrainBin,
    updateGrainBin,
    grain_bin_storage_periods: { grain_bin_storage_periods },
    loading,
    viewer_role,
    refetch_grain_bin_storage_periods = () => {},
    ...props
  }: WithCreateGrainBinHocChildProps &
    WithUpdateGrainBinHocChildProps &
    Pick<
      FormikWrapperProps<Values, GrainBinFragmentFragment>,
      'onSubmitSuccess' | 'onSubmitFailure'
    > & {
      grain_bin_storage_periods: { grain_bin_storage_periods };
      loading: boolean;
      refetch_grain_bin_storage_periods?: any;
      grain_bin: GrainBinFragmentFragment | null;
      account_id: number | null;
      viewer_role: UserRole;
    }) => {
    // set timezone of DateTime to grain_bin's location timezone
    if (grain_bin) {
      Settings.defaultZone = grain_bin.tz;
      Settings.defaultLocale = 'en-US';
    }
    const _storagePeriods = transform(grain_bin_storage_periods);
    const classes = useStyles();
    const initial_values = useMemo(() => asJSON(grain_bin), [grain_bin]);
    const [isLoading, setLoading] = useState(loading);
    const [isUpdating, setUpdating] = useState(false);
    const [storagePeriods, setStoragePeriods] = useState(_storagePeriods);
    const activeStoragePeriodContext = useContext(ActiveStoragePeriodContext);
    const refetchStoragePeriods = async () => {
      /* console.log('Refetching...'); */
      const res = await refetch_grain_bin_storage_periods();
      if (res && res.data && res.data.grain_bin_storage_periods) {
        const newStoragePeriods = transform(res.data.grain_bin_storage_periods);
        /* console.log('Refetched with: ', newStoragePeriods); */
        setStoragePeriods(newStoragePeriods);
        setLoading(false);
      }
    };

    /// Shows which storage period to expand on the accordion by index
    /// where index -1 is a special case for a **new storage period**
    const [expanded, setExpanded] = useState(null);
    const handleExpandChange = (storagePeriodIndex) => (isExpanded) => {
      setExpanded(isExpanded ? storagePeriodIndex : null);
    };
    const hasUserAccessRestricted = [UserRole.FanAccess, UserRole.ReadOnly].includes(viewer_role);
    const disabledTooltipText = hasUserAccessRestricted
      ? getUserPermissionTooltipText(viewer_role)
      : '';

    if (isLoading) {
      refetchStoragePeriods();
      return <DialogSpinner title="Loading..." open={isLoading} />;
    }

    if (isUpdating) {
      return <DialogSpinner title="Updating..." open={isUpdating} />;
    }

    return (
      <FormikWrapper<Values, GrainBinFragmentFragment>
        {...props}
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initial_values}
        onSubmit={async (values) => {
          const {
            bin_name,
            grain_type,
            height_ft,
            diameter_ft,
            has_grain_spreader,
            location: {
              latlng: { lat: latitude, lng: longitude },
            },
            current_grain_emc,
            current_grain_temp,
            target_grain_emc,
            floor_type,
          } = validationSchema.validateSync(values);
          const location = { latitude, longitude };
          if (account_id !== null) {
            const variables: CreateGrainBinMutationVariables = {
              // todo type update after backend
              account_id,
              bin_name,
              grain_type,
              height_ft,
              diameter_ft,
              location,
              has_grain_spreader,
              floor_type,
              current_grain_emc,
              current_grain_temp,
            };
            return await createGrainBin(variables);
          }

          if (!grain_bin) {
            throw new UiFormError('Grain bin not loaded');
          }

          const variables: UpdateGrainBinMutationVariables = {
            bin_name,
            grain_type,
            height_ft,
            diameter_ft,
            location,
            has_grain_spreader,
            floor_type,
            current_grain_emc,
            current_grain_temp,
            grain_bin_id: grain_bin.grain_bin_id,
          };
          return await updateGrainBin(variables);
        }}
        render={({
          values: {
            location: { latlng },
          },
        }) => (
          <>
            {grain_bin && (
              <BaseForm
                submitting_message={account_id ? 'Creating Grain Bin...' : 'Saving changes...'}
              >
                <Grid
                  container
                  direction="row"
                  alignContent="flex-start"
                  alignItems="center"
                  justify="center"
                  spacing={2}
                  className={classes.grid}
                >
                  <Grid item xs={12}>
                    <Typography variant="h5" className={classes.centered}>
                      {grain_bin ? grain_bin.bin_name : 'Create New Grain Bin'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: '30px' }}>
                    <Tooltip
                      enterTouchDelay={0}
                      disableFocusListener
                      placement="right"
                      title={
                        disabledTooltipText ? (
                          <span style={{ fontSize: 13 }}>{disabledTooltipText}</span>
                        ) : (
                          ''
                        )
                      }
                      arrow
                    >
                      <div>
                        <Button
                          style={{ margin: '10px 0' }}
                          fullWidth
                          variant="outlined"
                          onClick={() => handleExpandChange(-1)(expanded !== -1)}
                          disabled={hasUserAccessRestricted}
                        >
                          <AddCircle
                            style={{
                              color: hasUserAccessRestricted ? fade(amber_amber, 0.3) : amber_amber,
                            }}
                          />
                          &nbsp; Create New Storage Period
                        </Button>
                      </div>
                    </Tooltip>
                    {expanded === -1 && (
                      <>
                        <CreateOrUpdateStoragePeriodForm
                          grain_bin_id={grain_bin.grain_bin_id}
                          handleCloseForm={() => setExpanded(null)}
                          setUpdating={setUpdating}
                          refetchStoragePeriods={refetchStoragePeriods}
                          storagePeriodStartDate={''}
                          storagePeriodEndDate={null}
                          is_ongoing_storage_period={true}
                          storagePeriodGrainType={grain_bin.grain_type}
                          storagePeriodName={null}
                        />
                        <ErrorBox />
                      </>
                    )}
                  </Grid>
                  <StoragePeriodsTable
                    setUpdating={setUpdating}
                    grain_bin_id={grain_bin.grain_bin_id}
                    storagePeriods={storagePeriods}
                    refetchStoragePeriods={refetchStoragePeriods}
                    expanded={expanded}
                    handleExpandChange={handleExpandChange}
                    hasUserAccessRestricted={hasUserAccessRestricted}
                    viewer_role={viewer_role}
                  />
                  {/* <Grid item xs={12} className={classes.centered}>
                    <br />
                    <ButtonSubmit prevent_submit_on_enter />
                    </Grid> */}
                </Grid>
              </BaseForm>
            )}{' '}
          </>
        )}
      />
    );
  }
);

const StoragePeriodForm = withCreateGrainBinHoc(withUpdateGrainBinHoc(StoragePeriodFormBase));

const UpdateStoragePeriodForm = ({
  grain_bin,
  viewer_role,
  ...props
}: Omit<GetProps<typeof StoragePeriodForm>, 'account_id' | 'grain_bin'> & {
  grain_bin: GrainBinFragmentFragment;
} & { viewer_role: UserRole }) => (
  <StoragePeriodForm {...props} account_id={null} grain_bin={grain_bin} viewer_role={viewer_role} />
);

export const ManageStoragePeriods = withGetGrainBinHoc(
  ({
    grain_bin,
    loading,
    viewer_role,
    ...props
  }: Omit<GetProps<typeof UpdateStoragePeriodForm>, 'grain_bin'> &
    WithGetGrainBinHocChildProps & { viewer_role: UserRole }) => {
    if (loading) {
      return <DialogSpinner title="Loading Grain Bin..." open={loading} />;
    }
    if (!grain_bin) {
      return <Typography variant="h5">An error occurred while loading grain bin</Typography>;
    }
    return <UpdateStoragePeriodForm {...props} grain_bin={grain_bin} viewer_role={viewer_role} />;
  }
);

const asJSON = (grain_bin: GrainBinFragmentFragment | null) => {
  if (grain_bin === null) {
    return {
      bin_name: '',
      grain_type: null,
      height_ft: null,
      diameter_ft: null,
      location: {
        latlng: null,
        latlng_str: '',
      },
      target_grain_emc: null,
      current_grain_emc: null,
      current_grain_temp: null,
      has_grain_spreader: false,
      floor_type: FloorType.FullFloorAeration,
      recommendation_type: RecommendedOption.COOLING.toString(),
    };
  }

  return {
    bin_name: grain_bin.bin_name,
    grain_type: grain_bin.grain_type || null,
    height_ft: grain_bin.height_ft || null,
    diameter_ft: grain_bin.diameter_ft || null,
    location: {
      latlng: {
        lat: grain_bin.location.latitude,
        lng: grain_bin.location.longitude,
      },
      latlng_str: formatLatLng({
        lat: grain_bin.location.latitude,
        lng: grain_bin.location.longitude,
      }),
    },
    has_grain_spreader: grain_bin.has_grain_spreader || false,
    floor_type: grain_bin.floor_type || FloorType.FullFloorAeration,
    target_grain_emc: grain_bin.target_grain_emc || null,
    recommendation_type: grain_bin.recommendation_type || RecommendedOption.COOLING.toString(),
    current_grain_emc: grain_bin.current_grain_emc || null,
    current_grain_temp: grain_bin.current_grain_temp || null,
  };
};
