import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { BargeFragmentFragment, withGetBarge as HOC } from '../__generated';

export type WithGetBargeHocOwnProps = {
  barge_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetBargeHocChildProps = { loading: boolean; barge: BargeFragmentFragment | null };
export const withGetBargeHoc: AmberHoc<WithGetBargeHocOwnProps, WithGetBargeHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<WithGetBargeHocOwnProps, WithGetBargeHocChildProps, typeof component>,
    WithGetBargeHocChildProps
  >({
    options: ({ barge_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      variables: { barge_id },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          barge: null,
        };
      }
      return {
        loading: loading || false,
        barge: data.barge || null,
      };
    },
  })(component as any);
