import { Button, Grid, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ArrowForward, Cancel, PlayArrow, PlayCircleFilledOutlined } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useGrainBinData } from '../../../../../core/src/contexts';
import {
  ContainerType,
  FanControllerRunWindow,
  FanRunWindowRecommendedOption,
  withInitiateGuidedRecommendationsHoc,
  WithInitiateGuidedRecommendationsHocChildProps,
} from '../../../api';
import { AutomationIcon, CancelIcon } from './../../../../../core/src/media';

import { amber_green, amber_red, black_shade_2, light_gray_7, white } from '../../../style';
import { isNoEnd } from '../../../util';
import { DialogSpinner } from '../../spinner';
import DividerLine from '../../util/DividerLine';
import { formatDate } from './BinFanSettings';
import { GrainWeatherInputsCard } from './GrainWeatherInputsCard';

const useStyles = makeStyles((theme: Theme) => ({
  item: { display: 'inline-block', verticalAlign: 'middle' },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5px 0',
  },
  grainWeatherInputsCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 30,
  },
  icon: { paddingLeft: 10, paddingRight: 10, height: 36, width: 36 },
  h3: { margin: 0 },
  centered_col: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
  },
  button: {
    fontFamily: 'Work Sans,sans-serif',
    height: 38,
    borderRadius: 5,
    boxShadow: '0px 0px 4px #00000033',
    [theme.breakpoints.down('xs')]: {
      marginTop: 2,
    },
    margin: 10,
    color: black_shade_2,
    border: '1px solid #999999',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  extendAutomation: {
    fontFamily: 'Work Sans,sans-serif',
    height: 38,
    boxShadow: '0px 0px 4px #00000033',
    borderRadius: 5,
    textTransform: 'capitalize',
    border: '1px solid #999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightMargin: {
    marginRight: 10,
  },
  manageAutomationbtn: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 2,
    },
    margin: 10,
    marginBottom: 20,
  },
  editAutomationnGrid: {
    [theme.breakpoints.up('md')]: {
      textAlignLast: 'end',
    },
  },
  fanGrid: {
    [theme.breakpoints.up('md')]: {
      textAlignLast: 'center',
    },
  },
  fanAutomationIconContainer: {
    marginRight: 10,
    width: 16,
    height: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertText: { marginBottom: 20, fontWeight: 650, fontSize: 16, marginTop: 10 },
  centerDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonTextContainer: {
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
  },
  buttonIconContainer: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
  },
}));

export const DateSpan: React.FunctionComponent<{
  start: DateTime;
  end: DateTime;
}> = ({ start, end }) => {
  const classes = useStyles();
  const as_of = DateTime.local();
  const is_no_end = isNoEnd(start, end);
  return (
    <div className={classes.row}>
      <div className={classes.item}>
        <div>{formatDate(start, as_of)}</div>
        <h3 className={classes.h3}>{start.toLocaleString(DateTime.TIME_SIMPLE)}</h3>
      </div>
      {!is_no_end && (
        <>
          <ArrowForward className={classes.icon} />
          <div className={classes.item}>
            <div>{formatDate(end, as_of)}</div>
            <h3 className={classes.h3}>{end.toLocaleString(DateTime.TIME_SIMPLE)}</h3>
          </div>
        </>
      )}
    </div>
  );
};

export const AerationWindow = withInitiateGuidedRecommendationsHoc(
  ({
    start,
    end,
    cancel_button_text,
    restart_button_text,
    grain_bin_id,
    show_restart_button,
    onClickCancel,
    onClickRestart,
    onClickEdit,
    initiateGuidedRecommendations,
    show_fan_guidance_ext_prompt,
    setShowFanGuidanceExtPrompt,
    show_edit_sched_btn = false,
    hasUserReadOnlyAccess,
    fan_schedules,
    hasManualMode,
    showFanStopCommandSentMsg = false,
    fanStopCommandSentMsg = '',
    showFanRestartCommandSentMsg = false,
    fanRestartCommandSentMsg = '',
  }: WithInitiateGuidedRecommendationsHocChildProps & {
    cancel_button_text: string;
    restart_button_text?: string;
    onClickCancel: () => void;
    onClickRestart?: () => void;
    onClickEdit?: () => void;
    start: any;
    end: any;
    grain_bin_id: number;
    show_restart_button?: boolean;
    show_fan_guidance_ext_prompt: boolean;
    setShowFanGuidanceExtPrompt: (show_fan_guidance_ext) => void;
    show_edit_sched_btn?: boolean;
    hasUserReadOnlyAccess: boolean;
    fan_schedules: FanControllerRunWindow[] | null;
    hasManualMode: boolean;
    fanStopCommandSentMsg: string;
    showFanStopCommandSentMsg: boolean;
    fanRestartCommandSentMsg: string;
    showFanRestartCommandSentMsg: boolean;
  }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isNotSmallMobile = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const [is_extending, setIsExtending] = useState(false);

    const sorted_fan_schedules = useMemo(() => {
      const fanSchedules = fan_schedules ? fan_schedules : [];
      // sort newest to oldest i.e. highest epoch to lowest
      return [...fanSchedules].sort(
        (a, b) => new Date(a.start_epoch).getTime() - new Date(b.start_epoch).getTime()
      );
    }, [fan_schedules]);

    if (is_extending) {
      return <DialogSpinner title="Extending Automation Schedule..." open={is_extending} />;
    }
    const onClickExtendAutomation = async () => {
      setIsExtending(true);
      await initiateGuidedRecommendations({
        container_id: grain_bin_id,
        container_type: ContainerType.Bin,
        automated_period: 10,
      });
      setShowFanGuidanceExtPrompt(false);
      setIsExtending(false);
    };
    return (
      <>
        <div className={classes.centered_col}>
          {show_fan_guidance_ext_prompt && (
            <Grid container style={{ justifyContent: 'center', marginBottom: 15 }}>
              <Button
                variant="outlined"
                className={classes.extendAutomation}
                onClick={onClickExtendAutomation}
                disabled={hasUserReadOnlyAccess}
                style={{
                  color: white,
                  backgroundColor: amber_green,
                  width: isNotSmallMobile ? 325 : 300,
                }}
              >
                <div className={classes.centerDiv}>
                  <div className={classes.fanAutomationIconContainer}>
                    <AutomationIcon />
                  </div>
                  <div className={classes.buttonTextContainer} style={{ fontSize: 16 }}>
                    EXTEND AUTOMATION
                  </div>
                </div>
              </Button>
            </Grid>
          )}

          {show_restart_button && onClickRestart && (
            <Button
              variant="outlined"
              className={classes.extendAutomation}
              onClick={onClickRestart}
              disabled={hasUserReadOnlyAccess}
              style={{
                color: black_shade_2,
                marginBottom: 15,
                padding: 0,
                width: isNotSmallMobile ? 325 : 300,
              }}
            >
              <div className={classes.centerDiv}>
                <PlayArrow style={{ color: amber_green }} className={classes.rightMargin} />
                <div className={classes.buttonTextContainer} style={{ fontSize: 16 }}>
                  {restart_button_text}
                </div>
              </div>
            </Button>
          )}

          <div className={classes.btnsContainer}>
            {show_edit_sched_btn && onClickEdit && (
              <Button
                color="default"
                variant="outlined"
                className={classes.button}
                onClick={onClickEdit}
                disabled={hasUserReadOnlyAccess}
                style={{
                  width: 145,
                  height: 38,
                  marginLeft: 0,
                  padding: 0,
                  marginRight: isNotSmallMobile ? 35 : 15,
                }}
              >
                <div className={classes.centerDiv} style={{ height: 20 }}>
                  <div className={classes.buttonIconContainer} style={{ marginRight: 10 }}>
                    <EditIcon style={{ width: 20, height: 20 }} />
                  </div>
                  <div className={classes.buttonTextContainer} style={{ height: 20 }}>
                    Edit
                  </div>
                </div>
              </Button>
            )}

            <Button
              variant="outlined"
              className={classes.button}
              onClick={onClickCancel}
              disabled={hasUserReadOnlyAccess}
              style={{
                width: 145,
                height: 38,
                marginLeft: 0,
                marginRight: 0,
                padding: 0,
              }}
            >
              <div className={classes.centerDiv} style={{ height: 20 }}>
                <div className={classes.buttonIconContainer} style={{ marginRight: 10 }}>
                  <CancelIcon />
                </div>
                <div className={classes.buttonTextContainer} style={{ height: 20 }}>
                  {cancel_button_text}
                </div>
              </div>
            </Button>
          </div>

          {showFanStopCommandSentMsg && fanStopCommandSentMsg && (
            <div className={classes.alertText}>{fanStopCommandSentMsg}</div>
          )}

          {showFanRestartCommandSentMsg && fanRestartCommandSentMsg && (
            <div className={classes.alertText}>{fanRestartCommandSentMsg}</div>
          )}
        </div>
        <div style={{ marginBottom: 15, width: '100%' }}>
          <DividerLine />
        </div>
      </>
    );
  }
);
