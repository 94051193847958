import { AmberHoc, AmberHocProps } from '../../../util';
import {
  ConfigureAlertPrefMutationVariables as Variables,
  UserAlertPref,
  withConfigureAlertPref as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type withConfigureAlertPrefHocChildProps = {
  configureAlertPref: (variables: Variables) => Promise<UserAlertPref>;
};

export const withConfigureAlertPrefHoc: AmberHoc<{}, withConfigureAlertPrefHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<{}, withConfigureAlertPrefHocChildProps, typeof component>,
    withConfigureAlertPrefHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        configureAlertPref: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }

          const result = await mutate({ variables });

          if (!result) {
            throw new Error('Unexpected server response');
          }

          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.configureAlertPref;
        },
      };
    },
  })(component as any);
