import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy } from '../../../util';
import { GetWeatherHistoryQuery, withGetWeatherHistory as HOC } from '../__generated';
export type WithGetWeatherHistoryHocOwnProps = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  prev_week_no: number;
  loading?: boolean;
  pollInterval?: number;
  onError?: (error: ApolloError) => void;
};
export type WithGetWeatherHistoryHocChildProps = {
  weather_history_loading: boolean;
  grain_container: GetWeatherHistoryQuery['grain_container'] | null;
  refetch_weather_history: (variables) => Promise<any>;
};
export const withGetWeatherHistoryHoc: AmberHoc<
  WithGetWeatherHistoryHocOwnProps,
  WithGetWeatherHistoryHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetWeatherHistoryHocOwnProps,
      WithGetWeatherHistoryHocChildProps,
      typeof component
    >,
    WithGetWeatherHistoryHocChildProps
  >({
    options: ({ container_id, container_type, prev_week_no, onError }) => ({
      onError,
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      variables: {
        prev_week_no,
        container_id,
        container_type,
      },
      notifyOnNetworkStatusChange: true,
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          weather_history_loading: true,
          grain_container: null,
          refetch_weather_history: (variables) => Promise.resolve(),
        };
      }
      return {
        weather_history_loading: loading || false,
        grain_container: data.grain_container || null,
        refetch_weather_history: (variables) => data.refetch(variables),
      };
    },
  })(component as any);
