import { Accordion, AccordionDetails, AccordionSummary, Grid, Tooltip } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { makeStyles, withStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React, { FunctionComponent } from 'react';
import { GrainType, UserRole } from '../../api';
import { amber_amber, amber_medium_grey } from '../../style';
import { formatGrainType } from '../../util';
import { getUserPermissionTooltipText } from './aeration';
import { CreateOrUpdateStoragePeriodForm } from './CreateOrUpdateStoragePeriodForm';
import { TStoragePeriod } from './ManageStoragePeriods';

/* =============
      Styles
   ============= */

const useStyles = makeStyles({
  header: {
    paddingLeft: '18px',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '25px',
  },
  headerText: {
    fontWeight: 600,
    padding: '8px',
  },
  row: {
    backgroundColor: 'transparent',
  },
  rowText: {
    padding: '8px',
  },
});

const NoAnimationAccordionSummary = withStyles({
  root: {
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      borderTop: '1px solid grey',
      borderLeft: '1px solid grey',
      borderRight: '1px solid grey',
    },
  },
  expanded: {
    backgroundColor: amber_medium_grey,
    color: 'white',
  },
})(AccordionSummary);

const StoragePeriodAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '1px solid grey',
    borderLeft: '1px solid grey',
    borderRight: '1px solid grey',
  },
}))(AccordionDetails);

/* ===============
     Components
   =============== */

const StoragePeriodsTableHeader = () => {
  const classes = useStyles();
  return (
    <Grid container wrap="nowrap" className={classes.header}>
      <Grid item xs={3} className={classes.headerText}>
        Name
      </Grid>
      <Grid item xs={3} className={classes.headerText}>
        Grain Type
      </Grid>
      <Grid item xs={3} className={classes.headerText}>
        Start Date
      </Grid>
      <Grid item xs={3} className={classes.headerText}>
        End Date
      </Grid>
      <Grid item xs={1} className={classes.headerText} />
    </Grid>
  );
};

export const StoragePeriodsTable: FunctionComponent<{
  grain_bin_id: number;
  setUpdating: (shouldUpdate: boolean) => void;
  storagePeriods: TStoragePeriod[];
  refetchStoragePeriods: () => void;
  expanded: number | null;
  handleExpandChange: any;
  hasUserAccessRestricted: boolean;
  viewer_role: UserRole;
}> = ({
  grain_bin_id,
  setUpdating,
  storagePeriods = [],
  refetchStoragePeriods,
  expanded,
  handleExpandChange,
  hasUserAccessRestricted,
  viewer_role,
}) => {
  const classes = useStyles();
  const disabledTooltipText = hasUserAccessRestricted
    ? getUserPermissionTooltipText(viewer_role)
    : '';
  console.log('storagePeriods: ', storagePeriods);
  return (
    <div style={{ width: '100%' }}>
      <StoragePeriodsTableHeader />
      {storagePeriods &&
        storagePeriods.map((storagePeriod, index) => {
          console.log(storagePeriod);
          const startDate =
            (storagePeriod.start_date &&
              DateTime.fromFormat(storagePeriod.start_date, 'M/d/yyyy').toFormat('yyyy-MM-dd')) ||
            null;
          const endDate =
            (storagePeriod.end_date &&
              DateTime.fromFormat(storagePeriod.end_date, 'M/d/yyyy').toFormat('yyyy-MM-dd')) ||
            null;
          console.log(startDate, endDate);
          return (
            <Accordion
              className={classes.row}
              expanded={hasUserAccessRestricted ? false : expanded === index}
              onChange={handleExpandChange(index)}
              key={index}
            >
              <NoAnimationAccordionSummary
                style={
                  storagePeriod.is_ongoing_storage_period
                    ? { backgroundColor: amber_amber, color: 'black' }
                    : {}
                }
                // expandIcon={<BorderColorIcon />}
              >
                <Grid
                  style={
                    storagePeriod.is_ongoing_storage_period
                      ? { backgroundColor: amber_amber, alignItems: 'center' }
                      : { alignItems: 'center' }
                  }
                  container
                  wrap="nowrap"
                >
                  <Grid item xs={3} className={classes.rowText}>
                    {storagePeriod.storage_period_name}
                  </Grid>
                  <Grid item xs={3} className={classes.rowText}>
                    {formatGrainType(storagePeriod.grain_type as GrainType)}
                  </Grid>
                  <Grid item xs={3} className={classes.rowText}>
                    {DateTime.fromFormat(storagePeriod.start_date, 'M/d/yyyy').toFormat(
                      'MM-dd-yyyy'
                    )}
                  </Grid>
                  <Grid item xs={3} className={classes.rowText}>
                    {!storagePeriod.end_date
                      ? 'Ongoing'
                      : DateTime.fromFormat(storagePeriod.end_date, 'M/d/yyyy').toFormat(
                          'MM-dd-yyyy'
                        )}
                  </Grid>
                  <Grid item xs={1} className={classes.rowText}>
                    <Tooltip
                      enterTouchDelay={0}
                      disableFocusListener
                      placement="right"
                      title={
                        disabledTooltipText ? (
                          <span style={{ fontSize: 13 }}>{disabledTooltipText}</span>
                        ) : (
                          ''
                        )
                      }
                      arrow
                    >
                      <div>
                        <BorderColorIcon
                          style={{ color: hasUserAccessRestricted ? 'lightgray' : undefined }}
                        />
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
              </NoAnimationAccordionSummary>
              <StoragePeriodAccordionDetails>
                {expanded === index && (
                  <CreateOrUpdateStoragePeriodForm
                    grain_bin_storage_cycle_id={storagePeriod.grain_bin_storage_cycle_id}
                    grain_bin_id={grain_bin_id}
                    setUpdating={setUpdating}
                    refetchStoragePeriods={refetchStoragePeriods}
                    storagePeriodName={storagePeriod.storage_period_name}
                    storagePeriodGrainType={storagePeriod.grain_type}
                    storagePeriodStartDate={startDate}
                    storagePeriodEndDate={endDate}
                    is_ongoing_storage_period={storagePeriod.is_ongoing_storage_period}
                    handleCloseForm={handleExpandChange(null)}
                  />
                )}
              </StoragePeriodAccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
};
