import { DateTime } from 'luxon';
import { weather_icon_image_url_mapping } from '../..';
import { getTemperatureUnitLabel } from '../../../../../core/src/util';
import { ForecastDayV2FragmentFragment } from '../../../api';

const WEATHER_CHART_COLORS = {
  PLOT_NIGHT_BAND_COLOR: 'rgba(207, 208, 210, 0.5)',
  GRID_LINE_COLOR: '#c6c6c6',
  CROSS_HAIR_COLOR: 'blue',
  TEMP_SERIES_COLOR: '#d5202a',
  HUMIDITY_SERIES_COLOR: '#87c404',
  EMC_SERIES_COLOR: '#22730b',
  LIQUID_PRECIP_SERIES_COLOR: '#15aadc',
  AS_OF_HOUR_VERTICAL_LINE: '#1e2023',
  LOW_TEMP_COLOR: '#0053ae',
  HIGH_TEMP_COLOR: '#d5202a',
};

const WEATHER_STRING = {
  snow: 'Snow',
  sleet: 'Sleet',
  rain: 'Rain',
  cloudy: 'Cloudy',
  windy: 'Wind',
  partly: 'Partly Cloudy',
  clear: 'Clear',
};

const SERIES = {
  TEMPRATURE: 'Temperature',
  HUMIDITY: 'Humidity',
  EMC: 'EMC',
  PRECIPITAION: 'Hourly Liquid Precip. (in)',
};

const RAINE_INTENSITY_LEVELS = {
  HEAVY: 'heavy',
  MODERATE: 'moderate',
  LIGHT: 'light',
  DRIZZLE: 'drizzle',
  NO_PRECIPITATION: 'no precip.',
};

type NightPlotBandType = {
  from: number;
  to: number;
  color: string;
};

const drawTopBlocksToShowDaysLabels = function(chart, days: ForecastDayV2FragmentFragment[]) {
  const xAxis = chart.xAxis[0];
  const lastTickOnXaxisInPx = Math.round(xAxis.toPixels(xAxis.max));

  chart.renderer
    .path(['M', lastTickOnXaxisInPx, chart.chartHeight - 4, 'V', 50])
    .attr({
      stroke: WEATHER_CHART_COLORS.GRID_LINE_COLOR,
      'stroke-width': 1,
      opacity: 1,
      'stroke-dasharray': 'none',
    })
    .add();

  for (const idx in days) {
    console.log('idx', idx, typeof idx);
    const day = days[idx];
    // start of the day
    const dt = DateTime.fromMillis(day.epoch_time).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    console.log('start time of days:', dt);

    // Get the X position
    const x = Math.round(xAxis.toPixels(dt.toMillis()));
    // Create and Design the Day Box
    const { icon } = day;
    const dayIcon =
      weather_icon_image_url_mapping[icon && icon.toLowerCase()] ||
      weather_icon_image_url_mapping.clear;

    const block = `
    <div style="width:${
      Number(idx) === 0 ? '129px' : '134px'
    };height:50px;display:flex;justify-content:center;alignItems:center;margin-top:2px;border:1px solid ${
      WEATHER_CHART_COLORS.GRID_LINE_COLOR
    };
    ${Number(idx) === 0 ? 'border-top-left-radius: 8px 8px;' : ''}
    ${Number(idx) === days.length - 1 ? 'border-top-right-radius: 8px 8px;' : 'border-right:none;'}
    ">
    <div style="width:33%;text-align:center;font-size:12px;font-family:Source Sans Pro,sans-serif">
      ${dt.toFormat('EEE')}<br/>
      ${dt.toFormat('L/dd')}
    </div>
    <div style="display:flex;justify-content:center;align-items:center;width:33%;text-align:center">
      <img src='${dayIcon}' width='70%' height='70%' style='object-fit:contain'/>
    </div>
    <div style="width:33%;text-align:center;font-size:12px;white-space:normal;font-family:Source Sans Pro,sans-serif;word-break:word-break;">
      ${WEATHER_STRING[icon] || 'Clear'}
    </div>
    </div>  
      `;

    chart.renderer.text(block, x - 0.6, 10, true).add();
  }
};

const getNightPlotBandsPostions = (hours, days) => {
  let dayIndex = 0;
  let firstDayNightPlotBand: NightPlotBandType[] = [];
  let lastDayNightPlotBand: NightPlotBandType[] = [];
  const firstDay = days[0];
  const lastDay = days[days.length - 1];
  if (firstDay && firstDay.daytime_range && firstDay.daytime_range.length === 2) {
    firstDayNightPlotBand = [
      {
        color: WEATHER_CHART_COLORS.PLOT_NIGHT_BAND_COLOR,
        from: DateTime.fromMillis(new Date(firstDay.epoch_time).getTime())
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .toMillis(),
        to: new Date(firstDay.daytime_range[0]).getTime(),
      },
    ];
  }

  if (
    lastDay &&
    lastDay.nighttime_range &&
    lastDay.daytime_range &&
    lastDay.daytime_range.length === 2
  ) {
    lastDayNightPlotBand = [
      {
        color: WEATHER_CHART_COLORS.PLOT_NIGHT_BAND_COLOR,
        from: lastDay.nighttime_range[0]
          ? new Date(lastDay.nighttime_range[0]).getTime()
          : new Date(lastDay.daytime_range[1]).getTime(),
        to: DateTime.fromMillis(new Date(lastDay.epoch_time).getTime())
          .set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          })
          .toMillis(),
      },
    ];
  }

  const nightPlotBandsPositionsForOtherDays = hours.reduce((acc, dt) => {
    const day = days[dayIndex];
    const dayEpochTime = day ? DateTime.fromMillis(new Date(day.epoch_time).getTime()) : null;
    const nightStart =
      day && day.nighttime_range && day.nighttime_range[0]
        ? DateTime.fromMillis(new Date(day.nighttime_range[0]).getTime())
        : null;
    const nightEnd =
      day && day.nighttime_range && day.nighttime_range[1]
        ? DateTime.fromMillis(new Date(day.nighttime_range[1]).getTime())
        : null;

    if (dayEpochTime && nightStart && nightEnd) {
      if (
        dt.day === dayEpochTime.day &&
        dt.hour === nightStart.hour &&
        dt.minute === nightStart.minute
      ) {
        acc.push({
          color: WEATHER_CHART_COLORS.PLOT_NIGHT_BAND_COLOR,
          from: nightStart.toMillis(), // Start of the night plot band
          to: nightEnd.toMillis(), // End of the night plot band
        });
        dayIndex += 1;
      }
    }
    return acc;
  }, []);

  return [
    ...(firstDayNightPlotBand && firstDayNightPlotBand),
    ...nightPlotBandsPositionsForOtherDays,
    ...(lastDayNightPlotBand && lastDayNightPlotBand),
  ];
};

const getTooltipInfo = (category): string => {
  let categoryDataPoint = '';
  const tempUnit = getTemperatureUnitLabel();
  const getRainIntensity = (precipInches) => {
    let rainIntensity = RAINE_INTENSITY_LEVELS.NO_PRECIPITATION;
    if (precipInches > 0.3) {
      rainIntensity = RAINE_INTENSITY_LEVELS.HEAVY;
    } else if (precipInches > 0.1) {
      rainIntensity = RAINE_INTENSITY_LEVELS.MODERATE;
    } else if (precipInches > 0.02) {
      rainIntensity = RAINE_INTENSITY_LEVELS.LIGHT;
    } else if (precipInches > 0) {
      rainIntensity = RAINE_INTENSITY_LEVELS.DRIZZLE;
    }
    return rainIntensity;
  };

  switch (category.series.name) {
    case SERIES.TEMPRATURE:
      categoryDataPoint =
        `<span style="color:${category.color};">\u25CF</span> ` +
        ` <b>${Number(category.y).toFixed(1)} ${tempUnit}</b><br/>`;
      break;
    case SERIES.HUMIDITY:
      categoryDataPoint =
        `<span style="color:${category.color}">\u25CF</span> ` +
        ` <b>${Number(category.y * 100).toFixed(1)}% RH</b><br/>`;
      break;
    case SERIES.EMC:
      categoryDataPoint =
        `<span style="color:${category.color}">\u25CF</span> ` +
        ` <b>${Number(category.y * 100).toFixed(1)}% MC</b><br/>`;
      break;
    case SERIES.PRECIPITAION:
      const precipVal = Number(Number(category.y).toFixed(2));
      const rainIntensity = getRainIntensity(precipVal);
      const rainIntensityString = rainIntensity ? `(${rainIntensity})` : '';
      categoryDataPoint = `<span style="margin-left:-1px"><svg viewBox="0 0 9 9" fill=${
        category.color
      } class="icon icon-rain" width="9" height="9"><title>Precip</title><path d="M6.604 7.584A3.016 3.016 0 0 1 2.342 3.334C4.546 1.112 8.334 1.592 8.366 1.554S8.662 5.526 6.604 7.584Z"/></svg>
       </span>
         <b>${precipVal > 0 ? precipVal : '0'} in</b><br/>
         <span style="margin-left:${
           rainIntensity === RAINE_INTENSITY_LEVELS.NO_PRECIPITATION ? '0px' : '10px'
         }"><b>${rainIntensityString && rainIntensityString}</b></span>`;
      break;
    default:
      categoryDataPoint = '';
  }
  return categoryDataPoint;
};

export {
  drawTopBlocksToShowDaysLabels,
  getNightPlotBandsPostions,
  getTooltipInfo,
  WEATHER_CHART_COLORS,
  SERIES,
};
