import { makeStyles, Theme } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useState } from 'react';
import { amber_grey } from '../../../../../core/src/style';
import { Statistic } from '../../../../../core/src/util';

const useStyles = makeStyles((theme: Theme) => ({
  updateToggleBtn: {
    width: '100%',
    fontSize: 10,
    lineHeight: '18px',
    textTransform: 'none',
    color: amber_grey,
    padding: 5,
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const AirspaceHeaderCellRenderer = (props) => {
  const classes = useStyles();
  const initialStatisticVal = props.api['airspace_statistic'] || Statistic.co2_ppm;
  const [statisticVal, setStatisticVal] = useState<Statistic>(initialStatisticVal);
  const handleChange = (event: React.MouseEvent<HTMLElement>, value: Statistic) => {
    if (value === null) return;
    setStatisticVal(value);
    props.api['airspace_statistic'] = value;
    // TODO: only refresh cells for airspace column instead of all cells
    props.api.refreshCells({ force: true });
  };
  return (
    <div className={classes.container}>
      <ToggleButtonGroup
        style={{ width: '100%' }}
        value={statisticVal}
        exclusive
        onChange={handleChange}
        aria-label="Toggle airspace statistic "
      >
        <ToggleButton
          className={classes.updateToggleBtn}
          style={{
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
            border: '1px solid #707070',
          }}
          value={Statistic.co2_ppm}
        >
          CO2
        </ToggleButton>
        <ToggleButton
          style={{
            border: '1px solid #707070',
          }}
          className={classes.updateToggleBtn}
          value={Statistic.temp_f}
        >
          Temp.
        </ToggleButton>
        <ToggleButton
          style={{
            border: '1px solid #707070',
          }}
          className={classes.updateToggleBtn}
          value={Statistic.humidity_rh}
        >
          Hum.
        </ToggleButton>
        <ToggleButton
          style={{
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
            border: '1px solid #707070',
          }}
          className={classes.updateToggleBtn}
          value={Statistic.emc}
        >
          EMC
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export { AirspaceHeaderCellRenderer };
