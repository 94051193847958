import * as React from 'react';

export const PeakedGrainSurfaceIcon = (props) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 35.667"
    width={40}
    height={35.667}
    {...props}
  >
    <defs>
      <style>
        {'.peaked-cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;}'}
      </style>
    </defs>
    <g id="Layer_1-2">
      <path className="peaked-cls-1" d="m33.184 6.255 1.68 0.747" />
      <path
        className="peaked-cls-1"
        d="M39.72 21.38c-2.023 2.023 -4.063 4.028 -6.065 6.072 -2.601 2.659 -5.171 5.348 -7.753 8.025"
      />
      <path
        className="peaked-cls-1"
        d="M39.72 12.231c-7.437 7.437 -14.876 14.875 -22.312 22.316 -0.295 0.295 -0.563 0.62 -0.843 0.932"
      />
      <path className="peaked-cls-1" d="m3.309 7.936 -2.053 0.933" />
      <path
        className="peaked-cls-1"
        d="M18.06 1.027a7.083 7.083 0 0 0 -2.448 1.661C10.64 7.735 5.611 12.728 0.6 17.736c-0.108 0.108 -0.247 0.188 -0.372 0.281"
      />
      <path className="peaked-cls-1" d="m26.836 3.268 2.147 1.027" />
      <path
        className="peaked-cls-1"
        d="M9.657 4.948c-1.213 0.56 -2.44 1.095 -3.633 1.693 -0.448 0.225 -0.853 0.572 -1.213 0.927C3.287 9.061 1.785 10.58 0.137 12.231v2.147c0 2.988 -0.001 5.975 0.004 8.963 0 0.312 0.057 0.621 0.089 0.933"
      />
      <path className="peaked-cls-1" d="m39.72 30.716 -4.668 4.761" />
      <path
        className="peaked-cls-1"
        d="M22.821 1.68 0.135 24.575c0 1.721 -0.011 3.651 0.004 5.581 0.008 1.088 0.057 2.179 0.089 3.268"
      />
      <path className="peaked-cls-1" d="m24.781 2.335 1.68 0.747" />
      <path
        className="peaked-cls-1"
        d="M11.711 4.015c-0.499 0.373 -1.045 0.696 -1.487 1.128 -3.343 3.284 -6.667 6.589 -9.996 9.888"
      />
      <path
        className="peaked-cls-1"
        d="m32.811 6.068 -3.601 -1.673L0.181 33.7v1.824h22.452L39.72 18.205"
      />
      <path className="peaked-cls-1" d="m20.113 0.093 -1.68 0.747" />
      <path className="peaked-cls-1" d="m35.519 7.376 1.68 0.84" />
      <path
        className="peaked-cls-1"
        d="m39.72 27.355 -7.988 8.216c-0.383 0 -1.007 0.005 -1.628 0 -2.396 -0.028 -4.793 -0.061 -7.189 -0.092"
      />
      <path className="peaked-cls-1" d="M15.632 2.147 12.084 3.827" />
      <path className="peaked-cls-1" d="m39.72 33.704 -1.68 1.773" />
      <path className="peaked-cls-1" d="M39.907 9.616v25.861h-7.843" />
      <path
        className="peaked-cls-1"
        d="M39.72 24.368c-1.525 1.525 -3.068 3.032 -4.571 4.579 -2.103 2.163 -4.175 4.352 -6.259 6.532"
      />
      <path className="peaked-cls-1" d="m4.709 7.376 -1.027 0.373" />
      <path
        className="peaked-cls-1"
        d="M31.224 5.601c-4.761 4.761 -9.512 9.536 -14.288 14.28 -4.933 4.901 -9.895 9.775 -14.839 14.664 -0.297 0.293 -0.563 0.62 -0.843 0.932"
      />
      <path className="peaked-cls-1" d="M39.533 9.243 13.952 35.011" />
      <path
        className="peaked-cls-1"
        d="M32.997 6.629c-1.743 1.773 -3.475 3.559 -5.231 5.32 -4.972 4.985 -9.967 9.951 -14.932 14.944 -2.823 2.84 -5.605 5.721 -8.407 8.585"
      />
      <path className="peaked-cls-1" d="M0.881 9.056 0.093 9.547l0.135 2.403" />
      <path
        className="peaked-cls-1"
        d="M26.836 3.828c-2.583 2.552 -5.177 5.092 -7.745 7.66 -2.412 2.411 -4.788 4.86 -7.195 7.276 -3.695 3.711 -7.405 7.408 -11.104 11.116 -0.061 0.061 -0.065 0.183 -0.097 0.276"
      />
      <path className="peaked-cls-1" d="M24.408 2.147 20.832 0.463 0.695 20.82" />
      <path
        className="peaked-cls-1"
        d="M25.063 2.615C17.095 10.581 9.129 18.549 1.16 26.513c-0.295 0.295 -0.62 0.563 -0.932 0.843"
      />
      <path
        className="peaked-cls-1"
        d="M35.239 7.189c0.396 0.34 -0.08 0.431 -0.196 0.551 -2.321 2.347 -4.659 4.677 -6.993 7.011 -6.597 6.597 -13.196 13.193 -19.791 19.795 -0.295 0.295 -0.563 0.62 -0.843 0.932"
      />
      <path
        className="peaked-cls-1"
        d="m39.16 9.056 -1.655 -0.773 -8.429 8.429c-5.944 5.944 -11.889 11.887 -17.831 17.835 -0.295 0.295 -0.563 0.62 -0.843 0.932"
      />
      <path
        className="peaked-cls-1"
        d="M39.72 15.219c-3.081 3.081 -6.18 6.143 -9.237 9.247 -3.599 3.652 -7.163 7.34 -10.743 11.013"
      />
    </g>
  </svg>
);

export const InvertedGrainSurfaceIcon = (props) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 32.435"
    width={40}
    height={32.435}
    {...props}
  >
    <defs>
      <style>
        {'.inverted-cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;}'}
      </style>
    </defs>
    <g id="Layer_1-2">
      <path className="inverted-cls-1" d="M39.716 25.464c-2.23 2.262 -4.461 4.522 -6.691 6.784" />
      <path className="inverted-cls-1" d="M9.141 4.368c0.526 0.217 1.054 0.433 1.58 0.651" />
      <path className="inverted-cls-1" d="M0.695 0.258C0.55 0.511 0.431 0.721 0.312 0.929" />
      <path
        className="inverted-cls-1"
        d="M8.676 32.248c0.247 -0.279 0.481 -0.571 0.745 -0.835 9.788 -9.791 19.577 -19.581 29.367 -29.367 0.31 -0.31 0.643 -0.598 1.067 -0.991V0.093"
      />
      <path
        className="inverted-cls-1"
        d="M19.178 9.014c-0.533 -0.266 -1.067 -0.533 -1.611 -0.805 -2.663 2.663 -5.347 5.371 -8.057 8.052 -2.959 2.926 -5.944 5.827 -8.92 8.738 -0.11 0.108 -0.245 0.188 -0.37 0.281"
      />
      <path
        className="inverted-cls-1"
        d="M14.996 7.62C10.381 12.235 5.766 16.852 1.147 21.466c-0.294 0.294 -0.617 0.559 -0.927 0.838"
      />
      <path
        className="inverted-cls-1"
        d="M39.716 13.382c-5.979 5.979 -11.959 11.957 -17.934 17.938 -0.294 0.294 -0.559 0.617 -0.838 0.927"
      />
      <path className="inverted-cls-1" d="M15.181 7.063c0.619 0.31 1.24 0.619 1.859 0.929" />
      <path
        className="inverted-cls-1"
        d="M8.676 4.089c-1.929 -0.913 -3.857 -1.825 -5.836 -2.76C2.03 2.214 1.171 3.152 0.312 4.089"
      />
      <path className="inverted-cls-1" d="M39.716 31.597c-0.186 0.217 -0.372 0.433 -0.558 0.651" />
      <path
        className="inverted-cls-1"
        d="M10.999 5.483C7.715 8.767 4.433 12.051 1.147 15.332 0.853 15.626 0.53 15.891 0.219 16.17"
      />
      <path className="inverted-cls-1" d="M11.185 5.297c0.526 0.217 1.054 0.433 1.58 0.651" />
      <path
        className="inverted-cls-1"
        d="M39.716 16.356c-4.987 4.987 -9.975 9.974 -14.96 14.964 -0.294 0.294 -0.559 0.617 -0.838 0.927"
      />
      <path className="inverted-cls-1" d="M4.866 2.416c-1.548 1.548 -3.097 3.098 -4.647 4.647" />
      <path
        className="inverted-cls-1"
        d="M39.809 19.516c0.032 1.89 0.069 3.779 0.091 5.669 0.013 1.085 0.002 2.171 0.002 3.152 -1.29 1.414 -2.487 2.727 -3.652 4.004 -0.777 0 -1.398 0.006 -2.017 0 -2.385 -0.028 -4.771 -0.061 -7.156 -0.091"
      />
      <path className="inverted-cls-1" d="M29.772 4.832c-0.961 0.433 -1.92 0.868 -2.881 1.301" />
      <path
        className="inverted-cls-1"
        d="M13.044 6.412C9.08 10.377 5.115 14.343 1.147 18.306c-0.294 0.294 -0.617 0.559 -0.927 0.838"
      />
      <path className="inverted-cls-1" d="M8.955 4.554C6.042 7.435 3.132 10.316 0.219 13.196" />
      <path className="inverted-cls-1" d="M39.716 22.49c-3.221 3.253 -6.444 6.505 -9.665 9.758" />
      <path className="inverted-cls-1" d="M39.251 0.279c-0.589 0.279 -1.177 0.558 -1.766 0.836" />
      <path className="inverted-cls-1" d="M0.87 0.372c0.589 0.279 1.177 0.558 1.766 0.836" />
      <path
        className="inverted-cls-1"
        d="M39.809 10.408c0.032 1.89 0.069 3.779 0.091 5.669 0.013 1.085 0.002 2.171 0.002 3.439l-12.825 12.825c-0.712 0 -1.333 0.006 -1.952 0 -2.385 -0.028 -4.771 -0.061 -7.156 -0.091"
      />
      <path className="inverted-cls-1" d="M26.519 6.319c-1.362 0.619 -2.727 1.24 -4.089 1.859" />
      <path className="inverted-cls-1" d="M13.137 6.134c0.558 0.247 1.115 0.496 1.673 0.743" />
      <path
        className="inverted-cls-1"
        d="M39.809 1.301c0.032 1.89 0.069 3.779 0.091 5.669 0.013 1.085 0.002 2.171 0.002 3.439L18.016 32.294H0.126v-0.976c0 -10.161 0 -20.321 0.009 -30.482C0.136 0.561 -0.091 0.108 0.498 0.093"
      />
      <path className="inverted-cls-1" d="M22.058 8.364c-0.743 0.31 -1.487 0.619 -2.23 0.929" />
      <path className="inverted-cls-1" d="M6.725 3.439 3.658 6.505" />
      <path className="inverted-cls-1" d="M3.472 6.877c-1.084 1.054 -2.169 2.106 -3.253 3.16" />
      <path
        className="inverted-cls-1"
        d="M19.456 9.107c-6.412 6.381 -12.825 12.763 -19.237 19.144"
      />
      <path
        className="inverted-cls-1"
        d="M37.114 1.301c-1.299 0.559 -2.279 1.515 -3.262 2.5 -9.154 9.184 -18.33 18.347 -27.497 27.519 -0.294 0.294 -0.559 0.617 -0.838 0.927"
      />
      <path className="inverted-cls-1" d="M39.809 28.623v3.624h-3.624" />
      <path
        className="inverted-cls-1"
        d="M39.716 4.275c-9.014 9.014 -18.029 18.029 -27.041 27.045 -0.294 0.294 -0.559 0.617 -0.838 0.927"
      />
      <path
        className="inverted-cls-1"
        d="M23.917 7.713c-0.125 0.154 -0.234 0.323 -0.374 0.463C15.925 15.799 8.304 23.419 0.682 31.038c-0.139 0.139 -0.309 0.249 -0.463 0.374"
      />
      <path
        className="inverted-cls-1"
        d="M34.697 2.416c-0.682 0.31 -1.366 0.613 -2.045 0.931 -1.552 0.729 -2.899 1.686 -4.115 2.948 -3.769 3.911 -7.645 7.719 -11.501 11.546 -4.541 4.505 -9.106 8.985 -13.655 13.481 -0.296 0.292 -0.559 0.617 -0.838 0.927"
      />
      <path
        className="inverted-cls-1"
        d="M39.716 7.249c-8.024 8.024 -16.048 16.046 -24.068 24.071 -0.294 0.294 -0.559 0.617 -0.838 0.927"
      />
    </g>
  </svg>
);

export const HalfInvertedGrainSurfaceIcon = (props) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 31.045"
    width={40}
    height={31.045}
    {...props}
  >
    <defs>
      <style>
        {'.half-inverted-cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;}'}
      </style>
    </defs>
    <g id="Layer_1-2">
      <path className="half-inverted-cls-1" d="m0.745 2.845 -0.465 0.465" />
      <path
        className="half-inverted-cls-1"
        d="M0.559 2.566c-0.308 0.156 -0.446 0.268 -0.414 0.747 0.091 1.386 -0.045 2.784 -0.046 4.179 -0.009 7.805 -0.004 15.61 -0.004 23.461h39.675v-2.927"
      />
      <path
        className="half-inverted-cls-1"
        d="M27.133 3.495c-1.332 0.619 -2.663 1.239 -3.995 1.858"
      />
      <path className="half-inverted-cls-1" d="M9.572 1.823c1.301 0.619 2.602 1.239 3.902 1.858" />
      <path
        className="half-inverted-cls-1"
        d="M7.156 0.708c-0.125 -0.061 -0.247 -0.125 -0.372 -0.186"
      />
      <path
        className="half-inverted-cls-1"
        d="M39.305 16.132c-2.198 2.198 -4.386 4.41 -6.601 6.593 -2.557 2.522 -5.14 5.019 -7.706 7.532 -0.203 0.199 -0.374 0.431 -0.559 0.649"
      />
      <path
        className="half-inverted-cls-1"
        d="M6.32 0.243c-0.247 -0.032 -0.546 -0.167 -0.736 -0.076 -1.531 0.745 -3.044 1.533 -4.56 2.306"
      />
      <path
        className="half-inverted-cls-1"
        d="M14.497 4.332c-4.46 4.46 -8.92 8.922 -13.382 13.378 -0.294 0.294 -0.617 0.559 -0.927 0.838"
      />
      <path
        className="half-inverted-cls-1"
        d="M12.452 3.403C10.068 5.787 7.688 8.178 5.296 10.555c-1.693 1.682 -3.404 3.347 -5.108 5.019"
      />
      <path
        className="half-inverted-cls-1"
        d="M18.585 6.19c0.433 0.186 0.86 0.39 1.305 0.546 0.128 0.045 0.305 -0.05 0.461 -0.082"
      />
      <path
        className="half-inverted-cls-1"
        d="M39.77 9.814c0.043 0.929 0.085 1.858 0.123 2.672 -0.465 0.424 -0.816 0.723 -1.143 1.046 -2.512 2.505 -5.008 5.027 -7.532 7.521 -3.241 3.202 -6.5 6.383 -9.752 9.574"
      />
      <path
        className="half-inverted-cls-1"
        d="M18.399 6.004c-0.595 -0.268 -1.187 -0.535 -2.065 -0.929l-16.147 16.446"
      />
      <path
        className="half-inverted-cls-1"
        d="M8.271 1.451c-2.695 2.665 -5.389 5.328 -8.084 7.991"
      />
      <path
        className="half-inverted-cls-1"
        d="M6.041 0.708C4.399 2.351 2.76 3.993 1.115 5.631c-0.295 0.294 -0.617 0.559 -0.927 0.838"
      />
      <path
        className="half-inverted-cls-1"
        d="M32.801 1.173c-2.384 2.354 -4.783 4.694 -7.151 7.065 -3.482 3.486 -6.941 6.997 -10.41 10.496 -3.902 3.934 -7.805 7.866 -11.705 11.802 -0.108 0.11 -0.188 0.245 -0.281 0.37"
      />
      <path
        className="half-inverted-cls-1"
        d="M39.584 24.959c-1.951 1.983 -3.902 3.964 -5.854 5.947"
      />
      <path
        className="half-inverted-cls-1"
        d="M10.223 2.473c-3.345 3.315 -6.69 6.629 -10.035 9.942"
      />
      <path className="half-inverted-cls-1" d="M35.588 0.708c0.619 0.31 1.239 0.619 1.858 0.929" />
      <path
        className="half-inverted-cls-1"
        d="M39.77 12.787v5.954c-0.245 0.212 -0.509 0.413 -0.741 0.645 -3.75 3.744 -7.498 7.493 -11.243 11.243 -0.078 0.078 -0.126 0.184 -0.188 0.277"
      />
      <path
        className="half-inverted-cls-1"
        d="M22.766 5.54c-0.403 0.279 -0.814 0.544 -1.204 0.842 -0.208 0.158 -0.375 0.366 -0.561 0.552C14.248 13.686 7.496 20.438 0.743 27.187c-0.171 0.171 -0.37 0.312 -0.556 0.466"
      />
      <path className="half-inverted-cls-1" d="M7.528 0.894c0.557 0.247 1.115 0.496 1.672 0.743" />
      <path
        className="half-inverted-cls-1"
        d="M37.818 1.823c0.708 0.388 1.414 0.775 1.998 1.095v6.669c-0.325 0.29 -0.617 0.524 -0.881 0.786 -6.506 6.439 -13.01 12.882 -19.512 19.326 -0.388 0.385 -0.745 0.803 -1.117 1.206"
      />
      <path
        className="half-inverted-cls-1"
        d="M21.558 6.097c-0.279 0.125 -0.557 0.247 -0.836 0.372"
      />
      <path
        className="half-inverted-cls-1"
        d="M18.399 6.376c-1.89 1.951 -3.757 3.923 -5.672 5.85C8.652 16.329 4.555 20.408 0.465 24.492c-0.078 0.078 -0.184 0.126 -0.277 0.188"
      />
      <path className="half-inverted-cls-1" d="M35.217 0.522q-1.095 -0.613 -2.23 0.279" />
      <path
        className="half-inverted-cls-1"
        d="M39.77 18.919c0.043 0.929 0.085 1.858 0.138 3.003 -3.085 2.968 -6.21 5.974 -9.336 8.983"
      />
      <path className="half-inverted-cls-1" d="M13.846 3.867c0.836 0.403 1.672 0.805 2.509 1.208" />
      <path
        className="half-inverted-cls-1"
        d="M37.911 2.102c-9.292 9.292 -18.583 18.583 -27.873 27.876 -0.294 0.294 -0.559 0.617 -0.838 0.927"
      />
      <path
        className="half-inverted-cls-1"
        d="M36.053 1.173c-4.739 4.739 -9.466 9.49 -14.22 14.212 -4.91 4.878 -9.847 9.728 -14.768 14.593 -0.295 0.292 -0.559 0.617 -0.838 0.927"
      />
      <path className="half-inverted-cls-1" d="M39.77 21.893v5.947l-3.066 3.066" />
      <path
        className="half-inverted-cls-1"
        d="M39.677 6.469c-7.836 7.836 -15.673 15.671 -23.506 23.509 -0.294 0.294 -0.559 0.617 -0.838 0.927"
      />
      <path
        className="half-inverted-cls-1"
        d="M39.305 3.681c-4.521 4.521 -9.05 9.037 -13.564 13.567 -4.345 4.36 -8.675 8.732 -13.008 13.101 -0.078 0.078 -0.126 0.184 -0.188 0.277"
      />
      <path
        className="half-inverted-cls-1"
        d="M34.102 0.1c-0.929 0.433 -4.839 2.204 -5.757 2.661 -0.334 0.167 -0.663 0.385 -0.927 0.649C18.551 12.291 9.695 21.181 0.836 30.067c-0.201 0.201 -0.431 0.374 -0.649 0.559"
      />
    </g>
  </svg>
);

export const SideDrawGrainSurfaceIcon = (props) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 37.576"
    width={40}
    height={30.576}
    {...props}
  >
    <defs>
      <style>
        {'.side-draw-cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;}'}
      </style>
    </defs>
    <g id="Layer_1-2">
      <path className="side-draw-cls-1" d="M1.721 0.874c0.34 0.154 0.683 0.311 1.023 0.465" />
      <path
        className="side-draw-cls-1"
        d="M18.094 8.503c-0.558 -0.247 -1.116 -0.497 -1.674 -0.744"
      />
      <path className="side-draw-cls-1" d="M6.838 3.572c-2.233 2.201 -4.465 4.404 -6.698 6.605" />
      <path className="side-draw-cls-1" d="M39.583 31.853c-1.768 1.799 -3.535 3.596 -5.303 5.396" />
      <path
        className="side-draw-cls-1"
        d="M29.722 14.177c-3.535 3.535 -7.089 7.051 -10.6 10.611 -4.082 4.136 -8.129 8.306 -12.192 12.46"
      />
      <path
        className="side-draw-cls-1"
        d="M19.303 9.34c-6.078 6.078 -12.155 12.157 -18.235 18.232 -0.294 0.294 -0.618 0.56 -0.928 0.839"
      />
      <path
        className="side-draw-cls-1"
        d="M39.676 22.643c-4.558 4.558 -9.117 9.117 -13.673 13.677 -0.294 0.294 -0.56 0.618 -0.839 0.928"
      />
      <path
        className="side-draw-cls-1"
        d="M39.769 34.923c0.045 0.774 0.091 1.55 0.138 2.326h-8.603"
      />
      <path
        className="side-draw-cls-1"
        d="M11.117 5.433c-3.349 3.349 -6.696 6.7 -10.049 10.045C0.774 15.772 0.45 16.038 0.14 16.317"
      />
      <path className="side-draw-cls-1" d="M4.791 2.642c-1.55 1.52 -3.102 3.038 -4.651 4.558" />
      <path
        className="side-draw-cls-1"
        d="M15.303 7.386c-4.744 4.744 -9.489 9.491 -14.235 14.231 -0.294 0.294 -0.618 0.56 -0.928 0.839"
      />
      <path
        className="side-draw-cls-1"
        d="M0.14 13.34c2.934 -3.051 5.868 -6.103 8.767 -9.117 -1.782 -0.822 -3.848 -1.775 -5.937 -2.737 -0.874 0.815 -1.805 1.684 -2.737 2.551"
      />
      <path
        className="side-draw-cls-1"
        d="M31.769 15.108c-3.349 3.349 -6.717 6.679 -10.041 10.051 -3.957 4.011 -7.881 8.058 -11.82 12.09"
      />
      <path className="side-draw-cls-1" d="m39.304 19.852 -14.326 14.326 -2.605 2.605" />
      <path
        className="side-draw-cls-1"
        d="m39.304 28.969 -8.188 8.188 0.095 0.184h-17.303c-4.062 0 -8.125 0.002 -12.187 -0.004 -0.311 0 -0.62 -0.058 -0.93 -0.089"
      />
      <path className="side-draw-cls-1" d="M28.885 13.526c0.558 0.247 1.116 0.497 1.674 0.744" />
      <path
        className="side-draw-cls-1"
        d="M17.257 8.41c-5.396 5.396 -10.791 10.791 -16.189 16.185 -0.294 0.294 -0.618 0.56 -0.928 0.839"
      />
      <path
        className="side-draw-cls-1"
        d="M12.884 6.642C8.946 10.581 5.009 14.52 1.068 18.455c-0.294 0.294 -0.618 0.56 -0.928 0.839"
      />
      <path
        className="side-draw-cls-1"
        d="M27.676 13.154c-0.59 0.62 -1.167 1.252 -1.769 1.859 -3.407 3.416 -6.821 6.825 -10.231 10.235"
      />
      <path className="side-draw-cls-1" d="m15.489 25.62 -7.814 7.814 -3.349 3.349" />
      <path className="side-draw-cls-1" d="M9.721 4.596c0.683 0.311 1.364 0.62 2.047 0.93" />
      <path className="side-draw-cls-1" d="M26.838 12.596c0.558 0.247 1.116 0.497 1.674 0.744" />
      <path
        className="side-draw-cls-1"
        d="M30.932 14.456c2.14 0.992 4.283 1.978 6.417 2.981 0.815 0.383 1.613 0.804 2.467 1.232v6.975c-0.127 0.063 -0.247 0.091 -0.322 0.166 -3.786 3.81 -7.569 7.625 -11.353 11.439"
      />
      <path
        className="side-draw-cls-1"
        d="M36.048 17.154c-6.387 6.387 -12.776 12.775 -19.162 19.166 -0.294 0.294 -0.56 0.618 -0.839 0.928"
      />
      <path
        className="side-draw-cls-1"
        d="M21.536 10.27C14.713 17.093 7.892 23.916 1.068 30.734c-0.294 0.294 -0.618 0.56 -0.928 0.839"
      />
      <path className="side-draw-cls-1" d="M12.14 5.712c1.302 0.62 2.605 1.241 3.907 1.861" />
      <path
        className="side-draw-cls-1"
        d="M38.095 18.085c-6.078 6.078 -12.157 12.155 -18.232 18.235 -0.294 0.294 -0.56 0.618 -0.839 0.928"
      />
      <path
        className="side-draw-cls-1"
        d="M33.722 16.317c-6.512 6.544 -13.024 13.087 -19.538 19.627 -0.294 0.296 -0.618 0.56 -0.928 0.839"
      />
      <path className="side-draw-cls-1" d="M18.466 8.689c2.326 1.085 4.651 2.171 6.977 3.256" />
      <path
        className="side-draw-cls-1"
        d="M26.466 12.41c-0.24 -0.14 -0.48 -0.279 -0.707 -0.409C17.242 20.52 8.759 29 0.279 37.483c-0.061 -0.047 -0.123 -0.093 -0.186 -0.14V0.093c0.3 0.119 0.545 0.218 0.791 0.316"
      />
      <path
        className="side-draw-cls-1"
        d="M39.769 25.806c0.032 1.861 0.069 3.721 0.091 5.582 0.013 1.116 0.002 2.235 0.002 3.137 -0.969 1.012 -1.786 1.868 -2.605 2.724"
      />
      <path
        className="side-draw-cls-1"
        d="M23.582 11.201C16.079 18.704 8.575 26.21 1.068 33.711c-0.294 0.294 -0.618 0.56 -0.928 0.839"
      />
    </g>
  </svg>
);

export const LevelGrainSurfaceIcon = (props) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 29.147"
    width={40}
    height={29.147}
    {...props}
  >
    <defs>
      <style>
        {'.level-cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;}'}
      </style>
    </defs>
    <g id="Layer_1-2">
      <path className="level-cls-1" d="M39.717 21.651c-2.483 2.453 -4.967 4.904 -7.45 7.357" />
      <path className="level-cls-1" d="m3.213 0.233 -2.98 2.98" />
      <path
        className="level-cls-1"
        d="M39.717 12.525c-2.173 2.173 -4.334 4.358 -6.52 6.515 -3.062 3.023 -6.144 6.023 -9.214 9.039 -0.298 0.292 -0.561 0.618 -0.84 0.929"
      />
      <path
        className="level-cls-1"
        d="M39.81 6.565c0.032 1.894 0.069 3.786 0.091 5.68 0.013 1.088 0.002 2.175 0.002 3.215 -0.24 0.179 -0.415 0.281 -0.557 0.419 -4.381 4.312 -8.755 8.627 -13.13 12.944 -0.047 0.047 -0.063 0.123 -0.095 0.184"
      />
      <path
        className="level-cls-1"
        d="M39.81 24.817V29.054H0.093V0.093h39.717v6.293c-0.246 0.212 -0.507 0.417 -0.743 0.646 -0.535 0.52 -1.054 1.056 -1.585 1.581 -5.245 5.185 -10.493 10.366 -15.736 15.553 -1.529 1.512 -3.043 3.041 -4.565 4.561"
      />
      <path
        className="level-cls-1"
        d="M18.299 0.233C13.797 4.764 9.294 9.294 4.798 13.831c-1.406 1.419 -2.796 2.855 -4.192 4.282"
      />
      <path
        className="level-cls-1"
        d="M15.319 0.233c-4.749 4.749 -9.499 9.499 -14.25 14.246 -0.294 0.294 -0.618 0.561 -0.929 0.84"
      />
      <path className="level-cls-1" d="M6.193 0.233c-2.017 1.987 -4.036 3.973 -6.053 5.96" />
      <path
        className="level-cls-1"
        d="M9.173 0.233c-1.862 1.894 -3.727 3.785 -5.586 5.682C2.589 6.934 1.6 7.962 0.605 8.986"
      />
      <path className="level-cls-1" d="m39.624 27.89 -0.838 0.838" />
      <path
        className="level-cls-1"
        d="M12.339 0.233C8.582 3.989 4.827 7.746 1.069 11.499c-0.294 0.294 -0.618 0.561 -0.929 0.84"
      />
      <path
        className="level-cls-1"
        d="M39.81 15.691c0.032 1.894 0.069 3.786 0.091 5.68 0.013 1.088 0.002 2.175 0.002 3.464 -1.451 1.354 -2.961 2.762 -4.47 4.172"
      />
      <path className="level-cls-1" d="M27.425 0.233c-8.94 9.001 -17.88 18.004 -26.819 27.006" />
      <path
        className="level-cls-1"
        d="M36.737 0.233c-3.352 3.352 -6.694 6.718 -10.061 10.054 -3.742 3.708 -7.517 7.383 -11.262 11.087 -2.466 2.438 -4.908 4.902 -7.359 7.355"
      />
      <path
        className="level-cls-1"
        d="M39.624 18.764c-1.583 1.583 -3.161 3.172 -4.751 4.747 -1.855 1.838 -3.723 3.665 -5.586 5.496"
      />
      <path
        className="level-cls-1"
        d="M39.624 0.512c-0.87 0.87 -1.736 1.741 -2.607 2.607 -8.381 8.32 -16.762 16.637 -25.141 24.959 -0.296 0.294 -0.561 0.618 -0.84 0.929"
      />
      <path
        className="level-cls-1"
        d="M21.465 0.233c-6.798 6.798 -13.596 13.596 -20.396 20.392 -0.294 0.294 -0.618 0.561 -0.929 0.84"
      />
      <path
        className="level-cls-1"
        d="M39.624 9.638c-1.615 1.615 -3.22 3.237 -4.844 4.841 -4.589 4.537 -9.188 9.065 -13.778 13.6 -0.296 0.292 -0.561 0.618 -0.84 0.929"
      />
      <path
        className="level-cls-1"
        d="M39.717 3.399c-4.097 4.097 -8.184 8.208 -12.296 12.288 -4.176 4.144 -8.379 8.26 -12.566 12.391 -0.296 0.292 -0.561 0.618 -0.84 0.929"
      />
      <path
        className="level-cls-1"
        d="M30.591 0.233c-9.281 9.281 -18.563 18.561 -27.842 27.846 -0.294 0.294 -0.561 0.618 -0.84 0.929"
      />
      <path
        className="level-cls-1"
        d="M33.571 0.233c-9.281 9.281 -18.563 18.561 -27.842 27.846 -0.294 0.294 -0.561 0.618 -0.84 0.929"
      />
      <path
        className="level-cls-1"
        d="M24.445 0.233C16.654 8.023 8.863 15.816 1.069 23.605c-0.294 0.294 -0.618 0.561 -0.929 0.84"
      />
    </g>
  </svg>
);

export const HalfPeakedGrainSurfaceIcon = (props) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 31.716"
    width={40}
    height={31.716}
    {...props}
  >
    <defs>
      <style>
        {'.half-peaked-cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;}'}
      </style>
    </defs>
    <g id="Layer_1-2">
      <path className="half-peaked-cls-1" d="M2.998 1.45c1.372 0.623 2.745 1.248 4.117 1.871" />
      <path className="half-peaked-cls-1" d="M18.809 0.702c-0.249 0.094 -0.5 0.187 -0.748 0.281" />
      <path
        className="half-peaked-cls-1"
        d="M39.766 26.056c-1.809 1.839 -3.617 3.681 -5.426 5.52"
      />
      <path
        className="half-peaked-cls-1"
        d="M5.056 2.573c-1.278 1.278 -2.562 2.552 -3.832 3.84 -0.217 0.221 -0.378 0.494 -0.565 0.745"
      />
      <path
        className="half-peaked-cls-1"
        d="M2.81 1.263c-0.687 -0.312 -1.372 -0.623 -2.058 -0.936"
      />
      <path
        className="half-peaked-cls-1"
        d="M39.766 16.887c-4.584 4.584 -9.169 9.169 -13.751 13.755 -0.296 0.296 -0.563 0.621 -0.844 0.934"
      />
      <path className="half-peaked-cls-1" d="M39.766 29.05c-0.81 0.842 -1.622 1.684 -2.433 2.526" />
      <path
        className="half-peaked-cls-1"
        d="M34.714 2.479c-0.623 0.312 -1.248 0.623 -1.871 0.936"
      />
      <path className="half-peaked-cls-1" d="M20.774 0.327c1.216 0.593 2.433 1.184 3.649 1.778" />
      <path className="half-peaked-cls-1" d="M0.939 0.702c-0.219 0.187 -0.436 0.374 -0.655 0.561" />
      <path
        className="half-peaked-cls-1"
        d="M19.464 0.327c-0.374 0.374 -0.75 0.747 -1.123 1.123C12.664 7.187 6.991 12.928 1.312 18.663c-0.357 0.361 -0.747 0.689 -1.121 1.031"
      />
      <path
        className="half-peaked-cls-1"
        d="M9.172 4.444C6.459 7.219 3.748 9.996 1.031 12.769c-0.262 0.268 -0.559 0.501 -0.84 0.75"
      />
      <path
        className="half-peaked-cls-1"
        d="M39.298 0.327c-0.717 0.342 -1.435 0.687 -2.152 1.029"
      />
      <path className="half-peaked-cls-1" d="M39.766 22.875c-2.869 2.9 -5.739 5.801 -8.607 8.701" />
      <path className="half-peaked-cls-1" d="M8.143 3.789c0.561 0.249 1.123 0.5 1.684 0.748" />
      <path className="half-peaked-cls-1" d="M15.628 2.105c-1.746 0.81 -3.493 1.622 -5.239 2.433" />
      <path
        className="half-peaked-cls-1"
        d="M39.766 13.706c-5.645 5.645 -11.291 11.289 -16.932 16.936 -0.296 0.296 -0.563 0.621 -0.844 0.934"
      />
      <path className="half-peaked-cls-1" d="M24.797 2.292c1.372 0.623 2.745 1.248 4.117 1.871" />
      <path className="half-peaked-cls-1" d="M2.81 1.637c-0.842 0.904 -1.684 1.809 -2.526 2.713" />
      <path
        className="half-peaked-cls-1"
        d="M13.008 3.789c-4.023 4.055 -8.046 8.108 -12.067 12.164 -0.109 0.11 -0.189 0.247 -0.283 0.372"
      />
      <path className="half-peaked-cls-1" d="M17.686 1.169c-0.561 0.249 -1.123 0.5 -1.684 0.748" />
      <path
        className="half-peaked-cls-1"
        d="M21.896 1.076c-6.923 6.923 -13.847 13.847 -20.772 20.768 -0.296 0.296 -0.621 0.563 -0.934 0.844"
      />
      <path
        className="half-peaked-cls-1"
        d="M7.769 3.602c-0.131 -0.045 -0.262 -0.09 -0.556 -0.193L0.097 10.525v4.023c0 3.4 -0.009 6.798 0.004 10.198 0.006 1.342 0.058 2.681 0.09 4.023"
      />
      <path className="half-peaked-cls-1" d="M36.772 1.544c-0.561 0.249 -1.123 0.5 -1.684 0.748" />
      <path
        className="half-peaked-cls-1"
        d="M39.766 19.881c-3.587 3.587 -7.174 7.172 -10.757 10.761 -0.296 0.296 -0.563 0.621 -0.844 0.934"
      />
      <path className="half-peaked-cls-1" d="M29.288 4.35c0.281 0.125 0.561 0.249 0.842 0.374" />
      <path
        className="half-peaked-cls-1"
        d="M39.766 1.544C30.068 11.242 20.368 20.94 10.671 30.642c-0.296 0.296 -0.563 0.621 -0.844 0.934"
      />
      <path
        className="half-peaked-cls-1"
        d="M39.766 4.538c-8.701 8.701 -17.402 17.402 -26.101 26.105 -0.296 0.296 -0.563 0.621 -0.844 0.934"
      />
      <path
        className="half-peaked-cls-1"
        d="M0.097 0.094c0 2.322 -0.009 4.693 0.004 7.064 0.006 1.091 0.058 2.184 0.09 3.275"
      />
      <path
        className="half-peaked-cls-1"
        d="M26.107 3.041C17.482 11.663 8.86 20.287 0.144 29.003v2.62h39.762V0.094"
      />
      <path
        className="half-peaked-cls-1"
        d="M36.024 2.105c-0.281 0.342 -0.535 0.713 -0.846 1.025 -9.163 9.174 -18.334 18.341 -27.501 27.512 -0.296 0.296 -0.563 0.621 -0.844 0.934"
      />
      <path
        className="half-peaked-cls-1"
        d="M32.469 3.602c-1.126 0.339 -1.989 1.01 -2.825 1.852 -8.609 8.669 -17.26 17.295 -25.895 25.936 -0.047 0.047 -0.064 0.123 -0.095 0.185"
      />
      <path
        className="half-peaked-cls-1"
        d="M39.766 10.712c-6.643 6.643 -13.285 13.285 -19.926 19.93 -0.296 0.296 -0.563 0.621 -0.844 0.934"
      />
      <path
        className="half-peaked-cls-1"
        d="M39.766 7.719c-7.64 7.64 -15.282 15.28 -22.92 22.924 -0.296 0.296 -0.563 0.621 -0.844 0.934"
      />
      <path
        className="half-peaked-cls-1"
        d="M28.071 4.07C19.213 12.926 10.357 21.782 1.503 30.642c-0.296 0.296 -0.563 0.621 -0.844 0.934"
      />
      <path
        className="half-peaked-cls-1"
        d="m23.674 2.292 -15.437 15.437c-2.494 2.494 -4.99 4.989 -7.481 7.488 -0.062 0.062 -0.065 0.183 -0.097 0.277"
      />
    </g>
  </svg>
);
