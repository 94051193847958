import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { pushNav } from '../../action';
import { ViewerFragmentFragment } from '../../api';
import { UserPasswordForm } from './UserPasswordForm';

const useStyles = makeStyles({
  centered: {
    textAlign: 'center',
  },
});
export const UserPasswordDialog = ({ viewer }: { viewer: ViewerFragmentFragment }) => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const onSubmitSuccess = useCallback(() => {
    dispatch(pushNav({ path: '/dashboard' }));
    setTimeout(() => setOpen(false), 1000);
  }, [dispatch]);

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>Welcome to Amber Ag!</DialogTitle>
      <DialogContent>
        <Typography>
          Before we get started, please create a new password for your account.
        </Typography>
        <UserPasswordForm
          user_id={viewer.user_id}
          token={viewer.token}
          hide_title
          onSubmitSuccess={onSubmitSuccess}
        />
      </DialogContent>
    </Dialog>
  );
};
