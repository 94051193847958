import { TextField as MuiTextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { FieldValidator, useField } from 'formik';
import React, { FunctionComponent } from 'react';

export const TextField: FunctionComponent<
  { name: string; validate?: FieldValidator } & TextFieldProps
> = ({ name, validate, ...props }) => {
  const [field, { error, touched }] = useField({ name, validate });
  const has_error = Boolean(touched && error);
  return (
    <MuiTextField
      id={`TextField-${name}`}
      name={name}
      error={has_error}
      helperText={has_error ? error : ''}
      {...field}
      {...props}
      onBlur={(e) => {
        field.onBlur(e);
        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
    />
  );
};
