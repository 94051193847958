import { AmberError } from '../../../util';

export class GraphQLErrors extends Error {
  name = 'GraphQLErrors';
  errors: AmberError[];
  constructor(errors: AmberError[]) {
    super(errors.map(({ message }) => message).join('\n'));
    this.errors = errors;
  }
}

export const isGraphQLErrors = (err: any): err is GraphQLErrors =>
  err instanceof GraphQLErrors || err.name === 'GraphQLErrors';
