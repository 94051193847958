import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { black_shade_3, blue_shade_3, maroon_shade_2 } from '../../../../../../core/src/style';
import { formatPercent, formatTemp } from '../../../../util';
import { withSkeleton } from '../withCellSkeleton';
import { WeatherInfoPeriod } from './WeatherHeaderCellRenderer';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10,
    lineHeight: 1.6,
  },
  maxValue: {
    color: maroon_shade_2,
  },
  minValue: {
    color: blue_shade_3,
  },
  divider: {
    width: 13,
    height: 1,
    backgroundColor: black_shade_3,
    margin: '2px 0px',
  },
}));
const WeatherCellRenderer = withSkeleton((props) => {
  if (props.value === undefined) {
    return <div>-</div>;
  }

  const weatherPeriod = props.api['weather_period'] || WeatherInfoPeriod.today;
  const weatherInfo = props.value[weatherPeriod];
  const weatherParam = props.weatherParam;

  if (!weatherInfo || !weatherParam) {
    return <div>-</div>;
  }

  if (weatherPeriod === WeatherInfoPeriod.now) {
    return <NowWeatherCondition weatherInfo={weatherInfo} weatherParam={weatherParam} />;
  }

  const {
    temp: { temp_high_f, temp_low_f },
    humidity: { humidity_high_rh, humidity_low_rh },
    emc: { emc_high, emc_low },
  } = weatherInfo;

  if (weatherParam === 'temperature') {
    return (
      <MinMaxWeatherConditionCell max={formatTemp(temp_high_f)} min={formatTemp(temp_low_f)} />
    );
  }

  if (weatherParam === 'humidity') {
    return (
      <MinMaxWeatherConditionCell
        max={formatPercent(humidity_high_rh)}
        min={formatPercent(humidity_low_rh)}
      />
    );
  }

  if (weatherParam === 'emc') {
    return (
      <MinMaxWeatherConditionCell max={formatPercent(emc_high)} min={formatPercent(emc_low)} />
    );
  }

  return null;
});

export const NowWeatherCondition = ({ weatherInfo, weatherParam }) => {
  const { temp_f, humidity_rh, emc } = weatherInfo;
  if (weatherParam === 'temperature') {
    return <SimpleWeatherConditionCell value={formatTemp(temp_f)} />;
  }

  if (weatherParam === 'humidity') {
    return <SimpleWeatherConditionCell value={formatPercent(humidity_rh)} />;
  }

  if (weatherParam === 'emc') {
    return <SimpleWeatherConditionCell value={formatPercent(emc)} />;
  }

  return null;
};

export const SimpleWeatherConditionCell = ({ value }: { value: string }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div>{value}</div>
    </div>
  );
};

export const MinMaxWeatherConditionCell = ({ min, max }: { min: string; max: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.maxValue}>{max}</div>
      <div className={classes.divider} />
      <div className={classes.minValue}>{min}</div>
    </div>
  );
};

export { WeatherCellRenderer };
