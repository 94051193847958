import { makeStyles } from '@material-ui/styles';
import { Form, Formik, FormikConfig, FormikHelpers, useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';

import { formatError } from '../../../util';
import { DialogSpinner } from '../../spinner';

const useStyles = makeStyles({
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
  },
});

export const BaseForm = ({
  className,
  children,
  onSubmit,
  submitting_message = 'Submitting...',
  ...props
}: React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
  submitting_message?: string;
}) => {
  const classes = useStyles();
  const { isSubmitting } = useFormikContext();
  return (
    <Form {...props} className={className || classes.root} noValidate>
      {children}
      <DialogSpinner title={submitting_message} open={isSubmitting} />
    </Form>
  );
};

export type FormikWrapperProps<Values, Result> = Omit<FormikConfig<Values>, 'onSubmit'> &
  FormikWrapperHandlerProps<Values, Result> & {
    onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => Promise<Result>;
  };

export type FormikWrapperHandlerProps<Values, Result> = {
  onSubmitSuccess?: (result: Result, formikHelpers: FormikHelpers<Values>) => void;
  onSubmitFailure?: (error, formikHelpers: FormikHelpers<Values>) => void;
};

export const FormikWrapper = <Values extends {}, Result = any>({
  onSubmit,
  onSubmitSuccess,
  onSubmitFailure,
  ...props
}: FormikWrapperProps<Values, Result>) => {
  const submitCallback = useCallback(
    async (values, formikHelpers) => {
      const { setFieldError, setSubmitting } = formikHelpers;
      setFieldError('general', '');
      try {
        const result = await onSubmit(values, formikHelpers);
        setSubmitting(false);
        onSubmitSuccess && onSubmitSuccess(result, formikHelpers);
      } catch (err) {
        const msgs = formatError(err);
        setFieldError('general', msgs.length ? msgs[0] : 'An unexpected server error has occurred');
        setSubmitting(false);
        onSubmitFailure && onSubmitFailure(err, formikHelpers);
      }
    },
    [onSubmit, onSubmitSuccess, onSubmitFailure]
  );
  return <Formik<Values & { general?: string }> {...props} onSubmit={submitCallback} />;
};
