import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../action/schedule';
import { checkValidSchedule } from '../util/check-valid-schedule';

type ActionTypes = ActionType<typeof actions>;
export type ScheduleState = {
  fan_schedule_drafts: {
    start_epoch: Date | null;
    end_epoch: Date | null;
  }[];
};

export const scheduleReducer = (
  state: ScheduleState = {
    fan_schedule_drafts: [],
  },
  action: ActionTypes
): ScheduleState => {
  switch (action.type) {
    case getType(actions.setFanScheduleDrafts): {
      return {
        ...state,
        // only take valid schedules
        fan_schedule_drafts: action.payload.filter((window) =>
          checkValidSchedule({ start_epoch: window.start_epoch, end_epoch: window.end_epoch })
        ),
      };
    }
    default:
      return state;
  }
};
