import {
  Grid,
  LinearProgress,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWeatherData } from '../../../../../core/src/contexts';
import { ArrowDropDownNounIcon } from '../../../../../core/src/media';
import {
  FanControllerRunWindow,
  FanRunWindowRecommendedOption,
  GrainContainerAerationRunFragmentFragment,
} from '../../../api';
import {
  amber_green,
  amber_light_grey,
  black,
  black_shade_5,
  light_gray_4,
  light_gray_7,
  maroon_shade_1,
  red_shade_1,
} from '../../../style';
import {
  calculateRunwindowDuration,
  formatNumber,
  getTemperatureUnitLabel,
  isNoEnd,
} from '../../../util';
import { ColumnConfig } from '../../util';
import { getRemainingDaysAutomationPeriodString } from '../daily-forecast/AerationRecommnedationPlotHelpers';
import { RecommendedOption } from '../RecommendationOptionValue';
import { formatDate } from './BinFanSettings';
import { computeAvgGrainConditions } from './FanSetMultiSchedule';
import { NoSchedulePlaceholder } from './NoSchedulePlaceholder';
import { RunWinowReadOnly } from './RunWinowReadOnly';

export const getRecommendationOptionColor = (recommendationOption) => {
  switch (recommendationOption) {
    case RecommendedOption.COOLING:
      return '#6EC8FF';
    case RecommendedOption.DRYING:
      return '#FFC07F';
    case RecommendedOption.RECONDITIONING:
      return '#CA7FFF';
    default:
      return amber_green;
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    minWidth: 300,
    maxWidth: 400,
    margin: 20,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 600,
    },
  },
  schedule_heading: {
    color: black,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  manageAutomationbtn: {
    [theme.breakpoints.down('xs')]: {
      width: 152,
      lineHeight: 1.5,
    },
    backgroundColor: amber_light_grey,
    borderRadius: 20,
  },
  fanScheduleTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 5px',
  },
  scheduleButtons: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
    },
  },
  runWindowContainer: {
    margin: 0,
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 10,
    justifyContent: 'center',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  firstRunWindowContainer: {
    margin: 0,
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  runWindowWrapper: {
    padding: '0px 5px 0px 5px',
    marginTop: 15,
    [theme.breakpoints.only('xs')]: {
      padding: '0px',
    },
  },
  fanScheduleCount: {
    width: 20,
    height: 20,
    background: `${light_gray_4} 0% 0% no-repeat padding-box`,
    color: black,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    marginLeft: 6,
  },
  dropdownBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  automationRemainDays: {
    width: 'fit-content',
    height: 21,
    backgroundColor: light_gray_7,
    borderRadius: 7,
    padding: '3px 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    color: black_shade_5,
  },
  linearProgressContainer: {
    width: '100%',
    marginTop: 6,
    [theme.breakpoints.only('xs')]: {
      width: '98%',
    },
    [theme.breakpoints.between(405, 600)]: {
      width: '88%',
    },
    [theme.breakpoints.between(1280, 1580)]: {
      width: '100%',
    },
  },
  linearProgress: {
    backgroundColor: '#E6E6E6',
    '& .MuiLinearProgress-barColorPrimary': {
      zIndex: 0,
      background: ({ aeration_schedule_type }: makeStylesProps) => {
        return aeration_schedule_type
          ? `linear-gradient(120deg,rgba(255,255,255, 0) 30%,rgba(255,255,255, .8),rgba(255,255,255, 0) 70% ) ${getRecommendationOptionColor(
              aeration_schedule_type
            )}`
          : '';
      },
      backgroundSize: '300% 100%!important',
      animation: '$shine 2s infinite',
      animationPlayState: ({ hasScheduledRunInterrupted }: makeStylesProps) =>
        hasScheduledRunInterrupted ? 'paused' : 'running',
    },
  },
  '@keyframes shine': {
    '0%': { backgroundPosition: 'right' },
  },
  divider: { backgroundColor: light_gray_7, height: 1, width: '100%', marginTop: 10 },
  placeholderContainer: {
    width: '100%',
    marginTop: 6,
    [theme.breakpoints.only('xs')]: {
      width: '98%',
    },
    [theme.breakpoints.between(405, 600)]: {
      width: '88%',
    },
    [theme.breakpoints.between(1280, 1580)]: {
      width: '100%',
    },
  },
}));

const formatTime = (value) => value.toLocaleString(DateTime.TIME_SIMPLE);

const formatDuration = (ms: number): string => {
  const hours = ms / (1000 * 60 * 60);
  const h = Math.floor(hours);

  if (h > 1000) {
    return 'Indefinite';
  }

  const minutes = (hours - h) * 60;
  const m = Math.floor(minutes);
  return `${h}h ${m}m`;
};

export const formatDateTime = (date: Date) => {
  const dt = DateTime.fromMillis(new Date(date).getTime());
  return `${formatDate(dt, DateTime.local())} ${formatTime(dt)}`;
};

export const getMs = (date: Date): number => {
  return new Date(date).getTime();
};

export const getIdForAerationRun = ({ start_epoch }) => new Date(start_epoch).getTime();

const columns: ColumnConfig<FanControllerRunWindow & { removeRunWindow: () => void }>[] = [
  {
    title: 'Start',
    width: 25,
    align: 'left',
    getValue: ({ start_epoch }) => (start_epoch ? formatDateTime(start_epoch) : `N/A`),
  },
  {
    title: 'End',
    width: 25,
    align: 'left',
    getValue: ({ start_epoch, end_epoch }) =>
      end_epoch && !isNoEnd(start_epoch, end_epoch) ? formatDateTime(end_epoch) : `N/A`,
  },
  {
    title: 'Runtime',
    width: 20,
    align: 'left',
    getValue: ({ start_epoch, end_epoch }) =>
      end_epoch
        ? formatDuration(getMs(end_epoch) - getMs(start_epoch))
        : `${formatDuration(getMs(new Date()) - getMs(start_epoch))} & running`,
  },
];

export const getRunWindowProgress = (start_epoch, end_epoch) => {
  const totalDuration = new Date(end_epoch).getTime() - new Date(start_epoch).getTime();
  const timeSpent = new Date().getTime() - new Date(start_epoch).getTime();
  const progress = (timeSpent / totalDuration) * 100;
  return progress;
};

type makeStylesProps = {
  aeration_schedule_type: string | null;
  runWindowsProgress: number;
  hasScheduledRunInterrupted: boolean;
};

export const UpcomingFanScheduleTable: FunctionComponent<{
  any_running: boolean;
  fan_schedules: FanControllerRunWindow[];
  aeration_schedule_type: FanRunWindowRecommendedOption | null;
  loading: boolean;
  startSettingNavigation: () => void;
  addRecommendedWindow: () => void;
  opt_in_fan_guidance: boolean;
  enable_fan_guidance: boolean;
  fan_guidance_end_date: Date | null;
  fan_guidance_start_date: Date | null;
  recommendation_type: string | null;
  hasUserReadOnlyAccess: boolean;
  hasManualMode: boolean;
  hasNoRecommendedRunsWithinAutoPeriod?: boolean;
  showErrorMsgAboveSchedule?: boolean;
  fanStopErrorMsgs?: string;
  showErrorMsgInFirstRunWindow?: boolean;
  fanErrorMsgsWithoutStopFail?: string[];
  show_restart_button?: boolean;
  show_fan_guidance_ext_prompt: boolean;
}> = ({
  fan_schedules,
  aeration_schedule_type,
  loading,
  startSettingNavigation,
  addRecommendedWindow,
  opt_in_fan_guidance,
  enable_fan_guidance,
  fan_guidance_start_date,
  fan_guidance_end_date,
  recommendation_type,
  hasUserReadOnlyAccess,
  any_running,
  hasManualMode,
  hasNoRecommendedRunsWithinAutoPeriod = false,
  show_restart_button = false,
  showErrorMsgAboveSchedule = false,
  fanStopErrorMsgs = '',
  showErrorMsgInFirstRunWindow = false,
  fanErrorMsgsWithoutStopFail = [],
  show_fan_guidance_ext_prompt,
}) => {
  const theme = useTheme();
  const lastAerationRun: GrainContainerAerationRunFragmentFragment | null = useSelector(
    ({ global_state: { last_aeration_run } }) => last_aeration_run
  );
  console.log('lastAerationRun', lastAerationRun);
  console.log('current RunWindow Errors', {
    showErrorMsgAboveSchedule,
    fanStopErrorMsgs,
    showErrorMsgInFirstRunWindow,
    fanErrorMsgsWithoutStopFail,
  });
  const isSmallMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
  const weatherData = useWeatherData();
  const [avgGrainConditions, setAvgGrainConditions] = useState([]);
  const sorted_fan_schedules = useMemo(() => {
    // sort newest to oldest i.e. highest epoch to lowest
    return [...fan_schedules].sort(
      (a, b) => new Date(a.start_epoch).getTime() - new Date(b.start_epoch).getTime()
    );
  }, [fan_schedules]);
  const [runWindowsProgress, setRunWindowsProgress] = useState(0);
  const [manualRunProgress, setManualRunProgress] = useState(0);
  const hasScheduledRunInterrupted = show_restart_button || false;
  const classes = useStyles({
    aeration_schedule_type,
    runWindowsProgress,
    hasScheduledRunInterrupted,
  });
  const [showMoreWindows, setShowMoreWindows] = useState(false);
  const fanGuidanceEndDate = fan_guidance_end_date
    ? DateTime.fromMillis(new Date(fan_guidance_end_date).getTime())
    : null;
  const as_of = DateTime.local();
  const isTodayDate =
    fanGuidanceEndDate &&
    fanGuidanceEndDate.day === as_of.day &&
    fanGuidanceEndDate.month === as_of.month &&
    fanGuidanceEndDate.year === as_of.year;
  const tomorrow = as_of.plus({ days: 1 });
  const isTomorrowDate =
    fanGuidanceEndDate &&
    fanGuidanceEndDate.day === tomorrow.day &&
    fanGuidanceEndDate.month === tomorrow.month &&
    fanGuidanceEndDate.year === tomorrow.year;
  const hasOngoingFanGuidance = opt_in_fan_guidance && enable_fan_guidance && fanGuidanceEndDate;
  const getValidDuration = (duration) => (duration >= 1 && duration <= 10 ? duration : 10);
  let userPrevFanGuidanceDuration = 10;
  if (fan_guidance_start_date && fan_guidance_end_date) {
    const startDate = DateTime.fromMillis(new Date(fan_guidance_start_date).getTime());
    const endDate = DateTime.fromMillis(new Date(fan_guidance_end_date).getTime());
    userPrevFanGuidanceDuration = getValidDuration(
      Math.round(Number(endDate.diff(startDate, 'days').toObject().days))
    );
  }
  const showNextKeyword =
    opt_in_fan_guidance &&
    enable_fan_guidance &&
    fanGuidanceEndDate &&
    userPrevFanGuidanceDuration === 10;
  const remainingAutomationPeriodInDays =
    enable_fan_guidance && fanGuidanceEndDate
      ? fanGuidanceEndDate.diff(as_of, 'days').toObject().days
      : null;
  const showRedColorPeriod =
    remainingAutomationPeriodInDays !== null &&
    (show_fan_guidance_ext_prompt || remainingAutomationPeriodInDays <= 1);

  const remainingAutomationPeriodString =
    enable_fan_guidance && fanGuidanceEndDate
      ? getRemainingDaysAutomationPeriodString(as_of, fanGuidanceEndDate)
      : '';
  console.log(
    'remainingAutomationPeriodInDays',
    remainingAutomationPeriodInDays,
    remainingAutomationPeriodString
  );

  const hasScheduledRunWindowExistAfterFanGuidanceEnded =
    opt_in_fan_guidance &&
    enable_fan_guidance &&
    fan_schedules.length > 0 &&
    as_of.toMillis() > fanGuidanceEndDate.toMillis();
  const scheduleHeading = 'Schedule';
  const tempUnit = getTemperatureUnitLabel();
  const toggleShowingRunWindows = () => {
    setShowMoreWindows(!showMoreWindows);
  };
  const showDropdownButton = sorted_fan_schedules.length > 1;
  useEffect(() => {
    if (weatherData && weatherData.hourlyForecast) {
      const refreshedAverageGrainConditions: any = sorted_fan_schedules.map(
        ({ start_epoch, end_epoch }) => {
          const start = start_epoch.getTime();
          const end = end_epoch.getTime();
          const [temp, rh] = computeAvgGrainConditions(start, end, weatherData.hourlyForecast);

          return { temp, rh };
        }
      );
      setAvgGrainConditions(refreshedAverageGrainConditions);
    }
  }, [sorted_fan_schedules, weatherData]);

  // for non manual mode
  useEffect(() => {
    let runWindowProgressTimer;
    if (!hasManualMode && sorted_fan_schedules && sorted_fan_schedules[0]) {
      const { start_epoch, end_epoch } = sorted_fan_schedules[0];
      const hasFanRunningSchedule = any_running && start_epoch.getTime() < new Date().getTime();
      if (start_epoch && end_epoch && hasFanRunningSchedule) {
        setRunWindowsProgress(getRunWindowProgress(start_epoch, end_epoch));
        runWindowProgressTimer = setInterval(() => {
          setRunWindowsProgress((oldProgress) => {
            return getRunWindowProgress(start_epoch, end_epoch);
          });
        }, 1000 * 30); // update after every 30 sec
      }
    } else if (runWindowProgressTimer) {
      console.log('in else block of non ManualMode useEffect');
      clearInterval(runWindowProgressTimer);
    }
    return () => {
      runWindowProgressTimer && clearInterval(runWindowProgressTimer);
    };
  }, [any_running, hasManualMode]);

  // for manual mode
  useEffect(() => {
    let progressTimer;
    if (hasManualMode && sorted_fan_schedules && sorted_fan_schedules[0]) {
      const { start_epoch, end_epoch } = sorted_fan_schedules[0];
      const hasManualModeWithNextSchedule = Boolean(
        any_running &&
          hasManualMode &&
          start_epoch &&
          lastAerationRun &&
          lastAerationRun.start_epoch
      );
      if (start_epoch && end_epoch && lastAerationRun && hasManualModeWithNextSchedule) {
        setManualRunProgress(getRunWindowProgress(lastAerationRun.start_epoch, start_epoch));
        progressTimer = setInterval(() => {
          setManualRunProgress((oldProgress) => {
            return getRunWindowProgress(lastAerationRun.start_epoch, start_epoch);
          });
        }, 1000 * 30); // update after every 30 sec
      }
    } else if (progressTimer) {
      console.log('in else block of ManualMode useEffect');
      clearInterval(progressTimer);
    }
    return () => {
      progressTimer && clearInterval(progressTimer);
    };
  }, [any_running, hasManualMode, lastAerationRun]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        // maxHeight: 300,
        // marginTop: 15,
      }}
    >
      {loading && <LinearProgress color="secondary" />}

      <Grid container>
        <Grid item xs={12} className={classes.fanScheduleTitle}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" className={classes.schedule_heading}>
              {scheduleHeading}
            </Typography>
            <div className={classes.fanScheduleCount}>{sorted_fan_schedules.length}</div>
          </div>
          {showDropdownButton && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ArrowDropDownNounIcon
                style={{
                  width: 19,
                  height: 12,
                  transform: showMoreWindows ? 'rotate(180deg)' : 'none',
                  marginRight: 10,
                  cursor: 'pointer',
                }}
                onClick={toggleShowingRunWindows}
              />
            </div>
          )}
        </Grid>
      </Grid>

      <Grid
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'start',
        }}
      >
        {recommendation_type && hasScheduledRunWindowExistAfterFanGuidanceEnded && (
          <Grid>
            <p style={{ paddingRight: '5px', fontSize: 14, margin: '8px 0px' }}>
              Automation Period Expired: Maintaining last scheduled run
            </p>
          </Grid>
        )}
      </Grid>

      {/* placeholder when No Recommended Runs within Automation Period*/}
      {hasNoRecommendedRunsWithinAutoPeriod && (
        <NoSchedulePlaceholder
          message="No Recommended Runs within Automation Period"
          containerStyles={{ marginBottom: 0, marginTop: 15 }}
        />
      )}

      {/* placeholder when one fan has errror and other is running*/}
      {showErrorMsgAboveSchedule && fanStopErrorMsgs && (
        <div
          className={classes.placeholderContainer}
          style={{ marginTop: 10, padding: isSmallMobile ? undefined : '0px 5px' }}
        >
          <NoSchedulePlaceholder
            message={fanStopErrorMsgs}
            containerStyles={{ marginBottom: 0 }}
            hasErrorIcon
          />
        </div>
      )}

      {/* placeholder when Manual Mode */}
      {hasManualMode && (
        <div
          className={classes.placeholderContainer}
          style={{ padding: isSmallMobile ? undefined : '0px 5px', marginTop: 15 }}
        >
          <NoSchedulePlaceholder
            message="On Site Manual Run"
            containerStyles={{ marginBottom: 0 }}
          />
        </div>
      )}

      {hasManualMode && sorted_fan_schedules && sorted_fan_schedules[0] && (
        <div className={classes.linearProgressContainer} style={{ padding: '0px 5px' }}>
          <LinearProgress
            className={classes.linearProgress}
            variant="determinate"
            value={manualRunProgress}
          />
        </div>
      )}

      <div style={{ display: 'border-box', width: '100%' }} className={classes.runWindowWrapper}>
        {/* first runwindow only */}
        {sorted_fan_schedules &&
          sorted_fan_schedules[0] &&
          [sorted_fan_schedules[0]].map((schedule, ix) => {
            const { start_epoch, end_epoch } = schedule;
            const hasFanRunningSchedule =
              any_running && start_epoch.getTime() < new Date().getTime();
            const start = DateTime.fromMillis(start_epoch.getTime());
            const end = DateTime.fromMillis(end_epoch.getTime());
            const estimatedTemp = avgGrainConditions
              ? avgGrainConditions[ix]
                ? `${formatNumber(avgGrainConditions[ix]['temp'], 0)}${tempUnit}`
                : `- ${tempUnit}`
              : null;
            const estimatedRH = avgGrainConditions
              ? avgGrainConditions[ix]
                ? `${formatNumber(avgGrainConditions[ix]['rh'] * 100, 0)}% RH`
                : '- RH'
              : null;

            const duration = calculateRunwindowDuration({ start, end });
            const runtimeInText = end_epoch
              ? formatDuration(getMs(end_epoch) - getMs(start_epoch))
              : `${formatDuration(getMs(new Date()) - getMs(start_epoch))} & running`;
            return (
              <div key={`run-windows-upcoming-${ix}`}>
                <div
                  className={classes.firstRunWindowContainer}
                  style={{ marginBottom: showErrorMsgAboveSchedule || hasManualMode ? 10 : 0 }}
                >
                  <RunWinowReadOnly
                    index={ix}
                    start={start}
                    end={end}
                    estimatedTemp={estimatedTemp}
                    estimatedRH={estimatedRH}
                    runtime={duration}
                    runtimeInText={runtimeInText}
                    showLoadingAnimation={true}
                    recommendationOptionValue={aeration_schedule_type}
                    hasScheduledRunInterrupted={hasScheduledRunInterrupted}
                    showErrorMsgInFirstRunWindow={showErrorMsgInFirstRunWindow}
                    fanErrorMsgsWithoutStopFail={fanErrorMsgsWithoutStopFail}
                    preventNow
                  />
                  {!hasManualMode && !showErrorMsgAboveSchedule && (
                    <div className={classes.linearProgressContainer}>
                      <LinearProgress
                        className={classes.linearProgress}
                        variant="determinate"
                        value={runWindowsProgress}
                        style={{
                          marginBottom: 10,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}

        {showMoreWindows &&
          sorted_fan_schedules.slice(1).map((schedule, ix) => {
            const { start_epoch, end_epoch } = schedule;
            const start = DateTime.fromMillis(start_epoch.getTime());
            const end = DateTime.fromMillis(end_epoch.getTime());
            const estimatedTemp = avgGrainConditions
              ? avgGrainConditions[ix]
                ? `${formatNumber(avgGrainConditions[ix]['temp'], 0)}${tempUnit}`
                : `- ${tempUnit}`
              : null;
            const estimatedRH = avgGrainConditions
              ? avgGrainConditions[ix]
                ? `${formatNumber(avgGrainConditions[ix]['rh'] * 100, 0)}% RH`
                : '- RH'
              : null;

            const duration = calculateRunwindowDuration({ start, end });
            const runtimeInText = end_epoch
              ? formatDuration(getMs(end_epoch) - getMs(start_epoch))
              : `${formatDuration(getMs(new Date()) - getMs(start_epoch))} & running`;
            return (
              <div className={classes.runWindowContainer} key={`run-windows-${ix}`}>
                <RunWinowReadOnly
                  index={ix}
                  start={start}
                  end={end}
                  estimatedTemp={estimatedTemp}
                  estimatedRH={estimatedRH}
                  runtime={duration}
                  runtimeInText={runtimeInText}
                  showLoadingAnimation={true}
                  recommendationOptionValue={aeration_schedule_type}
                  preventNow
                />
              </div>
            );
          })}

        {recommendation_type &&
          hasOngoingFanGuidance &&
          !hasScheduledRunWindowExistAfterFanGuidanceEnded &&
          remainingAutomationPeriodString && (
            <Grid style={{ width: '100%' }}>
              <div
                className={classes.automationRemainDays}
                style={{
                  color: showRedColorPeriod ? maroon_shade_1 : undefined,
                  backgroundColor: showRedColorPeriod ? red_shade_1 : undefined,
                }}
              >
                {remainingAutomationPeriodString}
              </div>
            </Grid>
          )}
      </div>
      <div className={classes.divider} />
    </div>
  );
};

// export const UpcomingFanScheduleTable = withApollo(UpcomingFanScheduleTableBase)
