import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetUserDocument,
  GetUserQuery,
  UpdateUserSettingsMutationVariables as Variables,
  UserFragmentFragment as Result,
  withUpdateUserSettings as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUpdateUserSettingsHocChildProps = {
  updateUserSettings: (variables: Variables) => Promise<Result>;
};
export const withUpdateUserSettingsHoc: AmberHoc<{}, WithUpdateUserSettingsHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<{}, WithUpdateUserSettingsHocChildProps, typeof component>,
    WithUpdateUserSettingsHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updateUserSettings: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const { user_id } = variables;
          const result = await mutate({
            variables,
            update: (proxy, { data }) => {
              if (!data || !data.updateUser) {
                return;
              }

              proxy.writeQuery<GetUserQuery>({
                query: GetUserDocument,
                data: {
                  __typename: 'Query',
                  user: data.updateUser,
                },
                variables: { user_id },
              });
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.updateUser;
        },
      };
    },
  })(component as any);
