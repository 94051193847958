import { ApolloClient } from 'apollo-client';
import { delay } from 'redux-saga';
import { cancel, fork, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { pollBackgroundNotificationsUpdateState } from '../action';
import {
  GetUnreadNotificationsCountDocument,
  GetUnreadNotificationsCountQuery,
  GetViewerDocument,
  GetViewerQuery,
  GetViewerQueryVariables,
} from '../api';

let bg_notifications_update_poll_task: any;

function* pollForUpdatedNotificationsLoop(apollo_client: ApolloClient<any>, user_id: number) {
  try {
    while (true) {
      // poll every 10 sec
      yield delay(1000 * 10);

      // query for Unread Notifications Count
      const { data } = yield apollo_client.query<GetUnreadNotificationsCountQuery>({
        query: GetUnreadNotificationsCountDocument,
        variables: { user_id },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      // read Viewer from the cache
      const viewerData = yield apollo_client.readQuery({ query: GetViewerDocument });

      // update cache for Unread Notifications Count
      if (
        data.user.user_new_notifications_count !==
        viewerData.viewer.user.user_new_notifications_count
      ) {
        yield apollo_client.writeQuery<GetViewerQuery, GetViewerQueryVariables>({
          query: GetViewerDocument,
          data: {
            viewer: {
              ...viewerData.viewer,
              user: {
                ...viewerData.viewer.user,
                user_new_notifications_count: data.user.user_new_notifications_count,
              },
            },
            __typename: viewerData.__typename,
          },
          variables: { user_id },
        });
      }
    }
  } finally {
    // console.log('pollForUpdatedAccountLoop ended');
  }
}

function* pollBackgroundUpdatedNotificationsSaga(
  apollo_client: ApolloClient<any>,
  action: ReturnType<typeof pollBackgroundNotificationsUpdateState>
) {
  if (!action.payload) return;

  try {
    const {
      payload: { user_id },
    } = action;

    // cancel existing poll if there is one
    if (bg_notifications_update_poll_task) {
      yield cancel(bg_notifications_update_poll_task);
      bg_notifications_update_poll_task = null;
    }

    if (user_id) {
      bg_notifications_update_poll_task = yield fork(
        pollForUpdatedNotificationsLoop,
        apollo_client,
        user_id
      );
    }
  } catch (e) {
    console.error(e);
  }
}

export function* backgroundNotificationUpdatePollWatcher(apollo_client: ApolloClient<any>) {
  yield takeEvery(
    getType(pollBackgroundNotificationsUpdateState),
    pollBackgroundUpdatedNotificationsSaga,
    apollo_client
  );
}
