import { ApolloLink } from 'apollo-link';
import {
  getHasUserSelectedCelsiusUnit,
  getHasUserSelectedKgPerHectoliterUnit,
  getHasUserSelectedKilogramUnit,
  getHasUserSelectedMeterUnit,
  UnitsConverter,
} from '../../../../core/src/util';

const parseLocaleDistanceConversions = (variables) => {
  const fields = {};
  const variablesKeys = Object.keys(variables);
  if (variablesKeys.includes('height_ft') && variables['height_ft'] !== null) {
    fields['height_ft'] = UnitsConverter.convertMetersToFeet(variables.height_ft);
  }

  if (variablesKeys.includes('diameter_ft') && variables['diameter_ft'] !== null) {
    fields['diameter_ft'] = UnitsConverter.convertMetersToFeet(variables.diameter_ft);
  }

  if (variablesKeys.includes('diameter') && variables['diameter'] !== null) {
    fields['diameter'] = UnitsConverter.convertCentimetersToInches(variables.diameter);
  }

  if (variablesKeys.includes('hub_offset_ft') && variables['hub_offset_ft'] !== null) {
    fields['hub_offset_ft'] = UnitsConverter.convertMetersToFeet(variables.hub_offset_ft);
  }

  if (
    variablesKeys.includes('hubs') &&
    variables['hubs'] !== null &&
    variables['hubs'] &&
    variables.hubs.length > 0
  ) {
    fields['hubs'] = variables.hubs.map((hub) => {
      const hubKeys = Object.keys(hub);
      return {
        ...hub,
        ...(hubKeys.includes('hub_offset_ft') && {
          hub_offset_ft: UnitsConverter.convertMetersToFeet(hub.hub_offset_ft),
        }),
      };
    });
  }

  return fields;
};

const parseLocaleTemperatureConversions = (variables) => {
  const fields = {};
  const variablesKeys = Object.keys(variables);

  if (variablesKeys.includes('current_grain_temp') && variables['current_grain_temp'] !== null) {
    fields['current_grain_temp'] = UnitsConverter.convertCelsiusToFahrenheit(
      variables.current_grain_temp
    );
  }

  return fields;
};

const parseLocaleKilogramMassConversions = (variables) => {
  const fields = {};
  const variablesKeys = Object.keys(variables);

  if (variablesKeys.includes('weight_in_lbs') && variables['weight_in_lbs'] !== null) {
    fields['weight_in_lbs'] = UnitsConverter.convertKilogramToPound(variables.weight_in_lbs);
  }
  return fields;
};

const parseLocaleKgPerHectoliterUnitConversions = (variables) => {
  const fields = {};
  const variablesKeys = Object.keys(variables);

  if (variablesKeys.includes('test_weight_in_lbs') && variables['test_weight_in_lbs'] !== null) {
    fields['test_weight_in_lbs'] = UnitsConverter.convertKilogramPerHectoliterToPoundPerBu(
      variables.test_weight_in_lbs
    );
  }
  return fields;
};

const parseUnitsConversions = (variables) => {
  let fields = {};
  if (!variables) return fields;

  const hasUserSelectedMeterUnit = getHasUserSelectedMeterUnit();
  const hasUserSelectedCelsiusUnit = getHasUserSelectedCelsiusUnit();
  const hasUserSelectedKilogramUnit = getHasUserSelectedKilogramUnit();
  const hasUserSelectedKgPerHectoliterUnit = getHasUserSelectedKgPerHectoliterUnit();

  if (hasUserSelectedMeterUnit) {
    fields = { ...fields, ...parseLocaleDistanceConversions(variables) };
  }

  if (hasUserSelectedCelsiusUnit) {
    fields = { ...fields, ...parseLocaleTemperatureConversions(variables) };
  }

  if (hasUserSelectedKilogramUnit) {
    fields = { ...fields, ...parseLocaleKilogramMassConversions(variables) };
  }

  if (hasUserSelectedKgPerHectoliterUnit) {
    fields = { ...fields, ...parseLocaleKgPerHectoliterUnitConversions(variables) };
  }

  console.log('fields', fields);
  return fields;
};

class VariablesLink extends ApolloLink {
  request(operation, forward) {
    operation.variables = {
      ...operation.variables,
      ...parseUnitsConversions(operation.variables),
    };
    return forward(operation);
  }
}

export { VariablesLink };
