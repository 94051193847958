import { Checkbox, IconButton, Menu, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ConfirmationDialog } from '../../../../../../core/src/component/util';
import {
  DarkCheckBoxIcon,
  GearIcon,
  HideIcon,
  IntermidiateCheckboxIcon,
} from '../../../../../../core/src/media';
import { black_shade_8, light_gray_7 } from '../../../../../../core/src/style';
import { PrefJsonItem } from '../ManageSitesTable';
import {
  UNASSIGNED_BINS_SITE_ID,
  UNASSIGNED_SITE_DISPLAY_NAME,
  UNASSIGNED_SITE_NAME,
} from '../ManageSitesView';
import { ToggleEmptyExpandCollapseBtn } from './ToggleEmptyExpandCollapseBtn';

type GrainSiteProps = {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleGrainSiteMenuClose: () => void;
  renameGrainSite: () => void;
  deleteGrainSite: () => void;
  siteName: string;
};

interface IGrainSiteCellRendererProps {
  renameGrainSite: (siteId: number, siteName: string) => void;
  deleteGrainSite: (siteId: number) => void;
  updateRowVisibilityInBulk: (gridapi: any, binIds: number[], isHide: boolean) => Promise<void>;
  updateOperationsViewRowVisibilityPrefs: (newSiteRows: PrefJsonItem[]) => Promise<void>;
  value: any;
  node: any;
  data: any;
  api: any;
  rowIndex: number;
  binsVisibilityPrefs: any;
}

const CHECKBOX_STATES = {
  HideAll: 'HideAll',
  Indeterminate: 'Indeterminate',
  UnHideAll: 'UnHideAll',
};

const getCheckboxTriState = (visibilityList: boolean[]): string => {
  const checkboxTriState =
    visibilityList.length === 0
      ? CHECKBOX_STATES.UnHideAll
      : visibilityList.every((it) => it === true)
      ? CHECKBOX_STATES.UnHideAll
      : visibilityList.every((it) => it === false)
      ? CHECKBOX_STATES.HideAll
      : CHECKBOX_STATES.Indeterminate;

  return checkboxTriState;
};
const getCheckBoxState = (checkboxTriState: string) => {
  return checkboxTriState === CHECKBOX_STATES.UnHideAll ? false : true;
};

export const GrainSiteCellRenderer = ({
  renameGrainSite,
  deleteGrainSite,
  updateRowVisibilityInBulk,
  updateOperationsViewRowVisibilityPrefs,
  binsVisibilityPrefs,
  ...props
}: IGrainSiteCellRendererProps) => {
  const { value, data } = props;
  console.log('props in GrainSiteCellRenderer', props);
  if (!data) return null;
  const siteId = data.site_id;
  const siteName = data.siteHierarchy[0];
  const binsCount = props.node.childrenAfterFilter.length;
  const childBinsIds =
    props.node.childrenAfterFilter.length > 0
      ? props.node.childrenAfterFilter
          .map((child) => child && child.data && child.data.bin_id)
          .filter((it) => it)
      : [];
  const hasNoChildrenBins = Boolean(childBinsIds.length === 0);
  console.log('childBinsIds', childBinsIds);
  const binsVisibilityState = props.api.binsVisibilityState;
  console.log('binsVisibilityState', binsVisibilityState);
  const childrenBinsVisibilityState =
    binsCount > 0
      ? props.node.childrenAfterFilter.map((child) => {
          return binsVisibilityState && child && child.data
            ? binsVisibilityState[child.data.bin_id]
            : true;
        })
      : [];
  console.log('childrenBinsVisibilityState', { siteName, childrenBinsVisibilityState });
  const checkboxTriState = getCheckboxTriState(childrenBinsVisibilityState);
  console.log('checkboxTriState', { siteName, checkboxTriState });
  const [hideAll, setHideAll] = useState(getCheckBoxState(checkboxTriState));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // modal
  const open = Boolean(anchorEl);
  const handleGrainSiteSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleGrainSiteMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleSiteBinsVisibility = async ({ target: { checked } }) => {
    if (binsCount === 0) {
      return;
    }
    setHideAll(checked);
    if (checked) {
      // change child bins state to HideAll
      childBinsIds.forEach((binId) => {
        props.api['binsVisibilityState'][binId] = false;
      });
    } else {
      // change child bins state to UnHideAll
      childBinsIds.forEach((binId) => {
        props.api['binsVisibilityState'][binId] = true;
      });
    }
    props.api.refreshCells({ force: true });
    if (updateRowVisibilityInBulk) {
      await updateRowVisibilityInBulk(props.api, childBinsIds, checked);
    }
  };

  const editCell = () => {
    const rowIndex = props.node.rowIndex;
    console.log('rowIndex in editCell', { rowIndex });
    props.api.setFocusedCell(rowIndex, 'ag-Grid-AutoColumn');
    props.api.startEditingCell({
      rowIndex,
      colKey: 'ag-Grid-AutoColumn',
    });
  };

  const deleteSite = async (siteId: number) => {
    const allRowData: any[] = [];
    props.api.getModel().rowsToDisplay.forEach((node) => {
      if (node.data) allRowData.push(node.data);
    });
    const selectedSite = allRowData.find((it) => it.site_id === siteId);
    console.log('allRowData', { allRowData, selectedSite });
    // move selected site bins to Unassigned Site
    const selectedSiteBinsAfterUpdatingSiteInfo: any[] = [];
    allRowData.forEach((row) => {
      if (row.site_id === siteId && row.siteHierarchy.length !== 1) {
        selectedSiteBinsAfterUpdatingSiteInfo.push({
          ...row,
          site_id: UNASSIGNED_BINS_SITE_ID,
          siteHierarchy: [UNASSIGNED_SITE_NAME, row.siteHierarchy[1]],
        });
      }
    });
    console.log('selectedSiteBinsAfterUpdatingSiteInfo', selectedSiteBinsAfterUpdatingSiteInfo);
    selectedSiteBinsAfterUpdatingSiteInfo.length > 0 &&
      props.api.applyTransaction({ update: [...selectedSiteBinsAfterUpdatingSiteInfo] });

    // remove site row from the table
    // TODO:  props.api.applyTransaction is not working immediately after creating a new site
    props.api.applyTransaction({ remove: [{ id: selectedSite.id }] });

    // update operations view visibility prefs by removing deleted site
    const siteRows: PrefJsonItem[] = [];
    props.api.getModel().rowsToDisplay.forEach((rowNode, index) => {
      const rowData = rowNode.data;
      // only sites
      if (rowData && rowData.siteHierarchy.length === 1) {
        const childBins = rowNode.childrenAfterFilter.map((node) => {
          const prevBinVisibilityPref =
            binsVisibilityPrefs.length > 0
              ? binsVisibilityPrefs.find(({ bin_id }) => bin_id === node.data.bin_id)
              : null;
          return {
            bin_id: node.data.bin_id,
            hide: prevBinVisibilityPref ? prevBinVisibilityPref.hide : false,
          };
        });
        siteRows.push({
          site_id: rowData.site_id,
          bins: childBins,
        });
      }
    });

    // enforce UNASSIGNED_BINS site to reside on the last index always
    const newSiteRows: PrefJsonItem[] = [
      ...siteRows.filter((sitePref) => sitePref.site_id !== UNASSIGNED_BINS_SITE_ID),
      ...siteRows.filter((sitePref) => sitePref.site_id === UNASSIGNED_BINS_SITE_ID),
    ];

    console.log('siteRows after deleteSite', newSiteRows);

    await Promise.all([
      deleteGrainSite(siteId),
      updateOperationsViewRowVisibilityPrefs(newSiteRows),
    ]);
  };

  // update checkbox state after adding or removing new bins to site
  useEffect(() => {
    console.log('binsCount', binsCount);
    const childrenBinsVisibilityState = binsCount
      ? props.node.childrenAfterFilter.map((child) =>
          binsVisibilityState && child && child.data ? binsVisibilityState[child.data.bin_id] : true
        )
      : [];
    const checkboxTriState = getCheckboxTriState(childrenBinsVisibilityState);
    setHideAll(getCheckBoxState(checkboxTriState));
  }, [binsCount]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        {hasNoChildrenBins && <ToggleEmptyExpandCollapseBtn />}
        <Checkbox
          checked={hideAll}
          indeterminate={checkboxTriState === CHECKBOX_STATES.Indeterminate}
          icon={<DarkCheckBoxIcon />}
          checkedIcon={<HideIcon />}
          indeterminateIcon={<IntermidiateCheckboxIcon />}
          onChange={toggleSiteBinsVisibility}
          style={{ marginRight: 10 }}
        />

        <div
          style={{
            maxWidth: 171,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: 16,
            color: black_shade_8,
            fontWeight: 600,
          }}
        >
          {value === UNASSIGNED_SITE_NAME ? UNASSIGNED_SITE_DISPLAY_NAME : value}
        </div>
      </div>

      {siteId !== UNASSIGNED_BINS_SITE_ID && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: 41,
            justifyContent: 'end',
          }}
        >
          <IconButton
            onClick={handleGrainSiteSettingsClick}
            aria-haspopup="true"
            aria-controls={open ? 'grain-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="more"
            style={{ padding: 5, width: 30, height: 30 }}
          >
            <GearIcon />
          </IconButton>
          <GrainSiteMenu
            anchorEl={anchorEl}
            open={open}
            siteName={siteName}
            handleGrainSiteMenuClose={handleGrainSiteMenuClose}
            renameGrainSite={editCell}
            deleteGrainSite={() => deleteSite(siteId)}
          />
        </div>
      )}
    </div>
  );
};

const GrainSiteMenu = ({
  anchorEl,
  open,
  siteName,
  renameGrainSite,
  deleteGrainSite,
  handleGrainSiteMenuClose,
}: GrainSiteProps) => {
  const menuItemStyles = { minHeight: 22 };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteConfirmationMessage = (
    <>
      Are you sure you want to delete <strong>{siteName}</strong> site?
    </>
  );
  return (
    <>
      <Menu
        id="grain-site-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleGrainSiteMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          style: {
            border: `1px solid ${light_gray_7}`,
            marginLeft: 10,
          },
        }}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <MenuItem style={menuItemStyles} onClick={renameGrainSite}>
          Rename Site
        </MenuItem>
        <MenuItem style={menuItemStyles} onClick={() => setShowDeleteModal(true)}>
          Delete Site
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={showDeleteModal}
        confirmationMessage={deleteConfirmationMessage}
        onConfirmClick={deleteGrainSite}
        confirmBtbText="Delete"
        onClickClose={() => setShowDeleteModal(false)}
      />
    </>
  );
};
