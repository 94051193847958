// take an array of windows and collapse to non-overlapping set
export const collapseWindows = (windows: { start_epoch: Date; end_epoch: Date }[]) => {
  if (!windows || windows.length === 0) {
    return [];
  }
  // sort the windows by start date (earliest first)
  const sorted_windows = windows.sort(
    (a, b) => new Date(a.start_epoch).getTime() - new Date(b.start_epoch).getTime()
  );

  // iterate through dates tracking start and end
  let span_start = sorted_windows[0].start_epoch;
  let span_end = sorted_windows[0].end_epoch;
  const collapsed_windows: { start_epoch: Date; end_epoch: Date }[] = [];
  for (let i = 1; i < sorted_windows.length; i += 1) {
    // if this start is after tracked end add tracked dates to collapsed
    // otherwise set end to whichever end is later
    if (new Date(sorted_windows[i].start_epoch).getTime() > new Date(span_end).getTime()) {
      collapsed_windows.push({ start_epoch: span_start, end_epoch: span_end });
      span_start = sorted_windows[i].start_epoch;
      span_end = sorted_windows[i].end_epoch;
    } else if (new Date(sorted_windows[i].end_epoch).getTime() > new Date(span_end).getTime()) {
      span_end = new Date(sorted_windows[i].end_epoch);
    }
  }
  // push leftover
  collapsed_windows.push({ start_epoch: span_start, end_epoch: span_end });
  return collapsed_windows;
};
