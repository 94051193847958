import { Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { memo } from 'react';

import { amber_dark_grey } from '../../style';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    minWidth: 300,
    maxWidth: 500,
    margin: 20,
    width: '100%',
  },
  typography: {
    color: amber_dark_grey,
    fontSize: 20,
    width: 'fit-content',
  },
});

// Base dialog for user-facing applciations with built in title formatting, width,  and more intuitive close button
export const BaseDialog: React.FunctionComponent<{
  open: boolean;
  title: any;
  children: React.ReactNode;
  handleClose: () => void;
  showCloseButton?: boolean;
}> = memo(({ open, title, children, handleClose, showCloseButton = true, ...props }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      fullWidth
      {...props}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h5" className={classes.typography}>
          {title}
        </Typography>
        {showCloseButton && (
          <IconButton onClick={handleClose}>
            <Cancel />
          </IconButton>
        )}
      </DialogTitle>
      {children}
    </Dialog>
  );
});
