import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import { green, yellow } from '@material-ui/core/colors';
import { TouchApp, ToysOutlined } from '@material-ui/icons';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React from 'react';
import { GrainType } from '../../api/graphql';
import { amber_grey, white } from '../../style';
import { formatGrainType, isNoEnd } from '../../util';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: { marginRight: 10 },
  text: { color: white },
  light: {
    fontWeight: 300,
  },
});

export const HistoricalStoragePeriodBanner = ({
  name,
  grain_type,
  start_date,
  end_date,
}: {
  name: string;
  grain_type: string;
  start_date: Date;
  end_date: Date;
}) => {
  const background_color = amber_grey;
  const classes = useStyles();
  const getFormatedDateString = (date) =>
    DateTime.fromMillis(date.getTime()).toFormat('MM/dd/yyyy');
  const start_date_str = getFormatedDateString(start_date);
  const end_date_str = getFormatedDateString(end_date);
  return (
    <Grid item xs={12}>
      <Card raised style={{ backgroundColor: background_color }}>
        <CardHeader
          title={
            <div className={classes.title}>
              <Typography variant="h6" className={classes.text}>
                Historical Storage Period : {name} || {formatGrainType(grain_type as GrainType)} ||{' '}
                {start_date_str} - {end_date_str}
              </Typography>
            </div>
          }
        />
      </Card>
    </Grid>
  );
};
