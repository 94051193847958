import * as React from 'react';
export const SmallRightArrowIcon = (props) => (
  <svg
    width={17}
    height={10}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    overflow="hidden"
    {...props}
  >
    <defs>
      <clipPath id="sa_clip0">
        <rect x={853} y={340} width={17} height={10} />
      </clipPath>
    </defs>
    <g clipPath="url(#sa_clip0)" transform="translate(-853 -340)">
      <path
        d="M9.57972 0 9.57972 13.4116"
        stroke="#7C7C7C"
        strokeWidth={0.786028}
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
        transform="matrix(-1.83697e-16 -1 1.0529 -1.93414e-16 846.398 348.219)"
      />
      <path
        d="M5.74783 3.83189 9.57972 0"
        stroke="#7C7C7C"
        strokeWidth={0.786028}
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
        transform="matrix(-1.83697e-16 -1 1.0529 -1.93414e-16 846.398 348.219)"
      />
      <path
        d="M9.57972 0 13.4116 3.83189"
        stroke="#7C7C7C"
        strokeWidth={0.786028}
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
        transform="matrix(-1.83697e-16 -1 1.0529 -1.93414e-16 846.398 348.219)"
      />
      <path
        d="M3.83189 21.0754 3.83189 7.66377"
        stroke="#7C7C7C"
        strokeWidth={0.786028}
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
        transform="matrix(-1.83697e-16 -1 1.0529 -1.93414e-16 846.398 348.219)"
      />
      <path
        d="M7.66377 17.2435 3.83189 21.0754"
        stroke="#7C7C7C"
        strokeWidth={0.786028}
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
        transform="matrix(-1.83697e-16 -1 1.0529 -1.93414e-16 846.398 348.219)"
      />
      <path
        d="M3.83189 21.0754 0 17.2435"
        stroke="#7C7C7C"
        strokeWidth={0.786028}
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
        transform="matrix(-1.83697e-16 -1 1.0529 -1.93414e-16 846.398 348.219)"
      />
      <text
        fontFamily="Helvetica,Helvetica_MSFontService,sans-serif"
        fontWeight={700}
        fontSize={5}
        transform="matrix(-1.75977e-16 -0.957972 1.00864 -1.85285e-16 884.726 349)"
      >
        {'Created by Smashicons'}
      </text>
      <text
        fontFamily="Helvetica,Helvetica_MSFontService,sans-serif"
        fontWeight={700}
        fontSize={5}
        transform="matrix(-1.75977e-16 -0.957972 1.00864 -1.85285e-16 889.769 349)"
      >
        {'from the Noun Project'}
      </text>
    </g>
  </svg>
);
