import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { formatError } from '../../../util';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  message: { color: red['500'] },
});

export const ErrorBox: React.FunctionComponent<{ error? }> = ({ error = null }) => {
  const classes = useStyles();
  if (!error) {
    return null;
  }
  const messages = formatError(error);
  if (messages.length === 0) {
    return null;
  }
  return (
    <div className={classes.root}>
      {messages.map((message, ix) => (
        <div key={ix}>
          <span style={{ color: red['500'] }}>{message}</span>
        </div>
      ))}
    </div>
  );
};
