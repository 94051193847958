import { AmberHoc, AmberHocProps } from '../../../util';
import {
  UpdateGrainBinTicketMutation as Result,
  UpdateGrainBinTicketMutationVariables as Variables,
  withUpdateGrainBinTicket as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUpdateGrainBinTicketHocChildProps = {
  updateGrainBinTicket: (variables: Variables) => Promise<Result['updateGrainBinTicket']>;
};

export const withUpdateGrainBinTicketHoc: AmberHoc<{}, WithUpdateGrainBinTicketHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<{}, WithUpdateGrainBinTicketHocChildProps, typeof component>,
    WithUpdateGrainBinTicketHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updateGrainBinTicket: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }

          const result = await mutate({ variables });

          if (!result) {
            throw new Error('Unexpected server response');
          }

          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.updateGrainBinTicket;
        },
      };
    },
  })(component as any);
