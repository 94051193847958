import { DateTime } from 'luxon';
import React from 'react';
import { withSkeleton } from './withCellSkeleton';

export const getFormattedDurationText = ({
  days,
  hours,
  minutes,
}: {
  days: number;
  hours: number;
  minutes: number;
}) => {
  if (days === 0 && hours === 0) return `${Math.floor(minutes)}m`;
  if (days === 0) return `${hours}h ${Math.floor(minutes)}m`;
  const oneYearDays = 365;
  const isForever = Boolean(days && days >= oneYearDays);
  return isForever ? 'Forever' : `${days}d ${hours}h ${Math.floor(minutes)}m`;
};

const FanScheduleDurationCellRenderer = withSkeleton((props) => {
  const aerationSchedule = props.value;
  const hasFanFailToStartError = Boolean(props.data.has_fan_start_fail_error);
  const hasFanFailToStopError = Boolean(props.data.has_fan_stop_fail_error);
  const hasNoSchedule = aerationSchedule.length === 0;
  const hasFanStartStopError = hasFanFailToStartError || hasFanFailToStopError;
  const hasScheduledRunInterrupted = props.data.has_scheduled_run_interrupted;
  const showOverlay = Boolean(
    hasFanStartStopError || (hasScheduledRunInterrupted && !hasFanStartStopError)
  );

  if (hasNoSchedule) {
    return <div style={{ opacity: showOverlay ? 0.3 : undefined }}>No Schedule</div>;
  }

  const nextSchedule = aerationSchedule[0];
  const start = DateTime.fromMillis(new Date(nextSchedule.start_epoch).getTime());
  const end = DateTime.fromMillis(new Date(nextSchedule.end_epoch).getTime());

  const diff = end.diff(start, ['days', 'hours', 'minutes']).toObject();

  return (
    <div style={{ opacity: showOverlay ? 0.3 : undefined }}>{getFormattedDurationText(diff)}</div>
  );
});

export { FanScheduleDurationCellRenderer };
