import React from 'react';
import { HubType } from '../api';
import { ContainerTypeLegacy } from '../util/constant';

export type HubWithColorCode = {
  order: number;
  color: string;
  hub_container_link_id: number;
  hub_id: number;
  alias?: string | null;
  core_id: string;
  container_id: number;
  container_type: ContainerTypeLegacy;
  start_epoch: Date;
  end_epoch: Date;
  nickname: string;
  hub_type: HubType;
  has_level_sensor: boolean;
  hub_offset_ft: number;
};

type TContext = HubWithColorCode[] | null | undefined;

export const ActiveHubsWithColorContext = React.createContext({
  activeHubsWithColor: undefined as TContext,
  setActiveHubsWithColor: (value: TContext) => {},
});

export const ActiveHubsWithColor = ({ children }: React.PropsWithChildren<{}>) => {
  const initialValue = undefined;
  const [context, setContext] = React.useState<TContext>(initialValue);
  const value = {
    activeHubsWithColor: context,
    setActiveHubsWithColor: setContext,
  };

  return (
    <ActiveHubsWithColorContext.Provider value={value}>
      {children}
    </ActiveHubsWithColorContext.Provider>
  );
};
