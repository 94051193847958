import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { makeStyles, withStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { getMassUnitLabel } from '../../../../../core/src/util';
import { ActiveStoragePeriodContext } from '../../../../src/contexts';
import { GrainBinTicket, GrainTicketActionType, GrainType, UserRole } from '../../../api';
import { amber_medium_grey } from '../../../style';
import { getUserPermissionTooltipText } from '../aeration';
import { CreateOrUpdateGrainTicketForm } from './CreateOrUpdateGrainTicketForm';

const useStyles = makeStyles({
  header: {
    padding: '5px 8px',
  },
  headerText: {
    fontWeight: 600,
    padding: '5px',
    wordBreak: 'break-word',
  },
  row: {
    backgroundColor: 'transparent',
  },
  rowText: {
    padding: '5px',
    textAlign: 'start',
    fontSize: 14,
    wordBreak: 'break-word',
  },
});

export const NoAnimationAccordionSummary = withStyles({
  root: {
    minHeight: 56,
    padding: '0px 8px',
    '&$expanded': {
      minHeight: 56,
      borderTop: '1px solid grey',
      borderLeft: '1px solid grey',
      borderRight: '1px solid grey',
    },
  },
  expanded: {
    backgroundColor: amber_medium_grey,
    color: 'white',
  },
})(AccordionSummary);

const GrainTicketsAccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    borderBottom: '1px solid grey',
    borderLeft: '1px solid grey',
    borderRight: '1px solid grey',
  },
}))(AccordionDetails);

const GrainTicketsTableHeader = () => {
  const classes = useStyles();
  const massUnitLabel = getMassUnitLabel();
  return (
    <Grid container wrap="nowrap" className={classes.header}>
      <Grid item xs={3} className={classes.headerText}>
        Date
      </Grid>
      <Grid item xs={3} className={classes.headerText} style={{ textAlign: 'center' }}>
        Volume <br />
        (bu)
      </Grid>
      <Grid item xs={3} className={classes.headerText} style={{ textAlign: 'center' }}>
        Weight
        <br />({massUnitLabel})
      </Grid>
      <Grid item xs={2} className={classes.headerText} style={{ textAlign: 'center' }}>
        Grain MC
        <br />
        (%)
      </Grid>
      <Grid item xs={3} className={classes.headerText} style={{ textAlign: 'center' }}>
        Notes
      </Grid>
      <Grid item xs={1} className={classes.headerText} />
    </Grid>
  );
};

type GrainBinTicketsTableProps = {
  grain_bin_id: number;
  grain_type?: GrainType;
  grain_bin_tickets: GrainBinTicket[];
  expanded: number | null;
  handleExpandChange: any;
  refetchGrainBin: () => void;
  refetchGrainBinTickets: () => void;
  setUpdating: (shouldUpdate: boolean) => void;
  hasUserAccessRestricted: boolean;
  viewer_role: UserRole;
};

export const getActionColorAndSign = (action_performed: GrainTicketActionType) =>
  ({
    [GrainTicketActionType.Added]: { color: 'green', sign: '+' },
    [GrainTicketActionType.Removed]: { color: 'red', sign: '-' },
  }[action_performed]);

export const GrainBinTicketsTable = ({
  grain_bin_id,
  grain_bin_tickets,
  expanded,
  handleExpandChange,
  refetchGrainBin,
  refetchGrainBinTickets,
  setUpdating,
  grain_type,
  hasUserAccessRestricted,
  viewer_role,
}: GrainBinTicketsTableProps) => {
  console.log('grain_bin_tickets: ', grain_bin_tickets);
  const classes = useStyles();

  const activeStoragePeriodContext = useContext(ActiveStoragePeriodContext);
  const activeStoragePeriod = activeStoragePeriodContext.activeStoragePeriod;

  const sortedGrainBinTickets = grain_bin_tickets.sort(
    (a, b) => new Date(b.epoch_time).getTime() - new Date(a.epoch_time).getTime()
  );

  const getFormatedDateString = (date) =>
    DateTime.fromMillis(date.getTime()).toFormat('MM/dd/yyyy');
  const start_date_str = (start_date) => getFormatedDateString(start_date);
  const end_date_str = (end_date) =>
    end_date === null ? 'Ongoing' : getFormatedDateString(end_date);
  const disabledTooltipText = hasUserAccessRestricted
    ? getUserPermissionTooltipText(viewer_role)
    : '';

  if (sortedGrainBinTickets && sortedGrainBinTickets.length === 0) {
    return (
      <div
        style={{
          height: '100%',
          minHeight: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="h6">
          {activeStoragePeriod ? (
            <span>
              No Grain Bin Tickets added for Storage Period
              <br />
              <strong>{activeStoragePeriod.storage_cycle_name}</strong>
              <br />
              <strong>{`${start_date_str(activeStoragePeriod.start_date)} - ${end_date_str(
                activeStoragePeriod.end_date
              )}`}</strong>
            </span>
          ) : (
            `No Grain Bin Tickets Added`
          )}
        </Typography>
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <GrainTicketsTableHeader />
      {sortedGrainBinTickets &&
        sortedGrainBinTickets.map(
          (
            {
              epoch_time,
              action_performed,
              volume,
              test_weight_in_lbs,
              weight_in_lbs,
              grain_moisture_pct,
              grain_bin_ticket_id,
              notes,
              grain_surface_shape,
            },
            index
          ) => {
            const { color, sign } = getActionColorAndSign(action_performed);
            return (
              <Accordion
                className={classes.row}
                expanded={hasUserAccessRestricted ? false : expanded === index}
                onChange={handleExpandChange(index)}
                key={grain_bin_ticket_id}
              >
                <NoAnimationAccordionSummary>
                  <Grid style={{ alignItems: 'center' }} container wrap="nowrap">
                    <Grid item xs={3} className={classes.rowText}>
                      {DateTime.fromMillis(epoch_time.getTime()).toFormat('LLL d yyyy, t')}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className={classes.rowText}
                      style={{ color, fontWeight: 500, textAlign: 'center' }}
                    >
                      {sign}
                      {Math.round(volume)}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className={classes.rowText}
                      style={{ color, fontWeight: 500, textAlign: 'center' }}
                    >
                      {weight_in_lbs ? (
                        <>
                          {sign}
                          {Math.round(weight_in_lbs)}
                        </>
                      ) : (
                        <>-</>
                      )}
                    </Grid>
                    <Grid item xs={2} className={classes.rowText} style={{ textAlign: 'center' }}>
                      {grain_moisture_pct.toFixed(1)}%
                    </Grid>
                    <Grid item xs={3} className={classes.rowText} style={{ textAlign: 'center' }}>
                      {notes ? notes : '-'}
                    </Grid>
                    <Grid item xs={1} className={classes.rowText} style={{ textAlign: 'end' }}>
                      <Tooltip
                        enterTouchDelay={0}
                        disableFocusListener
                        placement="right"
                        title={
                          disabledTooltipText ? (
                            <span style={{ fontSize: 13 }}>{disabledTooltipText}</span>
                          ) : (
                            ''
                          )
                        }
                        arrow
                      >
                        <div>
                          <BorderColorIcon
                            style={{ color: hasUserAccessRestricted ? 'lightgray' : undefined }}
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </NoAnimationAccordionSummary>
                <GrainTicketsAccordionDetails>
                  {expanded === index && (
                    <CreateOrUpdateGrainTicketForm
                      grain_bin_ticket_id={grain_bin_ticket_id}
                      grain_bin_ticket_epoch_time={epoch_time}
                      action_performed={action_performed}
                      volume={parseFloat(volume.toFixed(2))}
                      test_weight_in_lbs={
                        test_weight_in_lbs ? parseFloat(test_weight_in_lbs.toFixed(2)) : undefined
                      }
                      weight_in_lbs={
                        weight_in_lbs ? parseFloat(weight_in_lbs.toFixed(2)) : undefined
                      }
                      grain_moisture_pct={grain_moisture_pct}
                      notes={notes ? notes : ''}
                      grain_surface_shape={grain_surface_shape}
                      grain_bin_id={grain_bin_id}
                      setUpdating={setUpdating}
                      refetchGrainBin={refetchGrainBin}
                      refetchGrainBinTickets={refetchGrainBinTickets}
                      handleCloseForm={handleExpandChange(null)}
                      grain_type={grain_type}
                    />
                  )}
                </GrainTicketsAccordionDetails>
              </Accordion>
            );
          }
        )}
    </div>
  );
};
