import { Grid, InputAdornment, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
import { GetProps } from 'react-redux';
import * as yup from 'yup';
import { SubmitButtonsGroup } from '../../../../../../core/src/component/util/form2/SubmitButtonsGroup';
import {
  FloorType,
  GrainBinFragmentFragment,
  GrainType,
  UpdateGrainBinMutationVariables,
  withUpdateGrainBinHoc,
  WithUpdateGrainBinHocChildProps,
} from '../../../../api';
import { UiFormError } from '../../../../util/format-error';

import { BaseForm, FormikWrapper, FormikWrapperProps } from '../../../util/form2/BaseForm';

import {
  GrainMoistureIcon,
  GrainTemperature,
  TargetMoistureIcon,
} from '../../../../../../core/src/media/icons';
import { amber_dark_grey } from '../../../../../../core/src/style';
import { getTemperatureUnitLabelWithDegreeSuffix } from '../../../../../../core/src/util';
import { ErrorBox } from '../../../util/form2/ErrorBox';
import { formatLatLng, yup_latlng } from '../../../util/form2/LocationPicker';
import { yup_floor_type } from '../../../util/form2/SelectFloorType';
import { yup_grain_type } from '../../../util/form2/SelectGrainType';
import { GrainBinForm } from '../../GrainBinForm';
import { GrainConditionInput } from '../../GrainConditionInput';
import { SelectRecommendationType } from '../../SelectRecommendationType';
import {
  createNavigationTypeFromOptionString,
  FanControlNavigationOption,
} from './../../FanControlNavigationOption';
import { RecommendedOption } from './../../RecommendationOptionValue';
import RecommSchedulingInfo from './../../RecommSchedulingInfo';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    minWidth: 250,
    maxWidth: 500,
    color: amber_dark_grey,
  },
  centered: {
    textAlign: 'center',
  },
  location: {
    height: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  grainInputCardContainer: {
    width: '100%',
    display: 'flex',
    // direct child
    '& > :not(:last-child)': {
      marginRight: 14,
      [theme.breakpoints.only('xs')]: {
        marginRight: 12,
      },
    },
  },
  grainConditionsHeading: {
    fontSize: 20,
    lineHeight: '28px',
  },
}));

type Values = {
  bin_name: string;
  grain_type: GrainType | null;
  height_ft: number | null;
  diameter_ft: number | null;
  location: {
    latlng_str: string;
    latlng: { lat: number; lng: number } | null;
  };
  target_grain_emc: number | null;
  recommendation_type: string | null;
  current_grain_emc: number | null;
  current_grain_temp: number | null;
  has_grain_spreader: boolean | null;
  floor_type: FloorType;
};
const validationSchema = yup.object().shape({
  bin_name: yup
    .string()
    .label('Bin Name')
    .required(),
  grain_type: yup_grain_type
    .label('Grain Type')
    .required()
    .nullable(false),
  height_ft: yup
    .number()
    .typeError('A number is required')
    .label('Eave Height')
    .required()
    .positive()
    .max(200),
  diameter_ft: yup
    .number()
    .typeError('A number is required')
    .label('Bin Diameter')
    .required()
    .positive()
    .max(200),
  location: yup_latlng.label('Location'),
  has_grain_spreader: yup.boolean(),
  floor_type: yup_floor_type
    .label('Floor Type')
    .required()
    .nullable(false),
  target_grain_emc: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .label('Target Grain Moisture %')
    .positive()
    .min(0)
    .max(100),
  recommendation_type: yup.string().required(),
  current_grain_emc: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required()
    .label('Current Grain Moisture %')
    .positive()
    .min(0)
    .max(100),
  current_grain_temp: yup
    .number()
    .typeError('A number is required')
    .required()
    .transform((value) => (isNaN(value) ? undefined : value))
    .label('Current Grain Temp F'),
});

const asJSON = (grain_bin: GrainBinFragmentFragment | null) => {
  if (grain_bin === null) {
    return {
      bin_name: '',
      grain_type: null,
      height_ft: null,
      diameter_ft: null,
      location: {
        latlng: null,
        latlng_str: '',
      },
      target_grain_emc: null,
      current_grain_emc: null,
      current_grain_temp: null,
      has_grain_spreader: false,
      floor_type: FloorType.FullFloorAeration,
      recommendation_type: RecommendedOption.COOLING.toString(),
    };
  }

  return {
    bin_name: grain_bin.bin_name,
    grain_type: grain_bin.grain_type || null,
    height_ft: grain_bin.height_ft || null,
    diameter_ft: grain_bin.diameter_ft || null,
    location: {
      latlng: {
        lat: grain_bin.location.latitude,
        lng: grain_bin.location.longitude,
      },
      latlng_str: formatLatLng({
        lat: grain_bin.location.latitude,
        lng: grain_bin.location.longitude,
      }),
    },
    has_grain_spreader: grain_bin.has_grain_spreader || false,
    floor_type: grain_bin.floor_type || FloorType.FullFloorAeration,
    target_grain_emc: grain_bin.target_grain_emc || null,
    recommendation_type: grain_bin.recommendation_type || RecommendedOption.COOLING.toString(),
    current_grain_emc: grain_bin.current_grain_emc || null,
    current_grain_temp: grain_bin.current_grain_temp || null,
  };
};

const RecommScheduleGrainConditionInputFormBase = ({
  grain_bin,
  updateGrainBin,
  onCancel,
  setRecommendation,
  navigation,
  setNavigation,
  ...props
}: WithUpdateGrainBinHocChildProps &
  Pick<
    FormikWrapperProps<Values, GrainBinFragmentFragment>,
    'onSubmitSuccess' | 'onSubmitFailure'
  > & { grain_bin: GrainBinFragmentFragment } & { onCancel: () => void } & {
    navigation: FanControlNavigationOption;
    setNavigation: (navigation: FanControlNavigationOption) => void;
    setRecommendation: (navigation: FanControlNavigationOption) => void;
  }) => {
  const classes = useStyles();
  const initial_values = useMemo(() => asJSON(grain_bin!), [grain_bin]);

  return (
    <FormikWrapper<Values, { result: GrainBinFragmentFragment; recommendation_type: string }>
      {...props}
      onSubmitSuccess={(result) => {
        const newNav = createNavigationTypeFromOptionString(
          navigation.navigationType,
          result.recommendation_type
        );
        setNavigation(newNav);
      }}
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initial_values}
      onSubmit={async (values) => {
        const {
          current_grain_emc,
          current_grain_temp,
          target_grain_emc,
          recommendation_type,
          floor_type,
        } = validationSchema.validateSync(values);
        const variables: UpdateGrainBinMutationVariables = {
          current_grain_emc,
          current_grain_temp,
          target_grain_emc,
          recommendation_type,
          floor_type,
          grain_bin_id: grain_bin.grain_bin_id,
        };

        if (
          (recommendation_type === RecommendedOption.DRYING.toString() ||
            recommendation_type === RecommendedOption.RECONDITIONING.toString()) &&
          !target_grain_emc
        ) {
          throw new UiFormError('The Target Grain Moisture % is required');
        }

        if (
          recommendation_type === RecommendedOption.DRYING.toString() &&
          target_grain_emc &&
          current_grain_emc &&
          target_grain_emc >= current_grain_emc
        ) {
          throw new UiFormError(
            'In Drying Mode the Target Grain Moisture must be less than the Current' +
              ' Grain Moisture'
          );
        } else if (
          recommendation_type === RecommendedOption.RECONDITIONING.toString() &&
          target_grain_emc &&
          current_grain_emc &&
          current_grain_emc >= target_grain_emc
        ) {
          throw new UiFormError(
            'In Reconditioning Mode the Target Grain Moisture must be greater than the Current' +
              ' Grain Moisture'
          );
        }

        return {
          result: await updateGrainBin(variables),
          recommendation_type: recommendation_type.toString(),
        };
      }}
      render={({
        values: {
          location: { latlng },
          recommendation_type,
        },
        errors,
        touched,
      }) => {
        // for current_grain_emc
        const currentGrainEmcError = errors['current_grain_emc'];
        const touchedCurrentGrainEmcError = touched['current_grain_emc'];
        const HasCurrentGrainEmcError = Boolean(
          touchedCurrentGrainEmcError && currentGrainEmcError
        );
        // for current_grain_temp
        const currentGrainTempError = errors['current_grain_temp'];
        const touchedCurrentGrainTempError = touched['current_grain_temp'];
        const HasCurrentGrainTempError = Boolean(
          touchedCurrentGrainTempError && currentGrainTempError
        );
        // for target_grain_emc
        const targetGrainEmcError = errors['target_grain_emc'];
        const touchedTargetGrainEmcError = touched['target_grain_emc'];
        const HasTargetGrainEmcError = Boolean(touchedTargetGrainEmcError && targetGrainEmcError);

        const hasRecommedationTypeCooling =
          recommendation_type && recommendation_type.toString() === RecommendedOption.COOLING;
        const tempUnit = getTemperatureUnitLabelWithDegreeSuffix();

        return (
          <BaseForm submitting_message={'Saving changes...'} style={{ padding: 0 }}>
            <Grid
              container
              direction="row"
              alignContent="flex-start"
              alignItems="center"
              justify="center"
              spacing={2}
              className={classes.grid}
            >
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <Typography variant="h5" className={classes.grainConditionsHeading}>
                  Input Grain Conditions
                </Typography>
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <div className={classes.grainInputCardContainer}>
                  <GrainConditionInput
                    title="Current Moisture"
                    name="current_grain_emc"
                    GrainConditionIcon={GrainMoistureIcon}
                    hasError={HasCurrentGrainEmcError}
                    inputError={currentGrainEmcError}
                    containerStyles={{ width: hasRecommedationTypeCooling ? '50%' : undefined }}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  />
                  <GrainConditionInput
                    title="Current Temperature"
                    name="current_grain_temp"
                    GrainConditionIcon={GrainTemperature}
                    hasError={HasCurrentGrainTempError}
                    inputError={currentGrainTempError}
                    containerStyles={{ width: hasRecommedationTypeCooling ? '50%' : undefined }}
                    endAdornment={<InputAdornment position="end">{tempUnit}</InputAdornment>}
                  />
                  {recommendation_type &&
                    recommendation_type.toString() !== RecommendedOption.COOLING.toString() && (
                      <GrainConditionInput
                        title="Target Moisture"
                        name="target_grain_emc"
                        GrainConditionIcon={TargetMoistureIcon}
                        hasError={HasTargetGrainEmcError}
                        inputError={targetGrainEmcError}
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      />
                    )}
                </div>
              </Grid>
              {/* recommendation type input*/}
              <Grid item style={{ width: '100%' }}>
                <SelectRecommendationType
                  defaultRecommendationType={grain_bin.recommendation_type}
                  navigation={navigation}
                  setRecommendation={setRecommendation}
                />
              </Grid>
              <ErrorBox />
              <Grid item xs={12} style={{ marginTop: 16, marginBottom: 16 }}>
                <RecommSchedulingInfo />
              </Grid>
              <SubmitButtonsGroup
                onCancel={onCancel}
                cancelText="Cancel"
                submitButtonText="Generate Schedule"
              />
            </Grid>
          </BaseForm>
        );
      }}
    />
  );
};

const RecommScheduleGrainConditionInputForm = withUpdateGrainBinHoc(
  RecommScheduleGrainConditionInputFormBase
);

export const RecommScheduleAskGrainCondtionsForm = ({
  grain_bin,
  onCancel,
  navigation,
  setNavigation,
  ...props
}: Omit<GetProps<typeof GrainBinForm>, 'account_id'> & { grain_bin: GrainBinFragmentFragment } & {
  onCancel: () => void;
} & {
  navigation: FanControlNavigationOption;
  setNavigation: (navigation: FanControlNavigationOption) => void;
  setRecommendation: (navigation: FanControlNavigationOption) => void;
}) => (
  <RecommScheduleGrainConditionInputForm
    {...props}
    grain_bin={grain_bin}
    onCancel={onCancel}
    navigation={navigation}
    setNavigation={setNavigation}
  />
);
