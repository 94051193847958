import * as React from 'react';
export const RowExpandIcon = (props) => (
  <svg
    id="DropExpandIcon"
    xmlns="http://www.w3.org/2000/svg"
    width={16.948}
    height={10.157}
    viewBox="0 0 16.948 10.157"
    {...props}
  >
    <path
      id="DropExpandIcon-2"
      data-name="DropExpandIcon"
      d="M15.321,0,8.531,6.933,1.6.143,0,1.8l8.588,8.36L16.948,1.6Z"
      transform="translate(0 0)"
      fill="#000"
    />
  </svg>
);
