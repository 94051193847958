import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { ContainerTypeLegacy } from '../../../../../core/src/util';
import {
  ContainerType,
  FanControllerRunWindow,
  ForecastDayV2FragmentFragment,
  GrainStatusFragmentFragment,
  RecommendationWindowsFragmentFragment,
  WeatherForecastFragmentFragment,
  withGetRecommendedRunWindowsExperimentsHoc,
  WithGetRecommendedRunWindowsExperimentsHocChildProps,
} from '../../../api';
import { CenteredSpinner } from '../../spinner';
import { DailyForecastTable } from '../daily-forecast';
import { DailyWeatherAndAerationHistory } from '../daily-weather-history';
import { SummaryCard } from '../summary-card';

const useStyles = makeStyles({
  full_width: { width: '100%' },
  min_height: { minHeight: 419 },
  forecast: {
    overflowX: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  daily_forecast_table_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  daily_forecast_table_container_loader: {
    width: '970px',
    height: 650,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type BinWeatherForecastCardType = {
  grain_bin_id: number;
  weather_forecast: WeatherForecastFragmentFragment;
  recommendation_windows_data: RecommendationWindowsFragmentFragment;
  refetch_grain_bin_container?: () => void;
  weather: GrainStatusFragmentFragment | null;
  mobile_width?: boolean;
  aeration_schedule?: FanControllerRunWindow[];
  grain_bin_location_timezone: string;
  recommendation_type: string | null;
  has_enable_fan_guidance: boolean;
  fan_guidance_start_date: Date | null;
  fan_guidance_end_date: Date | null;
  container_type: ContainerTypeLegacy;
  title?: string;
  cfm_scaling?: number;
  cfm_offset?: number;
  cfm_min?: number;
  cfm_max?: number;
  cfm?: number;
  lower_bound?: number;
  upper_bound?: number;
  setCfmValues?: (cfm_values) => void;
  setBoundValues?: (bound_values) => void;
  prev_week_no: number;
  setPrevWeekNo: React.Dispatch<React.SetStateAction<number>>;
} & WithGetRecommendedRunWindowsExperimentsHocChildProps;

export const BinWeatherForecastCard = withGetRecommendedRunWindowsExperimentsHoc(
  ({
    grain_bin_id,
    weather_forecast,
    recommendation_windows_data,
    experiment_run_windows,
    refetch_grain_bin_container,
    refetch_experiment_run_windows,
    aeration_schedule,
    grain_bin_location_timezone,
    recommendation_type,
    has_enable_fan_guidance,
    fan_guidance_start_date,
    fan_guidance_end_date,
    container_type,
    title,
    loading = false,
    cfm_scaling,
    cfm_offset,
    cfm_min,
    cfm_max,
    cfm,
    lower_bound,
    upper_bound,
    setCfmValues,
    setBoundValues,
    prev_week_no: prevWeekNo,
    setPrevWeekNo,
  }: BinWeatherForecastCardType) => {
    const experimentsRunWindowsFormValues = { cfm_scaling, cfm_offset, cfm_min, cfm_max, cfm };
    const boundFormValues = { lower_bound, upper_bound };
    const hourlyForecast = weather_forecast.hourly_forecast;
    const dailyForecast = weather_forecast.daily_forecast as ForecastDayV2FragmentFragment[];
    const [expExpandedStates, setExpExpandedStates] = useState<any>(null);
    const classes = useStyles();
    const presentView = prevWeekNo === 0;
    const goToPresentView = () => {
      setPrevWeekNo(0);
    };
    const goPrevWeek = () => {
      setPrevWeekNo((prevWeekNo) => prevWeekNo + 1);
    };
    const goNextWeek = () => {
      setPrevWeekNo((prevWeekNo) => prevWeekNo - 1);
    };

    const refetchExperiments = async () => {
      const response = await refetch_experiment_run_windows({
        recommendation_type,
        cfm_scaling,
        cfm_offset,
        cfm_min,
        cfm_max,
        cfm,
        from_weather_history: !presentView,
        prev_week_no: prevWeekNo,
      });
      console.log('refetchExperiments response', response);
    };

    useEffect(() => {
      refetchExperiments();
    }, [recommendation_type]);

    return (
      <SummaryCard title={title ? title : 'Weather Forecast at Site'}>
        <Grid className={classes.forecast} container direction={'column'} spacing={3}>
          {loading ? (
            <Grid item className={classes.daily_forecast_table_container_loader}>
              <CenteredSpinner fadeIn="none" />
            </Grid>
          ) : (
            <Grid item className={classes.daily_forecast_table_container}>
              {presentView ? (
                <>
                  {dailyForecast && dailyForecast.length > 0 && hourlyForecast ? (
                    <DailyForecastTable
                      dailyForecast={dailyForecast}
                      hourlyForecast={hourlyForecast}
                      recommendation_windows_data={recommendation_windows_data}
                      recommendation_windows_for_experiments={experiment_run_windows}
                      aeration_schedule={aeration_schedule}
                      grain_bin_location_timezone={grain_bin_location_timezone}
                      recommendation_type={recommendation_type}
                      has_enable_fan_guidance={has_enable_fan_guidance}
                      fan_guidance_start_date={fan_guidance_start_date}
                      fan_guidance_end_date={fan_guidance_end_date}
                      container_type={container_type}
                      refetch_grain_bin_container={refetch_grain_bin_container}
                      refetch_experiment_run_windows={refetch_experiment_run_windows}
                      setCfmValues={setCfmValues}
                      setBoundValues={setBoundValues}
                      experimentsRunWindowsFormValues={experimentsRunWindowsFormValues}
                      boundFormValues={boundFormValues}
                      expExpandedStates={expExpandedStates}
                      setExpExpandedStates={setExpExpandedStates}
                      goPrevWeek={goPrevWeek}
                      goNextWeek={goNextWeek}
                      prevWeekNo={prevWeekNo}
                      goToPresentView={goToPresentView}
                    />
                  ) : (
                    <Typography variant="h6" style={{ fontWeight: 600 }}>
                      Sorry! Incomplete weather forecast data.
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <DailyWeatherAndAerationHistory
                    container_id={grain_bin_id}
                    container_type={container_type}
                    prev_week_no={prevWeekNo}
                    has_enable_fan_guidance={has_enable_fan_guidance}
                    fan_guidance_start_date={fan_guidance_start_date}
                    fan_guidance_end_date={fan_guidance_end_date}
                    grain_bin_location_timezone={grain_bin_location_timezone}
                    goPrevWeek={goPrevWeek}
                    goNextWeek={goNextWeek}
                    prevWeekNo={prevWeekNo}
                    goToPresentView={goToPresentView}
                    refetch_grain_bin_container={refetch_grain_bin_container}
                    recommendation_windows_for_experiments={experiment_run_windows}
                    recommendation_windows_data={recommendation_windows_data}
                    refetch_experiment_run_windows={refetch_experiment_run_windows}
                    setCfmValues={setCfmValues}
                    setBoundValues={setBoundValues}
                    experimentsRunWindowsFormValues={experimentsRunWindowsFormValues}
                    boundFormValues={boundFormValues}
                    expExpandedStates={expExpandedStates}
                    setExpExpandedStates={setExpExpandedStates}
                    recommendation_type={recommendation_type}
                  />
                </>
              )}
            </Grid>
          )}
        </Grid>
      </SummaryCard>
    );
  }
);
