import { Grid, InputAdornment, Tooltip, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';
import debounce from 'just-debounce-it';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';
import { CenteredSpinner } from '../../../../../../core/src/component/spinner';
import {
  GrainBinFragmentFragment,
  UpdateGrainBinMutationVariables,
  UserRole,
  withGetGrainBinHoc,
  WithGetGrainBinHocChildProps,
  WithUpdateGrainBinHocChildProps,
} from '../../../../api/graphql';
import { withUpdateGrainBinHoc } from '../../../../api/graphql/hoc';
import { BaseForm, FormikWrapper } from '../../../util/form2/BaseForm';
import { ErrorBox } from '../../../util/form2/ErrorBox';
import { NumberTextField } from '../../../util/form2/NumberField';
import { PowerCardStatLine } from '../AerationRuntimeStats';
import { getUserPermissionTooltipText } from '../FanControlsCard';

const useStyles = makeStyles({
  centered_text: { textAlign: 'center' },
  centered_row: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  tooltip: { marginLeft: 5 },
  priceFieldLabelRoot: { fontSize: 20 },
});

type Values = {
  price: number | null | undefined;
};

const asJSON = (grain_bin: GrainBinFragmentFragment | null) => {
  if (grain_bin === null) {
    return {
      price: 8,
    };
  }

  return { price: grain_bin.price_per_kwh };
};

const validation_schema = yup.object().shape({
  price: yup
    .number()
    .typeError('A number is required')
    .moreThan(0)
    .nullable()
    .label('Price'),
});

const Form: React.FunctionComponent<{
  debounce_ms: number;
  kwh: number | null;
  cost: number | null;
  viewer_role: UserRole;
}> = ({ debounce_ms, kwh, cost, viewer_role }) => {
  const classes = useStyles();
  const { errors, submitForm, values } = useFormikContext();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const has_error = useMemo(() => errors['power_factor'] || errors['kwH'], [errors]);
  console.log('has_error', { has_error, kwh, cost });
  const hasUserAccessRestricted = [UserRole.ReadOnly, UserRole.FanAccess].includes(viewer_role);
  const disabledTooltipText = hasUserAccessRestricted
    ? getUserPermissionTooltipText(viewer_role)
    : '';

  const debouncedSubmit = useCallback(
    debounce(() => {
      submitForm();
    }, debounce_ms),
    [debounce_ms, submitForm]
  );

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, values]);

  const handlePriceFieldFormat = (val) =>
    val === undefined || val === null
      ? ''
      : val.toString().indexOf('.') === -1
      ? `${val}.0`
      : val.toString();

  return (
    <Grid container justify="center" spacing={2}>
      <Grid container item xs={12} justify="center">
        <Grid item xl={4} lg={6} md={8} xs={12}>
          <Tooltip
            enterTouchDelay={0}
            disableFocusListener
            placement="top"
            title={
              disabledTooltipText ? <span style={{ fontSize: 13 }}>{disabledTooltipText}</span> : ''
            }
            arrow
          >
            <div>
              <NumberTextField
                label="Cents per kwH"
                name="price"
                id="price"
                step="1"
                parseValue={(val) => Number(val)}
                disabled={!_.isNumber(kwh) || hasUserAccessRestricted}
                InputProps={{
                  startAdornment: <InputAdornment position="start">&cent;</InputAdornment>,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.priceFieldLabelRoot,
                  },
                }}
                formatValue={handlePriceFieldFormat}
                delayFormatting
                required
                inputMode="decimal"
              />
            </div>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        className={classes.centered_text}
        item
        container
        xs={6}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography variant="h5">Power Usage</Typography>
        {_.isNumber(kwh) && !has_error ? (
          <PowerCardStatLine heading={`${Math.round(kwh)}`} small="kwH" />
        ) : (
          <PowerCardStatLine heading={`--`} small="kwH" />
        )}
      </Grid>
      <Grid
        className={classes.centered_text}
        item
        container
        xs={6}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <div className={classes.centered_row}>
          <Typography variant="h5">Est. Cost</Typography>
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            disableFocusListener
            open={showTooltip}
            onOpen={() => setShowTooltip(true)}
            onClose={() => setShowTooltip(false)}
            className={classes.tooltip}
            title={
              <div style={{ textAlign: 'start' }}>
                <p style={{ fontSize: 14, lineHeight: 1 }}>
                  *This estimated cost does not factor in demand charge. Demand charge is typically
                  based upon the highest 15 min average power draw per billing cycle.
                </p>
                <p style={{ fontSize: 14, lineHeight: 1 }}>
                  *Minimizing the amount of fans and other devices running concurrently will
                  minimize the demand charge.
                </p>
              </div>
            }
          >
            <HelpOutline onClick={() => setShowTooltip(!showTooltip)} />
          </Tooltip>
        </div>

        {_.isNumber(cost) && !has_error ? (
          <PowerCardStatLine heading={`$${cost.toFixed(2)}`} />
        ) : (
          <PowerCardStatLine heading={`$ --`} />
        )}
      </Grid>
    </Grid>
  );
};

const PowerConsumptionBase: React.FunctionComponent<
  {
    total_kwh: number;
    viewer_role: UserRole;
  } & WithGetGrainBinHocChildProps & {
      grain_bin: GrainBinFragmentFragment;
    } & WithUpdateGrainBinHocChildProps
> = ({ total_kwh, grain_bin, viewer_role, updateGrainBin, loading }) => {
  const initial_values = useMemo(() => asJSON(grain_bin!), [grain_bin]);
  const [kwh, setKwh] = useState(total_kwh);
  const [price, setPrice] = useState<number | null>(null);
  const [cost, setCost] = useState<number | null>(null);
  const mounted = useRef(true);

  useEffect(() => {
    setKwh(total_kwh);
    if (price && _.isNumber(total_kwh)) {
      setCost((price / 100) * total_kwh);
    } else {
      setCost(null);
    }
  }, [total_kwh, price]);

  if (loading) {
    return <CenteredSpinner fadeIn="none" />;
  }
  return (
    <FormikWrapper<Values>
      initialValues={initial_values}
      enableReinitialize={true}
      validationSchema={validation_schema}
      onSubmit={async (values) => {
        const { price } = validation_schema.validateSync(values);
        if (mounted && mounted.current && price !== grain_bin.price_per_kwh) {
          const variables: UpdateGrainBinMutationVariables = {
            price_per_kwh: price,
            grain_bin_id: grain_bin.grain_bin_id,
          };
          // Fire and forget, so we don't see the loading spinner.
          updateGrainBin(variables);
        }

        setPrice(price);
        return Promise.resolve();
      }}
      render={() => (
        <BaseForm>
          <Form debounce_ms={1200} kwh={kwh} cost={cost} viewer_role={viewer_role} />
          <ErrorBox />
        </BaseForm>
      )}
    />
  );
};
export const PowerConsumption = withGetGrainBinHoc(withUpdateGrainBinHoc(PowerConsumptionBase));
