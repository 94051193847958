import { Grid, Theme, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Highcharts from 'highcharts';
import $ from 'jquery';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import {
  ComputedForecastHourV2FragmentFragment,
  ContainerType,
  ExperimentCfmValues,
  ExperimentPsychrometricRange,
  FanControllerRunWindow,
  ForecastDayV2FragmentFragment,
  RecommendationWindowsFragmentFragment,
  RecommRunWindowsExperiments,
} from '../../../api';
import { hasDiagnosticAccess, UserContext } from '../../../contexts';
import { amber_alternate_grey, amber_blue, amber_green } from '../../../style';
import { ContainerTypeLegacy, isTouchDevice } from '../../../util';
import { WeatherChartNavigation } from '../daily-weather-history';
import AerationRecommendationPlot from './AerationRecommendationPlot';
import { ExperimentsCharts } from './ExperimentsCharts';
import { WEATHER_CHART_COLORS } from './ForecastPlotHelpers';
import { RecommendedScheduledRange } from './RecommendationScheduleBar';
import WeatherForecastPlot from './WeatherForecastPlot';

const DRYING_COLOR = 'rgb(225, 114, 81)';
const RECONDITIONING_COLOR = 'rgb(192, 93, 174)';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexGrow: 1,
    flexWrap: 'nowrap',
  },
  customLegends: {
    height: 28,
  },
  weatherCharttop: {
    width: 700,
    marginLeft: 75,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
// ? all these icons collected from https://openweathermap.org/
const iconsBaseURL = 'https://d365fhto3odoim.cloudfront.net/img';
export const weather_icon_image_url_mapping = {
  snow: `${iconsBaseURL}/snow.svg`,
  sleet: `${iconsBaseURL}/sleet.svg`,
  rain: `${iconsBaseURL}/rain.svg`,
  cloudy: `${iconsBaseURL}/cloudy.svg`,
  windy: `${iconsBaseURL}/windy.svg`,
  partly: `${iconsBaseURL}/partly.svg`,
  clear: `${iconsBaseURL}/clear.svg`,
};

export enum RunWindowKind {
  Scheduled,
  Cooling,
  Drying,
  RECONDITIONING,
}

type PresentedRunWindow = {
  start: number;
  end: number;
  type: RunWindowKind;
};

export const presentViewFromRanges = (temp_ranges: PresentedRunWindow[]) => {
  const sorted = temp_ranges.sort((x, y) => {
    return x.start - y.start;
  });

  const res: RecommendedScheduledRange[] = [];

  sorted.forEach((temp_range, i) => {
    const startEpoch = temp_range.start;
    const endEpoch = temp_range.end;
    const startEpochDt = DateTime.fromMillis(startEpoch);
    const endEpochDt = DateTime.fromMillis(endEpoch);

    if (i === 0) {
      res.push({
        startEpoch,
        endEpoch,
        text: '',
        backgroundColor: 'transparent',
      });
    }

    const duration = Math.round(temp_range.end / (1000 * 3600) - temp_range.start / (1000 * 3600));
    let backgroundColor: string = amber_blue;

    switch (temp_range.type) {
      case RunWindowKind.Drying:
        backgroundColor = DRYING_COLOR;
        break;
      case RunWindowKind.RECONDITIONING:
        backgroundColor = RECONDITIONING_COLOR;
        break;

      case RunWindowKind.Scheduled:
        backgroundColor = amber_green;
        break;
    }

    let text;
    if (duration <= 10) {
      if (duration < 1) {
        const duration_in_mins = Math.round(temp_range.end / 60000 - temp_range.start / 60000);
        text = `${duration_in_mins}m`;
      } else {
        text = `${duration}h`;
      }
    } else {
      text =
        Math.round(endEpochDt.diff(startEpochDt, 'year').toObject().years) === 1
          ? 'Forever'
          : `${duration} hrs`;
    }

    res.push({
      startEpoch,
      endEpoch,
      backgroundColor,
      text,
    });
  });
  return res;
};

export function produceReconditioningPeriods(
  recommendation_windows_data: RecommendationWindowsFragmentFragment | null
): RecommendedScheduledRange[] {
  if (
    recommendation_windows_data == null ||
    recommendation_windows_data.emc_reconditioning_ranges == null
  ) {
    console.error(
      'Failed to read psychometric emc_ranges[reconditioning], got null or undefined',
      recommendation_windows_data
    );
    return [];
  }
  return presentViewFromRanges(
    recommendation_windows_data.emc_reconditioning_ranges
      .filter((emc_range) => {
        return emc_range.emc_diff != null;
      })
      .map((value) => {
        return {
          // @ts-ignore
          start: value.start as number,
          // @ts-ignore
          end: value.end as number,
          type: RunWindowKind.RECONDITIONING,
        };
      })
  );
}

export function produceDryingPeriods(
  recommendation_windows_data: RecommendationWindowsFragmentFragment | null
): RecommendedScheduledRange[] {
  if (recommendation_windows_data == null || recommendation_windows_data.emc_ranges == null) {
    console.error(
      'Failed to read psychometric emc_ranges[drying], got null or undefined',
      recommendation_windows_data
    );
    return [];
  }

  return presentViewFromRanges(
    recommendation_windows_data.emc_ranges
      .filter((emc_range) => {
        return emc_range.emc_diff != null;
      })
      .map((value) => {
        return {
          // @ts-ignore
          start: value.start as number,
          // @ts-ignore
          end: value.end as number,
          type: RunWindowKind.Drying,
        };
      })
  );
}

export const DailyForecastTable: React.FunctionComponent<{
  dailyForecast: ForecastDayV2FragmentFragment[];
  hourlyForecast: ComputedForecastHourV2FragmentFragment[];
  recommendation_windows_data: RecommendationWindowsFragmentFragment;
  recommendation_windows_for_experiments?: RecommRunWindowsExperiments[];
  aeration_schedule?: FanControllerRunWindow[];
  grain_bin_location_timezone: string;
  recommendation_type: string | null;
  has_enable_fan_guidance: boolean;
  fan_guidance_start_date: Date | null;
  fan_guidance_end_date: Date | null;
  container_type: ContainerTypeLegacy;
  refetch_grain_bin_container?: () => void;
  refetch_experiment_run_windows?: (variables) => void;
  setCfmValues?: (cfm_values) => void;
  setBoundValues?: (bound_values) => void;
  boundFormValues?: {
    lower_bound: number | undefined;
    upper_bound: number | undefined;
  };
  experimentsRunWindowsFormValues?: {
    cfm_scaling: number | undefined;
    cfm_offset: number | undefined;
    cfm_min: number | undefined;
    cfm_max: number | undefined;
    cfm: number | undefined;
  };
  expExpandedStates?: any;
  setExpExpandedStates?: React.Dispatch<React.SetStateAction<any>>;
  hideExpCharts?: boolean;
  goPrevWeek: () => void;
  goNextWeek: () => void;
  goToPresentView: () => void;
  prevWeekNo: number;
}> = ({
  dailyForecast,
  hourlyForecast,
  recommendation_windows_data,
  recommendation_windows_for_experiments,
  aeration_schedule,
  grain_bin_location_timezone,
  recommendation_type,
  has_enable_fan_guidance,
  fan_guidance_start_date,
  fan_guidance_end_date,
  container_type,
  refetch_grain_bin_container,
  refetch_experiment_run_windows,
  setCfmValues,
  experimentsRunWindowsFormValues,
  expExpandedStates,
  setExpExpandedStates,
  setBoundValues,
  boundFormValues,
  hideExpCharts = false,
  goPrevWeek,
  goNextWeek,
  prevWeekNo,
  goToPresentView,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const classes = useStyles();
  const [weatherChart, setWeatherChart] = useState<any>(null);
  const [recommendationChart, setRecommendationChart] = useState<any>(null);
  const [recommendationExperimentCharts, setRecommendationExperimentCharts] = useState<any[]>([]);
  const has_diagnostic_access = hasDiagnosticAccess(React.useContext(UserContext));
  const firstDayToShow =
    dailyForecast && dailyForecast[0]
      ? DateTime.fromMillis(new Date(dailyForecast[0].epoch_time).getTime())
      : DateTime.local();
  const minDate = firstDayToShow
    ? firstDayToShow.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    : null;
  const lastDayToShow =
    dailyForecast && dailyForecast[dailyForecast.length - 1]
      ? DateTime.fromMillis(new Date(dailyForecast[dailyForecast.length - 1].epoch_time).getTime())
      : null;
  const maxDate = lastDayToShow
    ? lastDayToShow.set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      })
    : null;

  const dryingPeriodsToPresent = produceDryingPeriods(recommendation_windows_data);
  const reconditioningPeriodsToPresent = produceReconditioningPeriods(recommendation_windows_data);

  const scheduledRunsToPresent = presentViewFromRanges(
    (aeration_schedule || []).map((aeration) => {
      const start = aeration.start_epoch.getTime();
      const end = aeration.end_epoch.getTime();
      return {
        // @ts-ignore
        start: start as number,
        end: end as number,
        type: RunWindowKind.Scheduled,
      };
    })
  );

  let coolingPeriodsToPresent: RecommendedScheduledRange[] = [];
  if (recommendation_windows_data == null || recommendation_windows_data.temp_ranges == null) {
    console.error(
      'Failed to read psychometric temp data, got null or undefined',
      recommendation_windows_data
    );
  } else {
    coolingPeriodsToPresent = presentViewFromRanges(
      recommendation_windows_data.temp_ranges.map((value) => {
        return {
          // @ts-ignore
          start: value.start as number,
          // @ts-ignore
          end: value.end as number,
          type: RunWindowKind.Cooling,
        };
      })
    );
  }

  const syncronizeCrossHairs = (chart) => {
    const moveCrossHairForWeatherChartOnly = (x, evt) => {
      // remove old plot line and draw new plot line (crosshair) for this chart
      const xAxis1 = weatherChart.xAxis[0];
      xAxis1.removePlotLine('myPlotLineId');
      xAxis1.addPlotLine({
        value: chart.xAxis[0].translate(x, true),
        width: 1,
        color: WEATHER_CHART_COLORS.CROSS_HAIR_COLOR,
        id: 'myPlotLineId',
        zIndex: 2,
      });

      // refresh weatherChart tooltip
      const tEvt = weatherChart.pointer.normalize(evt); // Find coordinates within the chart
      const tPoints: Highcharts.Point[] = [];
      for (const tSeries of weatherChart.series) {
        const tPoint: Highcharts.Point | undefined = tSeries.searchPoint(tEvt, true);

        if (tPoint && tSeries.visible) {
          tPoints.push(tPoint);
        } else {
          tSeries.stateMarkerGraphic && tSeries.stateMarkerGraphic.hide();
          tSeries.markerGroup && tSeries.markerGroup.hide();
          tSeries.halo && tSeries.halo.hide();
        }
      }
      if (tPoints.length) {
        weatherChart.tooltip.refresh(tPoints);
        setTimeout(() => {
          weatherChart.tooltip.hide(3000); // tooltip hide delay
        });
      }
    };

    const integrateCrossHair = (x, evt) => {
      if (weatherChart && weatherChart.xAxis) {
        // remove old plot line and draw new plot line (crosshair) for this chart
        const xAxis1 = weatherChart.xAxis[0];
        xAxis1.removePlotLine('myPlotLineId');
        xAxis1.addPlotLine({
          value: chart.xAxis[0].translate(x, true),
          width: 1,
          color: WEATHER_CHART_COLORS.CROSS_HAIR_COLOR,
          id: 'myPlotLineId',
          zIndex: 2,
        });
        // refresh weatherChart tooltip
        const tEvt = weatherChart.pointer.normalize(evt); // Find coordinates within the chart
        const tPoints: Highcharts.Point[] = [];
        for (const tSeries of weatherChart.series) {
          const tPoint: Highcharts.Point | undefined = tSeries.searchPoint(tEvt, true);

          if (tPoint && tSeries.visible) {
            tPoints.push(tPoint);
          } else {
            tSeries.stateMarkerGraphic && tSeries.stateMarkerGraphic.hide();
            tSeries.markerGroup && tSeries.markerGroup.hide();
            tSeries.halo && tSeries.halo.hide();
          }
        }
        if (tPoints.length) {
          weatherChart.tooltip.refresh(tPoints);
          setTimeout(() => {
            weatherChart.tooltip.hide(3000); // tooltip hide delay
          });
        }
      }

      if (recommendationChart && recommendationChart.xAxis) {
        // remove old crosshair and draw new crosshair on recommendationChart
        const xAxis2 = recommendationChart.xAxis[0];
        xAxis2.removePlotLine('myPlotLineId');
        xAxis2.addPlotLine({
          value: chart.xAxis[0].translate(x, true),
          width: 1,
          color: WEATHER_CHART_COLORS.CROSS_HAIR_COLOR,
          id: 'myPlotLineId',
          zIndex: 2,
        });

        // refresh recommendationChart tooltip
        recommendationChart.series[0].points.forEach((point) => {
          if (point.x < xAxis2.toValue(x, true) && point.x2 > xAxis2.toValue(x, true)) {
            chart !== recommendationChart && recommendationChart.tooltip.refresh(point);
          }
        });
      }

      // recommendation experiment charts
      if (has_diagnostic_access) {
        for (const recommendationExperimentChart of recommendationExperimentCharts) {
          if (recommendationExperimentChart && recommendationExperimentChart.xAxis) {
            const xAxis = recommendationExperimentChart.xAxis[0];
            xAxis.removePlotLine('myPlotLineId');
            xAxis.addPlotLine({
              value: chart.xAxis[0].translate(x, true),
              width: 1,
              color: WEATHER_CHART_COLORS.CROSS_HAIR_COLOR,
              id: 'myPlotLineId',
              zIndex: 2,
            });

            recommendationExperimentChart.series[0].points.forEach((point) => {
              if (point.x < xAxis.toValue(x, true) && point.x2 > xAxis.toValue(x, true)) {
                chart !== recommendationExperimentChart &&
                  recommendationExperimentChart.tooltip.refresh(point);
              }
            });
          }
        }
      }
    };

    if (weatherChart && recommendationChart && chart) {
      const container = $(chart.container);
      const offset = container.offset();
      const xAxis = chart.xAxis[0];

      if (isMobile) {
        chart.container.addEventListener(
          'touchstart',
          (evt) => {
            const tEvt = chart.pointer.normalize(evt);
            const tPoint = chart.series[0].searchPoint(tEvt, true);
            if (tPoint) {
              const x = Math.round(xAxis.toPixels(tPoint.x)) - chart.plotLeft;
              integrateCrossHair(x, evt);
            } else {
              const xAxisValue = new Date(chart.xAxis[0].toValue(tEvt.chartX)).getTime();
              const x = Math.round(xAxis.toPixels(xAxisValue)) - chart.plotLeft;
              integrateCrossHair(x, evt);
            }
          },
          { passive: true }
        );
        chart.container.addEventListener(
          'touchmove',
          (evt) => {
            const tEvt = chart.pointer.normalize(evt);
            const tPoint = chart.series[0].searchPoint(tEvt, true);
            if (tPoint) {
              const x = Math.round(xAxis.toPixels(tPoint.x)) - chart.plotLeft;
              integrateCrossHair(x, evt);
            } else {
              const xAxisValue = new Date(chart.xAxis[0].toValue(tEvt.chartX)).getTime();
              const x = Math.round(xAxis.toPixels(xAxisValue)) - chart.plotLeft;
              integrateCrossHair(x, evt);
            }
          },
          { passive: true }
        );
        // when window is resized on wide screen(non touchable device) then we want mousemove event to be work
        if (!isTouchDevice()) {
          chart.container.addEventListener(
            'mousemove',
            (evt) => {
              const tEvt = chart.pointer.normalize(evt);
              const tPoint = chart.series[0].searchPoint(tEvt, true);
              if (tPoint) {
                const x = Math.round(xAxis.toPixels(tPoint.x)) - chart.plotLeft;
                integrateCrossHair(x, evt);
              } else {
                const xAxisValue = new Date(chart.xAxis[0].toValue(tEvt.chartX)).getTime();
                const x = Math.round(xAxis.toPixels(xAxisValue)) - chart.plotLeft;
                integrateCrossHair(x, evt);
              }
            },
            { passive: true }
          );
        }
      } else {
        chart.container.addEventListener(
          'mousemove',
          (evt) => {
            const x = evt.clientX - chart.plotLeft - offset.left;
            integrateCrossHair(x, evt);
          },
          { passive: true }
        );
      }
    } else if (weatherChart && chart) {
      const container = $(chart.container);
      const offset = container.offset();
      const xAxis = chart.xAxis[0];

      if (isMobile) {
        chart.container.addEventListener(
          'touchstart',
          (evt) => {
            const tEvt = chart.pointer.normalize(evt);
            const tPoint = chart.series[0].searchPoint(tEvt, true);
            if (tPoint) {
              const x = Math.round(xAxis.toPixels(tPoint.x)) - chart.plotLeft;
              moveCrossHairForWeatherChartOnly(x, evt);
            } else {
              const xAxisValue = new Date(chart.xAxis[0].toValue(tEvt.chartX)).getTime();
              const x = Math.round(xAxis.toPixels(xAxisValue)) - chart.plotLeft;
              moveCrossHairForWeatherChartOnly(x, evt);
            }
          },
          { passive: true }
        );
        chart.container.addEventListener(
          'touchmove',
          (evt) => {
            const tEvt = chart.pointer.normalize(evt);
            const tPoint = chart.series[0].searchPoint(tEvt, true);
            if (tPoint) {
              const x = Math.round(xAxis.toPixels(tPoint.x)) - chart.plotLeft;
              moveCrossHairForWeatherChartOnly(x, evt);
            } else {
              const xAxisValue = new Date(chart.xAxis[0].toValue(tEvt.chartX)).getTime();
              const x = Math.round(xAxis.toPixels(xAxisValue)) - chart.plotLeft;
              moveCrossHairForWeatherChartOnly(x, evt);
            }
          },
          { passive: true }
        );
        // when window is resized on wide screen(non touchable device) then we want mousemove event to be work
        if (!isTouchDevice()) {
          chart.container.addEventListener(
            'mousemove',
            (evt) => {
              const tEvt = chart.pointer.normalize(evt);
              const tPoint = chart.series[0].searchPoint(tEvt, true);
              if (tPoint) {
                const x = Math.round(xAxis.toPixels(tPoint.x)) - chart.plotLeft;
                moveCrossHairForWeatherChartOnly(x, evt);
              } else {
                const xAxisValue = new Date(chart.xAxis[0].toValue(tEvt.chartX)).getTime();
                const x = Math.round(xAxis.toPixels(xAxisValue)) - chart.plotLeft;
                moveCrossHairForWeatherChartOnly(x, evt);
              }
            },
            { passive: true }
          );
        }
      } else {
        chart.container.addEventListener(
          'mousemove',
          (evt) => {
            const x = evt.clientX - chart.plotLeft - offset.left;
            moveCrossHairForWeatherChartOnly(x, evt);
          },
          { passive: true }
        );
      }
    }
  };

  const setExperimentChart = (chart: any, index: number) => {
    setRecommendationExperimentCharts((prev) => {
      const updated = [...prev];
      updated[index] = chart;
      return [...updated];
    });
  };

  console.log('recommendation_windows_for_experiments', recommendation_windows_for_experiments);
  return (
    <Grid container className={classes.container} spacing={3} alignContent="space-between">
      <Grid container style={{ padding: '10px 14px' }} direction="column" alignItems="center">
        <Grid item style={{ padding: '0 0.5rem', width: '100%', marginTop: 10 }}>
          <div className={classes.weatherCharttop}>
            <div>
              <WeatherChartNavigation
                startDayDate={minDate}
                endDayDate={maxDate}
                goPrevWeek={goPrevWeek}
                goNextWeek={goNextWeek}
                prevWeekNo={prevWeekNo}
                goToPresentView={goToPresentView}
              />
            </div>
            <div className={classes.customLegends}>
              <div
                className="weatherChartLegendArea"
                style={{ display: 'flex', justifyContent: 'center' }}
              />
            </div>
          </div>

          <WeatherForecastPlot
            grain_bin_location_timezone={grain_bin_location_timezone}
            hourly_forecast_data={hourlyForecast}
            days={dailyForecast}
            weatherChart={weatherChart}
            setWeatherChart={setWeatherChart}
            syncronizeCrossHairs={syncronizeCrossHairs}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Grid>
        {container_type === ContainerTypeLegacy.bin && (
          <Grid item style={{ padding: '0 0.5rem', width: '100%' }}>
            <div style={{ width: '100%' }}>
              <AerationRecommendationPlot
                has_enable_fan_guidance={has_enable_fan_guidance}
                scheduledRunsToPresent={scheduledRunsToPresent}
                coolingPeriodsToPresent={coolingPeriodsToPresent}
                dryingPeriodsToPresent={dryingPeriodsToPresent}
                reconditioningPeriodsToPresent={reconditioningPeriodsToPresent}
                grain_bin_location_timezone={grain_bin_location_timezone}
                recommendation_type={recommendation_type}
                setRecommendationChart={setRecommendationChart}
                syncronizeCrossHairs={syncronizeCrossHairs}
                minDate={minDate}
                maxDate={maxDate}
                fan_guidance_start_date={fan_guidance_start_date}
                fan_guidance_end_date={fan_guidance_end_date}
              />
            </div>
            {container_type === ContainerTypeLegacy.bin &&
              has_diagnostic_access &&
              !hideExpCharts && (
                <ExperimentsCharts
                  recommendation_type={recommendation_type}
                  recommendation_windows_for_experiments={recommendation_windows_for_experiments}
                  syncronizeCrossHairs={syncronizeCrossHairs}
                  minDate={minDate}
                  maxDate={maxDate}
                  has_enable_fan_guidance={has_enable_fan_guidance}
                  fan_guidance_start_date={fan_guidance_start_date}
                  fan_guidance_end_date={fan_guidance_end_date}
                  refetch_grain_bin_container={refetch_grain_bin_container}
                  refetch_experiment_run_windows={refetch_experiment_run_windows}
                  setBoundValues={setBoundValues}
                  setCfmValues={setCfmValues}
                  setExperimentChart={setExperimentChart}
                  grain_bin_location_timezone={grain_bin_location_timezone}
                  experimentsRunWindowsFormValues={experimentsRunWindowsFormValues}
                  boundFormValues={boundFormValues}
                  expExpandedStates={expExpandedStates}
                  setExpExpandedStates={setExpExpandedStates}
                />
              )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
