import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { Grid } from '@material-ui/core';
import {
  HubContainerLinkBaseFragmentFragment,
  withUpdateHubAssignmentHoc,
  WithUpdateHubAssignmentHocChildProps,
} from '../../../api';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../../util/form2/BaseForm';
import { Button, ButtonSubmit } from '../../util/form2/Button';
import { ErrorBox } from '../../util/form2/ErrorBox';
import { updateValidationSchema, UpdateValues } from '../HubAssignmentForm';
import { SelectedHubType } from '../HubRemovalForm';
import { AssignHubFormFields } from './AssignHubFormFields';

const useStyles = makeStyles({
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: 320,
  },
});

export const UpdateGrainBinHubFormBase = ({
  updateHubAssignment,
  grain_bin_hub,
  onSubmitSuccess,
  handleClose,
  ...props
}: WithUpdateHubAssignmentHocChildProps &
  FormikWrapperHandlerProps<UpdateValues, HubContainerLinkBaseFragmentFragment> & {
    grain_bin_hub: SelectedHubType;
    onSubmitSuccess: () => void;
    handleClose: () => void;
  }) => {
  const classes = useStyles();

  return (
    <FormikWrapper<UpdateValues, HubContainerLinkBaseFragmentFragment>
      {...props}
      validationSchema={updateValidationSchema}
      initialValues={{
        hub_nickname: grain_bin_hub.nickname,
        hub_type: grain_bin_hub.hub_type,
        has_level_sensor: grain_bin_hub.has_level_sensor || false,
        hub_offset_ft: grain_bin_hub.hub_offset_ft,
      }}
      onSubmit={(values) => {
        const {
          hub_nickname: nickname,
          hub_type,
          has_level_sensor,
          hub_offset_ft,
        } = updateValidationSchema.validateSync(values);

        return updateHubAssignment({
          nickname,
          hub_type,
          has_level_sensor,
          hub_container_link_id: grain_bin_hub.link_id,
          hub_offset_ft: has_level_sensor ? hub_offset_ft : 0,
        });
      }}
      onSubmitSuccess={onSubmitSuccess}
      render={() => (
        <BaseForm submitting_message="Updating Hub...">
          <div className={classes.column}>
            <AssignHubFormFields update />
            <Grid container spacing={2} alignItems="center" style={{ marginTop: '15px' }}>
              <Grid item xs={5}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{
                    margin: 0,
                    width: '100%',
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={7}>
                <ButtonSubmit
                  style={{
                    margin: 0,
                    width: '100%',
                  }}
                  variant="contained"
                  color="primary"
                  allow_pristine
                  allow_btn_disabled_for_validation={false}
                >
                  Save Changes
                </ButtonSubmit>{' '}
              </Grid>
            </Grid>
          </div>
          <ErrorBox withoutGrid />
        </BaseForm>
      )}
    />
  );
};

export const UpdateGrainBinHubForm = withUpdateHubAssignmentHoc(UpdateGrainBinHubFormBase);
