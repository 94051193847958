import * as React from 'react';
export const AutomationEnabledIcon = (props) => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" {...props}>
    <defs>
      <style>
        {'.auto-cls-1{fill:#ffc07f;fill-rule:evenodd;stroke:#000;stroke-miterlimit:10;}'}
      </style>
    </defs>
    <g id="Dry_w_Auto">
      <g id="Dial">
        <g id="DialText">
          <g id="Details">
            <g id="AutoEnabled">
              <path
                className="auto-cls-1"
                d="m13.75.66c.46.27.69.8.56,1.32l-1.72,7.05h4.71c.5,0,.95.31,1.13.78.17.47.04,1-.35,1.33l-12.48,10.67c-.41.35-1.02.38-1.46.07-.45-.31-.63-.89-.44-1.4l2.53-6.92H1.7c-.49,0-.94-.3-1.12-.76-.18-.46-.06-.98.3-1.32L12.32.82c.39-.36.97-.43,1.42-.16h0Z"
              />
              <path
                className="auto-cls-1"
                d="m19.7,13.7c.48,0,.91.29,1.1.73l3.6,8.4c.26.61-.02,1.31-.63,1.58-.61.26-1.31-.02-1.58-.63l-.72-1.67h-3.56l-.72,1.67c-.26.61-.97.89-1.58.63-.61-.26-.89-.97-.63-1.58l3.6-8.4c.19-.44.62-.73,1.1-.73h0Zm-.75,6h1.5l-.75-1.75-.75,1.75Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
