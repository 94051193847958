import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import React, { useState } from 'react';
import { FanModelCreatedType } from '../../../../../core/src/api';
import { AddOrUpdateCustomGrainFanModel } from './AddOrUpdateCustomGrainFanModel';
import { GenericFanModelsSelectorDashboard } from './GenericFanModelsSelectorDashboard';
import { FanModelDetails } from './GrainFanModelSelector';
import { StandardFanModelsSelectorDashboard } from './StandardFanModelsSelectorDashboard';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '900px',
  },
}));

const BootstrapDialogTitle = (props: {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}) => {
  const { onClose, children } = props;

  return (
    <DialogTitle style={{ margin: 20, padding: 2 }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: 8,
            top: 16,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const GrainFanModelsSettingsModal = ({
  openGrainFanModelsModal,
  handleSetCurrentFanModelState,
  handleClose,
  currentFanModelState,
}: {
  openGrainFanModelsModal: boolean;
  handleSetCurrentFanModelState: (currentFanModel: any) => void;
  handleClose: () => void;
  currentFanModelState: FanModelDetails | null;
}) => {
  const initialStateAccodian1 =
    (currentFanModelState &&
      currentFanModelState.fan_model_created_type &&
      currentFanModelState.fan_model_created_type === FanModelCreatedType.SystemCreated) ||
    currentFanModelState === null ||
    false;

  const initialStateAccodian2 =
    (currentFanModelState &&
      currentFanModelState.fan_model_created_type &&
      currentFanModelState.fan_model_created_type === FanModelCreatedType.GenericCustomCreated) ||
    false;

  const initialStateAccodian3 =
    (currentFanModelState &&
      currentFanModelState.fan_model_created_type &&
      currentFanModelState.fan_model_created_type === FanModelCreatedType.CustomCreated) ||
    false;

  const [is_accordion1_open, setAccordion1Open] = useState(initialStateAccodian1);
  const [is_accordion2_open, setAccordion2Open] = useState(initialStateAccodian2);
  const [is_accordion3_open, setAccordion3Open] = useState(initialStateAccodian3);

  const currentGenericCustomFanModelState =
    currentFanModelState &&
    FanModelCreatedType.GenericCustomCreated === currentFanModelState.fan_model_created_type
      ? currentFanModelState
      : null;

  const currentCustomFanModelState =
    currentFanModelState &&
    FanModelCreatedType.CustomCreated === currentFanModelState.fan_model_created_type
      ? currentFanModelState
      : null;
  const customModelSectionHeading = currentCustomFanModelState
    ? 'Update a Custom Fan Model'
    : 'Create a Custom Fan Model';
  console.log('currentCustomFanModelState', currentCustomFanModelState, currentFanModelState);
  console.log(
    'currentGenericCustomFanModelState',
    currentGenericCustomFanModelState,
    currentFanModelState
  );
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openGrainFanModelsModal}
        style={{ width: '100%' }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h4" style={{ fontSize: 24, textAlign: 'start', fontWeight: 600 }}>
            Select Your Fan Model
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ padding: '0px 20px 20px 20px' }}>
          <Grid style={{ border: 'black' }}>
            <Accordion
              style={{ width: '100%' }}
              expanded={is_accordion1_open}
              onChange={() => {
                if (!is_accordion1_open && (is_accordion2_open || is_accordion3_open)) {
                  setAccordion1Open(true);
                  setAccordion2Open(false);
                  setAccordion3Open(false);
                } else {
                  setAccordion1Open(!is_accordion1_open);
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: 0 }}
              >
                <Typography variant="h6" style={{ fontWeight: 600 }}>
                  Find your Exact Fan Model
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0, width: '100%' }}>
                <StandardFanModelsSelectorDashboard
                  handleSetCurrentFanModelState={handleSetCurrentFanModelState}
                  fan_model_filter={[
                    {
                      label_name: 'fan_model_created_type',
                      label_value: FanModelCreatedType.SystemCreated,
                    },
                    {
                      label_name: 'is_generic',
                      label_value: 'false',
                    },
                  ]} // to get all standard fan models
                  selectedFanModelId={
                    currentFanModelState &&
                    currentFanModelState.fan_model_created_type ===
                      FanModelCreatedType.SystemCreated
                      ? currentFanModelState.fan_model_id
                      : null
                  }
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ width: '100%' }}
              expanded={is_accordion2_open}
              onChange={() => {
                if (!is_accordion2_open && (is_accordion1_open || is_accordion3_open)) {
                  setAccordion1Open(false);
                  setAccordion2Open(true);
                  setAccordion3Open(false);
                } else {
                  setAccordion2Open(!is_accordion2_open);
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: 0 }}
              >
                <Typography variant="h6" style={{ fontWeight: 600 }}>
                  Select a Generic Fan Model
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0, width: '100%' }}>
                <GenericFanModelsSelectorDashboard
                  currentGenericCustomFanModelState={currentGenericCustomFanModelState}
                  handleSetCurrentFanModelState={handleSetCurrentFanModelState}
                  fan_model_filter={[
                    {
                      label_name: 'is_generic',
                      label_value: 'true',
                    },
                  ]} // to get all generic fan models
                  selectedFanModelId={
                    currentFanModelState &&
                    currentFanModelState.fan_model_created_type ===
                      FanModelCreatedType.GenericCustomCreated
                      ? currentFanModelState.linked_generic_fan_model_id
                      : null
                  }
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ width: '100%' }}
              expanded={is_accordion3_open}
              onChange={() => {
                if (!is_accordion3_open && (is_accordion1_open || is_accordion2_open)) {
                  setAccordion1Open(false);
                  setAccordion2Open(false);
                  setAccordion3Open(true);
                } else {
                  setAccordion3Open(!is_accordion3_open);
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: 0 }}
              >
                <Typography variant="h6" style={{ fontWeight: 600 }}>
                  {customModelSectionHeading}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0, width: '100%' }}>
                <AddOrUpdateCustomGrainFanModel
                  handleSetCurrentFanModelState={handleSetCurrentFanModelState}
                  currentCustomFanModelState={currentCustomFanModelState}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export { GrainFanModelsSettingsModal };
