import * as React from 'react';
export const SmallPlusIcon = (props) => (
  <svg
    id="PlusIcon"
    xmlns="http://www.w3.org/2000/svg"
    width={14.531}
    height={14.532}
    viewBox="0 0 14.531 14.532"
    {...props}
  >
    <path
      id="svgexport-26"
      d="M9.366,2.1a7.266,7.266,0,1,0,7.266,7.266A7.268,7.268,0,0,0,9.366,2.1ZM13,10.092H10.092V13H8.639V10.092H5.733V8.639H8.639V5.733h1.453V8.639H13Z"
      transform="translate(-2.1 -2.1)"
      fill="#e69f24"
    />
  </svg>
);
