import { NormalizedCache } from 'apollo-cache-inmemory';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy } from '../../../util';
import {
  GetPileDocument,
  GetPileQuery,
  GetPileQueryVariables,
  PileFragmentFragment as Result,
  UpdatePileMutationVariables as Variables,
  withUpdatePile as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithUpdatePileHocChildProps = {
  updatePile: (variables: Variables) => Promise<Result>;
};
export const withUpdatePileHoc: AmberHoc<{}, WithUpdatePileHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithUpdatePileHocChildProps, typeof component>,
    WithUpdatePileHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updatePile: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors: errors2 }) => {
              if (errors2 || !data) {
                return;
              }
              const { updatePile: result } = data;

              const cache: NormalizedCache = (proxy as any).data;
              const container_key = `GrainContainer:${ContainerTypeLegacy.bin}|${
                variables.pile_id
              }`;
              cache.delete(container_key);
              const cache_data = (cache as any).data;
              const container_match = `$${container_key}.`;
              Object.keys(cache_data).forEach((key) => {
                if (key.startsWith(container_match)) {
                  delete cache_data[key];
                }
              });

              proxy.writeQuery<GetPileQuery, GetPileQueryVariables>({
                query: GetPileDocument,
                data: { __typename: 'Query', pile: result },
                variables: { pile_id: variables.pile_id },
              });
              // TODO: Clear pile telemetry
            },
          });
          if (!result) {
            throw new Error('Unexpected response from server');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected response from server');
          }
          return data.updatePile;
        },
      };
    },
  })(component as any);
