import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import xrange from 'highcharts/modules/xrange';
import _ from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { GrainContainerAerationRunFragmentFragment } from '../../../../../core/src/api';
import { renderFanAutomationPeriodBorderColor } from '../../../../../core/src/util';
import { amber_grey } from '../../../style';
import { AerationEventDetailsDialog } from '../aeration/AerationEventDetailsDialog';
import {
  AerationsRunsRange,
  getAllCategories,
  getFormattedSeriesData,
} from './AerationHistoryPlotHelpers';
import { FanAutomationPeriod } from './DailyWeatherAndAerationHistory';

declare global {
  interface Window {
    moment: any;
  }
}
window.moment = moment;
xrange(Highcharts);
type AerationRecommendationPlotProps = {
  containerId: number;
  scheduledRunsToPresent: AerationsRunsRange[];
  fanAutomationPeriods: FanAutomationPeriod[];
  grain_bin_location_timezone: string;
  setRecommendationChart: Function;
  syncronizeCrossHairs: Function;
  minDate: DateTime | null;
  maxDate: DateTime | null;
  aerationRuns: GrainContainerAerationRunFragmentFragment[];
};

const getIdForAerationRun = ({ container_id, start_epoch }) => `${container_id}${start_epoch}`;

const AerationHistoryPlot = ({
  containerId,
  aerationRuns,
  scheduledRunsToPresent,
  fanAutomationPeriods,
  grain_bin_location_timezone,
  setRecommendationChart,
  syncronizeCrossHairs,
  minDate,
  maxDate,
}: AerationRecommendationPlotProps) => {
  const [chart2, setChart2] = useState(null);
  const [
    aeration_event,
    setAerationEvent,
  ] = useState<GrainContainerAerationRunFragmentFragment | null>(null);
  const closeEventDetailDialog = useCallback(() => setAerationEvent(null), [setAerationEvent]);
  const setSelected = (id: string) => {
    const found_event = aerationRuns.find((run) => getIdForAerationRun(run) === id);
    setAerationEvent(found_event || null);
  };

  useEffect(() => {
    syncronizeCrossHairs(chart2);
  }, [chart2]);
  Highcharts.setOptions({
    time: {
      timezone: grain_bin_location_timezone,
    },
  });
  console.log('fanAutomationPeriods', {
    fanAutomationPeriods,
  });
  console.log('scheduledRunsToPresent:', {
    scheduledRunsToPresent,
  });
  const categories = getAllCategories();
  const formattedSeriesData = getFormattedSeriesData({
    scheduledRunsToPresent,
  });
  console.log('formattedSeriesData', formattedSeriesData);
  const chartOptions = {
    credits: false,
    chart: {
      type: 'xrange',
      marginLeft: 75,
      marginRight: 145,
      height: 130,
      events: {
        load() {
          setChart2(this);
          setRecommendationChart(this);
          syncronizeCrossHairs(this);
        },
        render() {
          // If placeholders already exist, clear them
          if (this.categoryEmptyPlaceholders && this.categoryEmptyPlaceholders.length) {
            this.categoryEmptyPlaceholders.forEach((emptyPlaceholder) => {
              emptyPlaceholder.destroy();
            });
          }
          this.categoryEmptyPlaceholders = [];
          // Which categories are without any data?
          const categoriesToShowPlotData: any = [];
          this.series[0].points.forEach((point) => {
            if (
              categoriesToShowPlotData.indexOf(point.y) === -1 &&
              point.x !== undefined &&
              point.x2 !== undefined
            ) {
              categoriesToShowPlotData.push(point.y);
            }
          });
          const getEmptyPlaceholderMessage = (category) => {
            return {
              [categories[0]]: 'No Aeration Runs',
            }[category];
          };
          for (let i = 0; i <= this.yAxis[0].dataMax; i += 1) {
            if (categoriesToShowPlotData.indexOf(i) === -1) {
              const message = getEmptyPlaceholderMessage(categories[i]);
              this.categoryEmptyPlaceholders.push(
                this.renderer
                  .text(message, 0, this.yAxis[0].toPixels(i) + 4)
                  .css({ fontSize: 12, fontWeight: 'bold' })
                  .add()
                  .toFront()
              );
            }
          }
          // Correct placeholders positions (translateX -50%)
          this.categoryEmptyPlaceholders.forEach((label) => {
            label.attr({
              x: this.plotLeft + 10,
            });
          });
          // Draw Gray Background Plot
          const xAxis = this.xAxis[0];
          const lastKey = xAxis.extKey ? xAxis.extKey.split(',')[1] : xAxis.max;
          const width = Math.round(xAxis.toPixels(lastKey)) - this.plotLeft;
          const firstTickOnXaxisInPx = minDate
            ? Math.round(xAxis.toPixels(minDate.toMillis()))
            : Math.round(xAxis.toPixels(xAxis.tickPositions[0]));
          for (let i = 0; i <= this.yAxis[0].dataMax; i += 1) {
            this.renderer
              .rect(firstTickOnXaxisInPx, this.yAxis[0].toPixels(i) - this.plotTop, width, 19)
              .attr({
                zIndex: ' -10',
                stroke: 'rgb(211,211,211)',
                fill: 'rgb(211,211,211)',
                'stroke-width': 1,
              })
              .add();
          }
          // clear previous automation period plot
          if (this.automationPeriodBorderPlot && this.automationPeriodPlot) {
            this.automationPeriodBorderPlot.element.remove();
            this.automationPeriodPlot.element.remove();
          }
          // for automation period plots
          if (fanAutomationPeriods.length > 0) {
            fanAutomationPeriods.forEach(({ start, end, mode }) => {
              const automationStartDate =
                start.getTime() < minDate.toMillis() ? minDate.toMillis() : start.getTime();
              const automationEndDate =
                end.getTime() > maxDate.toMillis() ? lastKey : end.getTime();
              const startPxls = Math.round(xAxis.toPixels(automationStartDate));
              const width = Math.round(xAxis.toPixels(automationEndDate)) - startPxls;
              // draw new automation period plot
              this.automationPeriodPlot = this.renderer
                .rect(startPxls, this.yAxis[0].toPixels(0) - this.plotTop, width, 20)
                .attr({
                  zIndex: '-10',
                  stroke: 'rgb(169,169,169)',
                  fill: 'rgb(169,169,169)',
                  'stroke-width': 0,
                })
                .add();
              // border rectangle to indicate start and end of automation period
              this.automationPeriodBorderPlot = this.renderer
                .rect(startPxls, this.yAxis[0].toPixels(0) - this.plotTop, width, 20)
                .attr({
                  zIndex: '3',
                  stroke: renderFanAutomationPeriodBorderColor(mode),
                  'stroke-width': 2.5,
                })
                .add();
            });
          }
        },
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      // tickLength: 0,
      min: minDate ? minDate.toMillis() : null,
      max: maxDate ? maxDate.toMillis() : null,
      type: 'datetime',
      labels: {
        // enabled: false,
        format: '{value:%a}', // '{value:%Y-%b-%e %l:%M %p }',
        style: {
          fontFamily: 'Source Sans Pro,sans-serif',
          fontSize: 12,
        },
      },
      crosshair: false,
      tickInterval: 24 * 3600 * 1000, // 24 hrs
    },
    yAxis: {
      categories,
      title: {
        text: '',
      },
      labels: {
        useHTML: true,
        align: 'left',
        x: 0,
        y: -18,
        style: {
          fontFamily: 'Source Sans Pro,sans-serif',
          color: amber_grey,
          fontSize: 16,
          lineHeight: 0.5,
          fontWeight: 400,
          width: '600px',
        },
        formatter() {
          return `<span style="color:${amber_grey};font-size:16px; font-weight: 400;width:600px">${
            this.value
          }</span>`;
        },
      },
      reversed: true,
    },
    tooltip: {
      useHTML: true,
      formatter() {
        const tooltipHeading = `${Highcharts.dateFormat('%a %l:%M %p', this.x)}-${
          this.point.label === 'Forever' ? 'Forever' : Highcharts.dateFormat('%a %l:%M %p', this.x2)
        }`;
        return `<div style='background-color:#fff;padding:4px;margin:0px;border:1px solid gray;border-bottom:none;border-radius:3px'>
        <b>${this.point.mode ? `${this.point.mode} :` : ''}</b>
        <b>${tooltipHeading}</b>
        </div>`;
      },
      padding: 0,
      borderWidth: 1,
      borderRadius: 8,
      borderColor: 'gray',
      backgroundColor: '#fff',
      style: {
        opacity: 0.85,
        zIndex: 9999,
      },
    },
    series: [
      {
        name: 'Recommendation windows schedule',
        backgroundColor: 'gray',
        zIndex: 999,
        showInLegend: false,
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: 'rgb(66,66,66)',
        pointWidth: 20,
        data: formattedSeriesData,
        dataLabels: {
          useHTML: true,
          enabled: true,
          formatter() {
            return `<div style="color:black;font-weight:600">${this.point.label}</div>`;
          },
        },
      },
    ],
    plotOptions: {
      series: {
        cursor: 'pointer',
        events: {
          click(e) {
            setSelected(
              getIdForAerationRun({
                container_id: containerId,
                start_epoch: e.point.x,
              })
            );
          },
        },
      },
    },
  };
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      <AerationEventDetailsDialog
        grain_bin_id={containerId}
        aeration_event={aeration_event}
        onClickClose={closeEventDetailDialog}
        open={Boolean(aeration_event)}
      />
    </>
  );
};
export default AerationHistoryPlot;
