import { ContainerTypeLegacy } from './constant';

export const formatContainerType = (container_type: ContainerTypeLegacy | null) => {
  switch (container_type) {
    case ContainerTypeLegacy.bin: {
      return 'Bin';
    }
    case ContainerTypeLegacy.barge: {
      return 'Barge';
    }
    case ContainerTypeLegacy.pile: {
      return 'Pile';
    }
    default:
      return '';
  }
};
