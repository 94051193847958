import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles({
  separator: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 50,
  },
  hubHeading: {
    padding: '0 1rem' /* creates the space */,
    margin: 0,
    paddingTop: 5,
    paddingBottom: 5,
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderRadius: 16,
    fontWeight: 500,
  },
  line: {
    flex: '1',
    height: '1px',
    backgroundColor: '#000',
  },
});

const DividerWithText: FunctionComponent<{ centerText: string; style?: object }> = ({
  centerText = '',
  style = {},
}) => {
  const classes = useStyles();

  return (
    <div className={classes.separator} style={style}>
      <div className={classes.line} />
      <span className={classes.hubHeading}>{centerText}</span>
      <div className={classes.line} />
    </div>
  );
};

export default DividerWithText;
