import {
  DialogContent,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography,
} from '@material-ui/core';
import {CancelRounded as CancelRoundedIcon, CheckCircleRounded as CheckCircleRoundedIcon, RemoveCircleRounded as RemoveCircleRoundedIcon} from '@material-ui/icons';
import React, {useState} from 'react';

import {amber_green, amber_grey, amber_red} from '../../style';
import {BaseDialog} from "../util";
import {Button} from "../util/form2/Button";

const useStyles = makeStyles({
  header: {
    fontSize: 14,
    fontWeight: 600,
  },
  cell: {
    fontSize: 'medium',
  },
});

export interface CheckResultMap {
  [index: string]: SelfTestRow;
}

export type SelfTestRow = {
  name: string;
  passed: string;
  checks: {
    check_name: string;
    error?: string[] | null;
    data?: {};
    passed: string;
  }[];
};

const TestMoreInfoComponent = ({row, setShowMoreInfo})=>{
  const useStyles = makeStyles({
    header: {
      fontSize: 14,
      fontWeight: 600,
    },
    cell: {
      fontSize: 14,
    },
  });
  const classes = useStyles();
  if (!row){
    return null;
  }
  const {checks} = row;
  const row_data_comps = {};
  if (checks){
    for (const check of checks){
      const values: any[] = [];
      if (check['data']){
        for (const data in check['data']){
          const value = check['data'][data];
          values.push({data, value});
        }
        row_data_comps[check['check_name']]=values;
      }
    }
  }

  return (
      <>
        <BaseDialog
            title={row.name}
            open={true}
            handleClose={() => setShowMoreInfo(false)}
        >
          <DialogContent>
            <Grid container
                  style={{
                    display: 'flex',
                    alignItems: 'left',
                    flexDirection: 'column',
                  }}>
              {
              checks && checks.map((check)=>{
                  return (
                      <>
                        <Grid item style={{ marginTop: 10 }}>
                          <Typography variant="h5">
                            {check.check_name}:
                            {check.passed === "PASS"?
                                <span style={{color: amber_green}}> PASSED</span>:
                                check.passed === "FAIL"?
                                <span style={{color: amber_red}}> FAILED</span>:<span style={{color: amber_grey}}> SKIPPED</span>}
                          </Typography>
                          {check.passed!=="PASS" && <Grid item style={{ marginTop: 10 }}>
                            <div>
                              <p style={{fontSize:'large', color: amber_grey}}>How to fix</p>
                              <div>
                                {check.error && check.error.length?(
                                    <ul style={{textAlign: 'left'}}>
                                      {check.error && (
                                          check.error.filter((err)=>err.length).map((err, index)=>{
                                            return <li key={index}>{err}</li>;
                                          })
                                      )}
                                    </ul>
                                ):'Unexpected error, please contact support@amber.ag or our support team at (401) 307-1485 for assistance.'}
                              </div>
                            </div>
                          </Grid>}
                          <Grid item style={{ marginTop: 20 }}>
                            {/*<p style={{fontSize:'large', color: amber_grey}}>Test output metrics</p>*/}
                            <TableContainer>
                              <Table>
                                <TableBody>
                                  {row_data_comps && row_data_comps[check.check_name] && row_data_comps[check.check_name].map((check_data)=>{
                                    return (
                                        <TableRow key={`${check_data.data}${check_data.value}`}>
                                          <TableCell>{check_data.data}</TableCell>
                                          <TableCell align="center">{check_data.value?check_data.value:'--'}</TableCell>
                                        </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </>
                      );
                })
            }
            </Grid>
          </DialogContent>
        </BaseDialog>
      </>
  );
};

export const TestResultsTable = ({ row_map }:{row_map: CheckResultMap}) => {
  const classes = useStyles();
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const [rowMoreInfo, setRowMoreInfo] = useState<SelfTestRow>();
  return (
      <>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" style={{ fontSize: 14 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.header}>Check Name</TableCell>
                <TableCell align="left" className={classes.header}>
                  Pass/Fail
                </TableCell>
                <TableCell align="left" className={classes.header}>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(row_map).map((row) => (
                <TableRow key={row[1].name}>
                  <TableCell align="left" component="th" scope="row" className={classes.cell}>
                    {row[1].name}
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    {row[1].passed ==="PASS" ? (
                      <CheckCircleRoundedIcon style={{ color: amber_green }} />
                    ) : row[1].passed==="FAIL"?(
                      <CancelRoundedIcon style={{ color: amber_red }} />
                    ):(<RemoveCircleRoundedIcon style={{ color: amber_grey }} />)}
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    {row[1].passed ==='FAIL' ?<Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 20, width: 90 }}
                        onClick={() => {
                          setRowMoreInfo(row[1]);
                          setShowMoreInfo(true);
                        }}
                    >
                      More Info
                    </Button>: ''
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {showMoreInfo && <TestMoreInfoComponent row={rowMoreInfo} setShowMoreInfo={setShowMoreInfo}/>}
      </>

  );
};
