import { AmberHoc, AmberHocProps } from '../../../util';
import {
  BinEventFragmentFragment as Result,
  GetGrainBinEventsDocument,
  GetGrainBinEventsQuery,
  GetGrainBinEventsQueryVariables,
  RemoveBinEventMutationVariables as Variables,
  withRemoveBinEvent as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithRemoveBinEventHocChildProps = {
  removeBinEvent: (variables: Variables) => Promise<Result[]>;
};
export const withRemoveBinEventHoc: AmberHoc<{}, WithRemoveBinEventHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithRemoveBinEventHocChildProps, typeof component>,
    WithRemoveBinEventHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        removeBinEvent: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const { grain_bin_id } = variables;
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { removeBinEvent: result } = data;
              if (!result) {
                return;
              }
              proxy.writeQuery<GetGrainBinEventsQuery, GetGrainBinEventsQueryVariables>({
                query: GetGrainBinEventsDocument,
                data: { __typename: 'Query', latest_grain_bin_events: result },
                variables: { grain_bin_id },
              });
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.removeBinEvent || [];
        },
      };
    },
  })(component as any);
