import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { AccountFragmentFragment, withGetAccount as HOC } from '../__generated';

export type WithGetAccountHocOwnProps = {
  account_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetAccountHocChildProps = {
  loading: boolean;
  refecthAccount: () => Promise<any>;
  account: AccountFragmentFragment | null;
};

export const withGetAccountHoc: AmberHoc<WithGetAccountHocOwnProps, WithGetAccountHocChildProps> = (
  component
) =>
  HOC<
    AmberHocProps<WithGetAccountHocOwnProps, WithGetAccountHocChildProps, typeof component>,
    WithGetAccountHocChildProps
  >({
    options: ({ onError, account_id }) => ({
      onError,
      variables: { account_id },
      errorPolicy: onError ? 'all' : 'none',
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          refecthAccount: () => Promise.resolve(),
          loading: true,
          account: null,
        };
      }
      return {
        refecthAccount: () => data.refetch(),
        loading: loading || false,
        account: data.account || null,
      };
    },
  })(component as any);
