import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ComputedForecastHourV2FragmentFragment,
  ForecastDayV2FragmentFragment,
} from '../../../api';
import { amber_grey_accent, amber_light_green, gray_shade_3 } from '../../../style';
import { calculateRunwindowDuration, getDurationText } from '../../../util';
import { tooltipContainerStyles } from '../daily-forecast/WeatherForecastPlot';
import {
  drawTopBlocksToShowDaysLabels,
  getNightPlotBandsPostions,
  getTooltipInfo,
  SERIES,
  WEATHER_CHART_COLORS,
} from './IndividualRunwindowWeatherPlotHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  runWindowPlot: {
    width: '100%',
    '& .highcharts-container': {
      '&>span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },
  tooltipContainer: {
    ...tooltipContainerStyles,
    textAlign: 'start',
    fontSize: 10,
  },
  colorDot: {
    width: '12px',
    height: '12px',
    borderRadius: '50% 50%',
  },
  legendLabel: {
    marginLeft: 5,
  },
  '.highcharts-container': {
    width: '100% !important',
  },
}));

export const IndividualRunwindowWeatherPlot = ({
  grain_bin_location_timezone,
  days,
  hourly_forecast_data,
  runWindows,
  showInitialSeriesAnimation = true,
}: {
  grain_bin_location_timezone: string;
  days: ForecastDayV2FragmentFragment[];
  hourly_forecast_data: ComputedForecastHourV2FragmentFragment[];
  runWindows: { start: DateTime; end: DateTime }[];
  showInitialSeriesAnimation?: boolean;
}) => {
  Highcharts.setOptions({
    time: {
      timezone: grain_bin_location_timezone,
    },
  });
  const noOfDays = days.length;
  const classes = useStyles({ noOfDays });
  const theme = useTheme();
  const [chart, setChart] = useState<any>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const startOfTheWeek = DateTime.fromISO(new Date(days[0].epoch_time).toISOString()).startOf(
    'day'
  );
  const endOfTheWeek = DateTime.fromISO(
    new Date(days[days.length - 1].epoch_time).toISOString()
  ).endOf('day');

  const seven_days_hourly_forecast_data = hourly_forecast_data.filter(
    (forecastHour) =>
      forecastHour.epoch_time.getTime() >= startOfTheWeek.toMillis() &&
      forecastHour.epoch_time.getTime() <= endOfTheWeek.toMillis()
  );

  const { utcHours, hours, temps, humidities, emcs, precip_intensity_inph_list } = useMemo(() => {
    const hours: DateTime[] = [];
    const utcHours: number[] = [];
    const temps: number[] = [];
    const humidities: number[] = [];
    const emcs: number[] = [];
    const precip_intensity_inph_list: number[] = [];
    for (const hourlyForcast of seven_days_hourly_forecast_data) {
      const { epoch_time, temp_f, humidity_rh, emc, precip_intensity_inph } = hourlyForcast;

      if (
        epoch_time &&
        _.isNumber(temp_f) &&
        _.isNumber(humidity_rh) &&
        _.isNumber(emc) &&
        _.isNumber(precip_intensity_inph)
      ) {
        const dt = DateTime.fromMillis(epoch_time.getTime());
        utcHours.push(epoch_time.getTime());
        hours.push(dt);
        temps.push(temp_f);
        humidities.push(humidity_rh);
        emcs.push(emc);
        precip_intensity_inph_list.push(precip_intensity_inph);
      }
    }
    return {
      utcHours,
      hours,
      temps,
      humidities,
      emcs,
      precip_intensity_inph_list,
    };
  }, [seven_days_hourly_forecast_data]);

  const precip_intensity_inph_max = _.max(precip_intensity_inph_list) || 0;
  const asOfDate = DateTime.local();
  let asOfTickValue = asOfDate;

  hours.forEach((dt) => {
    const hasSameDate =
      asOfDate.day === dt.day &&
      asOfDate.hour === dt.hour &&
      asOfDate.month === dt.month &&
      asOfDate.year === dt.year;
    if (hasSameDate) {
      asOfTickValue = dt;
    }
  });

  const hasExistSmallRunWindow = runWindows.some((runwindow) => {
    const diffInHrs = runwindow.end.diff(asOfTickValue, 'hour').toObject().hours;
    return diffInHrs <= 10 && diffInHrs > 0;
  });

  const nightPlotBandsPostions = getNightPlotBandsPostions(hours, days);
  console.log('nightPlotBandsPostions', nightPlotBandsPostions);
  const runWindowPlotBandsPostions = runWindows
    .map(({ start, end }) => {
      const duration = calculateRunwindowDuration({ start, end });
      if (duration <= 0) return null;
      const durationText = getDurationText(duration, true);
      return {
        label: {
          text: durationText,
          align: 'center',
          y: 15,
          style: {
            fontFamily: 'Source Sans Pro,sans-serif',
            // color: amber_grey_accent,
            fontSize: 12,
            fontWeight: 600,
          },
        },
        color: amber_light_green,
        from: start.ts,
        to: end.ts,
        borderColor: 'black',
        borderWidth: 1,
        zIndex: 5,
      };
    })
    .filter((runWindowPlot) => runWindowPlot !== null);

  // minDate & maxDate
  const firstDayToShow =
    days && days[0]
      ? DateTime.fromMillis(new Date(days[0].epoch_time).getTime())
      : DateTime.local();
  const minDate = firstDayToShow
    ? firstDayToShow.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    : null;
  const lastDayToShow =
    days && days[days.length - 1]
      ? DateTime.fromMillis(new Date(days[days.length - 1].epoch_time).getTime())
      : null;
  const maxDate = lastDayToShow
    ? lastDayToShow.set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      })
    : null;

  const minHrsDiff = 8;
  const showNowLabelAtRight = minDate
    ? asOfTickValue.diff(minDate, 'hour').toObject().hours <= minHrsDiff
    : false;

  useEffect(() => {
    if (chart) {
      drawTopBlocksToShowDaysLabels(chart, days);
      chart.redraw();
    }
  }, [runWindows[0], noOfDays]);

  const options = {
    credits: false,
    chart: {
      type: 'spline',
      spacingTop: 30,
      spacingLeft: 1,
      spacingRight: 0,
      spacingBottom: 1,
      height: 102,
      events: {
        load() {
          setChart(this);
          drawTopBlocksToShowDaysLabels(this, days);
        },
      },
      reflow: false,
      ignoreHiddenSeries: false,
      animation: false,
      backgroundColor: gray_shade_3,
      borderRadius: '8px',
    },
    title: {
      text: '',
      y: 0,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        enabled: false,
        format: '{value:%l:%M %p }', // '{value:%Y-%b-%e %l:%M %p }',
      },
      min: minDate ? minDate.toMillis() : null,
      max: maxDate ? maxDate.toMillis() : null,
      gridLineWidth: 1,
      tickLength: 0,
      gridLineColor: WEATHER_CHART_COLORS.GRID_LINE_COLOR,
      plotBands: [...nightPlotBandsPostions, ...runWindowPlotBandsPostions],
      crosshair: {
        width: 1,
        color: WEATHER_CHART_COLORS.CROSS_HAIR_COLOR,
      },
      plotLines: [
        {
          color: WEATHER_CHART_COLORS.AS_OF_HOUR_VERTICAL_LINE,
          value: asOfTickValue.toMillis(),
          zIndex: 1,
          width: 1,
          dashStyle: 'longdashdot',
          label: {
            text: 'Now',
            verticalAlign: 'bottom',
            textAlign: 'center',
            y: -6,
            x: showNowLabelAtRight ? 13 : -13,
            rotation: 0,
            style: {
              fontFamily: 'Source Sans Pro,sans-serif',
              color: amber_grey_accent,
              fontSize: 10,
            },
          },
        },
      ],
    },
    yAxis: [
      {
        title: {
          text: '',
        },
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
        tickAmount: 6,
      },
      {
        title: {
          text: '',
        },
        labels: {
          enabled: false,
        },
        min: 0,
        max: 1,
        opposite: true,
        tickAmount: 6,
        gridLineWidth: 0,
      },
      {
        title: {
          text: '',
        },
        labels: {
          enabled: false,
        },
        opposite: true,
        tickAmount: 6,
        gridLineWidth: 0,
      },
      {
        visible: false,
        title: {
          text: 'Liquid Preip.',
        },
        min: null,
        max: precip_intensity_inph_max <= 0.3 ? 0.3 : null,
        labels: {
          formatter() {
            return this.value === 0 ? '0 in' : `${Number(this.value).toFixed(2)} in`;
          },
          style: {
            fontFamily: 'Source Sans Pro,sans-serif',
            fontSize: '12px',
          },
        },
      },
    ],
    tooltip: {
      outside: true,
      shared: true,
      useHTML: true,
      backgroundColor: 'rgba(255,255,255,0)',
      borderWidth: 0,
      borderRadius: 0,
      shadow: false,
      style: {
        zIndex: 9999,
      },
      formatter() {
        const tooltipHeading = Highcharts.dateFormat('%l:%M %p', this.x);
        const seriesdataPointInfo =
          this.points &&
          this.points
            .filter((category) => category.series.visible)
            .map((category) => getTooltipInfo(category))
            .filter((info) => info !== '')
            .join('');
        return `<div class="${classes.tooltipContainer}">
        <b>${tooltipHeading}</b><br/>${seriesdataPointInfo}
        </div>`;
      },
      followPointer: true,
      followTouchMove: true,
      stickOnContact: true,
    },
    series: [
      {
        name: SERIES.TEMPRATURE,
        yAxis: 0,
        data: utcHours.map((timestamp, idx) => ({
          x: timestamp,
          y: temps[idx],
        })),
        color: WEATHER_CHART_COLORS.TEMP_SERIES_COLOR,
      },
      {
        name: SERIES.HUMIDITY,
        yAxis: 1,
        data: utcHours.map((timestamp, idx) => ({
          x: timestamp,
          y: humidities[idx],
        })),
        color: WEATHER_CHART_COLORS.HUMIDITY_SERIES_COLOR,
      },
      {
        name: SERIES.EMC,
        yAxis: 2,
        data: utcHours.map((timestamp, idx) => ({
          x: timestamp,
          y: emcs[idx],
        })),
        color: WEATHER_CHART_COLORS.EMC_SERIES_COLOR,
      },
      {
        name: SERIES.PRECIPITAION,
        yAxis: 3,
        type: 'area',
        data: utcHours.map((timestamp, idx) => ({
          x: timestamp,
          y: precip_intensity_inph_list[idx],
        })),
        color: WEATHER_CHART_COLORS.LIQUID_PRECIP_SERIES_COLOR,
        fillOpacity: 0.25,
      },
    ],
    plotOptions: {
      series: {
        animation: showInitialSeriesAnimation,
        showInLegend: false,
        selected: true,
        lineWidth: 1,
        marker: {
          enabledThreshold: 6,
        },
      },
    },
  };

  return (
    <div className={classes.runWindowPlot}>
      <HighchartsReact highcharts={Highcharts} options={options} style={{ overflowX: 'auto' }} />
    </div>
  );
};
