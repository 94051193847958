import { Button, IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { mobilebar_height } from '../../util';

const useStyles = makeStyles({
  root: { marginBottom: 10 },
  mobileRoot: { marginBottom: 10 + mobilebar_height },
  bold: { fontWeight: 'bold' },
});

const refresh = () => location.reload();

export const RefreshSnackbar: React.FunctionComponent<{
  mobile_offset: boolean;
  open: boolean;
  handleClose: () => void;
}> = ({ mobile_offset, open, handleClose }) => {
  const classes = useStyles();
  const message = (
    <div style={{ textAlign: 'start' }}>
      <p style={{ fontSize: 14, margin: 0, fontWeight: 'bold' }}>
        A new version of the Dashboard is available
      </p>
      <p style={{ fontSize: 12, margin: 0 }}>
        (You may need to hit refresh 2 or 3 times for this message to go away)
      </p>
    </div>
  );
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleClose}
      message={message}
      // disable close by outside click
      ClickAwayListenerProps={{ onClickAway: () => {} }}
      className={mobile_offset ? classes.mobileRoot : classes.root}
      action={[
        <Button
          key="refresh"
          color="secondary"
          size="small"
          onClick={refresh}
          className={classes.bold}
        >
          Refresh
        </Button>,
        <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
          <Close />
        </IconButton>,
      ]}
    />
  );
};
