import { makeStyles } from '@material-ui/core';
import React from 'react';
import { black, light_gray_4 } from '../../../../core/src/style';

const useStyles = makeStyles(() => ({
  countText: {
    width: 20,
    height: 20,
    background: `${light_gray_4} 0% 0% no-repeat padding-box`,
    color: black,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    marginLeft: 6,
  },
}));

export const CountBadge = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.countText}>{children}</div>;
};
