import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetContainersAerationScheduleQuery as Result,
  withGetContainersAerationSchedule as HOC,
} from '../__generated';

export type WithGetContainersAerationScheduleProps = {
  grain_bin_ids: number[];
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetContainersAerationScheduleHocChildProps = {
  aeration_schedules_loading: boolean;
  containers_aeration_schedule: Result['containers_aeration_schedule'];
  aerationSchedulesRefetch: () => Promise<any>;
};
export const withGetContainersAerationScheduleHoc: AmberHoc<
  WithGetContainersAerationScheduleProps,
  WithGetContainersAerationScheduleHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetContainersAerationScheduleProps,
      WithGetContainersAerationScheduleHocChildProps,
      typeof component
    >,
    WithGetContainersAerationScheduleHocChildProps
  >({
    options: ({ grain_bin_ids, onError }) => ({
      onError,
      variables: { grain_bin_ids },
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      context: { important: true }, // flag to prevent from graphql batching
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          aeration_schedules_loading: true,
          containers_aeration_schedule: [] as Result['containers_aeration_schedule'],
          aerationSchedulesRefetch: () => Promise.resolve(),
        };
      }
      return {
        aeration_schedules_loading: loading || false,
        containers_aeration_schedule:
          (data.containers_aeration_schedule as Result['containers_aeration_schedule']) || [],
        aerationSchedulesRefetch: () => data.refetch(),
      };
    },
  })(component as any);
