import { MenuItem } from '@material-ui/core';
import React, { FunctionComponent, memo } from 'react';
import * as yup from 'yup';

import { AccountFragmentFragment } from '../../../api/graphql';
import { ContainerTypeLegacy } from '../../../util';
import { Select, SelectProps } from './Select';

export const yup_container_type = yup
  .number()
  .typeError('A number is required')
  .oneOf([
    null,
    ContainerTypeLegacy.bin,
    ContainerTypeLegacy.barge,
    ContainerTypeLegacy.pile,
  ]) as yup.NumberSchema<ContainerTypeLegacy | null>;

const formatContainerType = (
  container_type: ContainerTypeLegacy,
  placeholder = 'Grain Container'
): string => {
  switch (container_type) {
    case ContainerTypeLegacy.bin:
      return 'Grain Bin';
    case ContainerTypeLegacy.barge:
      return 'Barge';
    case ContainerTypeLegacy.pile:
      return 'Ground Pile';
    default:
      return placeholder;
  }
};

export const SelectContainerType: FunctionComponent<
  { account: AccountFragmentFragment } & SelectProps
> = memo(({ account: { grain_bin_support, barge_support, pile_support }, ...props }) => {
  return (
    <Select {...props}>
      {grain_bin_support ? (
        <MenuItem value={ContainerTypeLegacy.bin}>
          {formatContainerType(ContainerTypeLegacy.bin)}
        </MenuItem>
      ) : null}
      {barge_support ? (
        <MenuItem value={ContainerTypeLegacy.barge}>
          {formatContainerType(ContainerTypeLegacy.barge)}
        </MenuItem>
      ) : null}
      {pile_support ? (
        <MenuItem value={ContainerTypeLegacy.pile}>
          {formatContainerType(ContainerTypeLegacy.pile)}
        </MenuItem>
      ) : null}
    </Select>
  );
});

export const yup_container_id = yup
  .number()
  .typeError('A number is required')
  .integer();

export const SelectContainerId: FunctionComponent<
  { account: AccountFragmentFragment; container_type: ContainerTypeLegacy; label?: string } & Omit<
    SelectProps,
    'label'
  >
> = memo(
  ({ account: { barge_links, pile_links, grain_bin_links }, container_type, label, ...props }) => {
    return (
      container_type && (
        <Select {...props} label={label || formatContainerType(container_type)} fullWidth>
          <MenuItem value={-1}>Create New {formatContainerType(container_type)}</MenuItem>
          {container_type === ContainerTypeLegacy.barge &&
            barge_links.map(({ barge: { barge_id, alias } }) => (
              <MenuItem key={`${ContainerTypeLegacy.barge}-${barge_id}`} value={barge_id}>
                {alias}
              </MenuItem>
            ))}
          {container_type === ContainerTypeLegacy.bin &&
            grain_bin_links.map(({ grain_bin: { grain_bin_id, alias } }) => (
              <MenuItem key={`${ContainerTypeLegacy.bin}-${grain_bin_id}`} value={grain_bin_id}>
                {alias}
              </MenuItem>
            ))}
          {container_type === ContainerTypeLegacy.pile &&
            pile_links.map(({ pile: { pile_id, alias } }) => (
              <MenuItem key={`${ContainerTypeLegacy.pile}-${pile_id}`} value={pile_id}>
                {alias}
              </MenuItem>
            ))}
        </Select>
      )
    );
  }
);
