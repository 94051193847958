import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  FanRunWindowRecommendedOption,
  GrainContainerAerationRunFragmentFragment,
  RecommendationWindowsFragmentFragment,
  RecommRunWindowsExperiments,
  withGetWeatherHistoryHoc,
  WithGetWeatherHistoryHocChildProps,
} from '../../../../../core/src/api';
import { ContainerTypeLegacy } from '../../../../../core/src/util';
import { CenteredSpinner } from '../../spinner';
import { DailyWeatherAndAerationHistoryTable } from './DailyWeatherAndAerationHistoryTable';

export type AerationHistoryRunWinow = {
  start_epoch: Date;
  end_epoch: Date;
  recommendation_type: FanRunWindowRecommendedOption;
};

export type FanAutomationPeriod = { start: Date; end: Date; mode: FanRunWindowRecommendedOption };

const getAerationHistoryRunWinows = (
  aeration_run_history: GrainContainerAerationRunFragmentFragment[]
): AerationHistoryRunWinow[] => {
  if (aeration_run_history.length === 0) return [];
  return aeration_run_history.map((aeration_run) => {
    const recommendation_type =
      aeration_run.fan_runs && aeration_run.fan_runs[0]
        ? aeration_run.fan_runs[0].start_recommendation_type
          ? aeration_run.fan_runs[0].start_recommendation_type
          : FanRunWindowRecommendedOption.Custom
        : FanRunWindowRecommendedOption.Custom;
    return {
      recommendation_type,
      start_epoch: new Date(aeration_run.start_epoch),
      end_epoch: aeration_run.end_epoch ? new Date(aeration_run.end_epoch) : new Date(),
    };
  });
};

const DailyWeatherAndAerationHistoryBase: FunctionComponent<
  {
    container_id: number;
    container_type: ContainerTypeLegacy;
    prev_week_no: number;
    grain_bin_location_timezone: string;
    recommendation_type: string | null;
    has_enable_fan_guidance: boolean;
    fan_guidance_start_date: Date | null;
    fan_guidance_end_date: Date | null;
    goPrevWeek: () => void;
    goNextWeek: () => void;
    goToPresentView: () => void;
    prevWeekNo: number;
    recommendation_windows_data: RecommendationWindowsFragmentFragment;
    recommendation_windows_for_experiments?: RecommRunWindowsExperiments[];
    refetch_grain_bin_container?: () => void;
    refetch_experiment_run_windows?: (variables) => void;
    setCfmValues?: (cfm_values) => void;
    setBoundValues?: (bound_values) => void;
    boundFormValues?: {
      lower_bound: number | undefined;
      upper_bound: number | undefined;
    };
    experimentsRunWindowsFormValues?: {
      cfm_scaling: number | undefined;
      cfm_offset: number | undefined;
      cfm_min: number | undefined;
      cfm_max: number | undefined;
      cfm: number | undefined;
    };
    expExpandedStates?: any;
    hideExpCharts?: boolean;
    setExpExpandedStates?: React.Dispatch<React.SetStateAction<any>>;
  } & WithGetWeatherHistoryHocChildProps
> = ({
  container_id,
  weather_history_loading,
  grain_container,
  grain_bin_location_timezone,
  container_type,
  goPrevWeek,
  goNextWeek,
  prevWeekNo,
  goToPresentView,
  recommendation_windows_data,
  recommendation_windows_for_experiments,
  refetch_grain_bin_container,
  refetch_experiment_run_windows,
  setCfmValues,
  experimentsRunWindowsFormValues,
  expExpandedStates,
  setExpExpandedStates,
  setBoundValues,
  boundFormValues,
  hideExpCharts = false,
  recommendation_type,
  has_enable_fan_guidance,
  fan_guidance_start_date,
  fan_guidance_end_date,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const [hasIncompleteWeatherData, setHasIncompleteWeatherData] = useState(false);

  useEffect(() => {
    if (weather_history_loading === false && grain_container && grain_container.telemetry) {
      const { weather_history } = grain_container.telemetry;
      if (weather_history === null) {
        setHasIncompleteWeatherData(true);
      } else {
        const hasInvalidWeatherData =
          weather_history.daily_weather_history &&
          weather_history.daily_weather_history.length !== 7;
        setHasIncompleteWeatherData(Boolean(hasInvalidWeatherData));
      }
    }
  }, [weather_history_loading]);

  if (weather_history_loading) {
    return (
      <div style={{ width: isMobile ? '100%' : '970px', height: 660 }}>
        <CenteredSpinner fadeIn="none" />
      </div>
    );
  }

  if (!grain_container) return null;

  const {
    telemetry: { weather_history },
  } = grain_container;
  const aerationRuns: GrainContainerAerationRunFragmentFragment[] =
    weather_history && weather_history.aeration_run_history
      ? weather_history.aeration_run_history
      : [];
  const aeration_run_history =
    weather_history && weather_history.aeration_run_history
      ? getAerationHistoryRunWinows(weather_history.aeration_run_history)
      : [];
  const unique_durations_map = new Map<string, FanAutomationPeriod>();
  const allAutomationRunDurations = weather_history
    ? weather_history.aeration_run_history
        .filter(
          ({ enabled_fan_guidance, fan_guidance_start_date, fan_guidance_end_date }) =>
            enabled_fan_guidance && fan_guidance_start_date && fan_guidance_end_date
        )
        .map(({ fan_guidance_start_date, fan_guidance_end_date, fan_runs }) => {
          const recommendation_type =
            fan_runs && fan_runs[0]
              ? fan_runs[0].start_recommendation_type
                ? fan_runs[0].start_recommendation_type
                : FanRunWindowRecommendedOption.Custom
              : FanRunWindowRecommendedOption.Custom;
          const duration: {
            start: Date | null;
            end: Date | null;
            mode: FanRunWindowRecommendedOption;
          } = {
            start: fan_guidance_start_date ? new Date(fan_guidance_start_date) : null,
            end: fan_guidance_end_date ? new Date(fan_guidance_end_date) : null,
            mode: recommendation_type,
          };
          const key = `${duration.start}-${duration.end}`;
          if (duration.start !== null && duration.end !== null && !unique_durations_map.has(key)) {
            unique_durations_map.set(key, {
              start: duration.start,
              end: duration.end,
              mode: duration.mode,
            });
          }

          return duration;
        })
    : [];
  const fanAutomationPeriods = Array.from(unique_durations_map.values());

  console.log('fanAutomationPeriods', {
    allAutomationRunDurations,
    fanAutomationPeriods,
  });

  return (
    <>
      {weather_history &&
      weather_history.daily_weather_history &&
      weather_history.hourly_weather_history ? (
        <DailyWeatherAndAerationHistoryTable
          dailyForecast={weather_history.daily_weather_history as any}
          hourlyForecast={weather_history.hourly_weather_history as any}
          aeration_history={aeration_run_history}
          recommendation_windows_data={recommendation_windows_data}
          recommendation_windows_for_experiments={recommendation_windows_for_experiments}
          fanAutomationPeriods={fanAutomationPeriods}
          grain_bin_location_timezone={grain_bin_location_timezone}
          container_type={container_type}
          goToPresentView={goToPresentView}
          goPrevWeek={goPrevWeek}
          goNextWeek={goNextWeek}
          prevWeekNo={prevWeekNo}
          aerationRuns={aerationRuns}
          containerId={container_id}
          hasIncompleteWeatherData={hasIncompleteWeatherData}
          refetch_grain_bin_container={refetch_grain_bin_container}
          refetch_experiment_run_windows={refetch_experiment_run_windows}
          setCfmValues={setCfmValues}
          setBoundValues={setBoundValues}
          experimentsRunWindowsFormValues={experimentsRunWindowsFormValues}
          boundFormValues={boundFormValues}
          expExpandedStates={expExpandedStates}
          setExpExpandedStates={setExpExpandedStates}
          recommendation_type={recommendation_type}
          has_enable_fan_guidance={has_enable_fan_guidance}
          fan_guidance_start_date={fan_guidance_start_date}
          fan_guidance_end_date={fan_guidance_end_date}
        />
      ) : null}
    </>
  );
};

export const DailyWeatherAndAerationHistory = withGetWeatherHistoryHoc(
  DailyWeatherAndAerationHistoryBase
);
