import {
  AmberNotification,
  createSession,
  endSession,
  newVersion,
  SessionState,
  SourceType,
  updateApnsConnection,
  workerBroadcast,
  workerMessage,
  WorkerMessageType,
} from '@amber-ui/core/lib';
import {
  GetGrainBinFanSettingsDocument,
  GetGrainBinFanSettingsQuery,
  GetGrainBinFanSettingsQueryVariables,
  GetViewerDocument,
  GetViewerQuery,
} from '@amber-ui/core/lib/api/graphql/__generated';
import { ApolloClient } from 'apollo-client';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { sendMessage, setServiceWorkerViewer } from './init';

type StateWithSelected = {
  session: SessionState;
};
const selectedSelector = <T extends StateWithSelected>({ session }: T): SessionState => session;

const onNotification = async (apollo_client: ApolloClient<any>, note: AmberNotification) => {
  switch (note.source_type) {
    case SourceType.fc: {
      const { grain_bin_id } = note.params;
      await apollo_client.query<GetGrainBinFanSettingsQuery, GetGrainBinFanSettingsQueryVariables>({
        query: GetGrainBinFanSettingsDocument,
        variables: { grain_bin_id },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });
      return;
    }
  }
};

function* workerMessageSaga(
  apollo_client: ApolloClient<any>,
  { payload: { message } }: ReturnType<typeof workerMessage>
) {
  switch (message.message_type) {
    case WorkerMessageType.logout: {
      const session: SessionState = yield select(selectedSelector);
      if (session.user_id !== null) {
        yield put(endSession({ propagate: false }));
      }
      return;
    }
    case WorkerMessageType.auth: {
      const viewer = message.payload.viewer;
      const session: SessionState = yield select(selectedSelector);
      if (session.user_id === viewer.user_id) {
        return;
      }
      if (session.user_id && session.user_id !== viewer.user_id) {
        yield put(endSession({ propagate: true }));
      }
      apollo_client.writeQuery({
        query: GetViewerDocument,
        data: { viewer },
      });
      yield put(createSession({ viewer, propagate: false }));
      return;
    }
    case WorkerMessageType.notify:
      yield onNotification(apollo_client, message.payload);
      return;
    case WorkerMessageType.activated: {
      try {
        const result = apollo_client.readQuery<GetViewerQuery>({
          query: GetViewerDocument,
        });
        if (!result || !result.viewer) {
          return;
        }
        yield setServiceWorkerViewer(result.viewer, false);
      } catch (err) {
        // pass
      }
      return;
    }
    case WorkerMessageType.ios_register: {
      const session: SessionState = yield select(selectedSelector);
      if (session.user_id) {
        yield updateApnsConnection({
          user_id: session.user_id,
          device_token: message.payload.device_token,
        });
      }
      return;
    }
    case WorkerMessageType.new_version: {
      // open dialog
      yield put(newVersion());
      return;
    }
  }
}

function* workerBroadcastSaga({ payload: { message } }: ReturnType<typeof workerBroadcast>) {
  yield sendMessage(message);
}

export function* workerWatcher(apollo_client) {
  // Whenever action getType(workerMessage) is get dispatched then workerMessageSaga will invoke
  yield takeLatest(getType(workerMessage), workerMessageSaga, apollo_client);
  if (navigator.serviceWorker) {
    yield takeEvery(getType(workerBroadcast), workerBroadcastSaga);
  }
}
