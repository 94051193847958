import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import { green, yellow } from '@material-ui/core/colors';
import { TouchApp, ToysOutlined } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFanStartTriggered } from '../../../action';

import { FanControllerStateValueNext } from '../../../api';
import { amber_grey, white } from '../../../style';
import { isNoEnd } from '../../../util';
import { formatDate } from './BinFanSettings';
import { FanStatusValues } from './FanControls';

const formatTime = (value) => value.toFormat('h:mm a');

const styles = createStyles({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: { marginRight: 10 },
  text: { color: white },
  light: {
    fontWeight: 300,
  },
});

type NextAerationCardProps = WithStyles<typeof styles> & {
  fan_count: number;
  any_disabled: boolean;
  fan_running: boolean;
  run_window: { start_epoch: Date; end_epoch: Date } | null;
  override?: boolean;
  fan_statuses: FanStatusValues[];
};

const RawNextAerationCard = ({
  fan_count,
  any_disabled,
  fan_running,
  run_window,
  override,
  classes,
  fan_statuses,
}: NextAerationCardProps) => {
  const [isFanStopTriggered, isFanStartTriggered] = useSelector(
    ({ global_state: { isFanStopTriggered, isFanStartTriggered } }) => [
      isFanStopTriggered,
      isFanStartTriggered,
    ]
  );
  const dispatch = useDispatch();
  const fan_status = fan_statuses && fan_statuses.length > 0 ? fan_statuses[0].status : '';
  const start = run_window && new Date(run_window.start_epoch).getTime();
  const end = run_window && new Date(run_window.end_epoch).getTime();
  const now = run_window && new Date().getTime();
  const before_run_window = now && start && now < start;
  const in_run_window = now && start && end && now > start && now < end;
  let background_color = amber_grey;
  if (override || any_disabled) {
    background_color = yellow['700'];
  } else if (fan_running) {
    background_color = green['500'];
  }

  let fan_text: JSX.Element | null = null;

  useEffect(() => {
    if (fan_running && fan_status === FanControllerStateValueNext.Scheduled) {
      dispatch(setIsFanStartTriggered({ isFanStartTriggered: false }));
    }
  }, [fan_status, fan_running]);
  // ? this log added to debug fan state transition
  // console.log(
  //   'fan state transition ->',
  //   `fan_running:${fan_running} in_run_window:${in_run_window} isFanStopTriggered:${isFanStopTriggered} fan_status:${fan_status}`
  // );

  const setFanTextWhenFanRunning = () => {
    if (override) {
      fan_text = (
        <>
          <TouchApp className={classnames(classes.text, classes.icon)} />
          <Typography variant="h6" className={classes.text}>
            {fan_count === 1 ? 'Fan' : 'Fans'} running from a manual override
          </Typography>
        </>
      );
    } else {
      if (run_window) {
        if (in_run_window) {
          if (isFanStopTriggered) {
            fan_text = (
              <>
                <ToysOutlined className={classnames(classes.text, classes.icon)} />
                <Typography variant="h6" className={classes.text}>
                  {fan_count === 1 ? 'Fan' : 'Fans'} stopping... Allow up to 30 seconds
                </Typography>
              </>
            );
          } else if (!isNoEnd(run_window.start_epoch, run_window.end_epoch)) {
            fan_text = (
              <>
                <ToysOutlined className={classnames(classes.text, classes.icon)} />
                <Typography variant="h6" className={classes.text}>
                  {fan_count === 1 ? 'Fan is' : 'Fans are'} running until{' '}
                  {formatDate(
                    DateTime.fromMillis(new Date(run_window.end_epoch).getTime()),
                    DateTime.local()
                  )}{' '}
                  at {formatTime(DateTime.fromMillis(new Date(run_window.end_epoch).getTime()))}
                </Typography>
              </>
            );
          } else {
            fan_text = (
              <>
                <ToysOutlined className={classnames(classes.text, classes.icon)} />
                <Typography variant="h6" className={classes.text}>
                  {fan_count === 1 ? 'Fan is' : 'Fans are'} running with no scheduled end
                </Typography>
              </>
            );
          }
        }
      }
    }
  };

  const setFanTextWhenFanNotRunning = () => {
    if (override) {
      fan_text = (
        <>
          <TouchApp className={classnames(classes.text, classes.icon)} />
          <Typography variant="h6" className={classes.text}>
            {fan_count === 1 ? 'Fan' : 'Fans'} stopped by manual override
          </Typography>
        </>
      );
    } else {
      if (run_window) {
        if (in_run_window) {
          if (isFanStopTriggered) {
            fan_text = (
              <>
                <ToysOutlined className={classnames(classes.text, classes.icon)} />
                <Typography variant="h6" className={classes.text}>
                  Scheduled run interrupted
                </Typography>
              </>
            );
          } else if (
            fan_status === FanControllerStateValueNext.Scheduled ||
            fan_status === FanControllerStateValueNext.Updating
          ) {
            fan_text = (
              <>
                <ToysOutlined className={classnames(classes.text, classes.icon)} />
                <Typography variant="h6" className={classes.text}>
                  {fan_count === 1 ? 'Fan' : 'Fans'} starting... Allow up to 30 seconds
                </Typography>
              </>
            );
          } else if (fan_status === FanControllerStateValueNext.Ready && !isFanStartTriggered) {
            fan_text = (
              <>
                <ToysOutlined className={classnames(classes.text, classes.icon)} />
                <Typography variant="h6" className={classes.text}>
                  Scheduled run interrupted
                </Typography>
              </>
            );
          }
        } else if (before_run_window) {
          const total_hours =
            run_window &&
            Math.abs(run_window.end_epoch.getTime() - run_window.start_epoch.getTime()) / 36e5;
          const startEpochDt = DateTime.fromMillis(run_window.start_epoch.getTime());
          const endEpochDt = DateTime.fromMillis(run_window.end_epoch.getTime());
          fan_text = (
            <>
              <ToysOutlined className={classnames(classes.text, classes.icon)} />
              <Typography variant="h6" className={classes.text}>
                Next Aeration <span className={classes.light}>is scheduled at</span>{' '}
                {formatTime(DateTime.fromMillis(new Date(run_window.start_epoch).getTime()))},{' '}
                {formatDate(
                  DateTime.fromMillis(new Date(run_window.start_epoch).getTime()),
                  DateTime.local()
                )}{' '}
                <span className={classes.light}>for</span>{' '}
                {Math.round(endEpochDt.diff(startEpochDt, 'year').toObject().years) === 1
                  ? 'Forever'
                  : `${Math.round(total_hours)} hour${
                      total_hours && Math.round(total_hours) > 1 ? 's' : ''
                    }`}
              </Typography>
            </>
          );
        } else {
          fan_text = (
            <>
              <ToysOutlined className={classnames(classes.text, classes.icon)} />
              <Typography variant="h6" className={classes.text}>
                {fan_count === 1 ? 'Fan' : 'Fans'} Stopped
              </Typography>
            </>
          );
        }
      }
    }
  };

  if (fan_running) {
    setFanTextWhenFanRunning();
  } else {
    setFanTextWhenFanNotRunning();
  }

  if (!fan_text) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Card raised style={{ backgroundColor: background_color }}>
        <CardHeader title={<div className={classes.title}>{fan_text}</div>} />
      </Card>
    </Grid>
  );
};
export const NextAerationCard = withStyles(styles)(RawNextAerationCard);
