import { AmberHoc, AmberHocProps } from '../../../util';
import {
  UpdateBinStoragePeriodMutationVariables as Variables,
  // BinStoragePeriodFragmentFragment as Result,
  // GetGrainBinStoragePeriodQuery,
  // GetGrainBinStoragePeriodQueryVariables,
  // GetGrainBinStoragePeriodDocument,
  withUpdateBinStoragePeriod as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

type Result = {
  grain_bin_storage_cycle_id: number;
  grain_bin_id: number;
};

export type WithUpdateBinStoragePeriodHocChildProps = {
  /// TODO: update return type
  updateBinStoragePeriod: (variables: Variables) => any;
};
export const withUpdateBinStoragePeriodHoc: AmberHoc<
  {},
  WithUpdateBinStoragePeriodHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithUpdateBinStoragePeriodHocChildProps, typeof component>,
    WithUpdateBinStoragePeriodHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        updateBinStoragePeriod: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { updateBinStoragePeriod: result } = data;
              if (!result) {
                return;
              }
              console.log('Successfully mutated data with results: ', result);
              // proxy.writeQuery<GetGrainBinStoragePeriodQuery, GetGrainBinStoragePeriodQueryVariables>({
              //   query: GetGrainBinStoragePeriodDocument,
              //   data: { __typename: 'Query', latest_grain_bin_events: result },
              //   variables: { grain_bin_id: variables.grain_bin_id },
              // });
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { errors, data } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          return data.updateBinStoragePeriod || [];
        },
      };
    },
  })(component as any);
