import { IconButton, makeStyles } from '@material-ui/core';
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  Replay,
  ReplayOutlined,
} from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { FunctionComponent } from 'react';
import { gray_shade_5 } from '../../../../../core/src/style';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateRangeText: {
    fontSize: 16,
    fontWeight: 600,
    margin: '0px 6px',
  },
  buttons: {
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(0 0 0 / 4%)',
  },
  resetBtn: {
    width: 30,
    height: 30,
    padding: 5,
    backgroundColor: 'white',
    border: `1px solid #ededed`,
    borderRadius: 5,
    marginLeft: 10,
    cursor: 'pointer',
  },
});
export const WeatherChartNavigation: FunctionComponent<{
  startDayDate: DateTime | null;
  endDayDate: DateTime | null;
  goPrevWeek: () => void;
  goNextWeek: () => void;
  goToPresentView: () => void;
  prevWeekNo: number;
}> = ({ startDayDate, endDayDate, goPrevWeek, goNextWeek, prevWeekNo, goToPresentView }) => {
  const classes = useStyles();
  const isPresentViewSelected = prevWeekNo === 0;
  const getDateRangeText = (startDayDate: DateTime, endDayDate: DateTime) => {
    const isToday = DateTime.now().hasSame(startDayDate, 'day');
    return isToday
      ? `Today (${startDayDate.toFormat('LLL d')}) - ${endDayDate.toFormat('LLL d')}`
      : `${startDayDate.toFormat('LLL d')} - ${endDayDate.toFormat('LLL d')}`;
  };
  const dateRangeText =
    startDayDate && endDayDate ? getDateRangeText(startDayDate, endDayDate) : '';

  return (
    <div className={classes.root}>
      <div>
        <IconButton className={classes.buttons} onClick={goPrevWeek}>
          <ArrowBackIosRounded />
        </IconButton>
      </div>
      <div className={classes.dateRangeText}>{dateRangeText}</div>
      <div>
        <IconButton
          className={classes.buttons}
          onClick={goNextWeek}
          disabled={isPresentViewSelected}
        >
          <ArrowForwardIosRounded />
        </IconButton>
      </div>
      {!isPresentViewSelected && (
        <ReplayOutlined className={classes.resetBtn} onClick={goToPresentView} />
      )}
    </div>
  );
};
