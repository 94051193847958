import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';

export const Checkbox = ({ name, label, ...props }) => {
  const [field] = useField(name);
  return (
    <FormControlLabel
      control={<MuiCheckbox {...field} checked={field.value} />}
      label={label}
      {...props}
    />
  );
};
