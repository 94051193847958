import { useFormikContext } from 'formik';
import React, { CSSProperties, FunctionComponent, memo } from 'react';

import { AccountFragmentFragment } from '../../../api/graphql';
import { LocationMapSelect } from '../../location/LocationMapSelect';

export const SelectGrainBinMap: FunctionComponent<
  { account: AccountFragmentFragment; name: string } & {
    className?: string;
    style?: CSSProperties;
  }
> = memo(({ account: { grain_bin_links }, name, ...props }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const options = grain_bin_links.map(
    ({
      grain_bin: {
        grain_bin_id,
        alias,
        location: { latitude, longitude },
      },
    }) => ({
      value: grain_bin_id,
      label: alias,
      lat: latitude,
      lng: longitude,
    })
  );
  return (
    <LocationMapSelect
      value={values[name]}
      options={options}
      onChange={(next) => setFieldValue(name, next)}
      {...props}
    />
  );
});
