import React, { useState } from 'react';
import { RowCollapseIcon, RowExpandIcon } from '../../../../../../core/src/media';

export const ToggleEmptyExpandCollapseBtn = () => {
  const [toggleIcons, setToggleIcons] = useState(true);

  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        width: 16,
        height: 40,
        marginRight: 'var(--ag-cell-widget-spacing)',
        cursor: 'pointer',
        transform: toggleIcons ? undefined : 'rotate(180deg)',
      }}
      onClick={() => setToggleIcons(!toggleIcons)}
    >
      {toggleIcons ? <RowCollapseIcon /> : <RowExpandIcon />}
    </span>
  );
};
