import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DateTime, Settings } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { default_selected_state } from '../../../../core/src/reducer';
import {
  pollBackgroundAccountUpdateState,
  pollBackgroundFanControllerState,
  pollBackgroundGrainBinUpdateState,
  pollBackgroundRecommWindowsState,
  setShowCO2LevelWarningBanner,
  setShowGrainTicketWarningBanner,
  setUserTimeZone,
} from '../../action';
import { pollBackgroundFanGuidanceExtension } from '../../action/fan-guidance-extension';
import {
  ContainerType,
  ExperimentCfmValues,
  FanControllerStateValueNext,
  FanSettingsFragmentFragment,
  ForecastDayV2FragmentFragment,
  GetGrainBinContainerQuery,
  GrainBinFragmentFragment,
  UserRole,
  ViewerFragmentFragment,
  withGetGrainBinContainerHoc,
  WithGetGrainBinContainerHocChildProps,
  WithGetGrainBinDefaultExpValueHocChildProps,
  withGetGrainBinDefaultExpValuesHoc,
  withGetGrainBinExperimentCfmValuesHoc,
  WithGetGrainBinExperimentCfmValuesHocChildProps,
  withGetGrainBinFanSettingsHoc,
  withGetGrainBinHoc,
  WithGetGrainBinHocChildProps,
  withGetPromptFanGuidanceExtensionHoc,
  WithGetPromptFanGuidanceExtensionHocChildProps,
  withGetShowCo2LevelWarningHoc,
  WithGetShowCo2LevelWarningHocChildProps,
  withGetShowGrainTicketLevelDeviationWarningHoc,
  WithGetShowGrainTicketLevelDeviationWarningHocChildProps,
} from '../../api';
import {
  withGetGrainBinCfmSolutionHoc,
  WithGetGrainBinCfmSolutionHocChildProps,
} from '../../api/graphql/hoc/WithGetGrainBinCfmSolutionHoc';
import {
  ActiveHubsWithColorContext,
  ActiveStoragePeriodContext,
  GrainBinDataProvider,
  HubWithColorCode,
  WeatherDataProvider,
} from '../../contexts';
import { ContainerTypeLegacy } from '../../util';
import { DialogSpinner } from '../spinner';
import {
  AerationRuntimeSection,
  BinWeatherForecastCard,
  FanControlsCard,
  FanRunHistoryCard,
  NextAerationCard,
  PowerConsumptionCard,
} from './aeration';
import {
  FanControllerWithError,
  isQualifyingFanHeartbeatErrorCode,
  QualifyingFanHeartbeatErrorCode,
} from './aeration/FanControllerErrorDisplay';
import { FanStatusValues } from './aeration/FanControls';
import { RecommendationScheduleCard } from './aeration/recommendation-schedule';
import { ContainerDashboardLayout } from './BaseLayout';
import DiagnosticModeInfoSection from './DiagnosticModeInfoSection';
import { FutureStoragePeriodBanner } from './FutureStoragePeriodBanner';
import GrainBinBanners from './GrainBinBanners';
import { HistoricalStoragePeriodBanner } from './HistoricalStoragePeriodBanner';
import { SelectContainerPlaceholder } from './SelectContainerPlaceholder';
import { BinInteriorCard, SummaryCard } from './summary-card';
import { LINE_COLORS } from './summary-card/charts/LevelHistoryPlotHelpers';
import LevelSensingCard from './summary-card/LevelSensingCard';

const useStyles = makeStyles({});

const selectAccountId = ({ selected: { account_id } }) => account_id;
const sessionAccountId = ({ session: { account_id } }) => account_id;

type GrainBinDashboardProps = {
  grain_bin_id?: number | null | undefined;
  mobile_width?: boolean;
  pathname: string;
  url_base: string;
  viewer: ViewerFragmentFragment;
  current_account_id: number;
  goCreateBarge?: () => void;
  goCreatePile?: () => void;
  goCreateGrainBin?: () => void;
  onSelectContainer?: (args: any) => void;
};

export const GrainBinDashboard = ({
  grain_bin_id,
  mobile_width,
  pathname,
  url_base,
  viewer,
  current_account_id,
  goCreateBarge,
  goCreatePile,
  goCreateGrainBin,
  onSelectContainer,
}: GrainBinDashboardProps) => {
  const ctx = useContext(ActiveStoragePeriodContext);
  const activeStoragePeriod = ctx.activeStoragePeriod;
  const grainBinStorageCycleId: number | undefined =
    activeStoragePeriod && activeStoragePeriod.grain_bin_id === grain_bin_id
      ? activeStoragePeriod.grain_bin_storage_cycle_id
      : undefined;

  useEffect(() => {
    if (!grain_bin_id) {
      onSelectContainer &&
        onSelectContainer({
          container_id: default_selected_state.container_id,
          container_name: default_selected_state.container_name,
          container_type: default_selected_state.container_type,
        });
    }
  }, [grain_bin_id]);

  return (
    <ContainerDashboardLayout
      viewer={viewer}
      pathname={pathname}
      onSelectContainer={onSelectContainer}
      goCreateBarge={goCreateBarge}
      goCreatePile={goCreatePile}
      goCreateGrainBin={goCreateGrainBin}
      mobile_width={mobile_width}
    >
      {grain_bin_id ? (
        <GrainBinDashboardInner
          viewer={viewer}
          container_id={grain_bin_id}
          grain_bin_id={grain_bin_id}
          mobile_width={mobile_width}
          url_base={url_base}
          current_account_id={current_account_id}
          grain_bin_storage_cycle_id={grainBinStorageCycleId}
        />
      ) : (
        <SelectContainerPlaceholder />
      )}
    </ContainerDashboardLayout>
  );
};

type GrainBinDashboardInnerProps = Pick<GetGrainBinContainerQuery, 'grain_container'> & {
  grain_bin_fan_settings: FanSettingsFragmentFragment;
  grain_bin: GrainBinFragmentFragment;
  loading?: boolean;
  viewer: ViewerFragmentFragment;
  mobile_width?: boolean;
  url_base: string;
  current_account_id: number;
  grain_bin_storage_cycle_id: number | undefined;
};
const GrainBinDashboardInner = withGetGrainBinHoc(
  withGetGrainBinContainerHoc(
    withGetGrainBinFanSettingsHoc(
      withGetGrainBinCfmSolutionHoc(
        withGetPromptFanGuidanceExtensionHoc(
          withGetGrainBinExperimentCfmValuesHoc(
            withGetGrainBinDefaultExpValuesHoc(
              withGetShowCo2LevelWarningHoc(
                withGetShowGrainTicketLevelDeviationWarningHoc(
                  ({
                    grain_bin_fan_settings,
                    grain_bin,
                    refetch: refetchGrainBin,
                    refetch_grain_bin_container,
                    grain_container,
                    loading = false,
                    viewer,
                    mobile_width,
                    url_base,
                    grain_bin_cfm_solution,
                    current_account_id,
                    prompt_fan_guidance_extension,
                    grain_bin_experirment_cfm_values,
                    grain_bin_exp_cfm_values_loading,
                    grain_bin_default_experiment_bound_values,
                    default_exp_values_loading,
                    show_co2_level_warning,
                    show_co2_level_warning_loading,
                    show_grain_ticket_level_deviation_warning,
                    get_show_ticket_warning_loading,
                  }: GrainBinDashboardInnerProps &
                    WithGetGrainBinContainerHocChildProps &
                    WithGetGrainBinCfmSolutionHocChildProps &
                    WithGetPromptFanGuidanceExtensionHocChildProps &
                    WithGetGrainBinExperimentCfmValuesHocChildProps &
                    WithGetGrainBinHocChildProps &
                    WithGetGrainBinDefaultExpValueHocChildProps &
                    WithGetShowCo2LevelWarningHocChildProps &
                    WithGetShowGrainTicketLevelDeviationWarningHocChildProps) => {
                    const theme = useTheme();
                    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
                    const dispatch = useDispatch();
                    const {
                      isUserOnTempRecommWindowsPage,
                      showCO2WarningBanner,
                    }: {
                      isUserOnTempRecommWindowsPage: boolean;
                      showCO2WarningBanner: {
                        co2AlertSentOn: Date;
                        co2AlertType: string;
                      } | null;
                    } = useSelector(
                      ({
                        global_state: { isUserOnTempRecommWindowsPage, showCO2WarningBanner },
                      }) => ({
                        isUserOnTempRecommWindowsPage,
                        showCO2WarningBanner,
                      })
                    );
                    const [show_fan_guidance_ext_prompt, setShowFanGuidanceExtPrompt] = useState(
                      false
                    );
                    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    let cfmPerBu = null;
                    let fullBinCfmPerBu = null;
                    if (grain_bin_cfm_solution) {
                      const {
                        grain_bin_solution,
                        grain_bin_height_solution,
                      } = grain_bin_cfm_solution;
                      if (grain_bin_solution) {
                        const { cfm_per_bushel } = grain_bin_solution;
                        cfmPerBu = cfm_per_bushel;
                      }
                      if (grain_bin_height_solution) {
                        const { cfm_per_bushel } = grain_bin_height_solution;
                        fullBinCfmPerBu = cfm_per_bushel;
                      }
                    }

                    console.log('cfmPerBu & fullBinCfmPerBu', cfmPerBu, fullBinCfmPerBu);
                    console.log('grain_bin_cfm_solution', grain_bin_cfm_solution);

                    const [cfm_values, setCfmValues] = useState<ExperimentCfmValues | null>(
                      grain_bin_experirment_cfm_values
                    );
                    const [boundValues, setBoundValues] = useState<{
                      lower_bound: number;
                      upper_bound: number;
                    } | null>(null);
                    const [prevWeekNo, setPrevWeekNo] = useState(0);
                    const grainContainerId = grain_container ? grain_container.container_id : null;

                    console.log('cfm_values', cfm_values);
                    console.log('boundValues', boundValues);

                    useEffect(() => {
                      dispatch(
                        // Set user browser's local timezone
                        setUserTimeZone({
                          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                          offset: -new Date().getTimezoneOffset(),
                        })
                      );
                    }, []);

                    // Setting grain bin storage timezone
                    if (grain_bin) {
                      Settings.defaultZone = grain_bin.tz;
                      Settings.defaultLocale = 'en-US';
                    }

                    const activeStoragePeriodContext = useContext(ActiveStoragePeriodContext);
                    const activeStoragePeriod = activeStoragePeriodContext.activeStoragePeriod;

                    //
                    // If the bin doesn't match the storage cycle then set the active one to be undefined.
                    // This appears to reinitialize the storage period context, and base it off the current bin.
                    //
                    if (
                      grain_bin &&
                      activeStoragePeriod &&
                      activeStoragePeriod.grain_bin_id !== grain_bin.grain_bin_id
                    ) {
                      activeStoragePeriodContext.setActiveStoragePeriod(undefined);
                    }

                    const is_historical_period = Boolean(
                      activeStoragePeriod &&
                        activeStoragePeriod.end_date &&
                        activeStoragePeriod.end_date.getTime() <= new Date().getTime()
                    );
                    const is_historical_bin = Boolean(
                      activeStoragePeriod && !activeStoragePeriod.is_ongoing && is_historical_period
                    );

                    console.log('Active storage cycle:: ', activeStoragePeriod);
                    console.log('Current GrainBin: ', grain_bin);
                    const shouldShowFanRunCard =
                      activeStoragePeriod === null ||
                      activeStoragePeriod === undefined ||
                      activeStoragePeriod.is_ongoing;

                    // Hubs data with order and colorCode mapping for graphs
                    const activeHubsWithColorContext = useContext(ActiveHubsWithColorContext);
                    // Dispatching poller -- start
                    useEffect(() => {
                      if (!loading) {
                        if (
                          grain_container &&
                          grain_container.active_hub_links_including_fc_plenum_hubs &&
                          grain_container.active_hub_links_including_fc_plenum_hubs.length > 0
                        ) {
                          const new_active_hub_links: HubWithColorCode[] = grain_container.active_hub_links_including_fc_plenum_hubs.map(
                            (hub, idx) => ({
                              order: idx,
                              color: LINE_COLORS[idx],
                              ...hub,
                            })
                          );
                          activeHubsWithColorContext.setActiveHubsWithColor(new_active_hub_links);
                        } else {
                          activeHubsWithColorContext.setActiveHubsWithColor(null);
                        }
                      }
                    }, [loading, grain_container]);

                    useEffect(() => {
                      // reset to present weather view
                      if (grainContainerId) {
                        setPrevWeekNo(0);
                      }
                    }, [grainContainerId]);

                    // selected account id
                    const account_id =
                      useSelector(selectAccountId) || useSelector(sessionAccountId);
                    const next_aeration = useMemo(() => {
                      return grain_bin ? grain_bin.container.aeration_schedule[0] : null;
                    }, [grain_bin]);
                    useEffect(() => {
                      dispatch(
                        pollBackgroundFanControllerState({
                          fan_controller_ids: grain_bin_fan_settings
                            ? grain_bin_fan_settings.grain_bin.fan_controllers.map(
                                (fc) => fc.fan_controller_id_next
                              )
                            : null,
                        })
                      );
                      return () => {
                        dispatch(pollBackgroundFanControllerState({ fan_controller_ids: null }));
                      };
                    }, [grain_bin_fan_settings]);

                    useEffect(() => {
                      dispatch(
                        pollBackgroundGrainBinUpdateState({
                          container_id: grain_bin ? grain_bin.grain_bin_id : null,
                          container_type: ContainerTypeLegacy.bin,
                        })
                      );

                      return () => {
                        dispatch(
                          pollBackgroundGrainBinUpdateState({
                            container_id: null,
                            container_type: null,
                          })
                        );
                      };
                    }, [grain_bin]);

                    useEffect(() => {
                      dispatch(
                        pollBackgroundFanGuidanceExtension({
                          grain_bin_id:
                            grain_bin && grain_bin.enable_fan_guidance
                              ? grain_bin.grain_bin_id
                              : null,
                        })
                      );
                    }, [grain_bin]);

                    useEffect(() => {
                      dispatch(
                        pollBackgroundRecommWindowsState({
                          container_id: grain_container ? grain_container.container_id : null,
                          container_type: ContainerTypeLegacy.bin,
                        })
                      );

                      return () => {
                        dispatch(
                          pollBackgroundRecommWindowsState({
                            container_id: null,
                            container_type: null,
                          })
                        );
                      };
                    }, [grain_container]);

                    useEffect(() => {
                      dispatch(
                        pollBackgroundAccountUpdateState({
                          account_id,
                        })
                      );
                      return () => {
                        dispatch(pollBackgroundAccountUpdateState({ account_id: null }));
                      };
                    }, [account_id]);

                    if (grain_bin && grain_bin.enable_fan_guidance) {
                    }
                    useEffect(() => {
                      setShowFanGuidanceExtPrompt(prompt_fan_guidance_extension);
                    }, [prompt_fan_guidance_extension]);

                    useEffect(() => {
                      dispatch(
                        pollBackgroundFanGuidanceExtension({
                          grain_bin_id: grain_bin ? grain_bin.grain_bin_id : null,
                        })
                      );
                    }, [grain_bin]);

                    useEffect(() => {
                      /* 
                Here we are storing grain_bin_experirment_cfm_values for the first time.
                Here, grain_bin_experiment_cfm_values are not added as a useEffect dependency,
                because we don't want cfm_values to be reset to the default grain_bin_experiment_cfm_values after grain container polling.
                The cfm_values state is only used to set the default grain_bin_experiment_cfm_values on the first load and 
                then to store experiment form values. 
                 */
                      if (!grain_bin_exp_cfm_values_loading) {
                        console.log('first time', grain_bin_experirment_cfm_values);
                        setCfmValues(
                          grain_bin_experirment_cfm_values
                            ? {
                                ...grain_bin_experirment_cfm_values,
                              }
                            : grain_bin_experirment_cfm_values
                        );
                      }
                    }, [grain_bin_exp_cfm_values_loading]);

                    useEffect(() => {
                      if (!default_exp_values_loading) {
                        setBoundValues(
                          grain_bin_default_experiment_bound_values
                            ? {
                                ...grain_bin_default_experiment_bound_values,
                              }
                            : boundValues
                        );
                      }
                    }, [default_exp_values_loading]);

                    useEffect(() => {
                      if (!show_co2_level_warning_loading) {
                        console.log('show_co2_level_warning', show_co2_level_warning);
                        dispatch(
                          setShowCO2LevelWarningBanner(
                            show_co2_level_warning
                              ? {
                                  co2AlertSentOn: show_co2_level_warning.co2_alert_sent_on,
                                  co2AlertType: show_co2_level_warning.co2_alert_type,
                                }
                              : null
                          )
                        );
                      }
                    }, [show_co2_level_warning, show_co2_level_warning_loading]);

                    useEffect(() => {
                      if (grain_bin && !get_show_ticket_warning_loading) {
                        dispatch(
                          setShowGrainTicketWarningBanner({
                            showGrainTicketWarningBanner: show_grain_ticket_level_deviation_warning,
                          })
                        );
                      }
                    }, [
                      show_grain_ticket_level_deviation_warning,
                      get_show_ticket_warning_loading,
                      grain_bin,
                    ]);

                    const fan_statuses = useMemo(() => {
                      const grain_bin_fan_controllers = grain_bin_fan_settings
                        ? grain_bin_fan_settings.grain_bin.fan_controllers
                        : [];

                      const fan_statuses: FanStatusValues[] = grain_bin_fan_controllers.map(
                        (ctrl, ix) => {
                          const status =
                            ctrl && ctrl.fan_controller && ctrl.fan_controller.state_next
                              ? ctrl.fan_controller.state_next.value_next
                              : FanControllerStateValueNext.Offline;
                          return {
                            status,
                            id: ctrl.fan_controller_id_next,
                            fan_name: ctrl.alias || `Fan ${ix}`,
                          };
                        }
                      );

                      return fan_statuses;
                    }, [grain_bin_fan_settings]);

                    if (loading) {
                      return <DialogSpinner title="Loading Dashboard..." open={loading} />;
                    }

                    if (grain_bin_exp_cfm_values_loading) {
                      return (
                        <DialogSpinner
                          title="Loading Dashboard..."
                          open={grain_bin_exp_cfm_values_loading}
                        />
                      );
                    }

                    if (!viewer || !grain_bin || !grain_container) {
                      return null;
                    }

                    const {
                      account: { system_account },
                      user: { role, user_id },
                    } = viewer;
                    const diagnostic_mode = system_account && role === UserRole.Admin;

                    const {
                      container_id,
                      container_type,
                      alias,
                      default_grain_type,
                      telemetry: {
                        headspace = null,
                        interior = null,
                        tiers = [],
                        weather = null,
                        weather_forecast = null,
                        recommendation_windows = null,
                        temporary_recommendation_windows,
                      },
                      active_hub_links,
                      active_hub_links_including_fc_plenum_hubs,
                    } = grain_container;
                    const weatherData = weather_forecast
                      ? {
                          hourlyForecast: weather_forecast.hourly_forecast,
                          dailyForecast: weather_forecast.daily_forecast as ForecastDayV2FragmentFragment[],
                          grain_bin_location_timezone: grain_bin.tz
                            ? grain_bin.tz
                            : browserTimezone,
                        }
                      : undefined;
                    const recommendation_windows_data =
                      isUserOnTempRecommWindowsPage && temporary_recommendation_windows
                        ? temporary_recommendation_windows.recommendation_windows
                        : recommendation_windows;
                    if (container_type !== ContainerTypeLegacy.bin) {
                      return null;
                    }

                    const has_hub = active_hub_links.length > 0;
                    const has_hubs_including_fc_plenum_hubs =
                      active_hub_links_including_fc_plenum_hubs.length > 0;
                    const has_fan_controller =
                      grain_bin_fan_settings &&
                      grain_bin_fan_settings.grain_bin.fan_controllers.length > 0;
                    const has_override =
                      grain_bin_fan_settings &&
                      grain_bin_fan_settings.grain_bin.fan_controllers.some(
                        (ctrl) =>
                          ctrl.fan_controller &&
                          ctrl.fan_controller.state_next &&
                          ctrl.fan_controller.state_next.is_override
                      );

                    const mapped_errors: FanControllerWithError[] | null =
                      (grain_bin_fan_settings &&
                        grain_bin_fan_settings.grain_bin.fan_controllers.reduce((acc, next) => {
                          if (
                            next.fan_controller &&
                            next.fan_controller.state_next &&
                            isQualifyingFanHeartbeatErrorCode(
                              next.fan_controller.state_next.error_code
                            )
                          ) {
                            const error = {
                              error_code: next.fan_controller.state_next
                                .error_code as QualifyingFanHeartbeatErrorCode,
                              core_id: next.fan_controller.core_id,
                              alias: next.alias || next.fan_controller.alias,
                            };
                            const errors = acc || ([] as FanControllerWithError[]);
                            return [...errors, error];
                          }
                          return acc;
                        }, null)) ||
                      null;

                    const grain_bin_fan_controllers = grain_bin_fan_settings
                      ? grain_bin_fan_settings.grain_bin.fan_controllers
                      : [];
                    const any_fans_running = grain_bin_fan_controllers.some(
                      (ctrl) =>
                        ctrl.fan_controller.state_next && ctrl.fan_controller.state_next.is_on
                    );
                    const any_disabled = grain_bin_fan_controllers.some(
                      (ctrl) =>
                        ctrl.fan_controller.state_next &&
                        !ctrl.fan_controller.state_next.config.enabled
                    );
                    let uniform_ctrl_status: FanControllerStateValueNext | null | undefined;
                    let uniform_is_on: boolean | null | undefined;
                    const any_offline = grain_bin_fan_controllers.some(
                      (fc) =>
                        fc.fan_controller &&
                        fc.fan_controller.state_next &&
                        fc.fan_controller.state_next.value_next ===
                          FanControllerStateValueNext.Offline
                    );
                    if (grain_bin_fan_controllers.length > 1) {
                      uniform_ctrl_status = grain_bin_fan_controllers.reduce((acc, ctrl) => {
                        if (acc === null) {
                          return null;
                        }
                        if (acc === undefined && ctrl.fan_controller.state_next) {
                          return ctrl.fan_controller.state_next.value_next;
                        }
                        if (
                          ctrl.fan_controller.state_next &&
                          acc === ctrl.fan_controller.state_next.value_next
                        ) {
                          return acc;
                        }
                        return null;
                      }, undefined);

                      uniform_is_on = grain_bin_fan_controllers.reduce((acc, ctrl) => {
                        if (acc === null) {
                          return null;
                        }
                        if (acc === undefined && ctrl.fan_controller.state_next) {
                          return ctrl.fan_controller.state_next.is_on;
                        }
                        if (
                          ctrl.fan_controller.state_next &&
                          acc === ctrl.fan_controller.state_next.is_on
                        ) {
                          return acc;
                        }
                        return null;
                      }, undefined);
                    } else if (
                      grain_bin_fan_controllers.length === 1 &&
                      grain_bin_fan_controllers[0].fan_controller.state_next
                    ) {
                      uniform_ctrl_status =
                        grain_bin_fan_controllers[0].fan_controller.state_next.value_next;
                      uniform_is_on = grain_bin_fan_controllers[0].fan_controller.state_next.is_on;
                    }
                    return (
                      <GrainBinDataProvider grainBin={grain_bin}>
                        {diagnostic_mode && (
                          <DiagnosticModeInfoSection
                            account_id={grain_bin.account_id}
                            grain_bin_id={grain_bin.grain_bin_id}
                            {...{
                              active_hub_links,
                              grain_bin_fan_controllers,
                              grain_bin_cfm_solution,
                            }}
                          />
                        )}
                        <Grid
                          item
                          xs={12}
                          spacing={2}
                          container
                          direction="row"
                          justify="center"
                          alignItems="flex-start"
                          alignContent="flex-start"
                        >
                          {is_historical_bin &&
                            activeStoragePeriod &&
                            !activeStoragePeriod.is_ongoing &&
                            activeStoragePeriod.end_date && (
                              <HistoricalStoragePeriodBanner
                                name={activeStoragePeriod.storage_cycle_name}
                                grain_type={grain_bin.grain_type}
                                start_date={activeStoragePeriod.start_date}
                                end_date={activeStoragePeriod.end_date}
                              />
                            )}

                          {activeStoragePeriod &&
                            activeStoragePeriod.start_date.getTime() >
                              DateTime.local().toMillis() &&
                            activeStoragePeriod.end_date !== undefined && (
                              <FutureStoragePeriodBanner
                                name={activeStoragePeriod.storage_cycle_name}
                                grain_type={grain_bin.grain_type}
                                start_date={activeStoragePeriod.start_date}
                                end_date={activeStoragePeriod.end_date}
                              />
                            )}

                          <Grid item xs={12}>
                            <GrainBinBanners url_base={url_base} />
                          </Grid>

                          {has_fan_controller &&
                            uniform_ctrl_status &&
                            uniform_is_on !== null &&
                            uniform_is_on !== undefined &&
                            ((activeStoragePeriod && activeStoragePeriod.is_ongoing) ||
                              !activeStoragePeriod) &&
                            !any_offline && (
                              <NextAerationCard
                                fan_count={grain_bin_fan_controllers.length}
                                fan_running={uniform_is_on}
                                run_window={next_aeration}
                                override={has_override}
                                any_disabled={any_disabled}
                                fan_statuses={fan_statuses}
                              />
                            )}

                          {weather_forecast &&
                            has_fan_controller &&
                            shouldShowFanRunCard &&
                            activeStoragePeriodContext.activeStoragePeriod !== undefined && (
                              <Grid item lg={6} md={12} sm={12} xs={12}>
                                <WeatherDataProvider weatherData={weatherData}>
                                  <FanControlsCard
                                    grain_bin={grain_bin}
                                    loading={loading}
                                    refetchGrainBin={refetchGrainBin}
                                    grain_bin_fan_settings={grain_bin_fan_settings}
                                    fan_running={Boolean(any_fans_running)}
                                    has_override={has_override}
                                    grain_bin_id={container_id}
                                    mapped_errors={mapped_errors}
                                    container_type={container_type}
                                    storage_period={activeStoragePeriodContext.activeStoragePeriod}
                                    user_id={user_id}
                                    current_account_id={current_account_id}
                                    show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
                                    setShowFanGuidanceExtPrompt={setShowFanGuidanceExtPrompt}
                                    role={role}
                                    cfmPerBu={cfmPerBu}
                                    fullBinCfmPerBu={fullBinCfmPerBu}
                                    url_base={url_base}
                                  />
                                </WeatherDataProvider>
                              </Grid>
                            )}
                          {weather_forecast &&
                            !has_fan_controller &&
                            activeStoragePeriodContext.activeStoragePeriod !== undefined && (
                              <Grid item lg={6} md={12} sm={12} xs={12}>
                                <WeatherDataProvider weatherData={weatherData}>
                                  <RecommendationScheduleCard
                                    grain_bin_id={container_id}
                                    container_type={container_type}
                                    storage_period={activeStoragePeriodContext.activeStoragePeriod}
                                  />
                                </WeatherDataProvider>
                              </Grid>
                            )}
                          {has_fan_controller &&
                            !has_hubs_including_fc_plenum_hubs &&
                            activeStoragePeriodContext.activeStoragePeriod !== undefined && (
                              <Grid item lg={6} md={12} xs={12}>
                                <PowerConsumptionCard
                                  grain_bin_id={container_id}
                                  container_id={container_id}
                                  container_type={container_type}
                                  storage_period={activeStoragePeriodContext.activeStoragePeriod}
                                  viewer_role={role}
                                />
                              </Grid>
                            )}

                          {/*Airspace card*/}
                          {has_hubs_including_fc_plenum_hubs &&
                            activeStoragePeriodContext.activeStoragePeriod !== undefined && (
                              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                                <BinInteriorCard
                                  container_id={container_id}
                                  container_type={container_type}
                                  grain_status={
                                    headspace
                                      ? {
                                          ...headspace,
                                          temp_f_ranges: [],
                                          humidity_rh_ranges: [],
                                          emc_ranges: [],
                                        }
                                      : null
                                  }
                                  telemetry_type="headspace"
                                  title="Airspace"
                                  minHeight={mobile_width ? undefined : 419}
                                  maxHeight={mobile_width ? undefined : 530}
                                  grain_bin_location_timezone={
                                    grain_bin.tz ? grain_bin.tz : browserTimezone
                                  }
                                  show_co2_level_warning={
                                    showCO2WarningBanner ? showCO2WarningBanner : null
                                  }
                                  overlay_aeration
                                  selectable_period
                                />
                              </Grid>
                            )}

                          {/*Weather forecast card*/}
                          {weather_forecast && recommendation_windows_data && shouldShowFanRunCard && (
                            <Grid item xs={12}>
                              <BinWeatherForecastCard
                                grain_bin_id={container_id}
                                weather_forecast={weather_forecast}
                                recommendation_windows_data={recommendation_windows_data}
                                refetch_grain_bin_container={refetch_grain_bin_container}
                                weather={weather}
                                mobile_width={mobile_width}
                                aeration_schedule={grain_bin.container.aeration_schedule}
                                grain_bin_location_timezone={
                                  grain_bin.tz ? grain_bin.tz : browserTimezone
                                }
                                recommendation_type={
                                  isUserOnTempRecommWindowsPage && temporary_recommendation_windows
                                    ? temporary_recommendation_windows.recommendation_type || null
                                    : grain_bin.recommendation_type
                                    ? grain_bin.recommendation_type
                                    : null
                                }
                                has_enable_fan_guidance={grain_bin.enable_fan_guidance || false}
                                fan_guidance_start_date={grain_bin.fan_guidance_start_date || null}
                                fan_guidance_end_date={grain_bin.fan_guidance_end_date || null}
                                container_type={ContainerTypeLegacy.bin}
                                setCfmValues={setCfmValues}
                                setBoundValues={setBoundValues}
                                cfm_scaling={cfm_values ? cfm_values.cfm_scaling : undefined}
                                cfm_offset={cfm_values ? cfm_values.cfm_offset : undefined}
                                cfm_min={cfm_values ? cfm_values.cfm_min : undefined}
                                cfm_max={cfm_values ? cfm_values.cfm_max : undefined}
                                cfm={cfm_values && cfm_values.cfm ? cfm_values.cfm : undefined}
                                lower_bound={
                                  boundValues && boundValues.lower_bound
                                    ? boundValues.lower_bound
                                    : undefined
                                }
                                upper_bound={
                                  boundValues && boundValues.upper_bound
                                    ? boundValues.upper_bound
                                    : undefined
                                }
                                prev_week_no={prevWeekNo}
                                setPrevWeekNo={setPrevWeekNo}
                                from_weather_history={prevWeekNo !== 0}
                              />
                            </Grid>
                          )}
                          {has_fan_controller &&
                            activeStoragePeriodContext.activeStoragePeriod !== undefined && (
                              <Grid item xs={12}>
                                <FanRunHistoryCard
                                  storage_period={activeStoragePeriodContext.activeStoragePeriod}
                                  grain_bin_id={container_id}
                                  container_type={container_type}
                                  cfmPerBu={cfmPerBu}
                                  fullBinCfmPerBu={fullBinCfmPerBu}
                                  url_base={url_base}
                                />
                              </Grid>
                            )}

                          {/*Grain level card*/}
                          {activeStoragePeriodContext.activeStoragePeriod !== undefined && (
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12} id="123">
                              <LevelSensingCard
                                container_id={container_id}
                                container_type={container_type}
                                title="Grain Level"
                                url_base={url_base}
                                minHeight={mobile_width ? undefined : 419}
                                maxHeight={mobile_width ? undefined : 465}
                                grain_bin_location_timezone={
                                  grain_bin.tz ? grain_bin.tz : browserTimezone
                                }
                                show_grain_ticket_level_deviation_warning={
                                  show_grain_ticket_level_deviation_warning
                                }
                              />
                            </Grid>
                          )}

                          {/* commenting out the tiers because no sensors 2020
                    {has_hub && tiers && tiers.length > 0 && (
                    <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
                    <BinSummaryCard
                    container_id={container_id}
                    container_type={container_type}
                    telemetry={{ headspace, tiers }}
                    title={`${alias}, ${formatGrainType(default_grain_type)}`}
                    />
                    </Grid>
                    )} */}
                          {/*  commenting out interior because no sensoors 2020 */}
                          {/* {has_hub && interior && interior.temp_f && (
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <BinInteriorCard
                    container_id={container_id}
                    container_type={container_type}
                    grain_status={interior}
                    selectable_period
                    telemetry_type="interior"
                    title={formatGrainType(default_grain_type)}
                    minHeight={419}
                    />
                    </Grid>
                    )} */}

                          {/*Fan power usage card*/}
                          {has_fan_controller &&
                            has_hubs_including_fc_plenum_hubs &&
                            activeStoragePeriodContext.activeStoragePeriod !== undefined && (
                              <Grid item lg={6} md={12} xs={12}>
                                <PowerConsumptionCard
                                  storage_period={activeStoragePeriodContext.activeStoragePeriod}
                                  grain_bin_id={container_id}
                                  container_id={container_id}
                                  container_type={container_type}
                                  viewer_role={role}
                                />
                              </Grid>
                            )}
                        </Grid>
                      </GrainBinDataProvider>
                    );
                  }
                )
              )
            )
          )
        )
      )
    )
  )
);
