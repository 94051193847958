import { Checkbox } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { FunctionComponent } from 'react';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { amber_amber, amber_dark_grey } from '../../../../../core/src/style';

const animatedComponents = makeAnimated();

export const allOption: {
  label: string;
  value: string;
} = {
  label: 'Select All',
  value: '*',
};

const MultiValue = (props) => {
  let labelToBeDisplayed = `${props.data.label}`;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = 'All grain bins selected';
  }
  return (
    <components.MultiValue {...props}>
      <span style={{ fontSize: 14 }}>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const selectedOptionsLength = props.getValue().length;
  console.log('selectedOptionsLength', selectedOptionsLength);
  const currentValues = props.getValue();
  const maxLabelsToBeDisplayed = 6;
  let toBeRendered = children;

  if (currentValues.some((val) => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  } else if (selectedOptionsLength > maxLabelsToBeDisplayed) {
    toBeRendered = !props.selectProps.inputValue &&
      selectedOptionsLength > maxLabelsToBeDisplayed && [
        <span style={{ fontSize: 14 }}>{`${selectedOptionsLength} grain bins selected`}</span>,
        children[1],
      ];
  }

  return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FilterListIcon color="primary" />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  const isSelectAllOption = props.value === '*';
  const selectAllButtonStyles: any = isSelectAllOption
    ? {
        textDecoration: 'underline',
        textUnderlineOffset: 2,
        textDecorationColor: 'gray',
      }
    : undefined;
  return (
    <div style={{ textAlign: 'start', fontSize: '14px' }}>
      <components.Option {...props}>
        <Checkbox checked={props.isSelected} onChange={() => null} />
        <label style={selectAllButtonStyles}>{props.label}</label>
      </components.Option>
    </div>
  );
};

export type OptionValueType = {
  label: string;
  value: string;
}[];

type SelectProps = {
  name: string;
  selectedValue: OptionValueType;
  isDisabled?: boolean;
  onChange: (selectedOption: OptionValueType) => void;
  allowSelectAll?: boolean;
  placeholder?: string;
  options: OptionValueType;
};

export const SelectGrainBinsFilter: FunctionComponent<SelectProps> = ({
  name,
  selectedValue,
  onChange,
  placeholder,
  options,
  allowSelectAll = false,
}) => {
  const handleChange = (selected, event) => {
    if (selected !== null && selected.length > 0) {
      if (selected[selected.length - 1].value === allOption.value) {
        return onChange([allOption, ...options]);
      }
      let result: any = [];
      if (selected.length === options.length) {
        if (selected.includes(allOption)) {
          result = selected.filter((option) => option.value !== allOption.value);
        } else if (event.action === 'select-option') {
          result = [allOption, ...options];
        }
        return onChange(result);
      }
    }

    return onChange(selected);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: 20,
    }),
    option: (provided, state) => {
      return {
        ...provided,
        color: amber_dark_grey,
        backgroundColor: 'white',
        ':active': {
          ...provided[':active'],
          backgroundColor: 'white',
        },
        ':hover': {
          ...provided[':hover'],
          backgroundColor: 'rgba(189,197,209,.3)',
        },
      };
    },
  };

  if (allowSelectAll) {
    return (
      <Select
        styles={customStyles}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        name={name}
        placeholder={placeholder}
        value={selectedValue}
        options={[allOption, ...options]}
        allowSelectAll={true}
        components={{
          animatedComponents,
          MultiValue,
          ValueContainer,
          Option,
          DropdownIndicator,
        }}
        isMulti
        onChange={handleChange}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: amber_amber,
            primary50: '#DEEBFF',
            primary75: '#DEEBFF',
            primary25: '#DEEBFF',
          },
        })}
      />
    );
  }

  return (
    <Select
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      name={name}
      placeholder={placeholder}
      value={selectedValue}
      onChange={onChange}
      options={options}
      components={{
        animatedComponents,
        MultiValue,
        ValueContainer,
        Option,
      }}
      isMulti
    />
  );
};
