import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  CancelRounded as CancelRoundedIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
  PlayArrow as PlayArrowIcon,
  Replay as ReplayIcon,
} from '@material-ui/icons';
import {DateTime} from 'luxon';
import React, {useState} from 'react';
import {amber_dark_grey, amber_green, amber_grey, amber_red} from '../../style';
import {SummaryCard} from '../grain-container/summary-card';
import ContentModal from '../util/ContentModal';
import {Button} from '../util/form2/Button';
import {DIAG_TEST_RESULT_STATUSES, DiagTestResultStatus} from './DiagTests';
import {CheckResultMap, SelfTestRow, TestResultsTable} from './TestResultsTable';

const useStyles = makeStyles({
  longTestCardContainer: {
    width: '100%',
    marginBottom: 20,
  },
  testResultCell: {
    fontSize: 14,
    borderBottom: '1px solid rgb(224, 224, 224)',
    padding: 10,
    textAlign: 'center',
  },
  status_heading: {
    marginRight: 10,
    color: amber_dark_grey,
    fontSize: 36,
  },
  confirmButton: {},
  testResultStatusIcon: {
    verticalAlign: 'text-top',
  },
  runOnText:{
    fontSize: 'medium',
    color: 'grey',
    textAlign: 'center',
  },
});

type LongTestCardProps = {
  core_id: string;
  handleStartLongTest: () => void;
  lastLongTestResult: any;
  currentCellMetricTestResultUIStatus: DiagTestResultStatus | null;
  currentLongTestResultUIStatus: DiagTestResultStatus | null;
  currentQuickTestResultUIStatus: DiagTestResultStatus | null;
  isDiagnosticTestEnabled: boolean;
};

type LongTestCheckResult = {
  check_id: string;
  check_name: string;
  check_result_status: string;
  error_code?: string | null;
  error?: string[] | null;
  data?: {};
};

type LongTestResultsMetrics = {
  test_result?: {
    test_result: string;
    test_check_result?: {
      accel_check_result?: LongTestCheckResult;
      irms_sanity_check_result?: LongTestCheckResult;
      irms_vibe_check_result?: LongTestCheckResult;
      sht31_check_result?: LongTestCheckResult;
      fan_off_check_result?: LongTestCheckResult;
      fan_turn_on_check_result?: LongTestCheckResult;
      fan_stays_on_check_result?: LongTestCheckResult;
      fan_turns_off_check_result?: LongTestCheckResult;
    }
  }
  test_type: string;
  test_status: string;
  created_at: Date;
  last_updated_at: Date;
};

const ensureChecks = [
  {key:"check1", label:"All personnel present are aware the fan may start and stop under automatic control"},
  {key:"check2", label:"The fan is wired correctly according to Amber’s instructions to the best of my knowledge"},
  {key:"check3", label:"The bin floor is sufficiently weighted by grain to avoid damage to the floor structure"},
  {key:"check4", label:"I will not hold Amber liable for any damage or injury caused by the fan self-test"},
];

const LongTextConfirmationContent = ({
  setDisableConfirmButton,
}: {
  setDisableConfirmButton: Function;
}) => {
  const [checkedBoxes, setCheckedBoxes] = useState(0);
  const handleChange = (event) => {
    if (event.target.checked) {
      setCheckedBoxes((prevState) => prevState + 1);
    } else if (!event.target.checked) {
      setCheckedBoxes((prevState) => prevState - 1);
    }
  };
  if (checkedBoxes === 4) {
    setDisableConfirmButton(false);
  } else {
    setDisableConfirmButton(true);
  }
  return (
      <>
        <h3>Please ensure below points before starting Quick Test. <br/>I certify that:</h3>
        <FormGroup>
          {ensureChecks.map(({key, label})=>
              <FormControlLabel
                  control={<Checkbox onChange={handleChange} />}
                  key={key}
                  label={label}
                  style={{textAlign: 'left', marginBottom: '13px'}}
              />)}
        </FormGroup>
      </>
  );
};

export const LongTestCard = ({
  core_id,
  lastLongTestResult,
  handleStartLongTest,
  currentCellMetricTestResultUIStatus,
  currentLongTestResultUIStatus,
  currentQuickTestResultUIStatus,
  isDiagnosticTestEnabled,
}: LongTestCardProps) => {
  const classes = useStyles();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  let lastTestRsultsMetrics: LongTestResultsMetrics | null =  null;
  if (lastLongTestResult){
    lastTestRsultsMetrics = {
      test_type: "LONG_TEST",
      test_status: lastLongTestResult.test_status,
      created_at: lastLongTestResult.created_at,
      last_updated_at: lastLongTestResult.last_updated_at,
    }

    const {test_result} = lastLongTestResult;
    if (test_result){
      const test_result_1 = {
        test_result: lastLongTestResult.test_result.test_result,
      };
      const {test_check_result} = test_result;

      if (test_check_result){
        let test_check_result_1 = {};
        const {accel_check_result, irms_sanity_check_result, irms_vibe_check_result, sht31_check_result, fan_off_check_result, fan_turn_on_check_result, fan_stays_on_check_result, fan_turns_off_check_result} = test_check_result;
        if (accel_check_result){
          const {check_id, check_result_status, error_code, error} = accel_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            accel_check_result:{
              check_id,
              check_result_status,
              error_code,
              error,
              check_name: 'accelerometer',
            }
          }
        }
        if (irms_sanity_check_result){
          const {check_id, check_result_status, error_code, error, irms_sanity_A} = irms_sanity_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            irms_sanity_check_result:{
              check_id,
              check_result_status,
              error_code,
              error,
              check_name: 'current sensor',
              data: {
                irms_sanity_A,
              }
            }
          }
        }
        if (irms_vibe_check_result){
          const {check_id, check_result_status, error_code, error, vibe_sanity} = irms_vibe_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            irms_vibe_check_result:{
              check_id,
              check_result_status,
              error_code,
              error,
              check_name:'vibration sensor',
              data: {
                vibe_sanity,
              }
            }
          }
        }
        if (sht31_check_result){
          const {check_id, check_result_status, error_code, error, relative_humidity, temperature_fahrenheit} = sht31_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            sht31_check_result:{
              check_id,
              check_result_status,
              error_code,
              error,
              check_name: 'temp/humidity sensor',
              data: {
                relative_humidity,
                temperature_fahrenheit,
              }
            }
          }
        }
        if (fan_off_check_result){
          const {check_id, check_name, check_result_status, error_code, error, irms_off_A, vibe_off} = fan_off_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            fan_off_check_result:{
              check_id,
              check_name,
              check_result_status,
              error_code,
              error,
              data: {
                irms_off_A,
                vibe_off,
              }
            }
          }
        }
        if (fan_turn_on_check_result){
          const {check_id, check_name, check_result_status, error_code, error, irms_on_A, vibe_on} = fan_turn_on_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            fan_turn_on_check_result:{
              check_id,
              check_name,
              check_result_status,
              error_code,
              error,
              data: {
                irms_on_A,
                vibe_on,
              }
            }
          }
        }
        if (fan_stays_on_check_result){
          const {check_id, check_name, check_result_status, error_code, error, irms_hold_A, vibe_hold} = fan_stays_on_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            fan_stays_on_check_result:{
              check_id,
              check_name,
              check_result_status,
              error_code,
              error,
              data: {
                irms_hold_A,
                vibe_hold,
              }
            }
          }
        }
        if (fan_turns_off_check_result){
          const {check_id, check_name, check_result_status, error_code, error, irms_stop_A, vibe_stop} = fan_turns_off_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            fan_turns_off_check_result:{
              check_id,
              check_name,
              check_result_status,
              error_code,
              error,
              data: {
                irms_stop_A,
                vibe_stop,
              }
            }
          }
        }

        test_result_1['test_check_result']= {...test_check_result_1};
      }
      lastTestRsultsMetrics={
        ...lastTestRsultsMetrics,
        test_result: {...test_result_1},
      }
    }
  }


  const row_map: CheckResultMap = {};

  function build_check_map(row_map: CheckResultMap, check_result: LongTestCheckResult, check_key_name: string): CheckResultMap{
    let row: SelfTestRow;
    if (row_map[check_key_name]){
      row = row_map[check_key_name];
      if (row){
        row.passed = row.passed==='FAIL'?'FAIL':row.passed==='SKIPPED'?'SKIPPED':check_result.check_result_status;
        row.checks = [...row.checks, {
          check_name: check_result.check_name,
          data: check_result.data,
          error: check_result.error,
          passed: check_result.check_result_status,
        }];
      }
    } else {
      const {check_result_status, check_name, data, error} = check_result;
      row = {
        name: check_key_name,
        passed: check_result_status,
        checks: [{
          check_name,
          data,
          error,
          passed: check_result_status,
        }],
      };
    }
    row_map[check_key_name]=row;
    return row_map;
  }

  if (lastTestRsultsMetrics && lastTestRsultsMetrics.test_result && lastTestRsultsMetrics.test_result.test_check_result){
    const {accel_check_result, irms_sanity_check_result, irms_vibe_check_result, sht31_check_result, fan_off_check_result, fan_turn_on_check_result, fan_stays_on_check_result, fan_turns_off_check_result} = lastTestRsultsMetrics.test_result.test_check_result;
    if (accel_check_result){
      build_check_map(row_map, accel_check_result, "Sensor Check")
    }
    if (irms_sanity_check_result){
      build_check_map(row_map, irms_sanity_check_result, "Sensor Check")
    }
    if (sht31_check_result){
      build_check_map(row_map, sht31_check_result, "Sensor Check")
    }
    if (irms_vibe_check_result){
      build_check_map(row_map, irms_vibe_check_result, "Sensor Check")
    }
    if (fan_off_check_result){
      build_check_map(row_map, fan_off_check_result, "Fan Off")
    }
    if (fan_turn_on_check_result){
      build_check_map(row_map, fan_turn_on_check_result, "Fan Turns On")
    }
    if (fan_stays_on_check_result){
      build_check_map(row_map, fan_stays_on_check_result, "Fan Stays On")
    }
    if (fan_turns_off_check_result){
      build_check_map(row_map, fan_turns_off_check_result, "Fan Turns Off")
    }
  }


  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const last_test_result_run_datetime_at_zone: DateTime | null = lastTestRsultsMetrics
    ? DateTime.fromISO(lastTestRsultsMetrics.last_updated_at).setZone(browserTimezone)
    : null;
  const isTestRunning = currentLongTestResultUIStatus
    ? currentLongTestResultUIStatus.status === DIAG_TEST_RESULT_STATUSES.RUNNING.status
    : false;
  const isQuickTestRunning = currentQuickTestResultUIStatus
    ? currentQuickTestResultUIStatus.status === DIAG_TEST_RESULT_STATUSES.RUNNING.status
    : false;

  const isCellMetricTestRunning = currentCellMetricTestResultUIStatus
    ? currentCellMetricTestResultUIStatus.status === DIAG_TEST_RESULT_STATUSES.RUNNING.status
    : false;

  const longTestButtonText = isTestRunning ? (
    <>
      <CircularProgress style={{ width: 16, height: 16 }} />
      &nbsp;&nbsp;&nbsp;Running
    </>
  ) : (
    <>
      {currentLongTestResultUIStatus &&
      [DIAG_TEST_RESULT_STATUSES.TIMED_OUT.status, DIAG_TEST_RESULT_STATUSES.FAIL.status].includes(
        currentLongTestResultUIStatus.status
      ) ? (
        <>
          <ReplayIcon fontSize="small" />
          &nbsp;Retry Long Test
        </>
      ) : (
        <>
          <PlayArrowIcon fontSize="small" />
          &nbsp;Run Long Test
        </>
      )}
    </>
  );
  return (
    <SummaryCard className={classes.longTestCardContainer} title="Long Test (Optional)" subheaderComp={
      <div>
        <Typography variant="h5" align="left" style={{marginTop: '20px'}}>
          Description:
          <Typography style={{fontSize: 'large'}}>
            <p>
              The Long Test checks whether the fan controller is functioning correctly and can control the fan system as expected.
              Use the Long Test when you want to test whether the fan starts, runs, and stops correctly over a longer period of time
              than the Short Test. The Long Test may catch some issues that the Short Test does not.
            </p>
            <p>
              Ensure the bin has enough grain to adequately compress and protect the floor supports, if applicable. For lower grain
              levels, consider the Short Test instead.
            </p>
          </Typography>
        </Typography>

        <Typography variant="h5" align="left" style={{marginTop: '20px'}}>
          Steps:
          <ul style={{position: 'relative', left: '-25px', fontSize: 'large', fontWeight: 'normal'}}>
            <li><span style={{position: 'absolute', left:'50px'}}>Tries up to 5s to fan start</span></li>
            <li><span style={{position: 'absolute', left:'50px'}}>Runs fan for 30s</span></li>
            <li><span style={{position: 'absolute', left:'50px'}}>Tries to stop fan</span></li>
          </ul>
        </Typography>
      </div>
    }>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Grid item style={{ marginTop: 20 }}>
          {currentLongTestResultUIStatus && (
            <>
              <Typography variant="h3" className={classes.status_heading}>
                Long Test{' '}
                <span style={{ color: currentLongTestResultUIStatus.color || amber_grey }}>
                  {currentLongTestResultUIStatus.status}
                </span>
              </Typography>
              {currentLongTestResultUIStatus.status ===
                DIAG_TEST_RESULT_STATUSES.TIMED_OUT.status && (
                <Typography variant="body1" style={{ color: amber_red, marginTop: 10 }}>
                  Sorry, Your Long test request timed out! Please try again!
                </Typography>
              )}
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ borderRadius: 20, marginTop: 20, width: 148 }}
            onClick={() => setShowConfirmationModal(true)}
            disabled={isTestRunning || isQuickTestRunning || !isDiagnosticTestEnabled || isCellMetricTestRunning}
          >
            {longTestButtonText}
          </Button>
          <Typography variant="caption" display="block" gutterBottom style={{fontSize:"small", fontStyle:"italic"}}>
            (approx. run time 60 secs)
          </Typography>
        </Grid>
        {!isTestRunning && (
          <>
            {lastTestRsultsMetrics && lastTestRsultsMetrics.test_result && lastTestRsultsMetrics.test_result.test_result === 'PASS' && (
              <div>
                <Typography
                  variant="h5"
                  style={{ marginTop: 25, marginBottom: 10, fontWeight: 600 }}
                >
                  <span style={{ verticalAlign: 'text-bottom', marginRight: '10px' }}>
                    Test Results:
                  </span>
                  <CheckCircleRoundedIcon
                    className={classes.testResultStatusIcon}
                    style={{ color: amber_green }}
                  />
                </Typography>
                <Typography variant="body1" className={classes.runOnText}>
                  Run on - {last_test_result_run_datetime_at_zone.toFormat('ff')}
                </Typography>
              </div>
            )}
            {lastTestRsultsMetrics && lastTestRsultsMetrics.test_result && lastTestRsultsMetrics.test_result.test_result !== 'PASS' && (
              <div>
                <Typography
                  variant="h5"
                  style={{ marginTop: 25, marginBottom: 10, fontWeight: 600 }}
                >
                  <span style={{ verticalAlign: 'text-bottom', marginRight: '10px' }}>
                    Test Results:
                  </span>
                  <CancelRoundedIcon
                    className={classes.testResultStatusIcon}
                    style={{ color: amber_red }}
                  />
                </Typography>
                <Typography variant="body1" className={classes.runOnText}>
                  Run on {last_test_result_run_datetime_at_zone.toFormat('ff')}
                </Typography>
              </div>
            )}
            <TestResultsTable row_map={row_map} />
          </>
        )}
      </Grid>
      <ContentModal
        showModal={showConfirmationModal}
        ModalContent={LongTextConfirmationContent}
        cancelBtnText="Cancel"
        confirmBtbText="Run"
        handleCancel={() => setShowConfirmationModal(false)}
        handleConfirm={(e) => {
          handleStartLongTest();
          setShowConfirmationModal(false);
        }}
        disableConfirmButtonDefault={true}
      />
    </SummaryCard>
  );
};
