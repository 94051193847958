import { IconButton, InputAdornment, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import QrCodeIcon from '../../../../../core/src/media/icons/QrCodeIcon';
import { getDistanceUnitLabel } from '../../../../../core/src/util';
import { Checkbox } from '../../util/form2/Checkbox';
import { SelectHubType } from '../../util/form2/SelectHubType';
import { TextField } from '../../util/form2/TextField';

export const HUB_OFFSET_HELPER_TEXT = (
  <>
    Input distance between the <u> Bottom of the Headspace Sensor </u>and the
    <u> Top of the Top Ring </u>of the Bin :
  </>
);

export const AssignHubFormFields: React.FunctionComponent<{
  update?: boolean;
  openQRScannerDialog?: () => void;
}> = ({ update, openQRScannerDialog }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const distanceUnitLabel = getDistanceUnitLabel();

  useEffect(() => {
    // reset hub_offset_ft when has_level_sensor is unchecked
    if (!values.has_level_sensor) {
      setFieldValue('hub_offset_ft', 0);
    }
  }, [values.has_level_sensor]);
  return (
    <>
      {!update && (
        <TextField
          name="core_id"
          id="assign-hub"
          label="Hub ID"
          placeholder="AmAg_00-0-000"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="open QR code scanner"
                  onClick={() => openQRScannerDialog && openQRScannerDialog()}
                >
                  <QrCodeIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          required
        />
      )}
      <TextField
        style={{ marginTop: 10 }}
        name="hub_nickname"
        label="Hub Nickname"
        placeholder="Headspace Hub"
        fullWidth
        required
      />
      <SelectHubType
        style={{ marginTop: 10 }}
        name="hub_type"
        label="Hub Type"
        placeholder="None Selected"
        fullWidth
        required
      />
      <Checkbox
        style={{ marginTop: 10, width: '100%' }}
        name="has_level_sensor"
        label="Level Sensor Add-on Equipped"
      />
      {values.has_level_sensor && (
        <>
          <Typography variant="subtitle2" style={{ marginBottom: 5, marginTop: 5 }}>
            {HUB_OFFSET_HELPER_TEXT}
          </Typography>
          <TextField
            required
            fullWidth
            name="hub_offset_ft"
            label={`Hub Offset (${distanceUnitLabel})`}
            inputProps={{
              inputMode: 'decimal',
            }}
            type="number"
          />
        </>
      )}
    </>
  );
};
