import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';

import { createSession } from '../../action';
import { withUpdateUserVerificationHoc, WithUpdateUserVerificationHocChildProps } from '../../api';
import { ErrorLayout } from '../ErrorLayout';
import { DialogSpinner } from '../spinner';

const VerifyUserBase = ({
  token,
  updateUserVerification,
}: { token: string } & WithUpdateUserVerificationHocChildProps) => {
  const [success, setSuccess] = useState<null | boolean>(null);
  const dispatch = useDispatch();

  updateUserVerification({ token })
    .then((res) => {
      dispatch(createSession({ viewer: res, propagate: true }));
      setSuccess(true);
    })
    .catch((success) => {
      setSuccess(false);
    });
  if (success) {
    return <Redirect to={`/dashboard`} />;
  }
  if (success === false) {
    return <ErrorLayout />;
  }
  return <DialogSpinner open={true} title="Logging in..." />;
};

export const VerifyUser = withUpdateUserVerificationHoc(VerifyUserBase);
