import {
  Grid,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { DateTime, Interval } from 'luxon';
import React, { useEffect, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { useWeatherData } from '../../../../../core/src/contexts';
import RightArrowIcon from '../../../../../core/src/media/icons/RightArrowIcon';
import { amber_error_red, black_shade_1, light_gray_shade_1 } from '../../../../../core/src/style';
import { getDurationText } from '../../../../../core/src/util';
import { getFormatedDateTimeString } from '../../../../../core/src/util/format-date';
import { Select } from '../../util/form2/Select';
import { IndividualRunwindowWeatherPlot } from '../run-window-weather-chart';
import {
  DATE_IX_ARRAY,
  findNearestTimeFloor,
  formatDayNow,
  getNextStart,
  TIMES_ARRAY,
} from './FanSetMultiSchedule';
import { RunWindowStartEndIndicator } from './RunWindowStartEndIndicator';

const useStyles = makeStyles((theme: Theme) => ({
  // mobile styles
  mobileRunWindowRoot: {
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  mobileRunWindow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 5px',
    padding: 12,
    paddingRight: 8,
    border: '1px solid lightgray',
  },
  mobileRunWindowDelete: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  mobileRunWindowFieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  // wide screen styles
  runWindowRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    padding: 12,
    borderRadius: 10,
    border: '1px solid lightgray',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 5px',
  },
  centerItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  moreInfoLabels: {
    fontSize: 10,
    letterSpacing: 2,
    textTransform: 'uppercase',
    color: light_gray_shade_1,
  },
  moreInfoValues: {
    color: black_shade_1,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.1,
  },
  separater: {
    marginLeft: 15,
    marginRight: 15,
    display: 'flex',
    justifyContent: 'center',
  },
  selectField: {
    width: 84,
    height: 32,
    '& .MuiOutlinedInput-notchedOutline': { border: 0 },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#EFF1F4',
      padding: 8,
      borderRadius: 8,
    },
    borderRadius: 8,
    fontSize: 14,
    color: '#242a33',
    lineHeight: '18px',
    marginRight: 4,
  },
  selectFieldSkeleton: {
    height: 32,
    backgroundColor: '#EFF1F4',
    borderRadius: 8,
    marginRight: 4,
  },
  dashPlaceholder: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    height: 16,
  },
  error: {
    color: amber_error_red,
    fontSize: '11px',
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: '1.66',
    padding: '0px 8px',
    margin: 0,
  },
  runwindowWeatherPlotContainer: {
    height: 102,
    padding: '0 0px 0px 0px !important',
    marginLeft: 10,
    width: 'calc(100% - 320px)',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 5px',
    borderRight: '1px solid rgba(207, 208, 210, 0.5)',
  },
}));
export const RunWindow = ({
  showLoadingAnimation,
  runtime,
  estimatedTemp,
  estimatedRH,
  is_now,
  is_no_end,
  index: ix,
  filtered_start_times,
  filtered_end_times,
  runwindowCurrentValue,
  handleDeleteRunwindow,
  recommendationOptionValue,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
  const { setFieldValue, values, errors, touched } = useFormikContext<any>();
  const runWindow = values && values.run_windows[ix] ? values.run_windows[ix] : null;
  const weatherData = useWeatherData();
  const start =
    runWindow && runWindow.start_date && runWindow.start_time
      ? DateTime.fromFormat(
          getFormatedDateTimeString(runWindow.start_date, runWindow.start_time),
          'MM/dd/yyyy hh:mm'
        )
      : null;
  const end =
    runWindow && runWindow.end_date && runWindow.end_time
      ? DateTime.fromFormat(
          getFormatedDateTimeString(runWindow.end_date, runWindow.end_time),
          'MM/dd/yyyy hh:mm'
        )
      : null;
  const now = DateTime.local();
  const first_custom_option_dt = getNextStart(DateTime.local());
  const first_dt = first_custom_option_dt.startOf('day');
  const durationText = getDurationText(runtime);
  const hasRunWindowContainErrors =
    errors && errors.run_windows && Number(errors.run_windows.length) > 0 && errors.run_windows[ix];
  let runwindowErrors: string[] = [];
  let hasRunwindowsNagativeRuntime = false;
  // animation states
  const flipSpeedBackToFront = 0.6;
  const flipDirection = 'vertical';
  const showWithoutSkeleton = !showLoadingAnimation;
  const [isField1Flipped, setIsField1Flipped] = useState<boolean>(showWithoutSkeleton);
  const [isField2Flipped, setIsField2Flipped] = useState<boolean>(showWithoutSkeleton);
  const [isField3Flipped, setIsField3Flipped] = useState<boolean>(showWithoutSkeleton);
  const [isField4Flipped, setIsField4Flipped] = useState<boolean>(showWithoutSkeleton);
  const [hasMoreInfoLoaded, setHasMoreInfoLoaded] = useState<boolean>(showWithoutSkeleton);
  const adder = ix * 1000;
  const runwindowFieldsTimeouts = [adder + 100, adder + 300, adder + 500, adder + 700, adder + 900];
  const runtimeTextOnMobile = showLoadingAnimation ? (
    <Grow in={true}>
      <div>{durationText}</div>
    </Grow>
  ) : (
    <div>{durationText}</div>
  );

  const avgWeatherTextOnMobile = showLoadingAnimation ? (
    <Grow in={true}>
      <div>
        {estimatedTemp} · {estimatedRH}
      </div>
    </Grow>
  ) : (
    <div>
      {estimatedTemp} · {estimatedRH}
    </div>
  );

  const nowBlockStyles = is_now ? { width: 156 } : { width: 68 };
  const foreverBlockStyles = is_no_end ? { width: 156 } : { width: 68 };
  const addedYearDate = DateTime.local().plus({ years: 1 });
  const NO_END = DateTime.fromFormat(addedYearDate.toFormat('MM/dd/yyyy'), 'MM/dd/yyyy');

  useEffect(() => {
    let id1;
    let id2;
    let id3;
    let id4;
    let id5;
    if (showLoadingAnimation) {
      id1 = setTimeout(() => {
        setIsField1Flipped(true);
      }, runwindowFieldsTimeouts[0]);

      id2 = setTimeout(() => {
        setIsField2Flipped(true);
      }, runwindowFieldsTimeouts[1]);

      id3 = setTimeout(() => {
        setIsField3Flipped(true);
      }, runwindowFieldsTimeouts[2]);

      id4 = setTimeout(() => {
        setIsField4Flipped(true);
      }, runwindowFieldsTimeouts[3]);

      id5 = setTimeout(() => {
        setHasMoreInfoLoaded(true);
      }, runwindowFieldsTimeouts[4]);
    }

    return () => {
      if (showLoadingAnimation) {
        clearTimeout(id1);
        clearTimeout(id2);
        clearTimeout(id3);
        clearTimeout(id4);
        clearTimeout(id5);
      }
    };
  }, [showLoadingAnimation]);

  // If the current start date is before the now date then set start_date to yesterday's date.
  // this is done for showing "Now" option properly
  useEffect(() => {
    let timeId;
    if (start && start.toMillis() < DateTime.local().toMillis()) {
      timeId = setTimeout(() => {
        setFieldValue(
          `run_windows.${ix}.start_date`,
          now.plus({ days: -1 }).toFormat('MM/dd/yyyy')
        );
      }, 0);
    }
    return () => {
      timeId && clearTimeout(timeId);
    };
  }, []);

  const __editableStartSection = (
    <>
      <ReactCardFlip
        isFlipped={isField1Flipped}
        flipSpeedBackToFront={flipSpeedBackToFront}
        flipDirection={flipDirection}
      >
        <div className={classes.selectFieldSkeleton} style={{ ...nowBlockStyles }} />
        <Select
          size="small"
          className={classes.selectField}
          variant="outlined"
          style={{ ...nowBlockStyles }}
          name={`run_windows.${ix}.start_date`}
          onChange={(val: string) => {
            // set start_time when Start Date value is Now
            if (val === now.plus({ days: -1 }).toFormat('MM/dd/yyyy')) {
              setFieldValue(
                `run_windows.${ix}.start_time`,
                TIMES_ARRAY[findNearestTimeFloor(TIMES_ARRAY, DateTime.local().plus({ hours: 1 }))]
              );
            }
          }}
          renderValue={(val: string) => {
            const as_of = now.toFormat('MM/dd/yyyy');
            console.log('val inside render', { val, as_of, is_now });
            return val
              ? formatDayNow(
                  DateTime.fromFormat(val, 'MM/dd/yyyy'),
                  DateTime.fromFormat(as_of, 'MM/dd/yyyy'),
                  !is_now
                )
              : '';
          }}
          showError={false}
        >
          <MenuItem value={now.plus({ days: -1 }).toFormat('MM/dd/yyyy')}>Now</MenuItem>
          {DATE_IX_ARRAY.map((ix, i) => {
            const dt = ix === 0 ? first_custom_option_dt : first_dt.plus({ days: ix });

            return (
              <MenuItem key={`start-date-${ix}-${i}`} value={dt.toFormat('MM/dd/yyyy')}>
                {ix === 0
                  ? `Today (${dt.toFormat('yyyy-MM-dd')})`
                  : `${dt.toFormat('ccc')} (${dt.toFormat('yyyy-MM-dd')})`}
              </MenuItem>
            );
          })}
        </Select>
      </ReactCardFlip>
      {!is_now && (
        <ReactCardFlip
          isFlipped={isField2Flipped}
          flipSpeedBackToFront={flipSpeedBackToFront}
          flipDirection={flipDirection}
        >
          <div className={classes.selectFieldSkeleton} style={{ width: 84 }} />
          <Select
            size="small"
            className={classes.selectField}
            variant="outlined"
            disabled={runwindowCurrentValue.start_date === null}
            name={`run_windows.${ix}.start_time`}
            showError={false}
          >
            {filtered_start_times.map((time, time_ix) => {
              const dt = new Date(`1/1/2020 ${time}`);
              return (
                <MenuItem key={`start-time-${time_ix}`} value={time}>
                  {format(dt, 'h:mm A')}
                </MenuItem>
              );
            })}
          </Select>
        </ReactCardFlip>
      )}
    </>
  );

  const __editableEndSection = (
    <>
      <ReactCardFlip
        isFlipped={isField3Flipped}
        flipSpeedBackToFront={flipSpeedBackToFront}
        flipDirection={flipDirection}
      >
        <div className={classes.selectFieldSkeleton} style={{ ...foreverBlockStyles }} />
        <Select
          size="small"
          className={classes.selectField}
          variant="outlined"
          onChange={(val: string) => {
            // set end_time when End Date value is Forever
            if (val === NO_END.toFormat('MM/dd/yyyy')) {
              setFieldValue(
                `run_windows.${ix}.end_time`,
                TIMES_ARRAY[findNearestTimeFloor(TIMES_ARRAY, DateTime.local().plus({ hours: 1 }))]
              );
            }
          }}
          renderValue={(val: string) => {
            const as_of = now.toFormat('MM/dd/yyyy');
            return val
              ? formatDayNow(
                  DateTime.fromFormat(val, 'MM/dd/yyyy'),
                  DateTime.fromFormat(as_of, 'MM/dd/yyyy')
                )
              : '';
          }}
          style={{ ...foreverBlockStyles }}
          name={`run_windows.${ix}.end_date`}
          showError={false}
        >
          <MenuItem value={NO_END.toFormat('MM/dd/yyyy')}>Forever</MenuItem>
          {DATE_IX_ARRAY.map((ix) => {
            const dt = ix === 0 ? first_custom_option_dt : first_dt.plus({ days: ix });
            return (
              <MenuItem key={`end-date-${ix}`} value={dt.toFormat('MM/dd/yyyy')}>
                {ix === 0
                  ? `Today (${dt.toFormat('yyyy-MM-dd')})`
                  : `${dt.toFormat('ccc')} (${dt.toFormat('yyyy-MM-dd')})`}
              </MenuItem>
            );
          })}
        </Select>
      </ReactCardFlip>

      {!is_no_end && (
        <ReactCardFlip
          isFlipped={isField4Flipped}
          flipSpeedBackToFront={flipSpeedBackToFront}
          flipDirection={flipDirection}
        >
          <div className={classes.selectFieldSkeleton} style={{ width: 84 }} />
          <Select
            size="small"
            variant="outlined"
            className={classes.selectField}
            name={`run_windows.${ix}.end_time`}
            disabled={runwindowCurrentValue.end_date === null}
            showError={false}
          >
            {filtered_end_times.map((time, ix) => {
              const dt = new Date(`1/1/2020 ${time}`);
              return (
                <MenuItem key={`end-time-${ix}`} value={time}>
                  {format(dt, 'h:mm A')}
                </MenuItem>
              );
            })}
          </Select>
        </ReactCardFlip>
      )}
    </>
  );

  if (errors && errors.run_windows && errors.run_windows[ix]) {
    runwindowErrors = Object.values(errors.run_windows[ix]);
    hasRunwindowsNagativeRuntime = runwindowErrors.includes('Negative runtime');
  }

  const _mobileViewRunWindow = (
    <div className={classes.mobileRunWindowRoot}>
      <div style={{ position: 'absolute', left: -7 }}>
        <RunWindowStartEndIndicator recommendationOptionValue={recommendationOptionValue} />
      </div>
      <div className={classes.mobileRunWindow}>
        <div>
          {/* start section*/}
          <div className={classes.mobileRunWindowFieldsContainer} style={{ marginBottom: 12 }}>
            <div className={classes.centerItem}>{__editableStartSection}</div>
            <div style={{ marginLeft: 8 }}>
              <div className={classes.moreInfoLabels}>Runtime</div>
              <div
                className={classes.moreInfoValues}
                style={{ color: hasRunwindowsNagativeRuntime ? amber_error_red : undefined }}
              >
                {hasMoreInfoLoaded ? (
                  runtimeTextOnMobile
                ) : (
                  <div className={classes.dashPlaceholder}>——</div>
                )}
              </div>
            </div>
          </div>
          {/* end section */}
          <div className={classes.mobileRunWindowFieldsContainer}>
            <div className={classes.centerItem}>{__editableEndSection}</div>
            <div style={{ marginLeft: 8 }}>
              <div className={classes.moreInfoLabels}>Avg weather</div>
              <div className={classes.moreInfoValues}>
                {hasMoreInfoLoaded ? (
                  avgWeatherTextOnMobile
                ) : (
                  <div className={classes.dashPlaceholder}>—— • ——</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSmallMobile && (
        <div className={classes.mobileRunWindowDelete} onClick={handleDeleteRunwindow}>
          <IconButton style={{ padding: 0 }}>
            <DeleteIcon />
          </IconButton>
        </div>
      )}
    </div>
  );

  console.log('hasRunwindowsNagativeRuntime', hasRunwindowsNagativeRuntime);

  console.log('weatherData', weatherData);
  if (!weatherData) return null;

  console.log('weatherData.dailyForecast', weatherData.dailyForecast);
  // runwindow weather chart inputs
  const lastDailyForecastDate = new Date(
    weatherData.dailyForecast[weatherData.dailyForecast.length - 1].epoch_time
  );

  const hasNoRunWindowErrors = runwindowErrors.length === 0;
  const asOf = DateTime.local();
  const startDayDate = start && hasNoRunWindowErrors ? start.startOf('day') : asOf.startOf('day');
  const endDayDate = end && hasNoRunWindowErrors ? end.endOf('day') : asOf.endOf('day');
  const chartDaysInterval = Interval.fromDateTimes(startDayDate, endDayDate);

  const hourlyForecast = weatherData.hourlyForecast.filter(({ epoch_time }) =>
    chartDaysInterval.contains(DateTime.fromMillis(new Date(epoch_time).getTime()))
  );
  const dailyForecast = weatherData.dailyForecast.filter(({ epoch_time }) =>
    chartDaysInterval.contains(DateTime.fromMillis(new Date(epoch_time).getTime()))
  );

  const hasDailyForecast = dailyForecast.length > 0;

  const currRunWindow =
    start && end && hasNoRunWindowErrors
      ? start.toMillis() < asOf.toMillis()
        ? [{ end, start: asOf }]
        : [{ start, end }]
      : [];

  console.log('in runwindow - hourlyForecast & dailyForecast', {
    hourlyForecast,
    dailyForecast,
    currRunWindow,
    start,
    end,
    runwindowErrors,
  });

  return (
    <>
      {isSmallMobile ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {_mobileViewRunWindow}
          {runwindowErrors.length > 0 && (
            <div style={{ marginTop: 4, width: '100%' }}>
              {runwindowErrors.map((error) => {
                return <p className={classes.error}>{error}</p>;
              })}
            </div>
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', width: '100%' }}>
          <div
            style={{
              width: 280,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {_mobileViewRunWindow}
            {runwindowErrors.length > 0 && (
              <div style={{ marginTop: 4, width: '100%' }}>
                {runwindowErrors.map((error) => {
                  return <p className={classes.error}>{error}</p>;
                })}
              </div>
            )}
          </div>
          {hasDailyForecast && (
            <div className={classes.runwindowWeatherPlotContainer}>
              <IndividualRunwindowWeatherPlot
                grain_bin_location_timezone={weatherData.grain_bin_location_timezone}
                hourly_forecast_data={hourlyForecast}
                days={dailyForecast}
                runWindows={currRunWindow}
              />
            </div>
          )}
          <div
            style={{ width: '21px' }}
            className={classes.mobileRunWindowDelete}
            onClick={handleDeleteRunwindow}
          >
            <IconButton style={{ padding: 0 }}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
};
