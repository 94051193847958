import * as React from 'react';
export const CloseRoundedBtnIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12.781}
    height={12.782}
    viewBox="0 0 12.781 12.782"
    {...props}
  >
    <path
      id="CloseIcon"
      d="M205.707,123.524a1.024,1.024,0,0,0-1.445,0l-4.648,4.644-4.644-4.644a1.022,1.022,0,0,0-1.445,1.445l4.644,4.644-4.644,4.648a1.024,1.024,0,0,0,0,1.445,1.021,1.021,0,0,0,1.445,0l4.644-4.644,4.648,4.644a1.021,1.021,0,0,0,1.445,0,1.024,1.024,0,0,0,0-1.445l-4.644-4.648,4.644-4.644a1.024,1.024,0,0,0,0-1.445"
      transform="translate(-193.225 -123.225)"
      fill="#626060"
      fillRule="evenodd"
    />
  </svg>
);
