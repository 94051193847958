import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps, ContainerTypeLegacy } from '../../../util';
import {
  GetGrainContainerMonthlyCo2HistoryWithAerationsQuery,
  withGetGrainContainerMonthlyCo2HistoryWithAerations as HOC,
} from '../__generated';

export type WithGetGrainContainerMonthlyCO2HistoryWithAerationsHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  pollInterval?: number;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainContainerMonthlyCO2HistoryWithAerationsHocChildProps = {
  loading: boolean;
  grain_container_monthly_headspace_data:
    | GetGrainContainerMonthlyCo2HistoryWithAerationsQuery['grain_container']
    | null;
};
export const withGetGrainContainerMonthlyCO2HistoryWithAerationsHoc: AmberHoc<
  WithGetGrainContainerMonthlyCO2HistoryWithAerationsHocOwnProps,
  WithGetGrainContainerMonthlyCO2HistoryWithAerationsHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainContainerMonthlyCO2HistoryWithAerationsHocOwnProps,
      WithGetGrainContainerMonthlyCO2HistoryWithAerationsHocChildProps,
      typeof component
    >,
    WithGetGrainContainerMonthlyCO2HistoryWithAerationsHocChildProps
  >({
    options: ({ grain_bin_id, onError, pollInterval = 1000 * 60 * 30 }) => ({
      pollInterval,
      onError,
      errorPolicy: onError ? 'all' : 'none',
      variables: { container_id: grain_bin_id, container_type: ContainerTypeLegacy.bin },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          loading: true,
          grain_container_monthly_headspace_data: null,
        };
      }
      return {
        loading: loading || false,
        grain_container_monthly_headspace_data: data.grain_container || null,
      };
    },
  })(component as any);
