import { Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  ActiveHubsLevelSamples,
  GrainBinLevelSample,
  GrainBinTicketLevelSample,
} from '../../../api';

import { ActiveHubsWithColorContext } from '../../../contexts';
import { ActiveStoragePeriodContext } from '../../../contexts/activeStoragePeriod';
import { amber_amber, white } from '../../../style';
import { CenteredSpinner } from '../../spinner';

import { isValidDate } from '../summary-card/charts/LevelHistoryPlotHelpers';
import LevelHistoryPlotV2 from '../summary-card/charts/LevelHistoryPlotV2';

const useStyles = makeStyles({
  root_0: {
    paddingBottom: 25,
  },
  root_1: {
    paddingBottom: 0,
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 196,
    height: '100%',
  },
  selectedButton: {
    backgroundColor: amber_amber,
    color: white,
    '&:hover': {
      backgroundColor: fade(amber_amber, 0.8),
    },
  },
});

export const WeeklyGrainLevelChart = ({
  period,
  loading,
  height,
  minHeight,
  maxHeight,
  grain_bin_location_timezone,
  grain_bin_level_history,
  grain_bin_tickets_level_history,
}) => {
  console.log('Grain Bin Level History:', grain_bin_level_history);
  console.log('Grain Bin Tickets History:', grain_bin_tickets_level_history);

  const classes = useStyles();
  const dispatch = useDispatch();
  const ctx = useContext(ActiveStoragePeriodContext);
  const activeStoragePeriod = ctx.activeStoragePeriod;
  const activeHubsWithColorContext = useContext(ActiveHubsWithColorContext);
  const activeHubsWithColor = activeHubsWithColorContext.activeHubsWithColor;
  const today = DateTime.local();
  today.set({ hour: 12 });

  const hasLevelSamples = (active_hubs_level_samples) => {
    const rows = active_hubs_level_samples.filter((row) => row.level_samples.length !== 0);
    return rows.length > 0;
  };

  const { max_bushels, active_hubs_level_samples } = grain_bin_level_history;
  const { grain_tickets_level_samples } = grain_bin_tickets_level_history;
  const {
    no_grain_tickets_created,
    grain_tickets_bushel_level_on_period_start,
    grain_tickets_bushel_level_on_period_end,
  } = grain_bin_tickets_level_history;

  const active_hubs_formatted_level_data =
    active_hubs_level_samples.length > 0
      ? active_hubs_level_samples.map(({ hub_id, level_samples }: ActiveHubsLevelSamples) => {
          const bushelsData =
            level_samples.length > 0
              ? level_samples
                  .filter(({ epoch_time: dt }: GrainBinLevelSample) => {
                    if (
                      activeStoragePeriod &&
                      activeStoragePeriod.start_date.getTime() <= new Date(dt).getTime() &&
                      new Date(dt).getTime() <=
                        (activeStoragePeriod.end_date
                          ? activeStoragePeriod.end_date.getTime()
                          : Infinity)
                    ) {
                      return isValidDate(dt, period, today);
                    }
                    if (activeStoragePeriod === null) {
                      return isValidDate(dt, period, today);
                    }
                    return false;
                  })
                  .map(({ epoch_time, bushel }: GrainBinLevelSample) => ({
                    x: DateTime.fromMillis(new Date(epoch_time).getTime())
                      .set({ second: 0 })
                      .toMillis(),
                    y: bushel,
                  }))
                  .sort((a, b) => new Date(b.x).getTime() - new Date(a.x).getTime())
              : [];
          return { hub_id, bushelsData };
        })
      : [];

  // Grain Tickets within Active Storage Period
  let active_grain_tickets_level_data =
    grain_tickets_level_samples.length > 0
      ? grain_tickets_level_samples
          .filter(({ epoch_time: dt }: GrainBinTicketLevelSample) => {
            if (
              activeStoragePeriod &&
              activeStoragePeriod.start_date.getTime() <= new Date(dt).getTime() &&
              new Date(dt).getTime() <=
                (activeStoragePeriod.end_date ? activeStoragePeriod.end_date.getTime() : Infinity)
            ) {
              return true;
            }
            if (activeStoragePeriod === null) {
              return true;
            }
            return false;
          })
          .map(({ epoch_time, bushel }: GrainBinTicketLevelSample) => ({
            x: DateTime.fromMillis(new Date(epoch_time).getTime()).toMillis(),
            y: bushel,
          }))
      : [];

  // added grain bushel level on period start and end
  if (grain_tickets_bushel_level_on_period_start && grain_tickets_bushel_level_on_period_end) {
    active_grain_tickets_level_data = [
      {
        x: DateTime.fromMillis(
          new Date(grain_tickets_bushel_level_on_period_start.epoch_time).getTime()
        ).toMillis(),
        y: grain_tickets_bushel_level_on_period_start.bushel,
      },
      ...active_grain_tickets_level_data,
      {
        x: DateTime.fromMillis(
          new Date(grain_tickets_bushel_level_on_period_end.epoch_time).getTime()
        ).toMillis(),
        y: grain_tickets_bushel_level_on_period_end.bushel,
      },
    ];
  }

  console.log('active_hubs_formatted_level_data', active_hubs_formatted_level_data);
  console.log('active_grain_tickets_level_data', active_grain_tickets_level_data);

  const hasEmptyPlaceHolder =
    !loading &&
    (grain_bin_level_history &&
      grain_bin_level_history.active_hubs_level_samples.length === 0 &&
      no_grain_tickets_created);

  const EmptyPlaceholder = () => (
    <div
      style={{
        height: '100%',
        minHeight: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" style={{ fontWeight: 600 }}>
        No Grain Level Data
        <br /> Add a Grain Ticket to start tracking
      </Typography>
    </div>
  );

  return (
    <div
      style={{
        minHeight: minHeight && minHeight,
        maxHeight: maxHeight && maxHeight,
        padding: '10px 0px',
      }}
    >
      {hasEmptyPlaceHolder ? (
        <EmptyPlaceholder />
      ) : (
        <div className={classes.root_0}>
          {loading ? (
            <div style={height ? { minHeight: height } : {}} className={classes.spinner}>
              <CenteredSpinner fadeIn="none" />
            </div>
          ) : (
            <>
              <LevelHistoryPlotV2
                grain_bin_location_timezone={grain_bin_location_timezone}
                period={period}
                active_hubs_formatted_level_data={active_hubs_formatted_level_data}
                active_grain_tickets_level_data={active_grain_tickets_level_data}
                max_bushels={max_bushels}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};
