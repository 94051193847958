import { fade } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { DomainTuple } from 'victory';
import { getTemperatureUnitLabel, RelativeTimePeriod, Statistic } from '../../../../../src/util';
import {
  amber_faded_green,
  amber_grey,
  amber_light_green,
  amber_red,
  amber_yellow,
  gray_shade_2,
  red_shade_2,
  yellow_shade_2,
} from '../../../../style';
import { WEATHER_CHART_COLORS } from '../../daily-forecast/ForecastPlotHelpers';
import {
  HistoryPlotPoint,
  HistoryRecordWithHubID,
  MIN_RANGE,
  PLOT_BANDS,
} from './MultiHubTelemetryHistoryPlotV2';

const getDefaultDomain = (statistic: Statistic): DomainTuple => {
  switch (statistic) {
    case Statistic.humidity_rh:
      return [0, 1];
    case Statistic.temp_f:
      return [0, 90];
    case Statistic.emc:
      return [0.1, 0.25];
    case Statistic.co2_ppm:
      // wider range to minimize concern from minimal variation
      return [300, 2000];
    default:
      return [0, 1];
  }
};

const NORMAL_CO2_PPM_MAX = 2000;

const getDomain = (
  history: HistoryRecordWithHubID[],
  weather_history: HistoryPlotPoint[],
  hasWeatherSeriesVisible: boolean,
  statistic: Statistic
): DomainTuple => {
  if (history.length) {
    let { outer_min, outer_max } = history.reduce(
      ({ outer_min, outer_max }, { data }) => {
        // find min & max within individual hub
        const { min, max } = data.reduce(
          ({ min: inner_min, max: inner_max }, row) => ({
            min: Math.min(row.y, inner_min),
            max: Math.max(row.y, inner_max),
          }),
          {
            min: data[0] && data[0].y,
            max: data[0] && data[0].y,
          }
        );

        // find min & max among all hubs
        return {
          outer_min: Math.min(min, outer_min),
          outer_max: Math.max(max, outer_max),
        };
      },
      {
        outer_min: history[0] && history[0].data[0] && history[0].data[0].y,
        outer_max: history[0] && history[0].data[0] && history[0].data[0].y,
      }
    );

    if (hasWeatherSeriesVisible && weather_history.length) {
      const { min: weather_history_min, max: weather_history_max } = weather_history.reduce(
        ({ min: inner_min, max: inner_max }, row) => ({
          min: Math.min(row.y, inner_min),
          max: Math.max(row.y, inner_max),
        }),
        {
          min: weather_history[0] && weather_history[0].y,
          max: weather_history[0] && weather_history[0].y,
        }
      );
      outer_min = weather_history_min < outer_min ? weather_history_min : outer_min;
      outer_max = weather_history_max > outer_max ? weather_history_max : outer_max;
    }

    if (statistic === Statistic.co2_ppm) {
      outer_min = 0;
      outer_max =
        outer_max > NORMAL_CO2_PPM_MAX ? Math.ceil(outer_max / 500) * 500 : NORMAL_CO2_PPM_MAX;
    } else {
      const diff = outer_max - outer_min;
      const min_range = MIN_RANGE[statistic];
      if (diff < min_range) {
        const extra = (min_range - diff) / 2;
        outer_min -= extra;
        outer_max += extra;
      }
    }

    return [outer_min, outer_max];
  }
  return getDefaultDomain(statistic);
};

const getTickValues = (
  period: RelativeTimePeriod,
  year: number,
  month: number,
  date: number,
  hour: number
): DateTime[] => {
  if (period === RelativeTimePeriod.day) {
    return [
      DateTime.local(year, month, date, hour).plus({ hours: -24 }),
      DateTime.local(year, month, date, hour).plus({ hours: -20 }),
      DateTime.local(year, month, date, hour).plus({ hours: -16 }),
      DateTime.local(year, month, date, hour).plus({ hours: -12 }),
      DateTime.local(year, month, date, hour).plus({ hours: -8 }),
      DateTime.local(year, month, date, hour).plus({ hours: -4 }),
      DateTime.local(),
    ];
  }
  if (period === RelativeTimePeriod.week) {
    return [
      DateTime.local(year, month, date, 12).plus({ days: -6 }),
      DateTime.local(year, month, date, 12).plus({ days: -5 }),
      DateTime.local(year, month, date, 12).plus({ days: -4 }),
      DateTime.local(year, month, date, 12).plus({ days: -3 }),
      DateTime.local(year, month, date, 12).plus({ days: -2 }),
      DateTime.local(year, month, date, 12).plus({ days: -1 }),
      DateTime.local(),
    ];
  }
  if (period === RelativeTimePeriod.month) {
    return [
      DateTime.local(year, month, date, 12).plus({ days: -28 }),
      DateTime.local(year, month, date, 12).plus({ days: -21 }),
      DateTime.local(year, month, date, 12).plus({ days: -14 }),
      DateTime.local(year, month, date, 12).plus({ days: -7 }),
      DateTime.local(),
    ];
  }
  if (period === RelativeTimePeriod.quarter) {
    if (date < 15) {
      return [
        DateTime.local(year, month, date, 12).plus({ months: -3 }),
        DateTime.local(year, month, date, 12).plus({ months: -2 }),
        DateTime.local(year, month, date, 12).plus({ months: -1 }),
        DateTime.local(),
      ];
    }
    return [
      DateTime.local(year, month, 15, 12).plus({ months: -2 }),
      DateTime.local(year, month, 15, 12).plus({ months: -1 }),
      DateTime.local(),
    ];
  }
  throw new Error(`Unexpected period ${period}`);
};

const getTooltipInfo = (hub, statistic): string => {
  if (!hub) return '';
  let statisticDataPoint = '';
  const tempUnit = getTemperatureUnitLabel();
  switch (statistic) {
    case Statistic.temp_f:
      statisticDataPoint =
        `<span style="color:${hub.color};">\u25CF</span> ` +
        ` <b>${Number(hub.y).toFixed(1)}${tempUnit}</b><br/>`;
      break;
    case Statistic.humidity_rh:
      statisticDataPoint =
        `<span style="color:${hub.color};">\u25CF</span> ` +
        ` <b>${Number(hub.y * 100).toFixed(1)}% RH</b><br/>`;
      break;
    case Statistic.emc:
      statisticDataPoint =
        `<span style="color:${hub.color};">\u25CF</span> ` +
        ` <b>${Number(hub.y * 100).toFixed(1)}% MC</b><br/>`;
      break;
    case Statistic.co2_ppm:
      statisticDataPoint =
        `<span style="color:${hub.color};">\u25CF</span> ` +
        `<b>${Math.round(Number(hub.y))} ppm</b><br/>`;
      break;
    default:
      statisticDataPoint = '';
      break;
  }
  return statisticDataPoint;
};

const getHorizontalPlotBands = (statistic, y_max, chart) => {
  const labelThreshold = 4000;
  if (statistic === Statistic.co2_ppm) {
    return [
      {
        color: yellow_shade_2,
        from: 1000,
        to: 1500,
        zIndex: 1,
        label: {
          y: y_max > labelThreshold ? 10 : 25,
          align: 'left',
          verticalAlign: 'top',
          useHTML: true,
          text: `<span>${PLOT_BANDS.INSPECT}</span>`,
          style: {
            backgroundColor: yellow_shade_2,
            borderRadius: '8px',
            padding: '1px 2px',
            fontFamily: 'Source Sans Pro, sans-serif',
            fontWeight: 600,
            fontSize: '13px',
          },
        },
      },
      {
        color: red_shade_2,
        from: 1500,
        to: y_max,
        zIndex: 1,
        label: {
          y: y_max > labelThreshold ? 30 : 25,
          useHTML: true,
          align: 'left',
          verticalAlign: 'top',
          text: `<span>${PLOT_BANDS.TAKE_ACTION}</span>`,
          style: {
            backgroundColor: red_shade_2,
            borderRadius: '8px',
            padding: '1px 2px',
            fontFamily: 'Source Sans Pro, sans-serif',
            fontWeight: 600,
            fontSize: '13px',
          },
        },
      },
    ];
  }
  return [];
};

const getXaxisInterval = (period, isMobile) => {
  let interval;
  if (period === RelativeTimePeriod.day) {
    interval = isMobile ? 6 * 3600 * 1000 : 4 * 3600 * 1000; // 24 hrs
  } else if (period === RelativeTimePeriod.week) {
    interval = 24 * 3600 * 1000; // 24 hrs
  } else if (period === RelativeTimePeriod.month) {
    interval = 7 * 24 * 3600 * 1000; // 7 days
  } else if (period === RelativeTimePeriod.quarter) {
    interval = 31 * 24 * 3600 * 1000; // 1 month
  }
  return interval;
};

const getRunWindowsPlotBands = (statistic, runWindows, xMax) => {
  if (runWindows && runWindows.length > 0) {
    return runWindows.map(({ start_epoch, end_epoch }) => ({
      color: statistic === Statistic.co2_ppm ? 'rgba(207, 208, 210, 0.5)' : amber_faded_green,
      from: DateTime.fromMillis(start_epoch).toMillis(),
      to: end_epoch ? DateTime.fromMillis(end_epoch).toMillis() : xMax,
      zIndex: 2,
    }));
  }
  return [];
};

const getTickInterval = (statistic) => {
  let interval;
  if (statistic === Statistic.co2_ppm) {
    interval = 500;
  } else if (statistic === Statistic.humidity_rh) {
    interval = 0.25;
  }
  return interval;
};

const WEATHER_STATISTIC_LINE_COLOR = {
  [Statistic.temp_f]: WEATHER_CHART_COLORS['HIST_TEMP_SERIES_COLOR'],
  [Statistic.humidity_rh]: WEATHER_CHART_COLORS['HIST_HUMIDITY_SERIES_COLOR'],
  [Statistic.emc]: WEATHER_CHART_COLORS['HIST_EMC_SERIES_COLOR'],
};

export {
  getTickValues,
  getDomain,
  getTooltipInfo,
  getTickInterval,
  getHorizontalPlotBands,
  getXaxisInterval,
  getRunWindowsPlotBands,
  WEATHER_STATISTIC_LINE_COLOR,
};
