import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import { render } from 'react-dom';
import App from './component/App';

const initializeApp = async () => {
  const version_response = await fetch(`/version.json?t=${new Date().getTime()}`);
  const app_config = await version_response.json();
  window['app_config'] = app_config.config;

  if (app_config.config.env === 'local') {
    console.log('App config:', app_config.config);
  }

  const env = window['app_config'].env;
  console.log('Environment', env);

  datadogLogs.setLoggerGlobalContext({ env });
  datadogLogs.logger.setHandler(['http', 'console']);

  if (env === 'dev') {
    datadogLogs.init({
      clientToken: 'pub954529ab8b5174d86040a6b168a724cf',
      site: 'datadoghq.com',
      service: 'amber-dev-customer-dashboard',
      forwardErrorsToLogs: true,
      sampleRate: 100,
    });

    datadogRum.init({
      applicationId: 'b18d62ef-fd59-424f-a94a-d1d0a511577a',
      clientToken: 'pub954529ab8b5174d86040a6b168a724cf',
      site: 'datadoghq.com',
      service: 'amber-dev-customer-dashboard',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  } else if (env === 'prod') {
    datadogLogs.init({
      clientToken: 'pub954529ab8b5174d86040a6b168a724cf',
      service: 'amber-prod-customer-dashboard',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: 100,
    });
    datadogRum.init({
      applicationId: '9641def9-dec7-4325-a923-c12e375c7253',
      clientToken: 'pub7a48f9325ccb59a42e9a57f367a062a6',
      site: 'datadoghq.com',
      service: 'amber-prod-customer-dashboard',
      env: 'prod',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
    });
    datadogRum.startSessionReplayRecording();
  }

  const root = document.getElementById('app');
  render(<App />, root);
};

// tslint:disable-next-line:ter-arrow-parens
initializeApp().then((r) => console.log('The Amber Ag Dashboard Application has loaded.'));
