import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  fade,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import {
  CountBadge,
  formatGrainType,
  formatNumber,
  getHasUserSelectedKilogramUnit,
  getUserSelectedMassUnit,
  GrainTicketActionType,
  MassUnitType,
  UnitsConverter,
  UserRole,
} from '../../../../../core/src';

import { amber_dark_grey, green_shade_1, light_gray2, red_shade_3 } from '../../../style';
import { Button } from '../../util/form2/Button';
import { getUserPermissionTooltipText } from '../aeration';
import { CreateOrUpdateGrainTicketForm } from './CreateOrUpdateGrainTicketForm';
import { GrainBinTicketsTable } from './GrainBinTicketsTable';

const useStyles = makeStyles({
  grid: {
    minWidth: 300,
    maxWidth: 700,
  },
  header: {
    margin: '15px 0px',
  },
  createTicketsInfoSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 18,
    backgroundColor: light_gray2,
    borderRadius: 8,
    margin: '16px 8px',
  },
  grainButton: {
    width: 'fit-content',
    borderRadius: 20,
    height: 33,
    fontWeight: 600,
    '&:focus': {
      border: '1px solid black',
      backgroundColor: 'white',
    },
    '&:active': {
      border: '1px solid black',
      backgroundColor: 'white',
    },
  },
});

const GrainBinTicketsContainer = ({
  viewer_role,
  grain_bin_id,
  setUpdating,
  grain_type,
  grainBinCapacity,
  grain_bin_all_tickets,
  refetchGrainBin,
  refetchGrainBinTickets,
  grain_bin_tickets_total_bushels,
  grain_bin_tickets_total_weight_in_lbs,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);
  const [newTicketGrainActionPerformed, setNewTicketGrainActionPerformed] = useState<
    GrainTicketActionType | undefined
  >();
  const handleExpandChange = (grainTicketIndex) => (isExpanded) => {
    setExpanded(isExpanded ? grainTicketIndex : null);
  };
  const handleAddGrainClick = () => {
    handleExpandChange(-1)(true);
    setNewTicketGrainActionPerformed(GrainTicketActionType.Added);
  };
  const handleRemoveGrainClick = () => {
    handleExpandChange(-1)(true);
    setNewTicketGrainActionPerformed(GrainTicketActionType.Removed);
  };
  const prefMassUnit = getUserSelectedMassUnit();
  const hasUserSelectedKilogramUnit = getHasUserSelectedKilogramUnit();
  const isImperialUnitSelected = prefMassUnit === MassUnitType.Pound;
  const grainTicketsTotal = hasUserSelectedKilogramUnit
    ? UnitsConverter.convertPoundToMetricTon(grain_bin_tickets_total_weight_in_lbs)
    : grain_bin_tickets_total_weight_in_lbs;
  const grainTicketsTotalUnit = hasUserSelectedKilogramUnit ? 'ton' : 'lbs';
  const hasUserAccessRestricted = [UserRole.FanAccess, UserRole.ReadOnly].includes(viewer_role);
  const disabledTooltipText = hasUserAccessRestricted
    ? getUserPermissionTooltipText(viewer_role)
    : '';
  const grainBinAllTicketsCount = grain_bin_all_tickets ? grain_bin_all_tickets.length : 0;
  const showAddGrainTicketForm =
    expanded === -1 && newTicketGrainActionPerformed === GrainTicketActionType.Added;
  const showRemoveGrainTicketForm =
    expanded === -1 && newTicketGrainActionPerformed === GrainTicketActionType.Removed;

  return (
    <Card raised>
      <CardHeader
        className={classes.header}
        title={
          <Typography
            variant="h5"
            style={{
              fontSize: 20,
              color: amber_dark_grey,
              width: 'fit-content',
              textAlign: 'start',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Grain Tickets <CountBadge>{grainBinAllTicketsCount}</CountBadge>
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <Grid
          container
          direction="row"
          alignContent="flex-start"
          alignItems="center"
          justify="center"
          spacing={2}
          className={classes.grid}
        >
          <Grid item xs={12} className={classes.createTicketsInfoSection} style={{ padding: 12 }}>
            {isImperialUnitSelected ? (
              <Typography variant="h6" style={{ fontSize: 17 }}>
                Current Grain Tickets Inventory:{' '}
                <span
                  style={{
                    fontWeight: 600,
                    color: grain_bin_tickets_total_bushels < 0 ? 'red' : '',
                  }}
                >
                  {grain_bin_tickets_total_bushels
                    ? formatNumber(grain_bin_tickets_total_bushels)
                    : 0}{' '}
                  bu
                </span>
              </Typography>
            ) : (
              <Typography variant="h6" style={{ fontSize: 17 }}>
                Grain Bin Total Weight:{' '}
                <span
                  style={{
                    fontWeight: 600,
                    color: grainTicketsTotal < 0 ? 'red' : '',
                  }}
                >
                  {grainTicketsTotal ? formatNumber(grainTicketsTotal) : 0} {grainTicketsTotalUnit}
                </span>
              </Typography>
            )}

            {grainBinCapacity && (
              <Typography variant="subtitle1">
                Bin Capacity:{' '}
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {grainBinCapacity}
                </span>
              </Typography>
            )}

            {grain_type && (
              <Typography variant="subtitle1">
                Grain Type:{' '}
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {formatGrainType(grain_type)}
                </span>
              </Typography>
            )}
            <div style={{ display: 'flex', marginTop: 22 }}>
              <Tooltip
                enterTouchDelay={0}
                disableFocusListener
                placement="right"
                title={
                  disabledTooltipText ? (
                    <span style={{ fontSize: 13 }}>{disabledTooltipText}</span>
                  ) : (
                    ''
                  )
                }
                arrow
              >
                <div style={{ marginRight: 16 }}>
                  <Button
                    className={classes.grainButton}
                    variant="outlined"
                    onClick={handleAddGrainClick}
                    disabled={hasUserAccessRestricted}
                  >
                    <AddCircle
                      style={{
                        color: hasUserAccessRestricted ? fade(green_shade_1, 0.3) : green_shade_1,
                      }}
                    />
                    &nbsp; Add Grain
                  </Button>
                </div>
              </Tooltip>

              <Tooltip
                enterTouchDelay={0}
                disableFocusListener
                placement="right"
                title={
                  disabledTooltipText ? (
                    <span style={{ fontSize: 13 }}>{disabledTooltipText}</span>
                  ) : (
                    ''
                  )
                }
                arrow
              >
                <div>
                  <Button
                    className={classes.grainButton}
                    variant="outlined"
                    onClick={handleRemoveGrainClick}
                    disabled={hasUserAccessRestricted}
                  >
                    <AddCircle
                      style={{
                        color: hasUserAccessRestricted ? fade(red_shade_3, 0.3) : red_shade_3,
                      }}
                    />
                    &nbsp; Remove Grain
                  </Button>
                </div>
              </Tooltip>
            </div>
            {showAddGrainTicketForm && (
              <CreateOrUpdateGrainTicketForm
                grain_bin_id={grain_bin_id}
                setUpdating={setUpdating}
                handleCloseForm={() => setExpanded(null)}
                refetchGrainBin={refetchGrainBin}
                refetchGrainBinTickets={refetchGrainBinTickets}
                grain_type={grain_type}
                action_performed={GrainTicketActionType.Added}
              />
            )}
            {showRemoveGrainTicketForm && (
              <CreateOrUpdateGrainTicketForm
                grain_bin_id={grain_bin_id}
                setUpdating={setUpdating}
                handleCloseForm={() => setExpanded(null)}
                refetchGrainBin={refetchGrainBin}
                refetchGrainBinTickets={refetchGrainBinTickets}
                grain_type={grain_type}
                action_performed={GrainTicketActionType.Removed}
              />
            )}
          </Grid>
          <Grid item xs={12} style={{ padding: 0 }}>
            <GrainBinTicketsTable
              grain_bin_id={grain_bin_id}
              grain_bin_tickets={grain_bin_all_tickets}
              expanded={expanded}
              setUpdating={setUpdating}
              handleExpandChange={handleExpandChange}
              refetchGrainBin={refetchGrainBin}
              refetchGrainBinTickets={refetchGrainBinTickets}
              grain_type={grain_type}
              hasUserAccessRestricted={hasUserAccessRestricted}
              viewer_role={viewer_role}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GrainBinTicketsContainer;
