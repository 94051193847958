import { datadogLogs } from '@datadog/browser-logs';
import { ApolloError } from 'apollo-client';
import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { ActiveStoragePeriodContext, UserContext } from '../../contexts';

import { pushNav } from '../../action';
import { AccountFragmentFragment, UserRole, ViewerFragmentFragment } from '../../api';
import { ContainerTypeLegacy } from '../../util';
import { CreateGrainBinWizard } from '../device-wizard/CreateGrainBinWizard';
import { ErrorLayout } from '../ErrorLayout';
import { AuthenticatedRoute } from '../util';
import { AerationScheduleLayout } from './aeration';
import { BinEventsForm } from './BinEventsForm';
import { GrainBinRoutesSuffix } from './constant';
import { DevicesLayout } from './DevicesLayout';
import { ExportTelemetryForm } from './ExportTelemetryForm';
import { GrainBinDashboard } from './GrainBinDashboard';
import { UpdateGrainBinFormGql } from './GrainBinForm';
import { ManageGrainBinTickets } from './manage-grain-tickets';
import { ManageStoragePeriods } from './ManageStoragePeriods';
import WeatherForecast from './weather-forecast-analysis/WeatherForecast';
import { WeeklyGrainDashboardAnalysis } from './weekly-grain-dashboard-analysis';

export const validateGrainBinSupportAccess = (
  account: AccountFragmentFragment,
  { account: { grain_bin_support } }: ViewerFragmentFragment
) => account && account.grain_bin_support && grain_bin_support;

const hasSystemAccount = (account: AccountFragmentFragment) => account.system_account;

type GrainBinRoutesProps = RouteComponentProps & {
  accessFailureRedirect?: (
    account: AccountFragmentFragment,
    viewer: ViewerFragmentFragment
  ) => string;
  account_id: number;
  grain_bin_id?: number | null;
  mobile_width?: boolean;
  pathname: string;
  onError: (error: ApolloError) => void;
  goCreateBarge?: () => void;
  goCreatePile?: () => void;
  goCreateGrainBin?: () => void;
  goOrgSettings?: () => void;
  onSelectContainer?: (args: any) => void;
  onArchiveClick?: (args: any) => void;
};

export const GrainBinRoutes = ({
  match: { url },
  account_id,
  grain_bin_id,
  accessFailureRedirect,
  onError,
  mobile_width,
  pathname,
  goCreateBarge,
  goCreatePile,
  goCreateGrainBin,
  goOrgSettings,
  onSelectContainer,
  onArchiveClick,
}: GrainBinRoutesProps) => {
  const dispatch = useDispatch();
  const goDashboard = useCallback(
    (selected?: {
      container_id?: number;
      container_type?: ContainerTypeLegacy;
      container_name?: string;
    }) =>
      dispatch(pushNav({ path: `${url}/${GrainBinRoutesSuffix.dashboard}`, params: { selected } })),
    [url, dispatch]
  );
  const goDashboard2 = useCallback(() => goDashboard(), [goDashboard]);
  const activeStoragePeriodContext = useContext(ActiveStoragePeriodContext);

  return (
    <AuthenticatedRoute
      account_id={account_id}
      validateAccess={validateGrainBinSupportAccess}
      accessFailureRedirect={accessFailureRedirect}
      render={({ viewer }) => {
        console.log('Current Loaded account_id & viewer account_id', {
          currentLoadedAccountId: account_id,
          viewerAccountId: viewer.user.account_id,
        });
        return (
          <UserContext.Provider value={viewer}>
            <Switch>
              {viewer.user.role !== UserRole.ReadOnly && viewer.user.role !== UserRole.FanAccess && (
                <Route
                  path={`${url}/${GrainBinRoutesSuffix.create}`}
                  render={() => (
                    <CreateGrainBinWizard
                      account_id={account_id}
                      onCreateSuccess={({ grain_bin_id, bin_name }) =>
                        goDashboard({
                          container_name: bin_name,
                          container_id: grain_bin_id,
                          container_type: ContainerTypeLegacy.bin,
                        })
                      }
                    />
                  )}
                />
              )}
              {viewer.user.role !== UserRole.ReadOnly && grain_bin_id && (
                <Route
                  path={`${url}/${GrainBinRoutesSuffix.aeration_schedule}`}
                  render={() => (
                    <AerationScheduleLayout
                      grain_bin_id={grain_bin_id}
                      onError={onError}
                      onSubmitSuccess={() => goDashboard()}
                    />
                  )}
                />
              )}

              {hasSystemAccount(viewer.account) && viewer.user.role === UserRole.Admin && (
                <Route
                  path={`${url}/${
                    GrainBinRoutesSuffix.weather_forecast
                  }/:user_id/:account_id/:grain_bin_id`}
                  render={(props) => {
                    const dash = (
                      <WeatherForecast
                        viewer={viewer}
                        grain_bin_id={Number(props.match.params.grain_bin_id)}
                        account_id={Number(props.match.params.account_id)}
                        routeParams={props.match.params}
                        pathname={pathname}
                        mobile_width={mobile_width}
                      />
                    );
                    return dash || <ErrorLayout />;
                  }}
                />
              )}

              {hasSystemAccount(viewer.account) && viewer.user.role === UserRole.Admin && (
                <Route
                  path={`${url}/${
                    GrainBinRoutesSuffix.dashboard_analysis
                  }/:user_id/:account_id/:grain_bin_id`}
                  render={(props) => {
                    const dash = (
                      <WeeklyGrainDashboardAnalysis
                        viewer={viewer}
                        grain_bin_id={Number(props.match.params.grain_bin_id)}
                        account_id={Number(props.match.params.account_id)}
                        routeParams={props.match.params}
                        pathname={pathname}
                        mobile_width={mobile_width}
                        url_base={url}
                      />
                    );
                    return dash || <ErrorLayout />;
                  }}
                />
              )}

              <Route
                path={`${url}/${GrainBinRoutesSuffix.dashboard}`}
                render={() => {
                  const dash = (
                    <GrainBinDashboard
                      viewer={viewer}
                      grain_bin_id={grain_bin_id}
                      pathname={pathname}
                      onSelectContainer={onSelectContainer}
                      goCreateBarge={goCreateBarge}
                      goCreatePile={goCreatePile}
                      goCreateGrainBin={goCreateGrainBin}
                      mobile_width={mobile_width}
                      url_base={url}
                      current_account_id={account_id}
                    />
                  );
                  return dash || <ErrorLayout />;
                }}
              />
              {viewer.user.role !== UserRole.ReadOnly &&
                viewer.user.role !== UserRole.FanAccess &&
                grain_bin_id && (
                  <Route
                    path={`${url}/${GrainBinRoutesSuffix.devices}`}
                    render={() => (
                      <DevicesLayout
                        container_id={grain_bin_id}
                        container_type={ContainerTypeLegacy.bin}
                        onError={onError}
                        onHubAssignmentSuccess={() => {}}
                        onAssignFanControllerSuccess={() => {}}
                        onUnassignFanControllerSuccess={() => {}}
                      />
                    )}
                  />
                )}
              {viewer.user.role !== UserRole.ReadOnly &&
                viewer.user.role !== UserRole.FanAccess &&
                grain_bin_id && (
                  <Route
                    path={`${url}/${GrainBinRoutesSuffix.events}`}
                    render={() => <BinEventsForm grain_bin_id={grain_bin_id} onError={onError} />}
                  />
                )}
              {grain_bin_id && (
                <Route
                  path={`${url}/${GrainBinRoutesSuffix.export_telemetry}`}
                  render={() => (
                    <ExportTelemetryForm
                      container_id={grain_bin_id}
                      container_type={ContainerTypeLegacy.bin}
                      onError={onError}
                    />
                  )}
                />
              )}
              {viewer.user.role !== UserRole.ReadOnly &&
                viewer.user.role !== UserRole.FanAccess &&
                grain_bin_id && (
                  <Route
                    path={`${url}/${GrainBinRoutesSuffix.settings}`}
                    render={(props) =>
                      grain_bin_id && (
                        <UpdateGrainBinFormGql
                          grain_bin_id={grain_bin_id}
                          account_id={account_id}
                          onSubmitSuccess={({ grain_bin_id, bin_name }) =>
                            goDashboard({
                              container_name: bin_name,
                              container_id: grain_bin_id,
                              container_type: ContainerTypeLegacy.bin,
                            })
                          }
                          onError={onError}
                          {...props}
                          url_base={url}
                          onSelectContainer={onSelectContainer}
                          onArchiveClick={onArchiveClick}
                          goOrgSettings={goOrgSettings}
                        />
                      )
                    }
                  />
                )}
              {viewer.user.role !== UserRole.ReadOnly &&
                viewer.user.role !== UserRole.FanAccess &&
                grain_bin_id && (
                  <Route
                    path={`${url}/${GrainBinRoutesSuffix.events}`}
                    render={() => <BinEventsForm grain_bin_id={grain_bin_id} onError={onError} />}
                  />
                )}
              {grain_bin_id && (
                <Route
                  path={`${url}/${GrainBinRoutesSuffix.storage_periods}`}
                  render={() =>
                    grain_bin_id && (
                      <ManageStoragePeriods
                        grain_bin_id={grain_bin_id}
                        onSubmitSuccess={({ grain_bin_id, bin_name }) =>
                          goDashboard({
                            container_name: bin_name,
                            container_id: grain_bin_id,
                            container_type: ContainerTypeLegacy.bin,
                          })
                        }
                        onError={onError}
                        viewer_role={viewer.user.role}
                      />
                    )
                  }
                />
              )}

              {grain_bin_id && activeStoragePeriodContext.activeStoragePeriod !== undefined && (
                <Route
                  path={`${url}/${GrainBinRoutesSuffix.tickets}`}
                  render={() =>
                    grain_bin_id && (
                      <ManageGrainBinTickets
                        grain_bin_id={grain_bin_id}
                        grain_bin_storage_cycle_id={
                          activeStoragePeriodContext.activeStoragePeriod
                            ? activeStoragePeriodContext.activeStoragePeriod
                                .grain_bin_storage_cycle_id
                            : undefined
                        }
                        viewer_role={viewer.user.role}
                        container_id={grain_bin_id}
                        container_type={ContainerTypeLegacy.bin}
                        url_base={url}
                      />
                    )
                  }
                />
              )}
              {grain_bin_id && (
                <Route
                  path={`${url}/:grain_bin_id/${GrainBinRoutesSuffix.tickets}`}
                  render={() =>
                    grain_bin_id && (
                      <ManageGrainBinTickets
                        grain_bin_id={grain_bin_id}
                        grain_bin_storage_cycle_id={
                          activeStoragePeriodContext.activeStoragePeriod
                            ? activeStoragePeriodContext.activeStoragePeriod
                                .grain_bin_storage_cycle_id
                            : undefined
                        }
                        container_id={grain_bin_id}
                        container_type={ContainerTypeLegacy.bin}
                        viewer_role={viewer.user.role}
                        url_base={url}
                      />
                    )
                  }
                />
              )}
              <Route
                render={() => {
                  if (!grain_bin_id) {
                    datadogLogs.logger.error('App is redirected to /grain_bin/create route', {
                      userId: viewer.user_id,
                      accountId: viewer.account_id,
                    });
                    console.info('App is redirected to /grain_bin/create route', {
                      userId: viewer.user_id,
                      accountId: viewer.account_id,
                    });
                    console.error('App is redirected to /grain_bin/create route', {
                      userId: viewer.user_id,
                      accountId: viewer.account_id,
                    });
                  }

                  return (
                    <Redirect
                      to={`${url}/${
                        grain_bin_id ? GrainBinRoutesSuffix.dashboard : GrainBinRoutesSuffix.create
                      }`}
                    />
                  );
                }}
              />
            </Switch>
          </UserContext.Provider>
        );
      }}
    />
  );
};
