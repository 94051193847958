import { DateTime } from 'luxon';
import React, { FunctionComponent } from 'react';
import { ColumnConfig, ItemTable } from '../../../util';
import { formatDate } from '../BinFanSettings';

const getId = ({ fan_controller_id_next }: FanPowerStats) => fan_controller_id_next;

export type FanPowerStats = {
  fan_controller_id_next: number;
  alias?: string | null;
  total_run_hours: number;
  total_kwh: number;
  start_epoch: Date;
};

const columns: ColumnConfig<FanPowerStats>[] = [
  {
    title: 'Fan',
    width: 30,
    align: 'left',
    getValue: ({ alias }) => alias || 'Fan',
  },
  {
    title: 'Run Hours',
    width: 20,
    align: 'left',
    getValue: ({ total_run_hours }) => `${Math.round(total_run_hours)} hrs`,
  },
  {
    title: 'Power Usage',
    width: 20,
    align: 'left',
    getValue: ({ total_kwh }) => `${Math.round(total_kwh)} kwh`,
  },
  {
    title: 'Since',
    width: 20,
    align: 'left',
    getValue: ({ start_epoch }) => {
      return `${formatDate(
        DateTime.fromMillis(new Date(start_epoch).getTime()),
        DateTime.local()
      )}`;
    },
  },
];

export const PowerConsumptionTable: FunctionComponent<{
  fan_power_stats: FanPowerStats[];
}> = ({ fan_power_stats }) => {
  console.log('PowerConsumptionTable', fan_power_stats);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ display: 'border-box', width: '100%', overflowX: 'auto' }}>
        <ItemTable<FanPowerStats> items={fan_power_stats} getId={getId} columns={columns} />
      </div>
    </div>
  );
};
