import { Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Highcharts from 'highcharts';
import $ from 'jquery';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { hasDiagnosticAccess, UserContext } from '../../../../../core/src/contexts';
import {
  ComputedForecastHourV2FragmentFragment,
  ForecastDayV2FragmentFragment,
  GrainContainerAerationRunFragmentFragment,
  RecommendationWindowsFragmentFragment,
  RecommRunWindowsExperiments,
} from '../../../api';
import { ContainerTypeLegacy, isTouchDevice } from '../../../util';
import { ExperimentsCharts } from '../daily-forecast/ExperimentsCharts';
import { WEATHER_CHART_COLORS } from '../daily-forecast/ForecastPlotHelpers';
import WeatherForecastPlot from '../daily-forecast/WeatherForecastPlot';
import AerationHistoryPlot from './AerationHistoryPlot';
import { getAerationsRunsToPresent } from './AerationHistoryPlotHelpers';
import { AerationHistoryRunWinow, FanAutomationPeriod } from './DailyWeatherAndAerationHistory';
import { WeatherChartNavigation } from './WeatherChartNavigation';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexGrow: 1,
    flexWrap: 'nowrap',
  },
  customLegends: {
    height: 28,
    marginLeft: -200,
    [theme.breakpoints.up('sm')]: {
      marginLeft: -65,
    },
  },
  weatherCharttop: {
    width: 700,
    marginLeft: 75,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const DailyWeatherAndAerationHistoryTable: React.FunctionComponent<{
  containerId: number;
  aerationRuns: GrainContainerAerationRunFragmentFragment[];
  dailyForecast: ForecastDayV2FragmentFragment[];
  hourlyForecast: ComputedForecastHourV2FragmentFragment[];
  aeration_history?: AerationHistoryRunWinow[];
  grain_bin_location_timezone: string;
  fanAutomationPeriods: FanAutomationPeriod[];
  container_type: ContainerTypeLegacy;
  goPrevWeek: () => void;
  goNextWeek: () => void;
  goToPresentView: () => void;
  prevWeekNo: number;
  hasIncompleteWeatherData: boolean;
  recommendation_windows_data: RecommendationWindowsFragmentFragment;
  recommendation_windows_for_experiments?: RecommRunWindowsExperiments[];
  hideExpCharts?: boolean;
  refetch_grain_bin_container?: () => void;
  refetch_experiment_run_windows?: (variables) => void;
  setCfmValues?: (cfm_values) => void;
  setBoundValues?: (bound_values) => void;
  boundFormValues?: {
    lower_bound: number | undefined;
    upper_bound: number | undefined;
  };
  experimentsRunWindowsFormValues?: {
    cfm_scaling: number | undefined;
    cfm_offset: number | undefined;
    cfm_min: number | undefined;
    cfm_max: number | undefined;
    cfm: number | undefined;
  };
  expExpandedStates?: any;
  setExpExpandedStates?: React.Dispatch<React.SetStateAction<any>>;
  recommendation_type: string | null;
  has_enable_fan_guidance: boolean;
  fan_guidance_start_date: Date | null;
  fan_guidance_end_date: Date | null;
}> = ({
  containerId,
  aerationRuns,
  dailyForecast,
  hourlyForecast,
  aeration_history,
  grain_bin_location_timezone,
  fanAutomationPeriods,
  container_type,
  goPrevWeek,
  goNextWeek,
  prevWeekNo,
  goToPresentView,
  refetch_grain_bin_container,
  refetch_experiment_run_windows,
  hasIncompleteWeatherData,
  setCfmValues,
  experimentsRunWindowsFormValues,
  expExpandedStates,
  setExpExpandedStates,
  setBoundValues,
  boundFormValues,
  hideExpCharts = false,
  recommendation_windows_for_experiments,
  recommendation_type,
  has_enable_fan_guidance,
  fan_guidance_start_date,
  fan_guidance_end_date,
}) => {
  console.log(
    'recommendation_windows_for_experiments in DailyWeatherAndAerationHistoryTable',
    recommendation_windows_for_experiments
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const classes = useStyles();
  const [weatherChart, setWeatherChart] = useState<any>(null);
  const [recommendationChart, setRecommendationChart] = useState<any>(null);
  const [recommendationExperimentCharts, setRecommendationExperimentCharts] = useState<any[]>([]);
  const has_diagnostic_access = hasDiagnosticAccess(React.useContext(UserContext));
  const startDayAsOf = DateTime.local().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const startDayDate = startDayAsOf.minus({ week: prevWeekNo });
  const endDayDate = startDayDate.plus({ days: 6 });
  const firstDayToShow =
    dailyForecast && dailyForecast[0]
      ? DateTime.fromMillis(new Date(dailyForecast[0].epoch_time).getTime())
      : DateTime.local();
  const minDate = firstDayToShow
    ? firstDayToShow.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    : null;
  const lastDayToShow =
    dailyForecast && dailyForecast[dailyForecast.length - 1]
      ? DateTime.fromMillis(new Date(dailyForecast[dailyForecast.length - 1].epoch_time).getTime())
      : null;
  const maxDate = lastDayToShow
    ? lastDayToShow.set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      })
    : null;
  const scheduledRunsToPresent = getAerationsRunsToPresent(
    (aeration_history || []).map((aeration) => {
      const start = aeration.start_epoch.getTime();
      const end = aeration.end_epoch.getTime();
      const recommendationType = aeration.recommendation_type;
      return {
        // @ts-ignore
        start: start as number,
        end: end as number,
        mode: recommendationType,
      };
    })
  );
  console.log('scheduledRunsToPresent in weather history', scheduledRunsToPresent);

  const syncronizeCrossHairs = (chart) => {
    const moveCrossHairForWeatherChartOnly = (x, evt) => {
      // remove old plot line and draw new plot line (crosshair) for this chart
      const xAxis1 = weatherChart.xAxis[0];
      xAxis1.removePlotLine('myPlotLineId');
      xAxis1.addPlotLine({
        value: chart.xAxis[0].translate(x, true),
        width: 1,
        color: WEATHER_CHART_COLORS.CROSS_HAIR_COLOR,
        id: 'myPlotLineId',
        zIndex: 2,
      });
      // refresh weatherChart tooltip
      const tEvt = weatherChart.pointer.normalize(evt); // Find coordinates within the chart
      const tPoints: Highcharts.Point[] = [];
      for (const tSeries of weatherChart.series) {
        const tPoint: Highcharts.Point | undefined = tSeries.searchPoint(tEvt, true);
        if (tPoint && tSeries.visible) {
          tPoints.push(tPoint);
        } else {
          tSeries.stateMarkerGraphic && tSeries.stateMarkerGraphic.hide();
          tSeries.markerGroup && tSeries.markerGroup.hide();
          tSeries.halo && tSeries.halo.hide();
        }
      }
      if (tPoints.length) {
        weatherChart.tooltip.refresh(tPoints);
        setTimeout(() => {
          weatherChart.tooltip.hide(3000); // tooltip hide delay
        });
      }
    };
    const integrateCrossHair = (x, evt) => {
      if (weatherChart && weatherChart.xAxis) {
        // remove old plot line and draw new plot line (crosshair) for this chart
        const xAxis1 = weatherChart.xAxis[0];
        xAxis1.removePlotLine('myPlotLineId');
        xAxis1.addPlotLine({
          value: chart.xAxis[0].translate(x, true),
          width: 1,
          color: WEATHER_CHART_COLORS.CROSS_HAIR_COLOR,
          id: 'myPlotLineId',
          zIndex: 2,
        });
        // refresh weatherChart tooltip
        const tEvt = weatherChart.pointer.normalize(evt); // Find coordinates within the chart
        const tPoints: Highcharts.Point[] = [];
        for (const tSeries of weatherChart.series) {
          const tPoint: Highcharts.Point | undefined = tSeries.searchPoint(tEvt, true);

          if (tPoint && tSeries.visible) {
            tPoints.push(tPoint);
          } else {
            tSeries.stateMarkerGraphic && tSeries.stateMarkerGraphic.hide();
            tSeries.markerGroup && tSeries.markerGroup.hide();
            tSeries.halo && tSeries.halo.hide();
          }
        }
        if (tPoints.length) {
          weatherChart.tooltip.refresh(tPoints);
          setTimeout(() => {
            weatherChart.tooltip.hide(3000); // tooltip hide delay
          });
        }
      }

      if (recommendationChart && recommendationChart.xAxis) {
        // remove old crosshair and draw new crosshair on recommendationChart
        const xAxis2 = recommendationChart.xAxis[0];
        xAxis2.removePlotLine('myPlotLineId');
        xAxis2.addPlotLine({
          value: chart.xAxis[0].translate(x, true),
          width: 1,
          color: WEATHER_CHART_COLORS.CROSS_HAIR_COLOR,
          id: 'myPlotLineId',
          zIndex: 2,
        });

        // refresh recommendationChart tooltip
        recommendationChart.series[0].points.forEach((point) => {
          if (point.x < xAxis2.toValue(x, true) && point.x2 > xAxis2.toValue(x, true)) {
            chart !== recommendationChart && recommendationChart.tooltip.refresh(point);
          }
        });
      }

      // recommendation experiment charts
      if (has_diagnostic_access) {
        for (const recommendationExperimentChart of recommendationExperimentCharts) {
          if (recommendationExperimentChart && recommendationExperimentChart.xAxis) {
            const xAxis = recommendationExperimentChart.xAxis[0];
            xAxis.removePlotLine('myPlotLineId');
            xAxis.addPlotLine({
              value: chart.xAxis[0].translate(x, true),
              width: 1,
              color: WEATHER_CHART_COLORS.CROSS_HAIR_COLOR,
              id: 'myPlotLineId',
              zIndex: 2,
            });

            recommendationExperimentChart.series[0].points.forEach((point) => {
              if (point.x < xAxis.toValue(x, true) && point.x2 > xAxis.toValue(x, true)) {
                chart !== recommendationExperimentChart &&
                  recommendationExperimentChart.tooltip.refresh(point);
              }
            });
          }
        }
      }
    };

    if (weatherChart && recommendationChart) {
      const container = $(chart.container);
      const offset = container.offset();
      const xAxis = chart.xAxis[0];
      if (isMobile) {
        chart.container.addEventListener(
          'touchstart',
          (evt) => {
            const tEvt = chart.pointer.normalize(evt);
            const tPoint = chart.series[0].searchPoint(tEvt, true);
            if (tPoint) {
              const x = Math.round(xAxis.toPixels(tPoint.x)) - chart.plotLeft;
              integrateCrossHair(x, evt);
            } else {
              const xAxisValue = new Date(chart.xAxis[0].toValue(tEvt.chartX)).getTime();
              const x = Math.round(xAxis.toPixels(xAxisValue)) - chart.plotLeft;
              integrateCrossHair(x, evt);
            }
          },
          { passive: true }
        );
        chart.container.addEventListener(
          'touchmove',
          (evt) => {
            const tEvt = chart.pointer.normalize(evt);
            const tPoint = chart.series[0].searchPoint(tEvt, true);
            if (tPoint) {
              const x = Math.round(xAxis.toPixels(tPoint.x)) - chart.plotLeft;
              integrateCrossHair(x, evt);
            } else {
              const xAxisValue = new Date(chart.xAxis[0].toValue(tEvt.chartX)).getTime();
              const x = Math.round(xAxis.toPixels(xAxisValue)) - chart.plotLeft;
              integrateCrossHair(x, evt);
            }
          },
          { passive: true }
        );
        // when window is resized on wide screen(non touchable device) then we want mousemove event to be work
        if (!isTouchDevice()) {
          chart.container.addEventListener(
            'mousemove',
            (evt) => {
              const tEvt = chart.pointer.normalize(evt);
              const tPoint = chart.series[0].searchPoint(tEvt, true);
              if (tPoint) {
                const x = Math.round(xAxis.toPixels(tPoint.x)) - chart.plotLeft;
                integrateCrossHair(x, evt);
              } else {
                const xAxisValue = new Date(chart.xAxis[0].toValue(tEvt.chartX)).getTime();
                const x = Math.round(xAxis.toPixels(xAxisValue)) - chart.plotLeft;
                integrateCrossHair(x, evt);
              }
            },
            { passive: true }
          );
        }
      } else {
        chart.container.addEventListener(
          'mousemove',
          (evt) => {
            const x = evt.clientX - chart.plotLeft - offset.left;
            integrateCrossHair(x, evt);
          },
          { passive: true }
        );
      }
    } else if (weatherChart) {
      const container = $(chart.container);
      const offset = container.offset();
      const xAxis = chart.xAxis[0];
      if (isMobile) {
        chart.container.addEventListener(
          'touchstart',
          (evt) => {
            const tEvt = chart.pointer.normalize(evt);
            const tPoint = chart.series[0].searchPoint(tEvt, true);
            if (tPoint) {
              const x = Math.round(xAxis.toPixels(tPoint.x)) - chart.plotLeft;
              moveCrossHairForWeatherChartOnly(x, evt);
            } else {
              const xAxisValue = new Date(chart.xAxis[0].toValue(tEvt.chartX)).getTime();
              const x = Math.round(xAxis.toPixels(xAxisValue)) - chart.plotLeft;
              moveCrossHairForWeatherChartOnly(x, evt);
            }
          },
          { passive: true }
        );
        chart.container.addEventListener(
          'touchmove',
          (evt) => {
            const tEvt = chart.pointer.normalize(evt);
            const tPoint = chart.series[0].searchPoint(tEvt, true);
            if (tPoint) {
              const x = Math.round(xAxis.toPixels(tPoint.x)) - chart.plotLeft;
              moveCrossHairForWeatherChartOnly(x, evt);
            } else {
              const xAxisValue = new Date(chart.xAxis[0].toValue(tEvt.chartX)).getTime();
              const x = Math.round(xAxis.toPixels(xAxisValue)) - chart.plotLeft;
              moveCrossHairForWeatherChartOnly(x, evt);
            }
          },
          { passive: true }
        );
        // when window is resized on wide screen(non touchable device) then we want mousemove event to be work
        if (!isTouchDevice()) {
          chart.container.addEventListener(
            'mousemove',
            (evt) => {
              const tEvt = chart.pointer.normalize(evt);
              const tPoint = chart.series[0].searchPoint(tEvt, true);
              if (tPoint) {
                const x = Math.round(xAxis.toPixels(tPoint.x)) - chart.plotLeft;
                moveCrossHairForWeatherChartOnly(x, evt);
              } else {
                const xAxisValue = new Date(chart.xAxis[0].toValue(tEvt.chartX)).getTime();
                const x = Math.round(xAxis.toPixels(xAxisValue)) - chart.plotLeft;
                moveCrossHairForWeatherChartOnly(x, evt);
              }
            },
            { passive: true }
          );
        }
      } else {
        chart.container.addEventListener(
          'mousemove',
          (evt) => {
            const x = evt.clientX - chart.plotLeft - offset.left;
            moveCrossHairForWeatherChartOnly(x, evt);
          },
          { passive: true }
        );
      }
    }
  };

  const setExperimentChart = (chart: any, index: number) => {
    setRecommendationExperimentCharts((prev) => {
      const updated = [...prev];
      updated[index] = chart;
      return [...updated];
    });
  };

  if (hasIncompleteWeatherData) {
    return (
      <Grid container className={classes.container} spacing={3} alignContent="space-between">
        <Grid container style={{ padding: '10px 14px' }} direction="column" alignItems="center">
          <Grid item style={{ padding: '0 0.5rem', width: '100%', marginTop: 10 }}>
            <div className={classes.weatherCharttop}>
              <div>
                <WeatherChartNavigation
                  startDayDate={startDayDate}
                  endDayDate={endDayDate}
                  goPrevWeek={goPrevWeek}
                  goNextWeek={goNextWeek}
                  prevWeekNo={prevWeekNo}
                  goToPresentView={goToPresentView}
                />
              </div>
              <div className={classes.customLegends}>
                <div
                  className="weatherChartLegendArea"
                  style={{ display: 'flex', justifyContent: 'center' }}
                />
              </div>
            </div>
            <div
              style={{
                width: 922,
                height: 454,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 25,
                paddingLeft: 80,
                paddingRight: 150,
              }}
            >
              <div
                style={{
                  width: 700,
                  height: 454,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: `1px solid ${WEATHER_CHART_COLORS.GRID_LINE_COLOR}`,
                  borderRadius: 3,
                  marginTop: 20,
                }}
              >
                <Typography variant="h6" style={{ fontWeight: 600 }}>
                  Incomplete weather data in this Date Range
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.container} spacing={3} alignContent="space-between">
      <Grid container style={{ padding: '10px 14px' }} direction="column" alignItems="center">
        <Grid item style={{ padding: '0 0.5rem', width: '100%', marginTop: 10 }}>
          <div className={classes.weatherCharttop}>
            <div>
              <WeatherChartNavigation
                startDayDate={startDayDate}
                endDayDate={endDayDate}
                goPrevWeek={goPrevWeek}
                goNextWeek={goNextWeek}
                prevWeekNo={prevWeekNo}
                goToPresentView={goToPresentView}
              />
            </div>
            <div className={classes.customLegends}>
              <div
                className="weatherChartLegendArea"
                style={{ display: 'flex', justifyContent: 'center' }}
              />
            </div>
          </div>
          {dailyForecast.length > 0 && hourlyForecast.length > 0 && (
            <WeatherForecastPlot
              grain_bin_location_timezone={grain_bin_location_timezone}
              hourly_forecast_data={hourlyForecast}
              days={dailyForecast}
              weatherChart={weatherChart}
              setWeatherChart={setWeatherChart}
              syncronizeCrossHairs={syncronizeCrossHairs}
              minDate={minDate}
              maxDate={maxDate}
            />
          )}
        </Grid>
        {dailyForecast.length > 0 && container_type === ContainerTypeLegacy.bin && (
          <Grid item style={{ padding: '0 0.5rem', width: '100%' }}>
            <div style={{ width: '100%' }}>
              <AerationHistoryPlot
                containerId={containerId}
                scheduledRunsToPresent={scheduledRunsToPresent}
                fanAutomationPeriods={fanAutomationPeriods}
                grain_bin_location_timezone={grain_bin_location_timezone}
                setRecommendationChart={setRecommendationChart}
                syncronizeCrossHairs={syncronizeCrossHairs}
                minDate={minDate}
                maxDate={maxDate}
                aerationRuns={aerationRuns}
              />
            </div>
            {container_type === ContainerTypeLegacy.bin &&
              has_diagnostic_access &&
              !hideExpCharts && (
                <ExperimentsCharts
                  recommendation_type={recommendation_type}
                  recommendation_windows_for_experiments={recommendation_windows_for_experiments}
                  syncronizeCrossHairs={syncronizeCrossHairs}
                  minDate={minDate}
                  maxDate={maxDate}
                  has_enable_fan_guidance={has_enable_fan_guidance}
                  fan_guidance_start_date={fan_guidance_start_date}
                  fan_guidance_end_date={fan_guidance_end_date}
                  refetch_grain_bin_container={refetch_grain_bin_container}
                  refetch_experiment_run_windows={refetch_experiment_run_windows}
                  setBoundValues={setBoundValues}
                  setCfmValues={setCfmValues}
                  setExperimentChart={setExperimentChart}
                  grain_bin_location_timezone={grain_bin_location_timezone}
                  experimentsRunWindowsFormValues={experimentsRunWindowsFormValues}
                  boundFormValues={boundFormValues}
                  expExpandedStates={expExpandedStates}
                  setExpExpandedStates={setExpExpandedStates}
                />
              )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
