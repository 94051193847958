import { Card, CardContent, CardHeader, Divider, Grid, IconButton } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { ExpandLess, ExpandMore, Settings } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useCallback, useState } from 'react';

import { GrainStatusFragmentFragment } from '../../../api';
import { amber_amber } from '../../../style';
import {
  ContainerTypeLegacy,
  formatNumber,
  formatPercent,
  RangeState,
  RelativeTimePeriod,
  Statistic,
} from '../../../util';
import { DataWarning } from '../DataWarning';
import { PelletHistoryCard, Range, TileButtonToggle } from '../summary-card';

const formatHumidity = (humidity_rh) => (humidity_rh === null ? 'N/A' : formatPercent(humidity_rh));
const formatMoisture = (moisture_pct) =>
  moisture_pct === null ? 'N/A' : formatPercent(moisture_pct, 1);
const formatTemp = (temp_f) => (temp_f === null ? 'N/A' : `${formatNumber(temp_f)}\u00b0 F`);
const getTitleStyle = (state: string | null | undefined) => {
  if (!state) {
    return {};
  }
  switch (state) {
    case RangeState.bad:
      return { color: red['500'] };
    case RangeState.ok:
      return { color: amber_amber };
    case RangeState.good:
      return { color: green['500'] };
    default:
      return {};
  }
};

const useStyles = makeStyles({
  subheader: {
    textAlign: 'left',
  },
  action: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: { marginBottom: 2 },
  icon_root: {
    margin: -10,
  },
});

const getRanges = (
  statistic: Statistic | null,
  grain_status?: GrainStatusFragmentFragment | null
): Range[] => {
  if (!grain_status || !statistic) {
    return [];
  }
  switch (statistic) {
    case Statistic.temp_f:
      return ((grain_status.temp_f_ranges as unknown) as Range[]) || [];
    case Statistic.humidity_rh:
      return ((grain_status.humidity_rh_ranges as unknown) as Range[]) || [];
    case Statistic.emc:
      return ((grain_status.emc_ranges as unknown) as Range[]) || [];
    default:
      return [];
  }
};

export const BargePelletCard: FunctionComponent<{
  container_id: number;
  container_type: ContainerTypeLegacy;
  grain_status?: GrainStatusFragmentFragment | null;
  pellet_id: string;
  subtitle?: string;
  title: string;
  is_emc_stable?: boolean | null;
  trackPlotToggle?: (arg: any) => any;
  trackPeriodChange?: (arg: any) => any;
  goBargeCoverForm: (arg: { pellet_id: string }) => void;
}> = ({
  container_id,
  container_type,
  grain_status = null,
  title,
  subtitle,
  goBargeCoverForm,
  pellet_id,
  is_emc_stable,
  trackPlotToggle,
  trackPeriodChange,
}) => {
  const classes = useStyles();
  const [statistic, setStatistic] = useState<Statistic | null>(null);
  const [period, setPeriod] = useState(RelativeTimePeriod.week);
  const onSelectStatistic = useCallback(
    (value: Statistic) => {
      const on = value !== statistic;
      if (trackPlotToggle) {
        trackPlotToggle({
          on,
          statistic: value,
          data_source: 'pellet',
        });
      }
      const stat = on ? value : null;
      setStatistic(stat);
    },
    [statistic, trackPlotToggle, setStatistic]
  );
  const selectPeriodCallback = useCallback(
    (value: RelativeTimePeriod) => {
      if (trackPeriodChange) {
        trackPeriodChange({
          statistic,
          period: value,
          data_source: 'pellet',
        });
      }
      setPeriod(value);
    },
    [statistic, trackPeriodChange]
  );
  const setStatTempCallback = useCallback(() => setStatistic(Statistic.temp_f), [
    statistic,
    setStatistic,
  ]);
  const setStatNullCallback = useCallback(() => setStatistic(null), [statistic, setStatistic]);
  const tempSelectedCallback = useCallback(() => onSelectStatistic(Statistic.temp_f), [
    statistic,
    onSelectStatistic,
  ]);
  const rhSelectedCallback = useCallback(() => onSelectStatistic(Statistic.humidity_rh), [
    statistic,
    onSelectStatistic,
  ]);
  const emcSelectedCallback = useCallback(() => onSelectStatistic(Statistic.emc), [
    statistic,
    onSelectStatistic,
  ]);
  const { temp_f, temp_state, humidity_rh, humidity_state, emc, emc_state } = grain_status || {
    temp_f: null,
    temp_state: RangeState.unknown,
    humidity_rh: null,
    humidity_state: RangeState.unknown,
    emc: null,
    emc_state: RangeState.unknown,
  };

  let actions = [
    <IconButton
      key="settings"
      onClick={() => goBargeCoverForm({ pellet_id })}
      color="secondary"
      className={classes.icon_root}
    >
      <Settings />
    </IconButton>,
    !statistic && (
      <IconButton
        key="expand-more"
        onClick={setStatTempCallback}
        color="secondary"
        className={classes.icon_root}
      >
        <ExpandMore />
      </IconButton>
    ),
    statistic && (
      <IconButton
        key="expand-less"
        onClick={setStatNullCallback}
        color="secondary"
        className={classes.icon_root}
      >
        <ExpandLess />
      </IconButton>
    ),
  ];
  if (grain_status === null) {
    actions = [<DataWarning key="warning" warning_text="Sensor offline" />, ...actions];
  } else if (is_emc_stable === false) {
    actions = [
      <DataWarning
        key="warning"
        warning_text="Data suggests this sensor is too shallow in the grain"
      />,
      ...actions,
    ];
  }
  return (
    <Card raised>
      <CardHeader
        subheader={subtitle}
        title={title}
        titleTypographyProps={{
          color: 'secondary',
        }}
        action={actions}
        classes={{ subheader: classes.subheader, action: classes.action }}
      />
      <Divider classes={{ root: classes.divider }} />
      <CardContent>
        <Grid container alignContent="stretch" justify="space-between" spacing={2}>
          <Grid item xs={4}>
            <TileButtonToggle
              onClick={tempSelectedCallback}
              subtitle="temperature"
              title={formatTemp(temp_f)}
              titleStyle={getTitleStyle(temp_state)}
              toggled={statistic === 'temp_f'}
            />
          </Grid>
          <Grid item xs={4}>
            <TileButtonToggle
              onClick={rhSelectedCallback}
              subtitle="humidity"
              title={formatHumidity(humidity_rh)}
              titleStyle={getTitleStyle(humidity_state)}
              toggled={statistic === 'humidity_rh'}
            />
          </Grid>
          <Grid item xs={4}>
            <TileButtonToggle
              onClick={emcSelectedCallback}
              subtitle="moisture (emc)"
              title={formatMoisture(emc)}
              titleStyle={getTitleStyle(emc_state)}
              toggled={statistic === 'emc'}
            />
          </Grid>
          {statistic !== null && (
            <Grid item xs={12}>
              <PelletHistoryCard
                container_id={container_id}
                container_type={container_type}
                ranges={getRanges(statistic, grain_status)}
                period={period}
                pellet_id={pellet_id}
                onSelectPeriod={selectPeriodCallback}
                statistic={statistic}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
