import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  CancelRounded as CancelRoundedIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
  PlayArrow as PlayArrowIcon,
  Replay as ReplayIcon,
} from '@material-ui/icons';
import {DateTime} from 'luxon';
import React, {useState} from 'react';
import {amber_dark_grey, amber_green, amber_grey, amber_red} from '../../style';
import {SummaryCard} from '../grain-container/summary-card';
import ContentModal from '../util/ContentModal';
import {Button} from '../util/form2/Button';
import {DIAG_TEST_RESULT_STATUSES, DiagTestResultStatus} from './DiagTests';
import {CheckResultMap, SelfTestRow, TestResultsTable} from './TestResultsTable';

const useStyles = makeStyles({
  quickTestCardContainer: {
    width: '100%',
    marginBottom: 20,
  },
  testResultCell: {
    fontSize: 14,
    borderBottom: '1px solid rgb(224, 224, 224)',
    padding: 10,
    textAlign: 'center',
  },
  status_heading: {
    marginRight: 10,
    color: amber_dark_grey,
    fontSize: 36,
  },
  confirmButton: {},
  testResultStatusIcon: {
    verticalAlign: 'text-top',
  },
  runOnText:{
    fontSize: 'medium',
    color: 'grey',
    textAlign: 'center',
  },
});

type QuickTestCardProps = {
  core_id: string;
  handleStartQuickTest: () => void;
  lastQuickTestResult: any;
  currentCellMetricTestResultUIStatus: DiagTestResultStatus | null;
  currentQuickTestResultUIStatus: DiagTestResultStatus | null;
  currentLongTestResultUIStatus: DiagTestResultStatus | null;
  isDiagnosticTestEnabled: boolean;
};

type QuickTestCheckResult = {
  check_id: string;
  check_name: string;
  check_result_status: string;
  error_code?: string | null;
  error?: string[] | null;
  data?: {};
};

type QuickTestResultsMetrics = {
  test_result?: {
    test_result: string;
    test_check_result?: {
      accel_check_result?: QuickTestCheckResult;
      irms_sanity_check_result?: QuickTestCheckResult;
      irms_vibe_check_result?: QuickTestCheckResult;
      sht31_check_result?: QuickTestCheckResult;
      fan_off_check_result?: QuickTestCheckResult;
      fan_turn_on_check_result?: QuickTestCheckResult;
      fan_stays_on_check_result?: QuickTestCheckResult;
      fan_turns_off_check_result?: QuickTestCheckResult;
    }
  }
  test_type: string;
  test_status: string;
  created_at: Date;
  last_updated_at: Date;
};

const ensureChecks = [
  {key:"check1", label:"All personnel present are aware the fan may start and stop under automatic control"},
  {key:"check2", label:"The fan is wired correctly according to Amber’s instructions to the best of my knowledge"},
  {key:"check3", label:"The bin floor is sufficiently weighted by grain to avoid damage to the floor structure"},
  {key:"check4", label:"I will not hold Amber liable for any damage or injury caused by the fan self-test"},
];

const QuickTextConfirmationContent = ({
  setDisableConfirmButton,
}: {
  setDisableConfirmButton: Function;
}) => {
  const [checkedBoxes, setCheckedBoxes] = useState(0);
  const handleChange = (event) => {
    if (event.target.checked) {
      setCheckedBoxes((prevState) => prevState + 1);
    } else if (!event.target.checked) {
      setCheckedBoxes((prevState) => prevState - 1);
    }
  };
  if (checkedBoxes === 4) {
    setDisableConfirmButton(false);
  } else {
    setDisableConfirmButton(true);
  }
  return (
    <>
      <h3>Please ensure below points before starting Short Test. <br/>I certify that:</h3>
      <FormGroup>
        {ensureChecks.map(({key, label})=>
        <FormControlLabel
            control={<Checkbox onChange={handleChange} />}
            key={key}
            label={label}
            style={{textAlign: 'left', marginBottom: '13px'}}
        />)}
      </FormGroup>
    </>
  );
};

export const QuickTestCard = ({
  core_id,
  lastQuickTestResult,
  handleStartQuickTest,
  currentCellMetricTestResultUIStatus,
  currentQuickTestResultUIStatus,
  currentLongTestResultUIStatus,
  isDiagnosticTestEnabled,
}: QuickTestCardProps) => {
  const classes = useStyles();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  let lastTestRsultsMetrics: QuickTestResultsMetrics | null =  null;
  if (lastQuickTestResult){
    lastTestRsultsMetrics = {
      test_type: "QUICK_TEST",
      test_status: lastQuickTestResult.test_status,
      created_at: lastQuickTestResult.created_at,
      last_updated_at: lastQuickTestResult.last_updated_at,
    }

    const {test_result} = lastQuickTestResult;
    if (test_result){
      const test_result_1 = {
        test_result: lastQuickTestResult.test_result.test_result,
      };
      const {test_check_result} = test_result;

      if (test_check_result){
        let test_check_result_1 = {};
        const {accel_check_result, irms_sanity_check_result, irms_vibe_check_result, sht31_check_result, fan_off_check_result, fan_turn_on_check_result, fan_stays_on_check_result, fan_turns_off_check_result} = test_check_result;
        if (accel_check_result){
          const {check_id, check_result_status, error_code, error} = accel_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            accel_check_result:{
              check_id,
              check_result_status,
              error_code,
              error,
              check_name: 'accelerometer',
            }
          }
        }
        if (irms_sanity_check_result){
          const {check_id, check_result_status, error_code, error, irms_sanity_A} = irms_sanity_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            irms_sanity_check_result:{
              check_id,
              check_result_status,
              error_code,
              error,
              check_name: 'current sensor',
              data: {
                irms_sanity_A,
              }
            }
          }
        }
        if (irms_vibe_check_result){
          const {check_id, check_result_status, error_code, error, vibe_sanity} = irms_vibe_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            irms_vibe_check_result:{
              check_id,
              check_result_status,
              error_code,
              error,
              check_name:'vibration sensor',
              data: {
                vibe_sanity,
              }
            }
          }
        }
        if (sht31_check_result){
          const {check_id, check_result_status, error_code, error, relative_humidity, temperature_fahrenheit} = sht31_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            sht31_check_result:{
              check_id,
              check_result_status,
              error_code,
              error,
              check_name: 'temp/humidity sensor',
              data: {
                relative_humidity,
                temperature_fahrenheit,
              }
            }
          }
        }
        if (fan_off_check_result){
          const {check_id, check_name, check_result_status, error_code, error, irms_off_A, vibe_off} = fan_off_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            fan_off_check_result:{
              check_id,
              check_name,
              check_result_status,
              error_code,
              error,
              data: {
                irms_off_A,
                vibe_off,
              }
            }
          }
        }
        if (fan_turn_on_check_result){
          const {check_id, check_name, check_result_status, error_code, error, irms_on_A, vibe_on} = fan_turn_on_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            fan_turn_on_check_result:{
              check_id,
              check_name,
              check_result_status,
              error_code,
              error,
              data: {
                irms_on_A,
                vibe_on,
              }
            }
          }
        }
        if (fan_stays_on_check_result){
          const {check_id, check_name, check_result_status, error_code, error, irms_hold_A, vibe_hold} = fan_stays_on_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            fan_stays_on_check_result:{
              check_id,
              check_name,
              check_result_status,
              error_code,
              error,
              data: {
                irms_hold_A,
                vibe_hold,
              }
            }
          }
        }
        if (fan_turns_off_check_result){
          const {check_id, check_name, check_result_status, error_code, error, irms_stop_A, vibe_stop} = fan_turns_off_check_result;
          test_check_result_1 = {
            ...test_check_result_1,
            fan_turns_off_check_result:{
              check_id,
              check_name,
              check_result_status,
              error_code,
              error,
              data: {
                irms_stop_A,
                vibe_stop,
              }
            }
          }
        }

        test_result_1['test_check_result']= {...test_check_result_1};
      }
      lastTestRsultsMetrics={
        ...lastTestRsultsMetrics,
        test_result: {...test_result_1},
      }
    }
  }

  const row_map: CheckResultMap = {};

  function build_check_map(row_map: CheckResultMap, check_result: QuickTestCheckResult, check_key_name: string): CheckResultMap{
    let row: SelfTestRow;
    if (row_map[check_key_name]){
      row = row_map[check_key_name];
      if (row){
        row.passed = row.passed==='FAIL'?'FAIL':row.passed==='SKIPPED'?'SKIPPED':check_result.check_result_status;
        row.checks = [...row.checks, {
          check_name: check_result.check_name,
          data: check_result.data,
          error: check_result.error,
          passed: check_result.check_result_status,
        }];
      }
    } else {
      const {check_result_status, check_name, data, error} = check_result;

      row = {
        name: check_key_name,
        passed: check_result_status,
        checks: [{
          check_name,
          data,
          error,
          passed: check_result_status,
        }],
      };
    }
    row_map[check_key_name]=row;
    return row_map;
  }
  if (lastTestRsultsMetrics && lastTestRsultsMetrics.test_result && lastTestRsultsMetrics.test_result.test_check_result){
    const {accel_check_result, irms_sanity_check_result, irms_vibe_check_result, sht31_check_result, fan_off_check_result, fan_turn_on_check_result, fan_stays_on_check_result, fan_turns_off_check_result} = lastTestRsultsMetrics.test_result.test_check_result;

    if (accel_check_result){
      build_check_map(row_map, accel_check_result, "Sensor Check")
    }
    if (irms_sanity_check_result){
      build_check_map(row_map, irms_sanity_check_result, "Sensor Check")
    }
    if (sht31_check_result){
      build_check_map(row_map, sht31_check_result, "Sensor Check")
    }
    if (irms_vibe_check_result){
      build_check_map(row_map, irms_vibe_check_result, "Sensor Check")
    }
    if (fan_off_check_result){
      build_check_map(row_map, fan_off_check_result, "Fan Off")
    }
    if (fan_turn_on_check_result){
      build_check_map(row_map, fan_turn_on_check_result, "Fan Turns On")
    }
    if (fan_stays_on_check_result){
      build_check_map(row_map, fan_stays_on_check_result, "Fan Stays On")
    }
    if (fan_turns_off_check_result){
      build_check_map(row_map, fan_turns_off_check_result, "Fan Turns Off")
    }
  }

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const last_test_result_run_datetime_at_zone: DateTime | null = lastTestRsultsMetrics
    ? DateTime.fromISO(lastTestRsultsMetrics.last_updated_at).setZone(browserTimezone)
    : null;
  const isTestRunning = currentQuickTestResultUIStatus
    ? currentQuickTestResultUIStatus.status === DIAG_TEST_RESULT_STATUSES.RUNNING.status
    : false;

  const isLongTestRunning = currentLongTestResultUIStatus
    ? currentLongTestResultUIStatus.status === DIAG_TEST_RESULT_STATUSES.RUNNING.status
    : false;
  const isCellMetricTestRunning = currentCellMetricTestResultUIStatus ? currentCellMetricTestResultUIStatus.status === DIAG_TEST_RESULT_STATUSES.RUNNING.status
    : false;

  const quickTestButtonText = isTestRunning ? (
    <>
      <CircularProgress style={{ width: 16, height: 16 }} />
      &nbsp;&nbsp;&nbsp;Running
    </>
  ) : (
    <>
      {currentQuickTestResultUIStatus &&
      [DIAG_TEST_RESULT_STATUSES.TIMED_OUT.status, DIAG_TEST_RESULT_STATUSES.FAIL.status].includes(
        currentQuickTestResultUIStatus.status
      ) ? (
        <>
          <ReplayIcon fontSize="small" />
          &nbsp;Retry Short Test
        </>
      ) : (
        <>
          <PlayArrowIcon fontSize="small" />
          &nbsp;Run Short Test
        </>
      )}
    </>
  );

  return (
    <SummaryCard className={classes.quickTestCardContainer} title="Short Test" subheaderComp={
      <div>
        <Typography variant="h5" align="left" style={{marginTop: '20px'}}>
          Description:
          <Typography paragraph={true} style={{fontSize: 'large'}}>
            The Short Test checks whether the fan controller is functioning correctly and can control the fan system as
            expected. Use the Short Test to briefly test starting and stopping, or when it is preferred to avoid full startup
            of the fan. Ensure the bin has enough grain to adequately compress and protect the floor supports, if applicable.
          </Typography>
        </Typography>

        <Typography variant="h5" align="left" style={{marginTop: '20px'}}>
          Steps:
          <ul style={{position: 'relative', left: '-25px', fontSize: 'large', fontWeight: 'normal'}}>
            <li><span style={{position: 'absolute', left:'50px'}}>Tries up to 1s to fan start</span></li>
            <li><span style={{position: 'absolute', left:'50px'}}>Runs fan for 2s</span></li>
            <li><span style={{position: 'absolute', left:'50px'}}>Tries to stop fan</span></li>
          </ul>
          <Typography variant="body1" gutterBottom>
            <strong style={{color: amber_red}}>Caution: </strong>certain wiring issues can prevent the controller from
            stopping the fan. If limiting the run to 2 seconds is important, be prepared to quickly stop or cut power to
            the fan if the test fails to stop it.
          </Typography>
        </Typography>
      </div>
    }>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Grid item style={{ marginTop: 20 }}>
          {currentQuickTestResultUIStatus && (
            <>
              <Typography variant="h3" className={classes.status_heading}>
                Short Test{' '}
                <span style={{ color: currentQuickTestResultUIStatus.color || amber_grey }}>
                  {currentQuickTestResultUIStatus.status}
                </span>
              </Typography>
              {currentQuickTestResultUIStatus.status ===
                DIAG_TEST_RESULT_STATUSES.TIMED_OUT.status && (
                <Typography variant="body1" style={{ color: amber_red, marginTop: 10 }}>
                  Sorry, Your Short test request timed out! Please try again!
                </Typography>
              )}
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ borderRadius: 20, marginTop: 20, width: 160 }}
            onClick={() => setShowConfirmationModal(true)}
            disabled={isTestRunning || isLongTestRunning || !isDiagnosticTestEnabled || isCellMetricTestRunning}
          >
            {quickTestButtonText}
          </Button>
          <Typography variant="caption" display="block" gutterBottom style={{fontSize:"small", fontStyle:"italic"}}>
            (approx. run time 20 secs)
          </Typography>
        </Grid>
        {!isTestRunning && (
          <>
            {lastTestRsultsMetrics && lastTestRsultsMetrics.test_result && lastTestRsultsMetrics.test_result.test_result === 'PASS' && (
              <div>
                <Typography
                  variant="h5"
                  style={{ marginTop: 25, marginBottom: 10, fontWeight: 600 }}
                >
                  <span style={{ verticalAlign: 'text-bottom', marginRight: '10px' }}>
                    Test Results:
                  </span>
                  <CheckCircleRoundedIcon
                    className={classes.testResultStatusIcon}
                    style={{ color: amber_green }}
                  />
                </Typography>
                <Typography variant="body1" className={classes.runOnText}>
                  Run on {last_test_result_run_datetime_at_zone.toFormat('ff')}
                </Typography>
              </div>
            )}
            {lastTestRsultsMetrics && lastTestRsultsMetrics.test_result && lastTestRsultsMetrics.test_result.test_result !== 'PASS' && (
              <div>
                <Typography
                  variant="h5"
                  style={{ marginTop: 25, marginBottom: 10, fontWeight: 600 }}
                >
                  <span style={{ verticalAlign: 'text-bottom', marginRight: '10px' }}>
                    Test Results:
                  </span>
                  <CancelRoundedIcon
                    className={classes.testResultStatusIcon}
                    style={{ color: amber_red }}
                  />
                </Typography>
                <Typography variant="body1" className={classes.runOnText}>
                  Run on - {last_test_result_run_datetime_at_zone.toFormat('ff')}
                </Typography>
              </div>
            )}
            <TestResultsTable row_map={row_map} />
          </>
        )}
      </Grid>
      <ContentModal
        showModal={showConfirmationModal}
        ModalContent={QuickTextConfirmationContent}
        cancelBtnText="Cancel"
        confirmBtbText="Run"
        handleCancel={() => setShowConfirmationModal(false)}
        handleConfirm={() => {
          handleStartQuickTest();
          setShowConfirmationModal(false);
        }}
        disableConfirmButtonDefault={true}
      />
    </SummaryCard>
  );
};
