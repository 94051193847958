import { AmberHoc, AmberHocProps } from '../../../util';
import {
  ArchiveGrainContainerMutation,
  ArchiveGrainContainerMutationVariables as Variables,
  withArchiveGrainContainer as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type withArchiveUnArchiveGrainContainerHocChildProps = {
  archiveUnArchiveGrainContainer: (
    variables: Variables
  ) => Promise<ArchiveGrainContainerMutation['archiveUnArchiveGrainContainer']>;
};
export const withArchiveUnArchiveGrainContainerHoc: AmberHoc<
  {},
  withArchiveUnArchiveGrainContainerHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, withArchiveUnArchiveGrainContainerHocChildProps, typeof component>,
    withArchiveUnArchiveGrainContainerHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        archiveUnArchiveGrainContainer: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: async (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { archiveUnArchiveGrainContainer: updated } = data;
              if (!updated) {
                return;
              }
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data || !data.archiveUnArchiveGrainContainer) {
            throw new Error('Unexpected server response');
          }
          return data.archiveUnArchiveGrainContainer;
        },
      };
    },
  })(component as any);
