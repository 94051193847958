import {
  PerformGrainBinUnselectionMutationVariables as Variables,
  UserSelectedGrainContainerFragmentFragment as Result,
  withPerformGrainBinUnselection as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithPerformGrainBinUnselectionHocChildProps = {
  performGrainBinUnselection: (variables: Variables) => Promise<Result>;
};
export const withPerformGrainBinUnselectionHoc = HOC<
  any,
  WithPerformGrainBinUnselectionHocChildProps
>({
  options: { errorPolicy: 'all' },
  props: (props) => {
    const { mutate } = props;
    return {
      performGrainBinUnselection: async (variables) => {
        if (!mutate) {
          throw new Error('Unexpected UI error');
        }
        const result = await mutate({
          variables,
          update: (proxy, { data, errors }) => {
            if (errors || !data) {
              return;
            }
            const { performGrainBinUnselection: result } = data;
            if (!result) {
              return;
            }
          },
        });
        if (!result) {
          throw new Error('Unexpected server response');
        }
        const { errors, data } = result;
        if (errors) {
          throw new GraphQLErrors(errors);
        }
        if (!data) {
          throw new Error('Unexpected server response');
        }
        return data.performGrainBinUnselection;
      },
    };
  },
});
