import * as React from 'react';
export const OpsViewIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32.953}
    height={26.676}
    viewBox="0 0 32.953 26.676"
    {...props}
  >
    <g id="OperationsIcons" transform="translate(-78 -185)">
      <path
        id="svgexport-6_1_"
        data-name="svgexport-6 (1)"
        d="M10.246,5.711l3.923,3.531v6.128H12.6V10.661H7.892v4.708H6.323V9.241l3.923-3.531m0-2.111L2.4,10.661H4.754v6.277H9.461V12.23H11.03v4.708h4.708V10.661h2.354Z"
        transform="translate(84.23 181.4)"
        fill="#1b1b1b"
      />
      <path
        id="svgexport-6_1_2"
        data-name="svgexport-6 (1)"
        d="M10.246,5.711l3.923,3.531v6.128H12.6V10.661H7.892v4.708H6.323V9.241l3.923-3.531m0-2.111L2.4,10.661H4.754v6.277H9.461V12.23H11.03v4.708h4.708V10.661h2.354Z"
        transform="translate(75.6 194.738)"
        fill="#1b1b1b"
      />
      <path
        id="svgexport-6_1_3"
        data-name="svgexport-6 (1)"
        d="M10.246,5.711l3.923,3.531v6.128H12.6V10.661H7.892v4.708H6.323V9.241l3.923-3.531m0-2.111L2.4,10.661H4.754v6.277H9.461V12.23H11.03v4.708h4.708V10.661h2.354Z"
        transform="translate(92.861 194.738)"
        fill="#1b1b1b"
      />
    </g>
  </svg>
);
