import { userMonitor } from '../../../component/rum';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  CreateTokenMutationVariables as Variables,
  UserToken,
  withCreateToken as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';

export type WithCreateTokenHocChildProps = {
  createToken: (variables: Variables) => Promise<UserToken>;
};
export const withCreateTokenHoc: AmberHoc<{}, WithCreateTokenHocChildProps> = (component) =>
  HOC<
    AmberHocProps<{}, WithCreateTokenHocChildProps, typeof component>,
    WithCreateTokenHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        createToken: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
            },
          });

          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data) {
            throw new Error('Unexpected server response');
          }
          const { createToken: viewer } = data;
          if (!viewer) {
            throw new Error('Unexpected server response');
          }
          return viewer;
        },
      };
    },
  })(component as any);
