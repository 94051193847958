import React from 'react';

export const HideIcon = (props) => {
  return (
    <svg
      id="HideIcon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <clipPath id="hide-icon-clip-path">
          <rect
            id="Rectangle_2421"
            data-name="Rectangle 2421"
            width={16.705}
            height={14.42}
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="HideIcon-2" data-name="HideIcon" fill="#ccc" stroke="#999" strokeWidth={1}>
        <rect width={24} height={24} rx={4} stroke="none" />
        <rect x={0.5} y={0.5} width={23} height={23} rx={3.5} fill="none" />
      </g>
      <g id="HiddenEyeIcon" transform="translate(3.647 4.79)">
        <g id="Group_123" data-name="Group 123" clipPath="url(#hide-icon-clip-path)">
          <path
            id="Path_393"
            data-name="Path 393"
            d="M79.769,66.227a.527.527,0,0,1-.385-.14,3.5,3.5,0,0,1-.981-2.453A3.44,3.44,0,0,1,81.836,60.2a3.31,3.31,0,0,1,2.418,1.016.527.527,0,0,1,.14.385.737.737,0,0,1-.14.385l-4.1,4.1A.527.527,0,0,1,79.769,66.227Zm2.067-4.976a2.408,2.408,0,0,0-2.383,2.383A2.516,2.516,0,0,0,79.8,64.9L83.1,61.6a2.628,2.628,0,0,0-1.262-.35Z"
            transform="translate(-73.496 -56.433)"
          />
          <path
            id="Path_394"
            data-name="Path 394"
            d="M96.607,73.712a.527.527,0,0,1,.385.14,3.5,3.5,0,0,1,.981,2.453,3.44,3.44,0,0,1-3.434,3.434,3.311,3.311,0,0,1-2.418-1.016.527.527,0,0,1-.14-.385.737.737,0,0,1,.14-.385l4.1-4.1A.527.527,0,0,1,96.607,73.712ZM94.54,78.688a2.408,2.408,0,0,0,2.383-2.383,2.516,2.516,0,0,0-.351-1.261l-3.294,3.294a2.628,2.628,0,0,0,1.262.35Z"
            transform="translate(-86.226 -69.1)"
          />
          <path
            id="Path_395"
            data-name="Path 395"
            d="M4.7,41.557a.648.648,0,0,1-.245-.07A14.9,14.9,0,0,1,.106,37.7a.526.526,0,0,1,0-.631C.246,36.862,3.82,32.2,8.34,32.2a8.391,8.391,0,0,1,3.89,1.086.543.543,0,0,1,.28.385.552.552,0,0,1-.14.456L5.046,41.417a.407.407,0,0,1-.35.14Zm-3.5-4.17a15.1,15.1,0,0,0,3.434,2.979l6.483-6.483a6.813,6.813,0,0,0-2.768-.666c-3.364.035-6.307,3.154-7.148,4.17Z"
            transform="translate(0 -30.186)"
          />
          <path
            id="Path_396"
            data-name="Path 396"
            d="M74.125,48.44a.65.65,0,0,1,.246.07,14.944,14.944,0,0,1,4.359,3.8.528.528,0,0,1,0,.633c-.141.211-3.726,4.886-8.261,4.886a8.417,8.417,0,0,1-3.9-1.09.544.544,0,0,1-.281-.387.554.554,0,0,1,.141-.457l7.347-7.312a.408.408,0,0,1,.352-.141Zm3.515,4.183A15.155,15.155,0,0,0,74.2,49.635l-6.5,6.5a6.835,6.835,0,0,0,2.777.668c3.375-.035,6.327-3.164,7.171-4.183Z"
            transform="translate(-62.13 -45.409)"
          />
          <path
            id="Path_397"
            data-name="Path 397"
            d="M18.185,14.42a.527.527,0,0,1-.385-.14.508.508,0,0,1,0-.736L31.186.158a.508.508,0,0,1,.736,0,.508.508,0,0,1,0,.736L18.536,14.279a.406.406,0,0,1-.35.14Z"
            transform="translate(-16.538)"
          />
        </g>
      </g>
    </svg>
  );
};
