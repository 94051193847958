import { Card, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { CSSProperties, memo } from 'react';
import { amber_dark_grey } from '../../../style';

const useStyles = makeStyles((theme: Theme) => ({
  icon_root: {
    marginTop: -24,
    marginBottom: -24,
  },
  header: {
    margin: '15px 0px',
  },
}));
export const SummaryCard: React.FunctionComponent<{
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  style?: CSSProperties;
  className?: string;
  contentStyle?: CSSProperties;
  headerStyle?: CSSProperties;
  header_action?: React.ReactNode;
  subheaderComp?: React.ReactNode;
}> = memo(
  ({
    title,
    subtitle,
    children,
    style,
    className,
    contentStyle,
    headerStyle,
    header_action,
    subheaderComp,
  }) => {
    const classes = useStyles();
    return (
      <Card raised style={style} className={className}>
        <CardHeader
          className={classes.header}
          subheader={subheaderComp ? subheaderComp : subtitle}
          style={headerStyle}
          title={
            <Typography
              variant="h5"
              style={{
                fontSize: 20,
                color: amber_dark_grey,
                width: 'fit-content',
                textAlign: 'start',
              }}
            >
              {title}
            </Typography>
          }
          // make max height 26px or the header will be taller than the rest
          action={header_action}
        />
        <Divider />
        <CardContent style={contentStyle}>{children}</CardContent>
      </Card>
    );
  }
);
