import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import {
  FanController,
  FanControllerFragmentFragment,
  FanSettingsFragmentFragment,
  FanType,
  GrainBinFanControllerFragmentFragment,
  withUpdateFanControllerHoc,
  WithUpdateFanControllerHocChildProps,
  withUpdateGrainBinFanControllerHoc,
  WithUpdateGrainBinFanControllerHocChildProps,
} from '../../../api';
import { UiFormError } from '../../../util/format-error';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../../util/form2/BaseForm';
import { Button, ButtonSubmit } from '../../util/form2/Button';
import { ErrorBox } from '../../util/form2/ErrorBox';
import { FanModelDetails } from '../grain-fan-model/GrainFanModelSelector';
import {
  FanControllerFormFields,
  updateValidationSchema,
  UpdateValues,
} from './FanControllerFormFields';

const useStyles = makeStyles({
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: 320,
  },
});

export const UpdateGrainBinFanControllerFormBase = ({
  updateFanController,
  updateGrainBinFanController,
  grain_bin_fan_controller,
  onSubmitSuccess,
  fan_controller,
  handleClose,
  ...props
}: WithUpdateGrainBinFanControllerHocChildProps &
  WithUpdateFanControllerHocChildProps &
  FormikWrapperHandlerProps<UpdateValues, FanSettingsFragmentFragment> & {
    grain_bin_fan_controller: GrainBinFanControllerFragmentFragment;
    onSubmitSuccess: () => void;
    handleClose: () => void;
    fan_controller: FanControllerFragmentFragment;
  }) => {
  const classes = useStyles();
  const { fan_model_id } = grain_bin_fan_controller;
  const [currentFanModelState, setCurrentFanModelState] = useState<FanModelDetails | null>(null);

  if (!fan_controller) {
    return null;
  }
  return (
    <FormikWrapper<
      UpdateValues,
      GrainBinFanControllerFragmentFragment & Pick<FanController, 'diag_test_is_enable'>
    >
      {...props}
      validationSchema={updateValidationSchema}
      initialValues={{
        fan_alias:
          grain_bin_fan_controller.alias ||
          (grain_bin_fan_controller.fan_controller &&
            grain_bin_fan_controller.fan_controller.alias),
        has_plenum_sensor: grain_bin_fan_controller.has_plenum_sensor || false,
        power_factor: grain_bin_fan_controller.power_factor || null,
        phase: grain_bin_fan_controller.phase || null,
        voltage: grain_bin_fan_controller.voltage || null,
        diag_test_is_enable:
          fan_controller && fan_controller.diag_test_is_enable
            ? fan_controller.diag_test_is_enable
            : false,
        fan_model_id: fan_model_id || null,
        no_of_fans_connected: String(grain_bin_fan_controller.no_of_fans_connected),
      }}
      onSubmit={async (values) => {
        const {
          fan_alias: alias,
          has_plenum_sensor,
          phase,
          power_factor,
          voltage,
          diag_test_is_enable,
          no_of_fans_connected,
        } = updateValidationSchema.validateSync(values);
        if (currentFanModelState === null) {
          console.error('Invalid Fan Model');
          throw new UiFormError('Please Select Valid Fan Model');
        }
        await updateFanController({
          diag_test_is_enable,
          fan_controller_id: fan_controller.fan_controller_id_next,
        });
        return {
          ...(await updateGrainBinFanController({
            alias,
            power_factor,
            no_of_fans_connected: Number(no_of_fans_connected),
            grain_bin_fan_controller_id: grain_bin_fan_controller.grain_bin_fan_controller_id,
            has_plenum_sensor: has_plenum_sensor || false,
            phase: phase === undefined || phase === null ? null : phase,
            voltage: voltage === undefined || voltage === null ? null : voltage,
            ...currentFanModelState,
            fan_horsepower: String(currentFanModelState.fan_horsepower),
            fan_model_type: currentFanModelState.fan_model_type as FanType,
          })),
          diag_test_is_enable,
        };
      }}
      onSubmitSuccess={onSubmitSuccess}
      render={() => (
        <BaseForm submitting_message="Updating Fan Controller..." style={{ padding: 0 }}>
          <div className={classes.column}>
            <FanControllerFormFields
              update
              core_id={fan_controller.core_id}
              fan_model_id={fan_model_id}
              currentFanModelState={currentFanModelState}
              setCurrentFanModelState={setCurrentFanModelState}
            />
            <Grid container spacing={2} alignItems="center" style={{ margin: '15px 0px' }}>
              <Grid item xs={5}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{
                    margin: 0,
                    width: '100%',
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={7}>
                <ButtonSubmit
                  style={{
                    margin: 0,
                    width: '100%',
                  }}
                  variant="contained"
                  color="primary"
                  allow_pristine
                  allow_btn_disabled_for_validation={false}
                >
                  Save Changes
                </ButtonSubmit>{' '}
              </Grid>
            </Grid>
          </div>
          <ErrorBox withoutGrid />
        </BaseForm>
      )}
    />
  );
};

export const UpdateGrainBinFanControllerForm = withUpdateFanControllerHoc(
  withUpdateGrainBinFanControllerHoc(UpdateGrainBinFanControllerFormBase)
);
