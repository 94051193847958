import { Grid } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  withGetGrainContainerHistoryAerationRunsWithLimitHoc,
  WithGetGrainContainerHistoryAerationRunsWithLimitHocChildProps,
} from '../../../api';
import { CenteredSpinner } from '../../spinner';
import { AerationEventHistoryTable, getMs } from './AerationEventHistoryTable';
import { AerationRuntimeStats } from './AerationRuntimeStats';

const minHeight = 370;

type BaseAerationRuntimeSectionProps = WithGetGrainContainerHistoryAerationRunsWithLimitHocChildProps & {
  container_id: number;
} & {
  storage_period?: {
    grain_bin_storage_cycle_id: number;
  } | null;
};

const BaseAerationRuntimeSection: FunctionComponent<BaseAerationRuntimeSectionProps> = ({
  storage_period,
  container_id,
  loading,
  loadMore,
  aeration_runs,
  hasMore,
  currentOffset,
  total_runs,
  total_hrs_runtime,
}) => {
  const [totalHrsRuntime, setTotalHrsRuntime] = useState(0);
  const [totalRuns, setTotalRuns] = useState(0);
  const [hasStoragePeriodChanged, setHasStoragePeriodChanged] = useState(false);

  useEffect(() => {
    if (total_hrs_runtime !== undefined && total_runs !== undefined) {
      setTotalHrsRuntime(total_hrs_runtime);
      setTotalRuns(total_runs);
    }
  }, [total_hrs_runtime, total_runs]);

  useEffect(() => {
    setHasStoragePeriodChanged(true);
  }, [JSON.stringify(storage_period)]);

  useEffect(() => {
    if (!loading) setHasStoragePeriodChanged(false);
  }, [loading]);

  return (
    <Grid container justify="center" style={{ minHeight }}>
      {(loading && !aeration_runs) || hasStoragePeriodChanged ? (
        <div style={{ minHeight }}>
          <CenteredSpinner fadeIn="none" />
        </div>
      ) : (
        <>
          <AerationRuntimeStats total_hrs_runtime={totalHrsRuntime} total_runs={totalRuns} />
          <AerationEventHistoryTable
            loading={loading}
            grain_bin_id={container_id}
            aeration_runs={(aeration_runs as any) || ([] as any)}
            loadMore={loadMore}
            hasMore={hasMore}
            currentOffset={currentOffset}
          />
        </>
      )}
    </Grid>
  );
};

export const AerationRuntimeSection = withGetGrainContainerHistoryAerationRunsWithLimitHoc(
  BaseAerationRuntimeSection
);
