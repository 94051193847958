import { makeStyles, Theme } from '@material-ui/core';
import { Settings } from 'luxon';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  FanControllerStateFragmentFragment,
  FanHeartbeatErrorCode,
  GrainBinDtoFragmentFragment,
  GrainBinFanControllerFragmentFragment,
  withGetGrainBinSitesHoc,
  WithGetGrainBinSitesHocChildProps,
  withGetOpsViewColsVisibilityPrefsHoc,
  WithGetOpsViewColsVisibilityPrefsHocChildProps,
  withGetOpsViewRowsVisibilityPrefsHoc,
  WithGetOpsViewRowsVisibilityPrefsHocChildProps,
} from '../../../../core/src/api';
import { black_shade_9 } from '../../../../core/src/style';
import { formatGrainType } from '../../../../core/src/util';
import { isQualifyingFanHeartbeatErrorCode } from '../grain-container/aeration/FanControllerErrorDisplay';
import { getSimpleFanStatus, SimpleFanStatus } from '../grain-container/aeration/FanStatusHelpers';
import { DialogSpinner } from '../spinner';
import { BulkActionsView } from './BulkActionsView';
import { UNASSIGNED_BINS_SITE_ID } from './manage-sites';
import { PrefJsonItem } from './manage-sites/ManageSitesTable';
import { OperationsViewTable } from './OperationsViewTable';
import { getValidOperationsViewRowsVisibilityPrefs } from './OperationsViewTableHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  infoText: {
    fontSize: 20,
    position: 'absolute',
    transform: 'translate(0, -50%)',
    top: '50%',
    left: '40%',
    zIndex: 2,
    color: black_shade_9,
    fontWeight: 600,
  },
}));

type fanControllerDTO = {
  id: number;
  state: boolean;
  active: string;
  fan_attributes: GrainBinFanControllerFragmentFragment;
  fan_config_state: FanControllerStateFragmentFragment;
};

export type GrainBinAggregationDataType = {
  grain_bin_id: number;
  grain_bin_attributes: GrainBinDtoFragmentFragment;
  fan_controllers: fanControllerDTO[];
};

export type OpsViewVisibilityPrefsType = {
  site_id: number;
  site_name: string;
  bins: { bin_id: number; bin_name: string; hide: boolean }[];
};

const GrainOperationsBase: FunctionComponent<
  {
    grain_bin_ids: number[];
    goContainer: (args: any) => void;
  } & WithGetGrainBinSitesHocChildProps &
    WithGetOpsViewColsVisibilityPrefsHocChildProps &
    WithGetOpsViewRowsVisibilityPrefsHocChildProps &
    RouteComponentProps
> = ({
  grain_bin_ids,
  grain_bin_sites,
  grain_sites_loading,
  goContainer,
  loading,
  operations_view_cols_visibility_prefs,
  rows_visibility_prefs_loading,
  operations_view_rows_visibility_prefs,
}) => {
  console.log('grain_bin_sites', grain_bin_sites);
  console.log('operations_view_rows_visibility_prefs', operations_view_rows_visibility_prefs);
  const classes = useStyles();

  const hasAnySiteCreated = grain_bin_sites.some(
    (site) => site.site_id !== UNASSIGNED_BINS_SITE_ID
  );
  console.log('hasAnySiteCreated', hasAnySiteCreated);
  const [groupBySites, setGroupBySites] = useState(hasAnySiteCreated);
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  Settings.defaultLocale = 'en-US';
  Settings.defaultZone = browserTimezone;

  const unassignedSiteName = 'UNASSIGNED_BINS';
  let grainSitesHierarchyData = grain_bin_sites
    .filter((site) => site.site_name !== unassignedSiteName)
    .map((site) => ({
      site_id: site.site_id,
      siteHierarchy: [site.site_name],
    }));

  const grainBins: GrainBinAggregationDataType[] = grain_bin_sites.reduce((acc: any, site) => {
    if (site && site.bins.length > 0) acc.push(...site.bins);
    return acc;
  }, []);

  const unassignedBins = grainBins.filter((bin) => {
    const site_id = bin.grain_bin_attributes.site
      ? bin.grain_bin_attributes.site.site_id
      : UNASSIGNED_BINS_SITE_ID;
    return site_id === UNASSIGNED_BINS_SITE_ID;
  });
  console.log('unassignedBins in grain ops', grainBins, unassignedBins);
  const unassignedBinsIds = unassignedBins.map(({ grain_bin_id }) => grain_bin_id);
  console.log('unassignedBinsIds in grain ops', unassignedBinsIds);

  grainSitesHierarchyData = [
    ...grainSitesHierarchyData,
    ...(unassignedBins.length > 0
      ? [{ site_id: UNASSIGNED_BINS_SITE_ID, siteHierarchy: [unassignedSiteName] }]
      : []),
  ];

  let totalFansCount = 0;
  const grainBinsHierarchyData = grainBins.map((bin) => {
    const grain_bin_attributes = bin.grain_bin_attributes;
    const fan_controllers = bin.fan_controllers;
    const has_errored_fan = fan_controllers.some(
      (fc) =>
        fc.fan_config_state && isQualifyingFanHeartbeatErrorCode(fc.fan_config_state.error_code)
    );
    const has_fan_start_fail_error =
      has_errored_fan &&
      fan_controllers.some(
        (fc) =>
          fc.fan_config_state && fc.fan_config_state.error_code === FanHeartbeatErrorCode.StartFail
      );
    const has_fan_stop_fail_error =
      has_errored_fan &&
      fan_controllers.some(
        (fc) =>
          fc.fan_config_state && fc.fan_config_state.error_code === FanHeartbeatErrorCode.StopFail
      );
    console.log('has_fan_start_fail_error & has_fan_stop_fail_error', {
      has_fan_start_fail_error,
      has_fan_stop_fail_error,
      grain_bin_id: bin.grain_bin_id,
    });
    totalFansCount += fan_controllers.length;
    const bin_fan_status = fan_controllers.map((fc) => ({
      grain_bin_fan_controller_id: fc.fan_attributes.grain_bin_fan_controller_id,
      fan_name: fc.fan_attributes.alias,
      state: fc.fan_config_state.value_next,
      is_on: fc.fan_config_state.is_on,
      has_override: fc.fan_config_state.is_override,
      error_code: fc.fan_config_state.error_code,
    }));

    const any_fan_running = fan_controllers.some(
      (ctrl) => ctrl.fan_config_state && ctrl.fan_config_state.is_on
    );
    const fan_statuses = fan_controllers.map((ctrl, ix) => {
      return {
        id: ctrl.fan_attributes.fan_controller_id_next,
        fan_name: ctrl.fan_attributes.alias || `Fan ${ix}`,
        ...getSimpleFanStatus(ctrl.fan_attributes),
      };
    });
    const has_any_fan_ready = fan_statuses.some(({ status }) => status === SimpleFanStatus.READY);

    return {
      has_any_fan_ready,
      any_fan_running,
      has_errored_fan,
      has_fan_start_fail_error,
      has_fan_stop_fail_error,
      bin_fan_status,
      grain_bin_id: bin.grain_bin_id,
      bin_name: bin.grain_bin_attributes.bin_name,
      siteHierarchy: [
        bin.grain_bin_attributes.site ? bin.grain_bin_attributes.site.site_name : 'UNASSIGNED_BINS',
        bin.grain_bin_attributes.bin_name,
      ],
      automation_info: {
        enabled: grain_bin_attributes.enable_fan_guidance,
        fan_guidance_start_date: grain_bin_attributes.fan_guidance_start_date,
        fan_guidance_end_date: grain_bin_attributes.fan_guidance_end_date,
      },
      grain_type: formatGrainType(grain_bin_attributes.grain_type),
      grain_bin_location_timezone: grain_bin_attributes.tz || browserTimezone,
      grain_bin_recommendation_type: grain_bin_attributes.recommendation_type,
      last_week_co2_alert_sent_on: null,
      // loading columns
      mode: 'loading', // 'loading' is the just temporary placeholder, we are updating this with actual value once it's api call get finished
      grain_level: 'loading',
      fan_schedule: 'loading',
      last_week_airspace_history: 'loading',
      last_aeration_run: 'loading',
      weather: 'loading',
    };
  });

  const sitesHierarchyData = [...grainSitesHierarchyData, ...grainBinsHierarchyData];
  const operationsViewInfo = {
    sitesCount: grain_bin_sites.length - 1, // grain_bin_sites will always contains 'UNASSIGNED_BINS'
    binsCount: grainBins.length,
    fansCount: totalFansCount,
    allSitesGrainLevelCount: 0,
  };
  const sortedGrainBinsHierarchyData = grainBinsHierarchyData.sort((a, b) =>
    a.bin_name.toLowerCase() > b.bin_name.toLowerCase() ? 1 : -1
  );

  const initial_grain_bin_ids = grain_bin_ids.slice(0, 5); // for weather data column

  const allActiveSitesIds: number[] = grain_bin_sites.map((site) => site.site_id);

  const valid_operations_view_rows_visibility_prefs = getValidOperationsViewRowsVisibilityPrefs({
    allActiveSitesIds,
    operations_view_rows_visibility_prefs,
    unassignedBinsIds,
  });
  console.log(
    'valid_operations_view_rows_visibility_prefs',
    valid_operations_view_rows_visibility_prefs
  );

  useEffect(() => {
    setGroupBySites(hasAnySiteCreated);
  }, [hasAnySiteCreated]);

  if (grain_sites_loading || loading || rows_visibility_prefs_loading) {
    return <DialogSpinner open title="Loading Sites..." />;
  }

  console.log('sitesHierarchyData', sitesHierarchyData);
  return (
    <div style={{ marginTop: 15 }}>
      <div style={{ position: 'relative' }}>
        <div className={classes.infoText}>Coming Soon</div>
        <BulkActionsView />
      </div>
      <div style={{ marginTop: 15 }}>
        <OperationsViewTable
          sitesHierarchyData={sitesHierarchyData}
          sortedGrainBinsHierarchyData={sortedGrainBinsHierarchyData}
          initial_grain_bin_ids={initial_grain_bin_ids}
          grain_bin_ids={grain_bin_ids}
          goContainer={goContainer}
          operationsViewInfo={operationsViewInfo}
          groupBySites={groupBySites}
          setGroupBySites={setGroupBySites}
          operations_view_cols_visibility_prefs={operations_view_cols_visibility_prefs}
          valid_operations_view_rows_visibility_prefs={valid_operations_view_rows_visibility_prefs}
        />
      </div>
    </div>
  );
};

export const GrainOperations = withGetOpsViewRowsVisibilityPrefsHoc(
  withGetOpsViewColsVisibilityPrefsHoc(withGetGrainBinSitesHoc(GrainOperationsBase))
);
