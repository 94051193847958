import { Grid, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React from 'react';
import { ItemTable } from '../..';
import { amber_amber, amber_grey } from '../../../../../core/src/style';
import { isNoEnd } from '../../../../../core/src/util';
import { FanSettingsFragmentFragment } from '../../../api';
import { ColumnConfig } from '../../util';
import {
  FanControllerWithError,
  isQualifyingFanHeartbeatErrorCode,
  QualifyingFanHeartbeatErrorCode,
} from '../aeration/FanControllerErrorDisplay';
import { FanStatus } from '../aeration/FanStatus';
import { getIdForAerationRun } from '../aeration/UpcomingFanScheduleTable';
import { RecommendedScheduledRange } from '../daily-forecast/RecommendationScheduleBar';
import LogFanErrors from './LogFanErrors';

const useStyles = makeStyles({
  fan_status: {
    fontSize: '1.28rem',
    lineHeight: 1.33,
    textAlign: 'center',
  },
  fan_status_container: {
    textAlign: 'start',
    display: 'block',
  },
  smallIcon: {
    fontSize: 17,
    width: 40,
    height: 24,
    color: amber_amber,
  },
});

const formatDateTime = (date: Date) => {
  const dt = DateTime.fromMillis(new Date(date).getTime());
  return dt.toFormat('ccc t');
};

type AerationScheduleDetailsProps = {
  grain_bin_fan_settings: FanSettingsFragmentFragment;
  scheduledRunsToPresent: RecommendedScheduledRange[];
  recommendationSchedule: RecommendedScheduledRange[];
  showScheduleCaptureTime?: boolean;
  hasManualMode: boolean;
};

const columns: ColumnConfig<{ start_epoch: Date; end_epoch: Date }>[] = [
  {
    title: 'Start',
    width: 20,
    align: 'center',
    alignTitle: 'center',
    getValue: ({ start_epoch }) => (start_epoch ? formatDateTime(start_epoch) : `N/A`),
  },
  {
    title: '',
    width: 10,
    align: 'center',
    alignTitle: 'center',
    getValue: () => (
      <div>
        <Typography variant="subtitle1" style={{ color: amber_grey, width: 'fit-content' }}>
          <ArrowForward style={{ fontSize: 17, width: 40, height: 24, color: amber_amber }} />
        </Typography>
      </div>
    ),
  },
  {
    title: 'End',
    width: 20,
    align: 'center',
    alignTitle: 'center',
    getValue: ({ start_epoch, end_epoch }) =>
      end_epoch && !isNoEnd(start_epoch, end_epoch) ? formatDateTime(end_epoch) : `N/A`,
  },
];

const AerationScheduleDetails = ({
  grain_bin_fan_settings,
  scheduledRunsToPresent,
  recommendationSchedule,
  showScheduleCaptureTime,
  hasManualMode,
}: AerationScheduleDetailsProps) => {
  const classes = useStyles();
  const getFormattedSchedules = (schedules) => {
    return schedules.length > 0
      ? schedules
          .filter(({ text }) => text)
          .map(({ startEpoch, endEpoch }) => ({
            start_epoch: new Date(startEpoch),
            end_epoch: new Date(endEpoch),
          }))
      : [];
  };

  const fan_controllers_with_errors: FanControllerWithError[] | null =
    (grain_bin_fan_settings &&
      grain_bin_fan_settings.grain_bin.fan_controllers.reduce((acc, next) => {
        if (
          next.fan_controller &&
          next.fan_controller.state_next &&
          isQualifyingFanHeartbeatErrorCode(next.fan_controller.state_next.error_code)
        ) {
          const error = {
            error_code: next.fan_controller.state_next
              .error_code as QualifyingFanHeartbeatErrorCode,
            core_id: next.fan_controller.core_id,
            alias: next.alias || next.fan_controller.alias,
          };
          const errors = acc || ([] as FanControllerWithError[]);
          return [...errors, error];
        }
        return acc;
      }, null)) ||
    null;

  const scheduled_start =
    (scheduledRunsToPresent &&
      scheduledRunsToPresent[0] &&
      new Date(scheduledRunsToPresent[0].startEpoch)) ||
    null;
  const scheduled_end =
    (scheduledRunsToPresent &&
      scheduledRunsToPresent[0] &&
      new Date(scheduledRunsToPresent[0].endEpoch)) ||
    null;

  return (
    <>
      <Grid style={{ marginBottom: 10, marginLeft: 85 }}>
        {grain_bin_fan_settings && grain_bin_fan_settings.grain_bin.fan_controllers.length > 0 && (
          <Grid style={{ marginBottom: 15, marginTop: 5, display: 'flex' }}>
            <div>
              <Typography variant="h5" style={{ width: '120px', textAlign: 'start' }}>
                Fan Status:
              </Typography>
            </div>
            <FanStatus
              grain_bin_fan_settings={grain_bin_fan_settings}
              fan_updating={false}
              className={classes.fan_status}
              fanStatusContainer={classes.fan_status_container}
              scheduled_start={scheduled_start}
              scheduled_end={scheduled_end}
              hasManualMode={hasManualMode}
            />
          </Grid>
        )}
        {fan_controllers_with_errors && (
          <Grid style={{ display: 'flex' }}>
            <div>
              <Typography variant="h5" style={{ width: '120px', textAlign: 'start' }}>
                Fan Errors:
              </Typography>
            </div>
            <LogFanErrors fan_controllers_with_errors={fan_controllers_with_errors} />
          </Grid>
        )}
      </Grid>

      {showScheduleCaptureTime && (
        <Grid
          style={{
            marginBottom: 15,
            marginLeft: 80,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Typography variant="h5">
            Now: {DateTime.local().toFormat('ccc LLL d, t ZZZZ')}
          </Typography>
        </Grid>
      )}

      <Grid
        style={{
          margin: '15px 0px 15px 30px',
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        <div
          style={{
            display: 'border-box',
            width: '40%',
            overflowX: 'hidden',
          }}
        >
          <Typography variant="h6">Fan Run Schedule</Typography>
          {scheduledRunsToPresent && scheduledRunsToPresent.length > 0 ? (
            <div style={{ border: '1px solid #D3D3D3', marginTop: 10 }}>
              <ItemTable<{ start_epoch: Date; end_epoch: Date }>
                items={getFormattedSchedules(scheduledRunsToPresent)}
                getId={getIdForAerationRun}
                columns={columns}
              />
            </div>
          ) : (
            <div style={{ borderTop: '1px solid #D3D3D3', marginTop: 15 }}>
              <Typography variant="h6">No fan schedule configured</Typography>
            </div>
          )}
        </div>

        <div
          style={{
            display: 'border-box',
            width: '40%',
            overflowX: 'hidden',
          }}
        >
          <Typography variant="h6">Recommended Schedule</Typography>
          {recommendationSchedule && recommendationSchedule.length > 0 ? (
            <div style={{ border: '1px solid #D3D3D3', marginTop: 10 }}>
              <ItemTable<{ start_epoch: Date; end_epoch: Date }>
                items={getFormattedSchedules(recommendationSchedule)}
                getId={getIdForAerationRun}
                columns={columns}
              />
            </div>
          ) : (
            <div style={{ borderTop: '1px solid #D3D3D3', marginTop: 15 }}>
              <Typography variant="h6">No Recommended schedule</Typography>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default AerationScheduleDetails;
