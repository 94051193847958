import { createMuiTheme, lighten, Theme } from '@material-ui/core/styles';

import { amber_amber, amber_dark_grey, amber_faded_black, amber_grey, white } from './color';

export const mui_theme = createMuiTheme({
  shadows: Array(25).fill('none') as any,
  typography: {
    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
    fontSize: 12,
    h5: {
      fontWeight: 600,
    },
    h4: {
      color: amber_faded_black,
      fontWeight: 400,
      fontSize: 26,
    },
  },
  palette: {
    primary: {
      light: lighten(amber_grey, 0.7),
      main: amber_grey,
      dark: amber_grey,
      contrastText: white,
    },
    secondary: {
      light: lighten(amber_amber, 0.5),
      main: amber_amber,
      dark: amber_amber,
      contrastText: white,
    },
    type: 'light',
    background: {
      default: white,
      paper: white,
    },
    text: {
      primary: amber_grey,
    },
  },
  overrides: <Theme['overrides']>{
    MuiCardHeader: {
      root: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 14,
        paddingRight: 14,
        alignItems: 'center',
        '@media(min-width:600px)': {
          paddingLeft: 14,
          paddingRight: 14,
        },
      },
      title: {
        textAlign: 'left',
        fontSize: 15,
        color: amber_dark_grey,
      },
      action: {
        marginTop: 0,
        marginRight: 0,
        '@media(min-width:600px)': {
          marginRight: 0,
        },
      },
    },
    MuiCardContent: {
      root: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 14,
        paddingRight: 14,
        '@media(min-width:600px)': {
          paddingLeft: 14,
          paddingRight: 14,
        },
      },
    },
    MuiSlider: {
      root: {
        paddingTop: 24,
        paddingBottom: 24,
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
  },
});
