import { Typography } from '@material-ui/core';
import { FormikErrors } from 'formik';
import React from 'react';

export const WizardStep = <Values extends {}>({
  title,
  children,
}: {
  title: string;
  children: ((props: any) => React.ReactNode) | React.ReactNode;
  validate?: (values: Values) => void | object | Promise<FormikErrors<Values>>;
  validationSchema?: any;
  validateOnNext?: (values: Values) => Promise<string | null>;
}) => (
  <>
    <Typography variant="h4">{title}</Typography>
    {children}
  </>
);
