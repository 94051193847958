import { useMediaQuery, useTheme } from '@material-ui/core';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { pushNav } from '../../../../../core/src/action';
import {
  GrainBinSiteFragmentFragment,
  GrainBinSiteLink,
  OpsViewRowVisibilityPrefsFragmentFragment,
  SaveOperationsViewRowVisibilityPrefsMutationVariables,
  UpdateGrainBinMutationVariables,
  withCreateGrainBinSiteHoc,
  WithCreateGrainBinSiteHocChildProps,
  withDeleteGrainBinSiteHoc,
  WithDeleteGrainBinSiteHocChildProps,
  withGetAccountHoc,
  WithGetAccountHocChildProps,
  withGetGrainBinSitesHoc,
  WithGetGrainBinSitesHocChildProps,
  withGetOpsViewColsVisibilityPrefsHoc,
  WithGetOpsViewColsVisibilityPrefsHocChildProps,
  withGetOpsViewRowsVisibilityPrefsHoc,
  WithGetOpsViewRowsVisibilityPrefsHocChildProps,
  withRenameGrainBinSiteHoc,
  WithRenameGrainBinSiteHocChildProps,
  withSaveOperationsViewRowVisibilityPrefsHoc,
  WithSaveOperationsViewRowVisibilityPrefsHocChildProps,
  withSaveOperationsViewUserLevelPrefsHoc,
  WithSaveOperationsViewUserLevelPrefsHocChildProps,
  withUpdateGrainBinHoc,
  WithUpdateGrainBinHocChildProps,
  withUpdateGrainBinSiteLinkHoc,
  WithUpdateGrainBinSiteLinkHocChildProps,
} from '../../../../../core/src/api';
import { BackArrowIcon } from '../../../../../core/src/media';
import { black_shade_8 } from '../../../../../core/src/style';
import { DialogSpinner } from '../../spinner';
import { GrainBinAggregationDataType } from '../GrainOperations';
import { getValidOperationsViewRowsVisibilityPrefs } from '../OperationsViewTableHelpers';
import { ManageSitesTable, PrefJsonItem } from './ManageSitesTable';

export const UNASSIGNED_SITE_NAME = 'UNASSIGNED_BINS';
export const UNASSIGNED_BINS_SITE_ID = -1;
export const UNASSIGNED_SITE_DISPLAY_NAME = 'UNGROUPED BINS';
const ManageSitesViewBase: FunctionComponent<
  {
    grain_bin_ids: number[];
    goContainer: (args: any) => void;
    user_id: number;
    account_id: number;
  } & WithGetGrainBinSitesHocChildProps &
    WithCreateGrainBinSiteHocChildProps &
    WithRenameGrainBinSiteHocChildProps &
    WithDeleteGrainBinSiteHocChildProps &
    WithUpdateGrainBinSiteLinkHocChildProps &
    WithUpdateGrainBinHocChildProps &
    WithGetAccountHocChildProps &
    WithSaveOperationsViewUserLevelPrefsHocChildProps &
    WithSaveOperationsViewRowVisibilityPrefsHocChildProps &
    WithGetOpsViewColsVisibilityPrefsHocChildProps &
    WithGetOpsViewRowsVisibilityPrefsHocChildProps &
    RouteComponentProps
> = ({
  user_id,
  account_id,
  grain_bin_ids,
  grain_bin_sites,
  grain_sites_loading,
  grainSitesRefetch,
  goContainer,
  createGrainBinSite,
  deleteGrainBinSite,
  renameGrainBinSite,
  updateGrainBinSiteLink,
  updateGrainBin,
  refecthAccount,
  saveOperationsViewRowVisibilityPrefs,
  saveOperationsViewUserLevelPrefs,
  loading,
  rows_visibility_prefs_loading,
  operations_view_rows_visibility_prefs,
  operations_view_cols_visibility_prefs,
}) => {
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const dispatch = useDispatch();
  // get tree data
  console.log('grain_bin_sites', grain_bin_sites);
  const grainBins: GrainBinAggregationDataType[] = grain_bin_sites.reduce((acc: any, site) => {
    if (site && site.bins.length > 0) acc.push(...site.bins);
    return acc;
  }, []);

  console.log('grainBins', grainBins);

  const grainBinsHierarchyData = grainBins.map((bin) => {
    const grain_bin_attributes: any = bin.grain_bin_attributes;
    console.log('grain_bin_attributes', grain_bin_attributes);
    return {
      id: `bin-${bin.grain_bin_id}`,
      bin_id: bin.grain_bin_id,
      bin_name: bin.grain_bin_attributes.bin_name,
      siteHierarchy: [
        grain_bin_attributes.site ? grain_bin_attributes.site.site_name : 'UNASSIGNED_BINS',
        bin.grain_bin_attributes.bin_name,
      ],
      site_id: grain_bin_attributes.site
        ? grain_bin_attributes.site.site_id
        : UNASSIGNED_BINS_SITE_ID,
    };
  });
  console.log('grainBinsHierarchyData', grainBinsHierarchyData);
  const unassignedBins = grainBinsHierarchyData.filter(
    (bin) => bin.site_id === UNASSIGNED_BINS_SITE_ID
  );
  const unassignedBinsIds = unassignedBins.map(({ bin_id }) => bin_id);

  console.log('unassignedBinsIds in manage sites', unassignedBinsIds);

  let grainSitesHierarchyData = grain_bin_sites
    .filter((site) => site.site_id !== UNASSIGNED_BINS_SITE_ID)
    .map((site) => {
      return {
        id: `site-${site.site_id}`,
        site_id: site.site_id,
        siteHierarchy: [site.site_name],
      };
    });
  grainSitesHierarchyData = [
    ...grainSitesHierarchyData,
    {
      siteHierarchy: [UNASSIGNED_SITE_NAME],
      site_id: UNASSIGNED_BINS_SITE_ID,
      id: `site-${UNASSIGNED_BINS_SITE_ID}`,
    },
  ];

  console.log('grainSitesHierarchyData', grainSitesHierarchyData);

  // merge sites and bins hierarchy data
  const sitesHierarchyData = [...grainSitesHierarchyData, ...grainBinsHierarchyData];

  console.log('sitesHierarchyData', sitesHierarchyData);

  const allActiveSitesIds: number[] = grain_bin_sites.map((site) => site.site_id);
  const valid_operations_view_rows_visibility_prefs = getValidOperationsViewRowsVisibilityPrefs({
    allActiveSitesIds,
    operations_view_rows_visibility_prefs,
    unassignedBinsIds,
  });
  const updated_operations_view_rows_visibility_prefs: OpsViewRowVisibilityPrefsFragmentFragment | null = valid_operations_view_rows_visibility_prefs
    ? {
        account_id,
        pref_json: JSON.stringify({ sites: valid_operations_view_rows_visibility_prefs }),
        __typename: 'RowVisibilityPref',
      }
    : null;

  const goToOperationsView = () => {
    dispatch(pushNav({ path: '/grain/operations' }));
  };

  const renameGrainSite = async (site_id: number, site_name: string) => {
    try {
      // setIsUpdating(true);
      const result = await renameGrainBinSite({ site_id, site_name });
      console.log('renamed site', { result });
    } catch (error) {
      console.error(error);
    } finally {
      // setIsUpdating(false);
    }
  };

  const createGrainSite = async (
    site_name: string
  ): Promise<GrainBinSiteFragmentFragment | null> => {
    try {
      const result = await createGrainBinSite({ account_id, site_name });
      console.log('created site', { result });
      return result;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const deleteGrainSite = async (site_id: number) => {
    try {
      const result = await deleteGrainBinSite({ site_id });
      console.log('deleted site', { result });
    } catch (error) {
      console.error(error);
    }
  };

  const moveGrainBinIntoSite = async (
    grain_bin_id: number,
    site_id: number
  ): Promise<GrainBinSiteLink | null> => {
    console.log('moveGrainBinIntoSite', grain_bin_id, site_id);
    try {
      const result = await updateGrainBinSiteLink({ grain_bin_id, site_id });
      console.log('moved bin', { result });
      return result;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const renameGrainBin = async (grain_bin_id: number, bin_name: string) => {
    try {
      const variables: UpdateGrainBinMutationVariables = {
        grain_bin_id,
        bin_name,
        location: null,
      };
      const result = await updateGrainBin(variables);
      console.log('updated bin', { result });
      await refecthAccount();
    } catch (error) {
      console.error(error);
    }
  };

  const updateOperationsViewRowVisibilityPrefs = async (newSiteRows: PrefJsonItem[]) => {
    try {
      const variables: SaveOperationsViewRowVisibilityPrefsMutationVariables = {
        account_id,
        pref_json: JSON.stringify({ sites: newSiteRows }),
      };
      const result = await saveOperationsViewRowVisibilityPrefs(variables);
      console.log('updated prefs', { result });
    } catch (error) {
      console.error(error);
    }
  };

  if (grain_sites_loading || loading || rows_visibility_prefs_loading) {
    return <DialogSpinner open title="Loading Sites..." />;
  }
  console.log('sitesHierarchyData', sitesHierarchyData);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        // height: isMobileScreen ? 'calc(100vh - 136px)' : 'calc(100vh - 210px)',
        flexDirection: 'column',
        marginLeft: 20,
        marginRight: 20,
        width: isWideScreen ? '1030px' : '100%',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'start',
          margin: 20,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            marginRight: 10,
            cursor: 'pointer',
          }}
        >
          <BackArrowIcon onClick={goToOperationsView} />
        </div>
        <h1 style={{ margin: 0, fontSize: 20, fontWeight: 600, color: black_shade_8 }}>
          Manage Table
        </h1>
      </div>
      <ManageSitesTable
        user_id={user_id}
        account_id={account_id}
        data={sitesHierarchyData}
        grainSitesRefetch={grainSitesRefetch}
        deleteGrainSite={deleteGrainSite}
        renameGrainSite={renameGrainSite}
        createGrainSite={createGrainSite}
        moveGrainBinIntoSite={moveGrainBinIntoSite}
        renameGrainBin={renameGrainBin}
        operations_view_cols_visibility_prefs={operations_view_cols_visibility_prefs}
        operations_view_rows_visibility_prefs={updated_operations_view_rows_visibility_prefs}
        saveOperationsViewUserLevelPrefs={saveOperationsViewUserLevelPrefs}
        saveOperationsViewRowVisibilityPrefs={saveOperationsViewRowVisibilityPrefs}
        updateOperationsViewRowVisibilityPrefs={updateOperationsViewRowVisibilityPrefs}
      />
    </div>
  );
};

export const ManageSitesView = withGetAccountHoc(
  withGetOpsViewRowsVisibilityPrefsHoc(
    withGetOpsViewColsVisibilityPrefsHoc(
      withSaveOperationsViewRowVisibilityPrefsHoc(
        withSaveOperationsViewUserLevelPrefsHoc(
          withUpdateGrainBinHoc(
            withUpdateGrainBinSiteLinkHoc(
              withCreateGrainBinSiteHoc(
                withDeleteGrainBinSiteHoc(
                  withRenameGrainBinSiteHoc(withGetGrainBinSitesHoc(ManageSitesViewBase))
                )
              )
            )
          )
        )
      )
    )
  )
);
