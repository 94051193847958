import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  BargeAccountLinkInternalFragmentFragment as Result,
  withGetAccountBarges as HOC,
} from '../__generated';

export type WithGetAccountBargesHocOwnProps = {
  account_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetAccountBargesHocChildProps = {
  loading: boolean;
  barge_links: Result[];
  refetchBargeAccountLinks: () => Promise<any>;
};

export const withGetAccountBargesHoc: AmberHoc<
  WithGetAccountBargesHocOwnProps,
  WithGetAccountBargesHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetAccountBargesHocOwnProps,
      WithGetAccountBargesHocChildProps,
      typeof component
    >,
    WithGetAccountBargesHocChildProps
  >({
    options: ({ onError, account_id }) => ({
      onError,
      variables: { account_id },
      errorPolicy: onError ? 'all' : 'none',
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading || !data.account) {
        return {
          refetchBargeAccountLinks: () => Promise.resolve(),
          loading: true,
          barge_links: [],
        };
      }
      return {
        refetchBargeAccountLinks: () => data.refetch(),
        loading: loading || false,
        barge_links: data.account.barge_links || [],
      };
    },
  })(component as any);
