import formatNumber from './format-number';
import { getHasUserSelectedKilogramUnit, getHasUserSelectedMeterUnit } from './get-user-units-pref';
import { UnitsConverter } from './units-converter';

// ref: https://www.spikevm.com/calculators/farming/rd-grain-bins-us-bushels.php
// bu = 0.8036 * ((22 / 7) * Math.pow(diameter / 2, 2) * height);

export const calculateBushels = (diameter: number, height: number): number => {
  const hasUserSelectedMeterUnit = getHasUserSelectedMeterUnit();
  let bu;
  if (hasUserSelectedMeterUnit) {
    const diameterInFeets = UnitsConverter.convertMetersToFeet(diameter);
    const heightInFeets = UnitsConverter.convertMetersToFeet(height);
    bu = 0.8036 * ((22 / 7) * Math.pow(diameterInFeets / 2, 2) * heightInFeets);
  } else {
    bu = 0.8036 * ((22 / 7) * Math.pow(diameter / 2, 2) * height);
  }
  return bu;
};

export const estimateAndFormatBushels = (
  diameter: number,
  height: number,
  separator?: string
): string => {
  const bu = calculateBushels(diameter, height);
  const hasUserSelectedKgUnit = getHasUserSelectedKilogramUnit();
  const separatorSymbol = separator ? (separator === 'space' ? ' ' : '-') : '-';
  if (hasUserSelectedKgUnit) {
    const tons = UnitsConverter.convertBuToMetricTon(bu);
    return `${formatNumber(tons)}${separatorSymbol}ton`;
  }
  return `${formatNumber(Math.round(bu / 1000) * 1000)}${separatorSymbol}bu`;
};
