import {Grid, Typography} from "@material-ui/core";
import HowToRegOutlined from "@material-ui/icons/HowToRegOutlined";
import React, {useState} from "react";
import {RouteComponentProps} from "react-router";
import {signOffDiagnosticTestApi} from "../../api/rest";
import {
    amber_green,
    amber_light_grey
} from "../../style";
import ConfirmationModal from "../util/ConfirmationModal";
import {Button} from "../util/form2/Button";

export const DiagTestApproveCard = ({
        core_id,
        isDiagnosticTestEnabled,
    }:{
    core_id: string,
    isDiagnosticTestEnabled: boolean;
    } & RouteComponentProps)=>{
    const [showSignOffConfirmationModal, setShowSignOffConfirmationModal] = useState<boolean>(false);
    const signOffDiagnosticTest = async () => {
        try {
            const response = await signOffDiagnosticTestApi(core_id);
            if (response.status === 200) {
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <>
            <Grid container style={{ height: '200px' }}>
                { isDiagnosticTestEnabled && <Typography variant="body1" style={{marginTop:'15px', marginLeft: '10px', marginRight: '10px'}} gutterBottom>
                   Click 'Approve' once your installation has passed all desired checks. This disables the Fan Controller Diagnostics
                   page for this device for better security. The diagnostics page can be re-enabled at any time from the bin's
                   Devices page within the Amber Dashboard.
                </Typography>}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        style={{ marginTop:'5px', height: '55px', width: '120px', backgroundColor: isDiagnosticTestEnabled?amber_green:amber_light_grey}}
                        onClick={() => setShowSignOffConfirmationModal(true)}
                        disabled={!isDiagnosticTestEnabled}
                    >
                        Approve &nbsp;&nbsp; <HowToRegOutlined />
                    </Button>
                </Grid>
            </Grid>
            <ConfirmationModal
                showModal={showSignOffConfirmationModal}
                confirmationMessage={
                    <>
                        Diagnostic test will be <strong>disabled</strong> once approved. Click 'Approve' to
                        continue.
                    </>
                }
                cancelBtnText="Cancel"
                confirmBtbText="Approve"
                handleCancel={() => setShowSignOffConfirmationModal(false)}
                handleConfirm={() => {
                    signOffDiagnosticTest();
                    setShowSignOffConfirmationModal(false);
                }}
            />
        </>
    );
}