import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';

import { Cable, PileCablesTable } from './PileCablesTable';

const useStyles = makeStyles({
  divider: { marginBottom: 2 },
});

export const PileCablesTableCard: FunctionComponent<{
  cables: Cable[];
  selected_cable: Cable | null;
  loading: boolean;
  setSelectedCable: (item: Cable | null) => void;
}> = ({ cables, selected_cable, loading, setSelectedCable }) => {
  const classes = useStyles();

  return (
    <Card raised>
      <CardHeader
        title={`Pile Cables`}
        titleTypographyProps={{
          color: 'secondary',
        }}
      />
      <Divider classes={{ root: classes.divider }} />
      <CardContent>
        <PileCablesTable
          cables={cables}
          loading={loading}
          onSelectPileCable={setSelectedCable}
          selected_cable={selected_cable}
        />
      </CardContent>
    </Card>
  );
};
