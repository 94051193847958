import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  UserModelFragmentFragment as Result,
  withGetOrganizationUsersList as HOC,
} from '../__generated';

export type WithGetOrganizationUsersListHocOwnProps = {
  account_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetOrganizationUsersListHocChildProps = {
  loading: boolean;
  organization_users_list: Result[];
  refetchOrgUsersList: () => Promise<any>;
};

export const withGetOrganizationUsersListHoc: AmberHoc<
  WithGetOrganizationUsersListHocOwnProps,
  WithGetOrganizationUsersListHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetOrganizationUsersListHocOwnProps,
      WithGetOrganizationUsersListHocChildProps,
      typeof component
    >,
    WithGetOrganizationUsersListHocChildProps
  >({
    options: ({ onError, account_id }) => ({
      onError,
      variables: { account_id },
      errorPolicy: onError ? 'all' : 'none',
      notifyOnNetworkStatusChange: true,
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          refetchOrgUsersList: () => Promise.resolve(),
          loading: true,
          organization_users_list: [],
        };
      }
      return {
        refetchOrgUsersList: () => data.refetch(),
        loading: loading || false,
        organization_users_list: data.account ? data.account.organization_users_list : [],
      };
    },
  })(component as any);
