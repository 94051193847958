import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../action/session';

type ActionTypes = ActionType<typeof actions>;
export type SessionState = {
  account_id: number | null;
  user_id: number | null;
  email_address: string;
  ready: boolean;
};
export const default_session_state: Readonly<SessionState> = Object.freeze({
  account_id: null,
  user_id: null,
  email_address: '',
  ready: false,
});

export const sessionReducer = (
  state: SessionState = default_session_state,
  action: ActionTypes
) => {
  switch (action.type) {
    case getType(actions.createSession): {
      const {
        payload: { viewer },
      } = action;
      if (!viewer) {
        return { ...state, ready: true };
      }
      return {
        ...state,
        email_address: viewer.user.email_address,
        account_id: viewer.account_id,
        user_id: viewer.user_id,
        ready: false,
      };
    }
    case getType(actions.endSession): {
      return {
        ...state,
        account_id: null,
        user_id: null,
        ready: false,
      };
    }
    case getType(actions.readySession): {
      return {
        ...state,
        ready: true,
      };
    }
    default:
      return state;
  }
};
