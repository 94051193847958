import { Button, DialogContent, Grid, makeStyles, Theme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { AutomationIcon } from '../../../../../core/src/media';
import { amber_green, black, white } from '../../../../../core/src/style';
import { SimpleDialog } from '../../util/SimpleDialog';

const useStyles = makeStyles((theme: Theme) => ({
  fanAutomationIconContainer: {
    marginRight: 10,
    width: 16,
    height: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  generateAutomation: {
    width: 250,
    height: 40,
    fontSize: 20,
    color: white,
    backgroundColor: amber_green,
    boxShadow: '0px 0px 4px #00000033',
    borderRadius: 6,
    textTransform: 'capitalize',
  },
  editScheduledRunBtn: {
    width: 250,
    color: black,
    height: 40,
    boxShadow: '0px 0px 4px #00000033',
    borderRadius: 6,
    textTransform: 'capitalize',
    fontSize: 20,
  },
  dialogContent: {
    padding: '36px 26px',
  },
}));

export const EditScheduleDialog: React.FunctionComponent<{
  open: boolean;
  onClickClose: () => void;
  addRecommendedWindow?: () => void;
  startSettingNavigation?: () => void;
}> = ({ open, onClickClose, addRecommendedWindow, startSettingNavigation }) => {
  const classes = useStyles();

  return (
    <SimpleDialog open={open} handleClose={onClickClose}>
      <DialogContent className={classes.dialogContent}>
        <Grid container justify="center" alignItems="center" spacing={1}>
          <Grid item xs={12}>
            {startSettingNavigation && (
              <Button
                variant="outlined"
                className={classes.editScheduledRunBtn}
                onClick={() => {
                  startSettingNavigation();
                }}
              >
                <EditIcon style={{ marginRight: 10 }} />
                Edit Schedule
              </Button>
            )}

            {addRecommendedWindow && (
              <>
                <div>
                  <p style={{ fontSize: 20, margin: 15 }}>or</p>
                </div>
                <Button
                  variant="outlined"
                  className={classes.generateAutomation}
                  style={{
                    color: white,
                    backgroundColor: amber_green,
                  }}
                  onClick={() => addRecommendedWindow()}
                >
                  <div className={classes.fanAutomationIconContainer}>
                    <AutomationIcon />
                  </div>
                  Generate Automations
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </SimpleDialog>
  );
};
