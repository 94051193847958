import {
  DialogContent,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';

import { ArrowForward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React from 'react';
import {
  FanRunWindowRecommendedOption,
  GrainContainerAerationRunFragmentFragment,
} from '../../../api';
import { amber_amber, black } from '../../../style';
import { formatPercent, formatTemp } from '../../../util';
import { BaseDialog } from '../../util';
import {
  COOLING_COLOR,
  DRYING_COLOR,
  RECONDITIONING_COLOR,
} from '../daily-forecast/AerationRecommnedationPlotHelpers';
import { formatDateTime, formatDuration, getMs } from './AerationEventHistoryTable';
import { DateSpan } from './AerationWindow';

const useStyles = makeStyles({
  root: {
    paddingBottom: 20,
    '& td,th': {
      width: '50%',
      padding: 8,
    },
    '& tr:last-child': {
      '& td': {
        borderBottom: 'none',
      },
    },
  },
  top_margin: { marginTop: 20 },
  bold: { fontWeight: 'bold' },
  icon: { paddingLeft: 10, paddingRight: 10, height: 13, width: 36 },
  fan_start_end_reason_styles: {
    textTransform: 'capitalize',
  },
  table_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const WithOutline = (props) => (
  <Paper style={{ width: '85%' }} variant="outlined">
    {props.children}
  </Paper>
);

export const getFanStartEndReasonTextColor = (reason) =>
  ({
    custom: black,
    drying: DRYING_COLOR,
    cooling: COOLING_COLOR,
    reconditioning: RECONDITIONING_COLOR,
    manual: black,
  }[reason]);

export const AerationEventDetailsDialog: React.FunctionComponent<{
  aeration_event: GrainContainerAerationRunFragmentFragment | null;
  grain_bin_id: number;
  open: boolean;
  onClickClose: () => void;
}> = ({ aeration_event, open, onClickClose }) => {
  const classes = useStyles();
  if (!aeration_event) {
    return (
      <BaseDialog title="Fan Run Details" open={open} handleClose={onClickClose}>
        <DialogContent className={classes.root}>
          <Grid container justify="center" alignItems="center" spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">No Run Found</Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </BaseDialog>
    );
  }

  const {
    start_epoch,
    end_epoch,
    avg_temp_f,
    avg_humidity_rh,
    avg_emc,
    start_temp_f,
    start_humidity_rh,
    end_temp_f,
    end_humidity_rh,
    current_grain_temp,
    current_grain_emc,
    target_grain_emc,
    fan_runs,
  } = aeration_event;
  const enabled_fan_guidance = fan_runs && fan_runs[0] ? fan_runs[0].enabled_fan_guidance : null;
  const fan_guidance_automation =
    enabled_fan_guidance === true ? 'Enabled' : enabled_fan_guidance === false ? 'Disabled' : '-';
  const fan_run_recommendation_type =
    fan_runs && fan_runs[0] ? fan_runs[0].start_recommendation_type : null;

  const getFanStartReasonDescription = (start_reason) =>
    ({
      custom: 'custom scheduled start',
      drying: 'drying start',
      cooling: 'cooling start',
      reconditioning: 'reconditioning start',
      manual: 'manual start',
    }[start_reason]);

  const getFanEndReasonDescription = (start_reason) =>
    ({
      custom: 'custom scheduled end',
      drying: 'drying end',
      cooling: 'cooling end',
      reconditioning: 'reconditioning end',
      manual: 'manual end',
    }[start_reason]);

  return (
    <BaseDialog title="Fan Run Details" open={open} handleClose={onClickClose}>
      <DialogContent className={classes.root}>
        <Grid
          container
          justify="center"
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography variant="h5">Run Details</Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '0.5rem' }}>
            {end_epoch ? (
              <DateSpan
                start={DateTime.fromMillis(start_epoch)}
                end={DateTime.fromMillis(end_epoch)}
              />
            ) : (
              <Typography style={{ fontSize: '0.8rem' }}>
                {' '}
                {`Running since ${formatDateTime(start_epoch)}`}
              </Typography>
            )}
          </Grid>

          <Grid container justify="center" spacing={2}>
            <Grid item className={classes.table_container} xs={12}>
              <TableContainer component={WithOutline}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Runtime</TableCell>
                      <TableCell>
                        {end_epoch
                          ? formatDuration(getMs(end_epoch) - getMs(start_epoch))
                          : `${formatDuration(
                              getMs(DateTime.local().ts) - getMs(start_epoch)
                            )} & running`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> Exhaust Temp</TableCell>
                      <TableCell>
                        {' '}
                        {start_temp_f !== null && start_temp_f !== undefined
                          ? formatTemp(start_temp_f)
                          : 'Unknown'}
                        <ArrowForward className={classes.icon} />
                        {end_temp_f !== null && end_temp_f !== undefined
                          ? formatTemp(end_temp_f)
                          : 'Unknown'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> Exhaust Humidity</TableCell>
                      <TableCell>
                        {start_humidity_rh !== null && start_humidity_rh !== undefined
                          ? formatPercent(start_humidity_rh)
                          : 'Unknown'}
                        <ArrowForward className={classes.icon} />
                        {end_humidity_rh !== null && end_humidity_rh !== undefined
                          ? formatPercent(end_humidity_rh)
                          : 'Unknown'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item className={classes.table_container} xs={12}>
              <TableContainer component={WithOutline}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell> Weather Avg Temp</TableCell>
                      <TableCell>
                        {avg_temp_f !== null && avg_temp_f !== undefined
                          ? formatTemp(avg_temp_f)
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> Weather Avg Humidity</TableCell>
                      <TableCell>
                        {avg_humidity_rh !== null && avg_humidity_rh !== undefined
                          ? formatPercent(avg_humidity_rh)
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> Weather Avg EMC</TableCell>
                      <TableCell>
                        {avg_emc !== null && avg_emc !== undefined ? formatPercent(avg_emc) : '-'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {fan_run_recommendation_type &&
              ![
                FanRunWindowRecommendedOption.Custom,
                FanRunWindowRecommendedOption.Manual,
              ].includes(fan_run_recommendation_type) && (
                <Grid item className={classes.table_container} xs={12}>
                  <Typography
                    style={{ fontSize: '0.8rem', textAlign: 'start', marginBottom: '0.1rem' }}
                  >
                    Recommendation Inputs
                  </Typography>
                  <TableContainer component={WithOutline}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Automation</TableCell>
                          <TableCell>
                            <strong>{fan_guidance_automation}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Input Grain Temp</TableCell>
                          <TableCell>
                            {current_grain_temp ? formatTemp(current_grain_temp) : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Input Grain EMC</TableCell>
                          <TableCell>{current_grain_emc ? `${current_grain_emc}%` : '-'}</TableCell>
                        </TableRow>
                        {fan_run_recommendation_type !== FanRunWindowRecommendedOption.Cooling && (
                          <TableRow>
                            <TableCell>Target Grain EMC</TableCell>
                            <TableCell>{target_grain_emc ? `${target_grain_emc}%` : '-'}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
          </Grid>

          <Grid item xs={12} className={classes.top_margin}>
            <Typography variant="h5">Individual Fan Stats</Typography>
          </Grid>
          {aeration_event.fan_runs && aeration_event.fan_runs.length ? (
            aeration_event.fan_runs.map((fan_run, ix) => {
              const {
                start_epoch: start,
                fan_start_reason,
                end_epoch: end,
                fan_end_reason,
                current_avg_a,
              } = fan_run;
              return (
                <Grid item xs={12} container spacing={1} justify="center" key={`fr-${ix}`}>
                  <Grid item xs={12} className={classes.bold} style={{ fontSize: '0.8rem' }}>
                    Fan:{' '}
                    {(fan_run.grain_bin_fan_controller && fan_run.grain_bin_fan_controller.alias) ||
                      'Unknown'}
                  </Grid>
                  <Grid item xs={12}>
                    {end ? (
                      <DateSpan start={DateTime.fromMillis(start)} end={DateTime.fromMillis(end)} />
                    ) : (
                      <Typography style={{ fontSize: '0.8rem' }}>
                        {`Running since ${formatDateTime(start)}`}
                      </Typography>
                    )}
                  </Grid>

                  <Grid container justify="center" spacing={2}>
                    <Grid item className={classes.table_container} xs={12}>
                      <TableContainer component={WithOutline}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell> Start Reason</TableCell>
                              <TableCell>
                                {fan_start_reason ? (
                                  <span
                                    className={classes.fan_start_end_reason_styles}
                                    style={{
                                      color: getFanStartEndReasonTextColor(fan_start_reason),
                                      fontWeight: 500,
                                    }}
                                  >
                                    {getFanStartReasonDescription(fan_start_reason)}
                                  </span>
                                ) : (
                                  '-'
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell> End Reason</TableCell>
                              <TableCell>
                                {end && fan_end_reason ? (
                                  <span
                                    className={classes.fan_start_end_reason_styles}
                                    style={{
                                      color: getFanStartEndReasonTextColor(fan_end_reason),
                                      fontWeight: 500,
                                    }}
                                  >
                                    {getFanEndReasonDescription(fan_end_reason)}
                                  </span>
                                ) : (
                                  '-'
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell> Average Amp Draw</TableCell>
                              <TableCell>
                                {current_avg_a ? `${current_avg_a.toFixed(1)} A` : 'N/A'}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                  {ix !== (aeration_event.fan_runs as any).length - 1 && (
                    <Grid item xs={12}>
                      <Divider variant="fullWidth" />
                    </Grid>
                  )}
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12}>
              No Fan Runs Recorded
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};
