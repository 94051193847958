import { datadogLogs } from '@datadog/browser-logs';
import { ApolloClient } from 'apollo-client';
import { FetchResult } from 'apollo-link';
import { replace } from 'connected-react-router';
import { put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import {
  createSession,
  endSession,
  readySession,
  renewSession,
  setNavBar,
  workerBroadcast,
} from '../action';
import {
  AccountFragmentFragment,
  GetAccountDocument,
  GetAccountQuery,
  GetViewerDocument,
  notifyIos,
  RenewTokenDocument,
  RenewTokenMutation,
  RenewTokenMutationVariables,
  UserRole,
  ViewerFragmentFragment,
} from '../api';
import { userMonitor } from '../component/rum';
import { default_selected_state, SelectedState } from '../reducer';
import { ContainerTypeLegacy, getQueryParameter, WorkerMessageType } from '../util';

const validateSelected = async (
  apollo_client: ApolloClient<any>,
  selected: SelectedState
): Promise<SelectedState> => {
  try {
    const { data: cached } = await apollo_client.query<GetAccountQuery>({
      query: GetAccountDocument,
      variables: { account_id: selected.account_id },
      errorPolicy: 'all',
      fetchPolicy: 'cache-only',
    });
    let account: AccountFragmentFragment;
    if (!cached || !cached.account) {
      const { data } = await apollo_client.query<GetAccountQuery>({
        query: GetAccountDocument,
        variables: { account_id: selected.account_id },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });
      if (!data || !data.account) {
        return { ...selected, container_id: null, container_type: null };
      }
      account = data.account;
    } else {
      account = cached.account;
    }

    const { container_type, container_id } = selected;
    const {
      grain_bin_links,
      barge_links,
      pile_links,
      grain_bin_support,
      barge_support,
      pile_support,
    } = account;
    // Grain bin was previously selected...
    if (container_type === ContainerTypeLegacy.bin) {
      if (
        grain_bin_links.find(
          ({ grain_bin: { grain_bin_id, archived } }) => grain_bin_id === container_id && !archived
        )
      ) {
        return selected;
      }
      const unarchived_bins = grain_bin_links.filter((link) => !link.grain_bin.archived);

      return {
        ...selected,
        container_id: unarchived_bins.length ? unarchived_bins[0].grain_bin.grain_bin_id : null,
      };
    }

    // Barge was previously selected
    if (container_type === ContainerTypeLegacy.barge) {
      if (
        barge_links.find(
          ({ barge: { barge_id, archived } }) => barge_id === container_id && !archived
        )
      ) {
        return selected;
      }
      const unarchived_barges = barge_links.filter((link) => !link.barge.archived);
      return {
        ...selected,
        container_id: unarchived_barges.length ? unarchived_barges[0].barge.barge_id : null,
      };
    }

    // Pile was previously selected
    if (container_type === ContainerTypeLegacy.pile) {
      if (pile_links.find(({ pile: { pile_id } }) => pile_id === container_id)) {
        return selected;
      }
      return {
        ...selected,
        container_id: pile_links.length ? pile_links[0].pile.pile_id : null,
      };
    }

    // Nothing currently selected
    if (grain_bin_support) {
      return selected;
    }
    if (barge_support) {
      const unarchived_containers = barge_links.filter((link) => !link.barge.archived);
      return unarchived_containers.length
        ? {
            ...selected,
            container_id: unarchived_containers[0].barge.barge_id,
            container_type: ContainerTypeLegacy.barge,
          }
        : {
            ...selected,
            container_type: ContainerTypeLegacy.barge,
          };
    }
    if (pile_support) {
      return pile_links.length
        ? {
            ...selected,
            container_id: pile_links[0].pile.pile_id,
            container_type: ContainerTypeLegacy.pile,
          }
        : {
            ...selected,
            container_type: ContainerTypeLegacy.pile,
          };
    }
    return {
      ...selected,
      container_type: null,
      container_id: null,
    };
  } catch (err) {
    return selected;
  }
};

function* createSessionSaga(
  apollo_client: ApolloClient<any>,
  setServiceWorkerViewer: (viewer: ViewerFragmentFragment, propagate: boolean) => void,
  action: ReturnType<typeof createSession>
) {
  const {
    payload: { viewer },
  } = action;
  if (!viewer) {
    yield put(readySession({}));
    return;
  }
  apollo_client.writeQuery({
    query: GetAccountDocument,
    data: {
      account: viewer.account,
    },
    variables: { account_id: viewer.account.account_id },
  });
  const {
    token,
    user_id,
    account_id,
    user: { email_address, role, verified_email, selected_grain_bin },
    account: { barge_support, grain_bin_support, system_account },
  } = viewer;
  const system_admin = role === UserRole.Admin && system_account;
  let selected: SelectedState = { ...default_selected_state, user_id };
  const notLoggedInAsCustomerCare = !Boolean(localStorage.getItem('customer_care_token'));

  if (notLoggedInAsCustomerCare) {
    window.localStorage.setItem('token', token);
  }

  window.localStorage.setItem('email_address', email_address);

  if (window.location.pathname !== '/privacy') {
    if (selected_grain_bin) {
      datadogLogs.logger.info('SELECTED_GRAIN_BIN', {
        selected_grain_bin,
        account_id,
      });
      if (
        system_admin ||
        (selected_grain_bin.account_id === account_id &&
          selected_grain_bin.container_id !== null &&
          ((selected_grain_bin.container_type === ContainerTypeLegacy.bin && grain_bin_support) ||
            (selected_grain_bin.container_type === ContainerTypeLegacy.barge && barge_support)))
      ) {
        selected = {
          ...selected,
          ...selected_grain_bin,
        };
      } else if (selected_grain_bin.account_id) {
        selected = {
          ...selected,
          account_id: selected_grain_bin.account_id,
        };
      }
    } else {
      const unarchived_bins = viewer.account.grain_bin_links.filter(
        (link) => !link.grain_bin.archived
      );
      const unarchived_barges = viewer.account.barge_links.filter((link) => !link.barge.archived);
      selected = {
        user_id: viewer.user_id,
        account_id: viewer.account.account_id,
        container_type:
          viewer.account.grain_bin_support && viewer.account.grain_bin_links.length > 0
            ? ContainerTypeLegacy.bin
            : viewer.account.barge_support && viewer.account.barge_links.length > 0
            ? ContainerTypeLegacy.barge
            : viewer.account.pile_support && viewer.account.pile_links.length > 0
            ? ContainerTypeLegacy.pile
            : null,
        container_id:
          viewer.account.grain_bin_support && unarchived_bins.length > 0
            ? unarchived_bins[0].grain_bin.grain_bin_id
            : viewer.account.barge_support && unarchived_barges.length > 0
            ? unarchived_barges[0].barge.barge_id
            : viewer.account.pile_support && viewer.account.pile_links.length > 0
            ? viewer.account.pile_links[0].pile.pile_id
            : null,
        container_name:
          viewer.account.grain_bin_support && unarchived_bins.length > 0
            ? unarchived_bins[0].grain_bin.alias
            : viewer.account.barge_support && unarchived_barges.length > 0
            ? unarchived_barges[0].barge.alias
            : viewer.account.pile_support && viewer.account.pile_links.length > 0
            ? viewer.account.pile_links[0].pile.alias
            : '',
        pellet_id: '',
        cable_id: '',
        fan_controller: null,
        hub: null,
      };

      if (selected.account_id === null) {
        datadogLogs.logger.error("SELECT_ERROR: The selected account_id can't be null.", {
          selected,
          user: viewer.user,
          account: viewer.account,
        });
        console.error("account_id can't be null from viewer.account=", {
          selected,
          account: viewer.account,
        });
      }
    }
    if (
      !system_admin &&
      ((selected.container_type === ContainerTypeLegacy.bin && !grain_bin_support) ||
        (selected.container_type === ContainerTypeLegacy.barge && !barge_support))
    ) {
      selected = { ...selected, container_id: null, container_type: null };
    }

    datadogLogs.logger.info('Grain bin selection processing complete.', {
      viewer,
      selected,
    });

    selected = yield validateSelected(apollo_client, selected);
    datadogLogs.logger.info('Grain bin selection validation complete.', {
      selected,
      user: viewer.user,
      account: viewer.account,
    });
  }

  if (!verified_email) {
    if (!window.location.pathname.startsWith('/user')) {
      yield put(replace('/user/email_verification'));
    }
  } else if (
    window.location.pathname.startsWith('/error') ||
    window.location.pathname.startsWith('/login') ||
    window.location.pathname.startsWith('/otp') ||
    window.location.pathname.startsWith('/user/email_verification')
  ) {
    yield put(replace('/'));
  }
  yield put(
    readySession({
      selected,
    })
  );

  if (action.payload.propagate) {
    setServiceWorkerViewer(viewer, true);
  }
}

function* renewSessionSaga(action: ReturnType<typeof renewSession>) {
  window.localStorage.setItem('token', action.payload.token);
}

function* endSessionSaga(apollo_client: ApolloClient<any>, action: ReturnType<typeof endSession>) {
  userMonitor.removeUser();
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('customer_care_token');
  window.localStorage.removeItem('initial_logged_in_user');
  yield apollo_client.clearStore();
  yield put(replace('/login'));
  yield put(setNavBar({ open: false }));
  yield put(readySession({}));
  if (!action.payload || action.payload.propagate) {
    yield put(
      workerBroadcast({
        message: {
          message_type: WorkerMessageType.logout,
          payload: { propagate: true },
        },
      })
    );
  }
}

export function* loginByTokenSaga(apollo_client: ApolloClient<any>) {
  const hasQueryParamsToken = window.location.href.includes('?token=');
  const customerCareToken = localStorage.getItem('customer_care_token');
  const userToken = customerCareToken ? customerCareToken : localStorage.getItem('token');
  const token = hasQueryParamsToken ? getQueryParameter('token') : userToken;
  if (token) {
    try {
      const { data, errors }: FetchResult<RenewTokenMutation> = yield apollo_client.mutate<
        RenewTokenMutation,
        RenewTokenMutationVariables
      >({
        mutation: RenewTokenDocument,
        variables: { token },
        errorPolicy: 'all',
        update: (store, { data }) => {
          if (data) {
            store.writeQuery({ query: GetViewerDocument, data: { viewer: data.renewToken } });
          }
        },
      });
      if (errors) {
        yield put(endSession());
        return null;
      }
      if (data) {
        const { renewToken: viewer } = data;
        const hasRealUserLoggedIn = !customerCareToken;
        yield put(createSession({ viewer, propagate: true }));
        if (hasRealUserLoggedIn) {
          const tokenData = (data as RenewTokenMutation).renewToken;
          const trackUserData = {
            id: tokenData.user.user_id.toString(),
            email: tokenData.user.email_address,
            name: `${tokenData.user.first_name} ${tokenData.user.last_name}`,
            account_id: tokenData.account_id,
            organization: tokenData.account.organization,
          };
          userMonitor.setUser(trackUserData);
          localStorage.setItem('initial_logged_in_user', JSON.stringify(trackUserData));
        } else {
          const initialLoggedInUser = localStorage.getItem('initial_logged_in_user');
          const trackUserData = initialLoggedInUser ? JSON.parse(initialLoggedInUser) : '';
          trackUserData && userMonitor.setUser(trackUserData);
        }

        return viewer;
      }
    } catch (err) {
      const { networkError } = err;
      if (networkError) {
        if (networkError.result && networkError.result.name === 'BadTokenError') {
          yield put(endSession());
          return null;
        }
        yield put(replace('/error'));
        yield put(readySession({}));
        return null;
      }
    }
  }
  if (
    window.location.pathname !== '/privacy' &&
    !window.location.pathname.includes('/devices') &&
    !window.location.pathname.includes('/unsubscribe_weekly_email')
  ) {
    yield put(replace('/login'));
  }
  yield put(readySession({}));
  return null;
}

export function* noLoginInit() {
  window.localStorage.removeItem('token');
  yield put(readySession({}));
  return null;
}

function* readySessionSaga() {
  yield notifyIos({ message_type: 'ready' });
}

export function* sessionWatcher(
  apollo_client,
  setServiceWorkerViewer: (viewer: ViewerFragmentFragment, propagate: boolean) => void
) {
  yield takeLatest(
    getType(createSession),
    createSessionSaga,
    apollo_client,
    setServiceWorkerViewer
  );
  yield takeLatest(getType(endSession), endSessionSaga, apollo_client);
  yield takeLatest(getType(renewSession), renewSessionSaga);
  yield takeLatest(getType(readySession), readySessionSaga);
}
