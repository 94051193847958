const getFormatedDateTimeString = (date, time): string => {
  if (!date || !time) return '';
  let formatedString: string;
  if (Number(time.split(':')[0]) < 10) {
    formatedString = `${date} 0${time}`;
  } else {
    formatedString = `${date} ${time}`;
  }
  return formatedString;
};

export { getFormatedDateTimeString };
