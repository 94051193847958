import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { RelativeTimePeriod } from '../../../util';
import { HeadspaceCo2HistoryChart } from './HeadspaceCo2HistoryChart';

export const CO2Analysis = ({
  container_id,
  minHeight,
  maxHeight,
  width,
  grain_bin_location_timezone,
  grain_container_monthly_headspace_data,
  grain_container_weekly_headspace_data,
  loading,
}) => {
  const weekly_active_hubs_grain_telemetry_history =
    grain_container_weekly_headspace_data.telemetry_history_v2.headspace_history
      .active_hubs_grain_telemetry_history || [];

  const monthly_active_hubs_grain_telemetry_history =
    grain_container_monthly_headspace_data.telemetry_history_v2.headspace_history
      .active_hubs_grain_telemetry_history || [];

  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
      <Grid item style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="h6">1 Week</Typography>
        <HeadspaceCo2HistoryChart
          active_hubs_grain_telemetry_history={weekly_active_hubs_grain_telemetry_history}
          aeration_runs={
            grain_container_weekly_headspace_data.aeration_runs_within_period.aeration_runs || []
          }
          period={RelativeTimePeriod.week}
          grain_bin_location_timezone={grain_bin_location_timezone}
          minHeight={minHeight}
          maxHeight={maxHeight}
          width={width}
          loading={loading}
        />
      </Grid>
      <Grid item style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="h6">1 Month</Typography>
        <HeadspaceCo2HistoryChart
          active_hubs_grain_telemetry_history={monthly_active_hubs_grain_telemetry_history}
          aeration_runs={
            grain_container_monthly_headspace_data.aeration_runs_within_period.aeration_runs || []
          }
          period={RelativeTimePeriod.month}
          grain_bin_location_timezone={grain_bin_location_timezone}
          minHeight={minHeight}
          maxHeight={maxHeight}
          width={width}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};
