import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetContainersLastWeekAirspaceHistoryQuery as Result,
  withGetContainersLastWeekAirspaceHistory as HOC,
} from '../__generated';

export type WithGetContainersLastWeekAirspaceHistoryHocProps = {
  grain_bin_ids: number[];
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetContainersLastWeekAirspaceHistoryHocChildProps = {
  containers_last_week_airspace_history_loading: boolean;
  containers_last_week_airspace_history: Result['containers_last_week_airspace_history'];
  containersAirspaceHistoryRefetch: () => Promise<any>;
};
export const withGetContainersLastWeekAirspaceHistoryHoc: AmberHoc<
  WithGetContainersLastWeekAirspaceHistoryHocProps,
  WithGetContainersLastWeekAirspaceHistoryHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetContainersLastWeekAirspaceHistoryHocProps,
      WithGetContainersLastWeekAirspaceHistoryHocChildProps,
      typeof component
    >,
    WithGetContainersLastWeekAirspaceHistoryHocChildProps
  >({
    options: ({ grain_bin_ids, onError }) => ({
      onError,
      variables: { grain_bin_ids },
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      context: { important: true }, // flag to prevent from graphql batching
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          containers_last_week_airspace_history_loading: true,
          containers_last_week_airspace_history: [] as Result['containers_last_week_airspace_history'],
          containersAirspaceHistoryRefetch: () => Promise.resolve(),
        };
      }
      return {
        containers_last_week_airspace_history_loading: loading || false,
        containers_last_week_airspace_history:
          (data.containers_last_week_airspace_history as Result['containers_last_week_airspace_history']) ||
          [],
        containersAirspaceHistoryRefetch: () => data.refetch(),
      };
    },
  })(component as any);
