import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useField } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { GetProps, Omit } from 'react-redux';
import * as yup from 'yup';

import {
  AccountFragmentFragment,
  UserFragmentFragment,
  UserModelFragmentFragment,
  UserRole,
  withInviteUserHoc,
  WithInviteUserHocChildProps,
  withUpdateUserRoleHoc,
  WithUpdateUserRoleHocChildProps,
} from '../../api';
import { BaseForm, FormikWrapper, FormikWrapperProps } from '../util/form2/BaseForm';
import { ButtonSubmit } from '../util/form2/Button';
import { ErrorBox } from '../util/form2/ErrorBox';
import { SelectUserRole, yup_user_role } from '../util/form2/SelectUserRole';
import { TextField } from '../util/form2/TextField';

const UserRoleDetails: React.FunctionComponent<{
  name: string;
  account: AccountFragmentFragment;
}> = ({ name, account }) => {
  const hasAccountGrainSupport = account.grain_bin_support;
  const hasAccountBargerSupport = account.barge_support;
  const hasAccountGrainAndBargeSupport = hasAccountGrainSupport && hasAccountBargerSupport;
  const [{ value }] = useField(name);
  let title: string = '';
  const privileges: string[] = [];
  switch (value) {
    case UserRole.Admin:
      title = title || 'This role grants total and complete access to an account';
      privileges.push(
        'Add new users',
        'Modify entitlements of other users, including other administrators'
      );
    case UserRole.FullAccess:
      title = title || 'This role grants the ability to manage all grain assets';
      privileges.push(
        `Create, modify, archive and unarchive ${
          hasAccountGrainAndBargeSupport
            ? 'grain bins & barges'
            : hasAccountGrainSupport
            ? 'grain bins'
            : hasAccountBargerSupport
            ? 'barges'
            : ''
        }`
      );
      if (hasAccountGrainSupport) {
        privileges.push(
          'Create, modify, and delete grain storage period',
          'Create, modify, and delete grain tickets'
        );
      }
    case UserRole.FanAccess:
      if (hasAccountGrainSupport) {
        title = title || 'This role grants the ability to manage aeration systems';
        privileges.push('Remotely start, stop, and schedule aeration systems');
      }
    case UserRole.ReadOnly:
      title =
        title ||
        'This role grants the ability to see and monitor current conditions for all grain storage for this account';
      privileges.push('Monitor current grain storage conditions', 'Export device telemetry');
      break;
    default:
      return null;
  }
  return (
    <>
      <p>{title}</p>
      <p>Privileges:</p>
      <ul>
        {privileges.map((line, ix) => (
          <li key={ix}>{line}</li>
        ))}
      </ul>
    </>
  );
};

const useStyles = makeStyles({
  grid: {
    minWidth: 220,
    maxWidth: 400,
  },
  centered: {
    textAlign: 'center',
  },
  location: {
    height: 400,
  },
});

type Values = {
  role: UserRole;
  email_address: string;
  general?: string;
};
const validationSchema = yup.object().shape({
  role: yup_user_role.required().nullable(false),
  email_address: yup.string().email('Valid email is required'),
});

export const UserAdminForm = withUpdateUserRoleHoc(
  withInviteUserHoc(
    ({
      account_id,
      user,
      inviteUser,
      updateUserRole,
      account,
      ...props
    }: {
      account_id: number;
      account: AccountFragmentFragment;
      user: UserModelFragmentFragment | UserFragmentFragment | null;
      onSubmitSuccess?: (result: UserFragmentFragment) => void;
    } & WithInviteUserHocChildProps &
      WithUpdateUserRoleHocChildProps &
      Pick<
        FormikWrapperProps<Values, UserFragmentFragment | boolean>,
        'onSubmitSuccess' | 'onSubmitFailure'
      >) => {
      const classes = useStyles();
      const [submit_succeeded, setSubmitSucceeded] = useState(false);
      const initial_values = useMemo(
        () =>
          user
            ? { role: user.role, email_address: user.email_address }
            : { role: UserRole.Admin, email_address: '' },
        [user]
      );
      const submitCallback = useCallback(
        async (values) => {
          setSubmitSucceeded(false);
          const { email_address, role } = validationSchema.validateSync(values);
          const result = user
            ? await updateUserRole({ role, user_id: user.user_id })
            : await inviteUser({ email_address, account_id, role });
          setSubmitSucceeded(true);
          return result;
        },
        [user, account_id, updateUserRole]
      );
      const submitSuccessCallback = (result) => {
        props.onSubmitSuccess && props.onSubmitSuccess(result);
      };

      return (
        <FormikWrapper<Values, UserFragmentFragment | boolean>
          enableReinitialize
          initialValues={initial_values}
          validationSchema={validationSchema}
          onSubmit={submitCallback}
          onSubmitSuccess={submitSuccessCallback}
          {...props}
          render={() => (
            <BaseForm submitting_message="Saving changes...">
              <Grid
                container
                direction="row"
                alignItems="center"
                alignContent="flex-start"
                justify="center"
                spacing={2}
                className={classes.grid}
              >
                {submit_succeeded && (
                  <Grid item xs={12}>
                    <Typography variant="h5" className={classes.centered}>
                      {user ? 'Save Successful' : 'Invitation Sent'}
                    </Typography>
                  </Grid>
                )}
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5" className={classes.centered}>
                      {user ? `${user.first_name} ${user.last_name}` : 'Invite a New User'}
                    </Typography>
                  </Grid>
                  {!user && (
                    <Grid item xs={12}>
                      <TextField
                        name="email_address"
                        label="Email Address"
                        placeholder="Email Address"
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <SelectUserRole name="role" label="User Role" />
                    <UserRoleDetails name="role" account={account} />
                  </Grid>
                  <ErrorBox />
                  <Grid item xs={12} className={classes.centered}>
                    <ButtonSubmit>{user ? 'SAVE' : 'SEND INVITE'}</ButtonSubmit>
                  </Grid>
                </>
              </Grid>
            </BaseForm>
          )}
        />
      );
    }
  )
);

export const InviteUserForm = (
  props: Omit<GetProps<typeof UserAdminForm>, 'user'> & { account: AccountFragmentFragment }
) => <UserAdminForm {...props} user={null} />;

export const UpdateUserRoleForm = ({
  user,
  ...props
}: Omit<GetProps<typeof UserAdminForm>, 'account_id'> & {
  user: UserFragmentFragment;
  account: AccountFragmentFragment;
}) => <UserAdminForm {...props} user={user} account_id={user.account_id} />;
