import {
  Button,
  Chip,
  DialogContent,
  Grid,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { MediumRedAlertIcon } from '../../../../../core/src/media';
import {
  GrainStatusFragmentFragment,
  HubType,
  withGetShowCo2LevelWarningHoc,
  WithGetShowCo2LevelWarningHocChildProps,
} from '../../../api';
import { ActiveHubsWithColorContext } from '../../../contexts';
import {
  amber_dark_grey,
  amber_faded_green,
  amber_grey,
  amber_red,
  amber_yellow,
  red_shade_3,
} from '../../../style';
import {
  ContainerTypeLegacy,
  formatNumber,
  formatPercent,
  RelativeTimePeriod,
  Statistic,
} from '../../../util';
import { CenteredSpinner } from '../../spinner';
import { BaseDialog, Legend, LegendItem } from '../../util';
import {
  HeadspaceHistoryCard,
  InteriorHistoryCard,
  PelletHistoryCard,
  Range,
  WeatherHistoryCard,
} from './charts';
import { SummaryCard } from './SummaryCard';

const formatHumidity = (humidity_rh) => (humidity_rh === null ? 'N/A' : formatPercent(humidity_rh));
const formatMoisture = (moisture_pct) =>
  moisture_pct === null ? 'N/A' : formatPercent(moisture_pct, 1);
const formatTemp = (temp_f) => (temp_f === null ? 'N/A' : formatNumber(temp_f));
const statistics = [Statistic.co2_ppm, Statistic.temp_f, Statistic.humidity_rh, Statistic.emc];

const TelemetryTypeCard = {
  interior: InteriorHistoryCard,
  headspace: HeadspaceHistoryCard,
  weather: WeatherHistoryCard,
  pellet: PelletHistoryCard,
};

const useStyles = makeStyles((theme: Theme) => ({
  labelContainer: {
    padding: 0,
  },
  tabRoot: {
    minWidth: 0,
  },
  tabsContainer: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 38,
    },
  },
}));

const getRanges = (grain_status, statistic): Range[] => {
  if (!grain_status) {
    return [];
  }
  if (!statistic) {
    return [];
  }
  switch (statistic) {
    case 'temp_f':
      return ((grain_status.temp_f_ranges as unknown) as Range[]) || [];
    case 'humidity_rh':
      return ((grain_status.humidity_rh_ranges as unknown) as Range[]) || [];
    case 'emc':
      return ((grain_status.emc_ranges as unknown) as Range[]) || [];
    default:
      return [];
  }
};

const OTHER_LEGEND_ITEMS: LegendItem[] = [
  { label: 'Inspect', color: fade(amber_yellow, 0.3), shape: 'square', isBold: true },
  { label: 'Take Action', color: fade(amber_red, 0.3), shape: 'square', isBold: true },
  { label: 'Ignore (Fan Running)', color: fade(amber_grey, 0.3), shape: 'square', isBold: true },
];

const useTabLabelStyles = makeStyles((theme: Theme) => ({
  label: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabLabel: {
    marginRight: 5,
    fontSize: 11,
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
  },
  tabValue: {
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 17,
    },
  },
}));

const TabLabel = ({ title, value }) => {
  const classes = useTabLabelStyles();
  return (
    <div className={classes.label}>
      <Typography className={classes.tabLabel} variant="h6">
        {title}
      </Typography>
      {value !== null && (
        <Typography className={classes.tabValue} variant="h5">
          {value}
        </Typography>
      )}
    </div>
  );
};

type BinInteriorCardProps = {
  container_id: number;
  container_type: ContainerTypeLegacy;
  grain_status?: GrainStatusFragmentFragment | null;
  only_temp?: boolean;
  overlay_aeration?: boolean;
  pellet_id?: string;
  selectable_period: boolean;
  subtitle?: string;
  telemetry_type: string;
  title: string;
  minHeight?: number;
  maxHeight?: number;
  trackPlotToggle?: (args: any) => any;
  trackPeriodChange?: (args: any) => any;
  grain_bin_location_timezone?: string;
  exclude_statistics?: Statistic[];
  show_co2_level_warning?: {
    co2AlertSentOn: Date;
    co2AlertType: string;
  } | null;
};

export const BinInteriorCard = ({
  container_id,
  container_type,
  grain_status,
  only_temp,
  overlay_aeration,
  pellet_id,
  selectable_period,
  subtitle,
  telemetry_type,
  title,
  minHeight,
  maxHeight,
  trackPeriodChange,
  trackPlotToggle,
  grain_bin_location_timezone,
  exclude_statistics,
  show_co2_level_warning = null,
}: BinInteriorCardProps) => {
  const activeHubsWithColorContext = useContext(ActiveHubsWithColorContext);
  const activeHubsWithColor = activeHubsWithColorContext.activeHubsWithColor;

  const classes = useStyles();
  const dispatch = useDispatch();

  let include_statistics = statistics;
  if (exclude_statistics) {
    include_statistics = statistics.filter((statistic) => !exclude_statistics.includes(statistic));
  }

  const [state, setState] = useState<{
    statistic: Statistic | null;
    period: RelativeTimePeriod;
    tabIndex: number;
  }>({
    statistic: include_statistics[0],
    period: RelativeTimePeriod.week,
    tabIndex: 0,
  });
  const { statistic, period, tabIndex } = state;
  const [show_help, setShowHelp] = useState(false);

  const onSelectStatistic = useCallback(
    (next) => {
      const on = next !== statistic;
      if (trackPlotToggle) {
        trackPlotToggle({ statistic, on, data_source: telemetry_type });
      }
      setState((prevState) => ({
        ...prevState,
        statistic: on ? next : null,
        tabIndex: include_statistics.indexOf(next),
      }));
    },
    [statistic, telemetry_type, setState, trackPlotToggle]
  );
  const onSelectPeriod = useCallback(
    (period) => {
      if (trackPeriodChange) {
        trackPeriodChange({ period, statistic, data_source: telemetry_type });
      }
      setState((prevState) => ({ ...prevState, period }));
    },
    [telemetry_type, statistic, setState, trackPeriodChange]
  );

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newTabIndex: number) => {
      if (state.tabIndex === newTabIndex) {
        event.preventDefault();
        return false;
      }
      return onSelectStatistic(include_statistics[newTabIndex]);
    },
    [onSelectStatistic]
  );

  const legend_items = useMemo(() => {
    const items: LegendItem[] = [];
    activeHubsWithColor &&
      activeHubsWithColor.length > 0 &&
      activeHubsWithColor.forEach(({ nickname, color, hub_type }) => {
        const hubTypeLabel = hub_type === HubType.Headspace ? 'Headspace' : 'Plenum';
        items.push({ color, label: `${hubTypeLabel}-${nickname} ` });
      });

    if (overlay_aeration && statistic !== Statistic.co2_ppm) {
      items.push({ label: 'Fan Run', color: amber_faded_green, shape: 'square' });
    }

    return items;
  }, [telemetry_type, state, activeHubsWithColor]);

  const {
    temp_f = null,
    // temp_state = RangeState.unknown,
    humidity_rh = null,
    // humidity_state = RangeState.unknown,
    emc = null,
    // emc_state = RangeState.unknown,
  } = grain_status || {};

  const HistCard = TelemetryTypeCard[telemetry_type];
  const lastCo2AlertSentDaysText: string = useMemo(() => {
    if (show_co2_level_warning) {
      const lastCo2AlertSentOn: Date | null = show_co2_level_warning.co2AlertSentOn;
      if (lastCo2AlertSentOn) {
        const asOf = DateTime.local().setZone(grain_bin_location_timezone);
        const lastCo2AlertSentOnDt = DateTime.fromMillis(
          new Date(lastCo2AlertSentOn).getTime()
        ).setZone(grain_bin_location_timezone);
        const daysDiff = Math.round(asOf.diff(lastCo2AlertSentOnDt, ['days']).toObject().days);
        return daysDiff === 0 ? `Today` : `${daysDiff} Days Ago`;
      }
    }
    return '';
  }, [JSON.stringify(show_co2_level_warning)]);
  const showCo2WarningBadge = Boolean(show_co2_level_warning) && lastCo2AlertSentDaysText;

  return (
    <SummaryCard
      subtitle={subtitle}
      title={title}
      header_action={
        include_statistics.includes(Statistic.co2_ppm) && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {showCo2WarningBadge && (
              <Tooltip
                placement="bottom"
                title={<div style={{ fontSize: 12 }}>CO2 Alert</div>}
                arrow
              >
                <Chip
                  icon={<MediumRedAlertIcon />}
                  size="small"
                  variant="outlined"
                  label={lastCo2AlertSentDaysText}
                  style={{ border: `1px solid ${red_shade_3}`, padding: '0px 4px' }}
                />
              </Tooltip>
            )}
            <Button style={{ maxHeight: 26 }} onClick={() => setShowHelp(true)}>
              MORE INFO
            </Button>
          </div>
        )
      }
      style={{ minHeight: minHeight && minHeight, maxHeight: maxHeight && maxHeight }}
    >
      <div>
        {!only_temp && (
          <Tabs
            centered
            value={tabIndex}
            onChange={handleTabChange}
            variant="fullWidth"
            className={classes.tabsContainer}
          >
            {include_statistics.includes(Statistic.co2_ppm) && (
              <Tab
                classes={{
                  root: classes.tabRoot,
                  fullWidth: classes.labelContainer,
                }}
                fullWidth
                label={<TabLabel title="CO2 ppm" value={null} />}
              />
            )}
            {include_statistics.includes(Statistic.temp_f) && (
              <Tab
                classes={{
                  root: classes.tabRoot,
                  fullWidth: classes.labelContainer,
                }}
                fullWidth
                label={<TabLabel title="Temp" value={formatTemp(temp_f)} />}
              />
            )}
            {include_statistics.includes(Statistic.humidity_rh) && (
              <Tab
                classes={{
                  root: classes.tabRoot,
                  fullWidth: classes.labelContainer,
                }}
                fullWidth
                label={<TabLabel title="Humidity" value={formatHumidity(humidity_rh)} />}
              />
            )}
            {include_statistics.includes(Statistic.emc) && (
              <Tab
                classes={{
                  root: classes.tabRoot,
                  fullWidth: classes.labelContainer,
                }}
                fullWidth
                label={<TabLabel title="EMC" value={formatMoisture(emc)} />}
              />
            )}
          </Tabs>
        )}
        {telemetry_type !== 'headspace' && telemetry_type !== 'weather' && (
          <div style={{ margin: 10 }}>
            <Legend legend_items={legend_items} />
            {statistic === Statistic.co2_ppm && <Legend legend_items={OTHER_LEGEND_ITEMS} />}
          </div>
        )}
        <HistCard
          container_id={container_id}
          container_type={container_type}
          ranges={getRanges(grain_status, statistic)}
          period={period}
          pellet_id={pellet_id}
          onSelectPeriod={
            selectable_period && (telemetry_type === 'interior' || telemetry_type === 'headspace')
              ? onSelectPeriod
              : null
          }
          statistic={statistic}
          telemetry_type={telemetry_type}
          grain_bin_location_timezone={grain_bin_location_timezone}
          minHeight={minHeight}
          maxHeight={maxHeight}
        />
        {only_temp && (
          <Typography variant="h5" style={{ fontSize: 20, color: amber_dark_grey, width: '100%' }}>
            {/* Bottom margin to even out with the weather card*/}
            Current Temp: {formatTemp(temp_f)}
          </Typography>
        )}
      </div>
      {include_statistics.includes(Statistic.co2_ppm) && (
        <BaseDialog title="CO2 Help" open={show_help} handleClose={() => setShowHelp(false)}>
          <DialogContent>
            <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
              <Grid item xs={12}>
                <ul>
                  <li>
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      Grain spoilage and insect activity release large amounts of CO2 that will
                      accumulate in the headspace a few days after fans stop running.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      Normal CO2 level: 500-800ppm.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      Spoilage is likely starting to occur: &gt;1000ppm. Check the CO2 reading
                      weekly to monitor for persistent increase.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      Heavy spoilage detected: &gt;2000ppm. Take action. Confirm spoilage then
                      condition the grain or empty the bin.
                    </Typography>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </DialogContent>
        </BaseDialog>
      )}
    </SummaryCard>
  );
};
