import { DialogContent, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import {
  GrainSurfaceShapeType,
  GrainTicketActionType,
  GrainType,
  SensorGeneratedGrainTicket,
} from '../../../../../../core/src/api';
import { SimpleDialog } from '../../../util/SimpleDialog';
import { ApproveGrainTicketForm } from './ApproveGrainTicketForm';
import { UpdateGrainTicket } from './ManagePendingGrainTickets';

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: '36px 16px',
    [theme.breakpoints.only('xs')]: {
      padding: '36px 14px',
    },
  },
  paper: {
    margin: 20,
    width: '100%',
    overflowY: 'initial',
    minWidth: 335,
    maxWidth: 410,
    [theme.breakpoints.only('xs')]: {
      minWidth: 340,
    },
  },
}));

const ApproveGrainTicketDialog: React.FunctionComponent<{
  open: boolean;
  onClickClose: () => void;
  currentGrainTicket: SensorGeneratedGrainTicket;
  handleTicketApprove: (updatedGrainTicket: UpdateGrainTicket) => Promise<void>;
  grain_type: GrainType;
  grainSurfaceShape: GrainSurfaceShapeType;
  handleGrainSurfaceShapeChange: (
    event: React.MouseEvent<HTMLElement>,
    grainSurfaceShapeVal: GrainSurfaceShapeType
  ) => void;
  handleGrainActionChange: (action: GrainTicketActionType) => void;
}> = ({
  open,
  onClickClose,
  currentGrainTicket,
  handleTicketApprove,
  grain_type,
  grainSurfaceShape,
  handleGrainSurfaceShapeChange,
  handleGrainActionChange,
}) => {
  const classes = useStyles();
  return (
    <SimpleDialog open={open} handleClose={onClickClose} classes={{ paper: classes.paper }}>
      <DialogContent className={classes.dialogContent}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <ApproveGrainTicketForm
              grainTicket={currentGrainTicket}
              handleTicketApprove={handleTicketApprove}
              grain_type={grain_type}
              closeForm={onClickClose}
              grainSurfaceShape={grainSurfaceShape}
              handleGrainSurfaceShapeChange={handleGrainSurfaceShapeChange}
              handleGrainActionChange={handleGrainActionChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </SimpleDialog>
  );
};

export default ApproveGrainTicketDialog;
