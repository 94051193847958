import isValidCoverNumber from './is-valid-cover-number';

const getPelletPosition = (position: number): string => {
  switch (position) {
    case 0: {
      return 'Low';
    }
    case 1: {
      return 'Mid';
    }
    case 2: {
      return 'Top';
    }
    default: {
      return '';
    }
  }
};

export default (cover_no: number) => {
  if (isValidCoverNumber(cover_no)) {
    const scn = cover_no.toString();
    return `#${scn[0]}${Number(scn[1]) === 1 ? 'Stbd' : 'Pt'}${getPelletPosition(Number(scn[2]))}`;
  }
  return cover_no;
};
