import { makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useContext } from 'react';
import { OperationsViewInfoContext } from '../../../../core/src/contexts';
import { amber_dark_grey } from '../../../../core/src/style';
import {
  formatNumber,
  getHasUserSelectedKilogramUnit,
  UnitsConverter,
} from '../../../../core/src/util';
import SkeletonLoader from '../util/SkeletonLoader';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    color: 'red',
    display: 'flex',
    flexGrow: 1,
  },
  grainInfoHeading: {
    marginBottom: '0.8rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: 0,
    },
  },
}));

const OperationsViewInfo = ({ width, height }: { width?: number; height?: number }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const { info } = useContext(OperationsViewInfoContext);
  console.log('myinfo', { info });
  const hasOpsViewTableDashboard = location.pathname === '/grain/operations';
  const hasUserSelectedKilogramUnit = getHasUserSelectedKilogramUnit();
  const allSitesGrainLevelValue = info
    ? hasUserSelectedKilogramUnit
      ? `${formatNumber(
          Math.round(UnitsConverter.convertBuToMetricTon(info.allSitesGrainLevelCount))
        )} ton total`
      : `${formatNumber(Math.round(info.allSitesGrainLevelCount))} bu total`
    : null;

  return info ? (
    <Typography
      className={classes.grainInfoHeading}
      variant="h5"
      style={{
        textAlign: isWideScreen ? 'start' : 'center',
        color: amber_dark_grey,
        fontWeight: 400,
      }}
    >
      {info.sitesCount} Sites | {info.binsCount} Grain Bins | {info.fansCount} Fans |{' '}
      {allSitesGrainLevelValue !== null && allSitesGrainLevelValue}
    </Typography>
  ) : hasOpsViewTableDashboard ? (
    <SkeletonLoader width={width || 370} height={height || 20} />
  ) : null;
};

export { OperationsViewInfo };
