import { datadogLogs } from '@datadog/browser-logs';
import { ContainerTypeLegacy } from 'core/src';
import { DateTime } from 'luxon';
import React from 'react';
import {
  ContainerType,
  GetGrainBinContainerQuery,
  GrainBinTelemetryFragmentFragment,
  GrainContainer,
  GrainContainerHubLinksFragmentFragment,
  PsychrometricRangeFragmentFragment,
  withGetGrainBinContainerHoc,
} from '../../../api';
import { CenteredSpinner } from '../../spinner';
import { FanControlNavigationOption } from '../FanControlNavigationOption';
import { RecommendedOption } from '../RecommendationOptionValue';
import { GrainCondiFormSubmittedValuesType } from './FanControls';
import { FanSetMultiSchedule } from './FanSetMultiSchedule';

const getTimeFromDate = (dt: DateTime) =>
  `${dt.hour}:${`${dt.minute < 10 ? '0' : ''}${dt.minute}`}`;

function selectRecommendationWindows(
  navigation: FanControlNavigationOption,
  grain_container: { __typename: 'GrainContainer' } & {
    telemetry: { __typename: 'GrainTelemetry' } & GrainBinTelemetryFragmentFragment;
  } & GrainContainerHubLinksFragmentFragment
): PsychrometricRangeFragmentFragment[] {
  datadogLogs.logger.info('grain_container value in selectRecommendationWindows', {
    grain_container_value: grain_container,
  });

  let temp_ranges: any[] = [];
  let emc_ranges: any[] = [];
  let emc_reconditioning_ranges: any[] = [];

  if (grain_container.telemetry.temporary_recommendation_windows) {
    const {
      recommendation_windows,
      recommendation_type,
    } = grain_container.telemetry.temporary_recommendation_windows;
    if (recommendation_windows && recommendation_type) {
      temp_ranges = recommendation_windows.temp_ranges || [];
      emc_ranges = recommendation_windows.emc_ranges || [];
      emc_reconditioning_ranges = recommendation_windows.emc_reconditioning_ranges || [];
    } else {
      console.log('recommendation_windows or recommendation_type is null', {
        recommendation_windows,
        recommendation_type,
      });
    }
  }

  // const {
  //   telemetry: {
  //     temporary_recommendation_windows: {
  //       // @ts-ignore Property `recommendation_windows` does not exist on type (2339)
  //       recommendation_windows: {
  //         // @ts-ignore Property `temp_ranges` does not exist on type (2339)
  //         temp_ranges = [],
  //         // @ts-ignore Property `temp_ranges` does not exist on type (2339)
  //         emc_ranges = [],
  //         // @ts-ignore Property `temp_ranges` does not exist on type (2339)
  //         emc_reconditioning_ranges = [],
  //       },
  //     },
  //   },
  // } = grain_container;

  switch (navigation.recommendationOptionValue.option) {
    case RecommendedOption.RECONDITIONING:
      return emc_reconditioning_ranges;
    case RecommendedOption.DRYING:
      return emc_ranges;
    case RecommendedOption.COOLING:
    default:
      return temp_ranges;
  }
}

export const FanSetMultiRecommendedSchedule = withGetGrainBinContainerHoc(
  ({
    grain_container,
    loading,
    grain_bin_id,
    container_id,
    container_type,
    container_type2,
    storage_period,
    navigation,
    setNavigation,
    isRecommendingWindows = false,
    setShowFanGuidanceExtPrompt,
    showRecomendationsFlow,
    grainCondiFormSubmittedValues,
    ...props
  }: Pick<GetGrainBinContainerQuery, 'grain_container'> & {
    loading?: boolean;
    grain_bin_id: number;
    container_id: number;
    container_type: ContainerType;
    container_type2: ContainerTypeLegacy;
    storage_period?: {
      grain_bin_storage_cycle_id: number;
    } | null;
    navigation: FanControlNavigationOption;
    setNavigation: (navigation: FanControlNavigationOption) => void;
    isRecommendingWindows: boolean;
    setShowFanGuidanceExtPrompt?: (show_fan_guidance_ext) => void;
    showRecomendationsFlow: () => void;
    grainCondiFormSubmittedValues: GrainCondiFormSubmittedValuesType;
  }) => {
    if (loading) {
      return <CenteredSpinner fadeIn="none" />;
    }

    console.log('grain_container value inside FanSetMultiRecommendedSchedule', grain_container);

    const {
      telemetry: {
        // @ts-ignore Property `temp_ranges` does not exist on type (2339)
        weather_forecast: { hourly_forecast = [] },
      },
    } = grain_container;

    const results =
      isRecommendingWindows && grain_container
        ? selectRecommendationWindows(navigation, grain_container).map(({ start, end }) => {
            return {
              start_date: DateTime.fromMillis(start).toFormat('MM/dd/yyyy'),
              start_time: getTimeFromDate(DateTime.fromMillis(start)),
              end_date: DateTime.fromMillis(end).toFormat('MM/dd/yyyy'),
              end_time: getTimeFromDate(DateTime.fromMillis(end)),
            };
          })
        : [];

    console.log(
      'Psychrometric data output: ',
      grain_container.telemetry.weather_forecast,
      grain_container.telemetry.recommendation_windows
    );

    return (
      <FanSetMultiSchedule
        {...props}
        grain_bin_id={grain_bin_id}
        container_id={container_id}
        container_type={container_type}
        container_type2={container_type2}
        storage_period={storage_period}
        navigation={navigation}
        setNavigation={setNavigation}
        recommendedWindows={results}
        isRecommendingWindows={isRecommendingWindows}
        hourlyForecasts={hourly_forecast}
        setShowFanGuidanceExtPrompt={setShowFanGuidanceExtPrompt}
        showRecomendationsFlow={showRecomendationsFlow}
        grainCondiFormSubmittedValues={grainCondiFormSubmittedValues}
      />
    );
  }
);
