import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AskGrainConditionsForm, ContainerTypeLegacy } from '../../../../../core/src';
import { pollFanControllerState, setFanCardView, setIsFanStopTriggered } from '../../../action';
import {
  ContainerType,
  FanControllerRunWindow,
  FanControllerStateValueNext,
  FanRunWindowRecommendedOption,
  FanSettingsFragmentFragment,
  GrainBinFragmentFragment,
  UserRole,
  withSetGrainContainerAerationScheduleHoc,
  WithSetGrainContainerAerationScheduleHocChildProps,
  withStopGrainContainerAerationHoc,
  WithStopGrainContainerAerationHocChildProps,
  withUpdateGrainBinHoc,
  WithUpdateGrainBinHocChildProps,
} from '../../../api';
import { CenteredSpinner } from '../../spinner';
import {
  createNavigationType,
  createNavigationTypeFromNavOption,
  createNavigationTypeFromOptionString,
  createShowFanStatusNavigation,
  FanControlNavigationOption,
  FanControlsNavigation,
} from '../FanControlNavigationOption';
import { EditScheduleDialog } from './EditScheduleDialog';
import { FanControllerErrorDisplay, FanControllerWithError } from './FanControllerErrorDisplay';
import { FanSchedule } from './FanSchedule';
import { FanSetMultiRecommendedSchedule } from './FanSetMultiRecommendedSchedule';
import { FanStatus } from './FanStatus';

const useStyles = makeStyles((theme: Theme) => ({
  top_margin: {
    marginTop: 30,
  },
  spinner: { height: 254, display: 'flex', alignItems: 'center', justifyContent: 'center' },
}));

type RawFanControlsProps = WithUpdateGrainBinHocChildProps &
  WithStopGrainContainerAerationHocChildProps &
  WithSetGrainContainerAerationScheduleHocChildProps & {
    grain_bin_id: number;
    has_override: boolean;
    any_fans_running: boolean;
    mapped_errors: FanControllerWithError[] | null;
    container_type2: ContainerTypeLegacy;
    storage_period?: {
      grain_bin_storage_cycle_id: number;
    } | null;
    grain_bin_fan_settings: FanSettingsFragmentFragment | null;
    loading: boolean;
    grain_bin: GrainBinFragmentFragment | null;
    user_id: number;
    current_account_id: number;
    refechGrainBin: () => Promise<any>;
    show_fan_guidance_ext_prompt: boolean;
    setShowFanGuidanceExtPrompt: (show_fan_guidance_ext) => void;
    viewerRole: UserRole;
    fullBinCfmPerBu: number | null;
    cfmPerBu: number | null;
    goToManageGrainTickets: () => void;
  };

export type FanStatusValues = {
  status: FanControllerStateValueNext;
  id: number;
  fan_name: string;
};

export type GrainCondiFormSubmittedValuesType = null | {
  current_grain_emc: number;
  current_grain_temp: number;
  recommendation_type: string;
  target_grain_emc: number | undefined | null;
};

export const FanControls = withUpdateGrainBinHoc(
  withStopGrainContainerAerationHoc(
    withSetGrainContainerAerationScheduleHoc(
      ({
        grain_bin_id,
        grain_bin,
        refechGrainBin,
        grain_bin_fan_settings,
        stopGrainContainerAeration,
        setGrainContainerAerationSchedule,
        updateGrainBin,
        loading,
        has_override,
        any_fans_running,
        mapped_errors,
        container_type2,
        storage_period,
        user_id,
        current_account_id,
        show_fan_guidance_ext_prompt,
        setShowFanGuidanceExtPrompt,
        viewerRole,
        cfmPerBu,
        fullBinCfmPerBu,
        goToManageGrainTickets,
      }: RawFanControlsProps) => {
        const classes = useStyles();
        const dispatch = useDispatch();
        const initialNavigationType: FanControlsNavigation | undefined = useSelector(
          ({ global_state: { initialFanControlCardNavigationType } }) =>
            initialFanControlCardNavigationType
        );
        const hasUserReadOnlyAccess = Boolean(viewerRole === UserRole.ReadOnly);
        const hasManualMode = any_fans_running && has_override;
        const isLoading = loading;
        const defaultNav = createShowFanStatusNavigation();
        // @ts-ignore
        const [navigation, setNavigation] = useState(defaultNav);

        const [grainCondiFormSubmittedValues, setGrainCondiFormSubmittedValues] = useState<
          GrainCondiFormSubmittedValuesType
        >(null);
        const [fan_updating, setFanUpdating] = useState(false);
        const [showRestartButton, setShowRestartButton] = useState<boolean>(false);
        const [hide_restart_delay, setHideRestartDelay] = useState(false);
        const dispatchPollFanControllerState = useCallback(
          (args: {
            fan_controller_id: number;
            container_id: number;
            container_type: ContainerTypeLegacy;
            storage_period?: {
              grain_bin_storage_cycle_id: number;
            } | null;
          }) => dispatch(pollFanControllerState(args)),
          [dispatch]
        );
        const [stop_sent, setStopSent] = useState<Date | null>(null);
        const showStopSent = useCallback(() => {
          setStopSent(new Date());
        }, [setStopSent]);
        const [restart_sent, setRestartSent] = useState<Date | null>(null);
        const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
        const onClickEdit = () => setShowEditDialog(true);
        const closeEditDialog = () => setShowEditDialog(false);
        const showRecomendationsFlow = () =>
          setNavigation(
            createNavigationTypeFromNavOption(
              FanControlsNavigation.AskCurrentGrainConditions,
              navigation
            )
          );

        const showRestartSent = useCallback(() => {
          setRestartSent(new Date());
        }, [setRestartSent]);

        const fan_statuses = useMemo(() => {
          const grain_bin_fan_controllers = grain_bin_fan_settings
            ? grain_bin_fan_settings.grain_bin.fan_controllers
            : [];

          const fan_statuses: FanStatusValues[] = grain_bin_fan_controllers.map((ctrl, ix) => {
            const status =
              ctrl && ctrl.fan_controller && ctrl.fan_controller.state_next
                ? ctrl.fan_controller.state_next.value_next
                : FanControllerStateValueNext.Offline;
            return {
              status,
              id: ctrl.fan_controller_id_next,
              fan_name: ctrl.alias || `Fan ${ix}`,
            };
          });

          return fan_statuses;
        }, [grain_bin_fan_settings]);

        // make sure schedule is current
        const [run_window, aeration_schedule, aeration_schedule_type = null] = useMemo(() => {
          if (
            grain_bin &&
            grain_bin.container &&
            grain_bin.container.aeration_schedule &&
            grain_bin.container.aeration_schedule.length
          ) {
            // sort date newest to oldest
            return [
              [...grain_bin.container.aeration_schedule].sort(
                (a, b) => new Date(a.start_epoch).getTime() - new Date(b.start_epoch).getTime()
              )[0] || null,
              grain_bin.container.aeration_schedule.filter(
                ({ end_epoch }) => new Date(end_epoch).getTime() > new Date().getTime()
              ),
              grain_bin.container.aeration_schedule_type,
            ];
          }
          return [null, [] as FanControllerRunWindow[]];
        }, [grain_bin]);

        const stopFans = useCallback(async () => {
          try {
            setFanUpdating(true);
            dispatch(setIsFanStopTriggered({ isFanStopTriggered: true }));
            setStopSent(new Date());
            await stopGrainContainerAeration({
              container_id: grain_bin_id,
              container_type: ContainerType.Bin,
            });

            if (grain_bin && grain_bin.opt_in_fan_guidance && grain_bin.enable_fan_guidance) {
              await Promise.all([
                setGrainContainerAerationSchedule({
                  container_id: grain_bin_id,
                  container_type: ContainerType.Bin,
                  run_windows: [],
                  start_recommendation_type: FanRunWindowRecommendedOption.Custom,
                  end_recommendation_type: FanRunWindowRecommendedOption.Custom,
                }),
                updateGrainBin({
                  grain_bin_id,
                  enable_fan_guidance: false,
                }),
              ]);
            }

            // dispatchPollFanState
            await dispatchPollFanStateForAllIds(grain_bin_fan_settings);
            await refechGrainBin();
          } catch (err) {
            console.error(err);
          } finally {
            setFanUpdating(false);
          }
        }, [grain_bin_id, grain_bin_fan_settings, grain_bin]);

        const pollAllFanControllerState = async () => {
          // dispatchPollFanState
          try {
            await dispatchPollFanStateForAllIds(grain_bin_fan_settings);
          } catch (error) {
            console.error(error);
          }
        };

        const cancelAllSchedule = useCallback(async () => {
          try {
            setFanUpdating(true);
            await setGrainContainerAerationSchedule({
              container_id: grain_bin_id,
              container_type: ContainerType.Bin,
              run_windows: [],
              start_recommendation_type: FanRunWindowRecommendedOption.Custom,
              end_recommendation_type: FanRunWindowRecommendedOption.Custom,
            }),
              // dispatchPollFanState
              await dispatchPollFanStateForAllIds(grain_bin_fan_settings);
            await refechGrainBin();
          } catch (err) {
            console.error(err);
          } finally {
            setFanUpdating(false);
          }
        }, [grain_bin_id, grain_bin_fan_settings, grain_bin]);

        const cancelNext = useCallback(async () => {
          try {
            setFanUpdating(true);
            // clear current run window
            // await setGrainBinFanSettings({ grain_bin_id, clear_run_window: true });
            dispatch(setIsFanStopTriggered({ isFanStopTriggered: false }));

            await setGrainContainerAerationSchedule({
              container_id: grain_bin_id,
              container_type: ContainerType.Bin,
              start_recommendation_type: FanRunWindowRecommendedOption.Custom,
              end_recommendation_type: FanRunWindowRecommendedOption.Custom,
              run_windows:
                grain_bin && grain_bin.opt_in_fan_guidance && grain_bin.enable_fan_guidance
                  ? []
                  : aeration_schedule
                      .sort(
                        (a, b) =>
                          new Date(a.start_epoch).getTime() - new Date(b.start_epoch).getTime()
                      )
                      // filter out __typename
                      .map(({ start_epoch, end_epoch }) => ({ start_epoch, end_epoch }))
                      .slice(1),
            });

            if (grain_bin && grain_bin.opt_in_fan_guidance && grain_bin.enable_fan_guidance) {
              await updateGrainBin({
                grain_bin_id,
                enable_fan_guidance: false,
              });
            }

            // dispatchPollFanState
            await dispatchPollFanStateForAllIds(grain_bin_fan_settings);
            await refechGrainBin();
          } catch (err) {
            console.error(err);
          } finally {
            setFanUpdating(false);
          }
        }, [grain_bin_id, grain_bin_fan_settings, grain_bin]);

        const restartFans = useCallback(async () => {
          if (
            grain_bin &&
            grain_bin.container &&
            grain_bin.container.aeration_schedule &&
            grain_bin.container.aeration_schedule.length
          ) {
            setFanUpdating(true);
            setRestartSent(new Date());
            setHideRestartDelay(true);
            dispatch(setIsFanStopTriggered({ isFanStopTriggered: false }));
            // resend current run window
            // await setGrainBinFanSettings({ grain_bin_id, run_window: { start_epoch, end_epoch } });
            await setGrainContainerAerationSchedule({
              container_id: grain_bin_id,
              container_type: ContainerType.Bin,
              start_recommendation_type: FanRunWindowRecommendedOption.Custom,
              end_recommendation_type: FanRunWindowRecommendedOption.Custom,
              run_windows: grain_bin.container.aeration_schedule
                // filter out __typename
                .map(({ start_epoch, end_epoch }) => ({ start_epoch, end_epoch })),
            });
            // dispatchPollFanState
            await dispatchPollFanStateForAllIds(grain_bin_fan_settings);
            setFanUpdating(false);

            // hide the restart button for 30s after clicking restart
            await setTimeout(() => setHideRestartDelay(false), 30000);
          }
        }, [grain_bin_id, grain_bin_fan_settings, run_window]);

        const grain_bin_fan_controllers = grain_bin_fan_settings
          ? grain_bin_fan_settings.grain_bin.fan_controllers
          : [];

        const dispatchPollFanStateForAllIds = (grain_bin_fan_settings) => {
          if (grain_bin_fan_settings) {
            grain_bin_fan_settings.grain_bin.fan_controllers.forEach(
              ({ fan_controller: { fan_controller_id_next } }) =>
                dispatchPollFanControllerState({
                  storage_period,
                  container_type: container_type2,
                  fan_controller_id: fan_controller_id_next,
                  container_id: grain_bin_id,
                })
            );
          }
        };

        // reset initialFanControlCardNavigationType
        useEffect(() => {
          if (initialNavigationType) {
            dispatch(
              setFanCardView({
                initialFanControlCardNavigationType: undefined,
              })
            );
          }
        }, [initialNavigationType]);

        //
        // Ensure that the navigation has the connected grain_bin_id, which indicates the grain_bin
        // has been fully created.
        //
        if (
          !navigation.grain_bin_id &&
          grain_bin &&
          grain_bin.recommendation_type &&
          grain_bin.grain_bin_id
        ) {
          setNavigation(
            createNavigationTypeFromOptionString(
              initialNavigationType ? initialNavigationType : defaultNav.navigationType,
              grain_bin.recommendation_type,
              grain_bin.grain_bin_id
            )
          );
          return <CenteredSpinner className={classes.spinner} fadeIn="none" />;
        }

        if (isLoading) {
          return <CenteredSpinner className={classes.spinner} fadeIn="none" />;
        }

        const renderComponent = (navigation: FanControlNavigationOption) => {
          switch (navigation.navigationType) {
            case FanControlsNavigation.ShowFanStatus: {
              return (
                <Grid container direction="column" justify="center">
                  <FanStatus
                    grain_bin_fan_settings={grain_bin_fan_settings}
                    fan_updating={fan_updating}
                    scheduled_start={(run_window && run_window.start_epoch) || null}
                    scheduled_end={(run_window && run_window.end_epoch) || null}
                    hasManualMode={hasManualMode}
                    showRestartButton={showRestartButton}
                  />
                  {mapped_errors && (
                    <FanControllerErrorDisplay
                      fan_controllers_with_errors={mapped_errors}
                      fan_count={grain_bin_fan_controllers.length}
                      stopFans={stopFans}
                      any_running={any_fans_running}
                      stop_sent={stop_sent}
                      cancelNextRun={cancelNext}
                      scheduled_start={(run_window && run_window.start_epoch) || null}
                      scheduled_end={(run_window && run_window.end_epoch) || null}
                      grain_bin_id={grain_bin_id}
                      aeration_schedule={aeration_schedule}
                      aeration_schedule_type={aeration_schedule_type}
                      setNavigation={setNavigation}
                      navigation={navigation}
                      recommendation_type={(grain_bin && grain_bin.recommendation_type) || null}
                      opt_in_fan_guidance={(grain_bin && grain_bin.opt_in_fan_guidance) || false}
                      enable_fan_guidance={(grain_bin && grain_bin.enable_fan_guidance) || false}
                      fan_guidance_start_date={
                        (grain_bin && grain_bin.fan_guidance_start_date) || null
                      }
                      fan_guidance_end_date={(grain_bin && grain_bin.fan_guidance_end_date) || null}
                      show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
                      setShowFanGuidanceExtPrompt={setShowFanGuidanceExtPrompt}
                      hasUserReadOnlyAccess={hasUserReadOnlyAccess}
                      hasManualMode={hasManualMode}
                      onClickEdit={
                        grain_bin && grain_bin.opt_in_fan_guidance && grain_bin.enable_fan_guidance
                          ? showRecomendationsFlow
                          : onClickEdit
                      }
                      cancelAllSchedule={cancelAllSchedule}
                    />
                  )}
                  {!mapped_errors &&
                    (fan_updating ? (
                      <CenteredSpinner className={classes.spinner} fadeIn="none" />
                    ) : (
                      <FanSchedule
                        grain_bin_id={grain_bin_id}
                        any_running={any_fans_running}
                        has_override={has_override}
                        fan_count={grain_bin_fan_controllers.length}
                        scheduled_start={(run_window && run_window.start_epoch) || null}
                        scheduled_end={(run_window && run_window.end_epoch) || null}
                        aeration_schedule={aeration_schedule}
                        aeration_schedule_type={aeration_schedule_type}
                        hide_restart_delay={hide_restart_delay}
                        pollAllFanControllerState={pollAllFanControllerState}
                        stopFans={stopFans}
                        cancelNextRun={cancelNext}
                        cancelAllSchedule={cancelAllSchedule}
                        restartFans={restartFans}
                        navigation={navigation}
                        setNavigation={setNavigation}
                        stop_sent={stop_sent}
                        restart_sent={restart_sent}
                        fan_statuses={fan_statuses}
                        opt_in_fan_guidance={(grain_bin && grain_bin.opt_in_fan_guidance) || false}
                        enable_fan_guidance={(grain_bin && grain_bin.enable_fan_guidance) || false}
                        fan_guidance_start_date={
                          (grain_bin && grain_bin.fan_guidance_start_date) || null
                        }
                        fan_guidance_end_date={
                          (grain_bin && grain_bin.fan_guidance_end_date) || null
                        }
                        recommendation_type={(grain_bin && grain_bin.recommendation_type) || null}
                        refechGrainBin={refechGrainBin}
                        user_id={user_id}
                        current_account_id={current_account_id}
                        show_fan_guidance_ext_prompt={show_fan_guidance_ext_prompt}
                        setShowFanGuidanceExtPrompt={setShowFanGuidanceExtPrompt}
                        onClickEdit={
                          grain_bin &&
                          grain_bin.opt_in_fan_guidance &&
                          grain_bin.enable_fan_guidance
                            ? showRecomendationsFlow
                            : onClickEdit
                        }
                        hasManualMode={hasManualMode}
                        showRestartButton={showRestartButton}
                        setShowRestartButton={setShowRestartButton}
                      />
                    ))}
                </Grid>
              );
            }
            case FanControlsNavigation.ShowManualRunWindows: {
              return (
                <FanSetMultiRecommendedSchedule
                  grain_bin_id={grain_bin_id}
                  container_id={grain_bin_id}
                  container_type={ContainerType.Bin}
                  container_type2={container_type2}
                  storage_period={storage_period}
                  navigation={navigation}
                  setNavigation={(nav) =>
                    setNavigation(
                      createNavigationType(
                        FanControlsNavigation.ShowFanStatus,
                        nav.recommendationOptionValue,
                        nav.grain_bin_id
                      )
                    )
                  }
                  isRecommendingWindows={false}
                  showRecomendationsFlow={showRecomendationsFlow}
                  grainCondiFormSubmittedValues={grainCondiFormSubmittedValues}
                />
              );
            }
            case FanControlsNavigation.ShowRecommendedRunWindows: {
              return (
                <FanSetMultiRecommendedSchedule
                  grain_bin_id={grain_bin_id}
                  container_id={grain_bin_id}
                  container_type={ContainerType.Bin}
                  container_type2={container_type2}
                  storage_period={storage_period}
                  navigation={navigation}
                  setNavigation={(nav) =>
                    setNavigation(
                      createNavigationType(
                        FanControlsNavigation.ShowFanStatus,
                        nav.recommendationOptionValue,
                        grain_bin_id
                      )
                    )
                  }
                  isRecommendingWindows={true}
                  setShowFanGuidanceExtPrompt={setShowFanGuidanceExtPrompt}
                  grainCondiFormSubmittedValues={grainCondiFormSubmittedValues}
                  showRecomendationsFlow={showRecomendationsFlow}
                />
              );
            }
            case FanControlsNavigation.AskCurrentGrainConditions: {
              return (
                <AskGrainConditionsForm
                  grain_bin={grain_bin!}
                  grainCondiFormSubmittedValues={grainCondiFormSubmittedValues}
                  setGrainCondiFormSubmittedValues={setGrainCondiFormSubmittedValues}
                  navigation={navigation}
                  setRecommendation={(nav) =>
                    setNavigation(
                      createNavigationType(
                        FanControlsNavigation.AskCurrentGrainConditions,
                        nav.recommendationOptionValue,
                        grain_bin_id
                      )
                    )
                  }
                  setNavigation={(nav) =>
                    setNavigation(
                      createNavigationType(
                        FanControlsNavigation.ShowRecommendedRunWindows,
                        nav.recommendationOptionValue,
                        grain_bin_id
                      )
                    )
                  }
                  onCancel={() =>
                    setNavigation(
                      createNavigationType(
                        FanControlsNavigation.ShowFanStatus,
                        navigation.recommendationOptionValue,
                        grain_bin_id
                      )
                    )
                  }
                  cfmPerBu={cfmPerBu}
                  goToManageGrainTickets={goToManageGrainTickets}
                  fullBinCfmPerBu={fullBinCfmPerBu}
                />
              );
            }
          }
        };

        return (
          <>
            {renderComponent(navigation)}
            <EditScheduleDialog
              open={showEditDialog}
              onClickClose={closeEditDialog}
              startSettingNavigation={() => {
                closeEditDialog();
                setNavigation(
                  createNavigationTypeFromNavOption(
                    FanControlsNavigation.ShowManualRunWindows,
                    navigation
                  )
                );
              }}
              addRecommendedWindow={() => {
                closeEditDialog();
                showRecomendationsFlow();
              }}
            />
          </>
        );
      }
    )
  )
);
