import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { light_gray2 } from '../../../../core/src/style';
import { ExpandInfoAccordion } from '../util';

const useStyles = makeStyles(() => ({
  paraConatiner: {
    paddingBottom: 6,
    paddingTop: 6,
    backgroundColor: light_gray2,
    borderRadius: 8,
  },
  subText: {
    marginTop: '0',
    marginBottom: '0',
    fontSize: 13,
  },
  summaryText: {
    fontSize: 13,
    lineHeight: '20px',
    letterSpacing: 0.1,
  },
}));

const RecommSchedulingInfo = () => {
  const classes = useStyles();
  const items = [
    {
      id: 'panel1',
      summary: <Typography className={classes.summaryText}>What does this mean?</Typography>,
      details: (
        <>
          <p className={classes.subText}>
            To access the algorithm-based recommendations we need your most recent grain conditions.
          </p>
        </>
      ),
    },
    {
      id: 'panel2',
      summary: <Typography className={classes.summaryText}>What if I don’t have them?</Typography>,
      details: (
        <>
          <p className={classes.subText}>
            Enter the moisture from either your latest hand sample or what it was out of the
            combine. For grain temp, reference the last headspace temperature while your fan was
            running for any period greater than 5 hours. After some time the algorithm with
            automatically predict your grain temperature.
          </p>
        </>
      ),
    },
    {
      id: 'panel3',
      summary: (
        <Typography className={classes.summaryText}>
          What are these recommendations based on?
        </Typography>
      ),
      details: (
        <>
          <p className={classes.subText}>
            We run a prediction model based on your inputted bin setup, grain type, current grain
            conditions, and the future weather forecast, and psychrometric charts.
          </p>
        </>
      ),
    },
  ];
  return (
    <div className={classes.paraConatiner}>
      <ExpandInfoAccordion items={items} />
    </div>
  );
};

export default RecommSchedulingInfo;
