import { Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useState } from 'react';
import { GetProps, useDispatch } from 'react-redux';
import * as yup from 'yup';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { createSession } from '../../action';
import {
  ViewerFragmentFragment,
  withSetUserPasswordHoc,
  WithSetUserPasswordHocChildProps,
  withUpdateUserPasswordHoc,
  WithUpdateUserPasswordHocChildProps,
} from '../../api';
import { BaseForm, FormikWrapper, FormikWrapperHandlerProps } from '../util/form2/BaseForm';
import { ButtonSubmit } from '../util/form2/Button';
import { ErrorBox } from '../util/form2/ErrorBox';
import { TextField } from '../util/form2/TextField';

const isViewer = (arg): arg is ViewerFragmentFragment => Boolean(arg.user);

const useStyles = makeStyles({
  centered: {
    textAlign: 'center',
  },
  grid: {
    minWidth: 250,
    maxWidth: 250,
  },
});
type Values = {
  user_id: number | null;
  token: string;
  old_password: string;
  new_password: string;
  verify_password: string;
};
const validationSchema = yup.object().shape({
  user_id: yup
    .number()
    .typeError('A number is required')
    .integer()
    .nullable(true),
  old_password: yup
    .string()
    .label('Old Password')
    .when('user_id', (user_id, schema) => (user_id ? schema.required() : schema)),
  new_password: yup
    .string()
    .label('New Password')
    .required()
    .when('old_password', (old, schema) =>
      schema.test({
        message: 'Password must not be the same as the old password',
        test: (value) => old !== value,
      })
    ),
  verify_password: yup
    .string()
    .label('Verify Password')
    .required()
    .when('new_password', (password, schema) =>
      schema.test({
        message: 'Passwords do not match',
        test: (value) => password === value,
      })
    ),
});
export const UserPasswordFormBase = ({
  token,
  user_id,
  hide_title,
  updateUserPassword,
  setUserPassword,
  onSubmitSuccess,
  ...props
}: FormikWrapperHandlerProps<Values, any | void> &
  WithSetUserPasswordHocChildProps &
  WithUpdateUserPasswordHocChildProps &
  (
    | { token: string; user_id: null; hide_title?: boolean }
    | { token: null; user_id: number; hide_title?: boolean }) & {
    rootContainerStyles?: object;
    show_back_button?: boolean;
    goBack?: () => any;
  }) => {
  const classes = useStyles();
  const [succeeded, setSucceeded] = useState(false);
  const submitSuccessCallback = useCallback(
    (result, formikHelpers) => {
      setSucceeded(true);
      onSubmitSuccess && onSubmitSuccess(result, formikHelpers);
    },
    [onSubmitSuccess]
  );
  return (
    <FormikWrapper<Values, any>
      {...props}
      validationSchema={validationSchema}
      initialValues={{
        user_id,
        token: token || '',
        old_password: '',
        new_password: '',
        verify_password: '',
      }}
      onSubmit={(values) => {
        setSucceeded(false);
        const { user_id, token } = values;
        const { old_password, new_password } = validationSchema.validateSync(values);
        if (user_id) {
          return updateUserPassword({ user_id, old_password, new_password });
        }
        return setUserPassword({ token, password: new_password });
      }}
      onSubmitSuccess={submitSuccessCallback}
      render={() => {
        return (
          <BaseForm
            submitting_message="Updating user password..."
            style={props.rootContainerStyles ? props.rootContainerStyles : undefined}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              alignContent="flex-start"
              justify="center"
              spacing={2}
              className={classes.grid}
            >
              {!hide_title && (
                <Grid item xs={12} style={{ display: 'flex' }}>
                  {props.show_back_button && (
                    <IconButton style={{ padding: 0, marginRight: 20 }} onClick={props.goBack}>
                      <ArrowBackIcon />
                    </IconButton>
                  )}

                  <Typography variant="h5" className={classes.centered}>
                    {token ? 'Reset User Password' : 'User Password'}
                  </Typography>
                </Grid>
              )}
              {succeeded ? (
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.centered}>
                    Your password has been updated
                  </Typography>
                </Grid>
              ) : (
                <>
                  {user_id && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Old Password"
                        name="old_password"
                        placeholder="Current Password"
                        type="password"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="New Password"
                      name="new_password"
                      placeholder="New Password"
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Verify New Password"
                      name="verify_password"
                      placeholder="Verify New Password"
                      type="password"
                    />
                  </Grid>
                  <ErrorBox />
                  <Grid item xs={12} className={classes.centered}>
                    <ButtonSubmit />
                  </Grid>
                </>
              )}
            </Grid>
          </BaseForm>
        );
      }}
    />
  );
};

export const UserPasswordForm = withSetUserPasswordHoc(
  withUpdateUserPasswordHoc(
    ({ onSubmitSuccess, ...props }: GetProps<typeof UserPasswordFormBase>) => {
      const dispatch = useDispatch();
      return (
        <UserPasswordFormBase
          {...props}
          onSubmitSuccess={(result, formikHelpers) => {
            if (isViewer(result)) {
              dispatch(createSession({ viewer: result, propagate: true }));
            }
            onSubmitSuccess && onSubmitSuccess(result, formikHelpers);
          }}
        />
      );
    }
  )
);
