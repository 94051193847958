import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { withResetPasswordHoc, WithResetPasswordHocChildProps } from '../../api';
import { BaseForm, FormikWrapper } from '../util/form2/BaseForm';
import { ButtonSubmit } from '../util/form2/Button';
import { ErrorBox } from '../util/form2/ErrorBox';
import { TextField } from '../util/form2/TextField';

const useStyles = makeStyles({
  centered: { textAlign: 'center' },
  root: { paddingTop: 0 },
});

type Values = {
  email_address: string;
  general: string;
};

const validationSchema = yup.object().shape({
  email_address: yup
    .string()
    .label('Email Address')
    .email('Must be a valid email address')
    .required(),
});

const selectEmailAddress = ({ session: { email_address } }) => email_address || '';

export const ResetPasswordForm = withResetPasswordHoc(
  ({ resetPassword, ...props }: WithResetPasswordHocChildProps) => {
    const classes = useStyles();
    const email_address = useSelector(selectEmailAddress);
    const [submit_success, setSubmitSuccess] = useState(false);
    const initial_values = useMemo(() => ({ email_address, general: '' }), [email_address]);
    const submitCallback = useCallback(
      (values) =>
        (async () => {
          setSubmitSuccess(false);
          await resetPassword(validationSchema.validateSync(values));
          setSubmitSuccess(true);
        })(),
      [resetPassword]
    );
    const submitFailureCallback = useCallback(() => setSubmitSuccess(false), []);
    return (
      <FormikWrapper<Values>
        {...props}
        enableReinitialize
        initialValues={initial_values}
        validationSchema={validationSchema}
        onSubmit={submitCallback}
        onSubmitFailure={submitFailureCallback}
        render={() => (
          <Dialog open maxWidth="xs">
            {!submit_success && <DialogTitle>Reset Password</DialogTitle>}
            <DialogContent style={{ padding: submit_success ? '8px 24px' : undefined }}>
              {submit_success ? (
                <p>A reset link has been sent</p>
              ) : (
                <BaseForm
                  className={classes.root}
                  submitting_message="Requesting a password reset..."
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    alignContent="flex-start"
                    justify="center"
                    spacing={2}
                  >
                    <Grid container item xs={12}>
                      <TextField
                        name="email_address"
                        placeholder="Email Address"
                        label="Email Address"
                        fullWidth
                        type="email"
                      />
                    </Grid>
                    <ErrorBox />
                    <Grid item xs={12} className={classes.centered}>
                      <ButtonSubmit allow_pristine>SEND ME A RESET LINK</ButtonSubmit>
                    </Grid>
                  </Grid>
                </BaseForm>
              )}
            </DialogContent>
          </Dialog>
        )}
      />
    );
  }
);
