import React, { FunctionComponent } from 'react';
import Select from 'react-select';

const DAYS = [
  {
    label: 'Monday',
    value: 'monday',
  },
  {
    label: 'Tuesday',
    value: 'tuesday',
  },
  {
    label: 'Wednesday',
    value: 'wednesday',
  },
  {
    label: 'Thursday',
    value: 'thursday',
  },
  {
    label: 'Friday',
    value: 'friday',
  },
  {
    label: 'Saturday',
    value: 'saturday',
  },
  {
    label: 'Sunday',
    value: 'sunday',
  },
];

type SelectProps = {
  name: string;
  selectedValue: string;
  isDisabled?: boolean;
  onChange: (value: { value: string; label: string }) => void;
};

export const SelectScheduleDay: FunctionComponent<SelectProps> = ({
  name,
  selectedValue,
  ...props
}) => {
  const defaultOption = DAYS.find((option) => option.value === selectedValue);
  return (
    <Select options={DAYS} name={name} value={defaultOption} isSearchable={false} {...props} />
  );
};
