import { Button, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { mobilebar_height } from '../../util';

const useStyles = makeStyles({
  root: { marginBottom: 10 },
  mobileRoot: { marginBottom: 10 + mobilebar_height },
  bold: { fontWeight: 'bold' },
});

const refresh = () => location.reload();

export const NetworkSnackbar: React.FunctionComponent<{
  mobile_offset: boolean;
  open: boolean;
}> = ({ mobile_offset, open }) => {
  const classes = useStyles();
  const message = (
    <div style={{ textAlign: 'start' }}>
      <p style={{ fontSize: 14, margin: 0, fontWeight: 'bold' }}>
        Can't connect right now. Please hit reload.
      </p>
    </div>
  );
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      message={message}
      // disable close by outside click
      ClickAwayListenerProps={{ onClickAway: () => {} }}
      className={mobile_offset ? classes.mobileRoot : classes.root}
      action={[
        <Button
          key="refresh"
          color="secondary"
          size="small"
          onClick={refresh}
          className={classes.bold}
        >
          Reload
        </Button>,
      ]}
    />
  );
};
