import { RECOMMENDATION_OPTIONS, RecommendedOptionValue } from './RecommendationOptionValue';

export enum FanControlsNavigation {
  ShowGenerateRecommSchedule = 'ShowGenerateRecommSchedule',
  ShowFanStatus = 'ShowFanStatus',
  ShowManualRunWindows = 'ShowManualRunWindows',
  ShowRecommendedRunWindows = 'ShowRecommendedRunWindows',
  AskCurrentGrainConditions = 'AskCurrentGrainConditions',
}

export interface FanControlNavigationOption {
  grain_bin_id?: number;
  navigationType: FanControlsNavigation;
  recommendationOptionValue: RecommendedOptionValue;
}

export function createShowFanStatusNavigation(): FanControlNavigationOption {
  return {
    grain_bin_id: undefined,
    navigationType: FanControlsNavigation.ShowFanStatus,
    recommendationOptionValue: RECOMMENDATION_OPTIONS[0],
  };
}

export function createShowGenerateRecommScheduleNavigation(): FanControlNavigationOption {
  return {
    grain_bin_id: undefined,
    navigationType: FanControlsNavigation.ShowGenerateRecommSchedule,
    recommendationOptionValue: RECOMMENDATION_OPTIONS[0],
  };
}

export function createNavigationType(
  navigationType: FanControlsNavigation,
  recommendationOptionValue: RecommendedOptionValue,
  grain_bin_id: number | undefined = undefined
): FanControlNavigationOption {
  return { grain_bin_id, navigationType, recommendationOptionValue };
}

export function createNavigationTypeFromNavOption(
  navigationType: FanControlsNavigation,
  option: FanControlNavigationOption
): FanControlNavigationOption {
  return {
    navigationType,
    recommendationOptionValue: option.recommendationOptionValue,
    grain_bin_id: option.grain_bin_id,
  };
}

export function createNavigationTypeFromOptionString(
  navigationType: FanControlsNavigation,
  option: String,
  grain_bin_id: number | undefined = undefined
): FanControlNavigationOption {
  const fanOption: RecommendedOptionValue = RECOMMENDATION_OPTIONS.find((it) => {
    return it.option.toString() === option;
  }) as RecommendedOptionValue;

  return createNavigationType(navigationType, fanOption, grain_bin_id);
}
