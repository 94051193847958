import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetGrainBinDocument,
  GetGrainBinQuery,
  GetGrainBinQueryVariables,
  InitiateGuidedRecommendationsMutation,
  InitiateGuidedRecommendationsMutationVariables as Variables,
  withInitiateGuidedRecommendations as HOC,
} from '../__generated';
import { GraphQLErrors } from './error';
export type WithInitiateGuidedRecommendationsHocChildProps = {
  initiateGuidedRecommendations: (
    variables: Variables
  ) => Promise<InitiateGuidedRecommendationsMutation['initiateGuidedRecommendations']>;
};
export const withInitiateGuidedRecommendationsHoc: AmberHoc<
  {},
  WithInitiateGuidedRecommendationsHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<{}, WithInitiateGuidedRecommendationsHocChildProps, typeof component>,
    WithInitiateGuidedRecommendationsHocChildProps
  >({
    options: { errorPolicy: 'all' },
    props: (props) => {
      const { mutate } = props;
      return {
        initiateGuidedRecommendations: async (variables) => {
          if (!mutate) {
            throw new Error('Unexpected UI error');
          }
          const result = await mutate({
            variables,
            update: async (proxy, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const { initiateGuidedRecommendations: updated } = data;
              if (!updated) {
                return;
              }
              const cached = await proxy.readQuery<GetGrainBinQuery, GetGrainBinQueryVariables>({
                query: GetGrainBinDocument,
                variables: { grain_bin_id: variables.container_id },
              });
              if (!cached) {
                return updated;
              }
              proxy.writeQuery<GetGrainBinQuery, GetGrainBinQueryVariables>({
                query: GetGrainBinDocument,
                data: {
                  __typename: 'Query',
                  grain_bin: { ...cached.grain_bin, container: updated },
                },
                variables: { grain_bin_id: variables.container_id },
              });
            },
          });
          if (!result) {
            throw new Error('Unexpected server response');
          }
          const { data, errors } = result;
          if (errors) {
            throw new GraphQLErrors(errors);
          }
          if (!data || !data.initiateGuidedRecommendations) {
            throw new Error('Unexpected server response');
          }
          return data.initiateGuidedRecommendations;
        },
      };
    },
  })(component as any);
