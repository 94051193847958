import { TextField as MuiTextField } from '@material-ui/core';
import { StandardTextFieldProps } from '@material-ui/core/TextField';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';

type NumberTextFieldProps = Omit<
  StandardTextFieldProps,
  'type' | 'name' | 'value' | 'onChange' | 'onBlur' | 'inputProps'
> & {
  name: string;
  min?: number;
  max?: number;
  step?: number | string;
  showError?: boolean;
  formatValue?: (value: number) => string;
  parseValue?: (value: string) => number | null;
  delayFormatting?: boolean;
};

const parseInt10 = (val: string) => parseInt(val, 10);

export const NumberTextField = ({
  name,
  formatValue = (val) => (val === undefined || val === null ? '' : val.toString()),
  parseValue = parseInt10,
  min,
  max,
  step,
  helperText,
  inputMode = 'numeric',
  delayFormatting = false,
  showError = true,
  ...props
}: NumberTextFieldProps) => {
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();
  const [field, { error, touched, value, initialValue }] = useField({ name });
  const [value_str, setValueStr] = useState(value ? formatValue(value) : '');

  useEffect(() => {
    let timeoutId;
    if (delayFormatting) {
      timeoutId = setTimeout(() => {
        if (!isNaN(value)) {
          setValueStr(formatValue(value));
        }
      }, 4000);
    } else {
      if (!isNaN(value)) {
        setValueStr(formatValue(value));
      }
    }
    return () => {
      delayFormatting && timeoutId && clearTimeout(timeoutId);
    };
  }, [value]);

  const handleEvent = useCallback(
    ({ target: { value: next } }) => {
      setValueStr(next);
      const parsed = next ? parseValue(next) : initialValue ? null : initialValue;
      setFieldValue(name, isNaN(parsed) ? next : parsed);
    },
    [initialValue, name]
  );
  const blurCallback = useCallback(() => {
    setFieldTouched(name, true);
  }, [name]);

  const has_error = Boolean(touched && error);
  return (
    <MuiTextField
      fullWidth
      type="number"
      variant="standard"
      id={`TextField-${name}`}
      error={has_error}
      helperText={has_error && showError ? error : helperText}
      name={name}
      {...props}
      {...field}
      value={value_str}
      onChange={handleEvent}
      onBlur={blurCallback}
      inputProps={{
        max,
        min,
        step,
        inputMode,
      }}
    />
  );
};

export const IntTextField = (props: Omit<NumberTextFieldProps, 'parseValue' | 'formatValue'>) => (
  <NumberTextField {...props} />
);
